import { Component, OnInit  } from '@angular/core';
import { Router } from '@angular/router';
import { ResourceDto } from '../../Models/ResourceDto';
import { SharedServicesService } from '../../shared/sharedservices/shared-services.service';
import { Subscription } from 'rxjs';
import { LoginService } from '../../landing/login.service';
import { PrivacyNoticeVM } from '../../Models/PrivacyNoticeVM';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-sub-privacy-notice',
  templateUrl: './privacy-notice.component.html',
  styleUrls: ['./privacy-notice.component.scss']
})
export class SubscribePrivacyNoticeComponent implements OnInit {
  public disableButton =true; 
  resource: ResourceDto;
  privacyNoticeSubscription: Subscription;
  privacyNoticeSubscription2: Subscription;
  activeTabName = 'PrivacyNotice'; 
  privacyNoticeVMs: PrivacyNoticeVM[] = [];
  role: string;
  
  constructor(public restService: SharedServicesService,
    public router: Router,
    private loginService: LoginService,
    private spinner: NgxSpinnerService
    ) { 
    this.resource = new ResourceDto();
  }

  ngOnInit() {
    this.resource.isPrivacyNoticePage=true;
    this.restService.setHeaderDetails(this.resource);
    this.getPrivacyNoticeData();
  }

  changeAcknowledgeBtnState()
  {
    this.disableButton = !this.disableButton;
  }

  changePrivacyNoticeStatus()
  {
    this.privacyNoticeSubscription =  this.restService.updatePrivacyNoticeStatus().subscribe(
      (data) => {
        this.loginService.RoutetoUserRolePage(data);
      })
  }
  
  getPrivacyNoticeData()
  {
    this.spinner.show();
    this.privacyNoticeSubscription2 = this.restService.getPrivacyNoticeData().subscribe(
    data => {
      this.privacyNoticeVMs = data;
      this.spinner.hide();
    });
  }
}
