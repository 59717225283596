import { MasterVM } from "./MasterVM";

export class ResourceReplaceConfigurationDto {
       ResReplaceSubAreas :MasterVM[];
       ResReplaceManagerialCountries :MasterVM[];
       ResReplaceLegalEntities:MasterVM[];
       ResReplaceServiceLines :MasterVM[];
       ResReplaceBusinessUnits:MasterVM[];
       ResReplaceSubServiceLines :MasterVM[];
       ResReplaceSubManageUnits :MasterVM[]
       ResReplaceRanks :MasterVM[];
       RRRC_RInactive:boolean;
       RRRC_ISDisabled :boolean;
       RRRC_ISLikeEdit :boolean;
       RRRC_IV_Disabled :boolean;
       RRRC_EV_Disabled :boolean;
}

export class JobReplaceConfigurationDto {
       JobReplaceSubAreas :MasterVM[];
       JobReplaceManagerialCountries :MasterVM[];
       JobReplaceLegalEntities:MasterVM[];
       JobReplaceServiceLines :MasterVM[];
       JobReplaceBusinessUnits:MasterVM[];
       JobReplaceSubServiceLines :MasterVM[];
       JobReplaceSubManageUnits :MasterVM[]
       JobReplaceRanks :MasterVM[];
       JobReplaceJobCategories:MasterVM[];
       JobReplaceJobStatuses :MasterVM[];
       JobReplaceJobStatusBookings :MasterVM[]
       JobReplaceJobCode :MasterVM[];
       JobReplaceIncludeJobCode
       RJRC_Learning :boolean;
       RJRC_ISDisabled :boolean;
       RJRC_ISLikeEdit:boolean;
       RJRC_IsPended:boolean;
       RJRC_IV_Disabled:boolean;
       RJRC_EV_Disabled:boolean;

}