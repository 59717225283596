import { Component, OnInit, OnDestroy, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { OrderPipe } from 'ngx-order-pipe';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { WorkRequestService } from '../../Views/work-request/work-request.service'
import { WorkRequestQueueVM } from '../../Models/WorkRequestQueueVM';
import { BookingGridActionService } from '../../Models/BookingGridActionService';
import { SharedServicesService } from '../../shared/sharedservices/shared-services.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserVM } from 'app/Models/UserVM';
import { ExcelService } from 'app/shared/excel/excel.service';
import { formatDate } from "@angular/common";

@Component({
  selector: 'app-work-request',
  templateUrl: './work-request.component.html',
  styleUrls: ['./work-request.component.scss']
})
export class WorkRequestComponent implements OnInit {
  workRequestColumns: string[] =
    ['RequestId', 'RequestorName', 'BookingId', 'ActionDescriptionId', 'ActionDescription',
      'Engagement', 'ResourceName', 'RankDescription', 'ServiceLine', 'SubServiceLineDescription',
      'SubManagementUnitDescription', 'Department', 'CostCenter', 'LocationDescription', 'SubArea', 'RequestMode', 'Hours',
      'Loading', 'UserSelection', 'StartDate', 'EndDate', 'SubmittedOn',
      'Unconfirmed', 'StatusDescription', 'UpdatedBy'];

      filterWorkRequestColumns: string[] =
      ['RequestIdFilter', 'RequestorNameFilter', 'BookingIdFilter', 'ActionDescriptionIdFilter', 'ActionDescriptionFilter',
        'EngagementFilter', 'ResourceNameFilter', 'RankDescriptionFilter', 'ServiceLineFilter', 'SubServiceLineDescriptionFilter',
        'SubManagementUnitDescriptionFilter', 'DepartmentFilter', 'CostCenterFilter', 'LocationDescriptionFilter', 'SubAreaFilter', 'RequestModeFilter', 'HoursFilter',
        'LoadingFilter', 'UserSelectionFilter', 'StartDateFilter', 'EndDateFilter', 'SubmittedOnFilter',
        'UnconfirmedFilter', 'StatusDescriptionFilter', 'UpdatedByFilter'];
  
      excelColumns = [
        {Key:'RequestId', value:'Request ID'}, 
        {Key:'RequestorName', value:'Requestor Name'}, 
        {Key:'BookingId', value:'Booking ID'},
        {Key:'ActionDescriptionId', value:'Action ID'},
        {Key:'ActionDescription', value:'Action Description'},
        {Key:'InstanceName', value:'Retain Instance'},
        {Key:'Engagement', value:'Engagement'}, 
        {Key:'ResourceName', value:'Resource Name'}, 
        {Key:'RankDescription', value:'Rank'}, 
        {Key:'ServiceLine', value:'Service Line'},
        {Key:'SubServiceLineDescription', value:'Sub Service Line'},
        {Key:'SubManagementUnitDescription', value:'Sub Management Unit'},
        {Key:'Department', value:'HR Department'},
        {Key:'CostCenter', value:'Cost Center'}, 
        {Key:'LocationDescription', value:'Office Location'},
        {Key:'SubArea', value:'Sub Area'},
        {Key:'RequestMode', value:'Request Mode'},
        {Key:'Hours', value:'Hours'},
        {Key:'Loading', value:'Loading'},
        {Key:'UserSelection' , value:'User Selection'},
        {Key:'StartDate', value:'Start Date'},
        {Key:'EndDate', value:'End Date'},
        {Key:'SubmittedOn', value:'Requested Date/Time(GMT)'},
        {Key:'Unconfirmed', value:'Unconfirmed'},
        {Key:'Ghost', value:'Ghost'},
        {Key:'StatusDescription', value:'Status'},
        {Key:'UpdatedBy', value:'Updated By'},
     ]
  workRequestdataSource: MatTableDataSource<WorkRequestQueueVM>;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChild('empTbSort') empTbSort = new MatSort();
  selectedCallback = (args) => args.dataItem;
  activeTabName = 'WorkRequest';
  modalRef: BsModalRef;
  modalOption: ModalOptions = {};
  bookingWR: WorkRequestQueueVM;
  bookingList: WorkRequestQueueVM[];
  workRequestSubmission: WorkRequestQueueVM;
  bookingListSubscription: Subscription;
  recordAvailable: any;
  isRecordAvailable: any;
  public rowsToHighlight: any[] = [];
  excelData: any;
  excelDataSubscribtion: Subscription;
  currentUserName: any;
  currentUserId: any;
  currentUserEmail: any;
  workRequestList: WorkRequestQueueVM[];
  requestIdFilter = new FormControl('');
  requestorNameFilter = new FormControl('');
  bookingIdFilter = new FormControl('');
  actionDescriptionIdFilter = new FormControl('');
  actionDescriptionFilter = new FormControl('');
  engagementFilter = new FormControl('');
  resourceNameFilter = new FormControl('');
  rankDescriptionFilter = new FormControl('');
  serviceLineFilter = new FormControl('');
  subServiceLineDescriptionFilter = new FormControl('');
  subManagementUnitDescriptionFilter = new FormControl('');
  departmentFilter = new FormControl('');
  costCenterFilter = new FormControl('');
  locationDescriptionFilter = new FormControl('');
  subAreaFilter = new FormControl('');
  requestModeFilter = new FormControl('');
  hoursFilter = new FormControl('');
  loadingFilter = new FormControl('');
  userSelectionFilter = new FormControl('');
  unconfirmedFilter = new FormControl('');
  statusDescriptionFilter = new FormControl('');
  updatedByFilter = new FormControl('');
  keywordSearch = new FormControl('');
  filterValues = {
    requestId: '',
    requestIdType: true,
    requestorName: '',
    requestorNameType: true,
    bookingId: '',
    bookingIdType: true,
    actionDescriptionId: '',
    actionDescriptionIdType: true,
    actionDescription: '',
    actionDescriptionType: true,
    engagement: '',
    engagementType: true,
    resourceName: '',
    resourceNameType: true,
    rankDescription: '',
    rankDescriptionType: true,
    serviceLine: '',
    serviceLineType: true,
    subServiceLineDescription: '',
    subServiceLineDescriptionType: true,
    subManagementUnitDescription: '',
    subManagementUnitDescriptionType: true,
    department:'',
    departmentType: true,
    costCenter:'',
    costCenterType: true,
    locationDescription: '',
    locationDescriptionType: true,
    subArea: '',
    subAreaType: true,
    requestMode: '',
    requestModeType: true,
    hours: '',
    hoursType: true,
    loading: '',
    loadingType: true,
    userSelection: '',
    userSelectionType: true,
    unconfirmed: '',
    unconfirmedType: true,
    statusDescription: '',
    statusDescriptionType: true,
    updatedBy: '',
    updatedByType: true,
    global: '',
    globalType: false
  };
  
  loggedInUser: UserVM;
  defaultDateFormat: string;

  constructor(public restService: WorkRequestService,
    private spinner: NgxSpinnerService,
    public sharedService: SharedServicesService,
    private excelService: ExcelService,
    private modalService: BsModalService,
  ) {
    this.sharedService.activeLink = 'WorkRequestQueue';
  }

  ngOnInit() {
    this.loggedInUser = JSON.parse(sessionStorage.getItem('resource'));
    this.defaultDateFormat = this.sharedService.getDateFormat(this.loggedInUser.DefaultDateFormat);
    this.sharedService.activeLink = 'WorkRequestQueue';
    this.currentUserName = this.loggedInUser.ResourceName;
    this.currentUserEmail = this.loggedInUser.Email;
    this.currentUserId = sessionStorage.getItem("resourceId");
    if(this.loggedInUser.Unconfirmed){
      this.workRequestColumns.splice(this.workRequestColumns.indexOf('SubmittedOn')+1, 1);
      this.filterWorkRequestColumns.splice(this.filterWorkRequestColumns.indexOf('SubmittedOnFilter')+1, 1);
    }
    if(this.loggedInUser.IsMultiInstance){
      this.workRequestColumns.splice(this.workRequestColumns.indexOf('ActionDescription')+1, 0, 'Instance');
      this.filterWorkRequestColumns.splice(this.filterWorkRequestColumns.indexOf('ActionDescriptionFilter')+1, 0, 'InstanceFilter');
    }
    this.bookingWR = new WorkRequestQueueVM();
    if(this.loggedInUser.Ghost){
      this.workRequestColumns.splice(this.workRequestColumns.indexOf('Unconfirmed')+1, 0, 'Ghost');
      this.filterWorkRequestColumns.splice(this.filterWorkRequestColumns.indexOf('UnconfirmedFilter')+1, 0, 'GhostFilter');
    }
    // this.gridActionEventData = new BookingGridActionService();
    // this.workRequestSubmission = new WorkRequestQueueVM();  
    this.restService.refreshWorkRequestQueueEvent.subscribe(() => {
      this.getBookingList();
    });
    this.restService.currentMessageCloseModal.subscribe((closeModal) => {
      this.closeActionModal(closeModal);
    });

    this.requestIdFilter.valueChanges
      .subscribe(
        requestId => {
          this.filterValues.requestId = requestId;
          this.workRequestdataSource.filter = JSON.stringify(this.filterValues);
        }
      )
    this.requestorNameFilter.valueChanges
      .subscribe(
        requestorName => {
          this.filterValues.requestorName = requestorName;
          this.workRequestdataSource.filter = JSON.stringify(this.filterValues);
        }
      )
    this.bookingIdFilter.valueChanges
      .subscribe(
        bookingId => {
          this.filterValues.bookingId = bookingId;
          this.workRequestdataSource.filter = JSON.stringify(this.filterValues);
        }
      )
    this.actionDescriptionIdFilter.valueChanges
      .subscribe(
        actionDescriptionId => {
          this.filterValues.actionDescriptionId = actionDescriptionId;
          this.workRequestdataSource.filter = JSON.stringify(this.filterValues);
        }
      )
    this.actionDescriptionFilter.valueChanges
      .subscribe(
        actionDescription => {
          this.filterValues.actionDescription = actionDescription;
          this.workRequestdataSource.filter = JSON.stringify(this.filterValues);
        }
      )
    this.engagementFilter.valueChanges
      .subscribe(
        engagement => {
          this.filterValues.engagement = engagement;
          this.workRequestdataSource.filter = JSON.stringify(this.filterValues);
        }
      )
    this.resourceNameFilter.valueChanges
      .subscribe(
        resourceName => {
          this.filterValues.resourceName = resourceName;
          this.workRequestdataSource.filter = JSON.stringify(this.filterValues);
        }
      )

    this.rankDescriptionFilter.valueChanges
      .subscribe(
        rankDescription => {
          this.filterValues.rankDescription = rankDescription;
          this.workRequestdataSource.filter = JSON.stringify(this.filterValues);
        }
      )
    this.serviceLineFilter.valueChanges
      .subscribe(
        serviceLine => {
          this.filterValues.serviceLine = serviceLine;
          this.workRequestdataSource.filter = JSON.stringify(this.filterValues);
        }
      )
    this.subServiceLineDescriptionFilter.valueChanges
      .subscribe(
        subServiceLineDescription => {
          this.filterValues.subServiceLineDescription = subServiceLineDescription;
          this.workRequestdataSource.filter = JSON.stringify(this.filterValues);
        }
      )
    this.subManagementUnitDescriptionFilter.valueChanges
      .subscribe(
        subManagementUnitDescription => {
          this.filterValues.subManagementUnitDescription = subManagementUnitDescription;
          this.workRequestdataSource.filter = JSON.stringify(this.filterValues);
        }
      )
    this.departmentFilter.valueChanges
      .subscribe(
        dept => {
          this.filterValues.department = dept;
          this.workRequestdataSource.filter = JSON.stringify(this.filterValues);
        }
      )
    this.costCenterFilter.valueChanges
      .subscribe(
        costcenter => {
          this.filterValues.costCenter = costcenter;
          this.workRequestdataSource.filter = JSON.stringify(this.filterValues);
        }
      )
    this.locationDescriptionFilter.valueChanges
      .subscribe(
        locationDescription => {
          this.filterValues.locationDescription = locationDescription;
          this.workRequestdataSource.filter = JSON.stringify(this.filterValues);
        }
      )
    this.subAreaFilter.valueChanges
      .subscribe(
        subArea => {
          this.filterValues.subArea = subArea;
          this.workRequestdataSource.filter = JSON.stringify(this.filterValues);
        }
      )
    this.requestModeFilter.valueChanges
      .subscribe(
        requestMode => {
          this.filterValues.requestMode = requestMode;
          this.workRequestdataSource.filter = JSON.stringify(this.filterValues);
        }
      )
    this.hoursFilter.valueChanges
      .subscribe(
        hours => {
          this.filterValues.hours = hours;
          this.workRequestdataSource.filter = JSON.stringify(this.filterValues);
        }
      )
    this.loadingFilter.valueChanges
      .subscribe(
        loading => {
          this.filterValues.loading = loading;
          this.workRequestdataSource.filter = JSON.stringify(this.filterValues);
        }
      )
    this.userSelectionFilter.valueChanges
      .subscribe(
        userSelection => {
          this.filterValues.userSelection = userSelection;
          this.workRequestdataSource.filter = JSON.stringify(this.filterValues);
        }
      )
    this.unconfirmedFilter.valueChanges
      .subscribe(
        unconfirmed => {
          this.filterValues.unconfirmed = unconfirmed;
          this.workRequestdataSource.filter = JSON.stringify(this.filterValues);
        }
      )
    this.statusDescriptionFilter.valueChanges
      .subscribe(
        statusDescription => {
          this.filterValues.statusDescription = statusDescription;
          this.workRequestdataSource.filter = JSON.stringify(this.filterValues);
        }
      )
    this.updatedByFilter.valueChanges
      .subscribe(
        updatedBy => {
          this.filterValues.updatedBy = updatedBy;
          this.workRequestdataSource.filter = JSON.stringify(this.filterValues);
        }
      )
      this.keywordSearch.valueChanges.subscribe(
        filterValue => {
          if(filterValue.trim().length > 0){
            this.filterValues.globalType = true;
          }else{
            this.filterValues.globalType = false;
          }
          this.filterValues.global = filterValue;
          this.workRequestdataSource.filter = JSON.stringify(this.filterValues);
        }
      )
  }

  getBookingList() {
    this.bookingWR.CurrentUserEmailId = this.currentUserEmail;
    this.bookingWR.CurrentUserName = this.currentUserName;
    this.bookingWR.LoggedInResourceId = + sessionStorage.getItem('loggedin_resourceid');
    this.bookingListSubscription = this.restService.getBookingListForGrid(this.bookingWR).subscribe(
      data => {
        this.bookingList = data;
        this.bookingList.forEach((element) => {
          element.startDate_sort=element.StartDate;
          element.endDate_sort=element.EndDate;
          element.SubmittedOn_sort = element.SubmittedOn;
          element.StartDate = formatDate(element.StartDate, this.defaultDateFormat, 'en-US');
          element.EndDate = formatDate(element.EndDate, this.defaultDateFormat, 'en-US');
          element.SubmittedOn = formatDate(element.SubmittedOn, `${this.defaultDateFormat} HH:mm`, 'en-US');
          element.Unconfirmed = element.Unconfirmed =="True" ? "Yes" : "No" ;
          element.Ghost = element.Ghost?.toString() === 'false' ? "No" : "Yes" ;
          if (element.TimeDifference >= 24) {
            this.rowsToHighlight.push(element.TimeDifference);
          } else {
            element.TimeDifference = 0;
          }
        });
        
        this.workRequestdataSource = new MatTableDataSource(this.bookingList);
        this.empTbSort.disableClear = true;
        this.workRequestdataSource.paginator = this.paginator.toArray()[0];
        this.workRequestdataSource.sort = this.sort.toArray()[0];
        this.workRequestdataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'StartDate': return new Date(item.startDate_sort);
            case 'EndDate' : return new Date(item.endDate_sort);
            case 'SubmittedOn': return new Date(item.SubmittedOn_sort);
            default: return item[property];
          }
        };
        this.workRequestdataSource.filterPredicate = this.createFilterForWorkRequest();

      });
  }

  createFilterForWorkRequest(): (data: any, filter: string) => boolean {
    let filterFunction = function (data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      if(searchTerms.globalType){
        return ( (data.ActionDescription ? data.ActionDescription?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
        (data.ActionDescriptionId ? data.ActionDescriptionId?.toString().toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
        (data.BookingId ? data.BookingId?.toString().toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
        (data.CostCenter ? data.CostCenter?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
        (data.Department ? data.Department?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
        (data.EndDate ? data.EndDate?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
        (data.Engagement ? data.Engagement?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
        (data.Ghost !== null ? data.Ghost?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
        (data.Hours ? data.Hours?.toString().toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
        (data.InstanceName ? data.InstanceName?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
        (data.Loading ? data.Loading?.toString().toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
        (data.LocationDescription ? data.LocationDescription?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
        (data.RankDescription ? data.RankDescription?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
        (data.RejectionReasons ? data.RejectionReasons?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false)||
        (data.RequestId ? data.RequestId?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
        (data.RequestMode ? data.RequestMode?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
        (data.RequestorName ? data.RequestorName?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
        (data.ResourceName ? data.ResourceName?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
        (data.ServiceLine ? data.ServiceLine?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
        (data.StartDate ? data.StartDate?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
        (data.StatusDescription ? data.StatusDescription?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
        (data.SubArea ? data.SubArea?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
        (data.SubManagementUnitDescription ? data.SubManagementUnitDescription?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
        (data.SubServiceLineDescription ? data.SubServiceLineDescription?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
        (data.SubmittedOn ? data.SubmittedOn?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
        (data.Unconfirmed ? data.Unconfirmed?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
        (data.UpdatedBy ? data.UpdatedBy?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
        (data.UserSelection ? data.UserSelection?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) 
       );
      }else{
        return ((searchTerms.requestIdType ? data.RequestId?.toLowerCase().indexOf(searchTerms.requestId.toLowerCase()) !== -1 : data.RequestId?.toLowerCase().startsWith(searchTerms.requestId.toLowerCase()) !== false)
        &&(searchTerms.requestorNameType ? data.RequestorName?.toLowerCase().indexOf(searchTerms.requestorName.toLowerCase()) !== -1 : data.RequestorName?.toLowerCase().startsWith(searchTerms.requestorName.toLowerCase()) !== false)
      &&(searchTerms.bookingIdType ? data.BookingId?.toString().toLowerCase().indexOf(searchTerms.bookingId.toLowerCase()) !== -1 : data.BookingId?.toString().toLowerCase().startsWith(searchTerms.bookingId.toLowerCase()) !== false)
      &&(searchTerms.actionDescriptionIdType ? data.ActionDescriptionId?.toString().toLowerCase().indexOf(searchTerms.actionDescriptionId.toLowerCase()) !== -1 : data.ActionDescriptionId?.toString().toLowerCase().startsWith(searchTerms.actionDescriptionId.toLowerCase()) !== false)
      &&(searchTerms.actionDescriptionType ? data.ActionDescription?.toLowerCase().indexOf(searchTerms.actionDescription.toLowerCase()) !== -1 : data.ActionDescription?.toLowerCase().startsWith(searchTerms.actionDescription.toLowerCase()) !== false)
      &&(searchTerms.engagementType ? data.Engagement?.toLowerCase().indexOf(searchTerms.engagement.toLowerCase()) !== -1 : data.Engagement?.toLowerCase().startsWith(searchTerms.engagement.toLowerCase()) !== false)
      &&(searchTerms.resourceNameType ? data.ResourceName?.toLowerCase().indexOf(searchTerms.resourceName.toLowerCase()) !== -1 : data.ResourceName?.toLowerCase().startsWith(searchTerms.resourceName.toLowerCase()) !== false)
      &&(searchTerms.rankDescriptionType ? data.RankDescription?.toLowerCase().indexOf(searchTerms.rankDescription.toLowerCase()) !== -1 : data.RankDescription?.toLowerCase().startsWith(searchTerms.rankDescription.toLowerCase()) !== false)
      &&(searchTerms.serviceLineType ? data.ServiceLine?.toLowerCase().indexOf(searchTerms.serviceLine.toLowerCase()) !== -1 : data.ServiceLine?.toLowerCase().startsWith(searchTerms.serviceLine.toLowerCase()) !== false)
      &&(searchTerms.subServiceLineDescriptionType ? data.SubServiceLineDescription?.toLowerCase().indexOf(searchTerms.subServiceLineDescription.toLowerCase()) !== -1 : data.SubServiceLineDescription?.toLowerCase().startsWith(searchTerms.subServiceLineDescription.toLowerCase()) !== false)
      &&(searchTerms.subManagementUnitDescriptionType ? data.SubManagementUnitDescription?.toLowerCase().indexOf(searchTerms.subManagementUnitDescription.toLowerCase()) !== -1 : data.SubManagementUnitDescription?.toLowerCase().startsWith(searchTerms.subManagementUnitDescription.toLowerCase()) !== false)
      &&(searchTerms.departmentType ? data.Department?.toLowerCase().indexOf(searchTerms.department.toLowerCase()) !== -1 : data.Department?.toLowerCase().startsWith(searchTerms.department.toLowerCase()) !== false)
      &&(searchTerms.costCenterType ? data.CostCenter?.toLowerCase().indexOf(searchTerms.costCenter.toLowerCase()) !== -1 : data.CostCenter?.toLowerCase().startsWith(searchTerms.costCenter.toLowerCase()) !== false)
      &&(searchTerms.locationDescriptionType ? data.LocationDescription?.toLowerCase().indexOf(searchTerms.locationDescription.toLowerCase()) !== -1 : data.LocationDescription?.toLowerCase().startsWith(searchTerms.locationDescription.toLowerCase()) !== false)
      &&(searchTerms.subAreaType ? data.SubArea?.toLowerCase().indexOf(searchTerms.subArea.toLowerCase()) !== -1 : data.SubArea?.toLowerCase().startsWith(searchTerms.subArea.toLowerCase()) !== false)
      &&(searchTerms.requestModeType ? data.RequestMode?.toLowerCase().indexOf(searchTerms.requestMode.toLowerCase()) !== -1 : data.RequestMode?.toLowerCase().startsWith(searchTerms.requestMode.toLowerCase()) !== false)
      &&(searchTerms.hoursType ? data.Hours?.toString().toLowerCase().indexOf(searchTerms.hours.toLowerCase()) !== -1 : data.Hours?.toString().toLowerCase().startsWith(searchTerms.hours.toLowerCase()) !== false)
      &&(searchTerms.loadingType ? data.Loading?.toString().toLowerCase().indexOf(searchTerms.loading.toLowerCase()) !== -1 : data.Loading?.toString().toLowerCase().startsWith(searchTerms.loading.toLowerCase()) !== false)
      &&(searchTerms.userSelectionType ? data.UserSelection?.toLowerCase().indexOf(searchTerms.userSelection.toLowerCase()) !== -1 : data.UserSelection?.toLowerCase().startsWith(searchTerms.userSelection.toLowerCase()) !== false)
      &&(searchTerms.unconfirmedType ? data.Unconfirmed?.toLowerCase().indexOf(searchTerms.unconfirmed.toLowerCase()) !== -1 : data.Unconfirmed?.toLowerCase().startsWith(searchTerms.unconfirmed.toLowerCase()) !== false)
      &&(searchTerms.statusDescriptionType ? data.StatusDescription?.toLowerCase().indexOf(searchTerms.statusDescription.toLowerCase()) !== -1 : data.StatusDescription?.toLowerCase().startsWith(searchTerms.statusDescription.toLowerCase()) !== false)
      &&(searchTerms.updatedByType ? data.UpdatedBy?.toLowerCase().indexOf(searchTerms.updatedBy.toLowerCase()) !== -1 : data.UpdatedBy?.toLowerCase().startsWith(searchTerms.updatedBy.toLowerCase()) !== false));
      }
      // if (searchTerms.contains) {
      //   return data.RequestId?.indexOf(searchTerms.requestId) !== -1
      //     && data.RequestorName?.toLowerCase().indexOf(searchTerms.requestorName) !== -1
      //     && data.BookingId?.toString().indexOf(searchTerms.bookingId) !== -1
      //     && data.ActionDescriptionId?.toString().indexOf(searchTerms.actionDescriptionId) !== -1
      //     && data.ActionDescription?.toLowerCase().indexOf(searchTerms.actionDescription) !== -1
      //     && data.Engagement?.toLowerCase().indexOf(searchTerms.engagement) !== -1
      //     && data.ResourceName?.toLowerCase().indexOf(searchTerms.resourceName) !== -1
      //     && data.RankDescription?.toLowerCase().indexOf(searchTerms.rankDescription) !== -1
      //     && data.ServiceLine?.toLowerCase().indexOf(searchTerms.serviceLine) !== -1
      //     && data.SubServiceLineDescription?.toLowerCase().indexOf(searchTerms.subServiceLineDescription) !== -1
      //     && data.SubManagementUnitDescription?.toLowerCase().indexOf(searchTerms.subManagementUnitDescription) !== -1
      //     && data.Department?.toLowerCase().indexOf(searchTerms.department) !== -1
      //     && data.CostCenter?.toLowerCase().indexOf(searchTerms.costCenter) !== -1
      //     && data.LocationDescription?.toLowerCase().indexOf(searchTerms.locationDescription) !== -1
      //     && data.SubArea?.toLowerCase().indexOf(searchTerms.subArea) !== -1
      //     && data.RequestMode?.toLowerCase().indexOf(searchTerms.requestMode) !== -1
      //     && data.Hours?.toString().indexOf(searchTerms.hours) !== -1
      //     && data.Loading?.toString().indexOf(searchTerms.loading) !== -1
      //     && data.UserSelection?.toLowerCase().indexOf(searchTerms.userSelection) !== -1
      //     && data.Unconfirmed?.toLowerCase().indexOf(searchTerms.unconfirmed) !== -1
      //     && data.StatusDescription?.toLowerCase().indexOf(searchTerms.statusDescription) !== -1
      //     && data.UpdatedBy?.toLowerCase().indexOf(searchTerms.updatedBy) !== -1
      // } else {
      //   return data.RequestId?.indexOf(searchTerms.requestId) !== false
      //     && data.RequestorName?.toLowerCase().indexOf(searchTerms.requestorName) !== false
      //     && data.BookingId?.toString().indexOf(searchTerms.bookingId) !== false
      //     && data.ActionDescriptionId?.toString().indexOf(searchTerms.actionDescriptionId) !== false
      //     && data.ActionDescription?.toLowerCase().indexOf(searchTerms.actionDescription) !== false
      //     && data.Engagement?.toLowerCase().indexOf(searchTerms.engagement) !== false
      //     && data.ResourceName?.toLowerCase().indexOf(searchTerms.resourceName) !== false
      //     && data.RankDescription?.toLowerCase().indexOf(searchTerms.rankDescription) !== false
      //     && data.ServiceLine?.toLowerCase().indexOf(searchTerms.serviceLine) !== false
      //     && data.SubServiceLineDescription?.toLowerCase().indexOf(searchTerms.subServiceLineDescription) !== false
      //     && data.SubManagementUnitDescription?.toLowerCase().indexOf(searchTerms.subManagementUnitDescription) !== false
      //     && data.Department?.toLowerCase().indexOf(searchTerms.department) !== false
      //     && data.CostCenter?.toLowerCase().indexOf(searchTerms.costCenter) !== false
      //     && data.LocationDescription?.toLowerCase().indexOf(searchTerms.locationDescription) !== false
      //     && data.SubArea?.toLowerCase().indexOf(searchTerms.subArea) !== false
      //     && data.Hours?.toString().indexOf(searchTerms.hours) !== false
      //     && data.RequestMode?.toLowerCase().indexOf(searchTerms.requestMode) !== false
      //     && data.Loading?.toString().indexOf(searchTerms.loading) !== false
      //     && data.UserSelection?.toLowerCase().indexOf(searchTerms.userSelection) !== false
      //     && data.Unconfirmed?.toLowerCase().indexOf(searchTerms.unconfirmed) !== false
      //     && data.StatusDescription?.toLowerCase().indexOf(searchTerms.statusDescription) !== false
      //     && data.UpdatedBy?.toLowerCase().indexOf(searchTerms.updatedBy) !== false
      // }

    }
    return filterFunction;
  }
  closeActionModal(closeModal) {
    if (closeModal) {
      if (this.modalRef !== undefined && this.modalRef !== null) {
        this.modalRef.hide();
      }
    }
  }


  exportAsXLSX(): void {
  const fileName = 'WorkRequestLog_export_' + new Date().toISOString();
  var dataToExport =[];
    this.workRequestdataSource.data.forEach(element => {
      var data = {};
      this.excelColumns.forEach(item=>{
        if(item.Key =="Unconfirmed")
        {
          data[item.value] = element[item.Key];
        }
        if(item.Key =="Ghost" && this.loggedInUser.Ghost)
        {
          data[item.value] = element[item.Key];
        }
        if (
          (item.Key =="InstanceName" && this.loggedInUser.IsMultiInstance)
          || (item.Key !="Ghost" && item.Key !="InstanceName" && item.Key !="Unconfirmed"))
        {
          data[item.value] = element[item.Key];
        }
        
      })
      dataToExport.push(data);
    });

  let workbookData= [
    {
      workSheet: 'Sheet1',
      rows:dataToExport
    }
  ]

  this.excelService.exportAsExcelFile(workbookData, fileName);
  }

  clearByMeFilter() {
    this.workRequestdataSource.filter = '';
    this.requestIdFilter.setValue('');
    this.actionDescriptionIdFilter.setValue('');
    this.actionDescriptionFilter.setValue('');
    this.bookingIdFilter.setValue('');
    this.resourceNameFilter.setValue('');
    this.engagementFilter.setValue('');
    this.requestorNameFilter.setValue('');
    this.resourceNameFilter.setValue('');
    this.rankDescriptionFilter.setValue('');
    this.serviceLineFilter.setValue('');
    this.subServiceLineDescriptionFilter.setValue('');
    this.subManagementUnitDescriptionFilter.setValue('');
    this.departmentFilter.setValue('');
    this.costCenterFilter.setValue('');
    this.locationDescriptionFilter.setValue('');
    this.subAreaFilter.setValue('');
    this.requestModeFilter.setValue('');
    this.hoursFilter.setValue('');
    this.loadingFilter.setValue('');
    this.userSelectionFilter.setValue('');
    this.unconfirmedFilter.setValue('');
    this.statusDescriptionFilter.setValue('');
    this.updatedByFilter.setValue('');
    this.keywordSearch.setValue('');
    this.filterValues.requestIdType = true;
    this.filterValues.requestorNameType = true;
    this.filterValues.bookingIdType = true;
    this.filterValues.actionDescriptionIdType = true;
    this.filterValues.actionDescriptionType = true;
    this.filterValues.engagementType = true;
    this.filterValues.resourceNameType = true;
    this.filterValues.rankDescriptionType = true;
    this.filterValues.serviceLineType = true;
    this.filterValues.subServiceLineDescriptionType = true;
    this.filterValues.subManagementUnitDescriptionType = true;
    this.filterValues.departmentType = true;
    this.filterValues.costCenterType = true;
    this.filterValues.locationDescriptionType = true;
    this.filterValues.subAreaType = true;
    this.filterValues.requestModeType = true;
    this.filterValues.hoursType = true;
    this.filterValues.loadingType = true;
    this.filterValues.userSelectionType = true;
    this.filterValues.unconfirmedType = true;
    this.filterValues.statusDescriptionType = true;
    this.filterValues.updatedByType = true;
    this.filterValues.globalType = false;

  }

  openFilterModal(template) {
    this.keywordSearch.setValue('');
    this.filterValues.global = '';
    this.filterValues.globalType = false;
    this.workRequestdataSource.filter = JSON.stringify(this.filterValues);
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.modalRef = this.modalService.show(template, this.modalOption);
  }

  closeFilterModal() {
    this.clearByMeFilter();
    this.modalRef.hide();
  }

  filterGrid() {
    this.workRequestdataSource.filter = JSON.stringify(this.filterValues);
    this.modalRef.hide();
  }
  

}
