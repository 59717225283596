import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CompletedRequestFilterDto, CompletedRequestResultVM, CompletedRequestVM } from '../../Models/CompletedRequestVM';
//import 'rxjs/add/operator/share';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class CompletedRequestQueueService extends BehaviorSubject<any> {

  @Output() change: EventEmitter<boolean> = new EventEmitter();
  
  endpoint = environment.apiEndpoint;

  constructor(private http: HttpClient) {
    super(null);
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  private getAPI(actionName: string): string {
    return this.endpoint + 'CompletedRequest/' + actionName;
  }
  
  getFilterMasterData(): Observable<any> {
    return this.http.get<any>(this.getAPI('getFilterMasterData'), this.httpOptions).pipe(
      map(data => data),
      catchError(this.handleError<any>('getFilterMasterData'))
    );
  }

  getCompletedListForGrid(booking: CompletedRequestFilterDto): Observable<any> {
    return this.http.post<CompletedRequestResultVM>(this.getAPI('FetchCompletedRequestList'), booking,
      this.httpOptions).pipe(
        map(data => data),
        catchError(this.handleError<any>())
      );
  }

  async getExportData(booking: CompletedRequestFilterDto) {
    const excelData = await this.http.post<any>(this.getAPI('FetchCompletedRequestList'), booking,  this.httpOptions).toPromise();
    return excelData;
  }

  private handleError<T>(result?: T) {
    return (error: any): Observable<T> => {

      console.error(error); // log to console instead


      return of(result as T);
    };
  }
}
