<div class="popupLayout">
  <div class="fullWidth">
    <div style="font-size: 12px; margin:3px 0">Please note that requests not submitted by the end of each working day will be deleted during the daily refresh process. Ensure all requests are submitted no later than 6pm.</div>
  </div>
  <div class="previewTable">
    <table class="table tablePreview table-bordered">
      <tbody>
        <tr>
          <th>Select</th>
          <th>Action ID</th>
          <th>Action</th>
          <th>Engagement</th>
          <th>Resource Name</th>
          <th>Rank</th>
          <th>Hours</th>
          <th>Loading</th>
          <th>Start date</th>
          <th>End Date</th>
          <th>Unconfirmed</th>
          <th>User Selection</th>
        </tr>
        <tr *ngFor="let data of previewGridSource">
          <td> <mat-checkbox 
            (click)="$event.stopPropagation(); BokingSelection(data)"
            (change)="$event ? selection.toggle(data) : null" [checked]="selection.isSelected(data)"></mat-checkbox></td>
          <td>{{data.MeraBookingId}}</td>
          <td>{{data.ActionDescription}}</td>
          <td>{{data.JobDescription}}</td>
          <td>{{data.ResourceName}}</td>
          <td>{{data.RankDescription}}</td>
          <td>{{data.Hours}}</td>
          <td class="percentageColumn">{{data.Loading}} %</td>
          <td class="dateColumn">{{data.StartDate | date : defaultDateFormat}}</td>
          <td class="dateColumn">{{data.EndDate | date : defaultDateFormat}}</td>
          <td>{{data.Unconfirmed}}</td>
          <td>{{data.UserSelection}}</td>
        </tr>
      </tbody>
    </table>
    <div class="row" style="display:flex;justify-content: center;" *ngIf="previewGridSource === undefined">
      <mat-spinner  class="spinner-inner-table" [diameter]='40'></mat-spinner>
    </div>
    <div class="row" style="display:block;text-align: center;" *ngIf="previewGridSource?.length == 0">Bookings not found</div>
  </div>
  <div class="confirmationButtons">
    <button class="cancelBtn" (click)="cancel()">Cancel</button>
    <button class="deleteBtn" (click)="save()" type="submit" [disabled]="selctdRequsts.length == 0 ? true :null">{{buttonText}}</button>
  </div>
</div>


<!-- <div class="row">
    <div class="pb-6">
        <span class="pl-6" style="font-size: 12px;">Please note that requests not submitted by the end of each working day will be deleted during the daily refresh process. Ensure all requests are submitted no later than 6pm.</span>
    </div>
    <br>

    <div kendoTooltip
    showOn="none"
    [tooltipTemplate]="template"
    filter=".k-grid td"
    (mouseover)="showTooltip($event)">

  <kendo-grid [data]="previewGridSource" [selectable]="selectableSettings" [rowClass]="rowCallback" [kendoGridSelectBy]="selectedCallback"
              [selectedKeys]="mySelection" [loading] ="loading" style="height:350px">
    <kendo-grid-checkbox-column title="Select" width="70"></kendo-grid-checkbox-column>
    <kendo-grid-column field="BookingDetailId" title="BookingDetailId" width="40" hidden="hidden">
    </kendo-grid-column>
    <kendo-grid-column field="JobId" title="JobId" hidden="hidden">
    </kendo-grid-column>
    <kendo-grid-column field="MeraBookingId" title="Action ID" width="85" >     
    </kendo-grid-column>
    <kendo-grid-column field="RequestId" title="RequestId" hidden="hidden">
    </kendo-grid-column>
    <kendo-grid-column field="ResourceId" title="ResourceId" hidden="hidden">
    </kendo-grid-column>
    <kendo-grid-column field="ActionDescription" title="Action" width="90">
    </kendo-grid-column>
    <kendo-grid-column field="BookingType" title="Booking Type" width="140" hidden="hidden">
    </kendo-grid-column>
    <kendo-grid-column field="JobCode" title="Engagement Code" width="150" hidden="hidden">
    </kendo-grid-column>
    <kendo-grid-column field="JobDescription" title="Engagement" width="200">
    </kendo-grid-column>
    <kendo-grid-column field="ResourceName" title="Resource Name" width="150">
    </kendo-grid-column>
    <kendo-grid-column field="RankDescription" title="Rank" width="130">
    </kendo-grid-column>
    <kendo-grid-column field="Hours" title="Hours" width="80">
    </kendo-grid-column>
    <kendo-grid-column field="Loading" title="Loading" width="100" [format]="format">
    </kendo-grid-column>
    <kendo-grid-column field="StartDate" title="Start Date" width="100" format="{0: dd-MM-yyyy}">
    </kendo-grid-column>
    <kendo-grid-column field="EndDate" title="End Date" width="100" format="{0: dd-MM-yyyy}">
    </kendo-grid-column>
    <kendo-grid-column field="Unconfirmed" title="Unconfirmed" width="50">
    </kendo-grid-column>
    <kendo-grid-column field="UserSelection" title="User Selection" width="90">
    </kendo-grid-column>
  </kendo-grid>
    </div>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 pr-0">
    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
      <button class="cancelBtn" (click)="cancel()"><span class="glyphicon glyphicon-remove-sign pr-5"></span>Cancel</button>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
      
      <button class="addBtn" (click)="save()" type="submit" [disabled]="(!(buttonText == 'Submit'))">
        <span class="glyphicon glyphicon-ok-sign pr-5" style="font-size: 12px;"></span>{{buttonText}}</button>
    </div>
  </div>

  <ng-template #template let-anchor>
    <span>{{ anchor.nativeElement.innerText }}</span>
  </ng-template>

</div> -->
