import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SharedServicesService } from '../../../app/shared/sharedservices/shared-services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MY_DATE_FORMATS } from 'app/app.component';
import { ThemeService } from '../../shared/theme/theme.service';
import { ResourceDto } from 'app/Models/ResourceDto';
import { UserVM } from 'app/Models/UserVM';

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.scss'],
})
export class ProfileSettingsComponent implements OnInit {
  constructor(
    public restService: SharedServicesService,
    private spinner: NgxSpinnerService,
    private themeService: ThemeService
  ) {
    this.restService.activeLink = 'ProfileSettings';
  }

  bookingListSubscription: Subscription;
  showEmailToggle: boolean;
  public isUserLoggedIn: boolean = false;
  panelOpenState = false;
  successfulEmailToggle: boolean = false;
  rejectedEmailToggle: boolean = false;
  notApprovedToggle: boolean;
  preferenceOption: string = 'hour';
  dateConfiguration: string = 'System date format';
  instances;
  selectedInstance;
  userInstanceSubcription;
  resource;
  user;
  defaultDateFormat: string;
  loggedInUser: UserVM;
  themeOption: string;
  isDeniedRequired: boolean = false;
  resourceObj: ResourceDto;
  loggedInUserInit: UserVM;
  defaultPeriodFilter: string;
  private currentThemeBS: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );

  ngOnInit(): void {
    this.restService.activeLink = 'ProfileSettings';
    this.loggedInUserInit = JSON.parse(sessionStorage.getItem('resource'));
    this.resourceObj = new ResourceDto();
    this.resourceObj.GPN = this.loggedInUserInit.GPN;
    this.resourceObj.LoggedInResourceID = this.loggedInUserInit.UserId;
    this.resourceObj.InstanceId = 0;
    this.resourceObj.ResourceId = 0;
    this.spinner.show();
    this.userInstanceSubcription = this.restService
      .getUserResourceInstances(this.resourceObj)
      .subscribe((data) => {
        this.spinner.hide();
        this.instances = data;
        //this.getUserDetails();
        if (
          sessionStorage.getItem('resource') !== null &&
          sessionStorage.getItem('resource') !== undefined
        ) {
          this.resource = JSON.parse(sessionStorage.getItem('resource'));
          this.setUserDetails();
        }
      });
  }

  setUserDetails() {
    this.isDeniedRequired = this.resource.IsApproval;
    const selected = this.instances.filter(
      (x) => x.InstanceId === this.resource.DefaultInstance
    );
    if (selected) {
      this.selectedInstance = selected[0].InstanceId;
    }

    if (
      this.resource.EmailSubscription &&
      this.resource.EmailAlerts != null &&
      this.resource.EmailAlerts != 'undefined'
    ) {
      if (this.resource.EmailAlerts.hasOwnProperty('1')) {
        this.rejectedEmailToggle = this.resource.EmailAlerts['1'];
      } else {
        this.rejectedEmailToggle = false;
      }

      if (this.resource.EmailAlerts.hasOwnProperty('2')) {
        this.successfulEmailToggle = this.resource.EmailAlerts['2'];
      } else {
        this.successfulEmailToggle = false;
      }

      if (this.resource.EmailAlerts.hasOwnProperty('6')) {
        this.notApprovedToggle = this.resource.EmailAlerts['6'];
      } else {
        this.notApprovedToggle = false;
      }
    }

    if (this.resource.PercentSelected) {
      this.preferenceOption = 'percentage';
    } else {
      this.preferenceOption = 'hour';
    }

    if (this.resource.DefaultDateFormat) {
      this.defaultDateFormat = this.resource.DefaultDateFormat;
    }
    if (this.resource.DefaultPeriodFilter) {
      this.defaultPeriodFilter = this.resource.DefaultPeriodFilter;
    }

    if (this.resource.DefaultTheme) {
      this.themeOption = this.resource.DefaultTheme;
      this.themeService.sendData(this.resource.DefaultTheme);
    }
  }

  

  getUserDetails() 
  {
     this.bookingListSubscription = this.restService
      .getUserProfile()
      .subscribe((data) => {
        this.resource = data;
        sessionStorage.setItem('resource', JSON.stringify(this.resource));
        this.loggedInUser = JSON.parse(sessionStorage.getItem('resource'));
        let format =
          !this.loggedInUser.DefaultDateFormat ||
          this.loggedInUser.DefaultDateFormat == 'System date format'
            ? this.restService.getLocalDateFormat()
            : this.loggedInUser.DefaultDateFormat;
        MY_DATE_FORMATS.display.dateInput = format.toLocaleUpperCase();
        this.setUserDetails();
        this.spinner.hide();
      });
  }

  updateUserProfile() {
    this.user = {
      EmailSubscription: this.resource.EmailSubscription,
      EmailAlerts: this.resource.EmailAlerts,
      PercentSelected: this.resource.PercentSelected,
      DefaultInstance: this.resource.DefaultInstance,
      DefaultDateFormat: this.resource.DefaultDateFormat,
      DefaultPeriodFilter: this.resource.DefaultPeriodFilter,
      DefaultTheme:this.resource.DefaultTheme,
    };

    this.spinner.show();
    this.restService.updateUserProfile(this.user).subscribe((data) => {
      this.getUserDetails();
    });
  }

  emailSubscriptionChange(event, type) {
    this.resource.EmailSubscription = event;
    let dic: { [prop: number]: boolean } = {};
    dic[type] = event;
    if (
      this.resource.EmailSubscription &&
      this.resource.EmailAlerts != null &&
      this.resource.EmailAlerts != 'undefined'
    ) {
      if (type === 1) {
        this.resource.EmailAlerts[1] = event;
      }
      if (type === 2) {
        this.resource.EmailAlerts[2] = event;
      }
      if (type === 6) {
        this.resource.EmailAlerts[6] = event;
      }
    } else {
      this.resource.EmailAlerts = dic;
    }

    this.updateUserProfile();
  }

  onEmailSwitchChange(event, type) {
    //this.spinner.show();
    if (type == 'successful') {
      this.emailSubscriptionChange(event.checked, 2);
      this.successfulEmailToggle = event.checked;
    } else if (type == 'rejected') {
      this.emailSubscriptionChange(event.checked, 1);
      this.rejectedEmailToggle = event.checked;
    } else if (type == 'denied') {
      this.emailSubscriptionChange(event.checked, 6);
      this.notApprovedToggle = event.checked;
    }
  }

  onPercentSwitchChange(event) 
  {
    //this.spinner.show();
    this.resource.PercentSelected = event.value === 'hour' ? false : true;
    this.updateUserProfile();
  }

  onInstanceChange(event) {
    //this.spinner.show();
    this.resource.DefaultInstance = event.value;
    this.updateUserProfile();
  }
  ondateFormatSwitchChange(event) {
    this.resource.DefaultDateFormat = event.value;
    this.updateUserProfile();
  }

  onThemeSwitchChange(event) {
    if (event.value === 'light') {
      this.themeService.setLightTheme();
    } else {
      this.themeService.setDarkTheme();
    }
    this.resource.DefaultTheme = event.value;
    this.updateUserProfile();
  }
  
  onPeriodFilterSwitchChange(event) 
  {
    this.resource.DefaultPeriodFilter = event.value;
    this.updateUserProfile();
  }
}
