import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ResourceDto } from 'app/Models/ResourceDto';
import { SharedServicesService } from 'app/shared/sharedservices/shared-services.service';

@Component({
  selector: 'app-post-logout',
  templateUrl: './post-logout.component.html',
  styleUrls: ['./post-logout.component.scss']
})
export class PostLogoutComponent implements OnInit {
  
  resource: ResourceDto;
  logoutMsg = "You are Signed Out successfully.";
  
  constructor(public sharedService: SharedServicesService, 
    private router: Router) { }

  ngOnInit(): void {   
    this.resource = new ResourceDto();
    this.sharedService.setHeaderDetails(this.resource);
    this.sharedService.setFooterDetails(this.resource);
    
    if(sessionStorage.getItem('isTimeOut') == 'true') {
      this.logoutMsg = "You are Signed Out due to inactivity.";
      sessionStorage.removeItem('isTimeOut');
    }
  }

  navigateToLogin() {
    this.router.navigate(['Login']);
  }
}
