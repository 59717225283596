<div class="assign-role-container">
  <div class="top-row">
    <div class="resource-div-wrap">
      <div class="resource-div">Assign Access Role to Resources</div>
      <div
        *ngIf="messageBody?.length"
        class="alert alert-success message-block"
        [ngClass]="{
          'alert-success': messageTitle == 'Success',
          'alert-danger': messageTitle == 'Failed',
          'alert-info': messageTitle == 'Information'
        }"
      >
        <strong>{{ messageTitle }}: &nbsp;</strong>{{ messageBody }}
        <mat-icon (click)="closeMessage()">close</mat-icon>
      </div>
    </div>

    <div class="search-box">
      <!-- <label>Engagement</label> -->
      <mat-form-field class="resourceSearch" appearance="outline">
        <input
          type="text"
          placeholder="Search"
          matInput
          appSearchDebounce
          (debounceKeyup)="search()"
          [(ngModel)]="searchBar"
          autocomplete="off"
        />
        <mat-icon (click)="resetfilterBookingGridSearchBar()">close</mat-icon>
      </mat-form-field>
      <div class="dropdown-list-wrap-ar" *ngIf="showSearchList">
        <mat-spinner *ngIf="showSearchLoader" [diameter]="40"></mat-spinner>
        <ul *ngIf="showSearchList" class="dropdown-list">
          <li
            *ngFor="let item of resourceList"
            (click)="onItemSelect(item)"
            class="dropdown-list-item"
          >
            {{ item.ResourceName }}
          </li>
        </ul>
      </div>
    </div>

    <div class="materialTable table-ar">
      <table
        #table
        mat-table
        [dataSource]="selectedResources"
        class="mat-elevation-z8 bookingTable"
      >
        <ng-container matColumnDef="ResourceProfile">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="mat-column-ResourceProfile"
          >
            ResourceProfile
          </th>
          <td mat-cell *matCellDef="let element" data-title="ResourceProfile">
            {{
              element.ResourceName +
                (element.RankDescription && element.RankDescription.length > 0
                  ? " - " + element.RankDescription
                  : "") +
                (element.ResourceEmail && element.ResourceEmail.length > 0
                  ? " - " + element.ResourceEmail
                  : "") +
                (element.LocationDescription &&
                element.LocationDescription.length > 0
                  ? " - " + element.LocationDescription
                  : "")
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="PresentRole">
          <th mat-header-cell *matHeaderCellDef class="mat-column-PresentRole">
            PresentRole
          </th>
          <td mat-cell *matCellDef="let element" data-title="PresentRole">
            {{
              element.RoleName
                ? element.RoleName + " (" + element.RoleTypeDisplayName + ")  "
                : "No Role Assigned!  "
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="Actions">
          <th mat-header-cell *matHeaderCellDef class="mat-column-Actions">
            Actions
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            class="actionColumn"
          >
            <mat-icon class="tableIcon" (click)="removeSelectedResource(i)"
              >close</mat-icon
            >
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <div *ngIf="!previewGridSource?.length" class="noBookings">
        No Resource(s) Selected
      </div>
    </div>
  </div>
  <div class="bottom-row px-4">
    <button class="btn btn-ar-reset" (click)="reset()">Reset</button>
    <button
      *ngIf="!isRALoggedIn"
      [disabled]="
        !selectedRole ||
        selectedRole.R_ID == 0 ||
        selectedRole.RoleTypeName != roleTypeCSP ||
        selectedResources?.length
      "
      (click)="emailMatchedResources()"
      [ngClass]="{
        'btn-ar-find disabled':
          !selectedRole ||
          selectedRole.R_ID == 0 ||
          selectedRole.RoleTypeName != roleTypeCSP ||
          selectedResources?.length
      }"
      class="btn btn-ar-reset"
      title="Get email with resources detail that can be assigned to the selected role"
    >
      Find the resources match against Role Access
    </button>
    <button
      (click)="assignRole()"
      class="btn btn-ar-reset"
      [disabled]="
        !selectedRole ||
        selectedRole.R_ID == 0 ||
        (selectedRole.RoleTypeName != roleTypeCSP &&
          selectedResources.length == 0)
      "
      [ngClass]="{
        'btn-ar-find disabled':
          !selectedRole ||
          selectedRole.R_ID == 0 ||
          (selectedRole.RoleTypeName != roleTypeCSP &&
            selectedResources.length == 0)
      }"
      title="Assign selected role to resources based on assignment rule and get email with the assigned resources detail."
    >
      Assign Access Role to Resources
    </button>
    <button
      class="btn btn-delete"
      (click)="openConfirmationModal(ConfirmationTemplate)"
      [disabled]="!selectedResources?.length"
      [ngClass]="{
        'btn-ar-reset disabled': !selectedResources?.length
      }"
    >
      Delete Present Role of Resources
    </button>
  </div>
</div>

<ng-template #ConfirmationTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      <span class="glyphicon glyphicon-warning-sign pr-5"></span>Confirm
    </h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div>
          Are you sure you want to delete the already assigned roles of the
          selected resources?
        </div>
      </div>
      <div class="btn-container">
        <button class="cancelBtn" (click)="this.modalRef.hide()">No</button>
        <button class="deleteBtn" (click)="onConfirmAction()">Yes</button>
      </div>
    </div>
  </div>
</ng-template>
