<div class="popupLayout">
    <div class="fullWidth">
        <!-- <div class="confirmationText">
         Are you sure you want to delete 
         <b>{{Engagement}}</b>
         <span> from {{startDate | date: 'dd-MM-yyyy'}} to {{endDate | date: 'dd-MM-yyyy'}}?</span>
        </div> -->
        <div class="confirmationText">
          Are you sure you want to delete <b>{{Engagement}}</b>
          from {{startDate | date: 'dd-MM-yyyy'}} to {{endDate | date: 'dd-MM-yyyy'}}?
          <br/><br/>
          <span *ngIf="isNewBookingChildRowsExist">
            This will also cancel the New Booking requests (for the mentioned period) raised while any Edit/ Replace actions on Block Bookings. It will not cancel the related Edit/ Replace actions.
          </span>
        </div>
    </div>
    <div class="confirmationButtons">
        <button class="cancelBtn" (click)="cancelDelete()">Cancel</button>
        <button class="deleteBtn" (click)="deleteBooking()" [disabled] = "(!(buttonText == 'Delete'))||!dirtyFlag">{{buttonText}}</button>
      </div>
</div>
