import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { IndividualWallChartVM, ResourceUtilizationRequestVM, ResourceUtilizationVM } from '../../../Models/IndividualWallChartVM';
import { IndividualService } from '../../individual/individual.service';
import { IndividualBookingVM } from '../../../Models/IndividualBookingVM';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { DatePipe } from '@angular/common';
import { IndividualWallChartSummary } from '../../../Models/IndividualWallChartSummary';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedServicesService } from 'app/shared/sharedservices/shared-services.service';
// import { ApplicationInsightService } from 'src/applicationInsightService';

@Component({
    selector: 'app-individual-wallchart',
    templateUrl: './individual-wallchart.component.html',
    styleUrls: ['./individual-wallchart.component.scss']
})

export class IndividualWallchartComponent implements OnInit, OnDestroy {
    @Input() public booking: IndividualBookingVM;
    modalRef: BsModalRef;
    bookingData: IndividualBookingVM;
    wallchartDataList: any[];
    wallchartDataListDelete: any[];
    wallchartDataListAddNew: any[];
    wallchartDataListSplit: any[];
    wallchartDataListEdit: any[];
    wallchartData: IndividualWallChartSummary;
    //private bookingList: IndividualWallChartVM[];
    public bookingList: IndividualWallChartVM[];
    bookingListSubscription: Subscription;
    subscriptionWeeklyTotal: Subscription;
    arrays: any[];
    currentDate = new Date();
    value = '';
    isPercentageSelected = false;
    isHourSelected = true;
    isData = false;
    public data1 = [];
    public deleteAction = [];
    public addNewAction = [];
    public splitAction = [];
    public editAction = [];
    public model = [];
    currentUserName: any;
    currentUserId: any;
    currentUserEmail: any;
    chartLoading: boolean;
    chartNoDataText: string;
    loggedinUser : any;
    startOfWeek: Date;
    endOfWeek: Date;
    totalWeeklyHours: number = 0;
    weekNumber: number = 0;
    TimeZone: string;

    maxDate:Date=new Date();
    totalWeeks :number=0 ;

    weekDates = [];
    userEngagementData: any[] = [];
    firstDayOfWeekIndex:number = 6; //Week Day Index: SUN 0, MON 1, TUE 2, WED 3, THU 4, FRI 5, SAT 6.
    lastDayOfWeekIndex:number = (this.firstDayOfWeekIndex + 6) % 7;
    isWallChart : boolean = false;
    defaultDateFormat: string;
    instances : any[] = [];
    selectedInstanceName : string;
    isMultiInstance : boolean = false;

  constructor(public restService: IndividualService, private modalService: BsModalService,
    private spinner: NgxSpinnerService,public datepipe: DatePipe,
    public sharedService: SharedServicesService) {
      

    Date.prototype['getWeekDayInitial'] = function() {
    return ["S", "M", "T", "W", "T", "F", "S"][this.getDay()];
    }


    this.bookingList = [];
    this.arrays = [];
    this.wallchartDataList = [];
    this.bookingData = new IndividualBookingVM();
    this.wallchartData = new IndividualWallChartSummary();
    this.wallchartDataList = [];
    this.wallchartDataListDelete = [];
    this.wallchartDataListAddNew = [];
    this.wallchartDataListSplit = [];
    this.wallchartDataListEdit = [];
    const totalWeekNumber = this.calculateWeeksBetween();
    this.model = [];
    this.isData = false;
  }

  labelContent = (args: any) => {
    let year = this.currentDate.getFullYear();
    const startDateWeek = this.getDateOfWeek(52, year);
    const endDateWeek = this.getEndOfWeek(startDateWeek);
    if (this.currentDate > endDateWeek) {
      year = year + 1;
    }

    this.startOfWeek = new Date(this.getDateOfWeek(args.value, year));
    this.endOfWeek = new Date(this.startOfWeek);
    this.endOfWeek.setDate(this.endOfWeek.getDate() + 4);
    
    if(this.bookingList.length !== 0)
    {
      this.weekNumber = this.weekNumber + 1;
    }

    this.totalWeeklyHours = 0;
    for(var i = 0; i < this.bookingList.length; i++) {
      if(this.bookingList[i].ActionDescriptionId === 0) {
        if(new Date(this.bookingList[i].OrgStartDate) >= new Date(this.startOfWeek) && new Date(this.bookingList[i].OrgEndDate) <= new Date(this.endOfWeek)) {
          this.totalWeeklyHours = this.totalWeeklyHours + this.bookingList[i].Hours;        
        }
      }
      if(i === 0) {
        this.TimeZone = this.bookingList[i].TimeZone;
      }
    }

    if(this.weekNumber !== 53 && this.weekNumber !== 106) {
      var weeklyHours = this.totalWeeklyHours.toString();
      var index = weeklyHours.indexOf("."); 
      
      if(index !== -1) 
      {
        // return this.intl.toString(this.getDateOfWeek(args.value, year), 'ddMMM') + "   [" + weeklyHours.slice(0,index + 1) + weeklyHours.slice(index + 1, index + 3) + "]";    //+ Math.round(this.totalWeeklyHours * 100) / 100 + "]";
      }
      else
      {
        // return this.intl.toString(this.getDateOfWeek(args.value, year), 'ddMMM') + "   [" + weeklyHours + "]";    //+ Math.round(this.totalWeeklyHours * 100) / 100 + "]";
      }
    }
    else if(this.weekNumber === 53 || this.weekNumber === 106) {
      // return this.intl.toString(this.getDateOfWeek(args.value, year), 'ddMMM');
    }
  }

  getDateOfWeek(w, y) {
    var simple;
    if(this.TimeZone !== 'AEST') {
      simple = new Date(y, 0, 1 + (w - 1) * 7);
    }
    else {
      simple = new Date(y, 0, 1 + (w - 2) * 7);
   }
    const dow = simple.getDay();
    const ISOweekStart = simple;
    if (dow <= 4) {
      ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
      ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    return ISOweekStart;
  }

  getEndOfWeek(dt) {
    dt.setDate(dt.getDate() + 6);
    return dt;
  }

  openModal(templateWallchart) {
    const config: ModalOptions = { class: 'modal-wallChart' };
    config.backdrop = 'static';
    config.keyboard = false;
    this.modalRef = this.modalService.show(templateWallchart, config);
  }

  
  ngOnInit() {  
    this.loggedinUser = JSON.parse(sessionStorage.getItem('resource'));  
    this.currentUserName = this.loggedinUser.ResourceName;
    this.currentUserEmail = this.loggedinUser.Email;
    this.currentUserId = sessionStorage.getItem("resourceId");
    this.isMultiInstance = this.loggedinUser.IsMultiInstance;
    // this.applicationInsightsService.logPageView("Individual View Wallchart");
    this.defaultDateFormat = this.sharedService.getDateFormat(this.loggedinUser.DefaultDateFormat);
    this.isData = false;
    //this.spinner.show();
    this.loadInitialData();
    this.sharedService.getUserInstances().subscribe(
      data => {
        this.instances = this.sharedService.getStoredUserInstances();
        const selected = this.instances.filter(x => x.InstanceId === this.booking.InstanceId);
        if(selected.length > 0){
          this.selectedInstanceName = selected[0].InstanceName;
          
        }
        
    });
    this.restService.refreshChartEvent.subscribe((refresh) => {
      this.refreshData(refresh);
    });
    this.restService.currentMessageEngagementAllocation.subscribe(isPercentageSelected => {
      if (isPercentageSelected === undefined) {
        this.isPercentageSelected = false;
        this.isHourSelected = true;
      } else {
        this.isPercentageSelected = isPercentageSelected;
        this.isHourSelected = !isPercentageSelected;
      }
    });    
    this.weekNumber = 0;
  }

  ngOnDestroy() {
    if (this.bookingListSubscription !== undefined) {
      this.bookingListSubscription.unsubscribe();
    }
    if (this.subscriptionWeeklyTotal !== undefined) {
      this.subscriptionWeeklyTotal.unsubscribe();
    }
  }

  loadInitialData() {
  }


  weekHours:number[];
  count:number=0;

  getBookingListForWallchart() {
    //this.date();
    this.chartLoading = true;
    this.chartNoDataText = '';
    this.wallchartDataList = [];
    this.wallchartDataListDelete = [];
    this.wallchartDataListAddNew = [];
    this.wallchartDataListSplit = [];
    this.wallchartDataListEdit = [];
    this.isData = false;
    this.weekNumber = 0;
    

    this.bookingListSubscription = this.restService.getBookingListForWallchart(this.booking).subscribe
      (data => {
        this.bookingList = data;
       // alert(JSON.stringify(this.bookingList));
        this.model = [];
        if (this.bookingList?.length === 0) {
          this.chartNoDataText = 'Bookings not found';
          this.chartLoading = false;
          //this.spinner.hide();
        } else {
          this.chartNoDataText = '';
          for (let booking of this.bookingList) {
            this.wallchartDataList = [];
            this.wallchartDataListEdit = [];
           
            if (booking.ActionDescriptionId === 0) {
              if(booking.JobCode !== null) {
                this.wallchartDataList.push({
                  Category : booking.ClientDescription !== null ? booking.ClientDescription +" - " + booking.EngagementName + " - " + booking.JobCode : booking.EngagementName + " - " + booking.JobCode,
                  DisplayCategory : booking.ClientDescription !== null? booking.ClientDescription +" - " + booking.EngagementName : booking.EngagementName,
                  EngagementName: booking.EngagementName,
                  InstanceName: booking.InstanceName,
                  Start: booking.TimeZone !== 'AEST'?booking.NewStartDateWeekNumber:booking.NewStartDateWeekNumber +1,
                  End: booking.TimeZone !== 'AEST'?booking.NewEndDateWeekNumber:booking.NewEndDateWeekNumber +1,
                  color: booking.ColorCode, 
                  StartDate: this.formatDate(booking.OrgStartDate),
                  EndDate: this.formatDate(booking.OrgEndDate),
                  OrgStartDate: this.setDayStartTime(booking.OrgStartDate),
                  OrgEndDate: this.setDayEndTime(booking.OrgEndDate),
                  Hours: booking.Hours,
                  Loading: booking.Loading,
                  MeraBookingId: booking.MeraBookingId,
                  ClientName: booking.ClientDescription,
                  JobId: booking.JobId,
                  JobCode: booking.JobCode,
                  Description: booking.Description,
                  BookingId : booking.BookingId,
                  ResourceId: booking.ResourceId,
                  ActionDescriptionId: booking.ActionDescriptionId,
                  IsReplacedEngagement: booking.IsReplacedEngagement,
                  To_Engagement_Name: booking.To_Engagement_Name,
                  To_Engagement_Code: booking.To_Engagement_Code,
                  To_Client_Description: booking.To_Client_Description,
                  To_Client_Number: booking.To_Client_Number,
                  From_Engagement_Name: booking.From_Engagement_Name,
                  From_Engagement_Code: booking.From_Engagement_Code,
                  From_Client_Description: booking.From_Client_Description,
                  From_Client_Number: booking.From_Client_Number,
                  To_Job_Id: booking.To_Job_Id,
                  From_Job_Id: booking.From_Job_Id,
                  StatusDescription:booking.StatusDescription,
                  StatusId:booking.StatusId,
                  InstanceId:booking.InstanceId
                });
              }
              else if(booking.JobCode === null) {
                this.wallchartDataList.push({
                  Category : booking.ClientDescription !== null ? booking.ClientDescription + " - " + booking.EngagementName : booking.EngagementName,
                  DisplayCategory : booking.ClientDescription !== null? booking.ClientDescription + " - " + booking.EngagementName : booking.EngagementName,
                  EngagementName: booking.EngagementName,
                  InstanceName: booking.InstanceName,
                  Start: booking.TimeZone !== 'AEST'?booking.NewStartDateWeekNumber:booking.NewStartDateWeekNumber +1,
                  End: booking.TimeZone !== 'AEST'?booking.NewEndDateWeekNumber:booking.NewEndDateWeekNumber +1,
                  color: booking.ColorCode, 
                  StartDate: this.formatDate(booking.OrgStartDate),
                  EndDate: this.formatDate(booking.OrgEndDate),
                  OrgStartDate: this.setDayStartTime(booking.OrgStartDate),
                  OrgEndDate: this.setDayEndTime(booking.OrgEndDate),
                  Hours: booking.Hours,
                  Loading: booking.Loading,
                  MeraBookingId: booking.MeraBookingId,
                  ClientName: booking.ClientDescription,
                  JobId: booking.JobId,
                  JobCode: booking.JobCode,
                  Description: booking.Description,
                  BookingId : booking.BookingId,
                  ResourceId: booking.ResourceId,
                  ActionDescriptionId: booking.ActionDescriptionId,
                  IsReplacedEngagement: booking.IsReplacedEngagement,
                  To_Engagement_Name: booking.To_Engagement_Name,
                  To_Engagement_Code: booking.To_Engagement_Code,
                  To_Client_Description: booking.To_Client_Description,
                  To_Client_Number: booking.To_Client_Number,
                  From_Engagement_Name: booking.From_Engagement_Name,
                  From_Engagement_Code: booking.From_Engagement_Code,
                  From_Client_Description: booking.From_Client_Description,
                  From_Client_Number: booking.From_Client_Number,
                  To_Job_Id: booking.To_Job_Id,
                  From_Job_Id: booking.From_Job_Id,
                  StatusDescription:booking.StatusDescription,
                  StatusId:booking.StatusId,
                  InstanceId:booking.InstanceId
                });
              }
            }
            if(booking.ActionDescriptionId !== 0) {
              if(booking.JobCode !== null) {
                this.wallchartDataListEdit.push({
                  Category : booking.ClientDescription !== null ? booking.ClientDescription + " - " + booking.EngagementName + " - " + booking.JobCode : booking.EngagementName + " - " + booking.JobCode,
                  DisplayCategory : booking.ClientDescription !== null? booking.ClientDescription + " - " + booking.EngagementName : booking.EngagementName,
                  name: booking.EngagementName,
                  EngagementName: booking.EngagementName,
                  InstanceName: booking.InstanceName,
                  Start: booking.TimeZone !== 'AEST'?booking.NewStartDateWeekNumber:booking.NewStartDateWeekNumber +1,
                  End: booking.TimeZone !== 'AEST'?booking.NewEndDateWeekNumber:booking.NewEndDateWeekNumber +1,
                  color: booking.ColorCode, 
                  StartDate: this.formatDate(booking.OrgStartDate),
                  EndDate: this.formatDate(booking.OrgEndDate),
                  OrgStartDate: this.setDayStartTime(booking.OrgStartDate),
                  OrgEndDate: this.setDayEndTime(booking.OrgEndDate),
                  Hours: booking.Hours,
                  Loading: booking.Loading,
                  Action: booking.ActionDescription,
                  FromResourceName: booking.FromResourceName,
                  ToResourceName: booking.ToResourceName,
                  IsReplaced: booking.IsReplaced,
                  MeraBookingId: booking.MeraBookingId,
                  ClientName: booking.ClientDescription,
                  JobId: booking.JobId,
                  JobCode: booking.JobCode,
                  Description: booking.Description,
                  BookingId : booking.BookingId,
                  ResourceId: booking.ResourceId,
                  ActionDescriptionId: booking.ActionDescriptionId,
                  IsReplacedEngagement: booking.IsReplacedEngagement,
                  To_Engagement_Name: booking.To_Engagement_Name,
                  To_Engagement_Code: booking.To_Engagement_Code,
                  To_Client_Description: booking.To_Client_Description,
                  To_Client_Number: booking.To_Client_Number,
                  From_Engagement_Name: booking.From_Engagement_Name,
                  From_Engagement_Code: booking.From_Engagement_Code,
                  From_Client_Description: booking.From_Client_Description,
                  From_Client_Number: booking.From_Client_Number,
                  To_Job_Id: booking.To_Job_Id,
                  From_Job_Id: booking.From_Job_Id,
                  StatusDescription:booking.StatusDescription,
                  StatusId:booking.StatusId,
                  InstanceId:booking.InstanceId
                });      
              }
              else if(booking.JobCode === null) {
                
                this.wallchartDataListEdit.push({
                  Category : booking.ClientDescription !== null ? booking.ClientDescription + " - " + booking.EngagementName : booking.EngagementName,
                  DisplayCategory : booking.ClientDescription !== null ? booking.ClientDescription + " - " + booking.EngagementName : booking.EngagementName,
                  name: booking.EngagementName,
                  EngagementName: booking.EngagementName,
                  InstanceName: booking.InstanceName,
                  Start: booking.TimeZone !== 'AEST'?booking.NewStartDateWeekNumber:booking.NewStartDateWeekNumber +1,
                  End: booking.TimeZone !== 'AEST'?booking.NewEndDateWeekNumber:booking.NewEndDateWeekNumber +1,
                  color: booking.ColorCode, 
                  StartDate: this.formatDate(booking.OrgStartDate),
                  EndDate: this.formatDate(booking.OrgEndDate),
                  OrgStartDate: this.setDayStartTime(booking.OrgStartDate),
                  OrgEndDate: this.setDayEndTime(booking.OrgEndDate),
                  Hours: booking.Hours,
                  Loading: booking.Loading,
                  Action: booking.ActionDescription,
                  FromResourceName: booking.FromResourceName,
                  ToResourceName: booking.ToResourceName,
                  IsReplaced: booking.IsReplaced,
                  MeraBookingId: booking.MeraBookingId,
                  ClientName: booking.ClientDescription,
                  JobId: booking.JobId,
                  JobCode: booking.JobCode,
                  Description: booking.Description,
                  BookingId : booking.BookingId,
                  ResourceId: booking.ResourceId,
                  ActionDescriptionId: booking.ActionDescriptionId,
                  IsReplacedEngagement: booking.IsReplacedEngagement,
                  To_Engagement_Name: booking.To_Engagement_Name,
                  To_Engagement_Code: booking.To_Engagement_Code,
                  To_Client_Description: booking.To_Client_Description,
                  To_Client_Number: booking.To_Client_Number,
                  From_Engagement_Name: booking.From_Engagement_Name,
                  From_Engagement_Code: booking.From_Engagement_Code,
                  From_Client_Description: booking.From_Client_Description,
                  From_Client_Number: booking.From_Client_Number,
                  To_Job_Id: booking.To_Job_Id,
                  From_Job_Id: booking.From_Job_Id,
                  StatusDescription:booking.StatusDescription,
                  StatusId:booking.StatusId,
                  InstanceId:booking.InstanceId
                });      
              }
            }
             
            if (this.wallchartDataList.length > 0) {
              this.isData = true;
              this.model.push({ data: this.wallchartDataList });
            }
            if (this.wallchartDataListEdit.length > 0) {
              this.isData = true;
              this.model.push({ data: this.wallchartDataListEdit });
            }
          }
          this.setUserEngagementData();
          
          this.totalWeeks =Math.trunc(((this.maxDate.getTime()-new Date().getTime())/ (1000 * 3600 * 24))/7)+2;
      
          this.data1 = this.wallchartDataList;
          this.deleteAction = this.wallchartDataListDelete;
          this.addNewAction = this.wallchartDataListAddNew;
          this.splitAction = this.wallchartDataListSplit;
          this.editAction = this.wallchartDataListEdit;
        //  this.spinner.hide();
          this.chartLoading = false;
        }
      }
      );
  }

  minBookingDate: Date = null;
  maxBookingDate: Date = null;
  setUserEngagementData(){
    this.minBookingDate = null;
    this.maxBookingDate = null;
    this.userEngagementData = [];
    for( let modelObj of this.model ) {
      for( let wallchartDataObj of modelObj.data ) {
        
        this.minBookingDate = this.minBookingDate == null  
          ? wallchartDataObj.OrgStartDate 
          : ( this.minBookingDate < wallchartDataObj.OrgStartDate 
            ? this.minBookingDate : wallchartDataObj.OrgStartDate);
            
        this.maxBookingDate = this.maxBookingDate == null  
        ? wallchartDataObj.OrgEndDate
        : ( this.maxBookingDate > wallchartDataObj.OrgEndDate
          ? this.maxBookingDate : wallchartDataObj.OrgEndDate);

        let eg = this.userEngagementData.find(x => x.name == wallchartDataObj.JobId 
                && x.InstanceId == wallchartDataObj.InstanceId)
        if(eg)
        {
          let sDate:Date= new Date( wallchartDataObj.OrgStartDate);
          let eDate:Date= new Date( wallchartDataObj.OrgEndDate);
          let dates:string[]=[];
          while(sDate <= eDate)
          {
            let dt = this.datepipe.transform(sDate,'yyyy-MM-dd');
            dates.push(dt);
            if(eg.dict[dt + '_' + wallchartDataObj.JobId] == null) {
              eg.dict[dt + '_' + wallchartDataObj.JobId] = [];
            }
            eg.dict[dt + '_' + wallchartDataObj.JobId].push(wallchartDataObj);
            sDate= new Date(sDate.setDate(sDate.getDate() + 1));
          }
          dates.forEach(d=>eg.dates.push(d));
        }
        else{
          let sDate:Date= new Date( wallchartDataObj.OrgStartDate);
          let eDate:Date= new Date( wallchartDataObj.OrgEndDate);
          let dates:string[]=[];
          let dict = {};
          while(sDate <= eDate)
          {
            let dt = this.datepipe.transform(sDate,'yyyy-MM-dd');
            dates.push(dt);
            dict[dt + '_' + wallchartDataObj.JobId] = [];
            dict[dt + '_' + wallchartDataObj.JobId].push(wallchartDataObj);
            sDate= new Date(sDate.setDate(sDate.getDate() + 1));            
          }
          this.userEngagementData.push ({ 
            name: wallchartDataObj.JobId, 
            ClientName: wallchartDataObj.ClientName,
            EngagementName: wallchartDataObj.EngagementName,
            JobCode: wallchartDataObj.JobCode,
            //templateColor: wallchartDataObj.color, 
            Description : wallchartDataObj.Description,
            dates: dates,
            dict: dict,
            InstanceId:wallchartDataObj.InstanceId
          });              
        }
      }
    }

    this.populateWallChartWeekDates();
  }
  
  setDayStartTime(dt: Date) {
    dt = new Date(dt);
    return new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 0, 0, 0);
  }
  setDayEndTime(dt: Date) {
    dt = new Date(dt);
    return new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 23, 59, 59);
  }

  getMinimumValue(dt) {
    const tdt = new Date(dt.valueOf());
    const dayn = (dt.getDay() + 6) % 7;
    tdt.setDate(tdt.getDate() - dayn + 3);
    const firstThursday = tdt.valueOf();
    tdt.setMonth(0, 1);
    if (tdt.getDay() !== 4) {
      tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
    }
    return 1 + Math.ceil((firstThursday - tdt.valueOf()) / 604800000);
  }

  getMaximumValue(totalWeeks) {
    return this.getMinimumValue(this.currentDate) + totalWeeks;
  }

  calculateWeeksBetween() {
    const d = this.currentDate;
    const year = d.getFullYear();
    const month = d.getMonth();
    let day = d.getDate();
    // handle February 29th
    if(month == 1 && day == 29){
      day = 28;
    }
    const nextYearDate = new Date(year + 1, month, day);
    const date1 = d;
    const date2 = nextYearDate;
    const oneWeek = 1000 * 60 * 60 * 24 * 7;
    const date1_ms = date1.getTime();
    const date2_ms = date2.getTime();
    const difference_ms = Math.abs(date1_ms - date2_ms);
    return Math.floor(difference_ms / oneWeek);
  }

  formatDate(dt) {
    const result = new Date(dt);
    const dd = result.getDate();
    const mm = result.getMonth() + 1;
    let newDD: string;
    let newMM: string;
    const yyyy = result.getFullYear();
    if (dd < 10) {
      newDD = '0' + dd;
    } else {
      newDD = dd.toString();
    }
    if (mm < 10) {
      newMM = '0' + mm;
    } else {
      newMM = mm.toString();
    }
    return newDD + '/' + newMM + '/' + yyyy;
  }

  refreshData(refresh: number) {
    if(refresh > 0) {
     // this.spinner.show();
      this.isData = false;
      this.data1 = [];
      this.deleteAction = [];
      this.addNewAction = [];
      this.splitAction = [];
      this.editAction = [];
      // if(this.isWallChart){
        this.getBookingListForWallchart();
      // }
      
    }
  }
  
  weeklyBookingTotalHours = [];
  weeklyBookingTotalPercent = [];
  resourceIds: number[] = [];
  chartStartDate: Date;
  chartEndDate: Date
  populateWallChartWeekDates() {
    this.weekDates = [];
    this.weeklyBookingTotalHours = [];
    this.weeklyBookingTotalPercent = [];
    this.resourceIds= [];
    this.chartStartDate = this.getFirstDateOfWeek(this.minBookingDate);  
    this.chartEndDate = this.getLastDateOfWeek(this.maxBookingDate);
    var today = new Date();
    var todaysDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
    var currentWeekStartDate=this.getFirstDateOfWeek(todaysDate);
    let currentWeekStartDateFormat=this.datepipe.transform(currentWeekStartDate, 'yyyy-MM-dd');
    let loopIndex = 1; 
    let weeklyBookingTotalIndex = -1;
    let loopWeekBookings = [];
    let loopDate = new Date(this.chartStartDate);
    if (loopDate < currentWeekStartDate)
    loopDate=currentWeekStartDate;

    do {
      let date = this.datepipe.transform(loopDate, 'yyyy-MM-dd');
      this.weekDates.push({ date: date, day: loopDate['getWeekDayInitial']() });

      for(let j = 0; j < this.userEngagementData.length; j++) {
        let eg = this.userEngagementData[j];
        if(eg.dates.indexOf(date)>=0) {
          for (const booking of eg.dict[date+'_'+eg.name]) {
            if (loopWeekBookings.find(x => x.BookingId == booking.BookingId) == null 
            && booking.BookingId != 0) {
              loopWeekBookings.push(booking);
              if (this.resourceIds.indexOf(booking.ResourceId) == -1 && booking.ResourceId > 0
                && booking.ResourceId != null)
                this.resourceIds.push(booking.ResourceId);
            }
          }
        }
      }
      if (loopIndex % 7 == 0) {
        let bkTotal = 0;
        for(const booking of loopWeekBookings) {
          bkTotal += booking.Hours;
        }
        weeklyBookingTotalIndex++;
        this.weeklyBookingTotalHours[weeklyBookingTotalIndex] = +bkTotal.toFixed(2);  
        loopWeekBookings = [];
      }

      loopIndex++;
      loopDate.setDate(loopDate.getDate() + 1); 
    } while (loopDate <= this.chartEndDate)

    if(this.isHourSelected){      
     // this.spinner.hide();
    }
    else if(this.isPercentageSelected){
      this.getWeeklyBookingTotalPercent();
    }
  }

  getWeeklyBookingTotalPercent(){
    let input: ResourceUtilizationRequestVM = new ResourceUtilizationRequestVM();
    input.ResourceIds = [this.booking.ResourceId];
    input.StartDate = this.chartStartDate;
    input.EndDate = this.chartEndDate;
    input.InstanceId=this.booking.InstanceId;
    // input.StartDate = this.datepipe.transform(this.chartStartDate, 'dd-MMM-yyy');
    // input.EndDate = this.datepipe.transform(this.chartEndDate, 'dd-MMM-yyy');
    this.subscriptionWeeklyTotal = this.restService.GetResourceUtilization(input).subscribe(
      data => {
        const resUtil: ResourceUtilizationVM[] = data;
        let startDt = new Date(this.chartStartDate);
        let endDt = new Date(this.chartStartDate);
        endDt = new Date(endDt.setDate(endDt.getDate() + 6));
        let loopIndex = 0
        while(loopIndex < this.weeklyBookingTotalHours.length){
          let diaryHours = 0;
          let rUtil = 
            resUtil.filter(x => new Date(x.DiaryDate) >= startDt && new Date(x.DiaryDate) <= endDt);
          rUtil.forEach(x => diaryHours += x.DiaryHours);
          let percentage = diaryHours > 0 ?
            (this.weeklyBookingTotalHours[loopIndex]/diaryHours)*100
            : 0;
          this.weeklyBookingTotalPercent[loopIndex] = +percentage.toFixed(2);
          loopIndex++;
          startDt = new Date(startDt.setDate(startDt.getDate() + 7));
          endDt = new Date(endDt.setDate(endDt.getDate() + 7));
        }
       // this.spinner.hide();
      },
      error => {     
        // todo: show custome error message   
        console.log(error);
       // this.spinner.hide();
      }
    );
  }
  
  getFirstDateOfWeek(anyDateOfWeek: Date): Date {
    // date.getDay() RETURNS Week Day Index: SUN 0, MON 1, TUE 2, WED 3, THU 4, FRI 5, SAT 6.
    let date = new Date(anyDateOfWeek);
    let daysToShiftBack = (7 + (date.getDay() - this.firstDayOfWeekIndex)) % 7;
    let firstDateOfWeek = new Date(date.setDate(date.getDate() - daysToShiftBack));
    return firstDateOfWeek;
  }

  getLastDateOfWeek(anyDateOfWeek: Date): Date {
    // date.getDay() RETURNS Week Day Index: SUN 0, MON 1, TUE 2, WED 3, THU 4, FRI 5, SAT 6.
    let date = new Date(anyDateOfWeek);
    let daysToShiftForth = (6 - (date.getDay() - this.firstDayOfWeekIndex)) % 7;
    let lastDateOfWeek = new Date(date.setDate(date.getDate() + daysToShiftForth));
    return lastDateOfWeek;
  } 

  getYearEndDate(startDate: Date){
    startDate = new Date(startDate);
    startDate = new Date(startDate.setFullYear(startDate.getFullYear() + 1));
    startDate = new Date(startDate.setDate(startDate.getDate() - 1));
    return startDate;
  }

  previousPopover: any;
  closePrevious(currentPop){
    if(this.previousPopover && this.previousPopover != currentPop){
      this.previousPopover.hide();
     
    }
    this.previousPopover = currentPop;
  }
  closeCurrent(pop){
    pop.hide();
  }
  selectedItem : any;
  day : any
  selectItem(item, date){
    this.selectedItem = item;
    this.day = date;
  }
  }




