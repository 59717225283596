import { SCHEDULE_CONSTANTS } from '../scheduledView.service';
import { MeraActionDescription } from 'app/shared/enum';
import { EditedEngagement } from 'app/Models/ScheduleEngagement';
import { splitAndJoinDate } from './scheduleEngagementData';
import { Action } from 'rxjs/internal/scheduler/Action';

export class CellUpdateData {
  private cellDetails: EditedEngagement[] = [];
  private resourceData: any = [];
  private replacedResource: any = [];

  public setCellDetails = (
    engagement,
    booking,
    action?,
    occurredFromCellChange?
  ) => {
    const sDate = booking.WeekStartDate + '.000Z';
    const eDate = booking.WeekEndDate
      ? splitAndJoinDate(booking.WeekEndDate) + '.000Z'
      : splitAndJoinDate(booking.EndDate) + '.000Z';

    if (action) {
      switch (action) {
        case SCHEDULE_CONSTANTS.ACTION_DELETE:
          booking.CellAction = SCHEDULE_CONSTANTS.ACTION_DELETE;
          // booking.BookingHours = booking.ActualBookingHours;
          // booking.Loading = 0;
          break;

        case SCHEDULE_CONSTANTS.ACTION_CREATE:
          booking.CellAction = SCHEDULE_CONSTANTS.ACTION_CREATE;
          break;

        case SCHEDULE_CONSTANTS.ACTION_EDIT:
          booking.CellAction = SCHEDULE_CONSTANTS.ACTION_EDIT;
          break;

        case SCHEDULE_CONSTANTS.ACTION_REPLACE:
          booking.CellAction = SCHEDULE_CONSTANTS.ACTION_REPLACE;
          break;
      }
    }

    if (
      occurredFromCellChange === undefined ||
      occurredFromCellChange === true
    ) {
      // Check if the cell edited/deleted

      booking.Action = '';
      if (action === SCHEDULE_CONSTANTS.ACTION_REPLACE) {
        booking.Action = SCHEDULE_CONSTANTS.ACTION_REPLACE;
        booking.CellAction = SCHEDULE_CONSTANTS.ACTION_REPLACE;
      } else if (booking.BookingHours === '' || booking.Loading === '') {
        booking.Action = SCHEDULE_CONSTANTS.ACTION_DELETE;
        booking.CellAction = SCHEDULE_CONSTANTS.ACTION_DELETE;
      } else if (
        !booking.OldHoursValue &&
        !booking.OldPercentageValue &&
        !booking.OldDescription
      ) {
        booking.Action = SCHEDULE_CONSTANTS.ACTION_EDIT;
        booking.CellAction = SCHEDULE_CONSTANTS.ACTION_CREATE;
      } else if (
        !booking.OldHoursValue &&
        !booking.OldPercentageValue &&
        !booking.OldDescription
      ) {
        booking.Action = SCHEDULE_CONSTANTS.ACTION_EDIT;
        booking.CellAction = SCHEDULE_CONSTANTS.ACTION_CREATE;
      } else if (
        booking.BookingHours !== booking.OldHoursValue ||
        booking.Loading !== booking.OldPercentageValue ||
        booking.Description !== booking.OldDescription
      ) {
        booking.Action = SCHEDULE_CONSTANTS.ACTION_EDIT;
      }
    }
    // To get the action performed on cell (Create/Edit/Copy/Delete/Replace)
    let actionDetails = '';
    actionDetails =
      !booking.CellAction || booking.CellAction === ''
        ? SCHEDULE_CONSTANTS.ACTION_EDIT
        : booking.CellAction;

    // Check if the cell already exists in editedDetails array
    const itemExists = this.cellDetails.filter(
      (item) => item.TempId === booking.TempId
    );

    if (itemExists && itemExists.length > 0) {
      // To check if the user reverted his changes
      if (
        booking.BookingHours == booking.OldHoursValue &&
        !engagement.isCopy && // Do this check only if the booking is not from copy/replace resource
        !engagement.rowReplaced
      ) {
        this.deleteCellDetails(booking.TempId);
        booking.MeraBookingId = 0;
        booking.ColorCode = booking.ColorCodeOld;
      } else {
        this.cellDetails = this.cellDetails.map((cellItem) => {
          if (cellItem.TempId === booking.TempId) {
            cellItem.Hours = booking.BookingHours;
            cellItem.Description = booking.Description;
            cellItem.Loading = booking.Loading;
            cellItem.SplitId = booking.SplitId;
            cellItem.StartDate = booking.WeekStartDate + '.000Z';
            cellItem.EndDate = booking.WeekEndDate
              ? splitAndJoinDate(booking.WeekEndDate) + '.000Z'
              : splitAndJoinDate(booking.EndDate) + '.000Z';
          }
          return cellItem;
        });
      }
    } else {
      const tpId = booking.TempId
        ? booking.TempId
        : engagement.JobId +
          '_' +
          engagement.ResourceId +
          '_' +
          this.cellDetails.length +
          '_' +
          booking.BookingMode +
          '_' +
          booking.ActionMode;

      booking.MeraActionDescriptionId =
        MeraActionDescription[
          actionDetails as keyof typeof MeraActionDescription
        ];
      if (!(booking.BookingId == 0 && booking.MeraActionDescriptionId == 5)) {
        const data = {
          BookingId: booking.BookingId ? booking.BookingId : 0,
          ResourceId: engagement.ResourceId,
          JobId: engagement.JobId,
          BookingType: engagement.BookingType,
          StartDate: sDate,
          EndDate: eDate,
          Description: booking.Description,
          Hours:
            action === SCHEDULE_CONSTANTS.ACTION_DELETE
              ? booking.ActualBookingHours
              : booking.BookingHours,
          TempId: tpId,
          SplitId: booking.SplitId,
          Loading:
            booking.Loading === '' || booking.Loading === null
              ? 0
              : booking.Loading,
          InstanceId: null,
          Unconfirmed: booking.BookingMode === 'Unconfirmed' ? true : false,
          Ghost: booking.BookingMode === 'Ghost' ? true : false,
          IsOverallocation: false,
          ActionDescriptionId:
            action === SCHEDULE_CONSTANTS.ACTION_REPLACE ? 2 : 0,
          MeraActionDescriptionId:
            MeraActionDescription[
              actionDetails as keyof typeof MeraActionDescription
            ],
          ActualBookingId: booking.ActualBookingId
            ? booking.ActualBookingId
            : 0,
          ParentBookingId: booking.ParentBookingID
            ? booking.ParentBookingID
            : 0,
        };

        if (
          action === SCHEDULE_CONSTANTS.ACTION_CREATE ||
          action === SCHEDULE_CONSTANTS.ACTION_REPLACE ||
          booking.BookingHours !== booking.OldHoursValue
        )
          this.cellDetails.push(data);
      }
    }
    if (
      occurredFromCellChange === undefined ||
      occurredFromCellChange === true
    ) {
      booking.IsMergedBooking &&
        this.checkMergeBooking(engagement, booking, action);
      booking.IsSplitBooking && this.checkSplitBooking(engagement, booking);
    }
    console.log('Add editCell', this.cellDetails);
  };

  private checkSplitBooking = (engagement, booking) => {
    const currentResource = [];
    Object.keys(engagement.WeeklyBookings).forEach((week) => {
      // engagement.WeeklyBookings[week][booking.BookingMode].TempId !== booking.TempId &&
      if (
        engagement.WeeklyBookings[week][booking.BookingMode].ActualBookingId ===
        booking.ActualBookingId
      ) {
        currentResource.push(
          engagement.WeeklyBookings[week][booking.BookingMode]
        );
      }
    });

    if (currentResource.length > 0) {
      let actualBooking;
      actualBooking = currentResource.filter(
        (bookData) =>
          bookData.BookingId > 0 &&
          bookData.BookingId === bookData.ActualBookingId
      )[0];

      // To send the Edit request for the one with BookingId > 0
      if (actualBooking) {
        if (
          actualBooking.BookingHours === null ||
          actualBooking.BookingHours === ''
        ) {
          this.setCellDetails(
            engagement,
            actualBooking,
            SCHEDULE_CONSTANTS.ACTION_DELETE,
            false
          );
        } else {
          let newBooking = JSON.parse(JSON.stringify(actualBooking));
          newBooking.Action = SCHEDULE_CONSTANTS.ACTION_CREATE;
          newBooking.TempId = newBooking.TempId + 'split';
          newBooking.ActualBookingHours = 0;
          newBooking.BookingId = 0;
          newBooking.BookingHoursOld = 0;
          newBooking.ActualBookingId = 0;
          newBooking.IsSplitBooking = false;
          newBooking.Loading = 0;
          newBooking.LoadingOld = 0;
          newBooking.OldHoursValue = 0;
          newBooking.OldPercentageValue = 0;
          newBooking.IsNewBooking = true;
          newBooking.MeraActionDescriptionId = 1;
          this.setCellDetails(
            engagement,
            newBooking,
            SCHEDULE_CONSTANTS.ACTION_CREATE,
            false
          );
          this.deleteCellDetails(actualBooking.TempId);
          actualBooking.Action = SCHEDULE_CONSTANTS.ACTION_DELETE;
          actualBooking.MeraActionDescriptionId = 4;
          actualBooking.BookingHours = '';
          this.setCellDetails(
            engagement,
            actualBooking,
            SCHEDULE_CONSTANTS.ACTION_DELETE,
            false
          );
        }
      }

      // To send create request for those with BookingId === 0
      const otherbookings = currentResource.filter(
        (bookData) => bookData.BookingId === 0
      );

      otherbookings.forEach((bookData) => {
        if (bookData.BookingHours === null || bookData.BookingHours === '') {
          bookData.Action = SCHEDULE_CONSTANTS.ACTION_DELETE;
          bookData.MeraActionDescriptionId = 4;
          this.setCellDetails(
            engagement,
            bookData,
            SCHEDULE_CONSTANTS.ACTION_DELETE,
            false
          );
        } else {
          this.deleteCellDetails(bookData.TempId);
          bookData.MeraActionDescriptionId = 1;
          this.setCellDetails(
            engagement,
            bookData,
            SCHEDULE_CONSTANTS.ACTION_CREATE,
            false
          );
        }
      });
    }
  };

  private checkMergeBooking = (engagement, booking, action) => {
    let currentResource = [];
    if (action !== SCHEDULE_CONSTANTS.ACTION_REPLACE) {
      currentResource = this.resourceData.ScheduleBookingDto.filter(
        (currResource) => currResource.ResourceId === engagement.ResourceId
      );
    } else {
      currentResource = [this.replacedResource];
    }

    if (currentResource && currentResource.length > 0) {
      const isMergeBookingExists = currentResource[0].WeeklyBookings.filter(
        (currBook) =>
          currBook.ParentBookingID === booking.ActualBookingId &&
          currBook.ActionMode === 'D'
      );

      if (isMergeBookingExists && isMergeBookingExists.length > 0) {
        // Create delete requests for the merged cells
        isMergeBookingExists.forEach((eachBook) => {
          // To remove the details if already exists in cellDetails array
          const index = this.cellDetails.findIndex(
            (item) => item.ActualBookingId === eachBook.ActualBookingId
          );
          if (index === -1) {
            this.setCellDetails(
              engagement,
              eachBook,
              SCHEDULE_CONSTANTS.ACTION_DELETE,
              false
            );
          }
        });
      }
    }
  };

  public setData = (resData) => {
    this.resourceData = resData;
  };

  public saveReplacedResource = (resourceData) => {
    this.replacedResource = resourceData;
  };

  public fetchCurrentCellDetails = () => {
    return this.cellDetails;
  };

  public deleteCellDetails = (tempId) => {
    const editIndex = this.cellDetails.findIndex(
      (item) => item.TempId === tempId
    );
    if (editIndex > -1) {
      this.cellDetails.splice(editIndex, 1);
    }
  };

  public clearCellDetails = () => {
    this.cellDetails = [];
  };
}
