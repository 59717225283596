import {
  Component,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { SharedServicesService } from '../../shared/sharedservices/shared-services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ApprovalReqestVM,
  ApprovalQueueParams,
  ApproveRejectParmsModel,
  ApprovalBookingVM
} from '../../Models/ApprovalRequestVM';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { BookingGridActionService } from '../../Models/BookingGridActionService';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ApproveConfigService } from './approve-config.service';
import moment from 'moment';
import { SelectionModel } from '@angular/cdk/collections';
import { ExcelService } from 'app/shared/excel/excel.service';
import { DatePipe } from '@angular/common';
import { formatDate } from "@angular/common";
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-approval-queue',
  templateUrl: './approvalQueue.component.html',
  styleUrls: ['./approvalQueue.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ApprovalQueue implements OnInit {
  selection = new SelectionModel<ApprovalBookingVM>(true, []);
  range = new FormGroup({
    start: new FormControl(''),
    end: new FormControl(''),
  });
  requestedOnRange = new FormGroup({
    start: new FormControl(''),
    end: new FormControl(''),
  });
  jobCategory = 'Select';  //null;
  actionCategory = 'Select';  //null;
  engagementAllocationModal = '';
  isPercentageSelected: boolean = false;
  displayedColumns: string[] = [
    'Select',
    'RequestId',
    'ActionId',
    'ActionDescription',
    'ResourceName',
    'JobDescription',
    'Hours',
    'Loading',
    'StartDate',
    'EndDate',
    'Age',
  ];
  filterDisplayedColumns: string[] = [
    'SelectFilter',
    'RequestIdFilter',
    'ActionIdFilter',
    'ActionFilter',
    'ResourceNameFilter',
    'JobDescriptionFilter',
    'HoursFilter',
    'LoadingFilter',
    'StartDateFilter',
    'EndDateFilter',
    'AgeFilter',
  ];
  excelColumns: any[] = [
    { Key: 'RequestId', value: 'Request ID' },
    { Key: 'MeraBookingId', value: 'Action ID' },
    { Key: 'ActionDescription', value: 'Action' },
    { Key: 'ResourceName', value: 'Resource' },
    { Key: 'JobDescription', value: 'Engagement' },
    { Key: 'Hours', value: 'Hours' },
    { Key: 'Loading', value: 'Loading%' },
    { Key: 'StartDate', value: 'Start Date' },
    { Key: 'EndDate', value: 'End Date' },
    { Key: 'Age', value: 'Ageing(in days)' },
  ];
  //displayedColumns: string[] = ['Select', 'RequestId', 'ActionId', 'Action', 'Resources','Engagement','Hours', 'Loading', 'StartDate', 'EndDate','Age'];
  dataSourceApproval: MatTableDataSource<ApprovalBookingVM>;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChild('successMesgTemplte') successMesgTemplte: TemplateRef<any>;
  @ViewChild('empTbSort') empTbSort = new MatSort();
  approvalRequestList: ApprovalBookingVM[];
  contains: boolean = true;
  requestIdFilter = new FormControl('');
  actionIdFilter = new FormControl('');
  resourcesFilter = new FormControl('');
  engagementFilter = new FormControl('');
  ageingFilter = new FormControl('');
  keywordSearch = new FormControl('');
  filterValues = {
    requestId: '',
    requestIdType: true,
    action: '',
    actionType: true,
    resourceName: '',
    resourceNameType: true,
    engagement: '',
    engagementType: true,
    ageing: '',
    ageingType: true,
    global: '',
    globalType: false
  };
  defaultDateFormat: string;
  approvalListSubscription: Subscription;
  showModalConfig = false;
  gridActionModalHeader = '';
  gridActionEventData: BookingGridActionService;
  modalOption: ModalOptions = {};
  modalRef: BsModalRef;
  confirmModalRef: BsModalRef;
  detailsModalRef: BsModalRef;
  filterParams: ApprovalQueueParams;
  bokingParms: ApprovalReqestVM;
  appoveRejectParams: ApproveRejectParmsModel;
  startDate: null;
  endDate: null;
  startRequestedOn: null;
  endRequestedOn: null;
  searchBar: string;
  resDetail: null;
  jobDetail: null;
  checkedMultiple : any;
  unCheckedMultiple: any;
  multipleActnFlg:any;
  approveText='Approve';
  rejectText='Reject';
  listOfBookings_tab:any[]=[];
  selectedTabIndex = 0;
  overallocatnMsg = false;
  apprRejDisbleflg = false;
  approvalQueueObj:any;
  constructor(
    public sharedService: SharedServicesService,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService,
    private approveConfigService: ApproveConfigService,
    private excelService: ExcelService,
    private datePipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.sharedService.activeLink = 'ApprovalLayer';
    this.engagementAllocationModal =
      this.isPercentageSelected === true ? 'percentage' : 'hour';
  }

  loggedinUser: any;
  isConfigured: boolean = false;
  popupDetail: any;
  meraBookingDetails: any = [];
  exisitngBookingDetails: any = [];
  apprRejctDisbleFlg = true;
  approveRejectComents = new FormControl('', Validators.required);
  succesMsg_apprveRej = '';
  hasMultipleActinIds: boolean = false;
  selectdReqId:any;
  engCategoryList:any[]=[{'Text':'Select'}];
  actionsList:any[]=[{'Text':'Select'}];
  selectedTableRow: ApprovalBookingVM;
  approveRejectCommentsTitle;
  approveRejectconfirmationText;
  actionName;
  ngOnInit(): void {
    this.spinner.show();
    this.loggedinUser = JSON.parse(sessionStorage.getItem('resource'));
    this.defaultDateFormat = this.sharedService.getDateFormat(this.loggedinUser.DefaultDateFormat);
    this.filterParams = new ApprovalQueueParams();
    this.approveConfigService
      .getUserApprovalQueueConfigStatus()
      .subscribe(async (res) => {
        this.isConfigured = res;
        this.spinner.hide();
        if (this.isConfigured) {
          this.approvalQueueObj = this.approveConfigService.retrieveApprovalQueueRequestSelected()
          if (this.approvalQueueObj != undefined || this.approvalQueueObj !=null)
          {
               this.approvalRequestList = await this.approveConfigService.getApprovalQueueGridData();
               this.dataSourceApproval = new MatTableDataSource(
                this.approvalRequestList
              );
               this.selectedTabIndex=1;
               this.getRequestDetails(this.approvalQueueObj,'eachRow');
          }
          else     
            this.getApprovalQueue();
        }
      });
    this.gridActionEventData = new BookingGridActionService();
    this.engagementAllocationModal =
      this.loggedinUser.PercentSelected === true ? 'percentage' : 'hour';
    this.isPercentageSelected = this.loggedinUser.PercentSelected;
    this.sharedService.activeLink = 'ApprovalLayer';
    this.contains = true;
    this.approvalRequestList = [];
    this.getCategoryActionsList();
    this.handleGridFilterChange();
    this.approveConfigService.currentMessageCloseModal.subscribe(
      (closeModal) => {
        this.closeActionModal(closeModal);
      }
    );
  }
  // Fetch Category and Actions Data
  getCategoryActionsList(){
    this.approveConfigService.fetchCategoryActionData().subscribe(data=>{
      this.engCategoryList = data.EngagementCategoryList;
      this.actionsList = data.ActionDescriptionList;
      this.actionsList.splice(0, 0, {'Text':'Select'});
      this.engCategoryList.splice(0, 0, {'Text':'Select'});
      })
  }



  closeActionModal(closeModal) {
    if (closeModal) {
      if (this.modalRef !== undefined && this.modalRef !== null) {
        this.modalRef.hide();
        this.approveConfigService.getUserApprovalQueueConfigStatus().subscribe((res) => {
        this.isConfigured = res;
        this.spinner.hide();
        if (this.isConfigured) {
          this.getApprovalQueue();
        }else{
          //will do Nothng
        }
      });
        // this.isConfigured = true;
        // this.getApprovalQueue();
      }
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(from?) {
    if(from == 'search') return false;
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceApproval.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.apprRejctDisbleFlg = !this.isAllSelected() ? false : true;
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSourceApproval.data.forEach((row) =>
        this.selection.select(row)
      );
      this.dataSourceApproval.data.forEach(element => {
        element.Select = false;
      })
  }



  selectedIndex: number = null;
  selectedRow: object = null;
  pageSize: number = 10;
  highestIndex: number = this.pageSize - 1;
  clikType:any;

  onRowSelect(rowItem,type?) {
    this.clikType=type;
    this.dataSourceApproval.sortData(this.dataSourceApproval.filteredData,this.dataSourceApproval.sort).filter((eachReq,i)=>{
      if(eachReq.RequestId == rowItem.RequestId && eachReq.MeraBookingId == rowItem.MeraBookingId){
        this.selectedIndex = i;
      }
    });
    
    this.selectedRow = rowItem; // From this will pass inputParams and will pass to below method
    this.selectedTableRow = rowItem;
    this.getEachRequestDetails(rowItem);
  }

  getRequestDetails(rowItem,type?) {
    this.clikType=type; 
    this.dataSourceApproval.data.filter((eachReq,i)=>{
      if(eachReq.RequestId == rowItem.RequestId && eachReq.MeraBookingId == rowItem.MeraBookingId){
        this.selectedIndex = i;
      }
    });
    this.selectedRow = rowItem;
    this.selectedTableRow = rowItem;
    this.getEachRequestDetails(rowItem);
  }

  handleGridFilterChange() {
    this.requestIdFilter.valueChanges
      .subscribe(
        requestId => {
          this.filterValues.requestId = requestId;
          this.dataSourceApproval.filter = JSON.stringify(this.filterValues);
        }
      )
    this.actionIdFilter.valueChanges
      .subscribe(
        action => {
          this.filterValues.action = action;
          this.dataSourceApproval.filter = JSON.stringify(this.filterValues);
        }
      )
    this.resourcesFilter.valueChanges
      .subscribe(
        resourceName => {
          this.filterValues.resourceName = resourceName;
          this.dataSourceApproval.filter = JSON.stringify(this.filterValues);
        }
      )
    this.engagementFilter.valueChanges
      .subscribe(
        engagement => {
          this.filterValues.engagement = engagement;
          this.dataSourceApproval.filter = JSON.stringify(this.filterValues);
        }
      )
    this.ageingFilter.valueChanges
      .subscribe(
        ageing => {
          this.filterValues.ageing = ageing;
          this.dataSourceApproval.filter = JSON.stringify(this.filterValues);
        }
      )
      this.keywordSearch.valueChanges.subscribe(
        filterValue => {
          if(filterValue.trim().length > 0){
            this.filterValues.globalType = true;
          }else{
            this.filterValues.globalType = false;
          }
          this.filterValues.global = filterValue;
          this.dataSourceApproval.filter = JSON.stringify(this.filterValues);
        }
      )
  }

  getEachRequestDetails(eachRow?: any) {
    this.overallocatnMsg = eachRow.IsOverallocation ? true : false;
    if(eachRow.HasMultipleAction == 1){
      this.multipleActnFlg=true;
      this.approveText='Approve All';
      this.rejectText='Reject All';
    }else{
      this.multipleActnFlg=false;
      this.approveText='Approve';
      this.rejectText='Reject';
    }
    this.spinner.show();
    this.meraBookingDetails = [];
    this.exisitngBookingDetails = [];
    this.bokingParms = new ApprovalReqestVM();
    this.bokingParms.MeraBookingId = eachRow.MeraBookingId;
    this.bokingParms.ResourceID = eachRow.ResourceID;
    this.bokingParms.InstanceId = eachRow.InstanceId;
    this.bokingParms.BookingId = eachRow.BookingId;
    this.bokingParms.StartDate = eachRow.startDate_sort;
    this.bokingParms.EndDate = eachRow.endDate_sort;
    //this.bokingParms.StartDate = new Date(moment(eachRow.StartDate, this.defaultDateFormat).toISOString());
    //this.bokingParms.EndDate = new Date(moment(eachRow.EndDate, this.defaultDateFormat).toISOString());
    this.approveConfigService.getBookingReqDetails(this.bokingParms).subscribe((data) => {
      //console.log(data);
      this.selectedRow = data;
      data.BookingDetailDtos.forEach(ele => {
        if (ele.BookingSource == 'MB') {
          this.meraBookingDetails = ele;
          this.meraBookingDetails.IsDisabledViewBooking_Res=eachRow.IsDisabledViewBooking_Res;
          this.meraBookingDetails.IsDisabledViewBooking_Job=eachRow.IsDisabledViewBooking_Job;
          this.meraBookingDetails.InstanceId=eachRow.InstanceId;
          this.meraBookingDetails.ResourceID=eachRow.ResourceID;
          this.meraBookingDetails.MeraBookingId=eachRow.MeraBookingId; 
          this.meraBookingDetails.BookingId=eachRow.BookingId; 
          this.meraBookingDetails.startDate_sort=eachRow.startDate_sort;
          this.meraBookingDetails.endDate_sort=eachRow.endDate_sort;
          this.meraBookingDetails.IsOverallocation=eachRow.IsOverallocation;
          this.meraBookingDetails.HasMultipleAction=eachRow.HasMultipleAction;
          this.meraBookingDetails.RequestId=eachRow.RequestId;
          this.meraBookingDetails.Description= eachRow.Description;
          if(this.meraBookingDetails.StatusDescription == 'Pending Approval'){
            this.apprRejDisbleflg= true;
          }else{
            this.apprRejDisbleflg= false;
          }
        } else if (ele.BookingSource == 'B') {
          this.exisitngBookingDetails = ele;
          this.exisitngBookingDetails.IsDisabledViewBooking_Res=eachRow.IsDisabledViewBooking_Res;
          this.exisitngBookingDetails.IsDisabledViewBooking_Job=eachRow.IsDisabledViewBooking_Job;
          this.exisitngBookingDetails.InstanceId=eachRow.InstanceId;
          this.exisitngBookingDetails.ResourceID=eachRow.ResourceID;
          this.exisitngBookingDetails.BookingId=eachRow.BookingId; 
          this.exisitngBookingDetails.MeraBookingId=eachRow.MeraBookingId; 
          this.exisitngBookingDetails.startDate_sort=eachRow.startDate_sort;
          this.exisitngBookingDetails.endDate_sort=eachRow.endDate_sort;
          this.exisitngBookingDetails.IsOverallocation=eachRow.IsOverallocation;
          this.exisitngBookingDetails.HasMultipleAction=eachRow.HasMultipleAction;
          this.exisitngBookingDetails.RequestId=eachRow.RequestId;
          this.exisitngBookingDetails.Description= eachRow.Description;
        }
      })
      this.approvalQueueObj=null;
      this.approveConfigService.saveApprovalQueueRequestSelected(this.approvalQueueObj);
      this.spinner.hide();
    });

  }

  BokingSelection(row) {
    setTimeout(() => {
      this.apprRejctDisbleFlg = this.selection.selected.length > 0 ? false : true;

      this.selectdReqId=row['RequestId'];
      if(this.selection.isSelected(row)) {
        if (row['HasMultipleAction'] == 1) {
          row.Select = true;
            this.unCheckedMultiple = this.dataSourceApproval.data.filter((x) => {
              if(x.RequestId === this.selectdReqId){
                this.selection.select(x);
                return x.RequestId === this.selectdReqId;
              }
           });
           if( this.unCheckedMultiple.length > 0){
             this.unCheckedMultiple.forEach(element => {
              element.Select = true;
             });
           }
        }
        
      }else{
        if (row['HasMultipleAction'] == 1) {
          this.checkedMultiple = this.selection.selected.filter((x) => {
            if(x.RequestId === this.selectdReqId){
              this.selection.deselect(x);
              return x.RequestId === this.selectdReqId;
            }
           });
           this.apprRejctDisbleFlg = this.selection.selected.length > 0 ? false : true;
          if(this.checkedMultiple.length > 0){
             this.dataSourceApproval.data.forEach(element => {
               if(element.RequestId === this.selectdReqId){
                  element.Select = false;
               }
             })
          }
        }
      }
    }, 500)
   
   

  }

  approveRejectbookingReq() {
    this.modalRef.hide();
    this.spinner.show();
    let inputListParms = [];
    this.selection.selected.forEach(eachReq => {
      this.appoveRejectParams = new ApproveRejectParmsModel();
      this.appoveRejectParams.AppApprovalComments = this.approveRejectComents.value;
      this.appoveRejectParams.LoggedInUserId = this.loggedinUser.UserId;
      this.appoveRejectParams.IsApprove = (this.actionName == 'approve') ? true : false;
      this.appoveRejectParams.RequestId = eachReq.RequestId;
      this.appoveRejectParams.MeraBookingId = eachReq.MeraBookingId;
      this.appoveRejectParams.InstanceID = eachReq.InstanceId;
      inputListParms.push(this.appoveRejectParams)
    })
    this.approveConfigService.setApprovalQueueGridData(null);
    this.approveRejectServiceCall(inputListParms, this.actionName);

  }


  approveRejectServiceCall(payload, action, tabName?) {
    this.approveConfigService.saveApproveRejectReqDetails(payload).subscribe(res => {
      console.log(res);
      this.spinner.hide();
      if (res.success) {
        this.modalOption.backdrop = 'static';
        this.modalOption.keyboard = false;
        this.approveRejectComents.setValue('');
        if (action == 'reject') {
          this.succesMsg_apprveRej = 'The selected bookings request (s) has been rejected.';
        } else {
          this.succesMsg_apprveRej = 'The selected bookings request (s) has been approved.';
        }
        this.apprRejctDisbleFlg = true;
        this.actionName='';
        this.modalRef = this.modalService.show(this.successMesgTemplte, this.modalOption);
        setTimeout(() => {
          this.modalRef.hide();
          if (tabName == 'BDTab') {
            const selectedReqs_AR= this.listOfBookings_tab.length; // Selected bokings for Apprve/Reject
            let selctdindexAry=[];
            this.dataSourceApproval?.data.filter((booking,index)=>{
                 if(booking.RequestId == payload[0].RequestId){ // Filtering indexes with Same ReqID
                  selctdindexAry.push(index);
                 }
            })

            this.dataSourceApproval?.data.splice(selctdindexAry[0],selectedReqs_AR);
            let updatedBokingList = this.dataSourceApproval?.data; 
            if(updatedBokingList.length == 0){
              this.selection.clear();
            }else {
              if(updatedBokingList[selctdindexAry[0]] != undefined){
                this.selectedIndex=selctdindexAry[0];
                const rowData=updatedBokingList[selctdindexAry[0]];
                this.getEachRequestDetails(rowData);
              }else{
                this.selectedIndex=0;
                this.getEachRequestDetails(updatedBokingList[0]);
             }
            }
            
          } else {
            this.getApprovalQueue();
            this.selection.clear();
          }
        }, 3000)
      }
      else {
        this.succesMsg_apprveRej = 'Action failed, please check the request status again.';                
        this.apprRejctDisbleFlg = true;
        this.modalRef = this.modalService.show(this.successMesgTemplte, this.modalOption);
        setTimeout(() => {
          this.modalRef.hide();
          this.onRowSelect(this.selectedTableRow);
          this.apprRejDisbleflg= false;
        }, 3000)
      }
    })
  }
  rejectbookingReq(template) {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.approveRejectCommentsTitle='Reject';
    this.approveRejectconfirmationText='Please provide the reason for rejection';
    this.actionName='reject';
    this.modalRef = this.modalService.show(template, this.modalOption);
  }


  approvebookingReq(template){
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.approveRejectCommentsTitle='Approve';
    this.approveRejectconfirmationText='Please provide the reason for approval';
    this.actionName='approve'
    this.modalRef = this.modalService.show(template, this.modalOption);
  }

  // saveRejectedRequest() {
  //   this.modalRef.hide();
  //  // this.rejectComents.setValue('');
  //   this.spinner.show();
  //   this.approveRejectbookingReq('reject');
  // }
  // saveRejectedRequest_aditinl() {
  //   this.modalRef.hide();
  //   this.spinner.show();
  //   ///this.rejectComents.setValue('');
  //   this.approveRejectReq_aditnl('reject');
  // }


  approveRejectReq_aditnl() {
    this.modalRef.hide();
    this.spinner.show();
    let inputListParms = [];
    this.listOfBookings_tab=[];
    this.dataSourceApproval.data.filter(eachReq=>{
      if(eachReq.RequestId == this.selectedRow['RequestId']){
        this.listOfBookings_tab.push(eachReq);
      }
    })
    this.listOfBookings_tab.forEach(eachReq => {
      this.appoveRejectParams = new ApproveRejectParmsModel();
      this.appoveRejectParams.AppApprovalComments = this.approveRejectComents.value;
      this.appoveRejectParams.LoggedInUserId = this.loggedinUser.UserId;
      this.appoveRejectParams.IsApprove = (this.actionName == 'approve') ? true : false;
      this.appoveRejectParams.RequestId = eachReq.RequestId;
      this.appoveRejectParams.MeraBookingId = eachReq.MeraBookingId;
      this.appoveRejectParams.InstanceID = eachReq.InstanceId;
      inputListParms.push(this.appoveRejectParams)
    })
    this.approveConfigService.setApprovalQueueGridData(null);
    this.approveRejectServiceCall(inputListParms, this.actionName, 'BDTab'); //BookingDetailsTab
  }



  showNext(currIndex) {
    this.selectedIndex = currIndex + 1;
    const rowData = this.dataSourceApproval.data[currIndex + 1]; //Will pass this row data to below method
    this.getEachRequestDetails(rowData);
  }
  showPrev(currIndex) {
    this.selectedIndex = currIndex - 1;
    const rowData = this.dataSourceApproval.data[currIndex - 1];
    this.getEachRequestDetails(rowData);
  }

  onChangePage(pageEvent) {
    this.pageSize = pageEvent?.pageSize;
    const isLastPage =
      Math.floor(pageEvent?.length / this.pageSize) === pageEvent?.pageIndex;
    this.highestIndex = isLastPage ? pageEvent?.length % this.pageSize : 0;
    this.selectedIndex = null;
    this.selectedRow = null;
  }

   getApprovalQueue() {
    this.spinner.show();
    this.approvalListSubscription = this.approveConfigService
      .getApprovalQueueList(this.filterParams)
      .subscribe(async (data) => {
        this.approvalRequestList = data;
        const currDate = moment(new Date());
        this.approvalRequestList.forEach((element) => {
          element.startDate_sort=element.StartDate;
          element.endDate_sort=element.EndDate;
          element.StartDate = formatDate(element.StartDate, this.defaultDateFormat, 'en-US');
          element.EndDate = formatDate(element.EndDate, this.defaultDateFormat, 'en-US');
          element.Select = false;
          element.Age = this.calculateDaysDiff(new Date(element.SubmittedOn));
        });
        this.dataSourceApproval = new MatTableDataSource(
          this.approvalRequestList
        );
       if(this.empTbSort){
          this.empTbSort.disableClear = true;
       }
        this.dataSourceApproval.paginator = this.paginator.toArray()[0];
        this.dataSourceApproval.sort = this.sort.toArray()[0];
        this.dataSourceApproval.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'StartDate': return new Date(item.startDate_sort);
            case 'EndDate' : return new Date(item.endDate_sort);
            default: return item[property];
          }
        };
        this.dataSourceApproval.filterPredicate = this.createFilter();
        this.maintainHiddenValues();
     
        this.approveConfigService.setApprovalQueueGridData(this.approvalRequestList);
         this.spinner.hide();
        // highlighting 1st item on page load
        // if (this.dataSourceApproval?.data?.length)
        //  // this.onRowSelect(this.dataSourceApproval.data[0], 0);
        //  this.onRowSelect(
        //    this.dataSourceApproval.sortData(this.dataSourceApproval.filteredData,this.dataSourceApproval.sort)[0],
        //    0)
      });
  }
  public doFilter(value: string) {
    this.dataSourceApproval.filter = value.trim().toLocaleLowerCase();
  }

  calculateDaysDiff(submitedDt) {
    const todaysDt = new Date();
    let time_difference = todaysDt.getTime() - submitedDt.getTime();
    //calculate days difference by dividing total milliseconds in a day  
    let days_difference = time_difference / (1000 * 60 * 60 * 24);
    return Number(days_difference.toFixed());
  }

  resetTableFilter() {
    this.searchBar = '';
    this.doFilter('');
  }
  triggerFilter(){
    this.dataSourceApproval.filter = JSON.stringify(this.filterValues);
  }
  createFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function (data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      if(searchTerms.globalType){
        return((data.ActionDescription ? data.ActionDescription?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
        (data.MeraBookingId ? data.MeraBookingId?.toString().toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
        (data.RequestId ? data.RequestId?.toString().toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
        (data.EndDate ? data.EndDate.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
        (data.StartDate ? data.StartDate.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
        (data.ResourceName ? data.ResourceName.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
        (data.JobDescription ? data.JobDescription.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
        (data.Hours ? data.Hours.toString().toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
        (data.Age ? data.Age.toString().toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) )
      }else{
        return ((searchTerms.requestIdType ? data.RequestId.toString().toLowerCase().indexOf(searchTerms.requestId.toLowerCase()) !== -1 : data.RequestId.toString().startsWith(searchTerms.requestId.toLowerCase()) !== false)
      && (searchTerms.actionType ? data.MeraBookingId.toString().toLowerCase().indexOf(searchTerms.action.toLowerCase()) !== -1 : data.MeraBookingId.toString().toLowerCase().startsWith(searchTerms.action.toLowerCase()) !== false)
      && (searchTerms.resourceNameType ? data.ResourceName.toLowerCase().indexOf(searchTerms.resourceName.toLowerCase()) !== -1 : data.ResourceName.toLowerCase().startsWith(searchTerms.resourceName.toLowerCase()) !== false)
      && (searchTerms.engagementType ? data.JobDescription.toLowerCase().indexOf(searchTerms.engagement.toLowerCase()) !== -1 : data.JobDescription.toLowerCase().startsWith(searchTerms.engagement.toLowerCase()) !== false)
      && (searchTerms.ageingType ?  data.Age.toString().toLowerCase().indexOf(searchTerms.ageing.toLowerCase()) !== -1 : data.Age.toString().toLowerCase().startsWith(searchTerms.ageing.toLowerCase()) !== false))
      }
      
    };
    return filterFunction;
  }
  clearTableFilter() {
    this.dataSourceApproval.filter = '';
    this.requestIdFilter.setValue('');
    this.actionIdFilter.setValue('');
    this.resourcesFilter.setValue('');
    this.engagementFilter.setValue('');
    this.ageingFilter.setValue('');
    this.keywordSearch.setValue('');
    this.filterValues.requestIdType = true;
    this.filterValues.actionType = true;
    this.filterValues.resourceNameType = true;
    this.filterValues.engagementType = true;
    this.filterValues.ageingType = true;
    this.filterValues.globalType = false;

  }
  exportAsXLSX(): void {

    const fileName = 'ApprovalQueueLog_export_' + new Date().toISOString();
    var dataToExport = [];
    this.dataSourceApproval.data.forEach(element => {
      var data = {};
      this.excelColumns.forEach(item => {
        // if (item.Key == 'StartDate' || item.Key == 'EndDate') {
        //   data[item.value] = this.datePipe.transform(new Date(element[item.Key]), "dd-MM-yyyy");
        // } else
          data[item.value] = element[item.Key];
      })
      dataToExport.push(data);
    });

    let workbookData = [
      {
        workSheet: 'Sheet1',
        rows: dataToExport
      }
    ]

    this.excelService.exportAsExcelFile(workbookData, fileName);

  }
  onStartDateChange(event) { }
  onEndDateChange(event) {
    this.startDate = this.range.get('start').value;
    this.endDate = this.range.get('end').value;
  }
  onStartRequestedOnChange(event) { }
  onEndRequestedOnChange(event) {
    this.startRequestedOn = this.requestedOnRange.get('start').value;
    this.endRequestedOn = this.requestedOnRange.get('end').value;
  }
  resetRequestedOnRange() {
    this.startRequestedOn = null;
    this.endRequestedOn = null;
    this.requestedOnRange.get('start').setValue(null);
    this.requestedOnRange.get('end').setValue(null);
    this.filterParams.StartRequestedOn = null;
    this.filterParams.EndRequestedOn = null;
  }
  resetRange() {
    this.startDate = null;
    this.endDate = null;
    this.range.get('start').setValue(null);
    this.range.get('end').setValue(null);
    this.filterParams.StartDate = null;
    this.filterParams.EndDate = null;
  }
  resetfilterCalender() {
    this.startDate = null;
    this.endDate = null;
    this.startRequestedOn = null;
    this.endRequestedOn = null;
    this.range.get('start').setValue(null);
    this.range.get('end').setValue(null);
    this.requestedOnRange.get('start').setValue(null);
    this.requestedOnRange.get('end').setValue(null);
    this.filterParams= new ApprovalQueueParams();
    this.filterParams.JobCategory = this.jobCategory == 'Select' ? null : this.jobCategory;
    this.filterParams.ActionDescription = this.actionCategory == 'Select' ? null : this.actionCategory;
    //this.filterParams.IsPercentSelected = this.isPercentageSelected;
  }
  onEngagementCategoryChange(event) {
    this.jobCategory = event.value;
  }
  onActionCategoryChange(event) {
    this.actionCategory = event.value;
  }
  engagementAllocationOnChange(event) {
    this.isPercentageSelected = this.engagementAllocationModal === 'hour' ? false : true;
    this.maintainHiddenValues();
    this.getApprovalQueue();
  }
  applyFilter() {
    if (this.startDate && this.endDate) {
      this.filterParams.StartDate = this.range.get('start').value;
      this.filterParams.EndDate = this.range.get('end').value;
    }
    if (this.startRequestedOn && this.endRequestedOn) {
      this.filterParams.StartRequestedOn = this.requestedOnRange.get('start').value;
      this.filterParams.EndRequestedOn = this.requestedOnRange.get('end').value;
    }
    this.filterParams.JobCategory = this.jobCategory == 'Select' ? null : this.jobCategory;
    this.filterParams.ActionDescription = this.actionCategory == 'Select' ? null : this.actionCategory;
    //this.filterParams.IsPercentSelected = this.isPercentageSelected;
    this.keywordSearch.setValue('');
    this.selection = new SelectionModel<ApprovalBookingVM>(true, []);
    this.isAllSelected('search');
    this.apprRejctDisbleFlg=true;
    this.getApprovalQueue();
  }
  clearFilter() {
    this.filterParams = new ApprovalQueueParams();
    this.jobCategory = 'Select';  //null;
    this.actionCategory = 'Select';  //null;
    this.isPercentageSelected = this.loggedinUser.PercentSelected;
    //this.engagementAllocationModal = 'hour';
    this.engagementAllocationModal =
      this.loggedinUser.PercentSelected === true ? 'percentage' : 'hour';
    this.resetfilterCalender();
    this.keywordSearch.setValue('');
    this.selection = new SelectionModel<ApprovalBookingVM>(true, []);
    this.isAllSelected('search');
    this.apprRejctDisbleFlg=true;
    this.getApprovalQueue();
  }
  maintainHiddenValues() {
    if (this.isPercentageSelected) {
      const indexOfHour = this.displayedColumns.indexOf('Hours', 0);
      if (indexOfHour > -1) {
        this.displayedColumns.splice(indexOfHour, 1);
        this.filterDisplayedColumns.splice(indexOfHour, 1);
      }
      const indexOfPercentage = this.displayedColumns.indexOf('Loading', 0);
      if (indexOfPercentage === -1) {
        this.displayedColumns.splice(
          this.displayedColumns.indexOf('JobDescription') + 1,
          0,
          'Loading'
        );
        this.filterDisplayedColumns.splice(
          this.filterDisplayedColumns.indexOf('JobDescriptionFilter') + 1,
          0,
          'LoadingFilter'
        );
      }
    } else {
      const indexOfHour = this.displayedColumns.indexOf('Hours', 0);
      if (indexOfHour === -1) {
        this.displayedColumns.splice(
          this.displayedColumns.indexOf('JobDescription') + 1,
          0,
          'Hours'
        );
        this.filterDisplayedColumns.splice(
          this.filterDisplayedColumns.indexOf('JobDescriptionFilter') + 1,
          0,
          'HoursFilter'
        );
      }
      const indexOfPercentage = this.displayedColumns.indexOf('Loading', 0);
      if (indexOfPercentage > -1) {
        this.displayedColumns.splice(indexOfPercentage, 1);
        this.filterDisplayedColumns.splice(indexOfPercentage, 1);
      }
    }
  }
  openActionModal(template, event, dataItem, templateName) {
    this.showModalConfig = false;
    this.gridActionEventData.dataItem = dataItem;
    this.gridActionEventData.event = event;
    this.gridActionEventData.template = template;
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    if (templateName === 'configure') {
      this.gridActionModalHeader = 'Configure Approval Queue';
      this.showModalConfig = true;
      this.approveConfigService.openConfigureApproveQueueModal(
        this.gridActionEventData
      );
    }
    this.modalRef = this.modalService.show(template, this.modalOption);
    this.modalRef.setClass('ea-modal');
  }

  openConfirmationModal(template) {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.confirmModalRef = this.modalService.show(template, this.modalOption);
  }
  okayConfirm() {
    this.confirmModalRef.hide();
    if (this.modalRef !== undefined && this.modalRef !== null) {
      this.modalRef.hide();
    }
  }
  showDetails(type, element, template) {
    this.spinner.show();
    this.popupDetail = type;
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    if (type === 'Resource') {
      this.approveConfigService
        .getApprovalResDetails(element.ResourceID,element.InstanceId)
        .subscribe((data) => {
          this.resDetail = data;
          this.spinner.hide();
          this.detailsModalRef = this.modalService.show(
            template,
            this.modalOption
          );
        });
    } else if (type == 'Engagement') {
      this.approveConfigService
        .getApprovalJobDetails(element.JobID,element.InstanceId)
        .subscribe((data) => {
          this.jobDetail = data;
          this.spinner.hide();
          this.detailsModalRef = this.modalService.show(
            template,
            this.modalOption
          );
        });
    }else if(type == 'Res_Mera'){
      this.popupDetail = 'Resource';
      this.approveConfigService
      .getApprovalResDetails(this.selectedRow['BookingDetailDtos'][0].ResourceId,this.selectedRow['BookingDetailDtos'][0]?.InstanceID) 
      .subscribe((data) => {
        this.resDetail = data;
        this.spinner.hide();
        this.detailsModalRef = this.modalService.show(
          template,
          this.modalOption
        );
      });
    }else if( type == 'Res_Existng'){
      this.popupDetail = 'Resource';
      this.approveConfigService
      .getApprovalResDetails(this.selectedRow['BookingDetailDtos'][1]?.ResourceId,this.selectedRow['BookingDetailDtos'][1]?.InstanceID) 
      .subscribe((data) => {
        this.resDetail = data;
        this.spinner.hide();
        this.detailsModalRef = this.modalService.show(
          template,
          this.modalOption
        );
      });
    } else if(type == 'Eng_Mera'){
      this.popupDetail = 'Engagement';
      this.approveConfigService
      .getApprovalJobDetails(this.selectedRow['BookingDetailDtos'][0].JobID
                            ,this.selectedRow['BookingDetailDtos'][0].InstanceID) 
      .subscribe((data) => {
        this.jobDetail = data;
        this.spinner.hide();
        this.detailsModalRef = this.modalService.show(
          template,
          this.modalOption
        );
      });
    }else if( type == 'Eng_Existng'){
      this.popupDetail = 'Engagement';
      this.approveConfigService
      .getApprovalJobDetails(this.selectedRow['BookingDetailDtos'][1]?.JobID
                             ,this.selectedRow['BookingDetailDtos'][1]?.InstanceID) 
      .subscribe((data) => {
        this.jobDetail = data;
        this.spinner.hide();
        this.detailsModalRef = this.modalService.show(
          template,
          this.modalOption
        );
      });
    } 
  }
  openDetailsPopup() {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.detailsModalRef = this.modalService.show(
      'templateDetails',
      this.modalOption
    );
  }

  openFilterModal(template) {
    this.keywordSearch.setValue('');
    this.filterValues.global = '';
    this.filterValues.globalType = false;
    this.dataSourceApproval.filter = JSON.stringify(this.filterValues);
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.modalRef = this.modalService.show(template, this.modalOption);
  }

  closeFilterModal() {
    this.clearTableFilter();
    this.modalRef.hide();
  }

  filterGrid() {
    this.dataSourceApproval.filter = JSON.stringify(this.filterValues);
    this.modalRef.hide();
  }

  apprveQTabChange(index: number) {
    if(index == 0){
      this.keywordSearch.setValue('');
      this.clikType='';
      this.selection = new SelectionModel<ApprovalBookingVM>(true, []);
      //this.isAllSelected();
      this.apprRejctDisbleFlg=true;
      this.getApprovalQueue();
    }else if(index == 1 && this.clikType !='eachRow'){
      this.onRowSelect(
        this.dataSourceApproval.sortData(this.dataSourceApproval.filteredData,this.dataSourceApproval.sort)[0]
        )
    }   
  } 
 
  navigateEngagement(item){
    if(!item.IsDisabledViewBooking_Job){
      const product = {
        engagement: (item.JobCode === null || item.JobCode === undefined) ? item.JobDescription : item.JobCode,
        instanceId: item.InstanceId,
        resourceId: item.ResourceID,
        isNavFromApprovalQueue:true,
        meraBookingId : item.MeraBookingId,
        bookingId : item.BookingId,
        startDate : item.startDate_sort,
        endDate : item.endDate_sort,
        isOverallocation : item.IsOverallocation,
        hasMultipleAction : item.HasMultipleAction,
        requestId : item.RequestId,
        isDisabledViewBooking_Res : item.IsDisabledViewBooking_Res,
        isDisabledViewBooking_Job : item.IsDisabledViewBooking_Job,
        engagementName : item.JobDescription,
        Description : item.Description
      }
      this.approveConfigService.saveApprovalQueueRequestSelected(product)
      this.router.navigateByUrl('/EngagementBooking', { state: product });
    }
  }
  
  navigateIndividual(item){
    if(item.IsDisabledViewBooking_Res){
      return;
    }
    const product = {
      individual:  item.ResourceName,
      instanceId: item.InstanceId,
      resourceId: item.ResourceID,
      isNavFromApprovalQueue:true,
      meraBookingId : item.MeraBookingId,
      bookingId : item.BookingId,
      startDate : item.startDate_sort,
      endDate : item.endDate_sort,
      isOverallocation : item.IsOverallocation,
      hasMultipleAction : item.HasMultipleAction,
      requestId : item.RequestId,
      isDisabledViewBooking_Res : item.IsDisabledViewBooking_Res,
      isDisabledViewBooking_Job : item.IsDisabledViewBooking_Job,
      engagementName : item.JobDescription,
      Description : item.Description
    }
    this.approveConfigService.saveApprovalQueueRequestSelected(product)
    this.router.navigateByUrl('/IndividualBooking', { state: product });
  }
}
