import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, Validators, AbstractControl, FormBuilder } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApproveConfigService } from '../approve-config.service';
import { ConfigureVM, ResourceConfigurationDto, JobConfigurationDto } from '../../../Models/ApprovalQueueConfigVm';


@Component({
  selector: 'app-add-configuration',
  templateUrl: './addConfiguration.component.html',
  styleUrls: ['./addConfiguration.component.scss']
})

export class AddConfigurationComponent implements OnInit {
  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    public restService: ApproveConfigService) { }

  loggedinUser: any;
  buttonText: string;
  formGroup = this.formBuilder.group({});
  resEditLink: boolean = false;
  jobEditLink: boolean = false;
  dirtyFlag: boolean = false;
  dropdownSettings: IDropdownSettings = {};
  dropDownData;
  userConfig: ConfigureVM;
  config: ConfigureVM;
  SaveErrorMessage: string = '';
  customResultId: number;
  //Edit Configuration
  resEditSubAreaList = [];
  resEditSubAreaSelectedItems = [];
  resEditManageCountryList = [];
  resEditLegalEntityList = [];
  resEditBusinessUnitList = [];
  resEditLocationList = [];
  resEditServiceLineList = [];
  resEditSubServiceLineList = [];
  resEditManagementList = [];
  resEditSubManagementUnitList = [];
  resEditRankList = [];
  resEditOperatingUnitList = [];
  resEditDepartmentList = [];
  resEditGdsRegionAlignment = [];
  resEditCostCenterList = [];

  jobEditManageCountryList = [];
  jobEditLegalEntityList = [];
  jobEditBusinessUnitList = [];
  jobEditServiceLineList = [];
  jobEditSubServiceLineList = [];
  jobEditManagementUnitList = [];
  jobEditSubManagementUnitList = [];
  jobEditOperatingUnitList = [];
  jobEditJobCategoryList = [];
  jobEditJobCodeList = [];

  resEditManageCountrySelectedItems = [];
  resEditLegalEntitySelectedItems = [];
  resEditBusinessUnitSelectedItems = [];
  resEditLocationSelectedItems = [];
  resEditServiceLineSelectedItems = [];
  resEditSubServiceLineSelectedItems = [];
  resEditManagementUnitSelectedItems = [];
  resEditSubManagementUnitSelectedItems = [];
  resEditRankSelectedItems = [];
  resEditOperatingUnitSelectedItems = [];
  resEditDepartmentSelectedItems = [];
  resEditGdsRegionAlignmentSelectedItems = [];
  resEditCostCenterSelectedItems = [];

  jobEditManageCountrySelectedItems = [];
  jobEditBusinessUnitSelectedItems = [];
  jobEditLegalEntitySelectedItems = [];
  jobEditServiceLineSelectedItems = [];
  jobEditSubServiceLineSelectedItems = [];
  jobEditManagementUnitSelectedItems = [];
  jobEditSubManagementUnitSelectedItems = [];
  jobEditOperatingUnitSelectedItems = [];
  jobEditJobCategorySelectedItems = [];
  jobEditJobCodeSelectedItems = [];

  ngOnInit(): void {
    this.loggedinUser = JSON.parse(sessionStorage.getItem('resource'));
    this.buttonText = 'Apply';
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'Id',
      textField: 'Text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      enableCheckAll: false,
    };
    this.getInitialData();
    //this.masterData();
    this.restService.configureEvent.subscribe((data) => {
      console.log('configure', data);
    });
  }
  getInitialData() {
    this.spinner.show();
    this.restService.getData().subscribe(res => {
      this.dropDownData = res[1];
      this.userConfig = res[0];
      this.config = res[0];
      this.setData();
      console.log('useConfig', this.config);
    })
  }
  setData() {
    let resList = this.dropDownData.userApproverResConfigurationDto;
    let jobList = this.dropDownData.userApproverJobConfigurationDto;
    
    if (resList.ResViewManagerialCountries != null) {
      resList.ResViewManagerialCountries.forEach((element) => {
        this.resEditManageCountryList =
          this.resEditManageCountryList.concat({
            Id: element.ID,
            Text: element.Text,
          });
      });
    }
    if (resList.ResViewLegalEntities != null) {
      resList.ResViewLegalEntities.forEach((element) => {
        this.resEditLegalEntityList =
          this.resEditLegalEntityList.concat({
            Id: element.ID,
            Text: element.Text,
          });
      });
    }
    if (resList.ResViewBusinessUnits != null) {
      resList.ResViewBusinessUnits.forEach((element) => {
        this.resEditBusinessUnitList =
          this.resEditBusinessUnitList.concat({
            Id: element.ID,
            Text: element.Text,
          });
      });
    }
    if (resList.ResViewOfficeLocations != null) {
      resList.ResViewOfficeLocations.forEach((element) => {
        this.resEditLocationList =
          this.resEditLocationList.concat({
            Id: element.ID,
            Text: element.Text,
          });
      });
    }
    if (resList.ResViewServiceLines != null) {
      resList.ResViewServiceLines.forEach((element) => {
        this.resEditServiceLineList =
          this.resEditServiceLineList.concat({
            Id: element.ID,
            Text: element.Text,
          });
      });
    }
    if (resList.ResViewSubServiceLines != null) {
      resList.ResViewSubServiceLines.forEach((element) => {
        this.resEditSubServiceLineList =
          this.resEditSubServiceLineList.concat({
            Id: element.ID,
            Text: element.Text,
          });
      });
    }
    if (resList.ResViewManagementUnits != null) {                   //Management
      resList.ResViewManagementUnits.forEach((element) => {
        this.resEditManagementList =
          this.resEditManagementList.concat({
            Id: element.ID,
            Text: element.Text,
          });
      });
    }
    if (resList.ResViewSubManageUnits != null) {
      resList.ResViewSubManageUnits.forEach((element) => {
        this.resEditSubManagementUnitList =
          this.resEditSubManagementUnitList.concat({
            Id: element.ID,
            Text: element.Text,
          });
      });
    }
    if (resList.ResViewRanks != null) {
      resList.ResViewRanks.forEach((element) => {
        this.resEditRankList =
          this.resEditRankList.concat({
            Id: element.ID,
            Text: element.Text,
          });
      });
    }
    if (resList.ResViewOperatingUnits != null) {
      resList.ResViewOperatingUnits.forEach((element) => {
        this.resEditOperatingUnitList =
          this.resEditOperatingUnitList.concat({
            Id: element.ID,
            Text: element.Text,
          });
      });
    }
    if (resList.ResViewDepartments != null) {
      resList.ResViewDepartments.forEach((element) => {
        this.resEditDepartmentList =
          this.resEditDepartmentList.concat({
            Id: element.ID,
            Text: element.Text,
          });
      });
    }
    if (resList.ResViewGDSRegionAlignments != null) {
      resList.ResViewGDSRegionAlignments.forEach((element) => {
        this.resEditGdsRegionAlignment =
          this.resEditGdsRegionAlignment.concat({
            Id: element.ID,
            Text: element.Text,
          });
      });
    }
    if (resList.ResViewResourceCostCenters != null) {
      resList.ResViewResourceCostCenters.forEach((element) => {
        this.resEditCostCenterList =
          this.resEditCostCenterList.concat({
            Id: element.ID,
            Text: element.Text,
          });
      });
    }

    if (jobList.JobViewManagerialCountries != null) {
      jobList.JobViewManagerialCountries.forEach((element) => {
        this.jobEditManageCountryList =
          this.jobEditManageCountryList.concat({
            Id: element.ID,
            Text: element.Text,
          });
      });
    }
    if (jobList.JobViewLegalEntities != null) {
      jobList.JobViewLegalEntities.forEach((element) => {
        this.jobEditLegalEntityList =
          this.jobEditLegalEntityList.concat({
            Id: element.ID,
            Text: element.Text,
          });
      });
    }
    if (jobList.JobViewBusinessUnits != null) {
      jobList.JobViewBusinessUnits.forEach((element) => {
        this.jobEditBusinessUnitList =
          this.jobEditBusinessUnitList.concat({
            Id: element.ID,
            Text: element.Text,
          });
      });
    }
    if (jobList.JobViewServiceLines != null) {
      jobList.JobViewServiceLines.forEach((element) => {
        this.jobEditServiceLineList =
          this.jobEditServiceLineList.concat({
            Id: element.ID,
            Text: element.Text,
          });
      });
    }
    if (jobList.JobViewSubServiceLines != null) {
      jobList.JobViewSubServiceLines.forEach((element) => {
        this.jobEditSubServiceLineList =
          this.jobEditSubServiceLineList.concat({
            Id: element.ID,
            Text: element.Text,
          });
      });
    }
    if (jobList.ManagementUnits != null) {
      jobList.ManagementUnits.forEach((element) => {
        this.jobEditManagementUnitList =
          this.jobEditManagementUnitList.concat({
            Id: element.ID,
            Text: element.Text,
          });
      });
    }
    if (jobList.JobViewSubManageUnits != null) {
      jobList.JobViewSubManageUnits.forEach((element) => {
        this.jobEditSubManagementUnitList =
          this.jobEditSubManagementUnitList.concat({
            Id: element.ID,
            Text: element.Text,
          });
      });
    }
    if (jobList.OperatingUnits != null) {
      jobList.OperatingUnits.forEach((element) => {
        this.jobEditOperatingUnitList =
          this.jobEditOperatingUnitList.concat({
            Id: element.ID,
            Text: element.Text,
          });
      });
    }
    if (jobList.JobViewJobCategories != null) {
      jobList.JobViewJobCategories.forEach((element) => {
        this.jobEditJobCategoryList =
          this.jobEditJobCategoryList.concat({
            Id: element.ID,
            Text: element.Text,
          });
      });
    }
    if (jobList.JobViewJobTypes != null) {
      jobList.JobViewJobTypes.forEach((element) => {
        this.jobEditJobCodeList =
          this.jobEditJobCodeList.concat({
            Id: element.ID,
            Text: element.Text,
          });
      });
    }
    if(this.userConfig.userApproverResConfigurationDto.ResViewManagerialCountries !=null){
      this.userConfig.userApproverResConfigurationDto.ResViewManagerialCountries.forEach((element) => {
        this.resEditManageCountrySelectedItems = this.resEditManageCountrySelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverResConfigurationDto.ResViewLegalEntities !=null){
      this.userConfig.userApproverResConfigurationDto.ResViewLegalEntities.forEach((element) => {
        this.resEditLegalEntitySelectedItems = this.resEditLegalEntitySelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverResConfigurationDto.ResViewBusinessUnits !=null){
      this.userConfig.userApproverResConfigurationDto.ResViewBusinessUnits.forEach((element) => {
        this.resEditBusinessUnitSelectedItems = this.resEditBusinessUnitSelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverResConfigurationDto.ResViewOfficeLocations !=null){
      this.userConfig.userApproverResConfigurationDto.ResViewOfficeLocations.forEach((element) => {
        this.resEditLocationSelectedItems = this.resEditLocationSelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverResConfigurationDto.ResViewServiceLines !=null){
      this.userConfig.userApproverResConfigurationDto.ResViewServiceLines.forEach((element) => {
        this.resEditServiceLineSelectedItems = this.resEditServiceLineSelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverResConfigurationDto.ResViewSubServiceLines !=null){
      this.userConfig.userApproverResConfigurationDto.ResViewSubServiceLines.forEach((element) => {
        this.resEditSubServiceLineSelectedItems = this.resEditSubServiceLineSelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverResConfigurationDto.ResViewManagementUnits !=null){
      this.userConfig.userApproverResConfigurationDto.ResViewManagementUnits.forEach((element) => {
        this.resEditManagementUnitSelectedItems = this.resEditManagementUnitSelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverResConfigurationDto.ResViewSubManageUnits !=null){
      this.userConfig.userApproverResConfigurationDto.ResViewSubManageUnits.forEach((element) => {
        this.resEditSubManagementUnitSelectedItems = this.resEditSubManagementUnitSelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverResConfigurationDto.ResViewRanks !=null){
      this.userConfig.userApproverResConfigurationDto.ResViewRanks.forEach((element) => {
        this.resEditRankSelectedItems = this.resEditRankSelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverResConfigurationDto.ResViewOperatingUnits !=null){
      this.userConfig.userApproverResConfigurationDto.ResViewOperatingUnits.forEach((element) => {
        this.resEditOperatingUnitSelectedItems = this.resEditOperatingUnitSelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverResConfigurationDto.ResViewDepartments !=null){
      this.userConfig.userApproverResConfigurationDto.ResViewDepartments.forEach((element) => {
        this.resEditDepartmentSelectedItems = this.resEditDepartmentSelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverResConfigurationDto.ResViewGDSRegionAlignments !=null){
      this.userConfig.userApproverResConfigurationDto.ResViewGDSRegionAlignments.forEach((element) => {
        this.resEditGdsRegionAlignmentSelectedItems = this.resEditGdsRegionAlignmentSelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverResConfigurationDto.ResViewResourceCostCenters !=null){
      this.userConfig.userApproverResConfigurationDto.ResViewResourceCostCenters.forEach((element) => {
        this.resEditCostCenterSelectedItems = this.resEditCostCenterSelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverJobConfigurationDto.JobViewManagerialCountries !=null){
      this.userConfig.userApproverJobConfigurationDto.JobViewManagerialCountries.forEach((element) => {
        this.jobEditManageCountrySelectedItems = this.jobEditManageCountrySelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverJobConfigurationDto.JobViewBusinessUnits !=null){
      this.userConfig.userApproverJobConfigurationDto.JobViewBusinessUnits.forEach((element) => {
        this.jobEditBusinessUnitSelectedItems = this.jobEditBusinessUnitSelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverJobConfigurationDto.JobViewLegalEntities !=null){
      this.userConfig.userApproverJobConfigurationDto.JobViewLegalEntities.forEach((element) => {
        this.jobEditLegalEntitySelectedItems = this.jobEditLegalEntitySelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverJobConfigurationDto.JobViewServiceLines !=null){
      this.userConfig.userApproverJobConfigurationDto.JobViewServiceLines.forEach((element) => {
        this.jobEditServiceLineSelectedItems = this.jobEditServiceLineSelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverJobConfigurationDto.JobViewSubServiceLines !=null){
      this.userConfig.userApproverJobConfigurationDto.JobViewSubServiceLines.forEach((element) => {
        this.jobEditSubServiceLineSelectedItems = this.jobEditSubServiceLineSelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverJobConfigurationDto.ManagementUnits !=null){
      this.userConfig.userApproverJobConfigurationDto.ManagementUnits.forEach((element) => {
        this.jobEditManagementUnitSelectedItems = this.jobEditManagementUnitSelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverJobConfigurationDto.JobViewSubManageUnits !=null){
      this.userConfig.userApproverJobConfigurationDto.JobViewSubManageUnits.forEach((element) => {
        this.jobEditSubManagementUnitSelectedItems = this.jobEditSubManagementUnitSelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverJobConfigurationDto.OperatingUnits !=null){
      this.userConfig.userApproverJobConfigurationDto.OperatingUnits.forEach((element) => {
        this.jobEditOperatingUnitSelectedItems = this.jobEditOperatingUnitSelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverJobConfigurationDto.JobViewJobCategories !=null){
      this.userConfig.userApproverJobConfigurationDto.JobViewJobCategories.forEach((element) => {
        this.jobEditJobCategorySelectedItems = this.jobEditJobCategorySelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverJobConfigurationDto.JobViewJobTypes !=null){
      this.userConfig.userApproverJobConfigurationDto.JobViewJobTypes.forEach((element) => {
        this.jobEditJobCodeSelectedItems = this.jobEditJobCodeSelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    this.resEditLink = this.userConfig.UA_R_Link;
    this.jobEditLink = this.userConfig.UA_J_Link;
    this.spinner.hide();
  }
  makeDirty() {
    if (!this.dirtyFlag) {
      this.dirtyFlag = true;
    }
  }


  ClearAllSelectdVals(){
    this.dirtyFlag=true;
    this.resEditLink = this.userConfig?.UA_R_Link;
    this.jobEditLink = this.userConfig?.UA_J_Link;
    this.resEditManageCountrySelectedItems = [];
    this.resEditLegalEntitySelectedItems = [];
    this.resEditBusinessUnitSelectedItems = [];
    this.resEditLocationSelectedItems = [];
    this.resEditServiceLineSelectedItems = [];
    this.resEditSubServiceLineSelectedItems = [];
    this.resEditManagementUnitSelectedItems = [];
    this.resEditSubManagementUnitSelectedItems = [];
    this.resEditRankSelectedItems = [];
    this.resEditOperatingUnitSelectedItems = [];
    this.resEditDepartmentSelectedItems = [];
    this.resEditGdsRegionAlignmentSelectedItems = [];
    this.resEditCostCenterSelectedItems = [];
    this.jobEditManageCountrySelectedItems = [];
    this.jobEditBusinessUnitSelectedItems = [];
    this.jobEditLegalEntitySelectedItems = [];
    this.jobEditServiceLineSelectedItems = [];
    this.jobEditSubServiceLineSelectedItems = [];
    this.jobEditManagementUnitSelectedItems = [];
    this.jobEditSubManagementUnitSelectedItems = [];
    this.jobEditOperatingUnitSelectedItems = [];
    this.jobEditJobCategorySelectedItems = [];
    this.jobEditJobCodeSelectedItems = [];
  }

  clearForm() {
    this.resEditLink = this.userConfig.UA_R_Link;
    this.jobEditLink = this.userConfig.UA_J_Link;
    this.resEditManageCountrySelectedItems = [];
    this.resEditLegalEntitySelectedItems = [];
    this.resEditBusinessUnitSelectedItems = [];
    this.resEditLocationSelectedItems = [];
    this.resEditServiceLineSelectedItems = [];
    this.resEditSubServiceLineSelectedItems = [];
    this.resEditManagementUnitSelectedItems = [];
    this.resEditSubManagementUnitSelectedItems = [];
    this.resEditRankSelectedItems = [];
    this.resEditOperatingUnitSelectedItems = [];
    this.resEditDepartmentSelectedItems = [];
    this.resEditGdsRegionAlignmentSelectedItems = [];
    this.resEditCostCenterSelectedItems = [];
    this.jobEditManageCountrySelectedItems = [];
    this.jobEditBusinessUnitSelectedItems = [];
    this.jobEditLegalEntitySelectedItems = [];
    this.jobEditServiceLineSelectedItems = [];
    this.jobEditSubServiceLineSelectedItems = [];
    this.jobEditManagementUnitSelectedItems = [];
    this.jobEditSubManagementUnitSelectedItems = [];
    this.jobEditOperatingUnitSelectedItems = [];
    this.jobEditJobCategorySelectedItems = [];
    this.jobEditJobCodeSelectedItems = [];
    if(this.userConfig.userApproverResConfigurationDto.ResViewManagerialCountries !=null){
      this.userConfig.userApproverResConfigurationDto.ResViewManagerialCountries.forEach((element) => {
        this.resEditManageCountrySelectedItems = this.resEditManageCountrySelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverResConfigurationDto.ResViewLegalEntities !=null){
      this.userConfig.userApproverResConfigurationDto.ResViewLegalEntities.forEach((element) => {
        this.resEditLegalEntitySelectedItems = this.resEditLegalEntitySelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverResConfigurationDto.ResViewBusinessUnits !=null){
      this.userConfig.userApproverResConfigurationDto.ResViewBusinessUnits.forEach((element) => {
        this.resEditBusinessUnitSelectedItems = this.resEditBusinessUnitSelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverResConfigurationDto.ResViewOfficeLocations !=null){
      this.userConfig.userApproverResConfigurationDto.ResViewOfficeLocations.forEach((element) => {
        this.resEditLocationSelectedItems = this.resEditLocationSelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverResConfigurationDto.ResViewServiceLines !=null){
      this.userConfig.userApproverResConfigurationDto.ResViewServiceLines.forEach((element) => {
        this.resEditServiceLineSelectedItems = this.resEditServiceLineSelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverResConfigurationDto.ResViewSubServiceLines !=null){
      this.userConfig.userApproverResConfigurationDto.ResViewSubServiceLines.forEach((element) => {
        this.resEditSubServiceLineSelectedItems = this.resEditSubServiceLineSelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverResConfigurationDto.ResViewManagementUnits !=null){
      this.userConfig.userApproverResConfigurationDto.ResViewManagementUnits.forEach((element) => {
        this.resEditManagementUnitSelectedItems = this.resEditManagementUnitSelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverResConfigurationDto.ResViewSubManageUnits !=null){
      this.userConfig.userApproverResConfigurationDto.ResViewSubManageUnits.forEach((element) => {
        this.resEditSubManagementUnitSelectedItems = this.resEditSubManagementUnitSelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverResConfigurationDto.ResViewRanks !=null){
      this.userConfig.userApproverResConfigurationDto.ResViewRanks.forEach((element) => {
        this.resEditRankSelectedItems = this.resEditRankSelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverResConfigurationDto.ResViewOperatingUnits !=null){
      this.userConfig.userApproverResConfigurationDto.ResViewOperatingUnits.forEach((element) => {
        this.resEditOperatingUnitSelectedItems = this.resEditOperatingUnitSelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverResConfigurationDto.ResViewDepartments !=null){
      this.userConfig.userApproverResConfigurationDto.ResViewDepartments.forEach((element) => {
        this.resEditDepartmentSelectedItems = this.resEditDepartmentSelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverResConfigurationDto.ResViewGDSRegionAlignments !=null){
      this.userConfig.userApproverResConfigurationDto.ResViewGDSRegionAlignments.forEach((element) => {
        this.resEditGdsRegionAlignmentSelectedItems = this.resEditGdsRegionAlignmentSelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverResConfigurationDto.ResViewResourceCostCenters !=null){
      this.userConfig.userApproverResConfigurationDto.ResViewResourceCostCenters.forEach((element) => {
        this.resEditCostCenterSelectedItems = this.resEditCostCenterSelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverJobConfigurationDto.JobViewManagerialCountries !=null){
      this.userConfig.userApproverJobConfigurationDto.JobViewManagerialCountries.forEach((element) => {
        this.jobEditManageCountrySelectedItems = this.jobEditManageCountrySelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverJobConfigurationDto.JobViewBusinessUnits !=null){
      this.userConfig.userApproverJobConfigurationDto.JobViewBusinessUnits.forEach((element) => {
        this.jobEditBusinessUnitSelectedItems = this.jobEditBusinessUnitSelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverJobConfigurationDto.JobViewLegalEntities !=null){
      this.userConfig.userApproverJobConfigurationDto.JobViewLegalEntities.forEach((element) => {
        this.jobEditLegalEntitySelectedItems = this.jobEditLegalEntitySelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverJobConfigurationDto.JobViewServiceLines !=null){
      this.userConfig.userApproverJobConfigurationDto.JobViewServiceLines.forEach((element) => {
        this.jobEditServiceLineSelectedItems = this.jobEditServiceLineSelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverJobConfigurationDto.JobViewSubServiceLines !=null){
      this.userConfig.userApproverJobConfigurationDto.JobViewSubServiceLines.forEach((element) => {
        this.jobEditSubServiceLineSelectedItems = this.jobEditSubServiceLineSelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverJobConfigurationDto.ManagementUnits !=null){
      this.userConfig.userApproverJobConfigurationDto.ManagementUnits.forEach((element) => {
        this.jobEditManagementUnitSelectedItems = this.jobEditManagementUnitSelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverJobConfigurationDto.JobViewSubManageUnits !=null){
      this.userConfig.userApproverJobConfigurationDto.JobViewSubManageUnits.forEach((element) => {
        this.jobEditSubManagementUnitSelectedItems = this.jobEditSubManagementUnitSelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverJobConfigurationDto.OperatingUnits !=null){
      this.userConfig.userApproverJobConfigurationDto.OperatingUnits.forEach((element) => {
        this.jobEditOperatingUnitSelectedItems = this.jobEditOperatingUnitSelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverJobConfigurationDto.JobViewJobCategories !=null){
      this.userConfig.userApproverJobConfigurationDto.JobViewJobCategories.forEach((element) => {
        this.jobEditJobCategorySelectedItems = this.jobEditJobCategorySelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    if(this.userConfig.userApproverJobConfigurationDto.JobViewJobTypes !=null){
      this.userConfig.userApproverJobConfigurationDto.JobViewJobTypes.forEach((element) => {
        this.jobEditJobCodeSelectedItems = this.jobEditJobCodeSelectedItems.concat({
          Id: element.ID,
          Text: element.Text,
        });
      })
    }
    this.SaveErrorMessage = '';
    this.dirtyFlag = false;
    
  }
  onSubmit() {
    if (!this.dirtyFlag) {
      window.scroll(0, 0);
      return;
    }
    this.spinner.show();
    this.buttonText = 'Please wait..';
    //this.config = new ConfigureVM();
    let resViewDto = new ResourceConfigurationDto();
    let jobViewDto = new JobConfigurationDto();
    this.config.UA_J_Link = this.jobEditLink;
    this.config.UA_R_Link = this.resEditLink;
    // this.config.UA_CreatedBy = +sessionStorage.getItem('loggedin_resourceid');
    resViewDto.ResViewManagerialCountries = this.resEditManageCountrySelectedItems;
    resViewDto.ResViewBusinessUnits = this.resEditBusinessUnitSelectedItems;
    resViewDto.ResViewLegalEntities = this.resEditLegalEntitySelectedItems;
    resViewDto.ResViewOfficeLocations = this.resEditLocationSelectedItems;
    resViewDto.ResViewServiceLines = this.resEditServiceLineSelectedItems;
    resViewDto.ResViewSubServiceLines = this.resEditSubServiceLineSelectedItems;
    resViewDto.ResViewManagementUnits = this.resEditManagementUnitSelectedItems;
    resViewDto.ResViewSubManageUnits = this.resEditSubManagementUnitSelectedItems;
    resViewDto.ResViewOperatingUnits = this.resEditOperatingUnitSelectedItems;
    resViewDto.ResViewRanks = this.resEditRankSelectedItems;
    resViewDto.ResViewDepartments = this.resEditDepartmentSelectedItems;
    resViewDto.ResViewGDSRegionAlignments = this.resEditGdsRegionAlignmentSelectedItems;
    resViewDto.ResViewResourceCostCenters = this.resEditCostCenterSelectedItems;
    jobViewDto.JobViewManagerialCountries = this.jobEditManageCountrySelectedItems;
    jobViewDto.JobViewBusinessUnits = this.jobEditBusinessUnitSelectedItems;
    jobViewDto.JobViewLegalEntities = this.jobEditLegalEntitySelectedItems;
    jobViewDto.JobViewServiceLines = this.jobEditServiceLineSelectedItems;
    jobViewDto.JobViewSubServiceLines = this.jobEditSubServiceLineSelectedItems;
    jobViewDto.ManagementUnits = this.jobEditManagementUnitSelectedItems;
    jobViewDto.JobViewSubManageUnits = this.jobEditSubManagementUnitSelectedItems;
    jobViewDto.OperatingUnits = this.jobEditOperatingUnitSelectedItems;
    jobViewDto.JobViewJobCategories = this.jobEditJobCategorySelectedItems;
    jobViewDto.JobViewJobTypes = this.jobEditJobCodeSelectedItems;

    this.config.userApproverJobConfigurationDto = jobViewDto;
    this.config.userApproverResConfigurationDto = resViewDto;

    this.restService.saveApprovalQueueConfig(this.config).subscribe(
      (data) => {
        if (data != null) {
          if (data.customResultId <= 0) {
            this.SaveErrorMessage = data.customException;
            this.customResultId = data.customResultId;
            window.scroll(0, 0);
          } else {
            this.config.UA_ID = data.customResultId;
            this.buttonText = 'Applied';
            this.customResultId = null;
            this.config.Message = 'Approval Queue configuration submitted successfully';
            this.restService.closeModal(true);
          }
          window.scroll(0, 0);
        }
      },
      (err) => {
        this.buttonText = 'Error';
      },
      () => {
        this.spinner.hide();
        setTimeout(() => {
          this.buttonText = 'Apply';
          setTimeout(() => {
            this.SaveErrorMessage = '';
          }, 5000);
        }, 2000);
      }
    );
  }
}
