import { MasterVM } from "./MasterVM";

export class ResourceReplaceAllConfigurationDto {
       ResReplaceAllSubAreas :MasterVM[];
       ResReplaceAllManagerialCountries :MasterVM[];
       ResReplaceAllLegalEntities:MasterVM[];
       ResReplaceAllServiceLines :MasterVM[];
       ResReplaceAllBusinessUnits:MasterVM[];
       ResReplaceAllSubServiceLines :MasterVM[];
       ResReplaceAllSubManageUnits :MasterVM[]
       ResReplaceAllRanks :MasterVM[];
       RRRAC_RInactive:boolean;
       RRRAC_ISDisabled :boolean;
       RRRAC_ISLikeEdit :boolean;
       RRRAC_IV_Disabled :boolean;
       RRRAC_EV_Disabled :boolean;

}

export class JobReplaceAllConfigurationDto {
       JobReplaceAllSubAreas :MasterVM[];
       JobReplaceAllManagerialCountries :MasterVM[];
       JobReplaceAllLegalEntities:MasterVM[];
       JobReplaceAllServiceLines :MasterVM[];
       JobReplaceAllBusinessUnits:MasterVM[];
       JobReplaceAllSubServiceLines :MasterVM[];
       JobReplaceAllSubManageUnits :MasterVM[]
       JobReplaceAllRanks :MasterVM[];
       JobReplaceAllJobCategories:MasterVM[];
       JobReplaceAllJobStatuses :MasterVM[];
       JobReplaceAllJobStatusBookings :MasterVM[]
       JobReplaceAllJobCode :MasterVM[];
       JobReplaceAllIncludeJobCode
       RJRAC_Learning :boolean;
       RJRAC_ISDisabled :boolean;
       RJRAC_ISLikeEdit:boolean;
       RJRAC_IsPended:boolean;
       RJRAC_IV_Disabled:boolean;
       RJRAC_EV_Disabled:boolean;

}