<ngx-spinner></ngx-spinner>

<div class="appLayout engagement-booking">
  <div class="engagementScheduleSearchWrapper collapsibleWrapper">
    <section class="searchContainerHeading" (click)="toggleCollapse()">
      <span class="icon" [ngClass]="isCollapsed ? 'collapsed' : ''"></span>
      <h2 class="title">
        <label>{{ searchLabel }}</label>
        <span [ngClass]="EngagementTitle ? 'has-content' : ''">{{
          EngagementTitle ? EngagementTitle : ""
        }}</span>
      </h2>
    </section>
    <div class="searchContainer" *ngIf="!isCollapsed">
      <div class="searchFields">
        <div
          *ngIf="user.IsMultiInstance && user.R_InstEnable_EV"
          class="instanceContainer searchItem"
        >
          <label>Retain Instance</label>
          <mat-form-field appearance="outline">
            <mat-select
              [(value)]="selectedInstance"
              (selectionChange)="onInstanceChange($event)"
              placeholder="Retain instance"
            >
              <mat-option
                [value]="option.InstanceId"
                *ngFor="let option of instances"
                >{{ option.InstanceName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="searchBox searchItem">
          <label>Engagement</label>
          <mat-form-field class="resourceSearch" appearance="outline">
            <input
              type="text"
              placeholder="Search..."
              matInput
              appSearchDebounce
              (click)="onEngagementClick()"
              (debounceKeyup)="search()"
              [(ngModel)]="searchBar"
              autocomplete="off"
              maxlength="200"
            />
            <mat-icon (click)="resetfilterBookingGridSearchBar()"
              >close</mat-icon
            >
          </mat-form-field>

          <!-- for Favourite  Engagement (click)="onFavEngagementSearch(item)"--- Start-->
          <div class="dropdown-list-wrap" *ngIf="showFavouriteEngList">
            <mat-spinner *ngIf="showSearchLoader" [diameter]="40"></mat-spinner>
            <ul
              *ngIf="addFavouriteEngResults?.length > 0"
              class="dropdown-list tabdropdwn_actn"
            >
              <li><span class="favouriteText">Favourites</span></li>
              <li
                *ngFor="let item of addFavouriteEngResults"
                (click)="onFavEngagementSearch(item)"
                class="dropdown-list-item"
                tabindex="0"
              >
                <span class="EngFullname"> {{ item.EngagementFullName }} </span>
                <img
                  #Favimage1
                  (click)="onFavoriteImageClick(item)"
                  class="exportExcelIcon favouriteIcon"
                  src="../../../../{{ imagePath }}{{ item.favEngSrc }}"
                />
              </li>
            </ul>
            <div
              *ngIf="
                showSearchLoader === false &&
                addFavouriteEngResults?.length === 0 &&
                IsDataNotFound === true
              "
            >
              No Engagement Found
            </div>
          </div>
          <!-- for Favourite  Engagement --- End-->

          <div class="dropdown-list-wrap" *ngIf="showSearchList">
            <mat-spinner *ngIf="showSearchLoader" [diameter]="40"></mat-spinner>
            <ul *ngIf="showSearchList" class="dropdown-list tabdropdwn">
              <li
                *ngFor="let item of results; let i = index"
                (click)="OnItemSelect(item)"
                (keyup.enter)="OnItemSelect(item)"
                class="dropdown-list-item"
                tabindex="0"
              >
                <span class="EngFullname"> {{ item.EngagementFullName }} </span>

                <img
                  #Favimage1
                  (click)="onFavoriteImageClick(item)"
                  class="exportExcelIcon favouriteIcon"
                  src="../../../../{{ imagePath }}{{ item.favEngSrc }}"
                />
              </li>
            </ul>
            <div *ngIf="showSearchLoader === false && results.length === 0">
              No Engagement Found
            </div>
          </div>
        </div>

        <div class="dateSearch searchItem">
          <label>Period</label>
          <form [formGroup]="range">
            <mat-form-field appearance="outline">
              <mat-date-range-input [rangePicker]="picker" [min]="startWith">
                <input
                  matStartDate
                  formControlName="start"
                  placeholder="Select Start Date"
                  (click)="picker.open()"
                  (dateChange)="onBookingStartDateChange($event)"
                  readonly
                />-
                <input
                  matEndDate
                  formControlName="end"
                  placeholder="End Date"
                  (click)="picker.open()"
                  (dateChange)="onBookingEndDateChange($event)"
                  readonly
                />
              </mat-date-range-input>
              <mat-icon (click)="resetfilterBookingGridCalender($event)"
                >close</mat-icon
              >
              <!-- <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle> -->
              <mat-date-range-picker
                (closed)="OnClosedDatePicker()"
                #picker
              ></mat-date-range-picker>
              <mat-error
                *ngIf="range.controls.start.hasError('matStartDateInvalid')"
                >Invalid start date</mat-error
              >
              <mat-error
                *ngIf="range.controls.end.hasError('matEndDateInvalid')"
                >Invalid end date</mat-error
              >
            </mat-form-field>
            <div
              *ngIf="
                range.get('start').value != null &&
                (range.get('start').dirty || range.get('start').touched) &&
                range.get('end').value === null &&
                (range.get('end').dirty || range.get('end').touched) &&
                isPeriodErrorMsg
              "
              class="alert alert-danger"
            >
              End date is required
            </div>
          </form>
        </div>
        <div class="expansionPreference searchItem">
          <mat-radio-group
            aria-label="preferenceOption"
            class="expansionRadio"
            [(ngModel)]="engagementAllocationModal"
            (change)="engagementAllocationOnChange($event)"
          >
            <mat-radio-button value="hour" class="prefernceRadio"
              >Hour</mat-radio-button
            >
            <mat-radio-button value="percentage" class="prefernceRadio"
              >Percentage</mat-radio-button
            >
          </mat-radio-group>
        </div>

        <!-- <div class="serchButtonDiv">
        <div class="searchButton" (click)="filterBookingGrid()">Search</div>
      </div> -->
      </div>
      <div class="resourceField">
        <div class="resourceTitle">
          Engagement Forecast
          <span
            class="booking_heading_info_icon"
            (click)="openModal(engagementForecastInfo, $event)"
            matTooltip="Info"
          >
            <img
              src="../../../../{{ imagePath }}TableIcons/info-yellow.svg"
              alt="Info"
            />
          </span>
        </div>
        <div class="resourceDetails">
          <div class="resourceInfo">
            <div class="name">
              {{
                EngagementTitle && EngagementTitle != "" ? EngagementTitle : ""
              }}
            </div>
            <div class="serviceLine">
              {{
                selectedItem && selectedItem.ServiceLineDescription != ""
                  ? selectedItem.ServiceLineDescription +
                    " - " +
                    selectedItem.ManagerialCountryDescription
                  : ""
              }}
            </div>
            <div class="costCenter">
              {{
                selectedItem && selectedItem.CostCenterDescription != ""
                  ? selectedItem.CostCenterDescription
                  : ""
              }}
            </div>
            <div class="partnerResourceName">
              {{
                selectedItem && selectedItem.PartnerName != ""
                  ? "Eng. Partner: " + selectedItem.PartnerName
                  : ""
              }}
            </div>
            <div class="managerResourceName">
              {{
                selectedItem && selectedItem.ManagerNameAndGPN != ""
                  ? "Eng. Manager: " + selectedItem.ManagerNameAndGPN
                  : ""
              }}
            </div>
            <div class="instanceName">
              {{
                selectedItem && selectedItem.InstanceName != ""
                  ? "Retain Instance: " + selectedItem.InstanceName
                  : ""
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bookingContainer">
    <div class="bookingHeader">
      <div class="heading sectionHeading">
        <span>Bookings</span>
        <!-- <mat-icon (click)="openModal(template1)" class="questionIcon" matTooltip="Info">info</mat-icon> -->
        <span
          class="booking_heading_info_icon"
          (click)="openModal(template1, $event)"
          matTooltip="Info"
        >
          <img
            src="../../../../{{ imagePath }}TableIcons/info-yellow.svg"
            alt="Info"
          />
        </span>
      </div>
      <div class="bookingNew">
        <!-- <div *ngIf="isNavFromApprovalQueue && !isMobileDevice" class="navApprovalQueue" matTooltip="Back to Approval Queue">
          <mat-icon class="arrowBack">arrow_back</mat-icon> -->
        <a
          *ngIf="isNavFromApprovalQueue && !isMobileDevice"
          class="approvalQueueBtn"
          matTooltip="Back to Approval Queue"
          routerLink="/ApprovalLayer"
        >
          <mat-icon class="arrowBack">arrow_back</mat-icon>
          <span class="linkText">Approval Queue</span>
        </a>
        <!-- </div> -->

        <mat-icon
          *ngIf="
            !IsDisabledReplaceAllFlag &&
            bookingListCount > 0 &&
            editReplaceAll === 0
          "
          (click)="openActionModal(GridActions, $event, null, 'replaceallEng')"
          matTooltip="Replace All Engagement"
        >
          find_replace</mat-icon
        >

        <div
          class="newBooking"
          *ngIf="engagementSelected && !IsDisabledAddBooking"
          (click)="openActionModal(GridActions, $event, null, 'add')"
          matTooltip="Add"
        >
          New Booking
        </div>
      </div>
    </div>
    <div class="materialTable">
      <table
        mat-table
        [dataSource]="bookingList"
        multiTemplateDataRows
        class="mat-elevation-z8 bookingTable"
      >
        <ng-container matColumnDef="child">
          <th mat-header-cell *matHeaderCellDef class="mat-column-child"></th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.ChildRowsExist">
              <mat-icon
                class="expandTableIcon"
                *ngIf="!element.isExpandable"
                (click)="element.isExpandable = !element.isExpandable"
                matTooltip="Expand details"
                >add</mat-icon
              >
              <mat-icon
                class="expandTableIcon"
                *ngIf="element.isExpandable"
                (click)="element.isExpandable = !element.isExpandable"
                matTooltip="Collapse details"
                >remove</mat-icon
              >
            </span>
            <!-- <mat-icon class="expandTableIcon" style="color: #B9251C;" *ngIf="element.ActionType === 'New Booking' && currentUserEmail?.toLowerCase() === element.RequestorEmail?.toLowerCase()"
             (click)="openActionModal(GridActions, $event, element, 'delete')" matTooltip="Cancel"
             >close</mat-icon> -->
          </td>
        </ng-container>
        <ng-container matColumnDef="ResourceName">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="mat-column-ResourceName mat-column-child-header"
          >
            Resource Name
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            data-title="Resource Name :"
            (mouseover)="selectItem(element)"
            (click)="selectItem(element)"
            [popover]="element.TooltipData.ActionType ? tooltipTemplate : null"
            [placement]="'right'"
            [outsideClick]="true"
            [triggers]="'mouseover click'"
            #pop="bs-popover"
            (onShown)="closePrevious(pop)"
            (mouseleave)="closeCurrent(pop)"
          >
            <div
              class="navigationlink"
              [ngClass]="{
                disableNavigation: element.IsDisableViewBooking_Res === true
              }"
              (click)="navigateIndividual(element)"
              [matTooltip]="
                element.IsDisableViewBooking_Res === false
                  ? 'Click here to see individual details'
                  : null
              "
            >
              {{ element.ResourceName }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="Instance">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="mat-column-Instance mat-column-child-header"
          >
            Retain Instance
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            data-title="Retain Instance :"
            (mouseover)="selectItem(element)"
            (click)="selectItem(element)"
            [popover]="element.TooltipData.ActionType ? tooltipTemplate : null"
            [placement]="'right'"
            [outsideClick]="true"
            [triggers]="'mouseover click'"
            #pop="bs-popover"
            (onShown)="closePrevious(pop)"
            (mouseleave)="closeCurrent(pop)"
          >
            {{ element.InstanceName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="ServiceLine">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="mat-column-ServiceLine mat-column-child-header"
          >
            Service Line
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            data-title="Service Line :"
            (mouseover)="selectItem(element)"
            (click)="selectItem(element)"
            [popover]="element.TooltipData.ActionType ? tooltipTemplate : null"
            [placement]="'right'"
            [outsideClick]="true"
            [triggers]="'mouseover click'"
            #pop="bs-popover"
            (onShown)="closePrevious(pop)"
            (mouseleave)="closeCurrent(pop)"
          >
            {{ element.ServiceLineDescription }}
          </td>
        </ng-container>
        <ng-container matColumnDef="OfficeLocation">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="mat-column-OfficeLocation mat-column-child-header"
          >
            Office Location
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            data-title="Office Location :"
            (mouseover)="selectItem(element)"
            (click)="selectItem(element)"
            [popover]="element.TooltipData.ActionType ? tooltipTemplate : null"
            [placement]="'right'"
            [outsideClick]="true"
            [triggers]="'mouseover click'"
            #pop="bs-popover"
            (onShown)="closePrevious(pop)"
            (mouseleave)="closeCurrent(pop)"
          >
            {{ element.LocationDescription }}
          </td>
        </ng-container>
        <ng-container matColumnDef="Rank">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="mat-column-Rank mat-column-child-header"
          >
            Rank
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            data-title="Rank :"
            (mouseover)="selectItem(element)"
            (click)="selectItem(element)"
            [popover]="element.TooltipData.ActionType ? tooltipTemplate : null"
            [placement]="'right'"
            [outsideClick]="true"
            [triggers]="'mouseover click'"
            #pop="bs-popover"
            (onShown)="closePrevious(pop)"
            (mouseleave)="closeCurrent(pop)"
          >
            {{ element.RankDescription }}
          </td>
        </ng-container>
        <ng-container matColumnDef="Hours">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="mat-column-Hours mat-column-child-header"
          >
            Hours
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            data-title="Hours :"
            (mouseover)="selectItem(element)"
            (click)="selectItem(element)"
            [popover]="element.TooltipData.ActionType ? tooltipTemplate : null"
            [placement]="'right'"
            [outsideClick]="true"
            [triggers]="'mouseover click'"
            #pop="bs-popover"
            (onShown)="closePrevious(pop)"
            (mouseleave)="closeCurrent(pop)"
          >
            {{ element.Hours }}
          </td>
        </ng-container>
        <ng-container matColumnDef="Loading">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="mat-column-Loading mat-column-child-header"
          >
            Loading %
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            data-title="Loading % :"
            (mouseover)="selectItem(element)"
            (click)="selectItem(element)"
            [popover]="element.TooltipData.ActionType ? tooltipTemplate : null"
            [placement]="'right'"
            [outsideClick]="true"
            [triggers]="'mouseover click'"
            #pop="bs-popover"
            (onShown)="closePrevious(pop)"
            (mouseleave)="closeCurrent(pop)"
          >
            {{ element.Loading }}
          </td>
        </ng-container>
        <ng-container matColumnDef="StartDate">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="mat-column-StartDate mat-column-child-header"
          >
            Start Date
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            data-title="Start Date :"
            (mouseover)="selectItem(element)"
            (click)="selectItem(element)"
            [popover]="element.TooltipData.ActionType ? tooltipTemplate : null"
            [placement]="'right'"
            [outsideClick]="true"
            [triggers]="'mouseover click'"
            #pop="bs-popover"
            (onShown)="closePrevious(pop)"
            (mouseleave)="closeCurrent(pop)"
          >
            {{ element.StartDate | date: defaultDateFormat }}
          </td>
        </ng-container>
        <ng-container matColumnDef="EndDate">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="mat-column-EndDate mat-column-child-header"
          >
            End Date
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            data-title="End Date :"
            (mouseover)="selectItem(element)"
            (click)="selectItem(element)"
            [popover]="element.TooltipData.ActionType ? tooltipTemplate : null"
            [placement]="'right'"
            [outsideClick]="true"
            [triggers]="'mouseover click'"
            #pop="bs-popover"
            (onShown)="closePrevious(pop)"
            (mouseleave)="closeCurrent(pop)"
          >
            {{ element.EndDate | date: defaultDateFormat }}
          </td>
        </ng-container>
        <ng-container matColumnDef="Actions">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="mat-column-Actions mat-column-child-header"
          >
            Actions
          </th>
          <td mat-cell *matCellDef="let element" class="actionColumn">
            <span
              class="tableActionIcon edit"
              *ngIf="element.BookingId != 0 && !element.IsDisabledEditBooking"
              (click)="
                !element.ChildRowsExist
                  ? openActionModal(GridActions, $event, element, 'edit')
                  : openActionModal(GridActions, $event, element, 'parentEdit')
              "
              matTooltip="Edit"
            >
            </span>

            <!-- <mat-icon
              class="tableIcon"
              *ngIf="
                ((!element.ChildRowsExist && !element.BookingId == 0) ||
                  element.ChildRowsExist) &&
                !element.IsDisabledReplaceBooking
              "
              (click)="openActionModal(GridActions, $event, element, 'swap')"
              matTooltip="Replace"
              >find_replace</mat-icon -->

            <span
              class="tableActionIcon replace"
              *ngIf="
                ((!element.ChildRowsExist && element.BookingId != 0) ||
                  (element.ChildRowsExist &&
                    element.ActionDescriptionId != 1)) &&
                !element.IsDisabledReplaceBooking
              "
              (click)="openActionModal(GridActions, $event, element, 'swap')"
              matTooltip="Replace"
            >
            </span>
            <!-- <mat-icon
              class="tableIcon"
              *ngIf="
                !element.ChildRowsExist &&
                !element.BookingId == 0 &&
                !element.IsDisabledCopyBooking
              "
              (click)="openActionModal(GridActions, $event, element, 'split')"
              matTooltip="Copy"
              >content_copy</mat-icon
            > -->
            <span
              class="tableActionIcon copy"
              *ngIf="
                !element.ChildRowsExist &&
                element.BookingId != 0 &&
                !element.IsDisabledCopyBooking
              "
              (click)="openActionModal(GridActions, $event, element, 'split')"
              matTooltip="Copy"
            >
            </span>
            <!-- <mat-icon
              class="tableIcon"
              [ngClass]="{ disabledAction: element.IsDisabledDeleteBooking }"
              *ngIf="!element.ChildRowsExist && !element.BookingId == 0 && editDelete === 0"
              (click)="openActionModal(GridActions, $event, element, 'delete')" matTooltip="Delete"
              >delete</mat-icon
            >
            <mat-icon
              class="tableIcon"
              [ngClass]="{ disabledAction: element.IsDisabledDeleteBooking }"
              *ngIf="element.ChildRowsExist && editDelete === 0"
              (click)="openActionModal(GridActions, $event, element, 'deleteparent')" matTooltip="Delete"
              >delete</mat-icon
            > -->
            <!-- <mat-icon
              class="tableIcon"
              *ngIf="
                !element.BookingId == 0 && !element.IsDisabledDeleteBooking
              "
              (click)="
                !element.ChildRowsExist
                  ? openActionModal(GridActions, $event, element, 'delete')
                  : openActionModal(
                      GridActions,
                      $event,
                      element,
                      'deleteparent'
                    )
              "
              matTooltip="Delete"
              >delete</mat-icon
            > -->
            <span
              class="tableActionIcon delete"
              *ngIf="element.BookingId != 0 && !element.IsDisabledDeleteBooking"
              (click)="
                !element.ChildRowsExist
                  ? openActionModal(GridActions, $event, element, 'delete')
                  : openActionModal(
                      GridActions,
                      $event,
                      element,
                      'deleteparent'
                    )
              "
              matTooltip="Delete"
            >
            </span>

            <mat-icon
              class="tableIcon"
              style="color: #b9251c"
              *ngIf="element.Cancelable"
              (click)="
                openActionModal(GridActions, $event, element, 'cancelAction')
              "
              matTooltip="Cancel"
              >close</mat-icon
            >

            <span
              *ngIf="
                element.BookingId > 0 &&
                element.ChildRowsExist &&
                element.IsDisabledEditBooking &&
                element.IsDisabledDeleteBooking &&
                element.IsDisabledReplaceBooking &&
                element.IsDisabledCopyBooking &&
                element.HasRetainBookingChild &&
                (!element.JobCode || element.JobCode.indexOf('TR') <= -1)
              "
              class="booking_heading_info_icon error"
              matTooltip="Submissions are not supported on this booking.&#013;For any other updates, please follow the country specific process."
            >
              <mat-icon style="color: #b9251c; font-size: 16px">error</mat-icon>
            </span>

            <span
              *ngIf="
                !element.ChildRowsExist &&
                element.BookingId != 0 &&
                element.IsCurrentEditWeek == false &&
                (element.JobCode == null ||
                  element.JobCode.indexOf('TR') <= -1) &&
                element.IsDisabledEditBooking &&
                element.IsDisabledDeleteBooking &&
                element.IsDisabledCopyBooking &&
                element.IsDisabledReplaceBooking &&
                !element.IsReplaced &&
                !element.IsReplacedEngagement &&
                element.HasRetainBookingChild
              "
              class="booking_heading_info_icon error"
              matTooltip="Submissions are not supported on this booking.&#013;For any other updates, please follow the country specific process."
            >
              <mat-icon style="color: #b9251c; font-size: 16px">error</mat-icon>
            </span>
            <span
              *ngIf="
                element.BookingId != 0 &&
                element.IsCurrentEditWeek == false &&
                element.JobCode != null &&
                element.JobCode.indexOf('TR') > -1 &&
                element.IsDisabledEditBooking &&
                element.IsDisabledDeleteBooking &&
                element.IsDisabledCopyBooking &&
                element.IsDisabledReplaceBooking &&
                !element.IsReplaced &&
                !element.IsReplacedEngagement &&
                element.HasRetainBookingChild
              "
              class="booking_heading_info_icon error"
              matTooltip="Please refer to Success factors for updates to any course bookings"
            >
              <mat-icon style="color: #b9251c; font-size: 16px">error</mat-icon>
            </span>
            <span
              *ngIf="
                !element.ChildRowsExist &&
                element.BookingId != 0 &&
                element.IsCurrentEditWeek == true &&
                element.IsDisabledEditBooking &&
                element.IsDisabledDeleteBooking &&
                element.IsDisabledReplaceBooking &&
                element.IsDisabledCopyBooking &&
                !element.IsReplaced &&
                element.HasRetainBookingChild &&
                (!element.JobCode || element.JobCode.indexOf('TR') <= -1)
              "
              class="booking_heading_info_icon error"
              matTooltip="Please contact your Resource or Experience Management team &#013;to make any changes in the current week"
            >
              <mat-icon style="color: #b9251c; font-size: 16px">error</mat-icon>
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="expandedDetail">
          <td
            mat-cell
            *matCellDef="let element"
            [attr.colspan]="displayedColumns.length"
          >
            <div
              class="child-element-detail"
              [@detailExpand]="element.isExpandable ? 'expanded' : 'collapsed'"
            >
              <app-engagement-booking-child
                [selectedInstanceId]="selectedInstance"
                [currentWeekActionDTO]="this.currentWeek"
                [isPercentageSelected]="isPercentageSelected"
                [bookingData]="element"
                [defaultDateFormat]="defaultDateFormat"
                *ngIf="element.ChildRowsExist && element.isExpandable"
                (GetAllBookings)="GetAllBookings()"
              >
              </app-engagement-booking-child>
            </div>
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns"
          class="table-heading"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns; let entry"
          [ngClass]="{
            highlight: entry.HighlightStatus,
            hideBorder: entry.isExpandable
          }"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: ['expandedDetail']"
          class="bookingChild-detail-row"
          [ngClass]="{ noBorder: !row.isExpandable }"
        ></tr>
      </table>
      <div *ngIf="bookingListCount === 0" class="noBookings">
        Bookings not found
      </div>
      <div *ngIf="bookingListCount === -1" class="noBookings">
        <mat-spinner [diameter]="20"></mat-spinner>
      </div>
    </div>
    <div class="bookingSave">
      <div
        class="saveBookings"
        *ngIf="user.PreviewSubmit"
        (click)="openActionModal(GridPreviewActions, $event, null, 'preview')"
      >
        <div class="saveCount">{{ count }}</div>
        Preview and Submit
      </div>
    </div>
  </div>
  <div class="wallchartContainer" (click)="isWallChart = !isWallChart">
    <div class="wallchartSection">
      <div class="wallChartHeader">
        <div class="loadWallchart">
          <mat-icon *ngIf="!isWallChart">expand_more</mat-icon>
          <mat-icon *ngIf="isWallChart">expand_less</mat-icon>
        </div>
        <div class="wallChartHeadInfo sectionHeading">
          <div class="bookingDiv">WallChart</div>
          <!-- <mat-icon class="questionIcon" matTooltip="Info" (click)="openModal(templateWallchart)">info</mat-icon> -->
          <span
            class="booking_heading_info_icon"
            (click)="openModal(templateWallchart, $event)"
            matTooltip="Info"
          >
            <img
              src="../../../../{{ imagePath }}TableIcons/info-yellow.svg"
              alt="Info"
            />
          </span>
        </div>
      </div>
      <div *ngIf="isWallChart">
        <app-engagement-wallchart
          [booking]="booking"
        ></app-engagement-wallchart>
      </div>
    </div>
  </div>
  <div class="extraHeight">.</div>
</div>

<ng-template #template1>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Bookings</h4>
    <mat-icon class="close" (click)="modalRef.hide()">close</mat-icon>
  </div>
  <div class="modal-body schedule-info-modal">
    <p>Use the action buttons to update your staffing forecast</p>
    <table class="info-icon-table">
      <tbody>
        <tr>
          <td class="textInfo">
            <img
              src="../../../../{{ imagePath }}TableIcons/edit-gray.svg"
              alt="Edit"
            />
          </td>
          <td class="iconData">
            Edit a booking. The fields like start date, end date, notes
            description etc can be allowed to edit
          </td>
        </tr>
        <tr>
          <td class="textInfo">
            <img
              src="../../../../{{ imagePath }}TableIcons/replace-gray.png"
              alt="Replace"
            />
          </td>
          <td class="iconData">
            Replace some or all the bookings from either one resource to another
            and/or one engagement to another
          </td>
        </tr>
        <tr>
          <td class="textInfo">
            <img
              src="../../../../{{ imagePath }}TableIcons/copy-gray.svg"
              alt="Copy"
            />
          </td>
          <td class="iconData">
            Copy the booking details from an existing booking and creating a new
            booking
          </td>
        </tr>
        <tr>
          <td class="textInfo">
            <img
              src="../../../../{{ imagePath }}TableIcons/delete-gray.svg"
              alt="Delete"
            />
          </td>
          <td class="iconData">Delete a booking</td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>

<ng-template #engagementForecastInfo>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Engagement Forecast</h4>
    <mat-icon class="close" (click)="modalRef.hide()">close</mat-icon>
  </div>
  <div class="modal-body">
    <div>
      <h6>The following engagement information is displayed:</h6>
    </div>
    <div class="iconSection text-margin">
      <span class="iconData"> &lt;Eng. Name&gt; </span>
    </div>
    <div class="iconSection text-margin">
      <span class="iconData">
        &lt;Eng. Serviceline&gt; - &lt;Eng. Country&gt;
      </span>
    </div>
    <div class="iconSection text-margin">
      <span class="iconData"> &lt;Eng. Costcenter Desc&gt; </span>
    </div>
    <div class="iconSection text-margin">
      <span class="iconData">
        Eng. Partner: &lt;Resource Name&gt; - &lt;GPN&gt;
      </span>
    </div>
    <div class="iconSection text-margin">
      <span class="iconData">
        Eng. Manager: &lt;Resource Name&gt; - &lt;GPN&gt;
      </span>
    </div>
    <div class="iconSection text-margin">
      <span class="iconData"> Retain Instance: &lt;Retain Instance&gt; </span>
    </div>
    <br />
    <span>
      The Retain Instance field will be visible only if your access role is
      enabled to access all Retain instances.
    </span>
  </div>
</ng-template>

<ng-template #GridActions>
  <ngx-spinner></ngx-spinner>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ gridActionModalHeader }}</h4>
    <mat-icon class="close" (click)="openConfirmationModal(EditConfirmation)"
      >close</mat-icon
    >
  </div>
  <div class="modal-body">
    <app-engagement-add
      [selectedInstanceId]="selectedInstance"
      [roleDelgtFlg]="roleDelgtFlg"
      *ngIf="showModalAdd"
      [currentWeekActionDTO]="this.currentWeek"
      (isFormDirtyFlag)="makeDirty($event)"
    >
    </app-engagement-add>
    <!-- Use the parent-edit component for single edit also -->
    <!-- <app-editEngagement *ngIf="showModal" (isFormDirtyFlag)="makeDirty($event)">
    </app-editEngagement> -->
    <app-parent-edit
      *ngIf="showModalParentEdit"
      [isPercentageSelected]="isPercentageSelected"
      [gridActionEventData]="gridActionEventData"
      [currentWeekActionDTO]="this.currentWeek"
      [parentViewIVorEV]="'EV'"
      (isFormDirtyFlag)="makeDirty($event)"
      [singleEdit]="!gridActionEventData.dataItem.ChildRowsExist"
    >
    </app-parent-edit>
    <app-swap-engagement
      *ngIf="showModelSwap"
      [currentWeekActionDTO]="this.currentWeek"
      (isFormDirtyFlag)="makeDirty($event)"
    >
    </app-swap-engagement>
    <app-split-Engagement
      *ngIf="showModalSplit"
      [currentWeekActionDTO]="this.currentWeek"
      (isFormDirtyFlag)="makeDirty($event)"
    >
    </app-split-Engagement>
    <!-- <app-delete-Engagement *ngIf="showModalDelete"></app-delete-Engagement> -->
    <app-delete
      *ngIf="showModalDelete"
      [actionEventData]="gridActionEventData"
      [currentWeekActionDTO]="this.currentWeek"
      [isPercentSelected]="isPercentageSelected"
    ></app-delete>
    <app-cancel-request
      *ngIf="showModalCancel"
      [actionEventData]="gridActionEventData"
      [uiViewSource]="'EV'"
    ></app-cancel-request>
    <app-delete-parent
      *ngIf="showDeleteParent"
      [isPercentSelected]="isPercentageSelected"
      [gridActionEventData]="gridActionEventData"
      [parentViewIVorEV]="'EV'"
      (isFormDirtyFlag)="makeDirty($event)"
    >
    </app-delete-parent>
    <app-replaceall-engagement
      *ngIf="showReplaceAllEng"
      [UiViewSource]="'EV'"
      [ParentselectedInstance]="selectedInstance"
      [currentWeekActionDTO]="this.currentWeek"
      (isFormDirtyFlag)="makeDirty($event)"
    >
    </app-replaceall-engagement>
  </div>
</ng-template>

<ng-template #EditConfirmation>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Confirm</h4>
  </div>
  <div class="modal-body">
    <div class="popupLayout">
      <div class="fullWidth">
        <div class="confirmationText">
          All the changes will be lost. Are you sure you want to close?<span
          ></span>
        </div>
      </div>
      <div class="confirmationButtons">
        <button class="cancelBtn" (click)="this.confirmModalRef.hide()">
          Cancel
        </button>
        <button class="deleteBtn" (click)="okayConfirm(EditConfirmation)">
          Yes
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #templateWallchart>
  <div class="modal-header">
    <h4 class="modal-title pull-left">WallChart</h4>
    <mat-icon (click)="modalRef.hide()" class="close">close</mat-icon>
  </div>
  <div class="modal-body">
    <div class="wallChartDescription">
      You can navigate through the engagement list in the wallchart by clicking
      on the left side of your mouse, and whilst holding it down drag the
      pointer up and down in the wallchart. Alternatively, scrolling up and down
      is possible for those using touch screens.
    </div>
    <br />
    <table class="wallChartInfoTable">
      <tr>
        <th>Engagement Category</th>
        <th>Confirmed</th>
        <th>Unconfirmed*</th>
      </tr>
      <tr>
        <td>Chargeable</td>
        <td><span class="colorRect blueLabel"></span></td>
        <td><span class="colorRect lightBlueLabel"></span></td>
      </tr>
      <tr>
        <td>Authorised Project</td>
        <td><span class="colorRect yellowLabel"></span></td>
        <td><span class="colorRect lightYellowLabel"></span></td>
      </tr>
      <tr>
        <td>Non-Chargeable</td>
        <td><span class="colorRect greenLabel"></span></td>
        <td><span class="colorRect lightGreenLabel"></span></td>
      </tr>
      <tr>
        <td>Training Courses from Success Factors</td>
        <td><span class="colorRect purpleLabel"></span></td>
        <td></td>
      </tr>
      <tr>
        <td>Bookings that are in Submitted/In-Progress state</td>
        <td><span class="colorRect greyLabel"></span></td>
        <td></td>
      </tr>
      <tr>
        <td>Requests that are in Pending Approval*</td>
        <td><span class="colorRect lightGreyLabel"></span></td>
        <td></td>
      </tr>
      <tr>
        <td>Ghost Booking*</td>
        <td><span class="colorRect whiteLabel"></span></td>
        <td><span class="colorRect whiteLabel"></span></td>
      </tr>
      <!-- <tr>
        <td>
          Overlap between processed request and pending request. Pending request
          yet to be processed.
        </td>
        <td><span class="ZigZagLabel"></span></td>
        <td></td>
      </tr> -->
    </table>
    <br />
    <div class="wallChartFooter">
      <p>Total hours = Sum of existing weekly bookings in Retain.</p>
      <p>
        *Unconfirmed, Ghosts and Pending Approval bookings will be visible based
        on your access role configuration
      </p>
      <!-- <p>* Unconfirmed field is not applicable for Americas and APAC.</p> -->
    </div>
  </div>
</ng-template>
<ng-template #tooltipTemplate>
  <div class="tableTooltip" *ngIf="selectedTooltipItem.ActionType?.length > 0">
    <!-- <label>{{
      selectedTooltipItem.ActionDescription != null
      ? "Action Description: " + selectedTooltipItem.ActionDescription
      : null
      }}</label> -->
    <label>{{
      selectedTooltipItem.ClientName != null
        ? "Client Name: " + selectedTooltipItem.ClientName
        : null
    }}</label>
    <label>{{
      selectedTooltipItem.EngagementName != null
        ? "Engagement Name: " + selectedTooltipItem.EngagementName
        : null
    }}</label>
    <label>{{
      selectedTooltipItem.ResourceName != null
        ? "Resource Name: " + selectedTooltipItem.ResourceName
        : null
    }}</label>
    <!-- <label>{{
      selectedTooltipItem.FromEngagementName != null
      ? "From Engagement Name: " + selectedTooltipItem.FromEngagementName
      : null
      }}</label>
    <label>{{
      selectedTooltipItem.FromResourceName != null
      ? "From Resource Name: " + selectedTooltipItem.FromResourceName
      : null
      }}</label> -->
    <label
      >{{ selectedTooltipItem.StartDate != null ? "Start Date: " : null
      }}{{ selectedTooltipItem.StartDate | date: defaultDateFormat }}</label
    >
    <label
      >{{ selectedTooltipItem.EndDate != null ? "End Date: " : null
      }}{{ selectedTooltipItem.EndDate | date: defaultDateFormat }}</label
    >
    <label>{{
      selectedTooltipItem.Hours != null
        ? "Hours: " + selectedTooltipItem.Hours
        : null
    }}</label>
    <label>{{
      selectedTooltipItem.Loading != null
        ? "Loading: " + selectedTooltipItem.Loading
        : null
    }}</label>
    <!-- <label>{{
      selectedTooltipItem.ResourceId != null
      ? "Resource Id: " + selectedTooltipItem.ResourceId
      : null
      }}</label> -->
    <!-- <label>{{
      selectedTooltipItem.ToEngagementName != null
      ? "To Engagement Name: " + selectedTooltipItem.ToEngagementName
      : null
      }}</label>
    <label>{{
      selectedTooltipItem.ToResourceName != null
      ? "To Resource Name: " + selectedTooltipItem.ToResourceName
      : null
      }}</label> -->
    <label>{{
      selectedTooltipItem.Unconfirmed != null
        ? "Unconfirmed: " + selectedTooltipItem.Unconfirmed
        : null
    }}</label>
    <label>{{
      selectedTooltipItem.ActionType != null
        ? "Action: " + selectedTooltipItem.ActionType
        : null
    }}</label>
    <label>{{
      selectedTooltipItem.StatusId == 10
        ? "Status: " + selectedTooltipItem.StatusDescription
        : null
    }}</label>
    <label>{{
      selectedTooltipItem.Description != null
        ? "Description: " + selectedTooltipItem.Description
        : null
    }}</label>
  </div>
</ng-template>

<ng-template #GridPreviewActions>
  <div class="mod">
    <div class="modal-header">
      <h4 class="modal-title pull-left">{{ gridActionModalHeader }}</h4>
      <mat-icon class="close" (click)="openConfirmationModal(EditConfirmation)"
        >close</mat-icon
      >
    </div>
    <div class="modal-body">
      <app-preview *ngIf="showPreviewModal"> </app-preview>
    </div>
  </div>
</ng-template>
