import { Component, OnInit } from '@angular/core';
import { ResourceDto } from '../../../Models/ResourceDto';
import { Subscription } from 'rxjs';
import { SharedServicesService } from '../../sharedservices/shared-services.service';
import { CurrentUser } from '../../../Models/CurrentUser';
import { RegionDetailsVM } from '../../../Models/RegionDetailsVM';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  RegionSubscription: Subscription;
 
  TimeZone: string = '';
  resource: ResourceDto;
  bookingListSubscription: Subscription;

  constructor(public restService: SharedServicesService) { 
  }

  public modifiedDate: Date = new Date();
  public regionDetails: RegionDetailsVM = new RegionDetailsVM();
  public region : string = "";
  public isUserLoggedIn :boolean =false;
  currentYear: number = new Date().getFullYear();

  ngOnInit() {

      this.restService.setFooterDetailsEvent.subscribe((resource) => {
        if (sessionStorage.getItem("resource") !== null && sessionStorage.getItem("resource") !== undefined){
          this.isUserLoggedIn = true;
       // this.getRegion();
        //this.GetModifiedDate();   
        }   
      });
    
  }
  
  private GetModifiedDate() {
    if (CurrentUser.RoleFeatures.length > 0) {
    this.bookingListSubscription = this.restService.GetModifiedDate().subscribe(
      data => {
        this.modifiedDate = data;
      });
    }
    
  }

  private getRegion() {
    let region = sessionStorage.getItem('AppRegion')
    if(region != null && region != undefined && region != 'undefined' && region != ''){
      this.setTimezone(region);
    }
    // todo: need this?
    // else {
    //   this.RegionSubscription = this.restService.getRegion().subscribe(
    //   data => {
    //     setTimeout(() => {
    //       this.region = data;
    //       sessionStorage.setItem('AppRegion', this.region);
    //       this.setTimezone(this.region);
    //     }, 3000)
    //   });
    // } 
  }

  setTimezone(region){
    if(region == 'EMEIA')
    {
      this.TimeZone = "GMT";
    }
    else if(region == 'APAC')
    {
      this.TimeZone = "SGT";
    }
    else if(region == 'Americas')
    {
      this.TimeZone = "EST";
    }
  }
  
}
