<section
  class="appLayout scheduleEngagementBooking"
  id="scheduleEngagementPage"
>
  <div class="scheduleView" id="resourceWrapper">
    <div class="fixedSection" [ngStyle]="setWidth()">
      <div class="engagementScheduleSearchWrapper collapsibleWrapper">
        <section class="searchContainerHeading" (click)="toggleCollapse()">
          <span class="icon" [ngClass]="isCollapsed ? 'collapsed' : ''"></span>
          <h2 class="title fixedForecastTitle">
            <label>{{ searchLabel }}</label>
            <span
              [ngClass]="selectedEngagement.JobDescription ? 'has-content' : ''"
              >{{
                selectedEngagement.JobDescription &&
                selectedEngagement.JobDescription.length > 0
                  ? selectedEngagement.JobDescription
                  : ""
              }}</span
            >
          </h2>
        </section>
        <div class="searchContainer" *ngIf="!isCollapsed">
          <section class="searchFields">
            <div
              *ngIf="user.IsMultiInstance && user.R_InstEnable_TV"
              class="instanceContainer searchItem"
            >
              <label>Retain Instance</label>
              <mat-form-field appearance="outline">
                <mat-select
                  [(value)]="selectedInstance"
                  (selectionChange)="onInstanceChange($event)"
                  placeholder="Retain instance"
                >
                  <mat-option
                    [value]="option.InstanceId"
                    *ngFor="let option of instances; trackBy: trackByInstances"
                  >
                    {{ option.InstanceName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="searchBox searchItem">
              <label>Engagement</label>
              <mat-form-field class="resourceSearch" appearance="outline">
                <input
                  type="text"
                  placeholder="Search Engagement"
                  matInput
                  appSearchDebounce
                  (click)="onEngagementClick()"
                  (debounceKeyup)="search()"
                  [(ngModel)]="searchBar"
                  autocomplete="off"
                  maxlength="200"
                />
                <mat-icon
                  class="closeBtnSearch"
                  (click)="resetfilterBookingGridSearchBar()"
                  >close</mat-icon
                >
              </mat-form-field>

              <!-- for Favourite  Engagement (click)="onFavEngagementSearch(item,$event)"--- Start-->
              <div class="dropdown-list-wrap" *ngIf="showFavouriteEngList">
                <mat-spinner
                  *ngIf="showSearchLoader"
                  [diameter]="40"
                ></mat-spinner>
                <ul
                  *ngIf="addFavouriteEngResults?.length > 0"
                  class="dropdown-list tabdropdwn_actn"
                >
                  <li class="favouriteText">Favourites</li>
                  <li
                    *ngFor="
                      let item of addFavouriteEngResults;
                      trackBy: trackByaddFavEngResults
                    "
                    (click)="onFavEngagementSearch(item)"
                    class="dropdown-list-item"
                    tabindex="0"  matTooltip="{{item.EngagementFullName}}"
                  >
                    <span class="EngFullname">
                      {{ item.EngagementFullName }}
                    </span>
                    <img
                      #Favimage1
                      (click)="onFavoriteImageClick(item)"
                      class="exportExcelIcon favouriteIcon"
                      src="../../../../{{ imagePath }}{{ item.favEngSrc }}"
                    />
                  </li>
                </ul>
                <div
                  *ngIf="
                    showSearchLoader === false &&
                    addFavouriteEngResults?.length === 0 &&
                    IsDataNotFound === true
                  "
                >
                  No Engagement Found
                </div>
              </div>
              <!-- for Favourite  Engagement --- End-->

              <div class="dropdown-list-wrap" *ngIf="showSearchList">
                <mat-spinner
                  *ngIf="showSearchLoader"
                  class="spinner-section"
                  [diameter]="40"
                ></mat-spinner>
                <ul *ngIf="showSearchList" class="dropdown-list tabdropdwn">
                  <li
                    *ngFor="
                      let item of results;
                      let i = index;
                      trackBy: trackByaddFavEngResults
                    "
                    (click)="OnItemSelect(item)"
                    (keyup.enter)="OnItemSelect(item)"
                    class="dropdown-list-item"
                    tabindex="0" matTooltip="{{item.EngagementFullName}}"
                  >
                    <span class="EngFullname">
                      {{ item.EngagementFullName }}
                    </span>

                    <img
                      #Favimage1
                      (click)="onFavoriteImageClick(item)"
                      class="exportExcelIcon favouriteIcon"
                      src="../../../../{{ imagePath }}{{ item.favEngSrc }}"
                    />
                  </li>
                </ul>
                <div
                  *ngIf="
                    showSearchLoader === false &&
                    addFavouriteEngResults?.length === 0
                  "
                >
                  No Engagement Found
                </div>
              </div>
              <!-- for Favourite  Engagement --- End-->
              <div class="dropdown-list-wrap" *ngIf="showSearchList">
                <mat-spinner
                  *ngIf="showSearchLoader"
                  class="spinner-section"
                  [diameter]="40"
                ></mat-spinner>
                <ul *ngIf="showSearchList" class="dropdown-list tabdropdwn">
                  <li
                    *ngFor="
                      let item of results;
                      let i = index;
                      trackBy: trackByaddFavEngResults
                    "
                    (click)="OnItemSelect(item)"
                    (keyup.enter)="OnItemSelect(item)"
                    class="dropdown-list-item"
                    tabindex="0"  matTooltip="{{item.EngagementFullName}}"
                  >
                    <span class="EngFullname">
                      {{ item.EngagementFullName }}
                    </span>

                    <img
                      #Favimage1
                      (click)="onFavoriteImageClick(item)"
                      class="exportExcelIcon favouriteIcon"
                      src="../../../../{{ imagePath }}{{ item.favEngSrc }}"
                    />
                  </li>
                </ul>
                <div
                  class="no-data-section"
                  *ngIf="showSearchLoader === false && results.length === 0"
                >
                  No Engagement Found
                </div>
              </div>
            </div>
            <div class="dateSearch searchItem">
              <label>Period</label>
              <form [formGroup]="range">
                <mat-form-field appearance="outline">
                  <mat-date-range-input
                    [rangePicker]="picker"
                    [min]="startWith"
                  >
                    <input
                      matStartDate
                      formControlName="start"
                      placeholder="Select Start Date"
                      (click)="picker.open()"
                      (dateChange)="onBookingStartDateChange($event)"
                      readonly
                    />-
                    <input
                      matEndDate
                      formControlName="end"
                      placeholder="End Date"
                      (click)="picker.open()"
                      (dateChange)="onBookingEndDateChange($event)"
                      readonly
                    />
                  </mat-date-range-input>
                  <mat-icon
                    class="closeBtnSearch"
                    (click)="resetfilterBookingGridCalender($event)"
                    >close</mat-icon
                  >
                  <mat-date-range-picker
                    (closed)="OnClosedDatePicker()"
                    #picker
                  ></mat-date-range-picker>
                  <mat-error
                    *ngIf="range.controls.start.hasError('matStartDateInvalid')"
                    >Invalid start date</mat-error
                  >
                  <mat-error
                    *ngIf="range.controls.end.hasError('matEndDateInvalid')"
                    >Invalid end date</mat-error
                  >
                </mat-form-field>
                <div
                  *ngIf="
                    range.get('start').value != null &&
                    (range.get('start').dirty || range.get('start').touched) &&
                    range.get('end').value === null &&
                    (range.get('end').dirty || range.get('end').touched) &&
                    isPeriodErrorMsg
                  "
                  class="alert alert-danger"
                >
                  End date is required
                </div>
              </form>
            </div>
            <div class="expansionPreference searchItem">
              <mat-radio-group
                aria-label="preferenceOption"
                class="expansionRadio"
                [(ngModel)]="engagementAllocationModal"
                (change)="engagementAllocationOnChange($event)"
              >
                <mat-radio-button value="hour" class="prefernceRadio"
                  >Hour</mat-radio-button
                >
                <mat-radio-button value="percentage" class="prefernceRadio"
                  >Percentage</mat-radio-button
                >
              </mat-radio-group>
            </div>
          </section>
          <article class="resourceField">
            <div class="resourceTitle">
              Engagement Forecast
              <span
                class="booking_heading_info_icon"
                (click)="openInfo(engagementForecastInfo)"
                matTooltip="Info"
              >
                <img
                  src="../../../../{{ imagePath }}TableIcons/info-yellow.svg"
                  alt="Info"
                />
              </span>
            </div>
            <div class="resourceDetails">
              <div class="resourceInfo">
                <div class="name">
                  {{
                    selectedEngagement.JobDescription
                      ? selectedEngagement.JobDescription
                      : ""
                  }}
                </div>
                <div class="serviceLine">
                  {{
                    selectedEngagement.ServiceLineDescription ||
                    selectedEngagement.ManagerialCountryDescription
                      ? selectedEngagement.ServiceLineDescription +
                        " - " +
                        selectedEngagement.ManagerialCountryDescription
                      : ""
                  }}
                </div>
                <div class="costCenter">
                  {{
                    selectedEngagement.CostCenterDescription
                      ? selectedEngagement.CostCenterDescription
                      : ""
                  }}
                </div>
                <div class="partnerResourceName">
                  {{
                    selectedEngagement.PartnerName
                      ? "Eng. Partner: " + selectedEngagement.PartnerName
                      : ""
                  }}
                </div>
                <div class="managerResourceName">
                  {{
                    selectedEngagement.ManagerNameAndGPN
                      ? "Eng. Manager: " + selectedEngagement.ManagerNameAndGPN
                      : ""
                  }}
                </div>
                <div class="instanceName">
                  {{
                    selectedEngagement.InstanceName
                      ? "Retain Instance: " + selectedEngagement.InstanceName
                      : ""
                  }}
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
      <section class="scheduleHeader">
        <div class="sectionHeading">
          <span>Bookings</span>
          <span
            class="booking_heading_info_icon"
            (click)="openInfo(template1)"
            matTooltip="Info"
          >
            <img
              src="../../../../{{ imagePath }}TableIcons/info-yellow.svg"
              alt="Info"
            />
          </span>
          &nbsp;
          <div class="row py-3" *ngIf="saveErrorMessage != ''">
            <div style="float: right; margin-right: 5px">
              <div class="alert alert-danger" style="padding: 5px; margin: 0px">
                <button
                  type="button"
                  class="msg close"
                  (click)="saveErrorMessage = ''"
                >
                  &times;
                </button>
                &nbsp;
                <strong>Failed: &nbsp;</strong>{{ saveErrorMessage }}
              </div>
            </div>
          </div>
          <div class="row py-3" *ngIf="saveSucessMessage != ''">
            <div style="float: right; margin-right: 5px">
              <div
                class="alert alert-success"
                style="padding: 5px; margin: 0px"
              >
                <button
                  type="button"
                  class="msg close"
                  (click)="saveSucessMessage = ''"
                >
                  &times;
                </button>
                &nbsp;
                <strong>Success: &nbsp;</strong>{{ saveSucessMessage }}
              </div>
            </div>
          </div>
        </div>
        <mat-icon
          *ngIf="dataSource.length > 0 && !IsDisabledReplaceAllFlag && !IsAnyBookingWithInProgressStatus"
          (click)="openActionModal(GridActions, $event, null, 'replaceallEng')"
          matTooltip="Replace All Engagement"
          style="cursor: pointer"
        >
          find_replace
        </mat-icon>
        &nbsp;&nbsp;
        <div class="scheduleAction" *ngIf="checkIfAnyChanges()">
          <button
            color="accent"
            class="cancelButton"
            (click)="cancel()"
            type="button"
          >
            Cancel
          </button>
          <button
            color="primary"
            class="submitButton"
            (click)="submit()"
            type="submit"
          >
            Submit
          </button>
        </div>
      </section>
    </div>

    <div
      class="scheduleTableContainer"
      id="resourceScrollWrapper"
      [ngClass]="!isCollapsed ? 'expandedTableContainer' : ''"
    >
      <div *ngIf="dataSource" class="scheduleTableWrapper">
        <table mat-table [dataSource]="dataSource" class="scheduleTable">
          <ng-container
            [matColumnDef]="col"
            *ngFor="
              let col of displayedColumns;
              let columnIndex = index;
              trackBy: trackByDisplayColumns
            "
            [sticky]="isSticky(col)"
          >
            <th
              class="table-header-cell"
              [ngClass]="
                col === 'Resource Name' ||
                col === 'Rank' ||
                col === 'Retain Instance'
                  ? 'left-align'
                  : ''
              "
              mat-header-cell
              *matHeaderCellDef
            >
              <div class="header-details">
                <span>
                  {{ col === "Rank" ? "Rank/Office/Cost Center" : col }}
                </span>
                <span
                  *ngIf="
                    col !== 'Actions' &&
                    col !== 'Resource Name' &&
                    col !== 'Rank' &&
                    col !== 'Retain Instance' &&
                    this.isCurrentWeekDisabled &&
                    this.datepipe.transform(
                      currentWeekStartDate,
                      defaultDateFormat
                    ) === col
                  "
                  class="info-red-icon"
                  matTooltip="Please contact your Resource or Experience Management team to make any changes in the current week."
                >
                </span>
              </div>
            </th>
            <td
              mat-cell
              *matCellDef="let element; let i = index"
              [ngClass]="{
                hoursCell:
                  col !== 'Actions' &&
                  col !== 'Resource Name' &&
                  col !== 'Rank' &&
                  col !== 'Retain Instance',
                addResContainer:
                  col === 'Resource Name' &&
                  (!element.ResourceName ||
                    element.ResourceName.length === 0 ||
                    element.ResourceId === null)
              }"
            >
              <div *ngIf="col === 'Actions'" class="actionContainer">
                <article>
                  <ng-container
                  *ngIf="
                    !this.user.R_InstEnable_AddBooking
                      ? element.InstanceID == this.user.R_Home_InstanceID &&
                        checkIfRowCancelAvailableForNew(element)
                      : checkIfRowCancelAvailableForNew(element)
                  "
                >
                  <span class="rowCancel">
                    <mat-icon
                      matTooltip="Row Cancel"
                      class="closeBtnDelete"
                      (click)="rowCancel(element, i)"
                      >close
                    </mat-icon>
                  </span>
                </ng-container>
                <ng-container
                  *ngIf="
                    !checkIfRowCancelAvailableForNew(element) &&
                    (!this.user.R_InstEnable_AddBooking
                      ? element.InstanceID == this.user.R_Home_InstanceID
                      : true)
                  "
                >
                  <span
                    *ngIf="
                      !shouldShowActioninfo(element) &&
                      !element.IsDisabledReplaceBooking_EV &&
                      !element.rowReplaced
                    "
                    matTooltip="Replace resource"
                    class="tableActionIcon replace"
                    (click)="replaceResource(element, i)"
                  >
                  </span>
                  <span
                    *ngIf="
                      !shouldShowActioninfo(element) &&
                      !element.IsDisabledCopyBooking_EV &&
                      !element.rowReplaced
                    "
                    (click)="copyResource(element)"
                    matTooltip="Copy resource"
                    class="tableActionIcon copy"
                  >
                  </span>
                  <span
                    *ngIf="
                      !shouldShowActioninfo(element) &&
                      !element.IsDisabledDeleteBooking_EV &&
                      !element.rowReplaced
                    "
                    class="tableActionIcon delete"
                    matTooltip="Delete resource"
                    (click)="openDeleteConfirm(element, i)"
                  >
                  </span>
                  <span
                    *ngIf="
                      (!this.user.R_InstEnable_AddBooking &&
                        element.InstanceID != this.addBookingInstance) ||
                      (element.BookingId > 0 &&
                        element.JobCode != null &&
                        element.JobCode.indexOf('TR') <= -1 &&
                        element.IsDisabledDeleteBooking &&
                        element.IsDisabledCopyBooking &&
                        element.IsDisabledReplaceBooking &&
                        !this.isRowDeleted &&
                        !this.showReplaceAllEng)
                    "
                    class="booking_heading_info_icon error"
                    matTooltip="Submissions are not supported on this booking.&#013;For any other updates, please follow the country specific process."
                  >
                    <img
                      src="../../../../{{ imagePath }}TableIcons/info-red.svg"
                      alt="Warning"
                    />
                  </span>

                  <span
                    *ngIf="
                      element.BookingId > 0 &&
                      element.JobCode != null &&
                      element.JobCode.indexOf('TR') > -1 &&
                      element.IsDisabledDeleteBooking &&
                      element.IsDisabledCopyBooking &&
                      element.IsDisabledReplaceBooking
                    "
                    class="booking_heading_info_icon error"
                    matTooltip=" Please refer Success factors for update to course bookings"
                  >
                    <img
                      src="../../../../{{ imagePath }}TableIcons/info-red.svg"
                      alt="Warning"
                    />
                  </span>
                </ng-container>
                </article>
              </div>
              <div
                *ngIf="col === 'Resource Name'"
                class="resourceName addEngContainer"
              >
                <div
                  class="navigationlink"
                  [ngClass]="{
                    disableNavigation:
                      element.IsDisabledViewBooking_Res === true
                  }"
                  (click)="navigateScheduleViewIndividual(element)"
                  *ngIf="
                    element.ResourceName &&
                    element.ResourceName.length > 0 &&
                    element.ResourceId !== null &&
                    !element.rowReplaced
                  "
                  [matTooltip]="
                    element.IsDisabledViewBooking_Res === false
                      ? 'Click here to see individual details'
                      : null
                  "
                >
                  {{ element.ResourceName }}
                </div>

                <!-- Replace Functionality --- Start -->
                <div class="addResWrapper" *ngIf="element.rowReplaced">
                  <div class="replaceResource" *ngIf="user.RoleDelegate">
                    <form #replaceResourceForm="ngForm" class="replace-form">
                      <mat-form-field
                        class="resourceSearch"
                        appearance="outline"
                      >
                        <input
                          type="text"
                          autocomplete="off"
                          placeholder="Replace Resource"
                          matInput
                          appSearchDebounce
                          (debounceKeyup)="searchReplaceResource(element)"
                          [(ngModel)]="strsearchResourceReplace"
                          [ngClass]="{
                            textRed:
                              strsearchResourceReplace === null ||
                              strsearchResourceReplace === ''
                          }"
                          maxlength="100"
                          required
                          [ngModelOptions]="{ standalone: true }"
                          #replaceResourceControl="ngModel"
                          name="replaceResourceRow"
                        />
                        <mat-icon
                          class="closeBtnSearch"
                          (click)="resetResourceSearchReplace(element)"
                          >close</mat-icon
                        >
                      </mat-form-field>
                    </form>
                    <div
                      class="dropdown-list-wrap"
                      *ngIf="element.showReplaceResList"
                    >
                      <mat-spinner
                        *ngIf="showReplaceResLoader"
                        [diameter]="40"
                      ></mat-spinner>
                      <ul
                        *ngIf="sortedResourceReplaceList?.length > 0"
                        class="dropdown-list tabdropdwn_actn"
                      >
                        <li
                          *ngFor="
                            let item of sortedResourceReplaceList;
                            trackBy: trackByResourceList
                          "
                          (click)="OnReplaceResourceSelect(item, i, element)"
                          (keyup.enter)="
                            OnReplaceResourceSelect(item, i, element)
                          "
                          class="dropdown-list-item"
                          tabindex="0"
                        >
                          {{ item.ResourceName }}
                        </li>
                      </ul>
                      <div
                        class="noDataDropdown"
                        *ngIf="
                          showReplaceResLoader === false &&
                          resourceResultsReplace.length === 0
                        "
                      >
                        No Resource Found
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Replace Functionality --- End -->

                <div *ngIf="!element.rowReplaced" class="replaceInputContainer">
                  <section
                    class="addResWrapper"
                    *ngIf="
                      !element.ResourceName ||
                      element.ResourceName.length === 0 ||
                      element.ResourceId === null
                    "
                  >
                    <mat-form-field class="resourceSearch" appearance="outline">
                      <input
                        type="text"
                        autocomplete="off"
                        placeholder="Search resource"
                        matInput
                        id="copyResourceInput"
                        class="copyResourceInput"
                        [ngClass]="
                          searchResourceBarCopy.length === 0 ? 'textRed=' : ''
                        "
                        appSearchDebounce
                        (debounceKeyup)="searchResourceCopy()"
                        [(ngModel)]="searchResourceBarCopy"
                        maxlength="75"
                      />
                      <mat-icon
                        class="closeBtnSearch"
                        (click)="resetResourceSearchCopy()"
                        >close</mat-icon
                      >
                    </mat-form-field>
                    <div
                      class="dropdown-list-wrap"
                      *ngIf="showResourceListCopy"
                    >
                      <mat-spinner
                        *ngIf="showResourceLoaderCopy"
                        [diameter]="40"
                      ></mat-spinner>
                      <ul
                        *ngIf="resourceResultsCopy?.length > 0"
                        class="dropdown-list tabdropdwn"
                      >
                        <li
                          *ngFor="
                            let item of resourceResultsCopy;
                            trackBy: trackByResourceList
                          "
                          (click)="OnCopyResourceSelect(item, i)"
                          (keyup.enter)="OnCopyResourceSelect(item)"
                          class="dropdown-list-item"
                          tabindex="0"
                        >
                          {{ item.ResourceName }}
                        </li>
                      </ul>
                      <div
                        class="noDataDropdown"
                        *ngIf="
                          showResourceLoaderCopy === false &&
                          resourceResultsCopy.length === 0
                        "
                      >
                        No Resource Found
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div *ngIf="col === 'Rank'">
                <div>
                  <span
                    title="{{
                      (element.RankDescription ? element.RankDescription : '') +
                        '\n' +
                        (element.CountryAndLocation
                          ? element.CountryAndLocation
                          : '') +
                        '\n' +
                        (element.CostCenterWithOutCountryCode
                          ? element.CostCenterWithOutCountryCode
                          : '')
                    }}"
                  >
                    {{ element.RankDescription ? element.RankDescription : "" }}
                    <br />
                    {{
                      element.CountryAndLocation
                        ? element.CountryAndLocation
                        : ""
                    }}
                    <br />
                    {{
                      element.CostCenterWithOutCountryCode
                        ? element.CostCenterWithOutCountryCode
                        : ""
                    }}
                  </span>
                </div>
              </div>
              <div *ngIf="col === 'Retain Instance'">
                {{ element.InstanceName }}
              </div>
              <div
                class="cellWrapper"
                *ngIf="
                  col !== 'Actions' &&
                  col !== 'Resource Name' &&
                  col !== 'Rank' &&
                  col !== 'Retain Instance'
                "
              >
                <ng-container
                  *ngFor="
                    let booking of element.WeeklyBookings | keyvalue;
                    trackBy: trackByWeeklyBookings
                  "
                >
                  <ng-container
                    *ngIf="
                      weeksTobeDisplayed.indexOf(booking.key) + 3 ===
                      columnIndex
                    "
                  >
                    <div class="cellBooking">
                      <ng-container
                        *ngFor="
                          let type of bookingType;
                          trackBy: trackByBookingType
                        "
                      >
                        <section
                          class="cellDivWrapper"
                          [ngStyle]="getBorderColor(booking.value[type])"
                        >
                          <div
                            class="cellDivision"
                            (click)="
                              toggleInputState(element, element.WeeklyBookings)
                            "
                          >
                            <span
                              class="editCell"
                              *ngIf="
                                checkIfBookingAvailable(
                                  booking.value,
                                  type,
                                  element
                                )
                              "
                            >
                              <input
                                matInput
                                autocomplete="off"
                                type="text"
                                appHoursLoading
                                class="bookingHoursEdit"
                                value="isPercentageSelected ? booking.value[type].Loading : booking.value[type].BookingHours"
                                [(ngModel)]="
                                  isPercentageSelected
                                    ? booking.value[type].Loading
                                    : booking.value[type].BookingHours
                                "
                                placeholder="{{
                                  isPercentageSelected
                                    ? booking.value[type].Loading
                                    : booking.value[type].BookingHours
                                }}"
                                (focusout)="
                                  addEngagementDetails(
                                    element,
                                    booking.value[type]
                                  )
                                "
                                (ngModelChange)="
                                  checkIfNumbers($event, booking.value[type]) &&
                                    updateBookingHours(
                                      booking.value[type],
                                      $event
                                    );
                                  checkIfNumbers($event, booking.value[type]) &&
                                    getTotalHours()
                                "
                                [ngClass]="
                                  booking.value[type].isValid ? '' : 'invalid'
                                "
                              />
                            </span>
                            <span
                              class="valueCell"
                              *ngIf="
                                booking.value[type] &&
                                !booking.value[type].IsEdit
                              "
                            >
                              {{
                                !isPercentageSelected
                                  ? booking.value[type]
                                    ? booking.value[type].BookingHours
                                    : ""
                                  : booking.value[type] &&
                                    booking.value[type].BookingHours &&
                                    booking.value[type].BookingHours != null
                                  ? booking.value[type].Loading
                                  : ""
                              }}
                            </span>
                          </div>
                          <div
                            class="weeklyIcon notesIcon"
                            *ngIf="
                              booking.value[type] &&
                              (booking.value[type].IsEdit ||
                                (booking.value[type].BookingHours != null &&
                                  booking.value[type].BookingHours != '' &&
                                  booking.value[type].Description &&
                                  booking.value[type].Description.trim()
                                    .length > 0))
                            "
                          >
                            <img
                              (click)="
                                showNotes($event, booking.value[type], element)
                              "
                              src="../../../../{{ imagePath }}Notes.svg"
                              class="notesIcon"
                              matTooltip="{{ booking.value[type].Description }}"
                              alt="Notes"
                            />
                          </div>
                          <span
                            class="closeBtn"
                            *ngIf="
                              !booking.value[type].IsEdit &&
                              booking.value[type].MeraBookingId &&
                              booking.value[type].MeraBookingId > 0 &&
                              booking.value[type].MeraActionDescriptionId !=
                                6 &&
                              booking.value[type].MeraActionDescriptionId !=
                                5 &&
                              (booking.value[type].StatusId === 1 ||
                                booking.value[type].StatusId === 10) &&
                              user.Email?.toLowerCase() ===
                                booking.value[
                                  type
                                ].RequestorEmail?.toLowerCase()
                            "
                            matTooltip="Cell cancel"
                            (click)="
                              addEngagementDetails(
                                element,
                                booking.value[type],
                                'Delete'
                              )
                            "
                          ></span>
                        </section>
                      </ng-container>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </td>
          </ng-container>
          <ng-container
            [matColumnDef]="head.displayName"
            *ngFor="
              let head of totalHoursColumns;
              let i = index;
              trackBy: trackByTotalHours
            "
            [sticky]="isAvailabiltyHeaderSticky(head.displayName)"
          >
            <ng-container *ngIf="head.displayName === 'Checkbox'">
              <th
                mat-header-cell
                colspan="2"
                class="checkboxThead"
                *matHeaderCellDef
              >
                <div class="checkboxWrapper">
                  <div
                    class="weeklyIcon"
                    *ngFor="
                      let checkbox of tableCheckFilters;
                      trackBy: trackByCheckFilters
                    "
                  >
                    <mat-checkbox
                      [(ngModel)]="checkbox.isChecked"
                      (change)="
                        onBookingTypeToggle($event); saveInSession(checkbox)
                      "
                      *ngIf="
                        checkbox.shouldShow && checkbox.shouldShow === true
                      "
                      [ngModelOptions]="{ standalone: true }"
                    >
                      <img
                        src="../../../../{{ imagePath }}{{
                          checkbox.imgName
                        }}.svg"
                        matTooltip="{{ checkbox.name }}"
                        alt="{{ checkbox.name }}"
                      />{{ checkbox.value }}
                    </mat-checkbox>
                    <!-- <ng-container>
                      <input
                        type="checkbox"
                        id="filter_check_{{ checkbox.value }}"
                      />
                      <label
                        class="checkbox-label"
                        for="filter_check_{{ checkbox.value }}"
                      >
                        <img
                          src="../../../../{{ imagePath }}{{
                            checkbox.imgName
                          }}.svg"
                          matTooltip="{{ checkbox.name }}"
                          alt="{{ checkbox.name }}"
                        />
                        <span class="checkbox-name">{{ checkbox.value }}</span>
                      </label>
                    </ng-container> -->
                  </div>
                </div>
              </th>
            </ng-container>
            <ng-container *ngIf="head.displayName === 'totalHoursDup'">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="emptyHeaderCell"
              ></th>
            </ng-container>
            <ng-container
              *ngIf="
                head.displayName !== 'Checkbox' &&
                head.displayName !== 'totalHoursDup'
              "
            >
              <th
                mat-header-cell
                *matHeaderCellDef
                [class.totalHoursValueCell]="
                  head.displayName !== 'Total Scheduled Hours' &&
                  head.displayName !== 'Checkbox' &&
                  head.displayName !== 'totalHoursDup'
                "
                [class.totalHeading]="
                  head.displayName === 'Total Scheduled Hours'
                "
              >
                <div
                  class="hourContainer"
                  [ngClass]="
                    head.displayName === 'Total Scheduled Hours'
                      ? 'hourHead'
                      : ''
                  "
                >
                  {{
                    isPercentageSelected
                      ? head.displayName === 'Total Scheduled Hours' ? head.displayPercentage + ' %' : head.displayPercentage + '%'
                      : head.displayHours
                  }}
                </div>
              </th>
            </ng-container>
          </ng-container>
          <ng-container
            [matColumnDef]="addCol"
            *ngFor="
              let addCol of addDisplayedColumns;
              trackBy: trackByDisplayColumns
            "
            [sticky]="isStickyAddColumn(addCol)"
          >
            <th mat-header-cell *matHeaderCellDef>
              <div
                class="addEngContainer"
                *ngIf="
                  addCol === 'addResource Name' &&
                  !selectedEngagement.IsDisabledAddBooking
                "
              >
                <section class="addResWrapper">
                  <mat-form-field class="resourceSearch" appearance="outline">
                    <input
                      type="text"
                      autocomplete="off"
                      placeholder="Search resource"
                      matInput
                      appSearchDebounce
                      (debounceKeyup)="searchResource()"
                      [(ngModel)]="searchResourceBar"
                      maxlength="75"
                    />
                    <mat-icon
                      class="closeBtnSearch"
                      (click)="resetResourceSearch()"
                      >close</mat-icon
                    >
                  </mat-form-field>
                  <div class="dropdown-list-wrap" *ngIf="showResourceList">
                    <mat-spinner
                      *ngIf="showResourceLoader"
                      [diameter]="40"
                    ></mat-spinner>
                    <ul
                      *ngIf="resourceResults?.length > 0"
                      class="dropdown-list tabdropdwn"
                    >
                      <li
                        *ngFor="
                          let item of resourceResults;
                          trackBy: trackByResourceList
                        "
                        (click)="OnResourceSelect(item)"
                        (keyup.enter)="OnResourceSelect(item)"
                        class="dropdown-list-item"
                        tabindex="0"
                      >
                        {{ item.ResourceName }}
                      </li>
                    </ul>
                    <div
                      class="noDataDropdown"
                      *ngIf="
                        showResourceLoader === false &&
                        resourceResults.length === 0
                      "
                    >
                      No Resource Found
                    </div>
                  </div>
                </section>
              </div>
              <div
                class="weeklyheader"
                *ngIf="
                  addCol !== 'addActions' &&
                  addCol !== 'addResource Name' &&
                  addCol !== 'addRank' &&
                  addCol !== 'addRetain Instance'
                "
              >
                <div
                  class="weeklyIcon"
                  *ngIf="
                    (user.Unconfirmed && user.Unconfirmed === true) ||
                    (user.Ghost && user.Ghost === true)
                  "
                >
                  <img
                    src="../../../../{{ imagePath }}Confirmed-black.svg"
                    matTooltip="Confirmed Booking"
                    alt="Confirmed Booking"
                  />
                </div>
                <div
                  class="weeklyIcon"
                  *ngIf="user.Unconfirmed && user.Unconfirmed === true"
                >
                  <img
                    src="../../../../{{ imagePath }}Unconfirmed-black.svg"
                    matTooltip="Unconfirmed Booking"
                    alt="Unconfirmed Booking"
                  />
                </div>
                <div
                  class="weeklyIcon"
                  *ngIf="user.Ghost && user.Ghost === true"
                >
                  <img
                    src="../../../../{{ imagePath }}Ghost booking-black.svg"
                    matTooltip="Ghost Booking"
                    alt="Ghost Booking"
                  />
                </div>
              </div>
            </th>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="displayTotalHourHeader; sticky: true"
          ></tr>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
            class="yellowHeader"
          ></tr>
          <tr
            mat-header-row
            class="extra-rows"
            *matHeaderRowDef="addDisplayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            class="extra-rows"
            *matRowDef="let row; columns: displayedColumns"
          ></tr>
        </table>
      </div>
      <notes-dialog
        [isNotes]="isNotes"
        [noteAvailableFor]="noteAvailableFor"
        (onDialogClose)="notesSubmit($event)"
      >
      </notes-dialog>
      <div class="noDataTable" *ngIf="dataSource.length === 0">
        No Resource Data found
      </div>
    </div>
  </div>
</section>

<ng-template #userConfirmation>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title pull-left">{{ modelData.header }}</h4>
    </div>
    <div class="modal-body">
      <div class="popupLayout">
        <div class="fullWidth" [innerHTML]="modelData.text"></div>
        <div class="confirmationButtons" *ngIf="modelData.isConfirmRequired">
          <button class="cancelBtn" (click)="modalCancel()">
            {{ modelData.cancelText }}
          </button>
          <button class="deleteBtn" (click)="modalConfirm()">
            {{ modelData.yesText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #template1>
  <div class="modal-header sev-info-modal-header">
    <h4 class="modal-title pull-left">Bookings</h4>
    <mat-icon class="close" (click)="closeInfo()">close</mat-icon>
  </div>
  <div class="modal-body schedule-info-modal">
    <!-- <h6>Bookings</h6> -->
    <h6>Bookings</h6>

    <table class="info-icon-table">
      <tbody>
        <tr>
          <td class="textInfo">Edit</td>
          <td class="iconData">Clicks on a specific booking cell</td>
        </tr>
        <tr>
          <td class="textInfo">Delete</td>
          <td class="iconData">
            Clicks on a specific booking cell and remove the booking
            hours/Loading %
          </td>
        </tr>
        <tr>
          <td class="textInfo">New Booking</td>
          <td class="iconData">
            Select a Resource, and then a new booking row is created to enter
            the schedules
          </td>
        </tr>
        <tr>
          <td class="textInfo">
            <img
              src="../../../../{{ imagePath }}TableIcons/delete-gray.svg"
              alt="Delete"
            />
          </td>
          <td class="iconData">
            Deletes the entire engagement bookings of the selected Individual
          </td>
        </tr>
        <tr>
          <td class="textInfo">
            <img
              src="../../../../{{ imagePath }}TableIcons/replace-gray.png"
              alt="Replace"
            />
          </td>
          <td class="iconData">
            Replace some or all the bookings from either one resource to another
            and/or one engagement to another
          </td>
        </tr>
        <tr>
          <td class="textInfo">
            <img
              src="../../../../{{ imagePath }}TableIcons/copy-gray.svg"
              alt="Copy"
            />
          </td>
          <td class="iconData">
            Copy schedule details from an existing booking and creates a new
            booking row
          </td>
        </tr>
      </tbody>
    </table>

    <div class="wallChartDescription">
      <h6>Color Schemes:</h6>
    </div>

    <table class="wallChartInfoTable">
      <tr>
        <th>Engagement Category</th>
        <th>Confirmed</th>
        <th>Unconfirmed*</th>
      </tr>
      <tr>
        <td>Chargeable</td>
        <td><span class="colorRect blueLabel"></span></td>
        <td><span class="colorRect lightBlueLabel"></span></td>
      </tr>
      <tr>
        <td>Authorised Project</td>
        <td><span class="colorRect yellowLabel"></span></td>
        <td><span class="colorRect lightYellowLabel"></span></td>
      </tr>
      <tr>
        <td>Non-Chargeable</td>
        <td><span class="colorRect greenLabel"></span></td>
        <td><span class="colorRect lightGreenLabel"></span></td>
      </tr>
      <tr>
        <td>Training Courses from Success Factors</td>
        <td><span class="colorRect purpleLabel"></span></td>
        <td></td>
      </tr>
      <tr>
        <td>Bookings that are in Submitted Or InProgress state</td>
        <td><span class="colorRect greyLabel"></span></td>
        <td></td>
      </tr>
      <tr>
        <td>Requests that are in Pending Approval*</td>
        <td><span class="colorRect lightGreyLabel"></span></td>
        <td></td>
      </tr>
      <tr>
        <td>Ghost Booking*</td>
        <td><span class="colorRect whiteLabel"></span></td>
        <td><span class="colorRect whiteLabel"></span></td>
      </tr>
    </table>

    <div class="lineMargin">
      *Unconfirmed, Ghost and Pending Approval bookings will be visible based on
      your access role configuration.
    </div>
    <div class="lineMargin">
      Total Scheduled Hours = Sum of booked hours of that week.
    </div>
  </div>
</ng-template>

<ng-template #engagementForecastInfo>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Engagement Forecast</h4>
    <mat-icon class="close" (click)="closeInfo()">close</mat-icon>
  </div>
  <div class="modal-body">
    <div>
      <h6>The following engagement information is displayed:</h6>
    </div>
    <div class="iconSection text-margin">
      <span class="iconData"> &lt;Eng. Name&gt; </span>
    </div>
    <div class="iconSection text-margin">
      <span class="iconData">
        &lt;Eng. Serviceline&gt; - &lt;Eng. Country&gt;
      </span>
    </div>
    <div class="iconSection text-margin">
      <span class="iconData"> &lt;Eng. Costcenter Desc&gt; </span>
    </div>
    <div class="iconSection text-margin">
      <span class="iconData">
        Eng. Partner: &lt;Resource Name&gt; - &lt;GPN&gt;
      </span>
    </div>
    <div class="iconSection text-margin">
      <span class="iconData">
        Eng. Manager: &lt;Resource Name&gt; - &lt;GPN&gt;
      </span>
    </div>
    <div class="iconSection text-margin">
      <span class="iconData"> Retain Instance: &lt;Retain Instance&gt; </span>
    </div>
    <br />
    <span>
      The Retain Instance field will be visible only if your access role is
      enabled to access all Retain instances.
    </span>
  </div>
</ng-template>

<ng-template #GridActions>
  <ngx-spinner></ngx-spinner>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ gridActionModalHeader }}</h4>
    <mat-icon class="close" (click)="openConfirmationModal(EditConfirmation)"
      >close</mat-icon
    >
  </div>
  <div class="modal-body">
    <app-replaceall-engagement
      *ngIf="showReplaceAllEng"
      [UiViewSource]="'TEV'"
      [ParentselectedInstance]="selectedInstance"
      [currentWeekActionDTO]="this.currentWeek"
      (isFormDirtyFlag)="makeDirty($event)"
    >
    </app-replaceall-engagement>
  </div>
</ng-template>

<ng-template #GridActions>
  <ngx-spinner></ngx-spinner>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ gridActionModalHeader }}</h4>
    <mat-icon class="close" (click)="openConfirmationModal(EditConfirmation)"
      >close</mat-icon
    >
  </div>
  <div class="modal-body">
    <app-replaceall-engagement
      *ngIf="showReplaceAllEng"
      [currentWeekActionDTO]="this.currentWeek"
      (isFormDirtyFlag)="makeDirty($event)"
    >
    </app-replaceall-engagement>
  </div>
</ng-template>
