<form (ngSubmit)="onSplitSubmit()" [formGroup]="formGroupSplit">
  <div class="popupLayout">
    <div class="customRow">
      <div class="halfWidthItem">
        <label>Booking Type</label>
        <input type="text" class="form-control bg-grey"
          formControlName='bookingType' [readonly]="true">
      </div>
      <div class="halfWidthItem">
        <label class="formLabel">Engagement Code</label>
        <input type="text" class="form-control bg-grey"
          formControlName='engagementCode' [readonly]="true">
      </div>
    </div>
    <div class="fullWidth">
      <label>Engagement</label>
      <input type="text" class="form-control bg-grey" formControlName='engagement'
        [readonly]="true">
    </div>
    <div class="customRow">
      <div class="halfWidthItem">
        <label>Engagement Service Line</label>
        <input type="text" class="form-control bg-grey"
          formControlName='engagementServiceLine' [readonly]="true">
      </div>
      <div class="halfWidthItem">
        <label>Engagement Status</label>
        <input type="text" class="form-control bg-grey"
          formControlName='engagementStatus' [readonly]="true">
      </div>
    </div>
    <div id="split1" class="periodContainer">
      <div class="estimationContainer">
        <div class="col40percent">
          <label>Period</label>
          <mat-form-field appearance="outline" disabled readonly>
            <mat-date-range-input formGroupName="period1" [rangePicker]="picker" disabled readonly>
              <input matStartDate formControlName="start1" placeholder="Start Date"
                readonly>
              <input matEndDate formControlName="end1" placeholder="End Date"
                readonly>
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
          <!-- <mat-form-field appearance="outline" disabled readonly>

            <mat-date-range-input formGroupName="period1" [rangePicker]="picker" [min]="startWith" disabled readonly>
              <input matStartDate formControlName="start1" placeholder="Start Date" (focus)="picker.open()"
                (click)="picker.open()" readonly>
              <input matEndDate formControlName="end1" placeholder="End Date" (focus)="picker.open()"
                (click)="picker.open()" readonly>
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <mat-error *ngIf="formGroupSplit.get('period1').get('start1').hasError('matStartDateInvalid')">Invalid Start
              Date</mat-error>
            <mat-error *ngIf="formGroupSplit.get('period1').get('end1').hasError('matEndDateInvalid')">Invalid End Date
            </mat-error>
          </mat-form-field>
          <div
            *ngIf="formGroupSplit.get('period1').get('start1').value === null && (formGroupSplit.get('period1').get('start1').dirty || formGroupSplit.get('period1').get('start1').touched)"
            class="alert alert-danger">
            Start Date is required
          </div>
          <div
            *ngIf="formGroupSplit.get('period1').get('end1').value === null && (formGroupSplit.get('period1').get('end1').dirty || formGroupSplit.get('period1').get('end1').touched)"
            class="alert alert-danger">
            End Date is required
          </div> -->
        </div>
        <div class="col20percent">
          <label>{{engagementAllocationText}}</label>
          <mat-form-field appearance="outline">
            <input type="text" matInput autocomplete="off"
              formControlName="engagementAllocation" [readonly]="true">
          </mat-form-field>
          <!-- <mat-form-field appearance="outline">
            <input type="text" matInput (focus)="mouseEnter()" autocomplete="off"
              #tooltip="matTooltip"
              [matTooltip]="engagementAllocationPercentSelected ? '' : 'Note when adding time in hours this will be input for the full-time period of the booking, not per week. For long term bookings Loading% may be the preferred way to input time allocation to work (please see “preference” button in top right hand corner to switch between the time allocation input methods)'"
              triggers="hover click focus" placement="bottom" (focusout)="mouseLeave(engagementAllocationTypeValue) "
              formControlName="engagementAllocation" autocomplete="off" [readonly]="true">
          </mat-form-field>
          <div *ngIf="isValidInput('engagementAllocation')">
            <div *ngIf="formGroupSplit.controls['engagementAllocation'].errors.required" class="alert alert-danger">
              {{engagementAllocationText}} is required.
            </div>
            <div *ngIf="formGroupSplit.controls['engagementAllocation'].errors.pattern" class="alert alert-danger">
              {{engagementAllocationText}} is invalid.
            </div>
          </div> -->

        </div>
        <div class="col10percent" *ngIf="!resource?.Unconfirmed">
          <label>Unconfirmed</label>
          <mat-checkbox [(ngModel)]="unconfirmed" formControlName="unconfirmed" [disableRipple]='true'  onclick="return false">
          </mat-checkbox>
         
        </div>
        <div class="col10percent" *ngIf="resource?.Ghost">
          <label>Ghost</label>
          <mat-checkbox [(ngModel)]="ghost" formControlName="ghost" [disableRipple]='true'  onclick="return false">
          </mat-checkbox>
         
        </div>
        <div class="col10percent">
          &nbsp;
         
        </div>
      </div>
      

    </div>

    <div id="split2" class="periodContainer">
      <div class="estimationContainer">
        <div class="col40percent">
          <label>Period <span class="mandtoryIcn"> *</span></label>
          <mat-form-field appearance="outline">
            <mat-date-range-input formGroupName="period2" [rangePicker]="picker2" [min]="startWith">
              <input matStartDate formControlName="start2" placeholder="Start Date" (focus)="picker2.open()"
                (click)="picker2.open()" readonly>
              <input matEndDate formControlName="end2" placeholder="End Date" (focus)="picker2.open()"
                (click)="picker2.open()" readonly (dateChange)="makeDirty('period2')">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-date-range-picker #picker2></mat-date-range-picker>
            
          </mat-form-field>
          <div *ngIf="formGroupSplit.get('period2').get('start2').value === null && (formGroupSplit.get('period2').get('start2').dirty || formGroupSplit.get('period2').get('start2').touched)" class="alert alert-danger">
            Start Date is required
          </div>
          <div *ngIf="formGroupSplit.get('period2').get('end2').value === null && (formGroupSplit.get('period2').get('end2').dirty || formGroupSplit.get('period2').get('end2').touched)" class="alert alert-danger">
            End Date is required
          </div>
        </div>
        <div class="col20percent">
          <label class="formLabel">{{engagementAllocationText}} <span class="mandtoryIcn"> *</span></label>
          <mat-form-field appearance="outline">
            <input type="text" matInput (input)="makeDirty('Hours_2')" (focus)="mouseEnterSplit()" autocomplete="off"
              #tooltip="matTooltip"
              [matTooltip]="engagementAllocationPercentSelected ? '' : 'Note when adding time in hours this will be input for the full-time period of the booking, not per week. For long term bookings Loading% may be the preferred way to input time allocation to work (please see “preference” button in top right hand corner to switch between the time allocation input methods)'"
              triggers="hover click focus" placement="bottom" (focusout)="mouseLeave(engagementAllocationTypeValue_2)"
              formControlName="allocationValue_2" autocomplete="off" appHoursLoading [isPercentageSelected]="isPercentageSelected">
          </mat-form-field>
          <div *ngIf="isValidInput('allocationValue_2')">
            <div *ngIf="formGroupSplit.controls['allocationValue_2'].errors.required" class="alert alert-danger">
              {{engagementAllocationText}} is required.
            </div>
            <div *ngIf="formGroupSplit.controls['allocationValue_2'].errors.pattern" class="alert alert-danger">
              {{engagementAllocationText}} is invalid.
            </div>
          </div>

        </div>
        <div class="col10percent" *ngIf="!resource?.Unconfirmed">
          <label>Unconfirmed</label>
          <mat-checkbox [disableRipple]='true' (change)="makeDirty('unconfirmed_2')" [(ngModel)]="unconfirmed_2"
            [ngModelOptions]="{standalone:true}"></mat-checkbox>

        </div>
        <div class="col10percent" *ngIf="resource?.Ghost">
          <label>Ghost</label>
          <mat-checkbox [disableRipple]='true' (change)="makeDirty('')" [(ngModel)]="ghost_2"
            [ngModelOptions]="{standalone:true}"></mat-checkbox>

        </div>
        <div class="col10percent">
          <div class="morePeriods">
            <mat-icon class="addPeriod" (click)="showSplit()">add</mat-icon>
          </div>
        </div>

      </div>
      

    </div>

    <div id="split3" *ngIf="showSplit_2" class="periodContainer">
      <div class="estimationContainer">
        <div class="col40percent">
          <label>Period <span class="mandtoryIcn"> *</span></label>
          <mat-form-field appearance="outline">
            <mat-date-range-input formGroupName="period3" [rangePicker]="picker3" [min]="startWith">
              <input matStartDate formControlName="start3" placeholder="Start Date" (focus)="picker3.open()"
                (click)="picker3.open()" readonly>
              <input matEndDate formControlName="end3" placeholder="End Date" (focus)="picker3.open()"
                (click)="picker3.open()" readonly (dateChange)="makeDirty('period3')">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-date-range-picker #picker3></mat-date-range-picker>
            
          </mat-form-field>
          <div *ngIf="formGroupSplit.get('period3').get('start3').value === null && (formGroupSplit.get('period3').get('start3').dirty || formGroupSplit.get('period3').get('start3').touched)" class="alert alert-danger">
            Start Date is required
          </div>
          <div *ngIf="formGroupSplit.get('period3').get('end3').value === null && (formGroupSplit.get('period3').get('end3').dirty || formGroupSplit.get('period3').get('end3').touched)" class="alert alert-danger">
            End Date is required
          </div>
        </div>
        <div class="col20percent">
          <label>{{engagementAllocationText}} <span class="mandtoryIcn"> *</span></label>
          <mat-form-field appearance="outline">
            <input type="text" matInput (input)="makeDirty('Hours_3')" (focus)="mouseEnter() " autocomplete="off"
              #tooltip="matTooltip"
              [matTooltip]="engagementAllocationPercentSelected ? '' : 'Note when adding time in hours this will be input for the full-time period of the booking, not per week. For long term bookings Loading% may be the preferred way to input time allocation to work (please see “preference” button in top right hand corner to switch between the time allocation input methods)'"
              triggers="hover click focus" placement="bottom" (focusout)="mouseLeave(engagementAllocationTypeValue_3)"
              formControlName="allocationValue_3" autocomplete="off" appHoursLoading [isPercentageSelected]="isPercentageSelected">
          </mat-form-field>
          <div *ngIf="isValidInput('allocationValue_3')">
            <div *ngIf="formGroupSplit.controls['allocationValue_3'].errors.required" class="alert alert-danger">
              {{engagementAllocationText}} is required.
            </div>
            <div *ngIf="formGroupSplit.controls['allocationValue_3'].errors.pattern" class="alert alert-danger">
              {{engagementAllocationText}} is invalid.
            </div>
          </div>

        </div>
        <div class="col10percent" *ngIf="!resource?.Unconfirmed">
          <label>Unconfirmed</label>
          <mat-checkbox [disableRipple]='true' (change)="makeDirty('unconfirmed_3')" [(ngModel)]="unconfirmed_3"
            [ngModelOptions]="{standalone:true}"></mat-checkbox>

        </div>
        <div class="col10percent" *ngIf="resource?.Ghost">
          <label>Ghost</label>
          <mat-checkbox [disableRipple]='true' (change)="makeDirty('')" [(ngModel)]="ghost_3"
            [ngModelOptions]="{standalone:true}"></mat-checkbox>

        </div>
        <div class="col10percent">
          <div class="morePeriods">
            <mat-icon class="addPeriod" (click)="hideSplit(2)">remove</mat-icon>
          </div>
        </div>
      </div>
      
    </div>

    <div id="split4" *ngIf="showSplit_3" class="periodContainer">
      <div class="estimationContainer">
        <div class="col40percent">
          <label>Period <span class="mandtoryIcn"> *</span></label>
          <mat-form-field appearance="outline">
            <mat-date-range-input formGroupName="period4" [rangePicker]="picker4" [min]="startWith">
              <input matStartDate formControlName="start4" placeholder="Start Date" (focus)="picker4.open()"
                (click)="picker4.open()" readonly>
              <input matEndDate formControlName="end4" placeholder="End Date" (focus)="picker4.open()"
                (click)="picker4.open()" readonly (dateChange)="makeDirty('period4')">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
            <mat-date-range-picker #picker4></mat-date-range-picker>
            
          </mat-form-field>
          <div *ngIf="formGroupSplit.get('period4').get('start4').value === null && (formGroupSplit.get('period4').get('start4').dirty || formGroupSplit.get('period4').get('start4').touched)" class="alert alert-danger">
            Start Date is required
          </div>
          <div *ngIf="formGroupSplit.get('period4').get('end4').value === null && (formGroupSplit.get('period4').get('end4').dirty || formGroupSplit.get('period4').get('end4').touched)" class="alert alert-danger">
            End Date is required
          </div>

        </div>
        <div class="col20percent">
          <label>{{engagementAllocationText}} <span class="mandtoryIcn"> *</span></label>
          <mat-form-field appearance="outline">
            <input type="text" matInput (input)="makeDirty('Hours_4')" (focus)="mouseEnterSplit()" autocomplete="off"
              #tooltip="matTooltip"
              [matTooltip]="engagementAllocationPercentSelected ? '' : 'Note when adding time in hours this will be input for the full-time period of the booking, not per week. For long term bookings Loading% may be the preferred way to input time allocation to work (please see “preference” button in top right hand corner to switch between the time allocation input methods)'"
              triggers="hover click focus" placement="bottom" (focusout)="mouseLeave(engagementAllocationTypeValue_4)"
              formControlName="allocationValue_4" autocomplete="off" appHoursLoading [isPercentageSelected]="isPercentageSelected">
          </mat-form-field>
          <div *ngIf="isValidInput('allocationValue_4')">
            <div *ngIf="formGroupSplit.controls['allocationValue_4'].errors.required" class="alert alert-danger">
              {{engagementAllocationText}} is required.
            </div>
            <div *ngIf="formGroupSplit.controls['allocationValue_4'].errors.pattern" class="alert alert-danger">
              {{engagementAllocationText}} is invalid.
            </div>
          </div>
        </div>
        <div class="col10percent" *ngIf="!resource?.Unconfirmed">
          <label>Unconfirmed</label>
          <mat-checkbox [disableRipple]='true' (change)="makeDirty('unconfirmed_4')" [(ngModel)]="unconfirmed_4"
            [ngModelOptions]="{standalone:true}"></mat-checkbox>

        </div>
        <div class="col10percent" *ngIf="resource?.Ghost">
          <label>Ghost</label>
          <mat-checkbox [disableRipple]='true' (change)="makeDirty('')" [(ngModel)]="ghost_4"
            [ngModelOptions]="{standalone:true}"></mat-checkbox>

        </div>
        <div class="col10percent">
          <div class="morePeriods">
            <mat-icon class="addPeriod" (click)="hideSplit(3)">remove</mat-icon>
          </div>
        </div>
      </div>
      <!-- <div class="overallocationWarning">
        <div class="allocationText" [class.disabled]="overallocationerror_4">
          <label *ngIf="!hoursValid_4">This Booking request will cause an overallocation. If you
            wish to proceed, please select the check box and click Save.</label>
        </div>
        <div class="confirmAllocation" [class.disabled]="overallocationerror_4">
          <label *ngIf="!hoursValid_4">Confirm</label>
          <mat-checkbox  [disableRipple]='true'  (change)="confirmHoursChange('allocationValue_4')" *ngIf="!hoursValid_4"
            [(ngModel)]="confirmHours_4" [ngModelOptions]="{standalone:true}"></mat-checkbox>
        </div>
      </div> -->

    </div>

    <div id="split5" *ngIf="showSplit_4" class="periodContainer">
      <div class="estimationContainer">
        <div class="col40percent">
          <label>Period <span class="mandtoryIcn"> *</span></label>
          <mat-form-field appearance="outline">
            <mat-date-range-input formGroupName="period5" [rangePicker]="picker5" [min]="startWith">
              <input matStartDate formControlName="start5" placeholder="Start Date" (focus)="picker5.open()"
                (click)="picker5.open()" readonly>
              <input matEndDate formControlName="end5" placeholder="End Date" (focus)="picker5.open()"
                (click)="picker5.open()" readonly (dateChange)="makeDirty('period5')">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
            <mat-date-range-picker #picker5></mat-date-range-picker>
            <mat-error *ngIf="formGroupSplit.get('period5').get('start5').hasError('matStartDateInvalid')">Invalid Start
              Date</mat-error>
            <mat-error *ngIf="formGroupSplit.get('period5').get('end5').hasError('matEndDateInvalid')">Invalid End Date
            </mat-error>
          </mat-form-field>
          <div *ngIf="formGroupSplit.get('period5').get('start5').value === null && (formGroupSplit.get('period5').get('start5').dirty || formGroupSplit.get('period5').get('start5').touched)" class="alert alert-danger">
            Start Date is required
          </div>
          <div *ngIf="formGroupSplit.get('period5').get('end5').value === null && (formGroupSplit.get('period5').get('end5').dirty || formGroupSplit.get('period5').get('end5').touched)" class="alert alert-danger">
            End Date is required
          </div>
        </div>
        <div class="col20percent">
          <label class="formLabel">{{engagementAllocationText}} <span class="mandtoryIcn"> *</span></label>
          <mat-form-field appearance="outline">
            <input type="text" matInput (input)="makeDirty('Hours_5')" (focus)="mouseEnterSplit()" autocomplete="off"
              #tooltip="matTooltip"
              [matTooltip]="engagementAllocationPercentSelected ? '' : 'Note when adding time in hours this will be input for the full-time period of the booking, not per week. For long term bookings Loading% may be the preferred way to input time allocation to work (please see “preference” button in top right hand corner to switch between the time allocation input methods)'"
              triggers="hover click focus" placement="bottom" (focusout)="mouseLeave(engagementAllocationTypeValue_5)"
              formControlName="allocationValue_5" appHoursLoading [isPercentageSelected]="isPercentageSelected">
          </mat-form-field>
          <div *ngIf="isValidInput('allocationValue_5')">
            <div *ngIf="formGroupSplit.controls['allocationValue_5'].errors.required" class="alert alert-danger">
              {{engagementAllocationText}} is required.
            </div>
            <div *ngIf="formGroupSplit.controls['allocationValue_5'].errors.pattern" class="alert alert-danger">
              {{engagementAllocationText}} is invalid.
            </div>
          </div>

        </div>
        <div class="col10percent" *ngIf="!resource?.Unconfirmed">
          <label>Unconfirmed</label>
          <mat-checkbox [disableRipple]='true' (change)="makeDirty('unconfirmed_5')" [(ngModel)]="unconfirmed_5"
            [ngModelOptions]="{standalone:true}"></mat-checkbox>

        </div>
        <div class="col10percent" *ngIf="resource?.Ghost">
          <label>Ghost</label>
          <mat-checkbox [disableRipple]='true' (change)="makeDirty('')" [(ngModel)]="ghost_5"
            [ngModelOptions]="{standalone:true}"></mat-checkbox>

        </div>
        <div class="col10percent">
          <div class="morePeriods">
            <mat-icon class="addPeriod" (click)="hideSplit(4)">remove</mat-icon>
          </div>
        </div>
      </div>
     

    </div>

    <div class="fullWidth">
      <label>Notes Description</label>
      <mat-form-field appearance="outline">
        <input matInput autocomplete="off" #tooltip="matTooltip"
          matTooltip="Please do not enter any unnecessary personal information or any sensitive personal data (including tax file numbers or other government identifiers), client confidential information, audit secrets, state secrets, commercial secrets, or anything that would violate professional secrecy or confidentiality rules or that would be considered abusive/irrelevant."
          triggers="hover click focus" placement="bottom" type="text" (change)="makeDirty(description)"
          [(ngModel)]="description" formControlName='description' maxlength="200">
      </mat-form-field>
    </div>


    <div class="estimationContainer" *ngIf="!hoursValid">
      <div class="col80percent" [class.disabled]="overallocationerror">
        <label>This Booking request will cause an overallocation. If you wish to proceed, please select the check box
          and click on Copy.</label>
      </div>
      <div class="col20percent" [class.disabled]="overallocationerror">
        <label>Confirm</label>
        <!-- <input type="checkbox" class="check" (change)="confirmHoursChange()" [(ngModel)]="confirmHours"> -->
        <mat-checkbox [disableRipple]='true' (change)="confirmHoursChange()" [(ngModel)]="confirmHours"
          [ngModelOptions]="{standalone:true}"></mat-checkbox>
      </div>
    </div>
    <br />

    <div class="SubmitContainer">
      <button class="submitButton" type="submit" [disabled]="(!(buttonText == 'Copy'))">
        <span class="glyphicon glyphicon-ok-sign pr-5" style="font-size: 12px;"></span>{{buttonText}}</button>
    </div>
  </div>
</form>