<div class="approval-layer-config">
  <mat-icon
    *ngIf="showAddBtn"
    class="add-item-btn"
    title="Add new item"
    (click)="onAddActionRow()"
    >add_box</mat-icon
  >
  <div *ngIf="arrData?.length" class="materialTable table-approval-layer">
    <table
      #table
      mat-table
      [dataSource]="arrData"
      class="mat-elevation-z8 bookingTable"
    >
      <ng-container matColumnDef="engagementCategory">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="mat-column-engagementCategory"
        >
          Engagement Category
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          data-title="engagementCategory"
        >
          <div *ngIf="showEditable(i)">
            <mat-form-field floatLabel="never">
              <mat-select
                [(ngModel)]="element.RAC_JC_ID"
                placeholder="Engagement Category"
                (selectionChange)="onCategoryChange($event,i)"
              >
                <mat-option
                  *ngFor="let jobCategory of jobCategoryList"
                  [value]="jobCategory.Id"
                  >{{ jobCategory.Text }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="!showEditable(i)">
            {{
              findName(jobCategoryList, element.RAC_JC_ID)
            }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="mat-column-action">
          Action
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          data-title="action"
        >
          <div *ngIf="showEditable(i)">
            <mat-form-field floatLabel="never">
              <mat-select
                [(ngModel)]="element.RAC_ActionID"
                placeholder="Action"
                (selectionChange)="onActionChange($event,i)"
              >
                <mat-option
                  *ngFor="let actionDesc of actionDescList"
                  [value]="actionDesc.Id"
                  >{{ actionDesc.Text }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="!showEditable(i)">
            {{
              findName(actionDescList, element.RAC_ActionID)
            }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="overAllocation">
        <th mat-header-cell *matHeaderCellDef class="mat-column-overAllocation">
          Over Allocation
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          data-title="overAllocation"
        >
          <div *ngIf="showEditable(i)">
            <input
              type="checkbox"
              class="input-checkbox"
              [(ngModel)]="element.RAC_OverAllocation"
              [ngModelOptions]="{ standalone: true }"
            />
          </div>
          <div *ngIf="!showEditable(i)">
            {{ element.RAC_OverAllocation ? "Yes" : "No" }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="currentWeek">
        <th mat-header-cell *matHeaderCellDef class="mat-column-currentWeek">
          Current Week
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          data-title="currentWeek"
        >
          <div *ngIf="showEditable(i)">
            <input
              type="checkbox"
              class="input-checkbox"
              [(ngModel)]="element.RAC_CWEEK"
              [ngModelOptions]="{ standalone: true }"
            />
          </div>
          <div *ngIf="!showEditable(i)">
            {{ element.RAC_CWEEK ? "Yes" : "No" }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="currentWeekPastDate">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="mat-column-currentWeekPastDate"
        >
          Current Week with Past Date
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          data-title="currentWeekPastDate"
        >
          <div *ngIf="showEditable(i)">
            <input
              type="checkbox"
              class="input-checkbox"
              [(ngModel)]="element.RAC_CWEEKWPD"
              [ngModelOptions]="{ standalone: true }"
            />
          </div>
          <div *ngIf="!showEditable(i)">
            {{ element.RAC_CWEEKWPD ? "Yes" : "No" }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="nextWeek">
        <th mat-header-cell *matHeaderCellDef class="mat-column-nextWeek">
          Next Week
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          data-title="nextWeek"
        >
          <div *ngIf="showEditable(i)">
            <input
              type="checkbox"
              class="input-checkbox"
              [(ngModel)]="element.RAC_NWEEK"
              [ngModelOptions]="{ standalone: true }"
            />
          </div>
          <div *ngIf="!showEditable(i)">
            {{ element.RAC_NWEEK ? "Yes" : "No" }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="greaterThanTwoWeeks">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="mat-column-greaterThanTwoWeeks"
        >
          Greater than Two Weeks
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          data-title="greaterThanTwoWeeks"
        >
          <div *ngIf="showEditable(i)">
            <input
              type="checkbox"
              class="input-checkbox"
              [(ngModel)]="element.RAC_NNWEEK"
              [ngModelOptions]="{ standalone: true }"
            />
          </div>
          <div *ngIf="!showEditable(i)">
            {{ element.RAC_NNWEEK ? "Yes" : "No" }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef class="mat-column-Actions">
          Actions
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          class="actionColumn"
        >
          <div *ngIf="!showEditable(i) && showAddBtn">
            <mat-icon class="tableIcon" (click)="onEditApprovalLayerItem(i)"
              >edit</mat-icon
            >
            <mat-icon
              class="tableIcon"
              (click)="openConfirmationModal(Confirmation, 'delete', i)"
              >delete</mat-icon
            >
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
   <div *ngIf="disbleSaveFlg" class="ValidtinMsg">
    Same Combination of EngagementCategory and Action is not allowed
   </div>
    <div
      *ngIf="toggleAddEditMode"
      class="btns-container d-flex justify-content-center my-3"
    >
      <button class="mx-2 cancel-btn" (click)="onCancel()">Cancel</button>
      <button
        [disabled]="enableSubmitBtn() || disbleSaveFlg"
        class="mx-2 add-btn"
        (click)="onSubmit()"
      >
        Save
      </button>
    </div>
  </div>
  <div *ngIf="!arrData?.length" class="noBookings">
    No approval configurations found
  </div>
  <div class="row">
    <ng-template #Confirmation>
      <div class="modal-header">
        <h4 class="modal-title pull-left">
          <span class="glyphicon glyphicon-warning-sign pr-5"></span>Confirm
        </h4>
        <mat-icon (click)="modalRef.hide()">close</mat-icon>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div>Are you sure you want to {{ confirmationClickAction }} ?</div>
          </div>
          <div class="d-flex my-3">
            <button class="cancel-btn" (click)="this.modalRef.hide()">
              No
            </button>
            <button class="add-btn mx-3" (click)="onDeleteApprovalLayerItem()">
              Yes
            </button>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
