import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { TokenService } from '../../shared/auth/token.service';
import { Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  _userActionOccured: Subject<void> = new Subject();
  get userActionOccured(): Observable<void> {
    return this._userActionOccured.asObservable();
  }

  constructor(private token: TokenService, private router: Router) {}

  public checkTokenExpiredAndRefresh() {
    if (!environment.EnableAuth) {
      return false;
    }

    const resultInMinutes = this.tokenTimeDifference();
    const refreshTokenStatus = sessionStorage.getItem('refresh_token_status');

    if (refreshTokenStatus !== 'requested') {
      if (resultInMinutes <= 5) {
        if (this.isUserIdle()) {
          this.logout();
        } else {
          sessionStorage.setItem('refresh_token_status', 'requested');
          this.token.refreshToken().subscribe(
            (res) => {
              // this.token.validateUser().subscribe(
              //     (data) =>  {
              //       sessionStorage.setItem('refresh_token_status', 'completed');
              //       this.notifyUserAction();
              //     },
              //     (err) => {
              //       this.logout();
              // } );
            },
            (err) => {
              this.logout();
            }
          );
        }
      }
    }
  }

  public logout() {
    this.router.navigate(['Logout']);
  }

  public logoutForInactivity() {
    this.router.navigate(['Logout', { isTimeOut: true }]);
  }

  public isSessionExpired(): boolean {
    const userIdle = this.isUserIdle();
    const tokenExpired = this.isTokenExpired();
    if (userIdle && tokenExpired) {
      return true;
    } else {
      return false;
    }
  }
  public notifyUserAction() {
    this._userActionOccured.next();
  }

  public timerStarted() {
    sessionStorage.setItem('user_idle', 'false');
    this.notifyUserAction();
  }

  public timerExpired() {
    if (this.isTokenExpired()) {
      sessionStorage.setItem('user_idle', 'true');
      this.logout();
    } else {
      this.checkTokenExpiredAndRefresh();
    }
  }

  public isTokenExpired() {
    const timeDiff = this.tokenTimeDifference();
    return timeDiff <= 0;
  }

  public tokenTimeDifference(): number {
    const startTime = new Date();
    if (sessionStorage.getItem('expires_on') !== null) {
      const endTime = new Date(sessionStorage.getItem('expires_on'));
      const difference = endTime.getTime() - startTime.getTime();
      const resultInMinutes = Math.round(difference / 60000);
      return resultInMinutes;
    } else {
      return 50;
    }
  }

  public isUserIdle(): boolean {
    const userIdle = sessionStorage.getItem('user_idle');
    if (userIdle === 'true') {
      return true;
    } else {
      return false;
    }
  }
}
