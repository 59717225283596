<form (ngSubmit)="submit()" [formGroup]="formGroup">
  <div class="container-ure">
    <div class="row top-row">
      <div class="col-6 search-box">
        <mat-form-field class="resourceSearch" appearance="outline">
          <input
            type="text"
            placeholder="Search resource by name or GPN"
            matInput
            appSearchDebounce
            (debounceKeyup)="search()"
            formControlName="searchBar"
            autocomplete="off"
          />
          <mat-icon (click)="clear()">close</mat-icon>
        </mat-form-field>
        <div class="dropdown-list-wrap-vrr" *ngIf="showSearchList">
          <mat-spinner *ngIf="showSearchLoader" [diameter]="40"></mat-spinner>
          <ul *ngIf="showSearchList" class="dropdown-list">
            <li
              *ngFor="let item of resourceList"
              (click)="onItemSelect(item)"
              class="dropdown-list-item"
            >
              {{ item.ResourceName }}
            </li>
          </ul>
        </div>
      </div>
      <div class="col-4">
        <label>Resource Email: </label>
        <mat-form-field appearance="outline">
          <input
            type="text"
            formControlName="resEmailControl"
            id="resEmailControl"
            matInput
            placeholder="Resource Email"
            [readonly]="resourceRoleModel?.ResourceId > 0 ? false : true"
            [ngClass]="{
              'k-state-disabled': !(
                resourceRoleModel && resourceRoleModel.ResourceId > 0
              )
            }"
          />
        </mat-form-field>
      </div>
      <div class="col-2">
        <button
          style="
            width: 150px;
            background: #ffe600;
            color: #000000;
            text-align: center;
            padding: 5px;
            margin: 10px 0;
            cursor: pointer;
            border: none;
            margin-top: 22px;
          "
          type="submit"
          [disabled]="!(resourceRoleModel?.ResourceId > 0)"
          [ngClass]="{
            'edit-btn disabled': !(resourceRoleModel?.ResourceId > 0)
          }"
        >
          {{ buttonText }}
        </button>
      </div>
    </div>
    <div class="info-container">
      <div class="row">
        <div class="col-lg-5 col-md-5 col-sm-5">
          <div class="resource-div">Resource Information</div>
        </div>
        <div class="col-lg-7 col-md-7 col-sm-7"></div>
      </div>
      <div class="row first-row">
        <div class="col-lg-3 col-md-3 col-sm-3">
          <label>Sub-Area: </label> {{ resourceRoleModel?.SubAreaDescription }}
        </div>
        <div class="col-lg-3 col-md-3 col-sm-3">
          <label>Managerial Country: </label>
          {{ resourceRoleModel?.ManagerialCountryCode }}
        </div>
        <div class="col-lg-3 col-md-3 col-sm-3">
          <label>Legal Entity: </label> {{ resourceRoleModel?.LegalEntityCode }}
        </div>
        <div class="col-lg-3 col-md-3 col-sm-3">
          <label>Business Unit: </label>
          {{ resourceRoleModel?.BusinessUnitDescription }}
        </div>
      </div>
      <div class="row first-row">
        <div class="col-lg-3 col-md-3 col-sm-3">
          <label>Service Line: </label> {{ resourceRoleModel?.SLDescription }}
        </div>
        <div class="col-lg-3 col-md-3 col-sm-3">
          <label>Sub-Service Line: </label>
          {{ resourceRoleModel?.SSLDescription }}
        </div>
        <div class="col-lg-3 col-md-3 col-sm-3">
          <label>Sub-Management Unit: </label>
          {{ resourceRoleModel?.SubManagementUnitDescription }}
        </div>
        <div class="col-lg-3 col-md-3 col-sm-3"></div>
      </div>
    </div>
    <div class="bottom-container">
      <div>
        <label>Role Access Assigned: </label>
        {{
          resourceRoleModel?.RoleName
            ? resourceRoleModel?.RoleName +
              " (" +
              resourceRoleModel?.RoleTypeDisplayName +
              ")"
            : resourceRoleModel?.ResourceId > 0
            ? "No Role Assigned!"
            : ""
        }}
      </div>
    </div>
  </div>
</form>
