import { EngagementBookingChildVM } from '../Models/EngagementBookingChildVM';

export class EngagementBookingVM {
      BookingId: number;
      ResourceId: number | null;
      JobId: number | null;
      BookingType: string;
      StartDate: Date | null;
      EndDate: Date | null;
      Hours: number | null;
      Loading: number | null;
      Description: string;
      UpdatedOn: Date | null;
      UpdatedBy: string;
      EngagementCode: string;
      Engagement: string;
      ActionDescriptionId: number;
      MeraBookingStatusId: number;
      Unconfirmed: boolean;
      Ghost: boolean;
      Ghost_2: boolean;
      Ghost_3: boolean;
      Ghost_4: boolean;
      Ghost_5: boolean;
      EngagementType: string;
      Rank: number;
      StartDate_2: string | null;
      StartDate_3: string | null;
      StartDate_4: string | null;
      StartDate_5: string | null;
      EndDate_2: string | null;
      EndDate_3: string | null;
      EndDate_4: string | null;
      EndDate_5: string | null;
      Hours_2: number | null;
      Hours_3: number | null;
      Hours_4: number | null;
      Hours_5: number | null;
      Loading_2: number | null;
      Loading_3: number | null;
      Loading_4: number | null;
      Loading_5: number | null;
      Unconfirmed_2: boolean;
      Unconfirmed_3: boolean;
      Unconfirmed_4: boolean;
      Unconfirmed_5: boolean;
      ChildBooking: EngagementBookingChildVM[];
      ChildRowsExist: boolean;
      JobStatus: string;
      ServiceLineCode: string;
      ServiceLineDescription: string;

      ClientDescription: string;
      JobType: string;
      RankDescription: string;
      LocationDescription: string;

      JobCode:string;
      JobDescription:string;
      JobCategory:string;
      ResourceName:string;
  IsJobActive: boolean;
  CurrentUserName: string;
  CurrentUserId: number;
  CurrentUserEmail: string;

  IsPercentSelected: boolean;
  IsSplitOneEdit : boolean;
  GPN : boolean;

  HighlightStatus : boolean;
  TooltipData : string;

  BsRangeDate: Date[];
  IsDisabledEditBooking: boolean;
  
  IsDisabledDeleteBooking: boolean;
  IsDisabledReplaceBooking:boolean;
  oldjobid: string;
  RequestMode: number;
  LoggedInResourceID : number;
  IsCurrentEditWeek : boolean;
  IsReplacedEngagement : number;
  IsDisabledAddBooking : number;
  isExpandable: boolean;
  InstanceId:number;
  enabledWeekDay: Date;
  MeraBookingId : number;
  IsOverallocation:boolean;
  IsOverallocation_1:boolean;
  IsOverallocation_2:boolean;
  IsOverallocation_3:boolean;
  IsOverallocation_4:boolean;
  IsOverallocation_5:boolean;
  IsDisableViewBooking_Res:boolean;
  UiViewSource:string;
  ActualBookingId:number | null;
  ParentBookingId:number | null;
  B_TYPE: string;
  Cancelable: boolean;
  MeraActionDescriptionId: number;

  IsExistingBooking: boolean;
  IsExistingBooking_2: boolean;
  IsExistingBooking_3: boolean;
  IsExistingBooking_4: boolean;
  IsExistingBooking_5: boolean;
}
