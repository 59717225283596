<div class="clearFilters">
  <span>Expand / Collapse to view the complete details.</span>
  <mat-icon class="filterIcon" title="Clear Filters" (click)="ClearAllSelectdVals()">clear_all</mat-icon>
</div>
<form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
  <div class="popupLayout aproveSection">
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="sectionLabel">Resource</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="resSection">
        <div class="fullWidthSection">
          <div class="linkContainer">
            <label>Link</label>
            <mat-checkbox [disableRipple]='true' (change)="makeDirty()" [(ngModel)]="resEditLink"
              [ngModelOptions]="{standalone:true}"></mat-checkbox>
          </div>
        </div>
        <div class="multiOption">
          <div class="linkedItems">
            <fieldset [ngClass]="{ 'bf': resEditLink == true }">
              <legend *ngIf="resEditLink == true" class="lgnd">
                Link
              </legend>
              <div class="slct-three-items">
                <label class="mb-2">Managerial Country</label>
                <ng-multiselect-dropdown [placeholder]="'Select Managerial Country'" [settings]="dropdownSettings"
                  [data]="resEditManageCountryList" [(ngModel)]="resEditManageCountrySelectedItems"
                  (onSelect)="makeDirty()" (onDeSelect)="makeDirty()" [ngModelOptions]="{standalone: true}">
                </ng-multiselect-dropdown>
              </div>
              <div class="slct-three-items">
                <label class="mb-2">Legal Entity</label>
                <ng-multiselect-dropdown [placeholder]="'Select Legal Entity'"
                  [(ngModel)]="resEditLegalEntitySelectedItems" [settings]="dropdownSettings"
                  [data]="resEditLegalEntityList" (onSelect)="makeDirty()" (onDeSelect)="makeDirty()"
                  [ngModelOptions]="{standalone: true}">
                </ng-multiselect-dropdown>
              </div>
              <div class="slct-three-items">
                <label class="mb-2">Business Unit</label>
                <ng-multiselect-dropdown [placeholder]="'Select Business Unit'"
                  [(ngModel)]="resEditBusinessUnitSelectedItems" [settings]="dropdownSettings"
                  [data]="resEditBusinessUnitList" (onSelect)="makeDirty()" (onDeSelect)="makeDirty()"
                  [ngModelOptions]="{standalone: true}">
                </ng-multiselect-dropdown>
              </div>
              <div class="slct-three-items">
                <label class="mb-2">Operating Unit</label>
                <ng-multiselect-dropdown [placeholder]="'Select Operating Unit'"
                  [(ngModel)]="resEditOperatingUnitSelectedItems" [settings]="dropdownSettings"
                  [data]="resEditOperatingUnitList" (onSelect)="makeDirty()" (onDeSelect)="makeDirty()"
                  [ngModelOptions]="{standalone: true}">
                </ng-multiselect-dropdown>
              </div>
              <div class="slct-three-items">
                <label class="mb-2">Management Unit</label>
                <ng-multiselect-dropdown [placeholder]="'Select Management Unit'"
                  [(ngModel)]="resEditManagementUnitSelectedItems" [settings]="dropdownSettings"
                  [data]="resEditManagementList" (onSelect)="makeDirty()" (onDeSelect)="makeDirty()"
                  [ngModelOptions]="{standalone: true}">
                </ng-multiselect-dropdown>
              </div>
              <div class="slct-three-items">
                <label class="mb-2">Location</label>
                <ng-multiselect-dropdown [placeholder]="'Select Location'" [(ngModel)]=" resEditLocationSelectedItems"
                  [settings]="dropdownSettings" [data]="resEditLocationList" (onSelect)="makeDirty()"
                  (onDeSelect)="makeDirty()" [ngModelOptions]="{standalone: true}">
                </ng-multiselect-dropdown>
              </div>
              <div class="slct-three-items">
                <label class="mb-2">Cost center</label>
                <ng-multiselect-dropdown [placeholder]="'Select Cost Center'"
                  [(ngModel)]="resEditCostCenterSelectedItems" [settings]="dropdownSettings"
                  [data]="resEditCostCenterList" (onSelect)="makeDirty()" (onDeSelect)="makeDirty()"
                  [ngModelOptions]="{standalone: true}">
                </ng-multiselect-dropdown>
              </div>
              <div class="slct-three-items">
                <label class="mb-2">HR Department</label>
                <ng-multiselect-dropdown [placeholder]="'Select HR Department'" [(ngModel)]="resEditDepartmentSelectedItems"
                  [settings]="dropdownSettings" [data]="resEditDepartmentList" (onSelect)="makeDirty()" (onDeSelect)="makeDirty()"
                  [ngModelOptions]="{standalone: true}">
                </ng-multiselect-dropdown>
              </div>
              <div class="slct-three-items">
                <label class="mb-2">GDS Classification</label>
                <ng-multiselect-dropdown [placeholder]="'Select GDS Classification'" [(ngModel)]="resEditGdsRegionAlignmentSelectedItems"
                  [settings]="dropdownSettings" [data]="resEditGdsRegionAlignment" (onSelect)="makeDirty()" (onDeSelect)="makeDirty()"
                  [ngModelOptions]="{standalone: true}">
                </ng-multiselect-dropdown>
              </div>
              <div class="slct-three-items">
                <label class="mb-2">Service Line</label>
                <ng-multiselect-dropdown [placeholder]="'Select Service Line'" [(ngModel)]="resEditServiceLineSelectedItems"
                  [settings]="dropdownSettings" [data]="resEditServiceLineList" (onSelect)="makeDirty()"
                  [ngModelOptions]="{standalone: true}" (onDeSelect)="makeDirty()">
                </ng-multiselect-dropdown>
              </div>
              <div class="slct-three-items">
                <label class="mb-2">Sub Service Line</label>
                <ng-multiselect-dropdown [placeholder]="'Select Sub Service Line'"
                  [(ngModel)]="resEditSubServiceLineSelectedItems" [settings]="dropdownSettings"
                  [data]="resEditSubServiceLineList" (onSelect)="makeDirty()" (onDeSelect)="makeDirty()"
                  [ngModelOptions]="{standalone: true}">
                </ng-multiselect-dropdown>
              </div>
              <div class="slct-three-items">
                <label class="mb-2">Sub Management Unit</label>
                <ng-multiselect-dropdown [placeholder]="'Select Sub Management Unit'"
                  [(ngModel)]="resEditSubManagementUnitSelectedItems" [settings]="dropdownSettings"
                  [data]="resEditSubManagementUnitList" (onSelect)="makeDirty()" (onDeSelect)="makeDirty()"
                  [ngModelOptions]="{standalone: true}">
                </ng-multiselect-dropdown>
              </div>
              <div class="slct-three-items">
                <label class="mb-2">Rank</label>
                <ng-multiselect-dropdown [placeholder]="'Select Rank'" [(ngModel)]="resEditRankSelectedItems"
                  [settings]="dropdownSettings" [data]="resEditRankList" (onSelect)="makeDirty()" (onDeSelect)="makeDirty()"
                  [ngModelOptions]="{standalone: true}">
                </ng-multiselect-dropdown>
              </div>
              
            </fieldset>
          </div>
          <div class="twoItemsInRow">
            
            
          </div>
          <div class="twoItemsInRow">
            
            
          </div>
          <div class="twoItemsInRow">
            
          </div>
          <div class="twoItemsInRow">
            
            
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="sectionLabel">Engagement</div>
        </mat-panel-title>

      </mat-expansion-panel-header>
      <div class="engSection">
      <div class="fullWidthSection">
        
        <div class="linkContainer">
          <label>Link</label>
          <mat-checkbox [disableRipple]='true' (change)="makeDirty()" [(ngModel)]="jobEditLink"
            [ngModelOptions]="{standalone:true}"></mat-checkbox>
        </div>
      </div>
      <div class="multiOption">
        <div class="linkedItems">
          <fieldset [ngClass]="{ 'bf': jobEditLink == true }">
            <legend *ngIf="jobEditLink == true" class="lgnd">
              Link
            </legend>
            <div class="slct-three-items">
              <label class="mb-2">Engagement Category</label>
              <ng-multiselect-dropdown [placeholder]="'Select Engagement Category'"
                [(ngModel)]="jobEditJobCategorySelectedItems" [settings]="dropdownSettings"
                [data]="jobEditJobCategoryList" (onSelect)="makeDirty()" (onDeSelect)="makeDirty()"
                [ngModelOptions]="{standalone: true}">
              </ng-multiselect-dropdown>
            </div>
            <div class="slct-three-items">
              <label class="mb-2">Engagement Type</label>
              <ng-multiselect-dropdown [placeholder]="'Select Engagement Type'"
                [(ngModel)]="jobEditJobCodeSelectedItems" [settings]="dropdownSettings" [data]="jobEditJobCodeList"
                (onSelect)="makeDirty()" (onDeSelect)="makeDirty()" [ngModelOptions]="{standalone: true}">
              </ng-multiselect-dropdown>
            </div>
            <div class="slct-three-items">
              <label class="mb-2">Managerial Country</label>
              <ng-multiselect-dropdown [placeholder]="'Select Managerial Country'"
                [(ngModel)]="jobEditManageCountrySelectedItems" [settings]="dropdownSettings"
                [data]="jobEditManageCountryList" (onSelect)="makeDirty()" (onDeSelect)="makeDirty()"
                [ngModelOptions]="{standalone: true}">
              </ng-multiselect-dropdown>
            </div>
            <div class="slct-three-items">
              <label class="mb-2">Legal Entity</label>
              <ng-multiselect-dropdown [placeholder]="'Select Legal Entity'"
                [(ngModel)]="jobEditLegalEntitySelectedItems" [settings]="dropdownSettings"
                [data]="jobEditLegalEntityList" (onSelect)="makeDirty()" (onDeSelect)="makeDirty()"
                [ngModelOptions]="{standalone: true}">
              </ng-multiselect-dropdown>
            </div>
            <div class="slct-three-items">
              <label class="mb-2">Business Unit</label>
              <ng-multiselect-dropdown [placeholder]="'Select Business Unit'"
                [(ngModel)]="jobEditBusinessUnitSelectedItems" [settings]="dropdownSettings"
                [data]="jobEditBusinessUnitList" (onSelect)="makeDirty()" (onDeSelect)="makeDirty()"
                [ngModelOptions]="{standalone: true}">
              </ng-multiselect-dropdown>
            </div>
            <div class="slct-three-items">
            <label class="mb-2">Operating Unit</label>
            <ng-multiselect-dropdown [placeholder]="'Select Operating Unit'"
              [(ngModel)]="jobEditOperatingUnitSelectedItems" [settings]="dropdownSettings"
              [data]="jobEditOperatingUnitList" (onSelect)="makeDirty()" (onDeSelect)="makeDirty()"
              [ngModelOptions]="{standalone: true}">
            </ng-multiselect-dropdown>
          </div>
          <div class="slct-three-items">
            <label class="mb-2">Management Unit</label>
            <ng-multiselect-dropdown [placeholder]="'Select Management Unit'"
              [(ngModel)]="jobEditManagementUnitSelectedItems" [settings]="dropdownSettings"
              [data]="jobEditManagementUnitList" (onSelect)="makeDirty()" (onDeSelect)="makeDirty()"
              [ngModelOptions]="{standalone: true}">
            </ng-multiselect-dropdown>
          </div>
          <div class="slct-three-items">
            <label class="mb-2">Sub Management Unit</label>
            <ng-multiselect-dropdown [placeholder]="'Select Sub Management Unit'"
              [(ngModel)]="jobEditSubManagementUnitSelectedItems" [settings]="dropdownSettings"
              [data]="jobEditSubManagementUnitList" (onSelect)="makeDirty()" (onDeSelect)="makeDirty()"
              [ngModelOptions]="{standalone: true}">
            </ng-multiselect-dropdown>
          </div>
          <div class="slct-three-items">
            <label class="mb-2">Service Line</label>
            <ng-multiselect-dropdown [placeholder]="'Select Service Line'" [(ngModel)]="jobEditServiceLineSelectedItems"
              [settings]="dropdownSettings" [data]="jobEditServiceLineList" (onSelect)="makeDirty()"
              (onDeSelect)="makeDirty()" [ngModelOptions]="{standalone: true}">
            </ng-multiselect-dropdown>
          </div>
          <div class="slct-three-items">
            <label class="mb-2">Sub Service Line</label>
            <ng-multiselect-dropdown [placeholder]="'Select Sub Service Line'"
              [(ngModel)]="jobEditSubServiceLineSelectedItems" [settings]="dropdownSettings"
              [data]="jobEditSubServiceLineList" (onSelect)="makeDirty()" (onDeSelect)="makeDirty()"
              [ngModelOptions]="{standalone: true}">
            </ng-multiselect-dropdown>
          </div>
          </fieldset>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  </mat-accordion>

    <div class="confirmationButtons">
      <button class="cancelBtn" type="button" (click)="clearForm()">Cancel</button>
      <button class="deleteBtn" type="submit" [ngClass]="{'btn-disabled': dirtyFlag === false}" [disabled]="dirtyFlag === false">{{buttonText}}</button>
    </div>
  </div>
</form>