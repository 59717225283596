import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ResourceDto } from '../../../Models/ResourceDto';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserVM } from '../../../Models/UserVM';
import { SharedServicesService } from '../../sharedservices/shared-services.service';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { LoginService } from '../../../landing/login.service';
import { IndividualService } from 'app/Views/individual/individual.service';
import { RoleFeatureDto } from '../../../Models/RoleFeatureDto';

@Component({
  selector: 'app-header',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  currentUser: any;
  currentUserEmailId: string;
  faqFileLocation: string;
  meraSharepointUrl: string =
    'https://eygb.sharepoint.com/sites/RegionalMERADeploymentSharePointProjectTeam';
  resource: ResourceDto;
  bookingListSubscription: Subscription;
  faqFileSubscription: Subscription;
  modalRef: BsModalRef;
  modalOption: ModalOptions = {};
  showEmailToggle: boolean;
  public isUserLoggedIn: boolean = false;
  TokenSubscription: Subscription;
  showMobileMenu: boolean = false;
  selectedMenu: string;
  isWorkRequest: boolean = false;
  isCompletedRequest: boolean = false;
  isRoleConfiguration: boolean = false;
  isMenu: boolean = false;
  isApprovalQueue: boolean = false;
  isMobileDevice: boolean = false;
  userProfilePhotoUrl: any = null;
  @ViewChild('toggleMenu') toggleMenu: ElementRef;
  @ViewChild('mobileMenu') mobileMenu: ElementRef;
  reportLinks: [];
  profileIcon: string = '../../../../assets/images/profile_icon_big.png';

  featureMenus: (RoleFeatureDto & {
    SubFeatures: RoleFeatureDto[];
    SubFeatureNames: string;
  })[];

  constructor(
    public restService: SharedServicesService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private modalService: BsModalService,
    private loginService: LoginService,
    private individualService: IndividualService,
    private renderer: Renderer2
  ) {
    this.resource = new ResourceDto();
    this.restService.activeLink = 'Home';
    this.renderer.listen('window', 'click', (e: Event) => {
      /**
       * Only run when toggleButton is not clicked
       * If we don't check this, all clicks (even on the toggle button) gets into this
       * section which in the result we might never see the menu open!
       * And the menu itself is checked here, and it's where we check just outside of
       * the menu and button the condition abbove must close the menu
       */
      if (
        !this.toggleMenu?.nativeElement.contains(e.target) &&
        !this.mobileMenu?.nativeElement.contains(e.target)
      ) {
        this.showMobileMenu = false;
      }
    });
  }

  ngOnInit() {
    this.user.EmailSubscription = false;
    this.restService.activeLink = 'Home';
    this.loginService.isMenuShown.subscribe((data) => (this.isMenu = data));
    this.loginService.loggedUser.subscribe((data) => {
      this.currentUserEmailId = data.email;
      this.currentUser = JSON.parse(sessionStorage.getItem('resource'));
      if (this.currentUser) {
        this.currentUserEmailId = this.currentUser.Email;
        this.resource.isPrivacyNoticePage = this.currentUser.PrivacyNotice;
        if (this.currentUser.PrivacyNotice) {
          this.featureMenus = this.currentUser.RoleFeatures.filter(
            (x) => x.IsMenu && (!x.FeatureParentID || x.FeatureParentID == 0)
          ).sort((a, b) => (a.FeatureOrder > b.FeatureOrder ? 1 : -1));

          this.featureMenus.forEach((fr) => {
            fr.SubFeatures = this.currentUser.RoleFeatures.filter(
              (x) => x.IsMenu && x.FeatureParentID == fr.FeatureID
            ).sort((a, b) => (a.FeatureOrder > b.FeatureOrder ? 1 : -1));
            fr.SubFeatureNames = fr.SubFeatures.map((x) => x.FeatureName).join(
              ','
            );
          });

          this.getFAQFileLocation();
          this.getReportLink();
          // if(this.currentUser.RoleType !== 'Client Server'){
          //   this.getReportLink();
          // }
          //this.isWorkRequest = this.currentUser.RoleFeatures.includes('WorkRequestQueue');
          this.isWorkRequest =
            this.currentUser.RoleFeatures.find(
              (x) => x.FeatureName == 'WorkRequestQueue'
            ) != null;
          // this.isCompletedRequest = this.currentUser.RoleFeatures.includes('CompletedRequest');
          this.isCompletedRequest =
            this.currentUser.RoleFeatures.find(
              (x) => x.FeatureName == 'CompletedRequest'
            ) != null;
          // this.isRoleConfiguration = this.currentUser.RoleFeatures.includes('RoleConfiguration');
          this.isRoleConfiguration =
            this.currentUser.RoleFeatures.find(
              (x) => x.FeatureName == 'RoleConfiguration'
            ) != null;
          // this.isApprovalQueue = this.currentUser.RoleFeatures.includes('ApprovalLayer');
          this.isApprovalQueue =
            this.currentUser.RoleFeatures.find(
              (x) => x.FeatureName == 'ApprovalLayer'
            ) != null;
          this.isMobileDevice =
            JSON.parse(sessionStorage.getItem('requestMode')) === 1
              ? true
              : false;
        }
        this.individualService
          .getProfilePhoto(this.currentUser.Email)
          .subscribe((resPhoto) => {
            this.userProfilePhotoUrl = resPhoto;
          });
      }
    });
  }
  isViewEnabled(mobileFeature) {
    if (mobileFeature.SubFeatures && mobileFeature.SubFeatures.length > 0) {
      if (mobileFeature.FeatureName === 'Report' && this.isMobileDevice) {
        return false;
      } else {
        return true;
      }
    }
  }
  isSubMenuViewEnabled(mobileFeature, subMenu) {
    if (mobileFeature.SubFeatures && mobileFeature.SubFeatures.length > 0) {
      if (mobileFeature.FeatureName === 'Queues' && this.isMobileDevice) {
        if (subMenu === 'MyRequest') {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
  }
  getAccess(link) {
    if (this.currentUser && this.currentUser.RoleFeatures) {
      return this.currentUser.RoleFeatures.includes(link);
    } else {
      return false;
    }
  }
  toggleMobileMenu() {
    this.showMobileMenu = !this.showMobileMenu;
  }
  toggleDropDown(menu) {
    this.selectedMenu = menu;
  }
  linkNavigation(page) {
    this.toggleMobileMenu();
    this.selectedMenu = page;
    //this.restService.activeLink = page;
    this.router.navigate([`/${page}`]);
  }
  linkNavigation_New(fr) {
    if (fr.FeaturePath && fr.FeaturePath.length > 0) {
      if(fr.FeatureName === 'AvailabilityReportCSP' || fr.FeatureName === "BookedTimeReportCSP"){
        let reportLink = fr.FeaturePath;
      reportLink = reportLink.replaceAll(
        '[[RESOURCE_NAME]]',
        this.currentUser.ResourceName
      );
      window.open(reportLink, '_blank');
      }else{
        window.open(fr.FeaturePath, '_blank');
      }
    } else {
      this.toggleMobileMenu();
      this.selectedMenu = fr.FeatureName;
      //this.restService.activeLink = page;
      this.router.navigate([`/${fr.FeatureName}`]);
    }
  }
  public user: UserVM = new UserVM();

  successfulEmailToggle: boolean;
  rejectedEmailToggle: boolean;

  openModal(template) {
    this.showMobileMenu = false;
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.modalRef = this.modalService.show(template, this.modalOption);
  }
  navigateToMera() {
    window.location.href =
      'https://eygb.sharepoint.com/sites/RegionalMERADeploymentSharePointProjectTeam';
  }
  logout() {
    this.router.navigate(['Logout']);

    // if (sessionStorage.getItem("resource") !== null
    // && sessionStorage.getItem("resource") !== undefined
    // && environment.EnableAuth
    // ) {
    //   this.isUserLoggedIn = true;
    //   this.TokenSubscription = this.restService.blacklistToken().subscribe( res => {
    //     sessionStorage.clear();
    //     const url = environment.logoutUrl;
    //     window.location.href = url;
    //   });
    // }
    // else{
    //   sessionStorage.clear();
    //     const url = environment.logoutUrl;
    //     window.location.href = url;
    // }

    //  //this.restService.logoutToken().subscribe((res) => { });
  }

  getFAQFileLocation() {
    let faqFile = sessionStorage.getItem('FAQFileLocation');
    if (
      faqFile != null &&
      faqFile != undefined &&
      faqFile != 'undefined' &&
      faqFile != ''
    ) {
      this.faqFileLocation = faqFile;
    } else {
      this.faqFileSubscription = this.restService
        .getFAQFileLocation()
        .subscribe((data) => {
          this.faqFileLocation = data;
          sessionStorage.setItem('FAQFileLocation', this.faqFileLocation);
        });
    }
  }

  getReportLink() {
    this.restService.getReportLink().subscribe((data) => {
      console.log('report', data);
      this.reportLinks = data;
      const cspReportLinks = data.filter(
        (x) => x.RoleTypeName === 'Client Server'
      );
      sessionStorage.setItem('cspReportLinks', JSON.stringify(cspReportLinks));
    });
  }

  goToFAQ() {
    window.open(this.faqFileLocation, '_blank');
  }
  goToMERASharepoint() {
    window.open('https://sites.ey.com/sites/MERAGlobal', '_blank');
  }

  goToReports(link) {
    window.open(link, '_blank');
  }
}
