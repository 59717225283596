import { EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { UiViewSourceEnum } from 'app/shared/enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { BookingGridActionService } from '../../../Models/BookingGridActionService';
import { EngagementBookingVM } from '../../../Models/EngagementBookingVM';
import { DeleteBookingData, IndividualBookingVM } from '../../../Models/IndividualBookingVM';
import { EngagementService } from '../../engagement/engagement.service';
import { IndividualService } from '../individual.service';

@Component({
  selector: 'app-delete-parent',
  templateUrl: './delete-parent.component.html',
  styleUrls: ['./delete-parent.component.css']
  //, encapsulation: ViewEncapsulation.None       // commented for Notes Description tooltip css to work properly.
})
export class DeleteParentComponent implements OnInit {

  bookingList: IndividualBookingVM[] = [];
  deleteBookingsList: DeleteBookingData[] = [];
  bookingList1: IndividualBookingVM[];
  bookingListSubscription: Subscription;
  submitSubscription: Subscription;
  buttonText = "Submit";
  hourLoadingLabel = "Hours";
  parentDataItem: any;
  parentIVDataItem: IndividualBookingVM;
  parentEVDataItem: EngagementBookingVM;
  @Input() isPercentSelected: boolean;
  @Input() gridActionEventData: BookingGridActionService;
  @Input() parentViewIVorEV: string;
  @Output() isFormDirtyFlag: EventEmitter<boolean> = new EventEmitter<boolean>();
  chkSelectAll = false;
  resource;
  UiViewSource:string;

  constructor(private individualServices: IndividualService, 
  private engagementService: EngagementService,
  private spinner: NgxSpinnerService) {   
  }

  ngOnInit() {
    this.resource = JSON.parse(sessionStorage.getItem('resource'));
    this.hourLoadingLabel = this.isPercentSelected ? "Loading %" : "Hours";    
    this.parentDataItem = this.gridActionEventData.dataItem;
    this.parentDataItem.IsPercentSelected = this.isPercentSelected;

    if(this.parentViewIVorEV == "IV"){
      this.getIVBookingList();
      this.UiViewSource = UiViewSourceEnum.IndividualView;
    }
    else{
      this.getEVBookingList();
      this.UiViewSource = UiViewSourceEnum.EngamentView;
    }       
    this.isFormDirtyFlag.emit(false);
  }
  
  ngOnDestroy() {    
    if (this.bookingListSubscription !== undefined) {
      this.bookingListSubscription.unsubscribe();
    }
    if (this.submitSubscription !== undefined) {
      this.submitSubscription.unsubscribe();
    }
  }

  getIVBookingList() {
    if(!this.parentDataItem) return;
    this.spinner.show();
    this.bookingListSubscription = 
    this.individualServices.fetchBookingChildData(this.parentDataItem).subscribe(
      data => {
        this.SetFetchedData(data);
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    );
  }
  
  getEVBookingList() {
    if(!this.parentDataItem) return;
    this.spinner.show();
    this.bookingListSubscription = 
    this.engagementService.fetchBookingChildData(this.parentDataItem).subscribe(
      data => {
        this.SetFetchedData(data);
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    );
  }
  
  private SetFetchedData(data){
    this.bookingList = data;
    this.bookingList.forEach((element) => {
      this.deleteBookingsList.push(
        {
          BookingId: element.BookingId,
          ResourceId: element.ResourceId,
          JobId: element.JobId,
          JobCategory: element.JobCategory,
          BookingType: element.BookingType,
          Description: element.Description,
          StartDate: new Date(element.StartDate).toDateString(),
          EndDate: new Date(element.EndDate).toDateString(),
          Hours: element.Hours,
          Loading: element.Loading,
          Unconfirmed: element.BookingType.includes('Unconfirmed'),
          Ghost: element.Ghost,
          IsPercentSelected: this.isPercentSelected,
          IsCurrentWeekDelete: element.IsCurrentWeekDelete,
          IsSelected: !element.IsCurrentWeekDelete,
          DateRange: [new Date(element.StartDate), new Date(element.EndDate)]
        }
      );
    });
    this.checkChangeButtonText();
  }

  public changeSelectAll(event) {
    this.deleteBookingsList.forEach((item) => {
      if(!item.IsCurrentWeekDelete) {
        item.IsSelected = event.checked;
      }
    });    
    this.checkChangeButtonText();
    this.isFormDirtyFlag.emit(true);
  }
  
  public changeCheck(item, event) {
    this.deleteBookingsList.find(x => 
      x.BookingId == item.BookingId).IsSelected = event.checked;
    this.checkChangeButtonText();    
    this.isFormDirtyFlag.emit(true);
  }

  private checkChangeButtonText() {
    this.buttonText = this.deleteBookingsList.findIndex(x => x.IsSelected) > -1
    ? "Submit" : "Select bookings to delete!";
    this.chkSelectAll = this.deleteBookingsList.filter(x => !x.IsCurrentWeekDelete).length == 
      this.deleteBookingsList.filter(x => !x.IsCurrentWeekDelete && x.IsSelected).length;
  }
  
  submit() {
    this.buttonText="Saving...";
    // let deleteBookings = {
    //   IsPercentSelected: this.isPercentSelected, 
    //   InstanceId: this.bookingList[0].InstanceId,
    //   RequestMode: JSON.parse(sessionStorage.getItem('requestMode')),
    //   BookingIds: this.deleteBookingsList.filter(x => x.IsSelected).map(x => x.BookingId),
    //   UiViewSource : this.UiViewSource,
    //   ActualBookingIds:  null,//todo: this.deleteBookingsList.filter(x => x.IsSelected).map(x => x.ActualBookingId),
    //   ParentBookingIds: null //todo: this.deleteBookingsList.filter(x => x.IsSelected).map(x => x.ActualBookingId),
    // };
    let bookingDtos: any[] = [];
    this.deleteBookingsList.filter(x => x.IsSelected).forEach((element) => {
      let bk = this.bookingList.find(x => x.BookingId == element.BookingId)
      if(bk && bk !== undefined) {
        bk.MeraActionDescriptionId = 4;
        bk.ActualBookingId = bk.BookingId;
        bk.ParentBookingId = 0;
        bookingDtos.push(bk);
      }
    });
    let bookingActionDto = {
      Bookings: bookingDtos,
      IsPercentSelected: this.isPercentSelected, 
      RequestMode:JSON.parse(sessionStorage.getItem('requestMode')),
      UiViewSource: this.UiViewSource
    };
    this.submitSubscription = 
    this.individualServices.DeleteBookings(bookingActionDto).subscribe(
      (data: any) => {
        if (data.status = '200') {
          let response = data.body;
          if (response.customResultId > 0) {
            if(this.parentViewIVorEV == "IV"){
              this.individualServices.setRquestNumber(response.customResultId);
            }
            else{
               this.engagementService.setRquestNumber(response.customResultId);
            }
          }
          if(this.parentViewIVorEV == "IV"){
            this.individualServices.closeModal(true);          
          }
          else{
             this.engagementService.closeModal(true);          
          }          
        }
        this.buttonText = "Submit";
      }, err => {
        this.buttonText = "Error! Please try again.";
      }, () => {
        this.buttonText = "Submit";
      }
    );
  }
}
