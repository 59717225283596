export class ApprovalBookingVM {
    Select: boolean;
    ActionId: number ;
    ActionType: string;
    ResourceName: string;
    RequestId:Number;
    MeraBookingId:Number;
    Engagement: string;
    Hours: number;
    StartDate: string;
    EndDate: string;
    Age: Number;
    SubmittedOn: Date;
    BookingId:Number;
    IsOverallocation: boolean;
    InstanceId:Number;
    startDate_sort:string;
    endDate_sort:string;
    IsDisabledViewBooking_Job: boolean;
    IsDisabledViewBooking_Res: boolean;
    Description: string;
}
export class ApprovalReqestVM {
    Select: boolean;
    RequestNumber: string;
    ActionId: number ;
    ActionType: string;
    ResourceName: string;
    ResourceID: Number;
    RequestId:Number;
    MeraBookingId:Number;
    InstanceId:Number;
    Rank: string;
    ServiceLine: string;
    SubServiceLine: string;
    Engagement: string;
    Description: string;
    PercentLoading: number;
    Hours: number;
    StartDate: Date | null;
    EndDate: Date | null;
    Age: Number;
    Unconfirmed: string;
    Status: string;
    RequestedTime: Date | null;
    CompletedTime: Date | null;
    HoursCompletedIn: number;
    UpdatedBy:string;
    OfficeLocation : string;
    RequestorName : string;
    EngagementCategory: string;  
    SubAreaDescription: string;
    SubManagementUnit: string;
    RequestMode: number;
    RequestModeDisplay: string;
    InstanceName: string;
    SubmittedOn: Date;
    BookingId:Number;
    IsOverallocation: boolean;
}
export class ApprovalQueueParams {
    StartDate: Date | null;
    EndDate: Date | null;
    StartRequestedOn: Date | null;
    EndRequestedOn: Date | null;
    JobCategory: string;
    ActionDescription: string;
    IsPercentSelected: boolean;
}

export class ApproveRejectParmsModel {
    AppApprovalComments:string;
    LoggedInUserId: Number;
    IsApprove: boolean;
    RequestId:Number;
    MeraBookingId: Number;
    InstanceID: Number;
}