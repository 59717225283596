<div *ngIf="isPostLogout" class="appLayout postLogout">  
  <p>
    <mat-icon class="infoIcon">info</mat-icon>
  </p>
  <p class="infoText">
    {{ postLogoutMsg }}
    <br /> <br/>

    <span class="navigationlink" (click)="navigateToLogin()">
      Click here
    </span> to Sign In again.
  </p>
</div>
