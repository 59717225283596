import { Component, OnInit, Output, Input, EventEmitter, OnDestroy } from '@angular/core';
import { IndividualService } from '../individual.service';
import { Subscription } from 'rxjs';
import { IndividualBookingVM } from '../../../Models/IndividualBookingVM';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BookingGridActionService } from '../../../Models/BookingGridActionService';
import { Response } from '../../../Models/Response';
import * as moment from 'moment'; 
// import { ApplicationInsightService } from 'src/applicationInsightService';
import { NgxSpinnerService } from 'ngx-spinner';
import { UiViewSourceEnum } from 'app/shared/enum';
//import { CurrentWeekVM } from 'src/app/Models/CurrentWeekVM';
import { CurrentWeekActionDTO } from '../../../Models/CurrentWeekActionDTO';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent implements OnInit, OnDestroy {
  @Input() currentWeekActionDTO: CurrentWeekActionDTO;
  @Input() public calledFromChild: boolean;
  bookingType = '';
  engagementCode = '';
  Engagement = '';
  engagementAllocationText = '';
  engagementAllocationTypeValue: any;
  description = '';
  loading = '';
  startDate: Date = null;
  endDate: Date = null;
  hours: number;
  bookingId: number;
  resourceId: number;
  jobId: number;
  unconfirmed: boolean;
  ghost: boolean;
  engagementAllocationPercentSelected = false;
  closeModalWindow = false;
 // formGroup: FormGroup;
  booking: IndividualBookingVM;
  response: Response;
  eventData: BookingGridActionService;
  editBookingSubscription: Subscription;
  engagementStatus = '';
  engagementServiceLine = '';
  currentUserName: any;
  currentUserId: any;
  currentUserEmail: any;
  hoursValidationResultList: boolean[];
  isHoursValid: boolean = true;
  confirmHours: boolean = false;
  isValidationRequired: boolean = false;
  buttonText = "Submit";
  startOfWeek = new Date();
  overallocationerror: boolean = true;
  LoggedInResourceID: number;
  instanceId:number;
  formGroup = this.formBuilder.group({
    period1 : this.formBuilder.group({
      start1 : [this.startOfWeek, Validators.required],
      end1 : [this.startOfWeek, Validators.required]
    }),
    editLoading: new FormControl('', [
      Validators.required, Validators.pattern(/^(?!(0))[0-9]*(\.[0-9]{0,2})?$/),
      //Validators.max(999)
    ]),
    bookingType: [''],
    engagementCode: [''],
    engagement: [''],
    engagementServiceLine: [''],
    engagementStatus: [''],
    unconfirmed:[''],
    ghost:[''],
    description:['']
  });
  resource;
  isPercentageSelected = false;
  today = new Date();
  todaysDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), 0, 0, 0);
  ActualBookingId:number = 0;
  ParentBookingId:number = 0;

  constructor(private individualServices: IndividualService, 
    private spinner: NgxSpinnerService,private formBuilder: FormBuilder) {
   
  }

   ngOnInit() {     
    // this.getCurrentWeek();
    this.enabledWeek(!this.currentWeekActionDTO.IsDisabledEditBookingCurrWeek);
    this.resource = JSON.parse(sessionStorage.getItem('resource'));
    this.currentUserName = this.resource.ResourceName;
    this.currentUserEmail = this.resource.Email;
    this.currentUserId = sessionStorage.getItem("resourceId");
    // this.applicationInsightsService.logPageView("Individual View Edit Bookings");
    this.booking = new IndividualBookingVM();
    this.response = new Response();
    this.individualServices.editEvent.subscribe((data) => {
      this.eventData = data;
      this.SetData(this.eventData);
    });
    this.individualServices.currentMessageEngagementAllocation.subscribe(isPercentageSelected => {
      this.isPercentageSelected=isPercentageSelected
      this.setEngagementAllocation(isPercentageSelected);
    });
    this.individualServices.currentMessageCloseModal.subscribe(closeModal => {
      this.closeModalWindow = closeModal;
    });
    this.individualServices.currentMessageCloseChildModal.subscribe(closeChildModal => {
      this.closeModalWindow = closeChildModal;
    });
  }

  SetData(rowdata: BookingGridActionService) {
    this.formGroup.reset();
    this.bookingId = rowdata.dataItem.BookingId;
    this.resourceId = rowdata.dataItem.ResourceId;
    this.jobId = rowdata.dataItem.JobId;
    this.instanceId= rowdata.dataItem.InstanceId;
    //todo: remove comment after getapi contains ActualBookingId,ParentBookingId
    //this.ActualBookingId = rowdata.dataItem.ActualBookingId;
    //this.ParentBookingId = rowdata.dataItem.ParentBookingId;
    this.loading = rowdata.dataItem.Loading;
    this.hours = rowdata.dataItem.Hours;
    this.formGroup.controls.bookingType.setValue(rowdata.dataItem.BookingType);
    this.formGroup.controls.engagement.setValue(rowdata.dataItem.Engagement);
    this.formGroup.controls.engagementCode.setValue(rowdata.dataItem.EngagementCode);
    this.formGroup.controls.engagementServiceLine.setValue(rowdata.dataItem.ServiceLineDescription);
    this.formGroup.controls.engagementStatus.setValue(rowdata.dataItem.JobStatus);
    this.formGroup.controls.description.setValue(rowdata.dataItem.Description);
    this.formGroup.get('period1').get('start1').setValue( rowdata.dataItem.StartDate);
    this.formGroup.get('period1').get('end1').setValue( rowdata.dataItem.EndDate);
    this.loading = rowdata.dataItem.Loading;
    this.enabledWeek(!rowdata.dataItem.IsCurrentEditWeek);
    if (this.bookingType.includes('Unconfirmed')) {
      this.formGroup.controls.unconfirmed.setValue(true);
    } else {
      this.formGroup.controls.unconfirmed.setValue(false);
    }
    this.formGroup.controls.ghost.setValue(rowdata.dataItem.Ghost);
    this.setEngagementAllocation(this.engagementAllocationPercentSelected);
   
  }
  
  mouseEnter() {
    if (this.engagementAllocationPercentSelected) {
      //this.engagementAllocationTypeValue = this.engagementAllocationTypeValue.replace('% ', '');
    }
  }

  mouseLeave() {
    if (this.engagementAllocationPercentSelected) {
      this.engagementAllocationTypeValue = this.engagementAllocationTypeValue;
    }
  }

  setEngagementAllocation(isPercentageSelected) {
    if (!isPercentageSelected) {
      this.engagementAllocationText = 'Hours';
      this.formGroup.controls.editLoading.setValue(this.hours); 
       } else {
      this.engagementAllocationText = 'Loading %';
      if (this.loading !== '' && this.loading != null) {
        this.formGroup.controls.editLoading.setValue(this.loading); 
      }
    }
    this.engagementAllocationPercentSelected = isPercentageSelected;
  }

  onEditSubmit() {
    if(this.formGroup.get('period1').get('start1').value && this.formGroup.get('period1').get('end1').value){
      this.formGroup.get('period1').get('start1').setValidators(null);
      this.formGroup.get('period1').get('start1').setErrors(null);
      this.formGroup.get('period1').get('end1').setValidators(null);
      this.formGroup.get('period1').get('end1').setErrors(null);
    }
    if (!this.formGroup.invalid) {
      this.isHoursValid = true;
      this.booking.InstanceId= this.instanceId;
      this.booking.ResourceId = this.resourceId;
      this.booking.StartDate = (this.formGroup.get('period1').get('start1').value);
      this.booking.EndDate = (this.formGroup.get('period1').get('end1').value);
      this.booking.BookingId = this.bookingId;
      if (this.engagementAllocationPercentSelected) {
        this.booking.Loading = this.formGroup.get('editLoading').value;
        this.booking.IsPercentSelected = true;
        this.booking.Hours = this.hours;
      } else {
        this.booking.Loading = +this.loading;
        this.booking.Hours = this.formGroup.get('editLoading').value;
      }
      this.booking.InstanceId = this.instanceId;
      this.isHoursValid = true;
      if(this.isValidationRequired)
      {
        this.spinner.show();
        this.editBookingSubscription = this.individualServices.validateHours(this.booking).subscribe(
          (data: any) => {
            this.hoursValidationResultList = data;
            if(this.hoursValidationResultList[0] !== undefined && !this.hoursValidationResultList[0])
            {
              this.formGroup.get('editLoading').setErrors({'incorrect':true});
              this.isHoursValid=false;  
              this.overallocationerror = false;            
            }
            if(this.isHoursValid)
            {
              this.save();
            }
            else if(!this.isHoursValid && this.confirmHours)
            {
                this.save();
            }
            this.spinner.hide();
          }
        );
      }
      else
      {
        this.save();
      }
    }
  }

  public confirmHoursChange(checkboxSelected: string) {
    if(this.formGroup.get(checkboxSelected).value != ''){
      this.formGroup.get(checkboxSelected).setErrors(null)
    }
  } 

  @Output() isFormDirtyFlag : EventEmitter <boolean> = new EventEmitter<boolean>();
  
  public dirtyFlag=false;
  
  public makeDirty(controlName: string) {
    if(!this.dirtyFlag)
    {
      this.dirtyFlag=true;
      this.isFormDirtyFlag.emit(this.dirtyFlag);
    }  
    if(controlName=='Period' || controlName=='Hours' || controlName=='Unconfirmed')
    {
      this.isValidationRequired = true;
    }

    if(this.overallocationerror == false && controlName == 'Hours')
        {  
      this.overallocationerror = true;  
        }

  }

  
  save() {
    this.buttonText="Submitting...";
    // this.booking.ResourceId = this.resourceId;
    // this.booking.BookingId = this.bookingId;
    // this.booking.JobId = this.jobId;
    // this.booking.BookingType = this.formGroup.get('bookingType').value;
    // this.booking.Description = this.formGroup.get('description').value;
    // this.booking.StartDate = (this.formGroup.get('period1').get('start1').value);
    // this.booking.EndDate = (this.formGroup.get('period1').get('end1').value);
    // this.booking.ActionDescriptionId = 2;
    // this.booking.MeraBookingStatusId = 5;
    // this.booking.Unconfirmed = this.formGroup.get('unconfirmed').value;
    // if(!this.resource.Ghost){
    //   this.booking.Ghost = this.formGroup.get('ghost').value;
    // }
    // if (this.engagementAllocationPercentSelected) {
    //   this.booking.Loading = this.formGroup.get('editLoading').value;
    //   this.booking.IsPercentSelected = true;
    //   this.booking.Hours = this.hours;
    // } else {
    //   this.booking.Loading = +this.loading;
    //   this.booking.Hours = this.formGroup.get('editLoading').value;
    // }
    // this.booking.currentUserId = +sessionStorage.getItem('resourceId');
    // this.booking.CurrentUserEmail = this.currentUserEmail;
    // this.booking.CurrentUserName = this.currentUserName;
    // this.booking.IsOverallocation = this.confirmHours;
    // this.booking.RequestMode = JSON.parse(sessionStorage.getItem('requestMode'));

    let bookingDtos: any[] = [];
  
        bookingDtos.push({
          BookingId:  this.bookingId,
          ResourceId: this.resourceId,
          JobId: this.jobId,
          BookingType: this.formGroup.get('bookingType').value,
          Description: this.formGroup.get('description').value,
          StartDate:  (this.formGroup.get('period1').get('start1').value),
          EndDate: (this.formGroup.get('period1').get('end1').value),
          Hours: !this.engagementAllocationPercentSelected ? this.formGroup.get('editLoading').value: this.hours,
          Loading: this.isPercentageSelected ? this.formGroup.get('editLoading').value  : this.loading,
          Unconfirmed:  this.formGroup.get('unconfirmed').value,
          Ghost: this.formGroup.get('ghost').value,
          IsOverallocation:this.confirmHours,
          currentUserId : +sessionStorage.getItem('resourceId'),
          CurrentUserEmail : this.currentUserEmail,
          CurrentUserName : this.currentUserName,
          ActionDescriptionId : 2,
          MeraBookingStatusId : 5,
          InstanceId: this.instanceId,
          ActualBookingId : this.ActualBookingId,
          ParentBookingId : this.ParentBookingId
        })

    let bookingActionDto = {
      IsPercentSelected: this.isPercentageSelected, 
      RequestMode: JSON.parse(sessionStorage.getItem('requestMode')),
      //InstanceId: this.instanceId,
      Bookings: bookingDtos,
      UiViewSource: UiViewSourceEnum.IndividualView
    };
    
    this.editBookingSubscription = this.individualServices.editBooking(bookingActionDto).subscribe(
      (data: any) => {
        this.spinner.hide();
        if (data.status = '200') {
          this.response = data.body;
          if (this.response.customResultId > 0) { this.individualServices.setRquestNumber(this.response.customResultId); }
          //alert('Successfully saved the changes');
          if (this.calledFromChild) {
            this.individualServices.closeChildModal(true);
          } else {
            this.individualServices.closeModal(true);
          }
        }
        this.buttonText = "Submit";
      }, err => {
        this.buttonText = "Error";
      }, () => {
        this.buttonText = "Submit";
      });
    
    this.dirtyFlag = false;
  }
  isValidInput(fieldName): boolean {
    return this.formGroup.controls[fieldName].invalid &&
      (this.formGroup.controls[fieldName].dirty || this.formGroup.controls[fieldName].touched);
  }
  ngOnDestroy() {
    if (this.editBookingSubscription !== undefined) {
      this.editBookingSubscription.unsubscribe();
    }
  }

  setStartDateTime(inputDate) {
    let newDate = inputDate;
    if (newDate.getHours() === 0) {
      newDate.setHours(newDate.getHours() + 9);
      newDate.setMinutes(newDate.getMinutes() + 30);
    }
    return newDate;
  }

  setEndDateTime(inputDate) {
    let newDate = inputDate;
    if (newDate.getHours() === 0) {
      newDate.setHours(newDate.getHours() + 17);
      newDate.setMinutes(newDate.getMinutes() + 30);
    }
    return newDate;
  }

  //CurrentWeekVM : CurrentWeekVM;
  // EditWeek : number = 0;
  enabledWeek(isCurrentWeekEditable){

    if(this.currentWeekActionDTO.IsDisabledEditBookingCurrWeek === true){
      isCurrentWeekEditable = false;
    }

    this.startOfWeek = this.getFirstDateOfWeek(this.todaysDate);
    if(isCurrentWeekEditable){
      this.startOfWeek.setMinutes(this.startOfWeek.getMinutes() - this.startOfWeek.getTimezoneOffset());
    }
    else{
      this.startOfWeek.setDate(this.startOfWeek.getDate() + 7);
      this.startOfWeek.setMinutes(this.startOfWeek.getMinutes() - this.startOfWeek.getTimezoneOffset());
    }

    // const dayNeeded = 7 //For Saturday
    // const currentDay = moment().isoWeekday();
    // if(this.EditWeek === 1){
    //   isCurrentWeekEditable = false;
    // }
    // if(isCurrentWeekEditable){
    //   if (currentDay === dayNeeded) { 
    //     let day = moment().day(dayNeeded);
    //     this.startOfWeek = new Date(day.year(),day.month(),day.date())
    //   }else{
    //     let day = moment().subtract(1, 'weeks').isoWeekday(dayNeeded);
    //     this.startOfWeek = new Date(day.year(),day.month(),day.date());  
    //   }
    // }else{
    //   if (currentDay === dayNeeded) { 
    //     let day = moment().add(1, 'weeks').isoWeekday(dayNeeded);
    //     this.startOfWeek = new Date(day.year(),day.month(),day.date());
    //   }else{
    //     let day = moment().day(dayNeeded);
    //     this.startOfWeek = new Date(day.year(),day.month(),day.date());
         
    //   }
    // }
    
  }

  getFirstDateOfWeek(anyDateOfWeek: Date): Date {
    // date.getDay() RETURNS Week Day Index: SUN 0, MON 1, TUE 2, WED 3, THU 4, FRI 5, SAT 6.
    let date = new Date(anyDateOfWeek);
    let firstDayOfWeekIndex:number = 6; //Week Day Index: SUN 0, MON 1, TUE 2, WED 3, THU 4, FRI 5, SAT 6.
    let daysToShiftBack = (7 + (date.getDay() - firstDayOfWeekIndex)) % 7;
    let firstDateOfWeek = new Date(date.setDate(date.getDate() - daysToShiftBack));
    return firstDateOfWeek;
  }

  // getCurrentWeek() {
  //   this.spinner.show();
  //   this.editBookingSubscription = this.individualServices.getCurrentWeek().subscribe(
  //     (data: any) => {
  //       this.EditWeek = data.EditWeek; 
  //       if(this.EditWeek == 1 ){
  //         this.enabledWeek(false);
  //       }
  //       if(this.EditWeek == 0 || this.EditWeek == null)
  //       {
  //         this.enabledWeek(true);
  //       }      
  //     });
  //     this.spinner.hide();
  // }

}
