
export class WorkRequestQueueVM {
    BookingId: number;
    BookingType: string;
    BookingStatus: string;
    ResourceId: number | null;
    RequestId: number | null;
    ResourceName: string;
    GPN: string;
    ResourcesSL: string;
    ResourceSSL: string;
    ResourceSMU: string;
    ActionDescriptionId: number;
    ActionDescription: string;
    IsJobActive: boolean;
    JobId: number | null;
    JobCode: string;
    JobDescription: string;
    JobCategory: string;
    JobStatus: string;
    ServiceLineCode: string;
    ServiceLineDescription: string;
    SubServiceLineDescription: string;
    SubManagementUnitDescription: string;
    Department: string;
    CostCenter: string;
    ServiceLine: string;
    Rank: number;
    LocationDescription: string;
    SubArea : string;
    RequestMode : string;
    Hours: number | null;
    Loading: number | null;
    UserSelection: string;
    StartDate: string;
    EndDate: string;
    SubmittedOn: string;
    MeraBookingStatusId: number;
    Unconfirmed: string;
    Description: string;
    UpdatedOn: Date | null;
    UpdatedBy: string;
    EngagementCode: string;
    Engagement: string;
    EngagementType: string;
   
    ClientDescription: string;
    JobType: string;
    RankDescription: string;

    isInProgress: boolean;
    CurrentUserId: number;
    StatusId: number;
    StatusDescription: string;
    CurrentUserProcessing: boolean;

    ChildCount :boolean;
    LockedByOthers  :boolean;
    RetainNoteDescription :string;

    Action: string;
    MeraBookingId: number;
    Done: boolean;
    NotDone: boolean;

    CurrentUserName: string;
    CurrentUserEmailId: string;

    TimeDifference : number;

    MeraRejectionReason : any | null;
    RetainStatusComments : any| null;
    RejectionCommentId : any | null;

    RejectionReasons : any | null;
    RejectionReasonsID : any | null;

    RejectionCommentSelected : string | null;
    SelectedRejectedReason : string | null;
     LoggedInResourceId: number;
    Ghost: string;
    startDate_sort:string;
    endDate_sort:string;
    SubmittedOn_sort:string;
}
