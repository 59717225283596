<ngx-spinner></ngx-spinner>
<ng-template style="z-index: 1060;" #template1>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Inactivity Logout</h4>
    <mat-icon class="close" (click)="closeModal()">close</mat-icon>
  </div>
  
  <div class="modal-body">
    <div class="popupLayout">
      <div class="fullWidth">
          You will be Signed Out shortly due to inactivity. 
          <br/>
          <br/>
          Time remaining: {{minutesDisplay}}:{{secondsDisplay}}
      </div>

      <div class="confirmationButtons">
        <button class="cancelBtn" (click)="confirmLogout()">Sign Out</button>
        <button class="deleteBtn" (click)="closeModal()">Stay Active</button>
      </div>
    </div>
  </div>
</ng-template>