<div class="alignCenter" *ngIf="chartLoading">
  <mat-spinner class="spinner-inner-table" [diameter]="40"></mat-spinner>
</div>
<div class="alignCenter" *ngIf="!isData">
  {{ chartNoDataText }}
</div>
<div *ngIf="isData">
  <div class="wallchartContent">
    <div class="wallChartTableHeader">
      <table class="headerTable">
        <tbody>
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr *ngFor="let eg of userEngagementData">
            <td>
              <span title="{{ eg.name }}">
                {{ trimEngagemntName(eg.name) }}
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span
                style="font-weight: bold"
                title="Values displayed could differ based on whether bookings are made as a weekly or block booking. Weekly bookings are recommended."
                >Total Hours/Loading %</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="wallChartDataTable">
      <table class="dataTable">
        <tbody>
          <tr>
            <td
              *ngFor="let wd of weekDates; let dateIndex = index"
              [ngStyle]="{
                'border-right':
                  (dateIndex + 1) % 7 > 0
                    ? '1px solid #74747f'
                    : '1px solid #444'
              }"
            >
              {{ wd.date | date: "dd MMM" }}
            </td>
            <!-- <td style="width: 350px">&nbsp;</td> -->
          </tr>
          <tr>
            <td
              *ngFor="let wd of weekDates; let dayIndex = index"
              [ngStyle]="{
                'border-right':
                  (dayIndex + 1) % 7 > 0
                    ? '1px solid #74747f'
                    : '1px solid #444'
              }"
            >
              {{ wd.day }}
            </td>
            <!-- <td style="width: 350px">&nbsp;</td> -->
          </tr>
          <tr *ngFor="let eg of userEngagementData">
            <td
              *ngFor="let wd of weekDates; let wdIndex = index"
              (mouseover)="selectItem(eg, wd)"
              (click)="selectItem(eg, wd)"
              [popover]="eg.dates.indexOf(wd.date) >= 0 ? tooltipTemplate : ''"
              [placement]="'right'"
              [outsideClick]="true"
              [triggers]="'mouseover click'"
              #pop="bs-popover"
              (onShown)="closePrevious(pop)"
              (mouseleave)="closePrevious(pop)"
              [ngStyle]="{
                backgroundColor:
                  eg.dates.indexOf(wd.date) >= 0
                    ? eg.dict[wd.date + '_' + eg.name][
                        eg.dict[wd.date + '_' + eg.name].length - 1
                      ].color
                    : '',
                'border-right':
                  (wdIndex + 1) % 7 > 0 ? '1px solid #74747f' : '1px solid #444'
              }"
            >
              &nbsp;
            </td>
            <!-- <td style="width: 350px">&nbsp;</td> -->
          </tr>
          <tr *ngIf="isHourSelected">
            <td
              *ngFor="let weeklyBookingTotal of weeklyBookingTotalHours"
              colspan="7"
              style="border-right: 1px solid #444; text-align: center"
            >
              <b>{{ weeklyBookingTotal }}</b>
            </td>
            <!-- <td style="width: 350px">&nbsp;</td> -->
          </tr>
          <tr *ngIf="isPercentageSelected">
            <td
              *ngFor="let weeklyBookingTotal of weeklyBookingTotalPercent"
              colspan="7"
              style="border-right: 1px solid #444; text-align: center"
            >
              <b>{{ weeklyBookingTotal }}<span>%</span></b>
            </td>
            <!-- <td style="width: 350px">&nbsp;</td> -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #tooltipTemplate>
  <div
    *ngIf="selectedItem?.dates.indexOf(day.date) >= 0"
    style="max-height: 350px; overflow-y: auto; font-size: small; padding: 4px"
  >
    <ng-container
      *ngFor="
        let chartBooking of selectedItem?.dict[
          day.date + '_' + selectedItem.name
        ];
        let cbIndex = index
      "
    >
      <ul class="wallchart-cell-hover-details-list">
        <li *ngIf="chartBooking.IsReplaced == 1">
          From Resource :
          {{
            chartBooking.FromResourceName
              ? chartBooking.FromResourceName
              : " - "
          }}
        </li>
        <li *ngIf="chartBooking.IsReplaced == 1">
          To Resource :
          {{
            chartBooking.ToResourceName ? chartBooking.ToResourceName : " - "
          }}
        </li>
        <li
          *ngIf="
            chartBooking.IsReplacedEngagement == 1 &&
            chartBooking.JobId == chartBooking.To_Job_Id
          "
        >
          From Client :
          {{
            (chartBooking.From_Client_Description
              ? chartBooking.From_Client_Description
              : "") +
              " - " +
              (chartBooking.From_Client_Number
                ? chartBooking.From_Client_Number
                : "")
          }}
        </li>
        <li
          *ngIf="
            chartBooking.IsReplacedEngagement == 1 &&
            chartBooking.JobId == chartBooking.To_Job_Id
          "
        >
          From Engagement :
          {{
            chartBooking.From_Engagement_Name
              ? chartBooking.From_Engagement_Name
              : ""
          }}
        </li>
        <li
          *ngIf="
            chartBooking.IsReplacedEngagement == 1 &&
            chartBooking.JobId == chartBooking.From_Job_Id
          "
        >
          To Client :
          {{
            (chartBooking.To_Client_Description
              ? chartBooking.To_Client_Description
              : "") +
              " - " +
              (chartBooking.To_Client_Number
                ? chartBooking.To_Client_Number
                : "")
          }}
        </li>
        <li
          *ngIf="
            chartBooking.IsReplacedEngagement == 1 &&
            chartBooking.JobId == chartBooking.From_Job_Id
          "
        >
          To Engagement :
          {{
            chartBooking.To_Engagement_Name
              ? chartBooking.To_Engagement_Name
              : ""
          }}
        </li>
        <li *ngIf="chartBooking.ClientName != null">
          Client Name :
          {{ chartBooking.ClientName ? chartBooking.ClientName : " - " }}
        </li>
        <li>
          Engagement :
          {{
            chartBooking.EngagementName ? chartBooking.EngagementName : " - "
          }}
        </li>
        <li>
          Instance :
          {{ chartBooking.InstanceName ? chartBooking.InstanceName : " - " }}
        </li>
        <li
          *ngIf="
            chartBooking.IsReplaced != 1 || chartBooking.MeraBookingId != 0
          "
        >
          Start Date :
          {{ chartBooking.OrgStartDate | date: defaultDateFormat }}
        </li>
        <li
          *ngIf="
            chartBooking.IsReplaced != 1 || chartBooking.MeraBookingId != 0
          "
        >
          End Date :
          {{ chartBooking.OrgEndDate | date: defaultDateFormat }}
        </li>
        <li
          *ngIf="
            isHourSelected &&
            (chartBooking.IsReplaced != 1 ||
              chartBooking.IsReplacedEngagement != 1 ||
              chartBooking.MeraBookingId != 0)
          "
        >
          Hours : {{ chartBooking.Hours }}
        </li>
        <li
          *ngIf="
            isPercentageSelected &&
            (chartBooking.IsReplaced != 1 ||
              chartBooking.IsReplacedEngagement != 1 ||
              chartBooking.MeraBookingId != 0)
          "
        >
          Loading : {{ chartBooking.Loading }}%
        </li>
        <li
          *ngIf="
            chartBooking.Action != null ||
            chartBooking.IsReplacedEngagement == 1
          "
        >
          Action :
          {{
            chartBooking.IsReplacedEngagement == 1
              ? "Replace"
              : chartBooking.Action
          }}
        </li>
        <li
          class="text-nowrap"
          *ngIf="chartBooking.StatusId != 0 && chartBooking.StatusId == 10"
        >
          Status : {{ chartBooking.StatusDescription }}
        </li>
        <li class="text-nowrap" *ngIf="chartBooking.Description != ''">
          Description : {{ chartBooking.Description }}
        </li>
      </ul>
    </ng-container>
  </div>
</ng-template>
<!-- <ng-template #templateWallchart>
  <div class="modal-header">
    <h4 class="modal-title pull-left">WallChart</h4>
    <mat-icon (click)="modalRef.hide()" class="close">close</mat-icon>
  </div>
  <div class="modal-body modal-wallChart">
    <div class="wallChartDescription">
      You can navigate through the engagement list in the wallchart by clicking
      on the left side of your mouse, and whilst holding it down drag the
      pointer up and down in the wallchart. Alternatively, scrolling up and down
      is possible for those using touch screens.
    </div>
    <br />
    <table class="wallChartInfoTable">
      <tr>
        <th>Engagement Category</th>
        <th>Confirmed</th>
        <th>Unconfirmed*</th>
      </tr>
      <tr>
        <td>Chargeable</td>
        <td><span class="blueLabel"></span></td>
        <td><span class="lightBlueLabel"></span></td>
      </tr>
      <tr>
        <td>Authorised Project</td>
        <td><span class="yellowLabel"></span></td>
        <td><span class="lightYellowLabel"></span></td>
      </tr>
      <tr>
        <td>Non-Chargeable</td>
        <td><span class="greenLabel"></span></td>
        <td><span class="lightGreenLabel"></span></td>
      </tr>
      <tr>
        <td>Training Courses from Success Factors</td>
        <td><span class="purpleLabel"></span></td>
        <td></td>
      </tr>
      <tr>
        <td>Requests yet to be processed</td>
        <td><span class="greyLabel"></span></td>
        <td></td>
      </tr>
      <tr>
        <td>
          Overlap between processed request and pending request. Pending request
          yet to be processed.
        </td>
        <td><span class="ZigZagLabel"></span></td>
        <td></td>
      </tr>
    </table>
    <br />
    <div class="wallChartFooter">
      <p>Total hours = Sum of existing weekly bookings in Retain.</p>
      <p>* Unconfirmed field is not applicable for Americas and APAC.</p>
    </div>
  </div>
</ng-template> -->
