import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, BehaviorSubject, forkJoin } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { AuthService } from 'app/shared/auth/auth.service';
import { environment } from 'environments/environment';
import { ConfigureVM } from '../../Models/ApprovalQueueConfigVm';
import { BookingGridActionService } from '../../Models/BookingGridActionService';
import { ReturnStatement } from '@angular/compiler';

@Injectable({
  providedIn: 'root',
})
export class ApproveConfigService {
    endpoint = environment.apiEndpoint;
    loggedUser = JSON.parse(sessionStorage.getItem('resource'));
    instanceId = this.loggedUser.DefaultInstance;
    approvalQueueObj:any;
    approvalQueueGridData:any;
    constructor(private http: HttpClient, private auth: AuthService) {}
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }
  private getAPI(actionName: string): string {
    this.auth.checkTokenExpiredAndRefresh();
    return this.endpoint + 'ApprovalLayer/' + actionName;
  }

  private getMasterAPI(actionName: string): string {
    this.auth.checkTokenExpiredAndRefresh();
    return this.endpoint + 'Master/' + actionName;
  }

  closeModalWindow: boolean = false;
  private messageCloseModal = new BehaviorSubject(this.closeModalWindow);
  currentMessageCloseModal = this.messageCloseModal.asObservable();
  private eventData = new BookingGridActionService();
  private configureApprovalQueue = new BehaviorSubject(this.eventData);
  configureEvent = this.configureApprovalQueue.asObservable();
  openConfigureApproveQueueModal(eventData: BookingGridActionService) {
    this.configureApprovalQueue.next(eventData)
  }
  closeModal(exitModal) {
    this.messageCloseModal.next(exitModal);
  }
  getMasterData(id : number): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.set('InstanceId', id.toString());
    return this.http.get<any>(this.getAPI('FetchMasterDataForApprovalQueueConfiguration'),
    { params, headers }).pipe(
        map(data => data),
        catchError(this.handleError<any>('FetchMasterDataForApprovalQueueConfiguration'))
      );      
  }

  fetchApprovalStatus(): Observable<any> {
    return this.http.post<any>(this.getAPI('/FetchApprovalStatus'),
    this.httpOptions).pipe(
      map(data => data),
      catchError(this.handleError<any>('FetchApprovalStatus'))
    );
  }

  getUserApprovalQueueConfig(id : number): Observable<ConfigureVM[]> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.set('InstanceId', id.toString());
    return this.http.get<any>(this.getAPI('UserApprovalQueueConfigurationData'),
    { params, headers }).pipe(
        map(data => data),
        catchError(this.handleError<any>('UserApprovalQueueConfigurationData'))
      );      
  }

  saveApprovalQueueConfig(config : ConfigureVM): Observable<any> {
    return this.http.post<any>(this.getAPI('ConfigureApprovalQueue'), config,
    { headers: this.httpOptions.headers }).pipe(
      map(data => data),
      catchError(this.handleError<any>('ConfigureApprovalQueue'))
    );
  }
  getData(): Observable<any> {
    const response1 = this.getUserApprovalQueueConfig(this.instanceId);
    const response2 = this.getMasterData(this.instanceId);
    return forkJoin ([response1,response2]);
  }

  getUserApprovalQueueConfigStatus(): Observable<any>{
    return this.http.get<any>(this.getAPI('CheckUserApprovalQueueDataExists'),
    this.httpOptions).pipe(
      map(data => data),
      catchError(this.handleError<any>('CheckUserApprovalQueueDataExists'))
    );
  }

  getApprovalQueueList(params): Observable<any>{
    return this.http.post<any>(this.getAPI('FetchApprovalBookingReqests'),params,
    { headers: this.httpOptions.headers }).pipe(
      map(data => data),
      catchError(this.handleError<any>('FetchApprovalBookingReqests'))
    );
  }

  getApprovalResDetails(resourceId,instanceId): Observable<any>{
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    // let params = new HttpParams();
    // params = params.set('ResourceId', id.toString());
    return this.http.get<any>(this.getAPI('FetchApprovalResourceDetails/'+resourceId.toString() +'/'
    + instanceId.toString()),
    { headers }).pipe(
      map(data => data),
      catchError(this.handleError<any>('FetchApprovalResourceDetails'))
    );
  }

  getApprovalJobDetails(jobId,instanceId): Observable<any>{
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    // let params = new HttpParams();
    // params = params.set('JobId', id.toString());
    return this.http.get<any>(this.getAPI('FetchApprovalJobDetails/'+ jobId.toString()+'/'
            + instanceId.toString()),
    { headers }).pipe(
      map(data => data),
      catchError(this.handleError<any>('FetchApprovalJobDetails'))
    );
  }

  //Fetching bookingReqdetails 
  getBookingReqDetails(params): Observable<any>{
    return this.http.post<any>(this.getAPI('FetchApprovalBookingRequestsDetails'),params,
    { headers: this.httpOptions.headers }).pipe(
      map(data => data),
      catchError(this.handleError<any>('FetchApprovalBookingRequestsDetails'))
    );
  }

  // Approve-Reject API
  saveApproveRejectReqDetails(params){
    return this.http.post<any>(this.getAPI('SaveApprovalAndRejectBookingRequest'),params,
    { headers: this.httpOptions.headers }).pipe(
      map(data => data),
      catchError(this.handleError<any>('SaveApprovalAndRejectBookingRequest'))
    );
  }

  fetchCategoryActionData(){
    return this.http.get<any>(this.getMasterAPI('FetchEngagementCategoryAndActionDescription'),
    this.httpOptions).pipe(
      map(data => data),
      catchError(this.handleError<any>('FetchEngagementCategoryAndActionDescription'))
    );
  }

  saveApprovalQueueRequestSelected(data:any){
    this.approvalQueueObj=null;
    if(data != null && data != undefined)
    {
      this.approvalQueueObj = {
        InstanceId: data.instanceId,
        ResourceID: data.resourceId,  
        MeraBookingId: data.meraBookingId, 
        BookingId: data.bookingId ,
        startDate_sort: data.startDate, 
        endDate_sort: data.endDate ,
        IsOverallocation: data.isOverallocation , 
        HasMultipleAction: data.hasMultipleAction,
        RequestId : data.requestId,
        IsDisabledViewBooking_Res : data.isDisabledViewBooking_Res,
        IsDisabledViewBooking_Job : data.isDisabledViewBooking_Job,
        JobDescription : data.engagementName,
        Description : data.Description
      }
    }
   }
   
   retrieveApprovalQueueRequestSelected(){
    return this.approvalQueueObj;
   }

   setApprovalQueueGridData(data){
     this.approvalQueueGridData=null;
     if (data != null && data != undefined) {
       this.approvalQueueGridData = data;
     }
  }

   getApprovalQueueGridData(){
    return this.approvalQueueGridData;
  }

  
}