import { Component, OnInit } from '@angular/core';
import { SharedServicesService } from '../shared/sharedservices/shared-services.service';
import { ResourceDto } from '../Models/ResourceDto';
import { LoginService } from '../landing/login.service';

@Component({
  selector: 'app-access-error',
  templateUrl: './access-error.component.html',
  styleUrls: ['./access-error.component.scss']
})
export class AccessErrorComponent implements OnInit {

  resource: ResourceDto;
  
  constructor(public sharedService: SharedServicesService,private loginService : LoginService) { }

  ngOnInit() {    
    this.resource = new ResourceDto();
    // refresh Header & Footer
    this.sharedService.setHeaderDetails(this.resource);
    this.sharedService.setFooterDetails(this.resource);
    this.loginService.setMenuHideAndShow(false);
  }
}
