<ngx-spinner></ngx-spinner>
<div class="appLayout role-configuration">
  <div
    *ngIf="!isRALoggedIn || (AssignRoleAllowed && UpdateEmailAllowed)"
    class="rad-grp-container"
  >
    <mat-radio-group name="AccessOptions" (change)="onRadioChange($event)">
      <span *ngFor="let option of AccessOptions">
        <mat-radio-button
          *ngIf="option.isAllowed"
          [checked]="option.checked"
          value="{{ option.value }}"
        >
          {{ option.name }}
        </mat-radio-button>
      </span>
      <!-- <mat-radio-button
        *ngFor="let option of AccessOptions"
        [checked]="option.checked"
        value="{{ option.value }}"
        >{{ option.name }}
      </mat-radio-button> -->
    </mat-radio-group>
  </div>

  <div class="top-row" *ngIf="radioSelected == '1' || radioSelected == '2'">
    <div
      *ngIf="radioSelected == '2' && AssignRoleAllowed"
      class="row pt-10 pb-10"
    >
      <div style="display: flex; justify-content: flex-end">
        <span class="px-2" *ngIf="!disableReportIcon">
          <mat-icon
            title="Export excel with resources detail that are currently assigned to the selected role"
            (click)="allData()"
            >print</mat-icon
          >
        </span>
        <span
          class="px-2"
          *ngIf="
            selectedRole != null
              ? selectedRole.RoleTypeName == roleTypeEnum.ResourceAdmin
              : false
          "
        >
          <mat-icon title="Audit" (click)="onAuditClick(AuditReport)"
            >insert_drive_file</mat-icon
          >
        </span>
      </div>

      <ng-template #AuditReport>
        <div class="auditmodel">
          <div class="modal-header">
            <h4 class="modal-title pull-left">
              <label>Access Role: </label>
              {{ selectedRole ? selectedRole.R_Name : "" }}
            </h4>
            <mat-icon style="cursor: pointer" (click)="modalRef.hide()"
              >close</mat-icon
            >
          </div>
          <div class="modal-body">
            <app-audit-assign-role
              [accessRoleId]="accessRoleId"
            ></app-audit-assign-role>
          </div>
        </div>
      </ng-template>
    </div>

    <div *ngIf="radioSelected == '1'" class="row pt-10 pb-10">
      <div class="actions-frb">
        <span
          class="px-2"
          (click)="OnClickAction(actionNameEnum.Add, null, true)"
        >
          <mat-icon title="Add new role configuration">add_box</mat-icon>
        </span>
        <span
          *ngIf="accessRoleId"
          class="px-2"
          (click)="OnClickAction(actionNameEnum.Edit, null, true)"
        >
          <mat-icon title="Edit selected role configuration">edit</mat-icon>
        </span>
        <span
          *ngIf="accessRoleId"
          class="px-2"
          (click)="OnClickAction(actionNameEnum.Copy, null, true)"
        >
          <mat-icon title="Copy selected role configuration"
            >file_copy</mat-icon
          >
        </span>
        <span
          *ngIf="accessRoleId"
          class="px-2"
          (click)="
            OnClickAction(
              actionNameEnum.Delete,
              RoleConfigurationDeleteConfirmation,
              false
            )
          "
        >
          <mat-icon title="Delete selected role configuration">delete</mat-icon>
        </span>
        <span class="px-2">
          <mat-icon title="Info" (click)="openModal(RoleConfigurationHelp)"
            >info</mat-icon
          >
        </span>
      </div>
      <ng-template #RoleConfigurationHelp>
        <div class="modal-header">
          <h4 class="modal-title pull-left">Role Configuration</h4>
          <mat-icon style="cursor: pointer" (click)="modalRef.hide()"
            >close</mat-icon
          >
        </div>
        <div class="modal-body">
          <p>Use the action buttons to update the role configuration</p>
          <div class="py-2 icon-text-wrap">
            <span class="px-2">
              <mat-icon title="Add new role configuration">add_box</mat-icon>
            </span>
            <span class="px-1" style="font-size: 13px"
              >Add a new role configuration</span
            >
          </div>
          <div class="py-2 icon-text-wrap">
            <span class="px-2">
              <mat-icon title="Edit selected role configuration">edit</mat-icon>
            </span>
            <span class="px-1" style="font-size: 13px"
              >Edit an existing role configuration</span
            >
          </div>
          <div class="py-2 icon-text-wrap">
            <span class="px-2">
              <mat-icon title="Copy selected role configuration"
                >file_copy</mat-icon
              >
            </span>
            <span class="px-1" style="font-size: 13px"
              >Copy an existing role configuration</span
            >
          </div>
          <div class="py-2 icon-text-wrap">
            <span class="px-2">
              <mat-icon title="Delete selected role configuration"
                >delete</mat-icon
              >
            </span>
            <span class="px-1" style="font-size: 13px"
              >Delete a role configuration</span
            >
          </div>
        </div>
      </ng-template>

      <ng-template #RoleConfigurationDeleteConfirmation>
        <div class="modal-header">
          <h4 *ngIf="messageBody == ''" class="modal-title pull-left">
            Confirm
          </h4>
          <h4 *ngIf="messageBody != ''" class="modal-title pull-left">
            Delete Role Configuration
          </h4>
          <mat-icon
            style="cursor: pointer"
            (click)="modalRef.hide()"
            title="Delete selected role configuration"
            >close
          </mat-icon>
        </div>
        <div class="modal-body">
          <div *ngIf="messageBody == ''">
            <div>Are you sure you want to delete the role configuration?</div>
            <div class="btn-container">
              <button class="cancelBtn" (click)="modalRef.hide()">
                <span class="glyphicon glyphicon-remove-sign pr-5"></span>No
              </button>
              <button class="deleteBtn" (click)="onConfirmAction()">
                <span class="glyphicon glyphicon-ok pr-5"></span>Yes
              </button>
            </div>
          </div>
          <div *ngIf="messageBody != ''">
            {{ this.messageBody }}
          </div>
        </div>
        <!-- <div class="modal-body" >
          <div>Are you sure you want to delete the record?</div>
          <div class="btn-container">
            <button class="cancelBtn" (click)="modalRef.hide()">
              <span class="glyphicon glyphicon-remove-sign pr-5"></span>No
            </button>
            <button class="deleteBtn" (click)="onConfirmAction()">
              <span class="glyphicon glyphicon-ok pr-5"></span>Yes
            </button>
          </div>
        </div> -->
      </ng-template>
    </div>

    <div
      *ngIf="
        radioSelected == '1' || (radioSelected == '2' && AssignRoleAllowed)
      "
      class="search-row"
    >
      <div>
        <label>Access Role: </label>
        {{ selectedRole ? selectedRole.R_Name : "" }}
      </div>
      <div>
        <label>Role Type: </label>
        {{ selectedRole ? selectedRole.RoleTypeName : "" }}
      </div>
      <div class="search-box">
        <mat-form-field class="resourceSearch" appearance="outline">
          <input
            type="text"
            [placeholder]="
              isRALoggedIn ? selectedRole.R_Name : 'Search Role Name...'
            "
            matInput
            appSearchDebounce
            (debounceKeyup)="search()"
            [(ngModel)]="searchBar"
            autocomplete="off"
            [disabled]="isRALoggedIn"
          />
          <mat-icon (click)="resetSearchBar()">close</mat-icon>
        </mat-form-field>
        <div class="dropdown-list-wrap-ar" *ngIf="showSearchList">
          <mat-spinner *ngIf="showSearchLoader" [diameter]="40"></mat-spinner>
          <ul *ngIf="showSearchList" class="dropdown-list">
            <li
              *ngFor="let item of accessRoleNameList"
              (click)="onItemSelect(item)"
              class="dropdown-list-item"
            >
              {{ item.R_Name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <app-add-role-configuration
    *ngIf="
      accessRoleId == 0 &&
      radioSelected == '1' &&
      (actionSelected === actionNameEnum.Add) === true
    "
  >
  </app-add-role-configuration>
  <app-edit-role-configuration
    (onMessageClear)="onMessageClear()"
    [RoleId]="accessRoleId"
    [selectedRoleTypeName]="selectedRole.RoleTypeName"
    [SaveErrorMessage]="SaveErrorMessage"
    [SaveSuccessMessage]="SaveSuccessMessage"
    [actionSelected]="actionSelected"
    *ngIf="
      radioSelected == '1' &&
      (actionSelected === actionNameEnum.Edit ||
        actionSelected === actionNameEnum.Copy ||
        actionSelected === actionNameEnum.View ||
        actionSelected === actionNameEnum.Delete)
    "
  >
  </app-edit-role-configuration>
  <app-assign-role
    [selectedRole]="selectedRole"
    *ngIf="
      radioSelected == '2' &&
      (loggedinUserRoleTypeName === roleTypeEnum.SuperAdmin ||
        (AssignRoleAllowed &&
          loggedinUserRoleTypeName === roleTypeEnum.ResourceAdmin))
    "
  >
  </app-assign-role>
  <app-view-resource-role *ngIf="radioSelected == '3'">
  </app-view-resource-role>
  <app-update-resource-email
    *ngIf="
      radioSelected == '4' &&
      (loggedinUserRoleTypeName === roleTypeEnum.SuperAdmin ||
        (UpdateEmailAllowed &&
          loggedinUserRoleTypeName === roleTypeEnum.ResourceAdmin))
    "
  >
  </app-update-resource-email>
</div>
