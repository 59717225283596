import { Component, OnInit } from '@angular/core';
import { ResourceDto } from 'app/Models/ResourceDto';
import { OrderPipe } from 'ngx-order-pipe';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { RoleConfigService } from '../role-config.service';

@Component({
  selector: 'app-view-resource-role',
  templateUrl: './view-resource-role.component.html',
  styleUrls: ['./view-resource-role.component.scss'],
})
export class ViewResourceRoleComponent implements OnInit {
  resourceList: ResourceDto[] = [];
  resourceRoleModel: ResourceDto = new ResourceDto();
  subscription: Subscription;
  searchSubscription: Subscription;
  searchBar: string;
  showSearchList: boolean = false;
  showSearchLoader: boolean = false;

  constructor(
    public roleConfigurationService: RoleConfigService,
    private spinner: NgxSpinnerService,
    private orderPipe: OrderPipe
  ) {}

  ngOnInit() {}

  ngOnDestroy() {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
    if (this.searchSubscription !== undefined) {
      this.searchSubscription.unsubscribe();
    }
  }

  clearSelectedResource(){
    this.resourceList = [];
    this.showSearchLoader = false;
    this.showSearchList = false;
    this.resourceRoleModel = null;
    this.searchBar = '';
  }

  search() {
    this.showSearchLoader = true;
    this.showSearchList = true;
    const serachQuery = this.searchBar?.trim();
    if (serachQuery?.length >= 2) {
      let resourceToSearch = new ResourceDto();
      resourceToSearch.ResourceName = serachQuery;
      this.searchSubscription = this.roleConfigurationService
        .searchResourceRole(resourceToSearch)
        .subscribe((data) => {
          this.showSearchLoader = false;
          this.resourceList = this.orderPipe.transform(data, 'ResourceName');
        });
    } else{
      this.showSearchLoader = false;
      this.showSearchList = false;
      this.resourceList = [];
    }
  }

  onItemSelect(event) {
    this.showSearchList = false;
    this.showSearchLoader = false;
    this.resourceRoleModel = event;
    this.searchBar = event?.ResourceName;
  }

  resetFilterSearchBar() {
    this.searchBar = null;
    this.resourceRoleModel = new ResourceDto();
  }
}
