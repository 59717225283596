import { Component, OnInit, Output, Input, EventEmitter, OnDestroy } from '@angular/core';
import { IndividualService } from '../individual.service';
import { Subscription, Observable, Subject } from 'rxjs';
import { IndividualBookingVM } from '../../../Models/IndividualBookingVM';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BookingGridActionService } from '../../../Models/BookingGridActionService';
import { Response } from '../../../Models/Response';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
// import { ApplicationInsightService } from 'src/applicationInsightService';
import { NgxSpinnerService } from 'ngx-spinner';
// import { GridDataResult, RowArgs } from '@progress/kendo-angular-grid';
import { ResourceDto } from '../../../Models/ResourceDto';
import { OrderPipe } from 'ngx-order-pipe';
import { IndividualSwapResourceVM } from '../../../Models/IndividualSwapResourceVM';
import { JobVM } from '../../../Models/JobVM';
import moment from 'moment';
import { ActionDescription, UiViewSourceEnum } from 'app/shared/enum';
import { CurrentWeekActionDTO } from '../../../Models/CurrentWeekActionDTO';
// import { EngagementService } from '../../engagement/engagement.service';


@Component({
  selector: 'app-swap-individual',
  templateUrl: './swap-individual.component.html',
  styleUrls: ['./swap-individual.component.css']
})
export class SwapIndividualComponent implements OnInit {

  @Input() public bookingData: IndividualBookingVM;
  @Input() currentWeekActionDTO: CurrentWeekActionDTO;

  colorTheme = 'theme-dark-blue';
  bsConfig: Partial<BsDatepickerConfig>;
  @Input() public calledFromChild: boolean;
  bookingType = '';
  engagementCode = '';
  Engagement = '';
  engagementAllocationText = '';
  engagementAllocationTypeValue: any;
  description = '';
  loading = '';
  startDate: Date = null;
  endDate: Date = null;
  //bsRangeValue: Date[];
  hours: number;
  bookingId: number;
  resourceId: number;
  jobId: number;
  unconfirmed: boolean;
  ghost: boolean;
  engagementAllocationPercentSelected = false;
  closeModalWindow = false;
  formGroup: FormGroup;
  booking: IndividualBookingVM;
  response: Response;
  eventData: BookingGridActionService;
  editBookingSubscription: Subscription;  
  splittedBkSubscription: Subscription;
  engagementStatus = '';
  engagementServiceLine = '';
  currentUserName: any;
  currentUserId: any;
  currentUserEmail: any;
  hoursValidationResultList: boolean[];
  isHoursValid: boolean = true;
  confirmHours: boolean = false;
  isValidationRequired: boolean = false;
  // loading = true;
  isPercentageSelected: boolean;
  bookingListSubscription: Subscription;
  bookingList: IndividualBookingVM[];
  bookingList1: IndividualBookingVM[];
  myFormGroup: FormGroup;
  bsRangeDate: Date[];
  resource: ResourceDto;
  searchBookingSubscription: Subscription;
  sortedResourceList: any[];
  results: any[];
  selectedResourceItem: any = '';
  resourceRank: string;
  resourceLocation: string;
  SLDescription: string = '';
  departmentDescription:string='';
  RankDescription: string = '';
  LocationDescription: string = '';
  @Input() resourceName;
  ResourceName: string;
  SearchResourceName: string;
  public ChildRowDiv = true;
  autocompleteResetvalue = '';
  ReplaceResourceName: string;
  resourceListSubscription: Subscription;
  public childRows: IndividualBookingVM;
  public checkindex: number;
  indSwapResource: IndividualSwapResourceVM[];
  hoursValid: boolean = true;
  swapBooking: IndividualBookingVM[];
  public onlyParent: boolean = false;
  public onlyParentDirtyFlag: boolean;

  engagement: JobVM;
  jobCategory = '';
  engagementList: JobVM[];
  sortedEngagementList: any[];
  engagementListoptions: any[];
  selectedItem: any = '';
  placeHolderText: any = '';
  EngagementName: FormControl = new FormControl();
  ReplaceEngagementName: string;
  OldResourceName: string;
  buttonText = "Submit";
  overallocationerror: boolean = true;
  oldResourceId: any;
  loggedInR_CoWorker: any;
  loggedInRoleDelegate: any;
  jobStatus: string;
  ResourceSearch: boolean = true; // show resource area initially.
  EngagementSearch: boolean;
  jobId1: number;
  showSearchList: boolean = false;
  showSearchEngagementList: boolean = false;
  showSearchLoader: boolean = false;
  showSearchLoaderEngagement: boolean = false;
  searchBar: string;
  isAllChecked: boolean = true;
  egResults = [];
  //startOfWeek = new Date();
  user;
  listLength = 0;
  checkBoxDisbFlg = true;
  noresourcFoundFlg = false;
  
  today = new Date();
  //todaysDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), 0, 0, 0);
  todaysDate = this.today.getFullYear()+'-'+ ('0'+(this.today.getMonth()+1)).slice(-2) +'-'+ ('0'+this.today.getDate()).slice(-2) +'T00:00:00.000Z';
  startOfWeek = this.getFirstDateOfWeek(this.todaysDate);

  constructor(private individualServices: IndividualService,
    private spinner: NgxSpinnerService,
    private orderPipe: OrderPipe,
    private fb: FormBuilder) {

    this.bookingList = [];
    this.indSwapResource = [];
    this.swapBooking = [];

    this.formGroup = new FormGroup({
      EngagementResourceName: new FormControl('', [Validators.required
      ]),
      editPeriod: new FormControl('', [
        Validators.required
      ]),
      editLoading: new FormControl('', [
        Validators.required,
        Validators.pattern(/^(?!(0))[0-9]*(\.[0-9]{0,2})?$/)
      ]),
      selectAll_chk: new FormControl('', []),
      Notes: new FormControl(''),
      EngagementName: new FormControl('', []),

    });

  }

  ngOnInit() {
    // this.getCurrentWeek();
    this.enabledWeek(!this.currentWeekActionDTO.IsDisabledReplaceBookingCurrWeek);
    this.user = JSON.parse(sessionStorage.getItem('resource'));
    this.ResourceSearch = this.user.RoleDelegate;
    this.currentUserName = this.user.ResourceName
    this.currentUserEmail = this.user.Email;
    this.currentUserId = sessionStorage.getItem("resourceId");
    // this.applicationInsightsService.logPageView("Individual View Edit Bookings");
    const currentDate = new Date();
    this.resource = new ResourceDto();
    this.engagement = new JobVM();
    this.bsConfig = Object.assign({}, {
      containerClass: this.colorTheme,
      showWeekNumbers: false, minDate: currentDate, rangeInputFormat: 'DD-MM-YYYY'
    });
    this.booking = new IndividualBookingVM();
    this.response = new Response();
    this.results = [];
    this.individualServices.swapEvent.subscribe((data) => {
      this.eventData = data;
      this.SetData(this.eventData);
      this.bookingData = data.dataItem;
    });

    this.individualServices.currentMessageEngagementAllocation.subscribe(isPercentageSelected => {
      this.setEngagementAllocation(isPercentageSelected);
    });
    this.individualServices.currentMessageCloseModal.subscribe(closeModal => {
      this.closeModalWindow = closeModal;
    });
    this.individualServices.currentMessageCloseChildModal.subscribe(closeChildModal => {
      this.closeModalWindow = closeChildModal;
    });

    this.getBookingList();

    let group = {}
    group['number_of_resource'] = new FormControl('');
    //this.formGroup.get('selectAll_chk').enable();
    //this.formGroup.get('selectAll_chk').setValue(true);
    this.myFormGroup = new FormGroup(group);

  }

  // userprofileform = this.fb.group({
  //   fbhours: this.fb.array([
  //     this.fb.control('')
  //   ])
  // })
  // EditReplace: number = 0;
  enabledWeek(isCurrentWeekEditable) {
    if(this.currentWeekActionDTO.IsDisabledReplaceBookingCurrWeek === true){
      isCurrentWeekEditable = false;
    }
    if(isCurrentWeekEditable){
      //this.startOfWeek.setMinutes(this.startOfWeek.getMinutes() - this.startOfWeek.getTimezoneOffset());
    }
    else{
      //this.startOfWeek.setDate(this.startOfWeek.getDate() + 7);
      let dt = new Date(this.startOfWeek);
      this.startOfWeek = new Date(dt.setDate(dt.getDate() + 7)).toISOString();
      //this.startOfWeek.setMinutes(this.startOfWeek.getMinutes() - this.startOfWeek.getTimezoneOffset());
    }
    return this.startOfWeek;

    // const dayNeeded = 7 //For Saturday
    // const currentDay = moment().isoWeekday();
    // if (this.EditReplace === 1) {
    //   isCurrentWeekEditable = false;
    // }
    // if (isCurrentWeekEditable) {
    //   if (currentDay === dayNeeded) {
    //     let day = moment().day(dayNeeded);
    //     this.startOfWeek = new Date(day.year(), day.month(), day.date())
    //   } else {
    //     let day = moment().subtract(1, 'weeks').isoWeekday(dayNeeded);
    //     this.startOfWeek = new Date(day.year(), day.month(), day.date());
    //   }
    // } else {
    //   if (currentDay === dayNeeded) {
    //     let day = moment().add(1, 'weeks').isoWeekday(dayNeeded);
    //     this.startOfWeek = new Date(day.year(), day.month(), day.date());
    //   } else {
    //     let day = moment().day(dayNeeded);
    //     this.startOfWeek = new Date(day.year(), day.month(), day.date());

    //   }
    // }
    //this.startOfWeek = this.getFirstDateOfWeek(this.todaysDate);
    }

  getFirstDateOfWeek(anyDateOfWeek) {
    // date.getDay() RETURNS Week Day Index: SUN 0, MON 1, TUE 2, WED 3, THU 4, FRI 5, SAT 6.
    let date = new Date(anyDateOfWeek);
    //let date = this.getDateInLocalTimezone(anyDateOfWeek);
    let firstDayOfWeekIndex:number = 6; //Week Day Index: SUN 0, MON 1, TUE 2, WED 3, THU 4, FRI 5, SAT 6.
    let daysToShiftBack = (7 + (date.getDay() - firstDayOfWeekIndex)) % 7;
    let firstDateOfWeek = new Date(date.setDate(date.getDate() - daysToShiftBack));
    return firstDateOfWeek.toISOString();
  }
  
  // getCurrentWeek() {
  //   this.spinner.show();
  //   this.individualServices.getCurrentWeek().subscribe(
  //     (data: any) => {
  //       this.EditReplace = data.Replace;
  //       if (this.EditReplace == 1) {
  //         this.enabledWeek(false);
  //       }
  //       if (this.EditReplace == 0 || this.EditReplace == null) {
  //         this.enabledWeek(true);
  //       }
  //     });
  //   this.spinner.hide();
  // }
  
  // search() {
  //   if (this.searchBar != null) {
  //     if (this.searchBar.trim().length >= 2) {
  //       this.showSearchList = true;
  //       this.showSearchLoader = true;
  //       this.resource.ResourceName = this.searchBar;
  //       this.resource.ResourceId = +sessionStorage.getItem('resourceId');
  //       // this.resource.InstanceId = this.selectedInstance;
  //       this.resource.LoggedInResourceID = + sessionStorage.getItem('loggedin_resourceid');
  //       // this.applicationInsightsService.logEvent('Individual View - getResourceList');
  //       this.resourceListSubscription = this.individualServices.getResourceList(this.resource).subscribe(
  //         (data) => {
  //           this.sortedResourceList = this.orderPipe.transform(data, 'ResourceName');
  //           if (this.searchBar.length > 0) {
  //             this.results = this.sortedResourceList;
  //             this.showSearchLoader = false;
  //           }
  //           else {
  //             this.results = [];
  //             this.showSearchList = false;
  //           }
  //         });
  //     }
  //     else {
  //       this.results = [];
  //       this.showSearchList = false;
  //     }
  //   }
  //   else {
  //     this.results = [];
  //     this.showSearchList = false;
  //   }
  // }

  resetfilterBookingGridSearchBar() {
    this.showSearchList = false;
    this.onResourceClear();
    this.results = [];
    this.searchBar = '';
    // this.selectedResource = this.landingResource;
    // this.resourceId = this.landingResource.ResourceId;
    // this.bookingList = this.landingBookingList;
    // this.gridSelectedUser = this.resourceName;
    // //this.restService.setSelectedResource(this.resourceId);
    // if (this.gridSelectedUser == null) {
    //   this.resourceSelected = false;
    // }
  }
  
  SetData(rowdata: BookingGridActionService) {
    this.formGroup.reset();
    this.autocompleteResetvalue = null;
    this.ResourceName = rowdata.dataItem.resourceName;
    this.OldResourceName = rowdata.dataItem.resourceName;
    this.ReplaceResourceName = rowdata.dataItem.resourceName;

    this.RankDescription = rowdata.dataItem.RankDescription;
    this.LocationDescription = rowdata.dataItem.resourceLocation;
    this.SLDescription = rowdata.dataItem.SLDescription;
    this.departmentDescription=rowdata.dataItem.DepartmentDescription;


    this.bookingId = rowdata.dataItem.BookingId;
    this.resourceId = rowdata.dataItem.ResourceId;
    this.oldResourceId = rowdata.dataItem.ResourceId;
    this.jobId = rowdata.dataItem.JobId;
    this.bookingType = rowdata.dataItem.BookingType;
    this.startDate = rowdata.dataItem.StartDate;
    this.endDate = rowdata.dataItem.EndDate;

    this.Engagement = rowdata.dataItem.Engagement;
    this.ReplaceEngagementName = rowdata.dataItem.Engagement;

    this.engagementCode = rowdata.dataItem.EngagementCode;
    this.loading = rowdata.dataItem.Loading;
    this.hours = rowdata.dataItem.Hours;
    //this.bsRangeValue = [new Date(rowdata.dataItem.StartDate), new Date(rowdata.dataItem.EndDate)];
    this.description = "";//rowdata.dataItem.Description;
    if (this.bookingType.includes('Unconfirmed')) {
      this.unconfirmed = true;
    } else {
      this.unconfirmed = false;
    }
    this.ghost = rowdata.dataItem.Ghost;
    this.engagementStatus = rowdata.dataItem.JobStatus;
    this.engagementServiceLine = rowdata.dataItem.ServiceLineDescription;
    this.setEngagementAllocation(this.engagementAllocationPercentSelected);
   

  }


  mouseEnter() {
    if (this.engagementAllocationPercentSelected) {
      //this.engagementAllocationTypeValue = this.engagementAllocationTypeValue.replace('% ', '');
    }
  }

  mouseLeave() {
    if (this.engagementAllocationPercentSelected) {
      this.engagementAllocationTypeValue = this.engagementAllocationTypeValue;
    }
  }

  setEngagementAllocation(isPercentageSelected) {
    if (!isPercentageSelected) {
      this.engagementAllocationText = 'Hours';
      this.engagementAllocationTypeValue = this.hours;
    } else {
      this.engagementAllocationText = 'Loading %';
      if (this.loading !== '' && this.loading != null) {
        this.engagementAllocationTypeValue = this.loading;
      }
    }
    this.engagementAllocationPercentSelected = isPercentageSelected;
    this.isPercentageSelected = isPercentageSelected;
  }





  onEditSubmit() {
    //if (!this.myFormGroup.invalid && this.ChildRecordsDisable == false) {
    this.bookingList.forEach((num, i) => {
      if (this.myFormGroup.get('Periods_' + i).get('dtStart').value && this.myFormGroup.get('Periods_' + i).get('dtEnd').value) {
        this.myFormGroup.get('Periods_' + i).get('dtStart').setErrors(null);
        this.myFormGroup.get('Periods_' + i).get('dtStart').setValidators(null);
        this.myFormGroup.get('Periods_' + i).get('dtEnd').setErrors(null);
        this.myFormGroup.get('Periods_' + i).get('dtEnd').setValidators(null);
      }
    });
    if (!this.myFormGroup.invalid && this.ChildRecordsDisable == false) {
      this.spinner.show();
      if (this.SearchResourceName != this.OldResourceName) {
        this.indSwapResource = [];
        this.bookingList.forEach((num, i) => {
          if (this.myFormGroup.get("chk_" + i).value == true) {
            const selectedRow = new IndividualSwapResourceVM();
            if (!this.isPercentageSelected) {
              selectedRow.Hours = this.myFormGroup.get('Hours_' + i).value;
              selectedRow.IsPercentSelected = false;
            }
            else {
              selectedRow.Loading = this.myFormGroup.get('Hours_' + i).value;
              selectedRow.IsPercentSelected = true;
            }

            selectedRow.StartDate = (this.myFormGroup.get('Periods_' + i).get('dtStart').value);
            selectedRow.EndDate = (this.myFormGroup.get('Periods_' + i).get('dtEnd').value);
            selectedRow.BookingId = this.bookingId;
            selectedRow.ResourceId = this.resourceId;
            selectedRow.Unconfirmed = this.myFormGroup.get("Unconfirmed_" + i).value
            selectedRow.Ghost = this.myFormGroup.get("Ghost_" + i).value
            selectedRow.InstanceId = this.bookingData.InstanceId;
            this.indSwapResource.push(selectedRow);
            // this.indSwapResource[i] = new IndividualSwapResourceVM();
            // if (!this.isPercentageSelected) {
            //   this.indSwapResource[i].Hours = this.myFormGroup.get('Hours_' + i).value;
            //   this.indSwapResource[i].IsPercentSelected = false;
            // }
            // else {
            //   this.indSwapResource[i].Loading = this.myFormGroup.get('Hours_' + i).value;
            //   this.indSwapResource[i].IsPercentSelected = true;
            // }

            // this.indSwapResource[i].StartDate = (this.myFormGroup.get('Periods_' + i).get('dtStart').value);
            // this.indSwapResource[i].EndDate = (this.myFormGroup.get('Periods_' + i).get('dtEnd').value);
            // this.indSwapResource[i].BookingId = this.bookingId;
            // this.indSwapResource[i].ResourceId = this.resourceId;
            // this.indSwapResource[i].Unconfirmed = this.myFormGroup.get("Unconfirmed_" + i).value
            // this.indSwapResource[i].Ghost = this.myFormGroup.get("Ghost_" + i).value


          }

        })

        //alert(JSON.stringify(this.swapResource));       


        this.resourceListSubscription = this.individualServices.validateReplaceHours(this.indSwapResource).subscribe(
          (data: any) => {

            this.hoursValidationResultList = data;

            this.hoursValidationResultList.forEach((num, i) => {

              if (this.hoursValidationResultList[i] !== undefined && !this.hoursValidationResultList[i] ) {
                if (this.confirmHours != true) {

                  this.hoursValid = false;
                  this.dirtyFlag = false;
                  this.myFormGroup.get('Hours_' + i).setErrors({ 'incorrect': true });
                  this.overallocationerror = false;
                }
              }

            })

            if ((!this.hoursValid && this.confirmHours) || this.hoursValid) {
              // alert("API Call successfully Done");
              this.hoursValid = true;
              // this.confirmHours = false;
              this.save();
            }


            this.spinner.hide();
          }
        );

      }
      else {
        //alert("Please select a different resource name");
        this.buttonText = "please select a different resource name";
        setTimeout(() => { this.buttonText = "Submit" }, 3000);
      }


    }
    else {

      //alert("validation error");
      this.buttonText = "Validation error";
      setTimeout(() => { this.buttonText = "Submit" }, 3000);
    }

  }


  public confirmHoursChange() {

    if (this.confirmHours == true) {

      this.bookingList.forEach((num, i) => {
        if (this.myFormGroup.get('Hours_' + i).value != '') {

          this.myFormGroup.get('Hours_' + i).setErrors(null)
          this.dirtyFlag = true;
        }
      }
      )

    }
    else {
      this.dirtyFlag = false;
    }

  }


  @Output() isFormDirtyFlag: EventEmitter<boolean> = new EventEmitter<boolean>();

  public dirtyFlag = false;

  public makeDirty(controlName: string) {
    if (!this.dirtyFlag) {
      this.dirtyFlag = true;
      this.isFormDirtyFlag.emit(this.dirtyFlag);
    }
    if (controlName == 'Periods' || controlName == 'Hours' || controlName == 'Unconfirmed') {
      this.isValidationRequired = true;
    }
    this.overallocationerror = true;

  }

  public checkDirty() {
    if (!this.dirtyFlag) {
      if (this.formGroup.get('Periods').dirty) {
        this.makeDirty('Periods');
      }
    }
  }



  save() {
    this.buttonText = "Submitting...";

    // this.bookingList.forEach((num, i) => {

    //   if (this.myFormGroup.get("chk_" + i).value == true) {
    //     const selectedSwap = new IndividualBookingVM();
    //     if (!this.isPercentageSelected) {
    //       selectedSwap.Hours = this.myFormGroup.get('Hours_' + i).value;
    //       selectedSwap.IsPercentSelected = false;
    //     }
    //     else {
    //       selectedSwap.Loading = this.myFormGroup.get('Hours_' + i).value;
    //       selectedSwap.IsPercentSelected = true;
    //       selectedSwap.Hours = this.hours;
    //     }
    //     selectedSwap.CurrentUserId = this.currentUserId;
    //     selectedSwap.CurrentUserName = this.currentUserName;
    //     selectedSwap.CurrentUserEmail = this.currentUserEmail;
    //     selectedSwap.BookingId = num.BookingId;
    //     selectedSwap.ResourceId = this.resourceId == -1 ? this.oldResourceId : this.resourceId;
    //     selectedSwap.JobId = this.jobId;  //num.JobId;
    //     selectedSwap.BookingType = num.BookingType;
    //     selectedSwap.Description = this.description;  // num.Description
    //     selectedSwap.StartDate = (this.myFormGroup.get('Periods_' + i).get('dtStart').value);
    //     selectedSwap.EndDate = (this.myFormGroup.get('Periods_' + i).get('dtEnd').value);
    //     selectedSwap.ActionDescriptionId = 2;
    //     selectedSwap.MeraBookingStatusId = 5;
    //     selectedSwap.Unconfirmed = this.myFormGroup.get("Unconfirmed_" + i).value;
    //     selectedSwap.Ghost = this.myFormGroup.get("Ghost_" + i).value;
    //     selectedSwap.RequestMode = JSON.parse(sessionStorage.getItem('requestMode'));
    //     selectedSwap.InstanceId = num.InstanceId;
    //     selectedSwap.IsOverallocation = this.confirmHours;
    //     this.swapBooking.push(selectedSwap);
    //   }

    // })

// alert(this.myFormGroup.get('Periods_0').get('dtStart').value);
// alert((this.myFormGroup.get('Periods_0').get('dtStart').value));
// alert(moment(this.myFormGroup.get('Periods_0').get('dtStart').value));
// alert(moment(this.myFormGroup.get('Periods_0').get('dtStart').value).toDate());

    let bookingDtos: any[] = [];
    this.bookingList.forEach((num, i) => {
      if (this.myFormGroup.get("chk_" + i).value == true) {
        bookingDtos.push({
          BookingId: num.BookingId,
          ResourceId: this.resourceId == -1 ? this.oldResourceId : this.resourceId,
          JobId: this.jobId,
          BookingType: num.BookingType,
          Description: this.formGroup.get('Notes').value, //this.description,
          StartDate: (this.myFormGroup.get('Periods_' + i).get('dtStart').value),
          EndDate: (this.myFormGroup.get('Periods_' + i).get('dtEnd').value),
          Hours: !this.isPercentageSelected ? this.myFormGroup.get('Hours_' + i).value : num.Hours,
          Loading: this.isPercentageSelected ? this.myFormGroup.get('Hours_' + i).value : num.Loading,
          Unconfirmed: this.myFormGroup.get("Unconfirmed_" + i).value,
          Ghost: this.myFormGroup.get("Ghost_" + i).value,
          IsOverallocation:this.confirmHours,
          CurrentUserId : this.currentUserId,
          CurrentUserName : this.currentUserName,
          CurrentUserEmail : this.currentUserEmail,
          ActionDescriptionId:2,
          MeraBookingStatusId:5,
          InstanceId: this.bookingList[0].InstanceId,
          ActualBookingId: num.BookingId,
          ParentBookingId: null
        });
      }
    })

    let bookingActionDto = {
      IsPercentSelected: this.isPercentageSelected, 
      RequestMode:JSON.parse(sessionStorage.getItem('requestMode')),
      //InstanceId: this.bookingList[0].InstanceId,
      Bookings: bookingDtos,
      UiViewSource : UiViewSourceEnum.IndividualView
    };

    // alert(JSON.stringify(this.swapBooking));

    this.editBookingSubscription = this.individualServices.ReplaceBooking(bookingActionDto).subscribe(
      (data: any) => {
        this.spinner.hide();
        if (data.status = '200') {
          this.response = data.body;
          if (this.response.customResultId > 0) {
            this.individualServices.setRquestNumber(this.response.customResultId);
            // alert("Responce Id = "+this.response.customResultId)
          }
          //alert('Successfully saved the changes');
          if (this.calledFromChild) {
            this.individualServices.closeChildModal(true);
          } else {
            this.individualServices.closeModal(true);
          }
        }else{
          this.buttonText = "Submit";
        }
      }, err => {
        this.spinner.hide();
        this.buttonText = "Error";
      }, () => {
        this.buttonText = "Submit";
      }
    );
    this.dirtyFlag = false;
  }


  ngOnDestroy() {
    if (this.editBookingSubscription !== undefined) {
      this.editBookingSubscription.unsubscribe();
    }
  }

  setStartDateTime(inputDate) {
    let newDate = inputDate;
    if (newDate.getHours() === 0) {
      newDate.setHours(newDate.getHours() + 9);
      newDate.setMinutes(newDate.getMinutes() + 30);
    }
    return newDate;
  }

  setEndDateTime(inputDate) {
    let newDate = inputDate;
    if (newDate.getHours() === 0) {
      newDate.setHours(newDate.getHours() + 17);
      newDate.setMinutes(newDate.getMinutes() + 30);
    }
    return newDate;
  }

  public rowsToHighlight: any[] = [];
  // public isRowSelected = (e: RowArgs) => this.rowsToHighlight.indexOf(e.dataItem.HighlightStatus) >= 0;


  getBookingList() {
    this.bookingData.IsPercentSelected = this.isPercentageSelected;
    let splitReqDto: IndividualBookingVM = JSON.parse(JSON.stringify(this.bookingData));
    splitReqDto.BookingId = this.bookingData.ChildRowsExist === true ? 0 : splitReqDto.BookingId;
    splitReqDto.UiViewSource = UiViewSourceEnum.IndividualView;
    splitReqDto.MeraActionDescriptionId = ActionDescription.Replace;
    //this.bookingData.LoggedInResourceID = + sessionStorage.getItem('loggedin_resourceid');
    this.spinner.show();
    this.bookingListSubscription =
      this.individualServices.getBookingSplittedInPastFuture(splitReqDto).subscribe(
      //this.individualServices.fetchBookingChildData(this.bookingData).subscribe(
        data => {
          this.bookingList = data;

          //this.bookingList.forEach((element) => {
          //   element.StartDate = new Date(element.StartDate),
          //     element.EndDate = new Date(element.EndDate),
          //     element.enabledWeekDay = this.enabledWeek(element.IsCurrentEditWeek);
          //   element.BsRangeDate = [new Date(element.StartDate), new Date(element.EndDate)],
          //     element.StartDate = new Date(element.StartDate), element.EndDate = new Date(element.EndDate)
          //   if (element.HighlightStatus) {
          //     this.rowsToHighlight.push(element.HighlightStatus);
          //   }
          //   if (this.isPercentageSelected == true) {
          //     element.Hours = element.Loading;
          //   }
          //   else {
          //     element.Hours = element.Hours;
          //   }

          // });

           this.bookingList1 = JSON.parse(JSON.stringify(this.bookingList));

          if (this.bookingList.length == 1) {
            this.onlyParent = true;
            this.onlyParentDirtyFlag = true;
          }
          else { this.onlyParent = false }


          this.bookingList.forEach((element, index) => {
            // element.StartDate = new Date(element.StartDate),
            // element.EndDate = new Date(element.EndDate);                  
            // this.myFormGroup.addControl("Periods_" + index.toString(), fc);
            this.myFormGroup.addControl("Periods_" + index.toString(),
              this.fb.group({
                // dtStart: [new Date(element.StartDate), Validators.required],
                // dtEnd: [new Date(element.EndDate), Validators.required],
                dtStart: [element.StartDate, Validators.required],
                dtEnd: [element.EndDate, Validators.required],                
              })
            );


            if (this.isPercentageSelected == true) {
              this.myFormGroup.addControl("Hours_" + index.toString(), new FormControl(element.Loading, [Validators.required, Validators.pattern(/^(?!(0))[0-9]*(\.[0-9]{0,2})?$/)]));
            }
            else {
              this.myFormGroup.addControl("Hours_" + index.toString(), new FormControl(element.Hours, [Validators.required, Validators.pattern(/^(?!(0))[0-9]*(\.[0-9]{0,2})?$/)]));
            }

            this.myFormGroup.addControl("Unconfirmed_" + index.toString(), new FormControl(element.Unconfirmed));
            this.myFormGroup.addControl("Ghost_" + index.toString(), new FormControl(element.Ghost));
            this.myFormGroup.addControl("chk_" + index.toString(), new FormControl(''));
            this.myFormGroup.addControl('Notes_' + index.toString(), new FormControl(element.Description));
            //Added Missing code to resolve current week issue
            this.myFormGroup.get("Periods_" + index).disable();
            this.myFormGroup.get("Hours_" + index).disable();
            this.myFormGroup.get("Unconfirmed_" + index).disable();
            this.myFormGroup.get("Ghost_" + index).disable();
            this.myFormGroup.get("chk_" + index).disable();
          })
          this.formGroup.get('selectAll_chk').disable();
          this.formGroup.get('Notes').disable();
          this.listLength = this.bookingList.length;
          this.spinner.hide();
        },
        error => {
          this.spinner.hide();
        }
      );
  }

  searchValue = '';
  searchResource() {
    this.autocompleteResetvalue = this.formGroup.get('EngagementResourceName').value;
    if (this.autocompleteResetvalue != null) {
      if (this.autocompleteResetvalue.length >= 2) {
        this.showSearchList = true;
        this.showSearchLoader = true;
        this.noresourcFoundFlg = false;
        this.searchValue = this.autocompleteResetvalue;
        this.resource.ResourceName = this.autocompleteResetvalue;
        this.resource.ResourceId = 0; //+sessionStorage.getItem('resourceId');
        this.resource.LoggedInResourceID = + sessionStorage.getItem('loggedin_resourceid');
        this.resource.InstanceId=this.bookingData.InstanceId;
        this.searchBookingSubscription = this.individualServices.getResourceList(this.resource).subscribe((data) => {
          this.sortedResourceList = this.orderPipe.transform(data, 'ResourceName');
          if (this.searchValue.length > 0) {
            this.results = this.sortedResourceList;
            this.showSearchLoader = false;
            this.noresourcFoundFlg = true;
            this.spinner.hide();
          }
          else {
            this.results = [];
            this.showSearchList = false;
          }

        });
      }
      else {
        this.results = [];
        this.showSearchList = false;
      }
    }
    else {
      this.results = [];
      this.showSearchList = false;
    }
  }


  OnResourceItemSelect(event) {
    this.showSearchList = false;
    this.checkBoxDisbFlg = false;
    this.formGroup.get("EngagementResourceName").setValue(event.ResourceName)
    this.selectedResourceItem = event;
    this.resourceRank = this.selectedResourceItem.RankDescription;
    this.resourceLocation = this.selectedResourceItem.LocationDescription;
    this.SLDescription = this.selectedResourceItem.SLDescription;
    this.departmentDescription=this.selectedResourceItem.DepartmentDescription;
    this.RankDescription = this.resourceRank;
    this.LocationDescription = this.resourceLocation;
    this.resourceId = this.selectedResourceItem.ResourceId;
    this.ResourceName = this.selectedResourceItem.ResourceName;
    this.SearchResourceName = this.selectedResourceItem.ResourceName;
    this.ChildRowDiv = false;
    this.checkAllSelected();
    this.formGroup.get('selectAll_chk').enable();
    this.formGroup.get('selectAll_chk').setValue(true);

    this.formGroup.get('Notes').enable();
  }

  public key: string;
  checkAllSelected() {
    this.bookingList.forEach((num, i) => {

      if (num.IsCurrentEditWeek.valueOf() != true 
      && num.IsDisabledReplaceBooking.valueOf() != true) {
        this.myFormGroup.get("chk_" + i).setValue(true);
        this.myFormGroup.get("Unconfirmed_" + i).enable();
        this.myFormGroup.get("Ghost_" + i).enable();
        this.myFormGroup.get("chk_" + i).enable();

        this.myFormGroup.get("Periods_" + i).enable();
        this.myFormGroup.get("Periods_" + i).markAsDirty();
        this.myFormGroup.get("Periods_" + i).markAsTouched();
        this.myFormGroup.get("Periods_" + i).setValidators([Validators.required]);
        this.myFormGroup.get("Periods_" + i).updateValueAndValidity();

        this.myFormGroup.get("Hours_" + i).enable();
        this.myFormGroup.get("Hours_" + i).markAsDirty();
        this.myFormGroup.get("Hours_" + i).markAsTouched();
        this.myFormGroup.get("Hours_" + i).setValidators([Validators.required, Validators.pattern(/^(?!(0))[0-9]*(\.[0-9]{0,2})?$/), Validators.max(2147483647)]);
        this.myFormGroup.get("Hours_" + i).updateValueAndValidity();
        this.dirtyFlag = true;
      }
    })

    this.ChildRecordsDisable = false;
  }

  txtValue: string = null;
  ChildRecordsDisable: boolean = true;
  onResourceChange(value) {
    this.txtValue = value;
    if (this.txtValue == '') {
      this.bookingList.forEach((num, i) => {

        this.myFormGroup.get("chk_" + i).setValue(false);
        this.myFormGroup.get("Unconfirmed_" + i).disable();
        this.myFormGroup.get("chk_" + i).disable();
        this.myFormGroup.get("Ghost_" + i).disable();

        this.myFormGroup.get("Periods_" + i).disable();
        this.myFormGroup.get("Periods_" + i).markAsUntouched();
        this.myFormGroup.get("Periods_" + i).markAsPristine();
        this.myFormGroup.get("Periods_" + i).clearValidators();
        this.myFormGroup.get("Periods_" + i).updateValueAndValidity();
        this.myFormGroup.get("Periods_" + i).setErrors(null);

        this.myFormGroup.get("Hours_" + i).disable();
        this.myFormGroup.get("Hours_" + i).markAsUntouched();
        this.myFormGroup.get("Hours_" + i).markAsPristine();
        this.myFormGroup.get("Hours_" + i).clearValidators();
        this.myFormGroup.get("Hours_" + i).updateValueAndValidity();
        this.myFormGroup.get("Hours_" + i).setErrors(null);

        this.formGroup.get('selectAll_chk').disable();
        this.formGroup.get('selectAll_chk').setValue(false);

        this.formGroup.get('Notes').disable();
        this.formGroup.get('Notes').clearValidators();
        this.formGroup.get('Notes').updateValueAndValidity();
        this.formGroup.get('Notes').reset();

        this.dirtyFlag = false;
        this.hoursValid = true;
        this.setOldervalue(i);
      })
    }

    this.ChildRecordsDisable = true;
  }


  changeCheck(index, event) {
    if (event.checked) {
      this.isAllChecked = true;
      this.dirtyFlag = true;
      this.bookingList.forEach((num, i) => {
        if (i != index && this.myFormGroup.get("chk_" + i).value == false
          && num.IsCurrentEditWeek.valueOf() != true
          && num.IsDisabledReplaceBooking.valueOf() != true) {
          this.isAllChecked = false;
          return;
        };
      });
      this.formGroup.get("selectAll_chk").setValue(this.isAllChecked);
    } else {
      this.formGroup.get("selectAll_chk").setValue(false);
      this.dirtyFlag = false;
      this.bookingList.forEach((num, i) => {
        if (i != index && this.myFormGroup.get("chk_" + i).value == true
          && num.IsCurrentEditWeek.valueOf() != true
          && num.IsDisabledReplaceBooking.valueOf() != true) {
          this.dirtyFlag = true;
          return;
        };
      });
      this.setOldervalue(index);
    }
    this.setControlsStatus(index, event.checked);
    this.myFormGroup.get("chk_" + index).enable();
    // this.dirtyFlag = 
    // !(!this.myFormGroup.get((this.isPercentageSelected ? 'editLoading_' : 'editHours_')+ index).value 
    // || this.myFormGroup.get((this.isPercentageSelected ? 'editLoading_' : 'editHours_') + index).value.trim() == '' 
    // || isNaN(+this.myFormGroup.get((this.isPercentageSelected ? 'editLoading_' : 'editHours_') + index).value));
  }

  changeSelectAll(event) {
    this.bookingList.forEach((num, i) => {
      if (num.IsCurrentEditWeek.valueOf() != true 
      && num.IsDisabledReplaceBooking.valueOf() != true) {
        this.myFormGroup.get("chk_" + i).setValue(event.checked);
        this.setControlsStatus(i, event.checked);
      }
    });
    this.dirtyFlag = event.checked;
    if (this.dirtyFlag) {
      this.isFormDirtyFlag.emit(this.dirtyFlag);
    }
  }

  setControlsStatus(index, isEnabled) {
    if (!isEnabled) {
      this.myFormGroup.get("Unconfirmed_" + index).disable
      this.myFormGroup.get("Ghost_" + index).disable
      this.myFormGroup.get("Periods_" + index).disable();
      this.myFormGroup.get("Periods_" + index).markAsUntouched();
      this.myFormGroup.get("Periods_" + index).markAsPristine();
      //this.myFormGroup.get("editPeriod_" + index).clearValidators();
      this.myFormGroup.get("Periods_" + index).get('dtStart').clearValidators();
      this.myFormGroup.get("Periods_" + index).get('dtEnd').clearValidators();
      //this.myFormGroup.get("editPeriod_" + index).updateValueAndValidity();      
      this.myFormGroup.get("Periods_" + index).get('dtStart').updateValueAndValidity();
      this.myFormGroup.get("Periods_" + index).get('dtEnd').updateValueAndValidity();
      //this.myFormGroup.get("editPeriod_" + index).setErrors(null);      
      this.myFormGroup.get("Periods_" + index).get('dtStart').setErrors(null);
      this.myFormGroup.get("Periods_" + index).get('dtEnd').setErrors(null);

      // if (!this.isPercentageSelected) {
      this.myFormGroup.get("Hours_" + index).disable();
      this.myFormGroup.get("Hours_" + index).markAsUntouched();
      this.myFormGroup.get("Hours_" + index).markAsPristine();
      this.myFormGroup.get("Hours_" + index).clearValidators();
      this.myFormGroup.get("Hours_" + index).updateValueAndValidity();
      this.myFormGroup.get("Hours_" + index).setErrors(null);
      //}
      // else {
      //   this.myFormGroup.get("Loading_" + index).disable();
      //   this.myFormGroup.get("Loading_" + index).markAsUntouched();
      //   this.myFormGroup.get("Loading_" + index).markAsPristine();
      //   this.myFormGroup.get("Loading_" + index).clearValidators();
      //   this.myFormGroup.get("Loading_" + index).updateValueAndValidity();
      //   this.myFormGroup.get("Loading_" + index).setErrors(null);
      // }
      this.myFormGroup.get("Notes_" + index).disable();
      this.myFormGroup.get("Notes_" + index).clearValidators();
      this.myFormGroup.get("Notes_" + index).updateValueAndValidity();
      this.myFormGroup.get("Notes_" + index).reset();
    }
    else {
      this.myFormGroup.get("Unconfirmed_" + index).enable();
      this.myFormGroup.get("Ghost_" + index).enable();
      this.myFormGroup.get("Periods_" + index).enable();
      this.myFormGroup.get("Periods_" + index).markAsDirty();
      this.myFormGroup.get("Periods_" + index).markAsTouched();
      this.myFormGroup.get("Periods_" + index).get('dtStart').setValidators([Validators.required]);
      this.myFormGroup.get("Periods_" + index).get('dtEnd').setValidators([Validators.required]);
      this.myFormGroup.get("Periods_" + index).get('dtStart').updateValueAndValidity();
      this.myFormGroup.get("Periods_" + index).get('dtEnd').updateValueAndValidity();
      ///if (!this.isPercentageSelected) {
      this.myFormGroup.get("Hours_" + index).enable();
      this.myFormGroup.get("Hours_" + index).markAsDirty();
      this.myFormGroup.get("Hours_" + index).markAsTouched();
      this.myFormGroup.get("Hours_" + index).setValidators([Validators.required, Validators.pattern(/^(?!0*\.?0+$)\d*\.?\d{1,2}$/), Validators.max(2147483647)]);
      this.myFormGroup.get("Hours_" + index).updateValueAndValidity();
      // }
      // else {
      //   this.myFormGroup.get("Loading_" + index).enable();
      //   this.myFormGroup.get("Loading_" + index).markAsDirty();
      //   this.myFormGroup.get("Loading_" + index).markAsTouched();
      //   this.myFormGroup.get("Loading_" + index).setValidators([Validators.required, Validators.pattern(/^(?!0*\.?0+$)\d*\.?\d{1,2}$/), Validators.max(2147483647)]);
      //   this.myFormGroup.get("Loading_" + index).updateValueAndValidity();
      // }
      this.myFormGroup.get("Notes_" + index).enable();
    }

  }
  public setOldervalue(index) {
    this.bookingList1.forEach((num, i) => {

      if (index == i) {

        // this.myFormGroup.get("Periods_" + index).get("dtStart").setValue(new Date(num.StartDate));
        // this.myFormGroup.get("Periods_" + index).get("dtEnd").setValue(new Date(num.EndDate));
        this.myFormGroup.get("Periods_" + index).get("dtStart").setValue(num.StartDate);
        this.myFormGroup.get("Periods_" + index).get("dtEnd").setValue(num.EndDate);        
        this.myFormGroup.get("Hours_" + index).setValue(num.Hours);
        if (num.BookingType.includes('Unconfirmed')) {
          this.myFormGroup.get("Unconfirmed_" + index).setValue(true);

        } else {
          this.myFormGroup.get("Unconfirmed_" + index).setValue(false);

        }
        this.myFormGroup.get("Ghost_" + index).setValue(num.Ghost);
      }

    })
  }
  public child_chk_enabled = 0;
  public child_chk_disabled = 0;
  checkChildSelect(args) {

    this.bookingList1.forEach((num, i) => {
      if (this.myFormGroup.get("chk_" + i).value == true) {
        this.child_chk_enabled = this.child_chk_enabled + 1;

      }
      if (this.myFormGroup.get("chk_" + i).value == false) {
        this.child_chk_disabled = this.child_chk_disabled + 1;
      }

    })

    if (this.child_chk_enabled == 1 && !(args.checked)) {
      this.formGroup.get('selectAll_chk').setValue(false);

      this.formGroup.get('Notes').disable();
      this.formGroup.get('Notes').markAsUntouched();
      this.formGroup.get('Notes').markAsPristine();
      this.formGroup.get('Notes').clearValidators();
      this.formGroup.get('Notes').updateValueAndValidity();
      this.formGroup.get('Notes').reset();

      this.dirtyFlag = false;
    }

    else {
      this.formGroup.get('Notes').enable();
      this.formGroup.get('Notes').markAsDirty();
      this.formGroup.get('Notes').markAsTouched();
      this.dirtyFlag = true;

    }

    this.child_chk_disabled = 0;
    this.child_chk_enabled = 0;

  }



  onResourceClear() {
    this.checkBoxDisbFlg=true;
    this.noresourcFoundFlg=false;
    this.formGroup.get('EngagementResourceName').setValue('');
    this.searchValue = '';
    this.results = [];
    this.bookingList.forEach((num, i) => {
      if (num.IsCurrentEditWeek.valueOf() != true 
      && num.IsDisabledReplaceBooking.valueOf() != true) {
        this.myFormGroup.get("chk_" + i).setValue(false);
        this.setControlsStatus(i, false);
      }
    });
    this.dirtyFlag = false;
    if (this.dirtyFlag) {
      this.isFormDirtyFlag.emit(this.dirtyFlag);
    }
    this.formGroup.get('selectAll_chk').disable();
    this.formGroup.get('selectAll_chk').setValue(false);

    this.formGroup.get('Notes').disable();
    this.ChildRecordsDisable = true;
  }

  // getEngagementList(query) {
  //   let input = this.jobCategory;
  //   if (input == null || input === '') {
  //     input = 'Chargeable';
  //   }
  //   this.engagement.JobCategory = '-1';   //input;
  //   this.engagement.JobDescription = query;
  //   this.engagement.ClientDescription = query;
  //   this.engagement.LoggedInResourceId = + sessionStorage.getItem('loggedin_resourceid'); //+sessionStorage.getItem('resourceId'); 

  //   this.searchBookingSubscription = this.individualServices.getEngagementSearchResult(this.engagement).subscribe((data) => {
  //     this.engagementList = data.body;
  //     this.sortedEngagementList = this.orderPipe.transform(this.engagementList, 'EngagementFullName');
  //     this.setEngagementListData();
  //     this.spinner.hide();
  //   });
  // }

  setEngagementListData() {
    this.engagementListoptions = [];
    if (this.autocompleteResetvalue == null || this.autocompleteResetvalue == '') {
      this.egResults = [];
    }
    else {
      this.egResults = this.sortedEngagementList;
    }
  }

  OnItemSelect(event) {
    this.showSearchEngagementList = false;
    this.checkBoxDisbFlg=false;
    this.selectedItem = event;
    this.formGroup.get("EngagementName").setValue(event.EngagementFullName);
    this.engagementCode = event.JobCode;
    this.engagementStatus = event.JobStatus;
    this.engagementServiceLine = event.ServiceLineDescription;
    this.jobId = event.JobId;
    if (this.engagementCode === '' || this.engagementCode === null) {
      this.bookingType = event.JobType + '-' + 'Awaiting Code';
      this.engagementCode = 'Dummy';
    } else {
      this.bookingType = event.JobType;
    }

    this.checkAllSelected();
    this.formGroup.get('selectAll_chk').enable();
    this.formGroup.get('selectAll_chk').setValue(true);

    this.formGroup.get('Notes').enable();
  }


  // onEngagementCategoryChange(event) {
  //   this.onEngagementClear();
  //   const newVal = event.target.value;
  //   this.jobCategory = newVal;

  //   //this.makeDirty();
  // }


  isValidInput(fieldName): boolean {
    return this.formGroup.controls[fieldName].invalid &&
      (this.formGroup.controls[fieldName].dirty || this.formGroup.controls[fieldName].touched);
  }

  searchEngagement() {
    this.autocompleteResetvalue = this.formGroup.get('EngagementName').value;
    if (this.autocompleteResetvalue != null) {
      if (this.autocompleteResetvalue.trim().length >= 2) {
        this.showSearchEngagementList = true;
        this.showSearchLoaderEngagement = true;
        this.searchValue = this.autocompleteResetvalue;
        //this.getEngagementList(this.autocompleteResetvalue);
        let input = this.jobCategory;
        if (input == null || input === '') {
          input = 'Chargeable';
        }
        this.engagement.JobCategory = '-1';   // input;
        this.engagement.JobDescription = this.autocompleteResetvalue;
        //this.engagement.ClientDescription = this.autocompleteResetvalue;
        this.engagement.InstanceId = this.bookingData.InstanceId;

        this.searchBookingSubscription = this.individualServices.getEngagementSearchResult(this.engagement).subscribe((data) => {
          this.engagementList = data.body;
          this.sortedEngagementList = this.orderPipe.transform(this.engagementList, 'EngagementFullName');
          if (this.autocompleteResetvalue.length > 0) {
            this.egResults = this.sortedEngagementList;
            this.showSearchLoaderEngagement = false;
          }
          else {
            this.egResults = [];
            this.showSearchEngagementList = false;
          }

        });

      }
      else {
        this.egResults = [];
        this.showSearchEngagementList = false;
      }
    }
    else {
      this.egResults = [];
      this.showSearchEngagementList = false;
    }

  }

  onEngagementClear() {
    this.checkBoxDisbFlg=true;
    this.searchValue = '';
    this.placeHolderText = 'Search engagement ....';
    this.selectedItem = null;
    this.bookingType = null;
    this.Engagement = null;
    this.engagementCode = null;
    this.autocompleteResetvalue = null;
    this.engagementStatus = null;
    this.engagementServiceLine = null;
    this.formGroup.get('EngagementName').reset();
    this.egResults = [];
    this.bookingList.forEach((num, i) => {
      if (num.IsCurrentEditWeek.valueOf() != true 
      && num.IsDisabledReplaceBooking.valueOf() != true) {
        this.myFormGroup.get("chk_" + i).setValue(false);
        this.setControlsStatus(i, false);
      }
    });
    this.dirtyFlag = false;
    if (this.dirtyFlag) {
      this.isFormDirtyFlag.emit(this.dirtyFlag);
    }
    this.formGroup.get('selectAll_chk').disable();
    this.formGroup.get('selectAll_chk').setValue(false);

    this.formGroup.get('Notes').disable();
    this.ChildRecordsDisable = true;
  }

  private getDateInLocalTimezone(d): Date {
    let dt = new Date(d);
    let localTimezoneOffset = dt.getTimezoneOffset() * 60000;
    let date = new Date(dt.getTime() + localTimezoneOffset); //new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 0, 0, 0);
    return date;
  }
}
