import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { IndividualBookingVM } from '../../../Models/IndividualBookingVM';
import { BookingGridActionService } from '../../../Models/BookingGridActionService';
import { Subscription } from 'rxjs';
import { IndividualService } from '../individual.service';
import { Response } from '../../../Models/Response';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
// import { ApplicationInsightService } from 'src/applicationInsightService';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { SharedServicesService } from 'app/shared/sharedservices/shared-services.service';
import { BookingActionDto } from 'app/Models/BookingActionDto';
import { UiViewSourceEnum } from 'app/shared/enum';
import { CurrentWeekActionDTO } from '../../../Models/CurrentWeekActionDTO';

@Component({
  selector: 'app-split',
  templateUrl: './split.component.html',
  styleUrls: ['./split.component.css']
})
export class SplitComponent implements OnInit {
  colorTheme = 'theme-dark-blue';
  bsConfig: Partial<BsDatepickerConfig>;
  @Input() public calledFromChild: boolean;
  @Input() currentWeekActionDTO: CurrentWeekActionDTO;

  bookingType = '';
  engagementCode = '';
  Engagement = '';
  engagementAllocationText = '';
  description = '';
  loading = '';
  loading_2 = '';
  loading_3 = '';
  loading_4 = '';
  loading_5 = '';
  engagementAllocationTypeValue: any;
  engagementAllocationTypeValue_2: any;
  engagementAllocationTypeValue_3: any;
  engagementAllocationTypeValue_4: any;
  engagementAllocationTypeValue_5: any;
  bsRangeValue: Date[];
  bsRangeValue_2: Date[];
  bsRangeValue_3: Date[];
  bsRangeValue_4: Date[];
  bsRangeValue_5: Date[];
  unconfirmed: boolean;
  unconfirmed_2: boolean;
  unconfirmed_3: boolean;
  unconfirmed_4: boolean;
  unconfirmed_5: boolean;
  ghost: boolean;
  ghost_2: boolean;
  ghost_3: boolean;
  ghost_4: boolean;
  ghost_5: boolean;
  hours: number;
  bookingId: number;
  resourceId: number;
  jobId: number;
  splitId: number;
  engagementAllocationPercentSelected = false;
  closeModalWindow = false;
  showSplit_2 = false;
  showSplit_3 = false;
  showSplit_4 = false;
  showSplit_5 = false;
  booking: IndividualBookingVM;
  response: Response;
  eventData: BookingGridActionService;
  splitBookingSubscription: Subscription;
  engagementStatus = '';
  engagementServiceLine = '';
  // currentUserName: any;
  // currentUserId: any;
  // currentUserEmail: any;
  OldHours: number | null;
  OldStartDate: Date | null;
  OldEndDate: Date | null;
  OldLoading: any | null;
  OldUnconfirmed: boolean;
  OldbsRangeValue: Date[];
  OldengagementAllocationTypeValue: any;
  hoursValidationResultList: boolean[];
  // isHoursValid: boolean = true;
  // isHoursValid_2: boolean = true;
  // isHoursValid_3: boolean = true;
  // isHoursValid_4: boolean = true;
  // isHoursValid_5: boolean = true;
  hoursValid: boolean = true;
  hoursValid_2: boolean = true;
  hoursValid_3: boolean = true;
  hoursValid_4: boolean = true;
  hoursValid_5: boolean = true;
  confirmHours: boolean = false;
  confirmHours_2: boolean = false;
  confirmHours_3: boolean = false;
  confirmHours_4: boolean = false;
  confirmHours_5: boolean = false;
  isConfirmFlag: boolean;
  isValidationRequired: boolean = false;
  isFirstRecordEdited: boolean = false;
  isNewRecordAdded: boolean = false;
  buttonText = "Copy";
  overallocationerror: boolean = true;
  overallocationerror_1: boolean = true;
  overallocationerror_2: boolean = true;
  overallocationerror_3: boolean = true;
  overallocationerror_4: boolean = true;
  overallocationerror_5: boolean = true;
  startWith = new Date();
  instanceId: number;
  isPercentageSelected = false;
  resource;
  today = new Date();
  todaysDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), 0, 0, 0);
  formGroupSplit = this.formBuilder.group({
    engagement: [''],
    engagementServiceLine: [''],
    engagementStatus: [''],
    engagementCode: [''],
    unconfirmed: [''],
    ghost: [''],
    bookingType: [''],
    description: [''],

    period1: this.formBuilder.group({
      start1: [''],
      end1: ['']
    }),
    period2: this.formBuilder.group({
      start2: ['', Validators.required],
      end2: ['', Validators.required]
    }),
    period3: this.formBuilder.group({
      start3: ['', Validators.required],
      end3: ['', Validators.required]
    }),
    period4: this.formBuilder.group({
      start4: ['', Validators.required],
      end4: ['', Validators.required]
    }),
    period5: this.formBuilder.group({
      start5: ['', Validators.required],
      end5: ['', Validators.required]
    }),

    engagementAllocation: [''],


    allocationValue_2: ['', [
      Validators.required,
      Validators.pattern(/^(?!(0))[0-9]*(\.[0-9]{0,2})?$/),
      //Validators.max(999)
    ]],

    allocationValue_3: ['', [
      Validators.required,
      Validators.pattern(/^(?!(0))[0-9]*(\.[0-9]{0,2})?$/),
      //Validators.max(999)
    ]],


    allocationValue_4: ['', [
      Validators.required,
      Validators.pattern(/^(?!(0))[0-9]*(\.[0-9]{0,2})?$/),
      // Validators.max(999)
    ]],


    allocationValue_5: ['', [
      Validators.required,
      Validators.pattern(/^(?!(0))[0-9]*(\.[0-9]{0,2})?$/),
      //Validators.max(999.99)
    ]]
  });


  constructor(private individualServices: IndividualService, private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    public sharedService: SharedServicesService) {
    //this.initializeFormGroup();
  }


  ngOnInit() {
    // this.getCurrentWeek();
    this.enabledWeek(!this.currentWeekActionDTO.IsDisabledCopyBookingCurrWeek);

    this.resource = JSON.parse(sessionStorage.getItem('resource'));
    // this.applicationInsightsService.logPageView("Individual View Split Bookings");
    const currentDate = new Date();
    this.bsConfig = Object.assign({}, {
      containerClass: this.colorTheme,
      showWeekNumbers: false, minDate: currentDate, rangeInputFormat: 'DD-MM-YYYY'
    });
    this.splitId = 1;
    this.booking = new IndividualBookingVM();
    this.response = new Response();
    this.individualServices.splitEvent.subscribe((data) => {
      this.eventData = data;
      this.setData(data);
    });
    this.individualServices.currentMessageEngagementAllocation.subscribe(isPercentageSelected => {
      this.isPercentageSelected = isPercentageSelected
      this.setEngagementAllocation(isPercentageSelected);
    });
    this.individualServices.currentMessageCloseModal.subscribe(closeModal => {
      this.closeModalWindow = closeModal;
    });
    this.individualServices.currentMessageCloseChildModal.subscribe(closeChildModal => {
      this.closeModalWindow = closeChildModal;
    });
  }

  onSplitSubmit() {
    this.formGroupSplit.get('period1').get('start1').setErrors(null);
    this.formGroupSplit.get('period1').get('start1').setValidators(null);
    this.formGroupSplit.get('period1').get('end1').setErrors(null);
    this.formGroupSplit.get('period1').get('end1').setValidators(null);

    if (!this.showSplit_2) {
      this.formGroupSplit.get('period3').get('start3').setErrors(null);
      this.formGroupSplit.get('period3').get('start3').setValidators(null);
      this.formGroupSplit.get('period3').get('end3').setErrors(null);
      this.formGroupSplit.get('period3').get('end3').setValidators(null);
      this.formGroupSplit.get('allocationValue_3').setErrors(null);
      this.formGroupSplit.get('allocationValue_3').setValidators(null);
    } else {
      this.formGroupSplit.get('period3').get('start3').markAsDirty;
      this.formGroupSplit.get('period3').get('start3').touched;
      this.formGroupSplit.get('period3').get('end3').markAsDirty;
      this.formGroupSplit.get('period3').get('end3').touched;
      this.formGroupSplit.get('allocationValue_3').markAsDirty;
      this.formGroupSplit.get('allocationValue_3').touched;
    }
    if (!this.showSplit_3) {
      this.formGroupSplit.get('period4').get('start4').setErrors(null);
      this.formGroupSplit.get('period4').get('start4').setValidators(null);
      this.formGroupSplit.get('period4').get('end4').setErrors(null);
      this.formGroupSplit.get('period4').get('end4').setValidators(null);
      this.formGroupSplit.get('allocationValue_4').setErrors(null);
      this.formGroupSplit.get('allocationValue_4').setValidators(null);
    }
    if (!this.showSplit_4) {
      this.formGroupSplit.get('period5').get('start5').setErrors(null);
      this.formGroupSplit.get('period5').get('start5').setValidators(null);
      this.formGroupSplit.get('period5').get('end5').setErrors(null);
      this.formGroupSplit.get('period5').get('end5').setValidators(null);
      this.formGroupSplit.get('allocationValue_5').setErrors(null);
      this.formGroupSplit.get('allocationValue_5').setValidators(null);
    }
    if (!this.formGroupSplit.invalid) {
      this.booking.ResourceId = this.resourceId;

      if (this.isValidationRequired) {
        this.spinner.show();
        if (this.isFirstRecordEdited) {
          this.booking.StartDate = this.formGroupSplit.get('period1').get('start1').value;
          this.booking.EndDate = this.formGroupSplit.get('period1').get('end1').value;
        }
        else {
          this.booking.StartDate = null;//this.setStartDateTime(new Date(this.bsRangeValue[0]));
          this.booking.EndDate = null;//this.setEndDateTime(new Date(this.bsRangeValue[1]));
        }
        this.setSplitValues();
        this.hoursValid = true;
        // this.hoursValid_2 = true;
        // this.hoursValid_3 = true;
        // this.hoursValid_4 = true;
        // this.hoursValid_5 = true;
        this.isConfirmFlag = true;

        if (this.isNewRecordAdded) {
          this.booking.BookingId = 0;
        }
        else {
          this.booking.BookingId = this.bookingId;
        }
        this.booking.InstanceId = this.instanceId;
        this.booking.ActionDescriptionId = 3;
        this.splitBookingSubscription = this.individualServices.validateHours(this.booking).subscribe(
          (data: any) => {
            this.hoursValidationResultList = data;

            // if (!this.hoursValidationResultList[0]) {
            //   this.hoursValid = false;
            //   this.formGroupSplit.get('engagementAllocation').setErrors({ 'incorrect': true });
            //   this.overallocationerror = false;
            // }
            if (this.hoursValidationResultList[0] !== undefined && !this.hoursValidationResultList[0]) {
              this.hoursValid = false;
              this.formGroupSplit.get('allocationValue_2').setErrors({ 'incorrect': true });
              this.overallocationerror = false;
            }
            if (this.hoursValidationResultList[1] !== undefined && !this.hoursValidationResultList[1]) {
              //this.isHoursValid_3=false;
              this.hoursValid = false;
              this.formGroupSplit.get('allocationValue_3').setErrors({ 'incorrect': true });
              this.overallocationerror = false;
            }
            if (this.hoursValidationResultList[2] !== undefined && !this.hoursValidationResultList[2]) {
              //this.isHoursValid_4=false;
              this.hoursValid = false;
              this.formGroupSplit.get('allocationValue_4').setErrors({ 'incorrect': true });
              this.overallocationerror = false;
            }
            if (this.hoursValidationResultList[3] !== undefined && !this.hoursValidationResultList[3]) {
              //this.isHoursValid_5=false;
              this.hoursValid = false;
              this.formGroupSplit.get('allocationValue_5').setErrors({ 'incorrect': true });
              this.overallocationerror = false;
            }

            if (this.hoursValid || (!this.hoursValid && this.confirmHours)) {
              this.save();
            }

            // else 
            // {
            //   if(!this.hoursValid && !this.confirmHours)
            //   {
            //     this.isConfirmFlag = false;
            //   } 
            //   if(!this.hoursValid_2 && !this.confirmHours_2)
            //   {
            //     this.isConfirmFlag = false;
            //   } 
            //   if(!this.hoursValid_3 && !this.confirmHours_3)
            //   {
            //     this.isConfirmFlag = false;
            //   }
            //   if(!this.hoursValid_4 && !this.confirmHours_4)
            //   {
            //     this.isConfirmFlag = false;
            //   } 
            //   if(!this.hoursValid_5 && !this.confirmHours_5)
            //   {
            //     this.isConfirmFlag = false;
            //   }

            // 
            // }
            this.spinner.hide();
          }
        );
      }
      // else
      // {
      //   this.save();
      // }
    }
  }

  public confirmHoursChange() {
    // if(this.formGroupSplit.get(checkboxSelected).value != ''){
    //   this.formGroupSplit.get(checkboxSelected).setErrors(null)
    // }
    if (this.formGroupSplit.get('engagementAllocation').value != '') {
      this.formGroupSplit.get('engagementAllocation').setErrors(null)
    }
    if (this.formGroupSplit.get('allocationValue_2').value != ''
      || this.formGroupSplit.get('allocationValue_2').value != 0) {
      this.formGroupSplit.get('allocationValue_2').setErrors(null)
    }
    if (this.formGroupSplit.get('allocationValue_3').value != ''
      || this.formGroupSplit.get('allocationValue_3').value != 0) {
      this.formGroupSplit.get('allocationValue_3').setErrors(null)
    }
    if (this.formGroupSplit.get('allocationValue_4').value != ''
      || this.formGroupSplit.get('allocationValue_4').value != 0) {
      this.formGroupSplit.get('allocationValue_4').setErrors(null)
    }
    if (this.formGroupSplit.get('allocationValue_5').value != ''
      || this.formGroupSplit.get('allocationValue_5').value != 0) {
      this.formGroupSplit.get('allocationValue_5').setErrors(null)
    }

  }

  @Output() isFormDirtyFlag: EventEmitter<boolean> = new EventEmitter<boolean>();

  public dirtyFlag = false;

  public makeDirty(controlName: string) {
    if (!this.dirtyFlag) {
      this.dirtyFlag = true;
      this.isFormDirtyFlag.emit(this.dirtyFlag);
    }
    // if (controlName == 'period1' || controlName == 'Hours_1' || controlName == 'unconfirmed') {
    //   this.isValidationRequired = true;
    //   this.isFirstRecordEdited = true;
    // }
    if (controlName == 'period2') {
      this.isValidationRequired = true;
      this.isNewRecordAdded = true;
    }
    // if(controlName == 'Split'){
    //   this.isValidationRequired = false;
    // }

    if ((controlName == 'period2' || controlName == 'Hours_2' || controlName == 'unconfirmed_2') ||
      (controlName == 'period3' || controlName == 'Hours_3' || controlName == 'unconfirmed_3') ||
      (controlName == 'period4' || controlName == 'Hours_4' || controlName == 'unconfirmed_4') ||
      (controlName == 'period5' || controlName == 'Hours_5' || controlName == 'unconfirmed_5')) {
      this.isValidationRequired = true;
      this.isNewRecordAdded = true;
    }

    // if(controlName=='Hours_1' || controlName=='Hours_2' || 
    //    controlName=='Hours_3' || controlName=='Hours_4' ||controlName=='Hours_5' )
    // {
    //   this.overallocationerror = true; 
    // }

    if (this.overallocationerror_1 == false && controlName == 'Hours_1') {
      this.overallocationerror_1 = true;
    }
    if (this.overallocationerror_2 == false && controlName == 'Hours_2') {
      this.overallocationerror_2 = true;
    } if (this.overallocationerror_3 == false && controlName == 'Hours_3') {
      this.overallocationerror_3 = true;
    } if (this.overallocationerror_4 == false && controlName == 'Hours_4') {
      this.overallocationerror_4 = true;
    } if (this.overallocationerror_5 == false && controlName == 'Hours_5') {
      this.overallocationerror_5 = true;
    }

  }

  public checkDirty(controlName: string) {
    if (!this.dirtyFlag) {
      if (this.formGroupSplit.get('Period').dirty || this.formGroupSplit.get('Period_2').dirty || this.formGroupSplit.get('Period_3').dirty || this.formGroupSplit.get('Period_4').dirty || this.formGroupSplit.get('Period_5').dirty) {
        this.makeDirty(controlName);
      }
    }
  }

  setUnconfirmed() {
    this.unconfirmed_2 = this.unconfirmed;
    this.unconfirmed_3 = this.unconfirmed;
    this.unconfirmed_4 = this.unconfirmed;
    this.unconfirmed_5 = this.unconfirmed;
  }
  setEngagementAllocation(isPercentageSelected) {
    if (!isPercentageSelected) {
      this.engagementAllocationText = 'Hours';
      this.formGroupSplit.controls.engagementAllocation.setValue(this.hours);
      this.formGroupSplit.controls.allocationValue_2.setValue(this.hours);
      this.formGroupSplit.controls.allocationValue_3.setValue(this.hours);
      this.formGroupSplit.controls.allocationValue_4.setValue(this.hours);
      this.formGroupSplit.controls.allocationValue_5.setValue(this.hours);
      this.OldHours = this.engagementAllocationTypeValue;
    } else {
      this.engagementAllocationText = 'Loading %';
      if (this.loading !== '' && this.loading != null) {
        this.formGroupSplit.controls.engagementAllocation.setValue(this.loading);
        this.formGroupSplit.controls.allocationValue_2.setValue(this.loading);
        this.formGroupSplit.controls.allocationValue_3.setValue(this.loading);
        this.formGroupSplit.controls.allocationValue_4.setValue(this.loading);
        this.formGroupSplit.controls.allocationValue_5.setValue(this.loading);
        this.OldLoading = this.engagementAllocationTypeValue;
      }
    }
    this.engagementAllocationPercentSelected = isPercentageSelected;
  }

  setData(rowdata: BookingGridActionService) {
    this.formGroupSplit.reset();
    this.formGroupSplit.controls.bookingType.setValue(rowdata.dataItem.BookingType);
    this.formGroupSplit.controls.engagement.setValue(rowdata.dataItem.Engagement);
    this.formGroupSplit.controls.engagementCode.setValue(rowdata.dataItem.EngagementCode);
    this.formGroupSplit.controls.engagementServiceLine.setValue(rowdata.dataItem.ServiceLineDescription);
    this.formGroupSplit.controls.engagementStatus.setValue(rowdata.dataItem.JobStatus);
    this.formGroupSplit.get('period1').get('start1').setValue(rowdata.dataItem.StartDate);
    this.formGroupSplit.get('period1').get('end1').setValue(rowdata.dataItem.EndDate);
    this.bookingId = rowdata.dataItem.BookingId;
    this.resourceId = rowdata.dataItem.ResourceId;
    this.jobId = rowdata.dataItem.JobId;
    this.loading = rowdata.dataItem.Loading;
    this.hours = rowdata.dataItem.Hours;
    this.OldLoading = rowdata.dataItem.Loading;
    this.OldHours = rowdata.dataItem.Hours;
    this.OldStartDate = rowdata.dataItem.StartDate;
    this.OldEndDate = rowdata.dataItem.EndDate;
    this.instanceId = rowdata.dataItem.InstanceId;
    this.ghost = rowdata.dataItem.Ghost;
    this.ghost_2 = rowdata.dataItem.Ghost;
    this.ghost_3 = rowdata.dataItem.Ghost;
    this.ghost_4 = rowdata.dataItem.Ghost;
    this.ghost_5 = rowdata.dataItem.Ghost;
    this.bookingType = rowdata.dataItem.BookingType;
    this.description = rowdata.dataItem.Description;
    this.enabledWeek(!rowdata.dataItem.IsCurrentEditWeek);
    if (this.bookingType.includes('Unconfirmed')) {
      this.unconfirmed = true;
      this.OldUnconfirmed = true;
    } else {
      this.unconfirmed = false;
      this.OldUnconfirmed = false;
    }
    this.setEngagementAllocation(this.engagementAllocationPercentSelected);
    this.setUnconfirmed();

  }



  setSplitValues() {

    if (this.OldStartDate !== this.formGroupSplit.get('period1').get('start1').value
      || this.OldEndDate !== this.formGroupSplit.get('period1').get('end1').value
      || this.OldUnconfirmed !== this.unconfirmed) {
      this.booking.Unconfirmed = this.unconfirmed;
      this.booking.StartDate = this.formGroupSplit.get('period1').get('start1').value;
      this.booking.EndDate = this.formGroupSplit.get('period1').get('end1').value;
      this.booking.Loading = +this.loading;
      this.booking.Hours = this.formGroupSplit.get('engagementAllocation').value;
      this.booking.IsSplitOneEdit = true;
    }
    this.booking.ResourceId = this.resourceId;
    this.booking.BookingId = this.bookingId;
    this.booking.JobId = this.jobId;
    this.booking.InstanceId = this.instanceId;
    this.booking.BookingType = this.formGroupSplit.get('bookingType').value;
    this.booking.Description = this.formGroupSplit.get('description').value;
    this.booking.ActionDescriptionId = 3;
    this.booking.MeraBookingStatusId = 5;
    this.booking.Unconfirmed_2 = this.unconfirmed_2;
    this.booking.Unconfirmed_3 = this.unconfirmed_3;
    this.booking.Unconfirmed_4 = this.unconfirmed_4;
    this.booking.Unconfirmed_5 = this.unconfirmed_5;

    this.booking.Ghost = this.ghost;
    this.booking.Ghost_2 = this.ghost_2;
    this.booking.Ghost_3 = this.ghost_3;
    this.booking.Ghost_4 = this.ghost_4;
    this.booking.Ghost_5 = this.ghost_5;

    this.booking.StartDate_2 = this.formGroupSplit.get('period2').get('start2').value;
    this.booking.EndDate_2 = this.formGroupSplit.get('period2').get('end2').value;
    this.booking.StartDate_3 = this.formGroupSplit.get('period3').get('start3').value;
    this.booking.EndDate_3 = this.formGroupSplit.get('period3').get('end3').value;
    this.booking.StartDate_4 = this.formGroupSplit.get('period4').get('start4').value;
    this.booking.EndDate_4 = this.formGroupSplit.get('period4').get('end4').value;
    this.booking.StartDate_5 = this.formGroupSplit.get('period5').get('start5').value;
    this.booking.EndDate_5 = this.formGroupSplit.get('period5').get('end5').value;

    this.booking.IsOverallocation_1 = this.confirmHours;
    this.booking.IsOverallocation_2 = this.confirmHours;
    this.booking.IsOverallocation_3 = this.confirmHours;
    this.booking.IsOverallocation_4 = this.confirmHours;
    this.booking.IsOverallocation_5 = this.confirmHours;
    if (this.engagementAllocationPercentSelected) {
      if (this.OldLoading !== this.formGroupSplit.get('engagementAllocation').value || this.OldHours !== this.hours) {
        this.booking.Loading = this.formGroupSplit.get('engagementAllocation').value;
        this.booking.Hours = this.hours;
        this.booking.Unconfirmed = this.unconfirmed;
        this.booking.StartDate = this.formGroupSplit.get('period1').get('start1').value;
        this.booking.EndDate = this.formGroupSplit.get('period1').get('end1').value;
        this.booking.IsSplitOneEdit = true;
      }
      this.booking.Loading_2 = this.formGroupSplit.get('allocationValue_2').value;
      this.booking.Loading_3 = this.formGroupSplit.get('allocationValue_3').value;
      this.booking.Loading_4 = this.formGroupSplit.get('allocationValue_4').value;
      this.booking.Loading_5 = this.formGroupSplit.get('allocationValue_5').value;
      this.booking.IsPercentSelected = true;
      this.booking.Hours_2 = this.hours;
      this.booking.Hours_3 = this.hours;
      this.booking.Hours_4 = this.hours;
      this.booking.Hours_5 = this.hours;
    } else {
      if (this.OldLoading !== this.loading || this.OldHours !== this.formGroupSplit.get('engagementAllocation').value) {
        this.booking.Hours = this.formGroupSplit.get('engagementAllocation').value;
        this.booking.Loading = +this.loading;
        this.booking.Unconfirmed = this.unconfirmed;
        this.booking.StartDate = this.formGroupSplit.get('period1').get('start1').value;
        this.booking.EndDate = this.formGroupSplit.get('period1').get('end1').value;
        this.booking.IsSplitOneEdit = true;
      }
      this.booking.Hours_2 = this.formGroupSplit.get('allocationValue_2').value;
      this.booking.Hours_3 = this.formGroupSplit.get('allocationValue_3').value;
      this.booking.Hours_4 = this.formGroupSplit.get('allocationValue_4').value;
      this.booking.Hours_5 = this.formGroupSplit.get('allocationValue_5').value;
      this.booking.Loading_2 = +this.loading_2;
      this.booking.Loading_3 = +this.loading_3;
      this.booking.Loading_4 = +this.loading_4;
      this.booking.Loading_5 = +this.loading_5;
    }
    if (!this.showSplit) {
      this.booking.StartDate_2 = null; this.booking.EndDate_2 = null; this.booking.Loading_2 = null; this.booking.Hours_2 = null;
    }
    if (!this.showSplit_2) {
      this.booking.StartDate_3 = null; this.booking.EndDate_3 = null; this.booking.Loading_3 = null; this.booking.Hours_3 = null;
    }
    if (!this.showSplit_3) {
      this.booking.StartDate_4 = null; this.booking.EndDate_4 = null; this.booking.Loading_4 = null; this.booking.Hours_4 = null;
    }
    if (!this.showSplit_4) {
      this.booking.StartDate_5 = null; this.booking.EndDate_5 = null; this.booking.Loading_5 = null; this.booking.Hours_5 = null;
    }
    //this.booking.currentUserId = +sessionStorage.getItem('resourceId');

    this.booking.IsExistingBooking=true;
    this.booking.IsExistingBooking_2=false;
    this.booking.IsExistingBooking_3=false;
    this.booking.IsExistingBooking_4=false;
    this.booking.IsExistingBooking_5=false;
  }

  showSplit() {
    this.makeDirty('Split');
    this.splitId = this.splitId + 1;
    switch (this.splitId) {
      case 2:
        {
          if (this.showSplit_2) {
            this.showSplit();
          }
          this.showSplit_2 = true;
          break;
        }
      case 3:
        {
          if (this.showSplit_3) {
            this.showSplit();
          }
          this.showSplit_3 = true;
          break;
        }
      case 4:
        {
          if (this.showSplit_4) {
            this.showSplit();
          }
          this.showSplit_4 = true;
          break;
        }
      default:
        {
          if (this.splitId > 5) {
            this.splitId = 1;
            if (this.showSplit_2 && this.showSplit_3 && this.showSplit_4) {
              break;
            } else {
              this.showSplit();
            }
          }
          if (!this.showSplit_2 && !this.showSplit_3 && !this.showSplit_4) {
            this.splitId = 1;
            this.showSplit();
          }
          break;
        }
    }
  }

  hideSplit(splitId) {
    this.splitId = splitId - 1;
    switch (splitId) {
      case 2:
        {
          this.showSplit_2 = false;
          break;
        }
      case 3:
        {
          this.showSplit_3 = false;
          break;
        }
      case 4:
        {
          this.showSplit_4 = false;
          break;
        }
    }
    if (!this.showSplit_2 && !this.showSplit_3 && !this.showSplit_4) {
      this.splitId = 1;
    }
  }


  save() {
    this.buttonText = "Copying...";
    this.setSplitValues();
    // this.booking.CurrentUserEmail = this.currentUserEmail;
    // this.booking.CurrentUserName = this.currentUserName;
    this.booking.RequestMode = JSON.parse(sessionStorage.getItem('requestMode'));
    this.booking.UiViewSource = UiViewSourceEnum.IndividualView;

    let bookingActionDto: BookingActionDto = this.sharedService.getBookingActionDto(this.booking);

    this.splitBookingSubscription =
      this.individualServices.splitBooking(bookingActionDto).subscribe(
        (data: any) => {
          this.spinner.hide();
          if (data.status = '200') {
            this.response = data.body;
            if (this.response.customResultId > 0) { this.individualServices.setRquestNumber(this.response.customResultId); }
            //alert('Successfully saved the changes');
            if (this.calledFromChild) {
              this.individualServices.closeChildModal(true);
              this.individualServices.UpdateIndividualFilterBookingGrid();
            } else {
              this.individualServices.closeModal(true);
            }
          }
          this.buttonText = "Copy";
        }, err => {
          this.buttonText = "Error";
        }, () => {
          this.buttonText = "Copy";
        });
    this.dirtyFlag = false;
  }
  mouseEnter() {
  }
  mouseEnterSplit() {
  }

  mouseLeave(val) {
  }

  isValidInput(fieldName): boolean {
    return this.formGroupSplit.controls[fieldName].invalid &&
      (this.formGroupSplit.controls[fieldName].dirty || this.formGroupSplit.controls[fieldName].touched);
  }

  enabledWeek(isCurrentWeekEditable) {
    if (this.currentWeekActionDTO.IsDisabledCopyBookingCurrWeek === true) {
      isCurrentWeekEditable = false;
    }
    this.startWith = this.getFirstDateOfWeek(this.todaysDate);
    if (isCurrentWeekEditable) {
      this.startWith.setMinutes(this.startWith.getMinutes() - this.startWith.getTimezoneOffset());
    }
    else {
      this.startWith.setDate(this.startWith.getDate() + 7);
      this.startWith.setMinutes(this.startWith.getMinutes() - this.startWith.getTimezoneOffset());
    }

    // const dayNeeded = 7 //For Saturday
    // const currentDay = moment().isoWeekday();
    // if(this.EditCopy === 1){
    //   isCurrentWeekEditable = false;
    // }
    // if(isCurrentWeekEditable){
    //   if (currentDay === dayNeeded) { 
    //     let day = moment().day(dayNeeded);
    //     this.startWith = new Date(day.year(),day.month(),day.date())
    //   }else{
    //     let day = moment().subtract(1, 'weeks').isoWeekday(dayNeeded);
    //     this.startWith = new Date(day.year(),day.month(),day.date());  
    //   }
    // }else{
    //   if (currentDay === dayNeeded) { 
    //     let day = moment().add(1, 'weeks').isoWeekday(dayNeeded);
    //     this.startWith = new Date(day.year(),day.month(),day.date());
    //   }else{
    //     let day = moment().day(dayNeeded);
    //     this.startWith = new Date(day.year(),day.month(),day.date());

    //   }
    // }


  }

  getFirstDateOfWeek(anyDateOfWeek: Date): Date {
    // date.getDay() RETURNS Week Day Index: SUN 0, MON 1, TUE 2, WED 3, THU 4, FRI 5, SAT 6.
    let date = new Date(anyDateOfWeek);
    let firstDayOfWeekIndex: number = 6; //Week Day Index: SUN 0, MON 1, TUE 2, WED 3, THU 4, FRI 5, SAT 6.
    let daysToShiftBack = (7 + (date.getDay() - firstDayOfWeekIndex)) % 7;
    let firstDateOfWeek = new Date(date.setDate(date.getDate() - daysToShiftBack));
    return firstDateOfWeek;
  }
  //CurrentWeekVM: CurrentWeekVM;
  // EditCopy: number = 0;

  // getCurrentWeek() {
  //   this.individualServices.getCurrentWeek().subscribe(
  //     (data: any) => {
  //       this.EditCopy = data.Copy;

  //       const currentDate = new Date();

  //       if (this.EditCopy == 1) {
  //         this.enabledWeek(false);
  //       }
  //       if (this.EditCopy == 0 || this.EditCopy == null) {
  //         this.enabledWeek(true);
  //       }
  //     });
  // }

}
