import { MasterVM } from "./MasterVM";

export class ResourceEditConfigurationDto {
       ResEditSubAreas :MasterVM[];
       ResEditManagerialCountries :MasterVM[];
       ResEditLegalEntities:MasterVM[];
       ResEditServiceLines :MasterVM[];
       ResEditBusinessUnits:MasterVM[];
       ResEditSubServiceLines :MasterVM[];
       ResEditSubManageUnits :MasterVM[]
       ResEditRanks :MasterVM[];
       RREC_RInactive:boolean;
       RREC_ISDisabled :boolean;
       RREC_IV_Disabled :boolean;
       RREC_EV_Disabled :boolean;
}

export class JobEditConfigurationDto {
       JobEditSubAreas :MasterVM[];
       JobEditManagerialCountries :MasterVM[];
       JobEditLegalEntities:MasterVM[];
       JobEditServiceLines :MasterVM[];
       JobEditBusinessUnits:MasterVM[];
       JobEditSubServiceLines :MasterVM[];
       JobEditSubManageUnits :MasterVM[]
       JobEditRanks :MasterVM[];
       JobEditJobCategories:MasterVM[];
       JobEditJobStatuses :MasterVM[];
       JobEditJobStatusBookings :MasterVM[]
       JobEditJobCode :MasterVM[];
       JobEditIncludeJobCode
       RJEC_Learning :boolean;
       RJEC_ISDisabled :boolean;
       RJEC_IsPended :boolean;
       RJEC_IV_Disabled :boolean;
       RJEC_EV_Disabled :boolean;

}