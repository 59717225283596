import { Component, OnInit, Input, OnDestroy, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { IndividualService } from '../individual.service';
import { IndividualBookingVM } from '../../../Models/IndividualBookingVM';
import { ModalOptions, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BookingGridActionService } from '../../../Models/BookingGridActionService';
import { NavigationExtras, Router } from '@angular/router';
import { SharedServicesService } from '../../../shared/sharedservices/shared-services.service';
import { CurrentWeekActionDTO } from '../../../Models/CurrentWeekActionDTO';

@Component({
  selector: 'app-individualbookingchild',
  templateUrl: './individualbookingchild.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./individualbookingchild.component.scss']
})
export class IndividualbookingchildComponent implements OnInit, OnDestroy {
  @Input() public bookingData: IndividualBookingVM;
  @Input() currentWeekActionDTO: CurrentWeekActionDTO;
  @Input() SearchedResMultiInstance: boolean;

  public view: Observable<any>;
  public skip = 0;
  bookingListSubscription: Subscription;
  bookingListOrginal: IndividualBookingVM[];
  bookingList: IndividualBookingVM[];
  public hiddenColumns: string[] = [];
  modalOption: ModalOptions = {};
  showModal = false;
  showModalAdd = false;
  showModalSplit = false;
  showModalDelete = false;
  showModalCancel = false;
  showModelSwap = false;
  gridActionModalHeader = '';
  modalRef: BsModalRef;
  confirmModalRef: BsModalRef;
  gridActionEventData: BookingGridActionService;
  format = '#.# \\%';
  public pageSize = 2;
  user;
  loading = true;
  editDelete: number;
  @Input() isPercentageSelected;
  defaultDateFormat: string;
  displayedColumns: string[] = ['BookingType', 'Engagement', 'Instance', 'Hours', 'Loading', 'StartDate', 'EndDate', 'Actions'];
  @Output()
  GetAllBookings = new EventEmitter();

  constructor(public restService: IndividualService,
    private modalService: BsModalService, private router: Router, public sharedService: SharedServicesService) {
    this.view = this.restService;
    this.bookingListOrginal = [];
    this.bookingList = [];
  }

  ngOnInit() {
    // this.getCurrentWeek();
    this.editDelete = this.currentWeekActionDTO.IsDisabledDeleteBookingCurrWeek == true ? 1 : 0;
    this.user = JSON.parse(sessionStorage.getItem('resource'));
    
    // if(this.user.Ghost){
    //   this.displayedColumns.splice(this.displayedColumns.indexOf('EndDate')+1, 0, 'Ghost');
    // }
    this.defaultDateFormat = this.sharedService.getDateFormat(this.user.DefaultDateFormat);
    this.bookingList = [];
    this.getBookingList();
    // this.restService.editEvent.subscribe((data) => {
    //   this.gridActionEventData = data;
    // });
    this.gridActionEventData = new BookingGridActionService();
    this.restService.currentMessageCloseChildModal.subscribe((closeChildModal) => {
      this.closeActionModal(closeChildModal);
    });
    this.restService.currentMessageEngagementAllocation.subscribe(isPercentageSelected => {
      this.setEngagementAllocation(isPercentageSelected);
    });
    this.restService.currentMessageCloseModal.subscribe((closeModal) => {
      this.closeActionModal(closeModal);
    });
  }
  
  manageInstanceColumn() {
    const indexOfInstance = this.displayedColumns.indexOf('Instance', 0);
    if (this.SearchedResMultiInstance){
      if(indexOfInstance === -1)
        this.displayedColumns.splice(this.displayedColumns.indexOf('Engagement') + 1, 0, 'Instance');
    }
    else {
      if(indexOfInstance > -1)
        this.displayedColumns.splice(indexOfInstance, 1);
    }
  }

  public rowsToHighlight: any[] = [];
  // public isRowSelected = (e: RowArgs) => this.rowsToHighlight.indexOf(e.dataItem.HighlightStatus) >= 0; 

  getBookingList() {
    this.loading = true;
    this.bookingData.IsPercentSelected = this.isPercentageSelected;
    this.bookingData.LoggedInResourceID = + sessionStorage.getItem('loggedin_resourceid');
    this.manageInstanceColumn();

    this.bookingList = [];
    this.bookingListSubscription = this.restService.fetchBookingChildData(this.bookingData).subscribe(
      data => {
        if (data) {
          this.bookingList = data;
          this.bookingList?.forEach((element) => {
            // element.StartDate = new Date(element.StartDate).toDateString(), element.EndDate = new Date(element.EndDate).toDateString();
            if (element.HighlightStatus) {
              this.rowsToHighlight.push(element.HighlightStatus);
            }

          });
          this.loading = false;
        }

      });
  }
  // getCurrentWeek() {

  //   this.restService.getCurrentWeek().subscribe(
  //     (data: any) => {
  //       this.editDelete = data.DeleteWeek; 

  //     });

  // }
  setEngagementAllocation(isPercentageSelected) {
    const indexOfInstance = this.displayedColumns.indexOf('Instance', 0);
    if (isPercentageSelected) {
      const indexOfPercentage = this.displayedColumns.indexOf('Loading', 0);
      if (indexOfPercentage === -1) {
        this.displayedColumns.splice(
          this.displayedColumns.indexOf('Engagement') + (indexOfInstance > -1 ? 2 : 1), 
          0, 'Loading'
        );
      }
      const indexOfHour = this.displayedColumns.indexOf('Hours', 0);
      if (indexOfHour > -1) {
        this.displayedColumns.splice(indexOfHour, 1);
      }
    } else {
      const indexOfHour = this.displayedColumns.indexOf('Hours', 0);
      if (indexOfHour === -1) {
        this.displayedColumns.splice(
          this.displayedColumns.indexOf('Engagement') + (indexOfInstance > -1 ? 2 : 1), 
          0, 'Hours'
        );
      }
      const indexOfPercentage = this.displayedColumns.indexOf('Loading', 0);
      if (indexOfPercentage > -1) {
        this.displayedColumns.splice(indexOfPercentage, 1);
      }
    }
  }

  public isDirtyFlag = false;

  makeDirty(data) {
    this.isDirtyFlag = data;
  }

  openConfirmationModal(template) {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    if (!this.isDirtyFlag || this.showModalDelete) {
      if (this.modalRef !== undefined && this.modalRef !== null) {
        this.modalRef.hide();
      }
    } else {
      this.confirmModalRef = this.modalService.show(template, this.modalOption);
      this.isDirtyFlag = false;
    }
  }

  openActionModal(template, event, dataItem, templateName) {
    this.showModal = false;
    this.showModalAdd = false;
    this.showModalSplit = false;
    this.showModalDelete = false;
    this.showModalCancel = false;
    this.showModelSwap = false;

    this.gridActionEventData.dataItem = dataItem;
    this.gridActionEventData.event = event;
    this.gridActionEventData.template = template;
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    if (templateName === 'edit') {
      this.gridActionModalHeader = 'Edit Booking';
      this.showModal = true;
      this.restService.openEditModal(this.gridActionEventData);
    }

    else if (templateName === 'swap') {
      this.gridActionModalHeader = 'Replace Booking';
      this.showModelSwap = true;
      this.restService.openSwapModel(this.gridActionEventData);
    }

    else if (templateName === 'add') {
      this.gridActionModalHeader = 'New Booking';
      this.showModalAdd = true;
      this.restService.openAddModal(this.gridActionEventData);
    } else if (templateName === 'split') {
      this.gridActionModalHeader = 'Copy Booking';
      this.showModalSplit = true;
      this.restService.openSplitModal(this.gridActionEventData);
    } else if (templateName === 'delete') {
      this.gridActionModalHeader = 'Delete Booking';
      this.showModalDelete = true;
      this.restService.openDeleteModal(this.gridActionEventData);
    }
    else if (templateName === 'cancelAction') {
      this.gridActionModalHeader = 'Cancel Action Request';
      this.showModalCancel = true;
    }
    this.modalRef = this.modalService.show(template, this.modalOption);
  }

  closeActionModal(closeModal) {
    if (closeModal) {
      if (this.modalRef !== undefined && this.modalRef !== null) {
        this.modalRef.hide();
        this.GetAllBookings.emit();
        //this.getBookingList();
        //this.updateCount();
      }
    } else {
      if (this.modalRef !== undefined && this.modalRef !== null) {
        this.modalRef.hide();
      }

    }
  }

  okayConfirm(template) {
    this.confirmModalRef.hide();
    if (this.modalRef !== undefined && this.modalRef !== null) {
      this.modalRef.hide();
    }
  }

  ngOnDestroy() {
    if (this.bookingListSubscription !== undefined) {
      this.bookingListSubscription.unsubscribe();
    }
    this.modalRef = null;
  }

  @Output() updatePreviewCount = new EventEmitter();
  updateCount(): void {
    this.updatePreviewCount.next();
  }

  navigateEngagement(item) {
    if (!this.bookingData.IsDisabledEVNavigation && !this.bookingData.IsDisabledViewBooking_Job) {
      const product = {
        engagement: item.EngagementCode === null ? item.Engagement : item.EngagementCode,
        instanceId: item.InstanceId,
        resourcId: item.ResourceId,
        jobId: item.JobId
      }

      this.router.navigateByUrl('/EngagementBooking', { state: product });
    }
  }
  selectedItem: any;
  selectItem(item) {
    this.selectedItem = item.TooltipData;
  }
  previousPopover: any;
  closePrevious(currentPop) {
    if (this.previousPopover && this.previousPopover != currentPop) {
      this.previousPopover.hide();

    }
    this.previousPopover = currentPop;
  }
  closeCurrent(pop) {
    pop.hide();
  }
}
