import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  NavigationExtras,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';
import { CurrentUser } from '../../Models/CurrentUser';
import { LoginService } from '../../landing/login.service';

@Injectable({
  providedIn: 'root',
})
export class RoleguardService {
  constructor(
    public auth: AuthService,
    public router: Router,
    private loginService: LoginService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (!environment.EnableAuth) {
      return true;
    }
    // this will be passed from the route config
    // on the data property
    const expectedRoleFeature = route.data.expectedRoleFeature;

    const roleFeatures = CurrentUser.RoleFeatures;

    if (roleFeatures.length == 0) {
      this.loginService.setMenuHideAndShow(false);
      this.router.navigate(['Login']);
      return true;
    }

    let userRoleMatched = false;

    if (roleFeatures.filter(x=>x.FeatureName == expectedRoleFeature).length >= 0) {
      userRoleMatched = true;
    }

    if (!userRoleMatched && !(route.routeConfig.path === 'Home')) {
      this.loginService.setMenuHideAndShow(false);
      this.router.navigate(['Error']);
    } else {
      this.loginService.setMenuHideAndShow(true);
    }
    return true;
  }
}
