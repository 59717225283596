<form>

  <div class="popupLayout">
    <div *ngIf="parentViewIVorEV=='IV'" style="pointer-events: none;">
      <div class="fullWidth">
        <label>Engagement</label>
        <!-- <input type="text" class="form-control bg-grey" [readonly]="true"
        [value]='parentDataItem.Engagement'> -->
        <mat-form-field appearance="outline">
          <input type="text" [readonly]="true" [value]='parentDataItem.Engagement'
          matInput>
        </mat-form-field>
      </div>
    
      <div class="customRow">
        <div class="halfWidthItem">
          <label>Booking Type</label>
          <!-- <input type="text" class="form-control bg-grey" [readonly]="true"
          [value]='parentDataItem.BookingType'> -->
          <mat-form-field appearance="outline">
            <input type="text" [readonly]="true" [value]='parentDataItem.BookingType'
            matInput>
          </mat-form-field>
        </div>
        <div class="halfWidthItem">
          <label>Engagement Code</label>
          <!-- <input type="text" class="form-control bg-grey" [readonly]="true"
          [value]='parentDataItem.EngagementCode'> -->
          <mat-form-field appearance="outline">
            <input type="text" [readonly]="true" [value]='parentDataItem.EngagementCode'
            matInput>
          </mat-form-field>
        </div>
      </div>
    
      <div class="customRow">
        <div class="halfWidthItem">
          <label>Engagement Service Line</label>
          <!-- <input type="text" class="form-control bg-grey" [readonly]="true"
          [value]='parentDataItem.ServiceLineDescription'> -->
          <mat-form-field appearance="outline">
            <input type="text" [readonly]="true" [value]='parentDataItem.ServiceLineDescription'
            matInput>
          </mat-form-field>
        </div>
        <div class="halfWidthItem">
          <label>Engagement Status</label>
          <!-- <input type="text" class="form-control bg-grey" [readonly]="true"
          [value]="parentDataItem.JobStatus"> -->
          <mat-form-field appearance="outline">
            <input type="text" [readonly]="true" [value]='parentDataItem.JobStatus'
            matInput>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div *ngIf="parentViewIVorEV=='EV'" style="pointer-events: none;">
      <div class="fullWidth">
        <label>Resource Name</label>
        <!-- <input type="text" class="form-control bg-grey" [readonly]="true"
        [value]='parentDataItem.ResourceName'> -->
        <mat-form-field appearance="outline">
          <input type="text" [readonly]="true" [value]='parentDataItem.ResourceName'
          matInput>
        </mat-form-field>
      </div>
      
      <div class="customRow">
        <div class="halfWidthItem">
          <label>Service Line</label>
          <mat-form-field appearance="outline">
            <input type="text" matInput [value]="parentDataItem.ServiceLineDescription" [readonly]="true">
          </mat-form-field>
        </div>
        <div class="halfWidthItem">
          <label>HR Department</label>
          <mat-form-field appearance="outline">
            <input type="text" matInput [value]="parentDataItem.DepartmentDescription" [readonly]="true">
          </mat-form-field>
        </div>
      </div>

      <div class="customRow">
        <div class="halfWidthItem">
          <label>Rank</label>
          <!-- <input type="text" class="form-control bg-grey" [readonly]="true"
          [value]='parentDataItem.RankDescription'> -->
          <mat-form-field appearance="outline">
            <input type="text" [readonly]="true" [value]='parentDataItem.RankDescription'
            matInput>
          </mat-form-field>
        </div>
        <div class="halfWidthItem">
          <label>Office Location</label>
          <!-- <input type="text" class="form-control bg-grey" [readonly]="true"
          [value]="parentDataItem.LocationDescription"> -->
          <mat-form-field appearance="outline">
            <input type="text" [readonly]="true" [value]='parentDataItem.LocationDescription'
            matInput>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="customRow">
      <div class="col100percent">
        <label>Select All</label>        
        <!-- <input type="checkbox" class='check' [checked]="chkSelectAll"
        (click)="changeSelectAll($event)"> -->
        <mat-checkbox [checked]="chkSelectAll" (change)="changeSelectAll($event)" 
        [disableRipple]='true'></mat-checkbox>
      </div>
    </div>

    <div> 
      <div *ngFor="let item of deleteBookingsList; let i = index;" >
        <div title="{{ item.IsCurrentWeekDelete == true ? 'Disabled Current Week' : ''}}">
          <div class="estimationContainer">
            <div class="col10percent">
              <label>Select</label>        
              <!-- <input type="checkbox" class='check' [checked]="item.IsSelected" 
              [attr.disabled]="item.IsCurrentWeekDelete ? 'disabled' : null"
              (click)="changeCheck(item,$event)">  -->

              <mat-checkbox [checked]="item.IsSelected" (change)="changeCheck(item,$event)"
              [disabled]="item.IsCurrentWeekDelete ? 'true' : null"
              ></mat-checkbox>
            </div>        
            <div class="col50percent" style="cursor:default;">
              <label>Period</label>
              <!-- <input format="{dd-MM-yyyy}" type="text" class="form-control bg-grey"
              #dp="bsDaterangepicker" bsDaterangepicker [(ngModel)]="item.DateRange" 
              [bsConfig]="bsConfig" [placement]="'top'" [attr.disabled]="'disabled'">
              <span [attr.disabled]="'disabled'" class="glyphicon glyphicon-calendar glyicon" (click)="dp.toggle()" 
              [attr.aria-expanded]="dp.isOpen" value="Toggle" style="top:36px; pointer-events: none;"></span> -->

              <mat-form-field appearance="outline">  
                <mat-date-range-input>
                  <input format="{dd-MM-yyyy}" [value]="item.DateRange[0]"
                  matStartDate placeholder="start date" 
                    readonly>
                  <input format="{dd-MM-yyyy}" [value]="item.DateRange[1]"
                  matEndDate placeholder="end date"
                    readonly>
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix></mat-datepicker-toggle>
                <!-- <mat-date-range-picker #picker [attr.disabled]="'disabled'"></mat-date-range-picker> -->
              </mat-form-field>
            </div>
            <div class="col20percent">
              <label>{{hourLoadingLabel}}</label>
              <!-- <input [attr.disabled]="'disabled'" type="text" class="form-control bg-grey" 
                [value]="item.Hours" *ngIf="!isPercentSelected">
              <input [attr.disabled]="'disabled'" type="text" class="form-control bg-grey" 
                [value]="item.Loading" *ngIf="isPercentSelected"> -->

              <mat-form-field appearance="outline">
                <input [readonly]="true" type="text" [value]="item.Hours" *ngIf="!isPercentSelected"
                matInput autocomplete="off">
                <input [readonly]="true" type="text" [value]="item.Loading" *ngIf="isPercentSelected"
                matInput autocomplete="off">
              </mat-form-field>
            </div>
            <div class="col20percent" *ngIf="!resource?.Unconfirmed">
              <label>Unconfirmed</label>
              <!-- <input [attr.disabled]="'disabled'" type="checkbox" class="check bg-grey" [checked]="item.Unconfirmed"> -->

              <mat-checkbox style="cursor: default;" [checked]="item.Unconfirmed" 
              [disableRipple]='true' onclick="return false;">
              </mat-checkbox>
            </div>
            <div class="col20percent" *ngIf="resource?.Ghost">
              <label>Ghost</label>
              <mat-checkbox style="cursor: default;" [checked]="item.Ghost" 
              [disableRipple]='true' onclick="return false;">
              </mat-checkbox>
            </div>
          </div>
        </div> 
      </div>
    </div>
    <div class="SubmitContainer">
      <button class="submitButton" type="submit" 
      [ngClass]="{'k-state-disabled': buttonText!='Submit'}" (click)="submit()">
        <span class="glyphicon glyphicon-ok-sign pr-5" style="font-size: 12px;">
        </span>
        {{buttonText}}
      </button>
    </div>
  </div>

</form>
