import { Component, OnInit } from '@angular/core';
import { SharedServicesService } from '../shared/sharedservices/shared-services.service';
import { ResourceDto } from '../Models/ResourceDto';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginService } from '../landing/login.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  resource: ResourceDto;
  authorizationErrorSubscription: Subscription;
  
  errorMessage:string="";
  constructor(public sharedService: SharedServicesService,private spinner: NgxSpinnerService,private loginService : LoginService) { this.resource = new ResourceDto(); }

  ngOnInit() {
    
    this.getAuthorizationError();
    this.resource = new ResourceDto();
    // refresh Header & Footer
    this.sharedService.setHeaderDetails(this.resource);
    this.sharedService.setFooterDetails(this.resource);
    this.loginService.setMenuHideAndShow(false);
  }

  getAuthorizationError()
  {
    this.spinner.show();
     this.authorizationErrorSubscription = this.sharedService.getAuthorizationError().subscribe(
       (data) => {
         this.errorMessage = data;
         this.spinner.hide();
       }, (error) => {
        this.spinner.hide();
        this.errorMessage = error.error.text;
       });
  }
}
  
