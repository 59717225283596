import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ResourceDto } from 'app/Models/ResourceDto';
import { RoleConfigService } from '../role-config.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-audit-assign-role',
  templateUrl: './audit-assign-role.component.html',
  styleUrls: ['./audit-assign-role.component.scss'],
})
export class AuditAssignRoleComponent implements OnInit, AfterViewInit {
  @ViewChild('paginator') paginator: MatPaginator;
  public auditGridSource = new MatTableDataSource();
  loading = true;
  results: any;
  searchBar = '';
  roleId: number;
  @Input() accessRoleId: number;
  public gridData: any;
  displayedColumns: string[] = [
    'ResourceName',
    'OfficeLocation',
    'Action',
    'AssignedBy',
    'AssignedDate',
  ];
  constructor(public restService: RoleConfigService) {}

  ngOnInit() {
    this.restService.onAuditClick.subscribe((roleId) => {
      this.getRoleAssignmentAuditList(this.accessRoleId, -1);
      /* this.auditGridSource.data = [
        {
          ResourceId: 1414882,
          ResourceName: 'Kumar, Rahul - IN010M44769',
          LocationDescription: '9999V BLR RMZ/All Entities/NON-SEZ',
          Action: 'Add',
          AssignedBy: 'MERA-Agent',
          AssignedDate: '2021-03-06T09:48:19.227',
        },
        {
          ResourceId: 1682818,
          ResourceName: 'Bhuvendranathan, Kamalanathan - US015750921',
          LocationDescription: '9999W Mumbai - Hexaware',
          Action: 'Add',
          AssignedBy: null,
          AssignedDate: '2021-01-21T10:21:22.393',
        }        
      ]; */
    });
  }

  ngAfterViewInit() {
    this.auditGridSource.paginator = this.paginator;
  }
  rowCallback(context) {
    const isEven = context.index % 2 === 0;
    return {
      even: isEven,
      odd: !isEven,
    };
  }

  getRoleAssignmentAuditList(roleId: number, resourceId: number) {
    this.loading = true;
    this.roleId = roleId;
    this.restService
      .getRoleAssignmentAuditList(roleId, resourceId)
      .subscribe((data) => {
        this.auditGridSource.data = data;
        this.auditGridSource.data.forEach((element: any) => {
          element.AssignedDate = new Date(element.AssignedDate);
        });
        this.loading = false;
      });
  }

  doFilter(value: string) {
    this.auditGridSource.filter = value.trim().toLocaleLowerCase();
  }

  search(event) {
    if (event.query.length >= 2) {
      let resource = new ResourceDto();
      resource.ResourceName = event.query;
      resource.ResourceId = +sessionStorage.getItem('resourceId');
      resource.LoggedInResourceID = +sessionStorage.getItem(
        'loggedin_resourceid'
      );
      this.restService.searchResourceRole(resource).subscribe((data) => {
        this.results = data;
      });
    }
  }

  OnItemSelect(event) {
    this.loading = true;
    this.getRoleAssignmentAuditList(this.roleId, event.ResourceId);
    this.loading = false;
  }

  resetfilterResourceSearchBar() {
    this.loading = true;
    this.searchBar = '';
    this.getRoleAssignmentAuditList(this.roleId, -1);
    this.loading = false;
  }
}
