import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { BookingGridActionService } from '../../../Models/BookingGridActionService';
import { EngagementBookingVM } from '../../../Models/EngagementBookingVM';
import { EngagementService } from '../engagement.service';
import { DatePipe } from '@angular/common';
import { Response } from '../../../Models/Response';
import { IndividualService } from '../../individual/individual.service';
import { UiViewSourceEnum } from 'app/shared/enum';

// This Component is obsolete. Instead use src\app\Views\individual\delete\delete.component.
@Component({
  selector: 'app-delete-Engagement',
  templateUrl: './deleteEngagement.component.html',
  styleUrls: ['./deleteEngagement.component.css']
})
export class DeleteEngagementComponent implements OnInit {
  @Input() public calledFromChild: boolean;
  bookingId: number;
  resourceId: number;
  booking: EngagementBookingVM;
  eventData: BookingGridActionService;
  deleteBookingSubscription: Subscription;
  Engagement = '' ;
  startDate: Date = null;
  endDate: Date = null;
  closeModalWindow = false;
  response: Response;
  jobId = 0;
  jobCategory:any;
  description:any;
  currentUserName: any;
  currentUserId: any;
  currentUserEmail: any;
  hours: number;
  unconfirmed: boolean;
  loading = '';
  bookingType = '';
  buttonText = "Delete";
  isNewBooking : boolean = false;
  isNewBookingChildRowsExist: boolean = false;
  meraBookingId;
  loggedinUser : any
  ActualBookingId:number=0;
  ParentBookingId:number=0;
  constructor(private engagementServices: EngagementService, public datepipe: DatePipe, private individualService: IndividualService) { }

  ngOnInit() {
    this.loggedinUser = JSON.parse(sessionStorage.getItem('resource'));
    this.currentUserName = this.loggedinUser.ResourceName;
    this.currentUserEmail = this.loggedinUser.Email;
    this.currentUserId = sessionStorage.getItem("resourceId");
    this.booking = new EngagementBookingVM();
    this.response = new Response();
    this.engagementServices.deleteEvent.subscribe((data) => {
      this.eventData = data;
      this.setData(data);
    });
    this.engagementServices.currentMessageCloseModal.subscribe(closeModal => {
      this.closeModalWindow = closeModal;
    });
    this.engagementServices.currentMessageCloseChildModal.subscribe(closeModal => {
      this.closeModalWindow = closeModal;
    });
  }

  setData(rowdata: BookingGridActionService) {
    this.booking = rowdata.dataItem;
    this.bookingId = rowdata.dataItem.BookingId;
    this.resourceId = rowdata.dataItem.ResourceId;
    this.jobId = rowdata.dataItem.JobId;
    this.startDate = rowdata.dataItem.StartDate;
    this.endDate = rowdata.dataItem.EndDate;
    this.Engagement = rowdata.dataItem.Engagement;
    this.jobCategory= rowdata.dataItem.JobCategory;
    this.description= rowdata.dataItem.Description;
    this.loading = rowdata.dataItem.Loading;
    this.hours = rowdata.dataItem.Hours;
    this.bookingType = rowdata.dataItem.BookingType;
    this.meraBookingId = rowdata.dataItem.MeraBookingId;
    //todo: remove comment after get sp contain ActualBookingId
    //this.ActualBookingId = rowdata.dataItem.ActualBookingId;
    //this.ParentBookingId = rowdata.dataItem.ParentBookingId;
    if (this.bookingType.includes('Unconfirmed')) {
      this.unconfirmed = true;
    } else {
      this.unconfirmed = false;
    }
    this.isNewBookingChildRowsExist = rowdata.dataItem.ActionDescriptionId == 1 && rowdata.dataItem.ChildRowsExist;
    this.isNewBooking = (rowdata.dataItem.ActionDescriptionId >  0 &&  rowdata.dataItem.ActionType.length > 0) ? true : false;
  }

  deleteBooking() {
    this.buttonText = "Deleting";
    this.booking.CurrentUserId = this.currentUserId;
    this.booking.CurrentUserName = this.currentUserName;
    this.booking.CurrentUserEmail = this.currentUserEmail;
    this.booking.BookingId = this.bookingId;
    this.booking.ResourceId = this.resourceId;
    this.booking.JobId = this.jobId;
    this.booking.JobCategory = this.jobCategory;
    this.booking.BookingType = this.bookingType;
    this.booking.Description = this.description;
    this.booking.StartDate =this.startDate;// new Date(this.startDate);
    this.booking.EndDate =this.endDate;// new Date(this.endDate);
    this.booking.Loading = +this.loading;
    this.booking.Hours = this.hours;
    this.booking.RequestMode = JSON.parse(sessionStorage.getItem('requestMode'));
    this.booking.UiViewSource = UiViewSourceEnum.EngamentView;
    this.booking.ActualBookingId = this.ActualBookingId;
    this.booking.ParentBookingId = this.ParentBookingId;
    
    if(this.isNewBookingChildRowsExist) {
      this.individualService.CancelMeraBookingsChild(this.eventData.dataItem).subscribe(
        (data: any) => {
          if (data.status = '200') {
            this.response = data.body;
            if (this.calledFromChild) {
              this.individualService.closeChildModal(true);
            } else {
              this.individualService.closeModal(true);
            }
          }
          this.buttonText = "Delete";
        }, err => {
          this.buttonText = "Error";
        }, () => {
          this.buttonText = "Delete";
      });
    }
    else if(this.isNewBooking){
      this.booking.MeraBookingId = this.meraBookingId;
      this.deleteBookingSubscription = this.individualService.CancelBookings(this.booking).subscribe(
        (data: any) => {
          if (data.status = '200') {
            this.response = data.body;
           if (this.response.customResultId > 0) { this.engagementServices.setRquestNumber(this.response.customResultId); }
            
            if (this.calledFromChild) {
              this.engagementServices.closeChildModal(true);
            } else {
              this.engagementServices.closeModal(true);
            }
          }
          this.buttonText = "Delete";
        }, err => {
          this.buttonText = "Error";
        }, () => {
          this.buttonText = "Delete";
        }
      );

    }
    else{
      this.booking.ActionDescriptionId = 4;
      this.booking.MeraBookingStatusId = 5;
      this.deleteBookingSubscription = this.engagementServices.deleteBooking(this.booking).subscribe(
        (data: any) => {
          if (data.status = '200') {
            this.response = data.body;
            if (this.response.customResultId > 0) { this.engagementServices.setRquestNumber(this.response.customResultId); }
            // alert('Successfully saved the changes');
            if (this.calledFromChild) {
              this.engagementServices.closeChildModal(true);
            } else {
              this.engagementServices.closeModal(true);
            }
          }
          this.buttonText = "Delete";
        }, err => {
          this.buttonText = "Error";
        }, () => {
          this.buttonText = "Delete";
        }
      );
    }
    
    
    this.dirtyFlag = false;
  }

  cancelDelete() {
    this.engagementServices.closeModal(false);
    this.engagementServices.closeChildModal(false);
  }
  public dirtyFlag = true;
}
