import { Component, OnInit, Output, Input, EventEmitter, OnDestroy } from '@angular/core';
import { EngagementService } from '../engagement.service';
import { Subscription, Observable } from 'rxjs';
import { EngagementBookingVM } from '../../../Models/EngagementBookingVM';
import { FormGroup, FormControl, Validators, AbstractControl, FormBuilder } from '@angular/forms';
import { BookingGridActionService } from '../../../Models/BookingGridActionService';
import { Response } from '../../../Models/Response';
import { ResourceDto } from '../../../Models/ResourceDto';
import { NgxSpinnerService } from 'ngx-spinner';
import { OrderPipe } from 'ngx-order-pipe';
import * as moment from 'moment'; 
import { UiViewSourceEnum } from 'app/shared/enum';
import { CurrentWeekActionDTO } from '../../../Models/CurrentWeekActionDTO';

@Component({
  selector: 'app-editEngagement',
  templateUrl: './editEngagement.component.html',
  styleUrls: ['./editEngagement.component.css']
})
export class EditEngagementComponent implements OnInit, OnDestroy {
  
  
  @Input() public calledFromChild: boolean;
  @Input() currentWeekActionDTO: CurrentWeekActionDTO;
  bookingType = '';
  engagementCode = '';
  Engagement = '';
  engagementAllocationText = '';
  description = '';
  loading = '';
  startDate: Date = null;
  endDate: Date = null;
  // startWith = new Date();
  // endWith = new Date();
  bsRangeValue: Date[];
  hours: number;
  bookingId: number;
  resourceId: number;
  jobId: number;
  unconfirmed: boolean;
  ghost: boolean;
  engagementAllocationPercentSelected = false;
  closeModalWindow = false;
  //formGroup: FormGroup;
  booking: EngagementBookingVM;
  response: Response;
  eventData: BookingGridActionService;
  editBookingSubscription: Subscription;
  engagementStatus = '';
  engagementServiceLine = '';
  ResourceName: string;
  LocationDescription: string;
  RankDescription: string;
  resourceNameZZ: boolean;
  searchBookingSubscription: Subscription;
  sortedEngagementList: any[];
  resourceList: ResourceDto[];
  sortedResourceList: any[];
  resourceListoptions: any[];
  results: any[];
  selectedResourceItem: any = '';
  resourceRank: string;
  resourceLocation: string;
  validResource = true;
  public view: Observable<any>;
  Rank: string;
  Location: string;
  autocompleteResetvalue = '';
  SLDescription: string;
  currentUserName: any;
  currentUserId: any;
  currentUserEmail: any;
  resource: ResourceDto;
  hoursValidationResultList: boolean[];
  isHoursValid: boolean = true;
  confirmHours: boolean = false;
  isValidationRequired: boolean = false;
  buttonText = "Submit";
  startOfWeek = new Date();
  today: Date;
  user;
  nextSaturday: Date;
  overallocationerror: boolean = true;
  thisDay = new Date();
  todaysDate = new Date(this.thisDay.getFullYear(), this.thisDay.getMonth(), this.thisDay.getDate(), 0, 0, 0);
  formGroup = this.formBuilder.group({
    period1 : this.formBuilder.group({
      start1 : ['', Validators.required],
      end1 : ['', Validators.required]
    }),
    editLoading: ['', [
      Validators.required,
      Validators.pattern(/^(?!(0))[0-9]*(\.[0-9]{0,2})?$/), Validators.max(999)
    ]],
    editSave: [{ disabled: true }]
  });
  instanceId: number;
  departmentDescription:string;
  isPercentageSelected = false;
  ActualBookingId:number=0;
  ParentBookingId:number=0;

  constructor(private engagementServices: EngagementService,
    private spinner: NgxSpinnerService,
    private orderPipe: OrderPipe,private formBuilder: FormBuilder) {
    
  }

  ngOnInit() {
    // this.getCurrentWeek();
    this.enabledWeek(this.currentWeekActionDTO.IsDisabledEditBookingCurrWeek);
    this.user = JSON.parse(sessionStorage.getItem('resource'));
    this.resource = new ResourceDto();
    this.currentUserName = this.user.ResourceName;
    this.currentUserEmail = this.user.Email;
    this.currentUserId = sessionStorage.getItem("resourceId");
    
    this.resourceNameZZ = true;
    this.booking = new EngagementBookingVM();
    this.response = new Response();
    this.engagementServices.editEvent.subscribe((data) => {
      this.eventData = data;
      this.SetData(this.eventData);
    });
    this.engagementServices.currentMessageEngagementAllocation.subscribe(isPercentageSelected => {
      this.isPercentageSelected = isPercentageSelected;
      this.setEngagementAllocation(isPercentageSelected);
    });
    this.sortedEngagementList = [];
    this.results = [];
  }

  SetData(rowdata: BookingGridActionService) {
    this.formGroup.reset();
    this.ResourceName = rowdata.dataItem.ResourceName;
    if (/^ZZ/.test(this.ResourceName)) { this.resourceNameZZ = false; }
    this.RankDescription = rowdata.dataItem.RankDescription;
    this.LocationDescription = rowdata.dataItem.LocationDescription;
    this.SLDescription = rowdata.dataItem.ServiceLineDescription;
    this.departmentDescription=rowdata.dataItem.DepartmentDescription;
    this.bookingId = rowdata.dataItem.BookingId;
    this.resourceId = rowdata.dataItem.ResourceId;
    this.jobId = rowdata.dataItem.JobId;
    this.instanceId = rowdata.dataItem.InstanceId;
    //todo: remove comment after get sp contain ActualBookingId
    //this.ActualBookingId = rowdata.dataItem.ActualBookingId;
    //this.ParentBookingId = rowdata.dataItem.ParentBookingId;
    this.startDate = rowdata.dataItem.StartDate;
    this.endDate = rowdata.dataItem.EndDate;
    this.Engagement = rowdata.dataItem.Engagement;
    this.engagementCode = rowdata.dataItem.EngagementCode;
    this.loading = rowdata.dataItem.Loading;
    this.hours = rowdata.dataItem.Hours;
    // this.startWith = new Date(rowdata.dataItem.StartDate);
    // this.endWith = new Date(rowdata.dataItem.EndDate);
    this.formGroup.get('period1').get('start1').setValue(new Date(rowdata.dataItem.StartDate));
    this.formGroup.get('period1').get('end1').setValue(new Date(rowdata.dataItem.EndDate));
    this.bsRangeValue = [new Date(rowdata.dataItem.StartDate), new Date(rowdata.dataItem.EndDate)];
    this.description = rowdata.dataItem.Description;
    this.bookingType = rowdata.dataItem.BookingType;
    this.enabledWeek(!rowdata.dataItem.IsCurrentEditWeek);
    if (this.bookingType.includes('Unconfirmed')) {
      this.unconfirmed = true;
    } else {
      this.unconfirmed = false;
    }
    this.ghost = rowdata.dataItem.Ghost;
    this.engagementStatus = rowdata.dataItem.JobStatus;
    this.engagementServiceLine = rowdata.dataItem.ServiceLineDescription;
    this.setEngagementAllocation(this.engagementAllocationPercentSelected);
    this.formGroup.controls['editSave'].disable();
    
  }

 setEngagementAllocation(isPercentageSelected) {
    if (!isPercentageSelected) {
      this.engagementAllocationText = 'Hours';
      this.formGroup.get('editLoading').setValue(this.hours);
    } else {
      this.engagementAllocationText = 'Loading %';
      if (this.loading !== '' && this.loading != null) {
        this.formGroup.get('editLoading').setValue(this.loading);
      }
    }
    this.engagementAllocationPercentSelected = isPercentageSelected;
  }

  onEditSubmit() {
    if (!this.formGroup.invalid) {
      this.booking.ResourceId = this.resourceId;
      this.booking.BookingId = this.bookingId;
      this.booking.StartDate = this.formGroup.get('period1').get('start1').value;
      this.booking.EndDate = this.formGroup.get('period1').get('end1').value;
      if (this.engagementAllocationPercentSelected) {
        this.booking.Loading = this.formGroup.get('editLoading').value;
        this.booking.IsPercentSelected = true;
        this.booking.Hours = this.hours;
      } else {
        this.booking.Loading = +this.loading;
        this.booking.Hours = this.formGroup.get('editLoading').value;
      }
      this.booking.InstanceId = this.instanceId;
      this.isHoursValid = true;
      if(this.isValidationRequired)
      {
        this.spinner.show();
        this.editBookingSubscription = this.engagementServices.validateHours(this.booking).subscribe(
          (data: any) => {
            this.hoursValidationResultList = data;
            
            if(this.hoursValidationResultList[0] !== undefined && !this.hoursValidationResultList[0])
            {
              this.isHoursValid=false;
              this.formGroup.get('editLoading').setErrors({'incorrect':true});
              this.overallocationerror = false;
            }
  
            if(this.isHoursValid)
            {
              this.save();
            }
            else if(!this.isHoursValid && this.confirmHours)
            {
               this.save();
            }
            this.spinner.hide();
          }
        );
      }
      else
      {
        this.save();
      }
    }
  }

  public confirmHoursChange(checkboxSelected: string) {
    if(this.formGroup.get(checkboxSelected).value != ''){
      this.formGroup.get(checkboxSelected).setErrors(null)
    }
  } 

  @Output() isFormDirtyFlag : EventEmitter <boolean> = new EventEmitter<boolean>();
  
  public dirtyFlag=false;
  
  public makeDirty(controlName: string) {
    if (!this.dirtyFlag) {
      this.formGroup.controls['editSave'].enable();
      this.dirtyFlag = true;
      this.isFormDirtyFlag.emit(this.dirtyFlag);
    }
    if(controlName=='Period' || controlName=='Hours' || controlName=='Unconfirmed')
    {
      this.isValidationRequired = true;
    }

    
    if(this.overallocationerror == false && controlName == 'Hours')
        {  
      this.overallocationerror = true;  
        }
  }

  public checkDirty() {
    if (!this.dirtyFlag) {
      if (this.formGroup.get('editPeriod').dirty) {
        this.makeDirty('Period');
      }
    }
  }
  isValidInput(fieldName): boolean {
    return this.formGroup.controls[fieldName].invalid &&
      (this.formGroup.controls[fieldName].dirty || this.formGroup.controls[fieldName].touched);
  }
  
  save() {
    this.buttonText = "Submitting";
    this.booking.CurrentUserId = this.currentUserId;
    this.booking.CurrentUserName = this.currentUserName;
    this.booking.CurrentUserEmail = this.currentUserEmail;
    this.booking.ResourceId = this.resourceId;
    this.booking.BookingId = this.bookingId;
    this.booking.JobId = this.jobId;
    this.booking.BookingType = this.bookingType;
    this.booking.Description = this.description;
    this.booking.StartDate = this.formGroup.get('period1').get('start1').value;
    this.booking.EndDate = this.formGroup.get('period1').get('end1').value;
    this.booking.ActionDescriptionId = 2;
    this.booking.MeraBookingStatusId = 5;
    this.booking.Unconfirmed = this.unconfirmed;
    this.booking.IsOverallocation = this.confirmHours;
    if(!this.user?.Ghost){
      this.booking.Ghost = this.ghost;
    }
    this.booking.RequestMode = JSON.parse(sessionStorage.getItem('requestMode'));
    if (this.engagementAllocationPercentSelected) {
      this.booking.Loading = this.formGroup.get('editLoading').value;
      this.booking.Hours = this.hours;
      this.booking.IsPercentSelected = true;
    } else {
      this.booking.Loading = +this.loading;
      this.booking.Hours = this.formGroup.get('editLoading').value;      
    }
    this.booking.UiViewSource = UiViewSourceEnum.EngamentView;
    this.booking.ActualBookingId = this.ActualBookingId;
    this.booking.ParentBookingId = this.ParentBookingId;
    this.editBookingSubscription = this.engagementServices.editBooking(this.booking).subscribe(
      (data: any) => {
        this.spinner.hide();
        if (data.status = '200') {
          this.response = data.body;
          if (this.response.customResultId > 0) { this.engagementServices.setRquestNumber(this.response.customResultId); }
          // alert('Successfully saved the changes');
          if (this.calledFromChild) {
            this.engagementServices.closeChildModal(true);
          } else {
            this.engagementServices.closeModal(true);
          }
        }
        this.buttonText = "Submit";
      }, err => {
        this.buttonText = "Error";
      }, () => {
        this.buttonText = "Submit";
      }
    );
    this.dirtyFlag = false;
  }

  dateRangeValidator(control: AbstractControl): { [key: string]: boolean } | null {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    if (control.value !== undefined && control.value !== null && (control.value[0] < today || control.value[1] < today)) {
      return { 'ageRange': true };
    }
    return null;
  }

  setEngagementListData() {
    this.resourceListoptions = [];
    this.results = this.sortedResourceList;
  }

  OnResourceItemSelect(event) {
    this.selectedResourceItem = event;
    this.resourceRank = this.selectedResourceItem.RankDescription;
    this.resourceLocation = this.selectedResourceItem.LocationDescription;
    this.SLDescription = this.selectedResourceItem.SLDescription;
    this.departmentDescription=this.selectedResourceItem.DepartmentDescription;
    this.RankDescription = this.resourceRank;
    this.LocationDescription = this.resourceLocation;
    this.resourceId = this.selectedResourceItem.ResourceId;
    this.ResourceName = this.selectedResourceItem.ResourceName;
  }

  searchResource(event) {
    if (event.query.length >= 2) {
      this.resource.ResourceName = event.query;
      this.searchBookingSubscription = this.engagementServices.getResourceList(this.resource).subscribe((data) => {
          this.sortedResourceList = this.orderPipe.transform(data, 'ResourceName');
          this.results = this.sortedResourceList;
        this.spinner.hide();
        });
    }
  }

  ngOnDestroy() {
    if (this.editBookingSubscription !== undefined) {
      this.editBookingSubscription.unsubscribe();
    }
  }

  setStartDateTime(inputDate) {
    let newDate = inputDate;
    if (newDate.getHours() === 0) {
      newDate.setHours(newDate.getHours() + 9);
      newDate.setMinutes(newDate.getMinutes() + 30);
    }
    return newDate;
  }

  setEndDateTime(inputDate) {
    let newDate = inputDate;
    if (newDate.getHours() === 0) {
      newDate.setHours(newDate.getHours() + 17);
      newDate.setMinutes(newDate.getMinutes() + 30);
    }
    return newDate;
  }


  
  // EditWeek : number = 0;
  enabledWeek(isCurrentWeekEditable){

    if(this.currentWeekActionDTO.IsDisabledEditBookingCurrWeek === true){
      isCurrentWeekEditable = false;
    }

    this.startOfWeek = this.getFirstDateOfWeek(this.todaysDate);
    if(isCurrentWeekEditable){
      this.startOfWeek.setMinutes(this.startOfWeek.getMinutes() - this.startOfWeek.getTimezoneOffset());
    }
    else{
      this.startOfWeek.setDate(this.startOfWeek.getDate() + 7);
      this.startOfWeek.setMinutes(this.startOfWeek.getMinutes() - this.startOfWeek.getTimezoneOffset());
    }

    // const dayNeeded = 7 //For Saturday
    // const currentDay = moment().isoWeekday();
    // if(this.EditWeek === 1){
    //   isCurrentWeekEditable = false;
    // }
    // if(isCurrentWeekEditable){
    //   if (currentDay === dayNeeded) { 
    //     let day = moment().day(dayNeeded);
    //     this.startOfWeek = new Date(day.year(),day.month(),day.date())
    //   }else{
    //     let day = moment().subtract(1, 'weeks').isoWeekday(dayNeeded);
    //     this.startOfWeek = new Date(day.year(),day.month(),day.date());  
    //   }
    // }else{
    //   if (currentDay === dayNeeded) { 
    //     let day = moment().add(1, 'weeks').isoWeekday(dayNeeded);
    //     this.startOfWeek = new Date(day.year(),day.month(),day.date());
    //   }else{
    //     let day = moment().day(dayNeeded);
    //     this.startOfWeek = new Date(day.year(),day.month(),day.date());
         
    //   }
    // }
    
    
  }
  getFirstDateOfWeek(anyDateOfWeek: Date): Date {
    // date.getDay() RETURNS Week Day Index: SUN 0, MON 1, TUE 2, WED 3, THU 4, FRI 5, SAT 6.
    let date = new Date(anyDateOfWeek);
    let firstDayOfWeekIndex:number = 6; //Week Day Index: SUN 0, MON 1, TUE 2, WED 3, THU 4, FRI 5, SAT 6.
    let daysToShiftBack = (7 + (date.getDay() - firstDayOfWeekIndex)) % 7;
    let firstDateOfWeek = new Date(date.setDate(date.getDate() - daysToShiftBack));
    return firstDateOfWeek;
  }

  // getCurrentWeek() {
  //   this.editBookingSubscription = this.engagementServices.getCurrentWeek().subscribe(
  //     (data: any) => {
  //       this.EditWeek = data.EditWeek;

  //       const currentDate = new Date();

  //       if(this.EditWeek == 1)
  //       {
        
  //         this.enabledWeek(false);
             
  //       }
  //     if(this.EditWeek == 0 || this.EditWeek == null)
  //     {
  //       this.enabledWeek(true);
  //     }
  //     });
  // }

}
