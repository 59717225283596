import {
  Component,
  OnInit,
  ViewEncapsulation,
  Output,
  EventEmitter,
  Input,
  SimpleChanges,
  Directive,
} from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { RoleVM } from '../../../Models/RoleVM';
import { AssignmentConfigurationDto } from '../../../Models/AssignmentConfigurationDto';
import {
  ResourceEditConfigurationDto,
  JobEditConfigurationDto,
} from '../../../Models/EditConfigurationVM';
import {
  ResourceViewConfigurationDto,
  JobViewConfigurationDto,
} from '../../../Models/ViewConfigurationVM';
import { NgxSpinnerService } from 'ngx-spinner';
import { BookingConfigurationDto } from '../../../Models/BookingConfigurationDto';
import { ActionNameEnum, RoleTypeEnum } from '../../../shared/enum';
import {
  JobAddConfigurationDto,
  ResourceAddConfigurationDto,
} from '../../../Models/AddConfigurationVM';
import {
  JobDeleteConfigurationDto,
  ResourceDeleteConfigurationDto,
} from '../../../Models/DeleteConfigurationVM';
import {
  JobReplaceConfigurationDto,
  ResourceReplaceConfigurationDto,
} from '../../../Models/ReplaceConfigurationVM';
import {
  JobReplaceAllConfigurationDto,
  ResourceReplaceAllConfigurationDto,
} from '../../../Models/ReplaceAllConfigurationVM';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { RoleConfigService } from '../role-config.service';
import { SharedServicesService } from 'app/shared/sharedservices/shared-services.service';
import { RoleApprovalConfigurationVM } from 'app/Models/RoleApprovalConfigurationVM';
import { FeatureLevelConfigVM } from 'app/Models/FeatureLevelConfigVM';

@Component({
  selector: 'app-edit-role-configuration',
  templateUrl: './edit-role-configuration.component.html',
  styleUrls: ['./edit-role-configuration.component.scss'],
})
export class EditRoleConfigurationComponent implements OnInit {
  approvalArrDtoForSubmission: RoleApprovalConfigurationVM[] = [];
  enableAddInApprovalLayer: boolean = false;
  //#region Global Declaration
  panelOpenState: boolean;
  formGroup: FormGroup;
  modalOption: ModalOptions = {};
  modalRef: BsModalRef;
  assignmentSubAreaList = [];
  assignmentManageCountryList = [];
  assignmentLegalEntityList = [];
  assignmentBusinessUnitList = [];
  assignmentOperatingUnitList = [];
  assignmentManagementUnitList = [];
  assignmentDepartmentList = [];
  assignmentServiceLineList = [];
  assignmentSubServiceLineList = [];
  assignmentSubManagementUnitList = [];
  assignmentRankList = [];
  // Add Configuration
  resAddSubAreaList = [];
  resAddManageCountryList = [];
  resAddLegalEntityList = [];
  resAddBusinessUnitList = [];
  resAddServiceLineList = [];
  resAddSubServiceLineList = [];
  resAddSubManagementUnitList = [];
  resAddRankList = [];

  jobAddSubAreaList = [];
  jobAddManageCountryList = [];
  jobAddLegalEntityList = [];
  jobAddBusinessUnitList = [];
  jobAddServiceLineList = [];
  jobAddSubServiceLineList = [];
  jobAddSubManagementUnitList = [];
  jobAddRankList = [];
  jobAddJobCategoryList = [];
  jobAddJobStatusList = [];
  jobAddJobStatusBookingList = [];
  jobAddJobCodeList = [];
  jobAddIncludeJobCodeList = [];
  // End
  // Delete Configuration
  resDeleteSubAreaList = [];
  resDeleteManageCountryList = [];
  resDeleteLegalEntityList = [];
  resDeleteBusinessUnitList = [];
  resDeleteServiceLineList = [];
  resDeleteSubServiceLineList = [];
  resDeleteSubManagementUnitList = [];
  resDeleteRankList = [];

  jobDeleteSubAreaList = [];
  jobDeleteManageCountryList = [];
  jobDeleteLegalEntityList = [];
  jobDeleteBusinessUnitList = [];
  jobDeleteServiceLineList = [];
  jobDeleteSubServiceLineList = [];
  jobDeleteSubManagementUnitList = [];
  jobDeleteRankList = [];
  jobDeleteJobCategoryList = [];
  jobDeleteJobStatusList = [];
  jobDeleteJobStatusBookingList = [];
  jobDeleteJobCodeList = [];
  jobDeleteIncludeJobCodeList = [];
  // End

  // Replace Configuration
  resReplaceSubAreaList = [];
  resReplaceManageCountryList = [];
  resReplaceLegalEntityList = [];
  resReplaceBusinessUnitList = [];
  resReplaceServiceLineList = [];
  resReplaceSubServiceLineList = [];
  resReplaceSubManagementUnitList = [];
  resReplaceRankList = [];

  jobReplaceSubAreaList = [];
  jobReplaceManageCountryList = [];
  jobReplaceLegalEntityList = [];
  jobReplaceBusinessUnitList = [];
  jobReplaceServiceLineList = [];
  jobReplaceSubServiceLineList = [];
  jobReplaceSubManagementUnitList = [];
  jobReplaceRankList = [];
  jobReplaceJobCategoryList = [];
  jobReplaceJobStatusList = [];
  jobReplaceJobStatusBookingList = [];
  jobReplaceJobCodeList = [];
  jobReplaceIncludeJobCodeList = [];
  // End

  // Replace All Configuration
  resReplaceAllSubAreaList = [];
  resReplaceAllManageCountryList = [];
  resReplaceAllLegalEntityList = [];
  resReplaceAllBusinessUnitList = [];
  resReplaceAllServiceLineList = [];
  resReplaceAllSubServiceLineList = [];
  resReplaceAllSubManagementUnitList = [];
  resReplaceAllRankList = [];

  jobReplaceAllSubAreaList = [];
  jobReplaceAllManageCountryList = [];
  jobReplaceAllLegalEntityList = [];
  jobReplaceAllBusinessUnitList = [];
  jobReplaceAllServiceLineList = [];
  jobReplaceAllSubServiceLineList = [];
  jobReplaceAllSubManagementUnitList = [];
  jobReplaceAllRankList = [];
  jobReplaceAllJobCategoryList = [];
  jobReplaceAllJobStatusList = [];
  jobReplaceAllJobStatusBookingList = [];
  jobReplaceAllJobCodeList = [];
  jobReplaceAllIncludeJobCodeList = [];
  // End
  resEditSubAreaList = [];
  resEditManageCountryList = [];
  resEditLegalEntityList = [];
  resEditBusinessUnitList = [];
  resEditServiceLineList = [];
  resEditSubServiceLineList = [];
  resEditSubManagementUnitList = [];
  resEditRankList = [];

  jobEditSubAreaList = [];
  jobEditManageCountryList = [];
  jobEditLegalEntityList = [];
  jobEditBusinessUnitList = [];
  jobEditServiceLineList = [];
  jobEditSubServiceLineList = [];
  jobEditSubManagementUnitList = [];
  jobEditRankList = [];
  jobEditJobCategoryList = [];
  jobEditJobStatusList = [];
  jobEditJobStatusBookingList = [];
  jobEditJobCodeList = [];
  jobEditIncludeJobCodeList = [];

  resViewSubAreaList = [];
  resViewManageCountryList = [];
  resViewLegalEntityList = [];
  resViewBusinessUnitList = [];
  resViewServiceLineList = [];
  resViewSubServiceLineList = [];
  resViewSubManagementUnitList = [];
  resViewRankList = [];

  jobViewSubAreaList = [];
  jobViewManageCountryList = [];
  jobViewLegalEntityList = [];
  jobViewBusinessUnitList = [];
  jobViewServiceLineList = [];
  jobViewSubServiceLineList = [];
  jobViewSubManagementUnitList = [];
  jobViewRankList = [];
  jobViewJobCategoryList = [];
  jobViewJobStatusList = [];
  jobViewJobStatusBookingList = [];
  jobViewJobCodeList = [];
  jobViewIncludeJobCodeList = [];
  actionDescriptionList = [];

  assignmentSubAreaSelectedItems = [];
  assignmentManageCountrySelectedItems = [];
  assignmentBusinessUnitSelectedItems = [];
  assignmentLegalEntitySelectedItems = [];
  assignmentServiceLineSelectedItems = [];
  assignmentSubServiceLineSelectedItems = [];
  assignmentSubManagementUnitSelectedItems = [];
  assignmentRankSelectedItems = [];
  assignmentOperatingUnitSelectedItems = [];
  assignmentManagementUnitSelectedItems = [];
  assignmentDepartmentSelectedItems = [];
  // Add Configuration
  resAddSubAreaSelectedItems = [];
  resAddManageCountrySelectedItems = [];
  resAddBusinessUnitSelectedItems = [];
  resAddLegalEntitySelectedItems = [];
  resAddServiceLineSelectedItems = [];
  resAddSubServiceLineSelectedItems = [];
  resAddSubManagementUnitSelectedItems = [];
  resAddRankSelectedItems = [];

  jobAddSubAreaSelectedItems = [];
  jobAddManageCountrySelectedItems = [];
  jobAddBusinessUnitSelectedItems = [];
  jobAddLegalEntitySelectedItems = [];
  jobAddServiceLineSelectedItems = [];
  jobAddSubServiceLineSelectedItems = [];
  jobAddSubManagementUnitSelectedItems = [];
  jobAddRankSelectedItems = [];
  jobAddJobCategorySelectedItems = [];
  jobAddJobStatusSelectedItems = [];
  jobAddJobStatusBookingSelectedItems = [];
  jobAddJobCodeSelectedItems = [];
  jobAddIncludeJobCodeSelectedItems = [];

  // End

  // Delete Configuration
  resDeleteSubAreaSelectedItems = [];
  resDeleteManageCountrySelectedItems = [];
  resDeleteBusinessUnitSelectedItems = [];
  resDeleteLegalEntitySelectedItems = [];
  resDeleteServiceLineSelectedItems = [];
  resDeleteSubServiceLineSelectedItems = [];
  resDeleteSubManagementUnitSelectedItems = [];
  resDeleteRankSelectedItems = [];

  jobDeleteSubAreaSelectedItems = [];
  jobDeleteManageCountrySelectedItems = [];
  jobDeleteBusinessUnitSelectedItems = [];
  jobDeleteLegalEntitySelectedItems = [];
  jobDeleteServiceLineSelectedItems = [];
  jobDeleteSubServiceLineSelectedItems = [];
  jobDeleteSubManagementUnitSelectedItems = [];
  jobDeleteRankSelectedItems = [];
  jobDeleteJobCategorySelectedItems = [];
  jobDeleteJobStatusSelectedItems = [];
  jobDeleteJobStatusBookingSelectedItems = [];
  jobDeleteJobCodeSelectedItems = [];
  jobDeleteIncludeJobCodeSelectedItems = [];

  // End

  // Replace Configuration
  resReplaceSubAreaSelectedItems = [];
  resReplaceManageCountrySelectedItems = [];
  resReplaceBusinessUnitSelectedItems = [];
  resReplaceLegalEntitySelectedItems = [];
  resReplaceServiceLineSelectedItems = [];
  resReplaceSubServiceLineSelectedItems = [];
  resReplaceSubManagementUnitSelectedItems = [];
  resReplaceRankSelectedItems = [];

  jobReplaceSubAreaSelectedItems = [];
  jobReplaceManageCountrySelectedItems = [];
  jobReplaceBusinessUnitSelectedItems = [];
  jobReplaceLegalEntitySelectedItems = [];
  jobReplaceServiceLineSelectedItems = [];
  jobReplaceSubServiceLineSelectedItems = [];
  jobReplaceSubManagementUnitSelectedItems = [];
  jobReplaceRankSelectedItems = [];
  jobReplaceJobCategorySelectedItems = [];
  jobReplaceJobStatusSelectedItems = [];
  jobReplaceJobStatusBookingSelectedItems = [];
  jobReplaceJobCodeSelectedItems = [];
  jobReplaceIncludeJobCodeSelectedItems = [];

  // End

  // Replace Configuration
  resReplaceAllSubAreaSelectedItems = [];
  resReplaceAllManageCountrySelectedItems = [];
  resReplaceAllBusinessUnitSelectedItems = [];
  resReplaceAllLegalEntitySelectedItems = [];
  resReplaceAllServiceLineSelectedItems = [];
  resReplaceAllSubServiceLineSelectedItems = [];
  resReplaceAllSubManagementUnitSelectedItems = [];
  resReplaceAllRankSelectedItems = [];

  jobReplaceAllSubAreaSelectedItems = [];
  jobReplaceAllManageCountrySelectedItems = [];
  jobReplaceAllBusinessUnitSelectedItems = [];
  jobReplaceAllLegalEntitySelectedItems = [];
  jobReplaceAllServiceLineSelectedItems = [];
  jobReplaceAllSubServiceLineSelectedItems = [];
  jobReplaceAllSubManagementUnitSelectedItems = [];
  jobReplaceAllRankSelectedItems = [];
  jobReplaceAllJobCategorySelectedItems = [];
  jobReplaceAllJobStatusSelectedItems = [];
  jobReplaceAllJobStatusBookingSelectedItems = [];
  jobReplaceAllJobCodeSelectedItems = [];
  jobReplaceAllIncludeJobCodeSelectedItems = [];

  // End

  resEditSubAreaSelectedItems = [];
  resEditManageCountrySelectedItems = [];
  resEditBusinessUnitSelectedItems = [];
  resEditLegalEntitySelectedItems = [];
  resEditServiceLineSelectedItems = [];
  resEditSubServiceLineSelectedItems = [];
  resEditSubManagementUnitSelectedItems = [];
  resEditRankSelectedItems = [];

  jobEditSubAreaSelectedItems = [];
  jobEditManageCountrySelectedItems = [];
  jobEditBusinessUnitSelectedItems = [];
  jobEditLegalEntitySelectedItems = [];
  jobEditServiceLineSelectedItems = [];
  jobEditSubServiceLineSelectedItems = [];
  jobEditSubManagementUnitSelectedItems = [];
  jobEditRankSelectedItems = [];
  jobEditJobCategorySelectedItems = [];
  jobEditJobStatusSelectedItems = [];
  jobEditJobStatusBookingSelectedItems = [];
  jobEditJobCodeSelectedItems = [];
  jobEditIncludeJobCodeSelectedItems = [];

  resViewSubAreaSelectedItems = [];
  resViewManageCountrySelectedItems = [];
  resViewBusinessUnitSelectedItems = [];
  resViewLegalEntitySelectedItems = [];
  resViewServiceLineSelectedItems = [];
  resViewSubServiceLineSelectedItems = [];
  resViewSubManagementUnitSelectedItems = [];
  resViewRankSelectedItems = [];

  jobViewSubAreaSelectedItems = [];
  jobViewManageCountrySelectedItems = [];
  jobViewBusinessUnitSelectedItems = [];
  jobViewLegalEntitySelectedItems = [];
  jobViewServiceLineSelectedItems = [];
  jobViewSubServiceLineSelectedItems = [];
  jobViewSubManagementUnitSelectedItems = [];
  jobViewRankSelectedItems = [];
  jobViewJobCategorySelectedItems = [];
  jobViewJobStatusSelectedItems = [];
  jobViewJobStatusBookingSelectedItems = [];
  jobViewJobCodeSelectedItems = [];
  jobViewIncludeJobCodeSelectedItems = [];
  priorities = [];
  roleTypes = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings = {};
  role: RoleVM;
  accessRoleName = '';
  selectedRoleType :any;
  selectedPriority: number;
  selectedDelegate: boolean = true;
  remark = '';
  selectedRoleView: boolean = true;
  selectedRoleIsActive: boolean = true;
  assignmentLink: boolean = false;
  assignmentIsActive: boolean = true;
  RoleTypeName:string;
  @Input() selectedRoleTypeName :any;
  // Add configuration
  resAddLink: boolean = false;
  resAddIsActive: boolean = false;
  resAddIsDisabled: boolean = false;
  resAddIndividualView: boolean = false;
  resAddEngagementView: boolean = false;
  resAddIsLikeEdit: boolean = false;
  jobAddLink: boolean = false;
  jobAddIsTR: boolean = true;
  jobAddIsDisabled: boolean = false;

  jobAddIsPended: boolean = false;
  jobAddIndividualView: boolean = false;
  jobAddEngagementView: boolean = false;
  jobAddIsLikeEdit: boolean = false;
  // End
  // Delete configuration
  resDeleteLink: boolean = false;
  resDeleteIsActive: boolean = false;
  resDeleteIsDisabled: boolean = false;
  resDeleteIndividualView: boolean = false;
  resDeleteEngagementView: boolean = false;
  resDeleteIsLikeEdit: boolean = false;
  jobDeleteLink: boolean = false;
  jobDeleteIsTR: boolean = true;
  jobDeleteIsDisabled: boolean = false;

  jobDeleteIsPended: boolean = false;
  jobDeleteIndividualView: boolean = false;
  jobDeleteEngagementView: boolean = false;
  jobDeleteIsLikeEdit: boolean = false;
  // End

  // Replace configuration
  resReplaceLink: boolean = false;
  resReplaceIsActive: boolean = false;
  resReplaceIsDisabled: boolean = false;
  resReplaceIndividualView: boolean = false;
  resReplaceEngagementView: boolean = false;
  resReplaceIsLikeEdit: boolean = false;
  jobReplaceLink: boolean = false;
  jobReplaceIsTR: boolean = true;
  jobReplaceIsDisabled: boolean = false;

  jobReplaceIsPended: boolean = false;
  jobReplaceIndividualView: boolean = false;
  jobReplaceEngagementView: boolean = false;
  jobReplaceIsLikeEdit: boolean = false;
  // End

  // Replace All configuration
  resReplaceAllLink: boolean = false;
  resReplaceAllIsActive: boolean = false;
  resReplaceAllIsDisabled: boolean = false;
  resReplaceAllIndividualView: boolean = false;
  resReplaceAllEngagementView: boolean = false;
  resReplaceAllIsLikeEdit: boolean = false;
  jobReplaceAllLink: boolean = false;
  jobReplaceAllIsTR: boolean = true;
  jobReplaceAllIsDisabled: boolean = false;

  jobReplaceAllIsPended: boolean = false;
  jobReplaceAllIndividualView: boolean = false;
  jobReplaceAllEngagementView: boolean = false;
  jobReplaceAllIsLikeEdit: boolean = false;
  // End
  resEditLink: boolean = false;
  resEditIsActive: boolean = true;
  resEditIsDisabled: boolean = true;
  resEditIndividualView: boolean = false;
  resEditEngagementView: boolean = false;
  jobEditLink: boolean = false;
  jobEditIsTR: boolean = false;
  jobEditIsDisabled: boolean = false;

  jobEditIsPended: boolean = false;
  jobEditIndividualView: boolean = false;
  jobEditEngagementView: boolean = false;

  resViewLink: boolean = false;
  resViewIsActive: boolean = false;
  jobViewLink: boolean = false;
  jobViewIsTR: boolean = false;
  jobViewIsPended: boolean = false;

  bookingCurrentWeekEdit: boolean = false;
  bookingCurrentWeekDelete: boolean = false;
  bookingCurrentWeekAdd: boolean = false;
  bookingCurrentWeekCopy: boolean = false;
  bookingCurrentWeekReplace: boolean = false;
  bookingCurrentWeekReplaceAll: boolean = false;
  bookigTrainingEngagementDelete: boolean = true;
  bookigTrainingEngagementEdit: boolean = true;
  bookigDisabledDelete: boolean = false;
  bookigDisabledEdit: boolean = false;
  bookigDisabledCopy: boolean = false;
  bookigDisabledReplace: boolean = false;
  bookigDisabledReplaceAll: boolean = false;
  bookingDisabledGhostView: boolean = true;
  bookingDisabledGhostEdit: boolean = true;
  bookingDisabledGhostDelete: boolean = true;
  bookingDisabledGhostCopy: boolean = true;
  bookingDisabledGhostReplace: boolean = true;
  buttonText = 'Submit';
  isExistsAccessRole: boolean = false;
  isHomeRetainNotSelected:boolean = false;
  errorMessage: string = '';
  expanded: boolean = false;
  @Input() RoleId: number = 0;
  @Input() actionSelected: string = ActionNameEnum.View;
  actionName = '';
  selectedCoworkers: boolean = false;
  disableActionsGhostBooking: boolean = false;
  actionNameEnum = ActionNameEnum;
  @Input() SaveErrorMessage: string = '';
  dpDisabled: boolean = true;
  roleTypeEnum = RoleTypeEnum;
  prtDisabled: boolean = false;
  dirtyFlag: boolean = false;
  confirmationClickAction: string = 'cancel';
  customResultId: number;
  //public expandMode: number = PanelBarExpandMode.Full;
  overallocation: number;
  unconfirmed: boolean;
  isApproverReq: boolean;
  retainInstance: any;
  isAllretainInstReq: boolean;
  instances: any;
  selectedInstance: number;
  roleIndividualView: boolean = true;
  roleEngagementView: boolean = true;
  roleTeamView: boolean = true;
  previewSubmit:boolean;
  InstanceEnableAddBookingIV:boolean=true;
  selectedHomeBasedInstance:any=null;

  // Feature related variables
  featureField: {}; //ngModel for features level config
  featuresList = [];
  featureLevelDtos: FeatureLevelConfigVM[];
  fetchedFeatureLevelDtos = [];
  featureDataList:any;
  featuresfilterList:any;


  @Input() SaveSuccessMessage: string = '';
  //#endregion

  @Output() onMessageClear = new EventEmitter<any>();

  //#region Cntr
  constructor(
    public restService: RoleConfigService,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService,
    private sharedService: SharedServicesService
  ) {
    this.priorities = new Array(99).fill(0).map((x, i) => i + 1);
  }
  //#endregion

  //#region Parent Change

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.RoleId != null &&
        changes.RoleId.currentValue != changes.RoleId.previousValue) ||
      (changes.actionSelected != null &&
        changes.actionSelected.currentValue !=
          changes.actionSelected.previousValue)
    ) {
      this.expanded = false;
      if(changes?.selectedRoleTypeName!=null && changes.selectedRoleTypeName.currentValue != null)
      {
        if (changes.selectedRoleTypeName.currentValue == RoleTypeEnum.ClientServer )
            this.selectedRoleType = 1;
        else if (changes.selectedRoleTypeName.currentValue == RoleTypeEnum.ResourceAdmin)   
            this.selectedRoleType = 2;
      }
      this.spinner.show();
      this.clearMainControlls();
      this.bindData().then(() => {
        this.sleep(3500).then(() => {
          this.spinner.hide();
          this.expanded = true;
          setTimeout(() => {
            this.onMessageClear.emit();
          }, 3500);
        });
      });
    }
    // You can also use categoryId.previousValue and
    // categoryId.firstChange for comparing old and new values
    if (this.actionNameEnum.Edit === changes?.actionSelected?.currentValue)
      this.enableAddInApprovalLayer = true;
  }
  //#endregion Parent Change

  //#region  Init
  ngOnInit() {
    // this.spinner.show();
    this.setFormControl();
    this.masterData();
    //#endregion
    //#region DropDown Setting
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'Id',
      textField: 'Text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      enableCheckAll: false,
    };
    // this.spinner.hide();
  }

  //#endregion

  //#region User Defined Method
  setFormControl() {
    this.formGroup = new FormGroup({
      RoleType: new FormControl('', [Validators.required]),
      Priority: new FormControl(''),
      AccessRoleName: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[^\s][ A-Za-z0-9-,_]*$/),
        Validators.maxLength(25),
      ]),
      instancesSelected: new FormControl([]),
    });
  }

  onChangeFeatureFieldModel(): void {
    this.makeDirty();
    const excludedFeatures: any = Object.keys(this.featureField).filter(
      (item) => this.featureField[item] === true
    );

    this.featureLevelDtos = excludedFeatures.map((item) => {
      return {
        IsExclude: true,
        RFC_F_ID: this.featuresList.find((it) => it.Text === item).Id,
      };
    });
  }

  clearControls() {
    this.clearMainControlls();
    this.SaveErrorMessage = '';
    this.SaveSuccessMessage = '';
    this.buttonText = 'Submit';
    window.scroll(0, 0);
  }

  clearMainControlls() {
    this.assignmentSubAreaSelectedItems = [];
    this.assignmentManageCountrySelectedItems = [];
    this.assignmentBusinessUnitSelectedItems = [];
    this.assignmentLegalEntitySelectedItems = [];
    this.assignmentServiceLineSelectedItems = [];
    this.assignmentSubServiceLineSelectedItems = [];
    this.assignmentSubManagementUnitSelectedItems = [];
    this.assignmentRankSelectedItems = [];
    this.assignmentOperatingUnitSelectedItems = [];
    this.assignmentManagementUnitSelectedItems = [];
    this.assignmentDepartmentSelectedItems = [];
    this.resAddSubAreaSelectedItems = [];
    this.resAddManageCountrySelectedItems = [];
    this.resAddBusinessUnitSelectedItems = [];
    this.resAddLegalEntitySelectedItems = [];
    this.resAddServiceLineSelectedItems = [];
    this.resAddSubServiceLineSelectedItems = [];
    this.resAddSubManagementUnitSelectedItems = [];
    this.resAddRankSelectedItems = [];

    this.jobAddSubAreaSelectedItems = [];
    this.jobAddManageCountrySelectedItems = [];
    this.jobAddBusinessUnitSelectedItems = [];
    this.jobAddLegalEntitySelectedItems = [];
    this.jobAddServiceLineSelectedItems = [];
    this.jobAddSubServiceLineSelectedItems = [];
    this.jobAddSubManagementUnitSelectedItems = [];
    this.jobAddRankSelectedItems = [];
    this.jobAddJobCategorySelectedItems = [];
    this.jobAddJobStatusSelectedItems = [];
    this.jobAddJobStatusBookingSelectedItems = [];
    this.jobAddJobCodeSelectedItems = [];
    this.jobAddIncludeJobCodeSelectedItems = [];

    // Delete Configuration
    this.resDeleteSubAreaSelectedItems = [];
    this.resDeleteManageCountrySelectedItems = [];
    this.resDeleteBusinessUnitSelectedItems = [];
    this.resDeleteLegalEntitySelectedItems = [];
    this.resDeleteServiceLineSelectedItems = [];
    this.resDeleteSubServiceLineSelectedItems = [];
    this.resDeleteSubManagementUnitSelectedItems = [];
    this.resDeleteRankSelectedItems = [];

    this.jobDeleteSubAreaSelectedItems = [];
    this.jobDeleteManageCountrySelectedItems = [];
    this.jobDeleteBusinessUnitSelectedItems = [];
    this.jobDeleteLegalEntitySelectedItems = [];
    this.jobDeleteServiceLineSelectedItems = [];
    this.jobDeleteSubServiceLineSelectedItems = [];
    this.jobDeleteSubManagementUnitSelectedItems = [];
    this.jobDeleteRankSelectedItems = [];
    this.jobDeleteJobCategorySelectedItems = [];
    this.jobDeleteJobStatusSelectedItems = [];
    this.jobDeleteJobStatusBookingSelectedItems = [];
    this.jobDeleteJobCodeSelectedItems = [];
    this.jobDeleteIncludeJobCodeSelectedItems = [];

    // End

    // Replace Configuration
    this.resReplaceSubAreaSelectedItems = [];
    this.resReplaceManageCountrySelectedItems = [];
    this.resReplaceBusinessUnitSelectedItems = [];
    this.resReplaceLegalEntitySelectedItems = [];
    this.resReplaceServiceLineSelectedItems = [];
    this.resReplaceSubServiceLineSelectedItems = [];
    this.resReplaceSubManagementUnitSelectedItems = [];
    this.resReplaceRankSelectedItems = [];

    this.jobReplaceSubAreaSelectedItems = [];
    this.jobReplaceManageCountrySelectedItems = [];
    this.jobReplaceBusinessUnitSelectedItems = [];
    this.jobReplaceLegalEntitySelectedItems = [];
    this.jobReplaceServiceLineSelectedItems = [];
    this.jobReplaceSubServiceLineSelectedItems = [];
    this.jobReplaceSubManagementUnitSelectedItems = [];
    this.jobReplaceRankSelectedItems = [];
    this.jobReplaceJobCategorySelectedItems = [];
    this.jobReplaceJobStatusSelectedItems = [];
    this.jobReplaceJobStatusBookingSelectedItems = [];
    this.jobReplaceJobCodeSelectedItems = [];
    this.jobReplaceIncludeJobCodeSelectedItems = [];

    // End

    // Replace Configuration
    this.resReplaceAllSubAreaSelectedItems = [];
    this.resReplaceAllManageCountrySelectedItems = [];
    this.resReplaceAllBusinessUnitSelectedItems = [];
    this.resReplaceAllLegalEntitySelectedItems = [];
    this.resReplaceAllServiceLineSelectedItems = [];
    this.resReplaceAllSubServiceLineSelectedItems = [];
    this.resReplaceAllSubManagementUnitSelectedItems = [];
    this.resReplaceAllRankSelectedItems = [];

    this.jobReplaceAllSubAreaSelectedItems = [];
    this.jobReplaceAllManageCountrySelectedItems = [];
    this.jobReplaceAllBusinessUnitSelectedItems = [];
    this.jobReplaceAllLegalEntitySelectedItems = [];
    this.jobReplaceAllServiceLineSelectedItems = [];
    this.jobReplaceAllSubServiceLineSelectedItems = [];
    this.jobReplaceAllSubManagementUnitSelectedItems = [];
    this.jobReplaceAllRankSelectedItems = [];
    this.jobReplaceAllJobCategorySelectedItems = [];
    this.jobReplaceAllJobStatusSelectedItems = [];
    this.jobReplaceAllJobStatusBookingSelectedItems = [];
    this.jobReplaceAllJobCodeSelectedItems = [];
    this.jobReplaceAllIncludeJobCodeSelectedItems = [];

    // End
    this.resEditSubAreaSelectedItems = [];
    this.resEditManageCountrySelectedItems = [];
    this.resEditBusinessUnitSelectedItems = [];
    this.resEditLegalEntitySelectedItems = [];
    this.resEditServiceLineSelectedItems = [];
    this.resEditSubServiceLineSelectedItems = [];
    this.resEditSubManagementUnitSelectedItems = [];
    this.resEditRankSelectedItems = [];

    this.jobEditSubAreaSelectedItems = [];
    this.jobEditManageCountrySelectedItems = [];
    this.jobEditBusinessUnitSelectedItems = [];
    this.jobEditLegalEntitySelectedItems = [];
    this.jobEditServiceLineSelectedItems = [];
    this.jobEditSubServiceLineSelectedItems = [];
    this.jobEditSubManagementUnitSelectedItems = [];
    this.jobEditRankSelectedItems = [];
    this.jobEditJobCategorySelectedItems = [];
    this.jobEditJobStatusSelectedItems = [];
    this.jobEditJobStatusBookingSelectedItems = [];
    this.jobEditJobCodeSelectedItems = [];
    this.jobEditIncludeJobCodeSelectedItems = [];

    this.resViewSubAreaSelectedItems = [];
    this.resViewManageCountrySelectedItems = [];
    this.resViewBusinessUnitSelectedItems = [];
    this.resViewLegalEntitySelectedItems = [];
    this.resViewServiceLineSelectedItems = [];
    this.resViewSubServiceLineSelectedItems = [];
    this.resViewSubManagementUnitSelectedItems = [];
    this.resViewRankSelectedItems = [];

    this.jobViewSubAreaSelectedItems = [];
    this.jobViewManageCountrySelectedItems = [];
    this.jobViewBusinessUnitSelectedItems = [];
    this.jobViewLegalEntitySelectedItems = [];
    this.jobViewServiceLineSelectedItems = [];
    this.jobViewSubServiceLineSelectedItems = [];
    this.jobViewSubManagementUnitSelectedItems = [];
    this.jobViewRankSelectedItems = [];
    this.jobViewJobCategorySelectedItems = [];
    this.jobViewJobStatusSelectedItems = [];
    this.jobViewJobStatusBookingSelectedItems = [];
    this.jobViewJobCodeSelectedItems = [];
    this.jobViewIncludeJobCodeSelectedItems = [];
    this.selectedRoleView = true;
    this.selectedRoleIsActive = true;
    this.assignmentLink = false;
    this.assignmentIsActive = true;

    this.resAddLink = false;
    this.resAddIsActive = false;
    this.resAddIsDisabled = false;
    this.resAddIndividualView = false;
    this.resAddEngagementView = false;
    this.resAddIsLikeEdit = false;
    this.jobAddLink = false;
    this.jobAddIsTR = true;
    this.jobAddIsDisabled = false;
    this.jobAddIsPended = false;
    this.jobAddIndividualView = false;
    this.jobAddEngagementView = false;
    this.jobAddIsLikeEdit = false;

    this.resDeleteLink = false;
    this.resDeleteIsActive = false;
    this.resDeleteIsDisabled = false;
    this.resDeleteIndividualView = false;
    this.resDeleteEngagementView = false;
    this.resDeleteIsLikeEdit = false;
    this.jobDeleteLink = false;
    this.jobDeleteIsTR = true;
    this.jobDeleteIsDisabled = false;
    this.jobDeleteIsPended = false;
    this.jobDeleteIndividualView = false;
    this.jobDeleteEngagementView = false;
    this.jobDeleteIsLikeEdit = false;

    this.resReplaceLink = false;
    this.resReplaceIsActive = false;
    this.resReplaceIsDisabled = false;
    this.resReplaceIndividualView = false;
    this.resReplaceEngagementView = false;
    this.resReplaceIsLikeEdit = false;
    this.jobReplaceLink = false;
    this.jobReplaceIsTR = true;
    this.jobReplaceIsDisabled = false;
    this.jobReplaceIsPended = false;
    this.jobReplaceIndividualView = false;
    this.jobReplaceEngagementView = false;
    this.jobReplaceIsLikeEdit = false;

    this.resReplaceAllLink = false;
    this.resReplaceAllIsActive = false;
    this.resReplaceAllIsDisabled = false;
    this.resReplaceAllIsDisabled = false;
    this.resReplaceAllIndividualView = false;
    this.resReplaceAllEngagementView = false;
    this.jobReplaceAllLink = false;
    this.jobReplaceAllIsTR = true;
    this.jobReplaceAllIsDisabled = false;
    this.jobReplaceAllIsPended = false;
    this.jobReplaceAllIndividualView = false;
    this.jobReplaceAllEngagementView = false;
    this.jobReplaceAllIsLikeEdit = false;

    this.resEditLink = false;
    this.resEditIsActive = false;
    this.resEditIsDisabled = false;
    this.resEditIndividualView = false;
    this.resEditEngagementView = false;
    this.jobEditLink = false;
    this.jobEditIsTR = true;
    this.jobEditIsDisabled = false;
    this.jobEditIsPended = false;
    this.jobEditIndividualView = false;
    this.jobEditEngagementView = false;
    this.resViewLink = false;
    this.resViewIsActive = false;
    this.jobViewLink = false;
    this.jobViewIsTR = false;
    this.jobViewIsPended = false;

    this.bookingCurrentWeekEdit = false;
    this.bookingCurrentWeekDelete = false;
    this.bookingCurrentWeekAdd = false;
    this.bookingCurrentWeekCopy = false;
    this.bookingCurrentWeekReplace = false;
    this.bookingCurrentWeekReplaceAll = false;
    this.bookigTrainingEngagementDelete = true;
    this.bookigTrainingEngagementEdit = true;
    this.bookigDisabledDelete = false;
    this.bookigDisabledEdit = false;
    this.bookigDisabledCopy = false;
    this.bookigDisabledReplace = false;
    this.bookigDisabledReplaceAll = false;
    this.resetGhostBookingActionCheckBoxes();
    this.selectedDelegate = true;
    this.isExistsAccessRole = false;
    this.errorMessage = '';
    this.selectedCoworkers = false;
    this.remark = '';
    this.selectedPriority = 0;
    this.SaveErrorMessage = '';
    this.onChangeRoleType();
    this.confirmationClickAction = 'cancel';
    this.customResultId = null;
    this.dirtyFlag = false;
    this.prtDisabled = false;
  }

  populateRoleApprovalDTO(data) {
    this.makeDirty();
    this.approvalArrDtoForSubmission = data;
  }

  bindData() {
    return new Promise<void>((resolve) => {
      this.restService.getRolConfiguration(this.RoleId).subscribe((data) => {
        //this.RoleId = !this.isCopy ? data.R_ID : 0;
        if (
          this.actionSelected == ActionNameEnum.Edit ||
          this.actionSelected == ActionNameEnum.View || 
          this.actionSelected == ActionNameEnum.Copy
        ) {
          this.selectedRoleType = data.R_RT_ID;
          this.RoleTypeName = data.RT_Name;
          this.accessRoleName = data.R_Name;
          if (data.RT_Name == RoleTypeEnum.ResourceAdmin)
            this.prtDisabled = true;
          else this.prtDisabled = false;
        } else {
          this.selectedRoleType = '';
          this.accessRoleName = '';
          this.prtDisabled = false;
        }

        if (this.actionSelected != ActionNameEnum.View) {
          this.actionName = this.actionSelected;
        }
        this.selectedPriority = data.R_Priority;
        this.remark = data.R_Comments;
        this.selectedRoleView = data.R_View;
        this.selectedDelegate = data.R_Delegate;
        this.resAddLink = data.R_RADLinkConfig;
        this.resEditLink = data.R_RELinkConfig;
        this.resDeleteLink = data.R_RDLinkConfig;
        this.resReplaceLink = data.R_RRLinkConfig;
        this.resReplaceAllLink = data.R_RRALinkConfig;
        this.resViewLink = data.R_RVLinkConfig;
        this.jobAddLink = data.R_JADLinkConfig;
        this.jobEditLink = data.R_JELinkConfig;
        this.jobDeleteLink = data.R_JDLinkConfig;
        this.jobReplaceLink = data.R_JRLinkConfig;
        this.jobReplaceAllLink = data.R_JRALinkConfig;
        this.jobViewLink = data.R_JVLinkConfig;
        this.assignmentLink = data.R_RALinkConfig;
        this.selectedRoleIsActive = data.R_IsActive;
        this.selectedCoworkers = data.R_CoWorker;
        this.disableActionsGhostBooking = data.R_Ghost;
        this.selectedInstance = data.R_I_ID == 0 ? 1 : data.R_I_ID;
        this.getUserInstances();
        this.overallocation = data.R_OverAllocation;
        this.isAllretainInstReq = data.R_MUltipleInstance;
        this.unconfirmed = data.R_Unconfirmed;
        this.isApproverReq = data.R_IsReqdApproval;
        this.roleIndividualView = data.R_InstEnable_IV;
        this.roleEngagementView = data.R_InstEnable_EV;
        this.roleTeamView = data.R_InstEnable_TV;
        this.previewSubmit=data.R_PreviewSubmit;
        this.InstanceEnableAddBookingIV = data.R_InstEnable_AddBooking;
        this.selectedHomeBasedInstance = data.R_Home_InstanceID;

        if (data.RoleApprovalConfigurationDtos != null) {
          this.approvalArrDtoForSubmission = [
            ...data.RoleApprovalConfigurationDtos,
          ];
        }

        if (data.AssignmentConfigurationDto != null)
          this.assignmentIsActive = data.AssignmentConfigurationDto.RAR_ISACTIVE;
        if (data.AssignmentConfigurationDto.assignmentSubAreas != null) {
          data.AssignmentConfigurationDto.assignmentSubAreas.forEach(
            (element) => {
              this.assignmentSubAreaSelectedItems =
                this.assignmentSubAreaSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (
          data.AssignmentConfigurationDto.assignmentManagerialCountries != null
        ) {
          data.AssignmentConfigurationDto.assignmentManagerialCountries.forEach(
            (element) => {
              this.assignmentManageCountrySelectedItems =
                this.assignmentManageCountrySelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.AssignmentConfigurationDto.assignmentBusinessUnits != null) {
          data.AssignmentConfigurationDto.assignmentBusinessUnits.forEach(
            (element) => {
              this.assignmentBusinessUnitSelectedItems =
                this.assignmentBusinessUnitSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }

        if (data.AssignmentConfigurationDto.assignmentOperatingUnits != null) {
          data.AssignmentConfigurationDto.assignmentOperatingUnits.forEach(
            (element) => {
              this.assignmentOperatingUnitSelectedItems =
                this.assignmentOperatingUnitSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.AssignmentConfigurationDto.assignmentManageUnits != null) {
          data.AssignmentConfigurationDto.assignmentManageUnits.forEach(
            (element) => {
              this.assignmentManagementUnitSelectedItems =
                this.assignmentManagementUnitSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.AssignmentConfigurationDto.assignmentDepartment != null) {
          data.AssignmentConfigurationDto.assignmentDepartment.forEach(
            (element) => {
              this.assignmentDepartmentSelectedItems =
                this.assignmentDepartmentSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }

        if (data.AssignmentConfigurationDto.assignmentLegalEntities != null) {
          data.AssignmentConfigurationDto.assignmentLegalEntities.forEach(
            (element) => {
              this.assignmentLegalEntitySelectedItems =
                this.assignmentLegalEntitySelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.AssignmentConfigurationDto.assignmentServiceLines != null) {
          data.AssignmentConfigurationDto.assignmentServiceLines.forEach(
            (element) => {
              this.assignmentServiceLineSelectedItems =
                this.assignmentServiceLineSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.AssignmentConfigurationDto.assignmentSubServiceLines != null) {
          data.AssignmentConfigurationDto.assignmentSubServiceLines.forEach(
            (element) => {
              this.assignmentSubServiceLineSelectedItems =
                this.assignmentSubServiceLineSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.AssignmentConfigurationDto.assignmentSubManageUnits != null) {
          data.AssignmentConfigurationDto.assignmentSubManageUnits.forEach(
            (element) => {
              this.assignmentSubManagementUnitSelectedItems =
                this.assignmentSubManagementUnitSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.AssignmentConfigurationDto.assignmentRanks != null) {
          data.AssignmentConfigurationDto.assignmentRanks.forEach((element) => {
            this.assignmentRankSelectedItems =
              this.assignmentRankSelectedItems.concat({
                Id: element.ID,
                Text: element.Text,
              });
          });
        }

        // Add Config
        if (data.ResourceAddConfigurationDto.ResAddSubAreas != null) {
          data.ResourceAddConfigurationDto.ResAddSubAreas.forEach((element) => {
            this.resAddSubAreaSelectedItems =
              this.resAddSubAreaSelectedItems.concat({
                Id: element.ID,
                Text: element.Text,
              });
          });
        }
        if (
          data.ResourceAddConfigurationDto.ResAddManagerialCountries != null
        ) {
          data.ResourceAddConfigurationDto.ResAddManagerialCountries.forEach(
            (element) => {
              this.resAddManageCountrySelectedItems =
                this.resAddManageCountrySelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.ResourceAddConfigurationDto.ResAddBusinessUnits != null) {
          data.ResourceAddConfigurationDto.ResAddBusinessUnits.forEach(
            (element) => {
              this.resAddBusinessUnitSelectedItems =
                this.resAddBusinessUnitSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.ResourceAddConfigurationDto.ResAddLegalEntities != null) {
          data.ResourceAddConfigurationDto.ResAddLegalEntities.forEach(
            (element) => {
              this.resAddLegalEntitySelectedItems =
                this.resAddLegalEntitySelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.ResourceAddConfigurationDto.ResAddServiceLines != null) {
          data.ResourceAddConfigurationDto.ResAddServiceLines.forEach(
            (element) => {
              this.resAddServiceLineSelectedItems =
                this.resAddServiceLineSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.ResourceAddConfigurationDto.ResAddSubServiceLines != null) {
          data.ResourceAddConfigurationDto.ResAddSubServiceLines.forEach(
            (element) => {
              this.resAddSubServiceLineSelectedItems =
                this.resAddSubServiceLineSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.ResourceAddConfigurationDto.ResAddSubManageUnits != null) {
          data.ResourceAddConfigurationDto.ResAddSubManageUnits.forEach(
            (element) => {
              this.resAddSubManagementUnitSelectedItems =
                this.resAddSubManagementUnitSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobAddConfigurationDto.JobAddSubAreas != null) {
          data.JobAddConfigurationDto.JobAddSubAreas.forEach((element) => {
            this.jobAddSubAreaSelectedItems =
              this.jobAddSubAreaSelectedItems.concat({
                Id: element.ID,
                Text: element.Text,
              });
          });
        }
        if (data.JobAddConfigurationDto.JobAddManagerialCountries != null) {
          data.JobAddConfigurationDto.JobAddManagerialCountries.forEach(
            (element) => {
              this.jobAddManageCountrySelectedItems =
                this.jobAddManageCountrySelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobAddConfigurationDto.JobAddLegalEntities != null) {
          data.JobAddConfigurationDto.JobAddLegalEntities.forEach((element) => {
            this.jobAddLegalEntitySelectedItems =
              this.jobAddLegalEntitySelectedItems.concat({
                Id: element.ID,
                Text: element.Text,
              });
          });
        }
        if (data.JobAddConfigurationDto.JobAddBusinessUnits != null) {
          data.JobAddConfigurationDto.JobAddBusinessUnits.forEach((element) => {
            this.jobAddBusinessUnitSelectedItems =
              this.jobAddBusinessUnitSelectedItems.concat({
                Id: element.ID,
                Text: element.Text,
              });
          });
        }
        if (data.JobAddConfigurationDto.JobAddServiceLines != null) {
          data.JobAddConfigurationDto.JobAddServiceLines.forEach((element) => {
            this.jobAddServiceLineSelectedItems =
              this.jobAddServiceLineSelectedItems.concat({
                Id: element.ID,
                Text: element.Text,
              });
          });
        }
        if (data.JobAddConfigurationDto.JobAddSubServiceLines != null) {
          data.JobAddConfigurationDto.JobAddSubServiceLines.forEach(
            (element) => {
              this.jobAddSubServiceLineSelectedItems =
                this.jobAddSubServiceLineSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobAddConfigurationDto.JobAddSubManageUnits != null) {
          data.JobAddConfigurationDto.JobAddSubManageUnits.forEach(
            (element) => {
              this.jobAddSubManagementUnitSelectedItems =
                this.jobAddSubManagementUnitSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobAddConfigurationDto.JobAddJobCategories != null) {
          data.JobAddConfigurationDto.JobAddJobCategories.forEach((element) => {
            this.jobAddJobCategorySelectedItems =
              this.jobAddJobCategorySelectedItems.concat({
                Id: element.ID,
                Text: element.Text,
              });
          });
        }
        if (data.JobAddConfigurationDto.JobAddJobStatuses != null) {
          data.JobAddConfigurationDto.JobAddJobStatuses.forEach((element) => {
            this.jobAddJobStatusSelectedItems =
              this.jobAddJobStatusSelectedItems.concat({
                Id: element.ID,
                Text: element.Text,
              });
          });
        }
        if (data.JobAddConfigurationDto.JobAddJobStatusBookings != null) {
          data.JobAddConfigurationDto.JobAddJobStatusBookings.forEach(
            (element) => {
              this.jobAddJobStatusBookingSelectedItems =
                this.jobAddJobStatusBookingSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobAddConfigurationDto.JobAddJobCode != null) {
          data.JobAddConfigurationDto.JobAddJobCode.forEach((element) => {
            this.jobAddJobCodeSelectedItems =
              this.jobAddJobCodeSelectedItems.concat({
                Id: element.ID,
                Text: element.Text,
              });
          });
        }
        if (data.JobAddConfigurationDto.JobAddIncludeJobCode != null) {
          data.JobAddConfigurationDto.JobAddIncludeJobCode.forEach(
            (element) => {
              this.jobAddIncludeJobCodeSelectedItems =
                this.jobAddIncludeJobCodeSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        //END

        // Delete Config
        if (data.ResourceDeleteConfigurationDto.ResDeleteSubAreas != null) {
          data.ResourceDeleteConfigurationDto.ResDeleteSubAreas.forEach(
            (element) => {
              this.resDeleteSubAreaSelectedItems =
                this.resDeleteSubAreaSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (
          data.ResourceDeleteConfigurationDto.ResDeleteManagerialCountries !=
          null
        ) {
          data.ResourceDeleteConfigurationDto.ResDeleteManagerialCountries.forEach(
            (element) => {
              this.resDeleteManageCountrySelectedItems =
                this.resDeleteManageCountrySelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (
          data.ResourceDeleteConfigurationDto.ResDeleteBusinessUnits != null
        ) {
          data.ResourceDeleteConfigurationDto.ResDeleteBusinessUnits.forEach(
            (element) => {
              this.resDeleteBusinessUnitSelectedItems =
                this.resDeleteBusinessUnitSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (
          data.ResourceDeleteConfigurationDto.ResDeleteLegalEntities != null
        ) {
          data.ResourceDeleteConfigurationDto.ResDeleteLegalEntities.forEach(
            (element) => {
              this.resDeleteLegalEntitySelectedItems =
                this.resDeleteLegalEntitySelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.ResourceDeleteConfigurationDto.ResDeleteServiceLines != null) {
          data.ResourceDeleteConfigurationDto.ResDeleteServiceLines.forEach(
            (element) => {
              this.resDeleteServiceLineSelectedItems =
                this.resDeleteServiceLineSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (
          data.ResourceDeleteConfigurationDto.ResDeleteSubServiceLines != null
        ) {
          data.ResourceDeleteConfigurationDto.ResDeleteSubServiceLines.forEach(
            (element) => {
              this.resDeleteSubServiceLineSelectedItems =
                this.resDeleteSubServiceLineSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (
          data.ResourceDeleteConfigurationDto.ResDeleteSubManageUnits != null
        ) {
          data.ResourceDeleteConfigurationDto.ResDeleteSubManageUnits.forEach(
            (element) => {
              this.resDeleteSubManagementUnitSelectedItems =
                this.resDeleteSubManagementUnitSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobDeleteConfigurationDto.JobDeleteSubAreas != null) {
          data.JobDeleteConfigurationDto.JobDeleteSubAreas.forEach(
            (element) => {
              this.jobDeleteSubAreaSelectedItems =
                this.jobDeleteSubAreaSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (
          data.JobDeleteConfigurationDto.JobDeleteManagerialCountries != null
        ) {
          data.JobDeleteConfigurationDto.JobDeleteManagerialCountries.forEach(
            (element) => {
              this.jobDeleteManageCountrySelectedItems =
                this.jobDeleteManageCountrySelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobDeleteConfigurationDto.JobDeleteLegalEntities != null) {
          data.JobDeleteConfigurationDto.JobDeleteLegalEntities.forEach(
            (element) => {
              this.jobDeleteLegalEntitySelectedItems =
                this.jobDeleteLegalEntitySelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobDeleteConfigurationDto.JobDeleteBusinessUnits != null) {
          data.JobDeleteConfigurationDto.JobDeleteBusinessUnits.forEach(
            (element) => {
              this.jobDeleteBusinessUnitSelectedItems =
                this.jobDeleteBusinessUnitSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobDeleteConfigurationDto.JobDeleteServiceLines != null) {
          data.JobDeleteConfigurationDto.JobDeleteServiceLines.forEach(
            (element) => {
              this.jobDeleteServiceLineSelectedItems =
                this.jobDeleteServiceLineSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobDeleteConfigurationDto.JobDeleteSubServiceLines != null) {
          data.JobDeleteConfigurationDto.JobDeleteSubServiceLines.forEach(
            (element) => {
              this.jobDeleteSubServiceLineSelectedItems =
                this.jobDeleteSubServiceLineSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobDeleteConfigurationDto.JobDeleteSubManageUnits != null) {
          data.JobDeleteConfigurationDto.JobDeleteSubManageUnits.forEach(
            (element) => {
              this.jobDeleteSubManagementUnitSelectedItems =
                this.jobDeleteSubManagementUnitSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobDeleteConfigurationDto.JobDeleteJobCategories != null) {
          data.JobDeleteConfigurationDto.JobDeleteJobCategories.forEach(
            (element) => {
              this.jobDeleteJobCategorySelectedItems =
                this.jobDeleteJobCategorySelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobDeleteConfigurationDto.JobDeleteJobStatuses != null) {
          data.JobDeleteConfigurationDto.JobDeleteJobStatuses.forEach(
            (element) => {
              this.jobDeleteJobStatusSelectedItems =
                this.jobDeleteJobStatusSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobDeleteConfigurationDto.JobDeleteJobStatusBookings != null) {
          data.JobDeleteConfigurationDto.JobDeleteJobStatusBookings.forEach(
            (element) => {
              this.jobDeleteJobStatusBookingSelectedItems =
                this.jobDeleteJobStatusBookingSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobDeleteConfigurationDto.JobDeleteJobCode != null) {
          data.JobDeleteConfigurationDto.JobDeleteJobCode.forEach((element) => {
            this.jobDeleteJobCodeSelectedItems =
              this.jobDeleteJobCodeSelectedItems.concat({
                Id: element.ID,
                Text: element.Text,
              });
          });
        }
        if (data.JobDeleteConfigurationDto.JobDeleteIncludeJobCode != null) {
          data.JobDeleteConfigurationDto.JobDeleteIncludeJobCode.forEach(
            (element) => {
              this.jobDeleteIncludeJobCodeSelectedItems =
                this.jobDeleteIncludeJobCodeSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        //END

        // Replace Config
        if (data.ResourceReplaceConfigurationDto.ResReplaceSubAreas != null) {
          data.ResourceReplaceConfigurationDto.ResReplaceSubAreas.forEach(
            (element) => {
              this.resReplaceSubAreaSelectedItems =
                this.resReplaceSubAreaSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (
          data.ResourceReplaceConfigurationDto.ResReplaceManagerialCountries !=
          null
        ) {
          data.ResourceReplaceConfigurationDto.ResReplaceManagerialCountries.forEach(
            (element) => {
              this.resReplaceManageCountrySelectedItems =
                this.resReplaceManageCountrySelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (
          data.ResourceReplaceConfigurationDto.ResReplaceBusinessUnits != null
        ) {
          data.ResourceReplaceConfigurationDto.ResReplaceBusinessUnits.forEach(
            (element) => {
              this.resReplaceBusinessUnitSelectedItems =
                this.resReplaceBusinessUnitSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (
          data.ResourceReplaceConfigurationDto.ResReplaceLegalEntities != null
        ) {
          data.ResourceReplaceConfigurationDto.ResReplaceLegalEntities.forEach(
            (element) => {
              this.resReplaceLegalEntitySelectedItems =
                this.resReplaceLegalEntitySelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (
          data.ResourceReplaceConfigurationDto.ResReplaceServiceLines != null
        ) {
          data.ResourceReplaceConfigurationDto.ResReplaceServiceLines.forEach(
            (element) => {
              this.resReplaceServiceLineSelectedItems =
                this.resReplaceServiceLineSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (
          data.ResourceReplaceConfigurationDto.ResReplaceSubServiceLines != null
        ) {
          data.ResourceReplaceConfigurationDto.ResReplaceSubServiceLines.forEach(
            (element) => {
              this.resReplaceSubServiceLineSelectedItems =
                this.resReplaceSubServiceLineSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (
          data.ResourceReplaceConfigurationDto.ResReplaceSubManageUnits != null
        ) {
          data.ResourceReplaceConfigurationDto.ResReplaceSubManageUnits.forEach(
            (element) => {
              this.resReplaceSubManagementUnitSelectedItems =
                this.resReplaceSubManagementUnitSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobReplaceConfigurationDto.JobReplaceSubAreas != null) {
          data.JobReplaceConfigurationDto.JobReplaceSubAreas.forEach(
            (element) => {
              this.jobReplaceSubAreaSelectedItems =
                this.jobReplaceSubAreaSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (
          data.JobReplaceConfigurationDto.JobReplaceManagerialCountries != null
        ) {
          data.JobReplaceConfigurationDto.JobReplaceManagerialCountries.forEach(
            (element) => {
              this.jobReplaceManageCountrySelectedItems =
                this.jobReplaceManageCountrySelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobReplaceConfigurationDto.JobReplaceLegalEntities != null) {
          data.JobReplaceConfigurationDto.JobReplaceLegalEntities.forEach(
            (element) => {
              this.jobReplaceLegalEntitySelectedItems =
                this.jobReplaceLegalEntitySelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobReplaceConfigurationDto.JobReplaceBusinessUnits != null) {
          data.JobReplaceConfigurationDto.JobReplaceBusinessUnits.forEach(
            (element) => {
              this.jobReplaceBusinessUnitSelectedItems =
                this.jobReplaceBusinessUnitSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobReplaceConfigurationDto.JobReplaceServiceLines != null) {
          data.JobReplaceConfigurationDto.JobReplaceServiceLines.forEach(
            (element) => {
              this.jobReplaceServiceLineSelectedItems =
                this.jobReplaceServiceLineSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobReplaceConfigurationDto.JobReplaceSubServiceLines != null) {
          data.JobReplaceConfigurationDto.JobReplaceSubServiceLines.forEach(
            (element) => {
              this.jobReplaceSubServiceLineSelectedItems =
                this.jobReplaceSubServiceLineSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobReplaceConfigurationDto.JobReplaceSubManageUnits != null) {
          data.JobReplaceConfigurationDto.JobReplaceSubManageUnits.forEach(
            (element) => {
              this.jobReplaceSubManagementUnitSelectedItems =
                this.jobReplaceSubManagementUnitSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobReplaceConfigurationDto.JobReplaceJobCategories != null) {
          data.JobReplaceConfigurationDto.JobReplaceJobCategories.forEach(
            (element) => {
              this.jobReplaceJobCategorySelectedItems =
                this.jobReplaceJobCategorySelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobReplaceConfigurationDto.JobReplaceJobStatuses != null) {
          data.JobReplaceConfigurationDto.JobReplaceJobStatuses.forEach(
            (element) => {
              this.jobReplaceJobStatusSelectedItems =
                this.jobReplaceJobStatusSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (
          data.JobReplaceConfigurationDto.JobReplaceJobStatusBookings != null
        ) {
          data.JobReplaceConfigurationDto.JobReplaceJobStatusBookings.forEach(
            (element) => {
              this.jobReplaceJobStatusBookingSelectedItems =
                this.jobReplaceJobStatusBookingSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobReplaceConfigurationDto.JobReplaceJobCode != null) {
          data.JobReplaceConfigurationDto.JobReplaceJobCode.forEach(
            (element) => {
              this.jobReplaceJobCodeSelectedItems =
                this.jobReplaceJobCodeSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobReplaceConfigurationDto.JobReplaceIncludeJobCode != null) {
          data.JobReplaceConfigurationDto.JobReplaceIncludeJobCode.forEach(
            (element) => {
              this.jobReplaceIncludeJobCodeSelectedItems =
                this.jobReplaceIncludeJobCodeSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        //END
        // Replace All Config
        if (
          data.ResourceReplaceAllConfigurationDto.ResReplaceAllSubAreas != null
        ) {
          data.ResourceReplaceAllConfigurationDto.ResReplaceAllSubAreas.forEach(
            (element) => {
              this.resReplaceAllSubAreaSelectedItems =
                this.resReplaceAllSubAreaSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (
          data.ResourceReplaceAllConfigurationDto
            .ResReplaceAllManagerialCountries != null
        ) {
          data.ResourceReplaceAllConfigurationDto.ResReplaceAllManagerialCountries.forEach(
            (element) => {
              this.resReplaceAllManageCountrySelectedItems =
                this.resReplaceAllManageCountrySelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (
          data.ResourceReplaceAllConfigurationDto.ResReplaceAllBusinessUnits !=
          null
        ) {
          data.ResourceReplaceAllConfigurationDto.ResReplaceAllBusinessUnits.forEach(
            (element) => {
              this.resReplaceAllBusinessUnitSelectedItems =
                this.resReplaceAllBusinessUnitSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (
          data.ResourceReplaceAllConfigurationDto.ResReplaceAllLegalEntities !=
          null
        ) {
          data.ResourceReplaceAllConfigurationDto.ResReplaceAllLegalEntities.forEach(
            (element) => {
              this.resReplaceAllLegalEntitySelectedItems =
                this.resReplaceAllLegalEntitySelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (
          data.ResourceReplaceAllConfigurationDto.ResReplaceAllServiceLines !=
          null
        ) {
          data.ResourceReplaceAllConfigurationDto.ResReplaceAllServiceLines.forEach(
            (element) => {
              this.resReplaceAllServiceLineSelectedItems =
                this.resReplaceAllServiceLineSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (
          data.ResourceReplaceAllConfigurationDto
            .ResReplaceAllSubServiceLines != null
        ) {
          data.ResourceReplaceAllConfigurationDto.ResReplaceAllSubServiceLines.forEach(
            (element) => {
              this.resReplaceAllSubServiceLineSelectedItems =
                this.resReplaceAllSubServiceLineSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (
          data.ResourceReplaceAllConfigurationDto.ResReplaceAllSubManageUnits !=
          null
        ) {
          data.ResourceReplaceAllConfigurationDto.ResReplaceAllSubManageUnits.forEach(
            (element) => {
              this.resReplaceAllSubManagementUnitSelectedItems =
                this.resReplaceAllSubManagementUnitSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobReplaceAllConfigurationDto.JobReplaceAllSubAreas != null) {
          data.JobReplaceAllConfigurationDto.JobReplaceAllSubAreas.forEach(
            (element) => {
              this.jobReplaceAllSubAreaSelectedItems =
                this.jobReplaceAllSubAreaSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (
          data.JobReplaceAllConfigurationDto.JobReplaceAllManagerialCountries !=
          null
        ) {
          data.JobReplaceAllConfigurationDto.JobReplaceAllManagerialCountries.forEach(
            (element) => {
              this.jobReplaceAllManageCountrySelectedItems =
                this.jobReplaceAllManageCountrySelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (
          data.JobReplaceAllConfigurationDto.JobReplaceAllLegalEntities != null
        ) {
          data.JobReplaceAllConfigurationDto.JobReplaceAllLegalEntities.forEach(
            (element) => {
              this.jobReplaceAllLegalEntitySelectedItems =
                this.jobReplaceAllLegalEntitySelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (
          data.JobReplaceAllConfigurationDto.JobReplaceAllBusinessUnits != null
        ) {
          data.JobReplaceAllConfigurationDto.JobReplaceAllBusinessUnits.forEach(
            (element) => {
              this.jobReplaceAllBusinessUnitSelectedItems =
                this.jobReplaceAllBusinessUnitSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (
          data.JobReplaceAllConfigurationDto.JobReplaceAllServiceLines != null
        ) {
          data.JobReplaceAllConfigurationDto.JobReplaceAllServiceLines.forEach(
            (element) => {
              this.jobReplaceAllServiceLineSelectedItems =
                this.jobReplaceAllServiceLineSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (
          data.JobReplaceAllConfigurationDto.JobReplaceAllSubServiceLines !=
          null
        ) {
          data.JobReplaceAllConfigurationDto.JobReplaceAllSubServiceLines.forEach(
            (element) => {
              this.jobReplaceAllSubServiceLineSelectedItems =
                this.jobReplaceAllSubServiceLineSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (
          data.JobReplaceAllConfigurationDto.JobReplaceAllSubManageUnits != null
        ) {
          data.JobReplaceAllConfigurationDto.JobReplaceAllSubManageUnits.forEach(
            (element) => {
              this.jobReplaceAllSubManagementUnitSelectedItems =
                this.jobReplaceAllSubManagementUnitSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (
          data.JobReplaceAllConfigurationDto.JobReplaceAllJobCategories != null
        ) {
          data.JobReplaceAllConfigurationDto.JobReplaceAllJobCategories.forEach(
            (element) => {
              this.jobReplaceAllJobCategorySelectedItems =
                this.jobReplaceAllJobCategorySelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (
          data.JobReplaceAllConfigurationDto.JobReplaceAllJobStatuses != null
        ) {
          data.JobReplaceAllConfigurationDto.JobReplaceAllJobStatuses.forEach(
            (element) => {
              this.jobReplaceAllJobStatusSelectedItems =
                this.jobReplaceAllJobStatusSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (
          data.JobReplaceAllConfigurationDto.JobReplaceAllJobStatusBookings !=
          null
        ) {
          data.JobReplaceAllConfigurationDto.JobReplaceAllJobStatusBookings.forEach(
            (element) => {
              this.jobReplaceAllJobStatusBookingSelectedItems =
                this.jobReplaceAllJobStatusBookingSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobReplaceAllConfigurationDto.JobReplaceAllJobCode != null) {
          data.JobReplaceAllConfigurationDto.JobReplaceAllJobCode.forEach(
            (element) => {
              this.jobReplaceAllJobCodeSelectedItems =
                this.jobReplaceAllJobCodeSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (
          data.JobReplaceAllConfigurationDto.JobReplaceAllIncludeJobCode != null
        ) {
          data.JobReplaceAllConfigurationDto.JobReplaceAllIncludeJobCode.forEach(
            (element) => {
              this.jobReplaceAllIncludeJobCodeSelectedItems =
                this.jobReplaceAllIncludeJobCodeSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        //END
        if (data.ResourceEditConfigurationDto.ResEditSubAreas != null) {
          data.ResourceEditConfigurationDto.ResEditSubAreas.forEach(
            (element) => {
              this.resEditSubAreaSelectedItems =
                this.resEditSubAreaSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (
          data.ResourceEditConfigurationDto.ResEditManagerialCountries != null
        ) {
          data.ResourceEditConfigurationDto.ResEditManagerialCountries.forEach(
            (element) => {
              this.resEditManageCountrySelectedItems =
                this.resEditManageCountrySelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.ResourceEditConfigurationDto.ResEditBusinessUnits != null) {
          data.ResourceEditConfigurationDto.ResEditBusinessUnits.forEach(
            (element) => {
              this.resEditBusinessUnitSelectedItems =
                this.resEditBusinessUnitSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.ResourceEditConfigurationDto.ResEditLegalEntities != null) {
          data.ResourceEditConfigurationDto.ResEditLegalEntities.forEach(
            (element) => {
              this.resEditLegalEntitySelectedItems =
                this.resEditLegalEntitySelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.ResourceEditConfigurationDto.ResEditServiceLines != null) {
          data.ResourceEditConfigurationDto.ResEditServiceLines.forEach(
            (element) => {
              this.resEditServiceLineSelectedItems =
                this.resEditServiceLineSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.ResourceEditConfigurationDto.ResEditSubServiceLines != null) {
          data.ResourceEditConfigurationDto.ResEditSubServiceLines.forEach(
            (element) => {
              this.resEditSubServiceLineSelectedItems =
                this.resEditSubServiceLineSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.ResourceEditConfigurationDto.ResEditSubManageUnits != null) {
          data.ResourceEditConfigurationDto.ResEditSubManageUnits.forEach(
            (element) => {
              this.resEditSubManagementUnitSelectedItems =
                this.resEditSubManagementUnitSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobEditConfigurationDto.JobEditSubAreas != null) {
          data.JobEditConfigurationDto.JobEditSubAreas.forEach((element) => {
            this.jobEditSubAreaSelectedItems =
              this.jobEditSubAreaSelectedItems.concat({
                Id: element.ID,
                Text: element.Text,
              });
          });
        }
        if (data.JobEditConfigurationDto.JobEditManagerialCountries != null) {
          data.JobEditConfigurationDto.JobEditManagerialCountries.forEach(
            (element) => {
              this.jobEditManageCountrySelectedItems =
                this.jobEditManageCountrySelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobEditConfigurationDto.JobEditLegalEntities != null) {
          data.JobEditConfigurationDto.JobEditLegalEntities.forEach(
            (element) => {
              this.jobEditLegalEntitySelectedItems =
                this.jobEditLegalEntitySelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobEditConfigurationDto.JobEditBusinessUnits != null) {
          data.JobEditConfigurationDto.JobEditBusinessUnits.forEach(
            (element) => {
              this.jobEditBusinessUnitSelectedItems =
                this.jobEditBusinessUnitSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobEditConfigurationDto.JobEditServiceLines != null) {
          data.JobEditConfigurationDto.JobEditServiceLines.forEach(
            (element) => {
              this.jobEditServiceLineSelectedItems =
                this.jobEditServiceLineSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobEditConfigurationDto.JobEditSubServiceLines != null) {
          data.JobEditConfigurationDto.JobEditSubServiceLines.forEach(
            (element) => {
              this.jobEditSubServiceLineSelectedItems =
                this.jobEditSubServiceLineSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobEditConfigurationDto.JobEditSubManageUnits != null) {
          data.JobEditConfigurationDto.JobEditSubManageUnits.forEach(
            (element) => {
              this.jobEditSubManagementUnitSelectedItems =
                this.jobEditSubManagementUnitSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobEditConfigurationDto.JobEditJobCategories != null) {
          data.JobEditConfigurationDto.JobEditJobCategories.forEach(
            (element) => {
              this.jobEditJobCategorySelectedItems =
                this.jobEditJobCategorySelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobEditConfigurationDto.JobEditJobStatuses != null) {
          data.JobEditConfigurationDto.JobEditJobStatuses.forEach((element) => {
            this.jobEditJobStatusSelectedItems =
              this.jobEditJobStatusSelectedItems.concat({
                Id: element.ID,
                Text: element.Text,
              });
          });
        }
        if (data.JobEditConfigurationDto.JobEditJobStatusBookings != null) {
          data.JobEditConfigurationDto.JobEditJobStatusBookings.forEach(
            (element) => {
              this.jobEditJobStatusBookingSelectedItems =
                this.jobEditJobStatusBookingSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobEditConfigurationDto.JobEditJobCode != null) {
          data.JobEditConfigurationDto.JobEditJobCode.forEach((element) => {
            this.jobEditJobCodeSelectedItems =
              this.jobEditJobCodeSelectedItems.concat({
                Id: element.ID,
                Text: element.Text,
              });
          });
        }
        if (data.JobEditConfigurationDto.JobEditIncludeJobCode != null) {
          data.JobEditConfigurationDto.JobEditIncludeJobCode.forEach(
            (element) => {
              this.jobEditIncludeJobCodeSelectedItems =
                this.jobEditIncludeJobCodeSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.ResourceViewConfigurationDto.ResViewSubAreas != null) {
          data.ResourceViewConfigurationDto.ResViewSubAreas.forEach(
            (element) => {
              this.resViewSubAreaSelectedItems =
                this.resViewSubAreaSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (
          data.ResourceViewConfigurationDto.ResViewManagerialCountries != null
        ) {
          data.ResourceViewConfigurationDto.ResViewManagerialCountries.forEach(
            (element) => {
              this.resViewManageCountrySelectedItems =
                this.resViewManageCountrySelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.ResourceViewConfigurationDto.ResViewBusinessUnits != null) {
          data.ResourceViewConfigurationDto.ResViewBusinessUnits.forEach(
            (element) => {
              this.resViewBusinessUnitSelectedItems =
                this.resViewBusinessUnitSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.ResourceViewConfigurationDto.ResViewLegalEntities != null) {
          data.ResourceViewConfigurationDto.ResViewLegalEntities.forEach(
            (element) => {
              this.resViewLegalEntitySelectedItems =
                this.resViewLegalEntitySelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.ResourceViewConfigurationDto.ResViewServiceLines != null) {
          data.ResourceViewConfigurationDto.ResViewServiceLines.forEach(
            (element) => {
              this.resViewServiceLineSelectedItems =
                this.resViewServiceLineSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.ResourceViewConfigurationDto.ResViewSubServiceLines != null) {
          data.ResourceViewConfigurationDto.ResViewSubServiceLines.forEach(
            (element) => {
              this.resViewSubServiceLineSelectedItems =
                this.resViewSubServiceLineSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.ResourceViewConfigurationDto.ResViewSubManageUnits != null) {
          data.ResourceViewConfigurationDto.ResViewSubManageUnits.forEach(
            (element) => {
              this.resViewSubManagementUnitSelectedItems =
                this.resViewSubManagementUnitSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobViewConfigurationDto.JobViewSubAreas != null) {
          data.JobViewConfigurationDto.JobViewSubAreas.forEach((element) => {
            this.jobViewSubAreaSelectedItems =
              this.jobViewSubAreaSelectedItems.concat({
                Id: element.ID,
                Text: element.Text,
              });
          });
        }
        if (data.JobViewConfigurationDto.JobViewManagerialCountries != null) {
          data.JobViewConfigurationDto.JobViewManagerialCountries.forEach(
            (element) => {
              this.jobViewManageCountrySelectedItems =
                this.jobViewManageCountrySelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobViewConfigurationDto.JobViewLegalEntities != null) {
          data.JobViewConfigurationDto.JobViewLegalEntities.forEach(
            (element) => {
              this.jobViewLegalEntitySelectedItems =
                this.jobViewLegalEntitySelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobViewConfigurationDto.JobViewBusinessUnits != null) {
          data.JobViewConfigurationDto.JobViewBusinessUnits.forEach(
            (element) => {
              this.jobViewBusinessUnitSelectedItems =
                this.jobViewBusinessUnitSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobViewConfigurationDto.JobViewServiceLines != null) {
          data.JobViewConfigurationDto.JobViewServiceLines.forEach(
            (element) => {
              this.jobViewServiceLineSelectedItems =
                this.jobViewServiceLineSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobViewConfigurationDto.JobViewSubServiceLines != null) {
          data.JobViewConfigurationDto.JobViewSubServiceLines.forEach(
            (element) => {
              this.jobViewSubServiceLineSelectedItems =
                this.jobViewSubServiceLineSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobViewConfigurationDto.JobViewSubManageUnits != null) {
          data.JobViewConfigurationDto.JobViewSubManageUnits.forEach(
            (element) => {
              this.jobViewSubManagementUnitSelectedItems =
                this.jobViewSubManagementUnitSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobViewConfigurationDto.JobViewJobCategories != null) {
          data.JobViewConfigurationDto.JobViewJobCategories.forEach(
            (element) => {
              this.jobViewJobCategorySelectedItems =
                this.jobViewJobCategorySelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobViewConfigurationDto.JobViewJobStatuses != null) {
          data.JobViewConfigurationDto.JobViewJobStatuses.forEach((element) => {
            this.jobViewJobStatusSelectedItems =
              this.jobViewJobStatusSelectedItems.concat({
                Id: element.ID,
                Text: element.Text,
              });
          });
        }
        if (data.JobViewConfigurationDto.JobViewJobStatusBookings != null) {
          data.JobViewConfigurationDto.JobViewJobStatusBookings.forEach(
            (element) => {
              this.jobViewJobStatusBookingSelectedItems =
                this.jobViewJobStatusBookingSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.JobViewConfigurationDto.JobViewJobCode != null) {
          data.JobViewConfigurationDto.JobViewJobCode.forEach((element) => {
            this.jobViewJobCodeSelectedItems =
              this.jobViewJobCodeSelectedItems.concat({
                Id: element.ID,
                Text: element.Text,
              });
          });
        }
        if (data.JobViewConfigurationDto.JobViewIncludeJobCode != null) {
          data.JobViewConfigurationDto.JobViewIncludeJobCode.forEach(
            (element) => {
              this.jobViewIncludeJobCodeSelectedItems =
                this.jobViewIncludeJobCodeSelectedItems.concat({
                  Id: element.ID,
                  Text: element.Text,
                });
            }
          );
        }
        if (data.ResourceEditConfigurationDto != null) {
          this.resEditIsActive =
            data.ResourceEditConfigurationDto.RREC_RInactive;
          this.resEditIsDisabled =
            data.ResourceEditConfigurationDto.RREC_ISDisabled;
          this.resEditIndividualView =
            data.ResourceEditConfigurationDto.RREC_IV_Disabled;
          this.resEditEngagementView =
            data.ResourceEditConfigurationDto.RREC_EV_Disabled;
        }
        if (data.ResourceAddConfigurationDto != null) {
          this.resAddIsActive = data.ResourceAddConfigurationDto.RRAC_RInactive;
          this.resAddIsDisabled =
            data.ResourceAddConfigurationDto.RRAC_ISDisabled;
          this.resAddIndividualView =
            data.ResourceAddConfigurationDto.RRAC_IV_Disabled;
          this.resAddEngagementView =
            data.ResourceAddConfigurationDto.RRAC_EV_Disabled;
          this.resAddIsLikeEdit =
            data.ResourceAddConfigurationDto.RRAC_ISLikeEdit;
        }
        if (data.ResourceDeleteConfigurationDto != null) {
          this.resDeleteIsActive =
            data.ResourceDeleteConfigurationDto.RRDC_RInactive;
          this.resDeleteIsDisabled =
            data.ResourceDeleteConfigurationDto.RRDC_ISDisabled;
          this.resDeleteIndividualView =
            data.ResourceDeleteConfigurationDto.RRDC_IV_Disabled;
          this.resDeleteEngagementView =
            data.ResourceDeleteConfigurationDto.RRDC_EV_Disabled;
          this.resDeleteIsLikeEdit =
            data.ResourceDeleteConfigurationDto.RRDC_ISLikeEdit;
        }
        if (data.ResourceReplaceConfigurationDto != null) {
          this.resReplaceIsActive =
            data.ResourceReplaceConfigurationDto.RRRC_RInactive;
          this.resReplaceIsDisabled =
            data.ResourceReplaceConfigurationDto.RRRC_ISDisabled;
          this.resReplaceIndividualView =
            data.ResourceReplaceConfigurationDto.RRRC_IV_Disabled;
          this.resReplaceEngagementView =
            data.ResourceReplaceConfigurationDto.RRRC_EV_Disabled;
          this.resReplaceIsLikeEdit =
            data.ResourceReplaceConfigurationDto.RRRC_ISLikeEdit;
        }
        if (data.ResourceReplaceAllConfigurationDto != null) {
          this.resReplaceAllIsActive =
            data.ResourceReplaceAllConfigurationDto.RRRAC_RInactive;
          this.resReplaceAllIsDisabled =
            data.ResourceReplaceAllConfigurationDto.RRRAC_ISDisabled;
          this.resReplaceAllIndividualView =
            data.ResourceReplaceAllConfigurationDto.RRRAC_IV_Disabled;
          this.resReplaceAllEngagementView =
            data.ResourceReplaceAllConfigurationDto.RRRAC_EV_Disabled;
          this.resReplaceAllIsLikeEdit =
            data.ResourceReplaceAllConfigurationDto.RRRAC_ISLikeEdit;
        }
        if (data.JobEditConfigurationDto != null) {
          this.jobEditIsTR = data.JobEditConfigurationDto.RJEC_Learning;
          this.jobEditIsDisabled = data.JobEditConfigurationDto.RJEC_ISDisabled;
          this.jobEditIsPended = data.JobEditConfigurationDto.RJEC_IsPended;
          this.jobEditIndividualView =
            data.JobEditConfigurationDto.RJEC_IV_Disabled;
          this.jobEditEngagementView =
            data.JobEditConfigurationDto.RJEC_EV_Disabled;
        }
        if (data.JobAddConfigurationDto != null) {
          this.jobAddIsTR = data.JobAddConfigurationDto.RJAC_Learning;
          this.jobAddIsDisabled = data.JobAddConfigurationDto.RJAC_ISDisabled;
          this.jobAddIsPended = data.JobAddConfigurationDto.RJAC_IsPended;
          this.jobAddIsLikeEdit = data.JobAddConfigurationDto.RJAC_ISLikeEdit;
          this.jobAddIndividualView =
            data.JobAddConfigurationDto.RJAC_IV_Disabled;
          this.jobAddEngagementView =
            data.JobAddConfigurationDto.RJAC_EV_Disabled;
        }
        if (data.JobDeleteConfigurationDto != null) {
          this.jobDeleteIsTR = data.JobDeleteConfigurationDto.RJDC_Learning;
          this.jobDeleteIsDisabled =
            data.JobDeleteConfigurationDto.RJDC_ISDisabled;
          this.jobDeleteIsPended = data.JobDeleteConfigurationDto.RJDC_IsPended;
          this.jobDeleteIsLikeEdit =
            data.JobDeleteConfigurationDto.RJDC_ISLikeEdit;
          this.jobDeleteIndividualView =
            data.JobDeleteConfigurationDto.RJDC_IV_Disabled;
          this.jobDeleteEngagementView =
            data.JobDeleteConfigurationDto.RJDC_EV_Disabled;
        }
        if (data.JobReplaceConfigurationDto != null) {
          this.jobReplaceIsTR = data.JobReplaceConfigurationDto.RJRC_Learning;
          this.jobReplaceIsDisabled =
            data.JobReplaceConfigurationDto.RJRC_ISDisabled;
          this.jobReplaceIsPended =
            data.JobReplaceConfigurationDto.RJRC_IsPended;
          this.jobReplaceIsLikeEdit =
            data.JobReplaceConfigurationDto.RJRC_ISLikeEdit;
          this.jobReplaceIndividualView =
            data.JobReplaceConfigurationDto.RJRC_IV_Disabled;
          this.jobReplaceEngagementView =
            data.JobReplaceConfigurationDto.RJRC_EV_Disabled;
        }

        if (data.JobReplaceAllConfigurationDto != null) {
          this.jobReplaceAllIsTR =
            data.JobReplaceAllConfigurationDto.RJRAC_Learning;
          this.jobReplaceAllIsDisabled =
            data.JobReplaceAllConfigurationDto.RJRAC_ISDisabled;
          this.jobReplaceAllIsPended =
            data.JobReplaceAllConfigurationDto.RJRAC_IsPended;
          this.jobReplaceAllIsLikeEdit =
            data.JobReplaceAllConfigurationDto.RJRAC_ISLikeEdit;
          this.jobReplaceAllIndividualView =
            data.JobReplaceAllConfigurationDto.RJRAC_IV_Disabled;
          this.jobReplaceAllEngagementView =
            data.JobReplaceAllConfigurationDto.RJRAC_EV_Disabled;
        }
        if (data.ResourceViewConfigurationDto != null)
          this.resViewIsActive =
            data.ResourceViewConfigurationDto.RRVC_RInactive;
        if (data.JobViewConfigurationDto != null) {
          this.jobViewIsTR = data.JobViewConfigurationDto.RJVC_Learning;
          this.jobViewIsPended = data.JobViewConfigurationDto.RJVC_IsPended;
        }
        if (data.BookingConfigurationDto != null) {
          this.bookingCurrentWeekEdit =
            data.BookingConfigurationDto.RBC_BIDEWEEK;
          this.bookingCurrentWeekDelete =
            data.BookingConfigurationDto.RBC_BIDDWEEK;
          this.bookingCurrentWeekAdd =
            data.BookingConfigurationDto.RBC_BIDAWEEK;
          this.bookingCurrentWeekCopy =
            data.BookingConfigurationDto.RBC_BIDCWEEK;
          this.bookingCurrentWeekReplace =
            data.BookingConfigurationDto.RBC_BIDRWEEK;
          this.bookingCurrentWeekReplaceAll =
            data.BookingConfigurationDto.RBC_BIDRAWEEK;
          this.bookigTrainingEngagementDelete =
            data.BookingConfigurationDto.RBC_BIDDTRNG;
          this.bookigTrainingEngagementEdit =
            data.BookingConfigurationDto.RBC_BIDETRNG;
          this.bookigDisabledDelete = data.BookingConfigurationDto.RBC_BIDDDELT;
          this.bookigDisabledEdit = data.BookingConfigurationDto.RBC_BIDEEDIT;
          this.bookigDisabledCopy = data.BookingConfigurationDto.RBC_BIDCCOPY;
          this.bookigDisabledReplace =
            data.BookingConfigurationDto.RBC_BIDRRPLC;
          this.bookigDisabledReplaceAll =
            data.BookingConfigurationDto.RBC_BIDRARPLCA;
          this.bookingDisabledGhostView =
            data.BookingConfigurationDto.RBC_BIDVGhost;
          this.bookingDisabledGhostEdit =
            data.BookingConfigurationDto.RBC_BIDEGhost;
          this.bookingDisabledGhostDelete =
            data.BookingConfigurationDto.RBC_BIDDGhost;
          this.bookingDisabledGhostCopy =
            data.BookingConfigurationDto.RBC_BIDCGhost;
          this.bookingDisabledGhostReplace =
            data.BookingConfigurationDto.RBC_BIDRGhost;
        }
        this.fetchedFeatureLevelDtos = data.RoleFeatureLevelConfigurationDtos;
        if (this.fetchedFeatureLevelDtos?.length)
          this.bindFeatureLevelFields(this.fetchedFeatureLevelDtos);
      });

      resolve();
    });
  }

  bindFeatureLevelFields(arr) {
    const excludedIds = arr
      .filter((item) => item.IsExclude)
      .map((item) => item.RFC_F_ID);

    const objNamesExcluded = excludedIds.map((item) => {
      return this.featuresList.find((innItem) => innItem.Id === item)?.Text;
    });

    objNamesExcluded.forEach((item) => {
      if (item) this.featureField[item] = true;
    });
    this.featureLevelDtos = arr;
  }

  makeDirty() {
    if (!this.dirtyFlag) {
      this.dirtyFlag = true;
    }
  }

  managePrioirtyDisable() {
    let csRole = this.roleTypes.find((x) => x.ID == this.selectedRoleType);
    if (csRole != null && csRole.Code == this.roleTypeEnum.ClientServer) {
      if (this.selectedPriority == 0 || this.selectedPriority == null)
        this.selectedPriority = 1;
      this.prtDisabled = false;
    } else if (
      csRole != null &&
      csRole.Code == this.roleTypeEnum.ResourceAdmin
    ) {
      this.selectedPriority = 0;
      this.prtDisabled = true;
      this.isApproverReq=false;
    } else {
      this.selectedPriority = 0;
      this.prtDisabled = false;
    }
  }

  onChangeMultiInsta(): void{
    this.makeDirty();    
    this.roleIndividualView = true;
    this.roleEngagementView = true;
    this.roleTeamView = true; 
    this.InstanceEnableAddBookingIV = true;
    this.selectedHomeBasedInstance = null;
    this.isHomeRetainNotSelected = false;
  }

  onChangeNewBookingIV(): void{
    this.makeDirty();
    this.selectedHomeBasedInstance = null;
    this.isHomeRetainNotSelected = false;
  } 

  masterData() {
    // return new Promise((resolve) => {
    this.restService.getMasterData().subscribe((data) => {
      if (data.ActionDescriptionList?.length) {
        data.ActionDescriptionList.forEach((element) => {
          this.actionDescriptionList = this.actionDescriptionList.concat({
            Id: element.ID,
            Text: element.Text,
          });
        });
        this.actionDescriptionList = this.actionDescriptionList.filter(item => item.Id !=6
          && item.Id !=3); 
          //removed Replace All and Copy from list because copy =create and Replace All= Replace 
      }
      if (data.FeatureList?.length) {
        this.featureDataList = data.FeatureList;
        if(this.RoleTypeName != null) 
        this.featuresfilterList =  this.featureDataList.filter(x=> x.Code == this.RoleTypeName)
        this.featuresfilterList .forEach((element) => {
          this.featureField = { [`${element.Text}`]: false };
          this.featuresList = this.featuresList.concat({
            Id: element.ID,
            Text: element.Text,
          });
        });
        if (this.fetchedFeatureLevelDtos?.length)
          this.bindFeatureLevelFields(this.fetchedFeatureLevelDtos);
      }
      if (data.SubAreaList != null) {
        data.SubAreaList.forEach((element) => {
          this.assignmentSubAreaList = this.assignmentSubAreaList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.resAddSubAreaList = this.resAddSubAreaList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.jobAddSubAreaList = this.jobAddSubAreaList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.resEditSubAreaList = this.resEditSubAreaList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.jobEditSubAreaList = this.jobEditSubAreaList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.resDeleteSubAreaList = this.resDeleteSubAreaList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.jobDeleteSubAreaList = this.jobDeleteSubAreaList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.resReplaceSubAreaList = this.resReplaceSubAreaList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.jobReplaceSubAreaList = this.jobReplaceSubAreaList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.resReplaceAllSubAreaList = this.resReplaceAllSubAreaList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.jobReplaceAllSubAreaList = this.jobReplaceAllSubAreaList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.resViewSubAreaList = this.resViewSubAreaList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.jobViewSubAreaList = this.jobViewSubAreaList.concat({
            Id: element.ID,
            Text: element.Text,
          });
        });
      }
      if (data.ManageCountryList != null) {
        data.ManageCountryList.forEach((element) => {
          this.assignmentManageCountryList =
            this.assignmentManageCountryList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.resAddManageCountryList = this.resAddManageCountryList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.jobAddManageCountryList = this.jobAddManageCountryList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.resEditManageCountryList = this.resEditManageCountryList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.jobEditManageCountryList = this.jobEditManageCountryList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.resDeleteManageCountryList =
            this.resDeleteManageCountryList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.jobDeleteManageCountryList =
            this.jobDeleteManageCountryList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.resReplaceManageCountryList =
            this.resReplaceManageCountryList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.jobReplaceManageCountryList =
            this.jobReplaceManageCountryList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.resReplaceAllManageCountryList =
            this.resReplaceAllManageCountryList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.jobReplaceAllManageCountryList =
            this.jobReplaceAllManageCountryList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.resViewManageCountryList = this.resViewManageCountryList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.jobViewManageCountryList = this.jobViewManageCountryList.concat({
            Id: element.ID,
            Text: element.Text,
          });
        });
      }
      if (data.LegalEntityList != null) {
        data.LegalEntityList.forEach((element) => {
          this.assignmentLegalEntityList =
            this.assignmentLegalEntityList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.resAddLegalEntityList = this.resAddLegalEntityList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.jobAddLegalEntityList = this.jobAddLegalEntityList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.resEditLegalEntityList = this.resEditLegalEntityList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.jobEditLegalEntityList = this.jobEditLegalEntityList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.resDeleteLegalEntityList = this.resDeleteLegalEntityList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.jobDeleteLegalEntityList = this.jobDeleteLegalEntityList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.resReplaceLegalEntityList =
            this.resReplaceLegalEntityList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.jobReplaceLegalEntityList =
            this.jobReplaceLegalEntityList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.resReplaceAllLegalEntityList =
            this.resReplaceAllLegalEntityList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.jobReplaceAllLegalEntityList =
            this.jobReplaceAllLegalEntityList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.resViewLegalEntityList = this.resViewLegalEntityList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.jobViewLegalEntityList = this.jobViewLegalEntityList.concat({
            Id: element.ID,
            Text: element.Text,
          });
        });
      }
      if (data.BusinessUnitList != null) {
        data.BusinessUnitList.forEach((element) => {
          this.assignmentBusinessUnitList =
            this.assignmentBusinessUnitList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.resAddBusinessUnitList = this.resAddBusinessUnitList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.jobAddBusinessUnitList = this.jobAddBusinessUnitList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.resEditBusinessUnitList = this.resEditBusinessUnitList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.jobEditBusinessUnitList = this.jobEditBusinessUnitList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.resDeleteBusinessUnitList =
            this.resDeleteBusinessUnitList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.jobDeleteBusinessUnitList =
            this.jobDeleteBusinessUnitList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.resReplaceBusinessUnitList =
            this.resReplaceBusinessUnitList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.jobReplaceBusinessUnitList =
            this.jobReplaceBusinessUnitList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.resReplaceAllBusinessUnitList =
            this.resReplaceAllBusinessUnitList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.jobReplaceAllBusinessUnitList =
            this.jobReplaceAllBusinessUnitList.concat({
              Id: element.ID,
              Text: element.Text,
            });

          this.resViewBusinessUnitList = this.resViewBusinessUnitList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.jobViewBusinessUnitList = this.jobViewBusinessUnitList.concat({
            Id: element.ID,
            Text: element.Text,
          });
        });
      }
      if (data.OperatingUnitList?.length) {
        data.OperatingUnitList.forEach((element) => {
          this.assignmentOperatingUnitList =
            this.assignmentOperatingUnitList.concat({
              Id: element.ID,
              Text: element.Text,
            });
        });
      }
      if (data.ManagementUnitList?.length) {
        data.ManagementUnitList.forEach((element) => {
          this.assignmentManagementUnitList =
            this.assignmentManagementUnitList.concat({
              Id: element.ID,
              Text: element.Text,
            });
        });
      }
      if (data.DepartmentList?.length) {
        data.DepartmentList.forEach((element) => {
          this.assignmentDepartmentList = this.assignmentDepartmentList.concat({
            Id: element.ID,
            Text: element.Text,
          });
        });
      }
      if (data.ServiceLineList != null) {
        data.ServiceLineList.forEach((element) => {
          this.assignmentServiceLineList =
            this.assignmentServiceLineList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.resAddServiceLineList = this.resAddServiceLineList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.jobAddServiceLineList = this.jobAddServiceLineList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.resEditServiceLineList = this.resEditServiceLineList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.jobEditServiceLineList = this.jobEditServiceLineList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.resDeleteServiceLineList = this.resDeleteServiceLineList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.jobDeleteServiceLineList = this.jobDeleteServiceLineList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.resReplaceServiceLineList =
            this.resReplaceServiceLineList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.jobReplaceServiceLineList =
            this.jobReplaceServiceLineList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.resReplaceAllServiceLineList =
            this.resReplaceAllServiceLineList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.jobReplaceAllServiceLineList =
            this.jobReplaceAllServiceLineList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.resViewServiceLineList = this.resViewServiceLineList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.jobViewServiceLineList = this.jobViewServiceLineList.concat({
            Id: element.ID,
            Text: element.Text,
          });
        });
      }
      if (data.SubServiceLineList != null) {
        data.SubServiceLineList.forEach((element) => {
          this.assignmentSubServiceLineList =
            this.assignmentSubServiceLineList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.resAddSubServiceLineList = this.resAddSubServiceLineList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.jobAddSubServiceLineList = this.jobAddSubServiceLineList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.resEditSubServiceLineList =
            this.resEditSubServiceLineList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.jobEditSubServiceLineList =
            this.jobEditSubServiceLineList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.resDeleteSubServiceLineList =
            this.resDeleteSubServiceLineList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.jobDeleteSubServiceLineList =
            this.jobDeleteSubServiceLineList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.resReplaceSubServiceLineList =
            this.resReplaceSubServiceLineList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.jobReplaceSubServiceLineList =
            this.jobReplaceSubServiceLineList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.resReplaceAllSubServiceLineList =
            this.resReplaceAllSubServiceLineList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.jobReplaceAllSubServiceLineList =
            this.jobReplaceAllSubServiceLineList.concat({
              Id: element.ID,
              Text: element.Text,
            });

          this.resViewSubServiceLineList =
            this.resViewSubServiceLineList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.jobViewSubServiceLineList =
            this.jobViewSubServiceLineList.concat({
              Id: element.ID,
              Text: element.Text,
            });
        });
      }
      if (data.SubManagementUnitList != null) {
        data.SubManagementUnitList.forEach((element) => {
          this.assignmentSubManagementUnitList =
            this.assignmentSubManagementUnitList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.resAddSubManagementUnitList =
            this.resAddSubManagementUnitList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.jobAddSubManagementUnitList =
            this.jobAddSubManagementUnitList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.resEditSubManagementUnitList =
            this.resEditSubManagementUnitList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.jobEditSubManagementUnitList =
            this.jobEditSubManagementUnitList.concat({
              Id: element.ID,
              Text: element.Text,
            });

          this.resDeleteSubManagementUnitList =
            this.resDeleteSubManagementUnitList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.jobDeleteSubManagementUnitList =
            this.jobDeleteSubManagementUnitList.concat({
              Id: element.ID,
              Text: element.Text,
            });

          this.resReplaceSubManagementUnitList =
            this.resReplaceSubManagementUnitList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.jobReplaceSubManagementUnitList =
            this.jobReplaceSubManagementUnitList.concat({
              Id: element.ID,
              Text: element.Text,
            });

          this.resReplaceAllSubManagementUnitList =
            this.resReplaceAllSubManagementUnitList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.jobReplaceAllSubManagementUnitList =
            this.jobReplaceAllSubManagementUnitList.concat({
              Id: element.ID,
              Text: element.Text,
            });

          this.resViewSubManagementUnitList =
            this.resViewSubManagementUnitList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.jobViewSubManagementUnitList =
            this.jobViewSubManagementUnitList.concat({
              Id: element.ID,
              Text: element.Text,
            });
        });
      }
      if (data.RankList != null) {
        data.RankList.forEach((element) => {
          this.assignmentRankList = this.assignmentRankList.concat({
            Id: element.ID,
            Text: element.Text,
          });
        });
      }
      if (data.JobCategory != null) {
        data.JobCategory.forEach((element) => {
          this.jobAddJobCategoryList = this.jobAddJobCategoryList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.jobEditJobCategoryList = this.jobEditJobCategoryList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.jobDeleteJobCategoryList = this.jobDeleteJobCategoryList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.jobReplaceJobCategoryList =
            this.jobReplaceJobCategoryList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.jobReplaceAllJobCategoryList =
            this.jobReplaceAllJobCategoryList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.jobViewJobCategoryList = this.jobViewJobCategoryList.concat({
            Id: element.ID,
            Text: element.Text,
          });
        });
      }
      if (data.JobStatus != null) {
        data.JobStatus.forEach((element) => {
          this.jobAddJobStatusList = this.jobAddJobStatusList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.jobAddJobStatusBookingList =
            this.jobAddJobStatusBookingList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.jobEditJobStatusList = this.jobEditJobStatusList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.jobEditJobStatusBookingList =
            this.jobEditJobStatusBookingList.concat({
              Id: element.ID,
              Text: element.Text,
            });

          this.jobDeleteJobStatusList = this.jobDeleteJobStatusList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.jobDeleteJobStatusBookingList =
            this.jobDeleteJobStatusBookingList.concat({
              Id: element.ID,
              Text: element.Text,
            });

          this.jobReplaceJobStatusList = this.jobReplaceJobStatusList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.jobReplaceJobStatusBookingList =
            this.jobReplaceAllJobStatusBookingList.concat({
              Id: element.ID,
              Text: element.Text,
            });

          this.jobReplaceAllJobStatusList =
            this.jobReplaceAllJobStatusList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.jobReplaceAllJobStatusBookingList =
            this.jobReplaceAllJobStatusBookingList.concat({
              Id: element.ID,
              Text: element.Text,
            });

          this.jobViewJobStatusList = this.jobViewJobStatusList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.jobViewJobStatusBookingList =
            this.jobViewJobStatusBookingList.concat({
              Id: element.ID,
              Text: element.Text,
            });
        });
      }
      if (data.JobCode != null) {
        data.JobCode.forEach((element) => {
          this.jobAddJobCodeList = this.jobAddJobCodeList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.jobEditJobCodeList = this.jobEditJobCodeList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.jobDeleteJobCodeList = this.jobDeleteJobCodeList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.jobReplaceJobCodeList = this.jobReplaceJobCodeList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.jobReplaceAllJobCodeList = this.jobReplaceAllJobCodeList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.jobViewJobCodeList = this.jobViewJobCodeList.concat({
            Id: element.ID,
            Text: element.Text,
          });
        });
      }
      if (data.IncludeJobCode != null) {
        data.IncludeJobCode.forEach((element) => {
          this.jobAddIncludeJobCodeList = this.jobAddIncludeJobCodeList.concat({
            Id: element.ID,
            Text: element.Text,
          });
          this.jobEditIncludeJobCodeList =
            this.jobEditIncludeJobCodeList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.jobDeleteIncludeJobCodeList =
            this.jobDeleteIncludeJobCodeList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.jobReplaceIncludeJobCodeList =
            this.jobReplaceIncludeJobCodeList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.jobReplaceAllIncludeJobCodeList =
            this.jobReplaceAllIncludeJobCodeList.concat({
              Id: element.ID,
              Text: element.Text,
            });
          this.jobViewIncludeJobCodeList =
            this.jobViewIncludeJobCodeList.concat({
              Id: element.ID,
              Text: element.Text,
            });
        });
      }
      this.roleTypes = data.RoleTypeList;
    });

    // resolve();
    // });
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  openConfirmationModal(template, confirmationAction) {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.confirmationClickAction = confirmationAction;
    this.modalRef = this.modalService.show(template, this.modalOption);
  }

  //#endregion

  //#region Form Control Event
  cancel(template, confirmationAction) {
    this.openConfirmationModal(template, confirmationAction);
  }

  onConfirmAction() {
    this.modalRef.hide();
    if (this.confirmationClickAction.toLocaleLowerCase() == 'reset') {
      this.clearControls();
    } else {
      this.restService.cancelClick();
    }
  }

  resetEnableGhostBooking() {
    this.makeDirty();
    this.resetGhostBookingActionCheckBoxes();
  }

  resetGhostBookingActionCheckBoxes() {
    this.bookingDisabledGhostView = true;
    this.bookingDisabledGhostEdit = true;
    this.bookingDisabledGhostDelete = true;
    this.bookingDisabledGhostCopy = true;
    this.bookingDisabledGhostReplace = true;
  }

  onSubmit() {
    if (!this.dirtyFlag || this.formGroup.invalid || this.isExistsAccessRole) {
      window.scroll(0, 0);
      return;
    }
    if (this.accessRoleName.trim() === '') {
      this.isExistsAccessRole = true;
      this.errorMessage = 'Role Name is required';
      window.scroll(0, 0);
      return;
    }

    if (!this.InstanceEnableAddBookingIV && this.selectedHomeBasedInstance == null ) {
      this.isHomeRetainNotSelected = true;
      this.errorMessage = 'Please Select Home-Based Retain Instance';
      window.scroll(0, 0);
      return;
    }

    this.spinner.show();
    this.buttonText = 'Please wait..';
    this.role = new RoleVM();
    let assingmentDto = new AssignmentConfigurationDto();
    let resAddDto = new ResourceAddConfigurationDto();
    let jobAddDto = new JobAddConfigurationDto();
    let resDeleteDto = new ResourceDeleteConfigurationDto();
    let jobDeleteDto = new JobDeleteConfigurationDto();
    let resReplaceDto = new ResourceReplaceConfigurationDto();
    let jobReplaceDto = new JobReplaceConfigurationDto();
    let resReplaceAllDto = new ResourceReplaceAllConfigurationDto();
    let jobReplaceAllDto = new JobReplaceAllConfigurationDto();
    let resEditDto = new ResourceEditConfigurationDto();
    let jobEditDto = new JobEditConfigurationDto();
    let resViewDto = new ResourceViewConfigurationDto();
    let jobViewDto = new JobViewConfigurationDto();
    let bookingConfigurationDto = new BookingConfigurationDto();

    if (this.actionSelected == ActionNameEnum.Copy) {
      this.role.R_ID = 0;
    } else if (
      this.actionSelected == ActionNameEnum.Edit ||
      this.actionSelected == ActionNameEnum.View
    ) {
      this.role.R_ID = +this.RoleId;
    }
    this.role.R_RT_ID = +this.selectedRoleType;
    this.role.R_Name = this.accessRoleName;
    this.role.R_Priority = this.selectedPriority;
    this.role.R_Comments = this.remark;
    this.role.R_View = this.selectedRoleView;
    this.role.R_Delegate = this.selectedDelegate;
    this.role.R_RELinkConfig = this.resEditLink;
    this.role.R_RADLinkConfig = this.resAddLink; // Add Config
    this.role.R_RVLinkConfig = this.resViewLink;
    this.role.R_JELinkConfig = this.jobEditLink;
    this.role.R_JADLinkConfig = this.jobAddLink; // Add Config
    this.role.R_JVLinkConfig = this.jobViewLink;
    this.role.R_RALinkConfig = this.assignmentLink;
    this.role.R_ModifiedBy = +sessionStorage.getItem('loggedin_resourceid');
    this.role.R_CreatedBy = +sessionStorage.getItem('loggedin_resourceid');
    this.role.R_IsActive = this.selectedRoleIsActive;
    this.role.R_CoWorker = this.selectedCoworkers;
    this.role.R_MUltipleInstance = this.isAllretainInstReq;
    this.role.R_Ghost = this.disableActionsGhostBooking;
    this.role.R_OverAllocation = this.overallocation;
    this.role.R_Unconfirmed = this.unconfirmed;
    this.role.R_IsReqdApproval = this.isApproverReq;
    this.role.R_I_ID = this.isAllretainInstReq ? null : this.selectedInstance;
    this.role.R_InstEnable_IV = this.roleIndividualView;
    this.role.R_InstEnable_EV = this.roleEngagementView;
    this.role.R_InstEnable_TV = this.roleTeamView;
    this.role.R_PreviewSubmit=this.previewSubmit;
    this.role.R_InstEnable_AddBooking = this.InstanceEnableAddBookingIV;
    this.role.R_Home_InstanceID = this.selectedHomeBasedInstance;

    assingmentDto.AssignmentSubAreas = this.assignmentSubAreaSelectedItems;
    assingmentDto.AssignmentSubManageUnits =
      this.assignmentSubManagementUnitSelectedItems;
    assingmentDto.AssignmentSubServiceLines =
      this.assignmentSubServiceLineSelectedItems;
    assingmentDto.AssignmentManagerialCountries =
      this.assignmentManageCountrySelectedItems;
    assingmentDto.AssignmentLegalEntities =
      this.assignmentLegalEntitySelectedItems;
    assingmentDto.AssignmentServiceLines =
      this.assignmentServiceLineSelectedItems;
    assingmentDto.AssignmentBusinessUnits =
      this.assignmentBusinessUnitSelectedItems;
    assingmentDto.AssignmentRanks = this.assignmentRankSelectedItems;
    assingmentDto.RAR_ISACTIVE = this.assignmentIsActive;
    assingmentDto.AssignmentOperatingUnits =
      this.assignmentOperatingUnitSelectedItems;
    assingmentDto.AssignmentManageUnits =
      this.assignmentManagementUnitSelectedItems;
    assingmentDto.AssignmentDepartment = this.assignmentDepartmentSelectedItems;
    // Start Add Configuration
    resAddDto.ResAddSubAreas = this.resAddSubAreaSelectedItems;
    resAddDto.ResAddSubManageUnits = this.resAddSubManagementUnitSelectedItems;
    resAddDto.ResAddSubServiceLines = this.resAddSubServiceLineSelectedItems;
    resAddDto.ResAddManagerialCountries = this.resAddManageCountrySelectedItems;
    resAddDto.ResAddLegalEntities = this.resAddLegalEntitySelectedItems;
    resAddDto.ResAddServiceLines = this.resAddServiceLineSelectedItems;
    resAddDto.ResAddBusinessUnits = this.resAddBusinessUnitSelectedItems;
    resAddDto.RRAC_RInactive = this.resAddIsActive;
    resAddDto.RRAC_ISDisabled = this.resAddIsDisabled;
    resAddDto.RRAC_ISLikeEdit = this.resAddIsLikeEdit;
    resAddDto.RRAC_IV_Disabled = this.resAddIndividualView;
    resAddDto.RRAC_EV_Disabled = this.resAddEngagementView;

    jobAddDto.JobAddSubAreas = this.jobAddSubAreaSelectedItems;
    jobAddDto.JobAddSubManageUnits = this.jobAddSubManagementUnitSelectedItems;
    jobAddDto.JobAddSubServiceLines = this.jobAddSubServiceLineSelectedItems;
    jobAddDto.JobAddManagerialCountries = this.jobAddManageCountrySelectedItems;
    jobAddDto.JobAddLegalEntities = this.jobAddLegalEntitySelectedItems;
    jobAddDto.JobAddServiceLines = this.jobAddServiceLineSelectedItems;
    jobAddDto.JobAddBusinessUnits = this.jobAddBusinessUnitSelectedItems;

    jobAddDto.JobAddJobCategories = this.jobAddJobCategorySelectedItems;
    jobAddDto.JobAddJobCode = this.jobAddJobCodeSelectedItems;
    jobAddDto.JobAddIncludeJobCode = this.jobAddIncludeJobCodeSelectedItems;
    jobAddDto.JobAddJobStatuses = this.jobAddJobStatusSelectedItems;
    jobAddDto.JobAddJobStatusBookings =
      this.jobAddJobStatusBookingSelectedItems;
    jobAddDto.RJAC_Learning = this.jobAddIsTR;
    jobAddDto.RJAC_ISDisabled = this.jobAddIsDisabled;
    jobAddDto.RJAC_ISLikeEdit = this.jobAddIsLikeEdit;
    jobAddDto.RJAC_IsPended = this.jobAddIsPended;
    jobAddDto.RJAC_IV_Disabled = this.jobAddIndividualView;
    jobAddDto.RJAC_EV_Disabled = this.jobAddEngagementView;
    // END Add Configuration

    // Start Delete  Configuration
    resDeleteDto.ResDeleteSubAreas = this.resDeleteSubAreaSelectedItems;
    resDeleteDto.ResDeleteSubManageUnits =
      this.resDeleteSubManagementUnitSelectedItems;
    resDeleteDto.ResDeleteSubServiceLines =
      this.resDeleteSubServiceLineSelectedItems;
    resDeleteDto.ResDeleteManagerialCountries =
      this.resDeleteManageCountrySelectedItems;
    resDeleteDto.ResDeleteLegalEntities =
      this.resDeleteLegalEntitySelectedItems;
    resDeleteDto.ResDeleteServiceLines = this.resDeleteServiceLineSelectedItems;
    resDeleteDto.ResDeleteBusinessUnits =
      this.resDeleteBusinessUnitSelectedItems;
    resDeleteDto.RRDC_RInactive = this.resDeleteIsActive;
    resDeleteDto.RRDC_ISDisabled = this.resDeleteIsDisabled;
    resDeleteDto.RRDC_ISLikeEdit = this.resDeleteIsLikeEdit;
    resDeleteDto.RRDC_IV_Disabled = this.resDeleteIndividualView;
    resDeleteDto.RRDC_EV_Disabled = this.resDeleteEngagementView;

    jobDeleteDto.JobDeleteSubAreas = this.jobDeleteSubAreaSelectedItems;
    jobDeleteDto.JobDeleteSubManageUnits =
      this.jobDeleteSubManagementUnitSelectedItems;
    jobDeleteDto.JobDeleteSubServiceLines =
      this.jobDeleteSubServiceLineSelectedItems;
    jobDeleteDto.JobDeleteManagerialCountries =
      this.jobDeleteManageCountrySelectedItems;
    jobDeleteDto.JobDeleteLegalEntities =
      this.jobDeleteLegalEntitySelectedItems;
    jobDeleteDto.JobDeleteServiceLines = this.jobDeleteServiceLineSelectedItems;
    jobDeleteDto.JobDeleteBusinessUnits =
      this.jobDeleteBusinessUnitSelectedItems;
    jobDeleteDto.JobDeleteJobCategories =
      this.jobDeleteJobCategorySelectedItems;
    jobDeleteDto.JobDeleteJobCode = this.jobDeleteJobCodeSelectedItems;
    jobDeleteDto.JobDeleteIncludeJobCode =
      this.jobDeleteIncludeJobCodeSelectedItems;
    jobDeleteDto.JobDeleteJobStatuses = this.jobDeleteJobStatusSelectedItems;
    jobDeleteDto.JobDeleteJobStatusBookings =
      this.jobDeleteJobStatusBookingSelectedItems;
    jobDeleteDto.RJDC_Learning = this.jobDeleteIsTR;
    jobDeleteDto.RJDC_ISDisabled = this.jobDeleteIsDisabled;
    jobDeleteDto.RJDC_ISLikeEdit = this.jobDeleteIsLikeEdit;
    jobDeleteDto.RJDC_IsPended = this.jobDeleteIsPended;
    jobDeleteDto.RJDC_IV_Disabled = this.jobDeleteIndividualView;
    jobDeleteDto.RJDC_EV_Disabled = this.jobDeleteEngagementView;
    // END Delete Configuration

    // Start Replace  Configuration
    resReplaceDto.ResReplaceSubAreas = this.resReplaceSubAreaSelectedItems;
    resReplaceDto.ResReplaceSubManageUnits =
      this.resReplaceSubManagementUnitSelectedItems;
    resReplaceDto.ResReplaceSubServiceLines =
      this.resReplaceSubServiceLineSelectedItems;
    resReplaceDto.ResReplaceManagerialCountries =
      this.resReplaceManageCountrySelectedItems;
    resReplaceDto.ResReplaceLegalEntities =
      this.resReplaceLegalEntitySelectedItems;
    resReplaceDto.ResReplaceServiceLines =
      this.resReplaceServiceLineSelectedItems;
    resReplaceDto.ResReplaceBusinessUnits =
      this.resReplaceBusinessUnitSelectedItems;
    resReplaceDto.RRRC_RInactive = this.resReplaceIsActive;
    resReplaceDto.RRRC_ISDisabled = this.resReplaceIsDisabled;
    resReplaceDto.RRRC_ISLikeEdit = this.resReplaceIsLikeEdit;
    resReplaceDto.RRRC_IV_Disabled = this.resReplaceIndividualView;
    resReplaceDto.RRRC_EV_Disabled = this.resReplaceEngagementView;

    jobReplaceDto.JobReplaceSubAreas = this.jobReplaceSubAreaSelectedItems;
    jobReplaceDto.JobReplaceSubManageUnits =
      this.jobReplaceSubManagementUnitSelectedItems;
    jobReplaceDto.JobReplaceSubServiceLines =
      this.jobReplaceSubServiceLineSelectedItems;
    jobReplaceDto.JobReplaceManagerialCountries =
      this.jobReplaceManageCountrySelectedItems;
    jobReplaceDto.JobReplaceLegalEntities =
      this.jobReplaceLegalEntitySelectedItems;
    jobReplaceDto.JobReplaceServiceLines =
      this.jobReplaceServiceLineSelectedItems;
    jobReplaceDto.JobReplaceBusinessUnits =
      this.jobReplaceBusinessUnitSelectedItems;
    jobReplaceDto.JobReplaceJobCategories =
      this.jobReplaceJobCategorySelectedItems;
    jobReplaceDto.JobReplaceJobCode = this.jobReplaceJobCodeSelectedItems;
    jobReplaceDto.JobReplaceIncludeJobCode =
      this.jobReplaceIncludeJobCodeSelectedItems;
    jobReplaceDto.JobReplaceJobStatuses = this.jobReplaceJobStatusSelectedItems;
    jobReplaceDto.JobReplaceJobStatusBookings =
      this.jobReplaceJobStatusBookingSelectedItems;
    jobReplaceDto.RJRC_Learning = this.jobReplaceIsTR;
    jobReplaceDto.RJRC_ISDisabled = this.jobReplaceIsDisabled;
    jobReplaceDto.RJRC_ISLikeEdit = this.jobReplaceIsLikeEdit;
    jobReplaceDto.RJRC_IsPended = this.jobReplaceIsPended;
    jobReplaceDto.RJRC_IV_Disabled = this.jobReplaceIndividualView;
    jobReplaceDto.RJRC_EV_Disabled = this.jobReplaceEngagementView;
    // END Replace Configuration

    // Start Replace All  Configuration
    resReplaceAllDto.ResReplaceAllSubAreas =
      this.resReplaceAllSubAreaSelectedItems;
    resReplaceAllDto.ResReplaceAllSubManageUnits =
      this.resReplaceAllSubManagementUnitSelectedItems;
    resReplaceAllDto.ResReplaceAllSubServiceLines =
      this.resReplaceAllSubServiceLineSelectedItems;
    resReplaceAllDto.ResReplaceAllManagerialCountries =
      this.resReplaceAllManageCountrySelectedItems;
    resReplaceAllDto.ResReplaceAllLegalEntities =
      this.resReplaceAllLegalEntitySelectedItems;
    resReplaceAllDto.ResReplaceAllServiceLines =
      this.resReplaceAllServiceLineSelectedItems;
    resReplaceAllDto.ResReplaceAllBusinessUnits =
      this.resReplaceAllBusinessUnitSelectedItems;
    resReplaceAllDto.RRRAC_RInactive = this.resReplaceAllIsActive;
    resReplaceAllDto.RRRAC_ISDisabled = this.resReplaceAllIsDisabled;
    resReplaceAllDto.RRRAC_ISLikeEdit = this.resReplaceAllIsLikeEdit;
    resReplaceAllDto.RRRAC_IV_Disabled = this.resReplaceAllIndividualView;
    resReplaceAllDto.RRRAC_EV_Disabled = this.resReplaceAllEngagementView;

    jobReplaceAllDto.JobReplaceAllSubAreas =
      this.jobReplaceAllSubAreaSelectedItems;
    jobReplaceAllDto.JobReplaceAllSubManageUnits =
      this.jobReplaceAllSubManagementUnitSelectedItems;
    jobReplaceAllDto.JobReplaceAllSubServiceLines =
      this.jobReplaceAllSubServiceLineSelectedItems;
    jobReplaceAllDto.JobReplaceAllManagerialCountries =
      this.jobReplaceAllManageCountrySelectedItems;
    jobReplaceAllDto.JobReplaceAllLegalEntities =
      this.jobReplaceAllLegalEntitySelectedItems;
    jobReplaceAllDto.JobReplaceAllServiceLines =
      this.jobReplaceAllServiceLineSelectedItems;
    jobReplaceAllDto.JobReplaceAllBusinessUnits =
      this.jobReplaceAllBusinessUnitSelectedItems;
    jobReplaceAllDto.JobReplaceAllJobCategories =
      this.jobReplaceAllJobCategorySelectedItems;
    jobReplaceAllDto.JobReplaceAllJobCode =
      this.jobReplaceAllJobCodeSelectedItems;
    jobReplaceAllDto.JobReplaceAllIncludeJobCode =
      this.jobReplaceAllIncludeJobCodeSelectedItems;
    jobReplaceAllDto.JobReplaceAllJobStatuses =
      this.jobReplaceAllJobStatusSelectedItems;
    jobReplaceAllDto.JobReplaceAllJobStatusBookings =
      this.jobReplaceAllJobStatusBookingSelectedItems;
    jobReplaceAllDto.RJRAC_Learning = this.jobReplaceAllIsTR;
    jobReplaceAllDto.RJRAC_ISDisabled = this.jobReplaceAllIsDisabled;
    jobReplaceAllDto.RJRAC_ISLikeEdit = this.jobReplaceAllIsLikeEdit;
    jobReplaceAllDto.RJRAC_IsPended = this.jobReplaceAllIsPended;
    jobReplaceAllDto.RJRAC_IV_Disabled = this.jobReplaceAllIndividualView;
    jobReplaceAllDto.RJRAC_EV_Disabled = this.jobReplaceAllEngagementView;
    // END Replace All Configuration
    resEditDto.ResEditSubAreas = this.resEditSubAreaSelectedItems;
    resEditDto.ResEditSubManageUnits =
      this.resEditSubManagementUnitSelectedItems;
    resEditDto.ResEditSubServiceLines = this.resEditSubServiceLineSelectedItems;
    resEditDto.ResEditManagerialCountries =
      this.resEditManageCountrySelectedItems;
    resEditDto.ResEditLegalEntities = this.resEditLegalEntitySelectedItems;
    resEditDto.ResEditServiceLines = this.resEditServiceLineSelectedItems;
    resEditDto.ResEditBusinessUnits = this.resEditBusinessUnitSelectedItems;
    resEditDto.RREC_RInactive = this.resEditIsActive;
    resEditDto.RREC_ISDisabled = this.resEditIsDisabled;
    resEditDto.RREC_IV_Disabled = this.resEditIndividualView;
    resEditDto.RREC_EV_Disabled = this.resEditEngagementView;

    jobEditDto.JobEditSubAreas = this.jobEditSubAreaSelectedItems;
    jobEditDto.JobEditSubManageUnits =
      this.jobEditSubManagementUnitSelectedItems;
    jobEditDto.JobEditSubServiceLines = this.jobEditSubServiceLineSelectedItems;
    jobEditDto.JobEditManagerialCountries =
      this.jobEditManageCountrySelectedItems;
    jobEditDto.JobEditLegalEntities = this.jobEditLegalEntitySelectedItems;
    jobEditDto.JobEditServiceLines = this.jobEditServiceLineSelectedItems;
    jobEditDto.JobEditBusinessUnits = this.jobEditBusinessUnitSelectedItems;

    jobEditDto.JobEditJobCategories = this.jobEditJobCategorySelectedItems;
    jobEditDto.JobEditJobCode = this.jobEditJobCodeSelectedItems;
    jobEditDto.JobEditIncludeJobCode = this.jobEditIncludeJobCodeSelectedItems;
    jobEditDto.JobEditJobStatuses = this.jobEditJobStatusSelectedItems;
    jobEditDto.JobEditJobStatusBookings =
      this.jobEditJobStatusBookingSelectedItems;
    jobEditDto.RJEC_Learning = this.jobEditIsTR;
    jobEditDto.RJEC_ISDisabled = this.jobEditIsDisabled;
    jobEditDto.RJEC_IsPended = this.jobEditIsPended;
    jobEditDto.RJEC_IV_Disabled = this.jobEditIndividualView;
    jobEditDto.RJEC_EV_Disabled = this.jobEditEngagementView;

    resViewDto.ResViewSubAreas = this.resViewSubAreaSelectedItems;
    resViewDto.ResViewSubServiceLines = this.resViewSubServiceLineSelectedItems;
    resViewDto.ResViewManagerialCountries =
      this.resViewManageCountrySelectedItems;
    resViewDto.ResViewLegalEntities = this.resViewLegalEntitySelectedItems;
    resViewDto.ResViewServiceLines = this.resViewServiceLineSelectedItems;
    resViewDto.ResViewBusinessUnits = this.resViewBusinessUnitSelectedItems;
    resViewDto.ResViewSubManageUnits =
      this.resViewSubManagementUnitSelectedItems;
    resViewDto.RRVC_RInactive = this.resViewIsActive;

    jobViewDto.JobViewSubAreas = this.jobViewSubAreaSelectedItems;
    jobViewDto.JobViewSubServiceLines = this.jobViewSubServiceLineSelectedItems;
    jobViewDto.JobViewManagerialCountries =
      this.jobViewManageCountrySelectedItems;
    jobViewDto.JobViewLegalEntities = this.jobViewLegalEntitySelectedItems;
    jobViewDto.JobViewServiceLines = this.jobViewServiceLineSelectedItems;
    jobViewDto.JobViewBusinessUnits = this.jobViewBusinessUnitSelectedItems;
    jobViewDto.JobViewSubManageUnits =
      this.jobViewSubManagementUnitSelectedItems;

    jobViewDto.JobViewJobCategories = this.jobViewJobCategorySelectedItems;
    jobViewDto.JobViewJobCode = this.jobViewJobCodeSelectedItems;
    jobViewDto.JobViewIncludeJobCode = this.jobViewIncludeJobCodeSelectedItems;
    jobViewDto.JobViewJobStatuses = this.jobViewJobStatusSelectedItems;
    jobViewDto.JobViewjobStatusBookings =
      this.jobViewJobStatusBookingSelectedItems;
    jobViewDto.RJVC_Learning = this.jobViewIsTR;
    jobViewDto.RJVC_IsPended = this.jobViewIsPended;

    bookingConfigurationDto.RBC_BIDEWEEK = this.bookingCurrentWeekEdit;
    bookingConfigurationDto.RBC_BIDDWEEK = this.bookingCurrentWeekDelete;
    bookingConfigurationDto.RBC_BIDAWEEK = this.bookingCurrentWeekAdd;
    bookingConfigurationDto.RBC_BIDCWEEK = this.bookingCurrentWeekCopy;
    bookingConfigurationDto.RBC_BIDRWEEK = this.bookingCurrentWeekReplace;
    bookingConfigurationDto.RBC_BIDRAWEEK = this.bookingCurrentWeekReplaceAll;
    bookingConfigurationDto.RBC_BIDDTRNG = this.bookigTrainingEngagementDelete;
    bookingConfigurationDto.RBC_BIDETRNG = this.bookigTrainingEngagementEdit;
    bookingConfigurationDto.RBC_BIDDDELT = this.bookigDisabledDelete;
    bookingConfigurationDto.RBC_BIDEEDIT = this.bookigDisabledEdit;
    bookingConfigurationDto.RBC_BIDCCOPY = this.bookigDisabledCopy;
    bookingConfigurationDto.RBC_BIDRRPLC = this.bookigDisabledReplace;
    bookingConfigurationDto.RBC_BIDRARPLCA = this.bookigDisabledReplaceAll;
    if (this.disableActionsGhostBooking) {
      bookingConfigurationDto.RBC_BIDVGhost = this.bookingDisabledGhostView;
      bookingConfigurationDto.RBC_BIDEGhost = this.bookingDisabledGhostEdit;
      bookingConfigurationDto.RBC_BIDDGhost = this.bookingDisabledGhostDelete;
      bookingConfigurationDto.RBC_BIDCGhost = this.bookingDisabledGhostCopy;
      bookingConfigurationDto.RBC_BIDRGhost = this.bookingDisabledGhostReplace;
    }

    this.role.ResourceAddConfigurationDto = resAddDto; //Add Config
    this.role.ResourceDeleteConfigurationDto = resDeleteDto; //Delete Config
    this.role.ResourceReplaceConfigurationDto = resReplaceDto; //Replace Config
    this.role.ResourceReplaceAllConfigurationDto = resReplaceAllDto; //RepalceAll Config
    this.role.ResourceEditConfigurationDto = resEditDto;
    this.role.ResourceViewConfigurationDto = resViewDto;
    this.role.AssignmentConfigurationDto = assingmentDto;
    this.role.JobAddConfigurationDto = jobAddDto; //Add Config
    this.role.JobDeleteConfigurationDto = jobDeleteDto; //Delete Config
    this.role.JobReplaceConfigurationDto = jobReplaceDto; //Replace Config
    this.role.JobReplaceAllConfigurationDto = jobReplaceAllDto; //Replace Config
    this.role.JobEditConfigurationDto = jobEditDto;
    this.role.JobViewConfigurationDto = jobViewDto;
    this.role.BookingConfigurationDto = bookingConfigurationDto;
    this.role.RoleApprovalConfigurationDtos = this.approvalArrDtoForSubmission;
    this.role.RoleFeatureLevelConfigurationDtos = this.featureLevelDtos;

    this.restService.submitRoleConfiguration(this.role).subscribe(
      (data) => {
        if (data != null) {
          if (data.customResultId <= 0) {
            this.SaveErrorMessage = data.customException;
            this.customResultId = data.customResultId;
            this.SaveSuccessMessage = '';
          } else {
            this.buttonText = 'Saved';
            this.customResultId = null;
            this.SaveErrorMessage = '';
            this.SaveSuccessMessage =
              'Role configuration submitted successfully';
            // if (this.actionSelected == this.actionNameEnum.Copy) {
            //   this.RoleId = data.customResultId;
            //   this.actionSelected = this.actionNameEnum.Edit;
            //   this.actionName = this.actionNameEnum.Edit;

            // }
            if (this.actionSelected == this.actionNameEnum.Copy) {
              this.role.R_ID = data.customResultId;
              let csRole = this.roleTypes.find(
                (x) => x.ID == this.selectedRoleType
              );
              if (csRole != null) {
                this.role.RoleTypeName = csRole.Text;
                this.restService.redirectToEdit(this.role);
              }
            }
          }
          this.errorMessage = '';
          this.isExistsAccessRole = false;
        }
      },
      (err) => {
        this.buttonText = 'Error';
      },
      () => {
        this.spinner.hide();
        setTimeout(() => {
          this.buttonText = 'Submit';
          window.scroll(0, 0);
          setTimeout(() => {
            this.SaveSuccessMessage = '';
            this.SaveErrorMessage = '';
          }, 5000);
        }, 2000);
      }
    );
  }

  onChangeRoleType() {
    this.managePrioirtyDisable();
    this.manageFeatureLevelConfiguration();
    this.makeDirty();
  }

  onCoWorkerChange() {
    if (this.selectedDelegate && this.selectedCoworkers)
      this.selectedDelegate = false;
    this.makeDirty();
  }

  onDelegateChange() {
    if (this.selectedDelegate && this.selectedCoworkers)
      this.selectedCoworkers = false;
    this.makeDirty();
  }

  onKeyPressAccessRole() {
    if (this.isExistsAccessRole == true) {
      this.isExistsAccessRole = false;
    }
  }

  onChangeOutCheckAccessRole() {
    if (this.accessRoleName != '') {
      this.restService
        .checkAccessRole(this.RoleId, this.accessRoleName)
        .subscribe((data) => {
          this.isExistsAccessRole = data;
          this.errorMessage = 'Role Name is already exists';
        });
    }
    this.isExistsAccessRole = false;
    this.errorMessage = '';
    this.makeDirty();
  }

  getUserInstances() {
    const userInstance = this.sharedService.getStoredUserInstances();

    if (userInstance === null || userInstance === undefined) {
      this.sharedService.getUserInstances().subscribe((data) => {
        this.instances = this.sharedService.getStoredUserInstances();
        const selected = this.instances?.filter(
          (x) => x.InstanceId === this.selectedInstance
        );
        if (selected) {
          this.selectedInstance = selected[0].InstanceId;
        }
      });
    } else {
      this.instances = userInstance;
      const selected = this.instances?.filter(
        (x) => x.InstanceId === this.selectedInstance
      );
      if (selected) {
        this.selectedInstance = selected[0].InstanceId;
      }
    }
  }
  onInstanceChange(event) {
    this.selectedInstance = event.value;
  }

  onHomeBasedInstanceChange(event) {
    this.makeDirty();
    this.isHomeRetainNotSelected = false;
    this.selectedHomeBasedInstance = event.value;
  }

  manageFeatureLevelConfiguration(){
    let csRole = this.roleTypes.find((x) => x.ID == this.selectedRoleType);
    if (csRole != null && csRole.Code == this.roleTypeEnum.ClientServer ) {
      this.featuresfilterList = this.featureDataList.filter(x=> x.Code == this.roleTypeEnum.ClientServer)
    } else if (
      csRole != null && csRole.Code == this.roleTypeEnum.ResourceAdmin) {
        this.featuresfilterList = this.featureDataList.filter(x=> x.Code == this.roleTypeEnum.ResourceAdmin)
    }
    this.featuresList=[];
    if(this.featuresfilterList !=null)
    { 
       this.featuresfilterList.forEach((element) => {
      this.featureField = { [`${element.Text}`]: false };
      this.featuresList = this.featuresList.concat({
        Id: element.ID,
        Text: element.Text,
      });
    });
  }
}
  //#endregion
}
