import { Component, OnInit, Input } from '@angular/core';
import { BookingGridActionService } from '../../../Models/BookingGridActionService';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { MyRequest_RequestByMeVM } from '../../../Models/MyRequest_RequestByMeVM';
import { RequestQueueService } from '../request-queue.service';
import { Response } from '../../../Models/Response';
import { ResourceDto } from '../../../Models/ResourceDto';
import { IndividualService } from 'app/Views/individual/individual.service';
import { EngagementService } from 'app/Views/engagement/engagement.service';
import { CurrentWeekActionDTO } from 'app/Models/CurrentWeekActionDTO';

@Component({
  selector: 'app-cancel-request',
  templateUrl: './cancel-request.component.html',
  styleUrls: ['./cancel-request.component.css']
})
export class CancelRequestComponent implements OnInit {
  @Input() public calledFromChild: boolean;
  // requestId: number;
  // resourceId: number;
  // request: MyRequest_RequestByMeVM;
  //eventData: BookingGridActionService;
  @Input() actionEventData: BookingGridActionService;
  @Input() uiViewSource: string;
  @Input() currentWeekActionDTO: CurrentWeekActionDTO;
  deleteBookingSubscription: Subscription;
  cancelChildSubscription: Subscription;
  // Engagement = '' ;
  // startDate = '';
  // endDate = '';
  //closeModalWindow = false;
  response: Response;
  // jobId = 0;
  // resource: ResourceDto;
  // currentUserName: any;
  // currentUserId: any;
  // currentUserEmail: any;
  buttonText="Yes";  
  isNewBookingChildRowsExist: boolean = false;
   

  constructor(private requestQueueService: RequestQueueService, 
    private individualServices: IndividualService, 
    private engagementServices: EngagementService) //, public datepipe: DatePipe) 
  { 
    //this.resource = new ResourceDto();
  }

  ngOnInit() {
    this.isNewBookingChildRowsExist = 
      this.actionEventData.dataItem.ActionDescriptionId == 1 && this.actionEventData.dataItem.ChildRowsExist;
    //this.resource = JSON.parse(sessionStorage.getItem('resource'));
    //this.request = new MyRequest_RequestByMeVM();
    this.response = new Response();
    // this.currentUserName = this.resource.ResourceName;
    // this.currentUserEmail = this.resource.ResourceEmail;
    // this.currentUserId = sessionStorage.getItem("resourceId");
    // this.requestQueueService.deleteEvent.subscribe((data) => {
    //   //this.eventData = data;
    //   this.setData(data);
    // });
    //this.setData(this.actionEventData);
    // this.requestQueueService.currentMessageCloseModal.subscribe(closeModal => {
    //   this.closeModalWindow = closeModal;
    // });
    // this.requestQueueService.currentMessageCloseChildModal.subscribe(closeModal => {
    //   this.closeModalWindow = closeModal;
    // });    
  }

  // setData(rowdata: BookingGridActionService){
  //   this.requestId = rowdata.dataItem.ActionId;
  //   this.startDate = this.datepipe.transform(rowdata.dataItem.StartDate, 'dd-MM-yyyy');
  //   this.endDate = this.datepipe.transform(rowdata.dataItem.EndDate, 'dd-MM-yyyy');
  //   this.Engagement = rowdata.dataItem.Engagement;
  // }

  cancelRequest() {
    this.buttonText="Cancelling";
    // this.request.ActionId = this.requestId;
    // this.request.RequestByEmail = this.currentUserEmail;
    // this.request.RequestByName = this.currentUserName;
    //this.deleteBookingSubscription = this.requestQueueService.cancelRequest(this.request).subscribe(

    if (this.isNewBookingChildRowsExist) {
      this.cancelChildSubscription = 
      (this.uiViewSource == 'IV' ? this.individualServices : this.engagementServices)
      .CancelMeraBookingsChild(this.actionEventData.dataItem)
      .subscribe(
        (data: any) => {
          if (data.status = '200') {
            this.response = data.body;
            if(this.response.success) {
              this.closePopup(true);              
              this.buttonText = "Yes";
            }
            else {
              this.buttonText = "Error";    
            }
          }
          else { 
            this.buttonText = "Error";
          }
        }, error => {
          this.buttonText = "Error";
        }//, () => {
        //   this.buttonText = "Cancelled";
        // }
      ); 
    }
    else {
      let cancelBookingDto = {
        MeraBookingId: this.actionEventData.dataItem.MeraBookingId ?? this.actionEventData.dataItem.ActionId,
        StartDateDisplayed: this.actionEventData.dataItem.StartDate,
        EndDateDisplayed: this.actionEventData.dataItem.EndDate,
        UiViewSource: this.uiViewSource,
        RequestMode: JSON.parse(sessionStorage.getItem('requestMode'))
      }
      this.deleteBookingSubscription = 
      (this.uiViewSource == 'IV' ? this.individualServices
        : (this.uiViewSource == 'EV' ? this.engagementServices : this.requestQueueService))
      .CancelBookingAction(cancelBookingDto)
      .subscribe(
        (data: any) => {
          if (data.status = '200') {
            this.response = data.body;
            if(this.response.success) {
              this.closePopup(true);              
              this.buttonText = "Yes";
            }
            else {
              this.buttonText = "Error";    
            }
            // if (this.response.customResultId > 0) { this.individualServices.setRequestNumber(this.response.customResultId); }
            // //alert('Successfully saved the changes');
            // if (this.calledFromChild) {
            //   this.individualServices.closeChildModal(true);
            // } else {
            //   this.individualServices.closeModal(true);
            // }
          } 
          else { 
            this.buttonText = "Error";
          }
        }, error => {
          this.buttonText = "Error";
        }//, () => {
        //   this.buttonText = "Cancelled";
        // }
      );
    }
  }

  // cancelDelete(){
  //   this.requestQueueService.closeModal(true);
  // }

  closePopup(refreshData: boolean) {
    if(this.uiViewSource == 'IV') {
      if (this.calledFromChild)
        this.individualServices.closeChildModal(refreshData);
      else
        this.individualServices.closeModal(refreshData);
    }
    else if(this.uiViewSource == 'EV') {
      if (this.calledFromChild)
        this.engagementServices.closeChildModal(refreshData);
      else
      this.engagementServices.closeModal(refreshData);
    }
    else {
      this.requestQueueService.closeModal(refreshData);
    }
  }

  ngOnDestroy() {
    if (this.deleteBookingSubscription !== undefined) {
      this.deleteBookingSubscription.unsubscribe();
    }
    if (this.cancelChildSubscription !== undefined) {
      this.cancelChildSubscription.unsubscribe();
    }
  }
}
