<div class="aar-container">
  <mat-form-field class="resourceSearch filter-box" appearance="outline">
    <input
      type="text"
      placeholder="Search Resource"
      matInput
      (keyup)="doFilter($event.target.value)"
      [(ngModel)]="searchBar"
    />
    <mat-icon (click)="searchBar = ''; doFilter('')">close</mat-icon>
  </mat-form-field>
  <div class="materialTable table-arr">
    <table
      #table
      mat-table
      [dataSource]="auditGridSource"
      class="mat-elevation-z8 bookingTable"
    >
      <ng-container matColumnDef="ResourceName">
        <th mat-header-cell *matHeaderCellDef class="mat-column-ResourceName">
          Resource Name
        </th>
        <td mat-cell *matCellDef="let element" data-title="ResourceName">
          {{ element.ResourceName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="OfficeLocation">
        <th mat-header-cell *matHeaderCellDef class="mat-column-OfficeLocation">
          Office Location
        </th>
        <td mat-cell *matCellDef="let element" data-title="OfficeLocation">
          {{ element.LocationDescription }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Action">
        <th mat-header-cell *matHeaderCellDef class="mat-column-Action">
          Action
        </th>
        <td mat-cell *matCellDef="let element" data-title="Action">
          {{ element.Action }}
        </td>
      </ng-container>
      <ng-container matColumnDef="AssignedBy">
        <th mat-header-cell *matHeaderCellDef class="mat-column-AssignedBy">
          Assigned By
        </th>
        <td mat-cell *matCellDef="let element" data-title="AssignedBy">
          {{ element.AssignedBy }}
        </td>
      </ng-container>
      <ng-container matColumnDef="AssignedDate">
        <th mat-header-cell *matHeaderCellDef class="mat-column-AssignedDate">
          Assigned Date (GMT)
        </th>
        <td mat-cell *matCellDef="let element" data-title="AssignedDate">
          {{ element.AssignedDate | date: "dd-MM-yyyy HH:mm" }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <div *ngIf="!auditGridSource?.data?.length" class="noBookings my-3">
      Records not found
    </div>
  </div>
  <mat-paginator
    #paginator
    [pageSize]="5"
    [pageSizeOptions]="[5, 10, 25, 50]"
    showFirstLastButtons
    style="background: #2e2e38"
  >
  </mat-paginator>
</div>
