import { Component, OnInit } from '@angular/core';
import { RoleVM } from 'app/Models/RoleVM';
import { ActionNameEnum, RoleTypeEnum } from 'app/shared/enum';
import { SharedServicesService } from 'app/shared/sharedservices/shared-services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RoleConfigService } from '../role-config.service';
import { ModalOptions, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CurrentUser } from 'app/Models/CurrentUser';
import { DatePipe } from '@angular/common';
// todo: remove 'file-saver' from package.json
import { saveAs as importedSaveAs } from 'file-saver';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-role-configuration',
  templateUrl: './role-configuration.component.html',
  styleUrls: ['./role-configuration.component.scss'],
})
export class RoleConfigurationComponent implements OnInit {
  searchBar: string = '';
  accessRoleNameList = [];
  accessRoleId = 0;
  actionNameEnum = ActionNameEnum;
  selectedRole: RoleVM;
  actionSelected: string = ActionNameEnum.null;
  modalOption: ModalOptions = {};
  modalRef: BsModalRef;
  roleTypeEnum = RoleTypeEnum;
  AssignRoleAllowed = CurrentUser.RoleFeatures.find(x=>x.FeatureName =='AssignRM') != null;
  UpdateEmailAllowed = CurrentUser.RoleFeatures.find(x=>x.FeatureName =='UpdateResourceEmail') != null;
  AccessOptions = [
    {
      name: 'Manage Access Role Type Configuration',
      value: '1',
      checked: CurrentUser.RoleTypeName === this.roleTypeEnum.SuperAdmin,//false,
      isAllowed: CurrentUser.RoleTypeName === this.roleTypeEnum.SuperAdmin
    },
    {
      name: 'Assign Access Role to Resources',
      value: '2',
      checked: CurrentUser.RoleTypeName === this.roleTypeEnum.ResourceAdmin,//false,
      isAllowed: this.AssignRoleAllowed
    },
    {
      name: 'View Access Role assigned to Resource',
      value: '3',
      checked: false,
      isAllowed: CurrentUser.RoleTypeName === this.roleTypeEnum.SuperAdmin
    },
    {
      name: 'Update Resource Email',
      value: '4',
      checked: false,
      isAllowed: this.UpdateEmailAllowed
    },
  ];
  isRALoggedIn = false;
  radioSelected: string;
  loggedinUserRoleTypeName = '';
  disableReportIcon: boolean = true;
  accessRoleName: any;
  messageBody: string = '';
  messageTitle: string = '';
  public ExportFileName = '';
  public gridData: any;
  showSearchList: boolean = false;
  showSearchLoader: boolean = false;
  SaveSuccessMessage: string = '';
  SaveErrorMessage: string = '';

  constructor(
    private sharedService: SharedServicesService,
    private restService: RoleConfigService,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService,
    private datePipe: DatePipe
  ) {
    // setting 1st radio as checked
    //this.AccessOptions[0]['checked'] = !this.isRALoggedIn;
    //this.AccessOptions[1]['checked'] = this.isRALoggedIn && this.AssignRoleAllowed && this.UpdateEmailAllowed;
    this.onRadioChange({ value: 1 });
    this.loggedinUserRoleTypeName = CurrentUser.RoleTypeName;
  }
  onRadioChange(event) {
    this.radioSelected = event.value;
  }

  ngOnInit(): void {
    this.sharedService.activeLink = 'RoleConfiguration';
    //this.UpdateEmailAllowed = CurrentUser.RoleFeatures.find(x=>x.FeatureName =='UpdateResourceEmail') != null;
    this.restService.onCancelClick.subscribe((data) => {
      this.clearValue();
    });

    this.restService.onRedirectToEdit.subscribe((data) => {
      data = data as RoleVM;
      if (data != null && data.R_ID != null && data.R_ID > 0)
        this.redirectToEditClick(data);
      data = null;
    });
    if (CurrentUser.RoleTypeName == RoleTypeEnum.ResourceAdmin) {
      this.isRALoggedIn = true;
      this.radioSelected = this.AssignRoleAllowed ? '2' : '4';
      this.selectedRole = new RoleVM();
      this.selectedRole.R_ID = CurrentUser.RoleId;
      this.accessRoleId = CurrentUser.RoleId;
      this.selectedRole.R_Name = CurrentUser.RoleName;
      this.selectedRole.RoleTypeName = CurrentUser.RoleTypeName;
      this.selectedRole.RoleTypeDisplayName = CurrentUser.RoleTypeDisplayName;
    }

    // to be removed later
    // this.OnClickAction(ActionNameEnum.Add, null, true);
  }

  //#region Form Control Method
  clearValue() {
    this.spinner.show();
    this.disableReportIcon = true;
    this.OnClickAction(this.actionNameEnum.null, null, false);
    this.spinner.hide();
  }

  onConfirmAction() {
    // this.modalRef.hide();
    this.spinner.show();
    this.restService
      .deleteRolConfiguration(this.accessRoleId)
      .subscribe((data) => {
        if (data != null) {
          if (data.success && data.customResultId > 0) {
            this.messageTitle = 'Success';
            this.messageBody = data.customException;
            this.OnClickAction(this.actionNameEnum.null, null, false);
          } else if (data.customResultId <= 0) {
            this.SaveErrorMessage = data.customException;
          }
        }
        // setTimeout(() => {
        //   // this.messageTitle = '';
        //   // this.messageBody = '';
        //   // this.SaveErrorMessage = '';
        // }, 3000);
        this.spinner.hide();
      });
  }

  redirectToEditClick(role: RoleVM) {
    this.spinner.show();
    this.accessRoleId = 0;
    this.accessRoleId = role.R_ID;
    this.selectedRole = role;
    this.SaveSuccessMessage = role.Message;
    this.OnClickAction(this.actionNameEnum.Edit, null, false);
    this.spinner.hide();
  }

  onMessageClear() {
    this.SaveSuccessMessage = '';
    this.SaveErrorMessage = '';
    this.messageBody = '';
    this.messageTitle = '';
  }
  
  // obsolete_downloadResourcesForRole(roleId: number) {
  //   this.restService.fetchResourceAccessRoleList(roleId).subscribe((res) => {
  //     const data = new Blob([res], {
  //       type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  //     });
  //     const fileName =
  //       this.selectedRole.R_Name +
  //       '_ResourceList_' +
  //       this.datePipe.transform(new Date(), 'dd-MMM-yyyy_HH-mm-ss') +
  //       '.xlsx';
  //     importedSaveAs(data, fileName);
  //   });
  // }

  downloadResourcesForRole(roleId: number) {
    this.restService.fetchResourceAccessRoleList(roleId).subscribe((res) => {
      if(res.length == 0){
        alert('No resources has assigned role: '+this.selectedRole.R_Name);
        return;
      }
      const fileName =
      this.selectedRole.R_Name + '_ResourceList_' +
      new Date().toISOString() +
      '.xlsx';
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res);
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Resources');
      /* save to file */
      XLSX.writeFile(wb, fileName);
    });
  }

  public allData() {
    if (this.accessRoleId != 0) {
      this.downloadResourcesForRole(this.accessRoleId);
    }
  }

  onAuditClick(template) {
    this.openModal(template);
  }

  search() {
    this.showSearchLoader = true;
    this.showSearchList = true;
    const serachQuery = this.searchBar?.trim();
    if (serachQuery.length >= 2) {
      this.restService.getAccessRoleList(serachQuery).subscribe((data) => {
        this.showSearchLoader = false;
        this.accessRoleNameList = data;
      });
    } else if (this.accessRoleId > 0) {
      this.OnClickAction(this.actionNameEnum.null, true);
    } else {
      this.showSearchLoader = false;
      this.showSearchList = false;
      this.accessRoleNameList = [];
    }
  }

  OnClickAction(action, templateName, isLoadingRequired = false) {
    if (isLoadingRequired) this.spinner.show();
    if (action == this.actionNameEnum.Delete) {
      this.openModal(templateName);
    } else if (
      action == this.actionNameEnum.Add ||
      action == this.actionNameEnum.null
    ) {
      this.accessRoleId = 0;
      this.searchBar = '';
      this.selectedRole = null;
      this.actionSelected = this.actionNameEnum[action];
      this.disableReportIcon = true;
    } else {
      this.actionSelected = this.actionNameEnum[action];
    }

    if (isLoadingRequired) this.spinner.hide();
  }
  openModal(template) {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.modalRef = this.modalService.show(template, this.modalOption);
    this.modalRef.setClass('ea-modal');
  }

  onItemSelect(event) {
    this.spinner.show();
    this.searchBar = event.R_Name;
    this.showSearchList = false;
    this.showSearchLoader = false;
    this.accessRoleId = 0;
    this.accessRoleId = event.R_ID;
    this.accessRoleName = event.R_Name;
    this.selectedRole = event;
    this.disableReportIcon = false;
    this.OnClickAction(this.actionNameEnum.View, null, false);
    this.spinner.hide();
  }

  printData() {
    console.log('hello');
  }

  resetSearchBar() {
    if(this.isRALoggedIn) { return; }
    this.OnClickAction(this.actionNameEnum.null, null, false);
  }
}
