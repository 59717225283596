import { SafeUrl } from "@angular/platform-browser";

export class ResourceDto {

    ResourceId: number;
    GPN: string;
    ResourceName: string;
    RankDescription: string;
    LocationDescription: string;
    SLDescription: string;
    UpdatedOn: Date | null;
    UpdatedBy: string;
    ResourceEmail: string;
    isPrivacyNoticePage: boolean = false;
    LoggedInResourceID: number;
    IsDisabledAddBooking: boolean;
    SubAreaDescription: string;
    ManagerialCountryCode: string;
    LegalEntityCode: string;
    SSLDescription: string;
    SubManagementUnitDescription: string;
    BusinessUnitDescription: string;
    ResourceType: string;
    ResourceStatus: string;
    RoleId: number;
    RoleName: string;
    RoleTypeId: number;
    RoleTypeName: string;
    RoleTypeDisplayName: string;
    RequestedTime: string;
    DtCompletedTime: Date | null;
    CompletedTime: string;
    InstanceId: number;
    JobId: number | null;
    ResourcePhotoUrl: SafeUrl | null;
    ManagerialCountryDescription: string;
    CostCenterDescription: string;
    SearchedResMultiInstance: boolean;
}