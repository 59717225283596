import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
// import { Booking } from '../../Models/Booking';
import { JobVM } from '../../Models/JobVM';
import {
  EngagementSubmissionRecordVM,
  EngagementSubmissionVM,
} from '../../Models/EngagementSubmissionVM';
import { ResourceDto } from '../../Models/ResourceDto';
import { BookingGridActionService } from '../../Models/BookingGridActionService';

// import { GridDataResult } from '@progress/kendo-angular-grid';
// import { toODataString } from '@progress/kendo-data-query';
import { EngagementBookingVM } from '../../Models/EngagementBookingVM';
import { Observable, of, Subscription, BehaviorSubject } from 'rxjs';
// import 'rxjs/add/operator/share';
import { AuthService } from '../../shared/auth/auth.service';
import { ResourceBookingVM } from '../../Models/ResourceBookingVM';
import { SwapResourceVM } from '../../Models/SwapResourceVM';
import {PreviewCountVM} from '../../Models/PreviewCountVM'
import { CurrentWeekVM } from '../../Models/CurrentWeekVM';
import {
  ResourceUtilizationRequestVM,
  ResourceUtilizationVM,
} from '../../Models/EngagementWallChartVM';
import { IsDisableFlagVM } from '../../Models/IsDisableFlagVM';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { FavouriteJobsAddActionDto, FavouriteJobsDto } from 'app/Models/FavouriteJobsDto';

@Directive()
@Injectable({
  providedIn: 'root',
})
export class EngagementService extends BehaviorSubject<any> {
  isPercentageSelected: boolean = false;
  closeModalWindow: boolean = false;

  @Output() change: EventEmitter<boolean> = new EventEmitter();

  private eventData = new BookingGridActionService();
  private engagementSubmission = new EngagementSubmissionVM();
  private requestId = 0;
  private refreshChartData = 0;
  private jobId = 0;

  private editSource = new BehaviorSubject(this.eventData);
  editEvent = this.editSource.asObservable();

  private swapSource = new BehaviorSubject(this.eventData);
  swapEvent = this.swapSource.asObservable();

  private replaceallSource = new BehaviorSubject(this.eventData);
  replaceallEvent = this.replaceallSource.asObservable();

  private addSource = new BehaviorSubject(this.eventData);
  addEvent = this.addSource.asObservable();

  private splitSource = new BehaviorSubject(this.eventData);
  splitEvent = this.splitSource.asObservable();

  private deleteSource = new BehaviorSubject(this.eventData);
  deleteEvent = this.deleteSource.asObservable();

  private previewSource = new BehaviorSubject(this.engagementSubmission);
  previewEvent = this.previewSource.asObservable();

  private messageEngagementAllocation = new BehaviorSubject(
    this.isPercentageSelected
  );
  currentMessageEngagementAllocation =
    this.messageEngagementAllocation.asObservable();

  private messageCloseModal = new BehaviorSubject(this.closeModalWindow);
  currentMessageCloseModal = this.messageCloseModal.asObservable();

  private messageCloseChildModal = new BehaviorSubject(this.closeModalWindow);
  currentMessageCloseChildModal = this.messageCloseChildModal.asObservable();

  private setRequestId = new BehaviorSubject(this.requestId);
  setRequestIdEvent = this.setRequestId.asObservable();

  private refreshChart = new BehaviorSubject(this.refreshChartData);
  refreshChartEvent = this.refreshChart.asObservable();

  private setSelectedJobId = new BehaviorSubject(this.jobId);
  setSelectedJobIdEvent = this.setSelectedJobId.asObservable();

  private _defaultLoadingChange = new BehaviorSubject(null);
  defaultEngagementLoadingChange = this._defaultLoadingChange.asObservable();

  endpoint = environment.apiEndpoint;

  constructor(private http: HttpClient, private auth: AuthService,
    private router: Router,
    private spinner: NgxSpinnerService) {
    super(null);
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  private getAPI(actionName: string): string {
    this.auth.checkTokenExpiredAndRefresh();
    return this.endpoint + 'EngagementBooking/' + actionName;
  }
  
  getJobList(engagement: JobVM): Observable<any> {
    return this.http
      .post<JobVM[]>(this.getAPI('FetchJobs'), engagement, this.httpOptions)
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('getJobList'))
      );
  }
  invokeUpdateEngagementFilterBookingGrid = new EventEmitter();
  UpdateIndividualFilterBookingGridSubscription: Subscription;
  UpdateEngagementFilterBookingGrid() {
    this.invokeUpdateEngagementFilterBookingGrid.emit();
  }
  getEngagementBookingListForGrid(
    booking: EngagementBookingVM
  ): Observable<any> {
    return this.http
      .post<EngagementBookingVM[]>(
        this.getAPI('FetchEngagementBookingList'),
        booking,
        this.httpOptions
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('getEngagementBookingListForGrid'))
      );
  }

  getResourceList(resource: ResourceDto): Observable<any> {
    return this.http
      .post<ResourceDto[]>(
        this.getAPI('FetchResources'),
        resource,
        this.httpOptions
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('getResourceList'))
      );
  }
  
  private handleError<T>(result?: T) {
    // return (error: any): Observable<T> => {
    //   console.error(error); // log to console instead      
    //   return of(result as T);
    // };
    return (error: any): Observable<T> => {
      let status = '';
      let msg = '';
      if(Object.prototype.hasOwnProperty.call(error, 'status')) {
          status = error.status.toString();
      }
      if (typeof error.error === 'string') {
          msg = error.error;
      }
      else if (Object.prototype.hasOwnProperty.call(error.error, 'title')) {
          msg = error.error.title;
      }
      else if (Object.prototype.hasOwnProperty.call(error.error, 'customException')) {
          msg = error.error.customException;
          msg += `
          `+ JSON.stringify(error.error.customData);
      }
      if(status === '0' && (!msg || msg.trim().length === 0)) {
        msg = 'MERA Web API Service is down or inaccessible.'
      }

      let exceptionPrefix = '';
      if(status === '400') {
        exceptionPrefix = 'Unexpected error, please try later';
      }
    
      let alertMsg = `
      Response for MERA Web API Service - 
      Status Code: `+status+`
      Message: `+ exceptionPrefix + ` - 
      `+ msg;

      alert(alertMsg);
      //alert(JSON.stringify(error)); //todo: remove/comment on prod      
      console.error(alertMsg);
      console.error(JSON.stringify(error)); // log to console instead
      
      // if http response status is 401-Unauthorized
      if(status === '401') {
        this.router.navigate(['AccessError']);
      }
      else if(status === '0') {
        //MERA Web API Service is down or inaccessible.
        // todo: show diff page 
        //this.router.navigate(['AccessError']); 
      }
      
      this.spinner.hide();

      return of(result as T);
    };
  }

  engagementAllocationOnChange(isPercentageOptionSelected) {
    this.isPercentageSelected = isPercentageOptionSelected;
    this.messageEngagementAllocation.next(this.isPercentageSelected);
  }

  openEditModal(eventData: BookingGridActionService) {
    this.editSource.next(eventData);
  }
  openAddModal(eventData: BookingGridActionService) {
    this.addSource.next(eventData);
  }
  openReplaceAllEng(eventData: BookingGridActionService) {
    this.replaceallSource.next(eventData);
  }

  openSwapModel(eventData: BookingGridActionService) {
    this.swapSource.next(eventData);
  }

  openSplitModal(eventData: BookingGridActionService) {
    this.splitSource.next(eventData);
  }
  openDeleteModal(eventData: BookingGridActionService) {
    this.deleteSource.next(eventData);
  }
  openPreviewModal(engagementSubmission: EngagementSubmissionVM) {
    this.previewSource.next(engagementSubmission);
  }

  closeModal(exitModal) {
    this.messageCloseModal.next(exitModal);
  }

  closeChildModal(exitModal) {
    this.messageCloseChildModal.next(exitModal);
  }

  setRquestNumber(requestId) {
    this.setRequestId.next(requestId);
  }

  refreshWallChart(refresh) {
    this.refreshChart.next(refresh);
  }

  setSelectedJob(jobId) {
    this.setSelectedJobId.next(jobId);
  }

  setDefaultLoading() {
    this._defaultLoadingChange.next(null);
  }

  fetchBookingChildData(booking: EngagementBookingVM): Observable<any> {
    return this.http
      .post<EngagementBookingVM[]>(
        this.getAPI('FetchBookingListChild'),
        booking,
        this.httpOptions
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('fetchBookingChildData'))
      );
  }

  validateHours(booking: EngagementBookingVM): Observable<any> {
    return this.http
      .post<any[]>(this.getAPI('ValidateHours'), booking, {
        headers: this.httpOptions.headers,
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('validateHours'))
      );
  }

  addBooking(booking: any): Observable<any> {
    return this.http
      .post<ResourceBookingVM[]>(this.getAPI('AddBooking'), booking, {
        headers: this.httpOptions.headers,
        observe: 'response',
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('addBooking'))
      );
  }

  editBooking(booking: EngagementBookingVM): Observable<any> {
    return this.http
      .post<EngagementBookingVM[]>(this.getAPI('EditBooking'), booking, {
        headers: this.httpOptions.headers,
        observe: 'response',
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('editBooking'))
      );
  }

  deleteBooking(booking: EngagementBookingVM): Observable<any> {
    return this.http
      .post<EngagementBookingVM[]>(this.getAPI('RemoveBooking'), booking, {
        headers: this.httpOptions.headers,
        observe: 'response',
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('deleteBooking'))
      );
  }

  // cancelBooking(booking: EngagementBookingVM): Observable<any> {
  //   return this.http
  //     .post<EngagementBookingVM[]>(this.getAPI('CancelRequest'), booking, {
  //       headers: this.httpOptions.headers,
  //       observe: 'response',
  //     })
  //     .pipe(
  //       map((data) => data),
  //       catchError(this.handleError<any>('CancelRequest'))
  //     );
  // }

  CancelBookingAction(cancelBookingDto: any): Observable<any> {
    return this.http.post<any>(this.getAPI('CancelRequest'), cancelBookingDto,
      { headers: this.httpOptions.headers, observe: 'response' }).pipe(
        map(data => data),
        catchError(this.handleError<any>('CancelRequest'))
      );
  }

  CancelMeraBookingsChild(inputDto: any): Observable<any> {
    return this.http.post<any>(this.getAPI('CancelMeraBookingsChild'), inputDto,
      { headers: this.httpOptions.headers, observe: 'response' }).pipe(
        map(data => data),
        catchError(this.handleError<any>('CancelMeraBookingsChild'))
      );
  }

  splitBooking(booking: any): Observable<any> {
    return this.http
      .post<EngagementBookingVM[]>(this.getAPI('SplitBooking'), booking, {
        headers: this.httpOptions.headers,
        observe: 'response',
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('splitBooking'))
      );
  }

  getEngagementSubmissionList(): Observable<any> {
    return this.http
      .get<EngagementSubmissionVM[]>(
        this.getAPI('FetchEngagementSubmissions'),
        this.httpOptions
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('getEngagementSubmissionList'))
      );
  }

  getByteSize(obj) {
    if (!obj) return 0;
    let jObj = JSON.stringify(obj);
    var bytes = [];
    for (var j = 0; j < jObj.length; ++j) {
      bytes.push(jObj.charCodeAt(j));
    }
    return bytes.length;
  }

  updateEngagementSubmissionList(replaceAllBookings: any): Observable<any> {
    // let reqHeaders = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Content-Length': "'"+this.getByteSize(engagementSubmission)+"'",
    // });
    return this.http
      .post<any>(
        this.getAPI('UpdateEngagementSubmissionList'),
        replaceAllBookings,
        this.httpOptions
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('updateEngagementSubmissionList'))
      );
  }

  getBookingListForWallchart(booking: EngagementBookingVM): Observable<any> {
    return this.http
      .post<EngagementSubmissionVM[]>(
        this.getAPI('FetchBookingListWallchart'),
        booking,
        this.httpOptions
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('getBookingListForWallchart'))
      );
  }

  GetResourceUtilization(input: ResourceUtilizationRequestVM): Observable<any> {
    return this.http
      .post<ResourceUtilizationVM[]>(
        this.getAPI('FetchResourceUtilization'),
        input,
        this.httpOptions
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('GetResourceUtilization'))
      );
  }

  swapBooking(booking: EngagementBookingVM): Observable<any> {
    return this.http
      .post<EngagementBookingVM[]>(this.getAPI('EditBooking'), booking, {
        headers: this.httpOptions.headers,
        observe: 'response',
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('editBooking'))
      );
  }

  validateReplaceHours(booking: SwapResourceVM[]): Observable<any> {
    return this.http
      .post<any>(this.getAPI('ValidateReplaceHours'), booking, {
        headers: this.httpOptions.headers,
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('validateReplaceHours'))
      );
  }

  ReplaceBooking(booking: EngagementBookingVM[]): Observable<any> {
    return this.http
      .post<EngagementBookingVM[]>(this.getAPI('ReplaceBooking'), booking, {
        headers: this.httpOptions.headers,
        observe: 'response',
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('editBooking'))
      );
  }

  getPreviewCountList(): Observable<any> {
    return this.http
      .get<number>(this.getAPI('FetchPreviewCount'), this.httpOptions)
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('getPreviewCountList'))
      );
  }

  getEngagementSearchResult(category: JobVM): Observable<any> 
  {
      return this.http
      .post<JobVM[]>(this.getAPI('FetchEngagement'), category, {
        headers: this.httpOptions.headers,
        observe: 'response',
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('getEngagementSearchResult'))
      );
  }

  replaceAllEngagement(booking: EngagementBookingVM): Observable<any> {
    return this.http
      .post<EngagementBookingVM[]>(
        this.getAPI('ReplaceAllEngagement'),
        booking,
        { headers: this.httpOptions.headers, observe: 'response' }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('ReplaceAllEngagement'))
      );
  }

  // getCurrentWeek(): Observable<any> {
  //   return this.http
  //     .get<CurrentWeekVM>(this.getAPI('FetchCurrentWeek'), this.httpOptions)
  //     .pipe(
  //       map((data) => data),
  //       catchError(this.handleError<any>('FetchCurrentWeek'))
  //     );
  // }

  getIsDisableFlags(flag: IsDisableFlagVM): Observable<any> {
    return this.http
      .post<IsDisableFlagVM>(
        this.getAPI('FetchIsDisableFlags'),
        flag,
        this.httpOptions
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('FetchIsDisableFlags'))
      );
  }

 // for Favorite Engagement --- engagement service START

 addFavouriteJobs(favouriteJobs:FavouriteJobsAddActionDto): Observable<any>
  {
    return this.http.post<any[]>(this.getAPI('AddFavouriteJobs'), favouriteJobs,
      { headers: this.httpOptions.headers, observe: 'response' }).pipe(
        map(data => data),
        catchError(this.handleError<any>('addFavouriteJobs'))
      );
  }

  GetFavouriteJobsEngagementView(favouriteJobs): Observable<any> 
  {
    return this.http.post<FavouriteJobsDto[]>(this.getAPI('GetFavouriteJobsEngagementView'), favouriteJobs , 
    this.httpOptions
    ).pipe(
      map(data => data),
      catchError(this.handleError<any>('GetFavouriteJobsEngagementView'))
    );  
  }

    // for Favorite Engagement --- END
}
