import { MasterVM } from "./MasterVM";

export class ResourceAddConfigurationDto {
       ResAddSubAreas :MasterVM[];
       ResAddManagerialCountries :MasterVM[];
       ResAddLegalEntities:MasterVM[];
       ResAddServiceLines :MasterVM[];
       ResAddBusinessUnits:MasterVM[];
       ResAddSubServiceLines :MasterVM[];
       ResAddSubManageUnits :MasterVM[]
       ResAddRanks :MasterVM[];
       RRAC_RInactive:boolean;
       RRAC_ISDisabled :boolean;
       RRAC_ISLikeEdit :boolean;
       RRAC_IV_Disabled :boolean;
       RRAC_EV_Disabled :boolean;

}

export class JobAddConfigurationDto {
       JobAddSubAreas :MasterVM[];
       JobAddManagerialCountries :MasterVM[];
       JobAddLegalEntities:MasterVM[];
       JobAddServiceLines :MasterVM[];
       JobAddBusinessUnits:MasterVM[];
       JobAddSubServiceLines :MasterVM[];
       JobAddSubManageUnits :MasterVM[]
       JobAddRanks :MasterVM[];
       JobAddJobCategories:MasterVM[];
       JobAddJobStatuses :MasterVM[];
       JobAddJobStatusBookings :MasterVM[]
       JobAddJobCode :MasterVM[];
       JobAddIncludeJobCode
       RJAC_Learning :boolean;
       RJAC_ISDisabled :boolean;
       RJAC_ISLikeEdit:boolean;
       RJAC_IsPended:boolean;
       RJAC_IV_Disabled:boolean;
       RJAC_EV_Disabled:boolean;

}
