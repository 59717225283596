import { MasterVM } from "./MasterVM";

export class ResourceViewConfigurationDto {
    ResViewSubAreas: MasterVM[];
    ResViewManagerialCountries: MasterVM[];
    ResViewLegalEntities: MasterVM[];
    ResViewServiceLines: MasterVM[];
    ResViewBusinessUnits: MasterVM[];
    ResViewSubServiceLines: MasterVM[];
    ResViewSubManageUnits: MasterVM[]
    ResViewRanks: MasterVM[];
    RRVC_RInactive:boolean;
}

export class JobViewConfigurationDto {
    JobViewSubAreas: MasterVM[];
    JobViewManagerialCountries: MasterVM[];
    JobViewLegalEntities: MasterVM[];
    JobViewServiceLines: MasterVM[];
    JobViewBusinessUnits: MasterVM[];
    JobViewSubServiceLines: MasterVM[];
    JobViewSubManageUnits: MasterVM[]
    JobViewRanks: MasterVM[];
    JobViewJobCategories:MasterVM[];
    JobViewJobStatuses :MasterVM[];
    JobViewjobStatusBookings :MasterVM[]
    JobViewJobCode :MasterVM[];
    JobViewIncludeJobCode :MasterVM[];
    RJVC_Learning :boolean;
    RJVC_IsPended :boolean;
}