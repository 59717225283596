import {
  Component,
  ViewEncapsulation,
  ElementRef,
  Input,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'notes-dialog',
  templateUrl: './notes-dialog.component.html',
  styleUrls: ['./notes-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotesDialog implements OnInit {
  @Input() isNotes: boolean = false;
  @Input() noteAvailableFor: any;

  @Output() onDialogClose = new EventEmitter<any | boolean>();

  private element: any;
  date: any;
  extendData: string;
  minDate = new Date();
  constructor(private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isNotes && changes.isNotes.currentValue === true)
      this.extendData = 'extend_upto'; // To pre-select Extend upto radio button by default.
  }

  getNotesPosition() {
    this.minDate = new Date(this.noteAvailableFor?.cellData.WeekStartDate);
    this.minDate.setMinutes(this.minDate.getMinutes() - this.minDate.getTimezoneOffset());
    const notes = document.getElementById('notesWrapper');
    let styles = {
      left: this.noteAvailableFor.position.x - notes.clientWidth - 20 + 'px',
      top: this.noteAvailableFor.position.y - 55 + 'px',
    };
    return styles;
  }

  notesCancel() {
    this.onDialogClose.emit(false);
  }

  notesSubmit() {
    const params = {
      date:
        this.date === undefined
          ? new FormControl(
              new Date(this.noteAvailableFor.cellData.WeekStartDate)
            )
          : this.date,
      radioSelection: this.extendData,
      notes: this.noteAvailableFor.cellData.Description,
    };
    this.onDialogClose.emit(params);
  }

  clearDate() {
    this.date = '';
  }

  updateDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = new FormControl(new Date(event.value));
    this.extendData = 'extend_upto';
  }

  updateRadioSelection(event) {
    // console.log(event);
  }

  checkIfNewBooking() {
    return this.noteAvailableFor.cellData.CellAction === 'Create'
      ? false
      : true;
  }
}
