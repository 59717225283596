export class CompletedRequestResultVM {
    CompletedRequestFilterVM: CompletedRequestFilterDto;
    CompletedRequestList: CompletedRequestVM[];
  }
  
  export class CompletedRequestFilterDto {
    SaveFilters: Boolean = false;
    StartDate: string;
    EndDate: string;
    BookingStatus: String;
    ManagerialCountry: Number[] = [];
    ServiceLine: Number[] = [];
    SubServiceLine: Number[] = [];
    SubManagementUnit: Number[] = [];
    SelectedInstanceId: Number;
  }
  
  export class CompletedRequestVM {
      RequestNumber: string;
      ActionId: number ;
      ActionType: string;
      ResourceName: string;
      ResourceId: Number;
      Rank: string;
      ServiceLine: string;
      SubServiceLine: string;
      Engagement: string;
      Description: string;
      PercentLoading: number;
      Hours: number;
      StartDate: string;
      EndDate: string;
      Unconfirmed: string;
      Status: string;
      RequestedTime: string;
      CompletedTime: string;
      HoursCompletedIn: number;
      UpdatedBy:string;
      OfficeLocation : string;
      RequestorName : string;
      EngagementCategory: string;  
      SubAreaDescription: string;
      SubManagementUnit: string;
      Department: string;
      CostCenter: string;
      RequestMode: number;
      RequestModeDisplay: string;
      InstanceName: string;
      ApprovedOrRejectedBy:string;
      ApprovedOrRejectedOn : string;
      Ghost : string;
      startDate_sort:string;
      endDate_sort:string;
      RequestedTime_sort: string;
      CompletedTime_sort: string;
      ApprovedOrRejectedOn_sort : string;
      ApproverComment: string
  }
  
  export class CompletedRequestXLVM {
    RequestNumber: string;
    ActionId: number;
    ActionType: string;
    ResourceName: string;
    Rank: string;
    ServiceLine: string;
    SubServiceLine: string;
    Engagement: string;
    PercentLoading: number;
    Hours: number;
    StartDate: string;
    EndDate: string;
    Unconfirmed: string;
    Status: string;
    RequestedTime: string;
    DtCompletedTime:  Date | null;
    CompletedTime:  string;
    HoursCompletedIn: number;
    UpdatedBy: string;
    OfficeLocation: string;
    RequestorName: string;
    EngagementCategory: string;
    Description: string;
    BookingId: string;
    RejectionReason: string;
    ApprovedOrRejectedBy:string;
    ApprovedOrRejectedOn :Date | null;
  }
  