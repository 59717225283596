<ngx-spinner></ngx-spinner>
<!-- <div *ngFor="let alert of alerts" class="pl-0 pr-0">
  <alert [type]="alert.type" [dismissible]="dismissible" [dismissOnTimeout]="alert.timeout" (onClosed)="onClosed(alert)"
    class="alertpop">{{ alert.msg }}</alert>
</div> -->

<div class="appLayout individualBooking">
  <div class="engagementScheduleSearchWrapper collapsibleWrapper">
    <section class="searchContainerHeading" (click)="toggleCollapse()">
      <span class="icon" [ngClass]="isCollapsed ? 'collapsed' : ''"></span>
      <h2 class="title">
        <label>{{ searchLabel }}</label>
        <span class="has-content">{{
          gridSelectedUser ? gridSelectedUser : user.ResourceName
        }}</span>
      </h2>
    </section>
    <div class="searchContainer" *ngIf="!isCollapsed">
      <div class="searchFields">
        <div *ngIf="user.IsMultiInstance && user.R_InstEnable_IV" class="instanceContainer searchItem">
          <label>Retain Instance</label>
          <mat-form-field appearance="outline">
            <mat-select [(value)]="selectedInstance" (selectionChange)="onInstanceChange($event)"
              placeholder="Retain instance">
              <mat-option [value]="option.InstanceId" *ngFor="let option of instances">{{ option.InstanceName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="searchBox searchItem">
          <label>Resource</label>
          <mat-form-field class="resourceSearch" appearance="outline">
            <input type="text" autocomplete="off" placeholder="Search..." matInput appSearchDebounce
              (debounceKeyup)="search()" [(ngModel)]="searchBar" maxlength="75" />
            <mat-icon (click)="resetfilterBookingGridSearchBar()">close</mat-icon>
          </mat-form-field>
          <div class="dropdown-list-wrap" *ngIf="showSearchList">
            <mat-spinner *ngIf="showSearchLoader" [diameter]="40"></mat-spinner>
            <ul *ngIf="results?.length > 0" class="dropdown-list tabdropdwn">
              <li *ngFor="let item of results" (click)="OnItemSelect(item)" (keyup.enter)="OnItemSelect(item)"
                class="dropdown-list-item" tabindex="0">
                {{ item.ResourceName }}
              </li>
            </ul>
            <div *ngIf="showSearchLoader === false && results.length === 0">
              No Resource Found
            </div>
          </div>
        </div>
        <div class="dateSearch searchItem">
          <label>Period</label>
          <form [formGroup]="range">
            <mat-form-field appearance="outline">
              <mat-date-range-input [rangePicker]="picker" [min]="startWith">
                <input matStartDate formControlName="start" placeholder="Select Start Date" (click)="picker.open()"
                  (dateChange)="onBookingStartDateChange($event)" readonly />-
                <input matEndDate formControlName="end" placeholder="End Date" (click)="picker.open()"
                  (dateChange)="onBookingEndDateChange($event)" readonly />
              </mat-date-range-input>
              <mat-icon (click)="resetfilterBookingGridCalender($event)">close</mat-icon>
              <!-- <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle> -->
              <mat-date-range-picker (closed)="OnClosedDatePicker()" #picker></mat-date-range-picker>
              <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
              <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
            </mat-form-field>
            <div *ngIf="
                range.get('start').value != null &&
                (range.get('start').dirty || range.get('start').touched) &&
                range.get('end').value === null &&
                  (range.get('end').dirty || range.get('end').touched) &&
                isPeriodErrorMsg
              " class="alert alert-danger">
              End date is required
            </div>
          </form>
        </div>
        <div class="expansionPreference searchItem">
          <mat-radio-group aria-label="preferenceOption" class="expansionRadio" [(ngModel)]="engagementAllocationModal"
            (change)="engagementAllocationOnChange($event)">
            <mat-radio-button value="hour" class="prefernceRadio">Hour</mat-radio-button>
            <mat-radio-button value="percentage" class="prefernceRadio">Percentage</mat-radio-button>
          </mat-radio-group>
        </div>
        <!-- <div class="serchButtonDiv">
        <div class="searchButton" (click)="filterBookingGrid()">Search</div>
      </div> -->
      </div>
      <div class="resourceField">
        <div class="resourceContaier">
          <div class="resourceTitle">Individual Forecast
            <span class="booking_heading_info_icon" (click)="openModal(individualForecastInfo, $event)" matTooltip="Info">
              <img src="../../../../{{ imagePath }}TableIcons/info-yellow.svg" alt="Info" />
            </span>
          </div>
          <div class="resourceDetails">
            <!-- [ngClass]="{'noLink' : user?.RoleType === 'Client Server' && !isMobileDevice}" -->
            <div class="resourceImg" *ngIf="selectedResource.ResourcePhotoUrl">
              <img *ngIf="selectedResource.ResourcePhotoUrl" [src]="selectedResource.ResourcePhotoUrl" />
            </div>
            <div class="resourceInfo">
              <div class="name">{{ gridSelectedUser }}</div>
              <div class="rank">{{ selectedResource.RankDescription }}</div>
              <div class="serviceLine">
                {{ selectedResource.SLDescription }}
                {{ selectedResource.ManagerialCountryDescription ? "-" : "" }}
                {{ selectedResource.ManagerialCountryDescription }}
              </div>
              <div class="officeLocation">
                {{ selectedResource.LocationDescription }}
              </div>
              <div class="costCenter">
                {{ selectedResource.CostCenterDescription }}
              </div>

              <!-- <div class="area">{{ selectedResource.SubAreaDescription }}</div> -->
            </div>
          </div>
        </div>
        <!-- *ngIf="user?.RoleType === 'Client Server' && !isMobileDevice" -->
        <!-- <div *ngIf="user?.RoleType === 'Client Server' && !isMobileDevice && cspReports.length > 0">
          <div class="linkContainer">
            <div class="linkItem" *ngFor="let element of cspReports" (click)="navigateToCspReport(element.FeatureName)">
              {{element.FeatureDisplayName}}
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
  <div class="bookingContainer">
    <div class="bookingHeader">
      <div class="heading sectionHeading">
        <span>Bookings</span>
        <!-- <mat-icon matTooltip="Info" class="questionIcon" (click)="openModal(template1)">info</mat-icon> -->
        <span class="booking_heading_info_icon" (click)="openModal(template1, $event)" matTooltip="Info">
          <img src="../../../../{{ imagePath }}TableIcons/info-yellow.svg" alt="Info" />
        </span>
      </div>

      <div class="bookingNew">
        <!-- <div class="linkContainer" *ngIf="user?.RoleType === 'Client Server' && !isMobileDevice">
          <div class="linkItem" (click)="navigateAvailabiltyReport()">Availability Report</div>
          <div class="linkItem" (click)="navigateBookedReport()">Booked Time Report</div>
        </div> -->
        <!-- <div *ngIf="isNavFromApprovalQueue && !isMobileDevice" class="navApprovalQueue"  matTooltip="Back to Approval Queue">
          <mat-icon class="arrowBack">arrow_back</mat-icon> -->
        <a *ngIf="isNavFromApprovalQueue && !isMobileDevice" class="approvalQueueBtn"
          matTooltip="Back to Approval Queue" routerLink="/ApprovalLayer">
          <mat-icon class="arrowBack">arrow_back</mat-icon><span class="linkText">Approval Queue</span>
        </a>
        <!-- </div> -->
        <div class="newBooking" *ngIf="resourceSelected && !selectedResource.IsDisabledAddBooking"
          (click)="openActionModal(GridActions, $event, null, 'add')" matTooltip="Add">
          New Booking
        </div>
      </div>
    </div>
    <div class="materialTable">
      <table mat-table [dataSource]="bookingList" multiTemplateDataRows
        class="mat-elevation-z8 bookingTable individualBookingTable">
        <ng-container matColumnDef="child">
          <th mat-header-cell *matHeaderCellDef class="mat-column-child"></th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.ChildRowsExist">
              <mat-icon class="expandTableIcon" *ngIf="!element.isExpandable"
                (click)="element.isExpandable = !element.isExpandable" matTooltip="Expand details">add</mat-icon>

              <mat-icon class="expandTableIcon" *ngIf="element.isExpandable"
                (click)="element.isExpandable = !element.isExpandable" matTooltip="Collapse details">remove</mat-icon>
            </span>
            <!-- <mat-icon class="expandTableIcon" style="color: #B9251C;" *ngIf="element.ActionType === 'New Booking' && currentUserEmail?.toLowerCase() === element.RequestorEmail?.toLowerCase()"
             (click)="openActionModal(GridActions, $event, element, 'delete')" matTooltip="Cancel"
             >close</mat-icon> -->
          </td>
        </ng-container>

        <ng-container matColumnDef="BookingType">
          <th mat-header-cell *matHeaderCellDef class="mat-column-BookingType">
            Engagement Type
          </th>
          <td mat-cell *matCellDef="let element" data-title="Booking Type :" (mouseover)="selectItem(element)"
            (click)="selectItem(element)" [popover]="element.TooltipData.ActionType ? tooltipTemplate : null"
            [placement]="'right'" [outsideClick]="true" [triggers]="'mouseover click'" #pop="bs-popover"
            (onShown)="closePrevious(pop)" (mouseleave)="closeCurrent(pop)">
            <!-- {{ element.BookingType }} -->
            {{ element.EngagementType }}
          </td>
        </ng-container>

        <ng-container matColumnDef="Engagement">
          <th mat-header-cell *matHeaderCellDef class="mat-column-Engagement">
            Engagement
          </th>
          <td mat-cell *matCellDef="let element" data-title="Engagement :" (mouseover)="selectItem(element)"
            (click)="selectItem(element)" [popover]="element.TooltipData.ActionType ? tooltipTemplate : null"
            [placement]="'right'" [outsideClick]="true" [triggers]="'mouseover click'" #pop="bs-popover"
            (onShown)="closePrevious(pop)" (mouseleave)="closeCurrent(pop)">
            <div class="navigationlink" [ngClass]="{
                disableNavigation:
                  element.IsDisabledEVNavigation === true ||
                  element.IsDisabledViewBooking_Job === true
              }" (click)="navigateEngagement(element)" [matTooltip]="
                element.IsDisabledEVNavigation === false &&
                element.IsDisabledViewBooking_Job === false
                  ? 'Click here to see engagement details'
                  : null
              ">
              {{ element.Engagement }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="Instance">
          <th mat-header-cell *matHeaderCellDef class="mat-column-Instance">
            Retain Instance
          </th>
          <td mat-cell *matCellDef="let element" data-title="Retain Instance :" (mouseover)="selectItem(element)"
            (click)="selectItem(element)" [popover]="element.TooltipData.ActionType ? tooltipTemplate : null"
            [placement]="'right'" [outsideClick]="true" [triggers]="'mouseover click'" #pop="bs-popover"
            (onShown)="closePrevious(pop)" (mouseleave)="closeCurrent(pop)">
            {{ element.InstanceName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="Hours">
          <th mat-header-cell *matHeaderCellDef class="mat-column-Hours">
            Hours
          </th>
          <td mat-cell *matCellDef="let element" data-title="Hours :" (mouseover)="selectItem(element)"
            (click)="selectItem(element)" [popover]="element.TooltipData.ActionType ? tooltipTemplate : null"
            [placement]="'right'" [outsideClick]="true" [triggers]="'mouseover click'" #pop="bs-popover"
            (onShown)="closePrevious(pop)" (mouseleave)="closeCurrent(pop)">
            {{ element.Hours }}
          </td>
        </ng-container>

        <ng-container matColumnDef="Loading">
          <th mat-header-cell *matHeaderCellDef class="mat-column-Loading">
            Loading %
          </th>
          <td mat-cell *matCellDef="let element" data-title="Loading % :" (mouseover)="selectItem(element)"
            (click)="selectItem(element)" [popover]="element.TooltipData.ActionType ? tooltipTemplate : null"
            [placement]="'right'" [outsideClick]="true" [triggers]="'mouseover click'" #pop="bs-popover"
            (onShown)="closePrevious(pop)" (mouseleave)="closeCurrent(pop)">
            {{ element.Loading }} %
          </td>
        </ng-container>

        <ng-container matColumnDef="StartDate">
          <th mat-header-cell *matHeaderCellDef class="mat-column-StartDate">
            Start Date
          </th>
          <td mat-cell *matCellDef="let element" data-title="Start Date :" (mouseover)="selectItem(element)"
            (click)="selectItem(element)" [popover]="element.TooltipData.ActionType ? tooltipTemplate : null"
            [placement]="'right'" [outsideClick]="true" [triggers]="'mouseover click'" #pop="bs-popover"
            (onShown)="closePrevious(pop)" (mouseleave)="closeCurrent(pop)">
            {{ element.StartDate | date: defaultDateFormat }}
          </td>
        </ng-container>

        <ng-container matColumnDef="EndDate">
          <th mat-header-cell *matHeaderCellDef class="mat-column-EndDate">
            End Date
          </th>
          <td mat-cell *matCellDef="let element" data-title="End Date :" (mouseover)="selectItem(element)"
            (click)="selectItem(element)" [popover]="element.TooltipData.ActionType ? tooltipTemplate : null"
            [placement]="'right'" [outsideClick]="true" [triggers]="'mouseover click'" #pop="bs-popover"
            (onShown)="closePrevious(pop)" (mouseleave)="closeCurrent(pop)">
            {{ element.EndDate | date: defaultDateFormat }}
          </td>
        </ng-container>

        <!-- <ng-container matColumnDef="Ghost" *ngIf="user.Ghost">
          <th mat-header-cell *matHeaderCellDef class="mat-column-Ghost">
            Ghost
          </th>
          <td mat-cell *matCellDef="let element" data-title="Ghost :" (mouseover)="selectItem(element)"
          (click)="selectItem(element)"
          [popover]="element.TooltipData.ActionType ? tooltipTemplate : null " 
          [placement]="'right'"
          [outsideClick]="true"
          [triggers]="'mouseover click'"                 
          #pop="bs-popover"
          (onShown)="closePrevious(pop)"   
          (mouseleave)="closeCurrent(pop)">
            {{ 
              element.ChildRowsExist ? '' :
              (element.Ghost ? "Yes" : "No")
            }}
          </td>
        </ng-container> -->

        <ng-container matColumnDef="Actions">
          <th mat-header-cell *matHeaderCellDef class="mat-column-Actions">
            Actions
          </th>
          <td mat-cell *matCellDef="let element" class="actionColumn">
            <!-- [ngClass]="{'disabledAction' : element.IsDisabledEditBooking}" -->
            <!-- <mat-icon class="tableIcon" 
             *ngIf="!element.ChildRowsExist &&(!element.BookingId==0) && !element.IsDisabledEditBooking"
              (click)="openActionModal(GridActions, $event, element, 'edit')"
              matTooltip="Edit">edit</mat-icon>
              
            <mat-icon class="tableIcon" [ngClass]="{'disabledAction' : element.IsDisabledEditBooking}" 
            *ngIf="element.ChildRowsExist && !element.IsDisabledEditBooking"
             (click)="openActionModal(GridActions, $event, element, 'parentEdit')"
             matTooltip="Edit">edit</mat-icon> -->

            <!-- <mat-icon
              class="tableIcon"
              *ngIf="!element.BookingId == 0 && !element.IsDisabledEditBooking"
              (click)="
                !element.ChildRowsExist
                  ? openActionModal(GridActions, $event, element, 'edit')
                  : openActionModal(GridActions, $event, element, 'parentEdit')
              "
              matTooltip="Edit"
              >edit</mat-icon
            > -->

            <span class="tableActionIcon edit" *ngIf="element.BookingId != 0 && !element.IsDisabledEditBooking" (click)="
                !element.ChildRowsExist
                  ? openActionModal(GridActions, $event, element, 'edit')
                  : openActionModal(GridActions, $event, element, 'parentEdit')
              " matTooltip="Edit">
            </span>

            <!-- <mat-icon
              class="tableIcon"
              *ngIf="
                !element.BookingId == 0 && !element.IsDisabledReplaceBooking
              "
              (click)="openActionModal(GridActions, $event, element, 'swap')"
              matTooltip="Replace"
              >find_replace
            </mat-icon> -->
            <span *ngIf="
                element.BookingId != 0 && !element.IsDisabledReplaceBooking
              " class="tableActionIcon replace" matTooltip="Replace"
              (click)="openActionModal(GridActions, $event, element, 'swap')">
            </span>

            <!-- <mat-icon
              class="tableIcon"
              *ngIf="
                !element.ChildRowsExist &&
                !element.BookingId == 0 &&
                !element.IsDisabledCopyBooking
              "
              (click)="openActionModal(GridActions, $event, element, 'split')"
              matTooltip="Copy"
              >content_copy</mat-icon
            > -->
            <span *ngIf="
                !element.ChildRowsExist &&
                element.BookingId != 0 &&
                !element.IsDisabledCopyBooking
              " class="tableActionIcon copy" matTooltip="Copy"
              (click)="openActionModal(GridActions, $event, element, 'split')">
            </span>

            <!-- <mat-icon class="tableIcon" [ngClass]="{'disabledAction' : element.IsDisabledDeleteBooking}"
             *ngIf="!element.ChildRowsExist &&(!element.BookingId==0) && editDelete === 0"
              (click)="openActionModal(GridActions, $event, element, 'delete')"
              matTooltip="Delete">delete</mat-icon>
              
            <mat-icon class="tableIcon" [ngClass]="{'disabledAction' : element.IsDisabledDeleteBooking}"
             *ngIf="element.ChildRowsExist && editDelete === 0" 
             (click)="openActionModal(GridActions, $event, element, 'deleteparent')"
             matTooltip="Delete">delete</mat-icon> -->
            <!-- <mat-icon
              class="tableIcon"
              *ngIf="
                !element.BookingId == 0 && !element.IsDisabledDeleteBooking
              "
              (click)="
                !element.ChildRowsExist
                  ? openActionModal(GridActions, $event, element, 'delete')
                  : openActionModal(
                      GridActions,
                      $event,
                      element,
                      'deleteparent'
                    )
              "
              matTooltip="Delete"
              >delete</mat-icon
            > -->
            <span *ngIf="element.BookingId != 0 && !element.IsDisabledDeleteBooking" (click)="
                !element.ChildRowsExist
                  ? openActionModal(GridActions, $event, element, 'delete')
                  : openActionModal(
                      GridActions,
                      $event,
                      element,
                      'deleteparent'
                    )
              " matTooltip="Delete" class="tableActionIcon delete">
            </span>
            <mat-icon class="tableIcon" style="color: #b9251c" 
              *ngIf="element.Cancelable && (!element.ChildRowsExist || !element.HasRetainBookingChild)" 
              (click)="openActionModal(GridActions, $event, element, 'cancelAction')" 
              matTooltip="Cancel">
              close
            </mat-icon>
            <span *ngIf="
                element.BookingId > 0 &&
                element.ChildRowsExist &&
                element.IsDisabledEditBooking &&
                element.IsDisabledDeleteBooking &&
                element.IsDisabledReplaceBooking &&
                element.IsDisabledCopyBooking &&
                element.HasRetainBookingChild &&
                (!element.JobCode ||
                element.JobCode.indexOf('TR') <= -1 )
              " class="booking_heading_info_icon error"
              matTooltip="Submissions are not supported on this booking.&#013;For any other updates, please follow the country specific process.">
              <mat-icon style="color: #b9251c; font-size: 16px">error</mat-icon>
            </span>

            <span *ngIf="
                !element.ChildRowsExist &&
                element.BookingId != 0 &&
                element.IsCurrentEditWeek == false &&
                (element.JobCode == null ||
                  element.JobCode.indexOf('TR') <= -1) &&
                element.IsDisabledEditBooking &&
                element.IsDisabledDeleteBooking &&
                element.IsDisabledReplaceBooking &&
                element.IsDisabledCopyBooking &&
                !element.IsReplaced &&
                element.HasRetainBookingChild
              " class="booking_heading_info_icon error"
              matTooltip="Submissions are not supported on this booking.&#013;For any other updates, please follow the country specific process.">
              <mat-icon style="color: #b9251c; font-size: 16px">error</mat-icon>
            </span>

            <!-- !element.ChildRowsExist && -->
            <span *ngIf="
                element.BookingId != 0 &&
                element.IsCurrentEditWeek == false &&
                element.JobCode != null &&
                element.JobCode.indexOf('TR') > -1 &&
                element.IsDisabledEditBooking &&
                element.IsDisabledDeleteBooking &&
                element.IsDisabledCopyBooking &&
                element.IsDisabledReplaceBooking &&
                !element.IsReplaced &&
                element.HasRetainBookingChild
              " class="booking_heading_info_icon error"
              matTooltip="Please refer to Success factors for updates to any course bookings">
              <mat-icon style="color: #b9251c; font-size: 16px">error</mat-icon>
            </span>

            <span *ngIf="
                !element.ChildRowsExist &&
                element.BookingId != 0 &&
                element.IsCurrentEditWeek == true &&
                element.IsDisabledEditBooking &&
                element.IsDisabledDeleteBooking &&
                element.IsDisabledReplaceBooking &&
                element.IsDisabledCopyBooking &&
                !element.IsReplaced &&
                (!element.JobCode ||
                element.JobCode.indexOf('TR') <= -1 )
              "
              matTooltip="Please contact your Resource or Experience Management team &#013;to make any changes in the current week">
              <mat-icon style="color:#B9251C;font-size: 16px;">error</mat-icon>
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div class="child-element-detail" [@detailExpand]="element.isExpandable ? 'expanded' : 'collapsed'">
              <app-individualbookingchild (updatePreviewCount)="getPreviewCountList()"
                [isPercentageSelected]="isPercentageSelected" [bookingData]="element"
                [SearchedResMultiInstance]="selectedResource.SearchedResMultiInstance"
                [defaultDateFormat]="defaultDateFormat" *ngIf="element.ChildRowsExist && element.isExpandable"
                [currentWeekActionDTO]="this.currentWeek" (GetAllBookings)="GetAllBookings()">
              </app-individualbookingchild>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-heading"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns; let entry" [ngClass]="{
            highlight: entry.HighlightStatus,
            hideBorder: entry.isExpandable
          }"></tr>

        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="bookingChild-detail-row"
          [ngClass]="{ noBorder: !row.isExpandable }"></tr>
      </table>
      <div *ngIf="bookingList?.length === 0" class="noBookings">
        Bookings not found
      </div>
    </div>
    <div class="bookingSave">
      <div *ngIf="user.PreviewSubmit" class="saveBookings"
        (click)="openActionModal(GridPreviewActions, $event, null, 'preview')">
        <div class="saveCount">{{ count }}</div>
        Preview and Submit
      </div>
      <!-- <div *ngIf="!user.PreviewSubmit" class="saveBookings">Submit</div> -->
    </div>
  </div>
  <div class="wallchartContainer" (click)="isWallChart = !isWallChart">
    <div class="wallchartSection">
      <div class="wallChartHeader">
        <div class="loadWallchart">
          <mat-icon *ngIf="!isWallChart">expand_more</mat-icon>
          <mat-icon *ngIf="isWallChart">expand_less</mat-icon>
        </div>
        <div class="wallChartHeadInfo sectionHeading">
          <div class="bookingDiv">WallChart</div>
          <span class="booking_heading_info_icon" (click)="openModal(templateWallchart, $event)" matTooltip="Info">
            <img src="../../../../{{ imagePath }}TableIcons/info-yellow.svg" alt="Info" />
          </span>
          <!-- <mat-icon matTooltip="Info" class="questionIcon" (click)="openModal(templateWallchart)">info</mat-icon> -->
        </div>
      </div>
      <div *ngIf="isWallChart">
        <app-individual-wallchart [booking]="booking"></app-individual-wallchart>
      </div>
    </div>
  </div>
  <div class="extraHeight">.</div>
</div>
<!-- Modal for showing table icon infos -->
<ng-template #template1>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Bookings</h4>
    <mat-icon class="close" (click)="modalRef.hide()">close</mat-icon>
  </div>
  <div class="modal-body schedule-info-modal">
    <p>Use the action buttons to update your staffing forecast</p>
    <table class="info-icon-table">
      <tbody>
        <tr>
          <td class="textInfo">
            <img src="../../../../{{ imagePath }}TableIcons/edit-gray.svg" alt="Edit">
          </td>
          <td class="iconData">
            Edit a booking. The fields like start date, end date, notes description etc can be allowed to edit
          </td>
        </tr>
        <tr>
          <td class="textInfo">
            <img src="../../../../{{ imagePath }}TableIcons/replace-gray.png" alt="Replace">
          </td>
          <td class="iconData">Replace some or all the bookings from either one resource to another and/or one engagement to another</td>
        </tr>
        <tr>
          <td class="textInfo">
            <img src="../../../../{{ imagePath }}TableIcons/copy-gray.svg" alt="Copy">
          </td>
          <td class="iconData">Copy the booking details from an existing booking and creating a new booking</td>
        </tr>
        <tr>
          <td class="textInfo">
            <img src="../../../../{{ imagePath }}TableIcons/delete-gray.svg" alt="Delete">
          </td>
          <td class="iconData">Delete a booking</td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>

<ng-template #individualForecastInfo>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Individual Forecast
    </h4>
    <mat-icon class="close" (click)="modalRef.hide()">close</mat-icon>
  </div>
  <div class="modal-body">
    <div>
      <h6>The following Individual information is displayed:</h6>
    </div>
    <div class="iconSection text-margin">
      <span class="iconData">
        &lt;Resource Name&gt; - &lt;GPN&gt;
      </span>
    </div>
    <div class="iconSection text-margin">
      <span class="iconData">
        &lt;Rank&gt;
      </span>
    </div>
    <div class="iconSection text-margin">
      <span class="iconData">
        &lt;Res. ServiceLine&gt; - &lt;Res. Country&gt;
      </span>
    </div>
    <div class="iconSection text-margin">
      <span class="iconData">
        &lt;Res. Office Location&gt;
      </span>
    </div>
    <div class="iconSection text-margin">
      <span class="iconData">
        &lt;Res. Cost Center&gt;
      </span>
    </div>
  </div>
</ng-template>

<!-- Modal for Add new booking -->
<ng-template #GridActions>
  <ngx-spinner></ngx-spinner>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ gridActionModalHeader }}</h4>
    <mat-icon class="close" (click)="openConfirmationModal(EditConfirmation)">close</mat-icon>
  </div>
  <div class="modal-body">
    <!-- <app-edit *ngIf="showModal" (isFormDirtyFlag)="makeDirty($event)">
    </app-edit> -->
    <app-swap-individual *ngIf="showModelSwap" [currentWeekActionDTO]="this.currentWeek"
      (isFormDirtyFlag)="makeDirty($event)"></app-swap-individual>
    <app-delete-parent *ngIf="showDeleteParent" [isPercentSelected]="isPercentageSelected"
      [gridActionEventData]="gridActionEventData" [parentViewIVorEV]="'IV'" (isFormDirtyFlag)="makeDirty($event)">
    </app-delete-parent>
    <app-parent-edit *ngIf="showModalParentEdit" [isPercentageSelected]="isPercentageSelected"
      [gridActionEventData]="gridActionEventData" [parentViewIVorEV]="'IV'" (isFormDirtyFlag)="makeDirty($event)"
      [currentWeekActionDTO]="this.currentWeek" [singleEdit]="!gridActionEventData.dataItem.ChildRowsExist">
    </app-parent-edit>
    <app-add *ngIf="showModalAdd" [searchedResource]="selectedResource" [currentWeekActionDTO]="this.currentWeek"
      (isFormDirtyFlag)="makeDirty($event)">
    </app-add>
    <app-split *ngIf="showModalSplit" [currentWeekActionDTO]="this.currentWeek" (isFormDirtyFlag)="makeDirty($event)">
    </app-split>
    <app-delete *ngIf="showModalDelete" [actionEventData]="gridActionEventData" 
      [currentWeekActionDTO]="this.currentWeek" [isPercentSelected]="isPercentageSelected" 
      [uiViewSource]="'IV'">
    </app-delete> 
    <app-cancel-request *ngIf="showModalCancel" [actionEventData]="gridActionEventData" [uiViewSource]="'IV'">
    </app-cancel-request>
  </div>
</ng-template>

<ng-template #GridPreviewActions>
  <!-- <div class="mod"> -->
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ gridActionModalHeader }}</h4>
    <mat-icon class="close" (click)="openConfirmationModal(EditConfirmation)">close</mat-icon>
  </div>
  <div class="modal-body">
    <app-preview *ngIf="showPreviewModal"> </app-preview>

    <!-- </div> -->
  </div>
</ng-template>

<ng-template #EditConfirmation>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Confirm</h4>
  </div>
  <div class="modal-body">
    <div class="popupLayout">
      <div class="fullWidth">
        <div class="confirmationText">
          All the changes will be lost. Are you sure you want to close?<span></span>
        </div>
      </div>

      <div class="confirmationButtons">
        <button class="cancelBtn" (click)="this.confirmModalRef.hide()">
          Cancel
        </button>
        <button class="deleteBtn" (click)="okayConfirm(EditConfirmation)">
          Yes
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #templateWallchart>
  <div class="modal-header">
    <h4 class="modal-title pull-left">WallChart</h4>
    <mat-icon (click)="modalRef.hide()" class="close">close</mat-icon>
  </div>
  <div class="modal-body">
    <div class="wallChartDescription">
      You can navigate through the engagement list in the wallchart by clicking
      on the left side of your mouse, and whilst holding it down drag the
      pointer up and down in the wallchart. Alternatively, scrolling up and down
      is possible for those using touch screens.
    </div>
    <br />
    <table class="wallChartInfoTable">
      <tr>
        <th>Engagement Category</th>
        <th>Confirmed</th>
        <th>Unconfirmed*</th>
      </tr>
      <tr>
        <td>Chargeable</td>
        <td><span class="colorRect blueLabel"></span></td>
        <td><span class="colorRect lightBlueLabel"></span></td>
      </tr>
      <tr>
        <td>Authorised Project</td>
        <td><span class="colorRect yellowLabel"></span></td>
        <td><span class="colorRect lightYellowLabel"></span></td>
      </tr>
      <tr>
        <td>Non-Chargeable</td>
        <td><span class="colorRect greenLabel"></span></td>
        <td><span class="colorRect lightGreenLabel"></span></td>
      </tr>
      <tr>
        <td>Training Courses from Success Factors</td>
        <td><span class="colorRect purpleLabel"></span></td>
        <td></td>
      </tr>
      <tr>
        <td>Bookings that are in Submitted/In-Progress state</td>
        <td><span class="colorRect greyLabel"></span></td>
        <td></td>
      </tr>
      <tr>
        <td>Requests that are in Pending Approval*</td>
        <td><span class="colorRect lightGreyLabel"></span></td>
        <td></td>
      </tr>
      <tr>
        <td>Ghost Booking*</td>
        <td><span class="colorRect whiteLabel"></span></td>
        <td><span class="colorRect whiteLabel"></span></td>
      </tr>
    </table>
    <br />
    <div class="wallChartFooter">
      <p>Total hours = Sum of existing weekly bookings in Retain.</p>
      <p>*Unconfirmed, Ghosts and Pending Approval bookings will be visible based on your access role configuration</p>
      <!-- <p>* Unconfirmed field is not applicable for Americas and APAC.</p> -->
    </div>
  </div>
</ng-template>

<ng-template #tableInfoTemplate>
  <div style="max-height: 350px; overflow-y: auto; font-size: small; padding: 4px">
    <ng-container>
      <label style="font-weight: normal">
        {{ selectedRow.TooltipData.ActionType }}
      </label>
    </ng-container>
  </div>
</ng-template>

<ng-template #tooltipTemplate>
  <div class="tableTooltip" *ngIf="selectedItem.ActionType?.length > 0">
    <!-- <label>{{
      selectedItem.ActionDescription != null
        ? "Action Description: " + selectedItem.ActionDescription
        : null
    }}</label> -->
    <label>{{
      selectedItem.ClientName != null
        ? "Client Name: " + selectedItem.ClientName
        : null
    }}</label>
    <label>{{
      selectedItem.EngagementName != null
        ? "Engagement Name: " + selectedItem.EngagementName
        : null
    }}</label>
    <label>{{
      selectedItem.ResourceName != null
        ? "Resource Name: " + selectedItem.ResourceName
        : null
    }}</label>
    <label>{{ selectedItem.StartDate != null ? "Start Date: " : null
      }}{{ selectedItem.StartDate | date: defaultDateFormat }}</label>
    <label>{{ selectedItem.EndDate != null ? "End Date: " : null
      }}{{ selectedItem.EndDate | date: defaultDateFormat }}</label>
    <!-- <label>{{
      selectedItem.FromEngagementName != null
        ? "From Engagement Name: " + selectedItem.FromEngagementName
        : null
    }}</label> -->
    <!-- <label>{{
      selectedItem.FromResourceName != null
        ? "From Resource Name: " + selectedItem.FromResourceName
        : null
    }}</label> -->
    <label>{{
      selectedItem.Hours != null ? "Hours: " + selectedItem.Hours : null
    }}</label>
    <label>{{
      selectedItem.Loading != null ? "Loading: " + selectedItem.Loading : null
    }}</label>
    <!-- <label>{{
      selectedItem.ResourceId != null
        ? "Resource Id: " + selectedItem.ResourceId
        : null
    }}</label> -->
    <!-- <label>{{
      selectedItem.ToEngagementName != null
        ? "To Engagement Name: " + selectedItem.ToEngagementName
        : null
    }}</label> -->
    <!-- <label>{{
      selectedItem.ToResourceName != null
        ? "To Resource Name: " + selectedItem.ToResourceName
        : null
    }}</label> -->
    <label>{{
      selectedItem.Unconfirmed != null
        ? "Unconfirmed: " + selectedItem.Unconfirmed
        : null
    }}</label>
    <label>{{
      selectedItem.ActionType != null
        ? "Action: " + selectedItem.ActionType
        : null
    }}</label>
    <label>{{
      selectedItem.StatusId == 10
        ? "Status: " + selectedItem.StatusDescription
        : null
    }}</label>
    <label>{{
      selectedItem.Description != null
        ? "Description: " + selectedItem.Description
        : null
    }}</label>
  </div>
</ng-template>
