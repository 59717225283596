<ngx-spinner></ngx-spinner>
<div class="appLayout profileSetting">
    <div class="settingsContent">
        <div class="settingsHead">Email Notifications</div>
        <div class="expansionContent">
            <div class="expansionItems">
                <div class="toggleContainer">
                    Success : &nbsp;&nbsp; <mat-slide-toggle [(ngModel)]="successfulEmailToggle" [disableRipple]="true" (change)="onEmailSwitchChange($event,'successful')"></mat-slide-toggle>
                </div>
                <div class="toggleContainer">
                    Rejected : &nbsp;&nbsp; <mat-slide-toggle [(ngModel)]="rejectedEmailToggle" [disableRipple]="true" (change)="onEmailSwitchChange($event,'rejected')"></mat-slide-toggle>
                </div>
                <div class="toggleContainer" *ngIf="isDeniedRequired">
                    Denied : &nbsp;&nbsp; <mat-slide-toggle [(ngModel)]="notApprovedToggle" [disableRipple]="true" (change)="onEmailSwitchChange($event,'denied')"></mat-slide-toggle>
                </div>
                <!-- <mat-checkbox class="exanpsionCheckBox" [(ngModel)]="successfulEmailToggle" 
                [labelPosition]="'before'" [disableRipple]='true' 
                (change)="onEmailSwitchChange($event,'successful')">
                    Success
                </mat-checkbox> -->
                <!-- <mat-checkbox class="exanpsionCheckBox" [(ngModel)]="rejectedEmailToggle" 
                [labelPosition]="'before'" [disableRipple]='true' 
                (change)="onEmailSwitchChange($event,'rejected')">
                    Rejected
                </mat-checkbox>
                <mat-checkbox *ngIf="isDeniedRequired" class="exanpsionCheckBox" [(ngModel)]="notApprovedToggle" 
                [labelPosition]="'before'" [disableRipple]='true'
                (change)="onEmailSwitchChange($event,'denied')">
                    Denied
                 </mat-checkbox> -->
            </div>
        </div>
    </div>
    <div class="settingsContent">
        <div class="settingsHead">Booking Settings</div>
        <div class="expansionContent">
            <div class="expansionPreference">
                <label id="preferenceOption">Preference</label>
                <mat-radio-group aria-label="preferenceOption" class="expansionRadio" [(ngModel)]="preferenceOption"
                    (change)="onPercentSwitchChange($event)">
                    <mat-radio-button value="hour" class="prefernceRadio">Hour</mat-radio-button>
                    <mat-radio-button value="percentage" class="prefernceRadio">Percentage</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

    </div>
    <div class="settingsContent">
        <div class="settingsHead">Colour Theme</div>
        <div class="expansionContent">
            <div class="expansionPreference">
                <label id="preferenceOption">Theme</label>
                <mat-radio-group aria-label="preferenceOption" class="expansionRadio" [(ngModel)]="themeOption"
                    (change)="onThemeSwitchChange($event)">
                    <mat-radio-button value="light" class="prefernceRadio">Light</mat-radio-button>
                    <mat-radio-button value="dark" class="prefernceRadio">Dark</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </div>
    
    <div class="settingsContent">
        <div class="settingsHead">Date Configuration</div>
        <div class="expansionContent">
            <div class="expansionPreference">
                <mat-radio-group aria-label="dateFormatOption" class="expansionRadio" [(ngModel)]="defaultDateFormat"
                    (change)="ondateFormatSwitchChange($event)">
                    <mat-radio-button value="dd-MM-yyyy" class="dateFormatRadio">DD-MM-YYYY</mat-radio-button>
                    <mat-radio-button value="MM-dd-yyyy" class="dateFormatRadio">MM-DD-YYYY</mat-radio-button>
                    <mat-radio-button value="yyyy-MM-dd" class="dateFormatRadio">YYYY-MM-DD</mat-radio-button>
                    <!-- <mat-radio-button value="System date format" class="dateFormatRadio">System Date Format</mat-radio-button> -->
                </mat-radio-group>
            </div>
        </div>

    </div>
    <div class="settingsContent">
        <div class="settingsHead">Default Retain Instance</div>
        <div class="expansionContent">
            <div class="expansionPreference">
                <label id="preferenceOption">Retain Instance</label>
                <mat-form-field appearance="outline">
                    <mat-select [(value)]="selectedInstance" overlayPanelClass="profileSelect"
                        (selectionChange)="onInstanceChange($event)">
                        <mat-option [value]="option.InstanceId" *ngFor="let option of instances">{{ option.InstanceName
                            }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="settingsContent">
        <div class="settingsHead">Period Filter Settings</div>
        <div class="expansionContent">
            <div class="expansionPreference">
                <mat-radio-group aria-label="dateFormatOption" class="expansionRadio" [(ngModel)]="defaultPeriodFilter"
                    (change)="onPeriodFilterSwitchChange($event)">
                    <mat-radio-button value="6W" class="dateFormatRadio">6 Weeks</mat-radio-button>
                    <mat-radio-button value="3M" class="dateFormatRadio">3 Months</mat-radio-button>
                    <mat-radio-button value="6M" class="dateFormatRadio">6 Months</mat-radio-button>
                    <mat-radio-button value="12M" class="dateFormatRadio">12 Months</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </div>
</div>

<!-- <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Email notifications
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="expansionContent">
                <div class="expansionItems">
                    <mat-checkbox class="exanpsionCheckBox" [(ngModel)]="successfulEmailToggle" [labelPosition]="'before'" [disableRipple]='true' (change)="onEmailSwitchChange($event,'successful')">
                       Success
                    </mat-checkbox>
                    <mat-checkbox class="exanpsionCheckBox" [(ngModel)]="rejectedEmailToggle" [labelPosition]="'before'" [disableRipple]='true' (change)="onEmailSwitchChange($event,'rejected')">
                        Rejected
                     </mat-checkbox>
                    
                </div>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Booking settings
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="expansionContent">
                <div class="expansionPreference">
                    <label id="preferenceOption">Preference</label>
                    <mat-radio-group aria-label="preferenceOption" class="expansionRadio" [(ngModel)]="preferenceOption" (change)="onPercentSwitchChange($event)">
                        <mat-radio-button value="hour" class="prefernceRadio">Hour</mat-radio-button>
                        <mat-radio-button value="percentage" class="prefernceRadio">Percentage</mat-radio-button>
                      </mat-radio-group>
                </div>
            </div>
        </mat-expansion-panel>
        
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Default retain instance
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="expansionContent">
                <div class="expansionPreference">
                    <label id="preferenceOption">Retain instance</label>
                    <mat-form-field appearance="outline">
                        <mat-select [(value)]="selectedInstance" overlayPanelClass="profileSelect" (selectionChange)="onInstanceChange($event)">
                            <mat-option [value]="option.InstanceId" *ngFor="let option of instances">{{ option.InstanceName }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion> -->


<!-- <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Date configuration
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="expansionContent">
                <div class="expansionPreference">
                    <mat-radio-group aria-label="dateOption" class="expansionDate" [(ngModel)]="dateConfiguration">
                        <mat-radio-button class="dateRadio" value="dd-mm-yyyy">dd-mm-yyyy</mat-radio-button>
                        <mat-radio-button class="dateRadio" value="mm-dd-yyyy">mm-dd-yyyy</mat-radio-button>
                        <mat-radio-button class="dateRadio" value="yyyy-mm-dd">yyyy-mm-dd</mat-radio-button>
                        <mat-radio-button class="dateRadio" value="System date format">System date format</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </mat-expansion-panel> -->