<div class="appLayout scheduledIndividualBooking" id="scheduleIndividualPage">
  <div class="scheduleView" id="indWrapper">
    <div class="fixedSection" id="fixedForecast" [ngStyle]="setWidth()">
      <div class="engagementScheduleSearchWrapper collapsibleWrapper">
        <section class="searchContainerHeading" (click)="toggleCollapse()">
          <span class="icon" [ngClass]="isCollapsed ? 'collapsed' : ''"></span>
          <h2 class="title fixedForecastTitle">
            <label>{{ searchLabel }}</label>
            <span class="has-content">{{
              gridSelectedUser ? gridSelectedUser : user.ResourceName
              }}</span>
          </h2>
        </section>
        <div class="searchContainer" *ngIf="!isCollapsed">
          <div class="searchFields">
            <div *ngIf="user.IsMultiInstance && user.R_InstEnable_TV" class="instanceContainer searchItem">
              <label>Retain Instance</label>
              <mat-form-field appearance="outline">
                <mat-select [(value)]="selectedInstance" (selectionChange)="onInstanceChange($event)"
                  placeholder="Retain instance">
                  <mat-option [value]="option.InstanceId" *ngFor="let option of instances">{{ option.InstanceName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="searchBox searchItem">
              <label>Resource</label>
              <mat-form-field class="resourceSearch" appearance="outline">
                <input type="text" autocomplete="off" placeholder="Search..." matInput appSearchDebounce
                  (debounceKeyup)="search()" [(ngModel)]="searchBar" maxlength="75" />
                <mat-icon (click)="resetfilterBookingGridSearchBar()">close</mat-icon>
              </mat-form-field>
              <div class="dropdown-list-wrap" *ngIf="showSearchList">
                <mat-spinner *ngIf="showSearchLoader" [diameter]="40"></mat-spinner>
                <ul *ngIf="results?.length > 0" class="dropdown-list tabdropdwn">
                  <li *ngFor="let item of results" (click)="OnItemSelect(item)" (keyup.enter)="OnItemSelect(item)"
                    class="dropdown-list-item" tabindex="0">
                    {{ item.ResourceName }}
                  </li>
                </ul>
                <div class="noDataDropdown" *ngIf="showSearchLoader === false && results.length === 0">
                  No Resource Found
                </div>
              </div>
            </div>
            <div class="dateSearch searchItem">
              <label>Period</label>
              <form [formGroup]="range">
                <mat-form-field appearance="outline">
                  <mat-date-range-input [rangePicker]="picker" [min]="startWith">
                    <input matStartDate formControlName="start" placeholder="Select Start Date" (click)="picker.open()"
                      (dateChange)="onBookingStartDateChange($event)" readonly />-
                    <input matEndDate formControlName="end" placeholder="End Date" (click)="picker.open()"
                      (dateChange)="onBookingEndDateChange($event)" readonly />
                  </mat-date-range-input>
                  <mat-icon (click)="resetfilterBookingGridCalender($event)">close</mat-icon>
                  <mat-date-range-picker (closed)="OnClosedDatePicker()" #picker></mat-date-range-picker>
                  <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                  <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                </mat-form-field>
                <div *ngIf="
                    range.get('start').value != null &&
                    (range.get('start').dirty || range.get('start').touched) &&
                    range.get('end').value === null &&
                    (range.get('end').dirty || range.get('end').touched) &&
                    isPeriodErrorMsg
                  " class="alert alert-danger">
                  End date is required
                </div>
              </form>
            </div>
            <div class="expansionPreference searchItem">
              <mat-radio-group aria-label="preferenceOption" class="expansionRadio"
                [(ngModel)]="engagementAllocationModal" (change)="engagementAllocationOnChange($event)">
                <mat-radio-button value="hour" class="prefernceRadio">Hour</mat-radio-button>
                <mat-radio-button value="percentage" class="prefernceRadio">Percentage</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="resourceField">
            <div class="resourceContaier">
              <div class="resourceTitle">
                Individual Forecast
                <span class="booking_heading_info_icon" (click)="openInfo(individualForecastInfo)" matTooltip="Info">
                  <img src="../../../../{{ imagePath }}TableIcons/info-yellow.svg" alt="Info" />
                </span>
              </div>
              <div class="resourceDetails">
                <!-- [ngClass]="{noLink: user?.RoleType === 'Client Server' && !isMobileDevice}"  -->
                <div class="resourceImg" *ngIf="selectedResource.ResourcePhotoUrl">
                  <img *ngIf="selectedResource.ResourcePhotoUrl" [src]="selectedResource.ResourcePhotoUrl" />
                </div>
                <div class="resourceInfo">
                  <div class="name">{{ gridSelectedUser }}</div>
                  <div class="rank">{{ selectedResource.RankDescription }}</div>
                  <div class="serviceLine">
                    {{ selectedResource.SLDescription }}
                    {{
                    selectedResource.ManagerialCountryDescription ? "-" : ""
                    }}
                    {{ selectedResource.ManagerialCountryDescription }}
                  </div>
                  <div class="officeLocation">
                    {{ selectedResource.LocationDescription }}
                  </div>
                  <div class="costCenter">
                    {{ selectedResource.CostCenterDescription }}
                  </div>
                  <!-- <div class="area">{{ selectedResource.SubAreaDescription }}</div> -->
                </div>
              </div>
            </div>
            <!-- *ngIf="user?.RoleType === 'Client Server' && !isMobileDevice" -->
            <!-- <div class="linkContainer" *ngIf="user?.RoleType === 'Client Server' && !isMobileDevice">
              <div class="linkItem" *ngFor="let element of cspReports"
                (click)="navigateToCspReport(element.FeatureName)">
                {{ element.FeatureDisplayName }}
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="scheduleHeader">
        <div class="heading sectionHeading">
          <span>Bookings</span>
          <!-- <mat-icon matTooltip="Info" class="questionIcon" (click)="openInfo()">info</mat-icon> -->
          <span class="booking_heading_info_icon" (click)="openInfo(template1)" matTooltip="Info">
            <img src="../../../../{{ imagePath }}TableIcons/info-yellow.svg" alt="Info" />
          </span>
          &nbsp;
          <div class="row py-3" *ngIf="saveErrorMessage != ''">
            <div style="float: right; margin-right: 5px">
              <div class="alert alert-danger" style="padding: 5px; margin: 0px">
                <button type="button" class="msg close" (click)="saveErrorMessage = ''">
                  &times;
                </button>
                &nbsp;
                <strong>Failed: &nbsp;</strong>{{ saveErrorMessage }}
              </div>
            </div>
          </div>
          <div class="row py-3" *ngIf="saveSucessMessage != ''">
            <div style="float: right; margin-right: 5px">
              <div class="alert alert-success" style="padding: 5px; margin: 0px">
                <button type="button" class="msg close" (click)="saveSucessMessage = ''">
                  &times;
                </button>
                &nbsp;
                <strong>Success: &nbsp;</strong>{{ saveSucessMessage }}
              </div>
            </div>
          </div>
        </div>

        <div class="scheduleAction" *ngIf="
            updatedBookingDetails.fetchSaveDataList()?.length > 0 ||
            cancelRequestArray?.length > 0
          ">
          <button class="formBtn cancelButton" (click)="cancel()">Cancel</button>
          <!-- [disabled]="validateSave()" -->
          <button class="formBtn submitButton" (click)="submit()" type="submit">
            Submit
          </button>
        </div>
      </div>
    </div>

    <div class="scheduleTableContainer" [ngStyle]="setTopHeight()" id="individualEngWrapper" [formGroup]="myFormGroup">
      <div style="margin-bottom: 20px" *ngIf="dataSource">
        <table mat-table [dataSource]="dataSource" class="scheduleTable">
          <ng-container [matColumnDef]="col" *ngFor="let col of displayedColumns; let colIndex = index"
            [sticky]="isSticky(col)">
            <th mat-header-cell *matHeaderCellDef>
              <!-- <section class="currentWeekSection">
                <span>{{ col }}</span> -->
                <div class="currentWeekDisable">
                  <span class="booking_heading_info_icon disabledWeekInfo">
                    <img *ngIf="
                    isCurrentWeekDisabled &&
                    this.datepipe.transform(
                      tempdate,
                      this.defaultDateFormat
                    ) === col
                  "  matTooltip="Please contact your Resource or Experience Management team to make any changes in the current week." 
                  src="../../../../{{ imagePath }}TableIcons/info-red.svg" alt="Warning" />
                  </span>
                  {{ col }}
                </div>
                
              <!-- </section> -->
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [ngClass]="{
                copyRow:
                  element.isCopy === true ||
                  (element.isReplace &&
                    (element.showReplaceResList || element.showReplaceEngList)),
                    replaceRow: element.rowReplaced
              }">
              <div *ngIf="col === 'Actions'" class="actionContainer">
                <!-- Row cancel for ADD and Copy actions -->
                <span (click)="removeEng(element, i)" class="tableActionIcon cancelIcon" *ngIf="
                    !this.user.R_InstEnable_AddBooking
                      ? element.InstanceID == this.addBookingInstance &&
                        element.addEng
                      : element.addEng
                  ">
                  <mat-icon matTooltip="Row Cancel" class="cellClear">
                    clear
                  </mat-icon>
                </span>
                <!-- Row cancel for Delete action -->
                <span (click)="cancelRowDelete(element, i)" class="tableActionIcon cancelIcon" *ngIf="
                    !this.user.R_InstEnable_AddBooking
                      ? element.InstanceID == this.addBookingInstance &&
                        element.isRowDeleted
                      : element.isRowDeleted
                  ">
                  <mat-icon matTooltip="Row Cancel" class="cellClear">
                    clear
                  </mat-icon>
                </span>
                <!-- Row cancel for Replace action -->
                <span (click)="cancelRowReplace(element, i)" class="tableActionIcon cancelIcon"
                  *ngIf="isCancelReplace(element)">
                  <mat-icon matTooltip="Row Cancel" class="cellClear">
                    clear
                  </mat-icon>
                </span>
                 <!-- Row Replace action -->
                <span class="tableActionIcon replace" *ngIf="
                    !this.user.R_InstEnable_AddBooking
                      ? element.InstanceID == this.addBookingInstance &&
                        !(element.IsDisabledReplaceBooking || element.IsDisabledReplaceBooking_EV)
                      : !(element.IsDisabledReplaceBooking || element.IsDisabledReplaceBooking_EV)
                  " (click)="replaceRow(element)" matTooltip="Replace">
                </span>
                <!-- Row Copy action -->
                <span class="tableActionIcon copy" *ngIf="
                    !this.user.R_InstEnable_AddBooking
                      ? element.InstanceID == this.addBookingInstance &&
                        !(element.IsDisabledCopyBooking || element.IsDisabledCopyBooking_EV)
                      : !(element.IsDisabledCopyBooking || element.IsDisabledCopyBooking_EV)
                  " (click)="copyRow(element)" matTooltip="Copy">
                </span>
                <!-- Row Delete action -->
                <span class="tableActionIcon delete" *ngIf="
                    !this.user.R_InstEnable_AddBooking
                      ? element.InstanceID == this.addBookingInstance &&
                        !(element.IsDisabledDeleteBooking || element.IsDisabledDeleteBooking_EV)
                      : !(element.IsDisabledDeleteBooking || element.IsDisabledDeleteBooking_EV)
                  " (click)="openDeleteConfirm(element, i)" matTooltip="Delete">
                </span>
                <span *ngIf="
                    element.IsDisabledEditBooking &&
                    element.IsDisabledDeleteBooking &&
                    element.IsDisabledReplaceBooking &&
                    element.IsDisabledCopyBooking &&
                    !element.addEng
                  "
                  matTooltip="Please contact your Resource or Experience Management team &#013;to make any changes in the current week">
                  <mat-icon style="color: #b9251c; font-size: 16px">error</mat-icon>
                </span>

                <span *ngIf="
                    (!this.user.R_InstEnable_AddBooking &&
                      element.InstanceID != this.addBookingInstance) ||
                    (element.BookingId > 0 &&
                      element.JobCode != null &&
                      element.JobCode.indexOf('TR') <= -1 &&
                      element.IsDisabledDeleteBooking &&
                      element.IsDisabledCopyBooking &&
                      element.IsDisabledReplaceBooking &&
                      !element.isRowDeleted &&
                      !(element.rowReplaced || element.Replaced) &&
                      !element.addEng)
                  " class="booking_heading_info_icon"
                  matTooltip="Submissions are not supported on this booking.&#013;For any other updates, please follow the country specific process.">
                  <img src="../../../../{{ imagePath }}TableIcons/info-red.svg" alt="Warning" />
                </span>
                <span *ngIf="
                    element.BookingId > 0 &&
                    element.JobCode != null &&
                    element.JobCode.indexOf('TR') > -1 &&
                    element.IsDisabledDeleteBooking &&
                    element.IsDisabledCopyBooking &&
                    element.IsDisabledReplaceBooking
                  " class="booking_heading_info_icon"
                  matTooltip=" Please refer Success factors for update to course bookings">
                  <mat-icon style="color: #b9251c; font-size: 16px">error</mat-icon>
                </span>
              </div>
              <div *ngIf="col === 'Engagement Name'">
                <div *ngIf="!element.isCopy && !element.rowReplaced" class="navigationlink" [ngClass]="{
                    disableNavigation:
                      element.IsDisabledEVNavigation === true ||
                      element.IsDisabledViewBooking_Job === true
                  }" (click)="navigateEngagementScheduleView(element)" [matTooltip]="
                    element.IsDisabledEVNavigation === false &&
                    element.IsDisabledViewBooking_Job === false
                      ? 'Click here to see engagement details'
                      : null
                  ">
                  {{ element.EngagementName }}
                </div>
                <div class="replaceContainer" *ngIf="element.rowReplaced">
                  <div class="replaceResource" *ngIf="user.RoleDelegate">
                    <form #replaceResourceForm="ngForm" class="replace-form">
                      <mat-form-field class="resourceSearch" appearance="outline">
                        <input type="text" autocomplete="off" placeholder="Replace Resource" matInput appSearchDebounce
                          (debounceKeyup)="searchReplaceResource(element)" [(ngModel)]="element.searchResource"
                          maxlength="100" required [ngModelOptions]="{ standalone: true }"
                          #replaceResourceControl="ngModel" name="replaceResourceRow" />
                        <mat-icon (click)="onReplaceResourceClear(element)">close</mat-icon>
                      </mat-form-field>
                    </form>

                    <div class="dropdown-list-wrap" *ngIf="element.showReplaceResList">
                      <mat-spinner *ngIf="showReplaceResLoader" [diameter]="40"></mat-spinner>
                      <ul *ngIf="replaceResResults?.length > 0" class="dropdown-list tabdropdwn_actn">
                        <li *ngFor="let item of replaceResResults" (click)="OnReplaceResSelect(item, element)"
                          (keyup.enter)="OnReplaceResSelect(item, element)" class="dropdown-list-item" tabindex="0">
                          {{ item.ResourceName }}
                        </li>
                      </ul>
                      <div class="noDataDropdown" *ngIf="
                          showReplaceResLoader === false &&
                          replaceResResults.length === 0
                        ">
                        No Resource Found
                      </div>
                    </div>
                  </div>
                  <div class="replaceEngagement">
                    <form #replaceEngForm="ngForm" class="replace-form">
                      <mat-form-field class="resourceSearch" appearance="outline">
                        <input type="text" autocomplete="off" placeholder="Replace Engagement" matInput
                          appSearchDebounce (click)="onReplaceEngagementClick(element)"
                          (debounceKeyup)="searchReplaceEngagement(element)" [(ngModel)]="element.searchEngagement"
                          maxlength="100" required [ngModelOptions]="{ standalone: true }" #replaceEngControl="ngModel"
                          name="replaceEngRow" />
                        <mat-icon (click)="onReplaceEngClear(element)">close</mat-icon>
                      </mat-form-field>
                    </form>
                    <!-- for replaceFavourite  Engagement ---Replace Start-->
                    <div class="dropdown-list-wrap" *ngIf="element.replaceshowFavouriteEngList">
                      <mat-spinner *ngIf="showReplaceEngLoader" [diameter]="40"></mat-spinner>
                      <ul *ngIf="addFavouriteEngResults?.length > 0" class="dropdown-list tabdropdwn_actn">
                        <li class="favouriteText">Favourites</li>

                        <li *ngFor="let item of addFavouriteEngResults" (click)="OnReplaceEngSelect(item, element, i)"
                          (keyup.enter)="OnReplaceEngSelect(item, element, i)" class="dropdown-list-item" matTooltip="{{item.EngagementFullName}}">
                          <span class="EngFullname">
                            {{ item.EngagementFullName }}
                          </span>

                          <img #Favimage1 (click)="onFavoriteImageClick(item)" class="favouriteIcon exportExcelIcon"
                            src="../../../../{{ imagePath }}{{
                              item.favEngSrc
                            }}" />
                        </li>
                      </ul>
                      <div class="noDataDropdown" *ngIf="
                          showReplaceEngLoader === false &&
                          addFavouriteEngResults?.length === 0 &&
                          IsRepalceDataNotFound === true
                        ">
                        No Engagement Found
                      </div>
                    </div>
                    <!-- for Favourite  Engagement Replace --- End-->

                    <div class="dropdown-list-wrap" *ngIf="
                        element.showReplaceEngList && favEngReplaceIndex === 0
                      ">
                      <mat-spinner *ngIf="showReplaceEngLoader" [diameter]="40"></mat-spinner>
                      <ul *ngIf="replaceEngResults?.length > 0" class="dropdown-list tabdropdwn_actn">
                        <li *ngFor="let item of replaceEngResults" (click)="OnReplaceEngSelect(item, element)"
                          (keyup.enter)="OnReplaceEngSelect(item, element)" class="dropdown-list-item" tabindex="0" matTooltip="{{item.EngagementFullName}}">
                          <span class="EngFullname">
                            {{ item.EngagementFullName }}</span>

                          <img #Favimage1 (click)="onFavoriteImageClick(item)" class="exportExcelIcon favouriteIcon"
                            src="../../../../{{ imagePath }}{{
                              item.favEngSrc
                            }}" />
                        </li>
                      </ul>
                      <div class="noDataDropdown" *ngIf="
                          showReplaceEngLoader === false &&
                          replaceEngResults?.length === 0 &&
                          IsRepalceDataNotFound === true
                        ">
                        No Engagement Found
                      </div>
                    </div>
                  </div>
                </div>
                <div class="addEngContainer" *ngIf="
                    element.isCopy &&
                    resourceSelected &&
                    !selectedResource.IsDisabledAddBooking
                  ">
                  <div class="addRetainContainer" *ngIf="user.IsMultiInstance && user.R_InstEnable_AddBooking">
                    <mat-form-field appearance="outline">
                      <mat-select [(value)]="copyBookingInstance"
                        (selectionChange)="onCopyBookingInstanceChange($event)" placeholder="Select Retain instance">
                        <mat-option [value]="option.InstanceId" *ngFor="let option of addInstances">
                          {{ option.InstanceName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="searchEngForAdd" [ngClass]="{ fullWidth: !user.R_InstEnable_AddBooking }">
                    <!-- <form #copyForm="ngForm" class="copy-form"> -->
                    <mat-form-field class="resourceSearch" appearance="outline">
                      <input id="copyEngName" formControlName="copyEngName" type="text" autocomplete="off"
                        placeholder="Search Engagement" matInput appSearchDebounce
                        (debounceKeyup)="searchCopyEngagement()" maxlength="100" required
                        (click)="onCopyEngagementClick(element)" [ngModelOptions]="{ standalone: true }" />
                      <mat-icon (click)="onEngagementCopyClear()">close</mat-icon>
                    </mat-form-field>

                    <!-- </form> -->
                    <!-- for copyFavourite  Engagement copy --- Start-->
                    <div class="dropdown-list-wrap" *ngIf="copyshowFavouriteEngList">
                      <mat-spinner *ngIf="showEngCopyLoader" [diameter]="40"></mat-spinner>
                      <ul *ngIf="addFavouriteEngResults?.length > 0" class="dropdown-list tabdropdwn_actn">
                        <li class="favouriteText">Favourites</li>

                        <li *ngFor="let item of addFavouriteEngResults"
                          (click)="OnEngagementCopySelect(item, element, i)" (keyup.enter)="
                            OnEngagementCopySelect(item, element, i)
                          " class="dropdown-list-item" matTooltip="{{item.EngagementFullName}}">
                          <span class="EngFullname">
                            {{ item.EngagementFullName }}
                          </span>

                          <img #Favimage1 (click)="onFavoriteImageClick(item)" class="favouriteIcon exportExcelIcon"
                            src="../../../../{{ imagePath }}{{
                              item.favEngSrc
                            }}" />
                        </li>
                      </ul>
                      <div class="noDataDropdown" *ngIf="
                          showEngCopyLoader === false &&
                          addFavouriteEngResults?.length === 0 &&
                          IsCopyDataNotFound === true
                        ">
                        No Engagement Found
                      </div>
                    </div>
                    <!-- for Favourite  Engagement -copy -- End-->
                    <div class="dropdown-list-wrap" *ngIf="showCopyList">
                      <mat-spinner *ngIf="showCopyLoader" [diameter]="40"></mat-spinner>
                      <ul *ngIf="copyEngResults?.length > 0" class="dropdown-list tabdropdwn_actn">
                        <li *ngFor="let item of copyEngResults" (click)="OnEngagementCopySelect(item, element, i)"
                          (keyup.enter)="
                            OnEngagementCopySelect(item, element, i)
                          " class="dropdown-list-item" tabindex="0" matTooltip="{{item.EngagementFullName}}">
                          <span class="EngFullname">
                            {{ item.EngagementFullName }}</span>
                          <img #Favimage1 (click)="onFavoriteImageClick(item)" class="exportExcelIcon favouriteIcon"
                            src="../../../../{{ imagePath }}{{
                              item.favEngSrc
                            }}" />
                        </li>
                      </ul>
                      <div class="noDataDropdown" *ngIf="
                          showCopyLoader === false &&
                          copyEngResults?.length === 0 &&
                          IsCopyDataNotFound === true
                        ">
                        No Engagement Found
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="col === 'Engagement Category'" class="engCategory">
                {{ element.JobCategory }}
                <span *ngIf="selectedResource.SearchedResMultiInstance">
                  <br />
                  {{ element.InstanceName }}
                </span>
              </div>
              <!-- <div *ngIf="col === 'Retain Instance'" class="retainInstance">
                {{ element.InstanceName }}
              </div> -->
              <div class="bookingCells" *ngIf="
                  col !== 'Actions' &&
                  col !== 'Engagement Name' &&
                  col !== 'Engagement Category' &&
                  col !== 'Retain Instance'
                ">

                <ng-container *ngFor="let booking of element[col].Confirmed">
                  <div class="cellBooking" *ngIf="booking.BookingMode === 'Confirmed' && booking.ActionMode === 'E'">
                    <div class="bookingDetails" [ngClass]="{
                      replaceBooking: element.rowReplaced,
                      confirm: !isUnconfirmed && !isGhost
                    }" [ngStyle]="getBorderColor(element, booking)">
                      <!-- (click)="onClickEnable(i, col, booking)" -->
                      <div class="valueContainer" [ngClass]="{
                        faceValue:
                          (!isCurrentWeekDisabled ||
                            !(
                              this.datepipe.transform(
                                tempdate,
                                this.defaultDateFormat
                              ) === col
                            )) &&
                          ((!(this.selectedResource.IsDisabledAddBooking || element.IsDisabledAddEngagement_EV_Job) &&
                            booking.isNewBooking) ||
                            (!booking.isNewBooking &&
                              (!booking.IsDisabledEditBooking ||
                                !booking.IsDisabledDeleteBooking))),
                        faceValueAlong: !isUnconfirmed && !isGhost
                      }" (click)="onCellClick(element)">
                        <!-- *ngIf="showValue(i, col, booking)" -->
                        <div class="showDiv" *ngIf="!booking.IsEdit">
                          <div class="showValue" [ngClass]="{
                            errorValue: booking.isNotValidValue === true
                          }">
                            {{
                            this.isPercentageSelected
                            ? booking.Loading
                            : booking.BookingHours
                            }}
                          </div>
                          <div class="editActions" *ngIf="showCancelIcon(booking)">
                            <mat-icon matTooltip="Cell cancel" class="cellClear" (click)="
                              cellCancel(booking, col, element);
                              $event.stopPropagation()
                            ">
                              clear
                            </mat-icon>
                          </div>
                        </div>
                      </div>
                      <!-- *ngIf="editValue(i, col, booking)" -->
                      <div *ngIf="
                        !this.user.R_InstEnable_AddBooking
                          ? booking.InstanceId == this.addBookingInstance &&
                            booking.IsEdit
                          : booking.IsEdit
                      " class="editContainer">
                        <div [ngClass]="{
                          editError: booking.isNotValidValue === true
                        }">
                          <input class="editHour" appHoursLoading [isPercentageSelected]="isPercentageSelected"
                            type="text" [(ngModel)]="
                            isPercentageSelected
                              ? booking.Loading
                              : booking.BookingHours
                          " (focusout)="
                            onFocusOutEvent(
                              $event.target.value,
                              col,
                              booking,
                              element
                            )
                          " (focus)="checkForValidation(element, booking)"
                            (ngModelChange)="checkIfNumbers($event, booking)" [ngModelOptions]="{ standalone: true }" />
                          <!-- (ngModelChange)="modelChangeFn($event,col,booking,element)" -->
                        </div>
                        <!-- *ngIf="booking.isNewBooking || (user.Email?.toLowerCase() ===
                      booking.RequestorEmail?.toLowerCase() && (booking.StatusId === 1 || booking.StatusId === 10)" -->
                      </div>
                      <div class="weeklyIcon" (click)="onCellClick(element)" [ngClass]="{
                        noNotes:
                          booking.Description?.trim().length === 0 &&
                          booking.IsEdit === false
                      }">
                        <img *ngIf="booking.BookingHours > 0" (click)="showNotes($event, booking, element, col)"
                          src="../../../../{{ imagePath }}Notes.svg" class="notesIcon"
                          [matTooltip]="booking.Description" alt="Notes" />
                      </div>
                    </div>
                  </div>

                </ng-container>
                <ng-container *ngIf="isUnconfirmed">
                  <ng-container *ngFor="let booking of element[col].Unconfirmed">
                    <div class="cellBooking" *ngIf="
                    isUnconfirmed &&
                    booking.BookingMode === 'Unconfirmed' &&
                    booking.ActionMode === 'E'
                  ">
                      <div class="bookingDetails unconfirmColumn" [ngClass]="{ replaceBooking: element.rowReplaced }"
                        [ngStyle]="getBorderColor(element, booking)">
                        <div class="valueContainer" [ngClass]="{
                          faceValue:
                            (!isCurrentWeekDisabled ||
                              !(
                                this.datepipe.transform(
                                  tempdate,
                                  this.defaultDateFormat
                                ) === col
                              )) &&
                            ((!(this.selectedResource.IsDisabledAddBooking || element.IsDisabledAddEngagement_EV_Job) &&
                              booking.isNewBooking) ||
                              (!booking.isNewBooking &&
                                (!booking.IsDisabledEditBooking ||
                                  !booking.IsDisabledDeleteBooking)))
                        }" (click)="onCellClick(element)">
                          <div class="showDiv" *ngIf="!booking.IsEdit">
                            <div class="showValue" [ngClass]="{
                              errorValue: booking.isNotValidValue === true
                            }">
                              {{
                              this.isPercentageSelected
                              ? booking.Loading
                              : booking.BookingHours
                              }}
                            </div>
                            <div class="editActions" *ngIf="showCancelIcon(booking)">
                              <mat-icon matTooltip="Cell cancel" class="cellClear"
                                (click)="cellCancel(booking, col, element)">
                                clear
                              </mat-icon>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="
                          !this.user.R_InstEnable_AddBooking
                            ? booking.InstanceId == this.addBookingInstance &&
                              booking.IsEdit
                            : booking.IsEdit
                        " class="editContainer">
                          <div [ngClass]="{
                            editError: booking.isNotValidValue === true
                          }">
                            <input class="editHour" appHoursLoading [isPercentageSelected]="isPercentageSelected"
                              type="text" [(ngModel)]="
                              isPercentageSelected
                                ? booking.Loading
                                : booking.BookingHours
                            " (focusout)="
                              onFocusOutEvent(
                                $event.target.value,
                                col,
                                booking,
                                element
                              )
                            " (focus)="checkForValidation(element, booking)"
                              (ngModelChange)="checkIfNumbers($event, booking)"
                              [ngModelOptions]="{ standalone: true }" />
                          </div>
                        </div>
                        <div class="weeklyIcon" (click)="onCellClick(element)" [ngClass]="{
                          noNotes:
                            booking.Description?.trim().length === 0 &&
                            booking.IsEdit === false
                        }">
                          <img *ngIf="booking.BookingHours > 0" (click)="showNotes($event, booking, element, col)"
                            src="../../../../{{ imagePath }}Notes.svg" class="notesIcon"
                            [matTooltip]="booking.Description" alt="Notes" />
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="isGhost">
                  <ng-container *ngFor="let booking of element[col].Ghost">
                    <div class="cellBooking" *ngIf="
                  isGhost &&
                  booking.BookingMode === 'Ghost' &&
                  booking.ActionMode === 'E'
                ">
                      <div class="bookingDetails ghostColumn" [ngClass]="{ replaceBooking: element.rowReplaced }"
                        [ngStyle]="getBorderColor(element, booking)">
                        <div class="valueContainer" [ngClass]="{
                          faceValue:
                            (!isCurrentWeekDisabled ||
                              !(
                                this.datepipe.transform(
                                  tempdate,
                                  this.defaultDateFormat
                                ) === col
                              )) &&
                            ((!(this.selectedResource.IsDisabledAddBooking || element.IsDisabledAddEngagement_EV_Job) &&
                              booking.isNewBooking) ||
                              (!booking.isNewBooking &&
                                (!booking.IsDisabledEditBooking ||
                                  !booking.IsDisabledDeleteBooking)))
                        }" (click)="onCellClick(element)">
                          <div class="showDiv" *ngIf="!booking.IsEdit">
                            <div class="showValue" [ngClass]="{
                              errorValue: booking.isNotValidValue === true
                            }">
                              {{
                              this.isPercentageSelected
                              ? booking.Loading
                              : booking.BookingHours
                              }}
                            </div>
                            <div class="editActions" *ngIf="showCancelIcon(booking)">
                              <mat-icon matTooltip="Cell cancel" class="cellClear"
                                (click)="cellCancel(booking, col, element)">
                                clear
                              </mat-icon>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="
                          !this.user.R_InstEnable_AddBooking
                            ? booking.InstanceId == this.addBookingInstance &&
                              booking.IsEdit
                            : booking.IsEdit
                        " class="editContainer">
                          <div [ngClass]="{
                            editError: booking.isNotValidValue === true
                          }">
                            <input class="editHour" appHoursLoading [isPercentageSelected]="isPercentageSelected"
                              type="text" [(ngModel)]="
                              isPercentageSelected
                                ? booking.Loading
                                : booking.BookingHours
                            " (focusout)="
                              onFocusOutEvent(
                                $event.target.value,
                                col,
                                booking,
                                element
                              )
                            " (focus)="checkForValidation(element, booking)"
                              (ngModelChange)="checkIfNumbers($event, booking)"
                              [ngModelOptions]="{ standalone: true }" />
                          </div>
                        </div>
                        <div class="weeklyIcon" (click)="onCellClick(element)" [ngClass]="{
                          noNotes:
                            booking.Description?.trim().length === 0 &&
                            booking.IsEdit === false
                        }">
                          <img *ngIf="booking.BookingHours > 0" (click)="showNotes($event, booking, element, col)"
                            src="../../../../{{ imagePath }}Notes.svg" class="notesIcon"
                            [matTooltip]="booking.Description" alt="Notes" />
                        </div>
                      </div>
                    </div>
                  </ng-container>

                </ng-container>
              </div>
            </td>
          </ng-container>
          <ng-container [matColumnDef]="head.displayName" *ngFor="
              let head of availabilityColumns;
              let i = index;
              trackBy: trackByAvailabilty
            " [sticky]="isAvailabiltyHeaderSticky(head.displayName)">
            <th mat-header-cell *matHeaderCellDef [ngStyle]="getHeaderColor(head.displayHours)"
              [attr.colspan]="getColumnSpan(head.displayName)"
              [attr.rowspan]="head.displayName === 'HourCalculation' ? 2 : 1">
              <div *ngIf="head.displayName === 'HourCalculation'" class="hoursLogic">
                <div class="confirmCheckbox" *ngIf="isUnconfirmed || isGhost">
                  <mat-checkbox [(ngModel)]="isConfirmedChecked" (change)="calculateHours(); saveInSession()"
                    [ngModelOptions]="{ standalone: true }">
                    <img src="../../../../{{ imagePath }}Confirmed-white.svg" matTooltip="Confirmed Booking"
                      alt="Confirmed Booking" />Confirmed
                  </mat-checkbox>
                </div>
                <div *ngIf="isUnconfirmed">
                  <mat-checkbox [(ngModel)]="isUnconfirmedChecked" (change)="calculateHours(); saveInSession()"
                    [ngModelOptions]="{ standalone: true }">
                    <img src="../../../../{{ imagePath }}Unconfirmed-white.svg" matTooltip="Unconfirmed Booking"
                      alt="Unconfirmed Booking" />Unconfirmed
                  </mat-checkbox>
                </div>
                <div *ngIf="isGhost">
                  <mat-checkbox [(ngModel)]="isGhostChecked" (change)="calculateHours(); saveInSession()"
                    [ngModelOptions]="{ standalone: true }">
                    <img src="../../../../{{ imagePath }}Ghost booking-white.svg" matTooltip="Ghost Booking"
                      alt="Ghost Booking" />Ghost
                  </mat-checkbox>
                </div>
              </div>
              <div class="hourContainer" *ngIf="
                  head.displayName !== 'HourCalculation' &&
                  head.displayName !== 'Availability Hours'
                " [ngClass]="
                  (this.isPercentageSelected && head.displayPercentage < 0) ||
                  (!this.isPercentageSelected && head.displayHours < 0)
                    ? 'textRed'
                    : ''
                ">
                {{
                this.isPercentageSelected
                ? head.displayPercentage + '%'
                : head.displayHours
                }}
              </div>
              <div *ngIf="head.displayName === 'Availability Hours'" [ngClass]="
                  (this.isPercentageSelected && head.displayPercentage < 0) ||
                  (!this.isPercentageSelected && head.displayHours < 0)
                    ? 'textRed'
                    : ''
                ">
                {{
                this.isPercentageSelected
                ? head.displayPercentage
                : head.displayHours
                }}
              </div>
            </th>
          </ng-container>

          <ng-container [matColumnDef]="header.displayName" *ngFor="
              let header of totalHoursColumns;
              let j = index;
              trackBy: trackByTotalHours
            " [sticky]="isTotalHeaderSticky(header.displayName)">
            <th mat-header-cell *matHeaderCellDef>
              <div class="hourContainer" *ngIf="header.displayName !== 'Total Scheduled Hours'">
                {{
                this.isPercentageSelected
                ? header.displayPercentage  + '%'
                : header.displayHours
                }}
              </div>
              <div *ngIf="header.displayName === 'Total Scheduled Hours'">
                {{
                this.isPercentageSelected
                ? header.displayPercentage
                : header.displayHours
                }}
              </div>
            </th>
          </ng-container>

          <ng-container [matColumnDef]="addCol" *ngFor="let addCol of addDisplayedColumns"
            [sticky]="isStickyAddColumn(addCol)">
            <th mat-header-cell *matHeaderCellDef>
              <div class="addEngContainer" *ngIf="
                  addCol === 'addEngagement Name' &&
                  resourceSelected &&
                  !selectedResource.IsDisabledAddBooking
                ">
                <div class="addRetainContainer" *ngIf="user.IsMultiInstance && user.R_InstEnable_AddBooking">
                  <mat-form-field appearance="outline">
                    <mat-select [(value)]="addBookingInstance" (selectionChange)="onAddBookingInstanceChange($event)"
                      placeholder="Select Retain instance">
                      <mat-option [value]="option.InstanceId" *ngFor="let option of addInstances">
                        {{ option.InstanceName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="searchEngForAdd" [ngClass]="{ fullWidth: !user.R_InstEnable_AddBooking }">
                  <mat-form-field class="resourceSearch" appearance="outline">
                    <input
                      type="text"
                      autocomplete="off"
                      placeholder="Search Engagement"
                      matInput
                      appSearchDebounce
                      (debounceKeyup)="searchEngagement()"
                      (click)="onEngagementClick()"
                      [(ngModel)]="searchAddEngagement"
                      maxlength="100"
                      [ngModelOptions]="{ standalone: true }"
                      (focus)="isAddEnabled()"
                    />
                    <mat-icon (click)="onEngagementClear()">close</mat-icon>
                  </mat-form-field>
                  <!-- for Favourite  Engagement simple --- Start-->
                  <div class="dropdown-list-wrap" *ngIf="showFavouriteEngList">
                    <mat-spinner *ngIf="showEngLoader" [diameter]="40"></mat-spinner>
                    <ul *ngIf="addFavouriteEngResults?.length > 0" class="dropdown-list tabdropdwn_actn">
                      <li class="favouriteText">Favourites</li>

                      <li *ngFor="let item of addFavouriteEngResults" (click)="OnEngagementSelect(item)"
                        (keyup.enter)="OnEngagementSelect(item)" class="dropdown-list-item" tabindex="0" matTooltip="{{item.EngagementFullName}}">
                        <span class="EngFullname">
                          {{ item.EngagementFullName}}
                          
                        </span>

                        <img #Favimage1 (click)="onFavoriteImageClick(item)" class="favouriteIcon exportExcelIcon"
                             src="../../../../{{imagePath}}{{item.favEngSrc}}" />
                      </li>
                    </ul>
                    <div class="noDataDropdown" *ngIf="
                        showEngLoader === false &&
                        addFavouriteEngResults?.length === 0
                      ">
                      No Engagement Found
                    </div>
                  </div>
                  <!-- for Favourite  Engagement --- End-->

                  <div class="dropdown-list-wrap" *ngIf="showEngList">
                    <mat-spinner *ngIf="showEngLoader" [diameter]="40"></mat-spinner>
                    <ul *ngIf="addEngResults?.length > 0" class="dropdown-list tabdropdwn_actn">
                      <li *ngFor="let item of addEngResults" (click)="OnEngagementSelect(item)"
                        (keyup.enter)="OnEngagementSelect(item)" class="dropdown-list-item" tabindex="0" matTooltip="{{item.EngagementFullName}}">
                        <span class="EngFullname">
                          {{ item.EngagementFullName }}
                        </span>
                        <img #Favimage1 (click)="onFavoriteImageClick(item)" class="exportExcelIcon favouriteIcon"
                          src="../../../../{{ imagePath }}{{ item.favEngSrc }}" />
                      </li>
                    </ul>
                    <div class="noDataDropdown" *ngIf="
                        showEngLoader === false && addEngResults?.length === 0
                      ">
                      No Engagement Found
                    </div>
                  </div>
                </div>
              </div>
              <div class="weeklyheader" *ngIf="
                  addCol !== 'addActions' &&
                  addCol !== 'addEngagement Name' &&
                  addCol !== 'addEngagement Category' &&
                  addCol !== 'addRetain Instance'
                ">
                <div [ngClass]="{'confirmColumn' : !(isUnconfirmed && isGhost)}" *ngIf="isUnconfirmed || isGhost">
                  <img src="../../../../{{ imagePath }}Confirmed-black.svg" matTooltip="Confirmed Booking"
                    alt="Confirmed Booking" />
                </div>
                <div class="unconfirmColumn" [ngClass]="{'onlyTwoModes' : !isGhost}" *ngIf="isUnconfirmed">
                  <img src="../../../../{{ imagePath }}Unconfirmed-black.svg" matTooltip="Unconfirmed  Booking"
                    alt="Unconfirmed Booking" />
                </div>
                <div class="ghostColumn" [ngClass]="{'onlyTwoModes' : !isUnconfirmed}" *ngIf="isGhost">
                  <img src="../../../../{{ imagePath }}Ghost booking-black.svg" matTooltip="Ghost Booking"
                    alt="Ghost Booking" />
                </div>
              </div>
            </th>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayAvailabilityHeader; sticky: true" [ngClass]="
              isMultiInstance
                ? 'headerRowAvailability'
                : 'headerRowAvailabilityWithoutInstance'
            "></tr>
          <tr mat-header-row *matHeaderRowDef="displayTotalHourHeader; sticky: true" [ngClass]="
              isMultiInstance
                ? 'headerRowTotal'
                : 'headerRowTotalWithoutInstance'
            "></tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="headerMainRow"></tr>
          <tr mat-header-row *matHeaderRowDef="addDisplayedColumns; sticky: true" class="noPadding"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" class="noPadding"></tr>
        </table>
        <notes-dialog [isNotes]="isNotes" [noteAvailableFor]="noteAvailableFor" (onDialogClose)="notesSubmit($event)">
        </notes-dialog>
        <div class="noDataTable" *ngIf="!dataSource || dataSource?.length === 0">
          Bookings not found
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal for showing table icon infos -->
<ng-template #template1>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Bookings</h4>
    <mat-icon class="close" (click)="modalRef.hide()">close</mat-icon>
  </div>
  <div class="modal-body schedule-info-modal">
    <!-- <h6>Bookings</h6> -->
    <h6>Bookings</h6>

    <table class="info-icon-table">
      <tbody>
        <tr>
          <td class="textInfo">Edit</td>
          <td class="iconData">Clicks on a specific booking cell</td>
        </tr>
        <tr>
          <td class="textInfo">Delete</td>
          <td class="iconData">
            Clicks on a specific booking cell and remove the booking
            hours/Loading %
          </td>
        </tr>
        <tr>
          <td class="textInfo">New Booking</td>
          <td class="iconData">
            Select an engagement, and then a new booking row is created for that
            engagement to enter the schedules
          </td>
        </tr>
        <tr>
          <td class="textInfo">
            <img src="../../../../{{ imagePath }}TableIcons/delete-gray.svg" alt="Delete" />
          </td>
          <td class="iconData">
            Deletes the entire engagement bookings of the selected Individual
          </td>
        </tr>
        <tr>
          <td class="textInfo">
            <img src="../../../../{{ imagePath }}TableIcons/replace-gray.png" alt="Replace" />
          </td>
          <td class="iconData">
            Replace some or all the bookings from either one resource to another
            and/or one engagement to another
          </td>
        </tr>
        <tr>
          <td class="textInfo">
            <img src="../../../../{{ imagePath }}TableIcons/copy-gray.svg" alt="Copy" />
          </td>
          <td class="iconData">
            Copy schedule details from an existing booking and creates a new
            booking row
          </td>
        </tr>
      </tbody>
    </table>
    <div class="wallChartDescription">
      <h6>Color Schemes:</h6>
    </div>

    <table class="wallChartInfoTable">
      <tr>
        <th>Engagement Category</th>
        <th>Confirmed</th>
        <th>Unconfirmed*</th>
      </tr>
      <tr>
        <td>Chargeable</td>
        <td><span class="colorRect blueLabel"></span></td>
        <td><span class="colorRect lightBlueLabel"></span></td>
      </tr>
      <tr>
        <td>Authorised Project</td>
        <td><span class="colorRect yellowLabel"></span></td>
        <td><span class="colorRect lightYellowLabel"></span></td>
      </tr>
      <tr>
        <td>Non-Chargeable</td>
        <td><span class="colorRect greenLabel"></span></td>
        <td><span class="colorRect lightGreenLabel"></span></td>
      </tr>
      <tr>
        <td>Training Courses from Success Factors</td>
        <td><span class="colorRect purpleLabel"></span></td>
        <td></td>
      </tr>
      <tr>
        <td>Bookings that are in Submitted Or InProgress state</td>
        <td><span class="colorRect greyLabel"></span></td>
        <td></td>
      </tr>
      <tr>
        <td>Requests that are in Pending Approval*</td>
        <td><span class="colorRect lightGreyLabel"></span></td>
        <td></td>
      </tr>
      <tr>
        <td>Ghost Booking*</td>
        <td><span class="colorRect whiteLabel"></span></td>
        <td><span class="colorRect whiteLabel"></span></td>
      </tr>
    </table>

    <div class="lineMargin">
      *Unconfirmed, Ghost and Pending Approval bookings will be visible based on
      your access role configuration.
    </div>
    <div class="lineMargin">
      Total Scheduled Hours = Sum of booked hours of that week.
    </div>
    <div class="lineMargin">
      Availability = Total Scheduled Hours - Standard diary hours
    </div>
    <div class="lineMargin">
      When booked hours is greater than the standard diary hours then the
      conflict (overallocated) is highlighted in red
    </div>
  </div>
</ng-template>

<ng-template #individualForecastInfo>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Individual Forecast</h4>
    <mat-icon class="close" (click)="modalRef.hide()">close</mat-icon>
  </div>
  <div class="modal-body">
    <div>
      <h6>The following Individual information is displayed:</h6>
    </div>
    <div class="iconSection text-margin">
      <span class="iconData"> &lt;Resource Name&gt; - &lt;GPN&gt; </span>
    </div>
    <div class="iconSection text-margin">
      <span class="iconData"> &lt;Rank&gt; </span>
    </div>
    <div class="iconSection text-margin">
      <span class="iconData">
        &lt;Res. ServiceLine&gt; - &lt;Res. Country&gt;
      </span>
    </div>
    <div class="iconSection text-margin">
      <span class="iconData"> &lt;Res. Office Location&gt; </span>
    </div>
    <div class="iconSection text-margin">
      <span class="iconData"> &lt;Res. Cost Center&gt; </span>
    </div>
  </div>
</ng-template>

<ng-template #userConfirmation>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title pull-left">{{ modelData.header }}</h4>
    </div>
    <div class="modal-body">
      <div class="popupLayout">
        <div class="fullWidth" [innerHTML]="modelData.text"></div>
        <div class="confirmationButtons" *ngIf="modelData.isConfirmRequired">
          <button class="cancelBtn" (click)="cancelConfirm()">
            {{ modelData.cancelText }}
          </button>
          <button class="deleteBtn" (click)="okayConfirm()">
            {{ modelData.yesText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>