import {
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { RoleApprovalConfigurationVM } from 'app/Models/RoleApprovalConfigurationVM';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-approval-layer-configuration',
  templateUrl: './approval-layer-configuration.component.html',
  styleUrls: ['./approval-layer-configuration.component.scss'],
})
export class ApprovalLayerConfigurationComponent implements OnInit, OnChanges {
  @Input() arrData: RoleApprovalConfigurationVM[] = [];
  @Input() showAddBtn: boolean;
  @Input() actionDescList;
  @Input() jobCategoryList;
  @Output() roleApprovalArr = new EventEmitter<RoleApprovalConfigurationVM[]>();
  toggleAddEditMode: boolean = false;
  toggleAddEditModeIndex: number = null;
  displayedColumns: string[] = [
    'engagementCategory',
    'action',
    'overAllocation',
    'currentWeek',
    'currentWeekPastDate',
    'nextWeek',
    'greaterThanTwoWeeks',
    'Actions',
  ];
  origCopy: RoleApprovalConfigurationVM[] = [];
  modelAddDataRow: RoleApprovalConfigurationVM;
  modalOption: ModalOptions = {};
  modalRef: BsModalRef;
  confirmationClickAction: string = 'delete';
  delIndex: number = null;
  selectdAry:any[]=[];
  prevSelctdEngCatgry:any;
  prevSelctdAction:any;
  disbleSaveFlg:boolean=false;
  actionChnaged=false;
  categyChnage=false;
  editFlgVal=false;
  editIndex:number;
  constructor(private modalService: BsModalService) {}

  ngOnInit() {
    this.clearModelAddDataRow();
    this.emitArrData();
  }

  ngOnChanges() {
    //  if (this.actionDescList?.length && this.jobCategoryList?.length)
    //    console.log('onchange', this.actionDescList, this.jobCategoryList);
  }

  openConfirmationModal(template, confirmationAction, delIndex: number) {
    this.delIndex = delIndex;
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.confirmationClickAction = confirmationAction;
    this.modalRef = this.modalService.show(template, this.modalOption);
  }

  onAddActionRow(editIndex?: number): void {
    this.modelAddDataRow = new RoleApprovalConfigurationVM();
    this.toggleAddEditModeIndex = editIndex === undefined ? null : editIndex;
    this.origCopy = JSON.parse(JSON.stringify(this.arrData));
    if (!this.toggleAddEditMode) {
      this.toggleAddEditMode = true;
      if (editIndex === undefined)
        this.arrData = [...this.arrData, this.modelAddDataRow];
      else this.arrData = [...this.arrData];
    }
  }

  onEditApprovalLayerItem(index: number) {
    this.editFlgVal=true;
    this.editIndex=index;
    this.actionChnaged=true;
    this.categyChnage=true;
    this.onAddActionRow(index);
  }

  onDeleteApprovalLayerItem(): void {
    if (this.delIndex !== null) this.arrData.splice(this.delIndex, 1);
    if (this.delIndex !== null) this.selectdAry.splice(this.delIndex, 1);
    this.disbleSaveFlg=false;
    this.arrData = [...this.arrData];
    this.modalRef.hide();
    this.delIndex = null;
    this.emitArrData();
  }

  enableSubmitBtn(): boolean {
    return this.arrData.some((item) => !item.RAC_ActionID || !item.RAC_JC_ID);
  }

  onCancel(): void {
    this.toggleAddEditMode = false;
    this.disbleSaveFlg=false;
    this.actionChnaged=false;
    this.categyChnage=false;
    this.arrData = JSON.parse(JSON.stringify(this.origCopy));
    this.toggleAddEditModeIndex = null;
  }

  showEditable(index: number): boolean {
    const arrLengthLess1 = this.arrData?.length - 1;
    return this.toggleAddEditModeIndex !== null
      ? index === this.toggleAddEditModeIndex && this.toggleAddEditMode
      : index === arrLengthLess1 && this.toggleAddEditMode;
  }

  onSubmit(): void {
    this.toggleAddEditMode = false;
    this.disbleSaveFlg=false;
    this.actionChnaged=false;
    this.categyChnage=false;
    this.editFlgVal=false;
    this.clearModelAddDataRow();
    this.emitArrData();
  }

  emitArrData(): void {
    this.roleApprovalArr.emit(this.arrData);
  }

  findName(whichList, field): string {
    return whichList.find((item) => item.Id === field)?.Text;
  }

  clearModelAddDataRow(): void {
    this.modelAddDataRow = null;
  }

  
  onCategoryChange(e,index){
     this.categyChnage=true;
     this.prevSelctdEngCatgry = e.value;
     this.prevSelctdAction= this.actionChnaged ? this.prevSelctdAction : '';
     const selectdAry=[...this.arrData];
     if(selectdAry.length > 1 && this.editFlgVal){
      selectdAry.splice(this.editIndex,1)
       const editDuplicate=selectdAry.find(
        item => (item.RAC_JC_ID == this.prevSelctdEngCatgry && item.RAC_ActionID == this.prevSelctdAction)
      );
      this.disbleSaveFlg= (editDuplicate != undefined) ? true :false ;
     }else if(index > 0){
      const duplicate = selectdAry.splice(0,index).find(
              item => (item.RAC_JC_ID == this.prevSelctdEngCatgry && item.RAC_ActionID == this.prevSelctdAction)
            );
      this.disbleSaveFlg= (duplicate != undefined) ? true :false ;
     }
     
  }
  onActionChange(e,i){
    this.actionChnaged=true;
    this.prevSelctdEngCatgry= this.categyChnage ? this.prevSelctdEngCatgry : '';
    this.prevSelctdAction=e.value;
    const selectdAry=[...this.arrData];
    if(selectdAry.length > 1 && this.editFlgVal){
      selectdAry.splice(this.editIndex,1);
      const editDuplicate=selectdAry.find(
       item => (item.RAC_JC_ID == this.prevSelctdEngCatgry && item.RAC_ActionID == this.prevSelctdAction)
     );
     this.disbleSaveFlg= (editDuplicate != undefined) ? true :false ;
    }else if(i > 0){
      const duplicate = selectdAry.splice(0,i).find(
              item => (item.RAC_JC_ID == this.prevSelctdEngCatgry && item.RAC_ActionID == this.prevSelctdAction)
            );
      this.disbleSaveFlg= (duplicate != undefined) ? true :false ;
     }
  }

 }
