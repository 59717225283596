import { Component, Input, OnInit } from '@angular/core';
import { CurrentUser } from 'app/Models/CurrentUser';
import { ResourceDto } from 'app/Models/ResourceDto';
import { RoleVM } from 'app/Models/RoleVM';
import { RoleTypeEnum } from 'app/shared/enum';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { OrderPipe } from 'ngx-order-pipe';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { RoleConfigService } from '../role-config.service';

@Component({
  selector: 'app-assign-role',
  templateUrl: './assign-role.component.html',
  styleUrls: ['./assign-role.component.scss'],
})
export class AssignRoleComponent implements OnInit {
  @Input() selectedRole: RoleVM;
  searchBar: string = '';
  resourceList: ResourceDto[] = [];
  selectedResources: ResourceDto[] = [];
  resourceListSubscription: Subscription;
  roleTypeCSP = '';
  isRALoggedIn = false;
  showSearchList: boolean = false;
  showSearchLoader: boolean = false;
  previewGridSource = [];
  displayedColumns: string[] = ['ResourceProfile', 'PresentRole', 'Actions'];
  messageBody = '';
  messageTitle = '';
  modalOption: ModalOptions = {};
  modalRef: BsModalRef;
  respData = [
    {
      ResourceId: 1461950,
      GPN: 'US012361450',
      ResourceName: 'Rao, Shailesh - US012361450',
      RankDescription: 'Administrative Contractor',
      LocationDescription: '9999W Mumbai - Hexaware',
      SLDescription: '09 Core Business Services',
      UpdatedOn: null,
      UpdatedBy: null,
      ResourceEmail: 'Shailesh.Rao@ey.com',
      SubAreaDescription: 'INDIA',
      LegalEntityCode: 'XXL00',
      SSLDescription: '0915 Global Services - IT',
      LoggedInResourceId: 0,
      IsDisabledAddBooking: false,
      ManagerialCountryCode: 'US01',
      SubManagementUnitDescription: '0915852 CBS Finance',
      BusinessUnitDescription: 'US550 Global',
      ResourceType: 'Current employee',
      ResourceStatus: 'Active',
      RoleId: 0,
      RoleName: null,
      RoleTypeId: 0,
      RoleTypeName: null,
      RoleTypeDisplayName: null,
    },
  ];
  constructor(
    private roleConfigurationService: RoleConfigService,
    private orderPipe: OrderPipe,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService
  ) {
    this.roleTypeCSP = RoleTypeEnum.ClientServer;
    if (CurrentUser.RoleTypeName == RoleTypeEnum.ResourceAdmin) {
      this.isRALoggedIn = true;
    }
  }

  ngOnInit(): void {}

  removeSelectedResource(clickedIndex: number) {
    this.selectedResources = this.selectedResources.filter(
      (item) =>
        item.ResourceId !== this.selectedResources[clickedIndex].ResourceId
    );
  }

  assignRole(){
    if ( !this.selectedRole || this.selectedRole.R_ID == 0 ) {
      return;
    }   
    
    if (this.selectedResources.length > 0 
      || this.selectedRole.RoleTypeName == this.roleTypeCSP) {

      this.selectedRole.ResourceDtos = this.selectedResources;   
      this.spinner.show();
      this.roleConfigurationService.assignAccessRoleToResources(this.selectedRole)
      .subscribe(data => {
        if (data != null) {
          if (!data.success) {
            this.messageBody = data.customException;
            this.messageTitle = 'Failed';
          }
          else{            
            this.messageBody = 'Successfully Assigned Access Role to Resources.';
            this.messageTitle = 'Success';
          }
          this.closeMessageDelayed();
          window.scroll(0, 0);
        }
        this.clearValues();
        this.spinner.hide();
      }, err => {
        this.messageBody = 'Unexpected response! Please try after some time.';
        this.messageTitle = 'Failed';
        this.closeMessageDelayed();
        window.scroll(0, 0);
        this.spinner.hide();
      });
    }    
  }

  openConfirmationModal(template) {
    if (this.selectedResources.filter(x => x.RoleId > 0).length > 0) {
      this.modalOption.backdrop = 'static';
      this.modalOption.keyboard = false;
      this.modalRef = this.modalService.show(template, this.modalOption);
    }
    else {
      this.messageBody = "None of the selected resources have present role assigned.";
      this.messageTitle = "Information";
      this.closeMessageDelayed();
    }
  }

  emailMatchedResources() {
    if (
      this.selectedRole &&
      this.selectedRole.R_ID > 0 &&
      this.selectedRole.RoleTypeName == this.roleTypeCSP &&
      this.selectedResources.length == 0
    ) {
      this.selectedRole.ResourceDtos = this.selectedResources;
      this.spinner.show();
      this.roleConfigurationService
        .emailMatchedResourcesForAutoRoleAssignment(this.selectedRole)
        .subscribe(
          (data) => {
            if (data != null) {
              if (!data.success) {
                this.messageBody = data.customException;
                this.messageTitle = 'Failed';
              } else {
                this.messageBody =
                  'Email will be sent to you with the matched resources.';
                this.messageTitle = 'Success';
              }
              this.closeMessageDelayed();
              window.scroll(0, 0);
            }
            this.clearValues();
            this.spinner.hide();
          },
          (err) => {
            this.messageBody =
              'Unexpected response! Please try after some time.';
            this.messageTitle = 'Failed';
            this.closeMessageDelayed();
            window.scroll(0, 0);
            this.spinner.hide();
          }
        );
    }
  }

  closeMessage() {
    this.messageBody = '';
    this.messageTitle = '';
  }

  closeMessageDelayed() {
    setTimeout(() => {
      this.messageBody = '';
      this.messageTitle = '';
    }, 5000);
  }

  search() {
    this.showSearchLoader = true;
    this.showSearchList = true;
    const serachQuery = this.searchBar?.trim();
    if (serachQuery?.length >= 2) {
      let resourceToSearch = new ResourceDto();
      resourceToSearch.ResourceName = serachQuery;
      resourceToSearch.InstanceId = 1;
      this.resourceListSubscription = this.roleConfigurationService
        .searchResourceRole(resourceToSearch)
        .subscribe((data) => {
          this.showSearchLoader = false;
          this.resourceList = data
            ? data
            : this.respData.filter(
                (x) =>
                  (x.ResourceStatus == 'Active' ||
                    x.ResourceStatus == 'Leave of Absence') &&
                  x.ResourceId != CurrentUser.LoggedInResourceId &&
                  (!this.isRALoggedIn ||
                    x.RoleTypeName != RoleTypeEnum.SuperAdmin)
              );
          this.resourceList = this.orderPipe.transform(
            this.resourceList,
            'ResourceName'
          );
        });
    } else {
      this.showSearchLoader = false;
      this.showSearchList = false;
      this.resourceList = [];
    }
  }

  reset() {
    this.clearValues();
  }

  clearValues() {
    this.resourceList = [];
    this.selectedResources = [];
    this.searchBar = '';
  }

  onItemSelect(event) {
    if(this.selectedResources.find(x => x.ResourceId == event.ResourceId) == null) {
      // this.selectedResources.push(event);
      this.selectedResources = [...this.selectedResources, event];
    }
    this.showSearchList = false;
    this.showSearchLoader = false;
    this.searchBar = '';
  }

  resetfilterBookingGridSearchBar() {}
  onConfirmAction() {
    this.modalRef.hide();
    this.deleteRole();
  }

  deleteRole() {
    if (this.selectedResources.length > 0) {
      let roleVM = new RoleVM();
      roleVM.ResourceDtos = this.selectedResources;
      this.spinner.show();
      this.roleConfigurationService.deleteAccessRoleOfResources(roleVM)
        .subscribe(data => {
          if (data != null) {
            if (!data.success) {
              this.messageBody = data.customException;
              this.messageTitle = 'Failed';
            }
            else {
              this.messageBody = 'Successfully deleted Access Role of Resources.';
              this.messageTitle = 'Success';
            }
            this.closeMessageDelayed();
            window.scroll(0, 0);
          }
          this.clearValues();
          this.spinner.hide();
        }, err => {
          this.messageBody = 'Unexpected response! Please try after some time.';
          this.messageTitle = 'Failed';
          this.closeMessageDelayed();
          window.scroll(0, 0);
          this.spinner.hide();

        });

    }

  }
}
