import { Directive, OnInit, OnDestroy, HostListener, EventEmitter, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Directive({
  selector: '[appSearchDebounce]'
})
export class SearchDebounceDirective implements OnInit, OnDestroy {
  @Output() debounceKeyup = new EventEmitter();
  private keyUp = new Subject<KeyboardEvent>();
  private subscription: Subscription;
  private debounceTime = 1000;
  constructor() { }

  ngOnInit(): void {
    this.subscription = this.keyUp
      .pipe(debounceTime(this.debounceTime),
        distinctUntilChanged())
      .subscribe(e => this.debounceKeyup.emit(e));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('keyup', ['$event'])
  clickEvent(event): any {
    event.preventDefault();
    event.stopPropagation();
    this.keyUp.next(event);
  }

}
