import { Component, OnInit, OnDestroy, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { Subscription } from 'rxjs';
import { MyRequest_RequestByMeVM, FilterParamsByMe } from '../../../Models/MyRequest_RequestByMeVM';
import { RequestQueueService } from '../request-queue.service';
import { MyRequest_RequestForMeVM, FilterParamsForMe } from '../../../Models/MyRequest_RequestForMeVM';
import {  MyRequest_ApproveByMeVM, FilterParamsApprove } from '../../../Models/MyRequest_ApproveByMeVM';
import { ModalOptions, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BookingGridActionService } from '../../../Models/BookingGridActionService';
import { Router } from '@angular/router';
import { SharedServicesService } from '../../../shared/sharedservices/shared-services.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, FormGroup } from '@angular/forms';
import { UserVM } from 'app/Models/UserVM';
import * as _moment from 'moment';
import { formatDate } from "@angular/common";
import { RoleTypeEnum } from 'app/shared/enum';
import { ExcelService } from 'app/shared/excel/excel.service';

@Component({
  selector: 'app-request-queue',
  templateUrl: './request-queue.component.html',
  styleUrls: ['./request-queue.component.scss']
})

export class RequestQueueComponent implements OnInit, OnDestroy {
  displayedColumnsByMe: string[] = ['RequestId', 'ActionId', 'Action', 'ResourceName','Engagement', 'StartDate', 'EndDate', 'Hours', 'Loading','UserSelection', 'Status', 'RejectionReason', 'ApproverComment','ApprovedOrRejectedBy','ApprovedOrRejectedOn','RequestedDateTime', 'Cancel'];
  excelColumnsByMe = [
    {Key:'RequestId', value:'Request ID'}, 
    {Key:'ActionId', value:'Action ID'}, 
    {Key:'Action', value:'Action'},
    {Key:'ResourceName', value:'Resource Name'},
    {Key:'Engagement', value:'Engagement'},
    {Key:'InstanceName', value:'Retain Instance'},
    {Key:'StartDate', value:'Start Date'},
    {Key:'EndDate', value:'End Date'}, 
    {Key:'Hours', value:'Hours'}, 
    {Key:'Loading', value:'Loading %'}, 
    {Key:'Ghost', value:'Ghost'},
    {Key:'UserSelection', value:'User Selection'},
    {Key:'Unconfirmed', value:'Unconfirmed'},
    {Key:'Status', value:'Status'},
    {Key:'RejectionReason', value:'Status Information'},
    {Key:'ApproverComment', value:'Approver Comment'}, 
    {Key:'ApprovedOrRejectedBy', value:'Approved/Rejected By'},
    {Key:'ApprovedOrRejectedOn', value:'Approved/Rejected On'},
    {Key:'RequestedDateTime', value:'Requested Date/Time(GMT)'},
 ]
  filterColumnsByMe: string[] = ['RequestIdFilter', 'ActionIdFilter' , 'ActionFilter', 'ResourceNameFilter', 'EngagementFilter', 'StartDateFilter', 'EndDateFilter', 'HoursFilter','LoadingFilter','UserSelectionFilter','StatusFilter','RejectionReasonFilter','ApprovedOrRejectedByFilter','ApprovedOrRejectedOnFilter','RequestedDateTimeFilter','CancelFilter']
  dataSourceByMe: MatTableDataSource<MyRequest_RequestByMeVM>;
  displayedColumnsForMe: string[] = ['RequestId', 'ActionId', 'Action','Engagement','StartDate', 'EndDate', 'Hours', 'Loading','UserSelection', 'Status', 'RejectionReason', 'ApproverComment','ApprovedOrRejectedBy','ApprovedOrRejectedOn','ResourceName','RequestedDateTime'];
  excelColumnsForMe = [
    {Key:'RequestId', value:'Request ID'}, 
    {Key:'ActionId', value:'Action ID'}, 
    {Key:'Action', value:'Action'},
    {Key:'Engagement', value:'Engagement'},
    {Key:'InstanceName', value:'Retain Instance'},
    {Key:'StartDate', value:'Start Date'},
    {Key:'EndDate', value:'End Date'}, 
    {Key:'Hours', value:'Hours'}, 
    {Key:'Loading', value:'Loading %'}, 
    {Key:'Ghost', value:'Ghost'},
    {Key:'UserSelection', value:'User Selection'},
    {Key:'Unconfirmed', value:'Unconfirmed'},
    {Key:'Status', value:'Status'},
    {Key:'RejectionReason', value:'Status Information'},
    {Key:'ApproverComment', value:'Approver Comment'}, 
    {Key:'ApprovedOrRejectedBy', value:'Approved/Rejected By'},
    {Key:'ApprovedOrRejectedOn', value:'Approved/Rejected On'},
    {Key:'ResourceName', value:'Requested By'},
    {Key:'RequestedDateTime', value:'Requested Date/Time(GMT)'},
 ]
  filterColumnsForMe: string[] = ['RequestIdFilter', 'ActionIdFilter', 'ActionFilter','EngagementFilter','StartDateFilter', 'EndDateFilter', 'HoursFilter', 'LoadingFilter','UserSelectionFilter', 'StatusFilter', 'RejectionReasonFilter','ApprovedOrRejectedByFilter','ApprovedOrRejectedOnFilter','ResourceNameFilter','RequestedDateTimeFilter'];
  displayedColumnsApprove: string[] = ['RequestId', 'ActionId', 'Action','ResourceName','Engagement','StartDate', 'EndDate', 'Hours', 'Loading','UserSelection', 'Status', 'RejectionReason', 'ApproverComment','RequestorName','RequestedDateTime','ApprovedDateTime'];
  excelColumnsApproveOrReject = [
    {Key:'RequestId', value:'Request ID'}, 
    {Key:'ActionId', value:'Action ID'}, 
    {Key:'Action', value:'Action'},
    {Key:'ResourceName', value:'Resource'},
    {Key:'Engagement', value:'Engagement'},
    {Key:'InstanceName', value:'Retain Instance'},
    {Key:'StartDate', value:'Start Date'},
    {Key:'EndDate', value:'End Date'}, 
    {Key:'Hours', value:'Hours'}, 
    {Key:'Loading', value:'Loading %'}, 
    {Key:'Ghost', value:'Ghost'},
    {Key:'UserSelection', value:'User Selection'},
    {Key:'Unconfirmed', value:'Unconfirmed'},
    {Key:'Status', value:'Status'},
    {Key:'RejectionReason', value:'Status Information'},
    {Key:'ApproverComment', value:'Approver Comment'}, 
    {Key:'RequestorName', value:'Requestor Name'}, 
    {Key:'RequestedDateTime', value:'Requested Date/Time(GMT)'},
    {Key:'ApprovedDateTime', value:'Approved/Rejected Date/Time (GMT)'}
 ]
  dataSourceForMe: MatTableDataSource<MyRequest_RequestForMeVM>;
  dataSourceApproveByMe: MatTableDataSource<MyRequest_ApproveByMeVM>;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChild('empTbSort_byme') empTbSort_byme = new MatSort();
  @ViewChild('empTbSort_forme') empTbSort_forme = new MatSort();
  @ViewChild('empTbSort_aprvRej') empTbSort_aprvRej = new MatSort();
  activeTabName = 'MyRequest';
  showModal = false;
  showModalDelete = false;
  modalOption: ModalOptions = {};
  modalRef: BsModalRef;
  gridActionEventData: BookingGridActionService;
  gridActionModalHeader = '';
  requestByMeList: MyRequest_RequestByMeVM[];
  //requestByMe: MyRequest_RequestByMeVM;
  requestByMeListSubscription: Subscription;
  requestForMeList: MyRequest_RequestForMeVM[];
  //requestForMe: MyRequest_RequestForMeVM;
  requestForMeListSubscription: Subscription;
  approvalListByMe:  MyRequest_ApproveByMeVM[];
  approveRequestForMe:  MyRequest_ApproveByMeVM;
  aproveListSubscription: Subscription;
  contains: boolean = true;
  // currentUserEmail: any;
  // resourceEmail: string;
  requestIdFilter = new FormControl('');
  actionFilter = new FormControl('');
  resourceNameFilter = new FormControl('');
  engagementFilter = new FormControl('');
  statusFilter = new FormControl('');
  keywordByME = new FormControl('');
  requestIdFilterForMe = new FormControl('');
  actionFilterForMe = new FormControl('');
  resourceNameFilterForMe = new FormControl('');
  engagementFilterForMe = new FormControl('');
  statusFilterForMe = new FormControl('');
  keywordForME = new FormControl('');
  requestIdFilterApproval = new FormControl('');
  actionFilterApproval = new FormControl('');
  resourceNameFilterApproval = new FormControl('');
  engagementFilterApproval = new FormControl('');
  statusFilterApproval = new FormControl('');
  keywordApproveByME = new FormControl('');
  maxRequestedDate: Date = new Date();
  filterValuesByMe = {
    requestId: '',
    requestIdType: true,
    action: '',
    actionType: true,
    resourceName: '',
    resourceNameType: true,
    engagement: '',
    engagementType: true,
    status: '',
    statusType: true,
    global: '',
    globalType: false
  };
  filterValuesForMe = {
    requestId: '',
    requestIdType: true,
    action: '',
    actionType: true,
    resourceName: '',
    resourceNameType: true,
    engagement: '',
    engagementType: true,
    status: '',
    statusType: true,
    global: '',
    globalType: false
  };
  filterValuesApprovalByMe = {
    requestId: '',
    requestIdType: true,
    action: '',
    actionType: true,
    resourceName: '',
    resourceNameType: true,
    engagement: '',
    engagementType: true,
    status: '',
    statusType: true,
    global: '',
    globalType: false
  };
  loggedInUser: UserVM;
  defaultDateFormat: string;
  isRequestByMeExpanded : boolean = false;
  isRequestForMeExpanded : boolean = false;
  isApproveByMeExpanded : boolean = false;
  isMobileDevice : boolean = false;
  isApprovedByMeSectionRequired: boolean = false;
  requestedOnRangeByMe = new FormGroup({
    start: new FormControl(''),
    end: new FormControl(''),
  });
  requestedOnRangeForMe = new FormGroup({
    start: new FormControl(''),
    end: new FormControl(''),
  });
  startRequestedOnForMe: null;
  endRequestedOnForMe: null;
  requestedOnRangeApprove = new FormGroup({
    start: new FormControl(''),
    end: new FormControl(''),
  });
  startRequestedOnApprove: null;
  endRequestedOnApprove: null;
  filterParamsByMe : FilterParamsByMe;
  filterParamsForMe : FilterParamsForMe;
  filterParamsApprove : FilterParamsApprove;
  constructor(public restService: RequestQueueService,
    private modalService: BsModalService,
    private router: Router,
    public sharedService: SharedServicesService,
    private excelService: ExcelService,) {
    this.sharedService.activeLink = 'MyRequest';
    this.isMobileDevice = sessionStorage.getItem('requestMode') === '1' ? true : false;
  }
   

  ngOnInit() {
    this.isMobileDevice = sessionStorage.getItem('requestMode') === '1' ? true : false;
    this.loggedInUser = JSON.parse(sessionStorage.getItem('resource'));
    this.defaultDateFormat = this.sharedService.getDateFormat(this.loggedInUser.DefaultDateFormat);
    if(this.loggedInUser.RoleType == RoleTypeEnum.ResourceAdmin || this.loggedInUser.RoleType == RoleTypeEnum.SuperAdmin )
    {
      this.isApprovedByMeSectionRequired=true;
    }
    if (this.loggedInUser.Ghost) {
      this.displayedColumnsByMe.splice(this.displayedColumnsByMe.indexOf('Status') - 1, 0, 'Ghost');
      this.displayedColumnsForMe.splice(this.displayedColumnsForMe.indexOf('Status') - 1, 0, 'Ghost');
      this.displayedColumnsApprove.splice(this.displayedColumnsApprove.indexOf('Status') - 1, 0, 'Ghost');
      // this.filterColumnsByMe.splice(this.filterColumnsByMe.indexOf('StatusFilter') - 1, 0, 'GhostFilter');
      // this.filterColumnsForMe.splice(this.filterColumnsByMe.indexOf('StatusFilter') - 1, 0, 'GhostFilter');
    }

    if (this.loggedInUser.IsMultiInstance) {
      this.displayedColumnsByMe.splice(this.displayedColumnsByMe.indexOf('Engagement') + 1, 0, 'InstanceName');
      this.displayedColumnsForMe.splice(this.displayedColumnsForMe.indexOf('Engagement') + 1, 0, 'InstanceName');
      this.displayedColumnsApprove.splice(this.displayedColumnsApprove.indexOf('Engagement') + 1, 0, 'InstanceName');
      // this.filterColumnsByMe.splice(this.filterColumnsByMe.indexOf('EngagementFilter') + 1, 0, 'InstanceNameFilter');
      // this.filterColumnsForMe.splice(this.filterColumnsByMe.indexOf('EngagementFilter') + 1, 0, 'InstanceNameFilter');
    }
    if(!this.loggedInUser.Unconfirmed){
      this.displayedColumnsByMe.splice(this.displayedColumnsByMe.indexOf('UserSelection')+1, 0,'Unconfirmed');
      this.displayedColumnsForMe.splice(this.displayedColumnsForMe.indexOf('UserSelection')+1, 0,'Unconfirmed');
      this.displayedColumnsApprove.splice(this.displayedColumnsApprove.indexOf('UserSelection')+1, 0,'Unconfirmed');
      // this.filterColumnsByMe.splice(this.filterColumnsByMe.indexOf('UserSelectionFilter')+1, 0,'UnconfirmedFilter');
      // this.filterColumnsForMe.splice(this.filterColumnsByMe.indexOf('UserSelectionFilter')+1, 0,'UnconfirmedFilter');
    }
    this.sharedService.activeLink = 'MyRequest';
    this.requestByMeList = [];
    this.requestForMeList = [];
    this.gridActionEventData = new BookingGridActionService();
    //this.currentUserEmail = this.loggedInUser.Email;
    this.filterParamsByMe = new FilterParamsByMe();
    this.filterParamsForMe = new FilterParamsForMe();
    this.filterParamsApprove = new FilterParamsApprove();
    this.restService.currentMessageCloseModal.subscribe((refresh) => {
      this.closeActionModal(refresh);
    });
    
    this.setDefaultData();

    this.requestIdFilter.valueChanges
      .subscribe(
        requestId => {
          this.filterValuesByMe.requestId = requestId;
          this.dataSourceByMe.filter = JSON.stringify(this.filterValuesByMe);
        }
      )
    this.actionFilter.valueChanges
      .subscribe(
        action => {
          this.filterValuesByMe.action = action;
          this.dataSourceByMe.filter = JSON.stringify(this.filterValuesByMe);
        }
      )
    this.resourceNameFilter.valueChanges
      .subscribe(
        resourceName => {
          this.filterValuesByMe.resourceName = resourceName;
          this.dataSourceByMe.filter = JSON.stringify(this.filterValuesByMe);
        }
      )
    this.engagementFilter.valueChanges
      .subscribe(
        engagement => {
          this.filterValuesByMe.engagement = engagement;
          this.dataSourceByMe.filter = JSON.stringify(this.filterValuesByMe);
        }
      )
    this.statusFilter.valueChanges
      .subscribe(
        status => {
          this.filterValuesByMe.status = status;
          this.dataSourceByMe.filter = JSON.stringify(this.filterValuesByMe);
        }
      )
      this.keywordByME.valueChanges.subscribe(
        global => { 
          if(global.trim().length > 0){
            this.filterValuesByMe.globalType = true;
          }else{
            this.filterValuesByMe.globalType = false;
          }
          this.filterValuesByMe.global = global;
          this.dataSourceByMe.filter = JSON.stringify(this.filterValuesByMe);
        }
      )
    this.requestIdFilterForMe.valueChanges
      .subscribe(
        requestId => {
          this.filterValuesForMe.requestId = requestId;
          this.dataSourceForMe.filter = JSON.stringify(this.filterValuesForMe);
        }
      )
    this.actionFilterForMe.valueChanges
      .subscribe(
        action => {
          this.filterValuesForMe.action = action;
          this.dataSourceForMe.filter = JSON.stringify(this.filterValuesForMe);
        }
      )
    this.resourceNameFilterForMe.valueChanges
      .subscribe(
        resourceName => {
          this.filterValuesForMe.resourceName = resourceName;
          this.dataSourceForMe.filter = JSON.stringify(this.filterValuesForMe);
        }
      )
    this.engagementFilterForMe.valueChanges
      .subscribe(
        engagement => {
          this.filterValuesForMe.engagement = engagement;
          this.dataSourceForMe.filter = JSON.stringify(this.filterValuesForMe);
        }
      )
    this.statusFilterForMe.valueChanges
      .subscribe(
        status => {
          this.filterValuesForMe.status = status;
          this.dataSourceForMe.filter = JSON.stringify(this.filterValuesForMe);
        }
      )
      this.keywordForME.valueChanges.subscribe(
        global => { 
          if(global.trim().length > 0){
            this.filterValuesForMe.globalType = true;
          }else{
            this.filterValuesForMe.globalType = false;
          }
          this.filterValuesForMe.global = global;
          this.dataSourceForMe.filter = JSON.stringify(this.filterValuesForMe);
        }
      )
      this.requestIdFilterApproval.valueChanges
      .subscribe(
        requestId => {
          this.filterValuesApprovalByMe.requestId = requestId;
          this.dataSourceApproveByMe.filter = JSON.stringify(this.filterValuesApprovalByMe);
        }
      )
    this.actionFilterApproval.valueChanges
      .subscribe(
        action => {
          this.filterValuesApprovalByMe.action = action;
          this.dataSourceApproveByMe.filter = JSON.stringify(this.filterValuesApprovalByMe);
        }
      )
    this.resourceNameFilterApproval.valueChanges
      .subscribe(
        resourceName => {
          this.filterValuesApprovalByMe.resourceName = resourceName;
          this.dataSourceApproveByMe.filter = JSON.stringify(this.filterValuesApprovalByMe);
        }
      )
    this.engagementFilterApproval.valueChanges
      .subscribe(
        engagement => {
          this.filterValuesApprovalByMe.engagement = engagement;
          this.dataSourceApproveByMe.filter = JSON.stringify(this.filterValuesApprovalByMe);
        }
      )
    this.statusFilterApproval.valueChanges
      .subscribe(
        status => {
          this.filterValuesApprovalByMe.status = status;
          this.dataSourceApproveByMe.filter = JSON.stringify(this.filterValuesApprovalByMe);
        }
      )
      this.keywordApproveByME.valueChanges.subscribe(
        global => { 
          if(global.trim().length > 0){
            this.filterValuesApprovalByMe.globalType = true;
          }else{
            this.filterValuesApprovalByMe.globalType = false;
          }
          this.filterValuesApprovalByMe.global = global;
          this.dataSourceApproveByMe.filter = JSON.stringify(this.filterValuesApprovalByMe);
        }
      )
  }
  
  ngOnDestroy() {
    if (this.requestByMeListSubscription !== undefined) {
      this.requestByMeListSubscription.unsubscribe();
    }
    if (this.requestForMeListSubscription !== undefined) {
      this.requestForMeListSubscription.unsubscribe();
    }
    if (this.aproveListSubscription !== undefined) {
      this.aproveListSubscription.unsubscribe();
    }
    
  }

  setDefaultData() {
    // this.resourceEmail = this.currentUserEmail;
    // this.requestByMe = new MyRequest_RequestByMeVM();
    // this.requestForMe = new MyRequest_RequestForMeVM();
    this.getRequestByMeList();
    this.getRequestForMeList();
    this.getApprovalList();
  }

  getRequestByMeList() {
    this.dataSourceByMe = new MatTableDataSource();
    //this.requestByMe.RequestByEmail = this.currentUserEmail;
    this.requestByMeListSubscription = this.restService.getRequestByMeList(this.filterParamsByMe.RequestedDateFrom, this.filterParamsByMe.RequestedDateTo).subscribe(
      data => {
        this.requestByMeList = data;
        this.requestByMeList.forEach((element) => {
          const moment = _moment;
          element.startDate_sort=element.StartDate;
          element.endDate_sort=element.EndDate;
          element.RequestedDateTime_sort = element.RequestedDateTime;
          element.ApprovedOrRejectedOn_sort = element.ApprovedOrRejectedOn;
          
          // element.StartDate = formatDate(element.StartDate, this.defaultDateFormat, 'en-US');
          // element.EndDate = formatDate(element.EndDate, this.defaultDateFormat, 'en-US');
          // element.RequestedDateTime = formatDate(element.RequestedDateTime, `${this.defaultDateFormat} HH:mm`, 'en-US');
          // if(element.ApprovedOrRejectedOn && element.ApprovedOrRejectedOn.length > 0){
          //   element.ApprovedOrRejectedOn =  formatDate(element.ApprovedOrRejectedOn, `${this.defaultDateFormat} HH:mm`, 'en-US');
          // }
           element.Unconfirmed = element.Unconfirmed?.toString() =='false' ? "No" : "Yes" ;
          //element.Unconfirmed = element.Unconfirmed ;
          element.Ghost = element.Ghost?.toString() === 'false' ? "No" : "Yes" ;
        });
        this.dataSourceByMe = new MatTableDataSource(this.requestByMeList);
        this.empTbSort_byme.disableClear = true;
        this.dataSourceByMe.paginator = this.paginator.toArray()[0];
        this.dataSourceByMe.sort = this.sort.toArray()[0];
        this.dataSourceByMe.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'StartDate': return new Date(item.startDate_sort);
            case 'EndDate' : return new Date(item.endDate_sort);
            case 'RequestedDateTime': return new Date(item.RequestedDateTime_sort);
            case 'ApprovedOrRejectedOn' : return new Date(item.ApprovedOrRejectedOn_sort);
            default: return item[property];
          }
        };
        this.dataSourceByMe.filterPredicate = this.createFilterByMe();
        // if (this.requestByMe.RequestByEmail === null || this.requestByMe.RequestByEmail === undefined) {
        //   this.resourceEmail = this.currentUserEmail;
        // }
        ////moment().format("DD-MM-YYYY, kk:mm");
      });
  }
  createFilterByMe(): (data: any, filter: string) => boolean {
    let filterFunction = function (data, filter): boolean {
        let searchTerms = JSON.parse(filter);
        if(searchTerms.globalType){
          
          return ((data.Action ? data.Action?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
                (data.ActionId ? data.ActionId?.toString().toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
                (data.ApprovedOrRejectedBy ? data.ApprovedOrRejectedBy?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
                (data.ApprovedOrRejectedOn ? data.ApprovedOrRejectedOn?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
                (data.EndDate ? data.EndDate?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false)||
                (data.Engagement ? data.Engagement?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false)||
                (data.Ghost !== null ? data.Ghost?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
                (data.Hours ? data.Hours?.toString().toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false)||
                (data.InstanceName ? data.InstanceName?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false)||
                (data.Loading ? data.Loading?.toString().toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false)||
                (data.RejectionReason ? data.RejectionReason?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false)||
                (data.ApproverComment ? data.ApproverComment?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false)||
                (data.RequestId ? data.RequestId?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false)||
                (data.RequestedDateTime ? data.RequestedDateTime?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false)||
                (data.ResourceName ? data.ResourceName?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false)||
                (data.StartDate ? data.StartDate?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false)||
                (data.Status ? data.Status?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false)||
                (data.Unconfirmed ? data.Unconfirmed?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false)||
                (data.UserSelection ? data.UserSelection?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false));
        }else{
          return ((searchTerms.requestIdType ? data.RequestId?.toLowerCase().indexOf(searchTerms.requestId.toLowerCase()) !== -1 : data.RequestId?.toLowerCase().startsWith(searchTerms.requestId.toLowerCase()) !== false)
          && (searchTerms.actionType ? data.Action?.toLowerCase().indexOf(searchTerms.action.toLowerCase()) !== -1 : data.Action?.toLowerCase().startsWith(searchTerms.action.toLowerCase()) !== false)
          && (searchTerms.resourceNameType ? data.ResourceName?.toLowerCase().indexOf(searchTerms.resourceName.toLowerCase()) !== -1 : data.ResourceName?.toLowerCase().startsWith(searchTerms.resourceName.toLowerCase()) !== false)
          && (searchTerms.engagementType ? data.Engagement?.toLowerCase().indexOf(searchTerms.engagement.toLowerCase()) !== -1 : data.Engagement?.toLowerCase().startsWith(searchTerms.engagement.toLowerCase()) !== false)
          && (searchTerms.statusType ? data.Status?.toLowerCase().indexOf(searchTerms.status.toLowerCase()) !== -1 : data.Status?.toLowerCase().startsWith(searchTerms.status.toLowerCase()) !== false));
        }
       
    }
    return filterFunction;
  }
  clearByMeFilter() {
    this.dataSourceByMe.filter = '';
    this.requestIdFilter.setValue('');
    this.actionFilter.setValue('');
    this.resourceNameFilter.setValue('');
    this.engagementFilter.setValue('');
    this.statusFilter.setValue('');
    this.keywordByME.setValue('');
    this.filterValuesByMe.globalType = false;
    this.filterValuesByMe.actionType = true;
    this.filterValuesByMe.requestIdType = true;
    this.filterValuesByMe.resourceNameType = true;
    this.filterValuesByMe.engagementType = true;
    this.filterValuesByMe.statusType = true;
  }
    
  getRequestForMeList() {
    this.dataSourceForMe = new MatTableDataSource();
    //this.requestForMe.RequestForEmail = this.currentUserEmail;
    this.requestForMeListSubscription = this.restService.getRequestForMeList(this.filterParamsForMe.RequestedDateFrom, this.filterParamsForMe.RequestedDateTo).subscribe(
      data => {
        this.requestForMeList = data;
        this.requestForMeList.forEach((element) => {
          element.startDate_sort=element.StartDate;
          element.endDate_sort=element.EndDate;
          element.RequestedDateTime_sort = element.RequestedDateTime;
          element.ApprovedOrRejectedOn_sort = element.ApprovedOrRejectedOn;
          element.StartDate = formatDate(element.StartDate, this.defaultDateFormat, 'en-US');
          element.EndDate = formatDate(element.EndDate, this.defaultDateFormat, 'en-US');
          element.RequestedDateTime = formatDate(element.RequestedDateTime, `${this.defaultDateFormat} HH:mm`, 'en-US');
          if(element.ApprovedOrRejectedOn && element.ApprovedOrRejectedOn.length > 0){
            element.ApprovedOrRejectedOn =  formatDate(element.ApprovedOrRejectedOn, `${this.defaultDateFormat} HH:mm`, 'en-US');
          }
          // element.Unconfirmed = element.Unconfirmed =="True" ? "Yes" : "No" ;
          element.Unconfirmed = element.Unconfirmed;
          element.Ghost = element.Ghost?.toString() === 'false' ? "No" : "Yes" ;
        });
        this.dataSourceForMe = new MatTableDataSource(this.requestForMeList);
        this.empTbSort_forme.disableClear=true;
        this.dataSourceForMe.paginator = this.paginator.toArray()[1];
        this.dataSourceForMe.sort = this.sort.toArray()[1];
        this.dataSourceForMe.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'StartDate': return new Date(item.startDate_sort);
            case 'EndDate' : return new Date(item.endDate_sort);
            case 'RequestedDateTime': return new Date(item.RequestedDateTime_sort);
            case 'ApprovedOrRejectedOn' : return new Date(item.ApprovedOrRejectedOn_sort);
            default: return item[property];
          }
        };
        this.dataSourceForMe.filterPredicate = this.createFilterForMe();
        // if (this.requestForMe.RequestForEmail === null || this.requestForMe.RequestForEmail === undefined) {
        //   this.resourceEmail = sessionStorage.getItem('resourceEmail');
        // }

      });
  }
  createFilterForMe(): (data: any, filter: string) => boolean {
    let filterFunction = function (data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      if(searchTerms.globalType){
          
        return ((data.Action ? data.Action?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false)||
              (data.ActionId ? data.ActionId?.toString().toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
              (data.ApprovedOrRejectedBy ? data.ApprovedOrRejectedBy?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
              (data.ApprovedOrRejectedOn ? data.ApprovedOrRejectedOn?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
              (data.EndDate ? data.EndDate?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
              (data.Engagement ? data.Engagement?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
              (data.Ghost !== null ? data.Ghost?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
              (data.Hours ? data.Hours?.toString().toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
              (data.InstanceName ? data.InstanceName?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
              (data.Loading ? data.Loading?.toString().toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
              (data.RejectionReason ? data.RejectionReason?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false)||
              (data.ApproverComment ? data.ApproverComment?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false)||
              (data.RequestId ? data.RequestId?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false)||
              (data.RequestedDateTime ? data.RequestedDateTime?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false)||
              (data.RequestedBy ? data.RequestedBy?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false)||
              (data.StartDate ? data.StartDate?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false)||
              (data.Status ? data.Status?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false)||
              (data.Unconfirmed ? data.Unconfirmed?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false)||
              (data.UserSelection ? data.UserSelection?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false));
      }else{
      return ((searchTerms.requestIdType ? data.RequestId?.toLowerCase().indexOf(searchTerms.requestId.toLowerCase()) !== -1 : data.RequestId?.toLowerCase().startsWith(searchTerms.requestId.toLowerCase()) !== false)
        && (searchTerms.actionType ? data.Action?.toString().toLowerCase().indexOf(searchTerms.action.toLowerCase()) !== -1 : data.Action?.toString().toLowerCase().startsWith(searchTerms.action.toLowerCase()) !== false)
        && (searchTerms.resourceNameType ? data.RequestedBy?.toLowerCase().indexOf(searchTerms.resourceName.toLowerCase()) !== -1 : data.RequestedBy?.toLowerCase().startsWith(searchTerms.resourceName.toLowerCase()) !== false)
        && (searchTerms.engagementType ? data.Engagement?.toLowerCase().indexOf(searchTerms.engagement.toLowerCase()) !== -1 : data.Engagement?.toLowerCase().startsWith(searchTerms.engagement.toLowerCase()) !== false)
        && (searchTerms.statusType ? data.Status?.toLowerCase().indexOf(searchTerms.status.toLowerCase()) !== -1 : data.Status?.toLowerCase().startsWith(searchTerms.status.toLowerCase()) !== false));
      }
    }
    return filterFunction;
  }
  clearForMeFilter() {
    this.dataSourceForMe.filter = '';
    this.requestIdFilterForMe.setValue('');
    this.actionFilterForMe.setValue('');
    this.resourceNameFilterForMe.setValue('');
    this.engagementFilterForMe.setValue('');
    this.statusFilterForMe.setValue('');
    this.keywordForME.setValue('');
    this.filterValuesForMe.globalType = false;
    this.filterValuesForMe.actionType = true;
    this.filterValuesForMe.requestIdType = true;
    this.filterValuesForMe.resourceNameType = true;
    this.filterValuesForMe.engagementType = true;
    this.filterValuesForMe.statusType = true;
  }

  getApprovalList() {
    this.dataSourceApproveByMe = new MatTableDataSource();
    this.aproveListSubscription = this.restService.getApproveRequestsByMeList(this.filterParamsApprove.RequestedDateFrom, this.filterParamsApprove.RequestedDateTo).subscribe(
      data => {
      
        this.approvalListByMe = data;
        this.approvalListByMe.forEach((element) => {
          element.startDate_sort=element.StartDate;
          element.endDate_sort=element.EndDate;
          element.RequestedDateTime_sort = element.RequestedDateTime;
          element.ApprovedOrRejectedOn_sort = element.ApprovedOrRejectedOn;
          element.StartDate = formatDate(element.StartDate, this.defaultDateFormat, 'en-US');
          element.EndDate = formatDate(element.EndDate, this.defaultDateFormat, 'en-US');
          element.RequestedDateTime = formatDate(element.RequestedDateTime, `${this.defaultDateFormat} HH:mm`, 'en-US');
          if(element.ApprovedOrRejectedOn && element.ApprovedOrRejectedOn.length > 0){
            element.ApprovedOrRejectedOn =  formatDate(element.ApprovedOrRejectedOn, `${this.defaultDateFormat} HH:mm`, 'en-US');
          }
          // element.Unconfirmed = element.Unconfirmed =="True" ? "Yes" : "No" ;
          element.Unconfirmed = element.Unconfirmed ;
          element.Ghost = element.Ghost?.toString() === 'false' ? "No" : "Yes" ;
         });
        this.dataSourceApproveByMe = new MatTableDataSource(this.approvalListByMe);
        this.empTbSort_aprvRej.disableClear=true;
        this.dataSourceApproveByMe.paginator = this.paginator.toArray()[2];
        this.dataSourceApproveByMe.sort = this.sort.toArray()[2];
        this.dataSourceApproveByMe.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'StartDate': return new Date(item.startDate_sort);
            case 'EndDate' : return new Date(item.endDate_sort);
            case 'RequestedDateTime': return new Date(item.RequestedDateTime_sort);
            case 'ApprovedDateTime' : return new Date(item.ApprovedOrRejectedOn_sort);
            default: return item[property];
          }
        };
        this.dataSourceApproveByMe.filterPredicate = this.createFilterApproveByMe();
      });
  }
  createFilterApproveByMe():(data: any, filter: string) => boolean {
    let filterFunction = function (data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      if(searchTerms.globalType){
          
        return ((data.Action ? data.Action?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false)||
              (data.ActionId ? data.ActionId?.toString().toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false)||
              (data.ApprovedOrRejectedOn ? data.ApprovedOrRejectedBy?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
              (data.EndDate ? data.EndDate?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
              (data.Engagement ? data.Engagement?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
              (data.Ghost !== null ? data.Ghost?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
              (data.Hours ? data.Hours?.toString().toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
              (data.InstanceName ? data.InstanceName?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
              (data.Loading ? data.Loading?.toString().toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
              (data.RejectionReason ? data.RejectionReason?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false)||
              (data.ApproverComment ? data.ApproverComment?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false)||
              (data.RequestId ? data.RequestId?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false)||
              (data.RequestedDateTime ? data.RequestedDateTime?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false)||
              (data.RequestByName ? data.RequestByName?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
              (data.ResourceName ? data.ResourceName?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
              (data.StartDate ? data.StartDate?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
              (data.Status ? data.Status?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
              (data.Unconfirmed ? data.Unconfirmed?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
              (data.UserSelection ? data.UserSelection?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) );
      }else{
      return ((searchTerms.requestIdType ? data.RequestId?.toLowerCase().indexOf(searchTerms.requestId.toLowerCase()) !== -1 : data.RequestId?.toLowerCase().startsWith(searchTerms.requestId.toLowerCase()) !== false)
        && (searchTerms.actionType ? data.Action?.toString().toLowerCase().indexOf(searchTerms.action.toLowerCase()) !== -1 : data.Action?.toString().toLowerCase().startsWith(searchTerms.action.toLowerCase()) !== false)
        && (searchTerms.resourceNameType ? data.ResourceName?.toLowerCase().indexOf(searchTerms.resourceName.toLowerCase()) !== -1 : data.ResourceName?.toLowerCase().startsWith(searchTerms.resourceName.toLowerCase()) !== false)
        && (searchTerms.engagementType ? data.Engagement?.toLowerCase().indexOf(searchTerms.engagement.toLowerCase()) !== -1 : data.Engagement?.toLowerCase().startsWith(searchTerms.engagement.toLowerCase()) !== false)
        && (searchTerms.statusType ? data.Status?.toLowerCase().indexOf(searchTerms.status.toLowerCase()) !== -1 : data.Status?.toLowerCase().startsWith(searchTerms.status.toLowerCase()) !== false));
      }
    }
    return filterFunction;
  }
  clearApproveByMeFilter(){
    this.dataSourceApproveByMe.filter = '';
    this.requestIdFilterApproval.setValue('');
    this.actionFilterApproval.setValue('');
    this.resourceNameFilterApproval.setValue('');
    this.engagementFilterApproval.setValue('');
    this.statusFilterApproval.setValue('');
    this.keywordApproveByME.setValue('');
    this.filterValuesApprovalByMe.globalType = false;
    this.filterValuesApprovalByMe.actionType = true;
    this.filterValuesApprovalByMe.requestIdType = true;
    this.filterValuesApprovalByMe.resourceNameType = true;
    this.filterValuesApprovalByMe.engagementType = true;
    this.filterValuesApprovalByMe.statusType = true;
  }
  exportAsXLSXByMe(): void {
    const fileName = 'RequestByMe_export_' + new Date().toISOString();
    var dataToExport =[];
      this.dataSourceByMe.filteredData.forEach(element => {
        var data = {};
        this.excelColumnsByMe.forEach(item=>{
          if(item.Key =="Unconfirmed")
          {
            data[item.value] = element[item.Key];
          }
          if(item.Key =="Ghost" && this.loggedInUser.Ghost)
          {
            data[item.value] = element[item.Key];
          }
          if (
            (item.Key =="InstanceName" && this.loggedInUser.IsMultiInstance)
            || (item.Key !="Ghost" && item.Key !="InstanceName" && item.Key !="Unconfirmed"))
          {
            data[item.value] = element[item.Key];
          }
          
        })
        dataToExport.push(data);
      });
  
    let workbookData= [
      {
        workSheet: 'Sheet1',
        rows:dataToExport
      }
    ]
  
    this.excelService.exportAsExcelFile(workbookData, fileName);
    }
    exportAsXLSXForMe(): void {
      const fileName = 'RequestForMe_export_' + new Date().toISOString();
      var dataToExport =[];
        this.dataSourceForMe.filteredData.forEach(element => {
          var data = {};
          this.excelColumnsForMe.forEach(item=>{
            if(item.Key =="Unconfirmed")
            {
              data[item.value] = element[item.Key];
            }
            if(item.Key =="Ghost" && this.loggedInUser.Ghost)
            {
              data[item.value] = element[item.Key];
            }
            if (
              (item.Key =="InstanceName" && this.loggedInUser.IsMultiInstance)
              || (item.Key !="Ghost" && item.Key !="InstanceName" && item.Key !="Unconfirmed"))
            {
              data[item.value] = element[item.Key];
            }
            
          })
          dataToExport.push(data);
        });
    
      let workbookData= [
        {
          workSheet: 'Sheet1',
          rows:dataToExport
        }
      ]
    
      this.excelService.exportAsExcelFile(workbookData, fileName);
      }
      exportAsXLSXApproveOrReject(): void {
        const fileName = 'Approve/RejectedByMe_export_' + new Date().toISOString();
        var dataToExport =[];
          this.dataSourceApproveByMe.filteredData.forEach(element => {
            var data = {};
            this.excelColumnsApproveOrReject.forEach(item=>{
              if(item.Key =="Unconfirmed")
              {
                data[item.value] = element[item.Key];
              }
              if(item.Key =="Ghost" && this.loggedInUser.Ghost)
              {
                data[item.value] = element[item.Key];
              }
              if (
                (item.Key =="InstanceName" && this.loggedInUser.IsMultiInstance)
                || (item.Key !="Ghost" && item.Key !="InstanceName" && item.Key !="Unconfirmed"))
              {
                data[item.value] = element[item.Key];
              }
              
            })
            dataToExport.push(data);
          });
      
        let workbookData= [
          {
            workSheet: 'Sheet1',
            rows:dataToExport
          }
        ]
      
        this.excelService.exportAsExcelFile(workbookData, fileName);
        }
  openModal(template) {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.modalRef = this.modalService.show(template, this.modalOption);
  }

  openActionModal(template, event, dataItem, templateName) {
    this.showModal = false;
    this.showModalDelete = false;
    this.gridActionEventData.dataItem = dataItem;
    this.gridActionEventData.event = event;
    this.gridActionEventData.template = template;
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    if (templateName === 'cancelAction') {
      this.gridActionModalHeader = 'Cancel Action Request';
      this.showModalDelete = true;
      //this.restService.openDeleteModal(this.gridActionEventData);
    }
    this.modalRef = this.modalService.show(template, this.modalOption);
  }

  closeActionModal(refresh) {
    if (this.modalRef) {
      this.modalRef.hide();
    }
    if (refresh) {
      this.getRequestByMeList();
    }
  }

  openConfirmationModal(showModalDelete) {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    if (showModalDelete) {
      this.modalRef.hide();
    }
  }

  openFilterModalByMe(template) {
    this.keywordByME.setValue('');
    this.filterValuesByMe.global = '';
    this.filterValuesByMe.globalType = false;
    this.dataSourceByMe.filter = JSON.stringify(this.filterValuesByMe);
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.modalRef = this.modalService.show(template, this.modalOption);
  }

  closeFilterModalByMe() {
    this.clearByMeFilter();
    this.modalRef.hide();
  }

  filterGridByMe() {
    this.dataSourceByMe.filter = JSON.stringify(this.filterValuesByMe);
    this.modalRef.hide();
  }

  openFilterModalForMe(template) {
    this.keywordForME.setValue('');
    this.filterValuesForMe.global = '';
    this.filterValuesForMe.globalType = false;
    this.dataSourceForMe.filter = JSON.stringify(this.filterValuesForMe);
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.modalRef = this.modalService.show(template, this.modalOption);
  }

  closeFilterModalForMe() {
    this.clearForMeFilter();
    this.modalRef.hide();
  }

  filterGridForMe() {
    this.dataSourceForMe.filter = JSON.stringify(this.filterValuesForMe);
    this.modalRef.hide();
  }
  openFilterModalApproveByMe(template){
    this.keywordApproveByME.setValue('');
    this.filterValuesApprovalByMe.global = '';
    this.filterValuesApprovalByMe.globalType = false;
    this.dataSourceApproveByMe.filter = JSON.stringify(this.filterValuesApprovalByMe);
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.modalRef = this.modalService.show(template, this.modalOption);
  }
  closeFilterModalApprovalByMe(){
    this.clearApproveByMeFilter();
    this.modalRef.hide();
  }
  filterGridApprovalByMe(){
    this.dataSourceApproveByMe.filter = JSON.stringify(this.filterValuesApprovalByMe);
    this.modalRef.hide();
  }
  onStartRequestedOnChangeByMe(event) { }
  onEndRequestedOnChangeByMe(event) {
    this.filterParamsByMe.RequestedDateFrom = this.requestedOnRangeByMe.get('start').value;;
    this.filterParamsByMe.RequestedDateTo = this.requestedOnRangeByMe.get('end').value;;
    if(this.filterParamsByMe.RequestedDateFrom != null && this.filterParamsByMe.RequestedDateTo != null){
      this.getRequestByMeList();
    }

  }
  resetRequestedOnRangeByMe() {
    this.requestedOnRangeByMe.get('start').setValue(null);
    this.requestedOnRangeByMe.get('end').setValue(null);
    this.filterParamsByMe.RequestedDateFrom = null;
    this.filterParamsByMe.RequestedDateTo = null;
    this.getRequestByMeList();
  }

  onStartRequestedOnChangeForMe(event) { }
  onEndRequestedOnChangeForMe(event) {
    this.filterParamsForMe.RequestedDateFrom = this.requestedOnRangeForMe.get('start').value;
    this.filterParamsForMe.RequestedDateTo = this.requestedOnRangeForMe.get('end').value;
    if(this.filterParamsForMe.RequestedDateFrom != null && this.filterParamsForMe.RequestedDateTo != null){
      this.getRequestForMeList();
    }
  }
  resetRequestedOnRangeForMe() {
    this.requestedOnRangeForMe.get('start').setValue(null);
    this.requestedOnRangeForMe.get('end').setValue(null);
    this.filterParamsForMe.RequestedDateFrom = null;
    this.filterParamsForMe.RequestedDateTo = null;
    this.getRequestForMeList();
  }

  onStartRequestedOnChangeApprove(event) { }
  onEndRequestedOnChangeApprove(event) {
    this.filterParamsApprove.RequestedDateFrom  = this.requestedOnRangeApprove.get('start').value;
    this.filterParamsApprove.RequestedDateTo  = this.requestedOnRangeApprove.get('end').value;
    if(this.filterParamsApprove.RequestedDateFrom != null && this.filterParamsApprove.RequestedDateTo != null){
      this.getApprovalList();
    }
  }
  resetRequestedOnRangeApprove() {
    this.filterParamsApprove.RequestedDateFrom = null;
    this.filterParamsApprove.RequestedDateTo = null;
    this.requestedOnRangeApprove.get('start').setValue(null);
    this.requestedOnRangeApprove.get('end').setValue(null);
    this.getApprovalList();
  }
}
