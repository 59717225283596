import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
  ViewChild,
} from '@angular/core';
import { IndividualBookingVM } from '../../../Models/IndividualBookingVM';
import {
  FormControl,
  Validators,
  AbstractControl,
  FormBuilder,
} from '@angular/forms';
import { BookingGridActionService } from '../../../Models/BookingGridActionService';
import { JobVM } from '../../../Models/JobVM';
import { OrderPipe } from 'ngx-order-pipe';
import { Subscription } from 'rxjs';
import { IndividualService } from '../individual.service';
import { Response } from '../../../Models/Response';
import { NgxSpinnerService } from 'ngx-spinner';
// import { ApplicationInsightService } from 'src/applicationInsightService';
import { SharedServicesService } from '../../../shared/sharedservices/shared-services.service';
import { ResourceDto } from 'app/Models/ResourceDto';
import * as moment from 'moment';
import { UserVM } from 'app/Models/UserVM';
import { favEngImageSrc, UiViewSourceEnum } from 'app/shared/enum';
import { CurrentWeekActionDTO } from '../../../Models/CurrentWeekActionDTO';
import {
  FavouriteJobsAddActionDto,
  FavouriteJobsDto,
  FavouriteJobsTableTypeDto,
} from 'app/Models/FavouriteJobsDto';
import { ElementSchemaRegistry } from '@angular/compiler';
import { ScheduledViewService } from 'app/Views/scheduleView/scheduledView.service';
import { ThemeService } from 'app/shared/theme/theme.service';
import { DARK_PATH, LIGHT_PATH } from 'app/app-constants';
//import { CurrentWeekVM } from 'src/app/Models/CurrentWeekVM';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss'],
})
export class AddComponent implements OnInit, OnDestroy {
  @ViewChild('Favimage1') Favimage1;
  @ViewChild('Favimage2') Favimage2;
  @Input() public searchedResource: ResourceDto = new ResourceDto();
  @Input() currentWeekActionDTO: CurrentWeekActionDTO;
  FavoriteImageClick: boolean = false;
  IsFavEngInserted: boolean = false;
  colorTheme = 'theme-dark-blue';
  bookingType = '';
  engagementCode = '';
  Engagement = '';
  engagementAllocationText = '';
  engagementAllocationTypeValue: any;
  description = '';
  loading = '';
  autocompleteResetvalue = '';
  placeHolderText = '';
  selectedItem: any = '';
  startDate: Date = null;
  endDate: Date = null;
  imagePath: string = '';
  hours: number;
  bookingId: number;
  //resourceId: number;
  jobId: number;
  unconfirmed: boolean;
  engagementAllocationPercentSelected = false;
  isPercentageSelected = false;
  closeModalWindow = false;

  booking: IndividualBookingVM;
  eventData: BookingGridActionService;
  response: Response;
  engagementListFiltered: JobVM[];
  engagementList: JobVM[];
  sortedEngagementList: any[];
  addBookingSubscription: Subscription;
  searchBookingSubscription: Subscription;
  inputChanged: any;
  config: any;
  validEngagement = true;
  engagementfound = true;
  engagementStatus = '';
  engagementServiceLine = '';
  EngagementName: FormControl = new FormControl();
  engagementListoptions: any[];
  results: any[];
  jobCategory = 'Chargeable';
  engagement: JobVM;
  splitId: number;
  showSplit_2 = false;
  showSplit_3 = false;
  showSplit_4 = false;
  showSplit_5 = false;
  loading_2 = '';
  loading_3 = '';
  loading_4 = '';
  loading_5 = '';
  engagementAllocationTypeValue_2: any;
  engagementAllocationTypeValue_3: any;
  engagementAllocationTypeValue_4: any;
  engagementAllocationTypeValue_5: any;
  unconfirmed_2: boolean;
  unconfirmed_3: boolean;
  unconfirmed_4: boolean;
  unconfirmed_5: boolean;
  Ghost: boolean;
  Ghost_2: boolean;
  Ghost_3: boolean;
  Ghost_4: boolean;
  Ghost_5: boolean;
  currentUserName: any;
  currentUserId: any;
  currentUserEmail: any;
  hoursValidationResultList: boolean[];
  isHoursValid: boolean = true;
  isHoursValid_2: boolean = true;
  isHoursValid_3: boolean = true;
  isHoursValid_4: boolean = true;
  isHoursValid_5: boolean = true;
  confirmHours: boolean = false;
  confirmHours_2: boolean = false;
  confirmHours_3: boolean = false;
  confirmHours_4: boolean = false;
  confirmHours_5: boolean = false;
  isConfirmFlag: boolean;
  ConfirmCheck: any;
  buttonText = 'Submit';
  overallocationerror_1: boolean = true;
  overallocationerror_2: boolean = true;
  overallocationerror_3: boolean = true;
  overallocationerror_4: boolean = true;
  overallocationerror_5: boolean = true;
  selectedInstance;
  instances;
  loggedInUser: UserVM;
  showSearchList: boolean = false;
  showSearchLoader: boolean = false;
  startWith = new Date();
  today = new Date();
  favEngSrc: string;
  todaysDate = new Date(
    this.today.getFullYear(),
    this.today.getMonth(),
    this.today.getDate(),
    0,
    0,
    0
  );
  formGroup = this.formBuilder.group({
    EngagementName: ['', Validators.required],
    // Period: new FormControl('', [Validators.required, this.dateRangeValidator]),
    period1: this.formBuilder.group({
      start1: ['', Validators.required],
      end1: ['', Validators.required],
    }),
    period2: this.formBuilder.group({
      start2: ['', Validators.required],
      end2: ['', Validators.required],
    }),
    period3: this.formBuilder.group({
      start3: ['', Validators.required],
      end3: ['', Validators.required],
    }),
    period4: this.formBuilder.group({
      start4: ['', Validators.required],
      end4: ['', Validators.required],
    }),
    period5: this.formBuilder.group({
      start5: ['', Validators.required],
      end5: ['', Validators.required],
    }),

    EngagementAllocation: [
      '',
      [
        Validators.required,
        Validators.pattern(/^(?!(0))[0-9]*(\.[0-9]{0,2})?$/),
        // Validators.max(999)
      ],
    ],
    // Period_2: new FormControl('', [
    //   Validators.required
    // ]),

    allocationValue_2: [
      '',
      [
        Validators.required,
        Validators.pattern(/^(?!(0))[0-9]*(\.[0-9]{0,2})?$/),
        //Validators.max(999)
      ],
    ],
    // Period_3: new FormControl('', [
    //   Validators.required
    // ]),
    allocationValue_3: [
      '',
      [
        Validators.required,
        Validators.pattern(/^(?!(0))[0-9]*(\.[0-9]{0,2})?$/),
        // Validators.max(999)
      ],
    ],
    // Period_4: new FormControl('', [
    //   Validators.required
    // ]),

    allocationValue_4: [
      '',
      [
        Validators.required,
        Validators.pattern(/^(?!(0))[0-9]*(\.[0-9]{0,2})?$/),
        // Validators.max(999)
      ],
    ],
    // Period_5: new FormControl('', [
    //   Validators.required
    // ]),

    allocationValue_5: [
      '',
      [
        Validators.required,
        Validators.pattern(/^(?!(0))[0-9]*(\.[0-9]{0,2})?$/),
        //Validators.max(999)
      ],
    ],
  });

  showFavouriteEngList: boolean = false;
  addFavouriteEngResults:any[];
  isClickonFavEngStart:boolean = false;
  outClickCount:number=0;
  compareFavouriteEngResults:any[]= [];
  isVerifyOnEngClick:boolean=false;
  lbl:string;
  isFavEngONClick:boolean=true;
  IsDataNotFound:boolean = false;
 

  constructor(private orderPipe: OrderPipe, 
    private individualServices: IndividualService,
    private spinner: NgxSpinnerService,
    private themeService: ThemeService,
    public restService: SharedServicesService, private formBuilder: FormBuilder) {

    document.addEventListener('click', this.favoriteimgClickHandler.bind(this)); // bind on document
  }

  ngOnInit() 
    {
    const activeTheme = this.themeService.getActiveTheme();
    this.imagePath = activeTheme.name === 'light' ? LIGHT_PATH : DARK_PATH;
    this.loggedInUser = JSON.parse(sessionStorage.getItem('resource'));
    this.currentUserName = this.loggedInUser.ResourceName;
    this.currentUserEmail = this.loggedInUser.Email;
    this.currentUserId = sessionStorage.getItem('resourceId');
    // this.applicationInsightsService.logPageView("Individual View Add Bookings");
    this.engagement = new JobVM();
    // const currentDate = new Date();

    // this.getCurrentWeek();
    this.enabledWeek(!this.currentWeekActionDTO.IsDisabledAddBookingCurrWeek);

    this.individualServices.addEvent.subscribe((data) => {
      this.eventData = data;
      this.setData(this.eventData);
    });
    this.individualServices.currentMessageEngagementAllocation.subscribe(
      (isPercentageSelected) => {
        this.isPercentageSelected = isPercentageSelected;
        this.setEngagementAllocation(this.isPercentageSelected);
      }
    );
    // this.individualServices.setSelectedResourceIdEvent.subscribe((resourceId) => {
    //   this.resourceId = resourceId;
    // });
    //this.getUserInstances()
    this.getUserResourceInstances();

    
    this.booking = new IndividualBookingVM();
    this.response = new Response();
    this.engagementListFiltered = [];
    this.engagementList = [];
    this.sortedEngagementList = [];
    this.results = [];
    this.engagementListoptions = [];
    this.individualServices.currentMessageCloseModal.subscribe((closeModal) => {
      this.closeModalWindow = closeModal;
    });
    this.individualServices.currentMessageCloseChildModal.subscribe(
      (closeChildModal) => {
        this.closeModalWindow = closeChildModal;
      }
    );
  }

  getUserResourceInstances() {
    this.restService
      .getUserResourceInstances(this.searchedResource)
      .subscribe((data) => {
        this.instances = data;
        const selected = this.instances.filter(
          (x) => x.InstanceId === this.loggedInUser.DefaultInstance
        );
        if (!this.loggedInUser.R_InstEnable_AddBooking) {
          this.selectedInstance = this.loggedInUser.R_Home_InstanceID;
        } else if (selected) {
          this.selectedInstance = selected[0].InstanceId;
        }
      });
  }

  ngOnDestroy() {
    if (this.addBookingSubscription !== undefined) {
      this.addBookingSubscription.unsubscribe();
    }
    if (this.searchBookingSubscription !== undefined) {
      this.searchBookingSubscription.unsubscribe();
    }
  }
  isValidInput(fieldName): boolean {
    return (
      this.formGroup.controls[fieldName].invalid &&
      (this.formGroup.controls[fieldName].dirty ||
        this.formGroup.controls[fieldName].touched)
    );
  }

  onSubmit() {
    if (!this.showSplit_2) {
      this.formGroup.get('period2').get('start2').setErrors(null);
      this.formGroup.get('period2').get('start2').setValidators(null);
      this.formGroup.get('period2').get('end2').setErrors(null);
      this.formGroup.get('period2').get('end2').setValidators(null);
      this.formGroup.get('allocationValue_2').setErrors(null);
      this.formGroup.get('allocationValue_2').setValidators(null);
    }
    if (!this.showSplit_3) {
      this.formGroup.get('period3').get('start3').setErrors(null);
      this.formGroup.get('period3').get('start3').setValidators(null);
      this.formGroup.get('period3').get('end3').setErrors(null);
      this.formGroup.get('period3').get('end3').setValidators(null);
      this.formGroup.get('allocationValue_3').setErrors(null);
      this.formGroup.get('allocationValue_3').setValidators(null);
    }
    if (!this.showSplit_4) {
      this.formGroup.get('period4').get('start4').setErrors(null);
      this.formGroup.get('period4').get('start4').setValidators(null);
      this.formGroup.get('period4').get('end4').setErrors(null);
      this.formGroup.get('period4').get('end4').setValidators(null);
      this.formGroup.get('allocationValue_4').setErrors(null);
      this.formGroup.get('allocationValue_4').setValidators(null);
    }
    if (!this.showSplit_5) {
      this.formGroup.get('period5').get('start5').setErrors(null);
      this.formGroup.get('period5').get('start5').setValidators(null);
      this.formGroup.get('period5').get('end5').setErrors(null);
      this.formGroup.get('period5').get('end5').setValidators(null);
      this.formGroup.get('allocationValue_5').setErrors(null);
      this.formGroup.get('allocationValue_5').setValidators(null);
    }
    if (this.validate(this.jobId) && !this.formGroup.invalid) {
      this.spinner.show();
      //this.booking.ResourceId = this.resourceId;
      this.booking.ResourceId = this.instances.filter(
        (x) => x.InstanceId === this.selectedInstance
      )[0].ResourceId;
      this.booking.StartDate = this.formGroup
        .get('period1')
        .get('start1').value;
      this.booking.EndDate = this.formGroup.get('period1').get('end1').value;
      this.booking.BookingId = 0;
      this.setSplitValues();
      this.isHoursValid = true;
      this.isHoursValid_2 = true;
      this.isHoursValid_3 = true;
      this.isHoursValid_4 = true;
      this.isHoursValid_5 = true;
      this.isConfirmFlag = true;
      this.booking.InstanceId = this.selectedInstance;

      this.addBookingSubscription = this.individualServices
        .validateHours(this.booking)
        .subscribe((data: any) => {
          this.hoursValidationResultList = data;

          if (
            !this.hoursValidationResultList[0] &&
            this.hoursValidationResultList[0] !== undefined
          ) {
            this.isHoursValid = false;
            this.formGroup
              .get('EngagementAllocation')
              .setErrors({ incorrect: true });
            this.overallocationerror_1 = false;
          }
          if (
            !this.hoursValidationResultList[1] &&
            this.hoursValidationResultList[1] !== undefined
          ) {
            this.isHoursValid_2 = false;
            this.formGroup
              .get('allocationValue_2')
              .setErrors({ incorrect: true });
            this.overallocationerror_2 = false;
          }
          if (
            !this.hoursValidationResultList[2] &&
            this.hoursValidationResultList[2] !== undefined
          ) {
            this.isHoursValid_3 = false;
            this.formGroup
              .get('allocationValue_3')
              .setErrors({ incorrect: true });
            this.overallocationerror_3 = false;
          }
          if (
            !this.hoursValidationResultList[3] &&
            this.hoursValidationResultList[3] !== undefined
          ) {
            this.isHoursValid_4 = false;
            this.formGroup
              .get('allocationValue_4')
              .setErrors({ incorrect: true });
            this.overallocationerror_4 = false;
          }
          if (
            !this.hoursValidationResultList[4] &&
            this.hoursValidationResultList[4] !== undefined
          ) {
            this.isHoursValid_5 = false;
            this.formGroup
              .get('allocationValue_5')
              .setErrors({ incorrect: true });
            this.overallocationerror_5 = false;
          }
          if (
            this.isHoursValid &&
            this.isHoursValid_2 &&
            this.isHoursValid_3 &&
            this.isHoursValid_4 &&
            this.isHoursValid_5
          ) {
            this.save();
          } else {
            if (!this.isHoursValid && !this.confirmHours) {
              this.isConfirmFlag = false;
            }
            if (!this.isHoursValid_2 && !this.confirmHours_2) {
              this.isConfirmFlag = false;
            }
            if (!this.isHoursValid_3 && !this.confirmHours_3) {
              this.isConfirmFlag = false;
            }
            if (!this.isHoursValid_4 && !this.confirmHours_4) {
              this.isConfirmFlag = false;
            }
            if (!this.isHoursValid_5 && !this.confirmHours_5) {
              this.isConfirmFlag = false;
            }
            if (this.isConfirmFlag) {
              this.save();
            }
          }
          this.spinner.hide();
        });
    } else {
      this.formGroup.markAllAsTouched();
      this.formGroup.markAsDirty();
    }
  }

  public confirmHoursChange(checkboxSelected: string) {
    if (this.formGroup.get(checkboxSelected).value != '') {
      this.formGroup.get(checkboxSelected).setErrors(null);
    }
  }

  @Output() isFormDirtyFlag: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  public dirtyFlag = false;

  public makeDirty(event) {
    if (!this.dirtyFlag) {
      this.dirtyFlag = true;
      this.isFormDirtyFlag.emit(this.dirtyFlag);
    }

    if (this.overallocationerror_1 == false && event == 'Hours_1') {
      this.overallocationerror_1 = true;
    }
    if (this.overallocationerror_2 == false && event == 'Hours_2') {
      this.overallocationerror_2 = true;
    }
    if (this.overallocationerror_3 == false && event == 'Hours_3') {
      this.overallocationerror_3 = true;
    }
    if (this.overallocationerror_4 == false && event == 'Hours_4') {
      this.overallocationerror_4 = true;
    }
    if (this.overallocationerror_5 == false && event == 'Hours_5') {
      this.overallocationerror_5 = true;
    }
  }

  public checkDirty() {
    if (!this.dirtyFlag) {
      if (this.formGroup.get('Period').dirty) {
        this.makeDirty(event);
      }
    }
  }
  onInstanceChange(event) {
    this.selectedInstance = event.value;
    this.onEngagementClear();
  }

  onEngagementCategoryChange(event) {
    this.onEngagementClear();
    const newVal = event.value;
    this.jobCategory = newVal;
    this.makeDirty(event);
  }

  onInputChangedEvent(val: string) {
    this.inputChanged = val;
    const tempList = this.engagementList.filter(
      (engagement) => engagement.EngagementFullName === val
    );
    if (tempList.length === 0) {
      this.formGroup.get('EngagementName').setValidators([Validators.required]);
      this.formGroup.get('EngagementName').reset();
      this.autocompleteResetvalue = null;
      this.placeHolderText = 'Search engagement ....';
      this.engagementfound = false;
      this.bookingType = null;
      this.engagementCode = null;
      this.engagementStatus = null;
      this.engagementServiceLine = null;
    } else {
      this.formGroup.get('EngagementName').setValidators(null);
      this.engagementfound = true;
    }
  }

  mouseEnter() {}

  mouseLeave(val) {}

  setData(rowdata: BookingGridActionService) {
    this.config = {
      placeholder: '',
      sourceField: ['EngagementFullName'],
    };
    this.formGroup.reset();
    this.autocompleteResetvalue = null;
    this.placeHolderText = 'Search engagement ....';
    this.selectedItem = null;
    this.description = null;
    this.Engagement = null;
    this.engagementCode = null;
    this.unconfirmed = null;
    this.Ghost = null;
    this.setEngagementAllocation(this.isPercentageSelected);
    this.engagementStatus = null;
    if (this.bookingType.includes('Unconfirmed')) {
      this.unconfirmed = true;
    } else {
      this.unconfirmed = false;
    }
  }

  setEngagementAllocation(isPercentageSelected) {
    if (!isPercentageSelected) {
      this.engagementAllocationText = 'Hours';
      this.engagementAllocationTypeValue = this.hours;
      this.engagementAllocationTypeValue_2 = this.hours;
      this.engagementAllocationTypeValue_3 = this.hours;
      this.engagementAllocationTypeValue_4 = this.hours;
      this.engagementAllocationTypeValue_5 = this.hours;
    } else {
      this.engagementAllocationText = 'Loading %';
      this.engagementAllocationTypeValue = this.loading;
      this.engagementAllocationTypeValue_2 = this.loading;
      this.engagementAllocationTypeValue_3 = this.loading;
      this.engagementAllocationTypeValue_4 = this.loading;
      this.engagementAllocationTypeValue_5 = this.loading;
    }
    this.engagementAllocationPercentSelected = isPercentageSelected;
  }

  // getEngagementList(query) {
  //   let input = this.jobCategory;
  //   if (input == null || input === '') {
  //     input = 'Chargeable';
  //   }
  //   this.engagement.JobCategory = input;
  //   this.engagement.JobDescription = query;
  //   this.engagement.ClientDescription = query;
  //   this.engagement.LoggedInResourceId = +sessionStorage.getItem('resourceId'); //this.resourceId;

  //   this.searchBookingSubscription = this.individualServices.getEngagementSearchResult(this.engagement).subscribe((data) => {
  //     this.engagementList = data.body;
  //     this.sortedEngagementList = this.orderPipe.transform(this.engagementList, 'EngagementFullName');
  //     this.setEngagementListData();
  //     this.spinner.hide();
  //   });
  // }

  save() {
    this.buttonText = 'Saving...';

    // if (this.resourceId === 0 || this.resourceId === undefined) {
    //   this.booking.ResourceId = +sessionStorage.getItem('resourceId');
    // } else {
    //   this.booking.ResourceId = this.resourceId;
    // }
    this.booking.ResourceId = this.instances.filter(
      (x) => x.InstanceId === this.selectedInstance
    )[0].ResourceId;

    this.booking.InstanceId = this.selectedInstance;
    this.booking.UiViewSource = UiViewSourceEnum.IndividualView;
    this.booking.JobId = this.jobId;
    this.booking.BookingType = this.bookingType;
    this.booking.Description = this.description;
    this.booking.StartDate = this.formGroup.get('period1').get('start1').value;
    this.booking.EndDate = this.formGroup.get('period1').get('end1').value;
    this.booking.ActionDescriptionId = 1;
    this.booking.MeraBookingStatusId = 5;
    this.booking.currentUserId = +sessionStorage.getItem('resourceId');
    this.setSplitValues();
    this.booking.CurrentUserEmail = this.currentUserEmail;
    this.booking.CurrentUserName = this.currentUserName;
    this.booking.IsOverallocation_1 = this.confirmHours;
    this.booking.RequestMode = JSON.parse(
      sessionStorage.getItem('requestMode')
    );
    this.dirtyFlag = false;

    let bookingAddActionDto = this.restService.getBookingActionDto(
      this.booking
    );
    this.addBookingSubscription = this.individualServices
      .addBooking(bookingAddActionDto)
      .subscribe(
        (data: any) => {
          this.spinner.hide();
          if ((data.status = '200')) {
            this.response = data.body;
            if (this.response.customResultId > 0) {
              this.individualServices.setRquestNumber(
                this.response.customResultId
              );
            }

            this.individualServices.closeModal(true);
          } else {
            this.buttonText =
              'Data not saved. Please contact Admin for more details';
            this.individualServices.closeModal(true);
          }

          this.buttonText = 'Submit';
        },
        (err) => {
          this.buttonText = 'Error';
        },
        () => {
          this.buttonText = 'Submit';
        }
      );
  }

  validate(JobId) {
    let result = true;
    if (JobId === 0 || JobId === undefined || JobId === null) {
      this.validEngagement = false;
      this.engagementfound = false;
      result = false;
    }
    return result;
  }

  dateRangeValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    if (
      control.value !== undefined &&
      control.value !== null &&
      (control.value[0] < today || control.value[1] < today)
    ) {
      return { ageRange: true };
    }
    return null;
  }

  engagementValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    let validate = false;
    if (control.value !== undefined && control.value !== null && !validate) {
      return { engagementRange: true };
    }
    return null;
  }

  searchValue = '';

  search() {
    this.sortedEngagementList = [];
    this.isFavEngONClick = false;
    this.isVerifyOnEngClick = false;
    this.autocompleteResetvalue = this.formGroup.get('EngagementName').value;

    if (this.autocompleteResetvalue != null) {
      if (this.autocompleteResetvalue.trim().length >= 2) {
        this.showSearchList = true;
        this.showFavouriteEngList = false;
        this.showSearchLoader = true;
        this.searchValue = this.autocompleteResetvalue;
        //this.getEngagementList(this.autocompleteResetvalue);
        let input = this.jobCategory;
        if (input == null || input === '') {
          input = 'Chargeable';
        }
        this.engagement.JobCategory = input;
        this.engagement.JobDescription = this.autocompleteResetvalue;
        //this.engagement.ClientDescription = this.autocompleteResetvalue;
        this.engagement.InstanceId = this.selectedInstance;

        this.searchBookingSubscription = this.individualServices
          .getEngagementSearchResult(this.engagement)
          .subscribe((data) => {
            this.engagementList = data.body;

            //this.sortedEngagementList = this.orderPipe.transform(this.engagementList, 'EngagementFullName');
            //this.setEngagementListData();
            this.sortedEngagementList = data.body;
            // if (this.autocompleteResetvalue.length > 0)
            if (this.sortedEngagementList.length > 0) {
              this.setAllFavEngagement();
              this.results = this.sortedEngagementList;
              this.showSearchLoader = false;
              this.showFavouriteEngList = false;
            } else {
              this.showSearchLoader = false;
              this.results = [];
              this.IsDataNotFound = true;
              this.showSearchList = true;
            }
          });
      } else {
        this.results = [];
        this.showSearchList = false;
      }
    } else {
      this.results = [];
      this.showSearchList = false;
    }
  }

  private setAllFavEngagement(): void {
    this.sortedEngagementList.forEach((a) => {
      if (a.IsFavourite === true) {
        a.IsFavEngInserted = true;
        a.favEngSrc = favEngImageSrc.favEngImgStarfilled;
      } else {
        a.IsFavEngInserted = false;
        a.favEngSrc = favEngImageSrc.favEngImgStarOutline;
      }
    });
  }

  onEngagementClear() {
    this.IsDataNotFound = false;
    this.searchValue = '';
    this.placeHolderText = 'Search engagement ....';
    this.selectedItem = null;
    this.bookingType = null;
    this.Engagement = null;
    this.engagementCode = null;
    this.autocompleteResetvalue = null;
    this.engagementStatus = null;
    this.engagementServiceLine = null;
    this.formGroup.get('EngagementName').reset();
    this.results = [];
    this.addFavouriteEngResults = [];
    this.isClickonFavEngStart = false;
    this.showSearchList = false;
    this.isFavEngONClick = true;
  }

  setEngagementListData() {
    this.engagementListoptions = [];
    if (
      this.autocompleteResetvalue == null ||
      this.autocompleteResetvalue == ''
    ) {
      this.results = [];
    } else {
      this.results = this.sortedEngagementList;
    }
  }

  OnItemSelect(event: any) {
    if (this.FavoriteImageClick === true) {
      this.FavoriteImageClick = false;
      (event as Event).stopPropagation();
      // event.stopPropagation();
    }

    this.showSearchList = false;
    this.showFavouriteEngList = false;
    this.selectedItem = event;
    this.engagementCode = event.JobCode;
    this.engagementStatus = event.JobStatus;
    this.engagementServiceLine = event.ServiceLineDescription;
    this.formGroup.get('EngagementName').setValue(event.EngagementFullName);
    this.jobId = event.JobId;
    if (this.engagementCode === '' || this.engagementCode === null) {
      this.bookingType = event.JobType + '-' + 'Awaiting Code';
      this.engagementCode = 'Dummy';
    } else {
      this.bookingType = event.JobType;
    }
  }

  showSplit() {
    this.makeDirty(event);
    this.splitId = this.splitId + 1;
    switch (this.splitId) {
      case 2: {
        if (this.showSplit_2) {
          this.showSplit();
        }
        this.showSplit_2 = true;
        break;
      }
      case 3: {
        if (this.showSplit_3) {
          this.showSplit();
        }
        this.showSplit_3 = true;
        break;
      }
      case 4: {
        if (this.showSplit_4) {
          this.showSplit();
        }
        this.showSplit_4 = true;
        break;
      }
      case 5: {
        if (this.showSplit_5) {
          this.showSplit();
        }
        this.showSplit_5 = true;
        break;
      }
      default: {
        if (this.splitId > 5) {
          this.splitId = 1;
          if (
            this.showSplit_2 &&
            this.showSplit_3 &&
            this.showSplit_4 &&
            this.showSplit_5
          ) {
            break;
          } else {
            this.showSplit();
          }
        }
        if (
          !this.showSplit_2 &&
          !this.showSplit_3 &&
          !this.showSplit_4 &&
          !this.showSplit_5
        ) {
          this.splitId = 1;
          this.showSplit();
        }
        break;
      }
    }
  }

  hideSplit(splitId) {
    this.splitId = splitId - 1;
    switch (splitId) {
      case 2: {
        this.showSplit_2 = false;
        break;
      }
      case 3: {
        this.showSplit_3 = false;
        break;
      }
      case 4: {
        this.showSplit_4 = false;
        break;
      }
      case 5: {
        this.showSplit_5 = false;
        break;
      }
    }
    if (
      !this.showSplit_2 &&
      !this.showSplit_3 &&
      !this.showSplit_4 &&
      !this.showSplit_5
    ) {
      this.splitId = 1;
    }
  }

  mouseEnterSplit() {}

  setSplitValues() {
    this.booking.BookingId = this.bookingId;
    this.booking.Unconfirmed = this.unconfirmed;
    this.booking.Unconfirmed_2 = this.unconfirmed_2;
    this.booking.Unconfirmed_3 = this.unconfirmed_3;
    this.booking.Unconfirmed_4 = this.unconfirmed_4;
    this.booking.Unconfirmed_5 = this.unconfirmed_5;
    this.booking.Ghost = this.Ghost;
    this.booking.Ghost_2 = this.Ghost_2;
    this.booking.Ghost_3 = this.Ghost_3;
    this.booking.Ghost_4 = this.Ghost_4;
    this.booking.Ghost_5 = this.Ghost_5;
    this.booking.StartDate_2 = this.formGroup
      .get('period2')
      .get('start2').value;
    this.booking.EndDate_2 = this.formGroup.get('period2').get('end2').value;
    this.booking.StartDate_3 = this.formGroup
      .get('period3')
      .get('start3').value;
    this.booking.EndDate_3 = this.formGroup.get('period3').get('end3').value;
    this.booking.StartDate_4 = this.formGroup
      .get('period4')
      .get('start4').value;
    this.booking.EndDate_4 = this.formGroup.get('period4').get('end4').value;
    this.booking.StartDate_5 = this.formGroup
      .get('period5')
      .get('start5').value;
    this.booking.EndDate_5 = this.formGroup.get('period5').get('end5').value;
    this.booking.IsOverallocation_1 = this.confirmHours;
    this.booking.IsOverallocation_2 = this.confirmHours_2;
    this.booking.IsOverallocation_3 = this.confirmHours_3;
    this.booking.IsOverallocation_4 = this.confirmHours_4;
    this.booking.IsOverallocation_5 = this.confirmHours_5;
    if (this.engagementAllocationPercentSelected) {
      this.booking.Loading = this.formGroup.get('EngagementAllocation').value;
      this.booking.Loading_2 = this.formGroup.get('allocationValue_2').value;
      this.booking.Loading_3 = this.formGroup.get('allocationValue_3').value;
      this.booking.Loading_4 = this.formGroup.get('allocationValue_4').value;
      this.booking.Loading_5 = this.formGroup.get('allocationValue_5').value;
      this.booking.IsPercentSelected = true;
      this.booking.Hours = this.hours;
      this.booking.Hours_2 = this.hours;
      this.booking.Hours_3 = this.hours;
      this.booking.Hours_4 = this.hours;
      this.booking.Hours_5 = this.hours;
    } else {
      this.booking.Hours = this.formGroup.get('EngagementAllocation').value;
      this.booking.Hours_2 = this.formGroup.get('allocationValue_2').value;
      this.booking.Hours_3 = this.formGroup.get('allocationValue_3').value;
      this.booking.Hours_4 = this.formGroup.get('allocationValue_4').value;
      this.booking.Hours_5 = this.formGroup.get('allocationValue_5').value;
      this.booking.Loading = +this.loading;
      this.booking.Loading_2 = +this.loading_2;
      this.booking.Loading_3 = +this.loading_3;
      this.booking.Loading_4 = +this.loading_4;
      this.booking.Loading_5 = +this.loading_5;
    }
    if (!this.showSplit_2) {
      this.booking.StartDate_2 = null;
      this.booking.EndDate_2 = null;
      this.booking.Loading_2 = null;
      this.booking.Hours_2 = null;
      // this.booking.IsOverallocation_2=this.confirmHours_2;
    }
    if (!this.showSplit_3) {
      this.booking.StartDate_3 = null;
      this.booking.EndDate_3 = null;
      this.booking.Loading_3 = null;
      this.booking.Hours_3 = null;
      //this.booking.IsOverallocation_3=this.confirmHours_3;
    }
    if (!this.showSplit_4) {
      this.booking.StartDate_4 = null;
      this.booking.EndDate_4 = null;
      this.booking.Loading_4 = null;
      this.booking.Hours_4 = null;
      //this.booking.IsOverallocation_4=this.confirmHours_4;
    }
    if (!this.showSplit_5) {
      this.booking.StartDate_5 = null;
      this.booking.EndDate_5 = null;
      this.booking.Loading_5 = null;
      this.booking.Hours_5 = null;
      // this.booking.IsOverallocation_5=this.confirmHours_5;
    }
    this.booking.currentUserId = +sessionStorage.getItem('resourceId');
  }

  clearAll() {
    this.booking = new IndividualBookingVM();
  }

  setStartDateTime(inputDate) {
    let newDate = inputDate;
    if (newDate.getHours() === 0) {
      newDate.setHours(newDate.getHours() + 9);
      newDate.setMinutes(newDate.getMinutes() + 30);
    }
    return newDate;
  }

  setEndDateTime(inputDate) {
    let newDate = inputDate;
    if (newDate.getHours() === 0) {
      newDate.setHours(newDate.getHours() + 17);
      newDate.setMinutes(newDate.getMinutes() + 30);
    }
    return newDate;
  }
  enabledWeek(isCurrentWeekEditable) {
    if (this.currentWeekActionDTO.IsDisabledAddBookingCurrWeek === true) {
      isCurrentWeekEditable = false;
    }

    this.startWith = this.getFirstDateOfWeek(this.todaysDate);
    if (isCurrentWeekEditable) {
      this.startWith.setMinutes(
        this.startWith.getMinutes() - this.startWith.getTimezoneOffset()
      );
    } else {
      this.startWith.setDate(this.startWith.getDate() + 7);
      this.startWith.setMinutes(
        this.startWith.getMinutes() - this.startWith.getTimezoneOffset()
      );
    }

    // const dayNeeded = 7 //For Saturday
    // const currentDay = moment().isoWeekday();

    // if(this.AddWeek === 1){
    //   isCurrentWeekEditable = false;
    // }

    // if(isCurrentWeekEditable){
    //   if (currentDay === dayNeeded) {
    //     let day = moment().day(dayNeeded);
    //     this.startWith = new Date(day.year(),day.month(),day.date())
    //   }else{
    //     let day = moment().subtract(1, 'weeks').isoWeekday(dayNeeded);
    //     this.startWith = new Date(day.year(),day.month(),day.date());
    //   }
    // }else{
    //   if (currentDay === dayNeeded) {
    //     let day = moment().add(1, 'weeks').isoWeekday(dayNeeded);
    //     this.startWith = new Date(day.year(),day.month(),day.date());
    //   }else{
    //     let day = moment().day(dayNeeded);
    //     this.startWith = new Date(day.year(),day.month(),day.date());

    //   }
    // }
  }
  getFirstDateOfWeek(anyDateOfWeek: Date): Date {
    // date.getDay() RETURNS Week Day Index: SUN 0, MON 1, TUE 2, WED 3, THU 4, FRI 5, SAT 6.
    let date = new Date(anyDateOfWeek);
    let firstDayOfWeekIndex: number = 6; //Week Day Index: SUN 0, MON 1, TUE 2, WED 3, THU 4, FRI 5, SAT 6.
    let daysToShiftBack = (7 + (date.getDay() - firstDayOfWeekIndex)) % 7;
    let firstDateOfWeek = new Date(
      date.setDate(date.getDate() - daysToShiftBack)
    );
    return firstDateOfWeek;
  }
  //CurrentWeekVM: CurrentWeekVM;
  // AddWeek: number = 0;

  // getCurrentWeek() {
  //   this.addBookingSubscription = this.individualServices.getCurrentWeek().subscribe(
  //     (data: any) => {
  //       this.AddWeek = data.AddWeek;

  //       const currentDate = new Date();

  //       if (this.AddWeek == 1) {
  //         this.enabledWeek(false);
  //       }
  //       if (this.AddWeek == 0 || this.AddWeek == null) {
  //         this.enabledWeek(true);
  //       }
  //     });
  // }

  // added for faviourite Engagement serching functionality ------- ------------> START
  onFavoriteImageClick(item) {
    this.FavoriteImageClick = true;
    item.IsOldFavEngInserted = item.IsFavEngInserted;
    item.IsFavEngInserted = !item.IsFavEngInserted;

    if (item.IsFavEngInserted) {
      item.favEngSrc = favEngImageSrc.favEngImgStarfilled;
    } else {
      item.favEngSrc = favEngImageSrc.favEngImgStarOutline;
    }
  }

  onEngagementClick() {
    this.showSearchList = false;
    this.isVerifyOnEngClick = true;
    this.showFavouriteEngList = true;

    let val = this.formGroup.get('EngagementName').value;
    if (val != null) {
      if (val.trim().length >= 2) {
        this.showFavouriteEngList = false;
        this.isFavEngONClick = false;
      } else {
        this.showFavouriteEngList = true;
        this.isFavEngONClick = true;
      }
    }

    if (this.isFavEngONClick) {
      this.getFavouriteEngagement();
    }
  }

  private AddFavouriteEngagement(arr): void {
    this.individualServices.addFavouriteJobs(arr).subscribe((data) => {
      this.showSearchList = false;
      this.showFavouriteEngList = false;
      this.isFavEngONClick = true;
    });
  }

  private getFavouriteEngagement(): void {
    let favouriteJobs = new FavouriteJobsDto();
    this.showSearchLoader = true;
    favouriteJobs.InstanceId = this.selectedInstance;
    favouriteJobs.IsSearchfromIndivBooking = true;
    favouriteJobs.JobCategory = this.jobCategory;
    favouriteJobs.ResourceID = !this.searchedResource.ResourceId
      ? 0
      : this.searchedResource.ResourceId;

    //this.individualServices.getFavouriteJobs(favouriteJobs).subscribe((data) =>
    this.individualServices
      .GetFavouriteJobsIndividualView(favouriteJobs)
      .subscribe((data) => {
        this.showSearchLoader = false;
        const key = 'JobId';

        const resultEng = [
          ...new Map(data.map((item) => [item[key], item])).values(),
        ];
        this.addFavouriteEngResults = resultEng;
        this.isFavEngONClick = false;
        if (this.addFavouriteEngResults.length > 0) {
          this.IsDataNotFound = false;
          this.addFavouriteEngResults.forEach((i) => {
            i.IsFavEngInserted = true;
            i.favEngSrc = favEngImageSrc.favEngImgStarfilled;
          });
        } else {
          this.IsDataNotFound = true;
          this.lbl = 'Favourite Engagements not added.';
        }

        //console.log(this.addFavouriteEngResults);
      });
  }

  private favoriteimgClickHandler(event: any) {
    if (!!event.target.src) {
      var parts = event.target.src.split('/');
      var img = parts[parts.length - 1];

      var a = favEngImageSrc.favEngImgStarOutline.split('/');
      var ImgStarOutline = a[a.length - 1];

      var b = favEngImageSrc.favEngImgStarfilled.split('/');
      var ImgStarfilled = b[b.length - 1];

      // if((event.target.src === favEngImageSrc.favEngImgStarOutline) || (event.target.src === favEngImageSrc.favEngImgStarfilled))
      if (img === ImgStarOutline || img === ImgStarfilled) {
        this.isClickonFavEngStart = true;
        this.outClickCount = 0;
        //console.log("in side click");
      } else {
        this.isClickonFavEngStart = false;
        if (this.outClickCount == 0) {
          this.outClickCount++;
        }
      }
    } else {
      if (this.isClickonFavEngStart) {
        if (this.outClickCount == 0) {
          this.outClickCount++;
          //console.log("out click after click on start");
          this.getAllSelectedfavEngagement();
        }
      }
      // console.log("out click ");
    }
  }

  private getAllSelectedfavEngagement() {
    let typeArr: Array<FavouriteJobsTableTypeDto>;
    typeArr = [];
    let dataSE;
    if (this.outClickCount > 0) {
      this.outClickCount = 0;
      this.isClickonFavEngStart = false;
      this.compareFavouriteEngResults = [];
      if (!this.isVerifyOnEngClick) {
        dataSE = this.sortedEngagementList.filter(
          (ap) =>
            ap.IsOldFavEngInserted === true || ap.IsOldFavEngInserted === false
        );
      } else {
        dataSE = this.addFavouriteEngResults.filter(
          (ap) =>
            ap.IsOldFavEngInserted === true || ap.IsOldFavEngInserted === false
        );
      }

      if (dataSE.length > 0) {
        dataSE.forEach((data) => {
          if (
            data.IsOldFavEngInserted === true ||
            data.IsOldFavEngInserted === false
          ) {
            this.compareFavouriteEngResults.push(data);
            //console.log(this.compareFavouriteEngResults);
          }
        });
      }

      if (
        this.addFavouriteEngResults.length > 0 ||
        !!this.addFavouriteEngResults
      ) {
        let arr = [];
        this.compareFavouriteEngResults.forEach((op) => {
          if (!op.IsFavEngInserted) {
            if (this.addFavouriteEngResults.some((g) => g.JobId === op.JobId)) {
              op.InstanceId = this.selectedInstance;
              op.JobCategory = this.jobCategory;
              arr.push(op);
            }
          } else {
            op.InstanceId = this.selectedInstance;
            op.JobCategory = this.jobCategory;
            arr.push(op);
          }
        });
        if (arr.length > 0) {
          arr.forEach((tt) => {
            let obj = new FavouriteJobsTableTypeDto();
            obj.LoggedInUserId = this.loggedInUser.UserId;
            obj.JobID = tt.JobId;
            obj.Instance_Id = tt.InstanceId;
            obj.IsFavEngInserted = tt.IsFavEngInserted;
            typeArr.push(obj);
          });
          let k = new FavouriteJobsAddActionDto();
          k.FavouriteJobs = typeArr;

          this.AddFavouriteEngagement(k);
        }
      }
    }
  }

  // onFavEngagementSearch(event:any)
  // {

  //   this.autocompleteResetvalue = event.EngagementFullName;
  //   if (this.autocompleteResetvalue != null) {
  //     if (this.autocompleteResetvalue.trim().length >= 2) {
  //       this.showFavouriteEngList = true;
  //       this.showSearchLoader = true;
  //       this.searchValue = this.autocompleteResetvalue;
  //       //this.getEngagementList(this.autocompleteResetvalue);
  //       let input = this.jobCategory;
  //       if (input == null || input === '') {
  //         input = 'Chargeable';
  //       }
  //       this.engagement.JobCategory = input;
  //       this.engagement.JobDescription = this.autocompleteResetvalue;

  //       this.engagement.InstanceId = this.selectedInstance;

  //       this.searchBookingSubscription = this.individualServices.getEngagementSearchResult(this.engagement).subscribe((data) => {
  //         this.engagementList = data.body;
  //         this.sortedEngagementList = this.orderPipe.transform(this.engagementList, 'EngagementFullName');
  //         //this.setEngagementListData();

  //         if (this.autocompleteResetvalue.length > 0) {
  //           this.results = this.sortedEngagementList.filter(yy=> yy.JobId === event.JobId);
  //           this.showSearchLoader = false;
  //           if(this.results)
  //           {
  //           this.getOnItemforFavEngagement(this.results[0]);
  //           }
  //         }
  //         else {
  //           this.results = [];
  //           this.showSearchList = false;
  //         }

  //       });

  //     }
  //     else {
  //       this.results = [];
  //       this.showSearchList = false;
  //     }
  //   }
  //   else {
  //     this.results = [];
  //     this.showSearchList = false;
  //   }

  // }

  // private getOnItemforFavEngagement(event):void
  // {
  //   if(this.showFavouriteEngList)
  //     {

  //           this.showFavouriteEngList = false;
  //           this.selectedItem = event;
  //           this.engagementCode = event.JobCode;
  //           this.engagementStatus = event.JobStatus;
  //           this.engagementServiceLine = event.ServiceLineDescription;
  //           this.formGroup.get('EngagementName').setValue(event.EngagementFullName);
  //           this.jobId = event.JobId;
  //           if (this.engagementCode === '' || this.engagementCode === null) {
  //             this.bookingType = event.JobType + '-' + 'Awaiting Code';
  //             this.engagementCode = 'Dummy';
  //           } else {
  //             this.bookingType = event.JobType;
  //           }
  //     }
  // }

  ///  ************** ********************        ------- ------------> END
}
