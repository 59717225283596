import { Component, OnInit, TemplateRef  } from '@angular/core';

@Component({
  selector: 'app-privacy-notice',
  templateUrl: './privacy-notice.component.html',
  styleUrls: ['./privacy-notice.component.css']
})
export class PrivacyNoticeComponent implements OnInit {
  constructor() { }

  openModal(template: TemplateRef<any>) {
  }

  ngOnInit() {
  }

}
