<form [formGroup]="formGroup" (ngSubmit)="onReplaceAllSubmit()">
  <div class="popupLayout replceAllPop">
    <div class="fullWidth">
      <div class="engSearch">
        <label>Engagement Name <span class="mandtoryIcn"> *</span> : {{ReplaceEngagementName}}</label>
        <mat-form-field class="resourceSearch" appearance="outline"
          [ngClass]="{ 'is-invalid': isValidInput('EngagementName') }">
          <input type="text" formControlName="EngagementName" placeholder="Search Engagement..." 
          matInput autocomplete="off"
            appSearchDebounce (debounceKeyup)="search()" maxlength="200"
            (click)="onEngagementClick()">
          <mat-icon (click)="onEngagementClear()">close</mat-icon>
        </mat-form-field>
        <!-- <div *ngIf="isValidInput('EngagementName')" class="alert alert-danger">
          <div *ngIf="formGroup.controls['EngagementName'].errors.required && IsDataNotFound === true">
            Engagement is required.
          </div>

        
        </div> -->
           <!-- for Favourite  Engagement --- --------------------   START -->
           <div class="dropdown-list-wrap" *ngIf="showFavouriteEngList">
            <mat-spinner *ngIf="showSearchLoaderfav" [diameter]="40"></mat-spinner>
  
            <!-- <ul  class="dropdown-list tabdropdwn_actn" *ngIf="addFavouriteEngResults?.length===0">
              <li>
                  <span style="padding-left:8px;color:#ffe600;padding-top: 4px;">
                  {{lbl}}</span>
              </li>
            </ul> -->
            <ul
              *ngIf="addFavouriteEngResults?.length > 0"
              class="dropdown-list tabdropdwn_actn"
            >
              <li><span class="favouriteText">Favourites</span></li>
              <li
                *ngFor="let item of addFavouriteEngResults; let i = index"
                (click)="OnItemSelect(item)"
                (keyup.enter)="OnItemSelect(item)"
                class="dropdown-list-item"
                tabindex="0"
              >
                <span class="EngFullname"> {{ item.EngagementFullName }} </span>
  
                <img
                  #Favimage1
                  (click)="onFavoriteImageClick(item)"
                  class="favouriteIcon exportExcelIcon"
                  src="../../../../{{ imagePath }}{{ item.favEngSrc }}"
                />
              </li>
            </ul>
            <div
              *ngIf="
              showSearchLoaderfav === false &&
                addFavouriteEngResults?.length === 0 &&
                IsDataNotFound === true"
             >
               No Engagement Found
            </div>
          </div>
          <!-- for Favourite  Engagement --- --------------------   END -->
  
        <div class="dropdown-list-wrap" *ngIf="showSearchList">
          <mat-spinner *ngIf="showSearchLoader" [diameter]='40'></mat-spinner>
          <ul *ngIf="results?.length > 0" class="dropdown-list">
            <li *ngFor="let item of results" (click)="OnItemSelect(item)" class="dropdown-list-item">
              <span class="EngFullname"> {{ item.EngagementFullName }} </span>

              <img
                #Favimage1
                (click)="onFavoriteImageClick(item)"
                class="exportExcelIcon favouriteIcon"
                src="../../../../{{ imagePath }}{{ item.favEngSrc }}"
                
              />
            </li>
          </ul>
          <div *ngIf="showSearchLoader === false && results.length === 0">No Engagement Found</div>
        </div>

      </div>
    </div>
    <div class="customRow">
      <div class="halfWidthItem">
        <label>Engagement Type</label>
        <mat-form-field appearance="outline">
          <input type="text" matInput (change)="makeDirty(bookingType)" [(ngModel)]='bookingType' [readonly]="true" 
            [ngModelOptions]="{standalone:true}">
        </mat-form-field>

      </div>
      <div class="halfWidthItem">
        <label>Engagement Code</label>
        <mat-form-field appearance="outline">
          <input type="text" matInput (change)="makeDirty(engagementCode)" [(ngModel)]='engagementCode'
            [readonly]="true" [ngModelOptions]="{standalone:true}">
        </mat-form-field>
      </div>
    </div>
    <div class="customRow">
      <div class="halfWidthItem">
        <label class="formLabel">Engagement Service Line</label>
        <mat-form-field appearance="outline">
          <input type="text" matInput (change)="makeDirty(engagementServiceLine)" [(ngModel)]='engagementServiceLine'
            [readonly]="true" [ngModelOptions]="{standalone:true}">
        </mat-form-field>

      </div>
      <div class="halfWidthItem">
        <label class="formLabel">Engagement Status</label>
        <mat-form-field appearance="outline">
          <input type="text" matInput (change)="makeDirty(engagementStatus)" [(ngModel)]='engagementStatus'
            [readonly]="true" [ngModelOptions]="{standalone:true}">
        </mat-form-field>

      </div>
    </div>
    <div class="fullWidth">
      <label >Notes Description</label>
      <mat-form-field appearance="outline">
        <input matInput  autocomplete="off" #tooltip="matTooltip"
        matTooltip="Please do not enter any unnecessary personal information or any sensitive personal data (including tax file numbers or other government identifiers), client confidential information, audit secrets, state secrets, commercial secrets, or anything that would violate professional secrecy or confidentiality rules or that would be considered abusive/irrelevant."
          triggers="hover click focus" placement="bottom" type="text" (change)="makeDirty(description)"
          [(ngModel)]='description' [ngModelOptions]="{standalone:true}" maxlength="200">
      </mat-form-field>
      
    </div>
    <div class="SubmitContainer">
      
      <button class="submitButton" type="submit" [disabled]="(!(buttonText == 'Submit'))">
        {{buttonText}}</button>
    </div>
  </div>

 

</form>


