import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, Subscription } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router'
@Injectable({
  providedIn: 'root'
})
export class TokenService {
  refreshTokenUrl = '';
  private authenticationUrl = '';
  private serviceApiUrl = '';

  constructor(private http: HttpClient, private router: Router) {
    // this.authenticationUrl = environment.autherizationServiceUrl;
    // this.serviceApiUrl = environment.APIEndpoint;
    // this.refreshTokenUrl = environment.refreshTokenUrl;
  }
  httpOptions = {
    headers: new HttpHeaders({ 'Bearer': 'X-MS-TOKEN-AAD-ACCESS-TOKEN' })
  };

  validateUser(): Observable<any> {
    return this.http.get<any>(this.authenticationUrl,
      this.httpOptions).pipe(
        map(data => {
          sessionStorage.setItem('access_token', data[0].access_token);
          sessionStorage.setItem('expires_on', data[0].expires_on);
          sessionStorage.setItem('userclaims', JSON.stringify(data[0].user_claims));
          // sessionStorage.setItem('user_id', data[0].user_id);
          // sessionStorage.setItem('user_name', data[0].user_claims.find(o => o.typ === 'name').val);
          return data;
        }),
        catchError(this.handleError<any>('validateUser'))
      );
  }

  refreshToken(): Observable<any> {
    return this.http.get<any>(this.refreshTokenUrl,
      this.httpOptions).pipe(
        map(data => data),
        catchError(this.handleError<any>('refreshToken'))
      );
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error); // log to console instead


      return of(result as T);
    };
  }
}
