
export class UserVM {
    Id: number;
    Email: string ;
    EmailSubscription: boolean | null;
    PrivacyNotice: boolean | null;
    EmailType: number;
    PercentSelected: boolean;
    RoleFeatures: string[];
    RoleType: string;
    RoleTypeDisplayName: string;    
    //RoleTypeId: number;
    RoleId: number;
    RoleName: string;
    UserId: number;
    ResourceName: string;
    IsMultiInstance: boolean;
    R_InstEnable_IV: boolean;
    IsApproval: boolean;
    DefaultInstance: number;
    PreviewSubmit: boolean;
    OverAllocation: number;
    DefaultDateFormat: string;
    Unconfirmed: boolean;
    Ghost: boolean;
    RequestMode: number | null;
    GPN: string;
    R_InstEnable_AddBooking : boolean;
    R_Home_InstanceID : number;
    DefaultTheme: string;
}