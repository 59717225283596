import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import { BookingGridActionService } from '../../../Models/BookingGridActionService';
import { IndividualBookingVM } from '../../../Models/IndividualBookingVM';
import { IndividualService } from '../individual.service';
import { Subscription } from 'rxjs';
import { EngagementService } from '../../engagement/engagement.service';
import moment from 'moment';
import { UiViewSourceEnum } from 'app/shared/enum';
import { CurrentWeekActionDTO } from '../../../Models/CurrentWeekActionDTO';

@Component({
  selector: 'app-parent-edit',
  templateUrl: './parent-edit.component.html',
  styleUrls: ['./parent-edit.component.css']
  //, encapsulation: ViewEncapsulation.None       // commented for Notes Description tooltip css to work properly.
})
export class ParentEditComponent implements OnInit {

  eventData: BookingGridActionService;
  @Input() isPercentageSelected;
  @Input() gridActionEventData: BookingGridActionService;
  @Input() parentViewIVorEV: string;
  @Output() isFormDirtyFlag: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() singleEdit;
  @Input() currentWeekActionDTO: CurrentWeekActionDTO;

  parentDataItem: any;
  splitReqDto: {
    BookingId: number | null,
    ResourceId: number,
    JobId: number,
    InstanceId: number,
    StartDate: Date | null,
    EndDate: Date | null,
    MeraActionDescriptionId: number,
    UiViewSource: string
  };
  bsConfig: Partial<BsDatepickerConfig>;
  formGroup: FormGroup;
  myFormGroup: FormGroup;
  bookingList: IndividualBookingVM[];
  colorTheme = 'theme-dark-blue';
  buttonText = "Submit";
  dirtyFlag = true;
  engagementAllocationText = '';
  editBookingSubscription: Subscription;
  validateHourSubscription: Subscription;
  hoursValid = true;
  isAllChecked: boolean = true;
  confirmHours: boolean = false;
  bookingListSubscription: Subscription;
  //startOfWeek = new Date();
  resource;
  today = new Date();
  todaysDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), 0, 0, 0);
  startOfWeek = this.getFirstDateOfWeek(this.todaysDate);//new Date();
  UiViewSource: string;
  constructor(private individualServices: IndividualService,
    private engagementService: EngagementService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder) {
    this.formGroup = new FormGroup({
      selectAll_chk: new FormControl({ value: true }, [])
    });

  }

  ngOnInit() {
    this.resource = JSON.parse(sessionStorage.getItem('resource'));
    const currentDate = new Date();
    // this.getCurrentWeek();
    //console.log('CW from edit', this.currentWeekActionDTO);
    this.enabledWeek(!this.currentWeekActionDTO.IsDisabledEditBookingCurrWeek);
    // console.log('!this.currentWeekActionDTO.IsDisabledEditBookingCurrWeek',!this.currentWeekActionDTO.IsDisabledEditBookingCurrWeek)
    let group = {}
    group['number_of_resource'] = new FormControl('');
    this.myFormGroup = new FormGroup(group);

    this.engagementAllocationText = this.isPercentageSelected ? 'Loading %' : 'Hours';
    this.splitReqDto = {
      BookingId: this.singleEdit ? this.gridActionEventData.dataItem.BookingId : 0,
      ResourceId: this.gridActionEventData.dataItem.ResourceId,
      JobId: this.gridActionEventData.dataItem.JobId,
      InstanceId: this.gridActionEventData.dataItem.InstanceId,
      StartDate: this.gridActionEventData.dataItem.StartDate,
      EndDate: this.gridActionEventData.dataItem.EndDate,
      MeraActionDescriptionId: 2,
      UiViewSource: this.parentViewIVorEV
    };
    this.parentDataItem = this.gridActionEventData.dataItem;
    this.parentDataItem.IsPercentSelected = this.isPercentageSelected;

    if (this.parentViewIVorEV == "IV") {
      this.getIVBookingList();
      this.UiViewSource = UiViewSourceEnum.IndividualView;
    }
    else {
      this.getEVBookingList();
      this.UiViewSource = UiViewSourceEnum.EngamentView;
    }

    this.isFormDirtyFlag.emit(false);
  }

  // this.EditWeek = false;
  // this.IsDisabledEditBooking=false;
  enabledWeek(isCurrentWeekEditable) {
    if(this.currentWeekActionDTO.IsDisabledEditBookingCurrWeek === true){
      isCurrentWeekEditable = false;
    }
    this.startOfWeek = this.getFirstDateOfWeek(this.todaysDate);
    if (isCurrentWeekEditable) {
      this.startOfWeek.setMinutes(this.startOfWeek.getMinutes() - this.startOfWeek.getTimezoneOffset());
    }
    else {
      this.startOfWeek.setDate(this.startOfWeek.getDate() + 7);
      this.startOfWeek.setMinutes(this.startOfWeek.getMinutes() - this.startOfWeek.getTimezoneOffset());
    }
    return this.startOfWeek;

    // const dayNeeded = 7 //For Saturday
    // const currentDay = moment().isoWeekday();
    // if(isCurrentWeekEditable){
    //   if (currentDay === dayNeeded) { 
    //     let day = moment().day(dayNeeded);
    //     this.startOfWeek = new Date(day.year(),day.month(),day.date())
    //   }else{
    //     let day = moment().subtract(1, 'weeks').isoWeekday(dayNeeded);
    //     this.startOfWeek = new Date(day.year(),day.month(),day.date());  
    //   }
    // }else{
    //   if (currentDay === dayNeeded) { 
    //     let day = moment().add(1, 'weeks').isoWeekday(dayNeeded);
    //     this.startOfWeek = new Date(day.year(),day.month(),day.date());
    //   }else{
    //     let day = moment().day(dayNeeded);
    //     this.startOfWeek = new Date(day.year(),day.month(),day.date());

    //   }
    // }
 
  }

  getFirstDateOfWeek(anyDateOfWeek: Date): Date {
    // date.getDay() RETURNS Week Day Index: SUN 0, MON 1, TUE 2, WED 3, THU 4, FRI 5, SAT 6.
    let date = new Date(anyDateOfWeek);
    let firstDayOfWeekIndex: number = 6; //Week Day Index: SUN 0, MON 1, TUE 2, WED 3, THU 4, FRI 5, SAT 6.
    let daysToShiftBack = (7 + (date.getDay() - firstDayOfWeekIndex)) % 7;
    let firstDateOfWeek = new Date(date.setDate(date.getDate() - daysToShiftBack));
    return firstDateOfWeek;
  }
  // getCurrentWeek() {
  //   this.spinner.show();
  //   this.individualServices.getCurrentWeek().subscribe(
  //     (data: any) => {
  //       this.EditWeek = data.EditWeek; 
  //       if(this.EditWeek == 1 ){
  //         this.enabledWeek(false);
  //       }
  //       if(this.EditWeek == 0 || this.EditWeek == null)
  //     {
  //       this.enabledWeek(true);
  //     }        
  //     });
  //     this.spinner.hide();
  // }
  ngOnDestroy() {
    if (this.editBookingSubscription !== undefined) {
      this.editBookingSubscription.unsubscribe();
    }
    if (this.validateHourSubscription !== undefined) {
      this.validateHourSubscription.unsubscribe();
    }
    if (this.bookingListSubscription !== undefined) {
      this.bookingListSubscription.unsubscribe();
    }
  }

  makeDirty() {
    if (!this.dirtyFlag) {
      this.dirtyFlag = true;
    }
    if (this.dirtyFlag) {
      this.isFormDirtyFlag.emit(this.dirtyFlag);
    }
  }

  changeSelectAll(event) {
    this.bookingList.forEach((num, i) => {
      if (num.IsCurrentEditWeek.valueOf() != true && num.IsDisabledEditBooking.valueOf() != true) {
        this.myFormGroup.get("editchk_" + i).setValue(event.checked);
        this.setControlsStatus(i, event.checked);
      }
    });
    this.dirtyFlag = event.checked;
    if (this.dirtyFlag) {
      this.isFormDirtyFlag.emit(this.dirtyFlag);
    }
  }

  setControlsStatus(index, isEnabled) {
    if (!isEnabled) {
      this.myFormGroup.get("editUnconfirmed_" + index).disable();
      this.myFormGroup.get("editGhost_" + index).disable();

      this.myFormGroup.get("editPeriod_" + index).disable();
      this.myFormGroup.get("editPeriod_" + index).markAsUntouched();
      this.myFormGroup.get("editPeriod_" + index).markAsPristine();
      //this.myFormGroup.get("editPeriod_" + index).clearValidators();
      this.myFormGroup.get("editPeriod_" + index).get('dtStart').clearValidators();
      this.myFormGroup.get("editPeriod_" + index).get('dtEnd').clearValidators();
      //this.myFormGroup.get("editPeriod_" + index).updateValueAndValidity();      
      this.myFormGroup.get("editPeriod_" + index).get('dtStart').updateValueAndValidity();
      this.myFormGroup.get("editPeriod_" + index).get('dtEnd').updateValueAndValidity();
      //this.myFormGroup.get("editPeriod_" + index).setErrors(null);      
      this.myFormGroup.get("editPeriod_" + index).get('dtStart').setErrors(null);
      this.myFormGroup.get("editPeriod_" + index).get('dtEnd').setErrors(null);

      if (!this.isPercentageSelected) {
        this.myFormGroup.get("editHours_" + index).disable();
        this.myFormGroup.get("editHours_" + index).markAsUntouched();
        this.myFormGroup.get("editHours_" + index).markAsPristine();
        this.myFormGroup.get("editHours_" + index).clearValidators();
        this.myFormGroup.get("editHours_" + index).updateValueAndValidity();
        this.myFormGroup.get("editHours_" + index).setErrors(null);
      }
      else {
        this.myFormGroup.get("editLoading_" + index).disable();
        this.myFormGroup.get("editLoading_" + index).markAsUntouched();
        this.myFormGroup.get("editLoading_" + index).markAsPristine();
        this.myFormGroup.get("editLoading_" + index).clearValidators();
        this.myFormGroup.get("editLoading_" + index).updateValueAndValidity();
        this.myFormGroup.get("editLoading_" + index).setErrors(null);
      }
      this.myFormGroup.get("editNotes_" + index).disable();
      this.myFormGroup.get("editNotes_" + index).clearValidators();
      this.myFormGroup.get("editNotes_" + index).updateValueAndValidity();
      // this.myFormGroup.get("editNotes_" + index).reset();
    }
    else {
      this.myFormGroup.get("editUnconfirmed_" + index).enable();
      this.myFormGroup.get("editGhost_" + index).enable();
      this.myFormGroup.get("editPeriod_" + index).enable();
      this.myFormGroup.get("editPeriod_" + index).markAsDirty();
      this.myFormGroup.get("editPeriod_" + index).markAsTouched();
      //this.myFormGroup.get("editPeriod_" + index).setValidators([Validators.required]);
      this.myFormGroup.get("editPeriod_" + index).get('dtStart').setValidators([Validators.required]);
      this.myFormGroup.get("editPeriod_" + index).get('dtEnd').setValidators([Validators.required]);
      //this.myFormGroup.get("editPeriod_" + index).updateValueAndValidity();
      this.myFormGroup.get("editPeriod_" + index).get('dtStart').updateValueAndValidity();
      this.myFormGroup.get("editPeriod_" + index).get('dtEnd').updateValueAndValidity();
      if (!this.isPercentageSelected) {
        this.myFormGroup.get("editHours_" + index).enable();
        this.myFormGroup.get("editHours_" + index).markAsDirty();
        this.myFormGroup.get("editHours_" + index).markAsTouched();
        //this.myFormGroup.get("editHours_" + index).setValidators([Validators.required, Validators.pattern(/^(?!(0))[0-9]*(\.[0-9]{0,2})?$/), Validators.max(2147483647)]);
        this.myFormGroup.get("editHours_" + index).setValidators([Validators.required, Validators.pattern(/^(?!0*\.?0+$)\d*\.?\d{1,2}$/), Validators.max(2147483647)]);
        this.myFormGroup.get("editHours_" + index).updateValueAndValidity();
      }
      else {
        this.myFormGroup.get("editLoading_" + index).enable();
        this.myFormGroup.get("editLoading_" + index).markAsDirty();
        this.myFormGroup.get("editLoading_" + index).markAsTouched();
        //this.myFormGroup.get("editLoading_" + index).setValidators([Validators.required, Validators.pattern(/^(?!(0))[0-9]*(\.[0-9]{0,2})?$/), Validators.max(2147483647)]);
        this.myFormGroup.get("editLoading_" + index).setValidators([Validators.required, Validators.pattern(/^(?!0*\.?0+$)\d*\.?\d{1,2}$/), Validators.max(2147483647)]);
        this.myFormGroup.get("editLoading_" + index).updateValueAndValidity();
      }
      this.myFormGroup.get("editNotes_" + index).enable();
    }

  }

  changeCheck(index, event) {
    if (event.checked) {
      this.isAllChecked = true;
      this.dirtyFlag = true;
      this.bookingList.forEach((num, i) => {
        if (i != index && this.myFormGroup.get("editchk_" + i).value == false
          && num.IsCurrentEditWeek.valueOf() != true && num.IsDisabledEditBooking.valueOf() != true) {
          this.isAllChecked = false;
          return;
        };
      });
      this.formGroup.get("selectAll_chk").setValue(this.isAllChecked);
    } else {
      this.formGroup.get("selectAll_chk").setValue(false);
      this.dirtyFlag = false;
      this.bookingList.forEach((num, i) => {
        if (i != index && this.myFormGroup.get("editchk_" + i).value == true
          && num.IsCurrentEditWeek.valueOf() != true && num.IsDisabledEditBooking.valueOf() != true) {
          this.dirtyFlag = true;
          return;
        };
      });
    }
    this.setControlsStatus(index, event.checked);
    this.myFormGroup.get("editchk_" + index).enable();
    // this.dirtyFlag = 
    // !(!this.myFormGroup.get((this.isPercentageSelected ? 'editLoading_' : 'editHours_')+ index).value 
    // || this.myFormGroup.get((this.isPercentageSelected ? 'editLoading_' : 'editHours_') + index).value.trim() == '' 
    // || isNaN(+this.myFormGroup.get((this.isPercentageSelected ? 'editLoading_' : 'editHours_') + index).value));
  }

  // hoursLoadingError = false;
  // onHoursLoadingChange(event){
  //   this.makeDirty();
  //   this.dirtyFlag = !(!event.srcElement.value || event.srcElement.value.trim() == '' || isNaN(+event.srcElement.value));
  // }

  getIVBookingList() {
    if (!this.splitReqDto) return;
    this.spinner.show();
    this.bookingListSubscription =
      this.individualServices.getBookingSplittedInPastFuture(this.splitReqDto).subscribe(
        //this.individualServices.fetchBookingChildData(this.parentDataItem).subscribe(    
        data => {
          //console.log('getIVBookingList',data)
          this.SetFetchedData(data);
          this.spinner.hide();
        },
        error => {
          this.spinner.hide();
        });
  }

  getEVBookingList() {
    if (!this.splitReqDto) return;
    this.spinner.show();
    this.bookingListSubscription =
      this.individualServices.getBookingSplittedInPastFuture(this.splitReqDto).subscribe(
        //this.engagementService.fetchBookingChildData(this.parentDataItem).subscribe(
        data => {
          this.SetFetchedData(data);
          this.spinner.hide();
        },
        error => {
          this.spinner.hide();
        });
  }

  private SetFetchedData(data) {
    this.bookingList = data;
    this.bookingList.forEach((element, index) => {
      element.StartDate = new Date(element.StartDate),
      element.EndDate = new Date(element.EndDate);
      element.enabledWeekDay = this.enabledWeek(!element.IsCurrentEditWeek);
      //element.BsRangeDate = [new Date(element.StartDate), new Date(element.EndDate)]
      //this.myFormGroup.addControl('editPeriod_' + index.toString(), new FormControl(''));
      // let group = {}
      // group['dtStart'+ index.toString()] = new FormControl('', Validators.required);
      // group['dtEnd'+ index.toString()] = new FormControl('', Validators.required);
      // this.myFormGroup.addControl('editPeriod_' + index.toString(), new FormGroup(group));
      this.myFormGroup.addControl('editPeriod_' + index.toString(),
        this.formBuilder.group({
          dtStart: [moment(element.StartDate).format("YYYY-MM-DD HH:mm:ss"), Validators.required],
          dtEnd: [moment(element.EndDate).format("YYYY-MM-DD HH:mm:ss"), Validators.required],

          // dtStart : [new Date(element.StartDate), Validators.required],
          // dtEnd : [new Date(element.EndDate), Validators.required],
        })
      );
      // this.formGroup.get('editPeriod_' + index.toString()).get('dtStart').setValue(new Date(element.StartDate));
      // this.formGroup.get('editPeriod_' + index.toString()).get('dtEnd').setValue(new Date(element.EndDate));
      this.myFormGroup.addControl('editHours_' + index.toString(),
        //new FormControl(element.Hours, [Validators.required, Validators.pattern(/^(?!(0))[0-9]*(\.[0-9]{0,2})?$/), Validators.max(2147483647)]));
        new FormControl(element.Hours, [Validators.required, Validators.pattern(/^(?!0*\.?0+$)\d*\.?\d{1,2}$/), Validators.max(2147483647)]));
      this.myFormGroup.addControl('editLoading_' + index.toString(),
        //new FormControl(element.Loading, [Validators.required, Validators.pattern(/^(?!(0))[0-9]*(\.[0-9]{0,2})?$/), Validators.max(2147483647)]));
        new FormControl(element.Loading, [Validators.required, Validators.pattern(/^(?!0*\.?0+$)\d*\.?\d{1,2}$/), Validators.max(2147483647)]));
      this.myFormGroup.addControl('editUnconfirmed_' + index.toString(),
        new FormControl(element.Unconfirmed));
      this.myFormGroup.addControl('editGhost_' + index.toString(),
        new FormControl(element.Ghost));
      this.myFormGroup.addControl('editchk_' + index.toString(), new FormControl(''));
      this.myFormGroup.addControl('editNotes_' + index.toString(),
        new FormControl(element.Description));
      this.setControlsStatus(index, !(element.IsCurrentEditWeek || element.IsDisabledEditBooking));
      this.myFormGroup.get('editchk_' + index.toString()).setValue(!(element.IsCurrentEditWeek || element.IsDisabledEditBooking));
    })
    //console.log('setdata-bookingList',this.bookingList);
  }

  public confirmHoursChange(confirmHrs) {
    this.confirmHours = confirmHrs;
    if (this.confirmHours == true) {
      this.bookingList.forEach((num, i) => {
        if (this.myFormGroup.get((this.isPercentageSelected ? 'editLoading_' : 'editHours_') + i).value != '') {
          this.myFormGroup.get((this.isPercentageSelected ? 'editLoading_' : 'editHours_') + i).setErrors(null);
          this.dirtyFlag = true;
        }
      })
    }
    else {
      this.dirtyFlag = false;
    }
  }

  onEditSubmit() {
    if (this.confirmHours != true) {
      this.validateAndSaveRecords();
    }
    else {
      this.saveRecords();
    }
  }

  validateAndSaveRecords() {
    let validateBookings: any[] = [];
    if (this.myFormGroup.valid) {
      this.bookingList.forEach((num, i) => {
        if (this.myFormGroup.get("editchk_" + i).value == true
          && num.IsCurrentEditWeek.valueOf() != true && num.IsDisabledEditBooking.valueOf() != true)
        //if (this.myFormGroup.get("editchk_" + i).value == true) 
        {
          //let pastBk = this.bookingList.find(x => x.ActualBookingId == num.BookingId && x.MeraActionDescriptionId == 1);
          validateBookings.push({
            BookingId: num.BookingId,
            ResourceId: num.ResourceId,
            StartDate: (this.myFormGroup.get('editPeriod_' + i).get('dtStart').value),
            EndDate: (this.myFormGroup.get('editPeriod_' + i).get('dtEnd').value),
            //Hours: !this.isPercentageSelected ? +(this.myFormGroup.get('editHours_' + i).value) + (pastBk ? pastBk.Hours : 0) : num.Hours,
            Hours: !this.isPercentageSelected ? this.myFormGroup.get('editHours_' + i).value : num.Hours,
            Loading: this.isPercentageSelected ? this.myFormGroup.get('editLoading_' + i).value : num.Loading,
            IsPercentSelected: this.isPercentageSelected,
            InstanceId: num.InstanceId
          });
        }
      });

      this.buttonText = "Validating...";
      this.validateHourSubscription =
        this.individualServices.ValidateHoursLoadings(validateBookings).subscribe(
          (data: any) => {
            let hoursLoadingValidations: { BookingId: number, IsValid: boolean }[] = data;
            hoursLoadingValidations.forEach((num, i) => {
              if (!num.IsValid) {
                this.hoursValid = false;
                this.dirtyFlag = false;
                let idx = this.bookingList.findIndex(x => x.BookingId == num.BookingId);
                if (this.isPercentageSelected) {
                  this.myFormGroup.get('editLoading_' + idx).setErrors({ 'incorrect': true });
                }
                else {
                  this.myFormGroup.get('editHours_' + idx).setErrors({ 'incorrect': true });
                }
              }
            })

            if (this.hoursValid) {
              this.saveRecords();
            }
            else {
              this.buttonText = "Submit";
            }
          }
        );
    }
  }

  saveRecords() {
    this.buttonText = "Submitting...";
    // let editBookings: any[] = [];
    // this.bookingList.forEach((num, i) => {
    //   if (this.myFormGroup.get("editchk_" + i).value == true 
    //   && num.IsCurrentEditWeek.valueOf() != true) {
    //     editBookings.push({
    //       BookingId: num.BookingId,
    //       ResourceId: num.ResourceId,
    //       JobId: num.JobId,
    //       JobCategory: num.JobCategory,
    //       BookingType: num.BookingType,
    //       Description: this.myFormGroup.get('editNotes_' + i).value,
    //       StartDate: (this.myFormGroup.get('editPeriod_' + i).get('dtStart').value),
    //       EndDate: (this.myFormGroup.get('editPeriod_' + i).get('dtEnd').value),
    //       Hours: !this.isPercentageSelected ? this.myFormGroup.get('editHours_' + i).value : num.Hours,
    //       Loading: this.isPercentageSelected ? this.myFormGroup.get('editLoading_' + i).value : num.Loading,
    //       Unconfirmed: this.myFormGroup.get("editUnconfirmed_" + i).value,
    //       Ghost: this.myFormGroup.get("editGhost_" + i).value,
    //       IsPercentSelected: this.isPercentageSelected, 
    //       RequestMode: JSON.parse(sessionStorage.getItem('requestMode')),
    //       InstanceId: num.InstanceId
    //     });
    //   }
    // })
    let bookingDtos: any[] = [];
    this.bookingList.forEach((num, i) => {
      if (this.myFormGroup.get("editchk_" + i).value == true
        && num.IsCurrentEditWeek.valueOf() != true && num.IsDisabledEditBooking.valueOf() != true) {
        //let pastBk = this.bookingList.find(x => x.ActualBookingId == num.BookingId && x.MeraActionDescriptionId == 1);
        bookingDtos.push({
          BookingId: num.BookingId,
          ResourceId: num.ResourceId,
          JobId: num.JobId,
          BookingType: num.BookingType,
          Description: this.myFormGroup.get('editNotes_' + i).value,
          StartDate: (this.myFormGroup.get('editPeriod_' + i).get('dtStart').value),
          EndDate: (this.myFormGroup.get('editPeriod_' + i).get('dtEnd').value),
          //Hours: !this.isPercentageSelected ? +(this.myFormGroup.get('editHours_' + i).value) + (pastBk ? pastBk.Hours : 0) : num.Hours,
          Hours: !this.isPercentageSelected ? this.myFormGroup.get('editHours_' + i).value : num.Hours,
          Loading: this.isPercentageSelected ? this.myFormGroup.get('editLoading_' + i).value : num.Loading,
          Unconfirmed: this.myFormGroup.get("editUnconfirmed_" + i).value,
          Ghost: this.myFormGroup.get("editGhost_" + i).value,
          IsOverallocation: this.confirmHours,
          InstanceId: this.bookingList[0].InstanceId,
          ActualBookingId: num.BookingId,
          ParentBookingId: null
        });
      }
    })

    let bookingActionDto = {
      IsPercentSelected: this.isPercentageSelected,
      RequestMode: JSON.parse(sessionStorage.getItem('requestMode')),
      //InstanceId: this.bookingList[0].InstanceId,
      Bookings: bookingDtos,
      UiViewSource: this.UiViewSource
    };

    this.editBookingSubscription =
      this.individualServices.EditBookings(bookingActionDto).subscribe(
        (data: any) => {
          if (data.status = '200') {
            let response = data.body;
            if (response.customResultId > 0) {
              if (this.parentViewIVorEV == "IV") {
                this.individualServices.setRquestNumber(response.customResultId);
              }
              else {
                this.engagementService.setRquestNumber(response.customResultId);
              }
            }
            if (this.parentViewIVorEV == "IV") {
              this.individualServices.closeModal(true);
            }
            else {
              this.engagementService.closeModal(true);
            }
          }
          this.buttonText = "Submit";
        }, err => {
          this.buttonText = "Error";
        }, () => {
          this.buttonText = "Submit";
        }
      );
    this.dirtyFlag = false;
  }

}
