export interface Theme {
  name: string;
  properties: any;
}
export const dark: Theme = {
  name: 'dark',
  properties: {
    '--background-default': '#1A1A24',
    '--background-page': '#1A1A24',
    '--background-secondary': '#2E2E38',
    '--background-tertiary': '#000000',
    '--background-header-dd-hover': '#000000',
    '--background-scrollbar': 'rgba(255,255,255,0.5)',
    '--scrollbar-thumb': '#2E2E38',
    '--background-hover': 'rgba(0,0,0,0.5)',
    '--background-hover-light': 'rgba(0,0,0,0.3)',
    '--background-section': 'rgba(46, 46, 56,0.3)',
    '--container-background': 'rgba(46, 46, 56, 0.5)',
    '--background-accordian': 'rgba(46, 46, 56,0)',
    '--background-accordian-full': 'rgba(46, 46, 56)',
    '--table-background': '#22222c',
    '--table-scrollbar-bg': 'rgb(128 128 128 / 20%)',
    '--table-body-cell-background-odd': '#1A1A24',
    '--table-body-cell-background-even': '#2E2E38',
    '--table-body-2nd-cell-bg': '#1a1a24',
    '--table-cell-background-odd': '#1A1A24',
    '--table-cell-background-even': '#2E2E38',
    '--table-cell-background-check': '#1A1A24',
    '--table-label': '#2E2E38',
    '--table-heading-color': '#ffe600',
    '--checkbox-bg': '#777',
    '--table-checkbox-label-color': '#fff',
    '--table-header-cell-color': '#fff',
    '--table-body-cell-color': 'rgba(255,255,255,0.5)',
    '--table-checkbox-color': 'rgba(255,255,255,0.5)',
    '--grey-background': '#747480',
    '--disabled-border-color': '#5b5a5a',

    '--dropdown-bankground': '#2e2e38',
    '--dropdown-color': '#fff',
    '--dropdown-text-color': '#fff',

    // Modal
    '--modal-close-color': 'rgba(255,255,255,0.5)',
    '--modal-text-color': 'rgba(255,255,255,0.5)',
    '--modal-bg': '#2e2e38',
    '--modal-header-color': '#ffe600',
    '--modal-body-bg': '#2e2e38',
    '--modal-body-color': 'rgba(255,255,255,0.5)',
    '--modal-wall-border': '#74747f',
    '--modal-dropdown-hover': 'rgba(0, 0, 0, 0.8)',
    '--modal-wall-rect-border': '#333',
    '--paginator': '#24242e',

    // Home
    '--mera-header': '#ffe600',
    '--home-tile-background': 'rgba(46, 46, 56,0.3)',
    '--home-count-color': '#fff',
    '--home-count-subtext-color': '#ffe600',
    '--awaiting-approval-bg': '#1A1A24',
    '--awaiting-approval-text-color': '#777',
    '--awaiting-approval-count-color': '#2e2e38',
    '--awaiting-approval-count-bgcolor': '#ffe600',
    '--collapsible-bg': 'rgba(46, 46, 56,0.3)',

    //Queue
    '--input-placeholder': 'rgba(255,255,255,0.5)',
    '--border-queue': 'rgba(255,255,255,0.3)',
    '--approval-bg-second': 'rgb(54, 54, 64)',
    '--queue-label': '#fff',
    '--queue-desc': 'rgba(255,255,255,0.5)',
    '--queue-tab-text': '#ffe600',

    '--primary-default': 'rgba(255,255,255,0.5)',
    '--primary-dark': 'rgba(255,255,255,1)',
    '--primary-yellow': '#ffe600',
    '--primary-disabled': '#C4C4CD',
    '--primary-error-red': '#f00',
    '--siv-over-allocation': '#f00',
    '--paginator-disabled-arrow': 'rgba(255,255,255,0.2)',

    '--border-menu': 'rgba(255,255,255,0.15)',
    '--border-dropdown': '#707070',
    '--border-default': 'rgba(255,255,255,0.3)',
    '--border-table': '#74747f',
    '--border-yellow': 'rgba(255, 230, 0, 0.35)',
    '--border-search': '#45454c',
    '--border-division': '#45454c',

    '--error-default': '#ef3e36',
    '--error-dark': '#800600',
    '--error-light': '#ffcecc',

    '--sticky-border': '#2f2b2b',
    '--sticky-body-cell-border': '#2f2b2b',
    '--odd-border': '#3a3a3f',
    '--odd-body-cell-border': '#3a3a3f',

    '--section-heading-color': '#ffe600',
    '--forecast-box-bg': 'transparent',
    '--white-bg-input': 'transparent',
    '--section-heading-background': '#747480',
    '--subsection-heading-color': '#ffe600',
    '--collapsible-header-background': '#1a1a24',
    '--collapsible-header-text-color': '#ffe600',
    '--collapsible-header-color': '#fff',
    '--collapsible-content-background': '#1a1a24',
    '--slider-background': 'rgba(255,255,255,0.3)',
    '--slider-thumb': '#000',

    /* Buttons */
    '--primary-button-background': '#ffe600',
    '--primary-button-color': '#1A1A24',
    '--secondary-button-background': '#2E2E38',
    '--secondary-button-color': '#FFF',

    /* Shadows */
    '--background-shadow': 'rgba(0,0,0,0.07)',
    // booking color status
    '--confirmed-chargeable': '#0080FF',
    '--unconfirmed-chargeable': '#00FFFF',
    '--confirmed-authorised': '#FCDD03',
    '--unconfirmed-authorised': '#FFFF80',
    '--confirmed-nonchargeable': '#00EC00',
    '--unconfirmed-nonchargeable': '#9FFF9F',
    '--tr-successfactor': '#FD00FD',
  },
};

export const light: Theme = {
  name: 'light',
  properties: {
    '--background-default': '#c1c1ca',
    '--background-page': '#fff',
    '--background-secondary': '#2E2E38',
    '--background-tertiary': '#000033',
    '--background-header-dd-hover': '#6d6d79',
    '--background-scrollbar': '#eee',
    '--scrollbar-thumb': '#777',
    '--background-hover': '#00000033',
    '--background-hover-light': 'rgba(255,255,255,255.3)',
    '--background-section': '#FFFFFF',
    '--container-background': 'rgba(46, 46, 56, 0.5)',
    '--background-accordian': '#FFFFFF',
    '--background-accordian-full': '#FFFFFF',
    '--table-background': '#fff',
    '--table-scrollbar-bg': 'rgb(128 128 128 / 20%)',
    '--table-body-cell-background-odd': '#eaeaeb',
    '--table-body-2nd-cell-bg': '#fff',
    '--table-body-cell-background-even': '#fff',
    '--table-cell-background-odd': '#6d6d79',
    '--table-cell-background-even': '#747480',
    '--table-cell-background-check': '#747480',
    '--table-label': '#fff',
    '--table-heading-color': '#000',
    '--checkbox-bg': '#808080',
    '--table-checkbox-label-color': '#fff',
    '--table-header-cell-color': '#fff',
    '--table-body-cell-color': '#2e2e38',
    '--table-checkbox-color': '#fff',
    '--grey-background': '#747480',
    '--disabled-border-color': '#5b5a5a',

    '--dropdown-bankground': '#2e2e38',
    '--dropdown-color': '#fff',
    '--dropdown-text-color': '#2e2e38',

    // Modal
    '--modal-close-color': 'rgba(255,255,255,0.5)',
    '--modal-text-color': '#fff',
    '--modal-bg': '#3a3a4a',
    '--modal-header-color': '#fff',
    '--modal-body-bg': '#fff',
    '--modal-body-color': '#2e2e38',
    '--modal-wall-border': '#b2b2b2',
    '--modal-dropdown-hover': 'rgba(208, 208, 208, 0.8)',
    '--modal-wall-rect-border': '#ddd',
    '--paginator': '#24242e',

    // Home
    '--mera-header': '#ffe600',
    '--home-tile-background': '#3a3a4a',
    '--home-count-color': '#fff',
    '--home-count-subtext-color': '#fff',
    '--awaiting-approval-bg': '#ffe600',
    '--awaiting-approval-text-color': '#2e2e38',
    '--awaiting-approval-count-color': '#fff',
    '--awaiting-approval-count-bgcolor': '#2e2e38',
    '--collapsible-bg': '#f6f6fa',

    //Queue
    '--input-placeholder': 'rgba(0,0,0,0.8)',
    '--border-queue': '#aaaaaa',
    '--approval-bg-second': '#c6c6c64d',
    '--queue-label': '#2e2e38',
    '--queue-desc': 'rgba(0,0,0,0.5)',
    '--queue-tab-text': '#fff',

    '--primary-default': '#2e2e38',
    '--primary-dark': '#777',
    '--primary-yellow': '#ffe600',
    '--primary-disabled': '#C4C4CD',
    '--primary-error-red': '#f00',
    '--siv-over-allocation': '#fff',
    '--paginator-disabled-arrow': '#c4c4cd',

    '--border-menu': 'rgba(255,255,255,0.15)',
    '--border-dropdown': '#707070',
    '--border-default': 'rgba(0,0,0,0.3)',
    '--border-table': '#74747f',
    '--border-yellow': 'rgba(255, 230, 0, 0.35)',
    '--border-search': '#b2b2b2',
    '--border-division': 'rgb(144 144 144 / 20%)',

    '--error-default': '#ef3e36',
    '--error-dark': '#800600',
    '--error-light': '#ffcecc',

    '--sticky-border': 'rgb(125 125 135 / 20%)',
    '--sticky-body-cell-border': 'rgba(0, 0, 0, .12)',
    '--odd-border': 'rgb(131 131 140 / 20%)',
    '--odd-body-cell-border': '#eaeaeb',

    '--section-heading-color': '#fff',
    '--forecast-box-bg': '#eaeaeb',
    '--white-bg-input': '#fff',
    '--section-heading-background': '#747480',
    '--subsection-heading-color': '#2e2e38',
    '--collapsible-header-background': '#fff',
    '--collapsible-header-text-color': '#2e2e38',
    '--collapsible-header-color': '#747480',
    '--collapsible-content-background': '#fff',
    '--slider-background': '#c4c4cd',
    '--slider-thumb': '#1a1a24',

    /* Buttons */
    '--primary-button-background': '#ffe600',
    '--primary-button-color': '#1A1A24',
    '--secondary-button-background': '#2e2e38',
    '--secondary-button-color': '#FFF',

    /* Shadows */
    '--background-shadow': 'rgba(0,0,0,0.07)',
    // booking color status
    '--confirmed-chargeable': '#0080FF',
    '--unconfirmed-chargeable': '#00FFFF',
    '--confirmed-authorised': '#FCDD03',
    '--unconfirmed-authorised': '#FFFF80',
    '--confirmed-nonchargeable': '#00EC00',
    '--unconfirmed-nonchargeable': '#9FFF9F',
    '--tr-successfactor': 'FD00FD',
  },
};
