<ngx-spinner></ngx-spinner>
<div class="appLayout individualRequest">
  <div class="requestedByMe">
    <div style="display: flex; justify-content: flex-start">
      <div class="heading sectionHeading">
        <span>Completed Requests Filter</span>
        <!-- <mat-icon class="questionIcon"  matTooltip="info" (click)="openModal(templateHelp)">info</mat-icon> -->
        <span
          class="booking_heading_info_icon"
          (click)="openModal(templateHelp)"
          matTooltip="Info"
        ></span>
      </div>
    </div>
    <div class="customFilters">
      <div>
        <form [formGroup]="formGroup">
          <label>Requested Date Range</label>
          <mat-form-field appearance="outline">
            <mat-date-range-input
              formGroupName="dateRange"
              [rangePicker]="picker"
              [max]="maxRequestedDate"
              [min]="minRequestedDate"
            >
              <input
                matStartDate
                formControlName="dtStart"
                placeholder="Select start date"
                (click)="picker.open()"
                readonly
              />
              <input
                matEndDate
                formControlName="dtEnd"
                placeholder="end date"
                (click)="picker.open()"
                readonly
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <!-- <mat-error *ngIf="formGroup.get('dateRange').get('dtStart').hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="formGroup.get('dateRange').get('dtEnd').hasError('matEndDateInvalid')">Invalid end date</mat-error> -->
          </mat-form-field>
          <!-- <div class="alert alert-danger" *ngIf="formGroup.get('dateRange').get('dtStart').invalid || formGroup.get('dateRange').get('dtEnd').invalid">Date range should be between current date and past 3 months from current date</div> -->
        </form>
      </div>
      <div>
        <label>Booking Status</label>
        <mat-form-field appearance="outline">
          <mat-select
            [(value)]="filterVM.BookingStatus"
            #selBookingStatus
            (selectionChange)="onStatusChange(selBookingStatus.value)"
          >
            <mat-option
              *ngFor="let item of bookingStatusList"
              [value]="item.value"
            >
              {{ item.text }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <label>Managerial Country</label>
        <ng-multiselect-dropdown
          [placeholder]="'Select Managerial Country'"
          [data]="masterData.ManageCountryList"
          [settings]="dropdownSettings"
          [(ngModel)]="filterMC"
        >
        </ng-multiselect-dropdown>
      </div>
      <div>
        <label>Service Line</label>
        <ng-multiselect-dropdown
          [placeholder]="'Select Service Line'"
          [data]="masterData.ServiceLineList"
          [settings]="dropdownSettings"
          [(ngModel)]="filterSL"
        >
        </ng-multiselect-dropdown>
      </div>
    </div>
    <div class="customFilters">
      <div>
        <label>Sub-Service Line</label>
        <ng-multiselect-dropdown
          [placeholder]="'Select Sub-Service Line'"
          [data]="masterData.SubServiceLineList"
          [settings]="dropdownSettings"
          [(ngModel)]="filterSSL"
        >
        </ng-multiselect-dropdown>
      </div>
      <div>
        <label>Sub-Management Unit</label>
        <ng-multiselect-dropdown
          [placeholder]="'Select Sub-Management Unit'"
          [data]="masterData.SubManagementUnitList"
          [settings]="dropdownSettings"
          [(ngModel)]="filterSMU"
        >
        </ng-multiselect-dropdown>
      </div>
      <div *ngIf="isMultiInstance">
        <label *ngIf="isMultiInstance">Retain Instance</label>
        <mat-form-field appearance="outline" *ngIf="isMultiInstance">
          <mat-select
            [(value)]="selectedInstance"
            #instanceList
            (selectionChange)="onInstanceChange(instanceList.value)"
          >
            <mat-option
              *ngFor="let item of instances"
              [value]="item.InstanceId"
            >
              {{ item.InstanceName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <button class="setFilter" (click)="onFilter()" style="margin-top: 23px">
          Filter
        </button>
      </div>
      <div></div>
    </div>
  </div>

  <div class="requestedByMe">
    <div class="requestHead heading">Completed Requests</div>
    <div class="clearFilterContainer">
      <div class="filterMobile">
        <mat-form-field appearance="outline">
          <input
            matInput
            type="text"
            placeholder="Search..."
            [formControl]="keywordSearch"
            autocomplete="off"
            maxlength="150"
          />
        </mat-form-field>
      </div>
      &nbsp; &nbsp;
      <mat-icon
        class="filterIcon"
        (click)="openFilterModal(GridFilter)"
        matTooltip="Apply filters"
      >
        filter_alt
      </mat-icon>
      &nbsp; &nbsp;
      <mat-icon
        (click)="clearGridFilters()"
        class="clearFilter"
        matTooltip="Clear filters"
      >
        clear_all</mat-icon
      >
      &nbsp; &nbsp;
      <div
        (click)="exportAsXLSX()"
        matTooltip="Export to excel"
        class="exportExcel"
        [ngClass]="
          bookingList?.length === 0 || gridDataSource?.filteredData.length === 0
            ? 'disablClass'
            : 'enableClass'
        "
      >
        <img
          class="exportExcelIcon"
          src="../../../../assets/images/excel-export-black.jpg"
        />
      </div>
      <!-- <mat-icon class="clearFilter" (click)="exportAsXLSX()" matTooltip="Export to excel"
      [ngClass]="bookingList?.length === 0 || gridDataSource?.filteredData.length === 0 ? 'disablClass' : 'enableClass'">backup</mat-icon> -->
    </div>
    <div class="requestTable">
      <table
        mat-table
        [dataSource]="gridDataSource"
        class="mat-elevation-z8 queuesTable"
        matSort
        id="excel-table"
        #empTbSort="matSort"
      >
        <ng-container matColumnDef="RequestNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Request ID</th>
          <td mat-cell *matCellDef="let row">{{ row.RequestNumber }}</td>
        </ng-container>

        <ng-container matColumnDef="BookingId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Booking ID</th>
          <td mat-cell *matCellDef="let row">{{ row.BookingId }}</td>
        </ng-container>

        <ng-container matColumnDef="Status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let row">{{ row.Status }}</td>
        </ng-container>

        <ng-container matColumnDef="RejectionReason">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Status Information
          </th>
          <td mat-cell *matCellDef="let row">{{ row.RejectionReason }}</td>
        </ng-container>

        <ng-container matColumnDef="ApproverComment">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Approver Comment
          </th>
          <td mat-cell *matCellDef="let row">{{ row.ApproverComment }}</td>
        </ng-container>

        <ng-container matColumnDef="ActionId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Action ID</th>
          <td mat-cell *matCellDef="let row">{{ row.ActionId }}</td>
        </ng-container>

        <ng-container matColumnDef="ActionType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
          <td mat-cell *matCellDef="let row">{{ row.ActionType }}</td>
        </ng-container>

        <ng-container matColumnDef="InstanceName" *ngIf="isMultiInstance">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Retain Instance
          </th>
          <td mat-cell *matCellDef="let row">{{ row.InstanceName }}</td>
        </ng-container>

        <ng-container matColumnDef="Engagement">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Engagement</th>
          <td mat-cell *matCellDef="let row">{{ row.Engagement }}</td>
        </ng-container>

        <ng-container matColumnDef="ResourceName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Resource</th>
          <td mat-cell *matCellDef="let row">{{ row.ResourceName }}</td>
        </ng-container>

        <ng-container matColumnDef="Rank">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Rank</th>
          <td mat-cell *matCellDef="let row">{{ row.Rank }}</td>
        </ng-container>

        <ng-container matColumnDef="ServiceLine">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Service Line
          </th>
          <td mat-cell *matCellDef="let row">{{ row.ServiceLine }}</td>
        </ng-container>

        <ng-container matColumnDef="SubServiceLine">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Sub-Service Line
          </th>
          <td mat-cell *matCellDef="let row">{{ row.SubServiceLine }}</td>
        </ng-container>

        <ng-container matColumnDef="SubManagementUnit">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Sub-Management Unit
          </th>
          <td mat-cell *matCellDef="let row">{{ row.SubManagementUnit }}</td>
        </ng-container>

        <ng-container matColumnDef="Department">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            HR Department
          </th>
          <td mat-cell *matCellDef="let row">{{ row.Department }}</td>
        </ng-container>

        <ng-container matColumnDef="CostCenter">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Cost Center</th>
          <td mat-cell *matCellDef="let row">{{ row.CostCenter }}</td>
        </ng-container>

        <ng-container matColumnDef="OfficeLocation">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Office Location
          </th>
          <td mat-cell *matCellDef="let row">{{ row.OfficeLocation }}</td>
        </ng-container>

        <ng-container matColumnDef="Hours">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Hours</th>
          <td mat-cell *matCellDef="let row">{{ row.Hours }}</td>
        </ng-container>

        <ng-container matColumnDef="PercentLoading">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Loading %</th>
          <td mat-cell *matCellDef="let row">{{ row.PercentLoading }}</td>
        </ng-container>

        <ng-container matColumnDef="UserSelection">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            User Selection
          </th>
          <td mat-cell *matCellDef="let row">{{ row.UserSelection }}</td>
        </ng-container>

        <ng-container matColumnDef="StartDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</th>
          <td mat-cell *matCellDef="let row">{{ row.StartDate }}</td>
        </ng-container>

        <ng-container matColumnDef="EndDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>End Date</th>
          <td mat-cell *matCellDef="let row">{{ row.EndDate }}</td>
        </ng-container>

        <ng-container matColumnDef="Unconfirmed">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Unconfirmed</th>
          <td mat-cell *matCellDef="let row">{{ row.Unconfirmed }}</td>
        </ng-container>

        <ng-container *ngIf="loggedInUser.Ghost" matColumnDef="Ghost">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghost</th>
          <td mat-cell *matCellDef="let row">{{ row.Ghost ? "Yes" : "No" }}</td>
        </ng-container>

        <ng-container matColumnDef="RequestorName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Requestor Name
          </th>
          <td mat-cell *matCellDef="let row">{{ row.RequestorName }}</td>
        </ng-container>

        <ng-container matColumnDef="RequestedTime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Requested Time(GMT)
          </th>
          <td mat-cell *matCellDef="let row">{{ row.RequestedTime }}</td>
        </ng-container>

        <ng-container matColumnDef="CompletedTime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Completed Time(GMT)
          </th>
          <td mat-cell *matCellDef="let row">{{ row.CompletedTime }}</td>
        </ng-container>

        <ng-container matColumnDef="HoursCompletedIn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>SLA(hrs)</th>
          <td mat-cell *matCellDef="let row">{{ row.HoursCompletedIn }}</td>
        </ng-container>

        <ng-container matColumnDef="SubAreaDescription">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Resource Sub-Area
          </th>
          <td mat-cell *matCellDef="let row">{{ row.SubAreaDescription }}</td>
        </ng-container>

        <ng-container matColumnDef="RequestModeDisplay">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Booking Mode
          </th>
          <td mat-cell *matCellDef="let row">{{ row.RequestModeDisplay }}</td>
        </ng-container>

        <ng-container matColumnDef="UpdatedBy">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated By</th>
          <td mat-cell *matCellDef="let row">{{ row.UpdatedBy }}</td>
        </ng-container>

        <ng-container matColumnDef="ApprovedOrRejectedBy">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Approved/Rejected By
          </th>
          <td mat-cell *matCellDef="let row">{{ row.ApprovedOrRejectedBy }}</td>
        </ng-container>

        <ng-container matColumnDef="ApprovedOrRejectedOn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Approved/Rejected On(GMT)
          </th>
          <td mat-cell *matCellDef="let row">{{ row.ApprovedOrRejectedOn }}</td>
        </ng-container>

        <!-- <ng-container matColumnDef="RequestNumberFilter">
          <th mat-header-cell *matHeaderCellDef>
            <div class="filterContainer">
              <input [formControl]="requestIdFilter" autocomplete="off" (keydown)="$event.stopPropagation()" style="width: 50px;">
              <button mat-icon-button [matMenuTriggerFor]="RequestNumber">
                <mat-icon class="filterIcon">filter_alt</mat-icon>
              </button>
              <mat-menu #RequestNumber="matMenu">
                <button mat-menu-item (click)="gridFilterValues.requestIdType = true;triggerFilter()" ><div [ngStyle]="{'color' : gridFilterValues.requestIdType===true ? '#ffe600' : '#fff' }">Contains</div></button>
                <button mat-menu-item (click)="gridFilterValues.requestIdType = false;triggerFilter()"><div [ngStyle]="{'color' : gridFilterValues.requestIdType===false ? '#ffe600' : '#fff' }">Starts with</div></button>
              </mat-menu>
              <mat-icon class="filterIcon" (click)="gridFilterValues.requestIdType = true;requestIdFilter.setValue('')" *ngIf="requestIdFilter.value.length > 0">clear</mat-icon>
            </div>           
           </th>
        </ng-container>

        <ng-container matColumnDef="BookingIdFilter">
          <th mat-header-cell *matHeaderCellDef>
            <div class="filterContainer">
              <input [formControl]="bookingIdFilter" autocomplete="off" (keydown)="$event.stopPropagation()" style="width: 50px;">
              <button mat-icon-button [matMenuTriggerFor]="BookingId">
                <mat-icon class="filterIcon">filter_alt</mat-icon>
              </button>
              <mat-menu #BookingId="matMenu">
                <button mat-menu-item (click)="gridFilterValues.bookingIdType = true;triggerFilter()" ><div [ngStyle]="{'color' : gridFilterValues.bookingIdType===true ? '#ffe600' : '#fff' }">Contains</div></button>
                <button mat-menu-item (click)="gridFilterValues.bookingIdType = false;triggerFilter()"><div [ngStyle]="{'color' : gridFilterValues.bookingIdType===false ? '#ffe600' : '#fff' }">Starts with</div></button>
              </mat-menu>
              <mat-icon class="filterIcon" (click)="gridFilterValues.bookingIdType = true;bookingIdFilter.setValue('')" *ngIf="bookingIdFilter.value.length > 0">clear</mat-icon>
            </div>           
           </th>
        </ng-container>

        <ng-container matColumnDef="StatusFilter">
          <th mat-header-cell *matHeaderCellDef>
            <div class="filterContainer">
              <input [formControl]="statusFilter" autocomplete="off" (keydown)="$event.stopPropagation()" style="width: 50px;">
              <button mat-icon-button [matMenuTriggerFor]="Status">
                <mat-icon class="filterIcon">filter_alt</mat-icon>
              </button>
              <mat-menu #Status="matMenu">
                <button mat-menu-item (click)="gridFilterValues.statusType = true;triggerFilter()" ><div [ngStyle]="{'color' : gridFilterValues.statusType===true ? '#ffe600' : '#fff' }">Contains</div></button>
                <button mat-menu-item (click)="gridFilterValues.statusType = false;triggerFilter()"><div [ngStyle]="{'color' : gridFilterValues.statusType===false ? '#ffe600' : '#fff' }">Starts with</div></button>
              </mat-menu>
              <mat-icon class="filterIcon" (click)="gridFilterValues.statusType = true;statusFilter.setValue('')" *ngIf="statusFilter.value.length > 0">clear</mat-icon>
            </div>           
           </th>
        </ng-container>

        <ng-container matColumnDef="RejectionReasonFilter">
          <th mat-header-cell *matHeaderCellDef></th>
        </ng-container>

        <ng-container matColumnDef="ActionIdFilter">
          <th mat-header-cell *matHeaderCellDef></th>
        </ng-container>

        <ng-container matColumnDef="ActionTypeFilter">
          <th mat-header-cell *matHeaderCellDef>
            <div class="filterContainer">
              <input [formControl]="actionFilter" autocomplete="off" (keydown)="$event.stopPropagation()" style="width: 50px;">
              <button mat-icon-button [matMenuTriggerFor]="ActionType">
                <mat-icon class="filterIcon">filter_alt</mat-icon>
              </button>
              <mat-menu #ActionType="matMenu">
                <button mat-menu-item (click)="gridFilterValues.actionType = true;triggerFilter()" ><div [ngStyle]="{'color' : gridFilterValues.actionType===true ? '#ffe600' : '#fff' }">Contains</div></button>
                <button mat-menu-item (click)="gridFilterValues.actionType = false;triggerFilter()"><div [ngStyle]="{'color' : gridFilterValues.actionType===false ? '#ffe600' : '#fff' }">Starts with</div></button>
              </mat-menu>
              <mat-icon class="filterIcon" (click)="gridFilterValues.actionType = true;actionFilter.setValue('')" *ngIf="actionFilter.value.length > 0">clear</mat-icon>
            </div>           
           </th>
        </ng-container>

        <ng-container matColumnDef="InstanceNameFilter" *ngIf="isMultiInstance">
          <th mat-header-cell *matHeaderCellDef></th>
        </ng-container>

        <ng-container matColumnDef="EngagementFilter">
          <th mat-header-cell *matHeaderCellDef>
            <div class="filterContainer">
              <input [formControl]="engagementFilter" autocomplete="off" (keydown)="$event.stopPropagation()" style="width: 50px;">
              <button mat-icon-button [matMenuTriggerFor]="Engagement">
                <mat-icon class="filterIcon">filter_alt</mat-icon>
              </button>
              <mat-menu #Engagement="matMenu">
                <button mat-menu-item (click)="gridFilterValues.engagementType = true;triggerFilter()" ><div [ngStyle]="{'color' : gridFilterValues.engagementType===true ? '#ffe600' : '#fff' }">Contains</div></button>
                <button mat-menu-item (click)="gridFilterValues.engagementType = false;triggerFilter()"><div [ngStyle]="{'color' : gridFilterValues.engagementType===false ? '#ffe600' : '#fff' }">Starts with</div></button>
              </mat-menu>
              <mat-icon class="filterIcon" (click)="gridFilterValues.engagementType = true;engagementFilter.setValue('')" *ngIf="engagementFilter.value.length > 0">clear</mat-icon>
            </div>           
           </th>
        </ng-container>

        <ng-container matColumnDef="ResourceNameFilter">
          <th mat-header-cell *matHeaderCellDef>
            <div class="filterContainer">
              <input [formControl]="resourceFilter" autocomplete="off" (keydown)="$event.stopPropagation()" style="width: 50px;">
              <button mat-icon-button [matMenuTriggerFor]="ResourceName">
                <mat-icon class="filterIcon">filter_alt</mat-icon>
              </button>
              <mat-menu #ResourceName="matMenu">
                <button mat-menu-item (click)="gridFilterValues.resourceType = true;triggerFilter()" ><div [ngStyle]="{'color' : gridFilterValues.resourceType===true ? '#ffe600' : '#fff' }">Contains</div></button>
                <button mat-menu-item (click)="gridFilterValues.resourceType = false;triggerFilter()"><div [ngStyle]="{'color' : gridFilterValues.resourceType===false ? '#ffe600' : '#fff' }">Starts with</div></button>
              </mat-menu>
              <mat-icon class="filterIcon" (click)="gridFilterValues.resourceType = true;resourceFilter.setValue('')" *ngIf="resourceFilter.value.length > 0">clear</mat-icon>
            </div>           
           </th>
        </ng-container>

        <ng-container matColumnDef="RankFilter">
          <th mat-header-cell *matHeaderCellDef> 
            <div class="filterContainer">
              <input [formControl]="rankFilter" autocomplete="off" (keydown)="$event.stopPropagation()" style="width: 50px;">
              <button mat-icon-button [matMenuTriggerFor]="Rank">
                <mat-icon class="filterIcon">filter_alt</mat-icon>
              </button>
              <mat-menu #Rank="matMenu">
                <button mat-menu-item (click)="gridFilterValues.rankType = true;triggerFilter()" ><div [ngStyle]="{'color' : gridFilterValues.rankType===true ? '#ffe600' : '#fff' }">Contains</div></button>
                <button mat-menu-item (click)="gridFilterValues.rankType = false;triggerFilter()"><div [ngStyle]="{'color' : gridFilterValues.rankType===false ? '#ffe600' : '#fff' }">Starts with</div></button>
              </mat-menu>
              <mat-icon class="filterIcon" (click)="gridFilterValues.rankType = true;rankFilter.setValue('')" *ngIf="rankFilter.value.length > 0">clear</mat-icon>
            </div>           
           </th>
        </ng-container>

        <ng-container matColumnDef="ServiceLineFilter">
          <th mat-header-cell *matHeaderCellDef>
            <div class="filterContainer">
              <input [formControl]="serviceLineFilter" autocomplete="off" (keydown)="$event.stopPropagation()" style="width: 50px;">
              <button mat-icon-button [matMenuTriggerFor]="ServiceLine">
                <mat-icon class="filterIcon">filter_alt</mat-icon>
              </button>
              <mat-menu #ServiceLine="matMenu">
                <button mat-menu-item (click)="gridFilterValues.serviceLineType = true;triggerFilter()" ><div [ngStyle]="{'color' : gridFilterValues.serviceLineType===true ? '#ffe600' : '#fff' }">Contains</div></button>
                <button mat-menu-item (click)="gridFilterValues.serviceLineType = false;triggerFilter()"><div [ngStyle]="{'color' : gridFilterValues.serviceLineType===false ? '#ffe600' : '#fff' }">Starts with</div></button>
              </mat-menu>
              <mat-icon class="filterIcon" (click)="gridFilterValues.serviceLineType = true;serviceLineFilter.setValue('')" *ngIf="serviceLineFilter.value.length > 0">clear</mat-icon>
            </div>           
           </th>
        </ng-container>

        <ng-container matColumnDef="SubServiceLineFilter">
          <th mat-header-cell *matHeaderCellDef> 
            <div class="filterContainer">
              <input [formControl]="subServiceLineFilter" autocomplete="off" (keydown)="$event.stopPropagation()" style="width: 50px;">
              <button mat-icon-button [matMenuTriggerFor]="SubServiceLine">
                <mat-icon class="filterIcon">filter_alt</mat-icon>
              </button>
              <mat-menu #SubServiceLine="matMenu">
                <button mat-menu-item (click)="gridFilterValues.subServiceLineType = true;triggerFilter()" ><div [ngStyle]="{'color' : gridFilterValues.subServiceLineType===true ? '#ffe600' : '#fff' }">Contains</div></button>
                <button mat-menu-item (click)="gridFilterValues.subServiceLineType = false;triggerFilter()"><div [ngStyle]="{'color' : gridFilterValues.subServiceLineType===false ? '#ffe600' : '#fff' }">Starts with</div></button>
              </mat-menu>
              <mat-icon class="filterIcon" (click)="gridFilterValues.subServiceLineType = true;subServiceLineFilter.setValue('')" *ngIf="subServiceLineFilter.value.length > 0">clear</mat-icon>
            </div>           
           </th>
        </ng-container>

        <ng-container matColumnDef="SubManagementUnitFilter">
          <th mat-header-cell *matHeaderCellDef>
            <div class="filterContainer">
              <input [formControl]="subManagementUnitFilter" autocomplete="off" (keydown)="$event.stopPropagation()" style="width: 50px;">
              <button mat-icon-button [matMenuTriggerFor]="SubManagementUnit">
                <mat-icon class="filterIcon">filter_alt</mat-icon>
              </button>
              <mat-menu #SubManagementUnit="matMenu">
                <button mat-menu-item (click)="gridFilterValues.subManagementUnitType = true;triggerFilter()" ><div [ngStyle]="{'color' : gridFilterValues.subManagementUnitType===true ? '#ffe600' : '#fff' }">Contains</div></button>
                <button mat-menu-item (click)="gridFilterValues.subManagementUnitType = false;triggerFilter()"><div [ngStyle]="{'color' : gridFilterValues.subManagementUnitType===false ? '#ffe600' : '#fff' }">Starts with</div></button>
              </mat-menu>
              <mat-icon class="filterIcon" (click)="gridFilterValues.subManagementUnitType = true;subManagementUnitFilter.setValue('')" *ngIf="subManagementUnitFilter.value.length > 0">clear</mat-icon>
            </div>           
           </th>
        </ng-container>

        <ng-container matColumnDef="DepartmentFilter">
          <th mat-header-cell *matHeaderCellDef> 
            <div class="filterContainer">
              <input [formControl]="departmentFilter" autocomplete="off" (keydown)="$event.stopPropagation()" style="width: 50px;">
              <button mat-icon-button [matMenuTriggerFor]="Department">
                <mat-icon class="filterIcon">filter_alt</mat-icon>
              </button>
              <mat-menu #Department="matMenu">
                <button mat-menu-item (click)="gridFilterValues.departmentType = true;triggerFilter()" ><div [ngStyle]="{'color' : gridFilterValues.departmentType===true ? '#ffe600' : '#fff' }">Contains</div></button>
                <button mat-menu-item (click)="gridFilterValues.departmentType = false;triggerFilter()"><div [ngStyle]="{'color' : gridFilterValues.departmentType===false ? '#ffe600' : '#fff' }">Starts with</div></button>
              </mat-menu>
              <mat-icon class="filterIcon" (click)="gridFilterValues.departmentType = true;departmentFilter.setValue('')" *ngIf="departmentFilter.value.length > 0">clear</mat-icon>
            </div>           
           </th>
        </ng-container>

        <ng-container matColumnDef="CostCenterFilter">
          <th mat-header-cell *matHeaderCellDef> 
            <div class="filterContainer">
              <input [formControl]="costCenterFilter" autocomplete="off" (keydown)="$event.stopPropagation()" style="width: 50px;">
              <button mat-icon-button [matMenuTriggerFor]="CostCenter">
                <mat-icon class="filterIcon">filter_alt</mat-icon>
              </button>
              <mat-menu #CostCenter="matMenu">
                <button mat-menu-item (click)="gridFilterValues.costCenterType = true;triggerFilter()" ><div [ngStyle]="{'color' : gridFilterValues.costCenterType===true ? '#ffe600' : '#fff' }">Contains</div></button>
                <button mat-menu-item (click)="gridFilterValues.costCenterType = false;triggerFilter()"><div [ngStyle]="{'color' : gridFilterValues.costCenterType===false ? '#ffe600' : '#fff' }">Starts with</div></button>
              </mat-menu>
              <mat-icon class="filterIcon" (click)="gridFilterValues.costCenterType = true;costCenterFilter.setValue('')" *ngIf="costCenterFilter.value.length > 0">clear</mat-icon>
            </div>           
           </th>
        </ng-container>

        <ng-container matColumnDef="OfficeLocationFilter">
          <th mat-header-cell *matHeaderCellDef>
            <div class="filterContainer">
              <input [formControl]="officeLocationFilter" autocomplete="off" (keydown)="$event.stopPropagation()" style="width: 50px;">
              <button mat-icon-button [matMenuTriggerFor]="OfficeLocation">
                <mat-icon class="filterIcon">filter_alt</mat-icon>
              </button>
              <mat-menu #OfficeLocation="matMenu">
                <button mat-menu-item (click)="gridFilterValues.officeLocationType = true;triggerFilter()" ><div [ngStyle]="{'color' : gridFilterValues.officeLocationType ===true ? '#ffe600' : '#fff' }">Contains</div></button>
                <button mat-menu-item (click)="gridFilterValues.officeLocationType = false;triggerFilter()"><div [ngStyle]="{'color' : gridFilterValues.officeLocationType ===false ? '#ffe600' : '#fff' }">Starts with</div></button>
              </mat-menu>
              <mat-icon class="filterIcon" (click)="gridFilterValues.officeLocationType = true;officeLocationFilter.setValue('')" *ngIf="officeLocationFilter.value.length > 0">clear</mat-icon>
            </div>           
           </th>
        </ng-container>

        <ng-container matColumnDef="HoursFilter">
          <th mat-header-cell *matHeaderCellDef></th>
        </ng-container>

        <ng-container matColumnDef="PercentLoadingFilter">
          <th mat-header-cell *matHeaderCellDef></th>
        </ng-container>

        <ng-container matColumnDef="UserSelectionFilter">
          <th mat-header-cell *matHeaderCellDef></th>
        </ng-container>

        <ng-container matColumnDef="StartDateFilter">
          <th mat-header-cell *matHeaderCellDef></th>
        </ng-container>

        <ng-container matColumnDef="EndDateFilter">
          <th mat-header-cell *matHeaderCellDef></th>
        </ng-container>

        <ng-container matColumnDef="UnconfirmedFilter">
          <th mat-header-cell *matHeaderCellDef></th>
        </ng-container>

        <ng-container *ngIf="loggedInUser.Ghost" matColumnDef="GhostFilter">
          <th mat-header-cell *matHeaderCellDef></th>
        </ng-container>

        <ng-container matColumnDef="RequestorNameFilter">
          <th mat-header-cell *matHeaderCellDef> 
            <div class="filterContainer">
              <input [formControl]="requestorNameFilter" autocomplete="off" (keydown)="$event.stopPropagation()" style="width: 50px;">
              <button mat-icon-button [matMenuTriggerFor]="RequestorName">
                <mat-icon class="filterIcon">filter_alt</mat-icon>
              </button>
              <mat-menu #RequestorName="matMenu">
                <button mat-menu-item (click)="gridFilterValues.requestorNameType = true;triggerFilter()" ><div [ngStyle]="{'color' : gridFilterValues.requestorNameType===true ? '#ffe600' : '#fff' }">Contains</div></button>
                <button mat-menu-item (click)="gridFilterValues.requestorNameType = false;triggerFilter()"><div [ngStyle]="{'color' : gridFilterValues.requestorNameType===false ? '#ffe600' : '#fff' }">Starts with</div></button>
              </mat-menu>
              <mat-icon class="filterIcon" (click)="gridFilterValues.requestorNameType = true;requestorNameFilter.setValue('')" *ngIf="requestorNameFilter.value.length > 0">clear</mat-icon>
            </div>           
           </th>
        </ng-container>

        <ng-container matColumnDef="RequestedTimeFilter">
          <th mat-header-cell *matHeaderCellDef></th>
        </ng-container>

        <ng-container matColumnDef="CompletedTimeFilter">
          <th mat-header-cell *matHeaderCellDef>
            <div class="filterContainer">
              <input [formControl]="completedTimeFilter" autocomplete="off" (keydown)="$event.stopPropagation()" style="width: 50px;">
              <button mat-icon-button [matMenuTriggerFor]="CompletedTime">
                <mat-icon class="filterIcon">filter_alt</mat-icon>
              </button>
              <mat-menu #CompletedTime="matMenu">
                <button mat-menu-item (click)="gridFilterValues.completedTimeType = true;triggerFilter()" ><div [ngStyle]="{'color' : gridFilterValues.completedTimeType===true ? '#ffe600' : '#fff' }">Contains</div></button>
                <button mat-menu-item (click)="gridFilterValues.completedTimeType = false;triggerFilter()"><div [ngStyle]="{'color' : gridFilterValues.completedTimeType===false ? '#ffe600' : '#fff' }">Starts with</div></button>
              </mat-menu>
              <mat-icon class="filterIcon" (click)="gridFilterValues.completedTimeType = true;completedTimeFilter.setValue('')" *ngIf="completedTimeFilter.value.length > 0">clear</mat-icon>
            </div>           
           </th>
        </ng-container>

        <ng-container matColumnDef="HoursCompletedInFilter">
          <th mat-header-cell *matHeaderCellDef> 
            <div class="filterContainer">
              <input [formControl]="SlaHrsFilter" autocomplete="off" (keydown)="$event.stopPropagation()" style="width: 50px;">
              <button mat-icon-button [matMenuTriggerFor]="HoursCompletedIn">
                <mat-icon class="filterIcon">filter_alt</mat-icon>
              </button>
              <mat-menu #HoursCompletedIn="matMenu">
                <button mat-menu-item (click)="gridFilterValues.SlaHrsType = true;triggerFilter()" ><div [ngStyle]="{'color' : gridFilterValues.SlaHrsType===true ? '#ffe600' : '#fff' }">Contains</div></button>
                <button mat-menu-item (click)="gridFilterValues.SlaHrsType = false;triggerFilter()"><div [ngStyle]="{'color' : gridFilterValues.SlaHrsType===false ? '#ffe600' : '#fff' }">Starts with</div></button>
              </mat-menu>
              <mat-icon class="filterIcon" (click)="gridFilterValues.SlaHrsType = true;SlaHrsFilter.setValue('')" *ngIf="SlaHrsFilter.value.length > 0">clear</mat-icon>
            </div>           
           </th>
        </ng-container>

        <ng-container matColumnDef="SubAreaDescriptionFilter">
          <th mat-header-cell *matHeaderCellDef>
            <div class="filterContainer">
              <input [formControl]="resourceSubAreaFilter" autocomplete="off" (keydown)="$event.stopPropagation()" style="width: 50px;">
              <button mat-icon-button [matMenuTriggerFor]="SubAreaDescription">
                <mat-icon class="filterIcon">filter_alt</mat-icon>
              </button>
              <mat-menu #SubAreaDescription="matMenu">
                <button mat-menu-item (click)="gridFilterValues.resourceSubAreaType = true;triggerFilter()" ><div [ngStyle]="{'color' : gridFilterValues.resourceSubAreaType===true ? '#ffe600' : '#fff' }">Contains</div></button>
                <button mat-menu-item (click)="gridFilterValues.resourceSubAreaType = false;triggerFilter()"><div [ngStyle]="{'color' : gridFilterValues.resourceSubAreaType===false ? '#ffe600' : '#fff' }">Starts with</div></button>
              </mat-menu>
              <mat-icon class="filterIcon" (click)="gridFilterValues.resourceSubAreaType = true;resourceSubAreaFilter.setValue('')" *ngIf="resourceSubAreaFilter.value.length > 0">clear</mat-icon>
            </div>           
           </th>
        </ng-container>

        <ng-container matColumnDef="RequestModeDisplayFilter">
          <th mat-header-cell *matHeaderCellDef> 
            <div class="filterContainer">
              <input [formControl]="bookingModeFilter" autocomplete="off" (keydown)="$event.stopPropagation()" style="width: 50px;">
              <button mat-icon-button [matMenuTriggerFor]="RequestModeDisplay">
                <mat-icon class="filterIcon">filter_alt</mat-icon>
              </button>
              <mat-menu #RequestModeDisplay="matMenu">
                <button mat-menu-item (click)="gridFilterValues.bookingModeType = true;triggerFilter()" ><div [ngStyle]="{'color' : gridFilterValues.bookingModeType===true ? '#ffe600' : '#fff' }">Contains</div></button>
                <button mat-menu-item (click)="gridFilterValues.bookingModeType = false;triggerFilter()"><div [ngStyle]="{'color' : gridFilterValues.bookingModeType===false ? '#ffe600' : '#fff' }">Starts with</div></button>
              </mat-menu>
              <mat-icon class="filterIcon" (click)="gridFilterValues.bookingModeType = true;bookingModeFilter.setValue('')" *ngIf="bookingModeFilter.value.length > 0">clear</mat-icon>
            </div>           
           </th>
        </ng-container>

        <ng-container matColumnDef="UpdatedByFilter">
          <th mat-header-cell *matHeaderCellDef> 
            <div class="filterContainer">
              <input [formControl]="updatedByFilter" autocomplete="off" (keydown)="$event.stopPropagation()" style="width: 50px;">
              <button mat-icon-button [matMenuTriggerFor]="UpdatedBy">
                <mat-icon class="filterIcon">filter_alt</mat-icon>
              </button>
              <mat-menu #UpdatedBy="matMenu">
                <button mat-menu-item (click)="gridFilterValues.updatedByType = true;triggerFilter()" ><div [ngStyle]="{'color' : gridFilterValues.updatedByType===true ? '#ffe600' : '#fff' }">Contains</div></button>
                <button mat-menu-item (click)="gridFilterValues.updatedByType = false;triggerFilter()"><div [ngStyle]="{'color' : gridFilterValues.updatedByType===false ? '#ffe600' : '#fff' }">Starts with</div></button>
              </mat-menu>
              <mat-icon class="filterIcon" (click)="gridFilterValues.updatedByType = true;updatedByFilter.setValue('')" *ngIf="updatedByFilter.value.length > 0">clear</mat-icon>
            </div>           
           </th>
        </ng-container>
        <ng-container matColumnDef="ApprovedOrRejectedByFilter">
          <th mat-header-cell *matHeaderCellDef> 
           </th>
        </ng-container>

        <ng-container matColumnDef="ApprovedOrRejectedOnFilter">
          <th mat-header-cell *matHeaderCellDef>
          </th>
        </ng-container> -->

        <tr mat-header-row *matHeaderRowDef="gridColumns; sticky: true"></tr>
        <!-- <tr mat-header-row *matHeaderRowDef="filterGridColumns"></tr> -->
        <tr mat-row *matRowDef="let row; columns: gridColumns"></tr>
      </table>
      <div class="noResults">
        <div *ngIf="gridDataSource === undefined">
          <mat-spinner [diameter]="40"></mat-spinner>
        </div>
        <div
          class="no-booking"
          *ngIf="
            bookingList?.length === 0 ||
            gridDataSource?.filteredData.length === 0
          "
        >
          Bookings not found
        </div>
      </div>
    </div>
    <mat-paginator
      [pageSizeOptions]="[10, 25, 100]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>

<ng-template #templateHelp>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Completed Requests</h4>
    <mat-icon (click)="modalRef.hide()" class="close">close</mat-icon>
  </div>
  <div class="modal-body">
    <!-- <div class="wallChartFooter"> -->
    <p>
      By default, you can see requests that were processed successfully on
      previous day.
    </p>
    <p>
      You can see completed requests of last 3 months by adjusting the dates and
      the status on the appropriate filters.
    </p>
    <!-- </div> -->
  </div>
</ng-template>

<ng-template #GridFilter>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Filter</h4>
    <mat-icon class="close" (click)="closeFilterModal()">close</mat-icon>
  </div>
  <div class="modal-body">
    <div class="popupLayout">
      <div class="fullWidth">
        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input
              [formControl]="requestIdFilter"
              autocomplete="off"
              matInput
              type="text"
              placeholder="Request ID"
              maxlength="150"
            />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="RequestNumber">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #RequestNumber="matMenu">
            <button
              mat-menu-item
              (click)="gridFilterValues.requestIdType = true"
            >
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.requestIdType === true ? '#ffe600' : '#fff'
                }"
              >
                Contains
              </div>
            </button>
            <button
              mat-menu-item
              (click)="gridFilterValues.requestIdType = false"
            >
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.requestIdType === false
                      ? '#ffe600'
                      : '#fff'
                }"
              >
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>
        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input
              [formControl]="bookingIdFilter"
              autocomplete="off"
              matInput
              type="text"
              placeholder="Booking ID"
              maxlength="150"
            />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="BookingId">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #BookingId="matMenu">
            <button
              mat-menu-item
              (click)="gridFilterValues.bookingIdType = true"
            >
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.bookingIdType === true ? '#ffe600' : '#fff'
                }"
              >
                Contains
              </div>
            </button>
            <button
              mat-menu-item
              (click)="gridFilterValues.bookingIdType = false"
            >
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.bookingIdType === false
                      ? '#ffe600'
                      : '#fff'
                }"
              >
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>
        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input
              [formControl]="statusFilter"
              autocomplete="off"
              matInput
              type="text"
              placeholder="Status"
              maxlength="150"
            />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="Status">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #Status="matMenu">
            <button mat-menu-item (click)="gridFilterValues.statusType = true">
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.statusType === true ? '#ffe600' : '#fff'
                }"
              >
                Contains
              </div>
            </button>
            <button mat-menu-item (click)="gridFilterValues.statusType = false">
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.statusType === false ? '#ffe600' : '#fff'
                }"
              >
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>
        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input
              [formControl]="actionFilter"
              autocomplete="off"
              matInput
              type="text"
              placeholder="Action"
              maxlength="150"
            />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="ActionType">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #ActionType="matMenu">
            <button mat-menu-item (click)="gridFilterValues.actionType = true">
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.actionType === true ? '#ffe600' : '#fff'
                }"
              >
                Contains
              </div>
            </button>
            <button mat-menu-item (click)="gridFilterValues.actionType = false">
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.actionType === false ? '#ffe600' : '#fff'
                }"
              >
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>
        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input
              [formControl]="engagementFilter"
              autocomplete="off"
              matInput
              type="text"
              placeholder="Engagement"
              maxlength="150"
            />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="Engagement">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #Engagement="matMenu">
            <button
              mat-menu-item
              (click)="gridFilterValues.engagementType = true"
            >
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.engagementType === true
                      ? '#ffe600'
                      : '#fff'
                }"
              >
                Contains
              </div>
            </button>
            <button
              mat-menu-item
              (click)="gridFilterValues.engagementType = false"
            >
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.engagementType === false
                      ? '#ffe600'
                      : '#fff'
                }"
              >
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>
        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input
              [formControl]="resourceFilter"
              autocomplete="off"
              matInput
              type="text"
              placeholder="Resource"
              maxlength="150"
            />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="ResourceName">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #ResourceName="matMenu">
            <button
              mat-menu-item
              (click)="gridFilterValues.resourceType = true"
            >
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.resourceType === true ? '#ffe600' : '#fff'
                }"
              >
                Contains
              </div>
            </button>
            <button
              mat-menu-item
              (click)="gridFilterValues.resourceType = false"
            >
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.resourceType === false ? '#ffe600' : '#fff'
                }"
              >
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>
        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input
              [formControl]="rankFilter"
              autocomplete="off"
              matInput
              type="text"
              placeholder="Rank"
              maxlength="150"
            />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="Rank">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #Rank="matMenu">
            <button
              mat-menu-item
              (click)="gridFilterValues.rankType = true; triggerFilter()"
            >
              <div
                [ngStyle]="{
                  color: gridFilterValues.rankType === true ? '#ffe600' : '#fff'
                }"
              >
                Contains
              </div>
            </button>
            <button
              mat-menu-item
              (click)="gridFilterValues.rankType = false; triggerFilter()"
            >
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.rankType === false ? '#ffe600' : '#fff'
                }"
              >
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>
        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input
              [formControl]="serviceLineFilter"
              autocomplete="off"
              matInput
              type="text"
              placeholder="Service Line"
              maxlength="150"
            />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="ServiceLine">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #ServiceLine="matMenu">
            <button
              mat-menu-item
              (click)="gridFilterValues.serviceLineType = true"
            >
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.serviceLineType === true
                      ? '#ffe600'
                      : '#fff'
                }"
              >
                Contains
              </div>
            </button>
            <button
              mat-menu-item
              (click)="gridFilterValues.serviceLineType = false"
            >
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.serviceLineType === false
                      ? '#ffe600'
                      : '#fff'
                }"
              >
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>
        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input
              [formControl]="subServiceLineFilter"
              autocomplete="off"
              matInput
              type="text"
              placeholder="Sub-Service Line"
              maxlength="150"
            />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="SubServiceLine">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #SubServiceLine="matMenu">
            <button
              mat-menu-item
              (click)="gridFilterValues.subServiceLineType = true"
            >
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.subServiceLineType === true
                      ? '#ffe600'
                      : '#fff'
                }"
              >
                Contains
              </div>
            </button>
            <button
              mat-menu-item
              (click)="gridFilterValues.subServiceLineType = false"
            >
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.subServiceLineType === false
                      ? '#ffe600'
                      : '#fff'
                }"
              >
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>
        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input
              [formControl]="subManagementUnitFilter"
              autocomplete="off"
              matInput
              type="text"
              placeholder="Sub-Management Unit"
              maxlength="150"
            />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="SubManagementUnit">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #SubManagementUnit="matMenu">
            <button
              mat-menu-item
              (click)="gridFilterValues.subManagementUnitType = true"
            >
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.subManagementUnitType === true
                      ? '#ffe600'
                      : '#fff'
                }"
              >
                Contains
              </div>
            </button>
            <button
              mat-menu-item
              (click)="gridFilterValues.subManagementUnitType = false"
            >
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.subManagementUnitType === false
                      ? '#ffe600'
                      : '#fff'
                }"
              >
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>
        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input
              [formControl]="departmentFilter"
              autocomplete="off"
              matInput
              type="text"
              placeholder="HR Department"
              maxlength="150"
            />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="Department">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #Department="matMenu">
            <button
              mat-menu-item
              (click)="gridFilterValues.departmentType = true"
            >
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.departmentType === true
                      ? '#ffe600'
                      : '#fff'
                }"
              >
                Contains
              </div>
            </button>
            <button
              mat-menu-item
              (click)="gridFilterValues.departmentType = false"
            >
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.departmentType === false
                      ? '#ffe600'
                      : '#fff'
                }"
              >
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>
        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input
              [formControl]="costCenterFilter"
              autocomplete="off"
              matInput
              type="text"
              placeholder="Cost Center"
              maxlength="150"
            />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="CostCenter">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #CostCenter="matMenu">
            <button
              mat-menu-item
              (click)="gridFilterValues.costCenterType = true"
            >
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.costCenterType === true
                      ? '#ffe600'
                      : '#fff'
                }"
              >
                Contains
              </div>
            </button>
            <button
              mat-menu-item
              (click)="gridFilterValues.costCenterType = false"
            >
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.costCenterType === false
                      ? '#ffe600'
                      : '#fff'
                }"
              >
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>
        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input
              [formControl]="officeLocationFilter"
              autocomplete="off"
              matInput
              type="text"
              placeholder="Office Location"
              maxlength="150"
            />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="OfficeLocation">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #OfficeLocation="matMenu">
            <button
              mat-menu-item
              (click)="gridFilterValues.officeLocationType = true"
            >
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.officeLocationType === true
                      ? '#ffe600'
                      : '#fff'
                }"
              >
                Contains
              </div>
            </button>
            <button
              mat-menu-item
              (click)="gridFilterValues.officeLocationType = false"
            >
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.officeLocationType === false
                      ? '#ffe600'
                      : '#fff'
                }"
              >
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>
        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input
              [formControl]="requestorNameFilter"
              autocomplete="off"
              matInput
              type="text"
              placeholder="Requestor Name"
              maxlength="150"
            />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="RequestorName">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #RequestorName="matMenu">
            <button
              mat-menu-item
              (click)="gridFilterValues.requestorNameType = true"
            >
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.requestorNameType === true
                      ? '#ffe600'
                      : '#fff'
                }"
              >
                Contains
              </div>
            </button>
            <button
              mat-menu-item
              (click)="gridFilterValues.requestorNameType = false"
            >
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.requestorNameType === false
                      ? '#ffe600'
                      : '#fff'
                }"
              >
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>
        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input
              [formControl]="completedTimeFilter"
              autocomplete="off"
              matInput
              type="text"
              placeholder="Completed Time(GMT)"
              maxlength="150"
            />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="CompletedTime">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #CompletedTime="matMenu">
            <button
              mat-menu-item
              (click)="gridFilterValues.completedTimeType = true"
            >
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.completedTimeType === true
                      ? '#ffe600'
                      : '#fff'
                }"
              >
                Contains
              </div>
            </button>
            <button
              mat-menu-item
              (click)="gridFilterValues.completedTimeType = false"
            >
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.completedTimeType === false
                      ? '#ffe600'
                      : '#fff'
                }"
              >
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>
        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input
              [formControl]="SlaHrsFilter"
              autocomplete="off"
              matInput
              type="text"
              placeholder="SLA(hrs)"
              maxlength="150"
            />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="HoursCompletedIn">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #HoursCompletedIn="matMenu">
            <button mat-menu-item (click)="gridFilterValues.SlaHrsType = true">
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.SlaHrsType === true ? '#ffe600' : '#fff'
                }"
              >
                Contains
              </div>
            </button>
            <button mat-menu-item (click)="gridFilterValues.SlaHrsType = false">
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.SlaHrsType === false ? '#ffe600' : '#fff'
                }"
              >
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>
        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input
              [formControl]="resourceSubAreaFilter"
              autocomplete="off"
              matInput
              type="text"
              placeholder="Resource Sub-Area"
              maxlength="150"
            />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="SubAreaDescription">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #SubAreaDescription="matMenu">
            <button
              mat-menu-item
              (click)="gridFilterValues.resourceSubAreaType = true"
            >
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.resourceSubAreaType === true
                      ? '#ffe600'
                      : '#fff'
                }"
              >
                Contains
              </div>
            </button>
            <button
              mat-menu-item
              (click)="gridFilterValues.resourceSubAreaType = false"
            >
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.resourceSubAreaType === false
                      ? '#ffe600'
                      : '#fff'
                }"
              >
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>
        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input
              [formControl]="bookingModeFilter"
              autocomplete="off"
              matInput
              type="text"
              placeholder="Booking Mode"
              maxlength="150"
            />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="RequestModeDisplay">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #RequestModeDisplay="matMenu">
            <button
              mat-menu-item
              (click)="gridFilterValues.bookingModeType = true"
            >
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.bookingModeType === true
                      ? '#ffe600'
                      : '#fff'
                }"
              >
                Contains
              </div>
            </button>
            <button
              mat-menu-item
              (click)="gridFilterValues.bookingModeType = false"
            >
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.bookingModeType === false
                      ? '#ffe600'
                      : '#fff'
                }"
              >
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>
        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input
              [formControl]="updatedByFilter"
              autocomplete="off"
              matInput
              type="text"
              placeholder="Updated By"
              maxlength="150"
            />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="UpdatedBy">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #UpdatedBy="matMenu">
            <button
              mat-menu-item
              (click)="gridFilterValues.updatedByType = true"
            >
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.updatedByType === true ? '#ffe600' : '#fff'
                }"
              >
                Contains
              </div>
            </button>
            <button
              mat-menu-item
              (click)="gridFilterValues.updatedByType = false"
            >
              <div
                [ngStyle]="{
                  color:
                    gridFilterValues.updatedByType === false
                      ? '#ffe600'
                      : '#fff'
                }"
              >
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>
      </div>
      <div class="confirmationButtons">
        <button class="cancelBtn" (click)="closeFilterModal()">Cancel</button>
        <button class="deleteBtn" (click)="filterGrid()">Filter</button>
      </div>
    </div>
  </div>
</ng-template>
