import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { Subscription } from 'rxjs';
import { IndividualBookingVM } from '../../../Models/IndividualBookingVM';
import { IndividualService } from '../../individual/individual.service';
import { EngagementSubmissionVM } from '../../../Models/EngagementSubmissionVM';
import { ResourceDto } from '../../../Models/ResourceDto';
import { CurrentWeekActionDTO } from '../../../Models/CurrentWeekActionDTO';
import { DatePipe } from '@angular/common';
import { FormGroup, FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { BookingGridActionService } from '../../../Models/BookingGridActionService';
import { OrderPipe } from 'ngx-order-pipe';
import { SharedServicesService } from '../../../shared/sharedservices/shared-services.service';
import { PreviewCountVM } from '../../../Models/PreviewCountVM';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import  moment from 'moment';
import { ErrorStateMatcher } from '@angular/material/core';
import { DARK_PATH, LIGHT_PATH } from 'app/app-constants';
import { ThemeService } from 'app/shared/theme/theme.service';

@Component({
  selector: 'app-individual-booking',
  templateUrl: './individual-booking.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./individual-booking.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class IndividualBookingComponent implements OnInit, OnDestroy {
  confirmModalRef: BsModalRef;
  modalRef: BsModalRef;
  bookingList: IndividualBookingVM[];
  landingBookingList: IndividualBookingVM[];
  isCollapsed: boolean = false;
  searchLabel: string = 'Individual Forecast';
  sortedResourceList: ResourceDto[];
  booking: IndividualBookingVM;
  bookingListSubscription: Subscription;
  resourceListSubscription: Subscription;
  previewCountSubscription: Subscription;
  engagementAllocationModal = '';
  range = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null),
  });
  isPercentageSelected: boolean = false;
  resourceId: number;
  startDate: any;
  endDate: any;
  searchBar: string;
  resourceName: string;
  gridSelectedUser: string;
  gridActionEventData: BookingGridActionService;
  showModal = false;
  showModalAdd = false;
  showModelSwap = false;
  showDeleteParent = false;
  showModalSplit = false;
  showModalDelete = false;
  showModalCancel = false;
  showPreviewModal = false;
  showModalParentEdit = false;
  gridActionModalHeader = '';
  modalOption: ModalOptions = {};
  engagementSubmission: EngagementSubmissionVM;
  currentUserEmail: any;
  currentUserSubscription: Subscription;
  resource: ResourceDto;
  resourceSelected: boolean;
  resourceRank: any = '';
  resourceLocation: any = '';
  SLDescription: any = '';
  departmentDescription: string;
  resourceDetails: any;
  selectedInstance = 1;
  instances;
  user: any = {};
  results;
  displayedColumns: string[] = [
    'child',
    'BookingType',
    'Engagement',
    'Instance',
    'Hours',
    'Loading',
    'StartDate',
    'EndDate',
    'Actions',
  ];
  showSearchList: boolean = false;
  showSearchLoader: boolean = false;
  public isDirtyFlag = false;
  previewCountVM: PreviewCountVM;
  count: number = 0;
  selectedResource: ResourceDto = new ResourceDto();
  landingResource: ResourceDto;
  isWallChart: boolean = false;
  selectedRow: any;
  indFromEngagement: any;
  instanceFromEng = 0;
  resourceIdFromEng: number;
  defaultDateFormat: string;
  
  editDelete: number;
  userProfilePhoto: any;
  isMobileDevice: boolean = false;
  isNavFromApprovalQueue: boolean = false;
  availabilityReportLink: string;
  bookedTimeReportLink: string;
  today = new Date();
  todaysDate = new Date(
    this.today.getFullYear(),
    this.today.getMonth(),
    this.today.getDate(),
    0,
    0,
    0
  );
  startWith = this.sharedService.getFirstDateOfWeek(this.todaysDate);
  currentWeekStartDate = this.sharedService.getFirstDateOfWeek(this.todaysDate);
  currentWeek: CurrentWeekActionDTO;
  isPeriodErrorMsg: boolean = false;
  bookedReport: any;
  availabilityReport: any;
  cspReports: [];
  imagePath: string = '';

  constructor(
    public restService: IndividualService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private themeService: ThemeService,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    private orderPipe: OrderPipe,
    public sharedService: SharedServicesService
  ) {
    this.sharedService.activeLink = 'IndividualBooking';
    this.engagementAllocationModal =
      this.isPercentageSelected === true ? 'percentage' : 'hour';
    this.indFromEngagement =
      this.router.getCurrentNavigation().extras.state?.individual;
    this.instanceFromEng =
      this.router.getCurrentNavigation().extras.state?.instanceId;
    this.resourceIdFromEng =
      this.router.getCurrentNavigation().extras.state?.resourceId;
    this.selectedInstance = this.instanceFromEng > 0 ? this.instanceFromEng : 1;
    this.isNavFromApprovalQueue =
      this.router.getCurrentNavigation().extras.state?.isNavFromApprovalQueue;
  }

  ngOnInit() {
    this.spinner.show();
    // this.getCurrentWeek();
    this.editDelete = 0;
    const activeTheme = this.themeService.getActiveTheme();
    this.imagePath = activeTheme.name === 'light' ? LIGHT_PATH : DARK_PATH;
    this.sharedService.activeLink = 'IndividualBooking';
    this.isMobileDevice =
      JSON.parse(sessionStorage.getItem('requestMode')) === 1 ? true : false;
    this.user = JSON.parse(sessionStorage.getItem('resource'));
    this.sharedService.getDefaultPeriodSettings(this.user);
    this.range.setValue({
      start:  this.sharedService.defaultStartDate ? moment(this.sharedService.defaultStartDate) : null,
      end: this.sharedService.defaultEndDate? moment(this.sharedService.defaultEndDate) : null,
    });
    this.startDate = this.sharedService.defaultStartDate;
    this.endDate = this.sharedService.defaultEndDate;
    // this.currentWeekStartDate.setMinutes(
    //   this.currentWeekStartDate.getMinutes() -
    //     this.currentWeekStartDate.getTimezoneOffset()
    // );
    this.currentWeekStartDate = new Date(
      this.currentWeekStartDate.getFullYear(),
      this.currentWeekStartDate.getMonth(),
      this.currentWeekStartDate.getDate(),
      0,
      0,
      0
    );
    this.startWith.setMinutes(this.startWith.getMinutes() - this.startWith.getTimezoneOffset());
    this.defaultDateFormat = this.sharedService.getDateFormat(
      this.user.DefaultDateFormat
    );
    this.currentUserEmail = this.user.Email;
    
    // if(this.user.Ghost){
    //   this.displayedColumns.splice(this.displayedColumns.indexOf('EndDate')+1, 0, 'Ghost');
    // }
    this.resourceSelected = false;
    this.resource = new ResourceDto();
    this.booking = new IndividualBookingVM();
    // this.applicationInsightsService.AppInsightsQueuePush(this.currentUserEmail, sessionStorage.getItem("RoleTypeName").toString(), null);
    // this.applicationInsightsService.logUserId(this.currentUserEmail);
    this.updateLoadingPreference();

    if (this.indFromEngagement?.length > 0) {
      this.sharedService.getUserInstances().subscribe((data) => {
        this.instances = this.sharedService.getStoredUserInstances();
      });
      this.searchBar = this.indFromEngagement;
      this.selectedInstance = this.instanceFromEng;
      this.resource.ResourceName = this.indFromEngagement;
      this.resource.ResourceId = this.resourceIdFromEng;
      this.resource.InstanceId = this.selectedInstance;
      this.resource.LoggedInResourceID = +sessionStorage.getItem(
        'loggedin_resourceid'
      );
      // this.applicationInsightsService.logEvent('Individual View - getResourceList');
      this.resourceListSubscription = this.restService
        .getResourceList(this.resource)
        .subscribe((data) => {
          this.sortedResourceList = this.orderPipe.transform(
            data,
            'ResourceName'
          );
          if (this.sortedResourceList.length === 1) {
            this.OnItemSelect(this.sortedResourceList[0]);
          }
        });
    } else {
      this.getUserInstances();
    }

    this.bookingList = [];
    this.engagementSubmission = new EngagementSubmissionVM();
    this.gridActionEventData = new BookingGridActionService();
    this.restService.currentMessageCloseModal.subscribe((closeModal) => {
      this.closeActionModal(closeModal);
    });
    this.restService.setRequestIdEvent.subscribe((requestId) => {
      this.engagementSubmission.RequestId = requestId;
      if (
        this.engagementSubmission.RequestId === 0 ||
        this.engagementSubmission.RequestId === undefined
      ) {
        this.gridActionModalHeader = 'Preview Actions';
      } else {
        this.gridActionModalHeader =
          'Preview Actions. Request ID - ' +
          this.engagementSubmission.RequestId;
      }
      // this.gridActionModalHeader = 'Preview Actions. Request ID - ' + this.engagementSubmission.RequestId;
    });
    this.results = [];
    this.cspReports = this.user.RoleFeatures.filter(
      (x) =>
        x.FeatureName == 'AvailabilityReportCSP' ||
        x.FeatureName == 'BookedTimeReportCSP'
    );
    // const dayNeeded = 7 //For Saturday
    // const currentDay = moment().isoWeekday();
    // if (currentDay === dayNeeded) {
    //     let day = moment().day(dayNeeded);
    //     this.startWith = new Date(day.year(),day.month(),day.date())
    //   }else{
    //     let day = moment().subtract(1, 'weeks').isoWeekday(dayNeeded);
    //     this.startWith = new Date(day.year(),day.month(),day.date());
    //   }
    // this.restService.defaultLoadingChange.subscribe((data) => {
    //   this.updateLoadingPreference();
    // });
    //this.sharedService.setHeaderDetails(this.user);
    // this.sharedService.setFooterDetails(this.resource);
  }

  getUserInstances() {
    const userInstance = this.sharedService.getStoredUserInstances();

    if (userInstance === null || userInstance === undefined) {
      this.spinner.show();
      this.sharedService.getUserInstances().subscribe((data) => {
        this.instances = this.sharedService.getStoredUserInstances();
        const selected = this.instances.filter(
          (x) => x.InstanceId === this.resourceDetails.DefaultInstance
        );
        if (
          selected.length > 0 &&
          (this.instanceFromEng === 0 || this.instanceFromEng === undefined)
        ) {
          this.selectedInstance = selected[0].InstanceId;
          this.checkCurrentUserInResourceList();
        }
      });
    } else {
      this.instances = userInstance;
      const selected = this.instances.filter(
        (x) => x.InstanceId === this.resourceDetails.DefaultInstance
      );
      if (
        (selected.length > 0 && this.instanceFromEng === 0) ||
        this.instanceFromEng === undefined
      ) {
        this.selectedInstance = selected[0].InstanceId;
        this.checkCurrentUserInResourceList();
      }
    }
  }

  onInstanceChange(event) {
    this.selectedInstance = event.value;
    this.sharedService
      .getUserResourceInstances(this.selectedResource)
      .subscribe((data) => {
        const instanceOfUser = data;
        const selected = instanceOfUser.filter(
          (x) => x.InstanceId === this.selectedInstance
        );
        if (selected.length !== 0) {
          this.selectedResource.ResourceId = selected[0].ResourceId;
          this.isWallChart = false;
          this.getBookingList();
        } else {
          this.resetfilterBookingGridSearchBar();
        }
      });
  }

  ngOnDestroy() {
    if (this.bookingListSubscription !== undefined) {
      this.bookingListSubscription.unsubscribe();
    }
    if (this.resourceListSubscription !== undefined) {
      this.resourceListSubscription.unsubscribe();
    }
  }

  openModal(template, event) {
    event.stopPropagation();
    event.preventDefault();
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.modalRef = this.modalService.show(template, this.modalOption);
  }

  makeDirty(data) {
    this.isDirtyFlag = data;
  }

  openConfirmationModal(template) {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    if (!this.isDirtyFlag || this.showModalDelete || this.showPreviewModal) {
      if (this.modalRef !== undefined && this.modalRef !== null) {
        this.modalRef.hide();
      }
    } else {
      this.confirmModalRef = this.modalService.show(template, this.modalOption);
      this.isDirtyFlag = false;
    }
  }

  openActionModal(template, event, dataItem, templateName) {
    this.showModal = false;
    this.showModalAdd = false;
    this.showModalSplit = false;
    this.showModalDelete = false;
    this.showModalCancel = false;
    this.showPreviewModal = false;
    this.showModelSwap = false;
    this.showDeleteParent = false;
    this.showModalParentEdit = false;
    this.gridActionEventData.dataItem = dataItem;
    this.gridActionEventData.event = event;
    this.gridActionEventData.template = template;
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    // Use the parent-edit component for single edit also
    if (templateName === 'edit') {
      this.gridActionModalHeader = 'Edit Booking';
      // this.showModal = true;
      // this.restService.openEditModal(this.gridActionEventData);
      this.showModalParentEdit = true;
    } else if (templateName === 'swap') {
      this.gridActionModalHeader = 'Replace Booking';
      this.showModelSwap = true;
      this.gridActionEventData.dataItem.resourceName = this.gridSelectedUser;
      this.gridActionEventData.dataItem.RankDescription = this.resourceRank;
      this.gridActionEventData.dataItem.resourceLocation =
        this.resourceLocation;
      this.gridActionEventData.dataItem.SLDescription = this.SLDescription;
      this.gridActionEventData.dataItem.DepartmentDescription =
        this.departmentDescription;
      this.restService.openSwapModel(this.gridActionEventData);
    } else if (templateName === 'deleteparent') {
      this.gridActionModalHeader = 'Delete Bookings';
      this.showDeleteParent = true;
    } else if (templateName === 'parentEdit') {
      this.gridActionModalHeader = 'Edit Bookings';
      this.showModalParentEdit = true;
    } else if (templateName === 'add') {
      this.gridActionModalHeader = 'New Booking';
      this.showModalAdd = true;
      this.restService.openAddModal(this.gridActionEventData);
    } else if (templateName === 'split') {
      this.gridActionModalHeader = 'Copy Booking';
      this.showModalSplit = true;
      this.restService.openSplitModal(this.gridActionEventData);
    } else if (templateName === 'delete') {
      this.gridActionModalHeader = 'Delete Booking';
      this.showModalDelete = true;
      this.restService.openDeleteModal(this.gridActionEventData);
    }
    else if (templateName === 'cancelAction') {
      this.gridActionModalHeader = 'Cancel Action Request';
      this.showModalCancel = true;
    }    
    else if (templateName === 'preview') {
      this.showPreviewModal = true;
      if (
        this.engagementSubmission.RequestId === 0 ||
        this.engagementSubmission.RequestId === undefined
      ) {
        this.gridActionModalHeader = 'Preview Actions';
      } else {
        this.gridActionModalHeader =
          'Preview Actions. Request ID - ' +
          this.engagementSubmission.RequestId;
      }
      //this.engagementSubmission.ResourceId = +sessionStorage.getItem('resourceId');
      this.engagementSubmission.ResourceId = this.selectedResource.ResourceId;
      this.restService.openPreviewModal(this.engagementSubmission);
    }
    this.modalRef = this.modalService.show(template, this.modalOption);
    if (templateName !== 'cancelAction') {
      this.modalRef.setClass('ea-modal');
    }
  }

  closeActionModal(closeModal) {
    // if (this.modalRef !== undefined && this.modalRef !== null) {
    //   this.modalRef.hide();
    // }
    // if (closeModal) {
    //   this.getBookingList();
    // }
    if (closeModal) {
      if (this.modalRef !== undefined && this.modalRef !== null) {
        this.modalRef.hide();
        this.getBookingList();
      }
    } else {
      if (this.modalRef !== undefined && this.modalRef !== null) {
        this.modalRef.hide();
      }
    }
  }

  okayConfirm(template) {
    this.confirmModalRef.hide();
    if (this.modalRef !== undefined && this.modalRef !== null) {
      this.modalRef.hide();
    }
  }

  setDefaultData() {
    this.isPercentageSelected = this.resourceDetails.PercentSelected;
    this.engagementAllocationModal =
      this.resourceDetails.PercentSelected === true ? 'percentage' : 'hour';
    this.searchBar = null;
    this.range.setValue({
      start: null,
      end: null,
    });
    this.sortedResourceList = [];
    this.booking = new IndividualBookingVM();
    this.engagementSubmission = new EngagementSubmissionVM();
    this.restService.engagementAllocationOnChange(this.isPercentageSelected); //WORK ITEM 460
    this.loadInitialData();
  }

  loadInitialData() {
    this.resourceSelected = false;
    if (this.gridSelectedUser !== null && this.gridSelectedUser !== undefined) {
      this.resourceSelected = true;
    }
  }

  updateLoadingPreference() {
    this.resourceDetails = JSON.parse(sessionStorage.getItem('resource'));
    this.setDefaultData();
    this.maintainHiddenValues();
    //this.getUserInstances();
    //this.filterBookingGrid();
  }

  engagementAllocationOnChange(event) {
    this.isPercentageSelected =
      this.engagementAllocationModal === 'hour' ? false : true;
    this.maintainHiddenValues();
    this.getBookingList();
  }

  maintainHiddenValues() {
    this.restService.engagementAllocationOnChange(this.isPercentageSelected);    
    const indexOfInstance = this.displayedColumns.indexOf('Instance', 0);
    if (this.isPercentageSelected) {
      const indexOfPercentage = this.displayedColumns.indexOf('Loading', 0);
      if (indexOfPercentage === -1) {
        this.displayedColumns.splice(
          this.displayedColumns.indexOf('Engagement') + (indexOfInstance > -1 ? 2 : 1), 
          0, 'Loading'
        );
      }
      const indexOfHour = this.displayedColumns.indexOf('Hours', 0);
      if (indexOfHour > -1) {
        this.displayedColumns.splice(indexOfHour, 1);
      }
    } else {
      const indexOfHour = this.displayedColumns.indexOf('Hours', 0);
      if (indexOfHour === -1) {
        this.displayedColumns.splice(
          this.displayedColumns.indexOf('Engagement') + (indexOfInstance > -1 ? 2 : 1), 
          0, 'Hours'
        );
      }
      const indexOfPercentage = this.displayedColumns.indexOf('Loading', 0);
      if (indexOfPercentage > -1) {
        this.displayedColumns.splice(indexOfPercentage, 1);
      }
    }
  }

  onBookingStartDateChange(event: any) {
    this.startDate = this.range.get('start').value;
    //  this.range.controls.end.setValue('');
    this.endDate = null;
  }

  onBookingEndDateChange(event: any) {
    this.endDate = this.range.get('end').value;
    if (event.value != null) {
      // sessionStorage.setItem('PeriodFilterStDate', this.startDate);
      // sessionStorage.setItem('PeriodFilterEndDate', this.endDate);
      if (this.endDate != null) {
        // this.sharedService.getDefaultPeriodSettings(this.user);
        this.startDate = new Date(this.startDate);
        this.endDate = new Date(this.endDate);
        this.getBookingList();
      }
    }
  }
  OnClosedDatePicker() {
    if (this.startDate != null && this.endDate == null) {
      this.isPeriodErrorMsg = true;
      setTimeout(() => {
        this.isPeriodErrorMsg = false;
        this.startDate = null;
        this.endDate = null;
        this.range.setValue({
          start: null,
          end: null,
        });
      }, 3000);
    }
  }
  // filterBookingGrid() {
  //   if (this.resourceId > 0 && this.startDate === null && this.endDate === null) {
  //     this.booking.ResourceId = this.resourceId;
  //     this.booking.StartDate = null;
  //     this.booking.EndDate = null;
  //     this.getBookingList();
  //   } else if (this.resourceId === 0 && this.startDate !== null && this.endDate !== null) {
  //     this.booking.ResourceId = null;
  //     this.booking.StartDate = this.startDate;
  //     this.booking.EndDate = this.endDate;
  //     this.getBookingList();
  //   } else if (this.resourceId > 0 && this.startDate !== null && this.endDate !== null) {
  //     this.booking.ResourceId = this.resourceId;
  //     this.booking.StartDate = this.startDate;
  //     this.booking.EndDate = this.endDate;
  //     this.getBookingList();
  //   } else {
  //     this.booking.ResourceId = null;
  //     this.booking.StartDate = null;
  //     this.booking.EndDate = null;
  //     this.getBookingList();
  //   }
  // }

  resetfilterBookingGridCalender(event) {
    event.stopPropagation();
    event.preventDefault();
    this.range.setValue({
      start: null,
      end: null,
    });
    this.startDate = null;
    this.endDate = null;
    // sessionStorage.setItem('PeriodFilterStDate', this.startDate);
    // sessionStorage.setItem('PeriodFilterEndDate', this.endDate);
    // this.sharedService.getDefaultPeriodSettings(this.user);
    this.getBookingList();
    if (this.gridSelectedUser == null) {
      this.resourceSelected = false;
    }
  }

  resetfilterBookingGridSearchBar() {
    this.isWallChart = false;
    this.showSearchList = false;
    this.onResourceClear();
    this.results = [];
    this.searchBar = '';
    //this.filterBookingGrid();
    this.checkCurrentUserInResourceList();

    //this.restService.setSelectedResource(this.resourceId);
    if (this.gridSelectedUser == null) {
      this.resourceSelected = false;
    }
  }

  onResourceClear() {
    this.searchBar = '';
    this.results = [];
  }

  checkCurrentUserInResourceList() {
    // this.applicationInsightsService.logEvent('Individual View - checkCurrentUserInResourceList');
    //this.spinner.show();
    this.range.setValue({
      start:  this.sharedService.defaultStartDate ? moment(this.sharedService.defaultStartDate) : null,
      end: this.sharedService.defaultEndDate? moment(this.sharedService.defaultEndDate) : null,
    });
    this.startDate = this.sharedService.defaultStartDate;
    this.endDate = this.sharedService.defaultEndDate;
    this.resource = new ResourceDto();
    this.selectedInstance = this.user.DefaultInstance;
    this.resource.InstanceId = this.user.DefaultInstance;
    this.resource.ResourceEmail = this.user.Email;
    this.resource.ResourceName = this.user.ResourceName;
    this.resource.ResourceId = this.user.DefaultInstanceResourceId;
    this.currentUserSubscription = this.restService
      .fetchResourceDetail(this.resource)
      .subscribe((data) => {
        this.resource = data;
        this.landingResource = this.selectedResource = this.resource;
        this.updateCurrentWeekFlags(data);
        if (
          this.resource.ResourceId !== null &&
          this.resource.ResourceId !== undefined
        ) {
          this.resourceId = this.resource.ResourceId;
          if (
            this.resource.ResourceName !== null &&
            this.resource.ResourceName !== undefined
          ) {
            this.resourceName = this.resource.ResourceName.toString();
            this.gridSelectedUser = this.resource.ResourceName.toString();
            if (
              this.gridSelectedUser !== null &&
              this.gridSelectedUser !== undefined
            ) {
              this.resourceSelected = true;
            }
          }
          sessionStorage.setItem(
            'CurrentResource',
            JSON.stringify(this.resource)
          );
          this.getBookingList();
          //sessionStorage.setItem('resourceId', this.resource.ResourceId.toString());
          //this.restService.setSelectedResource(this.resourceId);
        }
      });
  }

  // getCurrentWeek() {
  //   this.spinner.show();
  //   this.restService.getCurrentWeek().subscribe(
  //     (data: any) => {
  //       this.editDelete = data.DeleteWeek;

  //     });
  //   this.spinner.hide();
  // }

  manageInstanceColumn() {
    const indexOfInstance = this.displayedColumns.indexOf('Instance', 0);
    if (this.selectedResource.SearchedResMultiInstance){
      if(indexOfInstance === -1)
        this.displayedColumns.splice(this.displayedColumns.indexOf('Engagement') + 1, 0, 'Instance');
    }
    else {
      if(indexOfInstance > -1)
        this.displayedColumns.splice(indexOfInstance, 1);
    }
  }

  getBookingList() {
    this.spinner.show();
    if (
      this.selectedResource.ResourceId === undefined ||
      this.selectedResource.ResourceId === null ||
      !this.selectedResource.ResourceId ||
      this.selectedResource.ResourceId === 0
    ) {
      this.spinner.hide();
      return;
    }    
    this.manageInstanceColumn();
    this.booking.ResourceId = this.selectedResource.ResourceId;
    this.booking.InstanceId = this.selectedInstance;
    this.booking.StartDate = this.startDate;
    this.booking.EndDate = this.endDate;
    this.booking.StartDate?.setMinutes(this.booking.StartDate?.getMinutes() - this.booking.StartDate?.getTimezoneOffset());
    this.booking.EndDate?.setMinutes(this.booking.EndDate?.getMinutes() - this.booking.EndDate?.getTimezoneOffset());
    //this.booking.LoggedInResourceID = + sessionStorage.getItem('loggedin_resourceid');
    this.booking.IsPercentSelected = this.isPercentageSelected;
    this.bookingListSubscription = this.restService
      .getBookingListForGrid(this.booking)
      .subscribe(
        (data) => {
          if (data) {
            this.bookingList = data;
            this.bookingList.forEach((element) => {
              if (element.ChildRowsExist) {
                element.isExpandable = false;
              }
            });

            this.getPreviewCountList();
            this.restService.refreshWallChart(this.resourceId);
            if (this.landingResource?.ResourceId === this.resourceId) {
              this.landingBookingList = this.bookingList;
            } else {
              // this.applicationInsightsService.logEvent('Individual View - getBookingList - ERROR: ' + data.status);
            }
            this.spinner.hide();
          }
        },
        (err) => {
          this.bookingList = []; // empty the grid data in case of error as it shows previous user's data.
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  search() {
    this.isWallChart = false;
    if (this.searchBar != null) {
      if (this.searchBar.trim().length >= 2) {
        this.showSearchList = true;
        this.showSearchLoader = true;
        this.resource.ResourceName = this.searchBar;
        this.resource.ResourceId = 0; //  +sessionStorage.getItem('resourceId');
        this.resource.InstanceId = this.selectedInstance;
        this.resource.LoggedInResourceID = +sessionStorage.getItem(
          'loggedin_resourceid'
        );
        // this.applicationInsightsService.logEvent('Individual View - getResourceList');
        this.resourceListSubscription = this.restService
          .getResourceList(this.resource)
          .subscribe((data) => {
            this.sortedResourceList = this.orderPipe.transform(
              data,
              'ResourceName'
            );
            if (this.searchBar.length > 0) {
              this.results = this.sortedResourceList;
              this.showSearchLoader = false;
            } else {
              this.results = [];
              this.showSearchList = false;
            }
          });
      } else {
        this.results = [];
        this.showSearchList = false;
      }
    } else {
      this.results = [];
      this.showSearchList = false;
    }
  }

  OnItemSelect(event) {
    this.showSearchList = false;
    this.resourceId = event.ResourceId;
    this.gridSelectedUser = event.ResourceName;
    this.selectedResource = event;
    this.resourceRank = event.RankDescription;
    this.resourceLocation = event.LocationDescription;
    this.SLDescription = event.SLDescription;
    this.departmentDescription = event.DepartmentDescription;
    this.searchBar = event.ResourceName;
    /*console.log(' This is event param', event.IsDisabledAddBooking_CurrWeek_R);*/
    this.updateCurrentWeekFlags(event);
    this.editDelete =
      this.currentWeek &&
      this.currentWeek.IsDisabledDeleteBookingCurrWeek == true
        ? 1
        : 0;
    this.range.setValue({
      start:  this.sharedService.defaultStartDate ? moment(this.sharedService.defaultStartDate) : null,
      end: this.sharedService.defaultEndDate? moment(this.sharedService.defaultEndDate) : null,
    });
    this.startDate = this.sharedService.defaultStartDate;
    this.endDate = this.sharedService.defaultEndDate;
    this.getBookingList();
    //this.restService.setSelectedResource(this.resourceId);
    this.resourceSelected = !event.IsDisabledAddBooking;
    if (this.selectedResource.ResourceEmail == this.user.Email) {
      this.restService
        .getProfilePhoto(this.selectedResource.ResourceEmail)
        .subscribe((photoUrl) => {
          this.selectedResource.ResourcePhotoUrl = photoUrl;
        });
    }
  }

  updateCurrentWeekFlags(event) {
    this.currentWeek = {
      IsDisabledAddBookingCurrWeek: event.IsDisabledAddBookingCurrWeek,
      IsDisabledEditBookingCurrWeek: event.IsDisabledEditBookingCurrWeek,
      IsDisabledDeleteBookingCurrWeek: event.IsDisabledDeleteBookingCurrWeek,
      IsDisabledCopyBookingCurrWeek: event.IsDisabledCopyBookingCurrWeek,
      IsDisabledReplaceBookingCurrWeek: event.IsDisabledReplaceBookingCurrWeek,
      IsDisabledReplaceAllBookingCurrWeek:
        event.IsDisabledReplaceAllBookingCurrWeek,
    };
    console.log('CW', this.currentWeek);
  }

  getPreviewCountList() {
    if (this.user.PreviewSubmit) {
      this.previewCountSubscription = this.restService
        .getPreviewCountList()
        .subscribe((data: number) => {
          this.count = data;
        });
    }
  }
  selectRow(row) {
    this.selectedRow = row;
  }
  previousTooltip: any;
  closeTooltip(currentPop) {
    if (this.previousTooltip && this.previousTooltip != currentPop) {
      this.previousTooltip.hide();
    }
    this.previousTooltip = currentPop;
  }

  navigateEngagement(item) {
    if (!item.IsDisabledEVNavigation && !item.IsDisabledViewBooking_Job) {
      const product = {
        engagement:
          item.EngagementCode === null ? item.Engagement : item.EngagementCode,
        instanceId: item.InstanceId,
        resourcId: item.ResourceId,
        jobId: item.JobId,
      };
      this.router.navigateByUrl('/EngagementBooking', { state: product });
    }
  }
  selectedItem: any;
  selectItem(item) {
    this.selectedItem = item.TooltipData;
  }
  previousPopover: any;
  closePrevious(currentPop) {
    if (this.previousPopover && this.previousPopover != currentPop) {
      this.previousPopover.hide();
    }
    this.previousPopover = currentPop;
  }
  closeCurrent(pop) {
    pop.hide();
  }

  navigateToCspReport(reportName: string) {
    if (reportName == 'AvailabilityReportCSP') {
      this.availabilityReport = this.user.RoleFeatures.filter(
        (x) => x.FeatureName == 'AvailabilityReportCSP'
      );
      const from = this.datePipe.transform(this.startDate, 'yyyy-MM-dd');
      const to = this.datePipe.transform(this.endDate, 'yyyy-MM-dd');
      //let reportLink = availabilityReport[0].ReportLink;
      let reportLink = this.availabilityReport[0].FeaturePath;
      reportLink = reportLink.replaceAll(
        '[[RESOURCE_NAME]]',
        this.gridSelectedUser
      );
      reportLink = reportLink.replaceAll('[[FROM_DATE]]', from);
      reportLink = reportLink.replaceAll('[[TO_DATE]]', to);
      this.availabilityReportLink = reportLink;
      window.open(this.availabilityReportLink, '_blank');
    } else if (reportName == 'BookedTimeReportCSP') {
      this.bookedReport = this.user.RoleFeatures.filter(
        (x) => x.FeatureName == 'BookedTimeReportCSP'
      );
      const from = this.datePipe.transform(this.startDate, 'yyyy-MM-dd');
      const to = this.datePipe.transform(this.endDate, 'yyyy-MM-dd');
      //let reportLink = bookedReport[0].ReportLink;
      let reportLink = this.bookedReport[0].FeaturePath;
      reportLink = reportLink.replaceAll(
        '[[RESOURCE_NAME]]',
        this.gridSelectedUser
      );
      reportLink = reportLink.replaceAll('[[FROM_DATE]]', from);
      reportLink = reportLink.replaceAll('[[TO_DATE]]', to);
      this.bookedTimeReportLink = reportLink;
      window.open(this.bookedTimeReportLink, '_blank');
    }
  }

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }
  GetAllBookings() {
    this.getBookingList();
  }
}
