import { Injectable } from '@angular/core';
import { CurrentUser } from '../Models/CurrentUser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
// import * as _ from 'lodash-es';
import { Router } from '@angular/router';
import { AuthService } from '../shared/auth/auth.service';
import { TokenService } from '../shared/auth/token.service';
import { ResourceDto } from '../Models/ResourceDto';
import { UserVM } from '../Models/UserVM';
import { SharedServicesService } from '../shared/sharedservices/shared-services.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ThemeService } from 'app/shared/theme/theme.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  //private currentUser: CurrentUser = this.createNullUser();
  private readonly sessionKeyUser = 'user';
  private authenticationUrl = '';
  private serviceApiUrl = '';
  loginSubscription: Subscription;
  refreshTokenUrl = '';
  resourceListSubscription: Subscription;
  resource: ResourceDto;
  userProfileListSubscription: Subscription;
  userData: any;
  privacyNotice: any;
  role: string;
  emailSubcription: string;
  user: UserVM;
  private loggedInUser = new BehaviorSubject({ email: '', name: '' });
  loggedUser = this.loggedInUser.asObservable();
  private isLoginSuccess = new BehaviorSubject(false);
  isMenuShown = this.isLoginSuccess.asObservable();
  constructor(
    private http: HttpClient,
    private router: Router,
    public auth: AuthService,
    private token: TokenService,
    public restService: SharedServicesService,
    private themeService: ThemeService,
    private deviceService: DeviceDetectorService
  ) {
    //this.authenticationUrl = environment.autherizationServiceUrl;
    //this.serviceApiUrl = environment.APIEndpoint;
    //this.refreshTokenUrl = environment.refreshTokenUrl;
    this.resource = new ResourceDto();
    this.user = new UserVM();
  }

  httpOptions = {
    headers: new HttpHeaders({ Bearer: 'X-MS-TOKEN-AAD-ACCESS-TOKEN' }),
  };
  setMenuHideAndShow(objResourceVM) {
    this.isLoginSuccess.next(objResourceVM);
  }

  login() {
    // todo: comment this to enable MSAL

    this.auth.timerStarted();
    this.SetUserDetailsAndPageRedirect();
  }

  private SetUserDetailsAndPageRedirect() {
    const isDesktopDevice = this.deviceService.isDesktop();
    if (isDesktopDevice) {
      sessionStorage.setItem('requestMode', JSON.stringify(2));
      this.user.RequestMode = 2;
    } else {
      sessionStorage.setItem('requestMode', JSON.stringify(1));
      this.user.RequestMode = 1;
    }

    this.userProfileListSubscription = this.restService
      .loginAndGetUserProfile(this.user.RequestMode)
      .subscribe((data) => {
        if (data) {
          this.user.Email = data.Email;
          this.RoutetoUserRolePage(data);
          this.themeService.sendData(data.DefaultTheme);
        }
      });
  }

  public RoutetoUserRolePage(response) {
    this.user = response;

    sessionStorage.setItem('resource', JSON.stringify(this.user));
    sessionStorage.setItem('resourceId', this.user.UserId.toString());
    sessionStorage.setItem('loggedin_resourceid', this.user.UserId.toString());
    sessionStorage.setItem(
      'roleFeatures',
      JSON.stringify(this.user.RoleFeatures)
    );
    // need this role type for ApplicationInsightService.AppInsightsQueuePush()
    sessionStorage.setItem('RoleTypeName', this.user.RoleType);
    sessionStorage.setItem(
      'RoleTypeDisplayName',
      this.user.RoleTypeDisplayName
    );
    sessionStorage.setItem('RoleName', this.user.RoleName);
    sessionStorage.setItem('RoleId', this.user.RoleId.toString());

    this.privacyNotice = response.PrivacyNotice;
    this.emailSubcription = response.EmailSubscription;
    sessionStorage.setItem('user_privacyNotice', this.privacyNotice);
    sessionStorage.setItem('user_emailSubscription', this.emailSubcription);
    this.restService.setHeaderDetails(this.user);

    let roleFeatures = CurrentUser.RoleFeatures;

    if (roleFeatures.length > 0 && this.privacyNotice) {
      //this.restService.setFooterDetails(this.resource);
      this.isLoginSuccess.next(true);
      this.router.navigate(['Home']);
    } else if (this.privacyNotice && this.user.UserId === 0) {
      this.isLoginSuccess.next(false);
      this.router.navigate(['Error']);
    } else if (roleFeatures.length == 0 && this.privacyNotice) {
      this.isLoginSuccess.next(false);
      this.router.navigate(['AccessError']);
    } else if (!this.privacyNotice) {
      this.isLoginSuccess.next(false);
      this.router.navigate(['PrivacyNotice']);
    } else {
      this.isLoginSuccess.next(false);
      this.router.navigate(['Error']);
    }
    this.loggedInUser.next({
      email: this.user.Email,
      name: this.user.ResourceName,
    });
  }
}
