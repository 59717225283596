import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ResourceDto } from 'app/Models/ResourceDto';
import { OrderPipe } from 'ngx-order-pipe';
import { Subscription } from 'rxjs';
import { RoleConfigService } from '../role-config.service';

@Component({
  selector: 'app-update-resource-email',
  templateUrl: './update-resource-email.component.html',
  styleUrls: ['./update-resource-email.component.scss'],
})
export class UpdateResourceEmailComponent implements OnInit {
  resourceList: ResourceDto[] = [];
  resourceRoleModel: ResourceDto = new ResourceDto();
  subscription: Subscription;
  searchSubscription: Subscription;
  buttonText = 'Save';
  submitSubscription: Subscription;
  formGroup: FormGroup;
  messageBody = '';
  messageTitle = '';
  // searchBar: string;
  showSearchList: boolean = false;
  showSearchLoader: boolean = false;
  constructor(
    public roleConfigurationService: RoleConfigService,
    private orderPipe: OrderPipe
  ) {
    this.formGroup = new FormGroup({
      resEmailControl: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.maxLength(100),
      ]),
      searchBar: new FormControl(''),
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
    if (this.searchSubscription !== undefined) {
      this.searchSubscription.unsubscribe();
    }
    if (this.submitSubscription !== undefined) {
      this.submitSubscription.unsubscribe();
    }
  }

  clear(){
    this.resourceList = [];
    this.showSearchLoader = false;
    this.showSearchList = false;
    this.resourceRoleModel = new ResourceDto();
    this.getFormControlObj('searchBar').patchValue('');
    this.getFormControlObj('resEmailControl').patchValue('');
    this.formGroup.reset();
    // this.searchBar = '';
  }

  getFormControlObj(inp1, inp2?) {
    const fg = this.formGroup;
    let fgObj;
    if (inp2 && inp2 !== '') fgObj = fg?.get(inp1).get(inp2);
    else fgObj = fg?.get(inp1);
    return fgObj;
  }

  search() {
    this.showSearchLoader = true;
    this.showSearchList = true;
    const serachQuery = this.getFormControlObj('searchBar').value;
    if (serachQuery.length >= 2) {
      let resourceToSearch = new ResourceDto();
      resourceToSearch.ResourceName = serachQuery;
      this.searchSubscription = this.roleConfigurationService
        .searchResourceRoleEditable(resourceToSearch)
        .subscribe((data) => {
          this.showSearchLoader = false;
          this.resourceList = this.orderPipe.transform(data, 'ResourceName');
          if(!this.resourceList?.length) this.showSearchList = false;
        });
    } else {
      this.showSearchLoader = false;
      this.showSearchList = false;
      this.resourceList = [];
    }
  }

  onItemSelect(event) {
    this.showSearchList = false;
    this.showSearchLoader = false;
    this.resourceRoleModel = event;
    // this.searchBar = event?.ResourceName;
    this.getFormControlObj('searchBar').patchValue(event?.ResourceName);
    // this.formGroup
    //   .get('resEmailControl')
    //   .setValue(
    //     this.resourceRoleModel ? this.resourceRoleModel.ResourceEmail : ''
    //   );

    this.getFormControlObj('resEmailControl').patchValue(this.resourceRoleModel?.ResourceEmail);
  }

  resetFilterSearchBar() {
    this.clear();
  }

  submit() {
    if (this.formGroup.invalid) {
      return;
    }
    this.buttonText = 'Saving...';
    let vm = {
      ResourceId: this.resourceRoleModel.ResourceId,
      ResourceEmail: this.formGroup.get('resEmailControl').value,
    };
    this.submitSubscription = this.roleConfigurationService
      .updateResourceEmail(vm)
      .subscribe(
        (data: any) => {
          if (data != null) {
            if (!data.success) {
              this.messageBody = 'Failed! Please try again.';
              this.messageTitle = 'Failed';
            } else {
              this.messageBody = 'Successfully updated Resource Email.';
              this.messageTitle = 'Success';
            }
          } else {
            this.messageBody = 'Failed! Please try again.';
            this.messageTitle = 'Failed';
          }
          this.closeMessageDelayed();
          window.scroll(0, 0);
          this.buttonText = 'Save';
          this.clear();
        },
        (err) => {
          this.messageBody = 'Error! Please try again.';
          this.messageTitle = 'Failed';
          this.closeMessageDelayed();
          window.scroll(0, 0);
          this.buttonText = 'Save';
          this.clear();
        },
        () => {
          this.buttonText = 'Save';
          this.clear();
        }
      );
  }

  closeMessageDelayed() {
    setTimeout(() => {
      this.messageBody = '';
      this.messageTitle = '';
    }, 5000);
  }

  closeMessage() {
    this.messageBody = '';
    this.messageTitle = '';
  }
}
