import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EngagementSubmissionVM } from '../../../Models/EngagementSubmissionVM';
import { ResourceDto } from '../../../Models/ResourceDto';
import { DatePipe } from '@angular/common';
import { FormGroup, FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { BookingGridActionService } from '../../../Models/BookingGridActionService';
import { OrderPipe } from 'ngx-order-pipe';
import { SharedServicesService } from '../../../shared/sharedservices/shared-services.service';
import { PreviewCountVM } from '../../../Models/PreviewCountVM';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CurrentWeekActionDTO } from '../../../Models/CurrentWeekActionDTO';

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { EngagementService } from '../engagement.service';
import { EngagementBookingVM } from 'app/Models/EngagementBookingVM';
import { JobVM } from 'app/Models/JobVM';
import { IsDisableFlagVM } from 'app/Models/IsDisableFlagVM';
import moment from 'moment';
import { ThemeService } from 'app/shared/theme/theme.service';
import { DARK_PATH, LIGHT_PATH } from 'app/app-constants';
import { favEngImageSrc } from 'app/shared/enum';
import { IndividualService } from 'app/Views/individual/individual.service';
import {
  FavouriteJobsAddActionDto,
  FavouriteJobsDto,
  FavouriteJobsTableTypeDto,
} from 'app/Models/FavouriteJobsDto';
import { ScheduledViewService } from 'app/Views/scheduleView/scheduledView.service';

@Component({
  selector: 'app-engagement-booking',
  templateUrl: './engagement-booking.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./engagement-booking.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class EngagementBookingComponent implements OnInit {
  public isDirtyFlag = false;
  startDate: any = null;
  endDate: any = null;

  gridActionModalHeader = '';
  engagementSubmission: EngagementSubmissionVM;
  engagementSubmissionList: EngagementSubmissionVM[];
  JobDescription: any;
  displayedColumns: string[] = [
    'child',
    'ResourceName',
    'ServiceLine',
    'OfficeLocation',
    'Rank',
    'Hours',
    'Loading',
    'StartDate',
    'EndDate',
    'Actions',
  ];
  range = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null),
  });
  
  showSearchList: boolean = false;
  showSearchLoader: boolean = false;
  user;
  engagementAllocationModal = '';
  searchBar: string = '';
  selectedResource: ResourceDto = new ResourceDto();
  landingResource: ResourceDto;
  gridSelectedUser: string;
  resource: ResourceDto;
  currentUserEmail: any;
  currentUserSubscription: Subscription;
  resourceSelected: boolean;
  bookingList: EngagementBookingVM[];
  bookingListSubscription: Subscription;
  previewCountSubscription: Subscription;
  resourceId: number;
  booking: EngagementBookingVM;
  isPercentageSelected: boolean = false;
  bookingListCount: number = -1;
  resourceName: string;
  count: number = 0;
  selectedInstance = 1;
  instances;
  engagementSearch: JobVM;
  engagementListSubscription: Subscription;
  sortedJobList: JobVM[];
  results: any[];
  selectedJobName = '';
  JobId: number;
  JobCode: number;
  engagementSelected: boolean;
  IsDisabledAddBooking: any = false;
  IsDisabledDeleteBooking: any;
  IsDisableFlag: IsDisableFlagVM;
  IsDisabledReplaceAllFlag: any;
  EngagementTitle: string;
  engagementCode: any;
  engagementStatus: any;
  engagementServiceLine: any;
  engagementType: any;
  selectedItem: any = '';
  modalOption: ModalOptions = {};
  confirmModalRef: BsModalRef;
  modalRef: BsModalRef;
  engagementAllocation: boolean = false;
  resourceDetails: any;
  requestIdSet = false;
  showModal = false;
  showModalAdd = false;
  showModelSwap = false;
  showDeleteParent = false;
  showModalParentEdit = false;
  showModalSplit = false;
  showModalDelete = false;
  showModalCancel = false;
  showPreviewModal = false;
  showReplaceAllEng = false;
  gridActionEventData: BookingGridActionService;
  engFromIndividual;
  isWallChart: boolean = false;
  instanceFromInd: number = 0;
  resourceIdFromInd: number;
  defaultDateFormat: string;
  editReplaceAll: number;
  editDelete: number;
  roleDelgtFlg: boolean = true;
  isNavFromApprovalQueue: boolean = false;
  isMobileDevice: boolean = false;
  today = new Date();
  todaysDate = new Date(
    this.today.getFullYear(),
    this.today.getMonth(),
    this.today.getDate(),
    0,
    0,
    0
  );
  startWith = this.sharedService.getFirstDateOfWeek(this.todaysDate);
  currentWeekStartDate = this.sharedService.getFirstDateOfWeek(this.todaysDate);
  isCollapsed: boolean = false;
  searchLabel: string = 'Engagement Forecast';
  currentWeek: CurrentWeekActionDTO;
  isPeriodErrorMsg: boolean = false;
  imagePath: string = '';

  showFavouriteEngList: boolean = false;
  addFavouriteEngResults: any[];
  isClickonFavEngStart: boolean = false;
  outClickCount: number = 0;
  compareFavouriteEngResults: any[] = [];
  isVerifyOnEngClick: boolean = false;
  lbl: string;
  FavoriteImageClick: boolean = false;
  IsFavEngInserted: boolean = false;
  sortedEngagementList: any[];
  isFavEngONClick: boolean = true;
  IsDataNotFound: boolean = false;

  constructor(
    public engagementService: EngagementService,
    public restService: EngagementService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    private themeService: ThemeService,
    private orderPipe: OrderPipe,
    public sharedService: SharedServicesService,
    private location: Location
  ) {
    this.sharedService.activeLink = 'EngagementBooking';
    this.engagementAllocationModal =
      this.isPercentageSelected === true ? 'percentage' : 'hour';
    this.engFromIndividual =
      this.router.getCurrentNavigation().extras.state?.engagement;
    this.instanceFromInd =
      this.router.getCurrentNavigation().extras.state?.instanceId;
    this.selectedInstance = this.instanceFromInd > 0 ? this.instanceFromInd : 1;
    this.resourceIdFromInd =
      this.router.getCurrentNavigation().extras.state?.resourcId;
    this.isNavFromApprovalQueue =
      this.router.getCurrentNavigation().extras.state?.isNavFromApprovalQueue;
    this.JobId = this.router.getCurrentNavigation().extras.state?.jobId;
    document.addEventListener('click', this.favoriteimgClickHandler.bind(this)); // bind on document
  }

  ngOnInit(): void {
    // console.log('data', history.state);
    // this.getCurrentWeek();
    this.editReplaceAll =
      this.currentWeek && this.currentWeek.IsDisabledReplaceAllBookingCurrWeek
        ? 1
        : 0;
    this.editDelete =
      this.currentWeek && this.currentWeek.IsDisabledDeleteBookingCurrWeek
        ? 1
        : 0;

    const activeTheme = this.themeService.getActiveTheme();
    this.imagePath = activeTheme.name === 'light' ? LIGHT_PATH : DARK_PATH;

    this.user = { multiInstance: undefined };
    this.bookingList = [];
    this.user = JSON.parse(sessionStorage.getItem('resource'));
    this.roleDelgtFlg = this.user.RoleDelegate;
    this.sharedService.getDefaultPeriodSettings(this.user);
    this.range.setValue({
      start:  this.sharedService.defaultStartDate ? moment(this.sharedService.defaultStartDate) : null,
      end: this.sharedService.defaultEndDate? moment(this.sharedService.defaultEndDate) : null,
    });
    this.startDate = this.sharedService.defaultStartDate;
    this.endDate = this.sharedService.defaultEndDate;
    // this.currentWeekStartDate.setMinutes(
    //   this.currentWeekStartDate.getMinutes() -
    //     this.currentWeekStartDate.getTimezoneOffset()
    // );
    this.currentWeekStartDate = new Date(
      this.currentWeekStartDate.getFullYear(),
      this.currentWeekStartDate.getMonth(),
      this.currentWeekStartDate.getDate(),
      0,
      0,
      0
    );
    this.startWith.setMinutes(this.startWith.getMinutes() - this.startWith.getTimezoneOffset());
    this.defaultDateFormat = this.sharedService.getDateFormat(
      this.user.DefaultDateFormat
    );

    //Remove the Instance column in eng view
    // if (this.user.IsMultiInstance) {
    //   this.displayedColumns.splice(
    //     this.displayedColumns.indexOf('Rank') + 1,
    //     0,
    //     'Instance'
    //   );
    // }

    // if(this.user.Ghost){
    //   this.displayedColumns.splice(this.displayedColumns.indexOf('EndDate')+1, 0, 'Ghost');
    // }
    this.isMobileDevice =
      JSON.parse(sessionStorage.getItem('requestMode')) === 1 ? true : false;
    this.resourceSelected = false;
    this.resource = new ResourceDto();
    this.booking = new EngagementBookingVM();
    this.gridActionEventData = new BookingGridActionService();
    this.currentUserEmail = this.user.Email;
    this.sharedService.setHeaderDetails(this.resource);
    this.sharedService.setFooterDetails(this.resource);
    this.getUserInstances();
    //this.getPreviewCountList();
    this.restService.defaultEngagementLoadingChange.subscribe((data) => {
      this.updateLoadingPreference();
    });
    this.restService.setRequestIdEvent.subscribe((requestId) => {
      if (this.engagementSubmission)
        this.engagementSubmission.RequestId = requestId;
      else this.engagementSubmission = new EngagementSubmissionVM();
      this.gridActionModalHeader =
        'Preview Actions. Request ID - ' + this.engagementSubmission.RequestId;
      if (requestId !== 0 && requestId !== undefined) {
        this.requestIdSet = true;
      }
      this.results = [];
    });
    this.restService.currentMessageCloseModal.subscribe((closeModal) => {
      this.closeActionModal(closeModal);
    });
    // const dayNeeded = 7; //For Saturday
    // const currentDay = moment().isoWeekday();
    // if (currentDay === dayNeeded) {
    //   let day = moment().day(dayNeeded);
    //   this.startWith = new Date(day.year(), day.month(), day.date());
    // } else {
    //   let day = moment().subtract(1, 'weeks').isoWeekday(dayNeeded);
    //   this.startWith = new Date(day.year(), day.month(), day.date());
    // }
  }

  closeActionModal(closeModal) {
    // if (this.modalRef !== undefined && this.modalRef !== null) {
    //   this.modalRef.hide();
    // }
    // if (closeModal) {
    //   this.filterBookingGrid();
    // }

    if (closeModal) {
      if (this.modalRef !== undefined && this.modalRef !== null) {
        this.modalRef.hide();
        this.filterBookingGrid();
      }
    } else {
      if (this.modalRef !== undefined && this.modalRef !== null) {
        this.modalRef.hide();
      }
    }
  }

  loadInitialData() {
    this.resourceDetails = JSON.parse(sessionStorage.getItem('resource'));
    this.engagementSubmissionList = [];
    this.engagementSubmission = new EngagementSubmissionVM();
    this.engagementAllocation = false;
    this.engagementSelected = false;
    this.searchBar = null;
    this.isPercentageSelected = this.resourceDetails.PercentSelected;
    this.engagementAllocation = this.resourceDetails.PercentSelected;
    this.restService.engagementAllocationOnChange(this.isPercentageSelected);
    // if (
    //   sessionStorage.getItem('JobId') !== null &&
    //   sessionStorage.getItem('JobId') !== undefined &&
    //   sessionStorage.getItem('EngagementSelected') === 'true'
    // ) {
    //   this.engagementSelected = true;
    //   this.EngagementTitle =  sessionStorage.getItem('JobDescription');
    //   this.searchBar = sessionStorage.getItem('JobDescription');
    // }
  }
  // getCurrentWeek() {
  //   this.restService.getCurrentWeek().subscribe((data: any) => {
  //     this.editReplaceAll = data.ReplaceAll;
  //     this.editDelete = data.DeleteWeek;
  //   });
  // }
  updateLoadingPreference() {
    this.resourceDetails = JSON.parse(sessionStorage.getItem('resource'));
    this.setDefaultData();
    this.maintainHiddenValues();
    if (this.engFromIndividual?.length > 0) {
      this.spinner.show();
      this.itemFromIndividual();
    } else {
      this.filterBookingGrid();
    }
  }

  setDefaultData() {
    this.isPercentageSelected = this.resourceDetails.PercentSelected;
    this.engagementAllocationModal =
      this.resourceDetails.PercentSelected === true ? 'percentage' : 'hour';
    this.searchBar = null;
    // this.range.setValue({
    //   start: null,
    //   end: null,
    // });
    this.sortedJobList = [];
    this.booking = new EngagementBookingVM();
    this.engagementSubmission = new EngagementSubmissionVM();
    this.restService.engagementAllocationOnChange(this.isPercentageSelected); //WORK ITEM 460
    this.loadInitialData();
  }

  engagementAllocationOnChange(event) {
    this.isPercentageSelected =
      this.engagementAllocationModal === 'hour' ? false : true;
    this.maintainHiddenValues();
    this.getBookingList();
  }

  maintainHiddenValues() {
    this.restService.engagementAllocationOnChange(this.isPercentageSelected);
    if (this.isPercentageSelected) {
      const indexOfHour = this.displayedColumns.indexOf('Hours', 0);
      if (indexOfHour > -1) {
        this.displayedColumns.splice(indexOfHour, 1);
      }
      const indexOfPercentage = this.displayedColumns.indexOf('Loading', 0);
      if (indexOfPercentage === -1) {
        if (this.user.IsMultiInstance) {
          this.displayedColumns.splice(4, 0, 'Loading');
        } else {
          this.displayedColumns.splice(3, 0, 'Loading');
        }
      }
    } else {
      const indexOfHour = this.displayedColumns.indexOf('Hours', 0);
      if (indexOfHour === -1) {
        if (this.user.IsMultiInstance) {
          this.displayedColumns.splice(4, 0, 'Hours');
        } else {
          this.displayedColumns.splice(3, 0, 'Hours');
        }
      }
      const indexOfPercentage = this.displayedColumns.indexOf('Loading', 0);
      if (indexOfPercentage > -1) {
        this.displayedColumns.splice(indexOfPercentage, 1);
      }
    }
  }

  getUserInstances() {
    const userInstance = this.sharedService.getStoredUserInstances();

    if (userInstance === null || userInstance === undefined) {
      this.sharedService.getUserInstances().subscribe((data) => {
        this.instances = this.sharedService.getStoredUserInstances();
        if (this.instances && this.instances.length > 0) {
          const selected = this.instances.filter(
            (x) => x.InstanceId === this.user.DefaultInstance
          );
          if (
            (selected.length > 0 && this.instanceFromInd === 0) ||
            this.instanceFromInd === undefined
          ) {
            this.selectedInstance = selected[0].InstanceId;
            this.resourceIdFromInd = selected[0].ResourceId;
          } else {
            this.selectedInstance = this.instanceFromInd;
          }
        }
      });
    } else {
      this.instances = userInstance;
      const selected = this.instances.filter(
        (x) => x.InstanceId === this.user.DefaultInstance
      );
      if (
        selected.length > 0 &&
        (this.instanceFromInd === 0 || this.instanceFromInd === undefined)
      ) {
        this.selectedInstance = selected[0].InstanceId;
        this.resourceIdFromInd = selected[0].ResourceId;
      } else {
        this.selectedInstance = this.instanceFromInd;
      }
    }
  }

  itemFromIndividual() {
    this.searchBar = this.engFromIndividual;
    this.selectedInstance = this.instanceFromInd;
    this.engagementSearch = new JobVM();
    this.engagementSearch.JobDescription = this.engFromIndividual;
    this.engagementSearch.LoggedInResourceId = +sessionStorage.getItem(
      'loggedin_resourceid'
    );
    this.engagementSearch.InstanceId = this.instanceFromInd;
    this.engagementSearch.JobId = this.JobId;
    this.engagementListSubscription = this.restService
      .getJobList(this.engagementSearch)
      .subscribe((data) => {
        this.sortedJobList = this.orderPipe.transform(data, 'JobDescription');
        if (this.sortedJobList.length === 1) {
          this.OnItemSelect(this.sortedJobList[0]);
          this.spinner.hide();
          // this.isCollapsed = true; // Commenting as part of latest CR
        } else {
          this.spinner.hide();
        }
      });
  }

  OnItemSelect(event) {
    if (this.FavoriteImageClick === true) {
      this.FavoriteImageClick = false;
      (event as Event).stopPropagation();
    }

    this.showSearchList = false;
    this.showFavouriteEngList = false;
    this.searchBar = event.JobDescription;
    this.selectedItem = event;
    this.JobId = event.JobId;
    this.EngagementTitle = event.JobDescription;
    this.engagementCode = event.JobCode;
    this.engagementStatus = event.JobStatus;
    this.engagementType = event.JobType;
    this.engagementServiceLine = event.ServiceLineDescription;
    this.engagementSelected = !event.IsDisabledAddBooking;
    this.updateCurrentWeekFlags(event);
    this.GetIsDisableFlags(this.JobId);

    sessionStorage.setItem('JobId', this.JobId.toString());
    sessionStorage.setItem('JobDescription', this.EngagementTitle);
    sessionStorage.setItem('engagementCode', this.engagementCode);
    sessionStorage.setItem('engagementType', this.engagementType);
    sessionStorage.setItem('engagementStatus', this.engagementStatus);
    sessionStorage.setItem('engagementServiceLine', this.engagementServiceLine);
    sessionStorage.setItem(
      'isPercentageSelected',
      this.isPercentageSelected.toString()
    );
    sessionStorage.setItem('instanceId', this.selectedInstance.toString());
    sessionStorage.setItem(
      'EngagementSelected',
      this.engagementSelected.toString()
    );

    this.filterBookingGrid();
    this.restService.setSelectedJob(this.JobId);
  }

  updateCurrentWeekFlags(event) {
    this.currentWeek = {
      IsDisabledAddBookingCurrWeek: event.IsDisabledAddBookingCurrWeek,
      IsDisabledEditBookingCurrWeek: event.IsDisabledEditBookingCurrWeek,
      IsDisabledDeleteBookingCurrWeek: event.IsDisabledDeleteBookingCurrWeek,
      IsDisabledCopyBookingCurrWeek: event.IsDisabledCopyBookingCurrWeek,
      IsDisabledReplaceBookingCurrWeek: event.IsDisabledReplaceBookingCurrWeek,
      IsDisabledReplaceAllBookingCurrWeek:
        event.IsDisabledReplaceAllBookingCurrWeek,
    };
    //console.log('CW', this.currentWeek);
  }

  GetIsDisableFlags(jobid) {
    this.IsDisableFlag = new IsDisableFlagVM();
    this.IsDisableFlag.JobId = jobid;
    // this.IsDisableFlag.LoggedInResourceID = +sessionStorage.getItem(
    //   'loggedin_resourceid'
    // );
    //this.IsDisableFlag.LoggedInResourceEmail = this.currentUserEmail;
    this.IsDisableFlag.InstanceId = this.selectedInstance;

    this.engagementListSubscription = this.restService
      .getIsDisableFlags(this.IsDisableFlag)
      .subscribe((data: any) => {
        if (data) {
          this.IsDisabledAddBooking = data.IsDisabledAddBooking;
          //this.IsDisabledDeleteBooking = data.IsDisabledDeleteBookings;
          this.IsDisabledReplaceAllFlag = data.IsDisabledReplaceAllFlag;
        }
      });
  }
  onInstanceChange(event) {
    this.selectedInstance = event.value;
    this.resetfilterBookingGridSearchBar();
    // const userInstance = this.sharedService.getStoredUserInstances();
    // if (userInstance === null || userInstance === undefined) {
    //   this.sharedService.getUserInstances().subscribe((data) => {
    //     this.instances = this.sharedService.getStoredUserInstances();
    //     if (this.instances && this.instances.length > 0) {
    //       const selected = this.instances.filter(
    //         (x) => x.InstanceId === this.selectedInstance
    //       );
    //       if (selected.length > 0) {
    //         this.resourceIdFromInd = selected[0].ResourceId;
    //         this.getBookingList();
    //       }else{
    //         this.resetfilterBookingGridSearchBar();
    //       }
    //     }
    //   });
    // } else {
    //   this.instances = userInstance;
    //   const selected = this.instances.filter(
    //     (x) => x.InstanceId === this.selectedInstance
    //   );
    //   if (selected.length > 0) {
    //     this.resourceIdFromInd = selected[0].ResourceId;
    //     this.getBookingList();
    //   }else{
    //     this.resetfilterBookingGridSearchBar();
    //   }
    // }
  }
  getBookingList() {
    // this.getPreviewCountList();
    this.spinner.show();
    // if (
    //   this.resourceId === undefined ||
    //   this.resourceId === null ||
    //   !this.resourceId ||
    //   this.resourceId === 0
    // ) {
    //   this.spinner.hide();
    //   return;
    // }
    // this.EngagementTitle = sessionStorage.getItem('JobDescription');
    // this.searchBar = sessionStorage.getItem('JobDescription');
    //this.booking.JobId = +sessionStorage.getItem('JobId');
    this.booking.JobId = this.JobId;
    this.booking.JobCode = this.engagementCode;
    this.booking.IsPercentSelected = this.engagementAllocation;
    //this.booking.ResourceId = +sessionStorage.getItem('resourceId');
    this.booking.ResourceId = this.resourceIdFromInd;
    this.booking.LoggedInResourceID = +sessionStorage.getItem(
      'loggedin_resourceid'
    );
    this.booking.InstanceId = this.selectedInstance;
    this.booking.StartDate?.setMinutes(this.booking.StartDate?.getMinutes() - this.booking.StartDate?.getTimezoneOffset());
    this.booking.EndDate?.setMinutes(this.booking.EndDate?.getMinutes() - this.booking.EndDate?.getTimezoneOffset());
    this.bookingListSubscription = this.restService
      .getEngagementBookingListForGrid(this.booking)
      .subscribe(
        (data) => {
          this.bookingList = data;
          this.bookingListCount = this.bookingList.length;
          this.spinner.hide();
          this.bookingList?.forEach((element) => {
            if (element.ChildRowsExist) {
              element.isExpandable = false;
            }
          });

          this.getPreviewCountList();

          this.restService.refreshWallChart(this.JobId);
        },
        (err) => {
          this.bookingList = []; // empty the grid data in case of error as it shows previous user's data.
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  getPreviewCountList() {
    if (this.user.PreviewSubmit) {
      this.spinner.show();
      this.previewCountSubscription = this.restService
        .getPreviewCountList()
        .subscribe((data: number) => {
          this.count = data;
          this.spinner.hide();
        });
    }
  }

  filterBookingGrid() {
    if (this.JobId > 0 && this.startDate === null && this.endDate === null) {
      this.booking.JobId = this.JobId;
      this.booking.StartDate = null;
      this.booking.EndDate = null;
      this.getBookingList();
    } else if (
      this.JobId === 0 &&
      this.startDate !== null &&
      this.endDate !== null
    ) {
      this.booking.JobId = null;
      this.booking.StartDate = this.startDate;
      this.booking.EndDate = this.endDate;
      this.getBookingList();
    } else if (
      this.JobId > 0 &&
      this.startDate !== null &&
      this.endDate !== null
    ) {
      this.booking.JobId = this.JobId;
      this.booking.StartDate = this.startDate;
      this.booking.EndDate = this.endDate;
      this.getBookingList();
    } else {
      this.booking.JobId = null;
      this.booking.StartDate = null;
      this.booking.EndDate = null;
      this.getBookingList();
    }
  }

  onBookingStartDateChange(event: any) {
    this.startDate = this.range.get('start').value;
    this.endDate = null;
  }

  onBookingEndDateChange(event: any) {
    this.endDate = this.range.get('end').value;
    if (event.value != null) {
      // sessionStorage.setItem('PeriodFilterStDate', this.startDate);
      // sessionStorage.setItem('PeriodFilterEndDate', this.endDate);
      if (this.endDate !== null) {
        this.startDate = new Date(this.startDate);
        this.endDate = new Date(this.endDate);
        // this.sharedService.getDefaultPeriodSettings(this.user);
        this.filterBookingGrid();
      }
    }
  }

  OnClosedDatePicker() {
    if (this.startDate != null && this.endDate == null) {
      this.isPeriodErrorMsg = true;
      setTimeout(() => {
        this.isPeriodErrorMsg = false;
        this.startDate = null;
        this.endDate = null;
        this.range.setValue({
          start: null,
          end: null,
        });
      }, 3000);
    }
  }

  resetfilterBookingGridCalender(event) {
    event.stopPropagation();
    event.preventDefault();
    this.range.setValue({
      start: null,
      end: null,
    });
    this.startDate = null;
    this.endDate = null;
    // sessionStorage.setItem('PeriodFilterStDate', this.startDate);
    // sessionStorage.setItem('PeriodFilterEndDate', this.endDate);
    // this.sharedService.getDefaultPeriodSettings(this.user);
    this.filterBookingGrid();
    if (this.gridSelectedUser == null) {
      this.resourceSelected = false;
    }
  }

  resetfilterBookingGridSearchBar() {
    this.isWallChart = false;
    this.OnResourceClear();
    this.addFavouriteEngResults = [];
    this.results = [];
    sessionStorage.setItem('JobId', this.JobId.toString());
    sessionStorage.setItem('JobDescription', this.selectedJobName);
    this.engagementSelected = false;
    sessionStorage.setItem(
      'EngagementSelected',
      this.engagementSelected.toString()
    );
    this.filterBookingGrid();
    this.restService.setSelectedJob(this.JobId);
    this.EngagementTitle = '';
    this.selectedItem.ServiceLineDescription = '';
    this.selectedItem.ManagerialCountryDescription = '';
    this.selectedItem.CostCenterDescription = '';
    this.selectedItem.PartnerName = '';
    this.selectedItem.ManagerNameAndGPN = '';
    this.selectedItem.InstanceName = '';
    this.isFavEngONClick = true;
    this.IsDataNotFound = false;
  }

  OnResourceClear() {
    this.results = [];
    this.showSearchList = false;
    this.searchBar = '';
    this.selectedJobName = '';
    this.JobCode = null;
    this.JobId = 0;
    this.isFavEngONClick = true;
    this.IsDataNotFound = false;
  }

  search() {
    this.isVerifyOnEngClick = false;
    this.sortedEngagementList = [];
    this.addFavouriteEngResults = [];
    this.sortedEngagementList = [];

    this.isWallChart = false;
    if (this.searchBar != null) {
      if (this.searchBar.trim().length >= 2) {
        this.showSearchList = true;
        this.showSearchLoader = true;
        this.engagementSearch = new JobVM();
        this.engagementSearch.JobDescription = this.searchBar.trim();
        this.engagementSearch.LoggedInResourceId = +sessionStorage.getItem(
          'loggedin_resourceid'
        );
        this.engagementSearch.InstanceId = this.selectedInstance;
        this.engagementSearch.JobId = 0;
        this.engagementListSubscription = this.restService
          .getJobList(this.engagementSearch)
          .subscribe((data) => {
            this.sortedJobList = this.orderPipe.transform(
              data,
              'JobDescription'
            );

            this.sortedEngagementList = data;
            //this.sortedEngagementList = this.orderPipe.transform(this.sortedJobList, 'EngagementFullName');
            if (this.sortedEngagementList.length > 0) {
              this.setAllFavEngagement();
            }
            //this.results = this.sortedJobList;
            this.results = data;
            this.showSearchLoader = false;
            this.showFavouriteEngList = false;
          });
      } else {
        this.results = [];
        this.showSearchList = false;
      }
    } else {
      this.results = [];
      this.showSearchList = false;
    }
  }

  private setAllFavEngagement(): void {
    this.sortedEngagementList.forEach((a) => {
      if (a.IsFavourite === true) {
        a.IsFavEngInserted = true;
        a.favEngSrc = favEngImageSrc.favEngImgStarfilled;
      } else {
        a.IsFavEngInserted = false;
        a.favEngSrc = favEngImageSrc.favEngImgStarOutline;
      }
    });
  }

  openModal(template, event) {
    event.stopPropagation();
    event.preventDefault();
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;

    this.modalRef = this.modalService.show(template, this.modalOption);
  }

  openActionModal(template, event, dataItem, templateName) {
    this.showModal = false;
    this.showModalAdd = false;
    this.showModalSplit = false;
    this.showModalDelete = false;
    this.showModalCancel = false;
    this.showPreviewModal = false;
    this.showModelSwap = false;
    this.showReplaceAllEng = false;
    this.showDeleteParent = false;
    this.showModalParentEdit = false;

    if (templateName !== 'add') this.gridActionEventData.dataItem = dataItem;
    this.modalOption.class = '';
    this.gridActionEventData.event = event;
    this.gridActionEventData.template = template;
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    // Use the parent-edit component for single edit also
    if (templateName === 'edit') {
      this.gridActionModalHeader = 'Edit Booking';
      // this.showModal = true;
      // this.restService.openEditModal(this.gridActionEventData);
      this.showModalParentEdit = true;
    } else if (templateName === 'swap') {
      this.gridActionModalHeader = 'Replace Booking';
      this.showModelSwap = true;
      this.restService.openSwapModel(this.gridActionEventData);
    } else if (templateName === 'deleteparent') {
      this.gridActionModalHeader = 'Delete Bookings';
      this.showDeleteParent = true;
    } else if (templateName === 'parentEdit') {
      this.gridActionModalHeader = 'Edit Bookings';
      this.showModalParentEdit = true;
    } else if (templateName === 'add') {
      this.gridActionModalHeader = 'New Booking';
      this.modalOption.class = 'modal-lg';
      this.showModalAdd = true;
      this.restService.openAddModal(this.gridActionEventData);
    } else if (templateName === 'replaceallEng') {
      this.gridActionModalHeader = 'Replace All Engagement';
      this.showReplaceAllEng = true;
      this.restService.openReplaceAllEng(this.gridActionEventData);
    } else if (templateName === 'split') {
      this.gridActionModalHeader = 'Copy Booking';
      this.showModalSplit = true;
      this.restService.openSplitModal(this.gridActionEventData);
    } else if (templateName === 'delete') {
      this.gridActionModalHeader = 'Delete Booking';
      this.showModalDelete = true;
      this.restService.openDeleteModal(this.gridActionEventData);
    } else if (templateName === 'cancelAction') {
      this.gridActionModalHeader = 'Cancel Action Request';
      this.showModalCancel = true;
    } else if (templateName === 'preview') {
      this.showPreviewModal = true;
      if (
        this.engagementSubmission.RequestId === 0 ||
        this.engagementSubmission.RequestId === undefined
      ) {
        this.gridActionModalHeader = 'Preview Actions';
      } else {
        this.gridActionModalHeader =
          'Preview Actions. Request ID - ' +
          this.engagementSubmission.RequestId;
      }
      this.engagementSubmission.JobId = this.JobId;
      this.restService.openPreviewModal(this.engagementSubmission);
    }
    this.modalRef = this.modalService.show(template, this.modalOption);
    if (templateName !== 'cancelAction') {
      this.modalRef.setClass('ea-modal');
    }
  }

  okayConfirm(template) {
    this.confirmModalRef.hide();
    if (this.modalRef !== undefined && this.modalRef !== null) {
      this.modalRef.hide();
    }
  }

  openConfirmationModal(template) {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    if (!this.isDirtyFlag || this.showModalDelete || this.showPreviewModal) {
      if (this.modalRef !== undefined && this.modalRef !== null) {
        this.modalRef.hide();
      }
    } else {
      this.confirmModalRef = this.modalService.show(template, this.modalOption);
      this.isDirtyFlag = false;
    }
  }

  makeDirty(data) {
    this.isDirtyFlag = data;
  }

  ngOnDestroy() {
    this.isWallChart = false;
    this.OnResourceClear();
    this.results = [];
    sessionStorage.setItem('JobId', this.JobId.toString());
    sessionStorage.setItem('JobDescription', this.selectedJobName);
    this.engagementSelected = false;
    sessionStorage.setItem(
      'EngagementSelected',
      this.engagementSelected.toString()
    );
    this.restService.setSelectedJob(this.JobId);
    if (this.bookingListSubscription !== undefined) {
      this.bookingListSubscription.unsubscribe();
    }
  }

  navigateIndividual(item) {
    if (item.IsDisableViewBooking_Res) {
      return;
    }
    const product = {
      individual: item.ResourceName,
      instanceId: item.InstanceId,
      resourceId: item.ResourceId,
    };
    this.router.navigateByUrl('/IndividualBooking', { state: product });
    // let navigationExtras : NavigationExtras = {
    //   queryParams: {
    //     individual: item.ResourceName,
    //     instanceId: item.InstanceId
    //   }
    // }
    // this.router.navigate(['/Individual'], navigationExtras);
  }

  selectedTooltipItem: any;
  selectItem(item) {
    this.selectedTooltipItem = item.TooltipData;
  }
  previousPopover: any;
  closePrevious(currentPop) {
    if (this.previousPopover && this.previousPopover != currentPop) {
      this.previousPopover.hide();
    }
    this.previousPopover = currentPop;
  }
  closeCurrent(pop) {
    pop.hide();
  }
  getFirstDateOfWeek(anyDateOfWeek: Date): Date {
    // date.getDay() RETURNS Week Day Index: SUN 0, MON 1, TUE 2, WED 3, THU 4, FRI 5, SAT 6.
    let date = new Date(anyDateOfWeek);
    let firstDayOfWeekIndex: number = 6; //Week Day Index: SUN 0, MON 1, TUE 2, WED 3, THU 4, FRI 5, SAT 6.
    let daysToShiftBack = (7 + (date.getDay() - firstDayOfWeekIndex)) % 7;
    let firstDateOfWeek = new Date(
      date.setDate(date.getDate() - daysToShiftBack)
    );
    return firstDateOfWeek;
  }

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }
  GetAllBookings() {
    this.getBookingList();
  }

  // added for faviourite Engagement serching functionality

  onEngagementClick() {
    this.showSearchList = false;
    this.isVerifyOnEngClick = true;
    this.showFavouriteEngList = true;

    if (this.searchBar != null) {
      if (this.searchBar.trim().length >= 2) {
        this.showFavouriteEngList = false;
        this.isFavEngONClick = false;
      } else {
        this.showFavouriteEngList = true;
        this.isFavEngONClick = true;
      }
    }
    if (this.isFavEngONClick) {
      this.getFavouriteEngagement();
    }
  }

  private getFavouriteEngagement(): void {
    let favouriteJobs = new FavouriteJobsDto();
    favouriteJobs.InstanceId = this.selectedInstance;
    favouriteJobs.IsSearchfromIndivBooking = true;
    favouriteJobs.JobCategory = '';
    this.showSearchLoader = true;

    this.engagementService
      .GetFavouriteJobsEngagementView(favouriteJobs)
      .subscribe((data) => {
        const key = 'JobId';

        const resultEng = [
          ...new Map(data.map((item) => [item[key], item])).values(),
        ];

        this.addFavouriteEngResults = resultEng;
        this.showSearchLoader = false;
        this.isFavEngONClick = false;
        if (this.addFavouriteEngResults.length > 0) {
          this.IsDataNotFound = false;
          this.showSearchLoader = false;
          this.addFavouriteEngResults.forEach((i) => {
            i.IsFavEngInserted = true;
            i.favEngSrc = favEngImageSrc.favEngImgStarfilled;
          });
        } else {
          this.showSearchLoader = false;
          this.IsDataNotFound = true;
          this.lbl = 'Favourite Engagements not added.';
        }
      });
  }

  onFavoriteImageClick(item) {
    this.FavoriteImageClick = true;
    item.IsOldFavEngInserted = item.IsFavEngInserted;
    item.IsFavEngInserted = !item.IsFavEngInserted;

    if (item.IsFavEngInserted) {
      item.favEngSrc = favEngImageSrc.favEngImgStarfilled;
    } else {
      item.favEngSrc = favEngImageSrc.favEngImgStarOutline;
    }
  }

  onFavEngagementSearch(event) {
    this.isWallChart = false;
    if (this.FavoriteImageClick === true) {
      this.FavoriteImageClick = false;
      (event as Event).stopPropagation();
    }
    if (event.EngagementFullName != null) {
      this.showSearchList = true;
      this.showSearchLoader = true;
      this.engagementSearch = new JobVM();
      this.engagementSearch.JobDescription = event.EngagementFullName.trim();
      this.engagementSearch.LoggedInResourceId = +sessionStorage.getItem(
        'loggedin_resourceid'
      );
      this.engagementSearch.InstanceId = this.selectedInstance;
      this.engagementSearch.JobId = event.JobId;
      this.engagementListSubscription = this.restService
        .getJobList(this.engagementSearch)
        .subscribe((data) => {
          this.sortedJobList = this.orderPipe.transform(data, 'JobDescription');

          this.results = this.sortedJobList;
          this.showSearchLoader = false;
          if (this.results) {
            this.OnItemSelect(this.results[0]);
          }
        });
    } else {
      this.results = [];
      this.showSearchList = false;
    }
  }

  private favoriteimgClickHandler(event: any) {
    if (!!event.target.src) {
      var parts = event.target.src.split('/');
      var img = parts[parts.length - 1];

      var a = favEngImageSrc.favEngImgStarOutline.split('/');
      var ImgStarOutline = a[a.length - 1];

      var b = favEngImageSrc.favEngImgStarfilled.split('/');
      var ImgStarfilled = b[b.length - 1];

      // if((event.target.src === favEngImageSrc.favEngImgStarOutline) || (event.target.src === favEngImageSrc.favEngImgStarfilled))
      if (img === ImgStarOutline || img === ImgStarfilled) {
        this.isClickonFavEngStart = true;
        this.outClickCount = 0;
        //console.log("in side click");
      } else {
        this.isClickonFavEngStart = false;
        if (this.outClickCount == 0) {
          this.outClickCount++;
        }
      }
    } else {
      if (this.isClickonFavEngStart) {
        if (this.outClickCount == 0) {
          this.outClickCount++;
          //console.log("out click after click on start");
          this.getAllSelectedfavEngagement();
        }
      }
      // console.log("out click ");
    }
  }

  private AddFavouriteEngagement(arr): void {
    this.engagementService.addFavouriteJobs(arr).subscribe((data) => {
      this.showSearchList = false;
      this.showFavouriteEngList = false;
      this.isFavEngONClick = true;
      this.searchBar = '';
    });
  }

  private getAllSelectedfavEngagement() {
    let typeArr: Array<FavouriteJobsTableTypeDto>;
    typeArr = [];
    let dataSE;
    if (this.outClickCount > 0) {
      this.outClickCount = 0;
      this.isClickonFavEngStart = false;
      this.compareFavouriteEngResults = [];
      if (!this.isVerifyOnEngClick) {
        dataSE = this.sortedEngagementList.filter(
          (ap) =>
            ap.IsOldFavEngInserted === true || ap.IsOldFavEngInserted === false
        );
      } else {
        dataSE = this.addFavouriteEngResults.filter(
          (ap) =>
            ap.IsOldFavEngInserted === true || ap.IsOldFavEngInserted === false
        );
      }

      if (dataSE.length > 0) {
        dataSE.forEach((data) => {
          if (
            data.IsOldFavEngInserted === true ||
            data.IsOldFavEngInserted === false
          ) {
            this.compareFavouriteEngResults.push(data);
            //console.log(this.compareFavouriteEngResults);
          }
        });
      }

      if (
        this.addFavouriteEngResults.length > 0 ||
        !!this.addFavouriteEngResults
      ) {
        let arr = [];
        this.compareFavouriteEngResults.forEach((op) => {
          if (!op.IsFavEngInserted) {
            if (this.addFavouriteEngResults.some((g) => g.JobId === op.JobId)) {
              op.InstanceId = this.selectedInstance;
              op.JobCategory = '';
              arr.push(op);
            }
          } else {
            op.InstanceId = this.selectedInstance;
            op.JobCategory = '';
            arr.push(op);
          }
        });
        if (arr.length > 0) {
          arr.forEach((tt) => {
            let obj = new FavouriteJobsTableTypeDto();
            obj.LoggedInUserId = this.user.UserId;
            obj.JobID = tt.JobId;
            obj.Instance_Id = tt.InstanceId;
            obj.IsFavEngInserted = tt.IsFavEngInserted;
            typeArr.push(obj);
          });
          let k = new FavouriteJobsAddActionDto();
          k.FavouriteJobs = typeArr;

          this.AddFavouriteEngagement(k);
        }
      }
    }
  }
}
