export class MyRequest_ApproveByMeVM {
    RequestId: string ;
    ActionId: number;
    Action: string;
    Resource: string;
    Engagement: string;
    StartDate: string;
    EndDate: string;
    Hours:number | null;
    Loading:number |null;
    UserSelection: string;
    Unconfirmed: string;
    Ghost: string;
    Status: string;
    RejectionReason: string;
    RequestorName : string;
    RequestedDateTime : string;
    ApprovedOrRejectedOn :string;
    startDate_sort:string;
    endDate_sort:string;
    RequestedDateTime_sort:string;
    ApprovedOrRejectedOn_sort :string;
    ApproverComment: string;
}

export class FilterParamsApprove {
    RequestedDateFrom: Date | null;
    RequestedDateTo: Date | null;
  }