<div class="appLayout accessError">  
  <p>
    <mat-icon class="errorIcon">error</mat-icon>
  </p>
  <p class="errorText">
    Access Role is not configured.
    <br /> <br/>
    If your country and service line is listed <a href="https://sites.ey.com/sites/MERAGlobal/SitePages/Deployed-Countries-by-Service-Line.aspx" target = "_blank"><u>here</u></a>, please clear browser cache and try again.
    <br/>
    If you still receive the same error message, please contact the IT Helpdesk.
    <br/><br/>
    If you need Resource/Experience Manager access role then contact your Resourcing/Experience Management team.
  </p>
</div>
