<form (ngSubmit)="onEditSubmit()" [formGroup]="formGroup">
  <div class="popupLayout">
    <div class="engSearch">
        <label>Resource Name <span class="mandtoryIcn"> *</span></label>
        <mat-form-field appearance="outline">
          <input type="text" matInput ngif="resourceNameZZ" [(ngModel)]='ResourceName' [readonly]="true" 
            [ngModelOptions]="{standalone:true}" autocomplete="off">
        </mat-form-field>
    </div>
    <div class="customRow">
      <div class="halfWidthItem">
        <label>Service Line</label>
        <mat-form-field appearance="outline">
          <input type="text" matInput [(ngModel)]="SLDescription" [readonly]="true" [ngModelOptions]="{ standalone: true }"
            autocomplete="off" />
        </mat-form-field>
      </div>
      <div class="halfWidthItem">
        <label>HR Department</label>
        <mat-form-field appearance="outline">
          <input type="text" matInput [(ngModel)]="departmentDescription" [readonly]="true"
            [ngModelOptions]="{ standalone: true }" autocomplete="off" />
        </mat-form-field>
      </div>
    </div>
    <div class="customRow">
      <div class="halfWidthItem">
        <label>Rank</label>
        <mat-form-field appearance="outline">
          <input type="text" matInput [(ngModel)]='RankDescription' [readonly]="true" 
            [ngModelOptions]="{standalone:true}" autocomplete="off">
        </mat-form-field>
      </div>
      <div class="halfWidthItem">
        <label>Office Location</label>
        <mat-form-field appearance="outline">
          <input type="text" matInput [(ngModel)]='LocationDescription' [readonly]="true" 
            [ngModelOptions]="{standalone:true}" autocomplete="off">
        </mat-form-field>
      </div>
    </div>
    <div class="periodContainer">
      <div class="estimationContainer">
        <div class="dateSearch">
          <label>Period <span class="mandtoryIcn"> *</span></label>
          <mat-form-field appearance="outline">
  
            <mat-date-range-input formGroupName="period1" [rangePicker]="picker" [min]="startOfWeek">
              <input matStartDate formControlName="start1" placeholder="start date" (focus)="picker.open()"
                (click)="picker.open()" readonly>
              <input matEndDate formControlName="end1" placeholder="end date" (focus)="picker.open()"
                (click)="picker.open()" readonly (dateChange)="makeDirty('Period')">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <mat-error *ngIf="formGroup.get('period1').get('start1').hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="formGroup.get('period1').get('end1').hasError('matEndDateInvalid')">Invalid end date</mat-error>
          </mat-form-field>
          <div *ngIf="formGroup.get('period1').get('start1').value === null && (formGroup.get('period1').get('start1').dirty || formGroup.get('period1').get('start1').touched)" class="alert alert-danger">
            Start date is required
          </div>
          <div *ngIf="formGroup.get('period1').get('end1').value === null && (formGroup.get('period1').get('end1').dirty || formGroup.get('period1').get('end1').touched)" class="alert alert-danger">
            End date is required
          </div>
        </div>
        <div class="estimation">
          <label>{{engagementAllocationText}} <span class="mandtoryIcn"> *</span></label>
          <mat-form-field appearance="outline" [ngClass]="{ 'is-invalid': isValidInput('editLoading') }">
            <input type="text" matInput (input)="makeDirty('Hours')"  autocomplete="off" #tooltip="matTooltip"
            [matTooltip]="engagementAllocationPercentSelected ? '' : 'Note when adding time in hours this will be input for the full-time period of the booking, not per week. For long term bookings Loading% may be the preferred way to input time allocation to work (please see “preference” button in top right hand corner to switch between the time allocation input methods)'"
              triggers="hover click focus" placement="bottom" 
              formControlName="editLoading" appHoursLoading [isPercentageSelected]="isPercentageSelected">
          </mat-form-field>
          <div *ngIf="isValidInput('editLoading')">
            <div *ngIf="formGroup.controls['editLoading'].errors.required" class="alert alert-danger">
              {{engagementAllocationText}} is required.
            </div>
            <div *ngIf="formGroup.controls['editLoading'].errors.pattern" class="alert alert-danger">
              {{engagementAllocationText}} is invalid.
            </div>
          </div>
        </div>
        <div class="confirmContainer" *ngIf="!user?.Unconfirmed">
          <label>Unconfirmed</label>
          <mat-checkbox [(ngModel)]="unconfirmed" [disableRipple]='true' (change)="makeDirty('Unconfirmed')"
            [ngModelOptions]="{standalone:true}"></mat-checkbox>          
  
        </div>
        <div class="confirmContainer" *ngIf="user?.Ghost">
          <label>Ghost</label>
          <mat-checkbox [(ngModel)]="ghost" [disableRipple]='true' (change)="makeDirty()"
            [ngModelOptions]="{standalone:true}"></mat-checkbox>          
  
        </div>
        
      </div>
      <div class="overallocationWarning">
        <div class="allocationText" [class.disabled]="overallocationerror">
          <label *ngIf="!isHoursValid">This Booking request will cause an overallocation. If you wish
            to proceed, please select the check box and click on Submit.</label>
        </div>
        <div class="confirmAllocation" [class.disabled]="overallocationerror">
          <label *ngIf="!isHoursValid">Confirm</label>
          <mat-checkbox [disableRipple]='true' [(ngModel)]="confirmHours" (change)="confirmHoursChange('editLoading')" *ngIf="!isHoursValid" [ngModelOptions]="{standalone:true}"></mat-checkbox>
          
        </div>
      </div>
      
    </div>
    <div>
      <label >Notes Description</label>
      <mat-form-field appearance="outline">
        <input matInput  autocomplete="off" #tooltip="matTooltip"
        matTooltip="Please do not enter any unnecessary personal information or any sensitive personal data (including tax file numbers or other government identifiers), client confidential information, audit secrets, state secrets, commercial secrets, or anything that would violate professional secrecy or confidentiality rules or that would be considered abusive/irrelevant."
          triggers="hover click focus" placement="bottom" type="text" [(ngModel)]='description'
          [ngModelOptions]="{standalone:true}" maxlength="200">
      </mat-form-field>
    </div>
    <div class="SubmitContainer">
        
      <button class="submitButton" type="submit" [disabled]="(!(buttonText == 'Submit'))">
        {{buttonText}}</button>
    </div>
  </div>
  
  <!-- <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="form-group mb-10">
         <div class="form-group mb-10">
          <label class="formLabel">Resource Name</label>
          <input type="text" (change)="makeDirty('ResourceName')" ngif="resourceNameZZ" class="form-control bg-grey" [(ngModel)]='ResourceName' [readonly]="true" [ngModelOptions]="{standalone:true}">
          
        </div>
      </div>
    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="form-group mb-10">
        <div class="form-group mb-10">
          <label class="formLabel">Service Line</label>
          <input type="text" (change)="makeDirty('Service')" class="form-control bg-grey" [(ngModel)]='SLDescription' [readonly]="true" [ngModelOptions]="{standalone:true}">
        </div>
      </div>
    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 pr-0">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
        <div class="form-group mb-10">
          <label class="formLabel">Rank</label>
          <input type="text" (change)="makeDirty('Rank')" class="form-control bg-grey" [(ngModel)]='RankDescription' [readonly]="true" [ngModelOptions]="{standalone:true}">
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
        <div class="form-group mb-10">
          <label class="formLabel">Office Location</label>
          <input type="text" (change)="makeDirty('Office')" class="form-control bg-grey" [(ngModel)]='LocationDescription' [readonly]="true"
                 [ngModelOptions]="{standalone:true}">
        </div>
      </div>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 pr-0">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
        <div class="form-group mb-10">
          <label class="formLabel">Period</label>
          <input type="text" (paste)="false" onkeydown="return false" (ngModelChange)="checkDirty()" placeholder="select start date and end date" class="form-control bg-white" #dp="bsDaterangepicker"
                 bsDaterangepicker [(ngModel)]="bsRangeValue" [bsConfig]="bsConfig"
                 formControlName="editPeriod" [placement]="'top'">
          <span class="glyphicon glyphicon-calendar glyicon" (click)="dp.toggle()" [attr.aria-expanded]="dp.isOpen"
                value="Toggle" style="top:36px"></span>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
        <div class="form-group mb-10">
          <label class="formLabel">{{engagementAllocationText}}</label>
          <input type="text" (change)="makeDirty('Hours')" class="form-control bg-white" [(ngModel)]='engagementAllocationTypeValue' (focus)="mouseEnter() "
          [tooltip]="engagementAllocationPercentSelected ? '' : 'Note when adding time in hours this will be input for the full-time period of the booking, not per week. For long term bookings Loading% may be the preferred way to input time allocation to work (please see “preference” button in top right hand corner to switch between the time allocation input methods)'" 
          triggers="hover click focus" placement="bottom"
                 (focusout)="mouseLeave()" formControlName="editLoading">
        </div>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
        <div class="form-group mb-10">
          <label class="formLabel">Unconfirmed</label>
          <input type="checkbox" (change)="makeDirty('Unconfirmed')" class="check" [(ngModel)]="unconfirmed" [ngModelOptions]="{standalone:true}">
        </div>
      </div>
      <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10" [class.disabled]="overallocationerror">
          <label class="formLabel" *ngIf="!isHoursValid">This Booking request will cause an overallocation. If you wish to proceed, please select the check box and click Save.</label>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 pr-0 pl-0" [class.disabled]="overallocationerror">
          <label class="formLabel" *ngIf="!isHoursValid">Confirm</label>
          <input type="checkbox" class="check" (change)="confirmHoursChange('editLoading')" *ngIf="!isHoursValid" [(ngModel)]="confirmHours" [ngModelOptions]="{standalone:true}">
        </div>              
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="form-group mb-10">
        <label class="formLabel">Notes Description</label>
        <input  tooltip="Please do not enter any unnecessary personal information or any sensitive personal data (including tax file numbers or other government identifiers), client confidential information, audit secrets, state secrets, commercial secrets, or anything that would violate professional secrecy or confidentiality rules or that would be considered abusive/irrelevant."
        triggers="hover click focus" placement="bottom"
        type="text" (change)="makeDirty('Notes')" class="form-control bg-white" [(ngModel)]='description' [ngModelOptions]="{standalone:true}">
      </div>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"> 
      <button class="editBtn" type="submit"  [disabled] = "(!(buttonText == 'save'))||!dirtyFlag"><span class="glyphicon glyphicon-ok-sign pr-5"></span>{{buttonText}}</button>
    </div>
  </div> -->

</form>
