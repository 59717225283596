import { IndividualBookingChildVM } from '../Models/IndividualBookingChildVM';

export class IndividualBookingVM {
      BookingId: number;
      ResourceId: number | null;
      JobId: number | null;
      BookingType: string;
      StartDate: Date | null;
      EndDate: Date | null;
      Hours: number | null;
      Loading: number | null;
      Description: string;
      UpdatedOn: Date | null;
      UpdatedBy: string;
      EngagementCode: string;
      Engagement: string;
      ActionDescriptionId: number;
      MeraActionDescriptionId: number;
      UiViewSource:string;
      ActualBookingId : number | null;
      ParentBookingId : number | null;
           
      MeraBookingStatusId: number;
      Unconfirmed: boolean;
      EngagementType: string;
      Rank: number;
      StartDate_2: Date | null;
      StartDate_3: Date | null;
      StartDate_4: Date | null;
      StartDate_5: Date | null;
      EndDate_2: Date | null;
      EndDate_3: Date | null;
      EndDate_4: Date | null;
      EndDate_5: Date | null;
      Hours_2: number | null;
      Hours_3: number | null;
      Hours_4: number | null;
      Hours_5: number | null;
      Loading_2: number | null;
      Loading_3: number | null;
      Loading_4: number | null;
      Loading_5: number | null;
      Unconfirmed_2: boolean;
      Unconfirmed_3: boolean;
      Unconfirmed_4: boolean;
      Unconfirmed_5: boolean;
      ChildBooking: IndividualBookingChildVM[];
      ChildRowsExist: boolean;
      JobStatus: string;
      ServiceLineCode: string;
      ServiceLineDescription: string;
      currentUserId: number;

      JobCategory: string;
      JobType: string;
      JobCode: string;
      IsPercentSelected: boolean;

      CurrentUserName: string;
      CurrentUserId: number;
      CurrentUserEmail: string;

      IsSplitOneEdit: boolean;

      HighlightStatus : boolean;
      TooltipData : string;
      BsRangeDate: Date[];
      resourceName: string;
      RankDescription: string;
      LocationDescription: string;
      SLDescription : string;
      IsDisabledEditBooking: boolean;
    
    IsDisabledDeleteBooking: boolean;
    IsDisabledReplaceBooking:boolean;
    IsDisabledCopyBooking: boolean;
    IsDisabledEVNavigation: boolean;
    IsDisabledViewBooking_Job: boolean;
    UserSelection : string;
    LoggedInResourceID : number | null;
    RequestMode : number;
    IsCurrentEditWeek : boolean;    
    IsCurrentWeekDelete: boolean;
    
    IsReplacedEngagement : number;
    To_Engagement_Name : string;
    To_Engagement_Code : string;
    To_Client_Description  : string;
    To_Client_Number : string;
    From_Engagement_Name : string;
    From_Engagement_Code : string;
    From_Client_Description: string;
    From_Client_Number : string;
    To_Job_Id : number;
    From_Job_Id : number;

    loggedInRoleDelegate : any;
    loggedInR_CoWorker : any;
    InstanceId: number;
    isExpandable: boolean;
    enabledWeekDay : Date;
    MeraBookingId: number;
    
    Ghost: boolean;
    Ghost_2: boolean;
    Ghost_3: boolean;
    Ghost_4: boolean;
    Ghost_5: boolean;
    IsOverallocation:boolean;
    IsOverallocation_1:boolean;
    IsOverallocation_2:boolean;
    IsOverallocation_3:boolean;
    IsOverallocation_4:boolean;
    IsOverallocation_5:boolean;    
    B_TYPE: string;
    Cancelable: boolean;
    IsExistingBooking: boolean;
    IsExistingBooking_2: boolean;
    IsExistingBooking_3: boolean;
    IsExistingBooking_4: boolean;
    IsExistingBooking_5: boolean;
}

export class DeleteBookingData {
  BookingId: number;
  ResourceId: number;
  JobId: number;
  JobCategory: string;
  BookingType: string;
  Description: string;
  StartDate: string | null;
  EndDate: string | null;
  Hours: number | null;
  Loading: number | null;  
  Unconfirmed: boolean;
  IsPercentSelected: boolean;
  IsCurrentWeekDelete: boolean;
  IsSelected: boolean;
  DateRange: Date[];
  Ghost: boolean;
}