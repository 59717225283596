import { Component, HostListener } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { LoginService } from './landing/login.service';
import { UserVM } from './Models/UserVM';
import { AuthService } from './shared/auth/auth.service';
import { SharedServicesService } from './shared/sharedservices/shared-services.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ApplicationInsightsService } from './shared/appInsights/application-insights.service';
import { environment } from '../environments/environment';
export var MY_DATE_FORMATS = {
  parse: {
    dateInput: '',
  },
  display: {
    dateInput: '',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
import { EncryptedKey } from './shared/EncryptedKey/EncryptedKey';
import { ThemeService } from './shared/theme/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }],
})
export class AppComponent {
  popup = environment.chatBotLink;
  //popup = "https://cbaasgwystg.ey.com/?botid=d442d475-c9e6-4d6f-aa84-8008e5b8ffc0&scope=EYCBInt&hint=ey.com" + "&output=embed";
  showChatBot: boolean = false;
  title = 'app';
  isIframe = false;
  loggedInUser: UserVM;
  leftX: number;
  heightX: number;
  isDesktop: boolean;
  isMenu: boolean = false;
  currentTheme: string = 'light';

  constructor(
    public sharedService: SharedServicesService,
    public themeService: ThemeService,
    public loginService: LoginService,
    private deviceService: DeviceDetectorService,
    public auth: AuthService,
    private ApplicationInsightsService: ApplicationInsightsService
  ) {}

  ngOnInit(): void {
    this.isDesktop = this.deviceService.isDesktop();
    this.isIframe = window !== window.parent && !window.opener;
    this.loginService.isMenuShown.subscribe((data) => (this.isMenu = data));
    this.loginService.loggedUser.subscribe((data) => {
      this.loggedInUser = JSON.parse(sessionStorage.getItem('resource'));
      if (this.loggedInUser) {
        this.themeService.sendData(this.loggedInUser.DefaultTheme);
        let format =
          !this.loggedInUser.DefaultDateFormat ||
          this.loggedInUser.DefaultDateFormat == 'System date format'
            ? this.sharedService.getLocalDateFormat()
            : this.loggedInUser.DefaultDateFormat;
        MY_DATE_FORMATS.display.dateInput = format.toLocaleUpperCase();
      }
    });

    this.themeService.currentTheme.subscribe((data) => {
      this.currentTheme = data;
      if (data === 'light') this.themeService.setLightTheme();
      else this.themeService.setDarkTheme();
    });
  }
  openChatBot() {
    this.leftX = window.innerWidth - 200;
    this.heightX = window.innerHeight;
    var windowFeatures = `popup,left=${this.leftX},top=20,width=320,height=${this.heightX}`;
    window.open(this.popup, 'MERA Bot', windowFeatures);
  }
  setHeight(){
    let heightDiv =  window.innerHeight;
    heightDiv = heightDiv - 100;
    let styles = {
      height: heightDiv + 'px'
    };
    return styles;
  }
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.setHeight();
  }
  @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  resetTimer() {
    this.auth.notifyUserAction();
  }
}
