export class IndividualWallChartVM {
  BookingId: number;
  JobId: number;
  MeraBookingId: number;
  RequestId: number;
  ActionDescriptionId: number;
  BookingType: string;
  EngagementName: string;
  InstanceName: string;
  ActionDescription: string;
  StatusDescription: string;
  StartDateChanged: boolean;
  EndDateChanged: boolean;
  OrgStartDate: Date;
  OrgEndDate: Date;
  NewStartDate: Date;
  NewEndDate: Date;
  NewStartDateWeekNumber: number;
  NewEndDateWeekNumber: number;
  Hours: number;
  ColorCode: string;
  Loading: number;
  FromResourceName: string;
  ToResourceName: string;
  IsReplaced: number;
  JobCode: string;
  ClientDescription: string;
  TimeZone: string;
  Description : string;
  ResourceId: number | null;
  
  IsReplacedEngagement : number;
  To_Engagement_Name : string;
  To_Engagement_Code : string;
  To_Client_Description  : string;
  To_Client_Number : string;
  From_Engagement_Name : string;
  From_Engagement_Code : string;
  From_Client_Description: string;
  From_Client_Number : string;
  To_Job_Id : number;
  From_Job_Id : number;
  StatusId:number;
  InstanceId:number;
}

export class ResourceUtilizationRequestVM {
  ResourceIds: number[];
  StartDate: Date;
  EndDate: Date;
  InstanceId:number;
}

export class ResourceUtilizationVM {
  ResourceId: number;
  GPN: string;
  DiaryHours: number;
  DiaryDate: Date;
}

