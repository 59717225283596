import { MasterVM } from "./MasterVM";

export class ResourceDeleteConfigurationDto {
       ResDeleteSubAreas :MasterVM[];
       ResDeleteManagerialCountries :MasterVM[];
       ResDeleteLegalEntities:MasterVM[];
       ResDeleteServiceLines :MasterVM[];
       ResDeleteBusinessUnits:MasterVM[];
       ResDeleteSubServiceLines :MasterVM[];
       ResDeleteSubManageUnits :MasterVM[]
       ResDeleteRanks :MasterVM[];
       RRDC_RInactive:boolean;
       RRDC_ISDisabled :boolean;
       RRDC_ISLikeEdit :boolean;
       RRDC_IV_Disabled :boolean;
       RRDC_EV_Disabled :boolean;
}

export class JobDeleteConfigurationDto {
       JobDeleteSubAreas :MasterVM[];
       JobDeleteManagerialCountries :MasterVM[];
       JobDeleteLegalEntities:MasterVM[];
       JobDeleteServiceLines :MasterVM[];
       JobDeleteBusinessUnits:MasterVM[];
       JobDeleteSubServiceLines :MasterVM[];
       JobDeleteSubManageUnits :MasterVM[]
       JobDeleteRanks :MasterVM[];
       JobDeleteJobCategories:MasterVM[];
       JobDeleteJobStatuses :MasterVM[];
       JobDeleteJobStatusBookings :MasterVM[]
       JobDeleteJobCode :MasterVM[];
       JobDeleteIncludeJobCode
       RJDC_Learning :boolean;
       RJDC_ISDisabled :boolean;
       RJDC_ISLikeEdit:boolean;
       RJDC_IsPended:boolean;
       RJDC_IV_Disabled:boolean;
       RJDC_EV_Disabled:boolean;

}