import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { Observable, Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedServicesService } from '../../../shared/sharedservices/shared-services.service';
import {
  FormControl,
  FormGroup,
  NgForm,
  NgModel,
  Validators,
} from '@angular/forms';
import { ResourceDto } from '../../../Models/ResourceDto';
import { ScheduledViewService } from '../scheduledView.service';

import { OrderPipe } from 'ngx-order-pipe';
import { DatePipe } from '@angular/common';
import { JobVM } from '../../../Models/JobVM';
import {
  ScheduleIndividualDto,
  ScheduleIndividualBookingVM,
  IndividualDairyVm,
} from '../../../Models/ScheduleIndividualVM';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { favEngImageSrc, UiViewSourceEnum } from 'app/shared/enum';
import moment from 'moment';
import { MatTable } from '@angular/material/table';
import { bookingUpdateData } from './individualScheduleSavedata';
import { ThrottlingUtils } from '@azure/msal-common';
import { element } from 'protractor';
import {
  FavouriteJobsAddActionDto,
  FavouriteJobsDto,
  FavouriteJobsTableTypeDto,
} from 'app/Models/FavouriteJobsDto';
import { DARK_PATH, LIGHT_PATH } from 'app/app-constants';
import { ThemeService } from 'app/shared/theme/theme.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { style } from '@angular/animations';

@Component({
  selector: 'app-individualScheduleView',
  templateUrl: './individualScheduleView.component.html',
  styleUrls: ['./individualScheduleView.component.scss'],
})
export class individualScheduleViewComponent implements OnInit {
  displayedColumns: string[] = [
    'Actions',
    'Engagement Name',
    'Engagement Category',
    // 'Retain Instance',
  ];
  addDisplayedColumns: string[] = [
    'addActions',
    'addEngagement Name',
    'addEngagement Category',
    // 'addRetain Instance',
  ];
  weeksTobeDisplayed: string[] = [];
  availabilityColumns = [
    { displayName: 'HourCalculation', displayHours: '', displayPercentage: '' },
    {
      displayName: 'Availability Hours',
      displayHours: 'Availability Hours',
      displayPercentage: 'Availability Percentage %',
    },
  ];
  displayAvailabilityHeader = [];
  totalHoursColumns = [
    {
      displayName: 'Total Scheduled Hours',
      displayHours: 'Total Scheduled Hours',
      displayPercentage: 'Total Scheduled Percentage %',
    },
  ];
  displayTotalHourHeader = [];
  availabilityHours: any[];
  totalHours: any[];
  dataSource: any;
  today = new Date();
  isMobileDevice: boolean = false;
  todaysDate = new Date(
    this.today.getFullYear(),
    this.today.getMonth(),
    this.today.getDate(),
    0,
    0,
    0
  );
  tempdate = this.sharedService.getFirstDateOfWeek(this.todaysDate);
  user: any;
  hourCalculation: any;
  defaultDateFormat: string;
  MaxWeekStartDate: Date;
  range = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null),
  });
  startWith = this.sharedService.getFirstDateOfWeek(this.todaysDate);
  resource: ResourceDto;
  resourceSelected: boolean;
  isPercentageSelected: boolean = false;
  isSubmitEnabled: boolean = true;
  engagementAllocationModal = '';
  searchBar: string;
  searchAddEngagement: string;
  //searchCopyEng: string;
  sortedResourceList: ResourceDto[];
  gridSelectedUser: string;
  selectedInstance = 1;
  addBookingInstance = 1;
  addBookingResourceID: number;
  addBookingInstanceName: string;
  copyBookingInstance = 1;
  copyBookingResourceID: number;
  copyBookingInstanceName: string;
  instances;
  addInstances;
  currentUserSubscription: Subscription;
  instanceSubscription: Subscription;
  selectedResource: ResourceDto = new ResourceDto();
  resourceId: number;
  showSearchList: boolean = false;
  showSearchLoader: boolean = false;
  showEngList: boolean = false;
  showEngLoader: boolean = false;
  showEngCopyLoader: boolean = false;
  newEngagement: JobVM;
  addNewEngagementList: JobVM[];
  sortedEngagementList: any[];
  addEngResults;
  copyEngResults;
  showCopyList: boolean = false;
  showCopyLoader: boolean = false;
  copyEngagement: JobVM;
  copyNewEngagementList: JobVM[];
  sortedCopyEngagementList: any[];
  results;
  resourceListSubscription: Subscription;
  searchEngagementSubscription: Subscription;
  searchCopyEngagementSubscription: Subscription;
  photoSubscription: Subscription;
  bookingListSubscription: Subscription;
  scheduleIndividualData: ScheduleIndividualDto;
  booking: ScheduleIndividualBookingVM;
  engagaementList: any[] = [];
  cancelRequestArray: any[] = [];
  dairyHoursDataList: IndividualDairyVm[];
  modalRef: BsModalRef;
  modalOption: ModalOptions = {};
  resourceParam = {
    ResourceId: 0,
    InstanceId: 0,
    StartDate: null,
    EndDate: null,
    JobId: -1,
  };
  modelData = {
    header: '',
    text: '',
    isConfirmRequired: true,
    cancelText: 'Cancel',
    yesText: 'Ok',
    action: '',
  };
  @ViewChild('userConfirmation') userConfirmation: TemplateRef<any>;
  @ViewChild('replaceResourceForm') replaceResourceForm: NgForm;
  @ViewChild('replaceResourceControl') replaceResourceControl: NgModel;
  @ViewChild('replaceEngForm') replaceEngForm: NgForm;
  @ViewChild('replaceEngControl') replaceEngControl: NgModel;
  invalidValues: any[] = [];
  editableColumn: string;
  editableIndex: number;
  editableBookingMode: string;
  isConfirmedChecked: boolean = true;
  isUnconfirmedChecked: boolean = true;
  isGhostChecked: boolean = true;
  isCollapsed: boolean = true;
  searchLabel: string = 'Individual Forecast';
  isNotes: boolean = false;
  noteAvailableFor: any;
  isMultiInstance: boolean = false;
  isGhost: boolean = false;
  isUnconfirmed = false;
  selectedEngagementItem: any;
  newConfirmedBooking = {
    id: '',
    BookingId: 0,
    ResourceId: this.resourceParam.ResourceId,
    BookingType: null,
    InstanceId: 1,
    JobId: null,
    IsOverallocation: false,
    ActionDescriptionId: 1,
    MeraActionDescriptionId: 1,
    ActualBookingId: 0,
    ParentBookingId: 0,
    ActionMode: 'E',
    BookingHours: null,
    Loading: null,
    BookingMode: 'Confirmed',
    Ghost: false,
    Unconfirmed: false,
    WeekStartDate: null,
    StartDate: null,
    WeekEndDate: null,
    Description: ' ',
    ColorCode: '#c4c4cd',
    StatusId: 1,
    isNewBooking: true,
    IsDisabledEditBooking: false,
    IsDisabledCopyBooking: false,
    IsDisabledDeleteBooking: false,
    IsDisabledReplaceBooking: false,
    RequestorEmail: '',
    MeraBookingId: 1,
    isNotValidValue: false,
    IsEdit: false,
  };
  newUnconfirmedBooking = {
    id: '',
    BookingId: 0,
    ResourceId: this.resourceParam.ResourceId,
    BookingType: null,
    InstanceId: 1,
    JobId: null,
    IsOverallocation: false,
    ActionDescriptionId: 1,
    MeraActionDescriptionId: 1,
    ActualBookingId: 0,
    ParentBookingId: 0,
    ActionMode: 'E',
    BookingHours: null,
    Loading: null,
    BookingMode: 'Unconfirmed',
    Ghost: false,
    Unconfirmed: true,
    WeekStartDate: null,
    StartDate: null,
    WeekEndDate: null,
    Description: '',
    ColorCode: '#c4c4cd',
    StatusId: 1,
    isNewBooking: true,
    IsDisabledCopyBooking: false,
    IsDisabledDeleteBooking: false,
    IsDisabledReplaceBooking: false,
    RequestorEmail: '',
    MeraBookingId: 1,
    isNotValidValue: false,
    IsEdit: false,
  };
  newGhostBooking = {
    id: '',
    BookingId: 0,
    ResourceId: this.resourceParam.ResourceId,
    BookingType: null,
    InstanceId: 1,
    JobId: null,
    IsOverallocation: false,
    ActionDescriptionId: 1,
    MeraActionDescriptionId: 1,
    ActualBookingId: 0,
    ParentBookingId: 0,
    ActionMode: 'E',
    BookingHours: null,
    Loading: null,
    BookingMode: 'Ghost',
    Ghost: true,
    Unconfirmed: false,
    WeekStartDate: null,
    StartDate: null,
    WeekEndDate: null,
    Description: '',
    ColorCode: '#c4c4cd',
    StatusId: 1,
    isNewBooking: true,
    IsDisabledCopyBooking: false,
    IsDisabledDeleteBooking: false,
    IsDisabledReplaceBooking: false,
    RequestorEmail: '',
    MeraBookingId: 1,
    isNotValidValue: false,
    IsEdit: false,
  };
  addedEngagement: JobVM;
  copiedEngagement: JobVM;
  isAtleastOneBooking = {
    confirm: false,
    unconfirm: false,
    ghost: false,
  };
  indFromEngagement: any;
  instanceFromEng = 0;
  resourceIdFromEng: number;
  @ViewChild(MatTable) table: MatTable<any>;
  updatedBookingDetails = new bookingUpdateData();
  rowDeleteEngagement;
  availabilityReportLink: string;
  bookedTimeReportLink: string;
  isCurrentWeekDisabled: boolean = false;
  isCurrentWeekEditDisabled: boolean = false;
  isCurrentWeekDeleteDisabled: boolean = false;
  isCurrentWeekCopyDisabled: boolean = false;
  isCurrentWeekReplaceDisabled: boolean = false;
  saveErrorMessage: string = '';
  saveSucessMessage: string = '';
  isPeriodErrorMsg: boolean = false;
  showReplaceResLoader: boolean = false;
  replaceResResults;
  replaceResource: ResourceDto;
  searchReplaceResSubscription: Subscription;
  sortedReplaceResList: any[];
  replacedResource: any;
  replaceEngResults;
  showReplaceEngLoader: boolean = false;
  replaceEngagement: JobVM;
  searchReplaceEngSubscription: Subscription;
  sortedReplaceEngList: any[];
  replacedEngagement: any;
  beforeReplace: any;
  showFavouriteEngList: boolean = false;
  addFavouriteEngResults: any[];
  imagePath: string = '';
  cspReports: [];
  availabilityReport: any;
  bookedReport: any;
  strFunction: string = '';
  lbl: string;
  isVerifyOnEngClick: boolean = false;
  isFavEngONClick: boolean = true;

  FavoriteImageClick: boolean = false;
  outClickCount: number = 0;
  compareFavouriteEngResults: any[] = [];
  isClickonFavEngStart: boolean = false;
  myFormGroup: FormGroup;
  IsDataNotFound: boolean = false;

  // for Copy
  copyshowFavouriteEngList: boolean = false;
  copyaddFavouriteEngResults: any[];
  copyisFavEngONClick: boolean = true;
  IsCopyDataNotFound: boolean = false;

  // for Replace
  //replaceshowFavouriteEngList: boolean = false;
  replaceaddFavouriteEngResults: any[];
  replaceisFavEngONClick: boolean = true;
  IsRepalceDataNotFound: boolean = false;

  favEngReplaceIndex: number;
  fixedSectionHeight: number;
  landscape = window.matchMedia('(orientation: landscape)');
  constructor(
    private spinner: NgxSpinnerService,
    private scheduledService: ScheduledViewService,
    private sharedService: SharedServicesService,
    private orderPipe: OrderPipe,
    private modalService: BsModalService,
    public datepipe: DatePipe,
    private themeService: ThemeService,
    public decimalPipe: DecimalPipe,
    private router: Router,
    private renderer: Renderer2,
    private snackBar: MatSnackBar
  ) {
    this.sharedService.activeLink = 'IndividualScheduleView';
    this.indFromEngagement =
      this.router.getCurrentNavigation().extras.state?.individual;
    this.instanceFromEng =
      this.router.getCurrentNavigation().extras.state?.instanceId;
    this.resourceIdFromEng =
      this.router.getCurrentNavigation().extras.state?.resourceId;
    this.selectedInstance = this.instanceFromEng > 0 ? this.instanceFromEng : 1;

    document.addEventListener('click', this.favoriteimgClickHandler.bind(this)); // bind on document
    this.landscape.addEventListener('change', (ev) => {
      if (!this.landscape.matches) {
        this.snackBar.open(
          'Please rotate your device. This page supports landscape mode only',
          '',
          {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          }
        );
      }
    });
  }

  ngOnInit(): void {
    this.sharedService.activeLink = 'IndividualScheduleView';
    const activeTheme = this.themeService.getActiveTheme();
    this.imagePath = activeTheme.name === 'light' ? LIGHT_PATH : DARK_PATH;

    this.isMobileDevice =
      JSON.parse(sessionStorage.getItem('requestMode')) === 1 ? true : false;
    this.user = JSON.parse(sessionStorage.getItem('resource'));
    this.hourCalculation = this.scheduledService.getHourMode();
    this.isConfirmedChecked =
      this.hourCalculation === null
        ? true
        : this.hourCalculation.isConfirmedChecked;
    this.isUnconfirmed = !this.user.Unconfirmed; // this.user.Unconfirmed = (1-Disable and 0-Enable)
    if (!this.isUnconfirmed) {
      this.isUnconfirmedChecked = false; //Flag used to show checkbox in Hour calculation in grid (inclue or exclude Unconfirm bookings)
    } else {
      this.isUnconfirmedChecked =
        this.hourCalculation === null
          ? true
          : this.hourCalculation.isUnconfirmedChecked;
    }
    this.isGhost = this.user.Ghost;
    if (!this.isGhost) {
      this.isGhostChecked = false; //Flag used to show checkbox in Hour calculation in grid (inclue or exclude Ghost bookings)
    } else {
      this.isGhostChecked =
        this.hourCalculation === null
          ? true
          : this.hourCalculation.isGhostChecked;
    }
    this.isMultiInstance = this.user.IsMultiInstance;
    this.defaultDateFormat = this.sharedService.getDateFormat(
      this.user.DefaultDateFormat
    );
    // this.tempdate.setMinutes(
    //   this.tempdate.getMinutes() - this.tempdate.getTimezoneOffset()
    // );
    this.tempdate = new Date(
      this.tempdate.getFullYear(),
      this.tempdate.getMonth(),
      this.tempdate.getDate(),
      0,
      0,
      0
    );
    this.sharedService.getDefaultPeriodSettings(this.user);

    this.range.setValue({
      start: this.sharedService.defaultStartDate ? moment(this.sharedService.defaultStartDate) : null,
      end: this.sharedService.defaultEndDate ? moment(this.sharedService.defaultEndDate) : null,
    });
    if (
      this.sharedService.defaultStartDate !== null &&
      this.sharedService.defaultEndDate !== null
    ) {
      this.resourceParam.StartDate = this.sharedService.getFirstDateOfWeek(
        this.sharedService.defaultStartDate
      );
      this.resourceParam.EndDate = this.sharedService.getFirstDateOfWeek(
        this.sharedService.defaultEndDate
      );
      if (
        moment(this.resourceParam.StartDate.toDateString()).isSame(
          this.resourceParam.EndDate.toDateString()
        )
      ) {
        this.resourceParam.EndDate.setDate(
          this.resourceParam.EndDate.getDate() + 6
        );
      }
    }
    let group = {};
    this.myFormGroup = new FormGroup(group);
    this.startWith.setMinutes(this.startWith.getMinutes() - this.startWith.getTimezoneOffset()); //min date for calender (saturday previous to current date)
    this.resourceSelected = false;
    this.resource = new ResourceDto();
    this.isPercentageSelected = this.user.PercentSelected;
    this.engagementAllocationModal =
      this.user.PercentSelected === true ? 'percentage' : 'hour';
    this.searchBar = null;
    this.sortedResourceList = [];
    this.scheduledService.engagementAllocationOnChange(
      this.isPercentageSelected
    );
    if (this.gridSelectedUser !== null && this.gridSelectedUser !== undefined) {
      this.resourceSelected = true;
    }

    this.cspReports = this.user.RoleFeatures.filter(
      (x) =>
        x.FeatureName == 'AvailabilityReportCSP' ||
        x.FeatureName == 'BookedTimeReportCSP'
    );

    this.getUserInstances();
    this.handleWindowClick();
    this.handleEngagementScroll();
  }

  addDatestoTableView(startingDate, endingDate) {
    //For adding week columns in table.
    this.displayedColumns = [
      //row with week dates
      'Actions',
      'Engagement Name',
      'Engagement Category'
    ];
    this.addDisplayedColumns = [
      //row with add functionality
      'addActions',
      'addEngagement Name',
      'addEngagement Category'
    ];
    this.availabilityColumns = [
      //for row to display Availablity hours
      {
        displayName: 'HourCalculation',
        displayHours: '',
        displayPercentage: '',
      },
      {
        displayName: 'Availability Hours',
        displayHours: 'Availability Hours',
        displayPercentage: 'Availability Percentage %',
      }
    ];
    this.totalHoursColumns = [
      //for row to display Total Schedule hours
      {
        displayName: 'Total Scheduled Hours',
        displayHours: 'Total Scheduled Hours',
        displayPercentage: 'Total Scheduled Percentage %',
      }
    ];

    this.totalHours = []; //Array used to save weekly total hours and corrsponding diary hours. Not cleared on any filters, recalculates the hours
    this.availabilityHours = []; //Array used to save weekly avilablity hours and corrsponding diary hours. Not cleared on any filters, recalculates the hours
    this.weeksTobeDisplayed = []; //Array to weeks which are displayed in grid
    do {
      this.displayedColumns.push(
        this.datepipe.transform(startingDate, this.defaultDateFormat)
      );
      this.addDisplayedColumns.push(
        this.datepipe.transform(startingDate, this.defaultDateFormat) + '-add'
      );
      this.totalHours[
        this.datepipe.transform(startingDate, this.defaultDateFormat)
      ] = { hour: 0, percentage: 0, diaryHour: 0 };
      this.availabilityHours[
        this.datepipe.transform(startingDate, this.defaultDateFormat)
      ] = { hour: 0, percentage: 0, diaryHour: 0 };

      this.weeksTobeDisplayed.push(startingDate.toDateString());
      startingDate.setDate(startingDate.getDate() + 7);
    } while (
      endingDate &&
      moment(startingDate.toDateString()).isSameOrBefore(
        new Date(endingDate).toDateString()
      )
    );
    console.log('columns', this.displayedColumns);
  }

  @HostListener('window:beforeunload')
  // insert logic to check if there are pending changes here;
  // returning true will navigate without confirmation
  // returning false will show a confirm dialog before navigating away
  canDeactivate(): Observable<boolean> | boolean {
    if (
      this.updatedBookingDetails.fetchSaveDataList()?.length > 0 ||
      this.cancelRequestArray.length > 0
    ) {
      return false;
    } else {
      return true;
    }
  }

  canDeactivateOnFilter() {
    if (
      confirm(
        'WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.'
      )
    ) {
      this.engagaementList = [];
      this.editableColumn = null;
      this.editableIndex = null;
      this.updatedBookingDetails.clearSaveDataList();
      this.cancelRequestArray = [];
      this.addedEngagement = null;
      this.addEngResults = [];
      this.searchAddEngagement = '';
      this.copiedEngagement = null;
      this.copyEngResults = [];
      this.myFormGroup?.controls?.copyEngName?.setValue('');
      this.invalidValues = [];
      this.engagaementList = JSON.parse(
        sessionStorage.getItem('ScheduledIndividual')
      );
      this.formatData();
      this.saveErrorMessage = '';
      this.saveSucessMessage = '';
      return true;
    } else {
      return false;
    }
  }

  getUserInstances() {
    this.spinner.show();
    this.instanceSubscription = this.sharedService
      .getUserInstances()
      .subscribe((data) => {
        this.instances = this.sharedService.getStoredUserInstances();
        if (this.indFromEngagement?.length > 0) {
          //Navigated from Engagement view resource link click
          this.searchBar = this.indFromEngagement;
          this.selectedInstance = this.instanceFromEng;
          this.resource.ResourceName = this.indFromEngagement;
          this.resource.ResourceId = this.resourceIdFromEng;
          this.resource.InstanceId = this.selectedInstance;
          this.resourceParam.InstanceId = this.selectedInstance;
          this.resource.LoggedInResourceID = +sessionStorage.getItem(
            'loggedin_resourceid'
          );
          // this.applicationInsightsService.logEvent('Individual View - getResourceList');
          this.resourceListSubscription = this.scheduledService
            .getResourceList(this.resource)
            .subscribe((data) => {
              this.sortedResourceList = this.orderPipe.transform(
                data,
                'ResourceName'
              );
              if (this.sortedResourceList.length === 1) {
                this.OnItemSelect(this.sortedResourceList[0]);
              }
            });
        } else {
          const selected = this.instances.filter(
            (x) => x.InstanceId === this.user.DefaultInstance
          );
          if (selected.length > 0) {
            this.selectedInstance = selected[0].InstanceId;
            this.checkCurrentUserInResourceList(); //searches for loggedin user
          }
        }
      });
  }
  checkCurrentUserInResourceList() {
    //searches for loggedin user
    this.resource = new ResourceDto();
    this.selectedInstance = this.user.DefaultInstance;
    this.resource.InstanceId = this.user.DefaultInstance;
    this.resource.ResourceEmail = this.user.Email;
    this.resource.ResourceName = this.user.ResourceName;
    this.resource.ResourceId = this.user.DefaultInstanceResourceId;
    this.currentUserSubscription = this.scheduledService
      .fetchResourceDetail(this.resource)
      .subscribe((data) => {
        this.resource = data;
        this.selectedResource = this.resource;
        this.isCurrentWeekDisabled = data?.IsDisabledAddBookingCurrWeek;
        this.isCurrentWeekEditDisabled = data?.IsDisabledEditBookingCurrWeek;
        this.isCurrentWeekDeleteDisabled =
          data?.IsDisabledDeleteBookingCurrWeek;
        this.isCurrentWeekCopyDisabled = data?.IsDisabledCopyBookingCurrWeek;
        this.isCurrentWeekReplaceDisabled =
          data?.IsDisabledReplaceBookingCurrWeek;
        if (
          this.resource.ResourceId !== null &&
          this.resource.ResourceId !== undefined
        ) {
          this.resourceId = this.resource.ResourceId;
          if (
            this.resource.ResourceName !== null &&
            this.resource.ResourceName !== undefined
          ) {
            this.gridSelectedUser = this.resource.ResourceName.toString();
            if (
              this.gridSelectedUser !== null &&
              this.gridSelectedUser !== undefined
            ) {
              this.resourceSelected = true;
            }
          }
          this.resourceParam.ResourceId = this.resource.ResourceId;
          this.resourceParam.InstanceId = this.selectedInstance;
          this.getUserResourceInstances(); //To get Retain instances where the loggedin user can add a booking
          this.getBookingList(this.resourceParam);
        }
      });
  }
  onInstanceChange(event) {
    if (
      this.updatedBookingDetails.fetchSaveDataList()?.length > 0 ||
      this.cancelRequestArray.length > 0
    ) {
      if (!this.canDeactivateOnFilter()) {
        return false;
      }
    }
    this.selectedInstance = event.value;
    this.sharedService
      .getUserResourceInstances(this.selectedResource)
      .subscribe((data) => {
        const instanceOfUser = data;
        const selected = instanceOfUser.filter(
          (x) => x.InstanceId === this.selectedInstance
        );
        if (selected.length !== 0) {
          this.selectedResource.ResourceId = selected[0].ResourceId;
          this.resourceParam.InstanceId = this.selectedInstance;
          this.resourceParam.ResourceId = this.selectedResource.ResourceId;
          this.spinner.show();
          this.getBookingList(this.resourceParam);
        } else {
          this.resetfilterBookingGridSearchBar();
        }
      });
  }
  getUserResourceInstances() {
    this.sharedService
      .getUserResourceInstances(this.selectedResource)
      .subscribe((data) => {
        this.addInstances = data;
        const selected = this.addInstances.filter(
          (x) => x.InstanceId === this.selectedInstance
        );
        if (!this.user.R_InstEnable_AddBooking) {
          this.addBookingInstance = this.user.R_Home_InstanceID;
          this.addBookingResourceID = this.addInstances.filter(
            (x) => x.InstanceId === this.user.R_Home_InstanceID
          )[0].ResourceId;
          this.addBookingInstanceName = this.addInstances.filter(
            (x) => x.InstanceId === this.user.R_Home_InstanceID
          )[0].InstanceName;
          this.copyBookingInstance = this.user.R_Home_InstanceID;
          this.copyBookingResourceID = this.addInstances.filter(
            (x) => x.InstanceId === this.user.R_Home_InstanceID
          )[0].ResourceId;
          this.copyBookingInstanceName = this.addInstances.filter(
            (x) => x.InstanceId === this.user.R_Home_InstanceID
          )[0].InstanceName;
        } else if (selected) {
          this.addBookingInstance = selected[0].InstanceId;
          this.addBookingResourceID = selected[0].ResourceId;
          this.addBookingInstanceName = selected[0].InstanceName;
          this.copyBookingInstance = selected[0].InstanceId;
          this.copyBookingResourceID = selected[0].ResourceId;
          this.copyBookingInstanceName = selected[0].InstanceName;
        }
      });
  }

  onAddBookingInstanceChange(event) {
    if (this.validateSave()) {
      return false;
    }
    this.addBookingInstance = event.value;

    this.sharedService
      .getUserResourceInstances(this.selectedResource)
      .subscribe((data) => {
        const instanceOfUser = data;
        const selected = instanceOfUser.filter(
          (x) => x.InstanceId === this.addBookingInstance
        );
        if (selected.length !== 0) {
          this.addBookingResourceID = selected[0].ResourceId;
          this.addBookingInstanceName = selected[0].InstanceName;
        }
      });
    this.onEngagementClear();
  }
  isRowEmpty() {
    if (this.resourceSelected && this.selectedResource.IsDisabledAddBooking) {
      if (!this.isUnconfirmed && !this.isGhost) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
  isAddEnabled() {
    if (this.validateSave()) {
      return false;
    }
  }

  onCopyBookingInstanceChange(event) {
    this.copyBookingInstance = event.value;
    this.addBookingInstance = event.value;
    this.sharedService
      .getUserResourceInstances(this.selectedResource)
      .subscribe((data) => {
        const instanceOfUser = data;
        const selected = instanceOfUser.filter(
          (x) => x.InstanceId === this.copyBookingInstance
        );
        if (selected.length !== 0) {
          this.copyBookingResourceID = selected[0].ResourceId;
        }
      });
    this.onEngagementClear();
  }

  resetfilterBookingGridCalender(event) {
    if (
      this.updatedBookingDetails.fetchSaveDataList()?.length > 0 ||
      this.cancelRequestArray.length > 0
    ) {
      if (!this.canDeactivateOnFilter()) {
        return false;
      }
    }
    event.stopPropagation();
    event.preventDefault();
    this.range.setValue({
      start: null,
      end: null,
    });
    this.resourceParam.StartDate = null;
    this.resourceParam.EndDate = null;
    // sessionStorage.setItem('PeriodFilterStDate', this.resourceParam.StartDate);
    // sessionStorage.setItem('PeriodFilterEndDate', this.resourceParam.EndDate);
    // this.sharedService.getDefaultPeriodSettings(this.user);
    this.spinner.show();
    this.getBookingList(this.resourceParam);
    if (this.gridSelectedUser == null) {
      this.resourceSelected = false;
    }
  }
  resetfilterBookingGridSearchBar() {
    if (
      this.updatedBookingDetails.fetchSaveDataList()?.length > 0 ||
      this.cancelRequestArray.length > 0
    ) {
      if (!this.canDeactivateOnFilter()) {
        return false;
      }
    }
    this.showSearchList = false;
    this.searchBar = '';
    this.results = [];
    this.spinner.show();
    this.engagaementList = [];
    this.checkCurrentUserInResourceList();
    if (this.gridSelectedUser == null) {
      this.resourceSelected = false;
    }
  }

  engagementAllocationOnChange(event) {
    this.isPercentageSelected =
      this.engagementAllocationModal === 'hour' ? false : true;
    this.scheduledService.engagementAllocationOnChange(
      this.isPercentageSelected
    );
  }

  onBookingStartDateChange(event: any) {
    this.resourceParam.StartDate = this.range.get('start').value;
    this.resourceParam.EndDate = null;
  }

  onBookingEndDateChange(event: any) {
    if (
      this.updatedBookingDetails.fetchSaveDataList()?.length > 0 ||
      this.cancelRequestArray.length > 0
    ) {
      if (!this.canDeactivateOnFilter()) {
        return false;
      }
    }
    this.resourceParam.EndDate = this.range.get('end').value;

    if (event.value != null) {
      if (this.resourceParam.EndDate != null) {
        this.resourceParam.StartDate = moment(this.resourceParam.StartDate).format("YYYY-MM-DDT00:00:00.000");
        this.resourceParam.EndDate = moment(this.resourceParam.EndDate).format("YYYY-MM-DDT00:00:00.000");
        this.resourceParam.StartDate = this.sharedService.getFirstDateOfWeek(this.resourceParam.StartDate);
        this.resourceParam.EndDate = this.sharedService.getFirstDateOfWeek(
          this.resourceParam.EndDate
        );
        if (
          moment(this.resourceParam.StartDate.toDateString()).isSame(
            this.resourceParam.EndDate.toDateString()
          )
        ) {
          this.resourceParam.EndDate.setDate(
            this.resourceParam.EndDate.getDate() + 6
          );
        }
        this.spinner.show();
        this.getBookingList(this.resourceParam);
      }
    }
  }

  OnClosedDatePicker() {
    if (
      this.resourceParam.StartDate != null &&
      this.resourceParam.EndDate == null
    ) {
      this.isPeriodErrorMsg = true;
      setTimeout(() => {
        this.isPeriodErrorMsg = false;
        this.resourceParam.StartDate = null;
        this.resourceParam.EndDate = null;
        this.range.setValue({
          start: null,
          end: null,
        });
      }, 3000);
    }
  }

  search() {
    if (
      this.updatedBookingDetails.fetchSaveDataList()?.length > 0 ||
      this.cancelRequestArray.length > 0
    ) {
      if (!this.canDeactivateOnFilter()) {
        return false;
      }
    }
    if (this.searchBar != null) {
      if (this.searchBar.trim().length >= 2) {
        this.showSearchList = true;
        this.showSearchLoader = true;
        this.resource.ResourceName = this.searchBar;
        this.resource.ResourceId = 0; //+sessionStorage.getItem('resourceId');
        this.resource.InstanceId = this.selectedInstance;
        this.resource.LoggedInResourceID = +sessionStorage.getItem(
          'loggedin_resourceid'
        );
        this.resourceListSubscription = this.scheduledService
          .getResourceList(this.resource)
          .subscribe((data) => {
            this.sortedResourceList = this.orderPipe.transform(
              data,
              'ResourceName'
            );
            if (this.searchBar.length > 0) {
              this.results = this.sortedResourceList;
              this.showSearchLoader = false;
            } else {
              this.results = [];
              this.showSearchList = false;
            }
          });
      } else {
        this.results = [];
        this.showSearchList = false;
      }
    } else {
      this.results = [];
      this.showSearchList = false;
    }
  }

  OnItemSelect(event) {
    if (this.FavoriteImageClick === true) {
      this.FavoriteImageClick = false;
      (event as Event).stopPropagation();
    }
    this.showSearchList = false;
    this.showFavouriteEngList = false;
    this.resourceId = event.ResourceId;
    this.gridSelectedUser = event.ResourceName;
    this.selectedResource = event;
    this.searchBar = event.ResourceName;
    this.resourceParam.ResourceId = this.selectedResource.ResourceId;
    this.spinner.show();
    this.getUserResourceInstances();
    this.getBookingList(this.resourceParam);
    this.resourceSelected = !event.IsDisabledAddBooking;
    this.isCurrentWeekDisabled = event.IsDisabledAddBookingCurrWeek;
    this.isCurrentWeekEditDisabled = event.IsDisabledEditBookingCurrWeek;
    this.isCurrentWeekDeleteDisabled = event.IsDisabledDeleteBookingCurrWeek;
    this.isCurrentWeekCopyDisabled = event.IsDisabledCopyBookingCurrWeek;
    this.isCurrentWeekReplaceDisabled = event.IsDisabledReplaceBookingCurrWeek;
    if (this.selectedResource.ResourceEmail == this.user.Email) {
      this.photoSubscription = this.scheduledService
        .getProfilePhoto(this.selectedResource.ResourceEmail)
        .subscribe((photoUrl) => {
          this.selectedResource.ResourcePhotoUrl = photoUrl;
        });
    }
  }
  saveInSession() {
    const params = {
      isConfirmedChecked: this.isConfirmedChecked,
      isUnconfirmedChecked: this.isUnconfirmedChecked,
      isGhostChecked: this.isGhostChecked,
    };
    this.scheduledService.setHourMode(params);
    // sessionStorage.setItem('hourCalculation', JSON.stringify(params));
  }
  getRecalculatedHours(colName) {
    //On CRUD operations calculate hour based on week
    this.totalHours[colName].hour = 0;
    this.totalHours[colName].percentage = 0;
    this.availabilityHours[colName].hour = 0;
    this.availabilityHours[colName].percentage = 0;
    if (this.engagaementList?.length > 0) {
      this.engagaementList.forEach((item) => {
        if (this.isConfirmedChecked) {
          item[colName].Confirmed.forEach((x) => {
            if (x.ActionMode === 'E') {
              this.totalHours[colName].hour =
                this.totalHours[colName].hour +
                (isNaN(Number(x.BookingHours)) ? 0 : Number(x.BookingHours));
            }
          });
        }
        if (this.isUnconfirmedChecked) {
          item[colName].Unconfirmed.forEach((x) => {
            if (x.ActionMode === 'E') {
              this.totalHours[colName].hour =
                this.totalHours[colName].hour +
                (isNaN(Number(x.BookingHours)) ? 0 : Number(x.BookingHours));
            }
          });
        }
        if (this.isGhostChecked) {
          item[colName].Ghost.forEach((x) => {
            if (x.ActionMode === 'E') {
              this.totalHours[colName].hour =
                this.totalHours[colName].hour +
                (isNaN(Number(x.BookingHours)) ? 0 : Number(x.BookingHours));
            }
          });
        }
      });
    }

    this.totalHours[colName].percentage =
      (this.totalHours[colName].hour / this.totalHours[colName].diaryHour) *
      100;
    if (
      this.totalHours[colName].percentage === Infinity ||
      this.totalHours[colName].percentage === -Infinity
    ) {
      this.totalHours[colName].percentage = 0;
    }
    this.availabilityHours[colName].hour =
      this.totalHours[colName].diaryHour - this.totalHours[colName].hour;
    this.availabilityHours[colName].percentage =
      ((this.totalHours[colName].diaryHour - this.totalHours[colName].hour) /
        this.totalHours[colName].diaryHour) *
      100;
    if (
      this.availabilityHours[colName].percentage === Infinity ||
      this.availabilityHours[colName].percentage === -Infinity
    ) {
      this.availabilityHours[colName].percentage = 0;
    }
    this.getActualHours();
  }
  calculateHours() {
    //Initial hour calculation on API call for fetching booking list
    this.weeksTobeDisplayed.forEach((week) => {
      this.totalHours[this.datepipe.transform(week, this.defaultDateFormat)] = {
        hour: 0,
        percentage: 0,
        diaryHour: 0,
      };
      this.availabilityHours[
        this.datepipe.transform(week, this.defaultDateFormat)
      ] = { hour: 0, percentage: 0, diaryHour: 0 };
      if (this.engagaementList?.length > 0) {
        this.engagaementList.forEach((item) => {
          if (this.isConfirmedChecked) {
            item[
              this.datepipe.transform(week, this.defaultDateFormat)
            ].Confirmed.forEach((x) => {
              if (x.ActionMode === 'E') {
                this.totalHours[
                  this.datepipe.transform(week, this.defaultDateFormat)
                ].hour =
                  this.totalHours[
                    this.datepipe.transform(week, this.defaultDateFormat)
                  ].hour +
                  (isNaN(Number(x.BookingHours)) ? 0 : Number(x.BookingHours));
              }
            });
          }
          if (this.isUnconfirmedChecked) {
            item[
              this.datepipe.transform(week, this.defaultDateFormat)
            ].Unconfirmed.forEach((x) => {
              if (x.ActionMode === 'E') {
                this.totalHours[
                  this.datepipe.transform(week, this.defaultDateFormat)
                ].hour =
                  this.totalHours[
                    this.datepipe.transform(week, this.defaultDateFormat)
                  ].hour +
                  (isNaN(Number(x.BookingHours)) ? 0 : Number(x.BookingHours));
              }
            });
          }
          if (this.isGhostChecked) {
            item[
              this.datepipe.transform(week, this.defaultDateFormat)
            ].Ghost.forEach((x) => {
              if (x.ActionMode === 'E') {
                this.totalHours[
                  this.datepipe.transform(week, this.defaultDateFormat)
                ].hour =
                  this.totalHours[
                    this.datepipe.transform(week, this.defaultDateFormat)
                  ].hour +
                  (isNaN(Number(x.BookingHours)) ? 0 : Number(x.BookingHours));
              }
            });
          }
        });
      }
      if (this.dairyHoursDataList?.length > 0) {
        const selectedDiary = this.dairyHoursDataList.filter((eachWeek) => {
          return (
            new Date(eachWeek.FiscalWeekStartDate).toDateString() === week &&
            eachWeek.InstanceID === this.selectedInstance
          );
        });
        if (selectedDiary?.length > 0) {
          this.totalHours[
            this.datepipe.transform(week, this.defaultDateFormat)
          ].diaryHour = selectedDiary[0].DiaryHours;
          this.availabilityHours[
            this.datepipe.transform(week, this.defaultDateFormat)
          ].diaryHour = selectedDiary[0].DiaryHours;
          const Diary =
            this.totalHours[
              this.datepipe.transform(week, this.defaultDateFormat)
            ].diaryHour;
          this.availabilityHours[
            this.datepipe.transform(week, this.defaultDateFormat)
          ].hour =
            Diary -
            this.totalHours[
              this.datepipe.transform(week, this.defaultDateFormat)
            ].hour;
          this.totalHours[
            this.datepipe.transform(week, this.defaultDateFormat)
          ].percentage = isNaN(
            (this.totalHours[
              this.datepipe.transform(week, this.defaultDateFormat)
            ].hour /
              Diary) *
            100
          )
              ? 0
              : (this.totalHours[
                this.datepipe.transform(week, this.defaultDateFormat)
              ].hour /
                Diary) *
              100;
          if (
            this.totalHours[
              this.datepipe.transform(week, this.defaultDateFormat)
            ].percentage === Infinity ||
            this.totalHours[
              this.datepipe.transform(week, this.defaultDateFormat)
            ].percentage === -Infinity
          ) {
            this.totalHours[
              this.datepipe.transform(week, this.defaultDateFormat)
            ].percentage = 0;
          }
          this.availabilityHours[
            this.datepipe.transform(week, this.defaultDateFormat)
          ].percentage = isNaN(
            ((Diary -
              this.totalHours[
                this.datepipe.transform(week, this.defaultDateFormat)
              ].hour) /
              Diary) *
            100
          )
              ? 0
              : ((Diary -
                this.totalHours[
                  this.datepipe.transform(week, this.defaultDateFormat)
                ].hour) /
                Diary) *
              100;
          if (
            this.availabilityHours[
              this.datepipe.transform(week, this.defaultDateFormat)
            ].percentage === Infinity ||
            this.availabilityHours[
              this.datepipe.transform(week, this.defaultDateFormat)
            ].percentage === -Infinity
          ) {
            this.availabilityHours[
              this.datepipe.transform(week, this.defaultDateFormat)
            ].percentage = 0;
          }
        }
      }
    });
    this.getActualHours();
  }
  getBookingList(resourceParam) {
    //API call for fetching booking list

    this.engagaementList = [];
    this.dataSource = null;
    if (resourceParam.StartDate !== null && resourceParam.EndDate !== null) {
      resourceParam.StartDate = moment(resourceParam.StartDate).format("YYYY-MM-DDT00:00:00.000");
      resourceParam.EndDate = moment(resourceParam.EndDate).format("YYYY-MM-DDT00:00:00.000");
    }

    this.bookingListSubscription = this.scheduledService
      .getEngagementList(resourceParam)
      .subscribe((data) => {
        if (data) {
          console.log('engList', data);
          this.scheduleIndividualData = data;
          this.dairyHoursDataList = data.DiaryHoursDtos;
          this.MaxWeekStartDate = new Date(data.MaxWeekStartDate);
          if (data.ScheduleBookingDto === null) {
            this.engagaementList = [];
          } else {
            this.engagaementList = data.ScheduleBookingDto;
          }
          sessionStorage.setItem(
            'ScheduledIndividual',
            JSON.stringify(this.engagaementList)
          );
          this.MaxWeekStartDate = this.sharedService.getFirstDateOfWeek(
            this.MaxWeekStartDate
          );
          this.tempdate = this.sharedService.getFirstDateOfWeek(
            this.todaysDate
          );
          // this.tempdate.setMinutes(
          //   this.tempdate.getMinutes() - this.tempdate.getTimezoneOffset()
          // ); // Previous saturday for the current day
          this.tempdate = new Date(
            this.tempdate.getFullYear(),
            this.tempdate.getMonth(),
            this.tempdate.getDate(),
            0,
            0,
            0
          );
          let checkdate = this.sharedService.getFirstDateOfWeek(
            this.todaysDate
          );
          checkdate.setDate(checkdate.getDate() + 51 * 7);
          checkdate = new Date(
            checkdate.getFullYear(),
            checkdate.getMonth(),
            checkdate.getDate(),
            0,
            0,
            0
          );

          if (
            this.resourceParam.StartDate !== null &&
            this.resourceParam.EndDate !== null
          ) {
            let start = JSON.parse(
              JSON.stringify(this.resourceParam.StartDate)
            );
            this.addDatestoTableView(
              new Date(start),
              this.resourceParam.EndDate
            );
          } else {
            let start = JSON.parse(JSON.stringify(this.tempdate));

            if (new Date(this.MaxWeekStartDate) > new Date(checkdate)) {
              //checking whether booking are there beyond one year
              this.addDatestoTableView(
                new Date(start),
                new Date(this.MaxWeekStartDate) //adds week columns upto the forward booking
              );
            } else {
              this.addDatestoTableView(new Date(start), checkdate); //adds week columns upto the next year from today.
            }
          }
          this.formatData();
          this.spinner.hide();
          this.toggleCollapse();
          if (!this.landscape.matches) {
            this.snackBar.open(
              'Please rotate your device. This page supports landscape mode only',
              '',
              {
                duration: 5000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              }
            );
          }
          setTimeout(() => {
            this.saveErrorMessage = '';
            this.saveSucessMessage = '';
          }, 5000);
        }
      });
  }
  formatData() {
    if (this.engagaementList?.length > 0) {
      //Filtering to find engagemnts which have bookings to show in cells.
      if (this.isUnconfirmed && this.isGhost) {
        // All booking modes needed
        this.engagaementList.filter((eachEng) => {
          const isbooking = eachEng.WeeklyBookings.filter((job) => {
            return job.ActionMode === 'E';
          });
          if (isbooking.length > 0) {
            eachEng.toBeRemoved = false;
          } else {
            eachEng.toBeRemoved = true;
          }
        });
      } else if (this.isUnconfirmed && !this.isGhost) {
        //Booking with Confirmed and Unconfirmed
        this.engagaementList.filter((eachEng) => {
          const isbooking = eachEng.WeeklyBookings.filter((job) => {
            return (
              job.ActionMode === 'E' &&
              (job.BookingMode === 'Confirmed' ||
                job.BookingMode === 'Unconfirmed')
            );
          });
          if (isbooking.length > 0) {
            eachEng.toBeRemoved = false;
          } else {
            eachEng.toBeRemoved = true;
          }
        });
      } else if (!this.isUnconfirmed && this.isGhost) {
        //Booking with Confirmed and Ghost
        this.engagaementList.filter((eachEng) => {
          const isbooking = eachEng.WeeklyBookings.filter((job) => {
            return (
              job.ActionMode === 'E' &&
              (job.BookingMode === 'Confirmed' || job.BookingMode === 'Ghost')
            );
          });
          if (isbooking.length > 0) {
            eachEng.toBeRemoved = false;
          } else {
            eachEng.toBeRemoved = true;
          }
        });
      } else if (!this.isUnconfirmed && !this.isGhost) {
        //Booking with Confirmed only
        this.engagaementList.filter((eachEng) => {
          const isbooking = eachEng.WeeklyBookings.filter((job) => {
            return job.ActionMode === 'E' && job.BookingMode === 'Confirmed';
          });
          if (isbooking.length > 0) {
            eachEng.toBeRemoved = false;
          } else {
            eachEng.toBeRemoved = true;
          }
        });
      }
      this.engagaementList.forEach((item, index) => {
        item.isRowDeleted = false;
        item.addEng = false;
        const newEng = item.WeeklyBookings.filter((x) => {
          return (
            (x.MeraActionDescriptionId === 1 ||
              x.MeraActionDescriptionId === 3) &&
            (x.StatusId === 1 || x.StatusId === 10) &&
            x.RequestorEmail.toLowerCase() === this.user.Email.toLowerCase()
          );
        });
        if (newEng.length === item.WeeklyBookings.length) {
          item.addEng = true;
        } else {
          item.addEng = false;
        }
        const deletedEng = item.WeeklyBookings.filter((x) => {
          return (
            x.MeraActionDescriptionId === 4 &&
            (x.StatusId === 1 || x.StatusId === 10) &&
            x.RequestorEmail.toLowerCase() === this.user.Email.toLowerCase()
          );
        });
        if (deletedEng.length === item.WeeklyBookings.length) {
          item.isRowDeleted = true;
          item.IsDisabledCopyBooking = true;
          item.IsDisabledDeleteBooking = true;
          item.IsDisabledReplaceBooking = true;
        } else {
          item.isRowDeleted = false;
        }
        const replacedEng = item.WeeklyBookings.filter((x) => {
          return (
            (x.MeraActionDescriptionId === 5 ||
              x.MeraActionDescriptionId === 6) &&
            (x.StatusId === 1 || x.StatusId === 10) &&
            x.RequestorEmail.toLowerCase() === this.user.Email.toLowerCase()
          );
        });
        if (replacedEng.length === item.WeeklyBookings.length) {
          item.Replaced = true;
          item.IsDisabledCopyBooking = true;
          item.IsDisabledDeleteBooking = true;
          item.IsDisabledReplaceBooking = true;
        } else {
          item.Replaced = false;
        }
        this.weeksTobeDisplayed.forEach((week) => {
          const weekBookings = item.WeeklyBookings.filter((x) => {
            return new Date(x.WeekStartDate).toDateString() === week;
          });
          let isConfirmedPresent = false;
          let isUnconfirmedPresent = false;
          let isGhostPresent = false;
          this.newConfirmedBooking.ResourceId = item.ResourceId;
          this.newUnconfirmedBooking.ResourceId = item.ResourceId;
          this.newGhostBooking.ResourceId = item.ResourceId;
          this.newConfirmedBooking.RequestorEmail = this.user.Email;
          this.newUnconfirmedBooking.RequestorEmail = this.user.Email;
          this.newGhostBooking.RequestorEmail = this.user.Email;
          this.newConfirmedBooking.JobId = item.JobId;
          this.newUnconfirmedBooking.JobId = item.JobId;
          this.newGhostBooking.JobId = item.JobId;
          this.newConfirmedBooking.BookingType = item.BookingType;
          this.newUnconfirmedBooking.BookingType = item.BookingType;
          this.newGhostBooking.BookingType = item.BookingType;
          this.newConfirmedBooking.InstanceId = item.InstanceID;
          this.newUnconfirmedBooking.InstanceId = item.InstanceID;
          this.newGhostBooking.InstanceId = item.InstanceID;
          this.newConfirmedBooking.id =
            item.JobId +
            item.InstanceID +
            week +
            this.newConfirmedBooking.BookingMode;
          this.newUnconfirmedBooking.id =
            item.JobId +
            item.InstanceID +
            week +
            this.newUnconfirmedBooking.BookingMode;
          this.newGhostBooking.id =
            item.JobId +
            item.InstanceID +
            week +
            this.newGhostBooking.BookingMode;
          let newStartDate = new Date(week);
          let newEndDate = new Date(week);
          newEndDate.setDate(newEndDate.getDate() + 6);
          // newStartDate.setMinutes(
          //   newStartDate.getMinutes() - newStartDate.getTimezoneOffset()
          // );
          newStartDate = new Date(
            newStartDate.getFullYear(),
            newStartDate.getMonth(),
            newStartDate.getDate(),
            0,
            0,
            0
          );

          // newEndDate.setMinutes(
          //   newEndDate.getMinutes() - newEndDate.getTimezoneOffset()
          // );
          newEndDate = new Date(
            newEndDate.getFullYear(),
            newEndDate.getMonth(),
            newEndDate.getDate(),
            0,
            0,
            0
          );

          // this.newConfirmedBooking.StartDate = newStartDate.toISOString();
          this.newConfirmedBooking.StartDate = moment(newStartDate).format("YYYY-MM-DDT00:00:00.000")
          this.newConfirmedBooking.WeekEndDate = moment(newEndDate).format("YYYY-MM-DDT00:00:00.000");
          this.newConfirmedBooking.WeekStartDate = moment(newStartDate).format("YYYY-MM-DDT00:00:00.000");
          this.newUnconfirmedBooking.StartDate = moment(newStartDate).format("YYYY-MM-DDT00:00:00.000");
          this.newUnconfirmedBooking.WeekEndDate = moment(newEndDate).format("YYYY-MM-DDT00:00:00.000");
          this.newUnconfirmedBooking.WeekStartDate = moment(newStartDate).format("YYYY-MM-DDT00:00:00.000");
          this.newGhostBooking.StartDate = moment(newStartDate).format("YYYY-MM-DDT00:00:00.000");
          this.newGhostBooking.WeekEndDate = moment(newEndDate).format("YYYY-MM-DDT00:00:00.000");
          this.newGhostBooking.WeekStartDate = moment(newStartDate).format("YYYY-MM-DDT00:00:00.000");
          if (weekBookings?.length > 0) {
            weekBookings.forEach((x) => {
              x.ResourceId = item.ResourceId;
              x.JobId = item.JobId;
              x.BookingType = item.BookingType;
              x.InstanceId = item.InstanceID;
              x.IsOverallocation = false;
              // x.BookingHours = this.decimalPipe.transform(
              //   x.BookingHours,
              //   '1.0-2'
              // );
              // x.Loading = this.decimalPipe.transform(x.Loading, '1.0-2');
              x.IsEdit = false;
              x.Notes = x.Description;
              x.beforeEditHours = x.BookingHours;
              x.beforeEditPercentage = x.Loading;
              x.beforeNotes = x.Description;
              x.isNewBooking = false;
              x.isNotValidValue = false;
              x.id = item.JobId + item.InstanceID + week + x.BookingMode;
              //Bookings with Delete Request - Need to show null value in UI.
              if (x.MeraActionDescriptionId === 4) {
                x.BookingHours = null;
                x.Loading = null;
              }
              if (x.BookingMode === 'Confirmed' && x.ActionMode === 'E') {
                isConfirmedPresent = true;
              } else if (
                x.BookingMode === 'Unconfirmed' &&
                x.ActionMode === 'E'
              ) {
                isUnconfirmedPresent = true;
              } else if (x.BookingMode === 'Ghost' && x.ActionMode === 'E') {
                isGhostPresent = true;
              }
            });

            if (!isConfirmedPresent) {
              weekBookings.push(
                JSON.parse(JSON.stringify(this.newConfirmedBooking))
              );
            }
            if (!isUnconfirmedPresent) {
              weekBookings.push(
                JSON.parse(JSON.stringify(this.newUnconfirmedBooking))
              );
            }
            if (!isGhostPresent) {
              weekBookings.push(
                JSON.parse(JSON.stringify(this.newGhostBooking))
              );
            }
            //item[this.datepipe.transform(week, this.defaultDateFormat)].push(weekBookings.filter(eachItem => eachItem.BookingMode === 'Unconfirmed'));
            item[this.datepipe.transform(week, this.defaultDateFormat)] = {
              Confirmed: [],
              Unconfirmed: [],
              Ghost: [],
            };
            item[
              this.datepipe.transform(week, this.defaultDateFormat)
            ].Confirmed = weekBookings.filter(
              (eachItem) => eachItem.BookingMode === 'Confirmed'
            );
            item[
              this.datepipe.transform(week, this.defaultDateFormat)
            ].Unconfirmed = weekBookings.filter(
              (eachItem) => eachItem.BookingMode === 'Unconfirmed'
            );
            item[this.datepipe.transform(week, this.defaultDateFormat)].Ghost =
              weekBookings.filter(
                (eachItem) => eachItem.BookingMode === 'Ghost'
              );
          } else {
            //item[this.datepipe.transform(week, this.defaultDateFormat)].push([JSON.parse(JSON.stringify(this.newConfirmedBooking))]);
            item[this.datepipe.transform(week, this.defaultDateFormat)] = {
              Confirmed: [],
              Unconfirmed: [],
              ghost: [],
            };
            item[
              this.datepipe.transform(week, this.defaultDateFormat)
            ].Confirmed = [
                JSON.parse(JSON.stringify(this.newConfirmedBooking)),
              ];
            item[
              this.datepipe.transform(week, this.defaultDateFormat)
            ].Unconfirmed = [
                JSON.parse(JSON.stringify(this.newUnconfirmedBooking)),
              ];
            item[this.datepipe.transform(week, this.defaultDateFormat)].Ghost =
              [JSON.parse(JSON.stringify(this.newGhostBooking))];
          }
        });
      });
      this.engagaementList = this.engagaementList.filter((eachEng) => {
        return eachEng.toBeRemoved === false;
      });

      // console.log(this.engagaementList);
    }
    this.calculateHours();
    this.dataSource = this.engagaementList;
  }
  getActualHours() {
    this.availabilityColumns = [
      {
        displayName: 'HourCalculation',
        displayHours: '',
        displayPercentage: '',
      },
      {
        displayName: 'Availability Hours',
        displayHours: 'Availability Hours',
        displayPercentage: 'Availability Percentage %',
      },
    ];
    this.totalHoursColumns = [
      {
        displayName: 'Total Scheduled Hours',
        displayHours: 'Total Scheduled Hours',
        displayPercentage: 'Total Scheduled Percentage %',
      },
    ];

    this.displayAvailabilityHeader = [];
    this.displayTotalHourHeader = [];
    for (let item in this.availabilityHours) {
      this.availabilityColumns.push({
        displayName: item + '-available',
        displayHours: (
          Math.round(this.availabilityHours[item].hour * 100) / 100
        ).toString(),
        displayPercentage: (
          Math.round(this.availabilityHours[item].percentage * 100) / 100
        ).toString(),
      });
    }
    this.displayAvailabilityHeader = this.availabilityColumns.map(
      (col) => col.displayName
    );
    for (let item in this.totalHours) {
      this.totalHoursColumns.push({
        displayName: item + '-total',
        displayHours: (
          Math.round(this.totalHours[item].hour * 100) / 100
        ).toString(),
        displayPercentage: (
          Math.round(this.totalHours[item].percentage * 100) / 100
        ).toString(),
      });
    }
    this.displayTotalHourHeader = this.totalHoursColumns.map(
      (col) => col.displayName
    );
  }
  isSticky(column) {
    if (
      column === 'Engagement Name' ||
      column === 'Actions' ||
      column === 'Engagement Category'
    ) {
      return true;
    } else {
      return false;
    }
  }
  isStickyAddColumn(column) {
    if (
      column === 'addEngagement Name' ||
      column === 'addActions' ||
      column === 'addEngagement Category'
    ) {
      return true;
    } else {
      return false;
    }
  }
  isAvailabiltyHeaderSticky(column) {
    if (column === 'Availability Hours' || column === 'HourCalculation') {
      return true;
    } else {
      return false;
    }
  }
  isTotalHeaderSticky(column) {
    if (column === 'Total Scheduled Hours') {
      return true;
    } else {
      return false;
    }
  }
  onClickEnable(index, colName, element) {
    console.log(element);
    this.editableColumn = colName;
    this.editableIndex = index;
    this.editableBookingMode = element.BookingMode;
  }
  showValue(index, colName, element) {
    element.IsEdit = false;
    return (
      !element.isNotValidValue &&
      (this.editableColumn !== colName ||
        this.editableIndex !== index ||
        this.editableBookingMode !== element.BookingMode)
    );
  }
  editValue(index, colName, element) {
    element.IsEdit = true;
    return (
      this.editableColumn === colName &&
      this.editableIndex === index &&
      this.editableBookingMode === element.BookingMode &&
      (!element.IsDisabledEditBooking || !element.IsDisabledDeleteBooking)
    );
  }
  getHeaderColor(value) {
    let color = '';
    if (parseInt(value) < 0) {
      color = 'red';
    }
    let styles = {
      borderTop: `3px solid`,
      borderTopColor: color,
    };
    return styles;
  }

  getBorderColor(element, booking) {
    let styles = {
      borderTop: '3px solid',
      borderTopColor: 'transparent'
    };
    if (booking.BookingHours != null || booking.MeraActionDescriptionId === 4) {
      if (
        booking.MeraBookingId === 1 &&
        booking.MeraActionDescriptionId !== 0 &&
        (booking.StatusId === 1 || booking.StatusId === 10)
      ) {
        return {
          ...styles,
          borderTopColor: '#c4c4cd'
        };
      } else if (
        booking.MeraBookingId === 0 &&
        booking.MeraActionDescriptionId === 0 &&
        booking.isCancelRequested === true
      ) {
        return {
          ...styles,
          borderTopColor: booking.ColorCodeOld
        };
      } else if (
        booking.MeraBookingId === 0 &&
        booking.MeraActionDescriptionId === 0 &&
        booking.isCancelRequested === false
      ) {
        return {
          ...styles,
          borderTopColor:
            booking.BookingHoursOld === booking.beforeEditHours
              ? booking.ColorCodeOld
              : booking.ColorCode,
        };
      } else {
        return {
          ...styles,
          borderTopColor: booking.ColorCode
        };
      }
    }
    return styles;
  }
  getColumnSpan(displayName) {
    if (displayName === 'HourCalculation') {
      return '2';
    } else {
      return '1';
    }
  }

  showCancelIcon(booking) {
    if (booking.MeraBookingId > 0 && this.user.Email?.toLowerCase() === booking.RequestorEmail?.toLowerCase() &&
      (booking.StatusId === 1 || booking.StatusId === 10) && (booking.MeraActionDescriptionId !== 5)) {
      if (booking.isNewBooking) {
        if (booking.BookingHours !== null) {
          return true;
        } else {
          return false;
        }
      } else {
        if (booking.MeraActionDescriptionId !== 6) {
          return true;
        } else {
          return false;
        }

      }
    } else {
      return false;
    }
  }

  cellCancel(booking, colName, element) {
    // event.stopPropagation();
    element.isRowDeleted = false;
    //new booking on cancel
    if (booking.isNewBooking) {
      let value = '';
      booking.MeraBookingId = 0;
      booking.MeraActionDescriptionId = 0;
      booking.isChanged = true;
      this.onFocusOutEvent(value, colName, booking, element);
    }
    //Normal booings edited and on cancel
    else if (!booking.isNewBooking && booking.MeraBookingId === 1) {
      let value = booking.beforeEditHours;
      booking.revertAction = true;
      booking.MeraBookingId = 0;
      booking.MeraActionDescriptionId = 0;
      if (value === null) {
        value = '';
      }
      booking.isChanged = true;
      this.onFocusOutEvent(value, colName, booking, element);
      this.getBorderColor(element, booking);
    }
    //Merabboking cancel
    else {
      if (this.showCancelIcon(booking)) {
        //Old retain vlaue restored
        let value = this.isPercentageSelected
          ? booking.LoadingOld
          : booking.BookingHoursOld;
        if (value === null) {
          value = '';
        }
        if (
          booking.isCancelRequested === undefined ||
          booking.isCancelRequested === false
        ) {
          this.cancelRequestArray.push(booking.MeraBookingId);
          const isMergeBookingExists = element.WeeklyBookings.filter(
            (currBook) =>
              currBook.WeekStartDate === booking.WeekStartDate &&
              currBook.ActionMode === 'D' && currBook.BookingMode === booking.BookingMode
          );
          if (isMergeBookingExists && isMergeBookingExists.length > 0) {
            isMergeBookingExists.forEach((eachBook) => {
              this.cancelRequestArray.push(eachBook.MeraBookingId);
            });
          }
          booking.isCancelRequested = true;
          booking.revertAction = true;
          booking.MeraBookingId = 0;
          booking.MeraActionDescriptionId = 0;
          booking.beforeEditHours = booking.BookingHoursOld;
          booking.beforeEditPercentage = booking.LoadingOld;
          booking.beforeNotes = booking.DescriptionOld;
          this.getBorderColor(element, booking);
        } else {
          booking.isCancelRequested = false;
        }
        booking.isChanged = true;
        this.onFocusOutEvent(value, colName, booking, element);
      }
    }
  }
  onCellClick(element) {
    this.weeksTobeDisplayed.forEach((week) => {
      const weekly = this.datepipe.transform(week, this.defaultDateFormat);
      element[weekly].Confirmed.forEach((item) => {
        if (item.ActionMode === 'E') {
          this.iscellClickable(element,item);
        }
      });
      element[weekly].Unconfirmed.forEach((item) => {
        if (item.ActionMode === 'E') {
          this.iscellClickable(element,item);
        }
      });
      element[weekly].Ghost.forEach((item) => {
        if (item.ActionMode === 'E') {
          this.iscellClickable(element,item);
        }
      });
    });
  }
  iscellClickable(element,item) {
    if (
      !this.isCurrentWeekDisabled ||
      !(
        this.datepipe.transform(this.tempdate, this.defaultDateFormat) ===
        this.datepipe.transform(item.WeekStartDate, this.defaultDateFormat)
      )
    ) {
      if (
        (!(this.selectedResource.IsDisabledAddBooking || element.IsDisabledAddEngagement_EV_Job) && item.isNewBooking) ||
        (!item.isNewBooking &&
          (!item.IsDisabledEditBooking || !item.IsDisabledDeleteBooking))
      ) {
        item.IsEdit = true;
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  checkForValidation(element, booking) {
    if (this.validateSave()) {
      if (booking.BookingHours === '0' || booking.Loading === '0') {
        booking.IsEdit = true;
      } else {
        booking.IsEdit = false;
      }
    }
  }

  checkIfNumbers(data, booking) {
    if (this.isPercentageSelected) booking.Loading = data;

    if (
      !this.isPercentageSelected &&
      booking.BookingHours === booking.beforeEditHours
    ) {
      if (
        booking.isCancelRequested === false &&
        booking.revertAction === false
      ) {
        booking.isChanged = true;
      } else {
        booking.isChanged = false;
      }
    } else if (
      this.isPercentageSelected &&
      booking.Loading === booking.beforeEditPercentage
    ) {
      if (
        booking.isCancelRequested === false &&
        booking.revertAction === false
      ) {
        booking.isChanged = true;
      } else {
        booking.isChanged = false;
      }
    } else {
      booking.isChanged = true;
    }
    if (
      this.isPercentageSelected &&
      (data === '' || (data > 0 && data < 1000))
    ) {
      booking.isNotValidValue = false;
    } else if (
      !this.isPercentageSelected &&
      (data === '' || (data > 0 && data < 10000))
    ) {
      booking.isNotValidValue = false;
    } else {
      booking.isNotValidValue = true;
    }
  }
  onFocusOutEvent(event, colName, booking, element) {
    if (!booking.isChanged) {
      return false;
    }
    let value = event;
    if (booking.isNotValidValue) {
      this.invalidValues.push(booking);
      return false;
    } else {
      this.invalidValues = this.invalidValues.filter((item) => {
        return item.id !== booking.id;
      });
    }
    let diary;
    if (this.dairyHoursDataList?.length > 0) {
      const selectedDiary = this.dairyHoursDataList.filter((eachWeek) => {
        let weekneeded = new Date(eachWeek.FiscalWeekStartDate).toDateString();
        weekneeded = this.datepipe.transform(
          weekneeded,
          this.defaultDateFormat
        );
        return (
          weekneeded === colName && eachWeek.InstanceID === this.selectedInstance
        );
      });
      diary = selectedDiary[0]?.DiaryHours;
    }

    //const diary = this.totalHours[colName].diaryHour;
    if (value === '') {
      if (booking.IsDisabledDeleteBooking || booking.IsDisabledDeleteBooking_EV) {
        if (
          booking.isCancelRequested === true &&
          booking.MeraActionDescriptionId === 1 &&
          booking.BookingHoursOld === null
        ) {
          booking.BookingHours = null;
          booking.Loading = null;
        } else {
          booking.BookingHours = booking.beforeEditHours;
          booking.Loading = booking.beforeEditPercentage;
          booking.Description = booking.beforeNotes;
          return false;
        }
      } else {
        value = null;
        booking.Loading = value;
        booking.BookingHours = value;
        if (!booking.isNewBooking) {
          if (booking.rowReplaced === true) {
            booking.MeraActionDescriptionId = 5;
          } else {
            booking.isDelete = true;
            booking.MeraActionDescriptionId = 4;
          }

        } else {
          booking.isDelete = false;
        }
        booking.isEdited = false;
      }
    } else {
      if (booking.IsDisabledEditBooking || booking.IsDisabledEditBooking_EV) {
        booking.BookingHours = booking.beforeEditHours;
        booking.Loading = booking.beforeEditPercentage;
        booking.Description = booking.beforeNotes;
        return false;
      } else {
        booking.isDelete = false;
        booking.isEdited = true;
        if (booking.isNewBooking) {
          booking.MeraActionDescriptionId = 1;
        } else {
          if (booking.rowReplaced === true) {
            booking.isEdited = false;
            booking.MeraActionDescriptionId = 5;
          } else {
            booking.MeraActionDescriptionId = 2;
          }
        }

        if (this.isPercentageSelected) {
          booking.Loading = Number(value);
          booking.BookingHours = (Number(value) * diary) / 100;
        } else {
          booking.BookingHours = Number(value);
          booking.Loading = (Number(value) / diary) * 100;
          if (booking.Loading === Infinity || booking.Loading === -Infinity) {
            booking.Loading = 0;
          }
        }
        if (booking.BookingHours.toString().includes('.')) {
          booking.BookingHours = booking.BookingHours.toFixed(2);
        }
        if (booking.Loading.toString().includes('.')) {
          booking.Loading = booking.Loading.toFixed(2);
        }

        // booking.BookingHours = this.decimalPipe.transform(booking.BookingHours, '1.0-2');
        // booking.Loading = this.decimalPipe.transform(booking.Loading, '1.0-2');
      }
    }
    this.getRecalculatedHours(colName);
    if (booking.revertAction === true) {
      booking.StatusId = 0;
      booking.MeraBookingId = 0;
      booking.MeraActionDescriptionId = 0;
    } else {
      //Making a booking MeraBooking
      booking.RequestorEmail = this.user.Email;
      booking.StatusId = 1;
      if (booking.MeraBookingId < 1) {
        booking.MeraBookingId = 1;
      }
      booking.revertAction = false;
      booking.isCancelRequested = false;
    }

    if (this.availabilityHours[colName].hour < 0) {
      booking.IsOverallocation = true;
    }

    if (!booking.isNewBooking) {
      if (booking.IsSplitBooking) {
        this.updatedBookingDetails.checkSplitBooking(element, booking);
      } else {
        this.updatedBookingDetails.bookingsToSave(booking);
      }
      booking.IsMergedBooking &&
        this.updatedBookingDetails.checkMergeBooking(element, booking, diary);
    } else {
      this.updatedBookingDetails.bookingsToSave(booking);
    }
    booking.isChanged = false;
  }

  openDeleteConfirm(element, index) {
    if (this.validateSave()) {
      return false;
    }
    this.rowDeleteEngagement = index;
    this.modelData.header = 'Delete Bookings';
    this.modelData.text = `Are you sure you want to delete all the bookings of ${element.EngagementName}`;
    this.modelData.isConfirmRequired = true;
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.modalService.show(this.userConfirmation, this.modalOption);
  }

  okayConfirm() {
    let deletedEng = this.engagaementList[this.rowDeleteEngagement];
    deletedEng.OldIsDisabledCopyBooking = deletedEng.IsDisabledCopyBooking;
    deletedEng.OldIsDisabledDeleteBooking = deletedEng.IsDisabledDeleteBooking;
    deletedEng.OldIsDisabledReplaceBooking =
      deletedEng.IsDisabledReplaceBooking;
    deletedEng.IsDisabledCopyBooking = true;
    deletedEng.IsDisabledDeleteBooking = true;
    deletedEng.IsDisabledReplaceBooking = true;

    this.weeksTobeDisplayed.forEach((week) => {
      const weekly = this.datepipe.transform(week, this.defaultDateFormat);
      deletedEng[weekly].Confirmed.forEach((item) => {
        if (item.ActionMode === 'E' && item.BookingHours !== null) {
          item.BookingHours = '';
          item.isChanged = true;
          this.onFocusOutEvent('', weekly, item, deletedEng);
        }
      });
      deletedEng[weekly].Unconfirmed.forEach((item) => {
        if (item.ActionMode === 'E' && item.BookingHours !== null) {
          item.BookingHours = '';
          item.isChanged = true;
          this.onFocusOutEvent('', weekly, item, deletedEng);
        }
      });
      deletedEng[weekly].Ghost.forEach((item) => {
        if (item.ActionMode === 'E' && item.BookingHours !== null) {
          item.BookingHours = '';
          item.isChanged = true;
          this.onFocusOutEvent('', weekly, item, deletedEng);
        }
      });
    });
    deletedEng.isRowDeleted = true;
    this.modalService.hide();
  }
  cancelRowDelete(engagement) {
    engagement.IsDisabledCopyBooking = engagement.OldIsDisabledCopyBooking;
    engagement.IsDisabledDeleteBooking = engagement.OldIsDisabledDeleteBooking;
    engagement.IsDisabledReplaceBooking =
      engagement.OldIsDisabledReplaceBooking;
    this.weeksTobeDisplayed.forEach((week) => {
      const weekly = this.datepipe.transform(week, this.defaultDateFormat);
      engagement[weekly].Confirmed.forEach((item) => {
        this.deleteBookingCell(item, engagement, weekly);
      });
      engagement[weekly].Unconfirmed.forEach((item) => {
        this.deleteBookingCell(item, engagement, weekly);
      });
      engagement[weekly].Ghost.forEach((item) => {
        this.deleteBookingCell(item, engagement, weekly);
      });
    });
    engagement.isRowDeleted = false;
  }
  deleteBookingCell(item, engagement, weekly) {
    if (item.ActionMode === 'E') {
      if (item.beforeEditHours !== undefined) {
        //checking whetehrt its a not new Booking
        item.BookingHours = item.beforeEditHours;
        item.revertAction = true;
        let value = item.beforeEditHours;
        if (value === null) {
          value = '';
        }
        if (
          item.MeraBookingId > 1 &&
          item.RequestorEmail.toLowerCase() === this.user.Email.toLowerCase()
        ) {
          let value = this.isPercentageSelected
            ? item.LoadingOld
            : item.BookingHoursOld;
          if (value === null) {
            value = '';
          }
          if (item.isCancelRequested === undefined) {
            this.cancelRequestArray.push(item.MeraBookingId);
            item.isCancelRequested = true;
            item.MeraBookingId = 0;
            item.beforeEditHours = item.BookingHoursOld;
            item.beforeEditPercentage = item.LoadingOld;
            item.beforeNotes = item.DescriptionOld;
            this.getBorderColor(engagement, item);
          } else {
            item.isCancelRequested = false;
          }
        }
        item.isChanged = true;
        this.onFocusOutEvent(value, weekly, item, engagement);
      }
    }
  }
  cancelConfirm() {
    this.rowDeleteEngagement = null;
    this.modalService.hide();
  }

  trackByAvailabilty = (i) => i;
  trackByTotalHours = (j) => j;

  searchEngagement() {
    if (this.validateSave()) {
      return false;
    }
    this.isVerifyOnEngClick = false;
    this.addEngResults = [];
    if (this.searchAddEngagement != null) {
      if (this.searchAddEngagement.trim().length >= 2) {
        this.showEngList = true;
        this.showCopyList = false;
        this.showFavouriteEngList = false;
        this.showEngLoader = true;
        this.newEngagement = new JobVM();
        this.newEngagement.JobDescription = this.searchAddEngagement;
        this.newEngagement.InstanceId = this.addBookingInstance;
        this.newEngagement.JobCategory = '';
        this.searchEngagementSubscription = this.scheduledService
          .getEngagementSearchResult(this.newEngagement)
          .subscribe((data) => {
            this.addNewEngagementList = data.body;
            // this.sortedEngagementList = this.orderPipe.transform(
            //   this.addNewEngagementList,
            //   'EngagementFullName'
            // );
            this.sortedEngagementList = data.body;
            if (this.searchAddEngagement.length > 0) {
              this.setAllFavEngagement();

              this.addEngResults = this.sortedEngagementList;
              this.showEngLoader = false;
            } else {
              this.addEngResults = [];
              this.showEngList = false;
            }
          });
      } else {
        this.addEngResults = [];
        this.showEngList = false;
      }
    } else {
      this.addEngResults = [];
      this.showEngList = false;
    }
  }

  private setAllFavEngagement(): void {
    this.sortedEngagementList.forEach((a) => {
      if (a.IsFavourite === true) {
        a.IsFavEngInserted = true;
        a.favEngSrc = favEngImageSrc.favEngImgStarfilled;
      } else {
        a.IsFavEngInserted = false;
        a.favEngSrc = favEngImageSrc.favEngImgStarOutline;
      }
    });
  }

  private setCopyAllFavEngagement(): void {
    this.sortedCopyEngagementList.forEach((a) => {
      if (a.IsFavourite === true) {
        a.IsFavEngInserted = true;
        a.favEngSrc = favEngImageSrc.favEngImgStarfilled;
      } else {
        a.IsFavEngInserted = false;
        a.favEngSrc = favEngImageSrc.favEngImgStarOutline;
      }
    });
  }

  private setReplaceAllFavEngagement(): void {
    this.sortedReplaceEngList.forEach((a) => {
      if (a.IsFavourite === true) {
        a.IsFavEngInserted = true;
        a.favEngSrc = favEngImageSrc.favEngImgStarfilled;
      } else {
        a.IsFavEngInserted = false;
        a.favEngSrc = favEngImageSrc.favEngImgStarOutline;
      }
    });
  }

  OnEngagementSelect(event) {
    if (this.FavoriteImageClick === true) {
      this.FavoriteImageClick = false;
      (event as Event).stopPropagation();
    }

    this.addedEngagement = event;
    if (event.JobCode === '' || event.JobCode === null) {
      this.addedEngagement.JobType = event.JobType + '-' + 'Awaiting Code';
      this.addedEngagement.JobCode = 'Dummy';
    } else {
      this.addedEngagement.JobType = event.JobType;
    }
    this.searchAddEngagement = null;
    let newRowAdded = {
      BookingType: this.addedEngagement.JobType,
      EngagementName: this.addedEngagement.EngagementFullName,
      InstanceID: this.addBookingInstance,
      InstanceName: this.addBookingInstanceName,
      JobCategory: this.addedEngagement.JobCategory,
      JobCode: this.addedEngagement.JobCode,
      JobId: this.addedEngagement.JobId,
      IsDisabledCopyBooking: true,
      IsDisabledDeleteBooking: true,
      IsDisabledEVNavigation: true,
      IsDisabledReplaceBooking: true,
      IsDisabledViewBooking_Job: true,
      addEng: true,
      rowAction: true,
    };

    this.weeksTobeDisplayed.forEach((week) => {
      this.newConfirmedBooking.ResourceId = this.addBookingResourceID;
      this.newUnconfirmedBooking.ResourceId = this.addBookingResourceID;
      this.newGhostBooking.ResourceId = this.addBookingResourceID;
      this.newConfirmedBooking.RequestorEmail = this.user.Email;
      this.newUnconfirmedBooking.RequestorEmail = this.user.Email;
      this.newGhostBooking.RequestorEmail = this.user.Email;
      this.newConfirmedBooking.InstanceId = this.addBookingInstance;
      this.newUnconfirmedBooking.InstanceId = this.addBookingInstance;
      this.newGhostBooking.InstanceId = this.addBookingInstance;
      this.newConfirmedBooking.JobId = this.addedEngagement.JobId;
      this.newUnconfirmedBooking.JobId = this.addedEngagement.JobId;
      this.newGhostBooking.JobId = this.addedEngagement.JobId;
      this.newConfirmedBooking.BookingType = this.addedEngagement.JobType;
      this.newUnconfirmedBooking.BookingType = this.addedEngagement.JobType;
      this.newGhostBooking.BookingType = this.addedEngagement.JobType;
      this.newConfirmedBooking.id =
        this.addedEngagement.JobId +
        this.addBookingInstance +
        week +
        this.newConfirmedBooking.BookingMode;
      this.newUnconfirmedBooking.id =
        this.addedEngagement.JobId +
        this.addBookingInstance +
        week +
        this.newUnconfirmedBooking.BookingMode;
      this.newGhostBooking.id =
        this.addedEngagement.JobId +
        this.addBookingInstance +
        week +
        this.newGhostBooking.BookingMode;
      // const newStartDate = new Date(week);
      // const newEndDate = new Date(week);
      // newEndDate.setDate(newEndDate.getDate() + 6);
      // newStartDate.setMinutes(
      //   newStartDate.getMinutes() - newStartDate.getTimezoneOffset()
      // );
      // newEndDate.setMinutes(
      //   newEndDate.getMinutes() - newEndDate.getTimezoneOffset()
      // );
      let newStartDate = new Date(week);
      let newEndDate = new Date(week);
      newEndDate.setDate(newEndDate.getDate() + 6);
      newStartDate = new Date(
        newStartDate.getFullYear(),
        newStartDate.getMonth(),
        newStartDate.getDate(),
        0,
        0,
        0
      );
      newEndDate = new Date(
        newEndDate.getFullYear(),
        newEndDate.getMonth(),
        newEndDate.getDate(),
        0,
        0,
        0
      );
      this.newConfirmedBooking.StartDate = moment(newStartDate).format("YYYY-MM-DDT00:00:00.000");
      this.newConfirmedBooking.WeekEndDate = moment(newEndDate).format("YYYY-MM-DDT00:00:00.000");
      this.newConfirmedBooking.WeekStartDate = moment(newStartDate).format("YYYY-MM-DDT00:00:00.000");
      this.newUnconfirmedBooking.StartDate = moment(newStartDate).format("YYYY-MM-DDT00:00:00.000");
      this.newUnconfirmedBooking.WeekEndDate = moment(newEndDate).format("YYYY-MM-DDT00:00:00.000");
      this.newUnconfirmedBooking.WeekStartDate = moment(newStartDate).format("YYYY-MM-DDT00:00:00.000");
      this.newGhostBooking.StartDate = moment(newStartDate).format("YYYY-MM-DDT00:00:00.000");
      this.newGhostBooking.WeekEndDate = moment(newEndDate).format("YYYY-MM-DDT00:00:00.000");
      this.newGhostBooking.WeekStartDate = moment(newStartDate).format("YYYY-MM-DDT00:00:00.000");
      newRowAdded[this.datepipe.transform(week, this.defaultDateFormat)] = [];
      newRowAdded[
        this.datepipe.transform(week, this.defaultDateFormat)
      ].Confirmed = [JSON.parse(JSON.stringify(this.newConfirmedBooking))];
      newRowAdded[
        this.datepipe.transform(week, this.defaultDateFormat)
      ].Unconfirmed = [JSON.parse(JSON.stringify(this.newUnconfirmedBooking))];
      newRowAdded[this.datepipe.transform(week, this.defaultDateFormat)].Ghost =
        [JSON.parse(JSON.stringify(this.newGhostBooking))];
    });
    this.engagaementList = [newRowAdded, ...this.engagaementList];
    this.dataSource = this.engagaementList;

    this.showEngList = false;
    this.showFavouriteEngList = false;
  }

  onEngagementClear() {
    this.showEngLoader = false;
    this.showEngList = false;
    this.searchAddEngagement = '';
    this.addedEngagement = null;
    this.newEngagement = null;
    this.addEngResults = [];
    this.copyEngResults = [];
    this.addFavouriteEngResults = [];
    this.isClickonFavEngStart = false;
    this.copyshowFavouriteEngList = false;
    this.showFavouriteEngList = false;
    //this.replaceshowFavouriteEngList = false;
    this.IsCopyDataNotFound = false;

    this.isFavEngONClick = true;
    this.copyisFavEngONClick = true;
  }

  removeEng(element, index) {
    element.WeeklyBookings?.forEach((newBooking) => {
      if (
        newBooking.MeraBookingId > 1 &&
        newBooking.RequestorEmail.toLowerCase() ===
        this.user.Email.toLowerCase()
      ) {
        let value = this.isPercentageSelected
          ? newBooking.LoadingOld
          : newBooking.BookingHoursOld;
        if (value === null) {
          value = '';
        }
        const week = this.datepipe.transform(
          new Date(newBooking.WeekStartDate),
          this.defaultDateFormat
        );
        newBooking.isChanged = true;
        this.onFocusOutEvent(value, week, newBooking, element);
        if (newBooking.isCancelRequested === undefined) {
          this.cancelRequestArray.push(newBooking.MeraBookingId);
          newBooking.isCancelRequested = true;
          newBooking.MeraBookingId = 0;
          newBooking.beforeEditHours = newBooking.BookingHoursOld;
          newBooking.beforeEditPercentage = newBooking.LoadingOld;
          newBooking.beforeNotes = newBooking.DescriptionOld;
          this.getBorderColor(element, newBooking);
        } else {
          newBooking.isCancelRequested = false;
        }
      }
    });
    this.myFormGroup?.controls?.copyEngName?.setValue('');
    this.myFormGroup?.controls?.copyEngName?.reset();
    this.engagaementList.splice(index, 1);
    this.dataSource = this.engagaementList;
    this.table.renderRows();
    this.updatedBookingDetails.removeEngagement(element);
  }

  searchCopyEngagement() {
    this.isVerifyOnEngClick = false;
    this.copyEngResults = [];
    this.addFavouriteEngResults = [];
    let searchCopyEngName = this.myFormGroup.controls.copyEngName.value;
    if (searchCopyEngName != null) {
      if (searchCopyEngName.trim().length >= 2) {
        this.showCopyList = true;
        this.showCopyLoader = true;
        this.copyEngagement = new JobVM();
        this.copyEngagement.JobDescription = searchCopyEngName;
        this.copyEngagement.InstanceId = this.copyBookingInstance;
        this.copyEngagement.JobCategory = '';
        this.searchCopyEngagementSubscription = this.scheduledService
          .getEngagementSearchResult(this.copyEngagement)
          .subscribe((data) => {
            this.copyNewEngagementList = data.body;
            // this.sortedCopyEngagementList = this.orderPipe.transform(
            //   this.copyNewEngagementList,
            //   'EngagementFullName'
            // );
            this.sortedCopyEngagementList = this.copyNewEngagementList;

            if (this.sortedCopyEngagementList.length > 0) {
              this.setCopyAllFavEngagement();
              this.IsCopyDataNotFound = false;
              this.copyEngResults = this.sortedCopyEngagementList;
              this.showCopyLoader = false;
            } else if (this.sortedCopyEngagementList.length === 0) {
              this.copyEngResults = [];
              this.IsCopyDataNotFound = true;
              this.showCopyLoader = false;
            }
            // if (searchCopyEngName.length > 0) {
            //   this.IsCopyDataNotFound = false;
            //   this.copyEngResults = this.sortedCopyEngagementList;
            //   this.showCopyLoader = false;
            // } else {
            //   this.copyEngResults = [];
            //   //this.showCopyList = false;
            //   this.IsCopyDataNotFound = true;
            //   this.showCopyLoader = false;
            // }
          });
      } else {
        this.copyEngResults = [];
        this.showCopyList = false;
        this.IsCopyDataNotFound = true;
      }
    } else {
      this.copyEngResults = [];
      this.showCopyList = false;
      this.IsCopyDataNotFound = true;
    }
  }

  OnEngagementCopySelect(event, engagement, index) {
    if (this.FavoriteImageClick === true) {
      this.FavoriteImageClick = false;
      (event as Event).stopPropagation();
    }
    this.copyshowFavouriteEngList = false;
    this.showCopyLoader = true;
    //this.showSearchList = false;
    //this.showCopyList = true;

    this.copiedEngagement = event;
    if (event.JobCode === '' || event.JobCode === null) {
      engagement.JobType = event.JobType + '-' + 'Awaiting Code';
      engagement.JobCode = 'Dummy';
    } else {
      engagement.JobType = event.JobType;
    }
    this.myFormGroup.controls.copyEngName.setValue('');
    engagement.BookingType = this.copiedEngagement.JobType;
    engagement.EngagementName = this.copiedEngagement.EngagementFullName;
    engagement.InstanceID = this.copyBookingInstance;
    engagement.InstanceId = this.copyBookingInstance;
    engagement.InstanceName = this.copyBookingInstanceName;
    engagement.JobCategory = this.copiedEngagement.JobCategory;
    engagement.JobCode = this.copiedEngagement.JobCode;
    engagement.JobId = this.copiedEngagement.JobId;
    engagement.IsDisabledCopyBooking = true;
    engagement.IsDisabledDeleteBooking = true;
    engagement.IsDisabledEVNavigation = true;
    engagement.IsDisabledReplaceBooking = true;
    engagement.IsDisabledViewBooking_Job = true;
    engagement.addEng = true;
    engagement.rowAction = true;
    engagement.isCopy = false;

    this.showCopyList = false;

    this.weeksTobeDisplayed.forEach((week) => {
      engagement[
        this.datepipe.transform(week, this.defaultDateFormat)
      ].Confirmed.forEach((item) => {
        if (item.ActionMode === 'E') {
          item.ResourceId = this.copyBookingResourceID;
          item.InstanceID = this.copyBookingInstance;
          item.InstanceId = this.copyBookingInstance;
          item.JobId = this.copiedEngagement.JobId;
          item.BookingType = this.copiedEngagement.JobType;
          item.id =
            this.copiedEngagement.JobId +
            this.copyBookingInstance +
            week +
            item.BookingMode;
          item.IsDisabledEditBooking = false;
          item.IsDisabledDeleteBooking = false;
          item.isCopy = false;
          item.isNewBooking = true;
          if (this.showCancelIcon(item)) {
            item.isChanged = true;
            this.onFocusOutEvent(
              item.BookingHours,
              this.datepipe.transform(week, this.defaultDateFormat),
              item,
              engagement
            );
          }
        }
      });
      engagement[
        this.datepipe.transform(week, this.defaultDateFormat)
      ].Unconfirmed.forEach((item) => {
        if (item.ActionMode === 'E') {
          item.ResourceId = this.copyBookingResourceID;
          item.InstanceID = this.copyBookingInstance;
          item.InstanceId = this.copyBookingInstance;
          item.JobId = this.copiedEngagement.JobId;
          item.BookingType = this.copiedEngagement.JobType;
          item.id =
            this.copiedEngagement.JobId +
            this.copyBookingInstance +
            week +
            item.BookingMode;
          item.IsDisabledEditBooking = false;
          item.IsDisabledDeleteBooking = false;
          item.isCopy = false;
          item.isNewBooking = true;
          if (this.showCancelIcon(item)) {
            item.isChanged = true;
            this.onFocusOutEvent(
              item.BookingHours,
              this.datepipe.transform(week, this.defaultDateFormat),
              item,
              engagement
            );
          }
        }
      });
      engagement[
        this.datepipe.transform(week, this.defaultDateFormat)
      ].Ghost.forEach((item) => {
        if (item.ActionMode === 'E') {
          item.ResourceId = this.copyBookingResourceID;
          item.InstanceID = this.copyBookingInstance;
          item.InstanceId = this.copyBookingInstance;
          item.JobId = this.copiedEngagement.JobId;
          item.BookingType = this.copiedEngagement.JobType;
          item.id =
            this.copiedEngagement.JobId +
            this.copyBookingInstance +
            week +
            item.BookingMode;
          item.IsDisabledEditBooking = false;
          item.IsDisabledDeleteBooking = false;
          item.isCopy = false;
          item.isNewBooking = true;
          if (this.showCancelIcon(item)) {
            item.isChanged = true;
            this.onFocusOutEvent(
              item.BookingHours,
              this.datepipe.transform(week, this.defaultDateFormat),
              item,
              engagement
            );
          }
        }
      });
    });
  }
  onEngagementCopyClear() {
    this.showCopyLoader = false;
    this.showCopyList = false;
    this.copyshowFavouriteEngList = false;
    this.myFormGroup.controls.copyEngName.setValue('');
    this.copiedEngagement = null;
    this.copyEngagement = null;
    this.copyEngResults = [];
    this.copyisFavEngONClick = true;
    this.IsCopyDataNotFound = false;
  }

  copyRow(element) {
    if (this.validateSave()) {
      return false;
    }
    this.showCopyList = false;
    this.showFavouriteEngList = false;

    this.copyshowFavouriteEngList = false;
    element.replaceshowFavouriteEngList = false;
    this.showEngList = false;
    let newRowAdded = {
      BookingType: '',
      EngagementName: '',
      InstanceID: this.copyBookingInstance,
      InstanceName: '',
      JobCategory: '',
      JobCode: '',
      JobId: 0,
      IsDisabledCopyBooking: true,
      IsDisabledDeleteBooking: true,
      IsDisabledEVNavigation: true,
      IsDisabledReplaceBooking: true,
      IsDisabledViewBooking_Job: true,
      addEng: true,
      rowAction: true,
      isCopy: true,
      searchCopyEng: '',
    };
    this.weeksTobeDisplayed.forEach((week) => {
      newRowAdded[this.datepipe.transform(week, this.defaultDateFormat)] = [];
      newRowAdded[this.datepipe.transform(week, this.defaultDateFormat)] =
        JSON.parse(
          JSON.stringify(
            element[this.datepipe.transform(week, this.defaultDateFormat)]
          )
        );
      newRowAdded[
        this.datepipe.transform(week, this.defaultDateFormat)
      ].Confirmed.forEach((item) => {
        if (item.ActionMode === 'E') {
          this.createNewCopyBooking(item);
        }
      });
      newRowAdded[
        this.datepipe.transform(week, this.defaultDateFormat)
      ].Unconfirmed.forEach((item) => {
        if (item.ActionMode === 'E') {
          this.createNewCopyBooking(item);
        }
      });
      newRowAdded[
        this.datepipe.transform(week, this.defaultDateFormat)
      ].Ghost.forEach((item) => {
        if (item.ActionMode === 'E') {
          this.createNewCopyBooking(item);
        }
      });
    });

    this.engagaementList = [newRowAdded, ...this.engagaementList];
    this.dataSource = this.engagaementList;
    this.myFormGroup.addControl(
      'copyEngName',
      new FormControl('', Validators.required)
    );
    setTimeout(() => {
      this.renderer.selectRootElement('#copyEngName').focus();
      document.getElementById('indWrapper').scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }, 1000);
  }

  createNewCopyBooking(eachBooking) {
    if (eachBooking.IsDisabledCopyBooking) {
      eachBooking.BookingHours = null;
      eachBooking.Loading = null;
      eachBooking.Description = null;
      eachBooking.MeraActionDescriptionId = 0;
      eachBooking.MeraBookingId = 0;
      return false;
    }
    if (this.showCancelIcon(eachBooking) || eachBooking.StatusId === 2) {
      if (
        (eachBooking.BookingHoursOld === null &&
          eachBooking.MeraActionDescriptionId === 1) ||
        eachBooking.isNewBooking === true
      ) {
        eachBooking.BookingHours = null;
        eachBooking.Loading = null;
        eachBooking.Description = null;
        eachBooking.MeraActionDescriptionId = 0;
        eachBooking.MeraBookingId = 0;
      } else {
        eachBooking.BookingHours = eachBooking.BookingHoursOld;
        eachBooking.Loading = eachBooking.LoadingOld;
        eachBooking.Description = eachBooking.DescriptionOld;
      }
    }
    eachBooking.BookingId = 0;
    eachBooking.ResourceId = '';
    eachBooking.JobId = null;
    eachBooking.ActionDescriptionId = 1;
    eachBooking.MeraActionDescriptionId = 1;
    eachBooking.ActualBookingId = 0;
    eachBooking.ParentBookingId = 0;
    eachBooking.Description = '';
    eachBooking.ColorCode = '#c4c4cd';
    eachBooking.StatusId = 1;
    eachBooking.isNewBooking = true;
    eachBooking.oldIsDisabledCopyBooking = eachBooking.IsDisabledCopyBooking;
    eachBooking.oldIsDisabledDeleteBooking =
      eachBooking.IsDisabledDeleteBooking;
    eachBooking.oldIsDisabledReplaceBooking =
      eachBooking.IsDisabledReplaceBooking;
    eachBooking.oldIsDisabledEditBooking = eachBooking.IsDisabledEditBooking;
    eachBooking.IsDisabledCopyBooking = true;
    eachBooking.IsDisabledDeleteBooking = true;
    eachBooking.IsDisabledReplaceBooking = true;
    eachBooking.IsDisabledEditBooking = true;
    eachBooking.RequestorEmail = this.user.Email;
    eachBooking.MeraBookingId = 1;
    eachBooking.IsEdit = false;
    eachBooking.ActualBookingHours = 0;
    eachBooking.ActualBookingId = 0;
    eachBooking.BookingHoursOld = null;
    eachBooking.beforeEditHours = null;
    eachBooking.beforeEditPercentage = null;
    eachBooking.beforeNotes = null;
    eachBooking.DescriptionOld = '';
    eachBooking.ColorCodeOld = '#c4c4cd';
    eachBooking.IsMergedBooking = false;
    eachBooking.IsSplitBooking = false;
    eachBooking.LoadingOld = null;
    eachBooking.isCopy = true;
  }
  isCancelReplace(element) {
    if (!this.user.R_InstEnable_AddBooking) {
      if (
        element.InstanceID == this.addBookingInstance &&
        (element.rowReplaced || element.Replaced)
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (element.rowReplaced || element.Replaced) {
        return true;
      } else {
        return false;
      }
    }
  }
  cancelRowReplace(element, index) {
    element.IsDisabledCopyBooking = element.OldIsDisabledCopyBooking;
    element.IsDisabledDeleteBooking = element.OldIsDisabledDeleteBooking;
    element.IsDisabledReplaceBooking = element.OldIsDisabledReplaceBooking;
    element.isReplace = false;
    element.rowReplaced = false;
    element.Replaced = false;
    this.weeksTobeDisplayed.forEach((week) => {
      element[
        this.datepipe.transform(week, this.defaultDateFormat)
      ].Confirmed.forEach((item) => {
        this.cancelReplaceCell(item, element);
      });
      element[
        this.datepipe.transform(week, this.defaultDateFormat)
      ].Unconfirmed.forEach((item) => {
        this.cancelReplaceCell(item, element);
      });
      element[
        this.datepipe.transform(week, this.defaultDateFormat)
      ].Ghost.forEach((item) => {
        this.cancelReplaceCell(item, element);
      });
    });
    if (element.toBeRemoved) {
      this.engagaementList.splice(index, 1);
      this.dataSource = this.engagaementList;
      this.table.renderRows();
      this.updatedBookingDetails.removeEngagement(element);
    }
  }
  cancelReplaceCell(item, element) {
    if (item.ActionMode === 'E') {
      if (
        item.MeraBookingId > 1 &&
        item.RequestorEmail.toLowerCase() === this.user.Email.toLowerCase()
      ) {
        if (item.isCancelRequested === undefined) {
          this.cancelRequestArray.push(item.MeraBookingId);
          item.isCancelRequested = true;
        } else {
          item.isCancelRequested = false;
        }
      }
      item.revertAction === true;
      item.StatusId = 0;
      item.MeraBookingId = 0;
      item.MeraActionDescriptionId = 0;
      item.ResourceId = item.ResourceIdOld ? item.ResourceIdOld : item.ResourceId;
      item.JobId = item.JobIdOld ? item.JobIdOld : item.JobId;
      item.BookingType = item.BookingTypeOld;
      item.IsDisabledCopyBooking = item.oldIsDisabledCopyBooking;
      item.IsDisabledDeleteBooking = item.oldIsDisabledDeleteBooking;
      item.IsDisabledReplaceBooking = item.oldIsDisabledReplaceBooking;
      item.IsDisabledEditBooking = item.oldIsDisabledEditBooking;
      if (item.IsReplaceResource && item.ResourceIdNew !== item.ResourceIdOld) {
        element.toBeRemoved = true;
      }
      if (item.IsReplaceEngagement) {
        element.EngagementName = item.EngagementNameOld;
        element.JobId = item.JobIdOld;
      }

      if (item.oldIsDisabledReplaceBooking) {
        if (item.BookingHoursOld !== null) {
          item.BookingHours = item.beforeEditHours;
          item.Loading = item.beforeEditPercentage;
          item.Description = item.DescriptionOld;
        }
      }
      this.updatedBookingDetails.removeBooking(item);
    }
  }
  replaceRow(element) {
    if (this.validateSave()) {
      return false;
    }
    this.showCopyList = false;
    this.showFavouriteEngList = false;

    this.copyshowFavouriteEngList = false;
    element.replaceshowFavouriteEngList = false;
    this.showEngList = false;

    this.beforeReplace = JSON.parse(JSON.stringify(element));
    element.OldIsDisabledCopyBooking = element.IsDisabledCopyBooking;
    element.OldIsDisabledDeleteBooking = element.IsDisabledDeleteBooking;
    element.OldIsDisabledReplaceBooking = element.IsDisabledReplaceBooking;
    element.IsDisabledCopyBooking = true;
    element.IsDisabledDeleteBooking = true;
    element.IsDisabledReplaceBooking = true;
    element.isReplace = true;
    element.rowReplaced = true;
    element.searchResource = '';
    element.showReplaceResList = false;
    element.searchEngagement = '';
    element.showReplaceEngList = false;
    element.replaceshowFavouriteEngList = false; //v1
    this.weeksTobeDisplayed.forEach((week) => {
      element[
        this.datepipe.transform(week, this.defaultDateFormat)
      ].Confirmed.forEach((item) => {
        if (item.ActionMode === 'E') {
          item.oldIsDisabledCopyBooking = item.IsDisabledCopyBooking;
          item.oldIsDisabledDeleteBooking = item.IsDisabledDeleteBooking;
          item.oldIsDisabledReplaceBooking = item.IsDisabledReplaceBooking;
          item.oldIsDisabledEditBooking = item.IsDisabledEditBooking;
          item.IsDisabledCopyBooking = true;
          item.IsDisabledDeleteBooking = true;
          item.IsDisabledReplaceBooking = true;
          item.IsDisabledEditBooking = true;
        }
      });
      element[
        this.datepipe.transform(week, this.defaultDateFormat)
      ].Unconfirmed.forEach((item) => {
        if (item.ActionMode === 'E') {
          item.oldIsDisabledCopyBooking = item.IsDisabledCopyBooking;
          item.oldIsDisabledDeleteBooking = item.IsDisabledDeleteBooking;
          item.oldIsDisabledReplaceBooking = item.IsDisabledReplaceBooking;
          item.oldIsDisabledEditBooking = item.IsDisabledEditBooking;
          item.IsDisabledCopyBooking = true;
          item.IsDisabledDeleteBooking = true;
          item.IsDisabledReplaceBooking = true;
          item.IsDisabledEditBooking = true;
          item.IsDisabledDeleteBooking = true;
        }
      });
      element[
        this.datepipe.transform(week, this.defaultDateFormat)
      ].Ghost.forEach((item) => {
        if (item.ActionMode === 'E') {
          item.oldIsDisabledCopyBooking = item.IsDisabledCopyBooking;
          item.oldIsDisabledDeleteBooking = item.IsDisabledDeleteBooking;
          item.oldIsDisabledReplaceBooking = item.IsDisabledReplaceBooking;
          item.oldIsDisabledEditBooking = item.IsDisabledEditBooking;
          item.IsDisabledCopyBooking = true;
          item.IsDisabledDeleteBooking = true;
          item.IsDisabledReplaceBooking = true;
          item.IsDisabledEditBooking = true;
          item.IsDisabledDeleteBooking = true;
        }
      });
    });
    setTimeout(() => {
      if (this.user.RoleDelegate)
        this.replaceResourceForm.addControl(this.replaceResourceControl);
      this.replaceEngForm.addControl(this.replaceEngControl);
    }, 1000);
  }

  searchReplaceResource(element) {
    element.isReplace = true;
    element.replaceshowFavouriteEngList = false; //v1
    this.replaceResResults = [];
    if (element.searchResource != null) {
      if (element.searchResource.trim().length >= 2) {
        element.showReplaceResList = true;
        this.showReplaceResLoader = true;
        this.replaceResource = new ResourceDto();
        this.replaceResource = this.resource;
        this.replaceResource.ResourceName = element.searchResource;
        this.replaceResource.ResourceId = 0;
        this.replaceResource.LoggedInResourceID = +sessionStorage.getItem(
          'loggedin_resourceid'
        );
        this.replaceResource.InstanceId = element.InstanceID;
        this.searchReplaceResSubscription = this.scheduledService
          .getResourceList(this.replaceResource)
          .subscribe((data) => {
            this.sortedReplaceResList = this.orderPipe.transform(
              data,
              'ResourceName'
            );
            if (element.searchResource.length > 0) {
              this.replaceResResults = this.sortedReplaceResList;
              this.showReplaceResLoader = false;
            } else {
              this.replaceResResults = [];
              element.showReplaceResList = false;
            }
          });
      } else {
        this.replaceResResults = [];
        element.showReplaceResList = false;
      }
    } else {
      this.replaceResResults = [];
      element.showReplaceResList = false;
    }
  }
  onReplaceResourceClear(element) {
    this.showReplaceResLoader = false;
    element.showReplaceResList = false;
    element.searchResource = '';
    this.sortedReplaceResList = [];
    this.replacedResource = null;
    this.replaceResResults = [];
    this.replaceisFavEngONClick = true;
  }
  OnReplaceResSelect(item, element) {
    this.replacedResource = item;
    element.searchResource = this.replacedResource.ResourceName;
    element.showReplaceResList = false;
    element.isReplace = false;
    this.weeksTobeDisplayed.forEach((week) => {
      element[
        this.datepipe.transform(week, this.defaultDateFormat)
      ].Confirmed.forEach((item) => {
        if (item.ActionMode === 'E') {
          if (this.processBookingBeforeReplace(item)) {
            item.ResourceIdOld = item.ResourceId;
            item.ResourceId = this.replacedResource.ResourceId;
            this.replaceCellData(item, element);
          }
        }
      });
      element[
        this.datepipe.transform(week, this.defaultDateFormat)
      ].Unconfirmed.forEach((item) => {
        if (item.ActionMode === 'E') {
          if (this.processBookingBeforeReplace(item)) {
            item.ResourceIdOld = item.ResourceId;
            item.ResourceId = this.replacedResource.ResourceId;
            this.replaceCellData(item, element);
          }
        }
      });
      element[
        this.datepipe.transform(week, this.defaultDateFormat)
      ].Ghost.forEach((item) => {
        if (item.ActionMode === 'E') {
          if (this.processBookingBeforeReplace(item)) {
            item.ResourceIdOld = item.ResourceId;
            item.ResourceId = this.replacedResource.ResourceId;
            this.replaceCellData(item, element);
          }
        }
      });
    });
  }
  searchReplaceEngagement(element) {
    this.isVerifyOnEngClick = false;
    this.addFavouriteEngResults = [];
    this.sortedReplaceEngList = [];
    element.isReplace = true;
    this.replaceEngResults = [];
    if (element.searchEngagement != null) {
      if (element.searchEngagement.trim().length >= 2) {
        element.showReplaceEngList = true;
        this.favEngReplaceIndex = 0;

        this.showReplaceEngLoader = true;
        this.replaceEngagement = new JobVM();
        this.replaceEngagement.JobDescription = element.searchEngagement;
        this.replaceEngagement.InstanceId = element.InstanceID;
        this.replaceEngagement.JobCategory = '';
        this.searchReplaceEngSubscription = this.scheduledService
          .getEngagementSearchResult(this.replaceEngagement)
          .subscribe((data) => {
            // this.sortedReplaceEngList = this.orderPipe.transform(
            //   data.body, 'EngagementFullName');
            this.sortedReplaceEngList = data.body;
            // if (element.searchEngagement.length > 0)
            if (this.sortedReplaceEngList.length > 0) {
              this.setReplaceAllFavEngagement();
              this.replaceEngResults = this.sortedReplaceEngList;
              this.showReplaceEngLoader = false;
              this.IsRepalceDataNotFound = false;
            } else if (this.sortedReplaceEngList.length === 0) {
              this.IsRepalceDataNotFound = true;
              this.showReplaceEngLoader = false;
              this.replaceEngResults = [];
              //console.log(element.showReplaceEngList);
              //console.log(this.favEngReplaceIndex);
            }
          });
      } else {
        this.replaceEngResults = [];
        element.showReplaceEngList = false;
      }
    } else {
      this.replaceEngResults = [];
      element.showReplaceEngList = false;
    }
  }

  onReplaceEngClear(element) {
    this.showReplaceEngLoader = false;
    element.showReplaceEngList = false;
    element.searchEngagement = '';
    this.sortedReplaceEngList = [];
    this.replaceEngagement = null;
    this.replaceEngResults = [];
    this.replaceisFavEngONClick = true;
    this.IsRepalceDataNotFound = false;
  }

  OnReplaceEngSelect(item, element) {
    if (this.FavoriteImageClick === true) {
      this.FavoriteImageClick = false;
      (item as Event).stopPropagation();
    }
    element.replaceshowFavouriteEngList = false;
    this.showReplaceEngLoader = true;

    this.replacedEngagement = item;
    element.searchEngagement = this.replacedEngagement.EngagementFullName;
    element.showReplaceEngList = false;
    element.isReplace = false;

    this.weeksTobeDisplayed.forEach((week) => {
      const diary =
        this.totalHours[this.datepipe.transform(week, this.defaultDateFormat)]
          .diaryHour;
      element[
        this.datepipe.transform(week, this.defaultDateFormat)
      ].Confirmed.forEach((item) => {
        if (item.ActionMode === 'E') {
          if (this.processBookingBeforeReplace(item)) {
            item.JobIdOld = item.JobId;
            item.BookingTypeOld = item.BookingType;
            item.JobId = this.replacedEngagement.JobId;
            item.BookingType = this.replacedEngagement.JobType;
            this.replaceCellData(item, element);
          }
        }
      });
      element[
        this.datepipe.transform(week, this.defaultDateFormat)
      ].Unconfirmed.forEach((item) => {
        if (item.ActionMode === 'E') {
          if (this.processBookingBeforeReplace(item)) {
            item.JobIdOld = item.JobId;
            item.BookingTypeOld = item.BookingType;
            item.JobId = this.replacedEngagement.JobId;
            item.BookingType = this.replacedEngagement.JobType;
            this.replaceCellData(item, element);
          }
        }
      });
      element[
        this.datepipe.transform(week, this.defaultDateFormat)
      ].Ghost.forEach((item) => {
        if (item.ActionMode === 'E') {
          if (this.processBookingBeforeReplace(item)) {
            item.JobIdOld = item.JobId;
            item.BookingTypeOld = item.BookingType;
            item.JobId = this.replacedEngagement.JobId;
            item.BookingType = this.replacedEngagement.JobType;
            this.replaceCellData(item, element);
          }
        }
      });
    });
  }
  processBookingBeforeReplace(item) {
    if (item.oldIsDisabledReplaceBooking) {
      item.BookingHours = null;
      item.Loading = null;
      item.Description = null;
      item.MeraActionDescriptionId = 0;
      item.MeraBookingId = 0;
      return false;
    }
    if (this.showCancelIcon(item) || item.StatusId === 2) {
      if (
        (item.BookingHoursOld === null && item.MeraActionDescriptionId === 1) ||
        item.isNewBooking === true
      ) {
        item.BookingHours = null;
        item.Loading = null;
        item.Description = null;
        item.MeraActionDescriptionId = 0;
        item.MeraBookingId = 0;
        return false;
      } else {
        item.BookingHours = item.BookingHoursOld;
        item.Loading = item.LoadingOld;
        item.Description = item.DescriptionOld;
        return true;
      }
    } else {
      return true;
    }
  }
  replaceCellData(item, element) {
    item.IsDisabledCopyBooking = item.oldIsDisabledCopyBooking;
    item.IsDisabledDeleteBooking = item.oldIsDisabledDeleteBooking;
    item.IsDisabledReplaceBooking = item.oldIsDisabledReplaceBooking;
    item.IsDisabledEditBooking = item.oldIsDisabledEditBooking;
    item.RequestorEmail = this.user.Email;
    item.MeraActionDescriptionId = 5;
    item.rowReplaced = true;
    item.StatusId = 1;
    if (item.MeraBookingId < 1) {
      item.MeraBookingId = 1;
    }
    if (!item.isNewBooking) {
      if (item.IsSplitBooking) {
        this.updatedBookingDetails.checkSplitBooking(element, item);
      } else {
        this.updatedBookingDetails.bookingsToSave(item);
      }
      item.IsMergedBooking &&
        this.updatedBookingDetails.checkMergeBooking(
          element,
          item,
          item.DiaryHours
        );
    }
  }
  openInfo(template) {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.modalRef = this.modalService.show(template, this.modalOption);
  }
  validateSave() {
    let valid = false;
    const replaceRow = this.engagaementList.filter((book) => {
      return book.isReplace === true;
    });
    if (replaceRow?.length > 0) {
      this.replaceResourceForm?.controls.replaceResourceRow.markAsTouched();
      this.replaceEngForm?.controls.replaceEngRow.markAsTouched();
      valid = true;
    }
    const copyEng = this.engagaementList.filter((book) => {
      return book.isCopy === true;
    });
    if (copyEng?.length > 0) {
      this.myFormGroup.controls.copyEngName.markAsTouched();
      valid = true;
    }
    if (this.invalidValues?.length > 0) {
      valid = true;
    }
    return valid;
  }
  submit() {
    if (this.validateSave()) {
      return false;
    }
    if (this.cancelRequestArray.length > 0) {
      this.spinner.show();
      let param = {
        MeraBookingsID: this.cancelRequestArray,
      };
      this.scheduledService.CancelMeraBookings(param).subscribe((data: any) => {
        if ((data.status = '200')) {
          this.cancelRequestArray = [];
          if (this.updatedBookingDetails.fetchSaveDataList().length > 0) {
            this.submitChanges();
          } else {
            this.spinner.hide();
            this.saveSucessMessage = 'Schedules updated successfully';
            this.editableColumn = null;
            this.editableIndex = null;
            this.updatedBookingDetails.clearSaveDataList();
            this.spinner.show();
            this.getBookingList(this.resourceParam);
          }
        } else {
          this.spinner.hide();
          this.saveErrorMessage = 'Schedules save failed';
        }
      });
    } else {
      this.submitChanges();
    }
  }
  submitChanges() {
    if (this.updatedBookingDetails.fetchSaveDataList().length > 0) {
      this.spinner.show();
      let saveParams = {
        IsPercentSelected: this.isPercentageSelected,
        RequestMode: JSON.parse(sessionStorage.getItem('requestMode')),
        UiViewSource: UiViewSourceEnum.IndividualScheduleView,
        Bookings: this.updatedBookingDetails.fetchSaveDataList(),
      };
      this.scheduledService.saveScheduleList(saveParams).subscribe(
        (data) => {
          console.log('submitted', data);
          if (data.success) {
            this.saveSucessMessage = 'Schedules updated successfully';
            this.spinner.hide();
            this.editableColumn = null;
            this.editableIndex = null;
            this.updatedBookingDetails.clearSaveDataList();
            this.spinner.show();
            this.getBookingList(this.resourceParam);
          } else {
            this.spinner.hide();
            this.saveErrorMessage = 'Schedules save failed';
          }
        },
        (error) => {
          this.saveErrorMessage = 'Schedules save failed';
        }
      );
    }
  }
  cancel() {
    if (
      this.updatedBookingDetails.fetchSaveDataList()?.length > 0 ||
      this.cancelRequestArray.length > 0
    ) {
      if (!this.canDeactivateOnFilter()) {
        return false;
      }
    }
    this.engagaementList = [];
    this.editableColumn = null;
    this.editableIndex = null;
    this.updatedBookingDetails.clearSaveDataList();
    this.cancelRequestArray = [];
    this.addedEngagement = null;
    this.addEngResults = [];
    this.searchAddEngagement = '';
    this.copiedEngagement = null;
    this.copyEngResults = [];
    this.myFormGroup?.controls?.copyEngName?.setValue('');
    this.invalidValues = [];
    this.engagaementList = JSON.parse(
      sessionStorage.getItem('ScheduledIndividual')
    );
    this.formatData();
    this.saveErrorMessage = '';
    this.saveSucessMessage = '';
  }
  ngOnDestroy() {
    if (this.currentUserSubscription !== undefined) {
      this.currentUserSubscription.unsubscribe();
    }
    if (this.instanceSubscription !== undefined) {
      this.instanceSubscription.unsubscribe();
    }
    if (this.resourceListSubscription !== undefined) {
      this.resourceListSubscription.unsubscribe();
    }
    if (this.photoSubscription !== undefined) {
      this.photoSubscription.unsubscribe();
    }
    if (this.bookingListSubscription !== undefined) {
      this.bookingListSubscription.unsubscribe();
    }
    if (this.searchEngagementSubscription !== undefined) {
      this.searchEngagementSubscription.unsubscribe();
    }
  }
  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
    setTimeout(() => {
      this.fixedSectionHeight =
        document.getElementById('fixedForecast').offsetHeight;
    }, 300);
  }
  setTopHeight() {
    let styles = {
      top: this.fixedSectionHeight + 'px',
      '-moz-transition': 'top 1s ease',
      '-webkit-transition': 'top 1s ease',
      '-o-transition': 'top 1s ease',
      transition: 'top 1s ease',
    };
    return styles;
  }
  setWidth() {
    let widthDiv = window.innerWidth;
    widthDiv = widthDiv - 50;
    let styles = {
      width: widthDiv + 'px',
    };
    return styles;
  }
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.setWidth();
  }
  updateWeeksFormat(array) {
    return array.map((eachWeekItem) =>
      this.datepipe.transform(eachWeekItem, this.defaultDateFormat)
    );
  }
  notesSubmit(data) {
    if (data) {
      // Submitted
      let start = new Date(
        this.noteAvailableFor.cellData.WeekStartDate
      ).toDateString();
      let weeksTobeUpdated = [];
      switch (data.radioSelection) {
        case 'update_all':
          // Update all the cells
          weeksTobeUpdated = this.weeksTobeDisplayed.filter((eachWeekItem) =>
            moment(new Date(eachWeekItem).toDateString()).isSameOrAfter(start)
          );
          weeksTobeUpdated = this.updateWeeksFormat(weeksTobeUpdated);
          break;

        case 'extend_upto':
          // Check the date and update until the date if date is present, unless update the current cell
          if (data.date) {
            // this.sharedService.getFirstDateOfWeek(data.date.value);
            weeksTobeUpdated = this.weeksTobeDisplayed.filter(
              (eachWeekItem) =>
                moment(new Date(eachWeekItem).toDateString()).isSameOrAfter(
                  start
                ) &&
                moment(new Date(eachWeekItem).toDateString()).isSameOrBefore(
                  data.date.value.toDateString()
                )
            );
            weeksTobeUpdated = this.updateWeeksFormat(weeksTobeUpdated);
          }
          break;
      }
      this.engagaementList = this.engagaementList.map((engagementItem) => {
        if (engagementItem.JobId === this.noteAvailableFor.cellData.JobId) {
          weeksTobeUpdated.forEach((week) => {
            engagementItem[week][
              this.noteAvailableFor.cellData.BookingMode
            ].forEach((booking) => {
              if (booking.ActionMode === 'E') {
                if (data.radioSelection === 'update_all') {
                  if (
                    booking.BookingHours ===
                    this.noteAvailableFor.cellData.beforeEditHours
                  ) {
                    booking.BookingHours =
                      this.noteAvailableFor.cellData.BookingHours;
                    booking.Description =
                      this.noteAvailableFor.cellData.Description;
                    booking.isChanged = true;
                    this.onFocusOutEvent(
                      booking.BookingHours,
                      week,
                      booking,
                      engagementItem
                    );
                  }
                } else {
                  booking.BookingHours =
                    this.noteAvailableFor.cellData.BookingHours;
                  booking.Description =
                    this.noteAvailableFor.cellData.Description;
                  booking.isChanged = true;
                  this.onFocusOutEvent(
                    booking.BookingHours,
                    week,
                    booking,
                    engagementItem
                  );
                }
              }
            });
          });
        }
        return engagementItem;
      });
      this.dataSource = this.engagaementList;
    } else {
      // Cancelled
    }
    this.isNotes = false;
  }
  showNotes(event, cellData, element, column) {
    if (this.validateSave()) {
      return false;
    }
    if (
      !this.isCurrentWeekDisabled ||
      !(
        this.datepipe.transform(this.tempdate, this.defaultDateFormat) ===
        column
      )
    ) {
      if (
        (!this.selectedResource.IsDisabledAddBooking &&
          cellData.isNewBooking) ||
        (!cellData.isNewBooking &&
          (!cellData.IsDisabledEditBooking ||
            !cellData.IsDisabledDeleteBooking))
      ) {
        cellData.IsEdit = true;
        this.isNotes = true;
        const resource = document
          .getElementById('individualEngWrapper')
          .getBoundingClientRect();
        if (element.addEng) {
          cellData.CellAction = 'Create';
        } else {
          cellData.CellAction = '';
        }
        this.noteAvailableFor = {
          cellData: cellData,
          position: {
            x: event.clientX - resource.left,
            y: event.clientY - resource.top,
          },
        };
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  // x: event.clientX - resource.left,
  // y: event.clientY - resource.top,
  handleEngagementScroll() {
    const scrollWrapper = document.getElementById('individualEngWrapper');
    const that = this;
    if (scrollWrapper) {
      scrollWrapper.addEventListener('scroll', function (event) {
        that.isNotes = false;
      });
    }
  }
  handleWindowClick() {
    const wrapper = document.getElementById('scheduleIndividualPage');
    // var container = document.getElementsByClassName('valueCell')[0];
    const notesArray = ['notesSection', 'weeklyIcon'];
    const editElArray = ['faceValue', 'weeklyIcon', 'showDiv', 'editContainer'];
    const that = this;
    wrapper.addEventListener('click', function (event) {
      let currElem = event.target as Element;
      if (
        currElem.classList &&
        that.checkIntersectionOfArrays(currElem.classList, editElArray)
      ) {
        that.isNotes = false;
      }
      let elementFound = false;
      do {
        if (
          (currElem.classList &&
            that.checkIntersectionOfArrays(currElem.classList, notesArray)) ||
          that.checkIntersectionOfArrays(currElem.classList, editElArray)
        )
          elementFound = true;
        currElem = currElem.parentElement;
      } while (!elementFound && currElem && currElem.parentElement);
      if (!elementFound) {
        // To close the notes dialog box
        that.isNotes = false;

        // To close all the opened edit cells
        that.weeksTobeDisplayed.forEach((week) => {
          if (that.dataSource?.length > 0) {
            that.dataSource.forEach((item) => {
              item[
                that.datepipe.transform(week, that.defaultDateFormat)
              ].Confirmed.forEach((x) => {
                if (!x.isNotValidValue) x.IsEdit = false;
              });
              item[
                that.datepipe.transform(week, that.defaultDateFormat)
              ].Unconfirmed.forEach((x) => {
                if (!x.isNotValidValue) x.IsEdit = false;
              });
              item[
                that.datepipe.transform(week, that.defaultDateFormat)
              ].Ghost.forEach((x) => {
                if (!x.isNotValidValue) x.IsEdit = false;
              });
            });
          }
        });
        that.editableColumn = null;
        that.editableIndex = null;
        that.editableBookingMode = null;
      }
    });
  }
  checkIntersectionOfArrays(arr1, arr2) {
    let itemFound = false;
    arr2.forEach((item) => {
      if (arr1.contains(item)) itemFound = true;
    });
    return itemFound;
  }
  navigateEngagementScheduleView(item) {
    if (!item.IsDisabledEVNavigation && !item.IsDisabledViewBooking_Job) {
      const engDetails = {
        jobId: item.JobId === null ? item.EngagementName : item.JobId,
        jobCode: item.JobCode,
        instanceId: item.InstanceID,
        resourcId: item.ResourceId,
      };
      this.router.navigateByUrl('/EngagementScheduleView', {
        state: engDetails,
      });
    }
  }
  // navigateAvailabiltyReport() {
  //   let availabilityReport = this.user.RoleFeatures.filter(x => x.FeatureName == 'AvailabilityReportCSP');
  //   if (availabilityReport.length > 0) {
  //     const from = this.datepipe.transform(this.range.get('start').value, "yyyy-MM-dd");
  //     const to = this.datepipe.transform(this.range.get('end').value, "yyyy-MM-dd");
  //     let reportLink = availabilityReport[0].FeaturePath;
  //     reportLink = reportLink.replaceAll("[[RESOURCE_NAME]]", this.gridSelectedUser);
  //     reportLink = reportLink.replaceAll("[[FROM_DATE]]", from);
  //     reportLink = reportLink.replaceAll("[[TO_DATE]]", to);
  //     this.availabilityReportLink = reportLink;
  //     window.open(this.availabilityReportLink, '_blank');
  //   }

  // }
  // navigateBookedReport() {
  //   let bookedReport = this.user.RoleFeatures.filter(x => x.FeatureName == 'BookedTimeReportCSP');
  //   if (bookedReport.length > 0) {
  //     const from = this.datepipe.transform(this.range.get('start').value, "yyyy-MM-dd");
  //     const to = this.datepipe.transform(this.range.get('end').value, "yyyy-MM-dd");
  //     let reportLink = bookedReport[0].FeaturePath;
  //     reportLink = reportLink.replaceAll("[[RESOURCE_NAME]]", this.gridSelectedUser);
  //     reportLink = reportLink.replaceAll("[[FROM_DATE]]", from);
  //     reportLink = reportLink.replaceAll("[[TO_DATE]]", to);
  //     this.bookedTimeReportLink = reportLink;
  //     window.open(this.bookedTimeReportLink, '_blank');
  //   }
  // }

  // added for faviourite Engagement serching functionality ----------- START

  private AddFavouriteEngagement(arr): void {
    this.scheduledService.addFavouriteJobs(arr).subscribe((data) => {
      this.showEngList = false;
      this.showCopyList = false;
      this.showFavouriteEngList = false;
      this.copyshowFavouriteEngList = false;
      // this.replaceshowFavouriteEngList = false;
      this.searchAddEngagement = '';
      this.isFavEngONClick = true;
      this.copyisFavEngONClick = true;
      this.replaceisFavEngONClick = true;
      this.isVerifyOnEngClick = undefined;
      this.favEngReplaceIndex = -1;
    });
  }

  onEngagementClick() {
    let ele: any;
    if (this.validateSave()) {
      return false;
    }
    this.strFunction = 'simple';
    this.isVerifyOnEngClick = true;
    this.showFavouriteEngList = true;

    // Hide Replace/copy flags
    this.favEngReplaceIndex = -1;
    this.copyshowFavouriteEngList = false;
    this.IsRepalceDataNotFound = false;
    this.showCopyList = false;

    if (this.searchAddEngagement != null) {
      if (this.searchAddEngagement.trim().length >= 2) {
        this.showFavouriteEngList = false;
        this.isFavEngONClick = false;
      } else {
        this.showFavouriteEngList = true;
        this.isFavEngONClick = true;
      }
    }
    if (this.isFavEngONClick) {
      this.getFavouriteEngagement(ele);
    }
  }

  onCopyEngagementClick(element, index) {
    this.strFunction = 'copy';
    this.isVerifyOnEngClick = true;
    this.copyshowFavouriteEngList = true;

    // Hide Replace/Add flags
    this.favEngReplaceIndex = -1;
    element.replaceshowFavouriteEngList = false;
    this.showCopyList = false;
    this.showFavouriteEngList = false;
    this.showEngList = false;

    let val = this.myFormGroup.controls.copyEngName.value;
    if (val != null) {
      if (val.trim().length >= 2) {
        this.copyshowFavouriteEngList = false;
        this.copyisFavEngONClick = false;
      } else {
        this.copyisFavEngONClick = true;
        this.copyshowFavouriteEngList = true;
      }
    }

    if (this.copyisFavEngONClick) {
      this.getFavouriteEngagement(element);
    }
  }

  onReplaceEngagementClick(element) {
    this.strFunction = 'replace';
    element.showReplaceEngList = true;
    this.favEngReplaceIndex = 0;
    this.isVerifyOnEngClick = true;
    element.replaceshowFavouriteEngList = true;

    // Hide Copy/Add flags
    this.showCopyList = false;
    this.showFavouriteEngList = false;
    this.copyshowFavouriteEngList = false;
    this.showEngList = false;

    let val = element.searchEngagement;
    if (val != null) {
      if (val.trim().length >= 2) {
        element.replaceshowFavouriteEngList = false;
        this.replaceisFavEngONClick = false;
      } else {
        element.replaceshowFavouriteEngList = true;
        this.replaceisFavEngONClick = true;
      }
    }

    if (this.replaceisFavEngONClick) {
      this.getFavouriteEngagement(element);
    }
  }

  private getFavouriteEngagement(element): void {
    //debugger;
    let favouriteJobs = new FavouriteJobsDto();
    this.addFavouriteEngResults = [];
    favouriteJobs.InstanceId = this.strFunction === 'replace' ? element.InstanceID : this.addBookingInstance;
    favouriteJobs.IsSearchfromIndivBooking = true;
    favouriteJobs.JobCategory = '';
    favouriteJobs.ResourceID = !this.resourceId ? 0 : this.resourceId;
    if (this.showFavouriteEngList) {
      this.showEngLoader = true;
    }
    if (this.copyshowFavouriteEngList) {
      this.showEngCopyLoader = true;
    }
    if (this.strFunction !== "simple") {
      if (element.replaceshowFavouriteEngList) {
        this.showReplaceEngLoader = true;
      }
    }

    this.scheduledService
      .GetFavouriteJobsIndividualView(favouriteJobs)
      .subscribe((data) => {
        const key = 'JobId';

        const resultEng = [
          ...new Map(data.map((item) => [item[key], item])).values(),
        ];
        this.addFavouriteEngResults = resultEng;
        if (this.showFavouriteEngList) {
          this.isFavEngONClick = false;
          this.showEngLoader = false;
        }
        if (this.copyshowFavouriteEngList) {
          this.copyisFavEngONClick = false;
          this.IsCopyDataNotFound = false;
          this.showEngCopyLoader = false;
        }

        if (this.strFunction !== "simple") {
          if (element.replaceshowFavouriteEngList) {
            this.replaceisFavEngONClick = false;
            this.IsRepalceDataNotFound = false;
            this.showReplaceEngLoader = false;
          }
        }

        if (this.addFavouriteEngResults.length > 0) {
          this.addFavouriteEngResults.forEach((i) => {
            i.IsFavEngInserted = true;
            i.favEngSrc = favEngImageSrc.favEngImgStarfilled;
          });
        } else {
          this.showEngLoader = false;

          if (this.copyshowFavouriteEngList) {
            this.IsCopyDataNotFound = true;
            this.showEngCopyLoader = false;
          }
          if (this.strFunction !== "simple") {
            if (element.replaceshowFavouriteEngList) {
              this.IsRepalceDataNotFound = true;
              this.showReplaceEngLoader = false;
            }
          }
        }
      });
  }

  onFavoriteImageClick(item) {
    this.FavoriteImageClick = true;
    item.IsOldFavEngInserted = item.IsFavEngInserted;
    item.IsFavEngInserted = !item.IsFavEngInserted;

    if (item.IsFavEngInserted) {
      item.favEngSrc = favEngImageSrc.favEngImgStarfilled;
    } else {
      item.favEngSrc = favEngImageSrc.favEngImgStarOutline;
    }
  }

  private favoriteimgClickHandler(event: any) {
    if (!!event.target.src) {
      var parts = event.target.src.split('/');
      var img = parts[parts.length - 1];

      var a = favEngImageSrc.favEngImgStarOutline.split('/');
      var ImgStarOutline = a[a.length - 1];

      var b = favEngImageSrc.favEngImgStarfilled.split('/');
      var ImgStarfilled = b[b.length - 1];

      // if((event.target.src === favEngImageSrc.favEngImgStarOutline) || (event.target.src === favEngImageSrc.favEngImgStarfilled))
      if (img === ImgStarOutline || img === ImgStarfilled) {
        this.isClickonFavEngStart = true;
        this.outClickCount = 0;
        //console.log("in side click");
      } else {
        this.isClickonFavEngStart = false;
        if (this.outClickCount == 0) {
          this.outClickCount++;
        }
      }
    } else {
      if (this.isClickonFavEngStart) {
        if (this.outClickCount == 0) {
          this.outClickCount++;

          this.getAllSelectedfavEngagement();
        }
      }
      // console.log("out click ");
    }
  }

  private getAllSelectedfavEngagement() {
    let typeArr: Array<FavouriteJobsTableTypeDto>;
    typeArr = [];
    let dataSE;
    if (this.outClickCount > 0) {
      this.outClickCount = 0;
      this.isClickonFavEngStart = false;
      this.compareFavouriteEngResults = [];
      if (!this.isVerifyOnEngClick) {
        this.sortedEngagementList = !!this.sortedEngagementList
          ? this.sortedEngagementList
          : [];
        this.sortedCopyEngagementList = !!this.sortedCopyEngagementList
          ? this.sortedCopyEngagementList
          : [];
        this.sortedReplaceEngList = !!this.sortedReplaceEngList
          ? this.sortedReplaceEngList
          : [];

        if (
          this.sortedEngagementList.length > 0 &&
          this.strFunction === 'simple'
        ) {
          dataSE = this.sortedEngagementList.filter(
            (ap) =>
              ap.IsOldFavEngInserted === true ||
              ap.IsOldFavEngInserted === false
          );
        }

        if (
          this.sortedCopyEngagementList.length > 0 &&
          this.strFunction === 'copy'
        ) {
          dataSE = this.sortedCopyEngagementList.filter(
            (ap) =>
              ap.IsOldFavEngInserted === true ||
              ap.IsOldFavEngInserted === false
          );
        }
        if (
          this.sortedReplaceEngList.length > 0 &&
          this.strFunction === 'replace'
        ) {
          dataSE = this.sortedReplaceEngList.filter(
            (ap) =>
              ap.IsOldFavEngInserted === true ||
              ap.IsOldFavEngInserted === false
          );
        }
      } else {
        dataSE = this.addFavouriteEngResults.filter(
          (ap) =>
            ap.IsOldFavEngInserted === true || ap.IsOldFavEngInserted === false
        );
      }

      if (dataSE.length > 0) {
        dataSE.forEach((data) => {
          if (
            data.IsOldFavEngInserted === true ||
            data.IsOldFavEngInserted === false
          ) {
            this.compareFavouriteEngResults.push(data);
            //console.log(this.compareFavouriteEngResults);
          }
        });
      }

      if (
        this.addFavouriteEngResults.length > 0 ||
        !!this.addFavouriteEngResults
      ) {
        let arr = [];
        this.compareFavouriteEngResults.forEach((op) => {
          if (!op.IsFavEngInserted) {
            if (this.addFavouriteEngResults.some((g) => g.JobId === op.JobId)) {
              op.InstanceId = this.addBookingInstance;
              op.JobCategory = '';
              arr.push(op);
            }
          } else {
            op.InstanceId = this.addBookingInstance;
            op.JobCategory = '';
            arr.push(op);
          }
        });
        if (arr.length > 0) {
          arr.forEach((tt) => {
            let obj = new FavouriteJobsTableTypeDto();
            obj.LoggedInUserId = this.user.UserId;
            obj.JobID = tt.JobId;
            obj.Instance_Id = tt.InstanceId;
            obj.IsFavEngInserted = tt.IsFavEngInserted;
            typeArr.push(obj);
          });
          let k = new FavouriteJobsAddActionDto();
          k.FavouriteJobs = typeArr;

          this.AddFavouriteEngagement(k);
        }
      }
    }
  }

  //********************  -----------------------------  END */

  navigateToCspReport(reportName: string) {
    if (reportName == 'AvailabilityReportCSP') {
      this.availabilityReport = this.user.RoleFeatures.filter(
        (x) => x.FeatureName == 'AvailabilityReportCSP'
      );
      const from = this.datepipe.transform(
        this.range.get('start').value,
        'yyyy-MM-dd'
      );
      const to = this.datepipe.transform(
        this.range.get('end').value,
        'yyyy-MM-dd'
      );
      //let reportLink = availabilityReport[0].ReportLink;
      let reportLink = this.availabilityReport[0].FeaturePath;
      reportLink = reportLink.replaceAll(
        '[[RESOURCE_NAME]]',
        this.gridSelectedUser
      );
      reportLink = reportLink.replaceAll('[[FROM_DATE]]', from);
      reportLink = reportLink.replaceAll('[[TO_DATE]]', to);
      this.availabilityReportLink = reportLink;
      window.open(this.availabilityReportLink, '_blank');
    } else if (reportName == 'BookedTimeReportCSP') {
      this.bookedReport = this.user.RoleFeatures.filter(
        (x) => x.FeatureName == 'BookedTimeReportCSP'
      );
      const from = this.datepipe.transform(
        this.range.get('start').value,
        'yyyy-MM-dd'
      );
      const to = this.datepipe.transform(
        this.range.get('end').value,
        'yyyy-MM-dd'
      );
      //let reportLink = bookedReport[0].ReportLink;
      let reportLink = this.bookedReport[0].FeaturePath;
      reportLink = reportLink.replaceAll(
        '[[RESOURCE_NAME]]',
        this.gridSelectedUser
      );
      reportLink = reportLink.replaceAll('[[FROM_DATE]]', from);
      reportLink = reportLink.replaceAll('[[TO_DATE]]', to);
      this.bookedTimeReportLink = reportLink;
      window.open(this.bookedTimeReportLink, '_blank');
    }
  }
}
