<ngx-spinner></ngx-spinner>
<div class="homePage" id="homeContainer">
  <div class="home-animation">
    <img src="../../assets/images/1.png" class="img-1" />
    <img src="../../assets/images/2.png" class="img-2" />
    <img src="../../assets/images/3.png" class="img-3" />
    <img src="../../assets/images/3-1.png" class="img-3" />
    <img src="../../assets/images/3-1.png" class="img-3-1" />
    <img src="../../assets/images/3-2.png" class="img-3-2" />
    <img src="../../assets/images/4.png" class="img-4" />
    <img src="../../assets/images/5.png" class="img-5" />
    <img src="../../assets/images/6.png" class="img-6" />
    <img src="../../assets/images/7.gif" class="img-7" />
    <img src="../../assets/images/8.gif" class="img-8" />
    <img src="../../assets/images/9.png" class="img-9" />
    <img src="../../assets/images/10.png" class="img-10" />
  </div>
  <div class="homeAppName">
    <div>
      <div class="appName">MERA</div>
      <div class="apptagline">MERA is a cloud based resourcing solution designed to transform the way Resource
        Management services are delivered to the business, enabling client serving teams to update and maintain either
        their Individual and Engagement team forecasts in Retain. </div>
        <div class="approvalInfo" *ngIf="isApprovalQueue && !isMobileDevice" (click)="linkNavigation('ApprovalLayer')">
        <div class="approvalContent">
          <span class="approvalText">Awaiting Approvals</span>
          <div class="approvalCount" *ngIf="count">{{count.AwaitingApprovals}}</div>
        </div>
        <div class="approvalAction">
          <mat-icon>arrow_forward</mat-icon>
        </div>
      </div>
    </div>
    <div class="infoSection2" *ngIf="count">
      <div class="countContainer">
        <div class="countText">{{count.ActiveUsers}}</div>
        <div class="countType">Active Users</div>
      </div>
      <!-- <div class="countContainer">
        <div class="countText">{{count.TodaysBookings}}</div>
        <div class="countType">Today's Bookings</div>
      </div> -->
      <div class="countContainer">
        <div class="countText">{{count.RequestFromWeb}}</div>
        <div class="countType">Web Requests</div>
      </div>
      <div class="countContainer">
        <div class="countText">{{count.RequestFromMobile}}</div>
        <div class="countType">Mobile Requests</div>
      </div>

      <div class="countContainer">
        <div class="countText">{{count.BookingsSubmitted}}</div>
        <div class="countType">Requests Submitted</div>
      </div>

      <div class="countContainer">
        <div class="countText">{{count.BookingsInProgress}}</div>
        <div class="countType">Requests In-Progress</div>
      </div>

      <div class="countContainer">
        <div class="countText">{{count.BookingsCompleted}}</div>
        <div class="countType">Requests Completed</div>
      </div>
    </div>
  </div>
  <div class="appInfoSection">
    <div class="infoSection1">
      <div class="refreshInfo" *ngIf="count">
        <div class="refreshHead">
          <span (click)="lastRefresh = true" [ngClass]="{'activeRefresh': lastRefresh === true}">Last Refresh
            Date/Time</span>
          <span (click)="lastRefresh = false" [ngClass]="{'activeRefresh': lastRefresh === false}">Next Refresh
            Date/Time</span>
        </div>
        <mat-accordion *ngIf="lastRefresh === true">
          <mat-expansion-panel *ngFor="let instance of count.LastRefreshTimeList">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{instance.InstanceName}}
              </mat-panel-title>

            </mat-expansion-panel-header>
            <div class="refreshList">
              <div class="refreshItem" *ngFor="let time of instance.DatafeedRefreshList"><span
                  class="refreshType">{{time.DatafeedName}}</span><span class="refreshValue">{{time.RefreshTime | date
                  : defaultDateFormat+', h:mm a'}} GMT</span></div>

            </div>
          </mat-expansion-panel>

        </mat-accordion>
        <mat-accordion *ngIf="lastRefresh === false">
          <mat-expansion-panel *ngFor="let instance of count.NextRefreshTimeList">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{instance.InstanceName}}
              </mat-panel-title>

            </mat-expansion-panel-header>
            <div class="refreshList">
              <div class="refreshItem" *ngFor="let time of instance.DatafeedRefreshList"><span
                  class="refreshType">{{time.DatafeedName}}</span><span class="refreshValue">{{time.RefreshTime | date
                  :defaultDateFormat+', h:mm a'}} GMT</span></div>

            </div>
          </mat-expansion-panel>

        </mat-accordion>
      </div>
    </div>
  </div>

</div>