export class SwapResourceVM {

    BookingId: number;
    ResourceId: number | null;
    JobId: number | null;
    BookingType: string;
    StartDate: string | null;
    EndDate: string | null;
    Hours: number | null;
    Loading: number | null;
    Description: string;
    UpdatedOn: Date | null;
    UpdatedBy: string;
    EngagementCode: string;
    Engagement: string;
    ActionDescriptionId: number;
    MeraBookingStatusId: number;
    Unconfirmed: boolean;
    EngagementType: string;
    Rank: number;
    
    ChildRowsExist: boolean;
    JobStatus: string;
    ServiceLineCode: string;
    ServiceLineDescription: string;

    ClientDescription: string;
    JobType: string;
    RankDescription: string;
    LocationDescription: string;

    JobCode:string;
    JobDescription:string;
    JobCategory:string;
    ResourceName:string;

    BsRangeDate: Date[];
    IsPercentSelected: boolean;

    Ghost: boolean;
}