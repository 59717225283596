<form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
  <div class="popupLayout">
    <div
      *ngIf="
        loggedInUser.IsMultiInstance && loggedInUser.R_InstEnable_AddBooking
      "
      class="fullWidth"
    >
      <label>Retain Instance</label>
      <mat-form-field appearance="outline">
        <mat-select
          [(value)]="selectedInstance"
          (selectionChange)="onInstanceChange($event)"
          placeholder="Retain instance"
        >
          <mat-option
            [value]="option.InstanceId"
            *ngFor="let option of instances"
            >{{ option.InstanceName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="customRow">
      <div class="halfWidthItem">
        <label>Engagement Category</label>
        <mat-form-field appearance="outline">
          <mat-select
            (selectionChange)="onEngagementCategoryChange($event)"
            [(value)]="jobCategory"
          >
            <mat-option value="Chargeable">Chargeable</mat-option>
            <mat-option value="Authorised Project"
              >Authorised Project
            </mat-option>
            <mat-option value="Non Chargeable">Non Chargeable</mat-option>
            <!-- <mat-option value="-1">All</mat-option> -->
          </mat-select>
        </mat-form-field>
      </div>
      <div class="engSearch">
        <label>Engagement <span class="mandtoryIcn"> *</span></label>
        <mat-form-field
          class="resourceSearch"
          appearance="outline"
          [ngClass]="{ 'is-invalid': isValidInput('EngagementName') }"
        >
          <input
            type="text"
            formControlName="EngagementName"
            placeholder="Search Engagement..."
            matInput
            autocomplete="off"
            appSearchDebounce
            (debounceKeyup)="search()"
            maxlength="200"
            (click)="onEngagementClick()"
          />
          <mat-icon (click)="onEngagementClear()">close</mat-icon>
        </mat-form-field>
        <div *ngIf="isValidInput('EngagementName')" class="alert alert-danger">
          <div *ngIf="formGroup.controls['EngagementName'].errors.required">
            Engagement is required.
          </div>
        </div>
        <!-- for Favourite  Engagement --- --------------------   START -->
        <div class="dropdown-list-wrap" *ngIf="showFavouriteEngList">
          <mat-spinner *ngIf="showSearchLoader" [diameter]="40"></mat-spinner>

          <!-- <ul  class="dropdown-list tabdropdwn_actn" *ngIf="addFavouriteEngResults?.length===0">
            <li>
                <span style="padding-left:8px;color:#ffe600;padding-top: 4px;">
                {{lbl}}</span>
            </li>
          </ul> -->
          <ul
            *ngIf="addFavouriteEngResults?.length > 0"
            class="dropdown-list tabdropdwn_actn"
          >
            <li><span class="favouriteText">Favourites</span></li>
            <li
              *ngFor="let item of addFavouriteEngResults; let i = index"
              (click)="OnItemSelect(item)"
              (keyup.enter)="OnItemSelect(item)"
              class="dropdown-list-item"
              tabindex="0"
            >
              <span class="EngFullname"> {{ item.EngagementFullName }} </span>

              <img
                #Favimage1
                (click)="onFavoriteImageClick(item)"
                class="favouriteIcon exportExcelIcon"
                src="../../../../{{ imagePath }}{{ item.favEngSrc }}"
              />
            </li>
          </ul>
          <div
            *ngIf="
              showSearchLoader === false &&
              addFavouriteEngResults?.length === 0 &&
              IsDataNotFound === true
            "
          >
            <!-- <span style="padding-left:8px;color:#ffe600;padding-top: 2px;">
            No Engagement Found</span> -->
            No Engagement Found
          </div>
        </div>
        <!-- for Favourite  Engagement --- --------------------   END -->

        <div class="dropdown-list-wrap" *ngIf="showSearchList">
          <mat-spinner *ngIf="showSearchLoader" [diameter]="40"></mat-spinner>
          <ul *ngIf="results.length > 0" class="dropdown-list tabdropdwn_actn">
            <li
              *ngFor="let item of results; let i = index"
              (click)="OnItemSelect(item)"
              (keyup.enter)="OnItemSelect(item)"
              class="dropdown-list-item"
              tabindex="0"
            >
              <span class="EngFullname"> {{ item.EngagementFullName }} </span>

              <img
                #Favimage1
                (click)="onFavoriteImageClick(item)"
                class="exportExcelIcon favouriteIcon"
                src="../../../../{{ imagePath }}{{ item.favEngSrc }}"
              />
            </li>
          </ul>
          <div
            *ngIf="
              showSearchLoader === false &&
              results?.length === 0 &&
              IsDataNotFound === true
            "
          >
            No Engagement Found
          </div>
        </div>
      </div>
    </div>
    <div class="customRow">
      <div class="halfWidthItem">
        <label>Engagement Type</label>
        <mat-form-field appearance="outline">
          <input
            type="text"
            matInput
            (change)="makeDirty(bookingType)"
            [(ngModel)]="bookingType"
            [readonly]="true"
            [ngModelOptions]="{ standalone: true }"
          />
        </mat-form-field>
      </div>
      <div class="halfWidthItem">
        <label>Engagement Code</label>
        <mat-form-field appearance="outline">
          <input
            type="text"
            matInput
            (change)="makeDirty(engagementCode)"
            [(ngModel)]="engagementCode"
            [readonly]="true"
            [ngModelOptions]="{ standalone: true }"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="customRow">
      <div class="halfWidthItem">
        <label class="formLabel">Engagement Service Line</label>
        <mat-form-field appearance="outline">
          <input
            type="text"
            matInput
            (change)="makeDirty(engagementServiceLine)"
            [(ngModel)]="engagementServiceLine"
            [readonly]="true"
            [ngModelOptions]="{ standalone: true }"
          />
        </mat-form-field>
      </div>
      <div class="halfWidthItem">
        <label class="formLabel">Engagement Status</label>
        <mat-form-field appearance="outline">
          <input
            type="text"
            matInput
            (change)="makeDirty(engagementStatus)"
            [(ngModel)]="engagementStatus"
            [readonly]="true"
            [ngModelOptions]="{ standalone: true }"
          />
        </mat-form-field>
      </div>
    </div>
    <div id="split1" class="periodContainer">
      <div class="estimationContainer">
        <div class="dateSearch">
          <label>Period <span class="mandtoryIcn"> *</span></label>
          <mat-form-field appearance="outline">
            <mat-date-range-input
              formGroupName="period1"
              [rangePicker]="picker"
              [min]="startWith"
            >
              <input
                matStartDate
                formControlName="start1"
                placeholder="Start Date"
                (focus)="picker.open()"
                (click)="picker.open()"
                readonly
              />
              <input
                matEndDate
                formControlName="end1"
                placeholder="End Date"
                (focus)="picker.open()"
                (click)="picker.open()"
                readonly
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <mat-error
              *ngIf="
                formGroup
                  .get('period1')
                  .get('start1')
                  .hasError('matStartDateInvalid')
              "
              >Invalid Start Date</mat-error
            >
            <mat-error
              *ngIf="
                formGroup
                  .get('period1')
                  .get('end1')
                  .hasError('matEndDateInvalid')
              "
              >Invalid End Date</mat-error
            >
          </mat-form-field>
          <!-- <div *ngIf="formGroup.get('period1').get('start1').value === null && (formGroup.get('period1').get('start1').dirty || formGroup.get('period1').get('start1').touched)" class="alert alert-danger">
            Start Date is required
          </div> -->
          <div
            *ngIf="
              (formGroup.get('period1').get('start1').value === null &&
                (formGroup.get('period1').get('start1').dirty ||
                  formGroup.get('period1').get('start1').touched)) ||
              (formGroup.get('period1').get('end1').value === null &&
                (formGroup.get('period1').get('end1').dirty ||
                  formGroup.get('period1').get('end1').touched))
            "
            class="alert alert-danger"
          >
            Start date and End date are required
          </div>
        </div>
        <div class="estimation">
          <label
            >{{ engagementAllocationText }}
            <span class="mandtoryIcn"> *</span></label
          >
          <mat-form-field appearance="outline">
            <input
              type="text"
              matInput
              (input)="makeDirty('Hours_1')"
              (focus)="mouseEnter()"
              autocomplete="off"
              #tooltip="matTooltip"
              [matTooltip]="
                isPercentageSelected
                  ? ''
                  : 'Note when adding time in hours this will be input for the full-time period of the booking, not per week. For long term bookings Loading% may be the preferred way to input time allocation to work (please see “preference” button in top right hand corner to switch between the time allocation input methods)'
              "
              triggers="hover click focus"
              placement="bottom"
              (focusout)="mouseLeave(engagementAllocationTypeValue)"
              formControlName="EngagementAllocation"
              appHoursLoading
              [isPercentageSelected]="isPercentageSelected"
            />
          </mat-form-field>
          <div *ngIf="isValidInput('EngagementAllocation')">
            <div
              *ngIf="formGroup.controls['EngagementAllocation'].errors.required"
              class="alert alert-danger"
            >
              {{ engagementAllocationText }} is required.
            </div>
            <div
              *ngIf="formGroup.controls['EngagementAllocation'].errors.pattern"
              class="alert alert-danger"
            >
              {{ engagementAllocationText }} is invalid.
            </div>
          </div>
        </div>
        <div class="confirmContainer" *ngIf="!loggedInUser?.Unconfirmed">
          <label>Unconfirmed</label>
          <mat-checkbox
            [(ngModel)]="unconfirmed"
            [disableRipple]="true"
            (change)="makeDirty(unconfirmed)"
            [ngModelOptions]="{ standalone: true }"
          ></mat-checkbox>
        </div>
        <div *ngIf="loggedInUser.Ghost" class="confirmContainer">
          <label>Ghost</label>
          <mat-checkbox
            [(ngModel)]="Ghost"
            [disableRipple]="true"
            (change)="makeDirty('')"
            [ngModelOptions]="{ standalone: true }"
          ></mat-checkbox>
        </div>
        <div class="morePeriods">
          <mat-icon class="addPeriod" (click)="showSplit()">add</mat-icon>
        </div>
      </div>
      <div class="overallocationWarning">
        <div class="allocationText" [class.disabled]="overallocationerror_1">
          <label *ngIf="!isHoursValid"
            >This Booking request will cause an overallocation. If you wish to
            proceed, please select the check box and click on Submit.</label
          >
        </div>
        <div class="confirmAllocation" [class.disabled]="overallocationerror_1">
          <label *ngIf="!isHoursValid">Confirm</label>
          <mat-checkbox
            [disableRipple]="true"
            [(ngModel)]="confirmHours"
            (change)="confirmHoursChange('EngagementAllocation')"
            *ngIf="!isHoursValid"
            [ngModelOptions]="{ standalone: true }"
          ></mat-checkbox>
          <!-- <input type="checkbox" class="check" (change)="confirmHoursChange('EngagementAllocation')" *ngIf="!isHoursValid"
            [(ngModel)]="confirmHours" [ngModelOptions]="{standalone:true}"> -->
        </div>
      </div>
    </div>

    <div id="split2" *ngIf="showSplit_2" class="periodContainer">
      <div class="estimationContainer">
        <div class="dateSearch">
          <label>Period<span class="mandtoryIcn"> *</span></label>
          <mat-form-field appearance="outline">
            <mat-date-range-input
              formGroupName="period2"
              [rangePicker]="picker2"
              [min]="startWith"
            >
              <input
                matStartDate
                formControlName="start2"
                placeholder="Start Date"
                (focus)="picker2.open()"
                (click)="picker2.open()"
                readonly
              />
              <input
                matEndDate
                formControlName="end2"
                placeholder="End Date"
                (focus)="picker2.open()"
                (click)="picker2.open()"
                readonly
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker2"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker2></mat-date-range-picker>
            <mat-error
              *ngIf="
                formGroup
                  .get('period2')
                  .get('start2')
                  .hasError('matStartDateInvalid')
              "
              >Invalid Start Date</mat-error
            >
            <mat-error
              *ngIf="
                formGroup
                  .get('period2')
                  .get('end2')
                  .hasError('matEndDateInvalid')
              "
              >Invalid End Date</mat-error
            >
          </mat-form-field>
          <div
            *ngIf="
              (formGroup.get('period2').get('start2').value === null &&
                (formGroup.get('period2').get('start2').dirty ||
                  formGroup.get('period2').get('start2').touched)) ||
              (formGroup.get('period2').get('end2').value === null &&
                (formGroup.get('period2').get('end2').dirty ||
                  formGroup.get('period2').get('end2').touched))
            "
            class="alert alert-danger"
          >
            Start date and End date are required
          </div>
        </div>
        <div class="estimation">
          <label class="formLabel"
            >{{ engagementAllocationText }}
            <span class="mandtoryIcn"> *</span></label
          >
          <mat-form-field
            appearance="outline"
            [ngClass]="{ 'is-invalid': isValidInput('EngagementAllocation') }"
          >
            <input
              type="text"
              matInput
              (input)="makeDirty('Hours_2')"
              (focus)="mouseEnterSplit()"
              autocomplete="off"
              #tooltip="matTooltip"
              [matTooltip]="
                isPercentageSelected
                  ? ''
                  : 'Note when adding time in hours this will be input for the full-time period of the booking, not per week. For long term bookings Loading% may be the preferred way to input time allocation to work (please see “preference” button in top right hand corner to switch between the time allocation input methods)'
              "
              triggers="hover click focus"
              placement="bottom"
              (focusout)="mouseLeave(engagementAllocationTypeValue_2)"
              formControlName="allocationValue_2"
              appHoursLoading
              [isPercentageSelected]="isPercentageSelected"
            />
          </mat-form-field>
          <div *ngIf="isValidInput('allocationValue_2')">
            <div
              *ngIf="formGroup.controls['allocationValue_2'].errors.required"
              class="alert alert-danger"
            >
              {{ engagementAllocationText }} is required.
            </div>
            <div
              *ngIf="formGroup.controls['allocationValue_2'].errors.pattern"
              class="alert alert-danger"
            >
              {{ engagementAllocationText }} is invalid.
            </div>
          </div>
        </div>
        <div class="confirmContainer" *ngIf="!loggedInUser?.Unconfirmed">
          <label>Unconfirmed</label>
          <mat-checkbox
            [disableRipple]="true"
            (change)="makeDirty('')"
            [(ngModel)]="unconfirmed_2"
            [ngModelOptions]="{ standalone: true }"
          ></mat-checkbox>
        </div>
        <div *ngIf="loggedInUser.Ghost" class="confirmContainer">
          <label>Ghost</label>
          <mat-checkbox
            [(ngModel)]="Ghost_2"
            [disableRipple]="true"
            (change)="makeDirty('')"
            [ngModelOptions]="{ standalone: true }"
          ></mat-checkbox>
        </div>
        <div class="morePeriods">
          <mat-icon class="addPeriod" (click)="hideSplit(2)">remove</mat-icon>
          <!-- <span class="addIcon"><span class="	glyphicon glyphicon-minus" (click)="hideSplit(2)"></span></span> -->
        </div>
      </div>
      <div class="overallocationWarning">
        <div class="allocationText" [class.disabled]="overallocationerror_2">
          <label *ngIf="!isHoursValid_2"
            >This Booking request will cause an overallocation. If you wish to
            proceed, please select the check box and click on Submit.</label
          >
        </div>
        <div class="confirmAllocation" [class.disabled]="overallocationerror_2">
          <label *ngIf="!isHoursValid_2">Confirm</label>
          <mat-checkbox
            [disableRipple]="true"
            (change)="confirmHoursChange('allocationValue_2')"
            *ngIf="!isHoursValid_2"
            [(ngModel)]="confirmHours_2"
            [ngModelOptions]="{ standalone: true }"
          ></mat-checkbox>
        </div>
      </div>
    </div>

    <div id="split3" *ngIf="showSplit_3" class="periodContainer">
      <div class="estimationContainer">
        <div class="dateSearch">
          <label>Period <span class="mandtoryIcn"> *</span></label>
          <mat-form-field appearance="outline">
            <mat-date-range-input
              formGroupName="period3"
              [rangePicker]="picker3"
              [min]="startWith"
            >
              <input
                matStartDate
                formControlName="start3"
                placeholder="Start Date"
                (focus)="picker3.open()"
                (click)="picker3.open()"
                readonly
              />
              <input
                matEndDate
                formControlName="end3"
                placeholder="End Date"
                (focus)="picker3.open()"
                (click)="picker3.open()"
                readonly
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker3"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker3></mat-date-range-picker>
            <mat-error
              *ngIf="
                formGroup
                  .get('period3')
                  .get('start3')
                  .hasError('matStartDateInvalid')
              "
              >Invalid Start Date</mat-error
            >
            <mat-error
              *ngIf="
                formGroup
                  .get('period3')
                  .get('end3')
                  .hasError('matEndDateInvalid')
              "
              >Invalid End Date</mat-error
            >
          </mat-form-field>
          <div
            *ngIf="
              (formGroup.get('period3').get('start3').value === null &&
                (formGroup.get('period3').get('start3').dirty ||
                  formGroup.get('period3').get('start3').touched)) ||
              (formGroup.get('period3').get('end3').value === null &&
                (formGroup.get('period3').get('end3').dirty ||
                  formGroup.get('period3').get('end3').touched))
            "
            class="alert alert-danger"
          >
            Start date and End date are required
          </div>
        </div>
        <div class="estimation">
          <label
            >{{ engagementAllocationText }}
            <span class="mandtoryIcn"> *</span></label
          >
          <mat-form-field
            appearance="outline"
            [ngClass]="{ 'is-invalid': isValidInput('EngagementAllocation') }"
          >
            <input
              type="text"
              matInput
              (input)="makeDirty('Hours_3')"
              (focus)="mouseEnter()"
              autocomplete="off"
              #tooltip="matTooltip"
              [matTooltip]="
                isPercentageSelected
                  ? ''
                  : 'Note when adding time in hours this will be input for the full-time period of the booking, not per week. For long term bookings Loading% may be the preferred way to input time allocation to work (please see “preference” button in top right hand corner to switch between the time allocation input methods)'
              "
              triggers="hover click focus"
              placement="bottom"
              (focusout)="mouseLeave(engagementAllocationTypeValue_3)"
              formControlName="allocationValue_3"
              appHoursLoading
              [isPercentageSelected]="isPercentageSelected"
            />
          </mat-form-field>
          <div *ngIf="isValidInput('allocationValue_3')">
            <div
              *ngIf="formGroup.controls['allocationValue_3'].errors.required"
              class="alert alert-danger"
            >
              {{ engagementAllocationText }} is required.
            </div>
            <div
              *ngIf="formGroup.controls['allocationValue_3'].errors.pattern"
              class="alert alert-danger"
            >
              {{ engagementAllocationText }} is invalid.
            </div>
          </div>
        </div>
        <div class="confirmContainer" *ngIf="!loggedInUser?.Unconfirmed">
          <label>Unconfirmed</label>
          <mat-checkbox
            [disableRipple]="true"
            (change)="makeDirty('')"
            [(ngModel)]="unconfirmed_3"
            [ngModelOptions]="{ standalone: true }"
          ></mat-checkbox>
        </div>
        <div *ngIf="loggedInUser.Ghost" class="confirmContainer">
          <label>Ghost</label>
          <mat-checkbox
            [(ngModel)]="Ghost_3"
            [disableRipple]="true"
            (change)="makeDirty('')"
            [ngModelOptions]="{ standalone: true }"
          ></mat-checkbox>
        </div>
        <div class="morePeriods">
          <mat-icon class="addPeriod" (click)="hideSplit(3)">remove</mat-icon>
          <!-- <span class="addIcon"> <span class="	glyphicon glyphicon-minus" (click)="hideSplit(3)"></span></span> -->
        </div>
      </div>
      <div class="overallocationWarning">
        <div class="allocationText" [class.disabled]="overallocationerror_3">
          <label *ngIf="!isHoursValid_3"
            >This Booking request will cause an overallocation. If you wish to
            proceed, please select the check box and click on Submit.</label
          >
        </div>
        <div class="confirmAllocation" [class.disabled]="overallocationerror_3">
          <label *ngIf="!isHoursValid_3">Confirm</label>
          <mat-checkbox
            [disableRipple]="true"
            (change)="confirmHoursChange('allocationValue_3')"
            *ngIf="!isHoursValid_3"
            [(ngModel)]="confirmHours_3"
            [ngModelOptions]="{ standalone: true }"
          ></mat-checkbox>
        </div>
      </div>
    </div>

    <div id="split4" *ngIf="showSplit_4" class="periodContainer">
      <div class="estimationContainer">
        <div class="dateSearch">
          <label>Period<span class="mandtoryIcn"> *</span></label>
          <mat-form-field appearance="outline">
            <mat-date-range-input
              formGroupName="period4"
              [rangePicker]="picker4"
              [min]="startWith"
            >
              <input
                matStartDate
                formControlName="start4"
                placeholder="Start Date"
                (focus)="picker4.open()"
                (click)="picker4.open()"
                readonly
              />
              <input
                matEndDate
                formControlName="end4"
                placeholder="End Date"
                (focus)="picker4.open()"
                (click)="picker4.open()"
                readonly
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker4"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker4></mat-date-range-picker>
            <mat-error
              *ngIf="
                formGroup
                  .get('period4')
                  .get('start4')
                  .hasError('matStartDateInvalid')
              "
              >Invalid Start Date</mat-error
            >
            <mat-error
              *ngIf="
                formGroup
                  .get('period4')
                  .get('end4')
                  .hasError('matEndDateInvalid')
              "
              >Invalid End Date</mat-error
            >
          </mat-form-field>
          <div
            *ngIf="
              (formGroup.get('period4').get('start4').value === null &&
                (formGroup.get('period4').get('start4').dirty ||
                  formGroup.get('period4').get('start4').touched)) ||
              (formGroup.get('period4').get('end4').value === null &&
                (formGroup.get('period4').get('end4').dirty ||
                  formGroup.get('period4').get('end4').touched))
            "
            class="alert alert-danger"
          >
            Start date and End date are required
          </div>
        </div>
        <div class="estimation">
          <label
            >{{ engagementAllocationText
            }}<span class="mandtoryIcn"> *</span></label
          >
          <mat-form-field
            appearance="outline"
            [ngClass]="{ 'is-invalid': isValidInput('EngagementAllocation') }"
          >
            <input
              type="text"
              matInput
              (input)="makeDirty('Hours_4')"
              (focus)="mouseEnterSplit()"
              autocomplete="off"
              #tooltip="matTooltip"
              [matTooltip]="
                isPercentageSelected
                  ? ''
                  : 'Note when adding time in hours this will be input for the full-time period of the booking, not per week. For long term bookings Loading% may be the preferred way to input time allocation to work (please see “preference” button in top right hand corner to switch between the time allocation input methods)'
              "
              triggers="hover click focus"
              placement="bottom"
              (focusout)="mouseLeave(engagementAllocationTypeValue_4)"
              formControlName="allocationValue_4"
              appHoursLoading
              [isPercentageSelected]="isPercentageSelected"
            />
          </mat-form-field>
          <div *ngIf="isValidInput('allocationValue_4')">
            <div
              *ngIf="formGroup.controls['allocationValue_4'].errors.required"
              class="alert alert-danger"
            >
              {{ engagementAllocationText }} is required.
            </div>
            <div
              *ngIf="formGroup.controls['allocationValue_4'].errors.pattern"
              class="alert alert-danger"
            >
              {{ engagementAllocationText }} is invalid.
            </div>
          </div>
        </div>
        <div class="confirmContainer" *ngIf="!loggedInUser?.Unconfirmed">
          <label>Unconfirmed</label>
          <mat-checkbox
            [disableRipple]="true"
            (change)="makeDirty('')"
            [(ngModel)]="unconfirmed_4"
            [ngModelOptions]="{ standalone: true }"
          ></mat-checkbox>
        </div>
        <div *ngIf="loggedInUser.Ghost" class="confirmContainer">
          <label>Ghost</label>
          <mat-checkbox
            [(ngModel)]="Ghost_4"
            [disableRipple]="true"
            (change)="makeDirty('')"
            [ngModelOptions]="{ standalone: true }"
          ></mat-checkbox>
        </div>
        <div class="morePeriods">
          <mat-icon class="addPeriod" (click)="hideSplit(4)">remove</mat-icon>
        </div>
      </div>
      <div class="overallocationWarning">
        <div class="allocationText" [class.disabled]="overallocationerror_4">
          <label *ngIf="!isHoursValid_4"
            >This Booking request will cause an overallocation. If you wish to
            proceed, please select the check box and click on Submit.</label
          >
        </div>
        <div class="confirmAllocation" [class.disabled]="overallocationerror_4">
          <label *ngIf="!isHoursValid_4">Confirm</label>
          <mat-checkbox
            [disableRipple]="true"
            (change)="confirmHoursChange('allocationValue_4')"
            *ngIf="!isHoursValid_4"
            [(ngModel)]="confirmHours_4"
            [ngModelOptions]="{ standalone: true }"
          ></mat-checkbox>
        </div>
      </div>
    </div>

    <div id="split5" *ngIf="showSplit_5" class="periodContainer">
      <div class="estimationContainer">
        <div class="dateSearch">
          <label>Period<span class="mandtoryIcn"> *</span></label>
          <mat-form-field appearance="outline">
            <mat-date-range-input
              formGroupName="period5"
              [rangePicker]="picker5"
              [min]="startWith"
            >
              <input
                matStartDate
                formControlName="start5"
                placeholder="Start Date"
                (focus)="picker5.open()"
                (click)="picker5.open()"
                readonly
              />
              <input
                matEndDate
                formControlName="end5"
                placeholder="End Date"
                (focus)="picker5.open()"
                (click)="picker5.open()"
                readonly
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker5"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker5></mat-date-range-picker>
            <mat-error
              *ngIf="
                formGroup
                  .get('period5')
                  .get('start5')
                  .hasError('matStartDateInvalid')
              "
              >Invalid Start Date</mat-error
            >
            <mat-error
              *ngIf="
                formGroup
                  .get('period5')
                  .get('end5')
                  .hasError('matEndDateInvalid')
              "
              >Invalid End Date</mat-error
            >
          </mat-form-field>
          <div
            *ngIf="
              (formGroup.get('period5').get('start5').value === null &&
                (formGroup.get('period5').get('start5').dirty ||
                  formGroup.get('period5').get('start5').touched)) ||
              (formGroup.get('period5').get('end5').value === null &&
                (formGroup.get('period5').get('end5').dirty ||
                  formGroup.get('period5').get('end5').touched))
            "
            class="alert alert-danger"
          >
            Start date and End date are required
          </div>
        </div>
        <div class="estimation">
          <label class="formLabel"
            >{{ engagementAllocationText
            }}<span class="mandtoryIcn"> *</span></label
          >
          <mat-form-field
            appearance="outline"
            [ngClass]="{ 'is-invalid': isValidInput('EngagementAllocation') }"
          >
            <input
              type="text"
              matInput
              (input)="makeDirty('Hours_5')"
              (focus)="mouseEnterSplit()"
              autocomplete="off"
              #tooltip="matTooltip"
              [matTooltip]="
                isPercentageSelected
                  ? ''
                  : 'Note when adding time in hours this will be input for the full-time period of the booking, not per week. For long term bookings Loading% may be the preferred way to input time allocation to work (please see “preference” button in top right hand corner to switch between the time allocation input methods)'
              "
              triggers="hover click focus"
              placement="bottom"
              (focusout)="mouseLeave(engagementAllocationTypeValue_5)"
              formControlName="allocationValue_5"
              appHoursLoading
              [isPercentageSelected]="isPercentageSelected"
            />
          </mat-form-field>
          <div *ngIf="isValidInput('allocationValue_5')">
            <div
              *ngIf="formGroup.controls['allocationValue_5'].errors.required"
              class="alert alert-danger"
            >
              {{ engagementAllocationText }} is required.
            </div>
            <div
              *ngIf="formGroup.controls['allocationValue_5'].errors.pattern"
              class="alert alert-danger"
            >
              {{ engagementAllocationText }} is invalid.
            </div>
          </div>
        </div>
        <div class="confirmContainer" *ngIf="!loggedInUser?.Unconfirmed">
          <label>Unconfirmed</label>
          <mat-checkbox
            [disableRipple]="true"
            (change)="makeDirty('')"
            [(ngModel)]="unconfirmed_5"
            [ngModelOptions]="{ standalone: true }"
          ></mat-checkbox>
        </div>
        <div *ngIf="loggedInUser.Ghost" class="confirmContainer">
          <label>Ghost</label>
          <mat-checkbox
            [(ngModel)]="Ghost_5"
            [disableRipple]="true"
            (change)="makeDirty('')"
            [ngModelOptions]="{ standalone: true }"
          ></mat-checkbox>
        </div>
        <div class="morePeriods">
          <mat-icon class="addPeriod" (click)="hideSplit(5)">remove</mat-icon>
        </div>
      </div>
      <div class="overallocationWarning">
        <div class="allocationText" [class.disabled]="overallocationerror_5">
          <label *ngIf="!isHoursValid_5"
            >This Booking request will cause an overallocation. If you wish to
            proceed, please select the check box and click on Submit.</label
          >
        </div>
        <div class="confirmAllocation" [class.disabled]="overallocationerror_5">
          <label *ngIf="!isHoursValid_5">Confirm</label>
          <mat-checkbox
            [disableRipple]="true"
            (change)="confirmHoursChange('allocationValue_5')"
            *ngIf="!isHoursValid_5"
            [(ngModel)]="confirmHours_5"
            [ngModelOptions]="{ standalone: true }"
          ></mat-checkbox>
        </div>
      </div>
    </div>

    <div class="">
      <label>Notes Description</label>
      <mat-form-field appearance="outline">
        <input
          matInput
          autocomplete="off"
          #tooltip="matTooltip"
          matTooltip="Please do not enter any unnecessary personal information or any sensitive personal data (including tax file numbers or other government identifiers), client confidential information, audit secrets, state secrets, commercial secrets, or anything that would violate professional secrecy or confidentiality rules or that would be considered abusive/irrelevant."
          triggers="hover click focus"
          placement="bottom"
          type="text"
          (change)="makeDirty(description)"
          [(ngModel)]="description"
          [ngModelOptions]="{ standalone: true }"
          maxlength="200"
        />
      </mat-form-field>
    </div>
    <div class="SubmitContainer">
      <button
        class="submitButton"
        type="submit"
        [disabled]="!(buttonText == 'Submit')"
      >
        {{ buttonText }}
      </button>
    </div>
  </div>
</form>
