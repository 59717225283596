import { Component, HostListener, OnInit } from '@angular/core';
import { SharedServicesService } from '../shared/sharedservices/shared-services.service';
import { LoginService } from '../landing/login.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { UserVM } from 'app/Models/UserVM';
import { ThemeService } from 'app/shared/theme/theme.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
  lastRefresh: boolean = true;
  breakpoint : any;
  isApprovalQueue: boolean = false;
  isMobileDevice: boolean = false;
  defaultDateFormat: string;
  loggedInUser: UserVM;

  constructor(public sharedService: SharedServicesService,
    private spinner: NgxSpinnerService,
    private loginService : LoginService,
    private themeService: ThemeService,
    private router: Router
    ) {
    this.sharedService.activeLink = 'Home';
    this.loggedInUser = JSON.parse(sessionStorage.getItem('resource'));
    this.themeService.sendData(this.loggedInUser.DefaultTheme);
    this.defaultDateFormat = !this.loggedInUser.DefaultDateFormat || 
    this.loggedInUser.DefaultDateFormat == 'System date format' 
    ? sharedService.getLocalDateFormat() : this.loggedInUser.DefaultDateFormat;
   }

  loggedinUser: any;
  count: any;
  ngOnInit(): void {
    this.spinner.show();
    this.loginService.setMenuHideAndShow(true);
    this.loggedinUser =  JSON.parse(sessionStorage.getItem('resource'));
    this.themeService.sendData(this.loggedinUser.DefaultTheme);
    this.sharedService.activeLink = 'Home';
    this.lastRefresh = true;
    this.breakpoint = (window.innerWidth <=400)? 1 : 2;
    this.loginService.loggedUser.subscribe(data => {
      this.loggedinUser = JSON.parse(sessionStorage.getItem('resource'));
      if(this.loggedinUser){
        this.isApprovalQueue = this.loggedinUser.RoleFeatures.includes('ApprovalLayer');
        this.isMobileDevice = JSON.parse(sessionStorage.getItem('requestMode')) === 1 ? true : false;
      }
    });
    this.sharedService.getUserInstances().subscribe(
      data => {});
    this.sharedService.getDashboardCounts().subscribe(
      data => {
        this.spinner.hide();
        console.log('counts', data);
        this.count= data;
    });
  }
  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 400) ? 1 : 2;
  }
  linkNavigation(page){
    this.router.navigate([`/${page}`]);
  }
}
