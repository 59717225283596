import { MasterVM } from "./MasterVM";

export class AssignmentConfigurationDto {
    AssignmentSubAreas: MasterVM[];
    AssignmentManagerialCountries: MasterVM[];
    AssignmentLegalEntities: MasterVM[];
    AssignmentServiceLines: MasterVM[];
    AssignmentBusinessUnits: MasterVM[];
    AssignmentSubServiceLines: MasterVM[];
    AssignmentSubManageUnits: MasterVM[];
    AssignmentRanks: MasterVM[];
    RAR_ISACTIVE:boolean;
    AssignmentOperatingUnits: MasterVM[];
    AssignmentManageUnits: MasterVM[];
    AssignmentDepartment: MasterVM[];
}