import { ModalOptions } from "ngx-bootstrap/modal";

export const data_IsDisabledBits = {
  IsDisabledAddEngagement_EV_Job: false,
  IsDisabledAddResource_Res: false,
  IsDisabledCopyBooking: false,
  IsDisabledCopyBooking_EV: false,
  IsDisabledDeleteBooking: false,
  IsDisabledDeleteBooking_EV: false,
  IsDisabledEVNavigation: false,
  IsDisabledEditBooking: false,
  IsDisabledEditBooking_EV: false,
  IsDisabledReplaceAllBooking: false,
  IsDisabledReplaceAllBooking_EV: false,
  IsDisabledReplaceBooking: false,
  IsDisabledReplaceBooking_EV: false,
  IsDisabledViewBooking_Job: false,
  IsDisabledViewBooking_Res: false,
}
export const data_bookingCell = {
  Action: '',
  ActionMode: 'E',
  ActualBookingHours: '',
  ActualBookingId: null,
  BookingHours: null,
  BookingId: null,
  BookingMode: '',
  ColorCode: '',
  Description: ' ',
  DiaryHours: null,
  EndDate: '',
  Ghost: false,
  InstanceID: null,
  ...data_IsDisabledBits,
  IsMergedBooking: false,
  IsSplitBooking: false,
  isValid: true,
  IsEdit: false,
  IsNotes: false,
  IsReplaceEngagement: false,
  IsReplaceResource: false,
  JobId: null,
  MeraBookingId: 1,
  Loading: '',
  OldDescription: '',
  OldHoursValue: null,
  OldPercentageValue: 0,
  ParentBookingID: null,
  ResourceId: null,
  StartDate: '',
  StatusDescription: '',
  StatusId: 0,
  TempId: '',
  Unconfirmed: false,
  WeekEndDate: '',
  WeekStartDate: '',
};

export const data_resource = {
  BookingType: '',
  EngagementName: '',
  GPN: '',
  InstanceID: null,
  InstanceName: '',
  IsDisabledCopyBooking: false,
  IsDisabledCopyBooking_EV: false,
  IsDisabledDeleteBooking: false,
  IsDisabledDeleteBooking_EV: false,
  IsDisabledEVNavigation: false,
  IsDisabledReplaceBooking: false,
  IsDisabledReplaceBooking_EV: false,
  IsDisabledViewBooking_Job: false,
  IsDisabledViewBooking_Res: false,
  JobCategory: '',
  JobCode: '',
  JobId: null,
  Order: null,
  RankCode: '',
  RankDescription: '',
  ResourceId: null,
  ResourceName: '',
  CostCenterDescription: '',
  LocationDescription: '',
};

export const data_totalHours = [
  {
    displayName: 'Checkbox',
    displayHours: '',
    displayPercentage: '',
  },
  {
    displayName: 'Total Scheduled Hours',
    displayHours: 'Total Scheduled Hours',
    displayPercentage: 'Total Scheduled Percentage',
  },
  //Remove the Instance column in eng view
  // {
  //   displayName: 'totalHoursDup',
  //   displayHours: '',
  //   displayPercentage: '',
  // },
];

export const data_ModelData = {
  header: '',
  text: '',
  isConfirmRequired: true,
  cancelText: 'Cancel',
  yesText: 'Ok',
  action: '',
};
export const modalOptions: ModalOptions = {
  backdrop: 'static',
  keyboard: false
}

export const data_displayTotalHourHeader = [
  'Checkbox',
  'Total Scheduled Hours',
  //Remove the Instance column in eng view
  // ,'totalHoursDup',
];

export const data_tableCheckFilters = [
  {
    value: 'Confirmed',
    name: 'Confirmed Booking',
    shouldShow: true,
    isChecked: true,
    imgName: 'Confirmed-white'
  },
  {
    value: 'Unconfirmed',
    name: 'Unconfirmed Booking',
    shouldShow: true,
    isChecked: true,
    imgName: 'Unconfirmed-white'
  },
  {
    value: 'Ghost',
    name: 'Ghost Booking',
    shouldShow: true,
    isChecked: true,
    imgName: 'Ghost booking-white'
  },
];

export const data_displayedColumns: string[] = [
  'Actions',
  'Resource Name',
  'Rank',
  //Remove the Instance column in eng view
  // ,'Retain Instance',
];

export const data_addDisplayedColumns: string[] = [
  'addActions',
  'addResource Name',
  'addRank',
  //Remove the Instance column in eng view
  // ,'addRetain Instance',
];

export const data_stickyColumns = ['Actions', 'Resource Name', 'Rank'];
export const data_stickyHeaderColumns: string[] = [
  'Checkbox',
  'Total Scheduled Hours',
];
export const data_stickyAddColumns: string[] = [
  'addActions',
  'addResource Name',
  'addRank',
];
export const data_bookingType: string[] = ['Confirmed', 'Unconfirmed', 'Ghost'];

export function getDisplayColumns(isMulti) {
  return !isMulti
    ? data_displayedColumns.filter((item) => item !== 'Retain Instance')
    : data_displayedColumns;
}

export function getAddDisplayColumns(isMulti) {
  return !isMulti
    ? data_addDisplayedColumns.filter((item) => item !== 'addRetain Instance')
    : data_addDisplayedColumns;
}

export function getTotalHoursColumns(isMulti) {
  return !isMulti
    ? data_totalHours.filter((item) => item.displayName !== 'totalHoursDup')
    : data_totalHours;
}

export function getTotalHoursColumnsHeader(isMulti) {
  return !isMulti
    ? data_displayTotalHourHeader.filter((item) => item !== 'totalHoursDup')
    : data_displayTotalHourHeader;
}

export function splitAndJoinDate(date) {
  return date.split('T')[0] + 'T00:00:00';
}
