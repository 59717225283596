import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Response } from 'app/Models/Response';
import { ResourceDto } from 'app/Models/ResourceDto';
import { AuthService } from 'app/shared/auth/auth.service';
import { environment } from 'environments/environment';
import { RoleVM } from 'app/Models/RoleVM';

@Injectable({
  providedIn: 'root',
})
export class RoleConfigService {
  endpoint = environment.apiEndpoint;
  private response: Response;
  private roleVM: RoleVM = new RoleVM();
  private roleId: number = 0;

  private _cancelClick = new BehaviorSubject(null);
  onCancelClick = this._cancelClick.asObservable();

  private _bindData = new BehaviorSubject(this.roleId);
  onBindData = this._bindData.asObservable();

  private _redirectToEdit = new BehaviorSubject(this.roleVM);
  onRedirectToEdit = this._redirectToEdit.asObservable();

  private _auditClick = new BehaviorSubject(this.roleId);
  onAuditClick = this._auditClick.asObservable();

  constructor(private http: HttpClient, private auth: AuthService) {}
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }
  private getAPI(actionName: string): string {
    this.auth.checkTokenExpiredAndRefresh();
    return this.endpoint + 'RoleConfiguration/' + actionName;
  }

  private getMasterAPI(actionName: string): string {
    this.auth.checkTokenExpiredAndRefresh();
    return this.endpoint + 'Master/' + actionName;
  }

  submitRoleConfiguration(role: RoleVM): Observable<any> {
    return this.http
      .post<any>(
        this.getAPI('SaveUserAccessConfiguration'),
        role,
        this.httpOptions
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('submitRoleConfiguration'))
      );
  }

  assignAccessRoleToResources(role: RoleVM): Observable<any> {
    return this.http
      .post<any>(
        this.getAPI('AssignAccessRoleToResources'),
        role,
        this.httpOptions
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('assignAccessRoleToResources'))
      );
  }

  emailMatchedResourcesForAutoRoleAssignment(role: RoleVM): Observable<any> {
    return this.http
      .post<any>(
        this.getAPI('EmailMatchedResourcesForAutoRoleAssignment'),
        role,
        this.httpOptions
      )
      .pipe(
        map((data) => data),
        catchError(
          this.handleError<any>('emailMatchedResourcesForAutoRoleAssignment')
        )
      );
  }

  deleteAccessRoleOfResources(role: RoleVM): Observable<any> {
    return this.http
      .post<any>(
        this.getAPI('DeleteAccessRoleOfResources'),
        role,
        this.httpOptions
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('deleteAccessRoleOfResources'))
      );
  }

  searchResourceRole(resource: ResourceDto): Observable<any> {
    return this.http
      .post<ResourceDto[]>(
        this.getAPI('SearchResourceRole'),
        resource,
        this.httpOptions
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('searchResourceRole'))
      );
  }

  searchResourceRoleEditable(resource: ResourceDto): Observable<any> {
    return this.http
      .post<ResourceDto[]>(
        this.getAPI('SearchResourceRoleEditable'),
        resource,
        this.httpOptions
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('searchResourceRoleEditable'))
      );
  }

  updateResourceEmail(vm: any): Observable<any> {
    return this.http
      .post<any>(this.getAPI('UpdateResourceEmail'), vm, this.httpOptions)
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('updateResourceEmail'))
      );
  }

  getMasterData(): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams().set('Id', '0');
    return this.http
      .get<any>(this.getMasterAPI('FetchAllMasterData'), { params, headers })
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('getMasterData'))
      );
  }

  checkAccessRole(roleId: number, accessRole: string): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.set('roleId', roleId.toString());
    params = params.set('accessRole', accessRole);

    return this.http
      .get<any>(this.getAPI('checkAccessRole'), { params, headers })
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('getSubArea'))
      );
  }

  getAccessRoleList(accessRole: string): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.set('accessRole', accessRole);

    return this.http
      .get<RoleVM[]>(this.getAPI('FetchAccessRole'), { params, headers })
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('FetchAccessRole'))
      );
  }

  getRolConfiguration(roleId: number): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.set('roleId', roleId.toString());

    return this.http
      .get<RoleVM[]>(this.getAPI('FetchRoleConfiguration'), { params, headers })
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('FetchRoleConfiguration'))
      );
  }

  getRoleAssignmentAuditList(
    roleId: number,
    resourceId: number
  ): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.set('roleId', roleId.toString());
    params = params.set('resourceId', resourceId.toString());
    return this.http
      .get<RoleVM[]>(this.getAPI('FetchRoleAssignAudit'), { params, headers })
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('FetchRoleAssignAudit'))
      );
  }

  deleteRolConfiguration(roleId: number): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.set('roleId', roleId.toString());

    return this.http
      .get<RoleVM[]>(this.getAPI('DeleteRoleConfiguration'), {
        params,
        headers,
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('DeleteRoleConfiguration'))
      );
  }

  cancelClick() {
    this._cancelClick.next(null);
  }

  redirectToEdit(role: RoleVM) {
    this._redirectToEdit.next(role);
  }

  BindData(roleId: number) {
    this._bindData.next(roleId);
  }

  auditClick(roleId: number) {
    this._auditClick.next(roleId);
  }

  public fetchResourceAccessRoleList(roleId: number): Observable<any[]> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.set('roleId', roleId.toString());
    return this.http
      .get<any[]>(this.getAPI('FetchResourceAccessRoleList'), {
        params,
        headers,
      })
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('FetchResourceAccessRoleList'))
      );
  }

  // api response is changed - updated method is fetchResourceAccessRoleList()
  public obsolete_fetchResourceAccessRoleList(roleId: number): Observable<Blob> {
    const headers = new HttpHeaders().set(
      'content-type',
      'multipart/form-data'
    );
    let url =
      this.getAPI('FetchResourceAccessRoleList') +
      '?roleId=' +
      roleId.toString();
    return this.http.get(url, { headers, responseType: 'blob' }).pipe(
      map((data) => data),
      catchError(this.handleError<any>('fetchResourceAccessRoleList'))
    );
  }
}
