import { MasterVM } from "./MasterVM";

export class BookingConfigurationDto {
    RBC_BIDEWEEK:boolean;
    RBC_BIDDWEEK:boolean;
    RBC_BIDAWEEK:boolean;
    RBC_BIDDTRNG:boolean;
    RBC_BIDETRNG:boolean;
    RBC_BIDDDELT:boolean;
    RBC_BIDEEDIT:boolean;
    RBC_BIDCCOPY:boolean;
    RBC_BIDCWEEK:boolean;
    RBC_BIDRWEEK:boolean;
    RBC_BIDRAWEEK:boolean;
    RBC_BIDRRPLC:boolean;
    RBC_BIDRARPLCA:boolean;
    RBC_BIDVGhost:boolean;
    RBC_BIDEGhost:boolean;
    RBC_BIDDGhost:boolean;
    RBC_BIDCGhost:boolean;
    RBC_BIDRGhost:boolean;
    

}
