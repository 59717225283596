import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { EngagementSubmissionVM } from '../../../Models/EngagementSubmissionVM';
import { Subscription } from 'rxjs';
import { IndividualService } from '../individual.service';
import { SharedServicesService } from 'app/shared/sharedservices/shared-services.service';
import { EngagementService } from 'app/Views/engagement/engagement.service';
import { SelectionModel } from '@angular/cdk/collections';
import { NgxSpinnerService } from 'ngx-spinner';
// import { ApplicationInsightService } from 'src/applicationInsightService';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.css'],
})

export class PreviewComponent implements OnInit, OnDestroy {

  // @ViewChild(TooltipDirective, { static: true }) public tooltipDir: TooltipDirective;
  selection = new SelectionModel(true, []);
  engagementSubmission: EngagementSubmissionVM;
  submitEngagementListSubscription: Subscription;
  RequestId: number;
  previewGridSource: any;
  closeModalWindow = false;
  // selectableSettings: SelectableSettings;
  mySelection: any[] = [];
  selctdRequsts:any[]=[];
  format = '#.## \\%';
  selectedCallback = (args) => args.dataItem;
  currentUserName: any;
  currentUserId: any;
  currentUserEmail: any;
  loading = true;
  buttonText = "Submit";
  loggedinUser: any;
  defaultDateFormat: string;
  selctionFlg=false;
  constructor(private restService: IndividualService, 
              private engagemntSer:EngagementService,
              public sharedService: SharedServicesService,
              private spinner : NgxSpinnerService
    ) {
    this.setSelectableSettings();
  }

  ngOnInit() {
    this.loggedinUser = JSON.parse(sessionStorage.getItem('resource'));  
    this.currentUserName = this.loggedinUser.ResourceName;
    this.currentUserEmail = this.loggedinUser.Email;
    this.currentUserId = sessionStorage.getItem("resourceId");
    this.defaultDateFormat = this.sharedService.getDateFormat(this.loggedinUser.DefaultDateFormat);
    // this.applicationInsightsService.logPageView("Individual View Preview and Submit Bookings");
    this.restService.previewEvent.subscribe((data) => {
      this.engagementSubmission = data;
      this.getEngagementSubmissionList();
    });
    this.restService.currentMessageCloseModal.subscribe(closeModal => {
      this.closeModalWindow = closeModal;
    });
  }

  getEngagementSubmissionList() {
    this.spinner.show();  
    this.loading = true;
    this.engagementSubmission.CurrentUserEmail = this.currentUserEmail;
    this.engagementSubmission.ResourceId = +sessionStorage.getItem("resourceId");
    
    this.submitEngagementListSubscription = this.restService.getEngagementSubmissionList().subscribe(
      (data: any) => {
          this.previewGridSource = data;
          this.previewGridSource.forEach((element) => {
            element.StartDate = element.StartDate === '0001-01-01T00:00:00' ? null : new Date(element.StartDate),
              element.EndDate = element.EndDate === '0001-01-01T00:00:00' ? null : new Date(element.EndDate);
            if (element.Unconfirmed) {
              element.Unconfirmed = 'Yes';
            } else {
              element.Unconfirmed = 'No';
            }
            this.RequestId = element.RequestId;
            this.mySelection.push(element);
            this.selection.select(element);
          });
          this.selctdRequsts = this.selection.selected;
          this.loading = false;
          if (this.previewGridSource !== null)
            this.restService.setRquestNumber(this.RequestId);
            
            this.spinner.hide();  
      });
  }

  ngOnDestroy() {
    if (this.submitEngagementListSubscription !== undefined) {
      this.submitEngagementListSubscription.unsubscribe();
    }
  }

  cancel() {
    let viewName=this.sharedService.activeLink;
    if(viewName == 'EngagementBooking'){
      this.engagemntSer.closeModal(false);
    }else if(viewName == 'IndividualBooking'){
      this.restService.closeModal(false);
    }
    
  }
  remove(item){
    this.selctionFlg=true;
    this.mySelection = this.mySelection.filter(x => x.RequestId !== item.RequestId);
  }

  BokingSelection(item){
    //this.selctionFlg=true;
    setTimeout(() => {
      this.selctdRequsts= this.selection.selected;
    },500)
    
    }

  save() {
    this.buttonText="Submitting...";    
    let reqPayload={};
    let isSelectedAll: boolean = this.previewGridSource.length == this.selctdRequsts.length;   
    let selectdIds = this.selctdRequsts.map(x => x.MeraBookingId);
    reqPayload={
        RequestId: this.previewGridSource[0].RequestId,
        IsSelectedAll: isSelectedAll,
        SelectedIds: selectdIds.length == 0 ? [] : selectdIds
       }
    
    
    // let replaceAllBookings = {
    //   RequestId: this.previewGridSource[0].RequestId,
    //   IsSelectedAll: isSelectedAll,
    //   SelectedIds: isSelectedAll ? [] : this.mySelection.map(x => x.MeraBookingId)
    // };
    
    this.submitEngagementListSubscription = 
    this.restService.updateEngagementSubmissionList(reqPayload).subscribe(
      (data: any) => {
        let viewName=this.sharedService.activeLink;
        if(viewName == 'EngagementBooking'){
          this.engagemntSer.closeModal(true);
        }else if(viewName == 'IndividualBooking'){
          this.restService.closeModal(true);
        }
        this.buttonText = "Submit";
      }, err => {
        this.buttonText = "Error";
      }, () => {
        this.buttonText = "Submit";
      }
    );
  }

  setSelectableSettings(): void {
    // this.selectableSettings = {
    //   checkboxOnly: true,
    //   mode: 'multiple'
    // };
  }

  // rowCallback(context: RowClassArgs) {
  //   const isEven = context.index % 2 === 0;
  //   return {
  //     even: isEven,
  //     odd: !isEven
  //   };
  // }

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH')
            && element.offsetWidth < element.scrollWidth) {
        //this.tooltipDir.toggle(element);
    } else {
        //this.tooltipDir.hide();
    }
}

}
