<ngx-spinner></ngx-spinner>

<ng-template #DeleteConfirmation>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Confirm</h4>
  </div>
  <div class="modal-body">
    <div *ngIf="dirtyFlag">
      The entered details will be lost. Are you sure you want to cancel the
      changes and delete the record?
    </div>
    <div *ngIf="!dirtyFlag">Are you sure you want to delete the record?</div>
    <div class="btn-container">
      <button class="cancelBtn" (click)="this.confirmModalRef.hide()">
        <span class="glyphicon glyphicon-remove-sign pr-5"></span>No
      </button>
      <button class="deleteBtn" (click)="deleteDataItem()">
        <span class="glyphicon glyphicon-ok pr-5"></span>Yes
      </button>
    </div>
  </div>
</ng-template>

<ng-template #SaveConfirmation>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Confirm</h4>
  </div>
  <div class="modal-body">
    <div>Are you sure you want to submit the changes?<span></span></div>
    <div class="btn-container">
      <button class="cancelBtn" (click)="this.confirmModalRef.hide()">
        <span class="glyphicon glyphicon-remove-sign pr-5"></span>No
      </button>
      <button class="deleteBtn" (click)="save()">
        <span class="glyphicon glyphicon-ok pr-5"></span>Yes
      </button>
    </div>
  </div>
</ng-template>
<form [formGroup]="formGroup">
  <div class="popupLayout ea">
    <div class="engSearch" *ngIf="roleDelgtFlg">
      <label>Resource Name <span class="mandtoryIcn">*</span></label>
      <mat-form-field class="resourceSearch" appearance="outline"
        [ngClass]="{ 'is-invalid': isValidInput('EngagementResourceName') }">
        <input type="text" formControlName="EngagementResourceName" placeholder="{{ placeHolderText }}" matInput
          autocomplete="off" appSearchDebounce [readonly]="editFlag ? true : false" (debounceKeyup)="search()"
          maxlength="75" />
        <mat-icon class="icon-close" (click)="onEngagementClear()" *ngIf="!editFlag ? true : false">close</mat-icon>
      </mat-form-field>
      <div *ngIf="isValidInput('EngagementResourceName')" class="alert alert-danger">
        <div *ngIf="formGroup.controls['EngagementResourceName'].errors?.required">
          Resource Name is required
        </div>
        <div *ngIf="formGroup.controls['EngagementResourceName'].errors?.incorrect">
          No Resource Found
        </div>
      </div>
      <div class="dropdown-list-wrap" *ngIf="showSearchList" [ngStyle]="{ border: engagementSelected ? 'none' : '' }">
        <mat-spinner *ngIf="showSearchLoader" [diameter]="40"></mat-spinner>
        <ul *ngIf="showSearchList" class="dropdown-list">
          <li *ngFor="let item of results" (click)="OnItemSelect(item)" class="dropdown-list-item">
            {{ item.ResourceName }}
          </li>
        </ul>
        <div *ngIf="showSearchLoader == false && results.length == 0">No Resource Found</div>
      </div>
    </div>
    <div class="engSearch" *ngIf="!roleDelgtFlg">
      <label>Resource Name <span class="mandtoryIcn"> *</span></label>
      <mat-form-field appearance="outline">
        <input type="text" matInput formControlName="EngagementResourceName" [(ngModel)]='ResourceName'
          [readonly]="true" [ngModelOptions]="{standalone:true}" autocomplete="off">
      </mat-form-field>
    </div>
    <div class="customRow" *ngIf="roleDelgtFlg">
      <div class="halfWidthItem">
        <label>Service Line</label>
        <mat-form-field appearance="outline">
          <input type="text" matInput (change)="makeDirty(engagementServiceLine)" [(ngModel)]="engagementServiceLine"
            [readonly]="true" [ngModelOptions]="{ standalone: true }" />
        </mat-form-field>
      </div>
      <div class="halfWidthItem">
        <label>HR Department</label>
        <mat-form-field appearance="outline">
          <input type="text" matInput (change)="makeDirty(departmentDescription)" [(ngModel)]="departmentDescription"
            [readonly]="true" [ngModelOptions]="{ standalone: true }" />
        </mat-form-field>
      </div>
    </div>
    <div class="customRow" *ngIf="!roleDelgtFlg">
      <div class="halfWidthItem">
        <label>Service Line</label>
        <mat-form-field appearance="outline">
          <input type="text" matInput (change)="makeDirty(engagementServiceLine)" [(ngModel)]="engagementServiceLine"
            [readonly]="true" [ngModelOptions]="{ standalone: true }" />
        </mat-form-field>
      </div>
      <div class="halfWidthItem">
        <label>HR Department</label>
        <mat-form-field appearance="outline">
          <input type="text" matInput (change)="makeDirty(departmentDescription)" [(ngModel)]="departmentDescription"
            [readonly]="true" [ngModelOptions]="{ standalone: true }" />
        </mat-form-field>
      </div>
    </div>
    <div class="customRow" *ngIf="roleDelgtFlg">
      <div class="halfWidthItem">
        <label>Office Location</label>
        <mat-form-field appearance="outline">
          <input type="text" matInput (change)="makeDirty(resourceLocation)" [(ngModel)]="resourceLocation"
            [readonly]="true" [ngModelOptions]="{ standalone: true }" autocomplete="off" />
        </mat-form-field>
      </div>
      <div class="halfWidthItem">
        <label>Rank</label>
        <mat-form-field appearance="outline">
          <input type="text" matInput (change)="makeDirty(Rank)" [(ngModel)]="Rank" [readonly]="true"
            [ngModelOptions]="{ standalone: true }" autocomplete="off" />
        </mat-form-field>
      </div>
    </div>
    <div class="customRow" *ngIf="!roleDelgtFlg">
      <div class="halfWidthItem">
        <label>Office Location</label>
        <mat-form-field appearance="outline">
          <input type="text" matInput (change)="makeDirty(resourceLocation)" [(ngModel)]="resourceLocation"
            [readonly]="true" [ngModelOptions]="{ standalone: true }" autocomplete="off" />
        </mat-form-field>
      </div>
      <div class="halfWidthItem">
        <label>Rank</label>
        <mat-form-field appearance="outline">
          <input type="text" matInput (change)="makeDirty(Rank)" [(ngModel)]="Rank" [readonly]="true"
            [ngModelOptions]="{ standalone: true }" autocomplete="off" />
        </mat-form-field>
      </div>
    </div>

    <div id="split1" class="periodContainer">
      <div class="estimationContainer">
        <div class="dateSearch">
          <label>Period <span class="mandtoryIcn">*</span></label>
          <mat-form-field appearance="outline">
            <mat-date-range-input formGroupName="period1" [rangePicker]="picker" [min]="startWith">
              <input matStartDate formControlName="start1" placeholder="Start Date" (focus)="picker.open()"
                (click)="picker.open()" readonly />
              <input matEndDate formControlName="end1" placeholder="End Date" (focus)="picker.open()"
                (click)="picker.open()" readonly />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <mat-error *ngIf="
                formGroup
                  .get('period1')
                  .get('start1')
                  .hasError('matStartDateInvalid')
              ">Invalid Start Date</mat-error>
            <mat-error *ngIf="
                formGroup
                  .get('period1')
                  .get('end1')
                  .hasError('matEndDateInvalid')
              ">Invalid End Date</mat-error>
          </mat-form-field>
          <!-- <div
            *ngIf="
              formGroup.get('period1').get('start1').value === null &&
              (formGroup.get('period1').get('start1').dirty ||
                formGroup.get('period1').get('start1').touched)
            "
            class="alert alert-danger"
          >
            Start Date is required
          </div> -->
          <div *ngIf="formGroup.get('period1').get('start1').value === null &&
            (formGroup.get('period1').get('start1').dirty ||
              formGroup.get('period1').get('start1').touched) ||
              formGroup.get('period1').get('end1').value === null &&
              (formGroup.get('period1').get('end1').dirty ||
                formGroup.get('period1').get('end1').touched)
            " class="alert alert-danger">
            Start Date and End Date are required
          </div>
        </div>
        <div class="estimation">
          <label>{{ engagementAllocationText }} <span class="mandtoryIcn">*</span></label>
          <mat-form-field appearance="outline" [ngClass]="{ 'is-invalid': isValidInput('EngagementAllocation') }">
            <input type="text" matInput (input)="makeDirty('Hours_1')" (focus)="mouseEnter()" autocomplete="off"
              #tooltip="matTooltip" [matTooltip]="
                isPercentageSelected
                  ? ''
                  : 'Note when adding time in hours this will be input for the full-time period of the booking, not per week. For long term bookings Loading% may be the preferred way to input time allocation to work (please see “preference” button in top right hand corner to switch between the time allocation input methods)'
              " triggers="hover click focus" placement="bottom" (focusout)="mouseLeave(engagementAllocationTypeValue)"
              formControlName="EngagementAllocation" appHoursLoading [isPercentageSelected]="isPercentageSelected" />
          </mat-form-field>
          <div
            *ngIf="(formGroup.get('EngagementAllocation').value === null || formGroup.get('EngagementAllocation').value === '') && (formGroup.get('EngagementAllocation').dirty || formGroup.get('EngagementAllocation').touched)">
            <div *ngIf="formGroup.controls['EngagementAllocation'].errors?.required" class="alert alert-danger">
              {{ engagementAllocationText }} is required.
            </div>
            <div *ngIf="formGroup.controls['EngagementAllocation'].errors?.pattern" class="alert alert-danger">
              {{ engagementAllocationText }} is invalid.
            </div>
          </div>
        </div>
        <div class="confirmContainer" *ngIf="!loggedInUser?.Unconfirmed">
          <label>Unconfirmed</label>
          <mat-checkbox [(ngModel)]="unconfirmed" [disableRipple]="true" (change)="makeDirty(unconfirmed)"
            [ngModelOptions]="{ standalone: true }"></mat-checkbox>
        </div>
        <div class="confirmContainer" *ngIf="loggedInUser.Ghost">
          <label>Ghost</label>
          <mat-checkbox [disableRipple]="true" (change)="makeDirty('')" [(ngModel)]="Ghost"
            [ngModelOptions]="{ standalone: true }"></mat-checkbox>
        </div>
        <div class="morePeriods">
          <mat-icon class="addPeriod" (click)="showSplit()">add</mat-icon>
        </div>
      </div>
      <div class="overallocationWarning">
        <div class="allocationText" [class.disabled]="overallocationerror_1">
          <label *ngIf="!isHoursValid">This Booking request will cause an overallocation. If you wish to
            proceed, please select the check box and click Save.</label>
        </div>
        <div class="confirmAllocation" [class.disabled]="overallocationerror_1">
          <label *ngIf="!isHoursValid">Confirm</label>
          <mat-checkbox [disableRipple]="true" [(ngModel)]="confirmHours" (change)="confirmHoursChange()"
            *ngIf="!isHoursValid" [ngModelOptions]="{ standalone: true }"></mat-checkbox>
        </div>
      </div>
    </div>

    <div id="split2" *ngIf="showSplit_2" class="periodContainer">
      <div class="estimationContainer">
        <div class="dateSearch">
          <label>Period<span class="mandtoryIcn"> *</span></label>
          <mat-form-field appearance="outline">
            <mat-date-range-input formGroupName="period2" [rangePicker]="picker2" [min]="startWith">
              <input matStartDate formControlName="start2" placeholder="Start Date" (focus)="picker2.open()"
                (click)="picker2.open()" readonly />
              <input matEndDate formControlName="end2" placeholder="End Date" (focus)="picker2.open()"
                (click)="picker2.open()" readonly />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-date-range-picker #picker2></mat-date-range-picker>
            <mat-error *ngIf="
                formGroup
                  .get('period2')
                  .get('start2')
                  .hasError('matStartDateInvalid')
              ">Invalid Start Date</mat-error>
            <mat-error *ngIf="
                formGroup
                  .get('period2')
                  .get('end2')
                  .hasError('matEndDateInvalid')
              ">Invalid End Date</mat-error>
          </mat-form-field>
          <!-- <div *ngIf="
              formGroup.get('period2').get('start2').value === null &&
              (formGroup.get('period2').get('start2').dirty ||
                formGroup.get('period2').get('start2').touched)
            " class="alert alert-danger">
            Start Date is required
          </div> -->
          <div *ngIf="formGroup.get('period2').get('start2').value === null &&
          (formGroup.get('period2').get('start2').dirty ||
            formGroup.get('period2').get('start2').touched) ||
              formGroup.get('period2').get('end2').value === null &&
              (formGroup.get('period2').get('end2').dirty ||
                formGroup.get('period2').get('end2').touched)
            " class="alert alert-danger">
            Start Date and End Date are required
          </div>
        </div>
        <div class="estimation">
          <label class="formLabel">{{ engagementAllocationText }}<span class="mandtoryIcn"> *</span></label>
          <mat-form-field appearance="outline" [ngClass]="{ 'is-invalid': isValidInput('EngagementAllocation') }">
            <input type="text" matInput (input)="makeDirty('Hours_2')" (focus)="mouseEnterSplit()" autocomplete="off"
              #tooltip="matTooltip" [matTooltip]="
                isPercentageSelected
                  ? ''
                  : 'Note when adding time in hours this will be input for the full-time period of the booking, not per week. For long term bookings Loading% may be the preferred way to input time allocation to work (please see “preference” button in top right hand corner to switch between the time allocation input methods)'
              " triggers="hover click focus" placement="bottom"
              (focusout)="mouseLeave(engagementAllocationTypeValue_2)" formControlName="allocationValue_2"
              appHoursLoading [isPercentageSelected]="isPercentageSelected" />
          </mat-form-field>
          <div *ngIf="isValidInput('allocationValue_2')">
            <div *ngIf="formGroup.controls['allocationValue_2'].errors?.required" class="alert alert-danger">
              {{ engagementAllocationText }} is required.
            </div>
            <div *ngIf="formGroup.controls['allocationValue_2'].errors?.pattern" class="alert alert-danger">
              {{ engagementAllocationText }} is invalid.
            </div>
          </div>
        </div>
        <div class="confirmContainer" *ngIf="!loggedInUser?.Unconfirmed">
          <label>Unconfirmed</label>
          <mat-checkbox [disableRipple]="true" (change)="makeDirty('')" [(ngModel)]="unconfirmed_2"
            [ngModelOptions]="{ standalone: true }"></mat-checkbox>
        </div>
        <div class="confirmContainer" *ngIf="loggedInUser.Ghost">
          <label>Ghost</label>
          <mat-checkbox [disableRipple]="true" (change)="makeDirty('')" [(ngModel)]="Ghost_2"
            [ngModelOptions]="{ standalone: true }"></mat-checkbox>
        </div>
        <div class="morePeriods">
          <mat-icon class="addPeriod" (click)="hideSplit(2)">remove</mat-icon>
        </div>
      </div>
      <div class="overallocationWarning">
        <div class="allocationText" [class.disabled]="overallocationerror_2">
          <label *ngIf="!isHoursValid_2">This Booking request will cause an overallocation. If you wish to
            proceed, please select the check box and click Save.</label>
        </div>
        <div class="confirmAllocation" [class.disabled]="overallocationerror_2">
          <label *ngIf="!isHoursValid_2">Confirm</label>
          <mat-checkbox [disableRipple]="true" (change)="confirmHoursChange('allocationValue_2')"
            *ngIf="!isHoursValid_2" [(ngModel)]="confirmHours_2" [ngModelOptions]="{ standalone: true }"></mat-checkbox>
        </div>
      </div>
    </div>

    <div id="split3" *ngIf="showSplit_3" class="periodContainer">
      <div class="estimationContainer">
        <div class="dateSearch">
          <label>Period<span class="mandtoryIcn"> *</span></label>
          <mat-form-field appearance="outline">
            <mat-date-range-input formGroupName="period3" [rangePicker]="picker3" [min]="startWith">
              <input matStartDate formControlName="start3" placeholder="Start Date" (focus)="picker3.open()"
                (click)="picker3.open()" readonly />
              <input matEndDate formControlName="end3" placeholder="End Date" (focus)="picker3.open()"
                (click)="picker3.open()" readonly />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-date-range-picker #picker3></mat-date-range-picker>
            <mat-error *ngIf="
                formGroup
                  .get('period3')
                  .get('start3')
                  .hasError('matStartDateInvalid')
              ">Invalid Start Date</mat-error>
            <mat-error *ngIf="
                formGroup
                  .get('period3')
                  .get('end3')
                  .hasError('matEndDateInvalid')
              ">Invalid End Date</mat-error>
          </mat-form-field>
          <!-- <div *ngIf="
              formGroup.get('period3').get('start3').value === null &&
              (formGroup.get('period3').get('start3').dirty ||
                formGroup.get('period3').get('start3').touched)
            " class="alert alert-danger">
            Start Date is required
          </div> -->
          <div *ngIf="formGroup.get('period3').get('start3').value === null &&
          (formGroup.get('period3').get('start3').dirty ||
            formGroup.get('period3').get('start3').touched) ||
              formGroup.get('period3').get('end3').value === null &&
              (formGroup.get('period3').get('end3').dirty ||
                formGroup.get('period3').get('end3').touched)
            " class="alert alert-danger">
            Start Date and End Date are required
          </div>
        </div>
        <div class="estimation">
          <label>{{ engagementAllocationText }}<span class="mandtoryIcn"> *</span></label>
          <mat-form-field appearance="outline" [ngClass]="{ 'is-invalid': isValidInput('EngagementAllocation') }">
            <input type="text" matInput (input)="makeDirty('Hours_3')" (focus)="mouseEnter()" autocomplete="off"
              #tooltip="matTooltip" [matTooltip]="
                isPercentageSelected
                  ? ''
                  : 'Note when adding time in hours this will be input for the full-time period of the booking, not per week. For long term bookings Loading% may be the preferred way to input time allocation to work (please see “preference” button in top right hand corner to switch between the time allocation input methods)'
              " triggers="hover click focus" placement="bottom"
              (focusout)="mouseLeave(engagementAllocationTypeValue_3)" formControlName="allocationValue_3"
              appHoursLoading [isPercentageSelected]="isPercentageSelected" />
          </mat-form-field>
          <div *ngIf="isValidInput('allocationValue_3')">
            <div *ngIf="formGroup.controls['allocationValue_3'].errors?.required" class="alert alert-danger">
              {{ engagementAllocationText }} is required.
            </div>
            <div *ngIf="formGroup.controls['allocationValue_3'].errors?.pattern" class="alert alert-danger">
              {{ engagementAllocationText }} is invalid.
            </div>
          </div>
        </div>
        <div class="confirmContainer" *ngIf="!loggedInUser?.Unconfirmed">
          <label>Unconfirmed</label>
          <mat-checkbox [disableRipple]="true" (change)="makeDirty('')" [(ngModel)]="unconfirmed_3"
            [ngModelOptions]="{ standalone: true }"></mat-checkbox>
        </div>
        <div class="confirmContainer" *ngIf="loggedInUser.Ghost">
          <label>Ghost</label>
          <mat-checkbox [disableRipple]="true" (change)="makeDirty('')" [(ngModel)]="Ghost_3"
            [ngModelOptions]="{ standalone: true }"></mat-checkbox>
        </div>

        <div class="morePeriods">
          <mat-icon class="addPeriod" (click)="hideSplit(3)">remove</mat-icon>
        </div>
      </div>
      <div class="overallocationWarning">
        <div class="allocationText" [class.disabled]="overallocationerror_3">
          <label *ngIf="!isHoursValid_3">This Booking request will cause an overallocation. If you wish to
            proceed, please select the check box and click Save.</label>
        </div>
        <div class="confirmAllocation" [class.disabled]="overallocationerror_3">
          <label *ngIf="!isHoursValid_3">Confirm</label>
          <mat-checkbox [disableRipple]="true" (change)="confirmHoursChange('allocationValue_3')"
            *ngIf="!isHoursValid_3" [(ngModel)]="confirmHours_3" [ngModelOptions]="{ standalone: true }"></mat-checkbox>
        </div>
      </div>
    </div>

    <div id="split4" *ngIf="showSplit_4" class="periodContainer">
      <div class="estimationContainer">
        <div class="dateSearch">
          <label>Period<span class="mandtoryIcn"> *</span></label>
          <mat-form-field appearance="outline">
            <mat-date-range-input formGroupName="period4" [rangePicker]="picker4" [min]="startWith">
              <input matStartDate formControlName="start4" placeholder="Start Date" (focus)="picker4.open()"
                (click)="picker4.open()" readonly />
              <input matEndDate formControlName="end4" placeholder="End Date" (focus)="picker4.open()"
                (click)="picker4.open()" readonly />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
            <mat-date-range-picker #picker4></mat-date-range-picker>
            <mat-error *ngIf="
                formGroup
                  .get('period4')
                  .get('start4')
                  .hasError('matStartDateInvalid')
              ">Invalid Start Date</mat-error>
            <mat-error *ngIf="
                formGroup
                  .get('period4')
                  .get('end4')
                  .hasError('matEndDateInvalid')
              ">Invalid End Date</mat-error>
          </mat-form-field>
          <!-- <div *ngIf="
              formGroup.get('period4').get('start4').value === null &&
              (formGroup.get('period4').get('start4').dirty ||
                formGroup.get('period4').get('start4').touched)
            " class="alert alert-danger">
            Start Date is required
          </div> -->
          <div *ngIf="formGroup.get('period4').get('start4').value === null &&
          (formGroup.get('period4').get('start4').dirty ||
            formGroup.get('period4').get('start4').touched) ||
              formGroup.get('period4').get('end4').value === null &&
              (formGroup.get('period4').get('end4').dirty ||
                formGroup.get('period4').get('end4').touched)
            " class="alert alert-danger">
            Start Date and End Date are required
          </div>
        </div>
        <div class="estimation">
          <label>{{ engagementAllocationText }}<span class="mandtoryIcn"> *</span></label>
          <mat-form-field appearance="outline" [ngClass]="{ 'is-invalid': isValidInput('EngagementAllocation') }">
            <input type="text" matInput (input)="makeDirty('Hours_4')" (focus)="mouseEnterSplit()" autocomplete="off"
              #tooltip="matTooltip" [matTooltip]="
                isPercentageSelected
                  ? ''
                  : 'Note when adding time in hours this will be input for the full-time period of the booking, not per week. For long term bookings Loading% may be the preferred way to input time allocation to work (please see “preference” button in top right hand corner to switch between the time allocation input methods)'
              " triggers="hover click focus" placement="bottom"
              (focusout)="mouseLeave(engagementAllocationTypeValue_4)" formControlName="allocationValue_4"
              appHoursLoading [isPercentageSelected]="isPercentageSelected" />
          </mat-form-field>
          <div *ngIf="isValidInput('allocationValue_4')">
            <div *ngIf="formGroup.controls['allocationValue_4'].errors?.required" class="alert alert-danger">
              {{ engagementAllocationText }} is required.
            </div>
            <div *ngIf="formGroup.controls['allocationValue_4'].errors?.pattern" class="alert alert-danger">
              {{ engagementAllocationText }} is invalid.
            </div>
          </div>
        </div>
        <div class="confirmContainer" *ngIf="!loggedInUser?.Unconfirmed">
          <label>Unconfirmed</label>
          <mat-checkbox [disableRipple]="true" (change)="makeDirty('')" [(ngModel)]="unconfirmed_4"
            [ngModelOptions]="{ standalone: true }"></mat-checkbox>
        </div>
        <div class="confirmContainer" *ngIf="loggedInUser.Ghost">
          <label>Ghost</label>
          <mat-checkbox [disableRipple]="true" (change)="makeDirty('')" [(ngModel)]="Ghost_4"
            [ngModelOptions]="{ standalone: true }"></mat-checkbox>
        </div>

        <div class="morePeriods">
          <mat-icon class="addPeriod" (click)="hideSplit(4)">remove</mat-icon>
        </div>
      </div>
      <div class="overallocationWarning">
        <div class="allocationText" [class.disabled]="overallocationerror_4">
          <label *ngIf="!isHoursValid_4">This Booking request will cause an overallocation. If you wish to
            proceed, please select the check box and click Save.</label>
        </div>
        <div class="confirmAllocation" [class.disabled]="overallocationerror_4">
          <label *ngIf="!isHoursValid_4">Confirm</label>
          <mat-checkbox [disableRipple]="true" (change)="confirmHoursChange('allocationValue_4')"
            *ngIf="!isHoursValid_4" [(ngModel)]="confirmHours_4" [ngModelOptions]="{ standalone: true }"></mat-checkbox>
        </div>
      </div>
    </div>

    <div id="split5" *ngIf="showSplit_5" class="periodContainer">
      <div class="estimationContainer">
        <div class="dateSearch">
          <label>Period<span class="mandtoryIcn"> *</span></label>
          <mat-form-field appearance="outline">
            <mat-date-range-input formGroupName="period5" [rangePicker]="picker5" [min]="startWith">
              <input matStartDate formControlName="start5" placeholder="Start Date" (focus)="picker5.open()"
                (click)="picker5.open()" readonly />
              <input matEndDate formControlName="end5" placeholder="End Date" (focus)="picker5.open()"
                (click)="picker5.open()" readonly />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
            <mat-date-range-picker #picker5></mat-date-range-picker>
            <mat-error *ngIf="
                formGroup
                  .get('period5')
                  .get('start5')
                  .hasError('matStartDateInvalid')
              ">Invalid Start Date</mat-error>
            <mat-error *ngIf="
                formGroup
                  .get('period5')
                  .get('end5')
                  .hasError('matEndDateInvalid')
              ">Invalid End Date</mat-error>
          </mat-form-field>
          <!-- <div *ngIf="
              formGroup.get('period5').get('start5').value === null &&
              (formGroup.get('period5').get('start5').dirty ||
                formGroup.get('period5').get('start5').touched)
            " class="alert alert-danger">
            Start Date is required
          </div> -->
          <div *ngIf="formGroup.get('period5').get('start5').value === null &&
          (formGroup.get('period5').get('start5').dirty ||
            formGroup.get('period5').get('start5').touched) ||
              formGroup.get('period5').get('end5').value === null &&
              (formGroup.get('period5').get('end5').dirty ||
                formGroup.get('period5').get('end5').touched)
            " class="alert alert-danger">
            Start Date and End Date are required
          </div>

        </div>
        <div class="estimation">
          <label class="formLabel">{{ engagementAllocationText }}<span class="mandtoryIcn"> *</span></label>
          <mat-form-field appearance="outline" [ngClass]="{ 'is-invalid': isValidInput('EngagementAllocation') }">
            <input type="text" matInput (input)="makeDirty('Hours_5')" (focus)="mouseEnterSplit()" autocomplete="off"
              #tooltip="matTooltip" [matTooltip]="
                isPercentageSelected
                  ? ''
                  : 'Note when adding time in hours this will be input for the full-time period of the booking, not per week. For long term bookings Loading% may be the preferred way to input time allocation to work (please see “preference” button in top right hand corner to switch between the time allocation input methods)'
              " triggers="hover click focus" placement="bottom"
              (focusout)="mouseLeave(engagementAllocationTypeValue_5)" formControlName="allocationValue_5"
              appHoursLoading [isPercentageSelected]="isPercentageSelected" />
          </mat-form-field>
          <div *ngIf="isValidInput('allocationValue_5')">
            <div *ngIf="formGroup.controls['allocationValue_5'].errors?.required" class="alert alert-danger">
              {{ engagementAllocationText }} is required.
            </div>
            <div *ngIf="formGroup.controls['allocationValue_5'].errors?.pattern" class="alert alert-danger">
              {{ engagementAllocationText }} is invalid.
            </div>
          </div>
        </div>
        <div class="confirmContainer" *ngIf="!loggedInUser?.Unconfirmed">
          <label>Unconfirmed</label>
          <mat-checkbox [disableRipple]="true" (change)="makeDirty('')" [(ngModel)]="unconfirmed_5"
            [ngModelOptions]="{ standalone: true }"></mat-checkbox>
        </div>
        <div class="confirmContainer" *ngIf="loggedInUser.Ghost">
          <label>Ghost</label>
          <mat-checkbox [disableRipple]="true" (change)="makeDirty('')" [(ngModel)]="Ghost_5"
            [ngModelOptions]="{ standalone: true }"></mat-checkbox>
        </div>
        <div class="morePeriods">
          <mat-icon class="addPeriod" (click)="hideSplit(5)">remove</mat-icon>
        </div>
      </div>
      <div class="overallocationWarning">
        <div class="allocationText" [class.disabled]="overallocationerror_5">
          <label *ngIf="!isHoursValid_5">This Booking request will cause an overallocation. If you wish to
            proceed, please select the check box and click Save.</label>
        </div>
        <div class="confirmAllocation" [class.disabled]="overallocationerror_5">
          <label *ngIf="!isHoursValid_5">Confirm</label>
          <mat-checkbox [disableRipple]="true" (change)="confirmHoursChange('allocationValue_5')"
            *ngIf="!isHoursValid_5" [(ngModel)]="confirmHours_5" [ngModelOptions]="{ standalone: true }"></mat-checkbox>
        </div>
      </div>
    </div>

    <div class="notes-desc-row">
      <label>Notes Description</label>
      <mat-form-field appearance="outline">
        <input type="text" autocomplete="off" matInput [(ngModel)]="description" [ngModelOptions]="{ standalone: true }"
          maxlength="200" />
      </mat-form-field>
    </div>

    <div class="customRow" *ngIf="roleDelgtFlg">
      <div class="halfWidthItem btn-reset" (click)="clearControls('reset')">
        <strong>Reset</strong>
      </div>
      <button class="halfWidthItem btn-add" *ngIf="!editFlag" type="submit" (click)="onSubmit('Add')">
        <strong>Add Resource</strong>
      </button>
      <button class="halfWidthItem btn-add" *ngIf="editFlag" type="submit" (click)="onSubmit('Update')">
        <strong>Update Resource</strong>
      </button>
    </div>
    <div class="text-center" *ngIf="!roleDelgtFlg">
      <div class="btn submitButton_self" type="submit" (click)="onSubmit('Add')">
        <span>Submit</span>
      </div>
    </div>
    <div class="materialTable" *ngIf="previewGridSource?.length > 0 && roleDelgtFlg">
      <table #table mat-table [dataSource]="previewGridSource" class="mat-elevation-z8 bookingTable">
        <ng-container matColumnDef="ResourceName">
          <th mat-header-cell *matHeaderCellDef class="mat-column-ResourceName">
            Resource Name
          </th>
          <td mat-cell *matCellDef="let element" data-title="Resource Name">
            {{ element.ResourceName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="Rank">
          <th mat-header-cell *matHeaderCellDef class="mat-column-Rank">
            Rank
          </th>
          <td mat-cell *matCellDef="let element" data-title="Rank">
            {{ element.Rank }}
          </td>
        </ng-container>
        <ng-container matColumnDef="StartDate">
          <th mat-header-cell *matHeaderCellDef class="mat-column-StartDate">
            Start Date
          </th>
          <td mat-cell *matCellDef="let element" data-title="Start Date">
            {{ element.StartDate | date: defaultDateFormat }}
          </td>
        </ng-container>
        <ng-container matColumnDef="EndDate">
          <th mat-header-cell *matHeaderCellDef class="mat-column-EndDate">
            End Date
          </th>
          <td mat-cell *matCellDef="let element" data-title="End Date">
            {{ element.EndDate | date: defaultDateFormat }}
          </td>
        </ng-container>
        <ng-container matColumnDef="Hours">
          <th mat-header-cell *matHeaderCellDef class="mat-column-Hours">
            Hours
          </th>
          <td mat-cell *matCellDef="let element" data-title="Hours">
            {{ element.Hours }}
          </td>
        </ng-container>
        <ng-container matColumnDef="Loading">
          <th mat-header-cell *matHeaderCellDef class="mat-column-Loading">
            Loading
          </th>
          <td mat-cell *matCellDef="let element" data-title="Loading">
            {{ element.Loading }}
          </td>
        </ng-container>
        <ng-container *ngIf="loggedInUser.Ghost" matColumnDef="Ghost">
          <th mat-header-cell *matHeaderCellDef class="mat-column-Ghost">
            Ghost
          </th>
          <td mat-cell *matCellDef="let element" data-title="Ghost">
            {{ element.GhostYesNo }}
          </td>
        </ng-container>
        <ng-container *ngIf="!loggedInUser.Unconfirmed" matColumnDef="Unconfirmed">
          <th mat-header-cell *matHeaderCellDef class="mat-column-Unconfirmed">
            Unconfirmed
          </th>
          <td mat-cell *matCellDef="let element" data-title="Unconfirmed">
            {{ element.UnconfirmedYesNo}}
          </td>
        </ng-container>
        <ng-container matColumnDef="Actions">
          <th mat-header-cell *matHeaderCellDef class="mat-column-Actions">
            Actions
          </th>
          <td mat-cell *matCellDef="let element; let i = index" class="actionColumn">
            <!-- <mat-icon class="tableIcon" [ngClass]="{ disabledAction: element.IsDisabledEditBooking }" *ngIf="true"
              (click)="editDataItem(i)">edit</mat-icon> -->
            <span class="tableActionIcon edit" [ngClass]="{ disabledAction: element.IsDisabledEditBooking }"
              (click)="editDataItem(i)" matTooltip="Edit">
            </span>

            <!-- <mat-icon class="tableIcon" [ngClass]="{ disabledAction: element.IsDisabledDeleteBooking }" *ngIf="true"
              (click)="openConfirmationModal(DeleteConfirmation, i, 'delete')">delete</mat-icon> -->
            <span class="tableActionIcon delete" [ngClass]="{ disabledAction: element.IsDisabledDeleteBooking }"
              (click)="openConfirmationModal(DeleteConfirmation, i, 'delete')" matTooltip="Delete">
            </span>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <div *ngIf="!previewGridSource?.length" class="noBookings">
        Bookings not found
      </div>
      <div class="submit-btn" *ngIf="previewGridSource?.length && roleDelgtFlg" (click)="save()"
        [class.disabled_addboking]="submitDisableFlg">
        <strong>Submit</strong>
      </div>
    </div>
  </div>
</form>
