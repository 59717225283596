import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subject, timer, Subscription } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { AuthService } from '../../shared/auth/auth.service';
import { ModalOptions, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SharedServicesService } from 'app/shared/sharedservices/shared-services.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-inactivity-timer',
  //template: ``,
  templateUrl: './inactivity-timer.component.html',
  styleUrls: ['./inactivity-timer.component.scss']
})
export class InactivityTimerComponent implements OnDestroy, OnInit {

  // set logoutAfterMinutes to 60 and showMsgAfterMinutes to 55.
  logoutAfterMinutes = 60;
  showMsgAfterMinutes = 55;
  refreshLoginTimeAfterMinutes = 1;

  minutesDisplay = 0;
  secondsDisplay = 0;

  modalDisplayed=false;
  modalOption: ModalOptions = {};
  modalRef: BsModalRef;
  @ViewChild('template1') template1: TemplateRef<any>;

  endTime = 1;

  unsubscribe$: Subject<void> = new Subject();
  timerSubscription: Subscription;

  constructor(private authService: AuthService,
    private modalService: BsModalService,
    public restService: SharedServicesService,
    private spinner: NgxSpinnerService) {
    
  }

  ngOnInit() {
    this.resetTimer();
    this.authService.userActionOccured.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      if(!this.modalDisplayed){
        this.resetTimer();
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  stopTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  resetTimer(endTime: number = this.endTime) {   
    //alert('Timer Started');
    this.stopTimer();

    if (!sessionStorage.getItem("resource")) {
      return;
    }
    
    this.refreshLoginTime();

    const interval = 1000;
    // const tokenTimeDiff = (this.authService.tokenTimeDifference() - 5);
    // let duration = tokenTimeDiff * 60;

    let duration = this.logoutAfterMinutes * 60;

    this.timerSubscription = timer(0, interval).pipe(
      take(duration)
    ).subscribe(value => {
      if(value > (this.showMsgAfterMinutes)*60) {
        this.render((duration - +value) * interval);
        if(!this.modalDisplayed){
          this.openModal();
        }
      }
     } ,
      err => { },
      // On Timer Completion Logout the user.
      () => {                
        if (sessionStorage.getItem("resource")) {
          this.spinner.show();
          this.stopTimer();
          this.authService.logoutForInactivity();
        }
      }
    );
  }

  refreshLoginTime() {
    if(sessionStorage.getItem('loginRefreshedOn')) {      
      let lastRefreshedOn = new Date(sessionStorage.getItem('loginRefreshedOn'));
      if(((new Date()).getTime() - lastRefreshedOn.getTime())
      >= (this.refreshLoginTimeAfterMinutes * 60 * 1000)) {
        this.restService.updateLoginRefreshTime().subscribe( res => {  
          sessionStorage.setItem('loginRefreshedOn', (new Date()).toString());
        });
      }
    }
  }
  
  openModal() {    
    if (!sessionStorage.getItem("resource")) {
      return;
    }
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.modalRef = this.modalService.show(this.template1, this.modalOption);
    //this.modalRef.setClass('ea-modal');
    this.modalDisplayed = true;
  }

  closeModal() {
    this.modalRef.hide();
    this.modalDisplayed = false;
    this.resetTimer();
  }

  confirmLogout(){
    this.spinner.show();
    this.stopTimer();
    this.authService.logout();
  }

  private render(count) {
    this.secondsDisplay = this.getSeconds(count);
    this.minutesDisplay = this.getMinutes(count);
  }

  private getSeconds(ticks: number) {
    const seconds = ((ticks % 60000) / 1000).toFixed(0);
    return this.pad(seconds);
  }

  private getMinutes(ticks: number) {
    const minutes = Math.floor(ticks / 60000);
    return this.pad(minutes);
  }

  private pad(digit: any) {
    return digit <= 9 ? '0' + digit : digit;
  }
}
