import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, Subscription, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { IndividualBookingVM } from '../../Models/IndividualBookingVM';
import { JobVM } from '../../Models/JobVM';
import { EngagementSubmissionVM } from '../../Models/EngagementSubmissionVM';
import { ResourceDto } from '../../Models/ResourceDto';
import { BookingGridActionService } from '../../Models/BookingGridActionService';
import { AuthService } from '../../shared/auth/auth.service';
import { IndividualSwapResourceVM } from '../../Models/IndividualSwapResourceVM';
import { CurrentWeekVM } from '../../Models/CurrentWeekVM';
import { ResourceUtilizationRequestVM, ResourceUtilizationVM } from '../../Models/IndividualWallChartVM';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { FavouriteJobsAddActionDto, FavouriteJobsDto, FavouriteJobsTableTypeDto } from 'app/Models/FavouriteJobsDto';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class IndividualService extends BehaviorSubject<any> {
  isPercentageSelected: boolean = false;
  closeModalWindow: boolean = false;
  @Output() change: EventEmitter<boolean> = new EventEmitter();
  private eventData = new BookingGridActionService();
  private engagementSubmission = new EngagementSubmissionVM();
  private requestId = 0;
  private refreshChartData = 0;
  private resource = new ResourceDto();
  private resourceId = 0;
  private editSource = new BehaviorSubject(this.eventData);
  editEvent = this.editSource.asObservable();
  private configureApprovalQueue = new BehaviorSubject(this.eventData);
  configureEvent = this.configureApprovalQueue.asObservable();
  private swapSource = new BehaviorSubject(this.eventData);
  swapEvent = this.swapSource.asObservable();

  private parentEditSource = new BehaviorSubject(this.eventData);
  parentEditEvent = this.parentEditSource.asObservable();

  private addSource = new BehaviorSubject(this.eventData);
  addEvent = this.addSource.asObservable();
  private splitSource = new BehaviorSubject(this.eventData);
  splitEvent = this.splitSource.asObservable();
  private deleteSource = new BehaviorSubject(this.eventData);
  deleteEvent = this.deleteSource.asObservable();
  private previewSource = new BehaviorSubject(this.engagementSubmission);
  previewEvent = this.previewSource.asObservable();
  private messageEngagementAllocation = new BehaviorSubject(this.isPercentageSelected);
  currentMessageEngagementAllocation = this.messageEngagementAllocation.asObservable();
  private messageCloseModal = new BehaviorSubject(this.closeModalWindow);
  currentMessageCloseModal = this.messageCloseModal.asObservable();
  private messageCloseChildModal = new BehaviorSubject(this.closeModalWindow);
  currentMessageCloseChildModal = this.messageCloseChildModal.asObservable();
  private setRequestId = new BehaviorSubject(this.requestId);
  setRequestIdEvent = this.setRequestId.asObservable();
  private refreshChart = new BehaviorSubject(this.refreshChartData);
  refreshChartEvent = this.refreshChart.asObservable();
  private setHeaderData = new BehaviorSubject(this.resource);
  setHeaderDetailsEvent = this.setHeaderData.asObservable();
  // private setSelectedResourceId = new BehaviorSubject(this.resourceId);
  // setSelectedResourceIdEvent = this.setSelectedResourceId.asObservable();


  private _defaultLoadingChange = new BehaviorSubject(null);
  defaultLoadingChange = this._defaultLoadingChange.asObservable();
  
  endpoint = environment.apiEndpoint;
  constructor(private http: HttpClient, private auth: AuthService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private sanitizer:DomSanitizer) {
    super(null);
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  private getAPI(actionName: string): string {
    this.auth.checkTokenExpiredAndRefresh();
    return this.endpoint + 'IndividualBooking/' + actionName;
  }

  getBookingListForGrid(booking: IndividualBookingVM): Observable<any> {
    return this.http.post<IndividualBookingVM[]>(this.getAPI('FetchBookingList'), booking,
      this.httpOptions).pipe(
        map(data => data),
        catchError(this.handleError<any>('getBookingListForGrid'))
      );
  }

  getBookingListForGrid_Kendo(): Observable<any> {
    return this.http.get<IndividualBookingVM[]>(this.getAPI('getBookingList'),
      this.httpOptions).pipe(
        map(data => data),
        catchError(this.handleError<any>('getBookingList'))
      );
  }

  invokeUpdateIndividualFilterBookingGrid = new EventEmitter();    
  UpdateIndividualFilterBookingGridSubscription: Subscription; 
  UpdateIndividualFilterBookingGrid() {    
    this.invokeUpdateIndividualFilterBookingGrid.emit();    
  }     
 

  getBookingListForWallchart(booking: IndividualBookingVM): Observable<any> {
    return this.http.post<IndividualBookingVM[]>(this.getAPI('FetchBookingListWallchart'), booking,
      this.httpOptions).pipe(
        map(data => data),
        catchError(this.handleError<any>('getBookingListForWallchart'))
      );
  }
  
  GetResourceUtilization(input: ResourceUtilizationRequestVM): Observable<any> {
    return this.http.post<ResourceUtilizationVM[]>(this.getAPI('FetchResourceUtilization'), input,
      this.httpOptions).pipe(
        map(data => data),
        catchError(this.handleError<any>('GetResourceUtilization'))
      );
  }

  editBooking(booking: any): Observable<any> {
    return this.http.post<any[]>(this.getAPI('EditBooking'), booking,
      { headers: this.httpOptions.headers, observe: 'response' }).pipe(
        map(data => data),
        catchError(this.handleError<any>('editBooking'))
      );
  }

  deleteBooking(booking: any): Observable<any> {
    return this.http.post<IndividualBookingVM[]>(this.getAPI('RemoveBooking'), booking,
      { headers: this.httpOptions.headers, observe: 'response' }).pipe(
        map(data => data),
        catchError(this.handleError<any>('deleteBooking'))
      );
  }

  splitBooking(booking: any): Observable<any> {
    return this.http.post<IndividualBookingVM[]>(this.getAPI('SplitBooking'), booking,
      { headers: this.httpOptions.headers, observe: 'response' }).pipe(
        map(data => data),
        catchError(this.handleError<any>('splitBooking'))
      );
  }

  getEngagementSearchResult(category: JobVM): Observable<any> {
    return this.http.post<JobVM[]>(this.getAPI('FetchEngagement'), category,
      { headers: this.httpOptions.headers, observe: 'response' }).pipe(
        map(data => data),
        catchError(this.handleError<any>('getEngagementSearchResult'))
      );
  }

  addBooking(booking): Observable<any> {
    return this.http.post<IndividualBookingVM[]>(this.getAPI('AddBooking'), booking,
      { headers: this.httpOptions.headers, observe: 'response' }).pipe(
        map(data => data),
        catchError(this.handleError<any>('addBooking'))
      );
  }

  validateHours(booking: IndividualBookingVM): Observable<any> {
    return this.http.post<any[]>(this.getAPI('ValidateHours'), booking,
    { headers: this.httpOptions.headers }).pipe(
      map(data => data),
      catchError(this.handleError<any>('validateHours'))
    );
  }

  getEngagementSubmissionList(): Observable<any> {
    return this.http.get<EngagementSubmissionVM[]>(this.getAPI('FetchEngagementSubmissions'),
      this.httpOptions).pipe(
        map(data => data),
        catchError(this.handleError<any>('getEngagementSubmissionList'))
      );
  }

  // updateEngagementSubmissionList(engagementSubmission: EngagementSubmissionVM[]): Observable<any> {
  //   return this.http.post<EngagementSubmissionVM[]>(this.getAPI('UpdateEngagementSubmissionList'),
  //     engagementSubmission, { headers: this.httpOptions.headers, observe: 'response' }).pipe(
  //       map(data => data),
  //       catchError(this.handleError<any>('updateEngagementSubmissionList'))
  //     );
  // }

  getResourceList(resource: ResourceDto): Observable<any> {
    return this.http.post<ResourceDto[]>(this.getAPI('FetchResource'), resource, this.httpOptions).pipe(
      map(data => data),
      catchError(this.handleError<any>('FetchResource'))
    );
  }

  private handleError<T>(result?: T) {
    // return (error: any): Observable<T> => {
    //   console.error(error); // log to console instead      
    //   return of(result as T);
    // };
    return (error: any): Observable<T> => {
      let status = '';
      let msg = '';
      if(Object.prototype.hasOwnProperty.call(error, 'status')) {
          status = error.status.toString();
      }
      if (typeof error.error === 'string') {
          msg = error.error;
      }
      else if (Object.prototype.hasOwnProperty.call(error.error, 'title')) {
          msg = error.error.title;
      }
      else if (Object.prototype.hasOwnProperty.call(error.error, 'customException')) {
          msg = error.error.customException;
          msg += `
          `+ JSON.stringify(error.error.customData);
      }
      if(status === '0' && (!msg || msg.trim().length === 0)) {
        msg = 'MERA Web API Service is down or inaccessible.'
      }

      let exceptionPrefix = '';
      if(status === '400') {
        exceptionPrefix = 'Unexpected error, please try later';
      }
    
      let alertMsg = `
      Response for MERA Web API Service - 
      Status Code: `+status+`
      Message: `+ exceptionPrefix + ` - 
      `+ msg;

      alert(alertMsg);
      //alert(JSON.stringify(error)); //todo: remove/comment on prod      
      console.error(alertMsg);
      console.error(JSON.stringify(error)); // log to console instead
      
      // if http response status is 401-Unauthorized
      if(status === '401') {
        this.router.navigate(['AccessError']);
      }
      else if(status === '0') {
        //MERA Web API Service is down or inaccessible.
        // todo: show diff page 
        //this.router.navigate(['AccessError']); 
      }
      
      this.spinner.hide();

      return of(result as T);
    };
  }

  engagementAllocationOnChange(isPercentageOptionSelected) {
    this.isPercentageSelected = isPercentageOptionSelected;
    this.messageEngagementAllocation.next(this.isPercentageSelected);
  }

  openEditModal(eventData: BookingGridActionService) {
    this.editSource.next(eventData);
  }

  openSwapModel(eventData: BookingGridActionService){
    this.swapSource.next(eventData);
  }

  openAddModal(eventData: BookingGridActionService) {
    this.addSource.next(eventData);
  }
  openSplitModal(eventData: BookingGridActionService) {
    this.splitSource.next(eventData);
  }
  openDeleteModal(eventData: BookingGridActionService) {
    this.deleteSource.next(eventData);
  }
  openPreviewModal(engagementSubmission: EngagementSubmissionVM) {
    this.previewSource.next(engagementSubmission);
  }

  openParentEditModal(eventData: BookingGridActionService) {
    this.parentEditSource.next(eventData);
  }

  openConfigureApproveQueueModal(eventData: BookingGridActionService) {
    this.configureApprovalQueue.next(eventData)
  }
  closeModal(exitModal) {
    this.messageCloseModal.next(exitModal);
  }

  closeChildModal(exitModal) {
    this.messageCloseChildModal.next(exitModal);
  }

  setRquestNumber(requestId) {
    this.setRequestId.next(requestId);
  }

  refreshWallChart(refresh: number) {
    this.refreshChart.next(refresh);
  }

  setHeaderDetails(objResourceVM) {
    this.setHeaderData.next(objResourceVM);
  }

  // setSelectedResource(resourceId) {
  //   this.setSelectedResourceId.next(resourceId);
  // }
  setDefaultLoading() {
    this._defaultLoadingChange.next(null);
  }
  
  fetchBookingChildData(booking: IndividualBookingVM): Observable<any> {
    return this.http.post<IndividualBookingVM[]>(this.getAPI('FetchBookingListChild'), booking,
      this.httpOptions).pipe(
        map(data => data),
        catchError(this.handleError<any>('fetchBookingChildData'))
      );
  }

  getBookingSplittedInPastFuture(requestDto): Observable<any> {
    return this.http.post<IndividualBookingVM[]>(this.getAPI('GetBookingSplittedInPastFuture'), 
    requestDto,
    this.httpOptions).pipe(
      map(data => data),
      catchError(this.handleError<any>('getBookingSplittedInPastFuture'))
    );
  }

  fetchResourceDetail(resource: ResourceDto): Observable<any> {
    return this.http.post<ResourceDto>(this.getAPI('FetchResource'), resource,
      this.httpOptions).pipe(
        map(data => {
          this.getProfilePhoto(resource.ResourceEmail).subscribe(result=>{
            data[0].ResourcePhotoUrl=result
          })
          return data[0]}),
        catchError(this.handleError<any>('getResourceDetails'))
      );
  }

  getProfilePhoto(userPrincipalName:string) {
    //let requestUrl = `https://graph.microsoft.com/v1.0/users/`+ userPrincipalName +  `/photo/$value`;
    let requestUrl = `https://graph.microsoft.com/v1.0/me/photo/$value`;
    return this.http.get(requestUrl,{ responseType: "blob" }).pipe(map(result => {
      let url = window.URL;
      return this.sanitizer.bypassSecurityTrustUrl(url.createObjectURL(result));
    }));

  }
  getProfilePhotoOthers(userPrincipalName:string) {
    let requestUrl = `https://graph.microsoft.com/v1.0/users/`+ userPrincipalName +  `/photo/$value`;
    //let requestUrl = `https://graph.microsoft.com/v1.0/me/photo/$value`;
    return this.http.get(requestUrl,{ responseType: "blob" }).pipe(map(result => {
      let url = window.URL;
      return this.sanitizer.bypassSecurityTrustUrl(url.createObjectURL(result));
    }));

  }
  
  swapBooking(booking: IndividualBookingVM): Observable<any> {
    return this.http.post<IndividualBookingVM[]>(this.getAPI('EditBooking'), booking,
      { headers: this.httpOptions.headers, observe: 'response' }).pipe(
        map(data => data),
        catchError(this.handleError<any>('editBooking'))
      );
  }

  validateReplaceHours(booking: IndividualSwapResourceVM[]): Observable<any> {
    return this.http.post<any>(this.getAPI('ValidateReplaceHours'), booking,
    { headers: this.httpOptions.headers }).pipe(
      map(data => data),
      catchError(this.handleError<any>('validateReplaceHours'))
    );
  }

  ValidateHoursLoadings(booking: any[]): Observable<any> {
    return this.http.post<any>(this.getAPI('ValidateHoursLoadings'), booking,
    { headers: this.httpOptions.headers }).pipe(
      map(data => data),
      catchError(this.handleError<any>('ValidateHoursLoadings'))
    );
  }

  ReplaceBooking(booking:any): Observable<any> {
    return this.http.post<any[]>(this.getAPI('ReplaceBooking'), booking,
      { headers: this.httpOptions.headers, observe: 'response' }).pipe(
        map(data => data),
        catchError(this.handleError<any>('editBooking'))
      );
  }

  DeleteBookings(booking: any): Observable<any> {
    return this.http.post<any>(this.getAPI('DeleteBookings'), booking,
      { headers: this.httpOptions.headers, observe: 'response' }).pipe(
        map(data => data),
        catchError(this.handleError<any>('DeleteBookings'))
      );
  }

  CancelBookings(booking: any): Observable<any> {
    return this.http.post<any>(this.getAPI('CancelRequest'), booking,
      { headers: this.httpOptions.headers, observe: 'response' }).pipe(
        map(data => data),
        catchError(this.handleError<any>('CancelRequest'))
      );
  }

  CancelBookingAction(CancelBookingDto: any): Observable<any> {
    return this.http.post<any>(this.getAPI('CancelRequest'), CancelBookingDto,
      { headers: this.httpOptions.headers, observe: 'response' }).pipe(
        map(data => data),
        catchError(this.handleError<any>('CancelRequest'))
      );
  }

  CancelMeraBookingsChild(inputDto: any): Observable<any> {
    return this.http.post<any>(this.getAPI('CancelMeraBookingsChild'), inputDto,
      { headers: this.httpOptions.headers, observe: 'response' }).pipe(
        map(data => data),
        catchError(this.handleError<any>('CancelMeraBookingsChild'))
      );
  }

  CancelMeraBookings(inputDto: any): Observable<any> {
    return this.http.post<any>(this.getAPI('CancelMeraBookings'), inputDto,
      { headers: this.httpOptions.headers, observe: 'response' }).pipe(
        map(data => data),
        catchError(this.handleError<any>('CancelMeraBookings'))
      );
  }

  EditBookings(booking: any): Observable<any> {
    return this.http.post<any[]>(this.getAPI('EditBookings'), booking,
      { headers: this.httpOptions.headers, observe: 'response' }).pipe(
        map(data => data),
        catchError(this.handleError<any>('EditBookings'))
      );
  }

  getPreviewCountList(): Observable<any> {
    return this.http.get<number>(this.getAPI('FetchPreviewCount'),
    this.httpOptions).pipe(
        map(data => data),
        catchError(this.handleError<any>('getPreviewCountList'))
      );      
  }

  // getCurrentWeek(): Observable<any> {
  //   return this.http.get<CurrentWeekVM>(this.getAPI('FetchCurrentWeek'),
  //   this.httpOptions).pipe(
  //       map(data => data),
  //       catchError(this.handleError<any>('FetchCurrentWeek'))
  //     );
  //   }

    updateEngagementSubmissionList(replaceAllBookings: any): Observable<any> {
      // let reqHeaders = new HttpHeaders({
      //   'Content-Type': 'application/json',
      //   'Content-Length': "'"+this.getByteSize(engagementSubmission)+"'",
      // });
      return this.http
        .post<any>(
          this.getAPI('UpdateEngagementSubmissionList'),
          replaceAllBookings,
          this.httpOptions
        )
        .pipe(
          map((data) => data),
          catchError(this.handleError<any>('updateEngagementSubmissionList'))
        );
    }

    addFavouriteJobs(favouriteJobs:FavouriteJobsAddActionDto): Observable<any> 
    {
       return this.http.post<any[]>(this.getAPI('AddFavouriteJobs'), favouriteJobs,
        { headers: this.httpOptions.headers, observe: 'response' }).pipe(
          map(data => data),
          catchError(this.handleError<any>('addFavouriteJobs'))
        );
    }
   
    getFavouriteJobs(favouriteJobs): Observable<any> {
      return this.http.post<FavouriteJobsDto[]>(this.getAPI('GetFavouriteJobs'), favouriteJobs , 
      this.httpOptions
      ).pipe(
        map(data => data),
        catchError(this.handleError<any>('GetFavouriteJobs'))
      );  
    }

    GetFavouriteJobsIndividualView(favouriteJobs): Observable<any> {
      return this.http.post<FavouriteJobsDto[]>(this.getAPI('GetFavouriteJobsIndividualView'), favouriteJobs , 
      this.httpOptions
      ).pipe(
        map(data => data),
        catchError(this.handleError<any>('GetFavouriteJobsIndividualView'))
      );  
    }
    
}
