<div class="appLayout individualRequest">
  <div class="requestedByMe">
    <div class="requestTableContainer">
      <div class="heading sectionHeading">
        <span>Requests By Me</span>
        <span
          class="booking_heading_info_icon"
          (click)="openModal(template1)"
          matTooltip="Legends of bookings status"
        ></span>
        <!-- <mat-icon class='arrowBack' (click)="openModal(template1)" class="questionIcon"  matTooltip="Legends of bookings status">info</mat-icon> -->
      </div>

      <div class="expandRequestByMe">
        <mat-icon
          *ngIf="!isRequestByMeExpanded"
          (click)="isRequestByMeExpanded = true"
          class="expandDetails"
          matTooltip="Expand for details"
          >expand_more
        </mat-icon>
        <mat-icon
          *ngIf="isRequestByMeExpanded"
          (click)="isRequestByMeExpanded = false"
          class="expandDetails"
          matTooltip="Expand for details"
          >expand_less
        </mat-icon>
      </div>
    </div>
    <div
      class="hideTableByMe"
      [ngClass]="{
        showTableByMe: isRequestByMeExpanded === true && isMobileDevice === true
      }"
    >
      <div class="clearFilterContainer">
        <div *ngIf="!isMobileDevice" class="dateContainer">
          <label class="req-label">Requests Submission</label>
          <form [formGroup]="requestedOnRangeByMe">
            <mat-form-field appearance="outline">
              <mat-date-range-input
                [rangePicker]="requestedOnPicker"
                [max]="maxRequestedDate"
              >
                <input
                  matStartDate
                  formControlName="start"
                  placeholder="Start Date"
                  (click)="requestedOnPicker.open()"
                  (dateChange)="onStartRequestedOnChangeByMe($event)"
                  readonly
                />-
                <input
                  matEndDate
                  formControlName="end"
                  placeholder="End Date"
                  (click)="requestedOnPicker.open()"
                  (dateChange)="onEndRequestedOnChangeByMe($event)"
                  readonly
                />
              </mat-date-range-input>
              <mat-icon class="clearDate" (click)="resetRequestedOnRangeByMe()"
                >close</mat-icon
              >
              <mat-date-range-picker #requestedOnPicker></mat-date-range-picker>
            </mat-form-field>
          </form>
        </div>
        <div class="filterMobile">
          <mat-form-field appearance="outline">
            <input
              matInput
              type="text"
              placeholder="Search..."
              [formControl]="keywordByME"
              autocomplete="off"
              maxlength="150"
            />
            <!-- <mat-icon class="clearSearchFilter" (click)="keywordByME='';doFilterByME()">close</mat-icon> -->
          </mat-form-field>
        </div>

        <mat-icon
          class="filterIcon"
          (click)="openFilterModalByMe(GridFilterByMe)"
          matTooltip="Apply filters"
          >filter_alt
        </mat-icon>
        <mat-icon
          (click)="clearByMeFilter()"
          class="clearFilter"
          matTooltip="Clear filters"
        >
          clear_all</mat-icon
        >
        <div
          *ngIf="!isMobileDevice"
          (click)="exportAsXLSXByMe()"
          matTooltip="Export to excel"
          class="exportExcel"
          [ngClass]="
            requestByMeList?.length === 0 ||
            dataSourceByMe?.filteredData.length === 0
              ? 'disablClass'
              : 'enableClass'
          "
        >
          <img
            class="exportExcelIcon"
            src="../../../../assets/images/excel-export-black.jpg"
          />
        </div>
        <!-- <mat-icon  *ngIf="!isMobileDevice" class="clearFilter" (click)="exportAsXLSXByMe()" matTooltip="Export to excel"
      [ngClass]="requestByMeList?.length === 0 || dataSourceByMe?.filteredData.length === 0? 'disablClass' : 'enableClass'">backup</mat-icon> -->
        <!-- <div class="clearFilter" (click)="clearByMeFilter()">Clear Filters</div> -->
      </div>
      <div class="requestTable">
        <table
          mat-table
          [dataSource]="dataSourceByMe"
          class="mat-elevation-z8 queuesTable"
          matSort
          #empTbSort_byme="matSort"
        >
          <ng-container matColumnDef="RequestId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Request ID
            </th>
            <td mat-cell *matCellDef="let row" data-title="Request ID">
              {{ row.RequestId }}
            </td>
          </ng-container>

          <ng-container matColumnDef="ActionId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Action ID</th>
            <td mat-cell *matCellDef="let row" data-title="Action ID">
              {{ row.ActionId }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Action">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
            <td mat-cell *matCellDef="let row" data-title="Action">
              {{ row.Action }}
            </td>
          </ng-container>

          <ng-container matColumnDef="ResourceName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Resource Name
            </th>
            <td mat-cell *matCellDef="let row" data-title="Resource Name">
              {{ row.ResourceName }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Engagement">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Engagement
            </th>
            <td mat-cell *matCellDef="let row" data-title="Engagement">
              {{ row.Engagement }}
            </td>
          </ng-container>

          <ng-container
            matColumnDef="InstanceName"
            *ngIf="loggedInUser?.IsMultiInstance"
          >
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Retain Instance
            </th>
            <td mat-cell *matCellDef="let row" data-title="Retain Instance">
              {{ row.InstanceName }}
            </td>
          </ng-container>

          <ng-container matColumnDef="StartDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Start Date
            </th>
            <td mat-cell *matCellDef="let row" data-title="Start Date">
              {{ row.StartDate | date: defaultDateFormat }}
            </td>
          </ng-container>

          <ng-container matColumnDef="EndDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>End Date</th>
            <td mat-cell *matCellDef="let row" data-title="End Date">
              {{ row.EndDate | date: defaultDateFormat }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Hours">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Hours</th>
            <td mat-cell *matCellDef="let row" data-title="Hours">
              {{ row.Hours }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Loading">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Loading %</th>
            <td mat-cell *matCellDef="let row" data-title="Loading %">
              {{ row.Loading }}%
            </td>
          </ng-container>

          <ng-container matColumnDef="UserSelection">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              User Selection
            </th>
            <td mat-cell *matCellDef="let row" data-title="User Selection">
              {{ row.UserSelection }}
            </td>
          </ng-container>

          <ng-container
            matColumnDef="Unconfirmed"
            *ngIf="!loggedInUser.Unconfirmed"
          >
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Unconfirmed
            </th>
            <td mat-cell *matCellDef="let row" data-title="Unconfirmed">
              {{ row.Unconfirmed }}
            </td>
          </ng-container>

          <ng-container *ngIf="loggedInUser.Ghost" matColumnDef="Ghost">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghost</th>
            <td mat-cell *matCellDef="let row" data-title="Ghost">
              {{ row.Ghost }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
            <td mat-cell *matCellDef="let row" data-title="Status">
              {{ row.Status }}
            </td>
          </ng-container>

          <ng-container matColumnDef="RejectionReason">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Status Information
            </th>
            <td mat-cell *matCellDef="let row" data-title="Status Information">
              {{ row.RejectionReason }}
            </td>
          </ng-container>

          <ng-container matColumnDef="ApproverComment">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Approver Comment
            </th>
            <td mat-cell *matCellDef="let row" data-title="Approver Comment">
              {{ row.ApproverComment }}
            </td>
          </ng-container>

          <ng-container matColumnDef="ApprovedOrRejectedBy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Approved/Rejected By
            </th>
            <td
              mat-cell
              *matCellDef="let row"
              data-title="Approved/Rejected By"
            >
              {{ row.ApprovedOrRejectedBy }}
            </td>
          </ng-container>

          <ng-container matColumnDef="ApprovedOrRejectedOn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Approved/Rejected On
            </th>
            <td
              mat-cell
              *matCellDef="let row"
              data-title="Approved/Rejected On"
            >
              <span
                *ngIf="
                  row.ApprovedOrRejectedOn &&
                  row.ApprovedOrRejectedOn.length > 0
                "
              >
                {{
                  row.ApprovedOrRejectedOn | date: defaultDateFormat + " HH:mm"
                }}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="RequestedDateTime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Requested Date/Time(GMT)
            </th>
            <td
              mat-cell
              *matCellDef="let row"
              data-title="Requested Date/Time(GMT)"
            >
              {{ row.RequestedDateTime | date: defaultDateFormat + " HH:mm" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Cancel">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Cancel</th>
            <td mat-cell *matCellDef="let row" class="actionColumn">
              <mat-icon
                class="tableIcon"
                style="color: #b9251c"
                *ngIf="row.Cancelable"
                matTooltip="Cancel"
                (click)="
                  openActionModal(GridActions, $event, row, 'cancelAction')
                "
                >close</mat-icon
              >
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumnsByMe; sticky: true"
          ></tr>
          <!-- <tr mat-header-row *matHeaderRowDef="filterColumnsByMe"></tr> -->
          <tr mat-row *matRowDef="let row; columns: displayedColumnsByMe"></tr>
        </table>
        <div class="noResults">
          <div *ngIf="dataSourceByMe === undefined">
            <mat-spinner [diameter]="40"></mat-spinner>
          </div>
          <div
            class="no-booking"
            *ngIf="
              requestByMeList.length === 0 ||
              dataSourceByMe.filteredData.length === 0
            "
          >
            Bookings not found
          </div>
        </div>
      </div>
      <mat-paginator
        [pageSizeOptions]="[10, 25, 100]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>
  <div class="requestedForMe">
    <div class="requestTableContainer">
      <div class="requestHead heading">Requests For Me</div>
      <div class="expandRequestByMe">
        <mat-icon
          *ngIf="!isRequestForMeExpanded"
          (click)="isRequestForMeExpanded = true"
          class="expandDetails"
          matTooltip="Expand for details"
          >expand_more
        </mat-icon>
        <mat-icon
          *ngIf="isRequestForMeExpanded"
          (click)="isRequestForMeExpanded = false"
          class="expandDetails"
          matTooltip="Expand for details"
          >expand_less
        </mat-icon>
      </div>
    </div>
    <div
      class="hideTableForMe"
      [ngClass]="{
        showTableForMe:
          isRequestForMeExpanded === true && isMobileDevice === true
      }"
    >
      <div class="clearFilterContainer">
        <div *ngIf="!isMobileDevice" class="dateContainer">
          <label class="req-label">Requests Submission</label>
          <form [formGroup]="requestedOnRangeForMe">
            <mat-form-field appearance="outline">
              <mat-date-range-input
                [rangePicker]="requestedOnPickerForMe"
                [max]="maxRequestedDate"
              >
                <input
                  matStartDate
                  formControlName="start"
                  placeholder="Start Date"
                  (click)="requestedOnPickerForMe.open()"
                  (dateChange)="onStartRequestedOnChangeForMe($event)"
                  readonly
                />-
                <input
                  matEndDate
                  formControlName="end"
                  placeholder="End Date"
                  (click)="requestedOnPickerForMe.open()"
                  (dateChange)="onEndRequestedOnChangeForMe($event)"
                  readonly
                />
              </mat-date-range-input>
              <mat-icon class="clearDate" (click)="resetRequestedOnRangeForMe()"
                >close</mat-icon
              >
              <mat-date-range-picker
                #requestedOnPickerForMe
              ></mat-date-range-picker>
            </mat-form-field>
          </form>
        </div>
        <div class="filterMobile">
          <mat-form-field appearance="outline">
            <input
              matInput
              type="text"
              placeholder="Search..."
              [formControl]="keywordForME"
              autocomplete="off"
              maxlength="150"
            />
          </mat-form-field>
        </div>
        <mat-icon
          class="filterIcon"
          (click)="openFilterModalForMe(GridFilterForMe)"
          matTooltip="Apply filters"
        >
          filter_alt
        </mat-icon>
        <mat-icon
          (click)="clearForMeFilter()"
          class="clearFilter"
          matTooltip="Clear filters"
        >
          clear_all</mat-icon
        >
        <div
          *ngIf="!isMobileDevice"
          (click)="exportAsXLSXForMe()"
          matTooltip="Export to excel"
          class="exportExcel"
          [ngClass]="
            requestForMeList?.length === 0 ||
            dataSourceForMe?.filteredData.length === 0
              ? 'disablClass'
              : 'enableClass'
          "
        >
          <img
            class="exportExcelIcon"
            src="../../../../assets/images/excel-export-black.jpg"
          />
        </div>
        <!-- <mat-icon  *ngIf="!isMobileDevice" class="clearFilter" (click)="exportAsXLSXForMe()" matTooltip="Export to excel"
      [ngClass]="requestForMeList?.length === 0 || dataSourceForMe?.filteredData.length === 0? 'disablClass' : 'enableClass'">backup</mat-icon> -->
      </div>
      <div class="requestTable">
        <table
          mat-table
          [dataSource]="dataSourceForMe"
          class="mat-elevation-z8 queuesTable"
          matSort
          id="excel-table"
          #empTbSort_forme="matSort"
        >
          <ng-container matColumnDef="RequestId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Request ID
            </th>
            <td mat-cell *matCellDef="let row" data-title="Request ID">
              {{ row.RequestId }}
            </td>
          </ng-container>

          <ng-container matColumnDef="ActionId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Action ID</th>
            <td mat-cell *matCellDef="let row" data-title="Action ID">
              {{ row.ActionId }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Action">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
            <td mat-cell *matCellDef="let row" data-title="Action">
              {{ row.Action }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Engagement">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Engagement
            </th>
            <td mat-cell *matCellDef="let row" data-title="Engagement">
              {{ row.Engagement }}
            </td>
          </ng-container>

          <ng-container
            matColumnDef="InstanceName"
            *ngIf="loggedInUser?.IsMultiInstance"
          >
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Retain Instance
            </th>
            <td mat-cell *matCellDef="let row" data-title="Retain Instance">
              {{ row.InstanceName }}
            </td>
          </ng-container>

          <ng-container matColumnDef="StartDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Start Date
            </th>
            <td mat-cell *matCellDef="let row" data-title="Start Date">
              {{ row.StartDate }}
            </td>
          </ng-container>

          <ng-container matColumnDef="EndDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>End Date</th>
            <td mat-cell *matCellDef="let row" data-title="End Date">
              {{ row.EndDate }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Hours">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Hours</th>
            <td mat-cell *matCellDef="let row" data-title="Hours">
              {{ row.Hours }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Loading">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Loading %</th>
            <td mat-cell *matCellDef="let row" data-title="Loading %">
              {{ row.Loading }}%
            </td>
          </ng-container>

          <ng-container matColumnDef="UserSelection">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="colhead">User Selection</div>
              <div class="emptyContainer">&nbsp;&nbsp;&nbsp;</div>
            </th>
            <td mat-cell *matCellDef="let row" data-title="User Selection">
              {{ row.UserSelection }}
            </td>
          </ng-container>

          <ng-container
            matColumnDef="Unconfirmed"
            *ngIf="!loggedInUser.Unconfirmed"
          >
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Unconfirmed
            </th>
            <td mat-cell *matCellDef="let row" data-title="Unconfirmed">
              {{ row.Unconfirmed }}
            </td>
          </ng-container>

          <ng-container *ngIf="loggedInUser.Ghost" matColumnDef="Ghost">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghost</th>
            <td mat-cell *matCellDef="let row" data-title="Ghost">
              {{ row.Ghost }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
            <td mat-cell *matCellDef="let row" data-title="Status">
              {{ row.Status }}
            </td>
          </ng-container>

          <ng-container matColumnDef="RejectionReason">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Status Information
            </th>
            <td mat-cell *matCellDef="let row" data-title="Status Information">
              {{ row.RejectionReason }}
            </td>
          </ng-container>

          <ng-container matColumnDef="ApproverComment">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Approver Comment
            </th>
            <td mat-cell *matCellDef="let row" data-title="Approver Comment">
              {{ row.ApproverComment }}
            </td>
          </ng-container>

          <ng-container matColumnDef="ApprovedOrRejectedBy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Approved/Rejected By
            </th>
            <td
              mat-cell
              *matCellDef="let row"
              data-title="Approved/Rejected By"
            >
              {{ row.ApprovedOrRejectedBy }}
            </td>
          </ng-container>

          <ng-container matColumnDef="ApprovedOrRejectedOn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Approved/Rejected On
            </th>
            <td
              mat-cell
              *matCellDef="let row"
              data-title="Approved/Rejected On"
            >
              {{ row.ApprovedOrRejectedOn }}
            </td>
          </ng-container>

          <ng-container matColumnDef="ResourceName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Requested By
            </th>
            <td mat-cell *matCellDef="let row" data-title="Requested By">
              {{ row.RequestedBy }}
            </td>
          </ng-container>

          <ng-container matColumnDef="RequestedDateTime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Requested Date/Time(GMT)
            </th>
            <td
              mat-cell
              *matCellDef="let row"
              data-title="Requested Date/Time(GMT)"
            >
              {{ row.RequestedDateTime }}
            </td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumnsForMe; sticky: true"
          ></tr>
          <!-- <tr mat-header-row *matHeaderRowDef="filterColumnsForMe"></tr> -->
          <tr mat-row *matRowDef="let row; columns: displayedColumnsForMe"></tr>
        </table>
        <div class="noResults">
          <div *ngIf="dataSourceForMe === undefined">
            <mat-spinner [diameter]="40"></mat-spinner>
          </div>
          <div
            class="no-booking"
            *ngIf="
              requestForMeList.length === 0 ||
              dataSourceForMe.filteredData.length === 0
            "
          >
            Bookings not found
          </div>
        </div>
      </div>
      <mat-paginator
        [pageSizeOptions]="[10, 25, 100]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>

  <div
    class="approveByMe"
    *ngIf="!isMobileDevice && isApprovedByMeSectionRequired"
  >
    <div class="requestTableContainer">
      <div class="requestHead heading">Approved/Rejected By Me</div>
      <div class="expandRequestByMe">
        <mat-icon
          *ngIf="!isApproveByMeExpanded"
          (click)="isApproveByMeExpanded = true"
          class="expandDetails"
          matTooltip="Expand for details"
          >expand_more
        </mat-icon>
        <mat-icon
          *ngIf="isApproveByMeExpanded"
          (click)="isApproveByMeExpanded = false"
          class="expandDetails"
          matTooltip="Expand for details"
          >expand_less
        </mat-icon>
      </div>
    </div>
    <div
      class="hideTableApproveForMe"
      [ngClass]="{
        showTableApproveForMe:
          isApproveByMeExpanded === true && isMobileDevice === true
      }"
    >
      <div class="clearFilterContainer">
        <div *ngIf="!isMobileDevice" class="dateContainer">
          <label class="req-label">Requests Submission</label>
          <form [formGroup]="requestedOnRangeApprove">
            <mat-form-field appearance="outline">
              <mat-date-range-input
                [rangePicker]="requestedOnPickerApprove"
                [max]="maxRequestedDate"
              >
                <input
                  matStartDate
                  formControlName="start"
                  placeholder="Start Date"
                  (click)="requestedOnPickerApprove.open()"
                  (dateChange)="onStartRequestedOnChangeApprove($event)"
                  readonly
                />-
                <input
                  matEndDate
                  formControlName="end"
                  placeholder="End Date"
                  (click)="requestedOnPickerApprove.open()"
                  (dateChange)="onEndRequestedOnChangeApprove($event)"
                  readonly
                />
              </mat-date-range-input>
              <mat-icon
                class="clearDate"
                (click)="resetRequestedOnRangeApprove()"
                >close</mat-icon
              >
              <mat-date-range-picker
                #requestedOnPickerApprove
              ></mat-date-range-picker>
            </mat-form-field>
          </form>
        </div>
        <div class="filterMobile">
          <mat-form-field appearance="outline">
            <input
              matInput
              type="text"
              placeholder="Search..."
              [formControl]="keywordApproveByME"
              autocomplete="off"
              maxlength="150"
            />
          </mat-form-field>
        </div>
        <mat-icon
          class="filterIcon"
          (click)="openFilterModalApproveByMe(GridFilterApproveByMe)"
          matTooltip="Apply filters"
        >
          filter_alt
        </mat-icon>
        <mat-icon
          (click)="clearApproveByMeFilter()"
          class="clearFilter"
          matTooltip="Clear filters"
        >
          clear_all</mat-icon
        >
        <div
          *ngIf="!isMobileDevice"
          (click)="exportAsXLSXApproveOrReject()"
          matTooltip="Export to excel"
          class="exportExcel"
          [ngClass]="
            approvalListByMe?.length === 0 ||
            dataSourceApproveByMe?.filteredData.length === 0
              ? 'disablClass'
              : 'enableClass'
          "
        >
          <img
            class="exportExcelIcon"
            src="../../../../assets/images/excel-export-black.jpg"
          />
        </div>
        <!-- <mat-icon  *ngIf="!isMobileDevice" class="clearFilter" (click)="exportAsXLSXApproveOrReject()" matTooltip="Export to excel"
      [ngClass]="approvalListByMe?.length === 0 || dataSourceApproveByMe?.filteredData.length === 0? 'disablClass' : 'enableClass'">backup</mat-icon> -->
      </div>
      <div class="requestTable">
        <table
          mat-table
          [dataSource]="dataSourceApproveByMe"
          class="mat-elevation-z8 queuesTable"
          matSort
          id="excel-table"
          #empTbSort_aprvRej="matSort"
        >
          <ng-container matColumnDef="RequestId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Request ID
            </th>
            <td mat-cell *matCellDef="let row" data-title="Request ID">
              {{ row.RequestId }}
            </td>
          </ng-container>

          <ng-container matColumnDef="ActionId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Action ID</th>
            <td mat-cell *matCellDef="let row" data-title="Action ID">
              {{ row.ActionId }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Action">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
            <td mat-cell *matCellDef="let row" data-title="Action">
              {{ row.Action }}
            </td>
          </ng-container>
          <ng-container matColumnDef="ResourceName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Resource</th>
            <td mat-cell *matCellDef="let row" data-title="Resource">
              {{ row.ResourceName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="Engagement">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Engagement
            </th>
            <td mat-cell *matCellDef="let row" data-title="Engagement">
              {{ row.Engagement }}
            </td>
          </ng-container>

          <ng-container
            matColumnDef="InstanceName"
            *ngIf="loggedInUser?.IsMultiInstance"
          >
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Retain Instance
            </th>
            <td mat-cell *matCellDef="let row" data-title="Retain Instance">
              {{ row.InstanceName }}
            </td>
          </ng-container>

          <ng-container matColumnDef="StartDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Start Date
            </th>
            <td mat-cell *matCellDef="let row" data-title="Start Date">
              {{ row.StartDate }}
            </td>
          </ng-container>

          <ng-container matColumnDef="EndDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>End Date</th>
            <td mat-cell *matCellDef="let row" data-title="End Date">
              {{ row.EndDate }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Hours">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Hours</th>
            <td mat-cell *matCellDef="let row" data-title="Hours">
              {{ row.Hours }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Loading">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Loading %</th>
            <td mat-cell *matCellDef="let row" data-title="Loading %">
              {{ row.Loading }}%
            </td>
          </ng-container>

          <ng-container matColumnDef="UserSelection">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="colhead">User Selection</div>
              <div class="emptyContainer">&nbsp;&nbsp;&nbsp;</div>
            </th>
            <td mat-cell *matCellDef="let row" data-title="User Selection">
              {{ row.UserSelection }}
            </td>
          </ng-container>

          <ng-container
            matColumnDef="Unconfirmed"
            *ngIf="!loggedInUser.Unconfirmed"
          >
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Unconfirmed
            </th>
            <td mat-cell *matCellDef="let row" data-title="Unconfirmed">
              {{ row.Unconfirmed }}
            </td>
          </ng-container>

          <ng-container *ngIf="loggedInUser.Ghost" matColumnDef="Ghost">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghost</th>
            <td mat-cell *matCellDef="let row" data-title="Ghost">
              {{ row.Ghost }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
            <td mat-cell *matCellDef="let row" data-title="Status">
              {{ row.Status }}
            </td>
          </ng-container>

          <ng-container matColumnDef="RejectionReason">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Status Information
            </th>
            <td mat-cell *matCellDef="let row" data-title="Status Information">
              {{ row.RejectionReason }}
            </td>
          </ng-container>

          <ng-container matColumnDef="ApproverComment">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Approver Comment
            </th>
            <td mat-cell *matCellDef="let row" data-title="Approver Comment">
              {{ row.ApproverComment }}
            </td>
          </ng-container>

          <ng-container matColumnDef="RequestorName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Requestor Name
            </th>
            <td mat-cell *matCellDef="let row" data-title="Requestor Name">
              {{ row.RequestorName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="RequestedDateTime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Requested Date/Time(GMT)
            </th>
            <td
              mat-cell
              *matCellDef="let row"
              data-title="Requested Date/Time(GMT)"
            >
              {{ row.RequestedDateTime }}
            </td>
          </ng-container>
          <ng-container matColumnDef="ApprovedDateTime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Approved/Rejected Date/Time (GMT)
            </th>
            <td
              mat-cell
              *matCellDef="let row"
              data-title="Approved/Rejected Date/Time (GMT)"
            >
              {{ row.ApprovedOrRejectedOn }}
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumnsApprove; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumnsApprove"
          ></tr>
        </table>
        <div class="noResults">
          <div *ngIf="dataSourceApproveByMe === undefined">
            <mat-spinner [diameter]="40"></mat-spinner>
          </div>
          <div
            class="no-booking"
            *ngIf="
              approvalListByMe?.length === 0 ||
              dataSourceApproveByMe?.filteredData.length === 0
            "
          >
            Booking not found
          </div>
        </div>
      </div>
      <mat-paginator
        [pageSizeOptions]="[10, 25, 100]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>
</div>
<ng-template #GridActions>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ gridActionModalHeader }}</h4>
    <!-- <mat-icon class="close" (click)="openConfirmationModal(EditConfirmation)">close</mat-icon> -->
    <mat-icon class="close" (click)="openConfirmationModal(showModalDelete)"
      >close</mat-icon
    >
  </div>
  <div class="modal-body">
    <app-cancel-request
      *ngIf="showModalDelete"
      [uiViewSource]="'MRV'"
      [actionEventData]="gridActionEventData"
    >
    </app-cancel-request>
  </div>
</ng-template>

<ng-template #GridFilterByMe>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Filter Requests By Me</h4>
    <mat-icon class="close" (click)="closeFilterModalByMe()">close</mat-icon>
  </div>
  <div class="modal-body">
    <div class="popupLayout">
      <div class="fullWidth">
        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input
              [formControl]="requestIdFilter"
              autocomplete="off"
              matInput
              type="text"
              placeholder="Request ID"
              maxlength="150"
            />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="RequestId">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #RequestId="matMenu">
            <button
              mat-menu-item
              (click)="filterValuesByMe.requestIdType = true"
            >
              <div
                class="menu-item"
                [ngClass]="{
                  selected: filterValuesByMe.requestIdType === true
                }"
              >
                Contains
              </div>
            </button>
            <button
              mat-menu-item
              (click)="filterValuesByMe.requestIdType = false"
            >
              <div
                class="menu-item"
                [ngClass]="{
                  selected: filterValuesByMe.requestIdType === false
                }"
              >
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>
        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input
              autocomplete="off"
              matInput
              type="text"
              placeholder="Action"
              [formControl]="actionFilter"
              maxlength="150"
            />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="Action">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #Action="matMenu">
            <button mat-menu-item (click)="filterValuesByMe.actionType = true">
              <div
                class="menu-item"
                [ngClass]="{
                  selected: filterValuesByMe.actionType === true
                }"
              >
                Contains
              </div>
            </button>
            <button mat-menu-item (click)="filterValuesByMe.actionType = false">
              <div
                class="menu-item"
                [ngClass]="{
                  selected: filterValuesByMe.actionType === false
                }"
              >
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>
        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input
              autocomplete="off"
              matInput
              type="text"
              placeholder="Resource Name"
              [formControl]="resourceNameFilter"
              maxlength="150"
            />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="ResourceName">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #ResourceName="matMenu">
            <button
              mat-menu-item
              (click)="filterValuesByMe.resourceNameType = true"
            >
              <div
                class="menu-item"
                [ngClass]="{
                  selected: filterValuesByMe.resourceNameType === true
                }"
              >
                Contains
              </div>
            </button>
            <button
              mat-menu-item
              (click)="filterValuesByMe.resourceNameType = false"
            >
              <div
                class="menu-item"
                [ngClass]="{
                  selected: filterValuesByMe.resourceNameType === false
                }"
              >
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>
        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input
              autocomplete="off"
              matInput
              type="text"
              placeholder="Engagement"
              [formControl]="engagementFilter"
              maxlength="150"
            />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="Engagement">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #Engagement="matMenu">
            <button
              mat-menu-item
              (click)="filterValuesByMe.engagementType = true"
            >
              <div
                class="menu-item"
                [ngClass]="{
                  selected: filterValuesByMe.engagementType === true
                }"
              >
                Contains
              </div>
            </button>
            <button
              mat-menu-item
              (click)="filterValuesByMe.engagementType = false"
            >
              <div
                class="menu-item"
                [ngClass]="{
                  selected: filterValuesByMe.engagementType === false
                }"
              >
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>
        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input
              autocomplete="off"
              matInput
              type="text"
              placeholder="Status"
              [formControl]="statusFilter"
              maxlength="150"
            />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="Status">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #Status="matMenu">
            <button mat-menu-item (click)="filterValuesByMe.statusType = true">
              <div
                class="menu-item"
                [ngClass]="{
                  selected: filterValuesByMe.statusType === true
                }"
              >
                Contains
              </div>
            </button>
            <button mat-menu-item (click)="filterValuesByMe.statusType = false">
              <div
                class="menu-item"
                [ngClass]="{
                  selected: filterValuesByMe.statusType === false
                }"
              >
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>
      </div>
      <div class="confirmationButtons">
        <button class="cancelBtn" (click)="closeFilterModalByMe()">
          Cancel
        </button>
        <button class="deleteBtn" (click)="filterGridByMe()">Filter</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #GridFilterForMe>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Filter Request For Me</h4>
    <mat-icon class="close" (click)="closeFilterModalForMe()">close</mat-icon>
  </div>
  <div class="modal-body">
    <div class="popupLayout">
      <div class="fullWidth">
        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input
              [formControl]="requestIdFilterForMe"
              autocomplete="off"
              matInput
              type="text"
              placeholder="Request ID"
              maxlength="150"
            />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="RequestIdForMe">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #RequestIdForMe="matMenu">
            <button
              mat-menu-item
              (click)="filterValuesForMe.requestIdType = true"
            >
              <div
                class="menu-item"
                [ngClass]="{
                  selected: filterValuesForMe.requestIdType === true
                }"
              >
                Contains
              </div>
            </button>
            <button
              mat-menu-item
              (click)="filterValuesForMe.requestIdType = false"
            >
              <div
                class="menu-item"
                [ngClass]="{
                  selected: filterValuesForMe.requestIdType === false
                }"
              >
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>

        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input
              [formControl]="actionFilterForMe"
              autocomplete="off"
              matInput
              type="text"
              placeholder="Action"
              maxlength="150"
            />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="ActionForMe">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #ActionForMe="matMenu">
            <button mat-menu-item (click)="filterValuesForMe.actionType = true">
              <div
                class="menu-item"
                [ngClass]="{
                  selected: filterValuesForMe.actionType === true
                }"
              >
                Contains
              </div>
            </button>
            <button
              mat-menu-item
              (click)="filterValuesForMe.actionType = false"
            >
              <div
                class="menu-item"
                [ngClass]="{
                  selected: filterValuesForMe.actionType === false
                }"
              >
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>
        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input
              [formControl]="engagementFilterForMe"
              autocomplete="off"
              matInput
              type="text"
              placeholder="Engagement"
              maxlength="150"
            />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="EngagementForMe">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #EngagementForMe="matMenu">
            <button
              mat-menu-item
              (click)="filterValuesForMe.engagementType = true"
            >
              <div
                class="menu-item"
                [ngClass]="{
                  selected: filterValuesForMe.engagementType === true
                }"
              >
                Contains
              </div>
            </button>
            <button
              mat-menu-item
              (click)="filterValuesForMe.engagementType = false"
            >
              <div
                class="menu-item"
                [ngClass]="{
                  selected: filterValuesForMe.engagementType === false
                }"
              >
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>
        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input
              matInput
              [formControl]="statusFilterForMe"
              autocomplete="off"
              matInput
              type="text"
              placeholder="Status"
              maxlength="150"
            />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="StatusForMe">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #StatusForMe="matMenu">
            <button mat-menu-item (click)="filterValuesForMe.statusType = true">
              <div
                class="menu-item"
                [ngClass]="{
                  selected: filterValuesForMe.statusType === true
                }"
              >
                Contains
              </div>
            </button>
            <button
              mat-menu-item
              (click)="filterValuesForMe.statusType = false"
            >
              <div
                class="menu-item"
                [ngClass]="{
                  selected: filterValuesForMe.statusType === false
                }"
              >
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>
        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input
              matInput
              [formControl]="resourceNameFilterForMe"
              autocomplete="off"
              matInput
              type="text"
              placeholder="Requested By"
              maxlength="150"
            />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="ResourceNameForMe">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #ResourceNameForMe="matMenu">
            <button
              mat-menu-item
              (click)="filterValuesForMe.resourceNameType = true"
            >
              <div
                class="menu-item"
                [ngClass]="{
                  selected: filterValuesForMe.resourceNameType === true
                }"
              >
                Contains
              </div>
            </button>
            <button
              mat-menu-item
              (click)="filterValuesForMe.resourceNameType = false"
            >
              <div
                class="menu-item"
                [ngClass]="{
                  selected: filterValuesForMe.resourceNameType === false
                }"
              >
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>
      </div>
      <div class="confirmationButtons">
        <button class="cancelBtn" (click)="closeFilterModalForMe()">
          Cancel
        </button>
        <button class="deleteBtn" (click)="filterGridForMe()">Filter</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #GridFilterApproveByMe>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Filter Approved/Rejected By Me</h4>
    <mat-icon class="close" (click)="closeFilterModalApprovalByMe()"
      >close</mat-icon
    >
  </div>
  <div class="modal-body">
    <div class="popupLayout">
      <div class="fullWidth">
        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input
              [formControl]="requestIdFilterApproval"
              autocomplete="off"
              matInput
              type="text"
              placeholder="Request ID"
              maxlength="150"
            />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="requestIdFilterMenu">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #requestIdFilterMenu="matMenu">
            <button
              mat-menu-item
              (click)="filterValuesApprovalByMe.requestIdType = true"
            >
              <div
                class="menu-item"
                [ngClass]="{
                  selected: filterValuesApprovalByMe.requestIdType === true
                }"
              >
                Contains
              </div>
            </button>
            <button
              mat-menu-item
              (click)="filterValuesApprovalByMe.requestIdType = false"
            >
              <div
                class="menu-item"
                [ngClass]="{
                  selected: filterValuesApprovalByMe.requestIdType === false
                }"
              >
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>

        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input
              [formControl]="actionFilterApproval"
              autocomplete="off"
              matInput
              type="text"
              placeholder="Action"
              maxlength="150"
            />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="actionFilterMenu">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #actionFilterMenu="matMenu">
            <button
              mat-menu-item
              (click)="filterValuesApprovalByMe.actionType = true"
            >
              <div
                class="menu-item"
                [ngClass]="{
                  selected: filterValuesApprovalByMe.actionType === true
                }"
              >
                Contains
              </div>
            </button>
            <button
              mat-menu-item
              (click)="filterValuesApprovalByMe.actionType = false"
            >
              <div
                class="menu-item"
                [ngClass]="{
                  selected: filterValuesApprovalByMe.actionType === false
                }"
              >
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>
        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input
              matInput
              [formControl]="resourceNameFilterApproval"
              autocomplete="off"
              matInput
              type="text"
              placeholder="Resource"
              maxlength="150"
            />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="resourceNameFilterMenu">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #resourceNameFilterMenu="matMenu">
            <button
              mat-menu-item
              (click)="filterValuesApprovalByMe.resourceNameType = true"
            >
              <div
                class="menu-item"
                [ngClass]="{
                  selected: filterValuesApprovalByMe.resourceNameType === true
                }"
              >
                Contains
              </div>
            </button>
            <button
              mat-menu-item
              (click)="filterValuesApprovalByMe.resourceNameType = false"
            >
              <div
                class="menu-item"
                [ngClass]="{
                  selected: filterValuesApprovalByMe.resourceNameType === false
                }"
              >
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>
        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input
              [formControl]="engagementFilterApproval"
              autocomplete="off"
              matInput
              type="text"
              placeholder="Engagement"
              maxlength="150"
            />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="engagementFilterMenu">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #engagementFilterMenu="matMenu">
            <button
              mat-menu-item
              (click)="
                filterValuesApprovalByMe.engagementType = true;
                triggerFilterForME()
              "
            >
              <div
                class="menu-item"
                [ngClass]="{
                  selected: filterValuesApprovalByMe.engagementType === true
                }"
              >
                Contains
              </div>
            </button>
            <button
              mat-menu-item
              (click)="
                filterValuesApprovalByMe.engagementType = false;
                triggerFilterForME()
              "
            >
              <div
                class="menu-item"
                [ngClass]="{
                  selected: filterValuesApprovalByMe.engagementType === false
                }"
              >
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>
        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input
              matInput
              [formControl]="statusFilterApproval"
              autocomplete="off"
              matInput
              type="text"
              placeholder="Status"
              maxlength="150"
            />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="statusFilterMenu">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #statusFilterMenu="matMenu">
            <button
              mat-menu-item
              (click)="filterValuesApprovalByMe.statusType = true"
            >
              <div
                class="menu-item"
                [ngClass]="{
                  selected: filterValuesApprovalByMe.statusType === true
                }"
              >
                Contains
              </div>
            </button>
            <button
              mat-menu-item
              (click)="filterValuesApprovalByMe.statusType = false"
            >
              <div
                class="menu-item"
                [ngClass]="{
                  selected: filterValuesApprovalByMe.statusType === false
                }"
              >
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>
      </div>
      <div class="confirmationButtons">
        <button class="cancelBtn" (click)="closeFilterModalApprovalByMe()">
          Cancel
        </button>
        <button class="deleteBtn" (click)="filterGridApprovalByMe()">
          Filter
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #template1>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Booking Status</h4>
    <mat-icon class="close" (click)="modalRef.hide()">close</mat-icon>
  </div>
  <div class="modal-body">
    <div class="iconSection">
      <!-- <span class="iconInfo"><mat-icon>fiber_manual_record</mat-icon></span> -->
      <span class="iconData">
        <span class="iconDataStatus">Pending Approval</span> – Booking requests
        submitted by CSP but awaiting RM approval.
      </span>
    </div>

    <div class="iconSection">
      <!-- <span class="iconInfo"><mat-icon>fiber_manual_record</mat-icon></span> -->
      <span class="iconData">
        <span class="iconDataStatus">Denied</span> – Booking requests that are
        rejected by the RM during the approval process.
      </span>
    </div>

    <div class="iconSection">
      <!-- <span class="iconInfo"><mat-icon>fiber_manual_record</mat-icon></span> -->
      <span class="iconData"
        ><span class="iconDataStatus">Submitted</span> – Bookings that are
        submitted/approved in MERA.</span
      >
    </div>
    <div class="iconSection">
      <!-- <span class="iconInfo"><mat-icon>fiber_manual_record</mat-icon></span> -->
      <span class="iconData"
        ><span class="iconDataStatus">Cancelled</span> – Booking Requests
        cancelled by the user before sending it to Retain.</span
      >
    </div>
    <div class="iconSection">
      <!-- <span class="iconInfo"><mat-icon>fiber_manual_record</mat-icon></span> -->
      <span class="iconData"
        ><span class="iconDataStatus">In-Progress</span> – Bookings that are
        picked by RUAT.
      </span>
    </div>
    <div class="iconSection">
      <!-- <span class="iconInfo"><mat-icon>fiber_manual_record</mat-icon></span> -->
      <span class="iconData"
        ><span class="iconDataStatus">Completed</span> – Bookings updated in
        Retain.
      </span>
    </div>
    <div class="iconSection">
      <!-- <span class="iconInfo"><mat-icon>fiber_manual_record</mat-icon></span> -->
      <span class="iconData"
        ><span class="iconDataStatus">Rejected</span> – Bookings rejected in
        Retain.
      </span>
    </div>
  </div>
</ng-template>
