import { getWeekYear } from 'ngx-bootstrap/chronos/units/week-year';
import { v4 as uuidv4 } from 'uuid';
import * as CryptoJS from 'crypto-js';  

export class EncryptedKey {


   static getUniqId():string{
        return uuidv4();
      }

  // Declare this key and iv values in declaration
    private static key = CryptoJS.enc.Utf8.parse('MeraGds@2022#key');
    private static iv = CryptoJS.enc.Utf8.parse('MeraGds@2022$iv');

  // Methods for the encrypt Using AES
   static encryptUsingAES256() {
   var uniId=EncryptedKey.getUniqId()
      var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(uniId), this.key, {
         keySize: 128 / 8,
         iv: this.iv,
         mode: CryptoJS.mode.CBC,
         padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
}


}