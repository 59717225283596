import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
} from '@azure/msal-browser';
import {
  msalConfig,
  loginRequest,
  protectedResources,
} from './shared/auth/auth-config';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LandingComponent } from './landing/landing.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './shared/layout/header/header.component';
import { FooterComponent } from './shared/layout/footer/footer.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ModalModule } from 'ngx-bootstrap/modal';
import { InactivityTimerComponent } from './landing/inactivity-timer/inactivity-timer.component';
import { AccessErrorComponent } from './access-error/access-error.component';
import { ErrorComponent } from './error/error.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { IndividualBookingComponent } from './Views/individual/individual-booking/individual-booking.component';
import { IndividualWallchartComponent } from './Views/individual/individual-wallchart/individual-wallchart.component';
import { EditComponent } from './Views/individual/edit/edit.component';
import { AddComponent } from './Views/individual/add/add.component';
import { SplitComponent } from './Views/individual/split/split.component';
import { DeleteComponent } from './Views/individual/delete/delete.component';
import { IndividualbookingchildComponent } from './Views/individual/individualbookingchild/individualbookingchild.component';
import { PreviewComponent } from './Views/individual/preview/preview.component';
import { PrivacyNoticeComponent } from './Views/individual/privacy-notice/privacy-notice.component';
import { SubscribePrivacyNoticeComponent } from './landing/privacy-notice/privacy-notice.component';
import { SwapIndividualComponent } from './Views/individual/swap-individual/swap-individual.component';
import { Safe } from './landing/privacy-notice/safe-html.pipe';
import { DeleteParentComponent } from './Views/individual/delete-parent/delete-parent.component';
import { ParentEditComponent } from './Views/individual/parent-edit/parent-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DatePipe } from '@angular/common';
import { OrderModule } from 'ngx-order-pipe';
import { MatInputModule } from '@angular/material/input';
import { SearchDebounceDirective } from './shared/search/search-debounce.directive';
import { EngagementBookingComponent } from './Views/engagement/engagement-booking/engagement-booking.component';
import { ProfileSettingsComponent } from './Views/profile-settings/profile-settings.component';
import { PopoverModule, PopoverConfig } from 'ngx-bootstrap/popover';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { RequestQueueComponent } from './Views/individualRequest/request-queue/request-queue.component';
import { CancelRequestComponent } from './Views/individualRequest/cancel-request/cancel-request.component';
import { EngagementBookingChildComponent } from './Views/engagement/engagement-booking-child/engagement-booking-child.component';
import { EngagementWallchartComponent } from './Views/engagement/engagement-wallchart/engagement-wallchart.component';
import { EngagementAddComponent } from './Views/engagement/engagement-add/engagement-add.component';
import { LogoutComponent } from './logout/logout.component';
import { EditEngagementComponent } from './Views/engagement/engagement-edit/editEngagement.component';
import { SwapEngagementComponent } from './Views/engagement/swap-engagement/swap-engagement.component';
import { SplitEngagementComponent } from './Views/engagement/split/splitEngagement.component';
import { DeleteEngagementComponent } from './Views/engagement/delete/deleteEngagement.component';
import { RoleConfigurationComponent } from './views/role-config/role-configuration/role-configuration.component';
import { AssignRoleComponent } from './views/role-config/assign-role/assign-role.component';
import { ViewResourceRoleComponent } from './views/role-config/view-resource-role/view-resource-role.component';
import { UpdateResourceEmailComponent } from './views/role-config/update-resource-email/update-resource-email.component';
import { AuditAssignRoleComponent } from './views/role-config/audit-assign-role/audit-assign-role.component';
import { WorkRequestComponent } from './Views/work-request/work-request.component';
import { AddRoleConfigurationComponent } from './views/role-config/add-role-configuration/add-role-configuration.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CompletedRequestQueueComponent } from './Views/workRequestCompleted/completed-request-queue/completed-request-queue.component';
import { EditRoleConfigurationComponent } from './views/role-config/edit-role-configuration/edit-role-configuration.component';
import { ReplaceallEngagementComponent } from './Views/engagement/replaceall-engagement/replaceall-engagement.component';
import {
  MatMomentDateModule,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { DatePickerFormatDirective } from './shared/date-format/date-picker-format.directive';
import { ApprovalLayerConfigurationComponent } from './views/role-config/approval-layer-configuration/approval-layer-configuration.component';
import { ApprovalQueue } from './Views/approvalQueue/approvalQueue.component';
import { AddConfigurationComponent } from './Views/approvalQueue/addConfiguration/addConfiguration.component';
import { MatTabsModule } from '@angular/material/tabs';
import { AppInterceptor } from './shared/http-interceptors/app-interceptor';
import { PostLogoutComponent } from './logout/post-logout/post-logout.component';
import { SafePipe } from './shared/safe.pipe';
import { ApplicationInsightsErrorHandler } from './shared/appInsights/app-insights-error-handlr';
import { HoursLoadingDirective } from './shared/hours-loading.directive';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { individualScheduleViewComponent } from './Views/scheduleView/individualScheduleView/individualScheduleView.component';
import { PendingChangesGuard } from './shared/pendingChangesGuard/pending-changes.guard';
import { ConfirmationComponent } from './shared/layout/confirmation/confirmation.component';
import { engagementScheduleViewComponent } from './Views/scheduleView/engagementScheduleView/engagementScheduleView.component';
import { NotesDialog } from './Views/scheduleView/notesDialog/notes-dialog.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    InactivityTimerComponent,
    AccessErrorComponent,
    ErrorComponent,
    IndividualBookingComponent,
    IndividualWallchartComponent,
    EditComponent,
    AddComponent,
    SplitComponent,
    DeleteComponent,
    IndividualbookingchildComponent,
    PreviewComponent,
    PrivacyNoticeComponent,
    SubscribePrivacyNoticeComponent,
    SwapIndividualComponent,
    Safe,
    DeleteParentComponent,
    ParentEditComponent,
    SearchDebounceDirective,
    EngagementBookingComponent,
    ProfileSettingsComponent,
    RequestQueueComponent,
    CancelRequestComponent,
    EngagementBookingChildComponent,
    EngagementWallchartComponent,
    EngagementAddComponent,
    LogoutComponent,
    EditEngagementComponent,
    SwapEngagementComponent,
    SplitEngagementComponent,
    DeleteEngagementComponent,
    RoleConfigurationComponent,
    AssignRoleComponent,
    ViewResourceRoleComponent,
    UpdateResourceEmailComponent,
    AuditAssignRoleComponent,
    WorkRequestComponent,
    AddRoleConfigurationComponent,
    CompletedRequestQueueComponent,
    EditRoleConfigurationComponent,
    ReplaceallEngagementComponent,
    DatePickerFormatDirective,
    ApprovalLayerConfigurationComponent,
    ApprovalQueue,
    AddConfigurationComponent,
    PostLogoutComponent,
    SafePipe,
    HoursLoadingDirective,
    engagementScheduleViewComponent,
    individualScheduleViewComponent,
    NotesDialog,
    ConfirmationComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    MsalModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatRadioModule,
    MatSelectModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatIconModule,
    MatSidenavModule,
    MatMenuModule,
    MatTableModule,
    MatListModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    ModalModule.forRoot(),
    MatFormFieldModule,
    FormsModule,
    NgMultiSelectDropDownModule,
    NgMultiSelectDropDownModule.forRoot(),
    ReactiveFormsModule,
    MatTooltipModule,
    OrderModule,
    MatInputModule,
    PopoverModule,
    MatPaginatorModule,
    MatTabsModule,
    MatSortModule,
    MatMomentDateModule,
    MatSlideToggleModule,
    MatSnackBarModule,
  ],
  providers: [
    DatePipe,
    DecimalPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ApplicationInsightsErrorHandler,
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    PendingChangesGuard,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}

///**
// * Here we pass the configuration parameters to create an MSAL instance.
// * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
// */
// export function MSALInstanceFactory(): IPublicClientApplication {
//  return new PublicClientApplication({
//    auth: {
//      clientId: 'e76222d1-be3f-4a25-9828-f46a2b51c4fe',
//      authority: 'https://login.microsoftonline.com/7c0c36f5-af83-4c24-8844-9962e0163719', //tenantId
//      redirectUri: 'http://localhost:4200'
//    }
//    // ,
//    // cache: {
//    //   cacheLocation: BrowserCacheLocation.LocalStorage,
//    //   storeAuthStateInCookie: isIE, // set to true for IE 11
//    // },
//  });
//}

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
 * MSAL Angular will automatically retrieve tokens for resources
 * added to protectedResourceMap. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me/', [
    'user.read',
  ]);
  // protectedResourceMap.set('https://graph.microsoft.com/v1.0/users/'+currentUserEmail, ['user.read']);
  protectedResourceMap.set(
    protectedResources.ApiList.endpoint,
    protectedResources.ApiList.scopes
  );

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest,
  };
}
