<app-header></app-header>

<!-- For MSAL : This isIframe is to avoid reload during acquireTokenSilent() because of hidden iframe -->
<div class="appContainer container-fluid" [ngClass]="currentTheme === 'light' ? 'light' : 'dark'" id="appHeight" [ngStyle]="setHeight()">
  <router-outlet *ngIf="!isIframe"></router-outlet>
  <app-footer></app-footer>
</div>
<div class="chat-container" [ngClass]="currentTheme === 'light' ? 'light' : 'dark'"*ngIf="isDesktop === true">
  <!-- <div class="chaticon-opener" (click)="openChatBot()">
  </div> -->
  <img class="chatbot-opener" src="../assets/{{ currentTheme === 'light' ? 'light' : 'images' }}/bot-1.png" alt="Chatbot Opener" (click)="openChatBot()">
</div>

<app-inactivity-timer></app-inactivity-timer>
