import { ActionDescription, MeraActionDescription } from 'app/shared/enum';

export class bookingUpdateData {
  private saveBookingList: any[] = [];

  public checkMergeBooking(element, booking, diary) {
    const isMergeBookingExists = element.WeeklyBookings.filter(
      (currBook) =>
        currBook.ParentBookingID === booking.ActualBookingId &&
        currBook.ActionMode === 'D'
    );
    if (isMergeBookingExists && isMergeBookingExists.length > 0) {
      // Create delete requests for the merged cells
      isMergeBookingExists.forEach((eachBook) => {
        eachBook.id = eachBook.ActualBookingId;
        eachBook.beforeEditHours = eachBook.ActualBookingHours;
        eachBook.beforeEditPercentage =
          (Number(eachBook.ActualBookingHours) / diary) * 100;
        eachBook.isDelete = true;
        this.bookingsToSave(eachBook);
      });
    }
  }

  public checkSplitBooking(element, booking) {
    const isSplitBookingExists = element.WeeklyBookings.filter(
      (currBook) =>
        currBook.ActualBookingId === booking.ActualBookingId &&
        currBook.ActionMode === 'E'
    );
    // Find min and Max dates
    if (isSplitBookingExists && isSplitBookingExists.length > 0) {
      isSplitBookingExists.forEach((splitBook) => {
        if (splitBook.BookingId === 0) {
          if (
            splitBook.BookingHours === null ||
            splitBook.BookingHours === ''
          ) {
            //Delete scenario of Block Booking
            splitBook.isEdited = false;
            splitBook.isNewBooking = false;
            splitBook.isDelete = true;
            splitBook.MeraActionDescriptionId = 4;
          } else {
            //Edit scenario of Block Booking
            splitBook.isEdited = false;
            splitBook.isNewBooking = true;
            splitBook.MeraActionDescriptionId = 1;
          }
        } else if (splitBook.BookingId === booking.ActualBookingId) {
          if (
            splitBook.BookingHours === null ||
            splitBook.BookingHours === ''
          ) {
            splitBook.isDelete = true;
            splitBook.MeraActionDescriptionId = 4;
          } else {
            splitBook.isDelete = true;
            splitBook.isEdited = false;
            splitBook.MeraActionDescriptionId = 4;
            let newBooking = JSON.parse(JSON.stringify(splitBook));
            newBooking.id = newBooking + 'split';
            newBooking.BookingId = 0;
            newBooking.ActualBookingId = 0;
            newBooking.isNewBooking = true;
            newBooking.MeraActionDescriptionId = 1;
            this.bookingsToSave(newBooking);
          }
        }
        this.bookingsToSave(splitBook);
      });
      // const min = isSplitBookingExists
      //     .map((book) => book.WeekStartDate)
      //     .reduce((acc, date) => {
      //         return acc && new Date(acc) < new Date(date) ? acc : date;
      //     }, '');

      // const max = isSplitBookingExists
      //     .map((book) => book.WeekEndDate)
      //     .reduce((acc, date) => {
      //         return acc && new Date(acc) > new Date(date) ? acc : date;
      //     }, '');

      // let totalBookingHoursOfSplitBooking = 0;
      // isSplitBookingExists.forEach((bookingWeek) => {
      //     totalBookingHoursOfSplitBooking += bookingWeek.BookingHours;
      // });
      // let newStartDate = new Date(min);
      // newStartDate.setMinutes(
      //     newStartDate.getMinutes() - newStartDate.getTimezoneOffset()
      // );
      // let newEndDate = new Date(max);
      // newEndDate.setMinutes(
      //     newEndDate.getMinutes() - newEndDate.getTimezoneOffset()
      // );
      // booking.id = booking.ActualBookingId;
      // booking.BookingHours = totalBookingHoursOfSplitBooking;
      // booking.Loading = (totalBookingHoursOfSplitBooking / diary) * 100;
      // booking.StartDate = newStartDate.toISOString();
      // booking.EndDate = newEndDate.toISOString();
      // booking.isEdited = true;
      // this.bookingsToSave(booking);
    }
  }

  public bookingsToSave(booking) {
    // if(booking.isCanacelRequested === true){
    //     booking.isCancelRequested = false;
    // }
    let actionID;
    let meraActionID;
    if (
      booking.isNewBooking &&
      booking.Loading !== null &&
      booking.BookingHours !== null
    ) {
      actionID = ActionDescription.Create;
      meraActionID = MeraActionDescription.Create;
    } else if (booking.isEdited) {
      actionID = ActionDescription.Edit;
      meraActionID = MeraActionDescription.Edit;
    } else if (booking.isDelete) {
      actionID = ActionDescription.Delete;
      meraActionID = MeraActionDescription.Delete;
    } else if (booking.rowReplaced) {
      actionID = ActionDescription.Replace;
      meraActionID = MeraActionDescription.Replace;
    }
    const param = {
      id: booking.id,
      BookingId: booking.BookingId,
      ResourceId: booking.ResourceId,
      JobId: booking.JobId,
      BookingType: booking.BookingType,
      StartDate: booking.StartDate,
      EndDate: booking.WeekEndDate,
      Description: booking.Description,
      Hours:
        meraActionID === 4
          ? parseInt(booking.beforeEditHours)
          : booking.BookingHours,
      Loading:
        meraActionID === 4
          ? parseInt(booking.beforeEditPercentage)
          : booking.Loading,
      Unconfirmed: booking.Unconfirmed,
      Ghost: booking.Ghost,
      IsOverallocation: booking.IsOverallocation,
      ActionDescriptionId: actionID,
      InstanceId: booking.InstanceId,
      MeraActionDescriptionId: meraActionID,
      ActualBookingId: booking.ActualBookingId,
      ParentBookingId: booking.ParentBookingID,
    };
    let indexToReplace = null;
    if (this.saveBookingList.length > 0) {
      this.saveBookingList.map((editedBookings, index) => {
        if (editedBookings.id === param.id) {
          indexToReplace = index;
        }
      });
      if (indexToReplace !== null) {
        if (
          booking.isNewBooking &&
          booking.Loading === null &&
          booking.BookingHours === null
        ) {
          this.saveBookingList.splice(indexToReplace, 1);
        } else if (booking.revertAction === true) {
          this.saveBookingList.splice(indexToReplace, 1);
          booking.revertAction = false;
        } else {
          this.saveBookingList[indexToReplace] = param;
        }
      } else {
        if (
          booking.isNewBooking &&
          booking.Loading !== null &&
          booking.BookingHours !== null
        ) {
          this.saveBookingList.push(param);
        } else if (
          booking.revertAction === false ||
          booking.revertAction === undefined
        ) {
          this.saveBookingList.push(param);
        } else if (booking.revertAction === true) {
          booking.revertAction = false;
        }
      }
    } else {
      if (
        booking.revertAction === false ||
        booking.revertAction === undefined
      ) {
        this.saveBookingList.push(param);
      } else {
        booking.revertAction = false;
      }
    }
    console.log(this.saveBookingList);
  }

  public removeEngagement(element) {
    this.saveBookingList = this.saveBookingList.filter((item) => {
      return (
        item.JobId + item.InstanceId !== element.JobId + element.InstanceID
      );
    });
  }
  public removeBooking(booking) {
    this.saveBookingList = this.saveBookingList.filter((item) => {
      item.id !== booking.id;
    });
  }
  public fetchSaveDataList = () => {
    return this.saveBookingList;
  };

  public clearSaveDataList = () => {
    this.saveBookingList = [];
  };
}
