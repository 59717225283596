import { Component, OnInit,  Input, Renderer2 } from '@angular/core';
import { EngagementBookingVM } from "../../../Models/EngagementBookingVM";
import { BookingGridActionService } from '../../../Models/BookingGridActionService';
import { EngagementService } from '../engagement.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { OrderPipe } from 'ngx-order-pipe';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { JobVM } from '../../../Models/JobVM';
import { Response } from '../../../Models/Response';
import * as moment from 'moment'; 
import { favEngImageSrc, UiViewSourceEnum } from 'app/shared/enum';
import { CurrentWeekActionDTO } from '../../../Models/CurrentWeekActionDTO';
import { FavouriteJobsAddActionDto, FavouriteJobsDto, FavouriteJobsTableTypeDto } from 'app/Models/FavouriteJobsDto';
import { ScheduledViewService } from 'app/Views/scheduleView/scheduledView.service';
import { DARK_PATH, LIGHT_PATH } from 'app/app-constants';
import { ThemeService } from 'app/shared/theme/theme.service';

@Component({
  selector: 'app-replaceall-engagement',
  templateUrl: './replaceall-engagement.component.html',
  styleUrls: ['./replaceall-engagement.component.scss']
})
export class ReplaceallEngagementComponent implements OnInit {

  @Input() currentWeekActionDTO: CurrentWeekActionDTO;
  @Input() ParentselectedInstance: any;
  @Input() UiViewSource: string;
  currentUserName: string;
  currentUserEmail: string;
  currentUserId: any;
  booking: EngagementBookingVM;
  response: Response;
  eventData: BookingGridActionService;
  childRows: any;
  //formGroup: any;
  bookingId: any;
  resourceId: any;
  jobId: any;
  Engagement: any;
  engagementCode: any;
  public view: Observable<any>;
  ResourceName: any;
  ReplaceResourceName: any;
  resourceNameZZ: boolean;
  RankDescription: any;
  autocompleteResetvalue = '';
  SLDescription: any;
  LocationDescription: any;
  description: string;
  bookingType: any;
  engagementStatus: any;
  engagementServiceLine: any;
  results: any[];
  ReplaceEngagementName: any;
  engagement: JobVM;
  jobCategory = '';
  engagementList: JobVM[];
  sortedEngagementList: any[];
  engagementListoptions: any[];
  selectedItem: any = '';
  placeHolderText:any = '';
  //EngagementName: FormControl = new FormControl(); 
  OldResourceName : string;
  searchBookingSubscription: Subscription;
  jobcode: string;
  engagementAllocation: false;
  bookingListSubscription: Subscription;
  bookingList: EngagementBookingVM[];
  oldjobid: string;
  isPercentageSelected: any;
  loggedinUser: any;
  buttonText = "Submit";
  showSearchList : boolean = false;
  showSearchLoader : boolean = false;
  selectedjobId: any = '';
  formGroup = this.formBuilder.group({
    EngagementName: ['',Validators.required]
  })
  startWith = new Date();
  thisDay = new Date();
  todaysDate = new Date(this.thisDay.getFullYear(), this.thisDay.getMonth(), this.thisDay.getDate(), 0, 0, 0);

  showFavouriteEngList: boolean = false;
  addFavouriteEngResults:any[];
  isClickonFavEngStart:boolean = false;
  outClickCount:number=0;
  compareFavouriteEngResults:any[]= [];
  isVerifyOnEngClick:boolean=false;
  lbl:string;
  isFavEngONClick:boolean=true;
  IsDataNotFound:boolean = false;
  favEngSrc:string;
  FavoriteImageClick:boolean= false;
  IsFavEngInserted:boolean=false;
  showSearchLoaderfav:boolean=false;
  imagePath: string = '';

  constructor(private engagementServices: EngagementService, 
    private scheduledService: ScheduledViewService,
    public restService: EngagementService,    
    private spinner: NgxSpinnerService,
    private orderPipe: OrderPipe,
    private formBuilder: FormBuilder,
    private themeService: ThemeService,
    )
     { 
          
      this.view = this.restService;

      // this.formGroup = new FormGroup({

      //   EngagementName: new FormControl('', [
      //   ]),

      //   Notes: new FormControl('', [
      //   ]),

      // });
      document.addEventListener('click', this.favoriteimgClickHandler.bind(this)); // bind on document

     }

  ngOnInit() 
  {
   
    const activeTheme = this.themeService.getActiveTheme();
    this.imagePath = activeTheme.name === 'light' ? LIGHT_PATH : DARK_PATH;
   
    this.loggedinUser = JSON.parse(sessionStorage.getItem('resource'));
    this.currentUserName = this.loggedinUser.ResourceName;
    this.currentUserEmail = this.loggedinUser.Email;
    this.currentUserId = sessionStorage.getItem("resourceId");
    const currentDate = new Date();
    // this.bsConfig = Object.assign({}, {
    //   containerClass: this.colorTheme,
    //   showWeekNumbers: false, minDate: currentDate, rangeInputFormat: 'DD-MM-YYYY'
    // });
    this.results = [];
    this.booking = new EngagementBookingVM();
    this.response = new Response();
    this.engagement = new JobVM();
    this.SetData();
    this.engagementServices.currentMessageEngagementAllocation.subscribe(isPercentageSelected => {
      this.setEngagementAllocation(isPercentageSelected);
    });

  }
  isValidInput(fieldName): boolean {
    return this.formGroup.controls[fieldName].invalid &&
      (this.formGroup.controls[fieldName].dirty || this.formGroup.controls[fieldName].touched);
  }
  SetData() {

    // todo: handle reset
    //this.formGroup.reset();
   //
   this.autocompleteResetvalue = null;
    this.jobId = sessionStorage.getItem('JobId');
    this.oldjobid = sessionStorage.getItem('JobId');
   // this.startDate = rowdata.dataItem.StartDate;
    //this.endDate = rowdata.dataItem.EndDate;
    this.Engagement = sessionStorage.getItem('JobDescription');
    this.ReplaceEngagementName = sessionStorage.getItem('JobDescription');
    this.engagementCode = sessionStorage.getItem('engagementCode');
    this.bookingType = sessionStorage.getItem('engagementType');
   // this.description = sessionStorage.getItem('JobId');
    //this.jobcode = sessionStorage.getItem('engagementStatus');
   
    this.engagementStatus = sessionStorage.getItem('engagementStatus');
    this.engagementServiceLine = sessionStorage.getItem('engagementServiceLine');
    this.isPercentageSelected = sessionStorage.getItem('isPercentageSelected');
  }

  setEngagementAllocation(isPercentageSelected) {
  //   if (!isPercentageSelected) {
  //     this.engagementAllocationText = 'Hours';
  //     this.engagementAllocationTypeValue = this.hours;
  //     this.isPercentageSelected = false;

  //   } else {
  //     this.engagementAllocationText = 'Loading %';
  //     this.isPercentageSelected = true;
  //     if (this.loading !== '' && this.loading != null) {
  //       this.engagementAllocationTypeValue = this.loading;
  //     }
  //   }
  //   this.engagementAllocationPercentSelected = isPercentageSelected;

  }

  searchValue = '';
  search()
   {
    //debugger;
    this.sortedEngagementList = [];
    this.isFavEngONClick = false;
    this.isVerifyOnEngClick = false;

    this.autocompleteResetvalue = this.formGroup.get('EngagementName').value;
    if (this.autocompleteResetvalue != null) {
    if (this.autocompleteResetvalue.length >= 2) {
      this.showSearchList = true;
        this.showSearchLoader = true;
      this.searchValue = this.autocompleteResetvalue;
     // this.getEngagementList(this.autocompleteResetvalue);
      this.engagement.JobCategory = '-1' ;   //input;
    this.engagement.JobDescription = this.autocompleteResetvalue;
    this.engagement.ClientDescription = this.autocompleteResetvalue;
    this.engagement.LoggedInResourceId =  + sessionStorage.getItem('loggedin_resourceid');
   // this.engagement.InstanceId= +sessionStorage.getItem('instanceId');
   this.engagement.InstanceId = this.ParentselectedInstance;

    if(this.UiViewSource === UiViewSourceEnum.EngagementScheduleView)
            {
              this.searchBookingSubscription = this.scheduledService.getEngagementSearchResult(this.engagement).subscribe((data) => {
                this.engagementList = data.body;
                
                this.sortedEngagementList = this.orderPipe.transform(this.engagementList, 'EngagementFullName');
                if(this.sortedEngagementList.length>0)
                      {
                      this.results = this.sortedEngagementList;
                      this.showSearchLoader = false;
            
                      this.setAllFavEngagement();
                      this.results = this.sortedEngagementList;
                      this.showSearchLoader = false;
                      this.showFavouriteEngList = false;
            
                      }
                      else
                      {
                        this.results = [];
                        this.showSearchList = false; this.showSearchLoader = false;
                        this.results = [];
                        this.IsDataNotFound = true;
                        this.showSearchList = true;
                        this.showFavouriteEngList = false;
                      }
              });
            }
            else
            {
              this.searchBookingSubscription = this.engagementServices.getEngagementSearchResult(this.engagement).subscribe((data) => {
                this.engagementList = data.body;
                
                this.sortedEngagementList = this.orderPipe.transform(this.engagementList, 'EngagementFullName');
                if(this.sortedEngagementList.length>0)
                      {
                      this.results = this.sortedEngagementList;
                      this.showSearchLoader = false;
            
                      this.setAllFavEngagement();
                      this.results = this.sortedEngagementList;
                      this.showSearchLoader = false;
                      this.showFavouriteEngList = false;
            
                      }
                      else
                      {
                        this.results = [];
                        this.showSearchList = false; this.showSearchLoader = false;
                        this.results = [];
                        this.IsDataNotFound = true;
                        this.showSearchList = true;
                        this.showFavouriteEngList = false;
                      }
                  });
            }
        }
        
        
        else
        {
          this.results = [];
          this.showSearchList = false;
        }
      
      }
      else
      {
        this.results = [];
        this.showSearchList = false;
      }
}


  getEngagementList(query) {
    // let input = this.jobCategory;
    // if (input == null || input === '') {
    //   input = 'Chargeable';
    // }
  
    this.engagement.JobCategory = '-1' ;   //input;
    this.engagement.JobDescription = query;
    this.engagement.ClientDescription = query;
    this.engagement.LoggedInResourceId =  + sessionStorage.getItem('loggedin_resourceid');

    if(this.UiViewSource === UiViewSourceEnum.EngagementScheduleView)
    {
      this.searchBookingSubscription = this.scheduledService.getEngagementSearchResult(this.engagement).subscribe((data) => 
      {
      this.engagementList = data.body;
      this.sortedEngagementList = this.orderPipe.transform(this.engagementList, 'EngagementFullName');
      this.setEngagementListData();
      this.spinner.hide();
      });
    }
    else
    {
      this.searchBookingSubscription = this.engagementServices.getEngagementSearchResult(this.engagement).subscribe((data) => 
      {
      this.engagementList = data.body;
      this.sortedEngagementList = this.orderPipe.transform(this.engagementList, 'EngagementFullName');
      this.setEngagementListData();
      this.spinner.hide();
      });
    }
   
  }

  setEngagementListData() {
    this.engagementListoptions = [];
    if(this.autocompleteResetvalue == null || this.autocompleteResetvalue == '')
    {
      this.results = [];
    }
    else
    {
    this.results = this.sortedEngagementList;
    }
  }

  OnItemSelect(event) 
  {
    if(this.FavoriteImageClick===true)
      {
        this.FavoriteImageClick = false;
        (event as Event).stopPropagation();
      
      }
    this.showFavouriteEngList = false;
    this.showSearchList = false;
    this.selectedItem = event;
    this.engagementCode = event.JobCode;
    this.engagementStatus = event.JobStatus;
    this.engagementServiceLine = event.ServiceLineDescription;
    this.formGroup.get('EngagementName').setValue(event.EngagementFullName);
    this.jobId = event.JobId;
    this.selectedjobId = event.JobId;
    if (this.engagementCode === '' || this.engagementCode === null) {
      this.bookingType = event.JobType + '-' + 'Awaiting Code';
      this.engagementCode = 'Dummy';
    } else {
      this.bookingType = event.JobType;
    }
      this.dirtyFlag = true;
      
  }

  onEngagementClear()
  {
    this.IsDataNotFound = false;
    this.searchValue = '';
    this.placeHolderText = 'Search engagement ....';
    this.autocompleteResetvalue = null;
    // todo: handle reset
    this.formGroup.get('EngagementName').reset();
    this.results = [];
    this.addFavouriteEngResults = [];
    this.isClickonFavEngStart = false;
    this.showSearchList = false;
    this.isFavEngONClick = true;
  }

  onEngagementCategoryChange(event) {
    this.onEngagementClear();
    const newVal = event.target.value;
    this.jobCategory = newVal;
    //this.makeDirty();
  }


    public dirtyFlag = false;

    public makeDirty(controlName: string) {
      
      // if (!this.dirtyFlag) {
      //   this.dirtyFlag = true;
      //   this.isFormDirtyFlag.emit(this.dirtyFlag);
      // }
      // if (controlName == 'Period' || controlName == 'Hours' || controlName == 'Unconfirmed') {
      //   this.isValidationRequired = true;
      // }
    }

    onReplaceAllSubmit() {
      if(this.selectedjobId != null && this.selectedjobId != undefined && this.selectedjobId != '')
      {
        if(this.selectedjobId != this.oldjobid)
        {
      this.buttonText="Submitting...";
      this.booking.JobId = this.jobId;      
      this.booking.ResourceId = +sessionStorage.getItem('resourceId');//1404098;;
      this.booking.Description = this.description == undefined ? '' : this.description;
      this.booking.CurrentUserEmail = this.currentUserEmail;
      this.booking.CurrentUserId =  this.currentUserId;
      this.booking.CurrentUserName = this.currentUserName;
      this.booking.oldjobid = this.oldjobid;
      this.booking.IsPercentSelected = JSON.parse(this.isPercentageSelected);
      this.booking.LoggedInResourceID = +sessionStorage.getItem('loggedin_resourceid'); 
      this.booking.RequestMode = JSON.parse(sessionStorage.getItem('requestMode'));
      //this.booking.InstanceId = JSON.parse(sessionStorage.getItem('instanceId'));
      this.booking.InstanceId = this.ParentselectedInstance;
      this.booking.UiViewSource = this.UiViewSource;
      if (this.UiViewSource == UiViewSourceEnum.EngamentView)
      {
        this.bookingListSubscription = this.restService.replaceAllEngagement(this.booking).subscribe(
          data => {
            
            if (data.status = '200') {
              this.response = data.body;
              if (this.response.customResultId > 0) { 
                this.engagementServices.setRquestNumber(this.response.customResultId); 
                //alert('Successfully saved the changes');
                                      
              }
              
                this.engagementServices.closeModal(true);
                this.buttonText = "Submit";
            }
          }
        );

      }
      else if (this.UiViewSource == UiViewSourceEnum.EngagementScheduleView)
      {
        this.bookingListSubscription = this.scheduledService.replaceAllEngagement(this.booking).subscribe(
          data => {
            
            if (data.status = '200') {
              this.response = data.body;
              if (this.response.customResultId > 0) { 
                this.engagementServices.setRquestNumber(this.response.customResultId); 
                //alert('Successfully saved the changes');
                                      
              }
              
                this.engagementServices.closeModal(true);
                this.buttonText = "Submit";
            }
          }
        );
      }
    

      }    
      else{
        this.buttonText ="Please select a different engagement name";
          setTimeout(()=>{this.buttonText="Submit"},3000);
      } 
    }
    

    }

    OnChange(event)
    {
      this.selectedItem = event;

      if(event != null){
        this.engagementCode = event.JobCode;
        this.engagementStatus = event.JobStatus;
        this.engagementServiceLine = event.ServiceLineDescription;
        this.jobId = event.JobId;
        this.selectedjobId = event.JobId;
        if (this.engagementCode === '' || this.engagementCode === null) {
          this.bookingType = event.JobType + '-' + 'Awaiting Code';
          this.engagementCode = 'Dummy';
        } else {
          this.bookingType = event.JobType;
        }
      }

      this.dirtyFlag = true;
    }
    
    enabledWeek(isCurrentWeekEditable) {

      this.startWith = this.getFirstDateOfWeek(this.todaysDate);
      if(isCurrentWeekEditable){
        this.startWith.setMinutes(this.startWith.getMinutes() - this.startWith.getTimezoneOffset());
      }
      else{
        this.startWith.setDate(this.startWith.getDate() + 7);
        this.startWith.setMinutes(this.startWith.getMinutes() - this.startWith.getTimezoneOffset());
      }
      this.spinner.hide();

      // const dayNeeded = 7 //For Saturday
      // const currentDay = moment().isoWeekday();
      // if(isCurrentWeekEditable){
      //   if (currentDay === dayNeeded) { 
      //     let day = moment().day(dayNeeded);
      //     this.startWith = new Date(day.year(),day.month(),day.date())
      //   }else{
      //     let day = moment().subtract(1, 'weeks').isoWeekday(dayNeeded);
      //     this.startWith = new Date(day.year(),day.month(),day.date());  
      //   }
      // }else{
      //   if (currentDay === dayNeeded) { 
      //     let day = moment().add(1, 'weeks').isoWeekday(dayNeeded);
      //     this.startWith = new Date(day.year(),day.month(),day.date());
      //   }else{
      //     let day = moment().day(dayNeeded);
      //     this.startWith = new Date(day.year(),day.month(),day.date());
           
      //   }
      // }

    }

    getFirstDateOfWeek(anyDateOfWeek: Date): Date {
      // date.getDay() RETURNS Week Day Index: SUN 0, MON 1, TUE 2, WED 3, THU 4, FRI 5, SAT 6.
      let date = new Date(anyDateOfWeek);
      let firstDayOfWeekIndex:number = 6; //Week Day Index: SUN 0, MON 1, TUE 2, WED 3, THU 4, FRI 5, SAT 6.
      let daysToShiftBack = (7 + (date.getDay() - firstDayOfWeekIndex)) % 7;
      let firstDateOfWeek = new Date(date.setDate(date.getDate() - daysToShiftBack));
      return firstDateOfWeek;
    }
    
// Replace Functionality   -------------------- start 


private setAllFavEngagement():void
  {
      this.sortedEngagementList.forEach(a=>
      {
        if(a.IsFavourite===true)
        {
          a.IsFavEngInserted = true;
          a.favEngSrc = favEngImageSrc.favEngImgStarfilled;
        }
        else
        {
          a.IsFavEngInserted = false;
          a.favEngSrc = favEngImageSrc.favEngImgStarOutline;
        }
      });
  }


    onEngagementClick()
    {
      this.showSearchList = false;
      this.isVerifyOnEngClick = true;
      this.showFavouriteEngList = true;
      
      let val = this.formGroup.get('EngagementName').value;
      if (val != null) 
        {
              if (val.trim().length >= 2) 
              {
                this.showFavouriteEngList = false;
                this.isFavEngONClick = false;
              }
              else
              {
                this.showFavouriteEngList = true;
                this.isFavEngONClick = true;
              }
        }
      
        if (this.isFavEngONClick) 
        {
          this.getFavouriteEngagement();
        }
    }

    onFavoriteImageClick(item)
    {
          
      this.FavoriteImageClick = true;
      item.IsOldFavEngInserted = item.IsFavEngInserted;
      item.IsFavEngInserted = !item.IsFavEngInserted;
  
      if(item.IsFavEngInserted)
      {
        item.favEngSrc = favEngImageSrc.favEngImgStarfilled;
      }else
      {
        item.favEngSrc = favEngImageSrc.favEngImgStarOutline;
      }
  
    }

    private getFavouriteEngagement():void
  {
       let favouriteJobs = new FavouriteJobsDto();
       this.showSearchLoaderfav = true;
       favouriteJobs.InstanceId = this.ParentselectedInstance;
       favouriteJobs.IsSearchfromIndivBooking = true;
       favouriteJobs.JobCategory = this.jobCategory;
       favouriteJobs.ResourceID = !JSON.parse(sessionStorage.getItem('resourceId')) ? 0:JSON.parse(sessionStorage.getItem('resourceId')) ;
          

       //this.individualServices.getFavouriteJobs(favouriteJobs).subscribe((data) => 

       if(this.UiViewSource === UiViewSourceEnum.EngagementScheduleView)
       {
          this.scheduledService.GetFavouriteJobsEngagementView(favouriteJobs).subscribe((data) =>
          {
            this.showSearchLoaderfav = false;
              const key = 'JobId';

              const resultEng = [...new Map(data.map(item =>
                [item[key], item])).values()];
                this.addFavouriteEngResults = resultEng;
                this.isFavEngONClick = false;
                if(this.addFavouriteEngResults.length > 0)
                {
                  this.IsDataNotFound = false;
                  this.addFavouriteEngResults.forEach(i=> 
                  {
                    i.IsFavEngInserted = true;
                    i.favEngSrc = favEngImageSrc.favEngImgStarfilled;
                  });
                }
                else
                {
                    this.IsDataNotFound = true;
                    this.lbl = "Favourite Engagements not added."
                }
              
                //console.log(this.addFavouriteEngResults);
              
          });

       }
       else
       {
            this.engagementServices.GetFavouriteJobsEngagementView(favouriteJobs).subscribe((data) =>
            {
              this.showSearchLoaderfav = false;
                const key = 'JobId';

                const resultEng = [...new Map(data.map(item =>
                  [item[key], item])).values()];
                  this.addFavouriteEngResults = resultEng;
                  this.isFavEngONClick = false;
                  if(this.addFavouriteEngResults.length > 0)
                  {
                    this.IsDataNotFound = false;
                    this.addFavouriteEngResults.forEach(i=> 
                    {
                      i.IsFavEngInserted = true;
                      i.favEngSrc = favEngImageSrc.favEngImgStarfilled;
                    });
                  }
                  else
                  {
                      this.IsDataNotFound = true;
                      this.lbl = "Favourite Engagements not added."
                  }
                
                  //console.log(this.addFavouriteEngResults);
                
            });

      }
     
  }

  private AddFavouriteEngagement(arr):void
  {
     if(this.UiViewSource === UiViewSourceEnum.EngagementScheduleView)
       {
          this.scheduledService.addFavouriteJobs(arr).subscribe((data) => 
          {
              this.showSearchList = false;
              this.showFavouriteEngList = false;
              this.isFavEngONClick = true;
          });
       }
       else
       {
          this.engagementServices.addFavouriteJobs(arr).subscribe((data) => 
          {
              this.showSearchList = false;
              this.showFavouriteEngList = false;
              this.isFavEngONClick = true;
          });
       }
      
  }
  private favoriteimgClickHandler(event:any) 
  {

     if(!!event.target.src)
     {
        var parts = event.target.src.split('/');
        var img = parts[parts.length - 1];

        var a = favEngImageSrc.favEngImgStarOutline.split('/');
        var ImgStarOutline = a[a.length - 1];

        var b = favEngImageSrc.favEngImgStarfilled.split('/');
        var ImgStarfilled = b[b.length - 1];

       // if((event.target.src === favEngImageSrc.favEngImgStarOutline) || (event.target.src === favEngImageSrc.favEngImgStarfilled))
       if((img === ImgStarOutline) || (img === ImgStarfilled))
          {
            this.isClickonFavEngStart = true;
            this.outClickCount = 0;
            //console.log("in side click");
          }
          else
          {
            this.isClickonFavEngStart = false;
            if(this.outClickCount == 0)
            {
              this.outClickCount++;
             
            }
          }
      }
        else
        {
          if(this.isClickonFavEngStart)
          {
            if(this.outClickCount == 0)
              {
                this.outClickCount++;
                //console.log("out click after click on start");
                this.getAllSelectedfavEngagement();
              }
          }
          // console.log("out click ");
          
        }
  }

  
  private getAllSelectedfavEngagement()
  {
    //debugger;
    let typeArr:Array<FavouriteJobsTableTypeDto> ;
    typeArr = [];
    let dataSE;
    if(this.outClickCount > 0)
    {
        this.outClickCount = 0;
        this.isClickonFavEngStart = false;
        this.compareFavouriteEngResults = [];
        if(!this.isVerifyOnEngClick)
        {
           dataSE = this.sortedEngagementList.filter(ap => ap.IsOldFavEngInserted === true 
            || ap.IsOldFavEngInserted === false);
        }
        else
        {
          dataSE = this.addFavouriteEngResults.filter(ap => ap.IsOldFavEngInserted === true 
            || ap.IsOldFavEngInserted === false);
        }

        if(dataSE.length > 0)
        {
          dataSE.forEach(data=>
              {
            
                if(data.IsOldFavEngInserted === true || data.IsOldFavEngInserted === false)
                {

                  this.compareFavouriteEngResults.push(data);
                  //console.log(this.compareFavouriteEngResults);
                }
                
              });
        }

        if(this.addFavouriteEngResults.length > 0 || !!this.addFavouriteEngResults)
        {
          let arr =[];
          this.compareFavouriteEngResults.forEach(op=> {
              if(!op.IsFavEngInserted)
              {
                  if(this.addFavouriteEngResults.some(g=> g.JobId === op.JobId ))
                  {
                   
                      op.InstanceId = this.ParentselectedInstance;
                      op.JobCategory = this.jobCategory;
                      arr.push(op);
                  }
               }
               else
               {
                      op.InstanceId = this.ParentselectedInstance;
                      op.JobCategory = this.jobCategory;
                      arr.push(op);
               }
          });
          if(arr.length > 0)
            {
                arr.forEach(tt=> {
                  let obj = new FavouriteJobsTableTypeDto();
                  obj.LoggedInUserId = this.loggedinUser.UserId;
                  obj.JobID = tt.JobId;
                  obj.Instance_Id = tt.InstanceId;
                  obj.IsFavEngInserted = tt.IsFavEngInserted;
                  typeArr.push(obj);
              });
              let k = new FavouriteJobsAddActionDto();
              k.FavouriteJobs =typeArr;
            
              this.AddFavouriteEngagement(k);
            }
        }
    }
  }
}
