<div class="popupLayout">
  <div class="row">
    <div class="fullWidth">
      <div class="confirmationText">
        <!-- <span *ngIf="isHistoryBlockBooking && !isNewBookingChildRowsExist && !isNewBooking"> -->
        <span *ngIf="isHistoryBlockBooking">
          This is a block booking from {{ this.datepipe.transform(booking.StartDate, this.defaultDateFormat) }} to {{ this.datepipe.transform(booking.EndDate, this.defaultDateFormat) }}
          <br/><br/>
          You cannot delete the booking from {{ this.datepipe.transform(booking.StartDate, this.defaultDateFormat) }} to {{ this.datepipe.transform(endOfHistory, this.defaultDateFormat) }}
          <br/><br/>
          Are you sure you want to delete <b>{{booking.Engagement}}</b> from {{ this.datepipe.transform(startOfWeek, this.defaultDateFormat) }} to {{ this.datepipe.transform(booking.EndDate, this.defaultDateFormat) }}?
        </span>
        <span *ngIf="!isHistoryBlockBooking">
          Are you sure you want to delete <b>{{booking.Engagement}}</b>
          from {{this.datepipe.transform(booking.StartDate, this.defaultDateFormat) }} to {{ this.datepipe.transform(booking.EndDate, this.defaultDateFormat) }}?
          <br/><br/>
        </span>        
        <!-- <span *ngIf="isNewBookingChildRowsExist">
          This will also cancel the New Booking requests (for the mentioned period) raised while any Edit/ Replace actions on Block Bookings. It will not cancel the related Edit/ Replace actions.
        </span> -->
      </div>
    </div>
    <div class="confirmationButtons">
      <div class="halfWidthItem">
        <button class="cancelBtn" (click)="cancelDelete(false)">
          <span class="glyphicon glyphicon-remove-sign pr-5"></span>
          Cancel
        </button>
      </div>
      <div class="halfWidthItem">
        <button class="deleteBtn" (click)="deleteBooking()"  [disabled] = "(!(buttonText == 'Delete'))||!dirtyFlag">
          <span class="glyphicon glyphicon-trash pr-5"></span>
          {{buttonText}}
        </button>
      </div>
    </div>
  </div>
</div>
<!-- booking.StartDate | date: 'dd-MM-yyyy' -->
<!-- booking.EndDate | date: 'dd-MM-yyyy' -->
<!-- endOfHistory | date: 'dd-MM-yyyy' -->
<!-- startOfWeek -->