<section
  class="notesSection"
  [ngStyle]="getNotesPosition()"
  id="notesWrapper"
  *ngIf="isNotes"
  #notesSection
>
  <header class="notesHeader">
    <mat-icon
      aria-hidden="false"
      aria-label="Close Notes"
      (click)="notesCancel()"
      >close</mat-icon
    >
  </header>
  <div class="notesBody">
    <mat-radio-group
      aria-label="Select an option"
      *ngIf="noteAvailableFor.cellData.IsEdit"
      [(ngModel)]="extendData"
      (ngModelChange)="updateRadioSelection($event)"
    >
      <mat-radio-button value="extend_upto" class="checkItemWrapper extendWrapper">
        <span>Extend booking until</span>
        <mat-form-field
          class="date-wrapper"
          appearance="fill"
          class="extend_dp"
        >
          <input
            matInput
            autocomplete="off"
            [min]="minDate"
            [matDatepicker]="picker"
            [value]="date && date.value"
            (dateInput)="updateDate('input', $event)"
            (dateChange)="updateDate('change', $event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-icon
            class="date-clear"
            aria-hidden="false"
            aria-label="Clear date"
            (click)="clearDate()"
            >close</mat-icon
          >
        </mat-form-field>
      </mat-radio-button>
      <mat-radio-button
        *ngIf="checkIfNewBooking()"
        value="update_all"
        class="checkItemWrapper updateAllChekbox"
        >Update the existing forward bookings with the revised value</mat-radio-button
      >
    </mat-radio-group>
    <div class="notesTextWrapper">
      <span>Notes</span>
      <textarea
        cols="30"
        rows="10"
        [disabled]="!noteAvailableFor.cellData.IsEdit"
        [(ngModel)]="noteAvailableFor.cellData.Description"
      ></textarea>
    </div>
  </div>
  <div class="btnContainer" *ngIf="noteAvailableFor.cellData.IsEdit">
    <button
      mat-raised-button
      color="primary"
      class="submitButton"
      (click)="notesSubmit()"
      type="submit"
    >
      Apply
    </button>
  </div>
</section>
