<form (ngSubmit)="onEditSubmit()" [formGroup]="formGroup">
  <div class="popupLayout">
    <div class="customRow">
        <div class="halfWidthItem">
          <label class="formLabel">Booking Type</label>
          <input type="text" class="form-control bg-grey"
            formControlName='bookingType' [readonly]="true">
        </div>
        <div class="halfWidthItem">
          <label class="formLabel">Engagement Code</label>
          <input type="text" class="form-control bg-grey"
            formControlName='engagementCode' [readonly]="true">
        </div>
    </div>
    <div class="fullWidth">
      <div class="form-group mb-10">
        <label class="formLabel">Engagement</label>
        <input type="text" class="form-control bg-grey" formControlName='engagement'
          [readonly]="true">
      </div>
    </div>
    <div class="customRow">
      <div class="halfWidthItem">
        <div class="form-group mb-10">
          <label class="formLabel">Engagement Service Line</label>
          <input type="text" class="form-control bg-grey"
            formControlName='engagementServiceLine' [readonly]="true">
        </div>
      </div>
      <div class="halfWidthItem">
        <div class="form-group mb-10">
          <label class="formLabel">Engagement Status</label>
          <input type="text" class="form-control bg-grey"
            formControlName='engagementStatus' [readonly]="true">
        </div>
    </div>
  </div>
    <div class="periodContainer">
      <div class="estimationContainer">
        
        <div class="dateSearch">
          <label>Period <span class="mandtoryIcn"> *</span></label>
          <mat-form-field appearance="outline">
  
            <mat-date-range-input formGroupName="period1" [rangePicker]="picker1" [min]="startOfWeek">
              <input matStartDate  formControlName="start1" placeholder="Start Date" (focus)="picker1.open()"
                (click)="picker1.open()" readonly>
              <input matEndDate formControlName="end1" placeholder="End Date" (focus)="picker1.open()"
                (click)="picker1.open()" readonly (dateChange)="makeDirty('Period')">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-date-range-picker #picker1></mat-date-range-picker>
            
          </mat-form-field>
          <div *ngIf="formGroup.get('period1').get('start1').value === null && (formGroup.get('period1').get('start1').dirty || formGroup.get('period1').get('start1').touched)" class="alert alert-danger">
            Start Date is required
          </div>
          <div *ngIf="formGroup.get('period1').get('end1').value === null && (formGroup.get('period1').get('end1').dirty || formGroup.get('period1').get('end1').touched)" class="alert alert-danger">
            End Date is required
          </div>
        </div>
     
      <div class="estimation">
          <label class="formLabel">{{engagementAllocationText}} <span class="mandtoryIcn"> *</span></label>
          <mat-form-field appearance="outline" [ngClass]="{ 'is-invalid': isValidInput('editLoading') }">
            <input type="text" matInput (input)="makeDirty('Hours')"  autocomplete="off" #tooltip="matTooltip"
            [matTooltip]="engagementAllocationPercentSelected ? '' : 'Note when adding time in hours this will be input for the full-time period of the booking, not per week. For long term bookings Loading% may be the preferred way to input time allocation to work (please see “preference” button in top right hand corner to switch between the time allocation input methods)'"
              triggers="hover click focus" placement="bottom" 
              formControlName="editLoading" appHoursLoading [isPercentageSelected]="isPercentageSelected">
          </mat-form-field>
          <div *ngIf="isValidInput('editLoading')">
            <div *ngIf="formGroup.controls['editLoading'].errors.required" class="alert alert-danger">
              {{engagementAllocationText}} is required.
            </div>
            <div *ngIf="formGroup.controls['editLoading'].errors.pattern" class="alert alert-danger">
              {{engagementAllocationText}} is invalid.
            </div>
          </div>
      </div>
      <div class="confirmContainer" *ngIf="!resource?.Unconfirmed">
          <label class="formLabel">Unconfirmed</label>
          <mat-checkbox formControlName="unconfirmed" [disableRipple]='true' (change)="makeDirty('Unconfirmed')"
            [ngModelOptions]="{standalone:true}"></mat-checkbox>

      </div>
      <div class="confirmContainer" *ngIf="resource?.Ghost">
          <label class="formLabel">Ghost</label>
          <mat-checkbox formControlName="ghost" [disableRipple]='true' (change)="makeDirty('')"
            [ngModelOptions]="{standalone:true}"></mat-checkbox>
          
      </div>
    </div>
      <div class="overallocationWarning">
        <div class="allocationText" [class.disabled]="overallocationerror">
          <label *ngIf="!isHoursValid">This Booking request will cause an overallocation. If you
            wish to proceed, please select the check box and click on Submit.</label>
        </div>
        <div class="confirmAllocation" [class.disabled]="overallocationerror">
          <label *ngIf="!isHoursValid">Confirm</label>
          <mat-checkbox  [disableRipple]='true' (change)="confirmHoursChange('editLoading')" *ngIf="!isHoursValid"
            [(ngModel)]="confirmHours" [ngModelOptions]="{standalone:true}"></mat-checkbox>
        </div>
        
      </div>
     
 
    </div>

    <div class="fullWidth">
      <div class="form-group mb-10">
        <label class="formLabel">Notes Description</label>
        <input
          tooltip="Please do not enter any unnecessary personal information or any sensitive personal data (including tax file numbers or other government identifiers), client confidential information, audit secrets, state secrets, commercial secrets, or anything that would violate professional secrecy or confidentiality rules or that would be considered abusive/irrelevant."
          triggers="hover click focus" maxlength="200" placement="bottom" type="text" class="form-control bg-grey" formControlName='description' autocomplete="off"> 
      </div>
    </div>
    <br />
    <div class="SubmitContainer">

      <button class="submitButton" type="submit" [disabled]="(!(buttonText == 'Submit'))">
        {{buttonText}}</button>
    </div>
  </div>

</form>