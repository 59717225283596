import {
  Component,
  HostListener,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { JobVM } from 'app/Models/JobVM';
import { SharedServicesService } from 'app/shared/sharedservices/shared-services.service';
import { EngagementService } from 'app/Views/engagement/engagement.service';
import { OrderPipe } from 'ngx-order-pipe';
import { NgxSpinnerService } from 'ngx-spinner';
import { IndividualService } from '../../individual/individual.service';
import { Observable, Subscription } from 'rxjs';
import {
  ScheduledViewService,
  SCHEDULE_CONSTANTS,
} from '../scheduledView.service';
import { DatePipe } from '@angular/common';
import { TotalHours } from 'app/Models/TotalHoursModal';
import { ResourceDto } from 'app/Models/ResourceDto';
import { EditedEngagement } from 'app/Models/ScheduleEngagement';
import { MatSnackBar } from '@angular/material/snack-bar';

import {
  data_totalHours,
  data_bookingType,
  data_stickyColumns,
  data_displayedColumns,
  data_stickyAddColumns,
  data_tableCheckFilters,
  data_addDisplayedColumns,
  data_stickyHeaderColumns,
  data_displayTotalHourHeader,
  getDisplayColumns,
  getAddDisplayColumns,
  getTotalHoursColumns,
  getTotalHoursColumnsHeader,
  data_bookingCell,
  data_resource,
  splitAndJoinDate,
  data_ModelData,
  modalOptions,
  data_IsDisabledBits,
} from './scheduleEngagementData';

import moment from 'moment';
import { CellUpdateData } from './engagementScheduleDataLogin';
import {
  favEngImageSrc,
  MeraActionDescription,
  MERAStatusId,
} from 'app/shared/enum';
import { ThemeService } from 'app/shared/theme/theme.service';
import { DARK_PATH, LIGHT_PATH } from 'app/app-constants';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import {
  FavouriteJobsAddActionDto,
  FavouriteJobsDto,
  FavouriteJobsTableTypeDto,
} from 'app/Models/FavouriteJobsDto';
import { BookingGridActionService } from 'app/Models/BookingGridActionService';
import { EngagementSubmissionVM } from 'app/Models/EngagementSubmissionVM';
import { element } from 'protractor';
import { threadId } from 'worker_threads';

@Component({
  selector: 'app-engagementSchedule',
  templateUrl: './engagementScheduleView.component.html',
  styleUrls: ['./engagementScheduleView.component.scss'],
})
export class engagementScheduleViewComponent implements OnInit {
  IsDataNotFound: boolean = false;
  isRowDeleted: boolean = false;
  isCollapsed: boolean = false;
  searchLabel: string = 'Engagement Forecast';
  engagementSelected: boolean = false;
  user: any;
  instanceSubscription: Subscription;
  engagementDetailsCopy: any = [];
  selectedInstance = 1;
  instances: any;
  range = new FormGroup({
    start: new FormControl(null),
    end: new FormControl(null),
  });
  todaysDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
    0,
    0,
    0
  );
  tempdate = this.sharedService.getFirstDateOfWeek(this.todaysDate);
  defaultperiod: string;
  defaultDateFormat: string;
  startDate = new Date();
  endDate = new Date();
  isCurrentWeekDisabled: boolean = false;
  startWith = this.sharedService.getFirstDateOfWeek(this.todaysDate);
  updatedCellDetails = new CellUpdateData();
  searchBar: string = '';
  showSearchList: boolean = false;
  showSearchLoader: boolean = false;
  showResourceList: boolean = false;
  showResourceLoader: boolean = false;
  showResourceListCopy: boolean = false;
  showResourceLoaderCopy: boolean = false;
  showReplaceResLoader: boolean = false;
  engagementSearch: JobVM;
  engagementListSubscription: Subscription;
  resourceListSubscription: Subscription;
  sortedJobList: JobVM[];
  results: any[] = [];
  resourceResults: any[] = [];
  resourceResultsCopy: any[] = [];
  JobId: number;
  addBookingInstance: number = 1;
  selectedJobName = '';
  selectedEngagement: any = {};
  engagementCode: any;
  engagementStatus: any;
  engagementServiceLine: any;
  engagementType: any;
  isPercentageSelected: boolean = false;
  scheduleEngData: any = [];
  engagementAllocationModal = '';
  isAnyChanges: boolean = false;
  dataSource: any = [];
  searchResourceBar: string = '';
  searchResourceBarCopy: string = '';
  resource: ResourceDto;
  sortedResourceList: ResourceDto[];
  bookingHoursData: any;
  totalHours: number[] = [];
  weeksTobeDisplayed: string[] = [];
  availabilityHours: any[] = [];
  isNotes: boolean = false;
  noteAvailableFor: any;
  editedDetails: EditedEngagement[] = [];
  invalidDataCells: string[] = [];
  currentWeekStartDate = this.sharedService.getFirstDateOfWeek(this.todaysDate);
  stickyColumns = data_stickyColumns;
  stickyHeaderColumns: string[] = [];
  stickyAddColumns: string[] = [];
  bookingType: string[] = [];
  displayedColumns: string[] = [];
  tableCheckFilters: any;
  displayTotalHourHeader: any;
  totalHoursColumns: TotalHours[] = [];
  addDisplayedColumns: any;
  private cancelCellArray: String[] = [];
  modelData: any = data_ModelData;
  @ViewChild('userConfirmation') userConfirmation: TemplateRef<any>;
  resourceToBeDeleted: number;
  isPeriodErrorMsg: boolean = false;
  imagePath: string = '';
  IsDisabledReplaceAllFlag: boolean = false;
  IsAnyBookingWithInProgressStatus: boolean=false;

  modalOption: ModalOptions = {};
  showReplaceAllEng = false;
  gridActionEventData: BookingGridActionService;
  showModal = false;
  gridActionModalHeader = '';
  engagementSubmission: EngagementSubmissionVM;
  modalRef: BsModalRef;
  saveErrorMessage: string = '';
  saveSucessMessage: string = '';
  engHourCalculation: any;

  showFavouriteEngList: boolean = false;
  addFavouriteEngResults: any[];
  isClickonFavEngStart: boolean = false;
  outClickCount: number = 0;
  compareFavouriteEngResults: any[] = [];
  isVerifyOnEngClick: boolean = false;
  lbl: string;
  FavoriteImageClick: boolean = false;
  IsFavEngInserted: boolean = false;
  sortedEngagementList: any[];
  isFavEngONClick: boolean = true;
  beforeReplace: any;
  landscape = window.matchMedia('(orientation: landscape)');

  //Replace
  strsearchResourceReplace: string = '';
  showResourceListReplace: boolean = false;
  showResourceLoaderReplace: boolean = false;
  resourceResultsReplace: any[] = [];
  sortedResourceReplaceList: ResourceDto[];

  constructor(
    private spinner: NgxSpinnerService,
    private sharedService: SharedServicesService,
    public engagementService: EngagementService,
    private scheduledService: ScheduledViewService,
    private modalService: BsModalService,
    private snackBar: MatSnackBar,
    private orderPipe: OrderPipe,
    private themeService: ThemeService,
    public datepipe: DatePipe,
    private router: Router
  ) {
    this.sharedService.activeLink = 'EngagementScheduleView';
    this.resetTableHeaders();
    document.addEventListener('click', this.favoriteimgClickHandler.bind(this)); // bind on document
    this.landscape.addEventListener('change', (ev) => {
      if (!this.landscape.matches) {
        this.snackBar.open(
          'Please rotate your device. This page supports landscape mode only',
          '',
          {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          }
        );
      }
    });
  }

  ngOnInit(): void {
    this.sortedEngagementList = [];
    this.sharedService.activeLink = 'EngagementScheduleView';
    this.user = JSON.parse(sessionStorage.getItem('resource'));
    this.user.Unconfirmed = !this.user.Unconfirmed;
    this.addBookingInstance = this.user.R_Home_InstanceID;
    this.setDynamicBookingTypes();
    this.resource = new ResourceDto();
    this.setTime();
    this.getUserInstances();
    this.isPercentageSelected = this.user.PercentSelected;
    this.engagementAllocationModal =
      this.isPercentageSelected === true ? 'percentage' : 'hour';
    this.gridActionEventData = new BookingGridActionService();
    this.engagementSubmission = new EngagementSubmissionVM();
    this.engagementService.currentMessageCloseModal.subscribe((closeModal) => {
      this.closeActionModal(closeModal);
    });

    // Inorder to populate the same engagement user clicked on SIV
    if (history.state && history.state.jobId) {
      this.spinner.show();
      this.handleSearchEngagement(history.state);
    } else if (
      sessionStorage.getItem('Selected_Engagement') &&
      sessionStorage.getItem('Selected_Engagement').length > 0
    ) {
      this.spinner.show();
      this.handleSearchEngagement(
        JSON.parse(sessionStorage.getItem('Selected_Engagement'))
      );
    }

    const activeTheme = this.themeService.getActiveTheme();
    this.imagePath = activeTheme.name === 'light' ? LIGHT_PATH : DARK_PATH;

    this.loadInitialPage();
    this.handleWindowClick();
    this.handleResourceScroll();
  }

  loadInitialPage() {
    let endDate = this.range.value.end;
    if (!endDate) {
      endDate = this.sharedService.getFirstDateOfWeek(this.todaysDate);
      endDate.setDate(endDate.getDate() + 51 * 7);
      endDate = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate(),
        0,
        0,
        0
      );
    }
    this.addDatestoTableView(this.tempdate, endDate);
    this.getTotalHours();
    if (!this.landscape.matches) {
      this.snackBar.open(
        'Please rotate your device. This page supports landscape mode only',
        '',
        {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        }
      );
    }
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    const cellDetails = this.updatedCellDetails.fetchCurrentCellDetails();
    if (
      this.cancelCellArray.length > 0 ||
      cellDetails.length > 0 ||
      this.isAnyChanges
    ) {
      return false;
    } else {
      return true;
    }
  }
  setWidth() {
    let widthDiv = window.innerWidth;
    widthDiv = widthDiv - 50;
    let styles = {
      width: widthDiv + 'px',
    };
    return styles;
  }
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.setWidth();
  }

  handleResourceScroll() {
    const scrollWrapper = document.getElementById('resourceScrollWrapper');
    const that = this;
    scrollWrapper.addEventListener('scroll', function (event) {
      that.isNotes = false;
    });
  }

  handleWindowClick() {
    const wrapper = document.getElementById('scheduleEngagementPage');
    // var container = document.getElementsByClassName('valueCell')[0];
    const notesArray = ['notesSection', 'weeklyIcon'];
    const editElArray = ['valueCell', 'cellDivision', 'editCell'];
    const that = this;
    wrapper.addEventListener('click', function (event) {
      let currElem = event.target as Element;
      let elementFound = false;
      do {
        if (
          (currElem.classList &&
            that.checkIntersectionOfArrays(currElem.classList, notesArray)) ||
          that.checkIntersectionOfArrays(currElem.classList, editElArray)
        )
          elementFound = true;
        currElem = currElem.parentElement;
      } while (!elementFound && currElem && currElem.parentElement);
      if (!elementFound) {
        // To close the notes dialog box
        that.isNotes = false;

        // To close all the opened edit cells
        that.dataSource.forEach((engagement) => {
          Object.keys(engagement.WeeklyBookings).forEach((week) => {
            Object.keys(engagement.WeeklyBookings[week]).forEach((type) => {
              if (engagement.WeeklyBookings[week][type].isValid)
                engagement.WeeklyBookings[week][type].IsEdit = false;
            });
          });
        });
      }
    });
  }

  checkIntersectionOfArrays(arr1, arr2) {
    let itemFound = false;
    arr2.forEach((item) => {
      if (arr1.contains(item)) itemFound = true;
    });
    return itemFound;
  }

  getEngagement(param?) {
    param = {
      StartDate: this.startDate,
      EndDate: this.endDate,
      JobId: this.JobId,
      InstanceId: this.selectedInstance,
      rowReplaced: false,
      ...param,
    };
    if (param.StartDate !== null && param.EndDate !== null) {
      param.StartDate = moment(param.StartDate).format(
        'YYYY-MM-DDT00:00:00.000'
      );
      param.EndDate = moment(param.EndDate).format('YYYY-MM-DDT00:00:00.000');
    }

    this.spinner.show();
    this.dataSource = [];
    this.scheduledService.getEngagementDataList(param).subscribe(
      (data) => {
        this.scheduleEngData = data; // Dynamic Data
        // this.scheduleEngData = engagementData; // Static Data
        this.IsAnyBookingWithInProgressStatus =this.scheduleEngData?.IsAnyBookingWithInProgressStatus;
        this.updatedCellDetails.setData(
          this.sharedService.getCopy(this.scheduleEngData)
        );

        if (this.scheduleEngData) {
          const maxWeekStartDate = this.sharedService.getFirstDateOfWeek(
            new Date(this.scheduleEngData.MaxWeekStartDate)
          );
          this.tempdate = this.sharedService.getFirstDateOfWeek(
            this.todaysDate
          );
          this.tempdate = new Date(
            this.tempdate.getFullYear(),
            this.tempdate.getMonth(),
            this.tempdate.getDate(),
            0,
            0,
            0
          );
          let checkdate = this.sharedService.getFirstDateOfWeek(
            this.todaysDate
          );
          checkdate.setDate(checkdate.getDate() + 51 * 7);
          checkdate = new Date(
            checkdate.getFullYear(),
            checkdate.getMonth(),
            checkdate.getDate(),
            0,
            0,
            0
          );

          if (this.startDate !== null && this.endDate !== null) {
            var startDate = new Date(this.startDate);
            var endDate = new Date(this.endDate);
            this.addDatestoTableView(startDate, endDate);
          } else {
            if (new Date(maxWeekStartDate) > new Date(checkdate)) {
              //checking whether booking are there beyond one year
              this.addDatestoTableView(
                this.tempdate,
                new Date(maxWeekStartDate) //adds week columns upto the forward booking
              );
            } else {
              this.addDatestoTableView(this.tempdate, checkdate); //adds week columns upto the next year from today.
            }
          }

          this.engagementDetailsCopy = JSON.parse(
            JSON.stringify(this.scheduleEngData)
          );

          this.filterDataSource(
            this.scheduleEngData.ScheduleBookingDto,
            this.scheduleEngData.DiaryHoursDtos
          );
        }
      },
      (err) => {
        this.snackBar.open('Error occurred, please try again', '', {
          duration: SCHEDULE_CONSTANTS.SNACKBAR_TIMEOUT,
        });
        console.log('Api Error', err);
      },
      () => {
        this.resetTableUI();
        setTimeout(() => {
          this.saveErrorMessage = '';
          this.saveSucessMessage = '';
        }, 5500);
      }
    );
  }

  resetTableUI() {
    setTimeout(() => {
      this.getTotalHours();
      this.spinner.hide();
      if (!this.landscape.matches) {
        this.snackBar.open(
          'Please rotate your device. This page supports landscape mode only',
          '',
          {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          }
        );
      }
    }, 500);
  }

  setDynamicBookingTypes() {
    this.engHourCalculation = this.scheduledService.getHourModeSEV();
    this.bookingType = ['Confirmed'];
    this.tableCheckFilters = this.tableCheckFilters.map((checkFilter) => {
      checkFilter.shouldShow = false;
      checkFilter.isChecked = false;
      if (checkFilter.value === 'Confirmed') {
        // In case if both Unconfirmed and Ghost role are false, hide the Confirmed checkbox from table
        if (this.user['Unconfirmed'] === true || this.user['Ghost'] === true) {
          checkFilter.shouldShow = true;
        }
        checkFilter.isChecked =
          this.engHourCalculation === null
            ? true
            : this.engHourCalculation.isConfirmedChecked;
      } else if (
        this.user[checkFilter.value] &&
        this.user[checkFilter.value] === true
      ) {
        this.bookingType.push(checkFilter.value);
        checkFilter.shouldShow = true;
        if (checkFilter.value === 'Unconfirmed') {
          checkFilter.isChecked =
            this.engHourCalculation === null
              ? true
              : this.engHourCalculation.isUnconfirmedChecked;
        } else if (checkFilter.value === 'Ghost') {
          checkFilter.isChecked =
            this.engHourCalculation === null
              ? true
              : this.engHourCalculation.isGhostChecked;
        }
      }
      return checkFilter;
    });
  }
  saveInSession(bookingType) {
    let param = {
      isConfirmedChecked: true,
      isUnconfirmedChecked: true,
      isGhostChecked: true,
    };
    this.tableCheckFilters.map((checkFilter) => {
      if (checkFilter.value === 'Confirmed') {
        param.isConfirmedChecked = checkFilter.isChecked;
      } else if (checkFilter.value === 'Unconfirmed') {
        param.isUnconfirmedChecked = checkFilter.isChecked;
      } else if (checkFilter.value === 'Ghost') {
        param.isGhostChecked = checkFilter.isChecked;
      }
    });
    this.scheduledService.setHourModeSEV(param);
  }

  filterDataSource(dataSource, diaryHours) {
    let newData = [];
    if (dataSource != null) {
      dataSource.forEach((engagement) => {
        let engObj = {
          ...engagement,
          WeeklyBookings: {},
        };
        const uniqueBookings = this.weeksTobeDisplayed.map((weekItem) =>
          splitAndJoinDate(weekItem.split('.000Z')[0])
        );

        uniqueBookings.forEach((bookingDate: string) => {
          engObj.WeeklyBookings[bookingDate] = {};
          const weeklyDiaryHours =
            diaryHours && diaryHours.length > 0
              ? diaryHours.filter(
                  (week) =>
                    week.ResourceId === engagement.ResourceId &&
                    week.FiscalWeekStartDate === bookingDate
                )
              : null;
          this.tableCheckFilters
            // .filter((item) => item.shouldShow)
            .map((checkItem) => {
              const uniqId =
                engagement.JobId +
                '_' +
                engagement.ResourceId +
                '_' +
                Object.keys(engObj.WeeklyBookings).length +
                '_' +
                checkItem.value;
              const sDate = bookingDate + '.000Z';
              const eDate = moment(
                new Date(bookingDate).setDate(
                  new Date(bookingDate).getDate() + 6
                )
              ).format('YYYY-MM-DDT00:00:00');
              const doesBookingExists = engagement.WeeklyBookings.filter(
                (booking) =>
                  booking.BookingMode === checkItem.value &&
                  booking.ActionMode === 'E' &&
                  splitAndJoinDate(booking.WeekStartDate) === bookingDate
              );
              if (doesBookingExists && doesBookingExists.length > 0) {
                engObj.WeeklyBookings[bookingDate][checkItem.value] = {
                  ...data_bookingCell,
                  ...doesBookingExists[0],
                  BookingHours:
                    doesBookingExists[0].MeraActionDescriptionId ===
                    MeraActionDescription.Delete
                      ? null
                      : doesBookingExists[0].BookingHours,
                  OldHoursValue: doesBookingExists[0].BookingHours,
                  OldPercentageValue: doesBookingExists[0].Loading,
                  OldDescription: doesBookingExists[0].Description,
                };
              } else {
                engObj.WeeklyBookings[bookingDate][checkItem.value] = {
                  ...data_bookingCell,
                  JobId: engagement.JobId,
                  ResourceId: engagement.ResourceId,
                  InstanceID: engagement.InstanceID,
                  BookingMode: checkItem.value,
                  WeekStartDate: bookingDate,
                  WeekEndDate: eDate,
                  StartDate: sDate,
                  EndDate: eDate,
                  RequestorEmail: this.user.Email,
                };
              }
              engObj.WeeklyBookings[bookingDate][checkItem.value] = {
                ...engObj.WeeklyBookings[bookingDate][checkItem.value],
                Action: '',
                DiaryHours:
                  weeklyDiaryHours && weeklyDiaryHours.length > 0
                    ? weeklyDiaryHours[0].DiaryHours
                    : 0,
                TempId: uniqId,
                IsNotes: false,
                IsEdit: false,
              };
            });
        });
        newData.push(engObj);
      });
      this.dataSource = [...newData];

      // for Replace1 functionality

      this.dataSource.forEach((hh) => {
        hh.rowReplaced = false;
      });

      this.beforeReplace = this.sharedService.getCopy(this.dataSource);
    }
  }

  addDatestoTableView(startingDate, endingDate) {
    this.totalHours = [];
    this.availabilityHours = [];
    this.weeksTobeDisplayed = [];

    // To get the display columns based on user.IsMultiInstance (Remove Retain instance column if user.IsMultiInstance is false)

    this.displayedColumns = this.sharedService.getCopy(
      getDisplayColumns(this.user.IsMultiInstance)
    );

    this.addDisplayedColumns = this.sharedService.getCopy(
      getAddDisplayColumns(this.user.IsMultiInstance)
    );

    this.totalHoursColumns = this.sharedService.getCopy(
      getTotalHoursColumns(this.user.IsMultiInstance)
    );

    this.displayTotalHourHeader = this.sharedService.getCopy(
      getTotalHoursColumnsHeader(this.user.IsMultiInstance)
    );

    do {
      this.displayedColumns.push(
        this.datepipe.transform(startingDate, this.defaultDateFormat)
      );
      this.addDisplayedColumns.push(
        this.datepipe.transform(startingDate, this.defaultDateFormat) + '-add'
      );
      this.weeksTobeDisplayed.push(
        splitAndJoinDate(moment(startingDate).format('YYYY-MM-DDT00:00:00'))
      );
      startingDate.setDate(startingDate.getDate() + 7);
    } while (
      endingDate &&
      moment(startingDate.toDateString()).isSameOrBefore(
        new Date(endingDate).toDateString()
      )
    );
  }

  compareDateWithWeek(bookingData, index) {
    const bookingIndex = this.weeksTobeDisplayed
      .map((item) => new Date(item).toDateString())
      .indexOf(new Date(bookingData.key).toDateString());
    return (
      bookingIndex > -1 && bookingIndex + data_displayedColumns.length === index
    );
  }

  checkUserAccessType(type) {
    const currentType = this.tableCheckFilters.filter(
      (item) => item.value === type
    )[0];
    return currentType.shouldShow;
  }

  getTotalHours() {
    this.resetTotalColumns();
    this.weeksTobeDisplayed.forEach((week, weekIndex) => {
      let total = 0;
      let totalPercentage = 0;
      this.dataSource.forEach((engagement) => {
        const itemIndex = Object.keys(engagement.WeeklyBookings)
          .map((item) => new Date(item).toDateString())
          .indexOf(new Date(week).toDateString());
        if (itemIndex > -1) {
          const appliedTypes = this.tableCheckFilters
            .filter((item) => item.isChecked)
            .map((item) => item.value);
          appliedTypes.forEach((type) => {
            // if (this.bookingType.indexOf(type) >= 0) {
            if (Object.values(engagement.WeeklyBookings)[itemIndex][type]) {
              total += Object.values(engagement.WeeklyBookings)[itemIndex][type]
                .BookingHours
                ? parseFloat(
                    Object.values(engagement.WeeklyBookings)[itemIndex][type]
                      .BookingHours
                  )
                : 0;
              totalPercentage += Object.values(engagement.WeeklyBookings)[
                itemIndex
              ][type].Loading
                ? parseFloat(
                    Object.values(engagement.WeeklyBookings)[itemIndex][type]
                      .Loading
                  )
                : 0;
            }
            // }
          });
        }
      });
      this.displayTotalHourHeader.push(total.toString() + '_' + weekIndex);
      this.totalHoursColumns.push({
        displayName: total.toString() + '_' + weekIndex,
        displayHours: parseFloat(total.toFixed(2)).toString(),
        displayPercentage: parseFloat(totalPercentage.toFixed(2)).toString(),
      });
    });
  }

  resetTotalColumns() {
    this.totalHoursColumns = this.sharedService.getCopy(
      getTotalHoursColumns(this.user.IsMultiInstance)
    );

    this.displayTotalHourHeader = this.sharedService.getCopy(
      getTotalHoursColumnsHeader(this.user.IsMultiInstance)
    );
  }

  setTime() {
    this.defaultDateFormat = this.sharedService.getDateFormat(
      this.user.DefaultDateFormat
    );
    this.sharedService.getDefaultPeriodSettings(this.user);
    this.range.setValue({
      start: this.sharedService.defaultStartDate
        ? moment(this.sharedService.defaultStartDate)
        : null,
      end: this.sharedService.defaultEndDate
        ? moment(this.sharedService.defaultEndDate)
        : null,
    });
    this.startDate = this.sharedService.defaultStartDate;
    this.endDate = this.sharedService.defaultEndDate;

    this.currentWeekStartDate = new Date(
      this.currentWeekStartDate.getFullYear(),
      this.currentWeekStartDate.getMonth(),
      this.currentWeekStartDate.getDate(),
      0,
      0,
      0
    );
    this.startWith.setMinutes(
      this.startWith.getMinutes() - this.startWith.getTimezoneOffset()
    );

    if (
      this.sharedService.defaultStartDate !== null &&
      this.sharedService.defaultEndDate !== null
    ) {
      this.startDate = this.sharedService.getFirstDateOfWeek(
        this.sharedService.defaultStartDate
      );
      this.endDate = this.sharedService.getFirstDateOfWeek(
        this.sharedService.defaultEndDate
      );
      if (
        moment(this.startDate.toDateString()).isSame(
          this.endDate.toDateString()
        )
      ) {
        this.endDate.setDate(this.endDate.getDate() + 6);
      }
    }
  }

  getUserInstances() {
    this.spinner.show();
    this.instanceSubscription = this.sharedService.getUserInstances().subscribe(
      (data) => {
        this.instances = this.sharedService.getStoredUserInstances();
        const selected = this.instances.filter(
          (x) => x.InstanceId === this.user.DefaultInstance
        );
        this.selectedInstance = selected[0]?.InstanceId;
        this.addBookingInstance = !this.user.R_InstEnable_AddBooking
          ? this.user.R_Home_InstanceID
          : selected[0]?.InstanceId;
        if (selected && selected.length > 0) {
          this.checkCurrentUserInResourceList();
        }
      },
      (err) => {
        console.log(err);
      },
      () => {
        if (
          (history.state && history.state.jobId) ||
          (sessionStorage.getItem('Selected_Engagement') &&
            sessionStorage.getItem('Selected_Engagement').length > 0)
        ) {
        } else {
          this.spinner.hide();
        }
      }
    );
  }

  onInstanceChange(event) {
    this.selectedInstance = event.value;
    this.resetfilterBookingGridSearchBar();
    if (this.searchBar && this.searchBar.trim().length > 0) {
      this.getEngagement();
    }
    this.addFavouriteEngResults = [];
  }

  resetfilterBookingGridSearchBar() {
    this.spinner.show();
    this.results = [];
    this.showSearchList = false;
    this.addFavouriteEngResults = []; // clear the favorite eng array
    this.JobId && sessionStorage.setItem('JobId', this.JobId.toString());
    sessionStorage.setItem('JobDescription', this.selectedJobName);
    this.engagementSelected = false;
    sessionStorage.setItem('Selected_Engagement', '');
    this.engagementService.setSelectedJob(this.JobId);
    this.searchBar = '';
    this.dataSource = [];
    this.JobId = null;
    this.resetTableUI();
    this.selectedEngagement.JobDescription = '';
    this.selectedEngagement.ServiceLineDescription = '';
    this.selectedEngagement.ManagerialCountryDescription = '';
    this.selectedEngagement.CostCenterDescription = '';
    this.selectedEngagement.PartnerName = '';
    this.selectedEngagement.ManagerNameAndGPN = '';
    this.selectedEngagement.InstanceName = '';
    this.cancelCellArray = [];
    this.updatedCellDetails.clearCellDetails();
    this.clearChanges();
    this.isFavEngONClick = true;
    this.IsDataNotFound = false;
  }

  clearChanges() {
    this.cancelCellArray = [];
    this.updatedCellDetails.clearCellDetails();
  }

  resetResourceSearch() {
    this.resourceResults = [];
    this.showResourceList = false;
    this.searchResourceBar = '';
  }

  resetResourceSearchCopy() {
    this.resourceResultsCopy = [];
    this.showResourceListCopy = false;
    this.searchResourceBarCopy = '';
  }

  resetResourceSearchReplace(element) {
    this.updatedCellDetails.clearCellDetails();
    this.resourceResultsReplace = [];
    this.showResourceListReplace = false;
    this.strsearchResourceReplace = '';
    element.showReplaceResList = false;
  }

  resetfilterBookingGridCalender(event) {
    event.stopPropagation();
    event.preventDefault();
    this.results = [];
    this.range.setValue({
      start: null,
      end: null,
    });
    this.startDate = null;
    this.endDate = null;
    // sessionStorage.setItem('PeriodFilterStDate', null);
    // sessionStorage.setItem('PeriodFilterEndDate', null);
    // this.sharedService.getDefaultPeriodSettings(this.user);
    this.getEngagement();
  }

  onBookingStartDateChange(event: any) {
    this.startDate = this.range.get('start').value;
    this.endDate = null;
  }

  onBookingEndDateChange(event: any) {
    this.endDate = this.range.get('end').value;
    if (event.value != null) {
      if (this.endDate != null) {
        // sessionStorage.setItem('PeriodFilterStDate', this.startDate.toString());
        // sessionStorage.setItem('PeriodFilterEndDate', this.endDate.toString());
        // let periodFilterStDate = new Date(this.startDate).toUTCString();
        // periodFilterStDate = periodFilterStDate.split('00')[0];
        // this.startDate = new Date(periodFilterStDate);
        // let periodFilterEndDate = new Date(this.endDate).toUTCString();
        // periodFilterEndDate = periodFilterEndDate.split('00')[0];
        // this.endDate =  new Date(periodFilterEndDate);
        this.startDate = new Date(
          moment(this.startDate).format('YYYY-MM-DDT00:00:00.000')
        );
        this.endDate = new Date(
          moment(this.endDate).format('YYYY-MM-DDT00:00:00.000')
        );
        this.startDate = this.sharedService.getFirstDateOfWeek(this.startDate);
        this.endDate = this.sharedService.getFirstDateOfWeek(this.endDate);
        this.startDate = new Date(
          this.startDate.getFullYear(),
          this.startDate.getMonth(),
          this.startDate.getDate(),
          0,
          0,
          0
        );
        this.endDate = new Date(
          this.endDate.getFullYear(),
          this.endDate.getMonth(),
          this.endDate.getDate(),
          0,
          0,
          0
        );
        if (
          moment(this.startDate.toDateString()).isSame(
            this.endDate.toDateString()
          )
        ) {
          this.endDate.setDate(this.endDate.getDate() + 6);
        }
        this.spinner.show();
        this.getEngagement({});
      }
    }
  }

  OnClosedDatePicker() {
    if (this.startDate != null && this.endDate == null) {
      this.isPeriodErrorMsg = true;
      setTimeout(() => {
        this.isPeriodErrorMsg = false;
        this.startDate = null;
        this.endDate = null;
        this.range.setValue({
          start: null,
          end: null,
        });
      }, 3000);
    }
  }

  checkCurrentUserInResourceList() {
    this.range.setValue({
      start: this.sharedService.defaultStartDate
        ? moment(this.sharedService.defaultStartDate)
        : null,
      end: this.sharedService.defaultEndDate
        ? moment(this.sharedService.defaultEndDate)
        : null,
    });
  }

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  search() {
    this.isFavEngONClick = false;
    this.isVerifyOnEngClick = false;
    this.showFavouriteEngList = false;
    if (this.searchBar != null) {
      if (this.searchBar.trim().length >= 2) {
        this.showFavouriteEngList = false;
        this.showSearchList = true;
        this.showSearchLoader = true;
        this.engagementSearch = new JobVM();
        this.engagementSearch.JobDescription = this.searchBar.trim();
        this.engagementSearch.JobId = 0;
        this.engagementSearch.LoggedInResourceId = +sessionStorage.getItem(
          'loggedin_resourceid'
        );
        this.engagementSearch.InstanceId = this.selectedInstance;
        this.engagementListSubscription = this.scheduledService
          .getJobList(this.engagementSearch)
          .subscribe((data) => {
            this.sortedEngagementList = data;
            this.sortedJobList = this.orderPipe.transform(
              data,
              'JobDescription'
            );

            // this.sortedEngagementList = this.orderPipe.transform(this.sortedJobList, 'EngagementFullName');
            if (this.sortedEngagementList.length > 0) {
              this.setAllFavEngagement();
            }

            this.results = data;
            this.showSearchLoader = false;
            this.showFavouriteEngList = false;
          });
      } else {
        this.results = [];
        this.showSearchList = false;
      }
    } else {
      this.results = [];
      this.showSearchList = false;
    }
  }

  private setAllFavEngagement(): void {
    this.sortedEngagementList.forEach((a) => {
      if (a.IsFavourite === true) {
        a.IsFavEngInserted = true;
        a.favEngSrc = favEngImageSrc.favEngImgStarfilled;
      } else {
        a.IsFavEngInserted = false;
        a.favEngSrc = favEngImageSrc.favEngImgStarOutline;
      }
    });
  }

  OnItemSelect(event) {
    if (this.FavoriteImageClick === true) {
      this.FavoriteImageClick = false;
      (event as Event).stopPropagation();
      // event.stopPropagation();
    }

    this.showSearchList = false;
    this.showFavouriteEngList = false;
    this.searchBar = event.JobDescription;
    this.selectedEngagement = event;
    this.JobId = event.JobId;
    this.isCurrentWeekDisabled = event.IsDisabledAddBookingCurrWeek;
    this.engagementSelected = !event.IsDisabledAddBooking;
    this.IsDisabledReplaceAllFlag = event.IsDisabledReplaceAllBooking_EV;
    this.engagementService.setSelectedJob(this.JobId);
    const params = {
      StartDate: this.startDate,
      EndDate: this.endDate,
    };
    sessionStorage.setItem('JobId', event.JobId.toString());
    sessionStorage.setItem('JobDescription', event.JobDescription);
    sessionStorage.setItem('engagementCode', event.JobCode);
    sessionStorage.setItem('engagementType', event.JobType);
    sessionStorage.setItem('engagementStatus', event.JobStatus);
    sessionStorage.setItem(
      'engagementServiceLine',
      event.ServiceLineDescription
    );
    sessionStorage.setItem(
      'isPercentageSelected',
      this.isPercentageSelected.toString()
    );
    sessionStorage.setItem('instanceId', this.selectedInstance.toString());
    sessionStorage.setItem(
      'EngagementSelected',
      this.engagementSelected.toString()
    );

    this.getEngagement(params);
    this.saveSelectedEngagementDetails(event);
  }

  saveSelectedEngagementDetails(event) {
    const data = {
      instanceId: this.selectedInstance,
      jobCode: event.JobCode,
      jobId: event.JobId,
    };
    sessionStorage.setItem('Selected_Engagement', JSON.stringify(data));
  }

  engagementAllocationOnChange(event) {
    this.isPercentageSelected =
      this.engagementAllocationModal === 'hour' ? false : true;
    this.maintainHiddenValues();
  }

  maintainHiddenValues() {
    this.scheduledService.engagementAllocationOnChange(
      this.isPercentageSelected
    );
  }

  submit() {
    if (
      this.dataSource.some((t) => t.ResourceId === null || t.ResourceId === '')
    ) {
      alert('One or more action/s pending');
      return;
    }

    this.spinner.show();
    const cellDetails = this.updatedCellDetails.fetchCurrentCellDetails();

    // For Create/Edit

    if (cellDetails && cellDetails.length > 0) {
      const editedDet = cellDetails.map((item) => {
        const { TempId, ...rest } = item;
        return { ...rest, InstanceId: this.selectedInstance };
      });
      const param = {
        IsPercentSelected: this.isPercentageSelected,
        RequestMode: 2,
        UiViewSource: 'TEV',
        Bookings: editedDet,
      };
      this.scheduledService.saveScheduleList(param).subscribe(
        (data) => {
          if (data.success)
            this.saveSucessMessage = 'Schedules updated successfully';
          this.getEngagement();
          this.updatedCellDetails.clearCellDetails();
        },
        (err) => {
          this.saveErrorMessage = 'Schedules save failed';
          console.log(err);
        },
        () => {
          this.searchResourceBar = '';
          this.cancelBookings();
          this.resetResourceSearchCopy();
        }
      );
    } else {
      this.cancelBookings();
    }
  }

  cancelBookings() {
    // For deleting the MERA/Retain Bookings
    if (this.cancelCellArray.length > 0) {
      let param = {
        MeraBookingsID: this.cancelCellArray,
      };
      this.scheduledService.CancelMeraBookings(param).subscribe(
        (data: any) => {
          if (data.success)
            this.saveSucessMessage = 'Schedules updated successfully';
        },
        (err) => {
          this.saveErrorMessage = 'Schedules save failed';
          console.log(err);
        },
        () => {
          this.getEngagement();
          this.cancelCellArray = [];
        }
      );
    }
  }

  cancel() {
    this.dataSource = [];
    this.filterDataSource(
      this.engagementDetailsCopy.ScheduleBookingDto,
      this.engagementDetailsCopy.DiaryHoursDtos
    );
    this.clearChanges();
    this.getTotalHours();
  }

  updateWeeksFormat(array) {
    return array.map((eachWeekItem) => splitAndJoinDate(eachWeekItem));
  }

  notesSubmit(data) {
    if (data) {
      // Submitted
      let weeksTobeUpdated = [];
      switch (data.radioSelection) {
        case 'update_all':
          // Update all the cells
          const dDate = this.updateWeeksFormat(this.weeksTobeDisplayed);
          dDate.forEach((week) => {
            if (
              moment(new Date(week).toDateString()).isSameOrAfter(
                new Date(
                  this.noteAvailableFor.cellData.WeekStartDate
                ).toDateString()
              )
            ) {
              weeksTobeUpdated.push(week);
            }
          });
          break;

        case 'extend_upto':
          // Check the date and update until the date if date is present, unless update the current cell
          if (data.date) {
            // this.sharedService.getFirstDateOfWeek(data.date.value);
            const dDate = this.updateWeeksFormat(this.weeksTobeDisplayed);
            dDate.forEach((eachWeekItem) => {
              if (
                moment(new Date(eachWeekItem).toDateString()).isSameOrBefore(
                  data.date.value.toDateString()
                ) &&
                moment(new Date(eachWeekItem).toDateString()).isSameOrAfter(
                  new Date(
                    this.noteAvailableFor.cellData.WeekStartDate
                  ).toDateString()
                )
              ) {
                weeksTobeUpdated.push(eachWeekItem);
              }
            });
            weeksTobeUpdated = this.updateWeeksFormat(weeksTobeUpdated);
          } else {
            weeksTobeUpdated.push(this.noteAvailableFor.cellData.WeekStartDate);
          }
          break;
      }
      this.dataSource = this.dataSource.map(
        (engagementItem, engagementIndex) => {
          if (
            engagementItem.ResourceId ===
            this.noteAvailableFor.engagement.ResourceId
          ) {
            // To update only those cells with same value as the current cell on 'update_all' action
            if (data.radioSelection === 'update_all') {
              const weeksFromData = this.scheduleEngData.ScheduleBookingDto[
                engagementIndex
              ].WeeklyBookings.filter(
                (weekItem) =>
                  weekItem.BookingHours ===
                  this.noteAvailableFor.cellData.OldHoursValue
              ).map((weekItem) => weekItem.WeekStartDate);

              weeksTobeUpdated = weeksTobeUpdated.filter((n) => {
                return weeksFromData.indexOf(n) !== -1;
              });
            }

            weeksTobeUpdated.forEach((week) => {
              if (
                engagementItem.WeeklyBookings[week] &&
                Object.keys(engagementItem.WeeklyBookings[week]).length > 0
              ) {
                if (data.radioSelection === 'update_all') {
                }
                let currBook =
                  engagementItem.WeeklyBookings[week][
                    this.noteAvailableFor.cellData.BookingMode
                  ];
                this.updateBookingHours(
                  currBook,
                  this.isPercentageSelected
                    ? this.noteAvailableFor.cellData.Loading
                    : this.noteAvailableFor.cellData.BookingHours
                );

                currBook.Description =
                  this.noteAvailableFor.cellData.Description;

                this.addEngagementDetails(
                  this.noteAvailableFor.engagement,
                  engagementItem.WeeklyBookings[week][
                    this.noteAvailableFor.cellData.BookingMode
                  ]
                );
              }
            });
          }
          return engagementItem;
        }
      );
      this.getTotalHours();
    } else {
      // Cancelled
    }
    this.isNotes = false;
  }

  isSticky(column) {
    if (this.stickyColumns.indexOf(column) > -1) {
      return true;
    } else {
      return false;
    }
  }

  isAvailabiltyHeaderSticky(column) {
    if (this.stickyHeaderColumns.indexOf(column) > -1) {
      return true;
    } else {
      return false;
    }
  }

  isStickyAddColumn(column) {
    if (this.stickyAddColumns.indexOf(column) > -1) {
      return true;
    } else {
      return false;
    }
  }

  showNotes(event, cellData, engagement) {
    if (
      // !cellData.IsDisabledCopyBooking_EV &&
      !cellData.IsDisabledDeleteBooking_EV ||
      // !cellData.IsDisabledReplaceAllBooking_EV &&
      // !cellData.IsDisabledReplaceBooking_EV &&
      !cellData.IsDisabledEditBooking_EV
    ) {
      this.isNotes = true;
      const resource = document
        .getElementById('resourceScrollWrapper')
        .getBoundingClientRect();
      this.noteAvailableFor = {
        cellData: cellData,
        engagement,
        position: {
          x: event.clientX - resource.left,
          y: event.clientY - resource.top,
        },
      };

      this.dataSource.forEach((eachEng) => {
        if (eachEng.ResourceId === engagement.ResourceId) {
          Object.keys(engagement.WeeklyBookings).forEach((bookWeek) => {
            let currentWeek = engagement.WeeklyBookings[bookWeek];
            Object.keys(currentWeek).forEach((type) => {
              let currentBooking = currentWeek[type];
              if (
                !currentBooking.IsDisabledCopyBooking_EV &&
                !currentBooking.IsDisabledDeleteBooking_EV &&
                !currentBooking.IsDisabledReplaceAllBooking_EV &&
                !currentBooking.IsDisabledReplaceBooking_EV &&
                !currentBooking.IsDisabledEditBooking_EV
              ) {
                currentBooking.IsEdit = true;
              }
            });
          });
        }
      });
    }
  }

  trackByTotalHours = (j) => j;

  trackByInstances(index, item) {
    return item.InstanceId + '_' + item.UserId + '_' + index;
  }

  trackByaddFavEngResults(index, item) {
    return item.JobId + '_' + item.JobCode + '_' + index;
  }

  trackByDisplayColumns(index, item) {
    return item.split(' ').join('_') + '_' + index;
  }

  trackByResourceList(index, item) {
    return item.ResourceId + '_' + index;
  }

  trackByWeeklyBookings(index, item) {
    return index + '_' + item.key;
  }

  trackByBookingType(index, item) {
    return index + '_' + item;
  }

  trackByCheckFilters(index, item) {
    return index + '_' + item.value;
  }

  checkIfNumbers(data, booking) {
    const pattern = /^\d+\.?\d*$/;
    const isNumber = isNaN(data) ? pattern.test(data) : true;
    const hoursUpperLimit = 9999.99,
      percentageUpperLimit = 999.99;
    if (data === null) {
      booking.isValid = true;
    } else {
      booking.isValid =
        0 < (data ? parseFloat(data) : 0.1) &&
        (this.isPercentageSelected
          ? (data ? parseFloat(data) : 0.1) < percentageUpperLimit
          : (data ? parseFloat(data) : 0.1) < hoursUpperLimit);
    }
    if (booking.isValid) {
      this.invalidDataCells = this.invalidDataCells.filter(
        (item) => item !== booking.TempId
      );
    } else {
      this.invalidDataCells.push(booking.TempId);
    }
    return isNumber;
  }

  openInfo(template) {
    modalOptions.backdrop = 'static';
    modalOptions.keyboard = false;
    this.modalService.show(template, modalOptions);
  }

  closeInfo() {
    this.modalService.hide();
  }

  searchResource() {
    if (
      !this.selectedEngagement ||
      Object.keys(this.selectedEngagement).length === 0
    ) {
      this.snackBar.open(
        'Kindly select an engagement before proceeding further',
        '',
        {
          duration: SCHEDULE_CONSTANTS.SNACKBAR_TIMEOUT,
        }
      );
      return;
    }
    if (this.searchResourceBar != null) {
      if (this.searchResourceBar.trim().length >= 2) {
        this.showResourceList = true;
        this.showResourceLoader = true;
        this.resource.ResourceName = this.searchResourceBar;
        this.resource.ResourceId = +sessionStorage.getItem('resourceId');
        this.resource.InstanceId = !this.user.R_InstEnable_AddBooking
          ? this.user.R_Home_InstanceID
          : this.selectedInstance;
        this.resource.LoggedInResourceID = +sessionStorage.getItem(
          'loggedin_resourceid'
        );
        // this.applicationInsightsService.logEvent('Individual View - getResourceList');
        this.resourceListSubscription = this.scheduledService
          .getEngagementResourceList(this.resource)
          .subscribe((data) => {
            this.sortedResourceList = this.orderPipe.transform(
              data,
              'ResourceName'
            );
            if (this.searchResourceBar.length > 0) {
              this.resourceResults = this.sortedResourceList;
              this.showResourceLoader = false;
            } else {
              this.resourceResults = [];
              this.showResourceList = false;
            }
          });
      } else {
        this.resourceResults = [];
        this.showResourceList = false;
      }
    } else {
      this.resourceResults = [];
      this.showResourceList = false;
    }
  }

  searchResourceCopy() {
    if (this.searchResourceBarCopy != null) {
      if (this.searchResourceBarCopy.trim().length >= 2) {
        this.showResourceListCopy = true;
        this.showResourceLoaderCopy = true;
        this.resource.ResourceName = this.searchResourceBarCopy;
        this.resource.ResourceId = +sessionStorage.getItem('resourceId');
        this.resource.InstanceId = !this.user.R_InstEnable_AddBooking
          ? this.user.R_Home_InstanceID
          : this.selectedInstance;
        this.resource.LoggedInResourceID = +sessionStorage.getItem(
          'loggedin_resourceid'
        );
        // this.applicationInsightsService.logEvent('Individual View - getResourceList');
        this.resourceListSubscription = this.scheduledService
          .getEngagementResourceList(this.resource)
          .subscribe((data) => {
            this.sortedResourceList = this.orderPipe.transform(
              data,
              'ResourceName'
            );
            if (this.searchResourceBarCopy.length > 0) {
              this.resourceResultsCopy = this.sortedResourceList;
              this.showResourceLoaderCopy = false;
            } else {
              this.resourceResultsCopy = [];
              this.showResourceListCopy = false;
            }
          });
      } else {
        this.resourceResultsCopy = [];
        this.showResourceListCopy = false;
      }
    } else {
      this.resourceResultsCopy = [];
      this.showResourceListCopy = false;
    }
  }

  OnResourceSelect(event) {
    const instances = this.sharedService.getStoredUserInstances();
    const weeklyBooking = {};

    const param = {
      StartDate: this.range.value.start
        ? this.range.value.start
        : this.sharedService.defaultStartDate,
      EndDate: this.range.value.end
        ? this.range.value.end
        : this.sharedService.defaultEndDate,
      InstanceId: this.selectedInstance,
      ResourceId: event.ResourceId,
    };

    this.spinner.show();

    this.scheduledService.getUserUtilization(param).subscribe(
      (userUtilizationData) => {
        if (userUtilizationData && userUtilizationData.length > 0) {
          this.weeksTobeDisplayed.forEach((eachWeek, weekIndex) => {
            let dStr = eachWeek.split('.000Z')[0];
            dStr = splitAndJoinDate(dStr);
            // const weDate = new Date(
            //   new Date(dStr).setDate(new Date(dStr).getDate() + 7)
            // )
            //   .toISOString()
            //   .split('.000Z')[0];
            const weDate = moment(
              new Date(dStr).setDate(new Date(dStr).getDate() + 6)
            ).format('YYYY-MM-DDT00:00:00');
            weeklyBooking[dStr] = {};
            const currWeek = userUtilizationData.filter((item) => {
              return item.DiaryDate.split('T')[0] === eachWeek.split('T')[0];
            });
            this.tableCheckFilters.forEach((bookType) => {
              const uniqId =
                this.JobId +
                '_' +
                event.ResourceId +
                '_' +
                weekIndex +
                '_' +
                bookType.value;
              // if (bookType.shouldShow && bookType.shouldShow === true) {
              weeklyBooking[dStr][bookType.value] = {
                ...data_bookingCell,
                JobId: this.JobId,
                ResourceId: event.ResourceId,
                InstanceID: this.selectedInstance,
                BookingMode: bookType.value,
                WeekStartDate: dStr,
                StartDate: dStr,
                EndDate: weDate,
                RequestorEmail: this.user.Email,
                Action: '',
                CellAction: SCHEDULE_CONSTANTS.ACTION_CREATE,
                TempId: uniqId,
                IsNewBooking: true,
                DiaryHours:
                  currWeek && currWeek.length > 0 ? currWeek[0].DiaryHours : 0,
                WeekEndDate: weDate,
              };
              // }
            });
          });

          const userData = {
            ...data_resource,
            BookingType: this.selectedEngagement.JobType,
            EngagementName: this.selectedEngagement.JobDescription,
            GPN: event.GPN,
            InstanceID: this.selectedInstance,
            InstanceName: instances.filter(
              (item) => item.InstanceId === this.selectedInstance
            )[0].InstanceName,
            JobCategory: this.selectedEngagement.JobCategory,
            JobCode: this.selectedEngagement.JobCode,
            JobId: this.JobId,
            RankDescription: event.RankDescription,
            CostCenterDescription: event.CostCenterDescription,
            LocationDescription: event.LocationDescription,
            ResourceName: event.ResourceName,
            ResourceId: event.ResourceId,
            IsNewBooking: true,
            WeeklyBookings: weeklyBooking,
            IsDisableViewBooking_Res: true,
          };
          if (
            this.dataSource.filter(
              (item) => item.ResourceId === event.ResourceId
            ).length > 0
          ) {
            this.snackBar.open('User already exists in the table', '', {
              duration: SCHEDULE_CONSTANTS.SNACKBAR_TIMEOUT,
            });
          } else {
            this.dataSource = [userData, ...this.dataSource];
          }

          this.showResourceList = false;
        } else {
          this.snackBar.open('Unable to fetch user data', '', {
            duration: SCHEDULE_CONSTANTS.SNACKBAR_TIMEOUT,
          });
        }
        this.searchResourceBar = '';
      },
      (err) => {
        this.snackBar.open('Error occurred, please try again', '', {
          duration: SCHEDULE_CONSTANTS.SNACKBAR_TIMEOUT,
        });
        console.log(err);
      },
      () => {
        this.resetTableUI();
      }
    );
  }

  OnCopyResourceSelect(event, index) {
    this.spinner.show();
    let copyData = this.sharedService.getCopy(this.dataSource[index]);
    let currResWeeklyBookings = this.sharedService.getCopy(
      copyData.WeeklyBookings
    );
    copyData = {
      ...copyData,
      ResourceName: event.ResourceName,
      ResourceId: event.ResourceId,
      RankDescription: event.RankDescription,
      LocationDescription: event.LocationDescription,
      CostCenterDescription: event.CostCenterDescription,
      GPN: event.GPN,
      IsNewBooking: true,
    };
    const param = {
      StartDate: this.range.value.start
        ? this.range.value.start
        : this.sharedService.defaultStartDate,
      EndDate: this.range.value.end
        ? this.range.value.end
        : this.sharedService.defaultEndDate,
      InstanceId: this.selectedInstance,
      ResourceId: event.ResourceId,
    };

    this.scheduledService.getUserUtilization(param).subscribe(
      (userUtilizationData) => {
        if (userUtilizationData && userUtilizationData.length > 0) {
          this.weeksTobeDisplayed.forEach((week, weekIndex) => {
            this.tableCheckFilters.forEach((bookType) => {
              const uniqId =
                this.JobId +
                '_' +
                event.ResourceId +
                '_' +
                weekIndex +
                '_' +
                bookType.value;

              let dStr = week.split('.000Z')[0];
              dStr = splitAndJoinDate(dStr);
              const weDate = moment(
                new Date(dStr).setDate(new Date(dStr).getDate() + 6)
              ).format('YYYY-MM-DDT00:00:00');

              const shouldConsider =
                ((currResWeeklyBookings[week][bookType.value].BookingHours !=
                  null &&
                  currResWeeklyBookings[week][bookType.value].BookingHours !=
                    '') ||
                  currResWeeklyBookings[week][bookType.value]
                    .ActualBookingHours != '') &&
                currResWeeklyBookings[week][bookType.value].BookingId !==
                  null &&
                currResWeeklyBookings[week][bookType.value].BookingId !== 0 &&
                currResWeeklyBookings[week][bookType.value].BookingId !== '';

              const currWeek = userUtilizationData.filter((item) => {
                return item.DiaryDate.split('T')[0] === week.split('T')[0];
              });
              currResWeeklyBookings[week][bookType.value] = {
                ...currResWeeklyBookings[week][bookType.value],
                TempId: uniqId,
                WeekStartDate: dStr,
                StartDate: dStr,
                EndDate: weDate,
                WeekEndDate: weDate,
                DiaryHours:
                  currWeek && currWeek.length > 0 ? currWeek[0].DiaryHours : 0,
              };
              if (!shouldConsider) {
                currResWeeklyBookings[week][bookType.value].BookingHours = '';
                currResWeeklyBookings[week][bookType.value].StatusId = 0;
                currResWeeklyBookings[week][bookType.value].Action = 'E';
                currResWeeklyBookings[week][
                  bookType.value
                ].MeraActionDescriptionId = 0;
              } else {
                this.updateBookingHours(
                  currResWeeklyBookings[week][bookType.value],
                  currResWeeklyBookings[week][bookType.value].BookingHours
                );
                this.addEngagementDetails(
                  copyData,
                  currResWeeklyBookings[week][bookType.value],
                  SCHEDULE_CONSTANTS.ACTION_CREATE
                );
              }
            });
          });
        } else {
          this.snackBar.open('Could not find the user details, try again', '', {
            duration: SCHEDULE_CONSTANTS.SNACKBAR_TIMEOUT,
          });
        }
      },
      (err) => {
        this.snackBar.open('Error occurred, please try again', '', {
          duration: SCHEDULE_CONSTANTS.SNACKBAR_TIMEOUT,
        });
        console.log(err);
      },
      () => {
        copyData = {
          ...copyData,
          WeeklyBookings: currResWeeklyBookings,
        };
        // if (
        //   this.dataSource.filter((item) => item.ResourceId === event.ResourceId)
        //     .length > 0
        // ) {
        //   this.snackBar.open('User already exists in the table', '', {
        //     duration: SCHEDULE_CONSTANTS.SNACKBAR_TIMEOUT,
        //   });
        //   this.updatedCellDetails.clearCellDetails();
        // } else {
        // if BookingId=0 then make BookingHours = ''
        Object.keys(copyData.WeeklyBookings).forEach((week) => {
          Object.keys(copyData.WeeklyBookings[week]).forEach((bookType) => {
            if (
              copyData.WeeklyBookings[week][bookType].BookingId === 0 ||
              copyData.WeeklyBookings[week][bookType].BookingId === null ||
              copyData.WeeklyBookings[week][bookType].BookingId === ''
            ) {
              // copyData.WeeklyBookings[week][bookType].
              copyData.WeeklyBookings[week][bookType].ColorCode = 'transparent';
              copyData.WeeklyBookings[week][bookType].CellAction = '';
              copyData.WeeklyBookings[week][bookType].BookingHours = '';
            }
          });
        });

        this.dataSource.splice(index, 1);
        this.dataSource = [copyData, ...this.dataSource];
        // }
        this.showResourceListCopy = false;
        this.resetTableUI();
        this.isAnyChanges = false;
      }
    );
  }

  scrollWindowToTop(elemId) {
    const scrollWindow = document.getElementById('resourceWrapper');
    scrollWindow.scrollTo(0, 0);
    setTimeout(() => {
      const element = document.getElementById(elemId);
      element.focus();
    }, 100);
  }

  onBookingTypeToggle(event) {
    this.getTotalHours();
  }

  updateBookingHours(booking, event) {
    if (this.isPercentageSelected) {
      booking.Loading = event;
      booking.BookingHours = booking.DiaryHours
        ? parseFloat(((booking.Loading / 100) * booking.DiaryHours).toFixed(2))
        : booking.BookingHours &&
          (booking.BookingHours !== null || booking.BookingHours !== '')
        ? booking.BookingHours
        : 0;
    } else {
      booking.BookingHours = event;
      booking.Loading = booking.DiaryHours
        ? parseFloat(
            ((booking.BookingHours / booking.DiaryHours) * 100).toFixed(2)
          )
        : 0;
    }
  }

  // On focus out from each cell, the same should be handled
  addEngagementDetails(resource, booking, action?) {
    if (action === SCHEDULE_CONSTANTS.ACTION_DELETE) {
      // booking.MeraBookingId = 0;
      this.handleCancelCells(resource, booking);
      resource.IsNewBooking = false;
    } else if (
      booking.BookingHours === '' ||
      booking.BookingHours === null ||
      booking.Loading === '' ||
      booking.Loading === null
    ) {
      // Cell delete
      booking.Action = '';
      booking.BookingHours = '';
      booking.Loading = 0;
      if (booking.ActualBookingId > 0) {
        // Retain booking
        booking.StatusId = MERAStatusId.Submitted;
        booking.MeraBookingId = 1;
        booking.RequestorEmail = this.user.Email;
      } else {
        if (booking.BookingId == null) {
          // New booking, remove the added values from booking cells
          this.updatedCellDetails.deleteCellDetails(booking.tempId);
        }
        return false; // user just clicked on cell, not a new booking and delete
      }
      this.updatedCellDetails.setCellDetails(
        resource,
        booking,
        SCHEDULE_CONSTANTS.ACTION_DELETE
      );
    } else {
      this.removeFromcancelCellArray(booking);
      if (booking.BookingHours && booking.BookingHours > 0) {
        booking.MeraBookingId = 1;
        booking.StatusId = MERAStatusId.Submitted;
        booking.MeraActionDescriptionId = MeraActionDescription.Create;
        booking.RequestorEmail = this.user.Email?.toLowerCase();
        this.updatedCellDetails.setCellDetails(resource, booking, action);
      }
    }
    this.getTotalHours();
  }

  public checkIfAnyChanges() {
    const cellDetails = this.updatedCellDetails.fetchCurrentCellDetails();
    return this.cancelCellArray.length > 0 || cellDetails.length > 0;
  }

  public handleCancelCells(resource, booking) {
    // Cell cancel
    booking.Action = '';
    booking.BookingHours = '';
    booking.Loading = 0;
    booking.ColorCode = 'transparent';
    if (booking.MeraBookingId === 1 && booking.ActualBookingId === null) {
      // Delete created cell - Handled @ : 1179
    } else if (booking.ActualBookingId > 0) {
      // Retain booking
      booking.BookingHours = booking.BookingHoursOld;
      booking.Loading = booking.LoadingOld;
      booking.ColorCode = booking.ColorCodeOld;
    } else if (booking.MeraBookingId > 0 && booking.ActualBookingId === 0) {
      // Mera booking - Handled @ : 1179
    }

    this.addTocancelCellArray(booking);
    this.updatedCellDetails.deleteCellDetails(booking.TempId);

    // To add merge bookings to cancel array
    const currResource = this.engagementDetailsCopy.ScheduleBookingDto.filter(
      (item) => item.ResourceId === resource.ResourceId
    );
    if (currResource && currResource.length > 0) {
      const mergeBookings = currResource[0].WeeklyBookings.filter(
        (book) =>
          book.WeekStartDate === booking.WeekStartDate &&
          book.ActionMode === 'D' &&
          book.BookingMode === booking.BookingMode
      );
      mergeBookings.forEach((bookMerge) => {
        this.addTocancelCellArray(bookMerge);
        this.updatedCellDetails.deleteCellDetails(bookMerge.TempId);
      });
    }
  }

  public addTocancelCellArray = (booking) => {
    if (booking.MeraBookingId > 1) {
      this.cancelCellArray.push(booking.MeraBookingId);
    }
    booking.MeraBookingId = 0;
  };

  public removeFromcancelCellArray = (booking) => {
    this.cancelCellArray.splice(
      this.cancelCellArray.indexOf(booking.MeraBookingId),
      1
    );
  };

  shouldShowActioninfo(element) {
    if (
      element.IsDisabledEditBooking &&
      element.IsDisabledDeleteBooking_EV &&
      element.IsDisabledReplaceBooking_EV &&
      element.IsDisabledCopyBooking_EV
    ) {
      return true;
    } else if (
      element.BookingId != 0 &&
      // element.IsCurrentEditWeek == false &&
      element.IsDisabledEditBooking &&
      element.IsDisabledDeleteBooking_EV &&
      element.IsDisabledCopyBooking_EV &&
      element.IsDisabledReplaceBooking_EV &&
      !element.IsReplaceResource &&
      !element.IsReplaceEngagement
    ) {
      return true;
    } else if (
      element.BookingId != 0 &&
      // element.IsCurrentEditWeek == true &&
      element.IsDisabledEditBooking &&
      element.IsDisabledDeleteBooking_EV &&
      element.IsDisabledReplaceBooking_EV &&
      element.IsDisabledCopyBooking_EV &&
      !element.IsReplaceResource
    ) {
      return true;
    } else if (
      !this.user.R_InstEnable_AddBooking &&
      element.InstanceID != this.user.R_Home_InstanceID
    ) {
      return true;
    }

    return false;
  }

  getBorderColor(booking) {
    return this.scheduledService.getCellBorderColor(booking);
  }

  navigateScheduleViewIndividual(item) {
    if (item.IsDisabledViewBooking_Res) {
      return;
    }
    const product = {
      individual: item.ResourceName,
      instanceId: item.InstanceID,
      resourceId: item.ResourceId,
    };
    this.router.navigateByUrl('/IndividualScheduleView', { state: product });
  }

  handleSearchEngagement(state) {
    this.engagementSearch = new JobVM();
    this.engagementSearch.JobDescription = state.jobCode
      ? state.jobCode.trim()
      : null;
    this.engagementSearch.JobId = state.jobId;
    this.engagementSearch.LoggedInResourceId = +sessionStorage.getItem(
      'loggedin_resourceid'
    );
    this.engagementSearch.InstanceId = state.instanceId;
    this.engagementListSubscription = this.scheduledService
      .getJobList(this.engagementSearch)
      .subscribe(
        (data) => {
          if (data && data.length > 0) {
            // this.isCollapsed = true; //Commenting this line to keep the forecast section open by default as part of new CR
            this.selectedInstance = state.instanceId;
            this.OnItemSelect(data[0]);
          }
        },
        (err) => {
          this.snackBar.open('Unable to load the engagement at the time', '', {
            duration: SCHEDULE_CONSTANTS.SNACKBAR_TIMEOUT,
          });
        },
        () => {}
      );
  }

  getNotesClass = (description) => {
    return this.scheduledService.getNotesClass(description);
  };

  rowCancel(engagement, index) {
    const currentResource = this.engagementDetailsCopy.ScheduleBookingDto.find(
      (item) => item.ResourceId === this.dataSource[index].ResourceId
    );

    if (engagement.strFuction === SCHEDULE_CONSTANTS.ACTION_REPLACE) {
      this.CloseReplace(engagement, index);
    } else if (
      currentResource &&
      currentResource.WeeklyBookings[0].IsReplaceResource
    ) {
      // Revert the Repalce to back
      this.strsearchResourceReplace = '';
      engagement.rowReplaced = false;

      const actualData = currentResource.WeeklyBookings[0];
      const userData = {
        ...engagement,
        ResourceId: actualData.ResourceIdOld,
        ResourceName: actualData.ResourceNameOld,
      };

      currentResource.WeeklyBookings.forEach((booking) => {
        userData.WeeklyBookings[booking.WeekStartDate][
          booking.BookingMode
        ].MeraActionDescriptionId = MeraActionDescription.Create;

        userData.WeeklyBookings[booking.WeekStartDate][
          booking.BookingMode
        ].StatusId = MERAStatusId.Cancelled;

        userData.WeeklyBookings[booking.WeekStartDate][
          booking.BookingMode
        ].BookingHours = booking.BookingHours;

        this.addEngagementDetails(
          engagement,
          userData.WeeklyBookings[booking.WeekStartDate][booking.BookingMode],
          SCHEDULE_CONSTANTS.ACTION_DELETE
        );
      });
      let copyData = [...this.dataSource];
      copyData.splice(index, 1, userData);
      this.dataSource = [...copyData];
    } else {
      const itemIndex = this.dataSource.findIndex(
        (eng) => eng.ResourceId === engagement.ResourceId
      );

      if (itemIndex > -1) {
        // Delete if any cell data are added for submit
        Object.keys(this.dataSource[itemIndex].WeeklyBookings).forEach(
          (week) => {
            const currWeek = this.dataSource[itemIndex].WeeklyBookings[week];
            Object.keys(currWeek).forEach((type) => {
              const tempId = currWeek[type].TempId;
              this.updatedCellDetails.deleteCellDetails(tempId);
              if (engagement.IsNewBooking) {
                currWeek[type].MeraBookingId = 1;
                currWeek[type].ActualBookingId = null;
              }
              this.handleCancelCells(
                this.dataSource[itemIndex],
                currWeek[type]
              );
            });
          }
        );
        this.dataSource[itemIndex].IsNewBooking = false;
        this.dataSource = this.dataSource.filter(
          (rr) => rr.ResourceId !== engagement.ResourceId
        );
      }
    }
    this.resetResourceSearchCopy();
  }

  rowDelete(engagement) {
    const itemIndex = this.dataSource.findIndex(
      (eng) => eng.ResourceId === engagement.ResourceId
    );

    if (itemIndex > -1) {
      // Delete if any cell data are added for submit
      Object.keys(this.dataSource[itemIndex].WeeklyBookings).forEach((week) => {
        const currWeek = this.dataSource[itemIndex].WeeklyBookings[week];
        Object.keys(currWeek).forEach((type) => {
          if (
            currWeek[type].BookingHours !== '' &&
            currWeek[type].BookingHours !== null
          ) {
            // added code when BookingHoursOld is null and BookingHours not null and click on cancel that case we require BookingHoursOld
            if (
              currWeek[type].MeraActionDescriptionId ==
                MeraActionDescription.Create ||
              currWeek[type].MeraActionDescriptionId ==
                MeraActionDescription.Copy
            ) {
              if (!currWeek[type].BookingHoursOld) {
                currWeek[type].BookingHoursOld = currWeek[type].BookingHours;
              }
            }
            currWeek[type].BookingHours = '';
            currWeek[type].Action = 'Edit';
            currWeek[type].Loading = 0;
            currWeek[type].MeraBookingId = 1;
            currWeek[type].ColorCode = 'transparent';
            currWeek[type].StatusId = MERAStatusId.Submitted;
            currWeek[type].RequestorEmail = this.user.Email;
            this.addEngagementDetails(
              this.dataSource[itemIndex],
              currWeek[type]
            );
          }
        });
      });
      this.dataSource[itemIndex].IsNewBooking = true;
    }
  }

  resetTableHeaders() {
    this.stickyColumns = data_stickyColumns;
    this.tableCheckFilters = this.sharedService.getCopy(data_tableCheckFilters);
    this.stickyHeaderColumns = this.sharedService.getCopy(
      data_stickyHeaderColumns
    );
    this.stickyAddColumns = this.sharedService.getCopy(data_stickyAddColumns);
    this.bookingType = this.sharedService.getCopy(data_bookingType);
    this.addDisplayedColumns = this.sharedService.getCopy(
      data_addDisplayedColumns
    );
    this.displayedColumns = this.sharedService.getCopy(data_displayedColumns);
    this.engHourCalculation = this.scheduledService.getHourModeSEV();
    this.tableCheckFilters.map((checkFilter) => {
      if (
        checkFilter.value === 'Confirmed'
        // && checkFilter.shouldShow === true
      ) {
        checkFilter.isChecked =
          this.engHourCalculation === null
            ? true
            : this.engHourCalculation.isConfirmedChecked;
      } else if (checkFilter.value === 'Unconfirmed') {
        checkFilter.isChecked =
          this.engHourCalculation === null
            ? true
            : this.engHourCalculation.isUnconfirmedChecked;
      } else if (checkFilter.value === 'Ghost') {
        checkFilter.isChecked =
          this.engHourCalculation === null
            ? true
            : this.engHourCalculation.isGhostChecked;
      }
    });
    this.displayTotalHourHeader = this.sharedService.getCopy(
      data_displayTotalHourHeader
    );
    this.totalHoursColumns = this.sharedService.getCopy(data_totalHours);
  }

  checkIfRowCancelAvailableForNew(resource) {
    const meraActionDescArr = [
      MeraActionDescription.Create,
      MeraActionDescription.Delete,
      MeraActionDescription.Replace,
      MeraActionDescription.ReplaceAll,
    ];

    const statusIdArr = [MERAStatusId.Submitted, MERAStatusId.Pending_Approval];

    const currentResource = this.dataSource
      ? this.dataSource.filter(
          (res) => res.ResourceId === resource.ResourceId
        )[0]
      : {};

    let isRowCancelAvailable = true;

    const currentActualBooking =
      this.engagementDetailsCopy &&
      this.engagementDetailsCopy.ScheduleBookingDto &&
      this.engagementDetailsCopy.ScheduleBookingDto.filter(
        (res) => res.ResourceId === resource.ResourceId
      )[0];
    if (
      currentActualBooking &&
      currentActualBooking.WeeklyBookings &&
      currentActualBooking.WeeklyBookings.filter(
        (item) => item.ActionMode === 'E'
      ).length === 0
    ) {
      isRowCancelAvailable = false;
    } else {
      Object.keys(currentResource.WeeklyBookings).forEach((week) => {
        const currentWeek = currentResource.WeeklyBookings[week];
        Object.keys(currentWeek).forEach((type) => {
          const currentBooking = currentWeek[type];
          if (
            currentBooking.BookingHours !== null &&
            currentBooking.BookingHours !== ''
          ) {
            if (
              meraActionDescArr.indexOf(
                currentBooking.MeraActionDescriptionId
              ) === -1 ||
              statusIdArr.indexOf(currentBooking.StatusId) === -1
            )
              isRowCancelAvailable = false;
          }
        });
      });
    }
    return resource.IsNewBooking || isRowCancelAvailable;
  }

  getTableHeaderNames(col) {
    if (
      col !== 'Actions' &&
      col !== 'Resource Name' &&
      col !== 'Rank' &&
      col !== 'Retain Instance' &&
      this.isCurrentWeekDisabled &&
      this.datepipe.transform(
        this.currentWeekStartDate,
        this.defaultDateFormat
      ) === col
    ) {
      return true;
    } else {
      return false;
    }
  }

  openDeleteConfirm(element, index) {
    let IsActionExist = this.dataSource.some((dd) => dd.ResourceId === null);
    if (!IsActionExist) {
      element.IsReplace = false;
      this.resourceToBeDeleted = index;
      this.modelData = {
        ...data_ModelData,
        header: 'Delete Bookings',
        text: `Are you sure you want to delete all the bookings of ${element.ResourceName}`,
        isConfirmRequired: true,
      };
      this.modalService.show(this.userConfirmation, modalOptions);
    }
  }

  modalConfirm() {
    this.isRowDeleted = true;
    const resourceDelete = this.dataSource[this.resourceToBeDeleted];
    this.rowDelete(resourceDelete);
    this.modalService.hide();
  }

  modalCancel() {
    this.isRowDeleted = false;
    this.resourceToBeDeleted = null;
    this.modalService.hide();
  }

  copyResource(element) {
    let IsActionExist = this.dataSource.some((dd) => dd.ResourceId === null);
    if (!IsActionExist) {
      element.IsReplace = false;
      element.strFuction = SCHEDULE_CONSTANTS.ACTION_COPY;
      const userData = {
        ...this.sharedService.getCopy(element),
        GPN: '',
        ResourceName: '',
        ResourceId: null,
        RankDescription: '',
        LocationDescription: '',
        CostCenterDescription: '',
        CostCenterWithOutCountryCode: '',
        CountryAndLocation: '',
        isCopy: true,
      };
      Object.keys(userData.WeeklyBookings).forEach((week) => {
        Object.keys(userData.WeeklyBookings[week]).forEach((bookType) => {
          const currType = this.tableCheckFilters.filter(
            (item) => item.value === bookType
          )[0];
          let shouldConsider =
            userData.WeeklyBookings[week][bookType].BookingHours != null ||
            userData.WeeklyBookings[week][bookType].ActualBookingHours != '';

          let hours = userData.WeeklyBookings[week][bookType].BookingHours;
          if (userData.WeeklyBookings[week][bookType].ActualBookingId > 0) {
            hours = userData.WeeklyBookings[week][bookType].BookingHoursOld;
          }

          if (this.isCurrentWeekDisabled) {
            if (
              this.datepipe.transform(
                this.currentWeekStartDate,
                this.defaultDateFormat
              ) === this.datepipe.transform(week, this.defaultDateFormat)
            ) {
              hours = '';
              shouldConsider = false;
            }
          }
          userData.WeeklyBookings[week][bookType] = {
            ...data_bookingCell,
            ...userData.WeeklyBookings[week][bookType],
            BookingHours: hours,
            ActualBookingHours:
              hours && hours > 0
                ? userData.WeeklyBookings[week][bookType].ActualBookingHours
                : '',
            ColorCode:
              hours && hours > 0
                ? userData.WeeklyBookings[week][bookType].ColorCode
                : null,
            MeraActionDescriptionId: MeraActionDescription.Create,
            MeraBookingId: shouldConsider ? 1 : 0,
            StatusId: shouldConsider ? MERAStatusId.Submitted : 0,
            Action: shouldConsider ? 'Edit' : '',
            RequestorEmail: this.user.Email,
            ...data_IsDisabledBits,
            DiaryHours: 0,
          };
        });
      });

      this.dataSource = [userData, ...this.dataSource];
      this.resetTableUI();
      this.scrollWindowToTop('copyResourceInput');
    }
    this.isAnyChanges = true;
  }

  checkIfEditOrAddEnabledOnCell(booking, bookingData) {
    const bookWeek = booking.key;
    const isCurrWeekDisabled = this.getTableHeaderNames(
      this.datepipe.transform(bookWeek, this.defaultDateFormat)
    );
    if (
      bookingData.BookingId === null &&
      bookingData.ActualBookingId === null &&
      bookingData.ParentBookingID === null
    ) {
      if (this.selectedEngagement.IsDisabledAddBooking) {
        return false;
      } else {
        if (
          bookingData.IsDisabledAddResource_Res &&
          bookingData.IsDisabledAddEngagement_EV_Job
        ) {
          return true;
        }
      }
    }
    return (
      (!bookingData.IsDisabledEditBooking_EV ||
        !bookingData.IsDisabledDeleteBooking_EV) &&
      !isCurrWeekDisabled
    );
  }

  toggleInputState(resource, booking) {
    Object.keys(booking).forEach((bookWeek) => {
      let currentWeek = booking[bookWeek];
      Object.keys(currentWeek).forEach((type) => {
        let currentBooking = currentWeek[type];
        if (
          currentBooking.BookingId === null &&
          currentBooking.ActualBookingId === null &&
          currentBooking.ParentBookingID === null
        ) {
          if (
            !resource.IsDisabledAddResource_Res &&
            !resource.IsDisabledAddEngagement_EV_Job
          ) {
            currentBooking.IsEdit = true;
          }
        } else {
          if (
            !currentBooking.IsDisabledDeleteBooking_EV ||
            !currentBooking.IsDisabledEditBooking_EV
          ) {
            if (
              (resource.isCopy && currentBooking.IsDisabledEditBooking_EV) ||
              (resource.rowReplaced &&
                currentBooking.IsDisabledReplaceBooking_EV) ||
              (resource.rowReplaced &&
                currentBooking.IsDisabledReplaceAllBooking_EV)
            ) {
              currentBooking.IsEdit = false;
            }
            if (
              !(
                this.isCurrentWeekDisabled &&
                this.datepipe.transform(
                  this.currentWeekStartDate,
                  this.defaultDateFormat
                ) ===
                  this.datepipe.transform(
                    currentBooking.WeekStartDate,
                    this.defaultDateFormat
                  )
              )
            )
              currentBooking.IsEdit = true;
          } else {
            currentBooking.IsEdit = false;
          }
        }
      });
    });
  }

  openActionModal(template, event, dataItem, templateName) {
    this.showModal = false;
    this.showReplaceAllEng = false;
    this.gridActionEventData.dataItem = dataItem;
    this.modalOption.class = '';
    this.gridActionEventData.event = event;
    this.gridActionEventData.template = template;
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    if (templateName === 'replaceallEng') {
      this.gridActionModalHeader = 'Replace All Engagement';
      this.showReplaceAllEng = true;
      this.engagementService.openReplaceAllEng(this.gridActionEventData);
      this.engagementSubmission.JobId = this.JobId;
      this.engagementService.openPreviewModal(this.engagementSubmission);
    }
    this.modalRef = this.modalService.show(template, this.modalOption);
    this.modalRef.setClass('ea-modal');
  }

  openConfirmationModal(template) {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    if (this.modalRef !== undefined && this.modalRef !== null) {
      this.modalRef.hide();
    }
  }

  closeActionModal(closeModal) {
    if (closeModal) {
      if (this.modalRef !== undefined && this.modalRef !== null) {
        this.modalRef.hide();
        this.getEngagement();
      }
    } else {
      if (this.modalRef !== undefined && this.modalRef !== null) {
        this.modalRef.hide();
      }
    }
  }

  chkifBookingAvailable(booking, type): boolean {
    let shouldshow = false;
    if (booking[type] && Object.keys(booking[type]).length > 0) {
      if (
        booking[type].InstanceID == this.user.R_Home_InstanceID &&
        booking[type].IsEdit
      ) {
        shouldshow = true;
      }
    }
    return shouldshow;
  }

  checkIfBookingAvailable(booking, type, element): boolean {
    let shouldshow = false;

    let IsActionExist = this.dataSource.some((dd) => dd.ResourceId === null);
    if (!IsActionExist) {
      if (booking[type] && Object.keys(booking[type]).length > 0) {
        if (!this.user.R_InstEnable_AddBooking) {
          if (
            booking[type].InstanceID == this.addBookingInstance &&
            booking[type].IsEdit
          )
            shouldshow = true;
        } else {
          if (booking[type].IsEdit) {
            shouldshow = true;
          }
        }
      }
    }
    return shouldshow;
  }

  // added for faviourite Engagement serching functionality

  onEngagementClick() {
    this.showSearchList = false;
    this.isVerifyOnEngClick = true;
    this.showFavouriteEngList = true;

    if (this.searchBar != null) {
      if (this.searchBar.trim().length >= 2) {
        this.showFavouriteEngList = false;
        this.isFavEngONClick = false;
      } else {
        this.showFavouriteEngList = true;
        this.isFavEngONClick = true;
      }
    }
    //if (this.showSearchList == false) {
    if (this.isFavEngONClick) {
      this.getFavouriteEngagement();
    }
  }

  private getFavouriteEngagement(): void {
    let favouriteJobs = new FavouriteJobsDto();
    favouriteJobs.InstanceId = this.selectedInstance;
    favouriteJobs.IsSearchfromIndivBooking = true;
    favouriteJobs.JobCategory = '';
    this.showSearchLoader = true;

    this.scheduledService
      .GetFavouriteJobsEngagementView(favouriteJobs)
      .subscribe((data) => {
        const key = 'JobId';

        const resultEng = [
          ...new Map(data.map((item) => [item[key], item])).values(),
        ];

        this.addFavouriteEngResults = resultEng;
        this.showSearchLoader = false;
        this.isFavEngONClick = false;
        if (this.addFavouriteEngResults.length > 0) {
          this.showSearchLoader = false;
          this.addFavouriteEngResults.forEach((i) => {
            i.IsFavEngInserted = true;
            this.IsDataNotFound = false;
            i.favEngSrc = favEngImageSrc.favEngImgStarfilled;
          });
        } else {
          this.IsDataNotFound = true;
          this.showSearchLoader = false;
          this.lbl = 'Favourite Engagements not added.';
        }
      });
  }

  private AddFavouriteEngagement(arr): void {
    this.scheduledService.addFavouriteJobs(arr).subscribe((data) => {
      this.showSearchList = false;
      this.showFavouriteEngList = false;
      this.isFavEngONClick = true;
    });
  }

  onFavoriteImageClick(item) {
    this.FavoriteImageClick = true;
    item.IsOldFavEngInserted = item.IsFavEngInserted;
    item.IsFavEngInserted = !item.IsFavEngInserted;

    if (item.IsFavEngInserted) {
      item.favEngSrc = favEngImageSrc.favEngImgStarfilled;
    } else {
      item.favEngSrc = favEngImageSrc.favEngImgStarOutline;
    }
  }

  onFavEngagementSearch(event, e): void {
    if (this.FavoriteImageClick === true) {
      this.FavoriteImageClick = false;
      (event as Event).stopPropagation();
      // e.preventDefault();
      // e.stopPropagation();
    }
    if (event.EngagementFullName != null) {
      this.showFavouriteEngList = true;
      this.showSearchLoader = true;
      // this.spinner.show();
      this.engagementSearch = new JobVM();
      this.engagementSearch.JobDescription = event.EngagementFullName.trim();
      this.engagementSearch.JobId = event.JobId;
      this.engagementSearch.LoggedInResourceId = +sessionStorage.getItem(
        'loggedin_resourceid'
      );
      this.engagementSearch.InstanceId = this.selectedInstance;
      this.engagementListSubscription = this.scheduledService
        .getJobList(this.engagementSearch)
        .subscribe((data) => {
          this.sortedJobList = this.orderPipe.transform(data, 'JobDescription');

          //this.results = this.sortedJobList;
          this.results = this.sortedJobList.filter(
            (yy) => yy.JobId === event.JobId
          );
          this.showSearchLoader = false;

          if (this.results) {
            this.getOnItemforFavEngagement(this.results);
            // this.spinner.hide();
          }
        });
    } else {
      this.results = [];
      this.showSearchList = false;
    }
  }

  private getOnItemforFavEngagement(event): void {
    if (this.showFavouriteEngList) {
      this.showSearchLoader = true;
      this.showFavouriteEngList = false;
      this.searchBar = event[0].JobDescription;
      this.selectedEngagement = event[0];

      this.JobId = event[0].JobId;
      this.isCurrentWeekDisabled = event[0].IsDisabledAddBookingCurrWeek;
      this.engagementSelected = !event[0].IsDisabledAddBooking;
      this.engagementService.setSelectedJob(this.JobId);
      const params = {
        StartDate: this.sharedService.defaultStartDate,
        EndDate: this.sharedService.defaultEndDate,
      };
      this.getEngagement(params);
      this.showSearchLoader = false;
    }
  }

  private favoriteimgClickHandler(event: any) {
    if (!!event.target.src) {
      var parts = event.target.src.split('/');
      var img = parts[parts.length - 1];

      var a = favEngImageSrc.favEngImgStarOutline.split('/');
      var ImgStarOutline = a[a.length - 1];

      var b = favEngImageSrc.favEngImgStarfilled.split('/');
      var ImgStarfilled = b[b.length - 1];

      if (img === ImgStarOutline || img === ImgStarfilled) {
        this.isClickonFavEngStart = true;
        this.outClickCount = 0;
      } else {
        this.isClickonFavEngStart = false;
        if (this.outClickCount == 0) {
          this.outClickCount++;
        }
      }
    } else {
      if (this.isClickonFavEngStart) {
        if (this.outClickCount == 0) {
          this.outClickCount++;
          this.getAllSelectedfavEngagement();
        }
      }
    }
  }

  private getAllSelectedfavEngagement() {
    let typeArr: Array<FavouriteJobsTableTypeDto>;
    typeArr = [];
    let dataSE;
    if (this.outClickCount > 0) {
      this.outClickCount = 0;
      this.isClickonFavEngStart = false;
      this.compareFavouriteEngResults = [];
      if (!this.isVerifyOnEngClick) {
        dataSE = this.sortedEngagementList.filter(
          (ap) =>
            ap.IsOldFavEngInserted === true || ap.IsOldFavEngInserted === false
        );
      } else {
        dataSE = this.addFavouriteEngResults.filter(
          (ap) =>
            ap.IsOldFavEngInserted === true || ap.IsOldFavEngInserted === false
        );
      }

      if (dataSE.length > 0) {
        dataSE.forEach((data) => {
          if (
            data.IsOldFavEngInserted === true ||
            data.IsOldFavEngInserted === false
          ) {
            this.compareFavouriteEngResults.push(data);
          }
        });
      }

      if (
        this.addFavouriteEngResults.length > 0 ||
        !!this.addFavouriteEngResults
      ) {
        let arr = [];
        this.compareFavouriteEngResults.forEach((op) => {
          if (!op.IsFavEngInserted) {
            if (this.addFavouriteEngResults.some((g) => g.JobId === op.JobId)) {
              op.InstanceId = this.selectedInstance;
              op.JobCategory = '';
              arr.push(op);
            }
          } else {
            op.InstanceId = this.selectedInstance;
            op.JobCategory = '';
            arr.push(op);
          }
        });
        if (arr.length > 0) {
          arr.forEach((tt) => {
            let obj = new FavouriteJobsTableTypeDto();
            obj.LoggedInUserId = this.user.UserId;
            obj.JobID = tt.JobId;
            obj.Instance_Id = tt.InstanceId;
            obj.IsFavEngInserted = tt.IsFavEngInserted;
            typeArr.push(obj);
          });
          let k = new FavouriteJobsAddActionDto();
          k.FavouriteJobs = typeArr;

          this.AddFavouriteEngagement(k);
        }
      }
    }
  }

  // Replace Functionality
  searchReplaceResource(element) {
    element.showReplaceResList = true;
    this.sortedResourceReplaceList = [];
    if (this.strsearchResourceReplace != null) {
      if (this.strsearchResourceReplace.trim().length >= 2) {
        this.showReplaceResLoader = true;
        this.showResourceListReplace = true;
        this.showResourceLoaderReplace = true;
        this.resource.ResourceName = this.strsearchResourceReplace;
        this.resource.ResourceId = +sessionStorage.getItem('resourceId');
        this.resource.InstanceId = !this.user.R_InstEnable_AddBooking
          ? this.user.R_Home_InstanceID
          : this.selectedInstance;
        this.resource.LoggedInResourceID = +sessionStorage.getItem(
          'loggedin_resourceid'
        );
        // this.applicationInsightsService.logEvent('Individual View - getResourceList');
        this.resourceListSubscription = this.scheduledService
          .getEngagementResourceList(this.resource)
          .subscribe((data) => {
            this.sortedResourceReplaceList = this.orderPipe.transform(
              data,
              'ResourceName'
            );
            if (this.sortedResourceReplaceList.length > 0) {
              this.resourceResultsReplace = this.sortedResourceReplaceList;
              this.showResourceLoaderReplace = false;
            } else {
              this.resourceResultsReplace = [];
              this.showResourceListReplace = false;
            }
            this.showReplaceResLoader = false;
          });
      } else {
        this.resourceResultsReplace = [];
        this.showResourceListReplace = false;
      }
    } else {
      this.resourceResultsReplace = [];
      this.showResourceListReplace = false;
    }
  }

  OnReplaceResourceSelect(event, index, element) {
    this.spinner.show();
    element.showReplaceResList = false;
    // element.rowReplaced = false;
    this.strsearchResourceReplace = event.ResourceName;
    let replaceData = this.sharedService.getCopy(this.dataSource[index]);
    this.updatedCellDetails.saveReplacedResource(
      this.scheduleEngData.ScheduleBookingDto[index]
    );

    let currResWeeklyBookings = this.sharedService.getCopy(
      replaceData.WeeklyBookings
    );
    replaceData = {
      ...replaceData,
      ResourceName: event.ResourceName,
      ResourceId: event.ResourceId,
      RankDescription: event.RankDescription,
      LocationDescription: event.LocationDescription,
      CostCenterDescription: event.CostCenterDescription,
      GPN: event.GPN,
      IsNewBooking: false,
    };
    const param = {
      StartDate: this.range.value.start
        ? this.range.value.start
        : this.sharedService.defaultStartDate,
      EndDate: this.range.value.end
        ? this.range.value.end
        : this.sharedService.defaultEndDate,
      InstanceId: this.selectedInstance,
      ResourceId: event.ResourceId,
    };

    this.scheduledService.getUserUtilization(param).subscribe(
      (userUtilizationData) => {
        if (userUtilizationData && userUtilizationData.length > 0) {
          this.weeksTobeDisplayed.forEach((week, weekIndex) => {
            this.tableCheckFilters.forEach((bookType) => {
              const uniqId =
                this.JobId +
                '_' +
                event.ResourceId +
                '_' +
                weekIndex +
                '_' +
                bookType.value;

              let dStr = week.split('.000Z')[0];
              dStr = splitAndJoinDate(dStr);

              const weDate = moment(
                new Date(dStr).setDate(new Date(dStr).getDate() + 6)
              ).format('YYYY-MM-DDT00:00:00');

              const shouldConsider =
                currResWeeklyBookings[week][bookType.value].BookingHours !=
                  null ||
                currResWeeklyBookings[week][bookType.value]
                  .ActualBookingHours != '';

              const currWeek = userUtilizationData.filter((item) => {
                return item.DiaryDate.split('T')[0] === week.split('T')[0];
              });
              currResWeeklyBookings[week][bookType.value] = {
                ...currResWeeklyBookings[week][bookType.value],
                TempId: uniqId,
                ColorCode: shouldConsider ? '#c4c4cd' : 'transparent',
                WeekStartDate: dStr,
                StartDate: dStr,
                EndDate: weDate,
                WeekEndDate: weDate,
                ResourceId: event.ResourceId,
                DiaryHours:
                  currWeek && currWeek.length > 0 ? currWeek[0].DiaryHours : 0,
              };
              if (shouldConsider) {
                this.updateBookingHours(
                  currResWeeklyBookings[week][bookType.value],
                  currResWeeklyBookings[week][bookType.value].BookingHours
                );
                this.addEngagementDetails(
                  replaceData,
                  currResWeeklyBookings[week][bookType.value],
                  SCHEDULE_CONSTANTS.ACTION_REPLACE
                );
              }
            });
          });
        } else {
          this.snackBar.open('Could not find the user details, try again', '', {
            duration: SCHEDULE_CONSTANTS.SNACKBAR_TIMEOUT,
          });
        }
      },
      (err) => {
        this.snackBar.open('Error occurred, please try again', '', {
          duration: SCHEDULE_CONSTANTS.SNACKBAR_TIMEOUT,
        });
        console.log(err);
      },
      () => {
        replaceData = {
          ...replaceData,
          WeeklyBookings: currResWeeklyBookings,
        };
        Object.keys(replaceData.WeeklyBookings).forEach((week) => {
          Object.keys(replaceData.WeeklyBookings[week]).forEach((bookType) => {
            if (
              replaceData.WeeklyBookings[week][bookType].BookingId === 0 ||
              replaceData.WeeklyBookings[week][bookType].BookingId === null ||
              replaceData.WeeklyBookings[week][bookType].BookingId === ''
            ) {
              replaceData.WeeklyBookings[week][bookType].ColorCode =
                'transparent';
              replaceData.WeeklyBookings[week][bookType].CellAction = '';
              replaceData.WeeklyBookings[week][bookType].BookingHours = '';
              replaceData.WeeklyBookings[week][bookType].MeraBookingId = 0;
            }
          });
        });

        // if week blocked
        Object.keys(replaceData.WeeklyBookings).forEach((week) => {
          Object.keys(replaceData.WeeklyBookings[week]).forEach((bookType) => {
            let jsonArrayObject = [];

            jsonArrayObject.push({
              key: week,
              value: element.WeeklyBookings[week],
            });
            let isCellEnabled = this.checkIfEditOrAddEnabledOnCell(
              jsonArrayObject[0],
              replaceData.WeeklyBookings[week][bookType]
            );

            if (!isCellEnabled) {
              replaceData.WeeklyBookings[week][bookType].BookingId = null;
              //replaceData.WeeklyBookings[week][bookType].ColorCode = 'orange';
              replaceData.WeeklyBookings[week][bookType].ColorCode =
                'transparent';
              replaceData.WeeklyBookings[week][bookType].CellAction = '';
              replaceData.WeeklyBookings[week][bookType].BookingHours = '';
              replaceData.WeeklyBookings[week][bookType].MeraBookingId = 0;
              // Still Booking present in retain
              replaceData.WeeklyBookings[week][bookType].ActualBookingId = 0;

              // v1
              this.addEngagementDetails(
                element,
                replaceData.WeeklyBookings[week][bookType],
                SCHEDULE_CONSTANTS.ACTION_DELETE
              );
            }
          });
        });

        let replaceResourceDetails = [...this.dataSource];
        replaceResourceDetails.splice(index, 1, replaceData);
        this.dataSource = [...replaceResourceDetails];
        this.spinner.hide();
        this.resetTableUI();
        this.isAnyChanges = false;
        //console.log(this.dataSource);
      }
    );
  }

  replaceResource(element, index) {
    this.strsearchResourceReplace = '';

    let IsReplaceExist = this.dataSource.some((dd) => dd.ResourceId === null);
    if (IsReplaceExist) {
      element.rowReplaced = false;
    } else {
      element.strFuction = SCHEDULE_CONSTANTS.ACTION_REPLACE;
      this.dataSource.forEach((hh) => {
        hh.rowReplaced = false;
      });
      element.rowReplaced = true;

      if (!!element.ResourceId) {
        element.OldResourceId = element.ResourceId;
      }

      const userData = {
        ...this.sharedService.getCopy(element),
        GPN: '',
        ResourceName: '',
        ResourceId: null,
        RankDescription: '',
        LocationDescription: '',
        CostCenterDescription: '',
        CostCenterWithOutCountryCode: '',
        CountryAndLocation: '',
      };

      Object.keys(userData.WeeklyBookings).forEach((week) => {
        Object.keys(userData.WeeklyBookings[week]).forEach((bookType) => {
          const shouldConsider =
            userData.WeeklyBookings[week][bookType].BookingHours != null ||
            userData.WeeklyBookings[week][bookType].ActualBookingHours != '';
          let hours = userData.WeeklyBookings[week][bookType].BookingHours;
          if (userData.WeeklyBookings[week][bookType].ActualBookingId > 0) {
            hours = userData.WeeklyBookings[week][bookType].BookingHoursOld;
          }

          data_bookingCell.BookingId = element.WeeklyBookings[week][bookType]
            .BookingId
            ? element.WeeklyBookings[week][bookType].BookingId
            : 0;
          data_bookingCell.ActualBookingId = element.WeeklyBookings[week][
            bookType
          ].ActualBookingId
            ? element.WeeklyBookings[week][bookType].ActualBookingId
            : 0;
          data_bookingCell.ParentBookingID = element.WeeklyBookings[week][
            bookType
          ].ParentBookingID
            ? element.WeeklyBookings[week][bookType].ParentBookingID
            : 0;

          userData.WeeklyBookings[week][bookType] = {
            ...data_bookingCell,
            ...userData.WeeklyBookings[week][bookType],
            BookingHours: hours,
            MeraActionDescriptionId:
              userData.WeeklyBookings[week][bookType].BookingHours !== null &&
              userData.WeeklyBookings[week][bookType].BookingHours !== ''
                ? MeraActionDescription.Replace
                : MeraActionDescription.Create,
            MeraBookingId: shouldConsider ? 1 : 0,
            StatusId: shouldConsider ? MERAStatusId.Submitted : 1,
            Action: shouldConsider ? 'Replace' : '',
            RequestorEmail: this.user.Email,
            ...data_IsDisabledBits,
            DiaryHours: 0,
          };
        });
      });

      let copyData = [...this.dataSource];
      // copyData.splice(index, 0, userData);
      copyData.splice(index, 1, userData);
      this.dataSource = [...copyData];

      this.setIsReplace(element, index);

      this.dataSource = this.dataSource.filter(
        (k) =>
          k.ResourceId !== element.ResourceId ||
          (k.ResourceId === null && k.ResourceName === '')
      );

      this.resetTableUI();
    }
    this.isAnyChanges = true;
  }

  private setIsReplace(element, index) {
    this.beforeReplace[index].IsReplace = true;
    this.dataSource[index].IsReplace = true;
  }

  CloseReplace(element, index) {
    this.strsearchResourceReplace = '';
    this.cancelReplace(element, index);
    element.rowReplaced = false;
  }

  private cancelReplace(element, index) {
    let dataCurrent = this.dataSource.find(
      (op) => op.ResourceId === element.ResourceId
    );

    let dataR = this.beforeReplace.find(
      (op) => op.ResourceId === element.OldResourceId
    );

    this.dataSource.splice(index, 1, dataR);

    this.dataSource = this.dataSource.filter((t) => t);
    this.dataSource.forEach((hh) => {
      hh.rowReplaced = false;
    });

    const cellDetails = this.updatedCellDetails.fetchCurrentCellDetails();

    Object.keys(dataCurrent.WeeklyBookings).forEach((week) => {
      Object.keys(dataCurrent.WeeklyBookings[week]).forEach((type) => {
        const currentBooking = dataCurrent.WeeklyBookings[week][type];
        if (cellDetails.some((item) => item.TempId === currentBooking.TempId))
          this.updatedCellDetails.deleteCellDetails(currentBooking.TempId);

        if (currentBooking.MeraBookingId && currentBooking.MeraBookingId > 0) {
          this.removeFromcancelCellArray(currentBooking);
        }
      });
    });
  }

  cancelRowReplace(element, index) {
    element.IsDisabledCopyBooking = element.OldIsDisabledCopyBooking;
    element.IsDisabledDeleteBooking = element.OldIsDisabledDeleteBooking;
    element.IsDisabledReplaceBooking = element.OldIsDisabledReplaceBooking;
    element.IsReplace = false;
    element.rowReplaced = false;
    element.Replaced = false;
    this.weeksTobeDisplayed.forEach((week) => {
      element[
        this.datepipe.transform(week, this.defaultDateFormat)
      ].Confirmed.forEach((item) => {
        this.cancelReplaceCell(item, element);
      });
      element[
        this.datepipe.transform(week, this.defaultDateFormat)
      ].Unconfirmed.forEach((item) => {
        this.cancelReplaceCell(item, element);
      });
      element[
        this.datepipe.transform(week, this.defaultDateFormat)
      ].Ghost.forEach((item) => {
        this.cancelReplaceCell(item, element);
      });
    });
    if (element.toBeRemoved) {
    }
  }

  cancelReplaceCell(item, element) {
    if (item.ActionMode === 'E') {
      if (
        item.MeraBookingId > 1 &&
        item.RequestorEmail.toLowerCase() === this.user.Email.toLowerCase()
      ) {
        if (item.isCancelRequested === undefined) {
          //   this.cancelRequestArray.push(item.MeraBookingId);
          item.isCancelRequested = true;
        } else {
          item.isCancelRequested = false;
        }
      }
      item.revertAction === true;
      item.StatusId = 0;
      item.MeraBookingId = 0;
      item.MeraActionDescriptionId = MeraActionDescription.Create;
      item.ResourceId = item.ResourceIdOld;
      item.JobId = item.JobIdOld;
      item.BookingType = item.BookingTypeOld;
      item.IsDisabledCopyBooking = item.oldIsDisabledCopyBooking;
      item.IsDisabledDeleteBooking = item.oldIsDisabledDeleteBooking;
      item.IsDisabledReplaceBooking = item.oldIsDisabledReplaceBooking;
      item.IsDisabledEditBooking = item.oldIsDisabledEditBooking;
      if (item.IsReplaceResource && item.ResourceIdNew !== item.ResourceIdOld) {
        element.toBeRemoved = true;
      }
      if (item.IsReplaceEngagement) {
        element.EngagementName = item.EngagementNameOld;
        element.JobId = item.JobIdOld;
      }

      if (item.oldIsDisabledReplaceBooking) {
        if (item.BookingHoursOld !== null) {
          item.BookingHours = item.beforeEditHours;
          item.Loading = item.beforeEditPercentage;
          item.Description = item.DescriptionOld;
        }
      }
    }
  }
}
