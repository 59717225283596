<ngx-spinner></ngx-spinner>
<div class="arc-container">
  <form [formGroup]="formGroup">
    <div class="mt-3 px-4 grey-bg">
      <div class="row py-3" *ngIf="SaveErrorMessage != ''">
        <div style="float: right; margin-right: 5px">
          <div class="alert alert-danger" style="padding: 5px; margin: 0px">
            <button
              type="button"
              class="msg close"
              (click)="SaveErrorMessage = ''"
            >
              &times;
            </button>
            <strong>Failed: &nbsp;</strong>{{ SaveErrorMessage }}
          </div>
        </div>
      </div>
      <div class="row py-3">
        <div class="col-lg-5 col-md-5 col-sm-5 pr-0">
          <div class="resourceDiv">Create Access Role</div>
        </div>
      </div>
      <div class="row py-3">
        <div class="col-lg-3 col-md-3 col-sm-3">
          <div
            class="form-group mb-10"
            [ngClass]="{
              error:
                isExistsAccessRole == true ||
                customResultId == -1 ||
                customResultId == 0
            }"
          >
            <label class="mb-2">Role Name</label>
            <input
              type="text"
              class="form-control"
              (keyup)="onKeyPressAccessRole()"
              (change)="onChangeOutCheckAccessRole()"
              maxlength="25"
              formControlName="AccessRoleName"
              [(ngModel)]="accessRoleName"
            />
            <!-- <div *ngIf="!isValidAccessRole">Access Role is required</div> -->
            <div *ngIf="isExistsAccessRole">{{ errorMessage }}</div>
          </div>
        </div>
        <div
          class="col-lg-3 col-md-3 col-sm-3"
          [ngClass]="{ error: customResultId == -1 }"
        >
          <div class="form-group mb-10">
            <label class="mb-2">Role Type</label>
            <select
              class="form-control"
              formControlName="RoleType"
              style="align-items: flex-end"
              [(ngModel)]="selectedRoleType"
              (change)="onChangeRoleType()"
            >
              <option *ngFor="let option of roleTypes" [value]="option.ID">
                {{ option.Text }}
              </option>
            </select>
          </div>
        </div>
        <div
          class="col-lg-3 col-md-3 col-sm-3"
          [ngClass]="{ error: customResultId == -2 }"
        >
          <label class="mb-2">Priority</label>
          <select
            class="form-control"
            (change)="makeDirty()"
            formControlName="Priority"
            style="align-items: flex-end"
            [(ngModel)]="selectedPriority"
            [attr.disabled]="prtDisabled == true ? '' : null"
          >
            <option
              [value]="0"
              *ngIf="prtDisabled === true || selectedRoleType == ''"
            >
              Select Prioirty
            </option>
            <option *ngFor="let number of priorities" [value]="number">
              {{ number }}
            </option>
          </select>
        </div>

        <div class="col-lg-2 col-md-2 col-sm-2 d-flex flex-column">
          <label class="mb-2">Full View Access</label>
          <input
            type="checkbox"
            (change)="makeDirty()"
            class="checkbox"
            [(ngModel)]="selectedRoleView"
            (change)="makeDirty()"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>
        <div class="col-lg-1 col-md-1 col-sm-1 d-flex flex-column">
          <label class="mb-2">Is Active</label>
          <input
            type="checkbox"
            (change)="makeDirty()"
            class="checkbox"
            [(ngModel)]="selectedRoleIsActive"
            (change)="makeDirty()"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>
      </div>
      <div class="row py-3">
        <div class="col-lg-6 col-md-6 col-sm-6">
          <label class="mb-2">Notes Description</label>
          <textarea
            type="textarea"
            rows="5"
            cols="70"
            class="form-control"
            [(ngModel)]="remark"
            (change)="makeDirty()"
            [ngModelOptions]="{ standalone: true }"
          >
          </textarea>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="d-flex flex-column">
            <label class="mb-2"
              >Allow to submit booking for another Resource</label
            >
            <input
              type="checkbox"
              (change)="onDelegateChange()"
              class="checkbox"
              [(ngModel)]="selectedDelegate"
              [ngModelOptions]="{ standalone: true }"
            />
          </div>
          <div class="d-flex flex-column mt-2">
            <label class="mb-2">Allow to submit booking for Coworkers</label>
            <input
              type="checkbox"
              (change)="onCoWorkerChange()"
              class="checkbox"
              [(ngModel)]="selectedCoworkers"
              [ngModelOptions]="{ standalone: true }"
            />
          </div>
          <div class="d-flex flex-column mt-2">
            <label class="mb-2">Enable Ghost Booking</label>
            <input
              type="checkbox"
              (change)="resetEnableGhostBooking()"
              class="checkbox"
              [(ngModel)]="disableActionsGhostBooking"
              [ngModelOptions]="{ standalone: true }"
            />
          </div>
        </div>
      </div>

      <div class="row py-3">
        <div class="col-lg-2 col-md-2 col-sm-2">
          <label class="formLabel mb-2">Overallocation Loading%</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="overallocation"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>
        <div class="col-lg-2 col-md-2 col-sm-2">
          <div class="d-flex flex-column">
            <label class="mb-2">Disable Unconfirmed Field</label>
            <input
              type="checkbox"
              (change)="makeDirty()"
              class="checkbox"
              [(ngModel)]="unconfirmed"
              [ngModelOptions]="{ standalone: true }"
            />
          </div>
        </div>
        <div *ngIf="selectedRoleType == '1'" class="col-lg-2 col-md-2 col-sm-2">
          <div class="d-flex flex-column">
            <label class="mb-2">Is Approval Required?</label>
            <input
              type="checkbox"
              (change)="makeDirty()"
              class="checkbox"
              [(ngModel)]="isApproverReq"
              [ngModelOptions]="{ standalone: true }"
            />
          </div>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-2" *ngIf="!isAllretainInstReq">
          <label class="mb-2">Default Retain Instance</label>
          <mat-form-field appearance="outline">
            <mat-select
              [(value)]="selectedInstance"
              (selectionChange)="onInstanceChange($event)"
              placeholder="Retain instance"
            >
              <mat-option
                [value]="option.InstanceId"
                *ngFor="let option of instances"
                [disabled]="isAllretainInstReq"
                >{{ option.InstanceName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-2">
          <div class="d-flex flex-column">
            <label class="mb-2">Is All Instance Required?</label>
            <input
              type="checkbox"
              class="checkbox"
              [(ngModel)]="isAllretainInstReq"
              (change)="onChangeMultiInsta()"
              [ngModelOptions]="{ standalone: true }"
            />
          </div>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-2">
          <div class="d-flex flex-column">
            <label class="mb-2">Enable Preview & Submit</label>
            <input
              type="checkbox"
              (change)="makeDirty()"
              class="checkbox"
              [(ngModel)]="previewSubmit"
              [ngModelOptions]="{ standalone: true }"
            />
          </div>
        </div>
      </div>

      <div class="disable-retain-instance-container">
        <fieldset class="bf">
          <legend class="lgnd">Enable Retain Instance Field</legend>
          <div class="d-flex justify-content-start p-3">
            <div class="d-flex flex-column">
              <label class="mb-2">Individual View</label>
              <input
                type="checkbox"
                (change)="makeDirty()"
                class="checkbox"
                [(ngModel)]="roleIndividualView"
                [ngModelOptions]="{ standalone: true }"
                [disabled]="!isAllretainInstReq"
              />
            </div>
            <div class="d-flex flex-column px-5">
              <label class="mb-2">Engagement View</label>
              <input
                type="checkbox"
                (change)="makeDirty()"
                class="checkbox"
                [(ngModel)]="roleEngagementView"
                [ngModelOptions]="{ standalone: true }"
                [disabled]="!isAllretainInstReq"
              />
            </div>
            <div class="d-flex flex-column px-5">
              <label class="mb-2">Schedule View</label>
              <input
                type="checkbox"
                (change)="makeDirty()"
                class="checkbox"                
                [(ngModel)]="roleTeamView"
                [ngModelOptions]="{ standalone: true }"
                [disabled]="!isAllretainInstReq"
              />
            </div>
            <fieldset  [ngClass]="{'bf p-3 my-2 home-retain-fieldset': InstanceEnableAddBookingIV == false}">
              <div class="d-flex flex-column px-4">
                <label class="mb-2">New Booking of Individual</label>
                <input
                  type="checkbox"
                  (change)="onChangeNewBookingIV()"
                  class="checkbox"                
                  [(ngModel)]="InstanceEnableAddBookingIV"
                  [ngModelOptions]="{ standalone: true }"
                  [disabled]="!isAllretainInstReq"
                />
              </div>
  
              <div class="d-flex flex-column px-4" *ngIf="!InstanceEnableAddBookingIV && isAllretainInstReq">
                <label class="mb-2">Home-based Retain Instance</label>
                <mat-form-field appearance="outline">
                  <mat-select
                    [(value)]="selectedHomeBasedInstance"
                    (selectionChange)="onHomeBasedInstanceChange($event)"
                    placeholder="Select Instance"
                  >
                    <mat-option
                      [value]="option.InstanceId"
                      *ngFor="let option of instances"
                      >{{ option.InstanceName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="alert alert-danger" *ngIf="isHomeRetainNotSelected">{{ errorMessage }}</div>
              </div>
              </fieldset>
          </div>
        </fieldset>
      </div>
    </div>
  </form>

  <div class="grey-bg">
    <div id="divFeaturesLevelConfiguration" class="toggle-config mb-1">
      <mat-accordion>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>FEATURES LEVEL CONFIGURATION</mat-panel-title>
          </mat-expansion-panel-header>
          <div class="toggle-body-content p-3">
            <fieldset class="bf p-3 my-2" style="display: flex;
            flex-wrap: wrap;">
              <legend class="lgnd">Disable Features</legend>
              <div class="d-inline-flex flex-row my-2"
              *ngFor="let fl of featuresList"
              style="flex: 0 0 188px; margin:5px;">              
                <input
                  type="checkbox"
                  (change)="onChangeFeatureFieldModel()"
                  class="checkbox"
                  [(ngModel)]="fl.IsSelected"
                  [ngModelOptions]="{ standalone: true }"
                />
                &nbsp;
                <label class="mb-2">{{ fl.Text }}</label>
              </div>
            </fieldset>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="toggle-config mb-1" *ngIf="isApproverReq">
      <mat-accordion>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>APPROVAL LAYER CONFIGURATION</mat-panel-title>
          </mat-expansion-panel-header>
          <div class="toggle-body-content p-3">
            <app-approval-layer-configuration
              (roleApprovalArr)="populateRoleApprovalDTO($event)"
              [jobCategoryList]="jobAddJobCategoryList"
              [actionDescList]="actionDescriptionList"
              [showAddBtn]="enableAddInApprovalLayer"
            ></app-approval-layer-configuration>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="toggle-config mb-1" *ngIf="selectedRoleType == '1'">
      <mat-accordion>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>ASSIGNMENT CONFIGURATION</mat-panel-title>
          </mat-expansion-panel-header>
          <div class="toggle-body-content p-3">
            <div class="row p-2">
              <div class="col-lg-3 col-md-13 col-sm-3 d-flex flex-column">
                <label class="mb-2">Link</label>
                <input
                  type="checkbox"
                  (change)="makeDirty()"
                  class="checkbox"
                  [(ngModel)]="assignmentLink"
                  (change)="makeDirty()"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>
              <div class="col-lg-2 col-md-2 col-sm-2 d-flex flex-column">
                <label class="mb-2">Is Active</label>
                <input
                  type="checkbox"
                  (change)="makeDirty()"
                  class="checkbox"
                  [(ngModel)]="assignmentIsActive"
                  (change)="makeDirty()"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>
            </div>
            <div class="p-2 d-flex justify-content-between">
              <fieldset
                [ngClass]="{ 'bf p-2': assignmentLink == true }"
                class="fs-left"
              >
                <legend *ngIf="assignmentLink == true" class="lgnd">
                  Link
                </legend>
                <label class="mb-2">Sub Area</label>
                <ng-multiselect-dropdown
                  [placeholder]="'Select Sub Area'"
                  [settings]="dropdownSettings"
                  [data]="assignmentSubAreaList"
                  [(ngModel)]="assignmentSubAreaSelectedItems"
                  (onSelect)="makeDirty()"
                  (onDeSelect)="makeDirty()"
                >
                </ng-multiselect-dropdown>
              </fieldset>
              <fieldset
                [ngClass]="{ 'bf p-2': assignmentLink == true }"
                class="d-flex justify-content-between fs-right"
              >
                <legend *ngIf="assignmentLink == true" class="lgnd">
                  Link
                </legend>
                <div class="slct-width-rhs">
                  <label class="mb-2">Managerial Country</label>
                  <ng-multiselect-dropdown
                    [placeholder]="'Select Managerial Country'"
                    [settings]="dropdownSettings"
                    [data]="assignmentManageCountryList"
                    [(ngModel)]="assignmentManageCountrySelectedItems"
                    (onSelect)="makeDirty()"
                    (onDeSelect)="makeDirty()"
                  >
                  </ng-multiselect-dropdown>
                </div>

                <div class="slct-width-rhs">
                  <label class="mb-2">Legal Entity</label>
                  <ng-multiselect-dropdown
                    [placeholder]="'Select Legal Entity'"
                    [settings]="dropdownSettings"
                    [data]="assignmentLegalEntityList"
                    [(ngModel)]="assignmentLegalEntitySelectedItems"
                    (onSelect)="makeDirty()"
                    (onDeSelect)="makeDirty()"
                  >
                  </ng-multiselect-dropdown>
                </div>
                <div class="slct-width-rhs">
                  <label class="mb-2">Business Unit</label>
                  <ng-multiselect-dropdown
                    [placeholder]="'Select Business Unit'"
                    [(ngModel)]="assignmentBusinessUnitSelectedItems"
                    [settings]="dropdownSettings"
                    [data]="assignmentBusinessUnitList"
                    (onSelect)="makeDirty()"
                    (onDeSelect)="makeDirty()"
                  >
                  </ng-multiselect-dropdown>
                </div>
              </fieldset>
            </div>
            <div class="p-2 d-flex justify-content-between">
              <fieldset
                [ngClass]="{ 'bf p-2': assignmentLink == true }"
                class="
                  fieldset-oprtngunit-mgmtunit
                  d-flex
                  justify-content-between
                "
              >
                <legend *ngIf="assignmentLink == true" class="lgnd">
                  Link
                </legend>
                <div class="width-oprtngunit-mgmtunit">
                  <label class="mb-2">Operating Unit</label>
                  <ng-multiselect-dropdown
                    [placeholder]="'Select Operating Unit'"
                    [(ngModel)]="assignmentOperatingUnitSelectedItems"
                    [settings]="dropdownSettings"
                    [data]="assignmentOperatingUnitList"
                    (onSelect)="makeDirty()"
                    (onDeSelect)="makeDirty()"
                  >
                  </ng-multiselect-dropdown>
                </div>
                <div class="width-oprtngunit-mgmtunit">
                  <label class="mb-2">Management Unit</label>
                  <ng-multiselect-dropdown
                    [placeholder]="'Select Management Unit'"
                    [(ngModel)]="assignmentManagementUnitSelectedItems"
                    [settings]="dropdownSettings"
                    [data]="assignmentManagementUnitList"
                    (onSelect)="makeDirty()"
                    (onDeSelect)="makeDirty()"
                  >
                  </ng-multiselect-dropdown>
                </div>
              </fieldset>
              <div class="width-sl-ssl">
                <label class="mb-2">Service Line</label>
                <ng-multiselect-dropdown
                  [placeholder]="'Select Service Line'"
                  [(ngModel)]="assignmentServiceLineSelectedItems"
                  [settings]="dropdownSettings"
                  [data]="assignmentServiceLineList"
                  (onSelect)="makeDirty()"
                  (onDeSelect)="makeDirty()"
                >
                </ng-multiselect-dropdown>
              </div>
              <div class="width-sl-ssl">
                <label class="mb-2">Sub Service Line</label>
                <ng-multiselect-dropdown
                  [placeholder]="'Select Sub Service Line'"
                  [(ngModel)]="assignmentSubServiceLineSelectedItems"
                  [settings]="dropdownSettings"
                  [data]="assignmentSubServiceLineList"
                  (onSelect)="makeDirty()"
                  (onDeSelect)="makeDirty()"
                >
                </ng-multiselect-dropdown>
              </div>
            </div>

            <div class="row p-2">
              <div class="col-lg-4 col-md-4 col-sm-4">
                <label class="mb-2">Sub Management Unit</label>
                <ng-multiselect-dropdown
                  [placeholder]="'Select Sub Management Unit'"
                  [(ngModel)]="assignmentSubManagementUnitSelectedItems"
                  [settings]="dropdownSettings"
                  [data]="assignmentSubManagementUnitList"
                  (onSelect)="makeDirty()"
                  (onDeSelect)="makeDirty()"
                >
                </ng-multiselect-dropdown>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4">
                <label class="mb-2">Department</label>
                <ng-multiselect-dropdown
                  [placeholder]="'Select Department'"
                  [(ngModel)]="assignmentDepartmentSelectedItems"
                  [settings]="dropdownSettings"
                  [data]="assignmentDepartmentList"
                  (onSelect)="makeDirty()"
                  (onDeSelect)="makeDirty()"
                >
                </ng-multiselect-dropdown>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4">
                <label class="mb-2">Rank</label>
                <ng-multiselect-dropdown
                  [placeholder]="'Select Rank'"
                  [(ngModel)]="assignmentRankSelectedItems"
                  [settings]="dropdownSettings"
                  [data]="assignmentRankList"
                  (onSelect)="makeDirty()"
                  (onDeSelect)="makeDirty()"
                >
                </ng-multiselect-dropdown>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="toggle-config mb-1" *ngIf="selectedRoleView === false">
      <mat-accordion>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>VIEW CONFIGURATION</mat-panel-title>
          </mat-expansion-panel-header>
          <mat-accordion>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title> Resource View Configuration </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="toggle-body-content py-3">
                <div class="px-4 mb-2 d-flex justify-content-between">
                  <div class="d-flex flex-column">
                    <label class="mb-2">Link</label>
                    <input
                      type="checkbox"
                      (change)="makeDirty()"
                      class="checkbox"
                      [(ngModel)]="resViewLink"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </div>
                  <div class="d-flex flex-column">
                    <label class="mb-2">Disable InActive Resources</label>
                    <input
                      type="checkbox"
                      (change)="makeDirty()"
                      class="checkbox"
                      [(ngModel)]="resViewIsActive"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </div>
                </div>
                <div class="px-4 d-flex justify-content-between">
                  <fieldset
                    [ngClass]="{ 'bf p-2': resViewLink == true }"
                    class="fs-left"
                  >
                    <legend *ngIf="resViewLink == true" class="lgnd">
                      Link
                    </legend>
                    <label class="mb-2">Sub Area</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Sub Area'"
                      [settings]="dropdownSettings"
                      [data]="resViewSubAreaList"
                      [(ngModel)]="resViewSubAreaSelectedItems"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </fieldset>
                  <fieldset
                    [ngClass]="{ 'bf p-2': resViewLink == true }"
                    class="d-flex justify-content-between fs-right"
                  >
                    <legend *ngIf="resViewLink == true" class="lgnd">
                      Link
                    </legend>
                    <div class="slct-width-rhs">
                      <label class="mb-2">Managerial Country</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Managerial Country'"
                        [settings]="dropdownSettings"
                        [data]="resViewManageCountryList"
                        [(ngModel)]="resViewManageCountrySelectedItems"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>

                    <div class="slct-width-rhs">
                      <label class="mb-2">Legal Entity</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Legal Entity'"
                        [settings]="dropdownSettings"
                        [data]="resViewLegalEntityList"
                        [(ngModel)]="resViewLegalEntitySelectedItems"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="slct-width-rhs">
                      <label class="mb-2">Business Unit</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Business Unit'"
                        [settings]="dropdownSettings"
                        [data]="resViewBusinessUnitList"
                        [(ngModel)]="resViewBusinessUnitSelectedItems"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                  </fieldset>
                </div>
                <div class="row p-4">
                  <div class="col-lg-3 col-md-3 col-sm-3">
                    <label class="mb-2">Service Line</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Service Line'"
                      [settings]="dropdownSettings"
                      [data]="resViewServiceLineList"
                      [(ngModel)]="resViewServiceLineSelectedItems"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <label class="mb-2">Sub Service Line</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Sub Service Line'"
                      [settings]="dropdownSettings"
                      [data]="resViewSubServiceLineList"
                      [(ngModel)]="resViewSubServiceLineSelectedItems"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                  <div class="col-lg-5 col-md-5 col-sm-5">
                    <label class="mb-2">Sub Management Unit</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Sub Management Unit'"
                      [settings]="dropdownSettings"
                      [data]="resViewSubManagementUnitList"
                      [(ngModel)]="resViewSubManagementUnitSelectedItems"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Engagement View Configuration
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="toggle-body-content py-3">
                <div class="px-4 mb-2 d-flex justify-content-between">
                  <div class="d-flex flex-column">
                    <label class="mb-2">Link</label>
                    <input
                      type="checkbox"
                      (change)="makeDirty()"
                      class="checkbox"
                      [(ngModel)]="jobViewLink"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </div>
                </div>
                <div class="px-4 d-flex justify-content-between">
                  <fieldset
                    [ngClass]="{ 'bf p-2': jobViewLink == true }"
                    class="fs-left"
                  >
                    <legend *ngIf="jobViewLink == true" class="lgnd">
                      Link
                    </legend>
                    <label class="mb-2">Sub Area</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Sub Area'"
                      [settings]="dropdownSettings"
                      [data]="jobViewSubAreaList"
                      [(ngModel)]="jobViewSubAreaSelectedItems"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </fieldset>
                  <fieldset
                    [ngClass]="{ 'bf p-2': jobViewLink == true }"
                    class="d-flex justify-content-between fs-right"
                  >
                    <legend *ngIf="jobViewLink == true" class="lgnd">
                      Link
                    </legend>
                    <div class="slct-width-rhs">
                      <label class="mb-2">Managerial Country</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Managerial Country'"
                        [settings]="dropdownSettings"
                        [data]="jobViewManageCountryList"
                        [(ngModel)]="jobViewManageCountrySelectedItems"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="slct-width-rhs">
                      <label class="mb-2">Legal Entity</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Legal Entity'"
                        [settings]="dropdownSettings"
                        [data]="jobViewLegalEntityList"
                        [(ngModel)]="jobViewLegalEntitySelectedItems"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="slct-width-rhs">
                      <label class="mb-2">Business Unit</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Business Unit'"
                        [settings]="dropdownSettings"
                        [data]="jobViewBusinessUnitList"
                        [(ngModel)]="jobViewBusinessUnitSelectedItems"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                  </fieldset>
                </div>
                <div class="row p-4">
                  <div class="col-lg-3 col-md-3 col-sm-3">
                    <label class="mb-2">Service Line</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Service Line'"
                      [settings]="dropdownSettings"
                      [data]="jobViewServiceLineList"
                      [(ngModel)]="jobViewServiceLineSelectedItems"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <label class="mb-2">Sub Service Line</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Sub Service Line'"
                      [settings]="dropdownSettings"
                      [data]="jobViewSubServiceLineList"
                      [(ngModel)]="jobViewSubServiceLineSelectedItems"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                  <div class="col-lg-5 col-md-5 col-sm-5">
                    <label class="mb-2">Sub Management Unit</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Sub Management Unit'"
                      [settings]="dropdownSettings"
                      [data]="jobViewSubManagementUnitList"
                      [(ngModel)]="jobViewSubManagementUnitSelectedItems"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                </div>
                <div class="row px-4">
                  <div class="col-lg-5 col-md-5 col-sm-5">
                    <label class="mb-2">Engagement Code</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Engagement Code'"
                      [(ngModel)]="jobViewIncludeJobCodeSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="jobViewIncludeJobCodeList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                </div>
                <fieldset class="bf m-4">
                  <legend class="lgnd">Disable</legend>
                  <div class="row px-3">
                    <div class="col-lg-3 col-md-3 col-sm-3">
                      <label class="mb-2">Engagement Category</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Engagement Category'"
                        [(ngModel)]="jobViewJobCategorySelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobViewJobCategoryList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3">
                      <label class="mb-2">Engagement Status</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Engagement Status'"
                        [(ngModel)]="jobViewJobStatusSelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobViewJobStatusList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="col-lg-3 col-md-3col-sm-3">
                      <label class="mb-2"
                        >Engagement Status (No Bookings)</label
                      >
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Engagement Status'"
                        [(ngModel)]="jobViewJobStatusBookingSelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobViewJobStatusBookingList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3">
                      <label class="mb-2">Engagement Code</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Engagement Code'"
                        [(ngModel)]="jobViewJobCodeSelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobViewJobCodeList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                  </div>
                  <div class="d-flex p-3">
                    <div class="d-flex flex-column">
                      <label class="mb-2">Suspended/Pended</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="jobViewIsPended"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div class="d-flex flex-column mx-5">
                      <label class="mb-2"
                        >Disable Successfactor Engagements</label
                      >
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="jobViewIsTR"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                  </div>
                </fieldset>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="toggle-config mb-1">
      <mat-accordion>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>EDIT CONFIGURATION</mat-panel-title>
          </mat-expansion-panel-header>
          <mat-accordion>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title> Resource Edit Configuration </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="toggle-body-content py-3">
                <div class="px-4 mb-2 d-flex justify-content-between">
                  <div class="d-flex flex-column">
                    <label class="mb-2">Link</label>
                    <input
                      type="checkbox"
                      (change)="makeDirty()"
                      class="checkbox"
                      [(ngModel)]="resEditLink"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </div>
                  <div class="d-flex flex-column">
                    <label class="mb-2">Read Only</label>
                    <input
                      type="checkbox"
                      (change)="makeDirty()"
                      class="checkbox"
                      [(ngModel)]="resEditIsDisabled"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </div>
                </div>
                <div class="px-4 d-flex justify-content-between">
                  <fieldset
                    [ngClass]="{ 'bf p-2': resEditLink == true }"
                    class="fs-left"
                  >
                    <legend *ngIf="resEditLink == true" class="lgnd">
                      Link
                    </legend>
                    <label class="mb-2">Sub Area</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Sub Area'"
                      [(ngModel)]="resEditSubAreaSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="resEditSubAreaList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </fieldset>
                  <fieldset
                    [ngClass]="{ 'bf p-2': resEditLink == true }"
                    class="d-flex justify-content-between fs-right"
                  >
                    <legend *ngIf="resEditLink == true" class="lgnd">
                      Link
                    </legend>
                    <div class="slct-width-rhs">
                      <label class="mb-2">Managerial Country</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Managerial Country'"
                        [settings]="dropdownSettings"
                        [data]="resEditManageCountryList"
                        [(ngModel)]="resEditManageCountrySelectedItems"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="slct-width-rhs">
                      <label class="mb-2">Legal Entity</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Legal Entity'"
                        [(ngModel)]="resEditLegalEntitySelectedItems"
                        [settings]="dropdownSettings"
                        [data]="resEditLegalEntityList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="slct-width-rhs">
                      <label class="mb-2">Business Unit</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Business Unit'"
                        [(ngModel)]="resEditBusinessUnitSelectedItems"
                        [settings]="dropdownSettings"
                        [data]="resEditBusinessUnitList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                  </fieldset>
                </div>
                <div class="row p-4">
                  <div class="col-lg-3 col-md-3 col-sm-3">
                    <label class="mb-2">Service Line</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Service Line'"
                      [(ngModel)]="resEditServiceLineSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="resEditServiceLineList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <label class="mb-2">Sub Service Line</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Sub Service Line'"
                      [(ngModel)]="resEditSubServiceLineSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="resEditSubServiceLineList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                  <div class="col-lg-5 col-md-5 col-sm-5">
                    <label class="mb-2">Sub Management Unit</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Sub Management Unit'"
                      [(ngModel)]="resEditSubManagementUnitSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="resEditSubManagementUnitList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                </div>
                <fieldset class="bf m-4">
                  <legend class="lgnd">Disable</legend>
                  <div class="d-flex justify-content-between p-2">
                    <div class="d-flex flex-column">
                      <label class="mb-2">InActive Resources</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="resEditIsActive"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div class="d-flex flex-column">
                      <label class="mb-2">Individual View</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="resEditIndividualView"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div class="d-flex flex-column">
                      <label class="mb-2">Engagement View</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="resEditEngagementView"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                  </div>
                </fieldset>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Engagement Edit Configuration
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="toggle-body-content py-3">
                <div class="px-4 mb-2 d-flex justify-content-between">
                  <div class="d-flex flex-column">
                    <label class="mb-2">Link</label>
                    <input
                      type="checkbox"
                      (change)="makeDirty()"
                      class="checkbox"
                      [(ngModel)]="jobEditLink"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </div>
                  <div class="d-flex flex-column">
                    <label class="mb-2">Read Only</label>
                    <input
                      type="checkbox"
                      (change)="makeDirty()"
                      class="checkbox"
                      [(ngModel)]="jobEditIsDisabled"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </div>
                </div>
                <div class="px-4 d-flex justify-content-between">
                  <fieldset
                    [ngClass]="{ 'bf p-2': jobEditLink == true }"
                    class="fs-left"
                  >
                    <legend *ngIf="jobEditLink == true" class="lgnd">
                      Link
                    </legend>
                    <label class="mb-2">Sub Area</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Sub Area'"
                      [(ngModel)]="jobEditSubAreaSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="jobEditSubAreaList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </fieldset>
                  <fieldset
                    [ngClass]="{ 'bf p-2': jobEditLink == true }"
                    class="d-flex justify-content-between fs-right"
                  >
                    <legend *ngIf="jobEditLink == true" class="lgnd">
                      Link
                    </legend>
                    <div class="slct-width-rhs">
                      <label class="mb-2">Managerial Country</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Managerial Country'"
                        [(ngModel)]="jobEditManageCountrySelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobEditManageCountryList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="slct-width-rhs">
                      <label class="mb-2">Legal Entity</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Legal Entity'"
                        [(ngModel)]="jobEditLegalEntitySelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobEditLegalEntityList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="slct-width-rhs">
                      <label class="mb-2">Business Unit</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Business Unit'"
                        [(ngModel)]="jobEditBusinessUnitSelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobEditBusinessUnitList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                  </fieldset>
                </div>
                <div class="row p-4">
                  <div class="col-lg-3 col-md-3 col-sm-3">
                    <label class="mb-2">Service Line</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Service Line'"
                      [(ngModel)]="jobEditServiceLineSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="jobEditServiceLineList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <label class="mb-2">Sub Service Line</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Sub Service Line'"
                      [(ngModel)]="jobEditSubServiceLineSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="jobEditSubServiceLineList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                  <div class="col-lg-5 col-md-5 col-sm-5">
                    <label class="mb-2">Sub Management Unit</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Sub Management Unit'"
                      [(ngModel)]="jobEditSubManagementUnitSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="jobEditSubManagementUnitList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                </div>
                <div class="row px-4">
                  <div class="col-lg-5 col-md-5 col-sm-5">
                    <label class="mb-2">Engagement Code</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Engagement Code'"
                      [(ngModel)]="jobEditIncludeJobCodeSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="jobEditIncludeJobCodeList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                </div>
                <fieldset class="bf m-4">
                  <legend class="lgnd">Disable</legend>
                  <div class="row px-3">
                    <div class="col-lg-3 col-md-3 col-sm-3">
                      <label class="mb-2">Engagement Category</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Engagement Category'"
                        [(ngModel)]="jobEditJobCategorySelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobEditJobCategoryList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3">
                      <label class="mb-2">Engagement Status</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Engagement Status'"
                        [(ngModel)]="jobEditJobStatusSelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobEditJobStatusList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3">
                      <label class="mb-2"
                        >Engagement Status (No Bookings)</label
                      >
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Engagement Status'"
                        [(ngModel)]="jobEditJobStatusBookingSelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobEditJobStatusBookingList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3">
                      <label class="mb-2">Engagement Code</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Engagement Code'"
                        [(ngModel)]="jobEditJobCodeSelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobEditJobCodeList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between p-3">
                    <div class="d-flex flex-column">
                      <label class="mb-2">Suspended/Pended</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="jobEditIsPended"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div class="d-flex flex-column">
                      <label class="mb-2">Successfactor Engagements</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="jobEditIsTR"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>

                    <div class="d-flex flex-column">
                      <label class="mb-2">Individual View</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="jobEditIndividualView"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div class="d-flex flex-column">
                      <label class="mb-2">Engagement View</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="jobEditEngagementView"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                  </div>
                </fieldset>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="toggle-config mb-1">
      <mat-accordion>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>ADD CONFIGURATION</mat-panel-title>
          </mat-expansion-panel-header>
          <mat-accordion>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title> Resource Add Configuration </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="toggle-body-content py-3">
                <div class="px-4 mb-2 d-flex justify-content-between">
                  <div class="d-flex">
                    <div class="d-flex flex-column">
                      <label class="mb-2">Is Like Edit</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="resAddIsLikeEdit"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div
                      class="d-flex flex-column mx-5"
                      *ngIf="resAddIsLikeEdit === false"
                    >
                      <label class="mb-2">Link</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="resAddLink"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                  </div>

                  <div
                    class="d-flex flex-column"
                    *ngIf="resAddIsLikeEdit === false"
                  >
                    <label class="mb-2">Read Only</label>
                    <input
                      type="checkbox"
                      (change)="makeDirty()"
                      class="checkbox"
                      [(ngModel)]="resAddIsDisabled"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </div>
                </div>

                <div
                  class="px-4 d-flex justify-content-between"
                  *ngIf="resAddIsLikeEdit === false"
                >
                  <fieldset
                    [ngClass]="{ 'bf p-2': resAddLink == true }"
                    class="fs-left"
                  >
                    <legend *ngIf="resAddLink == true" class="lgnd">
                      Link
                    </legend>
                    <label class="mb-2">Sub Area</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Sub Area'"
                      [(ngModel)]="resAddSubAreaSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="resAddSubAreaList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </fieldset>
                  <fieldset
                    [ngClass]="{ 'bf p-2': resAddLink == true }"
                    class="d-flex justify-content-between fs-right"
                  >
                    <legend *ngIf="resAddLink == true" class="lgnd">
                      Link
                    </legend>
                    <div class="slct-width-rhs">
                      <label class="mb-2">Managerial Country</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Managerial Country'"
                        [settings]="dropdownSettings"
                        [data]="resAddManageCountryList"
                        [(ngModel)]="resAddManageCountrySelectedItems"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="slct-width-rhs">
                      <label class="mb-2">Legal Entity</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Legal Entity'"
                        [(ngModel)]="resAddLegalEntitySelectedItems"
                        [settings]="dropdownSettings"
                        [data]="resAddLegalEntityList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="slct-width-rhs">
                      <label class="mb-2">Business Unit</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Business Unit'"
                        [(ngModel)]="resAddBusinessUnitSelectedItems"
                        [settings]="dropdownSettings"
                        [data]="resAddBusinessUnitList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                  </fieldset>
                </div>
                <div class="row p-4" *ngIf="resAddIsLikeEdit === false">
                  <div class="col-lg-3 col-md-3 col-sm-3">
                    <label class="mb-2">Service Line</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Service Line'"
                      [(ngModel)]="resAddServiceLineSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="resAddServiceLineList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <label class="mb-2">Sub Service Line</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Sub Service Line'"
                      [(ngModel)]="resAddSubServiceLineSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="resAddSubServiceLineList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                  <div class="col-lg-5 col-md-5 col-sm-5">
                    <label class="mb-2">Sub Management Unit</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Sub Management Unit'"
                      [(ngModel)]="resAddSubManagementUnitSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="resAddSubManagementUnitList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                </div>
                <fieldset class="bf m-4" *ngIf="resAddIsLikeEdit === false">
                  <legend class="lgnd">Disable</legend>
                  <div class="d-flex justify-content-between p-2">
                    <div class="d-flex flex-column">
                      <label class="mb-2">InActive Resources</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="resAddIsActive"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div class="d-flex flex-column">
                      <label class="mb-2">Individual View</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="resAddIndividualView"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div class="d-flex flex-column">
                      <label class="mb-2">Engagement View</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="resAddEngagementView"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                  </div>
                </fieldset>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Engagement Add Configuration
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="toggle-body-content py-3">
                <div class="px-4 mb-2 d-flex justify-content-between">
                  <div class="d-flex">
                    <div class="d-flex flex-column">
                      <label class="mb-2">Is Like Edit</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="jobAddIsLikeEdit"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div
                      class="d-flex flex-column mx-5"
                      *ngIf="jobAddIsLikeEdit === false"
                    >
                      <label class="mb-2">Link</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="jobAddLink"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                  </div>

                  <div
                    class="d-flex flex-column"
                    *ngIf="jobAddIsLikeEdit === false"
                  >
                    <label class="mb-2">Read Only</label>
                    <input
                      type="checkbox"
                      (change)="makeDirty()"
                      class="checkbox"
                      [(ngModel)]="jobAddIsDisabled"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </div>
                </div>

                <div
                  class="px-4 d-flex justify-content-between"
                  *ngIf="jobAddIsLikeEdit === false"
                >
                  <fieldset
                    [ngClass]="{ 'bf p-2': jobAddLink == true }"
                    class="fs-left"
                  >
                    <legend *ngIf="jobAddLink == true" class="lgnd">
                      Link
                    </legend>
                    <label class="mb-2">Sub Area</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Sub Area'"
                      [(ngModel)]="jobAddSubAreaSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="jobAddSubAreaList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </fieldset>
                  <fieldset
                    [ngClass]="{ 'bf p-2': jobAddLink == true }"
                    class="d-flex justify-content-between fs-right"
                  >
                    <legend *ngIf="jobAddLink == true" class="lgnd">
                      Link
                    </legend>
                    <div class="slct-width-rhs">
                      <label class="mb-2">Managerial Country</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Managerial Country'"
                        [(ngModel)]="jobAddManageCountrySelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobAddManageCountryList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="slct-width-rhs">
                      <label class="mb-2">Legal Entity</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Legal Entity'"
                        [(ngModel)]="jobAddLegalEntitySelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobAddLegalEntityList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="slct-width-rhs">
                      <label class="mb-2">Business Unit</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Business Unit'"
                        [(ngModel)]="jobAddBusinessUnitSelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobAddBusinessUnitList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                  </fieldset>
                </div>
                <div class="row p-4" *ngIf="jobAddIsLikeEdit === false">
                  <div class="col-lg-3 col-md-3 col-sm-3">
                    <label class="mb-2">Service Line</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Service Line'"
                      [(ngModel)]="jobAddServiceLineSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="jobAddServiceLineList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <label class="mb-2">Sub Service Line</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Sub Service Line'"
                      [(ngModel)]="jobAddSubServiceLineSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="jobAddSubServiceLineList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                  <div class="col-lg-5 col-md-5 col-sm-5">
                    <label class="mb-2">Sub Management Unit</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Sub Management Unit'"
                      [(ngModel)]="jobAddSubManagementUnitSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="jobAddSubManagementUnitList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                </div>
                <div class="row px-4" *ngIf="jobAddIsLikeEdit === false">
                  <div class="col-lg-5 col-md-5 col-sm-5">
                    <label class="mb-2">Engagement Code</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Engagement Code'"
                      [(ngModel)]="jobAddIncludeJobCodeSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="jobAddIncludeJobCodeList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                </div>
                <fieldset class="bf m-4" *ngIf="jobAddIsLikeEdit === false">
                  <legend class="lgnd">Disable</legend>
                  <div class="row px-3">
                    <div class="col-lg-3 col-md-3 col-sm-3">
                      <label class="mb-2">Engagement Category</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Engagement Category'"
                        [(ngModel)]="jobAddJobCategorySelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobAddJobCategoryList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3">
                      <label class="mb-2">Engagement Status</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Engagement Status'"
                        [(ngModel)]="jobAddJobStatusSelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobAddJobStatusList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3">
                      <label class="mb-2"
                        >Engagement Status (No Bookings)</label
                      >
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Engagement Status'"
                        [(ngModel)]="jobAddJobStatusBookingSelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobAddJobStatusBookingList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3">
                      <label class="mb-2">Engagement Code</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Engagement Code'"
                        [(ngModel)]="jobAddJobCodeSelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobAddJobCodeList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between p-3">
                    <div
                      class="d-flex flex-column"
                      *ngIf="jobAddIsLikeEdit === false"
                    >
                      <label class="mb-2">Suspended/Pended</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="jobAddIsPended"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div class="d-flex flex-column">
                      <label class="mb-2">Successfactor Engagements</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="jobAddIsTR"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>

                    <div class="d-flex flex-column">
                      <label class="mb-2">Individual View</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="jobAddIndividualView"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div class="d-flex flex-column">
                      <label class="mb-2">Engagement View</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="jobAddEngagementView"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                  </div>
                </fieldset>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="toggle-config mb-1">
      <mat-accordion>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>DELETE CONFIGURATION</mat-panel-title>
          </mat-expansion-panel-header>
          <mat-accordion>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Resource Delete Configuration
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="toggle-body-content py-3">
                <div class="px-4 mb-2 d-flex justify-content-between">
                  <div class="d-flex">
                    <div class="d-flex flex-column">
                      <label class="mb-2">Is Like Edit</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="resDeleteIsLikeEdit"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div
                      class="d-flex flex-column mx-5"
                      *ngIf="resDeleteIsLikeEdit === false"
                    >
                      <label class="mb-2">Link</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="resDeleteLink"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                  </div>
                  <div
                    class="d-flex flex-column"
                    *ngIf="resDeleteIsLikeEdit === false"
                  >
                    <label class="mb-2">Read Only</label>
                    <input
                      type="checkbox"
                      (change)="makeDirty()"
                      class="checkbox"
                      [(ngModel)]="resDeleteIsDisabled"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </div>
                </div>

                <div
                  class="px-4 d-flex justify-content-between"
                  *ngIf="resDeleteIsLikeEdit === false"
                >
                  <fieldset
                    [ngClass]="{ 'bf p-2': resDeleteLink == true }"
                    class="fs-left"
                  >
                    <legend *ngIf="resDeleteLink == true" class="lgnd">
                      Link
                    </legend>
                    <label class="mb-2">Sub Area</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Sub Area'"
                      [(ngModel)]="resDeleteSubAreaSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="resDeleteSubAreaList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </fieldset>
                  <fieldset
                    [ngClass]="{ 'bf p-2': resDeleteLink == true }"
                    class="d-flex justify-content-between fs-right"
                  >
                    <legend *ngIf="resDeleteLink == true" class="lgnd">
                      Link
                    </legend>
                    <div class="slct-width-rhs">
                      <label class="mb-2">Managerial Country</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Managerial Country'"
                        [settings]="dropdownSettings"
                        [data]="resDeleteManageCountryList"
                        [(ngModel)]="resDeleteManageCountrySelectedItems"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="slct-width-rhs">
                      <label class="mb-2">Legal Entity</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Legal Entity'"
                        [(ngModel)]="resDeleteLegalEntitySelectedItems"
                        [settings]="dropdownSettings"
                        [data]="resDeleteLegalEntityList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="slct-width-rhs">
                      <label class="mb-2">Business Unit</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Business Unit'"
                        [(ngModel)]="resDeleteBusinessUnitSelectedItems"
                        [settings]="dropdownSettings"
                        [data]="resDeleteBusinessUnitList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                  </fieldset>
                </div>
                <div class="row p-4" *ngIf="resDeleteIsLikeEdit === false">
                  <div class="col-lg-3 col-md-3 col-sm-3">
                    <label class="mb-2">Service Line</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Service Line'"
                      [(ngModel)]="resDeleteServiceLineSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="resDeleteServiceLineList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <label class="mb-2">Sub Service Line</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Sub Service Line'"
                      [(ngModel)]="resDeleteSubServiceLineSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="resDeleteSubServiceLineList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                  <div class="col-lg-5 col-md-5 col-sm-5">
                    <label class="mb-2">Sub Management Unit</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Sub Management Unit'"
                      [(ngModel)]="resDeleteSubManagementUnitSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="resDeleteSubManagementUnitList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                </div>
                <fieldset class="bf m-4" *ngIf="resDeleteIsLikeEdit === false">
                  <legend class="lgnd">Disable</legend>
                  <div class="d-flex justify-content-between p-3">
                    <div class="d-flex flex-column">
                      <label class="mb-2">InActive Resources</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="resDeleteIsActive"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div class="d-flex flex-column">
                      <label class="mb-2">Individual View</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="resDeleteIndividualView"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div class="d-flex flex-column">
                      <label class="mb-2">Engagement View</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="resDeleteEngagementView"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                  </div>
                </fieldset>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Engagement Delete Configuration
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="toggle-body-content py-3">
                <div class="px-4 mb-2 d-flex justify-content-between">
                  <div class="d-flex">
                    <div class="d-flex flex-column">
                      <label class="mb-2">Is Like Edit</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="jobDeleteIsLikeEdit"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div
                      class="d-flex flex-column mx-5"
                      *ngIf="jobDeleteIsLikeEdit === false"
                    >
                      <label class="mb-2">Link</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="jobDeleteLink"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                  </div>

                  <div
                    class="d-flex flex-column"
                    *ngIf="jobDeleteIsLikeEdit === false"
                  >
                    <label class="mb-2">Read Only</label>
                    <input
                      type="checkbox"
                      (change)="makeDirty()"
                      class="checkbox"
                      [(ngModel)]="jobDeleteIsDisabled"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </div>
                </div>
                <div
                  class="px-4 d-flex justify-content-between"
                  *ngIf="jobDeleteIsLikeEdit === false"
                >
                  <fieldset
                    [ngClass]="{ 'bf p-2': jobDeleteLink == true }"
                    class="fs-left"
                  >
                    <legend *ngIf="jobDeleteLink == true" class="lgnd">
                      Link
                    </legend>
                    <label class="mb-2">Sub Area</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Sub Area'"
                      [(ngModel)]="jobDeleteSubAreaSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="jobDeleteSubAreaList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </fieldset>
                  <fieldset
                    [ngClass]="{ 'bf p-2': jobDeleteLink == true }"
                    class="d-flex justify-content-between fs-right"
                  >
                    <legend *ngIf="jobDeleteLink == true" class="lgnd">
                      Link
                    </legend>
                    <div class="slct-width-rhs">
                      <label class="mb-2">Managerial Country</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Managerial Country'"
                        [(ngModel)]="jobDeleteManageCountrySelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobDeleteManageCountryList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="slct-width-rhs">
                      <label class="mb-2">Legal Entity</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Legal Entity'"
                        [(ngModel)]="jobDeleteLegalEntitySelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobDeleteLegalEntityList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="slct-width-rhs">
                      <label class="mb-2">Business Unit</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Business Unit'"
                        [(ngModel)]="jobDeleteBusinessUnitSelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobDeleteBusinessUnitList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                  </fieldset>
                </div>
                <div class="row p-4" *ngIf="jobDeleteIsLikeEdit === false">
                  <div class="col-lg-3 col-md-3 col-sm-3">
                    <label class="mb-2">Service Line</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Service Line'"
                      [(ngModel)]="jobDeleteServiceLineSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="jobDeleteServiceLineList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <label class="mb-2">Sub Service Line</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Sub Service Line'"
                      [(ngModel)]="jobDeleteSubServiceLineSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="jobDeleteSubServiceLineList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                  <div class="col-lg-5 col-md-5 col-sm-5">
                    <label class="mb-2">Sub Management Unit</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Sub Management Unit'"
                      [(ngModel)]="jobDeleteSubManagementUnitSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="jobDeleteSubManagementUnitList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                </div>
                <div class="row px-4" *ngIf="jobDeleteIsLikeEdit === false">
                  <div class="col-lg-5 col-md-5 col-sm-5">
                    <label class="mb-2">Engagement Code</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Engagement Code'"
                      [(ngModel)]="jobDeleteIncludeJobCodeSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="jobDeleteIncludeJobCodeList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                </div>
                <fieldset class="bf m-4" *ngIf="jobDeleteIsLikeEdit === false">
                  <legend class="lgnd">Disable</legend>
                  <div class="row px-3">
                    <div class="col-lg-3 col-md-3 col-sm-3">
                      <label class="mb-2">Engagement Category</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Engagement Category'"
                        [(ngModel)]="jobDeleteJobCategorySelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobDeleteJobCategoryList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3">
                      <label class="mb-2">Engagement Status</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Engagement Status'"
                        [(ngModel)]="jobDeleteJobStatusSelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobDeleteJobStatusList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3">
                      <label class="mb-2"
                        >Engagement Status (No Bookings)</label
                      >
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Engagement Status'"
                        [(ngModel)]="jobDeleteJobStatusBookingSelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobDeleteJobStatusBookingList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3">
                      <label class="mb-2">Engagement Code</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Engagement Code'"
                        [(ngModel)]="jobDeleteJobCodeSelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobDeleteJobCodeList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                  </div>

                  <div class="d-flex justify-content-between p-3">
                    <div
                      class="d-flex flex-column"
                      *ngIf="jobDeleteIsLikeEdit === false"
                    >
                      <label class="mb-2">Suspended/Pended</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="jobDeleteIsPended"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div class="d-flex flex-column">
                      <label class="mb-2">Successfactor Engagements</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="jobDeleteIsTR"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>

                    <div class="d-flex flex-column">
                      <label class="mb-2">Individual View</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="jobDeleteIndividualView"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div class="d-flex flex-column">
                      <label class="mb-2">Engagement View</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="jobDeleteIEngagementView"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                  </div>
                </fieldset>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="toggle-config mb-1">
      <mat-accordion>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>REPLACE CONFIGURATION</mat-panel-title>
          </mat-expansion-panel-header>
          <mat-accordion>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Resource Replace Configuration
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="toggle-body-content py-3">
                <div class="px-4 mb-2 d-flex justify-content-between">
                  <div class="d-flex">
                    <div class="d-flex flex-column">
                      <label class="mb-2">Is Like Edit</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="resReplaceIsLikeEdit"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div
                      class="d-flex flex-column mx-5"
                      *ngIf="resReplaceIsLikeEdit === false"
                    >
                      <label class="mb-2">Link</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="resReplaceLink"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                  </div>

                  <div
                    class="d-flex flex-column"
                    *ngIf="resReplaceIsLikeEdit === false"
                  >
                    <label class="mb-2">Read Only</label>
                    <input
                      type="checkbox"
                      (change)="makeDirty()"
                      class="checkbox"
                      [(ngModel)]="resReplaceIsDisabled"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </div>
                </div>

                <div
                  class="px-4 d-flex justify-content-between"
                  *ngIf="resReplaceIsLikeEdit === false"
                >
                  <fieldset
                    [ngClass]="{ 'bf p-2': resReplaceLink == true }"
                    class="fs-left"
                  >
                    <legend *ngIf="resReplaceLink == true" class="lgnd">
                      Link
                    </legend>
                    <label class="mb-2">Sub Area</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Sub Area'"
                      [(ngModel)]="resReplaceSubAreaSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="resReplaceSubAreaList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </fieldset>
                  <fieldset
                    [ngClass]="{ 'bf p-2': resReplaceLink == true }"
                    class="d-flex justify-content-between fs-right"
                  >
                    <legend *ngIf="resReplaceLink == true" class="lgnd">
                      Link
                    </legend>
                    <div class="slct-width-rhs">
                      <label class="mb-2">Managerial Country</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Managerial Country'"
                        [settings]="dropdownSettings"
                        [data]="resReplaceManageCountryList"
                        [(ngModel)]="resReplaceManageCountrySelectedItems"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="slct-width-rhs">
                      <label class="mb-2">Legal Entity</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Legal Entity'"
                        [(ngModel)]="resReplaceLegalEntitySelectedItems"
                        [settings]="dropdownSettings"
                        [data]="resReplaceLegalEntityList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="slct-width-rhs">
                      <label class="mb-2">Business Unit</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Business Unit'"
                        [(ngModel)]="resReplaceBusinessUnitSelectedItems"
                        [settings]="dropdownSettings"
                        [data]="resReplaceBusinessUnitList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                  </fieldset>
                </div>
                <div class="row p-4" *ngIf="resReplaceIsLikeEdit === false">
                  <div class="col-lg-3 col-md-3 col-sm-3">
                    <label class="mb-2">Service Line</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Service Line'"
                      [(ngModel)]="resReplaceServiceLineSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="resReplaceServiceLineList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <label class="mb-2">Sub Service Line</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Sub Service Line'"
                      [(ngModel)]="resReplaceSubServiceLineSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="resReplaceSubServiceLineList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                  <div class="col-lg-5 col-md-5 col-sm-5">
                    <label class="mb-2">Sub Management Unit</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Sub Management Unit'"
                      [(ngModel)]="resReplaceSubManagementUnitSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="resReplaceSubManagementUnitList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                </div>
                <fieldset class="bf m-4" *ngIf="resReplaceIsLikeEdit === false">
                  <legend class="lgnd">Disable</legend>
                  <div class="d-flex justify-content-between p-3">
                    <div class="d-flex flex-column">
                      <label class="mb-2">InActive Resources</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="resReplaceIsActive"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div class="d-flex flex-column">
                      <label class="mb-2">Individual View</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="resReplaceIndividualView"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div class="d-flex flex-column">
                      <label class="mb-2">Engagement View</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="resReplaceEngagementView"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                  </div>
                </fieldset>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Engagement Replace Configuration
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="toggle-body-content py-3">
                <div class="px-4 mb-2 d-flex justify-content-between">
                  <div class="d-flex">
                    <div class="d-flex flex-column">
                      <label class="mb-2">Is Like Edit</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="jobReplaceIsLikeEdit"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div
                      class="d-flex flex-column mx-5"
                      *ngIf="jobReplaceIsLikeEdit === false"
                    >
                      <label class="mb-2">Link</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="jobReplaceLink"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                  </div>

                  <div
                    class="d-flex flex-column"
                    *ngIf="jobReplaceIsLikeEdit === false"
                  >
                    <label class="mb-2">Read Only</label>
                    <input
                      type="checkbox"
                      (change)="makeDirty()"
                      class="checkbox"
                      [(ngModel)]="jobReplaceIsDisabled"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </div>
                </div>
                <div
                  class="px-4 d-flex justify-content-between"
                  *ngIf="jobReplaceIsLikeEdit === false"
                >
                  <fieldset
                    [ngClass]="{ 'bf p-2': jobReplaceLink == true }"
                    class="fs-left"
                  >
                    <legend *ngIf="jobReplaceLink == true" class="lgnd">
                      Link
                    </legend>
                    <label class="mb-2">Sub Area</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Sub Area'"
                      [(ngModel)]="jobReplaceSubAreaSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="jobReplaceSubAreaList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </fieldset>
                  <fieldset
                    [ngClass]="{ 'bf p-2': jobReplaceLink == true }"
                    class="d-flex justify-content-between fs-right"
                  >
                    <legend *ngIf="jobReplaceLink == true" class="lgnd">
                      Link
                    </legend>
                    <div class="slct-width-rhs">
                      <label class="mb-2">Managerial Country</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Managerial Country'"
                        [(ngModel)]="jobReplaceManageCountrySelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobReplaceManageCountryList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="slct-width-rhs">
                      <label class="mb-2">Legal Entity</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Legal Entity'"
                        [(ngModel)]="jobReplaceLegalEntitySelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobReplaceLegalEntityList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="slct-width-rhs">
                      <label class="mb-2">Business Unit</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Business Unit'"
                        [(ngModel)]="jobReplaceBusinessUnitSelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobReplaceBusinessUnitList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                  </fieldset>
                </div>
                <div class="row p-4" *ngIf="jobReplaceIsLikeEdit === false">
                  <div class="col-lg-3 col-md-3 col-sm-3">
                    <label class="mb-2">Service Line</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Service Line'"
                      [(ngModel)]="jobReplaceServiceLineSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="jobReplaceServiceLineList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <label class="mb-2">Sub Service Line</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Sub Service Line'"
                      [(ngModel)]="jobReplaceSubServiceLineSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="jobReplaceSubServiceLineList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                  <div class="col-lg-5 col-md-5 col-sm-5">
                    <label class="mb-2">Sub Management Unit</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Sub Management Unit'"
                      [(ngModel)]="jobReplaceSubManagementUnitSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="jobReplaceSubManagementUnitList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                </div>
                <div class="row px-4" *ngIf="jobReplaceIsLikeEdit === false">
                  <div class="col-lg-5 col-md-5 col-sm-5">
                    <label class="mb-2">Engagement Code</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Engagement Code'"
                      [(ngModel)]="jobReplaceIncludeJobCodeSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="jobReplaceIncludeJobCodeList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                </div>
                <fieldset class="bf m-4" *ngIf="jobReplaceIsLikeEdit === false">
                  <legend class="lgnd">Disable</legend>
                  <div class="row px-3">
                    <div class="col-lg-3 col-md-3 col-sm-3">
                      <label class="mb-2">Engagement Category</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Engagement Category'"
                        [(ngModel)]="jobReplaceJobCategorySelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobReplaceJobCategoryList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3">
                      <label class="mb-2">Engagement Status</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Engagement Status'"
                        [(ngModel)]="jobReplaceJobStatusSelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobReplaceJobStatusList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3">
                      <label class="mb-2"
                        >Engagement Status (No Bookings)</label
                      >
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Engagement Status'"
                        [(ngModel)]="jobReplaceJobStatusBookingSelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobReplaceJobStatusBookingList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3">
                      <label class="mb-2">Engagement Code</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Engagement Code'"
                        [(ngModel)]="jobReplaceJobCodeSelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobReplaceJobCodeList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                  </div>

                  <div class="d-flex justify-content-between p-3">
                    <div
                      class="d-flex flex-column"
                      *ngIf="jobReplaceIsLikeEdit === false"
                    >
                      <label class="mb-2">Suspended/Pended</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="jobReplaceIsPended"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div class="d-flex flex-column">
                      <label class="mb-2">Successfactor Engagements</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="jobReplaceIsTR"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>

                    <div class="d-flex flex-column">
                      <label class="mb-2">Individual View</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="jobReplaceIndividualView"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div class="d-flex flex-column">
                      <label class="mb-2">Engagement View</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="jobReplaceEngagementView"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                  </div>
                </fieldset>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="toggle-config mb-1">
      <mat-accordion>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>REPLACE ALL CONFIGURATION</mat-panel-title>
          </mat-expansion-panel-header>
          <mat-accordion>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Resource Replace All Configuration
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="toggle-body-content py-3">
                <div class="px-4 mb-2 d-flex justify-content-between">
                  <div class="d-flex">
                    <div class="d-flex flex-column">
                      <label class="mb-2">Is Like Edit</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="resReplaceAllIsLikeEdit"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div
                      class="d-flex flex-column mx-5"
                      *ngIf="resReplaceAllIsLikeEdit === false"
                    >
                      <label class="mb-2">Link</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="resReplaceAllLink"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                  </div>
                  <div
                    class="d-flex flex-column"
                    *ngIf="resReplaceAllIsLikeEdit === false"
                  >
                    <label class="mb-2">Read Only</label>
                    <input
                      type="checkbox"
                      (change)="makeDirty()"
                      class="checkbox"
                      [(ngModel)]="resReplaceAllIsDisabled"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </div>
                </div>

                <div
                  class="px-4 d-flex justify-content-between"
                  *ngIf="resReplaceAllIsLikeEdit === false"
                >
                  <fieldset
                    [ngClass]="{ 'bf p-2': resReplaceAllLink == true }"
                    class="fs-left"
                  >
                    <legend *ngIf="resReplaceAllLink == true" class="lgnd">
                      Link
                    </legend>
                    <label class="mb-2">Sub Area</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Sub Area'"
                      [(ngModel)]="resReplaceAllSubAreaSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="resReplaceAllSubAreaList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </fieldset>
                  <fieldset
                    [ngClass]="{ 'bf p-2': resReplaceAllLink == true }"
                    class="d-flex justify-content-between fs-right"
                  >
                    <legend *ngIf="resReplaceAllLink == true" class="lgnd">
                      Link
                    </legend>
                    <div class="slct-width-rhs">
                      <label class="mb-2">Managerial Country</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Managerial Country'"
                        [settings]="dropdownSettings"
                        [data]="resReplaceAllManageCountryList"
                        [(ngModel)]="resReplaceAllManageCountrySelectedItems"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="slct-width-rhs">
                      <label class="mb-2">Legal Entity</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Legal Entity'"
                        [(ngModel)]="resReplaceAllLegalEntitySelectedItems"
                        [settings]="dropdownSettings"
                        [data]="resReplaceAllLegalEntityList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="slct-width-rhs">
                      <label class="mb-2">Business Unit</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Business Unit'"
                        [(ngModel)]="resReplaceAllBusinessUnitSelectedItems"
                        [settings]="dropdownSettings"
                        [data]="resReplaceAllBusinessUnitList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                  </fieldset>
                </div>
                <div class="row p-4" *ngIf="resReplaceAllIsLikeEdit === false">
                  <div class="col-lg-3 col-md-3 col-sm-3">
                    <label class="mb-2">Service Line</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Service Line'"
                      [(ngModel)]="resReplaceAllServiceLineSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="resReplaceAllServiceLineList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <label class="mb-2">Sub Service Line</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Sub Service Line'"
                      [(ngModel)]="resReplaceAllSubServiceLineSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="resReplaceAllSubServiceLineList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                  <div class="col-lg-5 col-md-5 col-sm-5">
                    <label class="mb-2">Sub Management Unit</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Sub Management Unit'"
                      [(ngModel)]="resReplaceAllSubManagementUnitSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="resReplaceAllSubManagementUnitList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                </div>
                <fieldset
                  class="bf m-4"
                  *ngIf="resReplaceAllIsLikeEdit === false"
                >
                  <legend class="lgnd">Disable</legend>
                  <div class="d-flex justify-content-between p-3">
                    <div class="d-flex flex-column">
                      <label class="mb-2">InActive Resources</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="resReplaceAllIsActive"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div class="d-flex flex-column">
                      <label class="mb-2">Individual View</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="resReplaceAllIndividualView"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div class="d-flex flex-column">
                      <label class="mb-2">Engagement View</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="resReplaceAllEngagementView"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                  </div>
                </fieldset>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Engagement Replace All Configuration
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="toggle-body-content py-3">
                <div class="px-4 mb-2 d-flex justify-content-between">
                  <div class="d-flex">
                    <div class="d-flex flex-column">
                      <label class="mb-2">Is Like Edit</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="jobReplaceAllIsLikeEdit"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div
                      class="d-flex flex-column mx-5"
                      *ngIf="jobReplaceAllIsLikeEdit === false"
                    >
                      <label class="mb-2">Link</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="jobReplaceAllLink"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                  </div>
                  <div
                    class="d-flex flex-column"
                    *ngIf="jobReplaceAllIsLikeEdit === false"
                  >
                    <label class="mb-2">Read Only</label>
                    <input
                      type="checkbox"
                      (change)="makeDirty()"
                      class="checkbox"
                      [(ngModel)]="jobReplaceAllIsDisabled"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </div>
                </div>

                <div
                  class="px-4 d-flex justify-content-between"
                  *ngIf="jobReplaceAllIsLikeEdit === false"
                >
                  <fieldset
                    [ngClass]="{ 'bf p-2': jobReplaceAllLink == true }"
                    class="fs-left"
                  >
                    <legend *ngIf="jobReplaceAllLink == true" class="lgnd">
                      Link
                    </legend>
                    <label class="mb-2">Sub Area</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Sub Area'"
                      [(ngModel)]="jobReplaceAllSubAreaSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="jobReplaceAllSubAreaList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </fieldset>
                  <fieldset
                    [ngClass]="{ 'bf p-2': jobReplaceAllLink == true }"
                    class="d-flex justify-content-between fs-right"
                  >
                    <legend *ngIf="jobReplaceAllLink == true" class="lgnd">
                      Link
                    </legend>
                    <div class="slct-width-rhs">
                      <label class="mb-2">Managerial Country</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Managerial Country'"
                        [(ngModel)]="jobReplaceAllManageCountrySelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobReplaceAllManageCountryList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="slct-width-rhs">
                      <label class="mb-2">Legal Entity</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Legal Entity'"
                        [(ngModel)]="jobReplaceAllLegalEntitySelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobReplaceAllLegalEntityList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="slct-width-rhs">
                      <label class="mb-2">Business Unit</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Business Unit'"
                        [(ngModel)]="jobReplaceAllBusinessUnitSelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobReplaceAllBusinessUnitList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                  </fieldset>
                </div>
                <div class="row p-4" *ngIf="jobReplaceAllIsLikeEdit === false">
                  <div class="col-lg-3 col-md-3 col-sm-3">
                    <label class="mb-2">Service Line</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Service Line'"
                      [(ngModel)]="jobReplaceAllServiceLineSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="jobReplaceAllServiceLineList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <label class="mb-2">Sub Service Line</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Sub Service Line'"
                      [(ngModel)]="jobReplaceAllSubServiceLineSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="jobReplaceAllSubServiceLineList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                  <div class="col-lg-5 col-md-5 col-sm-5">
                    <label class="mb-2">Sub Management Unit</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Sub Management Unit'"
                      [(ngModel)]="jobReplaceAllSubManagementUnitSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="jobReplaceAllSubManagementUnitList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                </div>
                <div class="row px-4" *ngIf="jobReplaceAllIsLikeEdit === false">
                  <div class="col-lg-5 col-md-5 col-sm-5">
                    <label class="mb-2">Engagement Code</label>
                    <ng-multiselect-dropdown
                      [placeholder]="'Select Engagement Code'"
                      [(ngModel)]="jobReplaceAllIncludeJobCodeSelectedItems"
                      [settings]="dropdownSettings"
                      [data]="jobReplaceAllIncludeJobCodeList"
                      (onSelect)="makeDirty()"
                      (onDeSelect)="makeDirty()"
                    >
                    </ng-multiselect-dropdown>
                  </div>
                </div>
                <fieldset
                  class="bf m-4"
                  *ngIf="jobReplaceAllIsLikeEdit === false"
                >
                  <legend class="lgnd">Disable</legend>
                  <div class="row px-3">
                    <div class="col-lg-3 col-md-3 col-sm-3">
                      <label class="mb-2">Engagement Category</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Engagement Category'"
                        [(ngModel)]="jobReplaceAllJobCategorySelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobReplaceAllJobCategoryList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3">
                      <label class="mb-2">Engagement Status</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Engagement Status'"
                        [(ngModel)]="jobReplaceAllJobStatusSelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobReplaceAllJobStatusList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3">
                      <label class="mb-2"
                        >Engagement Status (No Bookings)</label
                      >
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Engagement Status'"
                        [(ngModel)]="jobReplaceAllJobStatusBookingSelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobReplaceAllJobStatusBookingList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3">
                      <label class="mb-2">Engagement Code</label>
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Engagement Code'"
                        [(ngModel)]="jobReplaceAllJobCodeSelectedItems"
                        [settings]="dropdownSettings"
                        [data]="jobReplaceAllJobCodeList"
                        (onSelect)="makeDirty()"
                        (onDeSelect)="makeDirty()"
                      >
                      </ng-multiselect-dropdown>
                    </div>
                  </div>

                  <div class="d-flex justify-content-between p-3">
                    <div
                      class="d-flex flex-column"
                      *ngIf="jobReplaceAllIsLikeEdit === false"
                    >
                      <label class="mb-2">Suspended/Pended</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="jobReplaceAllIsPended"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div class="d-flex flex-column">
                      <label class="mb-2">Successfactor Engagements</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="jobReplaceAllIsTR"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>

                    <div class="d-flex flex-column">
                      <label class="mb-2">Individual View</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="jobReplaceAllIndividualView"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div class="d-flex flex-column">
                      <label class="mb-2">Engagement View</label>
                      <input
                        type="checkbox"
                        (change)="makeDirty()"
                        class="checkbox"
                        [(ngModel)]="jobReplaceAllEngagementView"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                  </div>
                </fieldset>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="toggle-config mb-1">
      <mat-accordion>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>BOOKING CONFIGURATION</mat-panel-title>
          </mat-expansion-panel-header>
          <div class="toggle-body-content p-3">
            <fieldset class="bf p-3">
              <legend class="lgnd">
                Disable Action on Current Week Bookings
              </legend>
              <div class="row pb-10">
                <div class="col-lg-1 col-md-1 col-sm-1 d-flex flex-column">
                  <label class="mb-2">Add</label>
                  <input
                    type="checkbox"
                    (change)="makeDirty()"
                    class="checkbox"
                    [(ngModel)]="bookingCurrentWeekAdd"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
                <div class="col-lg-1 col-md-1 col-sm-1 d-flex flex-column">
                  <label class="mb-2">Edit</label>
                  <input
                    type="checkbox"
                    (change)="makeDirty()"
                    class="checkbox"
                    [(ngModel)]="bookingCurrentWeekEdit"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
                <div class="col-lg-1 col-md-1 col-sm-1 d-flex flex-column">
                  <label class="mb-2">Delete</label>
                  <input
                    type="checkbox"
                    (change)="makeDirty()"
                    class="checkbox"
                    [(ngModel)]="bookingCurrentWeekDelete"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
                <div class="col-lg-1 col-md-1 col-sm-1 d-flex flex-column">
                  <label class="mb-2">Copy</label>
                  <input
                    type="checkbox"
                    (change)="makeDirty()"
                    class="checkbox"
                    [(ngModel)]="bookingCurrentWeekCopy"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 d-flex flex-column">
                  <label class="mb-2">Replace</label>
                  <input
                    type="checkbox"
                    (change)="makeDirty()"
                    class="checkbox"
                    [(ngModel)]="bookingCurrentWeekReplace"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 d-flex flex-column">
                  <label class="mb-2">Replace All</label>
                  <input
                    type="checkbox"
                    (change)="makeDirty()"
                    class="checkbox"
                    [(ngModel)]="bookingCurrentWeekReplaceAll"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
              </div>
            </fieldset>
            <fieldset class="bf p-3 my-2">
              <legend class="lgnd">Disable Action on All Bookings</legend>
              <div class="row pb-13">
                <div class="col-lg-1 col-md-1 col-sm-1 d-flex flex-column">
                  <label class="mb-2">Edit </label>
                  <input
                    type="checkbox"
                    (change)="makeDirty()"
                    class="checkbox"
                    [(ngModel)]="bookigDisabledEdit"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
                <div class="col-lg-1 col-md-1 col-sm-1 d-flex flex-column">
                  <label class="mb-2">Delete </label>
                  <input
                    type="checkbox"
                    (change)="makeDirty()"
                    class="checkbox"
                    [(ngModel)]="bookigDisabledDelete"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
                <div class="col-lg-1 col-md-1 col-sm-1 d-flex flex-column">
                  <label class="mb-2">Copy </label>
                  <input
                    type="checkbox"
                    (change)="makeDirty()"
                    class="checkbox"
                    [(ngModel)]="bookigDisabledCopy"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
                <div class="col-lg-1 col-md-1 col-sm-1 d-flex flex-column">
                  <label class="mb-2">Replace</label>
                  <input
                    type="checkbox"
                    (change)="makeDirty()"
                    class="checkbox"
                    [(ngModel)]="bookigDisabledReplace"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 d-flex flex-column">
                  <label class="mb-2">Replace All</label>
                  <input
                    type="checkbox"
                    (change)="makeDirty()"
                    class="checkbox"
                    [(ngModel)]="bookigDisabledReplaceAll"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
              </div>
            </fieldset>
            <fieldset class="bf p-3 my-2">
              <legend class="lgnd">
                Disable Action on Successfactor Bookings
              </legend>

              <div class="d-flex justify-content-start">
                <div class="col-lg-3 col-md-3 col-sm-3 d-flex flex-column">
                  <label class="mb-2">Edit </label>
                  <input
                    type="checkbox"
                    (change)="makeDirty()"
                    class="checkbox"
                    [(ngModel)]="bookigTrainingEngagementEdit"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 d-flex flex-column">
                  <label class="mb-2">Delete </label>
                  <input
                    type="checkbox"
                    (change)="makeDirty()"
                    class="checkbox"
                    [(ngModel)]="bookigTrainingEngagementDelete"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
              </div>
            </fieldset>
            <fieldset class="bf p-3 my-2">
              <legend class="lgnd">Disable Actions on Ghost booking</legend>
              <div class="row pb-13">
                <div class="col-lg-1 col-md-1 col-sm-1 d-flex flex-column">
                  <label class="mb-2">View </label>
                  <input
                    type="checkbox"
                    (change)="makeDirty()"
                    class="checkbox"
                    [disabled]="!disableActionsGhostBooking"
                    [(ngModel)]="bookingDisabledGhostView"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
                <div class="col-lg-1 col-md-1 col-sm-1 d-flex flex-column">
                  <label class="mb-2">Edit </label>
                  <input
                    type="checkbox"
                    (change)="makeDirty()"
                    class="checkbox"
                    [disabled]="!disableActionsGhostBooking"
                    [(ngModel)]="bookingDisabledGhostEdit"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
                <div class="col-lg-1 col-md-1 col-sm-1 d-flex flex-column">
                  <label class="mb-2">Delete </label>
                  <input
                    type="checkbox"
                    (change)="makeDirty()"
                    class="checkbox"
                    [disabled]="!disableActionsGhostBooking"
                    [(ngModel)]="bookingDisabledGhostDelete"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
                <div class="col-lg-1 col-md-1 col-sm-1 d-flex flex-column">
                  <label class="mb-2">Copy </label>
                  <input
                    type="checkbox"
                    (change)="makeDirty()"
                    class="checkbox"
                    [disabled]="!disableActionsGhostBooking"
                    [(ngModel)]="bookingDisabledGhostCopy"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
                <div class="col-lg-1 col-md-1 col-sm-1 d-flex flex-column">
                  <label class="mb-2">Replace</label>
                  <input
                    type="checkbox"
                    (change)="makeDirty()"
                    class="checkbox"
                    [disabled]="!disableActionsGhostBooking"
                    [(ngModel)]="bookingDisabledGhostReplace"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
              </div>
            </fieldset>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <div class="row my-3">
    <div class="d-flex justify-content-center my-3">
      <div class="col-lg-2 col-md-2 col-sm-2">
        <button
          class="cancel-btn"
          (click)="reset(Confirmation, 'reset')"
          [ngClass]="{
            'btn-disabled': !(buttonText == 'Submit') || dirtyFlag == false
          }"
          [disabled]="!(buttonText == 'Submit') || dirtyFlag == false"
        >
          Reset
        </button>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-2">
        <button
          class="cancel-btn"
          (click)="cancel(Confirmation, 'cancel')"
          [ngClass]="{
            'btn-disabled': !(buttonText == 'Submit')
          }"
          [disabled]="!(buttonText == 'Submit')"
        >
          Cancel
        </button>
      </div>
      <form [formGroup]="formGroup">
        <div class="col-lg-2 col-md-2 col-sm-2">
          <button
            class="add-btn"
            (click)="onSubmit()"
            type="submit"
            [ngClass]="{
              'btn-disabled': !(buttonText == 'Submit') || dirtyFlag == false
            }"
            [disabled]="!(buttonText == 'Submit') || dirtyFlag == false"
          >
            {{ buttonText }}
          </button>
        </div>
      </form>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 pr-0">
      <div class="row">
        <ng-template #Confirmation>
          <div class="modal-header">
            <h4 class="modal-title pull-left">
              <span class="glyphicon glyphicon-warning-sign pr-5"></span>Confirm
            </h4>
            <mat-icon (click)="modalRef.hide()">close</mat-icon>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div *ngIf="dirtyFlag">
                  The entered details will be lost. Are you sure you want to
                  {{ confirmationClickAction }}?
                </div>
                <div *ngIf="!dirtyFlag">
                  Are you sure you want to {{ confirmationClickAction }}?
                </div>
              </div>
              <div class="d-flex my-3">
                <button class="cancel-btn" (click)="this.modalRef.hide()">
                  No
                </button>
                <button class="add-btn mx-3" (click)="onConfirmAction()">
                  Yes
                </button>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
