import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { AuthService } from '../shared/auth/auth.service';
import { environment } from '../../environments/environment';
import { SharedServicesService } from '../shared/sharedservices/shared-services.service';
import { MsalService } from '@azure/msal-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { ResourceDto } from 'app/Models/ResourceDto';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  TokenSubscription: any;
  isTimeOut: boolean = false;

  resource: ResourceDto;
  postLogoutMsg = "You are Signed Out successfully.";
  isPostLogout: boolean = false;
  
  constructor(public auth: AuthService, 
    public restService: SharedServicesService, 
    private router: Router,
// todo: uncomment this to enable MSAL
    private msalService: MsalService,

    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {        
   this.isTimeOut = this.activatedRoute.snapshot.paramMap.get('isTimeOut') == "true";

    if (sessionStorage.getItem("resource") !== null 
    && sessionStorage.getItem("resource") !== undefined
    && environment.EnableAuth
    ) {      
      this.spinner.show();
      this.TokenSubscription = this.restService.blacklistToken().subscribe( res => {          
        this.spinner.hide();
        this.LogoutUser();
      });
    }
    else {
      this.isPostLogout = true;
      this.postLogout();
    }
  }

  private LogoutUser() {
    sessionStorage.clear();
    localStorage.clear();
    if(this.isTimeOut) {
      //alert('Session expired due to inactivity!'); 
      sessionStorage.setItem('isTimeOut', 'true');
    }
    
// todo: uncomment this to enable MSAL
    this.msalService.logout();

// todo: comment this to enable MSAL
    //this.token.refreshToken().subscribe((res) => { });
    //const redirectUrl = environment.redirectUrl;
    //const redirectUrl = environment.logoutUrl;
    //window.location.href = redirectUrl;  
  }

  postLogout() {   
    this.resource = new ResourceDto();
    this.restService.setHeaderDetails(this.resource);
    this.restService.setFooterDetails(this.resource);
    
    if(sessionStorage.getItem('isTimeOut') == 'true') {
      this.postLogoutMsg = "You are Signed Out due to inactivity.";
      sessionStorage.removeItem('isTimeOut');
    }
  }

  navigateToLogin() {
    this.router.navigate(['Login']);
  }
}
