import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LandingComponent } from './landing/landing.component';
import { ErrorComponent } from './error/error.component';
import { AccessErrorComponent } from './access-error/access-error.component';
import { IndividualBookingComponent } from './Views/individual/individual-booking/individual-booking.component';
import { RoleguardService as RoleGuard } from './shared/auth/roleguard.service';
import { RoleFeatureEnum } from './shared/enum';
import { SubscribePrivacyNoticeComponent } from './landing/privacy-notice/privacy-notice.component';
import { EngagementBookingComponent } from './Views/engagement/engagement-booking/engagement-booking.component';
import { ProfileSettingsComponent } from './Views/profile-settings/profile-settings.component';
import { RequestQueueComponent } from './Views/individualRequest/request-queue/request-queue.component';
import { LogoutComponent } from './logout/logout.component';
import { RoleConfigurationComponent } from './views/role-config/role-configuration/role-configuration.component';
import { WorkRequestComponent } from './Views/work-request/work-request.component';
import { CompletedRequestQueueComponent } from './Views/workRequestCompleted/completed-request-queue/completed-request-queue.component';
import { ApprovalQueue } from './Views/approvalQueue/approvalQueue.component';
import { PostLogoutComponent } from './logout/post-logout/post-logout.component';
import { engagementScheduleViewComponent } from './Views/scheduleView/engagementScheduleView/engagementScheduleView.component';
import { individualScheduleViewComponent } from './Views/scheduleView/individualScheduleView/individualScheduleView.component';
import { PendingChangesGuard } from './shared/pendingChangesGuard/pending-changes.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/Login',
    pathMatch: 'full',
  },
  {
    path: 'Login',
    component: LandingComponent,
  },
  {
    path: 'Error',
    component: ErrorComponent,
  },
  {
    path: 'AccessError',
    component: AccessErrorComponent,
  },
  {
    path: 'postlogout',
    component: PostLogoutComponent,
  },
  {
    path: 'Home',
    component: HomeComponent,
    canActivate: [RoleGuard],
  },
  {
    path: 'IndividualBooking', // 'Individual',
    component: IndividualBookingComponent,
    canActivate: [RoleGuard],
    data: { expectedRoleFeature: RoleFeatureEnum.IndividualBooking },
  },
  {
    path: 'EngagementBooking', // 'Engagement',
    component: EngagementBookingComponent,
    canActivate: [RoleGuard],
    data: { expectedRoleFeature: RoleFeatureEnum.EngagementBooking },
  },
  {
    path: 'RoleConfiguration',
    component: RoleConfigurationComponent,
    canActivate: [RoleGuard],
    data: { expectedRoleFeature: RoleFeatureEnum.RoleConfiguration },
  },
  {
    path: 'MyRequest',
    component: RequestQueueComponent,
    canActivate: [RoleGuard],
    data: { expectedRoleFeature: RoleFeatureEnum.MyRequest },
  },
  {
    path: 'WorkRequestQueue',
    component: WorkRequestComponent,
    canActivate: [RoleGuard],
    data: { expectedRoleFeature: RoleFeatureEnum.WorkRequestQueue },
  },
  {
    path: 'CompletedRequest',
    component: CompletedRequestQueueComponent,
    canActivate: [RoleGuard],
    data: { expectedRoleFeature: RoleFeatureEnum.CompletedRequest },
  },
  {
    path: 'ApprovalLayer',
    component: ApprovalQueue,
    canActivate: [RoleGuard],
    data: { expectedRoleFeature: RoleFeatureEnum.ApprovalQueue },
  },
  { path: 'PrivacyNotice', component: SubscribePrivacyNoticeComponent },
  {
    path: 'ProfileSettings',
    component: ProfileSettingsComponent,
    canActivate: [RoleGuard],
    data: { expectedRoleFeature: RoleFeatureEnum.UserProfile },
  },
  {
    path: 'IndividualScheduleView',
    component: individualScheduleViewComponent,
    canActivate: [RoleGuard],
    data: { expectedRoleFeature: RoleFeatureEnum.ScheduleView },
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'EngagementScheduleView',
    component: engagementScheduleViewComponent,
    canActivate: [RoleGuard],
    canDeactivate: [PendingChangesGuard],
    data: { expectedRoleFeature: RoleFeatureEnum.ScheduleView },
  },
  { path: 'Logout', component: LogoutComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
