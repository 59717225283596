export class JobVM {

    JobId: number;
    JobCode: string;
    JobDescription: string;
    JobType: string;
    ClientNumber: string;
    ClientDescription: string;
    JobCategory: string;
    UpdatedOn: Date | null;
    UpdatedBy: string;
    EngagementFullName: string;
    IsActive: boolean;
    JobStatus: string;
    ServiceLineCode: string;
    ServiceLineDescription: string;
    SubAreaDescription: string;
    LegalEntityCode: string;
    ManagerNameAndGPN: string;
    LoggedInResourceId: number;
    InstanceId: number;
    ManagerialCountryDescription: string;
    PartnerNameAndGPN: string;
    CostCenterDescription: string;
    InstanceName: string;
    PartnerName: string;

    ResultId:number;
    IsFavourite:boolean;
    FavUpdatedOn:Date | null;

}
