export class EngagementSubmissionVM
    {
        RequestId: number;
        JobId: number;
        MeraBookingId: number;
        BookingDetailId: number;
        ResourceId: number;
        ActionDescription: string;
        BookingType: string;
        JobCode: string;
        JobDescription: string;
        ResourceName: string;
        Description: string;
        Loading: number | null;
        Hours: number | null;
        StartDate: string;
        EndDate: string;
        Unconfirmed: boolean;
        Done: boolean;
        RankDescription: string;
        LocationDescription: string;
        SubmittedOn: string;
        SubmittedBy: string;
        PostSubmissionStatusChangedOn: string;
        PostSubmissionStatusChangedBy: string;
        CurrentUserId: string;
        CurrentUserName: string;
        CurrentUserEmail: string;
        UserSelection : string;
        
        IsReplacedEngagement : number;
        To_Engagement_Name : string;
        To_Engagement_Code : string;
        To_Client_Description  : string;
        To_Client_Number : string;
        From_Engagement_Name : string;
        From_Engagement_Code : string;
        From_Client_Description: string;
        From_Client_Number : string;
        To_Job_Id : number;
        From_Job_Id : number;
    }

export class EngagementSubmissionRecordVM {
    Done: boolean;
    RequestId: number;
    MeraBookingId: number;
}