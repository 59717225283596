import { MasterVM } from "./MasterVM";

export class ResourceConfigurationDto {
    ResViewManagerialCountries :MasterVM[];
    ResViewLegalEntities :MasterVM[];
    ResViewBusinessUnits:MasterVM[];
    ResViewOfficeLocations :MasterVM[];
    ResViewServiceLines:MasterVM[];
    ResViewSubServiceLines :MasterVM[];
    ResViewManagementUnits :MasterVM[]
    ResViewSubManageUnits :MasterVM[];
    ResViewRanks :MasterVM[];
    ResViewOperatingUnits :MasterVM[];
    ResViewDepartments : MasterVM[];
    ResViewResourceCostCenters: MasterVM[];
    ResViewGDSRegionAlignments : MasterVM[];
}

export class JobConfigurationDto {
    JobViewManagerialCountries :MasterVM[];
    JobViewBusinessUnits :MasterVM[];
    JobViewLegalEntities:MasterVM[];
    JobViewServiceLines :MasterVM[];
    JobViewSubServiceLines:MasterVM[];
    ManagementUnits :MasterVM[];
    JobViewSubManageUnits :MasterVM[]
    OperatingUnits :MasterVM[];
    JobViewJobCategories:MasterVM[];
    JobViewJobTypes :MasterVM[];
}

export class ConfigureVM {
    UA_Comments: string;
    UA_CreatedBy: number;
    UA_CreatedDate : string
    UA_ID: number;
    UA_J_Link: boolean;
    UA_ModifiedBy: number;
    UA_ModifiedDate: string;
    UA_Name: string;
    UA_R_Link: boolean;
    UA_U_UserId: number;
    userApproverJobConfigurationDto: JobConfigurationDto;
    userApproverResConfigurationDto:ResourceConfigurationDto;
    Message : string;
}