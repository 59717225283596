import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appHoursLoading]'
})
export class HoursLoadingDirective {
  @Input() isPercentageSelected: string;
// Allow decimal numbers and negative values
  private regex: RegExp;
  maxNum:any;
  charLength:any;
// Allow key codes for special events. Reflect :
// Backspace, tab, end, home
private specialKeys: Array<string> = [
  'Backspace',
  'Tab',
  'End',
  'Home',
  //'-',
  'ArrowLeft',
  'ArrowRight',
  'Del',
  'Delete',
];

constructor(private el: ElementRef) {}
ngOnInit() {
  if(this.isPercentageSelected){
     this.regex = new RegExp(/^\d{0,3}\.?\d{0,2}$/g);
     this.maxNum=1000;
     this.charLength=4;
  }else{
    this.regex = new RegExp(/^\d{0,4}\.?\d{0,2}$/g);
    this.maxNum=10000;
    this.charLength=5;
  }
}
@HostListener('keydown', ['$event'])
onKeyDown(event: KeyboardEvent) {
  // Allow Backspace, tab, end, and home keys
  if (this.specialKeys.indexOf(event.key) !== -1) {
    return;
  }
  let current: string = this.el.nativeElement.value;
  const position = this.el.nativeElement.selectionStart;
  const next: string = [
    current.slice(0, position),
    event.key == 'Decimal' ? '.' : event.key,
    current.slice(position),
  ].join('');

  const beforeDecimal: any = next.split('.')[0];
  const AfterDecimal: any = next.split('.')[1];
  const checkDotFirstPosition: string = next.substring(0, 1);
  if (next === '00') {
    event.preventDefault();
  }
  if (checkDotFirstPosition === '.') {
    event.preventDefault();
  }
  if (beforeDecimal) {
    if (beforeDecimal <= this.maxNum) {
      if (beforeDecimal.toString().length >= this.charLength) {
        event.preventDefault();
      }
    } else {
      if (beforeDecimal.toString().length >= 3) {
        event.preventDefault();
      }
    }
  }
  if (AfterDecimal) {
    if (AfterDecimal.toString().length >= 3) {
      event.preventDefault();
    }
  }
  if (next && !String(next).match(this.regex)) {
    event.preventDefault();
  }
}

}
