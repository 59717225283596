import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

@Component({
    templateUrl: './confirmation.component.html'
})
export class ConfirmationComponent {
    @Input() modelData : {};
    @Output()  onClose = new EventEmitter<boolean>();
    constructor(private _bsModalRef: BsModalRef) {

    }

    public onConfirm(): void {
        this.onClose.emit(true);
        this._bsModalRef.hide();
    }

    public onCancel(): void {
        this.onClose.emit(false);
        this._bsModalRef.hide();
    }
}