export class ResourceBookingVM {
    BookingId: number;
    JobId: number;
    MeraBookingId: number;
    RequestId: number;
    ResourceId: number;
    ActionDescriptionId: number;
    BookingType: string;
    EngagementName: string;
    ActionDescription: string;
    StatusDescription: string;
    StartDate: string | null;
    EndDate: string | null;
    Hours: number | null;
    Loading: number | null;
    LoadingPercentage: string | null;
    ResourceName: string;
    JodCategory: string;
    StatusId: number;
    UpdatedOn: string;
    UpdatedBy: string;
    Unconfirmed: boolean;
    UnconfirmedYesNo: string;
    Ghost: boolean;
    GhostYesNo: string;
    Rank: string;
    Description: string;
    EngagementCode: string;
    Engagement: string;
    MeraBookingStatusId: number;
    EngagementType: string;
    
    JobStatus: string;
    ServiceLineCode: string;
    ServiceLineDescription: string;

    ClientDescription: string;
    JobType: string;
    RankDescription: string;
    LocationDescription: string;

    JobCode:string;
    JobDescription:string;
    JobCategory:string;
    IsJobActive: boolean;
    CurrentUserName: string;
    CurrentUserId: number;
    CurrentUserEmail: string;
    IsPercentSelected: boolean;
    IsSplitOneEdit : boolean;
    GPN : boolean;

    check: boolean;
    RequestMode : number;
    IsOverallocation:boolean;

}