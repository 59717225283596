import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { UserVM } from '../../Models/UserVM';
import { environment } from '../../../environments/environment';
import { ResourceDto } from '../../Models/ResourceDto';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { EmailTypeVM } from '../../Models/EmailTypeVM';
import { PrivacyNoticeVM } from '../../Models/PrivacyNoticeVM';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { IndividualBookingVM } from 'app/Models/IndividualBookingVM';
import { BookingActionDto } from 'app/Models/BookingActionDto';
import { BookingDto } from 'app/Models/BookingDto';
import moment from 'moment';

@Directive()
@Injectable({
  providedIn: 'root',
})
export class SharedServicesService extends BehaviorSubject<any> {
  @Output() change: EventEmitter<boolean> = new EventEmitter();
  private resource = new ResourceDto();
  private setHeaderData = new BehaviorSubject(this.resource);
  setHeaderDetailsEvent = this.setHeaderData.asObservable();

  private setFooterData = new BehaviorSubject(this.resource);
  setFooterDetailsEvent = this.setFooterData.asObservable();
  private userInstances;
  public activeLink = 'Home';
  today = new Date();
  todaysDate = new Date(
    this.today.getFullYear(),
    this.today.getMonth(),
    this.today.getDate(),
    0,
    0,
    0
  );
  public defaultStartDate: Date | null;
  public defaultEndDate: Date | null;

  endpoint = environment.apiEndpoint;

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {
    super(null);
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  private getAPI(actionName: string): string {
    this.auth.checkTokenExpiredAndRefresh();
    return this.endpoint + 'UserProfile/' + actionName;
  }
  private getMasrterAPI(actionName: string): string {
    this.auth.checkTokenExpiredAndRefresh();
    return this.endpoint + 'Master/' + actionName;
  }

  getUserProfile(): Observable<any> {
    return this.http
      .get<UserVM>(this.getAPI('FetchUserProfile'), this.httpOptions)
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('getUserProfile'))
      );
  }

  loginAndGetUserProfile(requestMode: number): Observable<UserVM> {
    return this.http
      .post<UserVM>(this.getAPI('LoginAndFetchUserProfile'), requestMode, {
        observe: 'response',
      })
      .pipe(
        map((res) => {
          sessionStorage.setItem('sessionkey', res.headers.get('x-sessionkey'));
          return res.body;
        }),
        catchError(this.handleError<any>(null))
      );
  }

  updateEmailAlertStatus(user: UserVM): Observable<any> {
    return this.http
      .post<UserVM>(
        this.getAPI('UpdateEmailAlertStatus'),
        user,
        this.httpOptions
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('onEmailSwitchChange'))
      );
  }
  updateDefaultPercentSelection(
    Email: String,
    IsPercentSelected: Boolean
  ): Observable<any> {
    return this.http
      .post<boolean>(
        this.getAPI(
          'UpdateDefaultPercentSelection?Email=' +
            Email +
            '&IsPercentSelected=' +
            IsPercentSelected
        ),
        this.httpOptions
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('onPercentSwitchChange'))
      );
  }
  updatePrivacyNoticeStatus(): Observable<any> {
    return this.http
      .post<UserVM>(this.getAPI('UpdatePrivacyNoticeStatus'), this.httpOptions)
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('UpdatePrivacyNoticeStatus'))
      );
  }

  setHeaderDetails(objResourceVM) {
    this.setHeaderData.next(objResourceVM);
  }
  setFooterDetails(objResourceVM) {
    this.setFooterData.next(objResourceVM);
  }

  private handleError<T>(result?: T) {
    // return (error: any): Observable<T> => {
    //   console.error(error); // log to console instead
    //   return of(result as T);
    // };
    return (error: any): Observable<T> => {
      let status = '';
      let msg = '';
      if (Object.prototype.hasOwnProperty.call(error, 'status')) {
        status = error.status.toString();
      }
      if (typeof error.error === 'string') {
        msg = error.error;
      } else if (Object.prototype.hasOwnProperty.call(error.error, 'title')) {
        msg = error.error.title;
      } else if (
        Object.prototype.hasOwnProperty.call(error.error, 'customException')
      ) {
        msg = error.error.customException;
        msg +=
          `
          ` + JSON.stringify(error.error.customData);
      }
      if (status === '0' && (!msg || msg.trim().length === 0)) {
        msg = 'MERA Web API Service is down or inaccessible.';
      }

      let exceptionPrefix = '';
      if (status === '400') {
        exceptionPrefix = 'Unexpected error, please try later';
      }

      let alertMsg =
        `
      Response for MERA Web API Service - 
      Status Code: ` +
        status +
        `
      Message: ` +
        exceptionPrefix +
        ` - 
      ` +
        msg;

      alert(alertMsg);
      //alert(JSON.stringify(error)); //todo: remove/comment on prod
      console.error(alertMsg);
      console.error(JSON.stringify(error)); // log to console instead

      // if http response status is 401-Unauthorized
      if (status === '401') {
        this.router.navigate(['AccessError']);
      } else if (status === '0') {
        //MERA Web API Service is down or inaccessible.
        // todo: show diff page
        //this.router.navigate(['AccessError']);
      }

      this.spinner.hide();

      return of(result as T);
    };
  }

  GetModifiedDate(): Observable<any> {
    return this.http
      .get<Date>(this.getAPI('GetModifiedDate'), this.httpOptions)
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>(''))
      );
  }

  setEmailType(user: UserVM): Observable<any> {
    return this.http
      .post<UserVM>(this.getAPI('SetEmailType'), user, this.httpOptions)
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('onEmailTypeChange'))
      );
  }

  getEmailTypeData(): Observable<any> {
    return this.http
      .get<EmailTypeVM[]>(this.getAPI('GetEmailTypeData'), this.httpOptions)
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('getEmailTypeData'))
      );
  }

  getAuthorizationError(): Observable<any> {
    return this.http.get<any>(this.getAPI('GetAuthorizationError'));
  }

  getFAQFileLocation(): Observable<any> {
    return this.http
      .get<string>(this.getAPI('GetFAQFileLocation'), this.httpOptions)
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>(''))
      );
  }

  getReportLink(): Observable<any> {
    return this.http
      .get<string>(this.getAPI('FetchReportLink'), this.httpOptions)
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>(''))
      );
  }

  getRegion(): Observable<any> {
    return this.http
      .get<string>(this.getAPI('GetRegion'), this.httpOptions)
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>(''))
      );
  }

  blacklistToken(): Observable<any> {
    return this.http
      .get<string>(this.getAPI('BlacklistToken'), this.httpOptions)
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>(''))
      );
  }

  updateLoginRefreshTime(): Observable<any> {
    return this.http
      .get(this.getAPI('UpdateLoginRefreshTime'), this.httpOptions)
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>(''))
      );
  }

  getPrivacyNoticeData(): Observable<any> {
    return this.http
      .get<PrivacyNoticeVM[]>(
        this.getAPI('FetchPrivacyNotice'),
        this.httpOptions
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('FetchPrivacyNotice'))
      );
  }

  getUserResourceInstances(resource: ResourceDto): Observable<any> {
    return this.http
      .post<any>(
        this.getAPI('GetUserResourceInstances'),
        resource,
        this.httpOptions
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('getUserResourceInstances'))
      );
  }
  getUserInstances(): Observable<any> {
    return this.http
      .get<any>(this.getAPI('GetUserInstances'), this.httpOptions)
      .pipe(
        map((data) => {
          this.userInstances = data;
        }),
        catchError(this.handleError<any>('GetUserInstances'))
      );
  }
  getStoredUserInstances(): any {
    return this.userInstances;
  }

  updateUserProfile(user): Observable<any> {
    return this.http
      .post<any>(this.getAPI('UpdateUserProfile'), user, this.httpOptions)
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('UpdateUserProfile'))
      );
  }
  getDashboardCounts(): Observable<any> {
    return this.http
      .get<any>(this.getAPI('GetDashboardCounts'), this.httpOptions)
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('GetDashboardCounts'))
      );
  }

  getDateFormat(usersDefaultDateFormat: string = null) {
    if (!usersDefaultDateFormat) {
      let loggedInUser = JSON.parse(sessionStorage.getItem('resource'));
      if (loggedInUser) {
        usersDefaultDateFormat = loggedInUser.DefaultDateFormat;
      }
    }
    let f =
      !usersDefaultDateFormat || usersDefaultDateFormat === 'System date format'
        ? this.getLocalDateFormat()
        : usersDefaultDateFormat;
    return f;
  }

  getFormattedDateString(dt: Date, format: string = null) {
    var f = this.getDateFormat(format);

    var y = dt.getFullYear(),
      m = dt.getMonth() + 1,
      d = dt.getDate();
    function prefixZero(s) {
      s = '' + s;
      return s.length > 1 ? s : '0' + s;
    }

    f = f.replace(/yyyy/, y);
    f = f.replace(/yy/, String(y).substr(2));
    f = f.replace(/MM/, prefixZero(m));
    f = f.replace(/M/, m);
    f = f.replace(/dd/, prefixZero(d));
    f = f.replace(/d/, d);
    return f;
  }

  getLocalDateFormat() {
    const formats = {
      'af-ZA': 'yyyy/MM/dd',
      'am-ET': 'd/M/yyyy',
      'ar-AE': 'dd/MM/yyyy',
      'ar-BH': 'dd/MM/yyyy',
      'ar-DZ': 'dd-MM-yyyy',
      'ar-EG': 'dd/MM/yyyy',
      'ar-IQ': 'dd/MM/yyyy',
      'ar-JO': 'dd/MM/yyyy',
      'ar-KW': 'dd/MM/yyyy',
      'ar-LB': 'dd/MM/yyyy',
      'ar-LY': 'dd/MM/yyyy',
      'ar-MA': 'dd-MM-yyyy',
      'ar-OM': 'dd/MM/yyyy',
      'ar-QA': 'dd/MM/yyyy',
      'ar-SA': 'dd/MM/yy',
      'ar-SY': 'dd/MM/yyyy',
      'ar-TN': 'dd-MM-yyyy',
      'ar-YE': 'dd/MM/yyyy',
      'arn-CL': 'dd-MM-yyyy',
      'as-IN': 'dd-MM-yyyy',
      'az-Cyrl-AZ': 'dd.MM.yyyy',
      'az-Latn-AZ': 'dd.MM.yyyy',
      'ba-RU': 'dd.MM.yy',
      'be-BY': 'dd.MM.yyyy',
      'bg-BG': 'dd.M.yyyy',
      'bn-BD': 'dd-MM-yy',
      'bn-IN': 'dd-MM-yy',
      'bo-CN': 'yyyy/M/d',
      'br-FR': 'dd/MM/yyyy',
      'bs-Cyrl-BA': 'd.M.yyyy',
      'bs-Latn-BA': 'd.M.yyyy',
      'ca-ES': 'dd/MM/yyyy',
      'co-FR': 'dd/MM/yyyy',
      'cs-CZ': 'd.M.yyyy',
      'cy-GB': 'dd/MM/yyyy',
      'da-DK': 'dd-MM-yyyy',
      'de-AT': 'dd.MM.yyyy',
      'de-CH': 'dd.MM.yyyy',
      'de-DE': 'dd.MM.yyyy',
      'de-LI': 'dd.MM.yyyy',
      'de-LU': 'dd.MM.yyyy',
      'dsb-DE': 'd. M. yyyy',
      'dv-MV': 'dd/MM/yy',
      'el-GR': 'd/M/yyyy',
      'en-029': 'MM/dd/yyyy',
      'en-AU': 'd/MM/yyyy',
      'en-BZ': 'dd/MM/yyyy',
      'en-CA': 'dd/MM/yyyy',
      'en-GB': 'dd/MM/yyyy',
      'en-IE': 'dd/MM/yyyy',
      'en-IN': 'dd-MM-yyyy',
      'en-JM': 'dd/MM/yyyy',
      'en-MY': 'd/M/yyyy',
      'en-NZ': 'd/MM/yyyy',
      'en-PH': 'M/d/yyyy',
      'en-SG': 'd/M/yyyy',
      'en-TT': 'dd/MM/yyyy',
      'en-US': 'M/d/yyyy',
      'en-ZA': 'yyyy/MM/dd',
      'en-ZW': 'M/d/yyyy',
      'es-AR': 'dd/MM/yyyy',
      'es-BO': 'dd/MM/yyyy',
      'es-CL': 'dd-MM-yyyy',
      'es-CO': 'dd/MM/yyyy',
      'es-CR': 'dd/MM/yyyy',
      'es-DO': 'dd/MM/yyyy',
      'es-EC': 'dd/MM/yyyy',
      'es-ES': 'dd/MM/yyyy',
      'es-GT': 'dd/MM/yyyy',
      'es-HN': 'dd/MM/yyyy',
      'es-MX': 'dd/MM/yyyy',
      'es-NI': 'dd/MM/yyyy',
      'es-PA': 'MM/dd/yyyy',
      'es-PE': 'dd/MM/yyyy',
      'es-PR': 'dd/MM/yyyy',
      'es-PY': 'dd/MM/yyyy',
      'es-SV': 'dd/MM/yyyy',
      'es-US': 'M/d/yyyy',
      'es-UY': 'dd/MM/yyyy',
      'es-VE': 'dd/MM/yyyy',
      'et-EE': 'd.MM.yyyy',
      'eu-ES': 'yyyy/MM/dd',
      'fa-IR': 'MM/dd/yyyy',
      'fi-FI': 'd.M.yyyy',
      'fil-PH': 'M/d/yyyy',
      'fo-FO': 'dd-MM-yyyy',
      'fr-BE': 'd/MM/yyyy',
      'fr-CA': 'yyyy-MM-dd',
      'fr-CH': 'dd.MM.yyyy',
      'fr-FR': 'dd/MM/yyyy',
      'fr-LU': 'dd/MM/yyyy',
      'fr-MC': 'dd/MM/yyyy',
      'fy-NL': 'd-M-yyyy',
      'ga-IE': 'dd/MM/yyyy',
      'gd-GB': 'dd/MM/yyyy',
      'gl-ES': 'dd/MM/yy',
      'gsw-FR': 'dd/MM/yyyy',
      'gu-IN': 'dd-MM-yy',
      'ha-Latn-NG': 'd/M/yyyy',
      'he-IL': 'dd/MM/yyyy',
      'hi-IN': 'dd-MM-yyyy',
      'hr-BA': 'd.M.yyyy.',
      'hr-HR': 'd.M.yyyy',
      'hsb-DE': 'd. M. yyyy',
      'hu-HU': 'yyyy. MM. dd.',
      'hy-AM': 'dd.MM.yyyy',
      'id-ID': 'dd/MM/yyyy',
      'ig-NG': 'd/M/yyyy',
      'ii-CN': 'yyyy/M/d',
      'is-IS': 'd.M.yyyy',
      'it-CH': 'dd.MM.yyyy',
      'it-IT': 'dd/MM/yyyy',
      'iu-Cans-CA': 'd/M/yyyy',
      'iu-Latn-CA': 'd/MM/yyyy',
      'ja-JP': 'yyyy/MM/dd',
      'ka-GE': 'dd.MM.yyyy',
      'kk-KZ': 'dd.MM.yyyy',
      'kl-GL': 'dd-MM-yyyy',
      'km-KH': 'yyyy-MM-dd',
      'kn-IN': 'dd-MM-yy',
      'ko-KR': 'yyyy. MM. dd',
      'kok-IN': 'dd-MM-yyyy',
      'ky-KG': 'dd.MM.yy',
      'lb-LU': 'dd/MM/yyyy',
      'lo-LA': 'dd/MM/yyyy',
      'lt-LT': 'yyyy.MM.dd',
      'lv-LV': 'yyyy.MM.dd.',
      'mi-NZ': 'dd/MM/yyyy',
      'mk-MK': 'dd.MM.yyyy',
      'ml-IN': 'dd-MM-yy',
      'mn-MN': 'yy.MM.dd',
      'mn-Mong-CN': 'yyyy/M/d',
      'moh-CA': 'M/d/yyyy',
      'mr-IN': 'dd-MM-yyyy',
      'ms-BN': 'dd/MM/yyyy',
      'ms-MY': 'dd/MM/yyyy',
      'mt-MT': 'dd/MM/yyyy',
      'nb-NO': 'dd.MM.yyyy',
      'ne-NP': 'M/d/yyyy',
      'nl-BE': 'd/MM/yyyy',
      'nl-NL': 'd-M-yyyy',
      'nn-NO': 'dd.MM.yyyy',
      'nso-ZA': 'yyyy/MM/dd',
      'oc-FR': 'dd/MM/yyyy',
      'or-IN': 'dd-MM-yy',
      'pa-IN': 'dd-MM-yy',
      'pl-PL': 'dd.MM.yyyy',
      'prs-AF': 'dd/MM/yy',
      'ps-AF': 'dd/MM/yy',
      'pt-BR': 'd/M/yyyy',
      'pt-PT': 'dd-MM-yyyy',
      'qut-GT': 'dd/MM/yyyy',
      'quz-BO': 'dd/MM/yyyy',
      'quz-EC': 'dd/MM/yyyy',
      'quz-PE': 'dd/MM/yyyy',
      'rm-CH': 'dd/MM/yyyy',
      'ro-RO': 'dd.MM.yyyy',
      'ru-RU': 'dd.MM.yyyy',
      'rw-RW': 'M/d/yyyy',
      'sa-IN': 'dd-MM-yyyy',
      'sah-RU': 'MM.dd.yyyy',
      'se-FI': 'd.M.yyyy',
      'se-NO': 'dd.MM.yyyy',
      'se-SE': 'yyyy-MM-dd',
      'si-LK': 'yyyy-MM-dd',
      'sk-SK': 'd. M. yyyy',
      'sl-SI': 'd.M.yyyy',
      'sma-NO': 'dd.MM.yyyy',
      'sma-SE': 'yyyy-MM-dd',
      'smj-NO': 'dd.MM.yyyy',
      'smj-SE': 'yyyy-MM-dd',
      'smn-FI': 'd.M.yyyy',
      'sms-FI': 'd.M.yyyy',
      'sq-AL': 'yyyy-MM-dd',
      'sr-Cyrl-BA': 'd.M.yyyy',
      'sr-Cyrl-CS': 'd.M.yyyy',
      'sr-Cyrl-ME': 'd.M.yyyy',
      'sr-Cyrl-RS': 'd.M.yyyy',
      'sr-Latn-BA': 'd.M.yyyy',
      'sr-Latn-CS': 'd.M.yyyy',
      'sr-Latn-ME': 'd.M.yyyy',
      'sr-Latn-RS': 'd.M.yyyy',
      'sv-FI': 'd.M.yyyy',
      'sv-SE': 'yyyy-MM-dd',
      'sw-KE': 'M/d/yyyy',
      'syr-SY': 'dd/MM/yyyy',
      'ta-IN': 'dd-MM-yyyy',
      'te-IN': 'dd-MM-yy',
      'tg-Cyrl-TJ': 'dd.MM.yy',
      'th-TH': 'd/M/yyyy',
      'tk-TM': 'dd.MM.yy',
      'tn-ZA': 'yyyy/MM/dd',
      'tr-TR': 'dd.MM.yyyy',
      'tt-RU': 'dd.MM.yyyy',
      'tzm-Latn-DZ': 'dd-MM-yyyy',
      'ug-CN': 'yyyy-M-d',
      'uk-UA': 'dd.MM.yyyy',
      'ur-PK': 'dd/MM/yyyy',
      'uz-Cyrl-UZ': 'dd.MM.yyyy',
      'uz-Latn-UZ': 'dd/MM yyyy',
      'vi-VN': 'dd/MM/yyyy',
      'wo-SN': 'dd/MM/yyyy',
      'xh-ZA': 'yyyy/MM/dd',
      'yo-NG': 'd/M/yyyy',
      'zh-CN': 'yyyy/M/d',
      'zh-HK': 'd/M/yyyy',
      'zh-MO': 'd/M/yyyy',
      'zh-SG': 'd/M/yyyy',
      'zh-TW': 'yyyy/M/d',
      'zu-ZA': 'yyyy/MM/dd',
    };

    var navigatorLanguage = navigator.language
      ? navigator.language
      : navigator['userLanguage']
      ? navigator['userLanguage']
      : navigator['browserLanguage']
      ? navigator['browserLanguage']
      : navigator.languages && navigator.languages.length > 0
      ? navigator.languages[0]
      : 'en';

    var f =
      navigatorLanguage in formats ? formats[navigatorLanguage] : 'dd-MM-yyyy';
    return f;
  }

  getBookingActionDtoFromArray(individualBookingDtos: any[]) {
    return !individualBookingDtos || individualBookingDtos.length == 0
      ? null
      : //new BookingAddActionDto()
        {
          Bookings: individualBookingDtos.map((x) => {
            //new BookingDto()
            return {
              BookingId: x.BookingId,
              BookingType: x.BookingType,
              Description: x.Description,
              EndDate: x.EndDate,
              Hours: x.Hours,
              JobId: x.JobId,
              Loading: x.Loading,
              ResourceId: x.ResourceId,
              StartDate: x.StartDate,
              Unconfirmed: x.Unconfirmed,
              Ghost: x.Ghost,
              IsOverallocation: x.IsOverallocation,
              InstanceId: x.InstanceId,
            };
          }),
          IsPercentSelected: individualBookingDtos[0].IsPercentSelected,
          RequestMode: individualBookingDtos[0].RequestMode,
          // InstanceId:individualBookingDtos[0].InstanceId
          UiViewSource: individualBookingDtos[0].UiViewSource,
        };
  }

  getBookingActionDto(individualBookingDto: any): BookingActionDto {
    var bookingActionDto = new BookingActionDto();
    bookingActionDto.IsPercentSelected = individualBookingDto.IsPercentSelected;
    bookingActionDto.RequestMode = individualBookingDto.RequestMode;
    //bookingActionDto.InstanceId = individualBookingDto.InstanceId;
    bookingActionDto.UiViewSource = individualBookingDto.UiViewSource;
    bookingActionDto.Bookings = this.getBookingDtoList(individualBookingDto);
    return bookingActionDto;
  }

  private getBookingDtoList(individualBookingDto: any) {
    var bookingDtos = [];
    bookingDtos.push(
      this.getBookingDto(
        individualBookingDto,
        individualBookingDto.StartDate,
        individualBookingDto.EndDate,
        individualBookingDto.Hours,
        individualBookingDto.Loading,
        individualBookingDto.Unconfirmed,
        individualBookingDto.Ghost,
        individualBookingDto.IsOverallocation_1,
        individualBookingDto.InstanceId,
        individualBookingDto.IsExistingBooking
      )
    );

    if (
      individualBookingDto.StartDate_2 &&
      individualBookingDto.EndDate_2 &&
      ((individualBookingDto.Hours_2 && individualBookingDto.Hours_2 > 0) ||
        (individualBookingDto.Loading_2 && individualBookingDto.Loading_2 > 0))
    ) {
      bookingDtos.push(
        this.getBookingDto(
          individualBookingDto,
          individualBookingDto.StartDate_2,
          individualBookingDto.EndDate_2,
          individualBookingDto.Hours_2,
          individualBookingDto.Loading_2,
          individualBookingDto.Unconfirmed_2,
          individualBookingDto.Ghost_2,
          individualBookingDto.IsOverallocation_2,
          individualBookingDto.InstanceId,
          individualBookingDto.IsExistingBooking_2
        )
      );
    }

    if (
      individualBookingDto.StartDate_3 &&
      individualBookingDto.EndDate_3 &&
      ((individualBookingDto.Hours_3 && individualBookingDto.Hours_3 > 0) ||
        (individualBookingDto.Loading_3 && individualBookingDto.Loading_3 > 0))
    ) {
      bookingDtos.push(
        this.getBookingDto(
          individualBookingDto,
          individualBookingDto.StartDate_3,
          individualBookingDto.EndDate_3,
          individualBookingDto.Hours_3,
          individualBookingDto.Loading_3,
          individualBookingDto.Unconfirmed_3,
          individualBookingDto.Ghost_3,
          individualBookingDto.IsOverallocation_3,
          individualBookingDto.InstanceId,
          individualBookingDto.IsExistingBooking_3
        )
      );
    }

    if (
      individualBookingDto.StartDate_4 &&
      individualBookingDto.EndDate_4 &&
      ((individualBookingDto.Hours_4 && individualBookingDto.Hours_4 > 0) ||
        (individualBookingDto.Loading_4 && individualBookingDto.Loading_4 > 0))
    ) {
      bookingDtos.push(
        this.getBookingDto(
          individualBookingDto,
          individualBookingDto.StartDate_4,
          individualBookingDto.EndDate_4,
          individualBookingDto.Hours_4,
          individualBookingDto.Loading_4,
          individualBookingDto.Unconfirmed_4,
          individualBookingDto.Ghost_4,
          individualBookingDto.IsOverallocation_4,
          individualBookingDto.InstanceId,
          individualBookingDto.IsExistingBooking_4
        )
      );
    }

    if (
      individualBookingDto.StartDate_5 &&
      individualBookingDto.EndDate_5 &&
      ((individualBookingDto.Hours_5 && individualBookingDto.Hours_5 > 0) ||
        (individualBookingDto.Loading_5 && individualBookingDto.Loading_5 > 0))
    ) {
      bookingDtos.push(
        this.getBookingDto(
          individualBookingDto,
          individualBookingDto.StartDate_5,
          individualBookingDto.EndDate_5,
          individualBookingDto.Hours_5,
          individualBookingDto.Loading_5,
          individualBookingDto.Unconfirmed_5,
          individualBookingDto.Ghost_5,
          individualBookingDto.IsOverallocation_5,
          individualBookingDto.InstanceId,
          individualBookingDto.IsExistingBooking_5
        )
      );
    }

    return bookingDtos;
  }

  private getBookingDto(
    individualBookingDto: any,
    StartDate,
    EndDate,
    Hours,
    Loading,
    Unconfirmed,
    ghost,
    overallocationFlg,
    instanceId,
    isExistingBooking
  ) {
    let booking: BookingDto = {
      BookingId: individualBookingDto.BookingId,
      ResourceId: individualBookingDto.ResourceId,
      JobId: individualBookingDto.JobId,
      BookingType: individualBookingDto.BookingType,
      StartDate: StartDate,
      EndDate: EndDate,
      Description: individualBookingDto.Description,
      Hours: Hours,
      Loading: Loading,
      Unconfirmed: Unconfirmed,
      Ghost: ghost,
      IsOverallocation: overallocationFlg,
      InstanceId: instanceId,
      IsExistingBooking: isExistingBooking,
    };
    return booking;
  }

  getFirstDateOfWeek(anyDateOfWeek: Date): Date {
    // date.getDay() RETURNS Week Day Index: SUN 0, MON 1, TUE 2, WED 3, THU 4, FRI 5, SAT 6.
    let date = new Date(anyDateOfWeek);
    let firstDayOfWeekIndex: number = 6; //Week Day Index: SUN 0, MON 1, TUE 2, WED 3, THU 4, FRI 5, SAT 6.
    let daysToShiftBack = (7 + (date.getDay() - firstDayOfWeekIndex)) % 7;
    let firstDateOfWeek = new Date(
      date.setDate(date.getDate() - daysToShiftBack)
    );
    return firstDateOfWeek;
  }

  getDefaultPeriodSettings(user: any) {
    this.defaultStartDate = this.getFirstDateOfWeek(this.todaysDate);
    this.defaultEndDate = this.getFirstDateOfWeek(this.todaysDate);
    if (user.DefaultPeriodFilter == '6W') {
      this.defaultEndDate.setDate(this.defaultEndDate.getDate() + 35);
    } else if (user.DefaultPeriodFilter == '3M') {
      this.defaultEndDate.setDate(this.defaultEndDate.getDate() + 12 * 7);
    } else if (user.DefaultPeriodFilter == '6M') {
      this.defaultEndDate.setDate(this.defaultEndDate.getDate() + 25 * 7);
    } else if (user.DefaultPeriodFilter == '12M') {
      this.defaultEndDate.setDate(this.defaultEndDate.getDate() + 51 * 7);
    }
   
    if (this.defaultStartDate != null)
      this.defaultStartDate = new Date(
        this.defaultStartDate.getFullYear(),
        this.defaultStartDate.getMonth(),
        this.defaultStartDate.getDate(),
        0,
        0,
        0
      );

    if (this.defaultEndDate != null)
      this.defaultEndDate = new Date(
        this.defaultEndDate.getFullYear(),
        this.defaultEndDate.getMonth(),
        this.defaultEndDate.getDate(),
        0,
        0,
        0
      );
  }

  getCopy = (item) => {
    return JSON.parse(JSON.stringify(item));
  };
}
