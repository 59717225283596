export enum RoleFeatureEnum {
  CompletedRequest = 'CompletedRequest',
  EngagementBooking = 'EngagementBooking',
  IndividualBooking = 'IndividualBooking',
  MyRequest = 'MyRequest',
  UserProfile = 'UserProfile',
  WorkRequestQueue = 'WorkRequestQueue',
  RoleConfiguration = 'RoleConfiguration',
  ApprovalQueue = 'ApprovalLayer',
  ScheduleView = 'ScheduleView',
}
export enum ActionNameEnum {
  null = 'null',
  Add = 'Add',
  Edit = 'Edit',
  Copy = 'Copy',
  View = 'View',
  Delete = 'Delete',
}

export enum RoleTypeEnum {
  ClientServer = 'Client Server',
  ResourceAdmin = 'Resource Admin',
  SuperAdmin = 'Super Admin',
}
export enum UiViewSourceEnum {
  IndividualView = 'IV',
  EngamentView = 'EV',
  IndividualScheduleView = 'TIV',
  EngagementScheduleView = 'TEV',
}
export enum MeraActionDescription {
  Create = 1,
  Edit = 2,
  Copy = 3,
  Delete = 4,
  Replace = 5,
  ReplaceAll = 6,
}

export enum ActionDescription {
  Create = 1,
  Edit = 2,
  Copy = 1,
  Delete = 4,
  Replace = 2,
  ReplaceAll = 2,
}

export enum MERAStatusId {
  Submitted = 1,
  In_Progress = 2,
  Processed = 3,
  Cancelled = 4,
  Draft = 5,
  Completed = 6,
  Rejected = 7,
  Approved = 8,
  Denied = 9,
  Pending_Approval = 10,
  Passive = 11,
}

export enum favEngImageSrc {
  // favEngImgStarOutline  =  "../../../../assets/images/old.ico",
  // favEngImgStarfilled = "../../../../assets/images/excel-export-black.jpg"
  favEngImgStarOutline  =  "star-outline.svg",
  favEngImgStarfilled = "star-filled.png"
}