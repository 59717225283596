<div class="meraHeader">
  <div class="imgHeader" (click)="linkNavigation('Home')">
    <img class="appImg" src="./assets/images/logo.png" />
    <div class="appName">MERA</div>
  </div>
  <div class="linkHeader" *ngIf="isMenu">
    <ng-container *ngFor="let featureMenu of featureMenus">
      <div
        *ngIf="!featureMenu.SubFeatures || featureMenu.SubFeatures.length == 0"
        [ngClass]="{
          active: restService.activeLink === featureMenu.FeatureName
        }"
        class="linkItems"
        (click)="linkNavigation_New(featureMenu)"
      >
        <span *ngIf="featureMenu.FeatureName != 'RoleConfiguration'">
          {{ featureMenu.FeatureDisplayName }}
        </span>
        <span class="setting-menu" *ngIf="featureMenu.FeatureName == 'RoleConfiguration'">
          <mat-icon matTooltip="{{ featureMenu.FeatureDisplayName }}"
            >settings</mat-icon
          >
        </span>
      </div>
      <button
        *ngIf="featureMenu.SubFeatures && featureMenu.SubFeatures.length > 0"
        [ngClass]="{
          active:
            featureMenu.SubFeatures &&
            featureMenu.SubFeatureNames.indexOf(restService.activeLink) > -1
        }"
        class="buttonLink"
        mat-button
        [matMenuTriggerFor]="matSubMenu"
      >
        {{ featureMenu.FeatureDisplayName }}
        <mat-icon class="dropDownArrow">arrow_drop_down</mat-icon>
      </button>
      <mat-menu #matSubMenu="matMenu" backdropClass="Usermenu">
        <button
          mat-menu-item
          *ngFor="let subMenu of featureMenu.SubFeatures"
          (click)="linkNavigation_New(subMenu)"
        >
          <div
            [ngStyle]="{
              color:
                restService.activeLink === subMenu.FeatureName
                  ? '#ffe600'
                  : '#fff'
            }"
          >
            {{ subMenu.FeatureDisplayName }}
          </div>
        </button>
      </mat-menu>
    </ng-container>

    <button class="userButton" mat-icon-button [matMenuTriggerFor]="faq">
      <mat-icon class="faqLink">help_outline</mat-icon>
    </button>
    <mat-menu #faq="matMenu" backdropClass="Usermenu">
      <!-- <a [href]="faqFileLocation" target="_blank">FAQ</a> -->
      <button mat-menu-item (click)="goToFAQ()">FAQ</button>
      <button mat-menu-item (click)="goToMERASharepoint()">
        MERA Sharepoint
      </button>
    </mat-menu>
    <button
      [ngClass]="{ active: restService.activeLink === 'ProfileSettings' }"
      class="userButton"
      mat-icon-button
      [matMenuTriggerFor]="profile"
    >
      <img
        [ngStyle]="{
          color:
            restService.activeLink === 'ProfileSettings' ? '#ffe600' : '#fff'
        }"
        [src]="userProfilePhotoUrl !== null ? userProfilePhotoUrl : profileIcon"
        class="userProfileImg"
      />
    </button>
    <mat-menu #profile="matMenu" backdropClass="Usermenu">
      <div class="profileMenuContent">
        <div class="alignright" (click)="openModal(LogoutConfirm)">
          Sign Out
        </div>
      </div>
      <div class="profileSettingMenu">
        <img
          [src]="userProfilePhotoUrl !== null ? userProfilePhotoUrl : profileIcon"
          class="userProfileImg"
        />
        <div class="userAccountDetails">
          <span title="{{ currentUserEmailId }}">{{ currentUserEmailId }}</span><br />
          <span class="roleName" title="{{ this.currentUser.RoleName }}">{{ this.currentUser.RoleName }}</span><br />
          <a class="settingLink" (click)="linkNavigation('ProfileSettings')"
            >Manage Profile Settings</a
          >
        </div>
      </div>
    </mat-menu>
  </div>

  <div
    #toggleMenu
    class="mobileMenu"
    (click)="toggleMobileMenu()"
    *ngIf="isMenu"
  >
    <mat-icon>menu</mat-icon>
  </div>
</div>

<div #mobileMenu *ngIf="showMobileMenu" class="sideMenu">
  <ng-container *ngFor="let featureMenu of featureMenus">
    <div
        *ngIf="(!featureMenu.SubFeatures || featureMenu.SubFeatures.length == 0) && featureMenu.FeatureName != 'RoleConfiguration'"
        [ngClass]="{
          activeMenu: restService.activeLink === featureMenu.FeatureName
        }"
        class="menuItem"
        (click)="linkNavigation_New(featureMenu)"
      >
        <span >
          {{ featureMenu.FeatureDisplayName }}
        </span>
      </div>
      <mat-accordion >
        <mat-expansion-panel *ngIf="isViewEnabled(featureMenu)">
          <mat-expansion-panel-header>
            <mat-panel-title [ngClass]="{ activeMenu: featureMenu.SubFeatures &&
              featureMenu.SubFeatureNames.indexOf(restService.activeLink) > -1 }"> {{ featureMenu.FeatureDisplayName }} </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="menuList">
            <div *ngFor="let subMenu of featureMenu.SubFeatures">
              <div 
            *ngIf="isSubMenuViewEnabled(featureMenu, subMenu.FeatureName)"
            class="listItem"
            [ngClass]="{ activeMenu: restService.activeLink === subMenu.FeatureName }"
            (click)="linkNavigation_New(subMenu)"
          >
          {{ subMenu.FeatureDisplayName }}
          </div>
            </div>
            
          </div>
        </mat-expansion-panel>
        
      </mat-accordion>
  </ng-container>
  <!-- <div
    class="menuItem"
    [ngClass]="{ activeMenu: restService.activeLink === 'Home' }"
    (click)="linkNavigation('Home')"
  >
    Home
  </div>
  <div
    class="menuItem"
    [ngClass]="{ activeMenu: restService.activeLink === 'IndividualBooking' }"
    (click)="linkNavigation('IndividualBooking')"
  >
    Individual
  </div>
  <div
    class="menuItem"
    [ngClass]="{ activeMenu: restService.activeLink === 'EngagementBooking' }"
    (click)="linkNavigation('EngagementBooking')"
  >
    Engagement
  </div> -->

  <!-- <mat-accordion >
    
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> Queues </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="menuList">
        <div
          class="listItem"
          [ngClass]="{ activeMenu: restService.activeLink === 'MyRequest' }"
          (click)="linkNavigation('MyRequest')"
        >
          My Requests View
        </div>
        <div
          *ngIf="isWorkRequest && !isMobileDevice"
          class="listItem"
          [ngClass]="{
            activeMenu: restService.activeLink === 'WorkRequestQueue'
          }"
          (click)="linkNavigation('WorkRequestQueue')"
        >
          Work Request Queue
        </div>
        <div
          *ngIf="isCompletedRequest && !isMobileDevice"
          class="listItem"
          [ngClass]="{
            activeMenu: restService.activeLink === 'CompletedRequest'
          }"
          (click)="linkNavigation('CompletedRequest')"
        >
          Completed Work Requests
        </div>
        <div
          *ngIf="isApprovalQueue && !isMobileDevice"
          class="listItem"
          [ngClass]="{ activeMenu: restService.activeLink === 'ApprovalLayer' }"
          (click)="linkNavigation('ApprovalLayer')"
        >
          Approval Queue
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel
      *ngIf="currentUser?.RoleType !== 'Client Server' && !isMobileDevice"
    >
      <mat-expansion-panel-header>
        <mat-panel-title> Report </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="menuList" *ngIf="reportLinks?.length > 0">
        <div class="listItem" *ngFor="let link of reportLinks">
          <a [href]="link.ReportLink" target="_blank">{{ link.ReportName }}</a>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion> -->
  <div class="iconMenu">
    <mat-icon
      *ngIf="isRoleConfiguration && !isMobileDevice"
      class="menuIconItem"
      [ngClass]="{ activeMenu: restService.activeLink === 'RoleConfiguration' }"
      (click)="linkNavigation('RoleConfiguration'); toggleDropDown('')"
      matTooltip="Settings"
      >settings</mat-icon
    >
    <mat-icon
      [ngClass]="{ activeMenu: selectedMenu === 'FAQ' }"
      class="menuIconItem"
      (click)="toggleDropDown('FAQ')"
    >
      help_outline</mat-icon
    >
    <!-- <mat-icon [ngClass]="{'activeMenu': selectedMenu === 'PROFILE'}" class="menuIconItem" (click)="toggleDropDown('PROFILE')"
          [ngClass]="{'activeMenu': restService.activeLink==='ProfileSettings'}">account_circle</mat-icon> -->
    <img
      [src]="userProfilePhotoUrl !== null ? userProfilePhotoUrl : profileIcon"
      [ngClass]="{ activeMenu: selectedMenu === 'PROFILE' }"
      class="userProfileImg"
      (click)="toggleDropDown('PROFILE')"
      [ngClass]="{ activeMenu: restService.activeLink === 'ProfileSettings' }"
    />
  </div>
  <div *ngIf="selectedMenu === 'FAQ'" class="subMenu">
    <div class="subMenuItem">
      <a [href]="faqFileLocation" target="_blank">FAQ</a>
    </div>
    <div class="subMenuItem">
      <a href="https://sites.ey.com/sites/MERAGlobal" target="_blank"
        >MERA Sharepoint</a
      >
    </div>
  </div>
  <div *ngIf="selectedMenu === 'PROFILE'" class="subMenu">
    <div class="subMenuItem">
      <!-- <mat-icon>account_circle</mat-icon> -->
      <img
        [src]="userProfilePhotoUrl !== null ? userProfilePhotoUrl : profileIcon"
        class="userProfileImg"
      />
      <span>{{ currentUserEmailId }}<br> {{ this.currentUser.RoleName }} </span>
    </div>
    <div class="subMenuItem" (click)="linkNavigation('ProfileSettings')">
      <mat-icon>manage_accounts</mat-icon>
      <span>Settings</span>
    </div>
    <div class="subMenuItem" (click)="openModal(LogoutConfirm)">
      <mat-icon>exit_to_app</mat-icon>
      <span>Sign Out</span>
    </div>
  </div>
</div>

<ng-template #LogoutConfirm>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Confirm</h4>
  </div>
  <div class="modal-body">
    <div class="popupLayout">
      <div class="fullWidth">
        <div class="confirmationText">
          Are you sure you want to Sign Out?<span></span>
        </div>
      </div>

      <div class="confirmationButtons">
        <button class="cancelBtn" (click)="this.modalRef.hide()">Cancel</button>
        <button class="deleteBtn" (click)="logout()">Yes</button>
      </div>
    </div>
  </div>
</ng-template>
