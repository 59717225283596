<form (ngSubmit)="onEditSubmit()" [formGroup]="formGroup">

  <div class="popupLayout">
    <div *ngIf="ResourceSearch">
      <div class="customRow">
        <div class="engSearchFullWidth">
          <label>Resource Name <span class="mandtoryIcn"> *</span> : {{ReplaceResourceName}}</label>
          <mat-form-field class="resourceSearch" appearance="outline"
            [ngClass]="{ 'is-invalid': isValidInput('EngagementResourceName') }">
            <input type="text" formControlName="EngagementResourceName" placeholder="Search Resource..." matInput
              autocomplete="off" appSearchDebounce (debounceKeyup)="searchResource()" maxlength="75">
            <mat-icon (click)="onResourceClear()">close</mat-icon>
          </mat-form-field>
          <div *ngIf="isValidInput('EngagementResourceName')" class="alert alert-danger">
            <div *ngIf="formGroup.controls['EngagementResourceName'].errors.required">
              Resource Name is required.
            </div>
          </div>
          <div class="dropdown-list-wrap" *ngIf="showSearchList">
            <mat-spinner *ngIf="showSearchLoader" [diameter]='40'></mat-spinner>
            <ul *ngIf="results.length > 0" class="dropdown-list tabdropdwn_actn">
              <li *ngFor="let item of results" (click)="OnResourceItemSelect(item)" 
              (keyup.enter)="OnResourceItemSelect(item)" 
              class="dropdown-list-item" tabindex="0">
                {{ item.ResourceName }}
              </li>
            </ul>
            <div *ngIf="showSearchLoader === false && results.length === 0 && noresourcFoundFlg">No Resource Found</div>
          </div>
  
        </div>
        <!-- <div class="engCategory">
          <label>Service Line</label>
          <mat-form-field appearance="outline">
            <input matInput type="text"  [(ngModel)]='SLDescription' [readonly]="true"
              [ngModelOptions]="{standalone:true}">
          </mat-form-field>
  
        </div> -->
  
      </div>
  
      <div class="customRow">
        <div class="halfWidthItem">
          <label>Service Line</label>
          <mat-form-field appearance="outline">
            <input type="text" matInput [(ngModel)]="SLDescription" [readonly]="true" [ngModelOptions]="{ standalone: true }">
          </mat-form-field>
        </div>
        <div class="halfWidthItem">
          <label>HR Department</label>
          <mat-form-field appearance="outline">
            <input type="text" matInput [(ngModel)]="departmentDescription" [readonly]="true" [ngModelOptions]="{ standalone: true }">
          </mat-form-field>
        </div>
      </div>
  
      <div class="customRow">
        <div class="halfWidthItem">
          <label>Rank</label>
          <mat-form-field appearance="outline">
            <input matInput type="text"  [(ngModel)]='RankDescription'
              [readonly]="true" [ngModelOptions]="{standalone:true}">
          </mat-form-field>
  
        </div>
        <div class="halfWidthItem">
          <label>Office Location</label>
          <mat-form-field appearance="outline">
            <input matInput type="text"  [(ngModel)]='LocationDescription'
              [readonly]="true" [ngModelOptions]="{standalone:true}">
          </mat-form-field>
        </div>
      </div>
    </div>
    

      <div class="engSearchFullWidth">
        <label>Engagement Name : {{ReplaceEngagementName}} </label>
        <mat-form-field class="resourceSearch" appearance="outline"
          [ngClass]="{ 'is-invalid': isValidInput('EngagementName') }">
          <input type="text" formControlName="EngagementName" placeholder="Search Engagement..." matInput
            autocomplete="off" appSearchDebounce (debounceKeyup)="searchEngagement()" maxlength="200">
          <mat-icon (click)="onEngagementClear()">close</mat-icon>
        </mat-form-field>
        <div *ngIf="isValidInput('EngagementName')" class="alert alert-danger">
          <div *ngIf="formGroup.controls['EngagementName'].errors.required">
            Engagement is required.
          </div>

        </div>
        <div class="dropdown-list-wrap" *ngIf="showSearchEngagementList">
          <mat-spinner *ngIf="showSearchLoaderEngagement" [diameter]='40'></mat-spinner>
          <ul *ngIf="egResults.length > 0" class="dropdown-list">
            <li *ngFor="let item of egResults" (click)="OnItemSelect(item)" class="dropdown-list-item">
              {{ item.EngagementFullName }}
            </li>
          </ul>
          <div *ngIf="showSearchLoaderEngagement === false && egResults.length === 0">No Engagement Found</div>
        </div>

      </div>
   
    <!-- <div class="fullWidth">
      <div class="searchContainer">
        <label>Engagement Name :  {{ReplaceEngagementName}}  </label>
        <p-autoComplete (change)="makeDirty(autocompleteResetvalue)" [(ngModel)]="autocompleteResetvalue" 
          placeholder="Search engagement..."
          formControlName="engagementName"  field="EngagementFullName" (completeMethod)="search($event)"
          delay = 1000 (onSelect)="OnItemSelect($event)" (onClear) = "onEngagementClear()" spellcheck="false"
          (ngModelChange)="onResourceChange($event)"
          ></p-autoComplete>
      </div>
    </div> -->


    <div class="customRow">
      <div class="halfWidthItem">
        <label>Engagement Type</label>
        <mat-form-field appearance="outline">
          <input matInput type="text" [(ngModel)]='bookingType' [readonly]="true"
            [ngModelOptions]="{standalone:true}">
        </mat-form-field>

      </div>
      <div class="halfWidthItem">
        <label>Engagement Code</label>
        <mat-form-field appearance="outline">
          <input matInput type="text" [(ngModel)]='engagementCode'
            [readonly]="true" [ngModelOptions]="{standalone:true}">
        </mat-form-field>
      </div>
    </div>


    <div class="customRow">
      <div class="halfWidthItem">
        <label>Engagement Service Line</label>
        <mat-form-field appearance="outline">
          <input matInput type="text"  [(ngModel)]='engagementServiceLine'
            [readonly]="true" [ngModelOptions]="{standalone:true}">
        </mat-form-field>
      </div>
      <div class="halfWidthItem">
        <label>Engagement Status</label>
        <mat-form-field appearance="outline">
          <input matInput type="text"  [(ngModel)]='engagementStatus'
            [readonly]="true" [ngModelOptions]="{standalone:true}">
        </mat-form-field>
      </div>
    </div>


    <div class="customRow">
      <div class="defaultColumnCheckbox">
        <label>Select All</label>
        <mat-checkbox formControlName="selectAll_chk" [disableRipple]='true' 
        [ngClass]="checkBoxDisbFlg ? 'disabled' :'enabled'"
        (change)="changeSelectAll($event)">
        </mat-checkbox>
      </div>
    </div>


    <div [formGroup]="myFormGroup" *ngIf="listLength > 0">
      <div *ngFor="let item of bookingList; let i = index;">
        <div title="{{ item.IsCurrentEditWeek == true ? 'Disabled Current Week' 
        : ( item.IsDisabledReplaceBooking == true && item.StartDate < startOfWeek ? 'History Booking Period' : '' )}}"
        [ngStyle]="item.IsDisabledReplaceBooking == true && {'opacity': 0.6, 'cursor': 'not-allowed', 'background-color': '#424244'}" 
          style="padding-left: .5rem; padding-right: .5rem;">
          <div class="estimationContainer">
            <div class="defaultColumnCheckbox">
              <label>Select </label>
              <mat-checkbox formControlName="chk_{{i}}" [disableRipple]='true'
              [disabled]="item.IsCurrentEditWeek === true? true : null"
              [ngClass]="checkBoxDisbFlg ? 'disabled' :'enabled'"
              (change)="changeCheck(i,$event)">
              </mat-checkbox>
            </div>
            <div class="col50percent">
              <label>Period <span *ngIf="item.IsDisabledReplaceBooking != true" class="mandtoryIcn"> *</span></label>
              <mat-form-field appearance="outline">
                <mat-date-range-input formGroupName="Periods_{{i}}" [rangePicker]="picker" [min]="startOfWeek">
                  <input matStartDate formControlName="dtStart" placeholder="Start Date" (focus)="picker.open()"
                    (click)="picker.open()" readonly>
                  <input matEndDate formControlName="dtEnd" placeholder="End Date" (focus)="picker.open()"
                    (click)="picker.open()" readonly>
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
                <mat-error *ngIf="myFormGroup && myFormGroup.get('Periods_'+i) && myFormGroup.get('Periods_'+i).get('dtStart').hasError('matStartDateInvalid')">Invalid Start Date</mat-error>
                <mat-error *ngIf="myFormGroup && myFormGroup.get('Periods_'+i) && myFormGroup.get('Periods_'+i).get('dtEnd').hasError('matEndDateInvalid')">Invalid End Date</mat-error>
              </mat-form-field>
              <div *ngIf="myFormGroup && myFormGroup.get('Periods_'+i) && myFormGroup.get('Periods_'+i).get('dtStart').value === null && (myFormGroup.get('Periods_'+i).get('dtStart').dirty || myFormGroup.get('Periods_'+i).get('dtStart').touched)" class="alert alert-danger">
                Start Date is required
              </div>
              <div *ngIf="myFormGroup && myFormGroup.get('Periods_'+i) && myFormGroup.get('Periods_'+i).get('dtEnd').value === null && (myFormGroup.get('Periods_'+i).get('dtEnd').dirty || myFormGroup.get('Periods_'+i).get('dtEnd').touched)" class="alert alert-danger">
                End Date is required
              </div>
            </div>
            <div class="col20percent">
              <label>{{engagementAllocationText}} <span *ngIf="item.IsDisabledReplaceBooking != true" class="mandtoryIcn"> *</span></label>
              <mat-form-field appearance="outline">
                <input matInput type="text" (change)="makeDirty(item.Hours)"
                  tooltip='Note when adding time in hours this will be input for the full-time period of the booking, not per week. For long term bookings Loading% may be the preferred way to input time allocation to work (please see “preference” button in top right hand corner to switch between the time allocation input methods)'
                  triggers="hover click focus" placement="bottom" formControlName="Hours_{{i}}" (focus)="mouseEnter()"
                  (focusout)="mouseLeave()" appHoursLoading [isPercentageSelected]="isPercentageSelected">

              </mat-form-field>
            <div>
            <div *ngIf="myFormGroup && myFormGroup.get('Hours_'+i)?.errors?.required" class="alert alert-danger">
              {{engagementAllocationText}} is required.
            </div>
            <div *ngIf="myFormGroup && myFormGroup.get('Hours_'+i)?.errors?.pattern" class="alert alert-danger">
              {{engagementAllocationText}} is invalid.
            </div>
          </div>
            </div>
            <div class="confirmContainer" *ngIf="!user?.Unconfirmed">
              <label>Unconfirmed</label>
              <mat-checkbox formControlName="Unconfirmed_{{i}}" [disableRipple]='true'
                (change)="makeDirty(Unconfirmed)"></mat-checkbox>
            </div>
            <div class="confirmContainer" *ngIf="user?.Ghost">
              <label>Ghost</label>
              <mat-checkbox formControlName="Ghost_{{i}}" [disableRipple]='true'
                (change)="makeDirty('')"></mat-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="estimationContainer" *ngIf="!hoursValid">
        <div class="col80percent" [class.disabledoverallocation]="overallocationerror">
          <label>This Booking request will cause an overallocation. If you wish to proceed, please select the check box
            and click on Submit.</label>
        </div>
        <div class="col20percent" [class.disabledoverallocation]="overallocationerror">
          <label>Confirm</label>
          <mat-checkbox [disableRipple]='true' (change)="confirmHoursChange()" [(ngModel)]="confirmHours"
            [ngModelOptions]="{standalone:true}"></mat-checkbox>
        </div>
      </div>
    </div>
    <div class="customRow">
      <div class="col100percent">
        <label>Notes Description</label>
        <mat-form-field appearance="outline">
          <input matInput
            tooltip="Please do not enter any unnecessary personal information or any sensitive personal data (including tax file numbers or other government identifiers), client confidential information, audit secrets, state secrets, commercial secrets, or anything that would violate professional secrecy or confidentiality rules or that would be considered abusive/irrelevant."
            type="text" triggers="hover click focus" placement="bottom" formControlName="Notes" maxlength="200">
        </mat-form-field>
      </div>
    </div>
    <div class="SubmitContainer">
      <button class="submitButton" type="submit" [disabled]="(!(buttonText == 'Submit'))">
        {{buttonText}}
      </button>
    </div>
  </div>

</form>