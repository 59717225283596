<form (ngSubmit)="onSplitSubmit()" [formGroup]="formGroupSplit">
  <div class="popupLayout">
    <div class="customRow">
      <div class="halfWidthItem">
        <label>Resource Name <span class="mandtoryIcn"> *</span></label>
        <mat-form-field appearance="outline">
          <input matInput type="text" [(ngModel)]='ResourceName' [readonly]="resourceNameZZ" [ngModelOptions]="{standalone:true}">
        </mat-form-field>

      </div>
      <div class="halfWidthItem">
        <label>Service Line</label>
        <mat-form-field appearance="outline">
          <input matInput type="text" [(ngModel)]='SLDescription' [readonly]="true" [ngModelOptions]="{standalone:true}">
        </mat-form-field>
      </div>
    </div>
    <div class="customRow">
      <div class="halfWidthItem">
        <label>Rank</label>
        <mat-form-field appearance="outline">
          <input matInput type="text" [(ngModel)]='RankDescription' [readonly]="true" [ngModelOptions]="{standalone:true}">
        </mat-form-field>

      </div>
      <div class="halfWidthItem">
        <label>Office Location</label>
        <mat-form-field appearance="outline">
          <input matInput type="text" [(ngModel)]='LocationDescription' [readonly]="true" [ngModelOptions]="{standalone:true}">
        </mat-form-field>
      </div>
    </div>
    <div id="split1" class="periodContainer" style="width: 98%;">
      <div class="estimationContainer" style="justify-content: flex-start;">
        <div class="dateSearch" style="margin-right: 4%;width: 50%;">
          <label>Period</label>
          <mat-form-field appearance="outline" disabled readonly>
  
            <mat-date-range-input formGroupName="Period" [rangePicker]="picker" disabled readonly>
              <input matStartDate formControlName="start1" placeholder="Start Date" (focus)="picker.open()"
                (click)="picker.open()" readonly>
              <input matEndDate formControlName="end1" placeholder="End Date" (focus)="picker.open()"
                (click)="picker.open()" readonly>
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
          
          
        </div>
        <div class="estimation">
          <label>{{engagementAllocationText}}</label>
          <mat-form-field appearance="outline">
            <input type="text" matInput autocomplete="off"
             formControlName="allocationValue" [readonly]="true">
          </mat-form-field>
          <!-- <div *ngIf="isValidInput('allocationValue')">
            <div *ngIf="formGroupSplit.controls['allocationValue'].errors.required" class="alert alert-danger">
              {{engagementAllocationText}} is required.
            </div>
            <div *ngIf="formGroupSplit.controls['allocationValue'].errors.pattern" class="alert alert-danger">
              {{engagementAllocationText}} is invalid.
            </div>
          </div> -->

          
        </div>
        <div class="confirmContainer" *ngIf="!user?.Unconfirmed">
          <label>Unconfirmed</label>
          <mat-checkbox [(ngModel)]="unconfirmed" [disableRipple]='true' [ngModelOptions]="{standalone:true}" onclick="return false"></mat-checkbox>
            
        </div>
        <div class="confirmContainer" *ngIf="user?.Ghost">
          <label>Ghost</label>
          <mat-checkbox [(ngModel)]="ghost" [disableRipple]='true' [ngModelOptions]="{standalone:true}" onclick="return false"></mat-checkbox>
            
        </div>
        
      </div>
    </div>
    <div id="split2" class="periodContainer">
      <div class="estimationContainer" style="justify-content: flex-start;">
        <div class="dateSearch" style="margin-right: 4%;width: 50%;">
          <label>Period <span class="mandtoryIcn"> *</span></label>
          <mat-form-field appearance="outline">
  
            <mat-date-range-input formGroupName="Period_2" [rangePicker]="picker2" [min]="startWith">
              <input matStartDate formControlName="start2" placeholder="Start Date" (focus)="picker2.open()"
                (click)="picker2.open()" readonly>
              <input matEndDate formControlName="end2" placeholder="End Date" (focus)="picker2.open()"
                (click)="picker2.open()" readonly (dateChange)="makeDirty('period2')">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-date-range-picker #picker2></mat-date-range-picker>            
            <mat-error *ngIf="formGroupSplit.get('Period_2').get('start2').hasError('matStartDateInvalid')">Invalid Start Date</mat-error>
            <mat-error *ngIf="formGroupSplit.get('Period_2').get('end2').hasError('matEndDateInvalid')">Invalid End Date</mat-error>
          </mat-form-field>
          <div *ngIf="formGroupSplit.get('Period_2').get('start2').value === null && (formGroupSplit.get('Period_2').get('start2').dirty || formGroupSplit.get('Period_2').get('start2').touched)" class="alert alert-danger">
            Start Date is required
          </div>
          <div *ngIf="formGroupSplit.get('Period_2').get('end2').value === null && (formGroupSplit.get('Period_2').get('end2').dirty || formGroupSplit.get('Period_2').get('end2').touched)" class="alert alert-danger">
            End Date is required
          </div>
        </div>
        <div class="estimation">
          <label>{{engagementAllocationText}} <span class="mandtoryIcn"> *</span></label>
          <mat-form-field appearance="outline">
            <input type="text" matInput (input)="makeDirty('Hours_2')" (focus)="mouseEnterSplit() " autocomplete="off" #tooltip="matTooltip"
            [matTooltip]="engagementAllocationPercentSelected ? '' : 'Note when adding time in hours this will be input for the full-time period of the booking, not per week. For long term bookings Loading% may be the preferred way to input time allocation to work (please see “preference” button in top right hand corner to switch between the time allocation input methods)'"
              triggers="hover click focus" placement="bottom" (focusout)="mouseLeave(engagementAllocationTypeValue_2)" formControlName="allocationValue_2" 
              appHoursLoading [isPercentageSelected]="isPercentageSelected">
          </mat-form-field>
          <div *ngIf="isValidInput('allocationValue_2')">
            <div *ngIf="formGroupSplit.controls['allocationValue_2'].errors.required" class="alert alert-danger">
              {{engagementAllocationText}} is required.
            </div>
            <div *ngIf="formGroupSplit.controls['allocationValue_2'].errors.pattern" class="alert alert-danger">
              {{engagementAllocationText}} is invalid.
            </div>
          </div>
          
        </div>
        <div class="confirmContainer" *ngIf="!user?.Unconfirmed">
          <label>Unconfirmed</label>
          <mat-checkbox [(ngModel)]="unconfirmed_2" [disableRipple]='true' (change)="makeDirty('Unconfirmed_2')" [ngModelOptions]="{standalone:true}"></mat-checkbox>
        </div>        
        <div class="confirmContainer" *ngIf="user?.Ghost">
          <label>Ghost</label>
          <mat-checkbox [(ngModel)]="ghost_2" [disableRipple]='true' (change)="makeDirty('')" [ngModelOptions]="{standalone:true}"></mat-checkbox>
        </div>
        <div class="morePeriods">
          <mat-icon class="addPeriod" (change)="makeDirty('addIcon')" (click)="showSplit()">add</mat-icon>
        </div>
      </div>
    </div>
    <div id="split3" *ngIf="showSplit_2" class="periodContainer">
      <div class="estimationContainer" style="justify-content: flex-start;">
        <div class="dateSearch" style="margin-right: 4%;width: 50%;">
          <label>Period <span class="mandtoryIcn"> *</span></label>
          <mat-form-field appearance="outline" disabled readonly>
  
            <mat-date-range-input formGroupName="Period_3" [rangePicker]="picker3" [min]="startWith">
              <input matStartDate formControlName="start3" placeholder="Start Date" (focus)="picker3.open()"
                (click)="picker3.open()" readonly>
              <input matEndDate formControlName="end3" placeholder="End Date" (focus)="picker3.open()"
                (click)="picker3.open()" readonly (dateChange)="makeDirty('period3')">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-date-range-picker #picker3></mat-date-range-picker>
            <mat-error *ngIf="formGroupSplit.get('Period_3').get('start3').hasError('matStartDateInvalid')">Invalid Start Date</mat-error>
            <mat-error *ngIf="formGroupSplit.get('Period_3').get('end3').hasError('matEndDateInvalid')">Invalid End Date</mat-error>            
          </mat-form-field>
          <div *ngIf="formGroupSplit.get('Period_3').get('start3').value === null && (formGroupSplit.get('Period_3').get('start3').dirty || formGroupSplit.get('Period_3').get('start3').touched)" class="alert alert-danger">
            Start Date is required
          </div>
          <div *ngIf="formGroupSplit.get('Period_3').get('end3').value === null && (formGroupSplit.get('Period_3').get('end3').dirty || formGroupSplit.get('Period_3').get('end3').touched)" class="alert alert-danger">
            End Date is required
          </div>
        </div>
        <div class="estimation">
          <label>{{engagementAllocationText}} <span class="mandtoryIcn"> *</span></label>
          <mat-form-field appearance="outline">
            <input type="text" matInput (input)="makeDirty('Hours_3')" (focus)="mouseEnterSplit() " autocomplete="off" #tooltip="matTooltip"
            [matTooltip]="engagementAllocationPercentSelected ? '' : 'Note when adding time in hours this will be input for the full-time period of the booking, not per week. For long term bookings Loading% may be the preferred way to input time allocation to work (please see “preference” button in top right hand corner to switch between the time allocation input methods)'"
              triggers="hover click focus" placement="bottom" (focusout)="mouseLeave(engagementAllocationTypeValue_3)" formControlName="allocationValue_3"
              appHoursLoading [isPercentageSelected]="isPercentageSelected">
          </mat-form-field>
          <div *ngIf="isValidInput('allocationValue_3')">
            <div *ngIf="formGroupSplit.controls['allocationValue_3'].errors.required" class="alert alert-danger">
              {{engagementAllocationText}} is required.
            </div>
            <div *ngIf="formGroupSplit.controls['allocationValue_3'].errors.pattern" class="alert alert-danger">
              {{engagementAllocationText}} is invalid.
            </div>
          </div>
        </div>
        <div class="confirmContainer" *ngIf="!user?.Unconfirmed">
          <label>Unconfirmed</label>
          <mat-checkbox [(ngModel)]="unconfirmed_3" [disableRipple]='true' (change)="makeDirty('Unconfirmed_3')" [ngModelOptions]="{standalone:true}"></mat-checkbox>
        </div>
        <div class="confirmContainer" *ngIf="user?.Ghost">
          <label>Ghost</label>
          <mat-checkbox [(ngModel)]="ghost_3" [disableRipple]='true' (change)="makeDirty('')" [ngModelOptions]="{standalone:true}"></mat-checkbox>
        </div>
        <div class="morePeriods">
          <mat-icon class="addPeriod" (change)="makeDirty('addIcon')" (click)="hideSplit(2)">remove</mat-icon>
        </div>
      </div>
    </div>
    <div id="split4" *ngIf="showSplit_3" class="periodContainer">
      <div class="estimationContainer" style="justify-content: flex-start;">
        <div class="dateSearch" style="margin-right: 4%;width: 50%;">
          <label>Period <span class="mandtoryIcn"> *</span></label>
          <mat-form-field appearance="outline" disabled readonly>
  
            <mat-date-range-input formGroupName="Period_4" [rangePicker]="picker4" [min]="startWith">
              <input matStartDate formControlName="start4" placeholder="Start Date" (focus)="picker4.open()"
                (click)="picker4.open()" readonly>
              <input matEndDate formControlName="end4" placeholder="End Date" (focus)="picker4.open()"
                (click)="picker4.open()" readonly (dateChange)="makeDirty('period4')">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
            <mat-date-range-picker #picker4></mat-date-range-picker>
            <mat-error *ngIf="formGroupSplit.get('Period_4').get('start4').hasError('matStartDateInvalid')">Invalid Start Date</mat-error>
            <mat-error *ngIf="formGroupSplit.get('Period_4').get('end4').hasError('matEndDateInvalid')">Invalid End Date</mat-error>            
          </mat-form-field>
          <div *ngIf="formGroupSplit.get('Period_4').get('start4').value === null && (formGroupSplit.get('Period_4').get('start4').dirty || formGroupSplit.get('Period_4').get('start4').touched)" class="alert alert-danger">
            Start Date is required
          </div>
          <div *ngIf="formGroupSplit.get('Period_4').get('end4').value === null && (formGroupSplit.get('Period_4').get('end4').dirty || formGroupSplit.get('Period_4').get('end4').touched)" class="alert alert-danger">
            End Date is required
          </div>
          
        </div>
        <div class="estimation">
          <label>{{engagementAllocationText}} <span class="mandtoryIcn"> *</span></label>
          <mat-form-field appearance="outline">
            <input type="text" matInput (input)="makeDirty('Hours_4')" (focus)="mouseEnterSplit() " autocomplete="off" #tooltip="matTooltip"
            [matTooltip]="engagementAllocationPercentSelected ? '' : 'Note when adding time in hours this will be input for the full-time period of the booking, not per week. For long term bookings Loading% may be the preferred way to input time allocation to work (please see “preference” button in top right hand corner to switch between the time allocation input methods)'"
              triggers="hover click focus" placement="bottom" (focusout)="mouseLeave(engagementAllocationTypeValue_4)" formControlName="allocationValue_4"
              appHoursLoading [isPercentageSelected]="isPercentageSelected">
          </mat-form-field>
          <div *ngIf="isValidInput('allocationValue_4')">
            <div *ngIf="formGroupSplit.controls['allocationValue_4'].errors.required" class="alert alert-danger">
              {{engagementAllocationText}} is required.
            </div>
            <div *ngIf="formGroupSplit.controls['allocationValue_4'].errors.pattern" class="alert alert-danger">
              {{engagementAllocationText}} is invalid.
            </div>
          </div>
          
        </div>
        <div class="confirmContainer" *ngIf="!user?.Unconfirmed">
          <label>Unconfirmed</label>
          <mat-checkbox [(ngModel)]="unconfirmed_4" [disableRipple]='true' (change)="makeDirty('Unconfirmed_4')" [ngModelOptions]="{standalone:true}"></mat-checkbox>
        </div>
        <div class="confirmContainer" *ngIf="user?.Ghost">
          <label>Ghost</label>
          <mat-checkbox [(ngModel)]="ghost_4" [disableRipple]='true' (change)="makeDirty('')" [ngModelOptions]="{standalone:true}"></mat-checkbox>
        </div>
        <div class="morePeriods">
          <mat-icon class="addPeriod" (change)="makeDirty('addIcon')" (click)="hideSplit(3)">remove</mat-icon>
        </div>
      </div>
    </div>
    <div id="split5" *ngIf="showSplit_4" class="periodContainer">
      <div class="estimationContainer" style="justify-content: flex-start;">
        <div class="dateSearch" style="margin-right: 4%;width: 50%;">
          <label>Period <span class="mandtoryIcn"> *</span></label>
          <mat-form-field appearance="outline" disabled readonly>
  
            <mat-date-range-input formGroupName="Period_5" [rangePicker]="picker5" [min]="startWith">
              <input matStartDate formControlName="start5" placeholder="Start Date" (focus)="picker5.open()"
                (click)="picker5.open()" readonly>
              <input matEndDate formControlName="end5" placeholder="End Date" (focus)="picker5.open()"
                (click)="picker5.open()" readonly (dateChange)="makeDirty('period5')">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
            <mat-date-range-picker #picker5></mat-date-range-picker>
            <mat-error *ngIf="formGroupSplit.get('Period_5').get('start5').hasError('matStartDateInvalid')">Invalid Start Date</mat-error>
            <mat-error *ngIf="formGroupSplit.get('Period_5').get('end5').hasError('matEndDateInvalid')">Invalid End Date</mat-error>
          </mat-form-field>
          <div *ngIf="formGroupSplit.get('Period_5').get('start5').value === null && (formGroupSplit.get('Period_5').get('start5').dirty || formGroupSplit.get('Period_5').get('start5').touched)" class="alert alert-danger">
            Start Date is required
          </div>
          <div *ngIf="formGroupSplit.get('Period_5').get('end5').value === null && (formGroupSplit.get('Period_5').get('end5').dirty || formGroupSplit.get('Period_5').get('end5').touched)" class="alert alert-danger">
            End Date is required
          </div>
          
        </div>
        <div class="estimation">
          <label>{{engagementAllocationText}} <span class="mandtoryIcn"> *</span></label>
          <mat-form-field appearance="outline">
            <input type="text" matInput (input)="makeDirty('Hours_5')" (focus)="mouseEnterSplit() " autocomplete="off" #tooltip="matTooltip"
            [matTooltip]="engagementAllocationPercentSelected ? '' : 'Note when adding time in hours this will be input for the full-time period of the booking, not per week. For long term bookings Loading% may be the preferred way to input time allocation to work (please see “preference” button in top right hand corner to switch between the time allocation input methods)'"
              triggers="hover click focus" placement="bottom" (focusout)="mouseLeave(engagementAllocationTypeValue_5)" formControlName="allocationValue_5"
              appHoursLoading [isPercentageSelected]="isPercentageSelected">
          </mat-form-field>
          <div *ngIf="isValidInput('allocationValue_5')">
            <div *ngIf="formGroupSplit.controls['allocationValue_5'].errors.required" class="alert alert-danger">
              {{engagementAllocationText}} is required.
            </div>
            <div *ngIf="formGroupSplit.controls['allocationValue_5'].errors.pattern" class="alert alert-danger">
              {{engagementAllocationText}} is invalid.
            </div>
          </div>
          
        </div>
        <div class="confirmContainer" *ngIf="!user?.Unconfirmed">
          <label>Unconfirmed</label>
          <mat-checkbox [(ngModel)]="unconfirmed_5" [disableRipple]='true' (change)="makeDirty('Unconfirmed_5')" [ngModelOptions]="{standalone:true}"></mat-checkbox>
        </div>
        <div class="confirmContainer" *ngIf="user?.Ghost">
          <label>Ghost</label>
          <mat-checkbox [(ngModel)]="ghost_5" [disableRipple]='true' (change)="makeDirty('')" [ngModelOptions]="{standalone:true}"></mat-checkbox>
        </div>
        <div class="morePeriods">
          <mat-icon class="addPeriod" (change)="makeDirty('addIcon')" (click)="hideSplit(4)">remove</mat-icon>
        </div>
      </div>
    </div>
    <div class="customRow">
      <div class="col100percent">
        <label>Notes Description</label>
        <mat-form-field appearance="outline">
          <input matInput tooltip="Please do not enter any unnecessary personal information or any sensitive personal data (including tax file numbers or other government identifiers), client confidential information, audit secrets, state secrets, commercial secrets, or anything that would violate professional secrecy or confidentiality rules or that would be considered abusive/irrelevant."
        type="text" triggers="hover click focus" placement="bottom"
        [(ngModel)]='description' [ngModelOptions]="{standalone:true}" maxlength="200">
        </mat-form-field>
      </div>          
    </div>
    <div class="customRow" *ngIf="!hoursValid">
      <div class="col80percent" [class.disabled]="overallocationerror">
        <label>This Booking request will cause an overallocation. If you wish to proceed, please select the check box and click on Copy.</label>
      </div>
      <div class="col20percent" [class.disabled]="overallocationerror">
        <label>Confirm</label>
        <mat-checkbox [disableRipple]='true' (change)="confirmHoursChange()" [(ngModel)]="confirmHours" [ngModelOptions]="{standalone:true}"></mat-checkbox>
      </div>
    </div> 
    <div class="SubmitContainer">
      <button class="submitButton" type="submit" [disabled]="(!(buttonText == 'Copy'))">
        <span class="glyphicon glyphicon-ok-sign pr-5" style="font-size: 12px;"></span>
        {{buttonText}}
      </button>
    </div>
  </div>
  
</form>
