export class RoleApprovalConfigurationVM {
    RAC_JC_ID?: number;
    JC_NAME?: string;
    RAC_ActionID?: number;
    Action_Description?: string;
    RAC_CWEEK: boolean | string;
    RAC_NWEEK: boolean | string;
    RAC_NNWEEK: boolean | string;
    RAC_OverAllocation: boolean | string;
    RAC_CWEEKWPD: boolean | string;
}