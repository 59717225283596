//import { isNullOrUndefined } from 'util';
import { RoleClaim } from '../Models/roleClaim';
import { RoleFeatureDto } from './RoleFeatureDto';

export class CurrentUser {
    private _access_token: string;
    private _expires: string;
    private _issued: string;
    private _token_type: string;
    private _userName: string;
    private _authUserid: string;
    private _firstName: string;
    private _lastName: string;
    private _id: number;
    private _refresh_token: string;
    private _form_token: string;
    private _role_claims: RoleClaim[] = [];
    private loggedinUser = JSON.parse(sessionStorage.getItem('resource'));
    constructor(
        userName?: string
        , authUserid?: string
        , token?: string
        , refreshToken?: string
        , expires?: string
        , issued?: string
        , tokenType?: string
        , id?: number
        , firstName?: string
        , lastName?: string
        , formToken?: string
    ) {
        this._access_token = token;
        this._userName = userName;
        this._authUserid = authUserid;
        this._expires = expires;
        this._token_type = tokenType;
        this._firstName = firstName;
        this._lastName = lastName;
        this._id = id;
        this._issued = issued;
        this._refresh_token = refreshToken;
        this._form_token = formToken;
    }

    public static create(value: string): CurrentUser {
        const d = <CurrentUser>JSON.parse(value);

        return new CurrentUser(
            d.userName
            , d.authUserid
            , d.access_token
            , d.expires

        );
    }

    updateToken(access_token: string, refresh_token: string, expires: string, issued: string) {
        this._access_token = access_token;
        this._refresh_token = refresh_token;
        this._expires = expires;
        this._issued = issued;
    }

    public toJSON(): string {
        const obj = Object.assign(this);
        const keys = Object.keys(this.constructor.prototype);
        obj.toJSON = undefined;
        return JSON.stringify(obj, keys);
    }

    get access_token(): string {
        return sessionStorage.getItem('access_token');
    }

    get expires(): string {
        return sessionStorage.getItem('expires_on');
    }

    get userName(): string {
        return this.loggedinUser.Email;
        // return sessionStorage.getItem('user_id');
    }

    get authUserid(): string {
        return this.loggedinUser.Email;
        // return sessionStorage.getItem('user_id');
    }

    get role_claims(): string {
        return sessionStorage.getItem('userclaims');
    }

    // get isAuthenticated(): boolean {
    //     //return !(this.isNullOrUndefined(this.access_token));
    //     return this.access_token !== null && this.access_token !== undefined;
    // }

    // public static get Email(): string {
    //   return !this.isNullOrUndefined(sessionStorage.getItem('user_id'))
    //             ? sessionStorage.getItem('user_id')
    //             : '';
    // }

    public static get RoleFeatures(): RoleFeatureDto[] {
        let roleFeatures: RoleFeatureDto[] = [];
        const strRoleFeatures = sessionStorage.getItem('roleFeatures');
        if(!this.isNullOrUndefined(strRoleFeatures) && strRoleFeatures != ''){
            roleFeatures = JSON.parse(strRoleFeatures);
        }
        if(this.isNullOrUndefined(roleFeatures)){
            roleFeatures = [];
        }
        return roleFeatures;
    }
    
    public static get RoleTypeName(): string {
      return !this.isNullOrUndefined(sessionStorage.getItem('RoleTypeName'))
                ? sessionStorage.getItem('RoleTypeName')
                : '';
    }
    
  public static get RoleTypeDisplayName(): string {
    return !this.isNullOrUndefined(sessionStorage.getItem('RoleTypeDisplayName'))
      ? sessionStorage.getItem('RoleTypeDisplayName')
      : '';
  }

  public static get RoleName(): string {
    return !this.isNullOrUndefined(sessionStorage.getItem('RoleName'))
      ? sessionStorage.getItem('RoleName')
      : '';
  }

  public static get RoleId(): number {
    return !this.isNullOrUndefined(sessionStorage.getItem('RoleId'))
      ? +sessionStorage.getItem('RoleId')
      : 0;
  }

  public static get LoggedInResourceId(): number {
    return !this.isNullOrUndefined(sessionStorage.getItem('loggedin_resourceid'))
      ? +sessionStorage.getItem('loggedin_resourceid')
      : 0;
  }

  private static isNullOrUndefined(value: any){
    return value === null || value === undefined;
  }
}
