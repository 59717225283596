import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { CompletedRequestQueueService } from '../completed-request-queue.service';
import { CompletedRequestFilterDto, CompletedRequestVM, CompletedRequestXLVM } from '../../../Models/CompletedRequestVM';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { SharedServicesService } from '../../../shared/sharedservices/shared-services.service';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UserVM } from 'app/Models/UserVM';
import { ExcelService } from 'app/shared/excel/excel.service';
import { formatDate } from "@angular/common";

//import { ExcelService } from './services/excel.service';
// import * as FileSaver from 'file-saver';
// import * as ExcelJS from 'exceljs/dist/exceljs.min.js';

@Component({
  selector: 'app-completed-request-queue',
  templateUrl: './completed-request-queue.component.html',
  styleUrls: ['./completed-request-queue.component.scss']
})



export class CompletedRequestQueueComponent implements OnInit {
  // EXCEL_TYPE =
  // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  // EXCEL_EXTENSION = '.xlsx';
  modalRef: BsModalRef;
  modalOption: ModalOptions = {};
  completedRequestXLList: CompletedRequestXLVM[];
  completedRequestXLListFinal: CompletedRequestXLVM[];
  bookingList: CompletedRequestVM[];
  isMultiInstance: boolean = false;
  gridColumns: string[] = ['RequestNumber', 'BookingId', 'Status', 'RejectionReason',
    'ApproverComment', 'ActionId', 'ActionType', 'Engagement', 'ResourceName', 'Rank', 'ServiceLine',
    'SubServiceLine', 'SubManagementUnit', 'Department', 'CostCenter', 'OfficeLocation', 'Hours', 'PercentLoading',
    'UserSelection', 'StartDate', 'EndDate', 'Unconfirmed', 'RequestorName',
    'RequestedTime', 'CompletedTime', 'HoursCompletedIn', 'SubAreaDescription',
    'RequestModeDisplay', 'UpdatedBy', 'ApprovedOrRejectedBy', 'ApprovedOrRejectedOn'];
  filterGridColumns: string[] = ['RequestNumberFilter', 'BookingIdFilter', 'StatusFilter', 'RejectionReasonFilter',
    'ApproverCommentFilter', 'ActionIdFilter', 'ActionTypeFilter', 'EngagementFilter', 'ResourceNameFilter', 'RankFilter', 'ServiceLineFilter',
    'SubServiceLineFilter', 'SubManagementUnitFilter', 'DepartmentFilter', 'CostCenterFilter', 'OfficeLocationFilter', 'HoursFilter', 'PercentLoadingFilter',
    'UserSelectionFilter', 'StartDateFilter', 'EndDateFilter', 'UnconfirmedFilter', 'RequestorNameFilter',
    'RequestedTimeFilter', 'CompletedTimeFilter', 'HoursCompletedInFilter', 'SubAreaDescriptionFilter',
    'RequestModeDisplayFilter', 'UpdatedByFilter', 'ApprovedOrRejectedByFilter', 'ApprovedOrRejectedOnFilter'];
  excelColumns = [
    { Key: 'RequestNumber', value: 'Request ID' },
    { Key: 'BookingId', value: 'Booking ID' },
    { Key: 'Status', value: 'Status' },
    { Key: 'RejectionReason', value: 'Status Information' },
    { Key: 'ApproverComment', value: 'Approver Comment' },
    { Key: 'ActionId', value: 'Action ID' },
    { Key: 'ActionType', value: 'Action' },
    { Key: 'InstanceName', value: 'Retain Instance' },
    { Key: 'Engagement', value: 'Engagement' },
    { Key: 'ResourceName', value: 'Resource' },
    { Key: 'Rank', value: 'Rank' },
    { Key: 'ServiceLine', value: 'Service Line' },
    { Key: 'SubServiceLine', value: 'Sub-Service Line' },
    { Key: 'SubManagementUnit', value: 'Sub-Management Unit' },
    { Key: 'Department', value: 'HR Department' },
    { Key: 'CostCenter', value: 'Cost Center' },
    { Key: 'OfficeLocation', value: 'Office Location' },
    { Key: 'Hours', value: 'Hours' },
    { Key: 'PercentLoading', value: 'Loading %' },
    { Key: 'UserSelection', value: 'User Selection' },
    { Key: 'StartDate', value: 'Start Date' },
    { Key: 'EndDate', value: 'End Date' },
    { Key: 'Unconfirmed', value: 'Unconfirmed' },
    { Key: 'Ghost', value: 'Ghost' },
    { Key: 'RequestorName', value: 'Requestor Name' },
    { Key: 'RequestedTime', value: 'Requested Time(GMT)' },
    { Key: 'CompletedTime', value: 'Completed Time(GMT)' },
    { Key: 'HoursCompletedIn', value: 'SLA(hrs)' },
    { Key: 'SubAreaDescription', value: 'Resource Sub-Area' },
    { Key: 'RequestModeDisplay', value: 'Booking Mode' },
    { Key: 'UpdatedBy', value: 'Updated By' },
    { Key: 'ApprovedOrRejectedBy', value: 'Approved/Rejected By' },
    { Key: 'ApprovedOrRejectedOn', value: 'Approved/Rejected On' },
  ]
  gridDataSource: MatTableDataSource<CompletedRequestVM>;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChild('empTbSort') empTbSort = new MatSort();
  gridFilterValues = {
    requestId: '',
    requestIdType: true,
    bookingId: '',
    bookingIdType: true,
    status: '',
    statusType: true,
    action: '',
    actionType: true,
    engagement: '',
    engagementType: true,
    resource: '',
    resourceType: true,
    rank: '',
    rankType: true,
    serviceLine: '',
    serviceLineType: true,
    subServiceLine: '',
    subServiceLineType: true,
    subManagementUnit: '',
    subManagementUnitType: true,
    department: '',
    departmentType: true,
    costCenter: '',
    costCenterType: true,
    officeLocation: '',
    officeLocationType: true,
    requestorName: '',
    requestorNameType: true,
    completedTime: '',
    completedTimeType: true,
    SlaHrs: '',
    SlaHrsType: true,
    resourceSubArea: '',
    resourceSubAreaType: true,
    bookingMode: '',
    bookingModeType: true,
    updatedBy: '',
    updatedByType: true,
    global: '',
    globalType: false
  };
  containsGridFilter: boolean = true
  requestIdFilter = new FormControl('');
  bookingIdFilter = new FormControl('');
  statusFilter = new FormControl('');
  actionFilter = new FormControl('');
  engagementFilter = new FormControl('');
  resourceFilter = new FormControl('');
  rankFilter = new FormControl('');
  serviceLineFilter = new FormControl('');
  subServiceLineFilter = new FormControl('');
  subManagementUnitFilter = new FormControl('');
  departmentFilter = new FormControl('');
  costCenterFilter = new FormControl('');
  officeLocationFilter = new FormControl('');
  requestorNameFilter = new FormControl('');
  completedTimeFilter = new FormControl('');
  SlaHrsFilter = new FormControl('');
  resourceSubAreaFilter = new FormControl('');
  bookingModeFilter = new FormControl('');
  updatedByFilter = new FormControl('');
  keywordSearch = new FormControl('');

  filterVM: CompletedRequestFilterDto;
  filterMC: any[] = [];
  filterSL: any[] = [];
  filterSSL: any[] = [];
  filterSMU: any[] = [];
  filterInstances: any[] = [];
  bookingStatusList = [
    { value: "-1", text: "All" },
    { value: "Cancelled", text: "Cancelled" },
    { value: "Completed", text: "Completed" },
    //{ value: "Draft", text: "Draft" },
    //{ value: "In Progress", text: "In Progress" },
    { value: "Rejected", text: "Rejected" },
    //{ value: "Submitted", text: "Submitted" }
    { value: "Denied", text: "Denied" }];
  dropdownSettings: IDropdownSettings = {};
  masterData: any = {};
  defaultBookingStatus = "Completed";
  hadFiltersEarlierSaved = false;
  defaultEndDate: Date = new Date();
  defaultStartDate: Date = new Date(new Date().setDate(new Date().getDate() - 1)); // yesterday date 
  maxRequestedDate: Date = new Date();
  minRequestedDate: Date = new Date(new Date().setDate(new Date().getDate() - 90)); // Three months back date
  //requestDateRange: Date[];  
  formGroup = this.formBuilder.group({
    dateRange: this.formBuilder.group({
    dtStart: ['', Validators.required],
    dtEnd: ['', Validators.required],
  })
  });
  bookingListSubscription: Subscription;
  colorTheme = 'theme-dark-blue';
  activeTabName = 'CompletedRequest';
  format = '#.## \\%';
  private filter: any;
  skip: number = 0;
  pageSize: number = 20;
  public currentDate = new Date();
  public rowsToHighlight: any[] = [];
  public multiple = false;
  public allowUnsort = true;
  public ExportFileName = '';
  loading = true;
  loggedInUser: UserVM;
  defaultDateFormat: string;
  instances : any[] = [];
  selectedInstance;
  instanceList;

  constructor(public restService: CompletedRequestQueueService,
    private spinner: NgxSpinnerService, private router: Router,
    private datePipe: DatePipe,
    private modalService: BsModalService,
    public sharedService: SharedServicesService,
    private excelService: ExcelService,
    private formBuilder: FormBuilder) {
    this.sharedService.activeLink = 'CompletedRequest';
  }

  ngOnInit() {
    this.loggedInUser = JSON.parse(sessionStorage.getItem('resource'));
    this.defaultDateFormat = this.sharedService.getDateFormat(this.loggedInUser.DefaultDateFormat);
    this.isMultiInstance = this.loggedInUser.IsMultiInstance;
    if (this.isMultiInstance) {
      this.gridColumns.splice(this.gridColumns.indexOf('ActionType') + 1, 0, 'InstanceName');
      this.filterGridColumns.splice(this.filterGridColumns.indexOf('ActionTypeFilter') + 1, 0, 'InstanceNameFilter');
    }
    if (this.loggedInUser.Unconfirmed) {
      this.gridColumns.splice(this.gridColumns.indexOf('EndDate') + 1, 1);
      this.filterGridColumns.splice(this.filterGridColumns.indexOf('EndDateFilter') + 1, 1);
    }
    if (this.loggedInUser.Ghost) {
      this.gridColumns.splice(this.gridColumns.indexOf('Unconfirmed') + 1, 0, 'Ghost');
      this.filterGridColumns.splice(this.filterGridColumns.indexOf('UnconfirmedFilter') + 1, 0, 'GhostFilter');
    }
    this.sharedService.activeLink = 'CompletedRequest';
    // this.setDayStartTime(this.minRequestedDate);
    // this.setDayEndTime(this.maxRequestedDate);
    // this.setDayStartTime(this.defaultStartDate);
    // this.setDayEndTime(this.defaultEndDate);

    this.sharedService.getUserInstances().subscribe(
      data => {
        this.instanceList = this.sharedService.getStoredUserInstances();
        this.instances.push({
          InstanceId: -1,
          InstanceName: "All Instances"
        })

        this.instanceList.forEach((i) => {
          this.instances.push(i);
        });

        if (!this.isMultiInstance) {
          const selected = this.instanceList.filter(x => x.InstanceId === this.loggedInUser.DefaultInstance);
          if (selected.length > 0) {
            this.selectedInstance = selected[0].InstanceId;
            //this.instances.push(selected[0]);
          }
        }
        if(!this.selectedInstance || this.selectedInstance == 0) {
          this.selectedInstance = -1;
        }
      });


    this.dropdownSettings = {
      singleSelection: false,
      idField: 'ID',
      textField: 'Text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      enableCheckAll: false
    };

    this.setDefaultData();
    this.handleGridFilterChange();
  }

  ngOnDestroy() {
    if (this.bookingListSubscription !== undefined) {
      this.bookingListSubscription.unsubscribe();
    }
  }

  handleGridFilterChange() {
    this.requestIdFilter.valueChanges.subscribe(
      filterValue => {
        this.gridFilterValues.requestId = filterValue;
        this.gridDataSource.filter = JSON.stringify(this.gridFilterValues);
      }
    )
    this.bookingIdFilter.valueChanges.subscribe(
      filterValue => {
        this.gridFilterValues.bookingId = filterValue;
        this.gridDataSource.filter = JSON.stringify(this.gridFilterValues);
      }
    )
    this.statusFilter.valueChanges.subscribe(
      filterValue => {
        this.gridFilterValues.status = filterValue;
        this.gridDataSource.filter = JSON.stringify(this.gridFilterValues);
      }
    )
    this.actionFilter.valueChanges.subscribe(
      filterValue => {
        this.gridFilterValues.action = filterValue;
        this.gridDataSource.filter = JSON.stringify(this.gridFilterValues);
      }
    )
    this.engagementFilter.valueChanges.subscribe(
      filterValue => {
        this.gridFilterValues.engagement = filterValue;
        this.gridDataSource.filter = JSON.stringify(this.gridFilterValues);
      }
    )
    this.resourceFilter.valueChanges.subscribe(
      filterValue => {
        this.gridFilterValues.resource = filterValue;
        this.gridDataSource.filter = JSON.stringify(this.gridFilterValues);
      }
    )
    this.rankFilter.valueChanges.subscribe(
      filterValue => {
        this.gridFilterValues.rank = filterValue;
        this.gridDataSource.filter = JSON.stringify(this.gridFilterValues);
      }
    )
    this.serviceLineFilter.valueChanges.subscribe(
      filterValue => {
        this.gridFilterValues.serviceLine = filterValue;
        this.gridDataSource.filter = JSON.stringify(this.gridFilterValues);
      }
    )
    this.subServiceLineFilter.valueChanges.subscribe(
      filterValue => {
        this.gridFilterValues.subServiceLine = filterValue;
        this.gridDataSource.filter = JSON.stringify(this.gridFilterValues);
      }
    )
    this.subManagementUnitFilter.valueChanges.subscribe(
      filterValue => {
        this.gridFilterValues.subManagementUnit = filterValue;
        this.gridDataSource.filter = JSON.stringify(this.gridFilterValues);
      }
    )
    this.departmentFilter.valueChanges.subscribe(
      filterValue => {
        this.gridFilterValues.department = filterValue;
        this.gridDataSource.filter = JSON.stringify(this.gridFilterValues);
      }
    )
    this.costCenterFilter.valueChanges.subscribe(
      filterValue => {
        this.gridFilterValues.costCenter = filterValue;
        this.gridDataSource.filter = JSON.stringify(this.gridFilterValues);
      }
    )
    this.officeLocationFilter.valueChanges.subscribe(
      filterValue => {
        this.gridFilterValues.officeLocation = filterValue;
        this.gridDataSource.filter = JSON.stringify(this.gridFilterValues);
      }
    )
    this.requestorNameFilter.valueChanges.subscribe(
      filterValue => {
        this.gridFilterValues.requestorName = filterValue;
        this.gridDataSource.filter = JSON.stringify(this.gridFilterValues);
      }
    )
    this.completedTimeFilter.valueChanges.subscribe(
      filterValue => {
        this.gridFilterValues.completedTime = filterValue;
        this.gridDataSource.filter = JSON.stringify(this.gridFilterValues);
      }
    )
    this.SlaHrsFilter.valueChanges.subscribe(
      filterValue => {
        this.gridFilterValues.SlaHrs = filterValue;
        this.gridDataSource.filter = JSON.stringify(this.gridFilterValues);
      }
    )
    this.resourceSubAreaFilter.valueChanges.subscribe(
      filterValue => {
        this.gridFilterValues.resourceSubArea = filterValue;
        this.gridDataSource.filter = JSON.stringify(this.gridFilterValues);
      }
    )
    this.bookingModeFilter.valueChanges.subscribe(
      filterValue => {
        this.gridFilterValues.bookingMode = filterValue;
        this.gridDataSource.filter = JSON.stringify(this.gridFilterValues);
      }
    )
    this.updatedByFilter.valueChanges.subscribe(
      filterValue => {
        this.gridFilterValues.updatedBy = filterValue;
        this.gridDataSource.filter = JSON.stringify(this.gridFilterValues);
      }
    )
    this.keywordSearch.valueChanges.subscribe(
      filterValue => {
        if (filterValue.trim().length > 0) {
          this.gridFilterValues.globalType = true;
        } else {
          this.gridFilterValues.globalType = false;
        }
        this.gridFilterValues.global = filterValue;
        this.gridDataSource.filter = JSON.stringify(this.gridFilterValues);
      }
    )
  }

  setDefaultData() {
    this.filterVM = new CompletedRequestFilterDto();
    this.filterVM.BookingStatus = this.defaultBookingStatus;
    //this.requestDateRange = [new Date(this.defaultStartDate), new Date(this.defaultEndDate)];
    this.formGroup.get('dateRange').get('dtStart').setValue(new Date(this.defaultStartDate));
    this.formGroup.get('dateRange').get('dtEnd').setValue(new Date(this.defaultEndDate));
    // this.dateRange.setValue({
    //   dtStart: new Date(this.defaultStartDate),
    //   dtEnd: new Date(this.defaultEndDate)
    // });
    // this.filterVM.StartDate = this.datePipe.transform(this.requestDateRange[0], "dd-MMM-yyyy HH:mm:ss"); 
    // this.filterVM.EndDate = this.datePipe.transform(this.requestDateRange[1], "dd-MMM-yyyy HH:mm:ss");
    this.filterVM.StartDate = this.datePipe.transform(new Date(this.defaultStartDate), "dd-MMM-yyyy HH:mm:ss");
    this.filterVM.EndDate = this.datePipe.transform(new Date(this.defaultEndDate), "dd-MMM-yyyy HH:mm:ss");
    this.getMasterData();
  }

  isDefaultFilter() {
    let startDate = this.formGroup.get('dateRange').get('dtStart').value;
    //let startDate = this.dateRange.get('dtStart').value;//this.requestDateRange[0];
    // this.setDayStartTime(startDate._d);
    let endDate = this.formGroup.get('dateRange').get('dtEnd').value;
    //let endDate = this.dateRange.get('dtEnd').value;//this.requestDateRange[1];
    // this.setDayEndTime(endDate._d);

    return this.filterVM.BookingStatus == this.defaultBookingStatus &&
      startDate._d.getTime() === this.defaultStartDate.getTime() &&
      endDate._d.getTime() === this.defaultEndDate.getTime() &&
      this.filterMC.length == 0 && this.filterSL.length == 0 &&
      this.filterSSL.length == 0 && this.filterSMU.length == 0 && this.selectedInstance == -1;
  }

  getMasterData() {
    this.showSpinner();
    this.loading = true;
    this.restService.getFilterMasterData().subscribe(data => {
      this.masterData = data;
      let startDateFromDB=new Date(this.masterData.StartDate);
      var startDateFromDBFormat = this.getFormattedDateWithTimeZero(startDateFromDB)
      let endDateFromDB = new Date(this.masterData.EndDate);
      var endDateFromDBFormat = this.getFormattedDateWithTimeZero(endDateFromDB)
      var minRequestedDateFormat= this.getFormattedDateWithTimeZero(this.minRequestedDate)
      var saveFilter= false;
      // if ( (startDateFromDBFormat.getTime() <  minRequestedDateFormat.getTime())
      //  ||  (endDateFromDBFormat.getTime() <  minRequestedDateFormat.getTime() ) )
      // {
      //   saveFilter= true;
      // }
      this.getBookingList(saveFilter);
    });
  }

  // setDayStartTime(dt: Date): void {
  //   dt.setHours(0);
  //   dt.setMinutes(0);
  //   dt.setSeconds(0);
  // }
  // setDayEndTime(dt: Date): void {
  //   dt.setHours(23);
  //   dt.setMinutes(59);
  //   dt.setSeconds(59);
  // }

  public onFilterChange(filter: any): void {
    this.filter = filter;
    this.skip = 0;
  }

  updateFilter(filter: CompletedRequestFilterDto) {
    var startDate = new Date(filter.StartDate)
    startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(),
      startDate.getHours(), startDate.getMinutes() - startDate.getTimezoneOffset());
    var EndDate = new Date(filter.EndDate)
    EndDate = new Date(EndDate.getFullYear(), EndDate.getMonth(), EndDate.getDate(),
      EndDate.getHours(), EndDate.getMinutes() - EndDate.getTimezoneOffset());
     var startDateFormat= this.getFormattedDateWithTimeZero(startDate);
     var endFateFormat =this.getFormattedDateWithTimeZero(EndDate);
     var minRequestedDateFormat= this.getFormattedDateWithTimeZero(this.minRequestedDate);

    if(endFateFormat < minRequestedDateFormat || startDateFormat < minRequestedDateFormat){
      this.formGroup.get('dateRange').get('dtStart').setValue(this.defaultStartDate);
      this.formGroup.get('dateRange').get('dtEnd').setValue(this.defaultEndDate);
    }else{
      this.formGroup.get('dateRange').get('dtStart').setValue(startDate);
      this.formGroup.get('dateRange').get('dtEnd').setValue(EndDate);
    }
    // this.formGroup.get('dateRange').get('dtStart').setValue(startDate);
    // this.formGroup.get('dateRange').get('dtEnd').setValue(EndDate);

    // this.dateRange.setValue({
    //   dtStart: startDate,
    //   dtEnd: EndDate
    // });
    this.filterMC = this.masterData.ManageCountryList.filter(x => filter.ManagerialCountry.includes(x.ID));
    this.filterSL = this.masterData.ServiceLineList.filter(x => filter.ServiceLine.includes(x.ID));
    this.filterSSL = this.masterData.SubServiceLineList.filter(x => filter.SubServiceLine.includes(x.ID));
    this.filterSMU = this.masterData.SubManagementUnitList.filter(x => filter.SubManagementUnit.includes(x.ID));
    this.selectedInstance = filter.SelectedInstanceId;
    this.instances = [];
    this.instances.push({
      InstanceId: -1,
      InstanceName: "All Instances"
    })
    this.instanceList.forEach((i) => {
      this.instances.push(i);
    });
    const selected = this.instanceList.filter(x => x.InstanceId === filter.SelectedInstanceId);
    if (selected.length > 0) {
      this.selectedInstance = selected[0].InstanceId;
      //this.instances.push(selected[0]);
    }
    else {
      if (!this.isMultiInstance) {
        const selectedDropdown = this.instanceList.filter(x => x.InstanceId === this.loggedInUser.DefaultInstance);
        if (selectedDropdown.length > 0) {
          this.selectedInstance = selectedDropdown[0].InstanceId;
          //this.instances.push(selected[0]);
        }
      }
      if(this.selectedInstance == 0) {
        this.selectedInstance = -1;
      }
      // if(this.selectedInstance === -1) {
      //   const selectedDropdown = this.instanceList.filter(x => x.InstanceId === this.loggedInUser.DefaultInstance);
      //   if (selectedDropdown.length > 0) {
      //     this.selectedInstance = selected[0].InstanceId;
      //     //this.instances.push(selectedDropdown[0]);
      //   }
      // }
    }
    
  }

  prepareFilterVM(saveFilters: boolean) {
    this.filterVM.StartDate = this.datePipe.transform(this.formGroup.get('dateRange').get('dtStart').value, "dd-MMM-yyyy HH:mm:ss");
    this.filterVM.EndDate = this.datePipe.transform(this.formGroup.get('dateRange').get('dtEnd').value, "dd-MMM-yyyy HH:mm:ss");
    this.filterVM.SaveFilters = this.isDefaultFilter() && !this.hadFiltersEarlierSaved ? false : saveFilters;
    this.filterVM.ManagerialCountry = this.filterMC.map(x => x.ID);
    this.filterVM.ServiceLine = this.filterSL.map(x => x.ID);
    this.filterVM.SubServiceLine = this.filterSSL.map(x => x.ID);
    this.filterVM.SubManagementUnit = this.filterSMU.map(x => x.ID);
    this.filterVM.SelectedInstanceId = this.selectedInstance;
  }

  getBookingList(saveFilters: boolean) {
    this.prepareFilterVM(saveFilters);
    this.bookingListSubscription =
      this.restService.getCompletedListForGrid(this.filterVM).subscribe(
        data => {
          if (data.CompletedRequestFilterDto) {
            this.hadFiltersEarlierSaved = true;
            this.filterVM = data.CompletedRequestFilterDto;
            this.updateFilter(data.CompletedRequestFilterDto);
          }
          this.bookingList = data.CompletedRequestList;
          this.bookingList.forEach((element) => {
            //Sortinggg stats
            element.startDate_sort=element.StartDate;
            element.endDate_sort=element.EndDate;
            element.RequestedTime_sort=element.RequestedTime;
            element.CompletedTime_sort=element.CompletedTime;
            element.ApprovedOrRejectedOn_sort = element.ApprovedOrRejectedOn;
            //Sorting ends
            element.StartDate = formatDate(element.StartDate, this.defaultDateFormat, 'en-US');
            element.EndDate = formatDate(element.EndDate, this.defaultDateFormat, 'en-US');
            element.RequestedTime = formatDate(element.RequestedTime, `${this.defaultDateFormat} HH:mm`, 'en-US');
            if(element.CompletedTime && element.CompletedTime.length > 0){
              element.CompletedTime = formatDate(element.CompletedTime, `${this.defaultDateFormat} HH:mm`, 'en-US');
            }
            if(element.ApprovedOrRejectedOn && element.ApprovedOrRejectedOn.length > 0){
              element.ApprovedOrRejectedOn = formatDate(element.ApprovedOrRejectedOn, `${this.defaultDateFormat} HH:mm`, 'en-US')
            }
            element.Unconfirmed = element.Unconfirmed == "True" ? "Yes" : "No";
            element.Ghost = element.Ghost?.toString() === 'false' ? "No" : "Yes";
            element.HoursCompletedIn = parseFloat(element.HoursCompletedIn.toFixed(2))
            if (element.HoursCompletedIn > 24) {
              this.rowsToHighlight.push(element.HoursCompletedIn);
            }
          });
          this.gridDataSource = new MatTableDataSource(this.bookingList);
          this.empTbSort.disableClear = true;
          this.gridDataSource.paginator = this.paginator.toArray()[0];
          this.gridDataSource.sort = this.sort.toArray()[0];
          this.gridDataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
              case 'StartDate': return new Date(item.startDate_sort);
              case 'EndDate' : return new Date(item.endDate_sort);
              case 'RequestedTime': return new Date(item.RequestedTime_sort);
              case 'CompletedTime' : return new Date(item.CompletedTime_sort);
              case 'ApprovedOrRejectedOn': return new Date(item.ApprovedOrRejectedOn_sort);
              default: return item[property];
            }
          };
          this.gridDataSource.filterPredicate = this.createGridFilters();
          this.skip = 0;
          this.loading = false;
          this.spinner.hide();
        },
        error => {
          // todo: show custome error message   
          console.log(error);
          this.loading = false;
          this.spinner.hide();
        },
        () => this.spinner.hide()
      );
  }
  triggerFilter() {
    this.gridDataSource.filter = JSON.stringify(this.gridFilterValues);
  }
  createGridFilters(): (data: any, filter: string) => boolean {
    let filterFunction = function (data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      if (searchTerms.globalType) {
        return ((data.ActionId ? data.ActionId?.toString().toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
          (data.ActionType ? data.ActionType?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
          (data.ApprovedOrRejectedBy ? data.ApprovedOrRejectedBy.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
          (data.ApprovedOrRejectedOn ? data.ApprovedOrRejectedOn.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
          (data.BookingId ? data.BookingId?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
          (data.CompletedTime ? data.CompletedTime?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
          (data.CostCenter ? data.CostCenter.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
          (data.Department ? data.Department.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
          (data.EndDate ? data.EndDate?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
          (data.Engagement ? data.Engagement?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
          (data.Ghost !== null ? data.Ghost.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
          (data.Hours ? data.Hours?.toString().toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
          (data.HoursCompletedIn ? data.HoursCompletedIn?.toString().toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
          (data.InstanceName ? data.InstanceName?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
          (data.OfficeLocation ? data.OfficeLocation.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
          (data.PercentLoading ? data.PercentLoading.toString().toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
          (data.Rank ? data.Rank?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
          (data.RejectionReason ? data.RejectionReason.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
          (data.ApproverComment ? data.ApproverComment.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
          (data.RequestModeDisplay ? data.RequestModeDisplay?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
          (data.RequestNumber ? data.RequestNumber?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
          (data.RequestedTime ? data.RequestedTime?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
          (data.RequestorName ? data.RequestorName?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
          (data.ResourceName ? data.ResourceName?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
          (data.ServiceLine ? data.ServiceLine?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
          (data.StartDate ? data.StartDate?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
          (data.Status ? data.Status?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
          (data.SubAreaDescription ? data.SubAreaDescription?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
          (data.SubManagementUnit ? data.SubManagementUnit?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
          (data.SubServiceLine ? data.SubServiceLine?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
          (data.Unconfirmed ? data.Unconfirmed?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
          (data.UpdatedBy ? data.UpdatedBy?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false) ||
          (data.UserSelection ? data.UserSelection?.toLowerCase().indexOf(searchTerms.global.toLowerCase()) !== -1 : false)
        );
      } else {
        return ((searchTerms.requestIdType ? data.RequestNumber?.toLowerCase().indexOf(searchTerms.requestId.toLowerCase()) !== -1 : data.RequestNumber?.toLowerCase().startsWith(searchTerms.requestId.toLowerCase()) !== false)
          && (searchTerms.bookingIdType ? data.BookingId?.toLowerCase().indexOf(searchTerms.bookingId.toLowerCase()) !== -1 : data.BookingId?.toLowerCase().startsWith(searchTerms.bookingId.toLowerCase()) !== false)
          && (searchTerms.statusType ? data.Status?.toLowerCase().indexOf(searchTerms.status.toLowerCase()) !== -1 : data.Status?.toLowerCase().startsWith(searchTerms.status.toLowerCase()) !== false)
          && (searchTerms.actionType ? data.ActionType?.toLowerCase().indexOf(searchTerms.action.toLowerCase()) !== -1 : data.ActionType?.toLowerCase().startsWith(searchTerms.action.toLowerCase()) !== false)
          && (searchTerms.engagementType ? data.Engagement?.toLowerCase().indexOf(searchTerms.engagement.toLowerCase()) !== -1 : data.Engagement?.toLowerCase().startsWith(searchTerms.engagement.toLowerCase()) !== false)
          && (searchTerms.resourceType ? data.ResourceName?.toLowerCase().indexOf(searchTerms.resource.toLowerCase()) !== -1 : data.ResourceName?.toLowerCase().startsWith(searchTerms.resource.toLowerCase()) !== false)
          && (searchTerms.rankType ? data.Rank?.toLowerCase().indexOf(searchTerms.rank.toLowerCase()) !== -1 : data.Rank?.toLowerCase().startsWith(searchTerms.rank.toLowerCase()) !== false)
          && (searchTerms.serviceLineType ? data.ServiceLine?.toLowerCase().indexOf(searchTerms.serviceLine.toLowerCase()) !== -1 : data.ServiceLine?.toLowerCase().startsWith(searchTerms.serviceLine.toLowerCase()) !== false)
          && (searchTerms.subServiceLineType ? data.SubServiceLine?.toLowerCase().indexOf(searchTerms.subServiceLine.toLowerCase()) !== -1 : data.SubServiceLine?.toLowerCase().startsWith(searchTerms.subServiceLine.toLowerCase()) !== false)
          && (searchTerms.subManagementUnitType ? data.SubManagementUnit?.toLowerCase().indexOf(searchTerms.subManagementUnit.toLowerCase()) !== -1 : data.SubManagementUnit?.toLowerCase().startsWith(searchTerms.subManagementUnit.toLowerCase()) !== false)
          && (searchTerms.departmentType ? data.Department?.toLowerCase().indexOf(searchTerms.department.toLowerCase()) !== -1 : data.Department?.toLowerCase().startsWith(searchTerms.department.toLowerCase()) !== false)
          && (searchTerms.costCenterType ? data.CostCenter?.toLowerCase().indexOf(searchTerms.costCenter.toLowerCase()) !== -1 : data.CostCenter?.toLowerCase().startsWith(searchTerms.costCenter.toLowerCase()) !== false)
          && (searchTerms.officeLocationType ? data.OfficeLocation?.toLowerCase().indexOf(searchTerms.officeLocation.toLowerCase()) !== -1 : data.OfficeLocation?.toLowerCase().startsWith(searchTerms.officeLocation.toLowerCase()) !== false)
          && (searchTerms.requestorNameType ? data.RequestorName?.toLowerCase().indexOf(searchTerms.requestorName.toLowerCase()) !== -1 : data.RequestorName?.toLowerCase().startsWith(searchTerms.requestorName.toLowerCase()) !== false)
          && (searchTerms.completedTimeType ? data.CompletedTime?.toLowerCase().indexOf(searchTerms.completedTime.toLowerCase()) !== -1 : data.CompletedTime?.toLowerCase().startsWith(searchTerms.completedTime.toLowerCase()) !== false)
          && (searchTerms.SlaHrsType ? data.HoursCompletedIn?.toString().toLowerCase().indexOf(searchTerms.SlaHrs.toLowerCase()) !== -1 : data.HoursCompletedIn?.toString().toLowerCase().startsWith(searchTerms.SlaHrs.toLowerCase()) !== false)
          && (searchTerms.resourceSubAreaType ? data.SubAreaDescription?.toLowerCase().indexOf(searchTerms.resourceSubArea.toLowerCase()) !== -1 : data.SubAreaDescription?.toLowerCase().startsWith(searchTerms.resourceSubArea.toLowerCase()) !== false)
          && (searchTerms.bookingModeType ? data.RequestModeDisplay?.toLowerCase().indexOf(searchTerms.bookingMode.toLowerCase()) !== -1 : data.RequestModeDisplay?.toLowerCase().startsWith(searchTerms.bookingMode.toLowerCase()) !== false)
          && (searchTerms.updatedByType ? data.UpdatedBy?.toLowerCase().indexOf(searchTerms.updatedBy.toLowerCase()) !== -1 : data.UpdatedBy?.toLowerCase().startsWith(searchTerms.updatedBy.toLowerCase()) !== false));
      }
    }
    return filterFunction;
  }

  clearGridFilters() {
    this.gridDataSource.filter = '';
    this.requestIdFilter.setValue('');
    this.bookingIdFilter.setValue('');
    this.statusFilter.setValue('');
    this.actionFilter.setValue('');
    this.engagementFilter.setValue('');
    this.resourceFilter.setValue('');
    this.rankFilter.setValue('');
    this.serviceLineFilter.setValue('');
    this.subServiceLineFilter.setValue('');
    this.subManagementUnitFilter.setValue('');
    this.departmentFilter.setValue('');
    this.costCenterFilter.setValue('');
    this.officeLocationFilter.setValue('');
    this.requestorNameFilter.setValue('');
    this.completedTimeFilter.setValue('');
    this.SlaHrsFilter.setValue('');
    this.resourceSubAreaFilter.setValue('');
    this.bookingModeFilter.setValue('');
    this.updatedByFilter.setValue('');
    this.keywordSearch.setValue('');
    this.gridFilterValues.requestIdType = true;
    this.gridFilterValues.bookingIdType = true;
    this.gridFilterValues.statusType = true;
    this.gridFilterValues.actionType = true;
    this.gridFilterValues.engagementType = true;
    this.gridFilterValues.resourceType = true;
    this.gridFilterValues.rankType = true;
    this.gridFilterValues.serviceLineType = true;
    this.gridFilterValues.subServiceLineType = true;
    this.gridFilterValues.subManagementUnitType = true;
    this.gridFilterValues.departmentType = true;
    this.gridFilterValues.costCenterType = true;
    this.gridFilterValues.officeLocationType = true;
    this.gridFilterValues.requestorNameType = true;
    this.gridFilterValues.completedTimeType = true;
    this.gridFilterValues.SlaHrsType = true;
    this.gridFilterValues.resourceSubAreaType = true;
    this.gridFilterValues.bookingModeType = true;
    this.gridFilterValues.updatedByType = true;
    this.gridFilterValues.globalType = false;
  }

  openFilterModal(template) {
    this.keywordSearch.setValue('');
    this.gridFilterValues.global = '';
    this.gridFilterValues.globalType = false;
    this.gridDataSource.filter = JSON.stringify(this.gridFilterValues);
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.modalRef = this.modalService.show(template, this.modalOption);
  }

  closeFilterModal() {
    this.clearGridFilters();
    this.modalRef.hide();
  }

  filterGrid() {
    this.gridDataSource.filter = JSON.stringify(this.gridFilterValues);
    this.modalRef.hide();
  }

  public onExcelExport(e: any): void {
    var sheet = e.workbook.sheets[0];
    for (var rowIndex = 1; rowIndex < sheet.rows.length; rowIndex++) {
      var row = sheet.rows[rowIndex];
      row.cells[15].format = "dd-MM-yyyy hh:mm:ss"
      row.cells[16].format = "dd-MM-yyyy hh:mm:ss"
    }
  }
  exportAsXLSX(): void {
    const fileName = 'CompletedRequestQueueList_export_' + new Date().toISOString();
    var dataToExport = [];
    this.gridDataSource.data.forEach(element => {
      var data = {};
      this.excelColumns.forEach(item => {
        if (item.Key == "Ghost" && this.loggedInUser.Ghost) {
          data[item.value] = element[item.Key];
        }
        if (
          (item.Key == "InstanceName" && this.isMultiInstance)
          || (item.Key != "Ghost" && item.Key != "InstanceName")) {
          data[item.value] = element[item.Key];
        }
      })
      dataToExport.push(data);
    });

    let workbookData = [
      {
        workSheet: 'Sheet1',
        rows: dataToExport
      }
    ]

    this.excelService.exportAsExcelFile(workbookData, fileName);

  }


  showSpinner() {
    this.spinner.show();
  }
  hideSpinner() { this.spinner.hide(); }


  setDefaultTime(inputDate) {
    let newDate = inputDate;
    if (newDate.getHours() === 0) {
      newDate.setHours(newDate.getHours() + 9);
      newDate.setMinutes(newDate.getMinutes() + 30);
    }
    return newDate;
  }
  
  onFilter() {
    if(this.formGroup.get('dateRange').get('dtStart').invalid || this.formGroup.get('dateRange').get('dtEnd').invalid){
      return;
    }
    this.showSpinner();
    this.loading = true;
    this.getBookingList(true);
  }

  onStatusChange(val) {
    this.filterVM.BookingStatus = val;
  }

  onInstanceChange(val){
    this.filterVM.SelectedInstanceId = val;
  }

  openModal(templateWallchart) {
    this.modalRef = this.modalService.show(templateWallchart);
  }

  getFormattedDateWithTimeZero (inputDate : Date) : Date
  {
    return new Date(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate(), 0, 0, 0);
  }


}
