<!-- <div
      kendoTooltip
      tooltipClass="my-class"
      [tooltipTemplate]="template"
      [filter]="'td span[condition]'"
      position="bottom"
    > -->
<div class="materialChildTable">
  <table
    mat-table
    [dataSource]="bookingList"
    class="mat-elevation-z8 bookingTable"
  >
    <!-- BookingType Column -->
    <ng-container matColumnDef="BookingType">
      <th mat-header-cell *matHeaderCellDef class="mat-column-BookingType">
        Booking Type
      </th>
      <td
        mat-cell
        *matCellDef="let element"
        data-title="Booking Type :"
        (mouseover)="selectItem(element)"
        (click)="selectItem(element)"
        [popover]="element.TooltipData.ActionType ? tooltipTemplate : null"
        [placement]="'right'"
        [outsideClick]="true"
        [triggers]="'mouseover click'"
        #pop="bs-popover"
        (onShown)="closePrevious(pop)"
        (mouseleave)="closeCurrent(pop)"
      >
        {{ element.BookingType }}
      </td>
    </ng-container>

    <!-- Engagement Column -->
    <ng-container matColumnDef="Engagement">
      <th mat-header-cell *matHeaderCellDef class="mat-column-Engagement">
        Engagement
      </th>
      <td
        mat-cell
        *matCellDef="let element"
        data-title="Engagement :"
        (mouseover)="selectItem(element)"
        (click)="selectItem(element)"
        [popover]="element.TooltipData.ActionType ? tooltipTemplate : null"
        [placement]="'right'"
        [outsideClick]="true"
        [triggers]="'mouseover click'"
        #pop="bs-popover"
        (onShown)="closePrevious(pop)"
        (mouseleave)="closeCurrent(pop)"
      >
        <div
          class="navigationlink"
          [ngClass]="{
            disableNavigation:
              bookingData.IsDisabledEVNavigation === true ||
              bookingData.IsDisabledViewBooking_Job === true
          }"
          (click)="navigateEngagement(element)"
          [matTooltip]="
            bookingData.IsDisabledEVNavigation === false &&
            bookingData.IsDisabledViewBooking_Job === false
              ? 'Click here to see engagement details'
              : null
          "
        >
          {{ element.Engagement }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="Instance">
      <th mat-header-cell *matHeaderCellDef class="mat-column-Instance">
        Retain Instance
      </th>
      <td
        mat-cell
        *matCellDef="let element"
        data-title="Retain Instance :"
        (mouseover)="selectItem(element)"
        (click)="selectItem(element)"
        [popover]="element.TooltipData.ActionType ? tooltipTemplate : null"
        [placement]="'right'"
        [outsideClick]="true"
        [triggers]="'mouseover click'"
        #pop="bs-popover"
        (onShown)="closePrevious(pop)"
        (mouseleave)="closeCurrent(pop)"
      >
        {{ element.InstanceName }}
      </td>
    </ng-container>

    <!-- Hours Column -->
    <ng-container matColumnDef="Hours">
      <th mat-header-cell *matHeaderCellDef class="mat-column-Hours">Hours</th>
      <td
        mat-cell
        *matCellDef="let element"
        data-title="Hours :"
        (mouseover)="selectItem(element)"
        (click)="selectItem(element)"
        [popover]="element.TooltipData.ActionType ? tooltipTemplate : null"
        [placement]="'right'"
        [outsideClick]="true"
        [triggers]="'mouseover click'"
        #pop="bs-popover"
        (onShown)="closePrevious(pop)"
        (mouseleave)="closeCurrent(pop)"
      >
        {{ element.Hours }}
      </td>
    </ng-container>

    <!-- Loading Column -->
    <ng-container matColumnDef="Loading">
      <th mat-header-cell *matHeaderCellDef class="mat-column-Loading">
        Loading %
      </th>
      <td
        mat-cell
        *matCellDef="let element"
        data-title="Loading %:"
        (mouseover)="selectItem(element)"
        (click)="selectItem(element)"
        [popover]="element.TooltipData.ActionType ? tooltipTemplate : null"
        [placement]="'right'"
        [outsideClick]="true"
        [triggers]="'mouseover click'"
        #pop="bs-popover"
        (onShown)="closePrevious(pop)"
        (mouseleave)="closeCurrent(pop)"
      >
        {{ element.Loading }} %
      </td>
    </ng-container>

    <!-- StartDate Column -->
    <ng-container matColumnDef="StartDate">
      <th mat-header-cell *matHeaderCellDef class="mat-column-StartDate">
        Start Date
      </th>
      <td
        mat-cell
        *matCellDef="let element"
        data-title="Start Date :"
        (mouseover)="selectItem(element)"
        (click)="selectItem(element)"
        [popover]="element.TooltipData.ActionType ? tooltipTemplate : null"
        [placement]="'right'"
        [outsideClick]="true"
        [triggers]="'mouseover click'"
        #pop="bs-popover"
        (onShown)="closePrevious(pop)"
        (mouseleave)="closeCurrent(pop)"
      >
        {{ element.StartDate | date: defaultDateFormat }}
      </td>
    </ng-container>

    <!-- EndDate Column -->
    <ng-container matColumnDef="EndDate">
      <th mat-header-cell *matHeaderCellDef class="mat-column-EndDate">
        End Date
      </th>
      <td
        mat-cell
        *matCellDef="let element"
        data-title="End Date :"
        (mouseover)="selectItem(element)"
        (click)="selectItem(element)"
        [popover]="element.TooltipData.ActionType ? tooltipTemplate : null"
        [placement]="'right'"
        [outsideClick]="true"
        [triggers]="'mouseover click'"
        #pop="bs-popover"
        (onShown)="closePrevious(pop)"
        (mouseleave)="closeCurrent(pop)"
      >
        {{ element.EndDate | date: defaultDateFormat }}
      </td>
    </ng-container>

    <!-- <ng-container matColumnDef="Ghost" *ngIf="user.Ghost">
          <th mat-header-cell *matHeaderCellDef class="mat-column-Ghost">
            Ghost
          </th>
          <td mat-cell *matCellDef="let element" data-title="Ghost :" (mouseover)="selectItem(element)"
          (click)="selectItem(element)"
          [popover]="element.TooltipData.ActionType ? tooltipTemplate : null " 
          [placement]="'right'"
          [outsideClick]="true"
          [triggers]="'mouseover click'"                 
          #pop="bs-popover"
          (onShown)="closePrevious(pop)"   
          (mouseleave)="closeCurrent(pop)">
            {{ element.Ghost ? "Yes" : "No" }}
          </td>
        </ng-container> -->

    <!-- Actions Column -->
    <ng-container matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef class="mat-column-Actions">
        Actions
      </th>
      <td mat-cell *matCellDef="let element" class="actionColumn">
        <!-- <mat-icon
          class="tableIcon"
          *ngIf="!element.IsDisabledEditBooking"
          (click)="openActionModal(GridChildActions, $event, element, 'edit')"
          matTooltip="Edit"
          >edit</mat-icon
        > -->
        <span
          class="tableActionIcon edit"
          *ngIf="!element.IsDisabledEditBooking"
          (click)="openActionModal(GridChildActions, $event, element, 'edit')"
          matTooltip="Edit"
        >
        </span>
        <!-- <mat-icon
          class="tableIcon"
          *ngIf="!element.IsDisabledCopyBooking"
          (click)="openActionModal(GridChildActions, $event, element, 'split')"
          matTooltip="Copy"
          >content_copy</mat-icon
        > -->
        <span
          *ngIf="!element.IsDisabledCopyBooking"
          class="tableActionIcon copy"
          matTooltip="Copy"
          (click)="openActionModal(GridChildActions, $event, element, 'split')"
        >
        </span>
        <!-- <mat-icon class="tableIcon" *ngIf="!element.IsDisabledDeleteBooking"
          (click)="openActionModal(GridChildActions, $event, element, 'delete')" matTooltip="Delete">delete</mat-icon> -->
        <span
          *ngIf="!element.IsDisabledDeleteBooking"
          (click)="openActionModal(GridChildActions, $event, element, 'delete')"
          matTooltip="Delete"
          class="tableActionIcon delete"
        >
        </span>

        <mat-icon
          class="tableIcon"
          style="color: #b9251c"
          *ngIf="
            element.StatusId != 2 &&
            element.MeraBookingId > 0 &&
            user?.Email?.toLowerCase() === element.RequestorEmail?.toLowerCase()
          "
          (click)="
            openActionModal(GridChildActions, $event, element, 'cancelAction')
          "
          matTooltip="Cancel"
          >close</mat-icon
        >

        <span
          *ngIf="
            element.IsCurrentEditWeek == false &&
            element.JobCode != null &&
            element.JobCode.indexOf('TR') > -1 &&
            element.IsDisabledEditBooking &&
            element.IsDisabledDeleteBooking &&
            element.IsDisabledCopyBooking &&
            element.IsDisabledReplaceBooking
          "
          class="booking_heading_info_icon error"
          matTooltip=" Please refer Success factors for update to course bookings"
        >
          <mat-icon style="color: #b9251c; font-size: 16px">error</mat-icon>
        </span>
        <span
          *ngIf="
            element.BookingId > 0 &&
            element.IsCurrentEditWeek == false &&
            (element.JobCode == null || element.JobCode.indexOf('TR') <= -1) &&
            element.IsDisabledEditBooking &&
            element.IsDisabledDeleteBooking &&
            element.MeraBookingId <= 0
          "
          class="booking_heading_info_icon error"
          matTooltip="Submissions are not supported on this booking.&#013;For any other updates, please follow the country specific process."
        >
          <mat-icon style="color: #b9251c; font-size: 16px">error</mat-icon>
        </span>
        <span
          *ngIf="
            element.IsCurrentEditWeek == true &&
            element.IsDisabledEditBooking &&
            element.IsDisabledDeleteBooking &&
            element.IsDisabledReplaceBooking &&
            element.IsDisabledCopyBooking &&
            !element.IsReplaced &&
            element.BookingId > 0
          "
          class="booking_heading_info_icon error"
          matTooltip="Please contact your Resource or Experience Management team &#013;to make any changes in the current week"
        >
          <mat-icon style="color: #b9251c; font-size: 16px">error</mat-icon>
        </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns; let entry"
      [ngClass]="{ highlight: entry.HighlightStatus }"
    ></tr>
  </table>
  <div *ngIf="bookingList?.length === 0" class="bookingsLoader">
    <mat-spinner class="spinner-inner-table" [diameter]="20"></mat-spinner>
  </div>
</div>

<ng-template #GridChildActions>
  <ngx-spinner></ngx-spinner>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ gridActionModalHeader }}</h4>
    <mat-icon
      class="close"
      (click)="openConfirmationModal(EditChildConfirmation)"
      >close</mat-icon
    >
  </div>
  <div class="modal-body">
    <!-- Use the parent-edit component for single edit also -->
    <!-- <app-edit [calledFromChild]="true" *ngIf="showModal" (isFormDirtyFlag)="makeDirty($event)"> </app-edit> -->
    <app-parent-edit
      *ngIf="showModal"
      [isPercentageSelected]="isPercentageSelected"
      [gridActionEventData]="gridActionEventData"
      [parentViewIVorEV]="'IV'"
      (isFormDirtyFlag)="makeDirty($event)"
      [singleEdit]="true"
      [currentWeekActionDTO]="this.currentWeekActionDTO"
    >
    </app-parent-edit>
    <app-add *ngIf="showModalAdd" (isFormDirtyFlag)="makeDirty($event)">
    </app-add>
    <app-split
      [calledFromChild]="true"
      *ngIf="showModalSplit"
      [currentWeekActionDTO]="this.currentWeekActionDTO"
      (isFormDirtyFlag)="makeDirty($event)"
    >
    </app-split>
    <app-delete
      *ngIf="showModalDelete"
      [actionEventData]="gridActionEventData"
      [currentWeekActionDTO]="this.currentWeekActionDTO"
      [isPercentSelected]="isPercentageSelected"
      [uiViewSource]="'IV'"
      [calledFromChild]="true"
    ></app-delete>
    <app-cancel-request
      *ngIf="showModalCancel"
      [actionEventData]="gridActionEventData"
      [calledFromChild]="true"
      [uiViewSource]="'IV'"
    ></app-cancel-request>
  </div>
</ng-template>

<ng-template #EditChildConfirmation>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Confirm</h4>
  </div>
  <div class="modal-body">
    <div class="popupLayout">
      <div class="fullWidth">
        <div class="confirmationText">
          All the changes will be lost. Are you sure you want to close?<span
          ></span>
        </div>
      </div>

      <div class="confirmationButtons">
        <button class="cancelBtn" (click)="this.confirmModalRef.hide()">
          Cancel
        </button>
        <button class="deleteBtn" (click)="okayConfirm(EditConfirmation)">
          Yes
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #template let-anchor>
  <div>{{ anchor.nativeElement.getAttribute("resourceName") }}</div>
  <div>{{ anchor.nativeElement.getAttribute("engagementname") }}</div>
  <div>{{ anchor.nativeElement.getAttribute("clientName") }}</div>
  <div>{{ anchor.nativeElement.getAttribute("startDate") }}</div>
  <div>{{ anchor.nativeElement.getAttribute("endDate") }}</div>
  <div>{{ anchor.nativeElement.getAttribute("hours") }}</div>
  <div>{{ anchor.nativeElement.getAttribute("loading") }}</div>
  <div>{{ anchor.nativeElement.getAttribute("unconfirmed") }}</div>
  <div>{{ anchor.nativeElement.getAttribute("description") }}</div>
  <div>{{ anchor.nativeElement.getAttribute("actionType") }}</div>
</ng-template>
<ng-template #tooltipTemplate>
  <div class="tableTooltip" *ngIf="selectedItem.ActionType?.length > 0">
    <!-- <label>{{selectedItem.ActionDescription}}</label>
    <label>{{selectedItem.ClientName}}</label>
    <label>{{selectedItem.Description}}</label>
    <label>{{selectedItem.StartDate}}</label>
    <label>{{selectedItem.EndDate}}</label>
    <label>{{selectedItem.EngagementName}}</label>
    <label>{{selectedItem.FromEngagementName}}</label>
    <label>{{selectedItem.FromResourceName}}</label>
    <label>{{selectedItem.Hours}}</label>
    <label>{{selectedItem.Loading}}</label>
    <label>{{selectedItem.ResourceId}}</label>
    <label>{{selectedItem.ResourceName}}</label>
    <label>{{selectedItem.ToEngagementName}}</label>
    <label>{{selectedItem.ToResourceName}}</label>
    <label>{{selectedItem.Unconfirmed}}</label>
    <label>{{selectedItem.ActionType}}</label> -->

    <label>{{
      selectedItem.ActionDescription != null
        ? "Action Description: " + selectedItem.ActionDescription
        : null
    }}</label>
    <label>{{
      selectedItem.ClientName != null
        ? "Client Name: " + selectedItem.ClientName
        : null
    }}</label>
    <label>{{
      selectedItem.Description != null
        ? "Description: " + selectedItem.Description
        : null
    }}</label>
    <label
      >{{ selectedItem.StartDate != null ? "Start Date: " : null
      }}{{ selectedItem.StartDate | date: defaultDateFormat }}</label
    >
    <label
      >{{ selectedItem.EndDate != null ? "End Date: " : null
      }}{{ selectedItem.EndDate | date: defaultDateFormat }}</label
    >
    <label>{{
      selectedItem.EngagementName != null
        ? "Engagement Name: " + selectedItem.EngagementName
        : null
    }}</label>
    <label>{{
      selectedItem.FromEngagementName != null
        ? "From Engagement Name: " + selectedItem.FromEngagementName
        : null
    }}</label>
    <label>{{
      selectedItem.FromResourceName != null
        ? "From Resource Name: " + selectedItem.FromResourceName
        : null
    }}</label>
    <label>{{
      selectedItem.Hours != null ? "Hours: " + selectedItem.Hours : null
    }}</label>
    <label>{{
      selectedItem.Loading != null ? "Loading: " + selectedItem.Loading : null
    }}</label>
    <label>{{
      selectedItem.ResourceId != null
        ? "Resource Id: " + selectedItem.ResourceId
        : null
    }}</label>
    <label>{{
      selectedItem.ResourceName != null
        ? "Resource Name: " + selectedItem.ResourceName
        : null
    }}</label>
    <label>{{
      selectedItem.ToEngagementName != null
        ? "To Engagement Name: " + selectedItem.ToEngagementName
        : null
    }}</label>
    <label>{{
      selectedItem.ToResourceName != null
        ? "To Resource Name: " + selectedItem.ToResourceName
        : null
    }}</label>
    <label>{{
      selectedItem.Unconfirmed != null
        ? "Unconfirmed: " + selectedItem.Unconfirmed
        : null
    }}</label>
    <label>{{
      selectedItem.ActionType != null
        ? "Action: " + selectedItem.ActionType
        : null
    }}</label>
    <label>{{
      selectedItem.StatusId == 10
        ? "Status: " + selectedItem.StatusDescription
        : null
    }}</label>
  </div>
</ng-template>
