export class FavouriteJobsDto
{
    Id: number| null;
    LoggedInUserId: number| null;
    JobId: number| null;
    InstanceId: number| null;
    UpdatedOn: Date | null;
    JobCategory: string | null;
    JobCode: string | null;
    JobType: string | null;
    EngagementFullName: string | null;
    IsSearchfromIndivBooking: boolean | null;
    IsFavEngInserted: boolean | null;
    IsOldFavEngInserted: boolean | null;
    ResourceID: number|null;
    
}


export class FavouriteJobsTableTypeDto
{
    IsFavEngInserted: boolean | null;
    LoggedInUserId: number| null;
    JobID: number| null;
    Instance_Id: number| null;
}

export class FavouriteJobsAddActionDto
{
        FavouriteJobs:FavouriteJobsTableTypeDto[];
}