
export class MyRequest_RequestForMeVM {
    RequestForEmail: string;
    RequestId: string ;
    ActionId: number;
    Action: string;
    Engagement: string;
    StartDate: string;
    EndDate: string;
    Hours:number | null;
    Unconfirmed: string;
    Status: string;
    RejectionReason: string;
    RequestedBy : string;
    RequestedDateTime : string;
    HighlightRow : boolean;
    Loading: Number;
    UserSelection: string;
    IsPercentSelected: boolean;
    Ghost: string;
    ApprovedOrRejectedBy:string;
    ApprovedOrRejectedOn : string;
    startDate_sort:string;
    endDate_sort:string;
    RequestedDateTime_sort:string;
    ApprovedOrRejectedOn_sort :string;
    ApproverComment: string;
}

export class FilterParamsForMe {
    RequestedDateFrom: Date | null;
    RequestedDateTo: Date | null;
  }