<div class="appLayout workRequest">

  <div class="requestedlog">
    <div class="requestHead heading">Request Log</div>
    <div class="clearFilterContainer">
      <div class="filterMobile">
        <mat-form-field appearance="outline">
          <input matInput type="text" placeholder="Search..." [formControl]="keywordSearch" autocomplete="off"
            maxlength="150">
        </mat-form-field>
      </div>
      &nbsp; &nbsp;
      <mat-icon class="filterIcon" (click)="openFilterModal(GridFilter)" matTooltip="Apply filters">
        filter_alt
      </mat-icon>
      &nbsp; &nbsp;
      <mat-icon (click)="clearByMeFilter()" class="clearFilter" matTooltip="Clear filters"> clear_all</mat-icon>
      &nbsp; &nbsp;
      <div (click)="exportAsXLSX()" matTooltip="Export to excel" class="exportExcel"
        [ngClass]="bookingList?.length === 0 || workRequestdataSource?.filteredData.length === 0? 'disablClass' : 'enableClass'">
        <img class="exportExcelIcon" src="../../../../assets/images/excel-export-black.jpg" />
      </div>
      <!-- <mat-icon class="clearFilter" (click)="exportAsXLSX()" matTooltip="Export to excel"
      [ngClass]="bookingList?.length === 0 || workRequestdataSource?.filteredData.length === 0? 'disablClass' : 'enableClass'">backup</mat-icon> -->
    </div>

    <div class="requestTable">
      <table mat-table [dataSource]="workRequestdataSource" class="mat-elevation-z8 queuesTable" matSort
        id="excel-table" #empTbSort="matSort">

        <ng-container matColumnDef="RequestId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Request ID
          </th>
          <td mat-cell *matCellDef="let row"> {{row.RequestId}} </td>
        </ng-container>

        <ng-container matColumnDef="RequestorName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Requestor Name
          </th>
          <td mat-cell *matCellDef="let row"> {{row.RequestorName}} </td>
        </ng-container>

        <ng-container matColumnDef="BookingId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Booking ID
          </th>
          <td mat-cell *matCellDef="let row"> {{row.BookingId}} </td>
        </ng-container>

        <ng-container matColumnDef="ActionDescriptionId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Action ID
          </th>
          <td mat-cell *matCellDef="let row"> {{row.ActionDescriptionId}} </td>
        </ng-container>

        <ng-container matColumnDef="ActionDescription">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Action Description
          </th>
          <td mat-cell *matCellDef="let row"> {{row.ActionDescription}} </td>
        </ng-container>

        <ng-container matColumnDef="Instance" *ngIf="loggedInUser.IsMultiInstance">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Retain Instance
          </th>
          <td mat-cell *matCellDef="let row"> {{row.InstanceName}} </td>
        </ng-container>

        <ng-container matColumnDef="Engagement">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Engagement
          </th>
          <td mat-cell *matCellDef="let row"> {{row.Engagement}} </td>
        </ng-container>

        <ng-container matColumnDef="ResourceName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Resource Name
          </th>
          <td mat-cell *matCellDef="let row"> {{row.ResourceName}} </td>
        </ng-container>

        <ng-container matColumnDef="RankDescription">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Rank
          </th>
          <td mat-cell *matCellDef="let row"> {{row.RankDescription}} </td>
        </ng-container>

        <ng-container matColumnDef="ServiceLine">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Service Line
          </th>
          <td mat-cell *matCellDef="let row"> {{row.ServiceLine}} </td>
        </ng-container>

        <ng-container matColumnDef="SubServiceLineDescription">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Sub Service Line
          </th>
          <td mat-cell *matCellDef="let row"> {{row.SubServiceLineDescription}} </td>
        </ng-container>

        <ng-container matColumnDef="SubManagementUnitDescription">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Sub Management Unit
          </th>
          <td mat-cell *matCellDef="let row"> {{row.SubManagementUnitDescription}} </td>
        </ng-container>

        <ng-container matColumnDef="Department">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            HR Department
          </th>
          <td mat-cell *matCellDef="let row"> {{row.Department}} </td>
        </ng-container>

        <ng-container matColumnDef="CostCenter">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Cost Center
          </th>
          <td mat-cell *matCellDef="let row"> {{row.CostCenter}} </td>
        </ng-container>

        <ng-container matColumnDef="LocationDescription">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Office Location
          </th>
          <td mat-cell *matCellDef="let row"> {{row.LocationDescription}} </td>
        </ng-container>

        <ng-container matColumnDef="SubArea">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Sub Area
          </th>
          <td mat-cell *matCellDef="let row"> {{row.SubArea}} </td>
        </ng-container>

        <ng-container matColumnDef="RequestMode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Request Mode
          </th>
          <td mat-cell *matCellDef="let row"> {{row.RequestMode}} </td>
        </ng-container>

        <ng-container matColumnDef="Hours">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <div class="colhead">Hours</div>
          </th>
          <td mat-cell *matCellDef="let row"> {{row.Hours}} </td>
        </ng-container>

        <ng-container matColumnDef="Loading">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Loading
          </th>
          <td mat-cell *matCellDef="let row"> {{row.Loading}} </td>
        </ng-container>

        <ng-container matColumnDef="UserSelection">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            User Selection
          </th>
          <td mat-cell *matCellDef="let row"> {{row.UserSelection}} </td>
        </ng-container>

        <ng-container matColumnDef="StartDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Start Date
          </th>
          <td mat-cell *matCellDef="let row"> {{row.StartDate}} </td>
        </ng-container>

        <ng-container matColumnDef="EndDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            End Date
          </th>
          <td mat-cell *matCellDef="let row"> {{row.EndDate}} </td>
        </ng-container>

        <ng-container matColumnDef="SubmittedOn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Requested Date/Time(GMT)
          </th>
          <td mat-cell *matCellDef="let row"> {{row.SubmittedOn}} </td>
        </ng-container>

        <ng-container matColumnDef="Unconfirmed">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Unconfirmed
          </th>
          <td mat-cell *matCellDef="let row"> {{row.Unconfirmed}} </td>
        </ng-container>

        <ng-container *ngIf="loggedInUser.Ghost" matColumnDef="Ghost">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <div class="colhead">Ghost</div>
          </th>
          <td mat-cell *matCellDef="let row"> {{ row.Ghost}} </td>
        </ng-container>

        <ng-container matColumnDef="StatusDescription">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Status
          </th>
          <td mat-cell *matCellDef="let row"> {{row.StatusDescription}} </td>
        </ng-container>

        <ng-container matColumnDef="UpdatedBy">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <div class="colhead">Updated By</div>

          </th>
          <td mat-cell *matCellDef="let row"> {{row.UpdatedBy}} </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="workRequestColumns; sticky: true"></tr>
        <!-- <tr mat-header-row *matHeaderRowDef="filterWorkRequestColumns"></tr> -->
        <tr mat-row *matRowDef="let row; columns: workRequestColumns;"></tr>

      </table>
      <div class="noResults">
        <div *ngIf="workRequestdataSource === undefined">
          <mat-spinner [diameter]='40'></mat-spinner>
        </div>
        <div class="no-booking" *ngIf="bookingList?.length === 0 || workRequestdataSource?.filteredData.length === 0">Bookings not found
        </div>
      </div>


    </div>
    <mat-paginator [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons></mat-paginator>
  </div>



</div>

<ng-template #GridFilter>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Filter</h4>
    <mat-icon class="close" (click)="closeFilterModal()">close</mat-icon>
  </div>
  <div class="modal-body">
    <div class="popupLayout">
      <div class="fullWidth">
        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input [formControl]="requestIdFilter" autocomplete="off" matInput type="text" placeholder="Request ID"
              maxlength="150">
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="RequestId">
            <mat-icon>filter_alt</mat-icon>
          </button>
          <mat-menu #RequestId="matMenu">
            <button mat-menu-item (click)="filterValues.requestIdType = true">
              <div [ngStyle]="{'color' : filterValues.requestIdType===true ? '#ffe600' : '#fff' }">Contains</div>
            </button>
            <button mat-menu-item (click)="filterValues.requestIdType = false">
              <div [ngStyle]="{'color' : filterValues.requestIdType===false ? '#ffe600' : '#fff' }">Starts with</div>
            </button>
          </mat-menu>
        </div>

        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input [formControl]="requestorNameFilter" autocomplete="off" matInput type="text"
              placeholder="Requestor Name" maxlength="150">
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="RequestorName">
            <mat-icon>filter_alt</mat-icon>
          </button>
          <mat-menu #RequestorName="matMenu">
            <button mat-menu-item (click)="filterValues.requestorNameType = true">
              <div [ngStyle]="{'color' : filterValues.requestorNameType===true ? '#ffe600' : '#fff' }">Contains</div>
            </button>
            <button mat-menu-item (click)="filterValues.requestorNameType = false">
              <div [ngStyle]="{'color' : filterValues.requestorNameType===false ? '#ffe600' : '#fff' }">Starts with
              </div>
            </button>
          </mat-menu>
        </div>

        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input [formControl]="bookingIdFilter" autocomplete="off" matInput type="text" placeholder="Booking ID"
              maxlength="150">
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="BookingId">
            <mat-icon>filter_alt</mat-icon>
          </button>
          <mat-menu #BookingId="matMenu">
            <button mat-menu-item (click)="filterValues.bookingIdType = true">
              <div [ngStyle]="{'color' : filterValues.bookingIdType===true ? '#ffe600' : '#fff' }">Contains</div>
            </button>
            <button mat-menu-item (click)="filterValues.bookingIdType = false">
              <div [ngStyle]="{'color' : filterValues.bookingIdType===false ? '#ffe600' : '#fff' }">Starts with</div>
            </button>
          </mat-menu>
        </div>

        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input [formControl]="actionDescriptionIdFilter" autocomplete="off" matInput type="text"
              placeholder="Action ID" maxlength="150">
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="ActionDescriptionId">
            <mat-icon>filter_alt</mat-icon>
          </button>
          <mat-menu #ActionDescriptionId="matMenu">
            <button mat-menu-item (click)="filterValues.actionDescriptionIdType = true">
              <div [ngStyle]="{'color' : filterValues.actionDescriptionIdType===true ? '#ffe600' : '#fff' }">Contains
              </div>
            </button>
            <button mat-menu-item (click)="filterValues.actionDescriptionIdType = false">
              <div [ngStyle]="{'color' : filterValues.actionDescriptionIdType===false ? '#ffe600' : '#fff' }">Starts
                with</div>
            </button>
          </mat-menu>
        </div>

        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input [formControl]="actionDescriptionFilter" autocomplete="off" matInput type="text" placeholder="Action"
              maxlength="150">
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="ActionDescription">
            <mat-icon>filter_alt</mat-icon>
          </button>
          <mat-menu #ActionDescription="matMenu">
            <button mat-menu-item (click)="filterValues.actionDescriptionType = true">
              <div [ngStyle]="{'color' : filterValues.actionDescriptionType===true ? '#ffe600' : '#fff' }">Contains
              </div>
            </button>
            <button mat-menu-item (click)="filterValues.actionDescriptionType = false">
              <div [ngStyle]="{'color' : filterValues.actionDescriptionType===false ? '#ffe600' : '#fff' }">Starts with
              </div>
            </button>
          </mat-menu>
        </div>

        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input [formControl]="engagementFilter" autocomplete="off" matInput type="text" placeholder="Engagement"
              maxlength="150">
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="Engagement">
            <mat-icon>filter_alt</mat-icon>
          </button>
          <mat-menu #Engagement="matMenu">
            <button mat-menu-item (click)="filterValues.engagementType = true">
              <div [ngStyle]="{'color' : filterValues.engagementType===true ? '#ffe600' : '#fff' }">Contains</div>
            </button>
            <button mat-menu-item (click)="filterValues.engagementType = false">
              <div [ngStyle]="{'color' : filterValues.engagementType===false ? '#ffe600' : '#fff' }">Starts with</div>
            </button>
          </mat-menu>
        </div>

        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input [formControl]="resourceNameFilter" autocomplete="off" matInput type="text"
              placeholder="Resource Name" maxlength="150">
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="ResourceName">
            <mat-icon>filter_alt</mat-icon>
          </button>
          <mat-menu #ResourceName="matMenu">
            <button mat-menu-item (click)="filterValues.resourceNameType = true">
              <div [ngStyle]="{'color' : filterValues.resourceNameType===true ? '#ffe600' : '#fff' }">Contains</div>
            </button>
            <button mat-menu-item (click)="filterValues.resourceNameType = false">
              <div [ngStyle]="{'color' : filterValues.resourceNameType===false ? '#ffe600' : '#fff' }">Starts with</div>
            </button>
          </mat-menu>
        </div>

        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input [formControl]="rankDescriptionFilter" autocomplete="off" matInput type="text" placeholder="Rank"
              maxlength="150">
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="RankDescription">
            <mat-icon>filter_alt</mat-icon>
          </button>
          <mat-menu #RankDescription="matMenu">
            <button mat-menu-item (click)="filterValues.rankDescriptionType = true">
              <div [ngStyle]="{'color' : filterValues.rankDescriptionType===true ? '#ffe600' : '#fff' }">Contains</div>
            </button>
            <button mat-menu-item (click)="filterValues.rankDescriptionType = false">
              <div [ngStyle]="{'color' : filterValues.rankDescriptionType===false ? '#ffe600' : '#fff' }">Starts with
              </div>
            </button>
          </mat-menu>
        </div>

        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input [formControl]="serviceLineFilter" autocomplete="off" matInput type="text" placeholder="Service Line"
              maxlength="150">
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="ServiceLine">
            <mat-icon>filter_alt</mat-icon>
          </button>
          <mat-menu #ServiceLine="matMenu">
            <button mat-menu-item (click)="filterValues.serviceLineType = true">
              <div [ngStyle]="{'color' : filterValues.serviceLineType===true ? '#ffe600' : '#fff' }">Contains</div>
            </button>
            <button mat-menu-item (click)="filterValues.serviceLineType = false">
              <div [ngStyle]="{'color' : filterValues.serviceLineType===false ? '#ffe600' : '#fff' }">Starts with</div>
            </button>
          </mat-menu>
        </div>

        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input [formControl]="subServiceLineDescriptionFilter" autocomplete="off" matInput type="text"
              placeholder="Sub Service Line" maxlength="150">
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="SubServiceLineDescription">
            <mat-icon>filter_alt</mat-icon>
          </button>
          <mat-menu #SubServiceLineDescription="matMenu">
            <button mat-menu-item (click)="filterValues.subServiceLineDescriptionType = true">
              <div [ngStyle]="{'color' : filterValues.subServiceLineDescriptionType===true ? '#ffe600' : '#fff' }">
                Contains</div>
            </button>
            <button mat-menu-item (click)="filterValues.subServiceLineDescriptionType = false">
              <div [ngStyle]="{'color' : filterValues.subServiceLineDescriptionType ===false ? '#ffe600' : '#fff' }">
                Starts with</div>
            </button>
          </mat-menu>
        </div>

        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input [formControl]="subManagementUnitDescriptionFilter" matInput type="text"
              placeholder="Sub Management Unit" maxlength="150">
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="SubManagementUnitDescription">
            <mat-icon>filter_alt</mat-icon>
          </button>
          <mat-menu #SubManagementUnitDescription="matMenu">
            <button mat-menu-item (click)="filterValues.subManagementUnitDescriptionType = true">
              <div [ngStyle]="{'color' : filterValues.subManagementUnitDescriptionType===true ? '#ffe600' : '#fff' }">
                Contains</div>
            </button>
            <button mat-menu-item (click)="filterValues.subManagementUnitDescriptionType = false">
              <div [ngStyle]="{'color' : filterValues.subManagementUnitDescriptionType ===false ? '#ffe600' : '#fff' }">
                Starts with</div>
            </button>
          </mat-menu>
        </div>

        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input [formControl]="departmentFilter" autocomplete="off" matInput type="text" placeholder="Department"
              maxlength="150">
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="Department">
            <mat-icon>filter_alt</mat-icon>
          </button>
          <mat-menu #Department="matMenu">
            <button mat-menu-item (click)="filterValues.departmentType = true">
              <div [ngStyle]="{'color' : filterValues.departmentType===true ? '#ffe600' : '#fff' }">Contains</div>
            </button>
            <button mat-menu-item (click)="filterValues.departmentType = false">
              <div [ngStyle]="{'color' : filterValues.departmentType===false ? '#ffe600' : '#fff' }">Starts with</div>
            </button>
          </mat-menu>
        </div>

        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input [formControl]="costCenterFilter" autocomplete="off" matInput type="text" placeholder="Cost Center"
              maxlength="150">
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="CostCenter">
            <mat-icon>filter_alt</mat-icon>
          </button>
          <mat-menu #CostCenter="matMenu">
            <button mat-menu-item (click)="filterValues.costCenterType = true">
              <div [ngStyle]="{'color' : filterValues.costCenterType===true ? '#ffe600' : '#fff' }">Contains</div>
            </button>
            <button mat-menu-item (click)="filterValues.costCenterType = false">
              <div [ngStyle]="{'color' : filterValues.costCenterType===false ? '#ffe600' : '#fff' }">Starts with</div>
            </button>
          </mat-menu>
        </div>

        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input [formControl]="locationDescriptionFilter" autocomplete="off" matInput type="text"
              placeholder="Office Location" maxlength="150">
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="LocationDescription">
            <mat-icon>filter_alt</mat-icon>
          </button>
          <mat-menu #LocationDescription="matMenu">
            <button mat-menu-item (click)="filterValues.locationDescriptionType = true">
              <div [ngStyle]="{'color' : filterValues.locationDescriptionType===true ? '#ffe600' : '#fff' }">Contains
              </div>
            </button>
            <button mat-menu-item (click)="filterValues.locationDescriptionType = false">
              <div [ngStyle]="{'color' : filterValues.locationDescriptionType===false ? '#ffe600' : '#fff' }">Starts
                with</div>
            </button>
          </mat-menu>
        </div>

        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input [formControl]="subAreaFilter" autocomplete="off" matInput type="text" placeholder="Sub Area"
              maxlength="150">
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="SubArea">
            <mat-icon>filter_alt</mat-icon>
          </button>
          <mat-menu #SubArea="matMenu">
            <button mat-menu-item (click)="filterValues.subAreaType = true">
              <div [ngStyle]="{'color' : filterValues.subAreaType===true ? '#ffe600' : '#fff' }">Contains</div>
            </button>
            <button mat-menu-item (click)="filterValues.subAreaType = false">
              <div [ngStyle]="{'color' : filterValues.subAreaType===false ? '#ffe600' : '#fff' }">Starts with</div>
            </button>
          </mat-menu>
        </div>

        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input [formControl]="requestModeFilter" autocomplete="off" matInput type="text" placeholder="Request Mode"
              maxlength="150">
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="RequestMode">
            <mat-icon>filter_alt</mat-icon>
          </button>
          <mat-menu #RequestMode="matMenu">
            <button mat-menu-item (click)="filterValues.requestModeType = true">
              <div [ngStyle]="{'color' : filterValues.requestModeType===true ? '#ffe600' : '#fff' }">Contains</div>
            </button>
            <button mat-menu-item (click)="filterValues.requestModeType = false">
              <div [ngStyle]="{'color' : filterValues.requestModeType===false ? '#ffe600' : '#fff' }">Starts with</div>
            </button>
          </mat-menu>
        </div>

        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input [formControl]="hoursFilter" autocomplete="off" matInput type="text" placeholder="Hours"
              maxlength="150">
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="Hours">
            <mat-icon>filter_alt</mat-icon>
          </button>
          <mat-menu #Hours="matMenu">
            <button mat-menu-item (click)="filterValues.hoursType = true">
              <div [ngStyle]="{'color' : filterValues.hoursType===true ? '#ffe600' : '#fff' }">Contains</div>
            </button>
            <button mat-menu-item (click)="filterValues.hoursType = false">
              <div [ngStyle]="{'color' : filterValues.hoursType===false ? '#ffe600' : '#fff' }">Starts with</div>
            </button>
          </mat-menu>
        </div>

        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input [formControl]="loadingFilter" autocomplete="off" matInput type="text" placeholder="Loading"
              maxlength="150">
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="Loading">
            <mat-icon>filter_alt</mat-icon>
          </button>
          <mat-menu #Loading="matMenu">
            <button mat-menu-item (click)="filterValues.loadingType = true">
              <div [ngStyle]="{'color' : filterValues.loadingType ===true ? '#ffe600' : '#fff' }">Contains</div>
            </button>
            <button mat-menu-item (click)="filterValues.loadingType  = false">
              <div [ngStyle]="{'color' : filterValues.loadingType ===false ? '#ffe600' : '#fff' }">Starts with</div>
            </button>
          </mat-menu>
        </div>

        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input [formControl]="userSelectionFilter" autocomplete="off" matInput type="text"
              placeholder="User Selection" maxlength="150">
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="UserSelection">
            <mat-icon>filter_alt</mat-icon>
          </button>
          <mat-menu #UserSelection="matMenu">
            <button mat-menu-item (click)="filterValues.userSelectionType = true">
              <div [ngStyle]="{'color' : filterValues.userSelectionType===true ? '#ffe600' : '#fff' }">Contains</div>
            </button>
            <button mat-menu-item (click)="filterValues.userSelectionType = false">
              <div [ngStyle]="{'color' : filterValues.userSelectionType===false ? '#ffe600' : '#fff' }">Starts with
              </div>
            </button>
          </mat-menu>
        </div>

        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input [formControl]="unconfirmedFilter" autocomplete="off" matInput type="text" placeholder="Unconfirmed"
              maxlength="150">
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="Unconfirmed">
            <mat-icon>filter_alt</mat-icon>
          </button>
          <mat-menu #Unconfirmed="matMenu">
            <button mat-menu-item (click)="filterValues.unconfirmedType = true">
              <div [ngStyle]="{'color' : filterValues.unconfirmedType===true ? '#ffe600' : '#fff' }">Contains</div>
            </button>
            <button mat-menu-item (click)="filterValues.unconfirmedType = false">
              <div [ngStyle]="{'color' : filterValues.unconfirmedType===false ? '#ffe600' : '#fff' }">Starts with</div>
            </button>
          </mat-menu>
        </div>

        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input [formControl]="statusDescriptionFilter" autocomplete="off" matInput type="text" placeholder="Status"
              maxlength="150">
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="StatusDescription">
            <mat-icon>filter_alt</mat-icon>
          </button>
          <mat-menu #StatusDescription="matMenu">
            <button mat-menu-item (click)="filterValues.statusDescriptionType = true">
              <div [ngStyle]="{'color' : filterValues.statusDescriptionType===true ? '#ffe600' : '#fff' }">Contains
              </div>
            </button>
            <button mat-menu-item (click)="filterValues.statusDescriptionType = false">
              <div [ngStyle]="{'color' : filterValues.statusDescriptionType===false ? '#ffe600' : '#fff' }">Starts with
              </div>
            </button>
          </mat-menu>
        </div>

        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input [formControl]="updatedByFilter" autocomplete="off" matInput type="text" placeholder="Updated By"
              maxlength="150">
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="UpdatedBy">
            <mat-icon>filter_alt</mat-icon>
          </button>
          <mat-menu #UpdatedBy="matMenu">
            <button mat-menu-item (click)="filterValues.updatedByType = true">
              <div [ngStyle]="{'color' : filterValues.updatedByType===true ? '#ffe600' : '#fff' }">Contains</div>
            </button>
            <button mat-menu-item (click)="filterValues.updatedByType = false">
              <div [ngStyle]="{'color' : filterValues.updatedByType===false ? '#ffe600' : '#fff' }">Starts with</div>
            </button>
          </mat-menu>
        </div>

      </div>
      <div class="confirmationButtons">
        <button class="cancelBtn" (click)="closeFilterModal()">Cancel</button>
        <button class="deleteBtn" (click)="filterGrid()">Filter</button>
      </div>
    </div>
  </div>

</ng-template>
