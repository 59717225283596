import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, BehaviorSubject, forkJoin } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { AuthService } from 'app/shared/auth/auth.service';
import { environment } from 'environments/environment';
import { IndividualBookingVM } from '../../Models/IndividualBookingVM';
import { ResourceDto } from 'app/Models/ResourceDto';
import { DomSanitizer } from '@angular/platform-browser';
import { JobVM } from 'app/Models/JobVM';
import { FavouriteJobsAddActionDto, FavouriteJobsDto } from 'app/Models/FavouriteJobsDto';
import { EngagementBookingVM } from 'app/Models/EngagementBookingVM';

@Injectable({
  providedIn: 'root',
})
export class ScheduledViewService extends BehaviorSubject<any> {
  endpoint = environment.apiEndpoint;
  loggedUser = JSON.parse(sessionStorage.getItem('resource'));
  instanceId = this.loggedUser.DefaultInstance;
  isPercentageSelected: boolean = false;
  closeModalWindow: boolean = false;
  private messageEngagementAllocation = new BehaviorSubject(
    this.isPercentageSelected
  );
  private messageCloseModal = new BehaviorSubject(this.closeModalWindow);
  currentMessageCloseModal = this.messageCloseModal.asObservable();
  private hourCalcalationModes = {
    isConfirmedChecked: true,
    isUnconfirmedChecked: true,
    isGhostChecked: true
  };
  private hourCalcalationModesSEV = {
    isConfirmedChecked: true,
    isUnconfirmedChecked: true,
    isGhostChecked: true
  };
  constructor(private http: HttpClient, private auth: AuthService, private sanitizer: DomSanitizer) {
    super(null);
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }
  private getAPI(actionName: string): string {
    this.auth.checkTokenExpiredAndRefresh();
    return this.endpoint + 'ScheduleView/' + actionName;
  }
  setHourMode(param){
    this.hourCalcalationModes = param;
  }
  getHourMode(){
    return this.hourCalcalationModes;
  }
  setHourModeSEV (param){
    this.hourCalcalationModesSEV = param;
  }
  getHourModeSEV(){
    return this.hourCalcalationModesSEV;
  }
  engagementAllocationOnChange(isPercentageOptionSelected) {
    this.isPercentageSelected = isPercentageOptionSelected;
    this.messageEngagementAllocation.next(this.isPercentageSelected);
  }

  getBookingListForGrid(booking: IndividualBookingVM): Observable<any> {
    return this.http
      .post<IndividualBookingVM[]>(
        this.getAPI('FetchBookingList'),
        booking,
        this.httpOptions
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('getBookingListForGrid'))
      );
  }
  getEngagementList(resource) {
    return this.http
      .post<IndividualBookingVM[]>(
        this.getAPI('FetchScheduleViewDataForIndividual'),
        resource,
        this.httpOptions
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('FetchScheduleViewDataForIndividual'))
      );
  }

  getCellBorderColor(booking) {
    let bColor =
      booking && booking.ColorCode ? booking.ColorCode : 'transparent';
    if (booking) {
      switch (booking.Action) {
        case SCHEDULE_CONSTANTS.ACTION_EDIT:
        case SCHEDULE_CONSTANTS.ACTION_DELETE:
          bColor = '#c4c4cd ';
          break;
      }
    }
    let styles = {
      borderTop: '3px solid',
      borderTopColor: bColor,
    };
    return styles;
  }

  getEngagementDataList(userData) {
    let engagement = {
      ResourceId: -1,
      ...userData,
    };

    return this.http
      .post<IndividualBookingVM[]>(
        this.getAPI('FetchScheduleViewDataForEngagement'),
        engagement,
        this.httpOptions
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('getBookingListForGrid'))
      );
  }

  saveScheduleList(data) {
    return this.http
      .post<IndividualBookingVM[]>(
        this.getAPI('SaveScheduleBookings'),
        data,
        this.httpOptions
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('SaveScheduleBookings'))
      );
  }

  getNotesClass = (description) => {
    return description && description.trim().length > 0
      ? 'enabled'
      : 'disabled';
  };

  getUserUtilization = (data) => {
    return this.http
      .post<IndividualBookingVM[]>(
        this.getAPI('GetResourceUtilizationForScheduleView'),
        data,
        this.httpOptions
      )
      .pipe(
        map((data) => data),
        catchError(
          this.handleError<any>('GetResourceUtilizationForScheduleView')
        )
      );
  };

  getResourceList(resource: ResourceDto): Observable<any> {
    return this.http.post<ResourceDto[]>(this.getAPI('FetchResource'), resource, this.httpOptions).pipe(
      map(data => data),
      catchError(this.handleError<any>('FetchResource'))
    );
  }

  fetchResourceDetail(resource: ResourceDto): Observable<any> {
    return this.http.post<ResourceDto>(this.getAPI('FetchResource'), resource,
      this.httpOptions).pipe(
        map(data => {
          if (data) {
            this.getProfilePhoto(resource.ResourceEmail).subscribe(result => {
              data[0].ResourcePhotoUrl = result
            })
          }
          return data[0]
        }),
        catchError(this.handleError<any>('getResourceDetails'))
      );
  }

  getProfilePhoto(userPrincipalName: string) {
    //let requestUrl = `https://graph.microsoft.com/v1.0/users/`+ userPrincipalName +  `/photo/$value`;
    let requestUrl = `https://graph.microsoft.com/v1.0/me/photo/$value`;
    return this.http.get(requestUrl, { responseType: "blob" }).pipe(map(result => {
      let url = window.URL;
      return this.sanitizer.bypassSecurityTrustUrl(url.createObjectURL(result));
    }));

  }

  getEngagementSearchResult(category: JobVM): Observable<any> {
    return this.http.post<JobVM[]>(this.getAPI('FetchEngagement'), category,
      { headers: this.httpOptions.headers, observe: 'response' }).pipe(
        map(data => data),
        catchError(this.handleError<any>('getEngagementSearchResult'))
      );
  }

  CancelMeraBookings(inputDto: any): Observable<any> {
    return this.http.post<any>(this.getAPI('CancelMeraBookings'), inputDto,
      { headers: this.httpOptions.headers, observe: 'response' }).pipe(
        map(data => data),
        catchError(this.handleError<any>('CancelMeraBookings'))
      );
  }

  addFavouriteJobsCopy(favouriteJobs): Observable<any> {
    return this.http.post<FavouriteJobsDto>(this.getAPI('AddFavouriteJobs'), favouriteJobs,
      { headers: this.httpOptions.headers, observe: 'response' }).pipe(
        map(data => data),
        catchError(this.handleError<any>('addFavouriteJobs'))
      );
  }

  getFavouriteJobsCopy(favouriteJobs): Observable<any> {
    return this.http.post<FavouriteJobsDto[]>(this.getAPI('GetFavouriteJobs'), favouriteJobs,
      this.httpOptions
    ).pipe(
      map(data => data),
      catchError(this.handleError<any>('GetFavouriteJobs'))
    );
  }

  getJobList(engagement: JobVM): Observable<any> {
    return this.http
      .post<JobVM[]>(this.getAPI('FetchJobs'), engagement, this.httpOptions)
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('getJobList'))
      );
  }



  getEngagementResourceList(resource: ResourceDto): Observable<any> {
    return this.http
      .post<ResourceDto[]>(
        this.getAPI('FetchEngagementResources'),
        resource,
        this.httpOptions
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('getResourceList'))
      );
  }

  // for Favorite Engagement --- scheduleView START

  addFavouriteJobs(favouriteJobs: FavouriteJobsAddActionDto): Observable<any> {
    return this.http.post<any[]>(this.getAPI('AddFavouriteJobs'), favouriteJobs,
      { headers: this.httpOptions.headers, observe: 'response' }).pipe(
        map(data => data),
        catchError(this.handleError<any>('addFavouriteJobs'))
      );
  }

  GetFavouriteJobsIndividualView(favouriteJobs): Observable<any> {
    return this.http.post<FavouriteJobsDto[]>(this.getAPI('GetFavouriteJobsIndividualView'), favouriteJobs,
      this.httpOptions
    ).pipe(
      map(data => data),
      catchError(this.handleError<any>('GetFavouriteJobsIndividualView'))
    );
  }

  GetFavouriteJobsEngagementView(favouriteJobs): Observable<any> {
    return this.http.post<FavouriteJobsDto[]>(this.getAPI('GetFavouriteJobsEngagementView'), favouriteJobs,
      this.httpOptions
    ).pipe(
      map(data => data),
      catchError(this.handleError<any>('GetFavouriteJobsEngagementView'))
    );
  }

  replaceAllEngagement(booking: EngagementBookingVM): Observable<any> {
    return this.http
      .post<EngagementBookingVM[]>(
        this.getAPI('ReplaceAllEngagement'),
        booking,
        { headers: this.httpOptions.headers, observe: 'response' }
      )
      .pipe(
        map((data) => data),
        catchError(this.handleError<any>('ReplaceAllEngagement'))
      );
  }
}

// for Favorite Engagement --- END

export const SCHEDULE_CONSTANTS = {
  SNACKBAR_TIMEOUT: 3000,
  ACTION_DELETE: 'Delete',
  ACTION_EDIT: 'Edit',
  ACTION_CREATE: 'Create',
  ACTION_REPLACE: 'Replace',
  ACTION_COPY: 'Copy'
};



