
export class MyRequest_RequestByMeVM {
    RequestByEmail: string;
    RequestId: string ;
    ActionId: number;
    Action: string;
    ResourceName: string;
    Engagement: string;
    StartDate: string;
    EndDate: string;
    Hours:number | null;
    UserSelection: string;
    Unconfirmed: string;
    Status: string;
    RejectionReason: string;
    RequestedDateTime : string;
    HighlightRow : boolean = false;
    RequestByName: string;
    Loading: number;
    IsPercentSelected: boolean;
    Ghost: string;
    ApprovedOrRejectedBy:string;
    ApprovedOrRejectedOn :string;
    startDate_sort:string;
    endDate_sort:string;
    RequestedDateTime_sort:string;
    ApprovedOrRejectedOn_sort :string;
    ApproverComment: string;
}

export class FilterParamsByMe {
    RequestedDateFrom: Date | null;
    RequestedDateTo: Date | null;
  }