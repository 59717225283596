<form (ngSubmit)="onEditSubmit()" [formGroup]="formGroup">

  <div class="popupLayout">
    <div *ngIf="parentViewIVorEV=='IV'" style="pointer-events: none;">      
      <div class="fullWidth">
        <label>Engagement</label>
        <mat-form-field appearance="outline">
          <input matInput type="text" [value]='parentDataItem.Engagement' [readonly]="true">
        </mat-form-field>
        
      </div>

      <div class="customRow">
        <div class="halfWidthItem">
          <label>Booking Type</label>
          <mat-form-field appearance="outline">
            <input matInput type="text" [value]='parentDataItem.BookingType' [readonly]="true">
          </mat-form-field>
          
        </div>
        <div class="halfWidthItem">
          <label>Engagement Code</label>
          <mat-form-field appearance="outline">
            <input matInput type="text" [value]='parentDataItem.EngagementCode' [readonly]="true">
          </mat-form-field>
          
        </div>
      </div>

      <div class="customRow">
        <div class="halfWidthItem">
          <label>Engagement Service Line</label>
          <mat-form-field appearance="outline">
            <input matInput type="text" [value]='parentDataItem.ServiceLineDescription' [readonly]="true">
          </mat-form-field>
         
        </div>
        <div class="halfWidthItem">
          <label>Engagement Status</label>
          <mat-form-field appearance="outline">
            <input matInput type="text" [value]='parentDataItem.JobStatus' [readonly]="true">
          </mat-form-field>
          
        </div>
      </div>
    </div>

    <div *ngIf="parentViewIVorEV=='EV'" style="pointer-events: none;">
      <div class="engSearch">
        <!-- <div class="halfWidthItem"> -->
          <label>Resource Name <span class="mandtoryIcn"> *</span></label>
          <mat-form-field appearance="outline">
            <input type="text" matInput [value]='parentDataItem.ResourceName' [readonly]="true">
          </mat-form-field>
        <!-- </div>
        <div class="halfWidthItem">
          <label>Service Line</label>
          <mat-form-field appearance="outline">
            <input type="text" matInput [value]='parentDataItem.ServiceLineDescription' [readonly]="true">
          </mat-form-field>
        </div> -->
      </div>
      <div class="customRow">
        <div class="halfWidthItem">
          <label>Service Line</label>
          <mat-form-field appearance="outline">
            <input type="text" matInput [value]="parentDataItem.ServiceLineDescription" [readonly]="true">
          </mat-form-field>
        </div>
        <div class="halfWidthItem">
          <label>HR Department</label>
          <mat-form-field appearance="outline">
            <input type="text" matInput [value]="parentDataItem.DepartmentDescription" [readonly]="true">
          </mat-form-field>
        </div>
      </div>
      <div class="customRow">
        <div class="halfWidthItem">
          <label>Rank</label>
          <mat-form-field appearance="outline">
            <input type="text" matInput [readonly]="true" [value]='parentDataItem.RankDescription'>
          </mat-form-field>
        </div>
        <div class="halfWidthItem">
          <label class="formLabel">Office Location</label>
          <mat-form-field appearance="outline">
            <input type="text" matInput [readonly]="true" [value]="parentDataItem.LocationDescription">
          </mat-form-field>
        </div>
      </div>
    </div>
  
    <div *ngIf="!singleEdit" class="customRow">
      <div class="defaultColumnCheckbox">
        <label>Select All</label>   
         <mat-checkbox formControlName ="selectAll_chk" [disableRipple]='true' (change)="changeSelectAll($event)"></mat-checkbox> 
      </div>
    </div>
    <div [formGroup]="myFormGroup">
      <div *ngFor="let item of bookingList; let i = index;">
        <div title="{{ item.IsCurrentEditWeek == true ? 'Disabled Current Week' 
        : ( item.IsDisabledEditBooking == true && item.StartDate < startOfWeek ? 'History Booking Period' : '' )}}"
        [ngStyle]="item.IsDisabledEditBooking == true && {'opacity': 0.6, 'cursor': 'not-allowed', 'background-color': '#424244'}" 
        style="padding-left: .5rem; padding-right: .5rem;">
          <div class="estimationContainer">
            <div *ngIf="!singleEdit" class="defaultColumnCheckbox">
              <label>Select </label>
              <mat-checkbox  formControlName ="editchk_{{i}}"  [disableRipple]='true'
              [disabled]="item.IsCurrentEditWeek == true || item.IsDisabledEditBooking == true ? true : null"
               (change)="changeCheck(i,$event)"></mat-checkbox>
              
            </div>          
            <div class="col50percent">
                <label>Period <span *ngIf="item.IsDisabledEditBooking != true" class="mandtoryIcn"> *</span></label>
                <mat-form-field appearance="outline">  
                  <mat-date-range-input formGroupName="editPeriod_{{i}}" [rangePicker]="picker" [min]="item.enabledWeekDay">
                    <input matStartDate formControlName="dtStart" placeholder="start date" (focus)="picker.open()"
                      (click)="picker.open()" readonly>
                    <input matEndDate formControlName="dtEnd" placeholder="end date" (focus)="picker.open()"
                      (click)="picker.open()" readonly>
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                  </mat-form-field>
                  <div *ngIf="myFormGroup && myFormGroup.get('editPeriod_'+i) && myFormGroup.get('editPeriod_'+i).get('dtStart').hasError('matStartDateInvalid')" class="alert alert-danger">Invalid start date</div>
                  <div *ngIf="myFormGroup && myFormGroup.get('editPeriod_'+i) && myFormGroup.get('editPeriod_'+i).get('dtEnd').hasError('matEndDateInvalid')" class="alert alert-danger">Invalid end date</div>
                
                <div *ngIf="myFormGroup && myFormGroup.get('editPeriod_'+i) && myFormGroup.get('editPeriod_'+i).get('dtStart').value === null && (myFormGroup.get('editPeriod_'+i).get('dtStart').dirty || myFormGroup.get('editPeriod_'+i).get('dtStart').touched)" class="alert alert-danger">
                  Start date is required
                </div>
                <div *ngIf="myFormGroup && myFormGroup.get('editPeriod_'+i) && myFormGroup.get('editPeriod_'+i).get('dtEnd').value === null && (myFormGroup.get('editPeriod_'+i).get('dtEnd').dirty || myFormGroup.get('editPeriod_'+i).get('dtEnd').touched)" class="alert alert-danger">
                  End date is required
                </div>
            </div>
            <div class="col20percent">
              <label>{{engagementAllocationText}} <span *ngIf="item.IsDisabledEditBooking != true" class="mandtoryIcn"> *</span></label>
              <mat-form-field appearance="outline">
                <input matInput type="text" (input)="makeDirty()" 
              tooltip='Note when adding time in hours this will be input for the full-time period of the booking, not per week. For long term bookings Loading% may be the preferred way to input time allocation to work (please see “preference” button in top right hand corner to switch between the time allocation input methods)'
              triggers="hover click focus" placement="bottom"
                formControlName="editHours_{{i}}" *ngIf="isPercentageSelected == false" autocomplete="off" appHoursLoading [isPercentageSelected]="isPercentageSelected">
              <input type="text" matInput (input)="makeDirty()" 
                formControlName="editLoading_{{i}}" *ngIf="isPercentageSelected == true" autocomplete="off" appHoursLoading [isPercentageSelected]="isPercentageSelected">
              </mat-form-field>
              <div *ngIf="isPercentageSelected == false">
                <div *ngIf="myFormGroup && myFormGroup.get('editHours_'+i)?.errors?.required" class="alert alert-danger">
                  {{engagementAllocationText}} is required.
                </div>
                <div *ngIf="myFormGroup && myFormGroup.get('editHours_'+i)?.errors?.pattern" class="alert alert-danger">
                  {{engagementAllocationText}} is invalid.
                </div>
              </div>
              <div *ngIf="isPercentageSelected == true">
                <div *ngIf="myFormGroup && myFormGroup.get('editLoading_'+i)?.errors?.required" class="alert alert-danger">
                  {{engagementAllocationText}} is required.
                </div>
                <div *ngIf="myFormGroup && myFormGroup.get('editLoading_'+i)?.errors?.pattern" class="alert alert-danger">
                  {{engagementAllocationText}} is invalid.
                </div>
                
              </div>
             
            </div>
            <div class="confirmContainer" *ngIf="!resource?.Unconfirmed">
                <label>Unconfirmed</label>
                <mat-checkbox formControlName="editUnconfirmed_{{i}}" [disableRipple]='true' (change)="makeDirty()"></mat-checkbox>
                
            </div>
            <div class="confirmContainer" *ngIf="resource?.Ghost">
                <label>Ghost</label>
                <mat-checkbox formControlName="editGhost_{{i}}" [disableRipple]='true' (change)="makeDirty()"></mat-checkbox>
                
            </div>
          </div>  
          <div class="customRow">
            <div class="col100percent">
              <label>Notes Description</label>
              <mat-form-field appearance="outline">
                <input matInput tooltip="Please do not enter any unnecessary personal information or any sensitive personal data (including tax file numbers or other government identifiers), client confidential information, audit secrets, state secrets, commercial secrets, or anything that would violate professional secrecy or confidentiality rules or that would be considered abusive/irrelevant."
              type="text" triggers="hover click focus" placement="bottom"
               formControlName="editNotes_{{i}}" autocomplete="off" maxlength="200">
              </mat-form-field>
              
            </div>          
          </div>
        </div>
      </div>
      <div class="estimationContainer" *ngIf="!hoursValid">
        <div class="col80percent" [class.disabledoverallocation]="!hoursValid">
          <label>This Booking request will cause an overallocation. If you wish to proceed, please select the check box and click on Submit.</label>
        </div>
        <div class="col20percent" [class.disabledoverallocation]="!hoursValid">
          <label>Confirm</label>
         
          <mat-checkbox [disableRipple]='true' (change)="confirmHoursChange(confirmHrs.checked)"  #confirmHrs></mat-checkbox>
         
        </div>
      </div> 
    </div>
    <div class="SubmitContainer">
      <button class="submitButton" type="submit" 
      [ngClass]="{'k-state-disabled': (buttonText != 'Submit' || dirtyFlag == false || myFormGroup.invalid == true)}">
        {{buttonText}}
      </button>
    </div>
  </div>

</form>
