/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

 import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
 import { environment } from '../../../environments/environment';

 const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
 
 /**
  * Configuration object to be passed to MSAL instance on creation. 
  * For a full list of MSAL.js configuration parameters, visit:
  * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
  */
 export const msalConfig: Configuration = {
  auth: {
    clientId: environment.uiClientId, // This is the ONLY mandatory field that you need to supply.
    authority: environment.authority, // Defaults to "https://login.microsoftonline.com/common"
    redirectUri: environment.redirectUri,   // 'https://global-mera-dev.ey.com', //You must register this URI on Azure portal/App Registration.
    postLogoutRedirectUri: environment.postLogoutRedirectUri  // 'https://global-mera-dev.ey.com/Logout'
    // redirectUri: '/', // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
  },
  cache: {
    // todo: should use BrowserCacheLocation.MemoryStorage ?
    cacheLocation: BrowserCacheLocation.SessionStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {
        // console.log(message);
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false
    }
  }
}
 
 /**
  * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
  * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
  */
 export const protectedResources = {
  ApiList: {
     //endpoint: "https://acemwpdmraweb02.azurewebsites.net/api/",
     endpoint: environment.apiEndpoint,   //"https://api.global-mera-dev.ey.com/",
     scopes: [environment.apiScope]   //["api://252250d5-5974-4e2c-94a9-00e887d0eb90/Write.Settings"]
   },
 }
 
 /**
  * Scopes you add here will be prompted for user consent during sign-in.
  * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
  * For more information about OIDC scopes, visit: 
  * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
  */
 export const loginRequest = {
   scopes: [environment.apiScope]
 };
