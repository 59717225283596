<ngx-spinner></ngx-spinner>
<div class="appLayout approvalQueue">
  <div class="configureContent">

    <div class="configure" (click)="openActionModal(GridActions, $event, null, 'configure')">
      <span>
        <mat-icon>settings</mat-icon>
      </span>
      <span>Configure Approval Queue</span>
    </div>
  </div>
  <div *ngIf="!isConfigured" class="firstConfigure">
    No configuration found.<br />Configure by clicking 'Configure Approval Queue' button to
    list booking requests in approval queue.
  </div>
  <div class="approvalRequest" *ngIf="isConfigured">

    <div class="approveRequests">
      <mat-tab-group (selectedIndexChange)="apprveQTabChange($event)" [(selectedIndex)]="selectedTabIndex"
        mat-align-tabs="start" class="my-3 tab-grp" #approvalBokngTabGrup>
        <mat-tab label="Booking Request">
          <div class="approvalQueueContainer">
            <!-- <div class="requestLabel">Booking requests</div> -->

            <div class="row">
              <div class="col-md-1 actionsTab">
                <div class="actions">
                  <div class="actionSubmit">
                    <div class="row">
                      <div (click)="exportAsXLSX()" matTooltip="Export to excel" class="exportExcel"
                        [ngClass]="approvalRequestList?.length === 0 || dataSourceApproval?.filteredData.length === 0? 'disablClass' : 'enableClass'">
                        <img class="exportExcelIcon" src="../../../../assets/images/excel-export-black.jpg" />
                      </div>
                      <!-- <mat-icon class="reject" 
                      (click)="exportAsXLSX()" 
                      [ngClass]="approvalRequestList?.length === 0  || this.dataSourceApproval?.filteredData.length === 0 ? 'disablClass' : 'enableClass'"
                       matTooltip="Export to excel">
                        backup
                      </mat-icon> -->
                      <mat-icon (click)="clearTableFilter()" class="reject"
                        [ngClass]="approvalRequestList?.length === 0 ? 'disablClass' : 'enableClass'"
                        matTooltip="Clear filters"> clear_all</mat-icon>
                    </div>
                    <div class="row">
                      <mat-icon class="approve" (click)="approvebookingReq(approveRejectComments)"
                        [ngClass]="apprRejctDisbleFlg ? 'disablClass' : 'enableClass'" matTooltip="Approve">done
                      </mat-icon>
                      <mat-icon class="reject" (click)="rejectbookingReq(approveRejectComments)"
                        [ngClass]="apprRejctDisbleFlg ? 'disablClass' : 'enableClass'" matTooltip="Reject">close
                      </mat-icon>

                    </div>
                  </div>

                </div>
              </div>

              <div class="col-md-11 approvalFilter">
                <div class="dateContainer">
                  <label>Period</label>
                  <form [formGroup]="range">
                    <mat-form-field appearance="outline">
                      <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate formControlName="start" placeholder="Start Date" (click)="picker.open()"
                          (dateChange)="onStartDateChange($event)" readonly />-
                        <input matEndDate formControlName="end" placeholder="End Date" (click)="picker.open()"
                          (dateChange)="onEndDateChange($event)" readonly />
                      </mat-date-range-input>
                      <mat-icon class="clearDate" (click)="resetRange()">close</mat-icon>
                      <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                  </form>
                </div>
                <div class="dateContainer">
                  <label>Requests Submission</label>
                  <form [formGroup]="requestedOnRange">
                    <mat-form-field appearance="outline">
                      <mat-date-range-input [rangePicker]="requestedOnPicker">
                        <input matStartDate formControlName="start" placeholder="Start Date"
                          (click)="requestedOnPicker.open()" (dateChange)="onStartRequestedOnChange($event)"
                          readonly />-
                        <input matEndDate formControlName="end" placeholder="End Date"
                          (click)="requestedOnPicker.open()" (dateChange)="onEndRequestedOnChange($event)" readonly />
                      </mat-date-range-input>
                      <mat-icon class="clearDate" (click)="resetRequestedOnRange()">close</mat-icon>
                      <mat-date-range-picker #requestedOnPicker></mat-date-range-picker>
                    </mat-form-field>
                  </form>
                </div>
                <div style="width: 15%;">
                  <label>Engagement Category</label>
                  <mat-form-field appearance="outline">
                    <mat-select placeholder="Select" (selectionChange)="onEngagementCategoryChange($event)"
                      [(value)]="jobCategory">
                      <mat-option *ngFor="let engCatgry of engCategoryList" [value]="engCatgry.Text">
                        {{ engCatgry.Text }}</mat-option>

                      <!-- <mat-option value="Chargeable">Chargeable</mat-option>
                      <mat-option value="Authorised Project">Authorised Project
                      </mat-option>
                      <mat-option value="Non Chargeable">Non Chargeable</mat-option> -->
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="filterParam">
                  <label>Action</label>
                  <mat-form-field appearance="outline">
                    <mat-select placeholder="Select" (selectionChange)="onActionCategoryChange($event)"
                      [(value)]="actionCategory">
                      <mat-option *ngFor="let action of actionsList" [value]="action.Text">{{ action.Text }}
                      </mat-option>
                      <!-- <mat-option value="Create">Create</mat-option>
                      <mat-option value="Edit">Edit</mat-option>
                      <mat-option value="Delete">Delete</mat-option>
                      <mat-option value="Copy">Copy</mat-option>
                      <mat-option value="Replace">Replace</mat-option>
                      <mat-option value="Replace All">Replace All</mat-option> -->
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="filterParam">
                  <mat-radio-group aria-label="preferenceOption" class="expansionRadio"
                    [(ngModel)]="engagementAllocationModal" (change)="engagementAllocationOnChange($event)">
                    <mat-radio-button value="hour" class="prefernceRadio">Hour</mat-radio-button>
                    <mat-radio-button value="percentage" class="prefernceRadio">Percentage</mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="search">
                  <button class="searchButton" (click)="applyFilter()">Search</button>
                  <button class="clearSearch" (click)="clearFilter()">Clear</button>
                </div>
              </div>


            </div>
            <div class="clearFilterContainer">
              <div class="filterMobile">
                <mat-form-field appearance="outline">
                  <input matInput type="text" placeholder="Search..." [formControl]="keywordSearch" autocomplete="off"
                    maxlength="150">
                </mat-form-field>
              </div>
              &nbsp; &nbsp;
              <mat-icon class="filterIcon" (click)="openFilterModal(GridFilter)" matTooltip="Apply filters">
                filter_alt
              </mat-icon>
              &nbsp; &nbsp;
              <mat-icon (click)="clearTableFilter()" class="clearFilter" matTooltip="Clear filters"> clear_all
              </mat-icon>
            </div>
            <div class="approveTable">
              <table mat-table [dataSource]="dataSourceApproval" class="mat-elevation-z8 queuesTable" matSort
                id="excel-table" #empTbSort="matSort">
                <ng-container matColumnDef="Select">
                  <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      [disabled]="dataSourceApproval?.filteredData.length === 0">
                    </mat-checkbox>
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation(); BokingSelection(row)"
                      (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                  </td>
                </ng-container>
                <ng-container matColumnDef="RequestId">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Request ID
                  </th>
                  <!-- {{row.IsOverallocation ? 'This request will cause an overallocation' : ''}} -->
                  <td mat-cell *matCellDef="let row">
                    <div class="detailsContainer">
                      <!-- <span *ngIf="(row.IsOverallocation && row.IsOverallocation === true) || (row.Select)"
                        class="badge badge-overallocation"
                        title="{{(row.Select) ? 'Selected request has multiple ActionIDs' : ''}}&#013;{{row.IsOverallocation ? 'This request will cause an overallocation' : ''}}">!</span> -->

                        
                        <span class="badgeContainer"  *ngIf="(row.IsOverallocation && row.IsOverallocation === true) || (row.Select)">
                          <mat-icon style="color:red;" matTooltip="{{(row.Select) ? 'Selected request has multiple ActionIDs' : ''}}&#013;{{row.IsOverallocation ? 'This request will cause an overallocation' : ''}}" 
                          class="badge-info"
                          (click)="showDetails('Resource', row, templateDetails)">info</mat-icon>
                        </span>


                      <span class="detailsLink PadLeft" matTooltip="View Booking details"
                        (click)="approvalBokngTabGrup.selectedIndex = 1;onRowSelect(row,'eachRow')">{{ row.RequestId }}</span>
                      <!-- <span *ngIf="hasMultipleActinIds && row.RequestId == selectdReqId" class="badge badge-multipleIds"
                        title="Selected request has multiple ActionIDs">!</span> -->
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="ActionId">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Action ID
                  </th>
                  <td mat-cell *matCellDef="let row">{{ row.MeraBookingId }}</td>
                </ng-container>

                <ng-container matColumnDef="ActionDescription">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Action
                  </th>
                  <td mat-cell *matCellDef="let row">{{ row.ActionDescription }}</td>
                </ng-container>

                <ng-container matColumnDef="ResourceName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Resource
                  </th>
                  <!-- <td mat-cell *matCellDef="let row">
                    <div class="detailsLink" 
                    (click)="showDetails('Resource', row, templateDetails)" 
                    matTooltip="View Resource details">
                      {{ row.ResourceName }}
                    </div>
                  </td> -->
                  <td mat-cell *matCellDef="let row">
                    <div class="detailsContainer">
                      <span class="badgeContainer">
                        <mat-icon matTooltip="View Resource details" class="badge-info"
                          (click)="showDetails('Resource', row, templateDetails)">
                          info</mat-icon>
                      </span>
                      <span class="detailsLink PadLeft" (click)="navigateIndividual(row)"
                      [ngClass]="{'disableNavigation' : row.IsDisableViewBooking_Res === true }" [matTooltip]="row.IsDisableViewBooking_Res !== true ? 
                    'Navigate to Individual View' : null">
                      {{ row.ResourceName }}
                    </span>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="JobDescription">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Engagement
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <!-- <div class="detailsLink" 
                    (click)="showDetails('Engagement', row, templateDetails)" 
                    matTooltip="View Engagement details">
                      {{ row.JobDescription }}
                    </div> -->
                    <div class="detailsContainer">
                      <span class="badgeContainer">
                        <mat-icon matTooltip="View Engagement details" class="badge-info"
                          (click)="showDetails('Engagement', row, templateDetails)">
                          info</mat-icon>
                      </span>                      
                      <span class="detailsLink PadLeft" (click)="navigateEngagement(row)"
                        [ngClass]="{'disableNavigation': row.IsDisabledViewBooking_Job === true }"
                        [matTooltip]="row.IsDisabledViewBooking_Job !== true ? 'Navigate to Engagement View' : null">
                        {{ row.JobDescription }}
                      </span>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="Hours">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Hours
                  </th>
                  <td mat-cell *matCellDef="let row">{{ row.Hours }}</td>
                </ng-container>

                <ng-container matColumnDef="Loading">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Loading %
                  </th>
                  <td mat-cell *matCellDef="let row">{{ row.Loading }}%</td>
                </ng-container>

                <ng-container matColumnDef="StartDate">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Start Date
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.StartDate}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="EndDate">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    End Date
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.EndDate}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="Age">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Ageing (in days)
                  </th>
                  <td mat-cell *matCellDef="let row">{{ row.Age }}</td>
                </ng-container>

                <ng-container matColumnDef="SelectFilter">
                  <th mat-header-cell *matHeaderCellDef></th>
                </ng-container>

                <!-- <ng-container matColumnDef="RequestIdFilter">
                  <th mat-header-cell *matHeaderCellDef>
                    <div class="filterContainer">
                      <input [formControl]="requestIdFilter" autocomplete="off" (keydown)="$event.stopPropagation()"
                        style="width: 50px" />
                      <button mat-icon-button [matMenuTriggerFor]="RequestId">
                        <mat-icon class="filterIcon">filter_alt</mat-icon>
                      </button>
                      <mat-menu #RequestId="matMenu">
                        <button mat-menu-item (click)="filterValues.requestIdType = true;triggerFilter()">
                          <div [ngStyle]="{
                              color: filterValues.requestIdType === true ? '#ffe600' : '#fff'
                            }">
                            Contains
                          </div>
                        </button>
                        <button mat-menu-item (click)="filterValues.requestIdType = false;triggerFilter()">
                          <div [ngStyle]="{
                              color: filterValues.requestIdType === false ? '#ffe600' : '#fff'
                            }">
                            Starts with
                          </div>
                        </button>
                      </mat-menu>
                      <mat-icon class="filterIcon" (click)="filterValues.requestIdType = true; requestIdFilter.setValue('')"
                        *ngIf="requestIdFilter.value.length > 0">clear</mat-icon>
                    </div>
                  </th>
                </ng-container>

                <ng-container matColumnDef="ActionIdFilter">
                  <th mat-header-cell *matHeaderCellDef>
                    <div class="filterContainer">
                      <input [formControl]="actionIdFilter" autocomplete="off" style="width: 50px" />
                      <button mat-icon-button [matMenuTriggerFor]="ActionID">
                        <mat-icon class="filterIcon">filter_alt</mat-icon>
                      </button>
                      <mat-menu #ActionID="matMenu">
                        <button mat-menu-item (click)="filterValues.actionType = true;triggerFilter()">
                          <div [ngStyle]="{
                              color: filterValues.actionType === true ? '#ffe600' : '#fff'
                            }">
                            Contains
                          </div>
                        </button>
                        <button mat-menu-item (click)="filterValues.actionType = false;triggerFilter()">
                          <div [ngStyle]="{
                              color: filterValues.actionType === false ? '#ffe600' : '#fff'
                            }">
                            Starts with
                          </div>
                        </button>
                      </mat-menu>
                      <mat-icon class="filterIcon" (click)="filterValues.actionType = true; actionIdFilter.setValue('')"
                        *ngIf="actionIdFilter.value.length > 0">clear</mat-icon>
                    </div>
                  </th>
                </ng-container>

                <ng-container matColumnDef="ActionFilter">
                  <th mat-header-cell *matHeaderCellDef></th>
                </ng-container>

                <ng-container matColumnDef="ResourceNameFilter">
                  <th mat-header-cell *matHeaderCellDef>
                    <div class="filterContainer">
                      <input [formControl]="resourcesFilter" autocomplete="off" (keydown)="$event.stopPropagation()"
                        style="width: 80px" />
                      <button mat-icon-button [matMenuTriggerFor]="ResourceName">
                        <mat-icon class="filterIcon">filter_alt</mat-icon>
                      </button>
                      <mat-menu #ResourceName="matMenu">
                        <button mat-menu-item (click)="filterValues.resourceNameType = true;triggerFilter()">
                          <div [ngStyle]="{
                              color: filterValues.resourceNameType === true ? '#ffe600' : '#fff'
                            }">
                            Contains
                          </div>
                        </button>
                        <button mat-menu-item (click)="filterValues.resourceNameType = false;triggerFilter()">
                          <div [ngStyle]="{
                              color: filterValues.resourceNameType === false ? '#ffe600' : '#fff'
                            }">
                            Starts with
                          </div>
                        </button>
                      </mat-menu>
                      <mat-icon class="filterIcon" (click)="filterValues.resourceNameType = true; resourcesFilter.setValue('')"
                        *ngIf="resourcesFilter.value.length > 0">clear</mat-icon>
                    </div>
                  </th>
                </ng-container>

                <ng-container matColumnDef="JobDescriptionFilter">
                  <th mat-header-cell *matHeaderCellDef>
                    <div class="filterContainer">
                      <input [formControl]="engagementFilter" autocomplete="off" (keydown)="$event.stopPropagation()" />
                      <button mat-icon-button [matMenuTriggerFor]="JobDescription">
                        <mat-icon class="filterIcon">filter_alt</mat-icon>
                      </button>
                      <mat-menu #JobDescription="matMenu">
                        <button mat-menu-item (click)="filterValues.engagementType = true;triggerFilter()">
                          <div [ngStyle]="{
                              color: filterValues.engagementType === true ? '#ffe600' : '#fff'
                            }">
                            Contains
                          </div>
                        </button>
                        <button mat-menu-item (click)="filterValues.engagementType = false;triggerFilter()">
                          <div [ngStyle]="{
                              color: filterValues.engagementType === false ? '#ffe600' : '#fff'
                            }">
                            Starts with
                          </div>
                        </button>
                      </mat-menu>
                      <mat-icon class="filterIcon" (click)="filterValues.engagementType = true; engagementFilter.setValue('')"
                        *ngIf="engagementFilter.value.length > 0">clear</mat-icon>
                    </div>
                  </th>
                </ng-container>

                <ng-container matColumnDef="HoursFilter">
                  <th mat-header-cell *matHeaderCellDef></th>
                </ng-container>

                <ng-container matColumnDef="LoadingFilter">
                  <th mat-header-cell *matHeaderCellDef></th>
                </ng-container>

                <ng-container matColumnDef="StartDateFilter">
                  <th mat-header-cell *matHeaderCellDef> </th>
                </ng-container>

                <ng-container matColumnDef="EndDateFilter">
                  <th mat-header-cell *matHeaderCellDef></th>
                </ng-container>

                <ng-container matColumnDef="AgeFilter">
                  <th mat-header-cell *matHeaderCellDef>
                    <div class="filterContainer">
                      <input [formControl]="ageingFilter" autocomplete="off" style="width: 50px" />
                      <button mat-icon-button [matMenuTriggerFor]="Age">
                        <mat-icon class="filterIcon">filter_alt</mat-icon>
                      </button>
                      <mat-menu #Age="matMenu">
                        <button mat-menu-item (click)="filterValues.ageingType = true;triggerFilter()">
                          <div [ngStyle]="{
                              color: filterValues.ageingType === true ? '#ffe600' : '#fff'
                            }">
                            Contains
                          </div>
                        </button>
                        <button mat-menu-item (click)="filterValues.ageingType = false;triggerFilter()">
                          <div [ngStyle]="{
                              color: filterValues.ageingType === false ? '#ffe600' : '#fff'
                            }">
                            Starts with
                          </div>
                        </button>
                      </mat-menu>
                      <mat-icon class="filterIcon" (click)="filterValues.ageingType = true; ageingFilter.setValue('')"
                        *ngIf="ageingFilter.value.length > 0">clear</mat-icon>
                    </div>
                  </th>
                </ng-container> -->


                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                <!-- <tr mat-header-row *matHeaderRowDef="filterDisplayedColumns"></tr> -->
                <tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns"></tr>
              </table>
              <div class="noResults">
                <div *ngIf="dataSourceApproval === undefined">
                  <mat-spinner [diameter]="40"></mat-spinner>
                </div>
                <div *ngIf="approvalRequestList?.length === 0 || dataSourceApproval?.filteredData.length === 0">
                  Bookings not found
                </div>
              </div>
            </div>
            <mat-paginator (page)="pageEvent = onChangePage($event)" [pageSize]="pageSize"
              [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons></mat-paginator>
          </div>
        </mat-tab>
        <mat-tab label="Booking Details"
          *ngIf="!(approvalRequestList?.length === 0 || dataSourceApproval?.filteredData.length === 0)">
          <div class="px-4 booking-details">
            <div class="row my-3">
              <!-- commented this code and added new css -->
              <!-- <div class="mx-2 p-3 col-left">
                <span class="bookngHeadng">Booking Information</span>
                <div class="row">
                  <div class="col-md-6">
                    <span class="eachLabelStyle">Request ID</span><br>
                    <span class="bokingData">{{ selectedRow?.RequestId }} <span *ngIf="overallocatnMsg"
                        class="badge badge-overallocation" title="This request will cause an overallocation">!</span>
                    </span>
                  </div>
                  <div class="col-md-6">
                    <span class="eachLabelStyle"> Action ID</span><br>
                    <span class="bokingData">{{ selectedRow?.MeraBookingId }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <span class="eachLabelStyle">Booking ID</span><br>
                    <span class="bokingData">{{ selectedRow?.BookingId }}</span>
                  </div>
                  <div class="col-md-6">
                    <span class="eachLabelStyle">Action</span><br>
                    <span class="bokingData">{{ selectedRow?.ActionDescription }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <span class="eachLabelStyle">Requestor Name</span><br>
                    <span class="bokingData">{{ selectedRow?.RequestorName }}</span>
                  </div>
                  <div class="col-md-6">
                    <span class="eachLabelStyle">Requested On</span><br>
                    <span class="bokingData">{{ selectedRow?.RequestedOn | date: defaultDateFormat}}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <span class="eachLabelStyle">Total Scheduled Loading%</span><br>
                    <span class="bokingData">{{ selectedRow?.TotalLoading }}</span>
                  </div>
                  <div class="col-md-6">
                    <span class="eachLabelStyle">Scheduled Hours - Confirmed</span><br>
                    <span class="bokingData">{{ selectedRow?.ScheduledHours }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <span class="eachLabelStyle">Scheduled Hours - Requests</span><br>
                    <span class="bokingData">{{ selectedRow?.RequestedHours }}</span>
                  </div>
                  <div class="col-md-6">
                    <span class="eachLabelStyle"> Scheduled Loading - Confirmed</span><br>
                    <span class="bokingData">{{ selectedRow?.ScheduledLoading }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <span class="eachLabelStyle"> Scheduled Loading - Requests</span><br>
                    <span class="bokingData">{{ selectedRow?.RequestedLoading }}</span>
                  </div>

                </div>
              </div>
              <div class="mx-2 p-3 col-right">
                <div class="row headingRow">
                  <div class="col-md-3">

                  </div>
                  <div class="col-md-4">
                    <span class="bookngHeadng"> MERA Booking</span>
                  </div>
                  <div class="col-md-5">
                    <span class="bookngHeadng"> Existing Booking</span>
                  </div>
                </div>
                <div class="row individaulRows">
                  <div class="col-md-3">
                    Start Date
                  </div>
                  <div class="col-md-4">
                    <span class="bokingData">{{meraBookingDetails.StartDate | date: defaultDateFormat}}</span>
                  </div>
                  <div class="col-md-5">
                    <span class="bokingData">{{exisitngBookingDetails.StartDate | date: defaultDateFormat}}</span>
                  </div>
                </div>
                <div class="row individaulRows">
                  <div class="col-md-3">
                    End Date
                  </div>
                  <div class="col-md-4">
                    <span class="bokingData">{{meraBookingDetails.EndDate | date: defaultDateFormat}}</span>
                  </div>
                  <div class="col-md-5">
                    <span class="bokingData">{{exisitngBookingDetails.EndDate | date: defaultDateFormat}}</span>
                  </div>
                </div>
                <div class="row individaulRows">
                  <div class="col-md-3">
                    Hours
                  </div>
                  <div class="col-md-4">
                    <span class="bokingData">{{meraBookingDetails.Hours}}</span>
                  </div>
                  <div class="col-md-5">
                    <span class="bokingData">{{exisitngBookingDetails.Hours}}</span>
                  </div>
                </div>
                <div class="row individaulRows">
                  <div class="col-md-3">
                    Loading%
                  </div>
                  <div class="col-md-4">
                    <span class="bokingData">{{meraBookingDetails.Loading}}</span>
                  </div>
                  <div class="col-md-5">
                    <span class="bokingData">{{exisitngBookingDetails.Loading}}</span>
                  </div>
                </div>
                <div class="row individaulRows">
                  <div class="col-md-3">
                    BookingType
                  </div>
                  <div class="col-md-4">
                    <span class="bokingData">{{meraBookingDetails.BookingType}}</span>
                  </div>
                  <div class="col-md-5">
                    <span class="bokingData">{{exisitngBookingDetails.BookingType}}</span>
                  </div>
                </div>
                <div class="row individaulRows">
                  <div class="col-md-3">
                    Unconfirmed
                  </div>
                  <div class="col-md-4">
                    <span class="bokingData">{{meraBookingDetails.Unconfirmed}}</span>
                  </div>
                  <div class="col-md-5">
                    <span class="bokingData"> </span>
                  </div>
                </div>
                <div class="row individaulRows">
                  <div class="col-md-3">
                    Ghost
                  </div>
                  <div class="col-md-4">
                    <span class="bokingData">{{meraBookingDetails.Ghost}}</span>
                  </div>
                  <div class="col-md-5">
                    <span class="bokingData">{{exisitngBookingDetails.Ghost}}</span>
                  </div>
                </div>
                <div class="row individaulRows">
                  <div class="col-md-3">
                    Retain Instance
                  </div>
                  <div class="col-md-4">
                    <span class="bokingData">{{meraBookingDetails.InstanceName}}</span>
                  </div>
                  <div class="col-md-5">
                    <span class="bokingData">{{exisitngBookingDetails.InstanceName}}</span>
                  </div>
                </div>
                <div class="row individaulRows">
                  <div class="col-md-3">
                    Status
                  </div>
                  <div class="col-md-4">
                    <span class="bokingData">{{meraBookingDetails.StatusDescription}}</span>
                  </div>
                  <div class="col-md-5">
                    <span class="bokingData">{{exisitngBookingDetails.StatusDescription}}</span>
                  </div>
                </div>
                <div class="row individaulRows">
                  <div class="col-md-3">
                    Resource
                  </div>
                  <div class="col-md-4 resourceName">
                    <span class="detailsLink" (click)="navigateIndividual(meraBookingDetails)"
                      [ngClass]="{'disableNavigation' : meraBookingDetails.IsDisableViewBooking_Res === true }"
                      [matTooltip]="meraBookingDetails.IsDisableViewBooking_Res !== true ? 
                        'Navigate to Individual View' : null">
                      {{ meraBookingDetails.ResourceName }}
                    </span>
                    <span class="badgeContainer">
                      <mat-icon matTooltip="View Resource details" class="badge-info"
                        (click)="showDetails('Res_Mera', selectedRow, templateDetails)">
                        info</mat-icon>
                    </span>
                  </div>
                  <div class="col-md-5 resourceName" *ngIf="exisitngBookingDetails?.ResourceName">
                    <span class="detailsLink" *ngIf="exisitngBookingDetails && exisitngBookingDetails.ResourceName"
                      (click)="navigateIndividual(exisitngBookingDetails)"
                      [ngClass]="{'disableNavigation' : exisitngBookingDetails.IsDisableViewBooking_Res === true }"
                      [matTooltip]="exisitngBookingDetails.IsDisableViewBooking_Res !== true ? 
                      'Navigate to Individual View' : null">
                      {{ exisitngBookingDetails.ResourceName }}
                    </span>
                    <span class="badgeContainer">
                      <mat-icon *ngIf="exisitngBookingDetails && exisitngBookingDetails.ResourceName"
                        matTooltip="View Resource details" class="badge-info"
                        (click)="showDetails('Res_Existng', selectedRow, templateDetails)">
                        info</mat-icon>
                    </span>
                  </div>
                </div>
                <div class="row individaulRows">
                  <div class="col-md-3">
                    Engagement
                  </div>
                  <div class="col-md-4 engagemntname">
                    <span class="detailsLink" (click)="navigateEngagement(meraBookingDetails)"
                      [ngClass]="{'disableNavigation': meraBookingDetails.IsDisabledViewBooking_Job === true }"
                      [matTooltip]="meraBookingDetails.IsDisabledViewBooking_Job !== true ? 'Navigate to Engagement View' : null">
                      {{ meraBookingDetails.JobDescription }}

                    </span>
                    <span class="badgeContainer">
                      <mat-icon matTooltip="View Engagement details" class="badge-info"
                        (click)="showDetails('Eng_Mera', selectedRow, templateDetails)">
                        info</mat-icon>
                    </span>
                  </div>
                  <div class="col-md-5 engagemntname">
                    <span *ngIf="exisitngBookingDetails && exisitngBookingDetails.JobDescription" class="detailsLink"
                      (click)="navigateEngagement(exisitngBookingDetails)"
                      [ngClass]="{'disableNavigation': exisitngBookingDetails.IsDisabledViewBooking_Job === true }"
                      [matTooltip]="exisitngBookingDetails.IsDisabledViewBooking_Job !== true ? 'Navigate to Engagement View' : null">
                      {{ exisitngBookingDetails.JobDescription }}

                    </span>
                    <span class="badgeContainer">
                      <mat-icon *ngIf="exisitngBookingDetails && exisitngBookingDetails.JobDescription"
                        matTooltip="View Engagement details" class="badge-info"
                        (click)="showDetails('Eng_Existng', selectedRow, templateDetails)">
                        info</mat-icon>
                    </span>
                  </div>
                </div>

              </div> -->
              <div class="mx-2 p-3 col-left">
                <span class="bookngHeadng">Booking Information</span>
                <div class="row">
                  <div class="col-md-6">
                    <span class="eachLabelStyle">Request ID</span><br>
                  </div>
                  <div class="col-md-6">
                    <span class="bokingData">{{ selectedRow?.RequestId }} <span *ngIf="overallocatnMsg"
                        class="badge badge-overallocation" title="This request will cause an overallocation">!</span>
                    </span>
                  </div>
                </div>
            
                <div class="row">
                  <div class="col-md-6">
                    <span class="eachLabelStyle"> Action ID</span><br>
                  </div>
                  <div class="col-md-6">
                    <span class="bokingData">{{ selectedRow?.MeraBookingId }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <span class="eachLabelStyle">Booking ID</span><br>
                  </div>
                  <div class="col-md-6">
                    <span class="bokingData">{{ selectedRow?.BookingId }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <span class="eachLabelStyle">Action</span><br>
                  </div>
                  <div class="col-md-6">
                    <span class="bokingData">{{ selectedRow?.ActionDescription }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <span class="eachLabelStyle">Requestor Name</span><br>
                  </div>
                  <div class="col-md-6">
                    <span class="bokingData">{{ selectedRow?.RequestorName }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <span class="eachLabelStyle">Requested On</span><br>
                  </div>
                  <div class="col-md-6">
                    <span class="bokingData">{{ selectedRow?.RequestedOn | date: defaultDateFormat}}</span>
                  </div>
                </div>
            
                <div class="row">
                  <div class="col-md-6">
                    <span class="eachLabelStyle">Total Scheduled Loading%</span><br>
                  </div>
                  <div class="col-md-6">
                    <span class="bokingData">{{ selectedRow?.TotalLoading }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <span class="eachLabelStyle">Scheduled Hours - Confirmed</span><br>
                  </div>
                  <div class="col-md-6">
                    <span class="bokingData">{{ selectedRow?.ScheduledHours }}</span>
                  </div>
                </div>
            
                <div class="row">
                  <div class="col-md-6">
                    <span class="eachLabelStyle">Scheduled Hours - Requests</span><br>
                  </div>
                  <div class="col-md-6">
                    <span class="bokingData">{{ selectedRow?.RequestedHours }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <span class="eachLabelStyle"> Scheduled Loading - Confirmed</span><br>
                  </div>
                  <div class="col-md-6">
                    <span class="bokingData">{{ selectedRow?.ScheduledLoading }}</span>
                  </div>
                </div>
            
                <div class="row">
                  <div class="col-md-6">
                    <span class="eachLabelStyle"> Scheduled Loading - Requests</span><br>
                  </div>
                  <div class="col-md-6">
                    <span class="bokingData">{{ selectedRow?.RequestedLoading }}</span>
                  </div>
                </div>
              </div>
              <div class="mx-2 p-3 col-right">
                <div class="row headingRow">
                  <div class="col-md-2">
            
                  </div>
                  <div class="col-md-5">
                    <span class="bookngHeadng"> MERA Booking</span>
                  </div>
                  <div class="col-md-5">
                    <span class="bookngHeadng"> Existing Booking</span>
                  </div>
                </div>
                <div class="row individaulRows">
                  <div class="col-md-2">
                    Start Date
                  </div>
                  <div class="col-md-5">
                    <span class="bokingData">{{meraBookingDetails.StartDate | date: defaultDateFormat}}</span>
                  </div>
                  <div class="col-md-5">
                    <span class="bokingData">{{exisitngBookingDetails.StartDate | date: defaultDateFormat}}</span>
                  </div>
                </div>
                <div class="row individaulRows">
                  <div class="col-md-2">
                    End Date
                  </div>
                  <div class="col-md-5">
                    <span class="bokingData">{{meraBookingDetails.EndDate | date: defaultDateFormat}}</span>
                  </div>
                  <div class="col-md-5">
                    <span class="bokingData">{{exisitngBookingDetails.EndDate | date: defaultDateFormat}}</span>
                  </div>
                </div>
                <div class="row individaulRows">
                  <div class="col-md-2">
                    Hours
                  </div>
                  <div class="col-md-5">
                    <span class="bokingData">{{meraBookingDetails.Hours}}</span>
                  </div>
                  <div class="col-md-5">
                    <span class="bokingData">{{exisitngBookingDetails.Hours}}</span>
                  </div>
                </div>
                <div class="row individaulRows">
                  <div class="col-md-2">
                    Loading%
                  </div>
                  <div class="col-md-5">
                    <span class="bokingData">{{meraBookingDetails.Loading}}</span>
                  </div>
                  <div class="col-md-5">
                    <span class="bokingData">{{exisitngBookingDetails.Loading}}</span>
                  </div>
                </div>
                <div class="row individaulRows">
                  <div class="col-md-2">
                    BookingType
                  </div>
                  <div class="col-md-5">
                    <span class="bokingData">{{meraBookingDetails.BookingType}}</span>
                  </div>
                  <div class="col-md-5">
                    <span class="bokingData">{{exisitngBookingDetails.BookingType}}</span>
                  </div>
                </div>
                <div class="row individaulRows">
                  <div class="col-md-2">
                    Unconfirmed
                  </div>
                  <div class="col-md-5">
                    <span class="bokingData">{{meraBookingDetails.Unconfirmed}}</span>
                  </div>
                  <div class="col-md-5">
                    <span class="bokingData"> </span>
                  </div>
                </div>
                <div class="row individaulRows">
                  <div class="col-md-2">
                    Ghost
                  </div>
                  <div class="col-md-5">
                    <span class="bokingData">{{meraBookingDetails.Ghost}}</span>
                  </div>
                  <div class="col-md-5">
                    <span class="bokingData">{{exisitngBookingDetails.Ghost}}</span>
                  </div>
                </div>
                <div class="row individaulRows">
                  <div class="col-md-2">
                    Retain Instance
                  </div>
                  <div class="col-md-5">
                    <span class="bokingData">{{meraBookingDetails.InstanceName}}</span>
                  </div>
                  <div class="col-md-5">
                    <span class="bokingData">{{exisitngBookingDetails.InstanceName}}</span>
                  </div>
                </div>
                <div class="row individaulRows">
                  <div class="col-md-2">
                    Status
                  </div>
                  <div class="col-md-5">
                    <span class="bokingData">{{meraBookingDetails.StatusDescription}}</span>
                  </div>
                  <div class="col-md-5">
                    <span class="bokingData">{{exisitngBookingDetails.StatusDescription}}</span>
                  </div>
                </div>
                <div class="row individaulRows">
                  <div class="col-md-2">
                    Resource
                  </div>
                  <div class="col-md-5 resourceName">
                    <!-- <span class="bokingData">{{meraBookingDetails.ResourceName}}</span>
                               <mat-icon matTooltip="View Resource details" class="badge-info"
                                  (click)="showDetails('Res_Mera', selectedRow, templateDetails)">
                                  info</mat-icon> -->
                    <span class="detailsLink" (click)="navigateIndividual(meraBookingDetails)"
                      [ngClass]="{'disableNavigation' : meraBookingDetails.IsDisableViewBooking_Res === true }" [matTooltip]="meraBookingDetails.IsDisableViewBooking_Res !== true ? 
                                    'Navigate to Individual View' : null">
                      {{ meraBookingDetails.ResourceName }}
                    </span>
                    <span class="badgeContainer">
                      <mat-icon matTooltip="View Resource details" class="badge-info"
                        (click)="showDetails('Res_Mera', selectedRow, templateDetails)">
                        info</mat-icon>
                    </span>
                  </div>
                  <div class="col-md-5 resourceName" *ngIf="exisitngBookingDetails?.ResourceName">
                    <!-- <span class="bokingData">{{exisitngBookingDetails.ResourceName}}</span>
                               <mat-icon *ngIf="exisitngBookingDetails && exisitngBookingDetails.ResourceName"
                               matTooltip="View Resource details" class="badge-info"
                               (click)="showDetails('Res_Existng', selectedRow, templateDetails)">
                                  info</mat-icon> -->
                    <span class="detailsLink" *ngIf="exisitngBookingDetails && exisitngBookingDetails.ResourceName"
                      (click)="navigateIndividual(exisitngBookingDetails)"
                      [ngClass]="{'disableNavigation' : exisitngBookingDetails.IsDisableViewBooking_Res === true }" [matTooltip]="exisitngBookingDetails.IsDisableViewBooking_Res !== true ? 
                                  'Navigate to Individual View' : null">
                      {{ exisitngBookingDetails.ResourceName }}
                    </span>
                    <span class="badgeContainer">
                      <mat-icon *ngIf="exisitngBookingDetails && exisitngBookingDetails.ResourceName"
                        matTooltip="View Resource details" class="badge-info"
                        (click)="showDetails('Res_Existng', selectedRow, templateDetails)">
                        info</mat-icon>
                    </span>
                  </div>
                </div>
                <div class="row individaulRows">
                  <div class="col-md-2">
                    Engagement
                  </div>
                  <div class="col-md-5 engagemntname">
                    <!-- <span class="bokingData">{{meraBookingDetails.JobDescription}}</span>                   
                               <mat-icon matTooltip="View Engagement details" class="badge-info"
                               (click)="showDetails('Eng_Mera', selectedRow, templateDetails)">
                                  info</mat-icon> -->
                    <span class="detailsLink" (click)="navigateEngagement(meraBookingDetails)"
                      [ngClass]="{'disableNavigation': meraBookingDetails.IsDisabledViewBooking_Job === true }"
                      [matTooltip]="meraBookingDetails.IsDisabledViewBooking_Job !== true ? 'Navigate to Engagement View' : null">
                      {{ meraBookingDetails.JobDescription }}
            
                    </span>
                    <span class="badgeContainer">
                      <mat-icon matTooltip="View Engagement details" class="badge-info"
                        (click)="showDetails('Eng_Mera', selectedRow, templateDetails)">
                        info</mat-icon>
                    </span>
                  </div>
                  <div class="col-md-5 engagemntname">
                    <!-- <span class="bokingData">{{exisitngBookingDetails.JobDescription}}</span>
                               <mat-icon *ngIf="exisitngBookingDetails && exisitngBookingDetails.JobDescription"
                               matTooltip="View Engagement details" class="badge-info"
                               (click)="showDetails('Eng_Existng', selectedRow, templateDetails)">
                                  info</mat-icon> -->
                    <span *ngIf="exisitngBookingDetails && exisitngBookingDetails.JobDescription" class="detailsLink"
                      (click)="navigateEngagement(exisitngBookingDetails)"
                      [ngClass]="{'disableNavigation': exisitngBookingDetails.IsDisabledViewBooking_Job === true }"
                      [matTooltip]="exisitngBookingDetails.IsDisabledViewBooking_Job !== true ? 'Navigate to Engagement View' : null">
                      {{ exisitngBookingDetails.JobDescription }}
            
                    </span>
                    <span class="badgeContainer">
                      <mat-icon *ngIf="exisitngBookingDetails && exisitngBookingDetails.JobDescription"
                        matTooltip="View Engagement details" class="badge-info"
                        (click)="showDetails('Eng_Existng', selectedRow, templateDetails)">
                        info</mat-icon>
                    </span>
                  </div>
                </div>
            
                <div class="row individaulRows">
                  <div class="col-md-2">
                    Notes Description
                  </div>
                  <div class="col-md-5">
                    <span class="bokingData">{{meraBookingDetails.Description}}
                      <!-- <span  *ngIf ="exisitngBookingDetails.DescriptionLen > 250" (click)="showFullDescription('merabooking')"  
                        style="color: #ffe600; cursor: pointer;" >More</span> -->
                    </span>
                  </div>
                  <div class="col-md-5">
                    <span class="bokingData">{{exisitngBookingDetails.Description}}
                      <!-- <span *ngIf ="exisitngBookingDetails.DescriptionLen > 250" (click)="showFullDescription('existingbooking')"  
                        style="color: #ffe600;cursor: pointer;" >More</span> -->
                    </span>
                  </div>
                </div>
            
              </div>
            </div>
          </div>
          <div class="actionItems">
            <div class="col-lg-4 multiPleActionText" *ngIf="multipleActnFlg && apprRejDisbleflg">
              <span class="mandtoryIcn">* </span>Selected Request has Multiple Action IDs and Approve/Reject will be
              applied to all action IDs of this request
            </div>
            <div class="col-lg-4 multiPleActionText" *ngIf="!apprRejDisbleflg">
              <span class="mandtoryIcn">* </span>Selected Request is Already Approved/Rejected by Others.
            </div>
            <button type="button" class="btn prev_nextBtn" (click)="showPrev(selectedIndex)"
              [ngClass]="selectedIndex > 0 == true ? 'enableBtn' : 'disablBtn'">
              Previous
            </button>
            <button type="button" class="btn apprv_rejeBtn" (click)="approvebookingReq(approveRejectComments_aditnl)"
              [ngClass]="apprRejDisbleflg? 'enableBtn' : 'disablBtn'">
              {{approveText}}
            </button>
            <button type="button" class="btn apprv_rejeBtn" (click)="rejectbookingReq(approveRejectComments_aditnl)"
              [ngClass]="apprRejDisbleflg? 'enableBtn' : 'disablBtn'">
              {{rejectText}}
            </button>
            <button type="button" class="btn prev_nextBtn"
              [ngClass]="(selectedIndex == (dataSourceApproval?.data?.length -1))? 'disablBtn' : 'enableBtn'"
              (click)="showNext(selectedIndex)">
              Next
            </button>
          </div>
          <!-- <p >
            <button *ngIf="selectedIndex > 0" (click)="showPrev(selectedIndex)">
              Prev
            </button>
            <button
              *ngIf="
                selectedIndex < (highestIndex ? highestIndex - 1 : pageSize - 1)
              "
              (click)="showNext(selectedIndex)"
            >
              Next
            </button>
          </p> -->
        </mat-tab>
      </mat-tab-group>

      <!-- <div class="individualRequest">
                each request
            </div> -->
    </div>
  </div>
</div>

<ng-template #GridActions>
  <ngx-spinner></ngx-spinner>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ gridActionModalHeader }}</h4>
    <mat-icon class="close" (click)="openConfirmationModal(EditConfirmation)">close</mat-icon>
  </div>
  <div class="modal-body">
    <app-add-configuration *ngIf="showModalConfig"></app-add-configuration>
  </div>
</ng-template>
<ng-template #EditConfirmation>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Confirm</h4>
  </div>
  <div class="modal-body">
    <div class="popupLayout">
      <div class="fullWidth">
        <div class="confirmationText">
          All the changes will be lost. Are you sure you want to close?<span></span>
        </div>
      </div>

      <div class="confirmationButtons">
        <button class="cancelBtn" (click)="this.confirmModalRef.hide()">
          Cancel
        </button>
        <button class="deleteBtn" (click)="okayConfirm()">Yes</button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #templateDetails>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ popupDetail }} Details</h4>
    <mat-icon (click)="detailsModalRef.hide()" class="close">close</mat-icon>
  </div>
  <div class="modal-body">
    <div class="popupLayout">
      <table class="detailsTable" *ngIf="popupDetail === 'Resource'">
        <tr>
          <th>
            <div class="tableHead">Resource Fields</div>
          </th>
          <th>
            <div class="tableHead">Resource Values</div>
          </th>
        </tr>
        <tr>
          <td class="labelColumn">Resource Name</td>
          <td>{{ resDetail.ResourceName }}</td>
        </tr>
        <tr>
          <td class="labelColumn">Rank</td>
          <td>{{ resDetail.RankDescription }}</td>
        </tr>
        <tr>
          <td class="labelColumn">Resource Status</td>
          <td>{{ resDetail.ResourceStatus }}</td>
        </tr>
        <tr>
          <td class="labelColumn">Resource Type</td>
          <td>{{ resDetail.ResourceType }}</td>
        </tr>
        <tr>
          <td class="labelColumn">Service Line</td>
          <td>{{ resDetail.SLDescription }}</td>
        </tr>
        <tr>
          <td class="labelColumn">Sub-Service Line</td>
          <td>{{ resDetail.SSLDescription }}</td>
        </tr>
        <tr>
          <td class="labelColumn">Sub-Area</td>
          <td>{{ resDetail.SubAreaDescription }}</td>
        </tr>
        <tr>
          <td class="labelColumn">Managerial Country</td>
          <td>{{ resDetail.ManagerialCountryCode }}</td>
        </tr>
        <tr>
          <td class="labelColumn">Legal Entity</td>
          <td>{{ resDetail.LegalEntityCode }}</td>
        </tr>
        <tr>
          <td class="labelColumn">Business Unit</td>
          <td>{{ resDetail.BusinessUnitDescription }}</td>
        </tr>
        <tr>
          <td class="labelColumn">Sub-Management Unit</td>
          <td>{{ resDetail.SubManagementUnitDescription }}</td>
        </tr>
        <tr>
          <td class="labelColumn">HR Department</td>
          <td>{{ resDetail.DepartmentDescription }}</td>
        </tr>
        <tr>
          <td class="labelColumn">Cost Center</td>
          <td>{{ resDetail.CostCenterDescription }}</td>
        </tr>
        <tr>
          <td class="labelColumn">GDS Classification</td>
          <td>{{ resDetail.GDSRegionAllignment }}</td>
        </tr>
      </table>
      <table class="detailsTable" *ngIf="popupDetail === 'Engagement'">
        <tr>
          <th>
            <div class="tableHead">Engagement Fields</div>
          </th>
          <th>
            <div class="tableHead">Engagement Values</div>
          </th>
        </tr>
        <tr>
          <td class="labelColumn">Engagement Name</td>
          <td>{{ jobDetail.JobDescription }}</td>
        </tr>
        <tr>
          <td class="labelColumn">Engagement Category</td>
          <td>{{ jobDetail.JobCategory }}</td>
        </tr>
        <tr>
          <td class="labelColumn">Engagement Type</td>
          <td>{{ jobDetail.JobType }}</td>
        </tr>
        <tr>
          <td class="labelColumn">Engagement Status</td>
          <td>{{ jobDetail.JobStatus }}</td>
        </tr>
        <tr>
          <td class="labelColumn">Service Line</td>
          <td>{{ jobDetail.ServiceLineDescription }}</td>
        </tr>
        <tr>
          <td class="labelColumn">Sub-Service Line</td>
          <td>{{ jobDetail.SSLDescription }}</td>
        </tr>
        <tr>
          <td class="labelColumn">Sub-Area</td>
          <td>{{ jobDetail.SubAreaDescription }}</td>
        </tr>
        <tr>
          <td class="labelColumn">Managerial Country</td>
          <td>{{ jobDetail.ManagerialCountryCode }}</td>
        </tr>
        <tr>
          <td class="labelColumn">Legal Entity</td>
          <td>{{ jobDetail.LegalEntityCode }}</td>
        </tr>
        <tr>
          <td class="labelColumn">Business Unit</td>
          <td>{{ jobDetail.BusinessUnitDescription }}</td>
        </tr>
        <tr>
          <td class="labelColumn">Sub-Management Unit</td>
          <td>{{ jobDetail.SubManagementUnitDescription }}</td>
        </tr>
        <tr>
          <td class="labelColumn">Engagement Partner</td>
          <td>{{ jobDetail.PartnerName }}</td>
        </tr>
        <tr>
          <td class="labelColumn">Engagement Manager</td>
          <td>{{ jobDetail.ManagerNameAndGPN }}</td>
        </tr>
      </table>
    </div>
  </div>
</ng-template>
<ng-template #approveRejectComments>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{approveRejectCommentsTitle}}</h4>
    <mat-icon (click)="modalRef.hide();approveRejectComents.setValue('')" class="close">close</mat-icon>
  </div>
  <div class="modal-body">
    <div class="popupLayout">
      <div class="fullWidth">
        <div class="confirmationText">
          <span class="rejctCmnts">{{approveRejectconfirmationText}} <span style="color:red;"> *</span></span>
          <textarea id="name" class="rejctCmnts" rows="4" cols="50" [formControl]="approveRejectComents"
            maxlength="800">
          </textarea>
        </div>
      </div>

      <div class="confirmationButtons">
        <button type='button' class="deleteBtn" (click)="approveRejectbookingReq()"
          [disabled]="!approveRejectComents.valid">Save</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #approveRejectComments_aditnl>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{approveRejectCommentsTitle}}</h4>
    <mat-icon (click)="modalRef.hide();approveRejectComents.setValue('')" class="close">close</mat-icon>
  </div>
  <div class="modal-body">
    <div class="popupLayout">
      <div class="fullWidth">
        <div class="confirmationText">
          <span class="rejctCmnts">{{approveRejectconfirmationText}} <span style="color:red;"> *</span></span>
          <textarea id="name" class="rejctCmnts" rows="4" cols="50" [formControl]="approveRejectComents" maxlength="50">
          </textarea>
        </div>
      </div>

      <div class="confirmationButtons">
        <button type='button' class="deleteBtn" (click)="approveRejectReq_aditnl()"
          [disabled]="!approveRejectComents.valid">Save</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #successMesgTemplte>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Success</h4>
  </div>
  <div class="modal-body">
    <div class="popupLayout">
      <div class="fullWidth">
        <div class="confirmationText">
          <span style="color:white;">{{succesMsg_apprveRej}}</span>
        </div>
      </div>

    </div>
  </div>
</ng-template>

<ng-template #GridFilter>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Filter</h4>
    <mat-icon class="close" (click)="closeFilterModal()">close</mat-icon>
  </div>
  <div class="modal-body">
    <div class="popupLayout">
      <div class="fullWidth">
        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input [formControl]="requestIdFilter" autocomplete="off" matInput type="text" placeholder="Request ID"
              maxlength="150" />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="RequestId">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #RequestId="matMenu">
            <button mat-menu-item (click)="filterValues.requestIdType = true">
              <div [ngStyle]="{
                  color: filterValues.requestIdType === true ? '#ffe600' : '#fff'
                }">
                Contains
              </div>
            </button>
            <button mat-menu-item (click)="filterValues.requestIdType = false">
              <div [ngStyle]="{
                  color: filterValues.requestIdType === false ? '#ffe600' : '#fff'
                }">
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>

        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input [formControl]="actionIdFilter" autocomplete="off" matInput type="text" placeholder="Action ID"
              maxlength="150" />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="ActionID">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #ActionID="matMenu">
            <button mat-menu-item (click)="filterValues.actionType = true">
              <div [ngStyle]="{
                  color: filterValues.actionType === true ? '#ffe600' : '#fff'
                }">
                Contains
              </div>
            </button>
            <button mat-menu-item (click)="filterValues.actionType = false">
              <div [ngStyle]="{
                  color: filterValues.actionType === false ? '#ffe600' : '#fff'
                }">
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>

        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input [formControl]="resourcesFilter" autocomplete="off" matInput type="text" placeholder="Resources"
              maxlength="150" />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="ResourceName">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #ResourceName="matMenu">
            <button mat-menu-item (click)="filterValues.resourceNameType = true">
              <div [ngStyle]="{
                  color: filterValues.resourceNameType === true ? '#ffe600' : '#fff'
                }">
                Contains
              </div>
            </button>
            <button mat-menu-item (click)="filterValues.resourceNameType = false">
              <div [ngStyle]="{
                  color: filterValues.resourceNameType === false ? '#ffe600' : '#fff'
                }">
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>

        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input [formControl]="engagementFilter" autocomplete="off" matInput type="text" placeholder="Engagement"
              maxlength="150" />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="JobDescription">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #JobDescription="matMenu">
            <button mat-menu-item (click)="filterValues.engagementType = true">
              <div [ngStyle]="{
                  color: filterValues.engagementType === true ? '#ffe600' : '#fff'
                }">
                Contains
              </div>
            </button>
            <button mat-menu-item (click)="filterValues.engagementType = false">
              <div [ngStyle]="{
                  color: filterValues.engagementType === false ? '#ffe600' : '#fff'
                }">
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>

        <div class="filterContainer">
          <mat-form-field appearance="outline">
            <input [formControl]="ageingFilter" autocomplete="off" matInput type="text" placeholder="Ageing (in days)"
              maxlength="150" />
          </mat-form-field>
          <button mat-icon-button [matMenuTriggerFor]="Age">
            <mat-icon class="filterIcon">filter_alt</mat-icon>
          </button>
          <mat-menu #Age="matMenu">
            <button mat-menu-item (click)="filterValues.ageingType = true">
              <div [ngStyle]="{
                  color: filterValues.ageingType === true ? '#ffe600' : '#fff'
                }">
                Contains
              </div>
            </button>
            <button mat-menu-item (click)="filterValues.ageingType = false">
              <div [ngStyle]="{
                  color: filterValues.ageingType === false ? '#ffe600' : '#fff'
                }">
                Starts with
              </div>
            </button>
          </mat-menu>
        </div>
      </div>
      <div class="confirmationButtons">
        <button class="cancelBtn" (click)="closeFilterModal()">Cancel</button>
        <button class="deleteBtn" (click)="filterGrid()">Filter</button>
      </div>
    </div>
  </div>
</ng-template>
