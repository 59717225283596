import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewEncapsulation,
  EventEmitter,
  Output,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { EngagementService } from '../engagement.service';
import { EngagementBookingVM } from '../../../Models/EngagementBookingVM';
import { ModalOptions, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BookingGridActionService } from '../../../Models/BookingGridActionService';
import { NavigationExtras, Router } from '@angular/router';
import { SharedServicesService } from '../../../shared/sharedservices/shared-services.service';
import { CurrentWeekActionDTO } from '../../../Models/CurrentWeekActionDTO';


@Component({
  selector: 'app-engagement-booking-child',
  templateUrl: './engagement-booking-child.component.html',
  styleUrls: ['./engagement-booking-child.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EngagementBookingChildComponent implements OnInit {
  @Input() public bookingData: EngagementBookingVM;
  @Input() currentWeekActionDTO: CurrentWeekActionDTO;

  displayedColumns: string[] = [
    'ResourceName',
    'ServiceLine',
    'OfficeLocation',
    'Rank',
    'Hours',
    'Loading',
    'StartDate',
    'EndDate',
    'Actions',
  ];
  //public view: Observable<any>;
  user;
  public skip = 0;
  bookingListSubscription: Subscription;
  bookingList: EngagementBookingVM[];
  modalOption: ModalOptions = {};
  showModal = false;
  showModalAdd = false;
  showModalSplit = false;
  showModalDelete = false;
  showModalCancel = false;
  showModelSwap = false;
  gridActionModalHeader = '';
  modalRef: BsModalRef;
  confirmModalRef: BsModalRef;
  gridActionEventData: BookingGridActionService;
  format = '#.# \\%';
  showIcon: any;
  loading = true;
  @Input() isPercentageSelected;
  @Input() selectedInstanceId;
  defaultDateFormat: string;
  @Output() updatePreviewCount = new EventEmitter();
  editDelete: number;
  updateCount(): void {
    this.updatePreviewCount.next();
  }
  @Output()
  GetAllBookings = new EventEmitter();

  constructor(
    public restService: EngagementService,
    private modalService: BsModalService,
    public sharedService: SharedServicesService,
    private router: Router
  ) {
    //this.view = this.restService;
    this.bookingList = [];
  }

  ngOnInit() {
    this.bookingList = [];
    // this.getCurrentWeek();
    this.editDelete = this.currentWeekActionDTO.IsDisabledDeleteBookingCurrWeek ? 1 : 0
    // this.showIcon = +sessionStorage.getItem('ShowActionIcon');
    // if (this.showIcon === 1) this.showActionIcon = true; else this.showActionIcon = false;
    this.user = JSON.parse(sessionStorage.getItem('resource'));
    //removed multiinstance from child
    // if (this.user?.IsMultiInstance) {
    //   this.displayedColumns.splice(this.displayedColumns.indexOf('Rank') + 1, 0, 'Instance');
    // }
    // if(this.user.Ghost){
    //   this.displayedColumns.splice(this.displayedColumns.indexOf('EndDate')+1, 0, 'Ghost');
    // }
    this.defaultDateFormat = this.sharedService.getDateFormat(this.user.DefaultDateFormat);
    this.getBookingList();
    this.restService.editEvent.subscribe((data) => {
      this.gridActionEventData = data;
    });
    this.restService.currentMessageCloseChildModal.subscribe(
      (closeChildModal) => {
        this.closeActionModal(closeChildModal);
      }
    );
    this.restService.currentMessageEngagementAllocation.subscribe(
      (isPercentageSelected) => {
        this.setEngagementAllocation(isPercentageSelected);
      }
    );
    this.restService.currentMessageCloseModal.subscribe((closeModal) => {
      this.closeActionModal(closeModal);
    });
  }

  public rowsToHighlight: any[] = [];
  /* public isRowSelected = (e: RowArgs) =>
    this.rowsToHighlight.indexOf(e.dataItem.HighlightStatus) >= 0; */

  getBookingList() {
    this.loading = true;
    this.bookingData.IsPercentSelected = this.isPercentageSelected;
    this.bookingData.LoggedInResourceID = +sessionStorage.getItem(
      'loggedin_resourceid'
    );
    this.bookingData.InstanceId = this.selectedInstanceId;

    this.bookingListSubscription = this.restService
      .fetchBookingChildData(this.bookingData)
      .subscribe((data) => {
        this.bookingList = data;
        // this.bookingList?.forEach((element) => {
        //   (element.StartDate = new Date(element.StartDate)),
        //     (element.EndDate = new Date(element.EndDate));

        // });
        this.loading = false;
      });
  }

  setEngagementAllocation(isPercentageSelected) {
    if (isPercentageSelected) {
      const indexOfHour = this.displayedColumns.indexOf('Hours', 0);
      if (indexOfHour > -1) {
        this.displayedColumns.splice(indexOfHour, 1);
      }
      const indexOfPercentage = this.displayedColumns.indexOf('Loading', 0);
      if (indexOfPercentage === -1) {
        this.displayedColumns.splice(this.displayedColumns.indexOf('StartDate') - 1, 0, 'Loading');
      }
    } else {
      const indexOfHour = this.displayedColumns.indexOf('Hours', 0);
      if (indexOfHour === -1) {
        this.displayedColumns.splice(this.displayedColumns.indexOf('StartDate') - 1, 0, 'Hours');
      }
      const indexOfPercentage = this.displayedColumns.indexOf('Loading', 0);
      if (indexOfPercentage > -1) {
        this.displayedColumns.splice(indexOfPercentage, 1);
      }
    }
  }

  public isDirtyFlag = false;

  makeDirty(data) {
    this.isDirtyFlag = data;
  }

  openConfirmationModal(template) {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;

    if (!this.isDirtyFlag || this.showModalDelete) {
      if (this.modalRef !== undefined && this.modalRef !== null) {
        this.modalRef.hide();
      }
    } else {
      this.confirmModalRef = this.modalService.show(template, this.modalOption);
      this.isDirtyFlag = false;
    }
    // this.modalOption.backdrop = 'static';
    // this.modalOption.keyboard = false;
    // if (this.showModalDelete) {
    //   this.modalRef.hide();
    // } else {
    //   this.confirmModalRef = this.modalService.show(template, this.modalOption);
    // }
  }

  // getCurrentWeek() {
  //   this.restService.getCurrentWeek().subscribe(
  //     (data: any) => {
  //       this.editDelete = data.DeleteWeek;
  //     });
  // }

  openActionModal(template, event, dataItem, templateName) {
    this.showModal = false;
    this.showModalAdd = false;
    this.showModalSplit = false;
    this.showModalDelete = false;
    this.showModalCancel = false;
    this.showModelSwap = false;
    this.gridActionEventData.dataItem = dataItem;
    this.gridActionEventData.event = event;
    this.gridActionEventData.template = template;
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;

    if (templateName === 'edit') {
      this.gridActionModalHeader = 'Edit Booking';
      this.showModal = true;
      this.restService.openEditModal(this.gridActionEventData);
    } else if (templateName === 'swap') {
      this.gridActionModalHeader = 'Replace Booking';
      this.showModelSwap = true;
      this.restService.openSwapModel(this.gridActionEventData);
    } else if (templateName === 'add') {
      this.gridActionModalHeader = 'New Booking';
      this.showModalAdd = true;
      this.restService.openAddModal(this.gridActionEventData);
    } else if (templateName === 'split') {
      this.gridActionModalHeader = 'Copy Booking';
      this.showModalSplit = true;
      this.restService.openSplitModal(this.gridActionEventData);
    } else if (templateName === 'delete') {
      this.gridActionModalHeader = 'Delete Booking';
      this.showModalDelete = true;
      this.restService.openDeleteModal(this.gridActionEventData);
    }
    else if (templateName === 'cancelAction') {
      this.gridActionModalHeader = 'Cancel Action Request';
      this.showModalCancel = true;
    }
    this.modalRef = this.modalService.show(template, this.modalOption);
  }

  closeActionModal(closeModal) {
    if (closeModal) {
      if (this.modalRef !== undefined && this.modalRef !== null) {
        this.modalRef.hide();
        // this.getBookingList();
        // this.updateCount();
        this.GetAllBookings.emit();
      }
    } else {
      if (this.modalRef !== undefined && this.modalRef !== null) {
        this.modalRef.hide();
      }

    }
  }

  okayConfirm(template) {
    this.confirmModalRef.hide();
    if (this.modalRef !== undefined && this.modalRef !== null) {
      this.modalRef.hide();
    }
  }

  ngOnDestroy() {
    if (this.bookingListSubscription !== undefined) {
      this.bookingListSubscription.unsubscribe();
    }
  }

  navigateIndividual(item) {
    if (this.bookingData.IsDisableViewBooking_Res) {
      return;
    }
    const product = {
      individual: item.ResourceName,
      instanceId: item.InstanceId,
      resourceId: item.ResourceId
    }
    this.router.navigateByUrl('/IndividualBooking', { state: product });
  }
  selectedItem: any;
  selectItem(item) {
    this.selectedItem = item.TooltipData;
  }
  previousPopover: any;
  closePrevious(currentPop) {
    if (this.previousPopover && this.previousPopover != currentPop) {
      this.previousPopover.hide();

    }
    this.previousPopover = currentPop;
  }
  closeCurrent(pop) {
    pop.hide();
  }
}
