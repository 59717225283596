import { AssignmentConfigurationDto } from "./AssignmentConfigurationDto";
import { ResourceEditConfigurationDto, JobEditConfigurationDto } from "./EditConfigurationVM";
import { ResourceViewConfigurationDto, JobViewConfigurationDto } from "./ViewConfigurationVM";
import { BookingConfigurationDto } from "./BookingConfigurationDto";
import { ResourceDto } from "./ResourceDto";
import { JobAddConfigurationDto, ResourceAddConfigurationDto } from "./AddConfigurationVM";
import { JobDeleteConfigurationDto, ResourceDeleteConfigurationDto } from "./DeleteConfigurationVM";
import { JobReplaceConfigurationDto, ResourceReplaceConfigurationDto } from "./ReplaceConfigurationVM";
import { JobReplaceAllConfigurationDto, ResourceReplaceAllConfigurationDto } from "./ReplaceAllConfigurationVM";
import { RoleApprovalConfigurationVM } from "./RoleApprovalConfigurationVM";
import { FeatureLevelConfigVM } from "./FeatureLevelConfigVM";

export class RoleVM {

    R_ID: number;
    R_RT_ID: number;
    RoleTypeName: string;
    RoleTypeDisplayName: string;
    RoleTypePriority: number;
    R_Name: string;
    R_View: boolean;
    R_Delegate: boolean;
    R_CreatedBy: number;
    R_ModifiedBy: number;
    R_Comments: string;
    R_IsActive: boolean;
    R_Priority: number;
    R_RELinkConfig:boolean;
    R_RADLinkConfig:boolean;
    R_RVLinkConfig:boolean;
    R_JELinkConfig:boolean;
    R_JADLinkConfig:boolean;
    R_JVLinkConfig:boolean;
    R_RALinkConfig:boolean;
    R_CoWorker:boolean;
    R_MUltipleInstance:boolean;
    R_OverAllocation:number;
    R_Unconfirmed:boolean;
    R_I_ID:number|null;
    R_IsReqdApproval:boolean;
    R_InstEnable_IV:boolean;
    R_InstEnable_EV:boolean;
    R_InstEnable_TV:boolean;
    R_Ghost:boolean;
    R_PreviewSubmit:boolean;
    R_InstEnable_AddBooking:boolean;
    R_Home_InstanceID:number|null;
    
    AssignmentConfigurationDto:AssignmentConfigurationDto;
    ResourceAddConfigurationDto:ResourceAddConfigurationDto;
    ResourceDeleteConfigurationDto:ResourceDeleteConfigurationDto;
    ResourceReplaceConfigurationDto:ResourceReplaceConfigurationDto;
    ResourceReplaceAllConfigurationDto:ResourceReplaceAllConfigurationDto;
    ResourceEditConfigurationDto:ResourceEditConfigurationDto;
    JobAddConfigurationDto:JobAddConfigurationDto;
    JobDeleteConfigurationDto:JobDeleteConfigurationDto;
    JobReplaceConfigurationDto:JobReplaceConfigurationDto;
    JobReplaceAllConfigurationDto:JobReplaceAllConfigurationDto;
    JobEditConfigurationDto:JobEditConfigurationDto;
    ResourceViewConfigurationDto:ResourceViewConfigurationDto;
    JobViewConfigurationDto:JobViewConfigurationDto;
    BookingConfigurationDto:BookingConfigurationDto;
    RoleApprovalConfigurationDtos: RoleApprovalConfigurationVM[];
    RoleFeatureLevelConfigurationDtos: FeatureLevelConfigVM[];
    ResourceDtos: ResourceDto[];
    Message:string="";
}
