<div class="appLayout privacyNotice">  
  <ngx-spinner></ngx-spinner>

  <tabset *ngIf="privacyNoticeVMs && privacyNoticeVMs.length > 1" #privacyNoteTabset>
    <ng-container *ngFor="let privacyNote of privacyNoticeVMs; let privacyNoteIndex = index;">
      <tab [heading]="privacyNote.Language">
        <div [innerHTML]='privacyNote.Content | safeHtml' class="pt-10"></div>
        <div>
          <input type="checkbox" (change)="changeAcknowledgeBtnState()"/>
          <span class="pl-5">{{ privacyNote.Terms }}</span>
        </div>
        <div>
          <button class="ackBtn" [disabled]="disableButton" 
            (click)="changePrivacyNoticeStatus()">{{ privacyNote.Button }}
          </button>
        </div>
      </tab>
    </ng-container>
  </tabset>

  <div *ngIf="privacyNoticeVMs && privacyNoticeVMs.length == 1">
    <ng-container *ngFor="let privacyNote of privacyNoticeVMs; let privacyNoteIndex = index;">
      <div [innerHTML]='privacyNote.Content | safeHtml' class="pt-10"></div>
      <div>
        <input type="checkbox" (change)="changeAcknowledgeBtnState()"/>
        <span class="pl-5">{{ privacyNote.Terms }}</span>
      </div>
      <div>
        <button class="ackBtn" [disabled]="disableButton" 
          (click)="changePrivacyNoticeStatus()">{{ privacyNote.Button }}
        </button>
      </div>
    </ng-container>
  </div>

</div>
