import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  FormBuilder,
} from '@angular/forms';
import { BookingGridActionService } from 'app/Models/BookingGridActionService';
import { JobVM } from 'app/Models/JobVM';
import { OrderPipe } from 'ngx-order-pipe';
import { Subscription, Observable } from 'rxjs';
import { EngagementService } from '../engagement.service';
import { Response } from 'app/Models/Response';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import { ResourceDto } from 'app/Models/ResourceDto';
import { EngagementBookingVM } from 'app/Models/EngagementBookingVM';
import { BsModalRef, ModalOptions, BsModalService } from 'ngx-bootstrap/modal';
import { ResourceBookingVM } from 'app/Models/ResourceBookingVM';
import moment from 'moment';
import { IndividualService } from '../../individual/individual.service';
import { UserVM } from 'app/Models/UserVM';
import { SharedServicesService } from 'app/shared/sharedservices/shared-services.service';
import { UiViewSourceEnum } from 'app/shared/enum';
import { CurrentWeekActionDTO } from '../../../Models/CurrentWeekActionDTO';

@Component({
  selector: 'app-engagement-add',
  templateUrl: './engagement-add.component.html',
  styleUrls: ['./engagement-add.component.scss'],
})
export class EngagementAddComponent implements OnInit {
  @Input() selectedInstanceId: number;
  @Input() roleDelgtFlg: boolean;
  @Input() currentWeekActionDTO: CurrentWeekActionDTO;

  isConfirmFlag: boolean;
  isPercentageSelected: boolean = false;
  displayedColumns: string[] = [
    'ResourceName',
    'Rank',
    'StartDate',
    'EndDate',
    'Hours',
    'Loading',
    'Unconfirmed',
    'Ghost',
    'Actions',
  ];
  overallocationerror_1: boolean = true;
  overallocationerror_2: boolean = true;
  overallocationerror_3: boolean = true;
  overallocationerror_4: boolean = true;
  overallocationerror_5: boolean = true;
  isHoursValid: boolean = true;
  isHoursValid_2: boolean = true;
  isHoursValid_3: boolean = true;
  isHoursValid_4: boolean = true;
  isHoursValid_5: boolean = true;
  showSearchList: boolean = false;
  showSearchLoader: boolean = false;
  colorTheme = 'theme-dark-blue';
  bsConfig: Partial<BsDatepickerConfig>;
  bookingType = '';
  engagementCode = '';
  Engagement = '';
  engagementAllocationText = '';
  engagementAllocationTypeValue: any;
  description = '';
  loading = '';
  autocompleteResetvalue = '';
  placeHolderText = 'Search Resource...';
  selectedItem: any = '';
  startDate: Date = null;
  endDate: Date = null;
  bsRangeValue: Date[];
  hours: number;
  bookingId: number;
  resourceId: number;
  jobId: number;
  unconfirmed: boolean;
  engagementAllocationPercentSelected = false;
  closeModalWindow = false;
  //formGroup: FormGroup;
  booking: EngagementBookingVM;
  eventData: BookingGridActionService;
  response: Response;
  engagementListFiltered: JobVM[];
  engagementList: JobVM[];
  sortedEngagementList: any[];
  addBookingSubscription: Subscription;
  searchBookingSubscription: Subscription;
  inputChanged: any;
  config: any;
  validEngagement = true;
  engagementfound = true;
  engagementStatus = '';
  engagementServiceLine = '';
  EngagementResourceName: FormControl = new FormControl();
  engagementListoptions: any[];
  results: any[];
  jobCategory = '';
  engagement: JobVM;
  searchBar: string = '';
  resource: ResourceDto;
  resourceList: ResourceDto[];
  sortedResourceList: any[];
  resourceListoptions: any[];
  selectedResourceItem: any = '';
  resourceRank: string;
  resourceLocation: string;
  validResource = true;
  Rank: string;
  Location: string;
  ResourceName: string;
  currentUserName: any;
  currentUserId: any;
  currentUserEmail: any;
  splitId: number;
  showSplit_2 = false;
  showSplit_3 = false;
  showSplit_4 = false;
  showSplit_5 = false;
  loading_2 = '';
  loading_3 = '';
  loading_4 = '';
  loading_5 = '';
  engagementAllocationTypeValue_2: any;
  engagementAllocationTypeValue_3: any;
  engagementAllocationTypeValue_4: any;
  engagementAllocationTypeValue_5: any;
  bsRangeValue_2: Date[];
  bsRangeValue_3: Date[];
  bsRangeValue_4: Date[];
  bsRangeValue_5: Date[];
  unconfirmed_2: boolean = false;
  unconfirmed_3: boolean = false;
  unconfirmed_4: boolean = false;
  unconfirmed_5: boolean = false;
  Ghost: boolean;
  Ghost_2: boolean;
  Ghost_3: boolean;
  Ghost_4: boolean;
  Ghost_5: boolean;

  confirmHours: boolean = false;
  confirmHours_2: boolean = false;
  confirmHours_3: boolean = false;
  confirmHours_4: boolean = false;
  confirmHours_5: boolean = false;
  hoursValidationResultList: boolean[];
  hoursValid: boolean = true;
  startWith = new Date();
  mySelection: any[] = [];
  previewGridSource: any[] = [];
  resourceBooking: ResourceBookingVM = new ResourceBookingVM();
  selectedCallback: any;
  editFlag: boolean = false;
  buttonType: string;
  rowNumberforEdit: number = -1;
  totalGridElements = 0;
  confirmModalRef: BsModalRef;
  modalOption: ModalOptions = {};
  rowNumberforDelete: number;
  departmentDescription: string;

  updateFlag: boolean = false;
  buttonText = 'Save';
  nextSaturday: Date;
  today: Date;
  overallocationerror: boolean = true;
  RequestMode: number = JSON.parse(sessionStorage.getItem('requestMode'));
  loggedInUser: UserVM;
  defaultDateFormat: string;
  submitDisableFlg = false;
  thisDay = new Date();
  todaysDate = new Date(
    this.thisDay.getFullYear(),
    this.thisDay.getMonth(),
    this.thisDay.getDate(),
    0,
    0,
    0
  );
  formGroup = this.formBuilder.group({
    //EngagementResourceName: new FormControl('', [Validators.required]),
    EngagementResourceName: ['', Validators.required],
    period1: this.formBuilder.group({
      start1: ['', Validators.required],
      end1: ['', Validators.required],
    }),
    period2: this.formBuilder.group({
      start2: ['', Validators.required],
      end2: ['', Validators.required],
    }),
    period3: this.formBuilder.group({
      start3: ['', Validators.required],
      end3: ['', Validators.required],
    }),
    period4: this.formBuilder.group({
      start4: ['', Validators.required],
      end4: ['', Validators.required],
    }),
    period5: this.formBuilder.group({
      start5: ['', Validators.required],
      end5: ['', Validators.required],
    }),
    EngagementAllocation: [
      '',
      [
        Validators.required,
        Validators.pattern(/^(?!(0))[0-9]*(\.[0-9]{0,2})?$/),
        //Validators.max(999),
      ],
    ],
    allocationValue_2: [
      '',
      [
        Validators.required,
        Validators.pattern(/^(?!(0))[0-9]*(\.[0-9]{0,2})?$/),
        // Validators.max(999),
      ],
    ],
    allocationValue_3: [
      '',
      [
        Validators.required,
        Validators.pattern(/^(?!(0))[0-9]*(\.[0-9]{0,2})?$/),
        // Validators.max(999),
      ],
    ],
    allocationValue_4: [
      '',
      [
        Validators.required,
        Validators.pattern(/^(?!(0))[0-9]*(\.[0-9]{0,2})?$/),
        // Validators.max(999),
      ],
    ],
    allocationValue_5: [
      '',
      [
        Validators.required,
        Validators.pattern(/^(?!(0))[0-9]*(\.[0-9]{0,2})?$/),
        // Validators.max(999),
      ],
    ],
  });
  constructor(
    public restService: EngagementService,
    private EngagementServices: EngagementService,
    private spinner: NgxSpinnerService,
    private orderPipe: OrderPipe,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    public sharedService: SharedServicesService
  ) {
    // this.view = this.restService;
  }

  ngOnInit() {
    this.bsConfig = Object.assign(
      {},
      {
        containerClass: this.colorTheme,
      }
    );
    this.loggedInUser = JSON.parse(sessionStorage.getItem('resource'));
    this.defaultDateFormat = this.sharedService.getDateFormat(
      this.loggedInUser.DefaultDateFormat
    );
    this.spinner.show();
    this.currentUserName = sessionStorage.getItem('user_name');
    this.currentUserEmail = this.loggedInUser.Email;
    this.currentUserId = sessionStorage.getItem('resourceId');
    if (!this.roleDelgtFlg) {
      this.resource = new ResourceDto();
      this.fetchResourceData();
    }
    // this.getCurrentWeek();
    this.enabledWeek(!this.currentWeekActionDTO.IsDisabledAddBookingCurrWeek);
    this.loadInitialData();
    this.engagement = new JobVM();

    this.booking = new EngagementBookingVM();
    this.response = new Response();
    this.engagementListFiltered = [];
    this.engagementList = [];
    this.sortedEngagementList = [];
    this.results = [];
    this.engagementListoptions = [];
    this.unconfirmed = null;
    this.Ghost = null;
    this.resource = new ResourceDto();
    this.setData();
    this.EngagementServices.currentMessageEngagementAllocation.subscribe(
      (isPercentageSelected) => {
        this.isPercentageSelected = isPercentageSelected;
        this.setEngagementAllocation(this.isPercentageSelected);
        this.maintainHiddenColumns();
      }
    );
    //this.spinner.hide();
  }

  maintainHiddenColumns() {
    if (this.isPercentageSelected)
      this.displayedColumns = this.displayedColumns.filter(
        (item) => item !== 'Hours'
      );
    else
      this.displayedColumns = this.displayedColumns.filter(
        (item) => item !== 'Loading'
      );

    if (!this.loggedInUser.Ghost) {
      this.displayedColumns = this.displayedColumns.filter(
        (item) => item !== 'Ghost'
      );
    }
    if (this.loggedInUser.Unconfirmed) {
      this.displayedColumns = this.displayedColumns.filter(
        (item) => item !== 'Unconfirmed'
      );
    }
  }

  loadInitialData() {
    this.searchBar = null;
    this.bsRangeValue = [];
  }

  setEngagementListData() {
    this.onEngagementClear();
    this.resourceListoptions = [];
    if (
      this.autocompleteResetvalue == null ||
      this.autocompleteResetvalue == ''
    ) {
      this.results = [];
    } else {
      this.results = this.sortedResourceList;
    }
  }

  isValidInput(fieldName): boolean {
    return (
      this.formGroup.controls[fieldName].invalid &&
      (this.formGroup.controls[fieldName].dirty ||
        this.formGroup.controls[fieldName].touched)
    );
  }

  fetchResourceData() {
    //this.spinner.show();
    this.autocompleteResetvalue = this.loggedInUser.GPN;
    if (this.autocompleteResetvalue?.trim().length >= 2) {
      this.resource.ResourceId = +sessionStorage.getItem('resourceId'); //1404098; // this.resourceId
      this.resource.LoggedInResourceID = +sessionStorage.getItem(
        'loggedin_resourceid'
      );
      this.resource.ResourceName = this.autocompleteResetvalue;
      this.resource.InstanceId = this.selectedInstanceId;

      this.searchBookingSubscription = this.restService
        .getResourceList(this.resource)
        .subscribe(
          (data) => {
            this.resourceId = data[0].ResourceId;
            this.ResourceName = data[0].ResourceName;
            this.engagementServiceLine = data[0].SLDescription;
            this.departmentDescription = data[0].DepartmentDescription;
            this.Rank = data[0].RankDescription;
            this.resourceLocation = data[0].LocationDescription;
            this.spinner.hide();
          },
          (err) => {
            console.log('error occured', err);
          }
        );
    }
  }

  search() {
    this.autocompleteResetvalue =
      this.formGroup.controls['EngagementResourceName'].value;

    if (this.autocompleteResetvalue?.trim().length >= 2) {
      this.showSearchList = true;
      this.showSearchLoader = true;
      this.resource.ResourceId = +sessionStorage.getItem('resourceId'); //1404098; // this.resourceId
      this.resource.LoggedInResourceID = +sessionStorage.getItem(
        'loggedin_resourceid'
      );
      this.resource.ResourceName = this.autocompleteResetvalue;
      this.resource.InstanceId = this.selectedInstanceId;

      this.searchBookingSubscription = this.restService
        .getResourceList(this.resource)
        .subscribe((data) => {
          if (data.length > 0) {
            this.sortedResourceList = this.orderPipe.transform(
              data,
              'ResourceName'
            );

            this.results = this.sortedResourceList;
            this.showSearchLoader = false;
            if (!this.results?.length) this.showSearchList = false;
          } else {
            this.results = [];
            this.showSearchLoader = false;
            this.showSearchList = false;
            this.formGroup.controls['EngagementResourceName'].value == null;
            this.formGroup.controls['EngagementResourceName'].setErrors({
              incorrect: true,
            });
          }
        });
    } else {
      this.results = [];
      this.showSearchLoader = false;
      this.showSearchList = false;
    }
  }

  OnItemSelect(event) {
    this.getFormControlObj('EngagementResourceName')?.patchValue(
      event?.ResourceName
    );
    this.selectedResourceItem = event;
    this.showSearchList = false;
    this.Rank = event.RankDescription;
    this.resourceRank = event.RankDescription;
    this.resourceLocation = event.LocationDescription
      ? event.LocationDescription
      : null;
    this.engagementServiceLine = event.SLDescription;
    this.jobId = event.JobId;
    this.resourceId = event.ResourceId;
    this.Location = this.resourceLocation;
    this.departmentDescription = event.DepartmentDescription;
  }

  onResourceNameClear() {
    this.placeHolderText = 'Search Resource...';
    this.selectedItem = null;
    this.bookingType = null;
    this.Engagement = null;
    this.engagementCode = null;
    this.autocompleteResetvalue = null;
    this.engagementStatus = null;
    this.engagementServiceLine = null;
    this.formGroup.get('EngagementResourceName').reset();
    this.results = [];
  }

  dateRangeValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    if (
      control.value !== undefined &&
      control.value !== null &&
      (control.value[0] < today || control.value[1] < today)
    ) {
      return { ageRange: true };
    }
    return null;
  }

  onSubmit(buttonType?) {
    this.hoursValid = true;
    const btnType = buttonType;
    if (!this.showSplit_2) {
      this.formGroup.get('period2').get('start2').setErrors(null);
      this.formGroup.get('period2').get('start2').setValidators(null);
      this.formGroup.get('period2').get('end2').setErrors(null);
      this.formGroup.get('period2').get('end2').setValidators(null);
      this.formGroup.get('allocationValue_2').setErrors(null);
      this.formGroup.get('allocationValue_2').setValidators(null);
    }
    if (!this.showSplit_3) {
      this.formGroup.get('period3').get('start3').setErrors(null);
      this.formGroup.get('period3').get('start3').setValidators(null);
      this.formGroup.get('period3').get('end3').setErrors(null);
      this.formGroup.get('period3').get('end3').setValidators(null);
      this.formGroup.get('allocationValue_3').setErrors(null);
      this.formGroup.get('allocationValue_3').setValidators(null);
    }
    if (!this.showSplit_4) {
      this.formGroup.get('period4').get('start4').setErrors(null);
      this.formGroup.get('period4').get('start4').setValidators(null);
      this.formGroup.get('period4').get('end4').setErrors(null);
      this.formGroup.get('period4').get('end4').setValidators(null);
      this.formGroup.get('allocationValue_4').setErrors(null);
      this.formGroup.get('allocationValue_4').setValidators(null);
    }
    if (!this.showSplit_5) {
      this.formGroup.get('period5').get('start5').setErrors(null);
      this.formGroup.get('period5').get('start5').setValidators(null);
      this.formGroup.get('period5').get('end5').setErrors(null);
      this.formGroup.get('period5').get('end5').setValidators(null);
      this.formGroup.get('allocationValue_5').setErrors(null);
      this.formGroup.get('allocationValue_5').setValidators(null);
    }
    if (
      (buttonType =
        'Add' &&
        this.formGroup.controls['EngagementResourceName'].value == null)
    ) {
      this.formGroup.controls['EngagementResourceName'].setErrors({
        required: true,
      });
    }
    if (
      this.formGroup.controls['period1'].get('start1').value == null ||
      this.formGroup.controls['period1'].get('start1').value == ''
    ) {
      this.formGroup
        .get('period1')
        .get('start1')
        .setErrors({ incorrect: true });
    }
    if (
      this.formGroup.controls['period1'].get('end1').value == null ||
      this.formGroup.controls['period1'].get('end1').value == ''
    ) {
      this.formGroup.get('period1').get('end1').setErrors({ incorrect: true });
    }
    if (
      this.formGroup.controls['EngagementAllocation'].value == null ||
      this.formGroup.controls['EngagementAllocation'].value == ''
    ) {
      this.formGroup.get('EngagementAllocation').setErrors({ required: true });
      //this.formGroup.get('EngagementAllocation').markAsTouched();
      //this.formGroup.get('EngagementAllocation').markAsDirty();
    }

    if (this.validate(this.resourceId) && !this.formGroup.invalid) {
      this.spinner.show();
      this.setSplitValues();

      this.isHoursValid = true;
      this.isHoursValid_2 = true;
      this.isHoursValid_3 = true;
      this.isHoursValid_4 = true;
      this.isHoursValid_5 = true;
      this.isConfirmFlag = true;

      // return;

      this.addBookingSubscription = this.EngagementServices.validateHours(
        this.booking
      ).subscribe((data: any) => {
        if (!data[0] && !this.confirmHours) {
          this.isHoursValid = false;
          this.overallocationerror_1 = false;
        } else {
          this.hoursValidationResultList = data;
          if (
            this.hoursValidationResultList &&
            this.hoursValidationResultList[0] &&
            !this.hoursValidationResultList[0]
          ) {
            this.isHoursValid = false;
            this.hoursValid = false;
            this.formGroup
              .get('EngagementAllocation')
              .setErrors({ incorrect: true });
            this.overallocationerror_1 = false;
          }
          if (
            this.hoursValidationResultList &&
            this.hoursValidationResultList[1] &&
            !this.hoursValidationResultList[1]
          ) {
            this.isHoursValid_2 = false;
            this.hoursValid = false;
            this.formGroup
              .get('allocationValue_2')
              .setErrors({ incorrect: true });
            this.overallocationerror_2 = false;
          }
          if (
            this.hoursValidationResultList &&
            this.hoursValidationResultList[2] &&
            !this.hoursValidationResultList[2]
          ) {
            this.isHoursValid_3 = false;
            this.hoursValid = false;
            this.formGroup
              .get('allocationValue_3')
              .setErrors({ incorrect: true });
            this.overallocationerror_3 = false;
          }
          if (
            this.hoursValidationResultList &&
            this.hoursValidationResultList[3] &&
            !this.hoursValidationResultList[3]
          ) {
            this.isHoursValid_4 = false;
            this.hoursValid = false;
            this.formGroup
              .get('allocationValue_4')
              .setErrors({ incorrect: true });
            this.overallocationerror_1 = false;
          }
          if (
            this.hoursValidationResultList &&
            this.hoursValidationResultList[4] &&
            !this.hoursValidationResultList[4]
          ) {
            this.isHoursValid_5 = false;
            this.hoursValid = false;
            this.formGroup
              .get('allocationValue_5')
              .setErrors({ incorrect: true });
            this.overallocationerror_5 = false;
          }
          if (this.hoursValid || (!this.hoursValid && this.confirmHours)) {
            this.setValues(btnType);
          }
        }
        this.spinner.hide();
      });
    } else {
      this.formGroup.markAllAsTouched();
      this.formGroup.markAsDirty();
    }
  }

  onEngagementClear() {
    this.formGroup.get('EngagementResourceName').reset();
    this.autocompleteResetvalue = null;
    this.resourceRank = null;
    this.resourceLocation = null;
    this.Rank = null;
    this.Location = null;
    this.resourceId = null;
    this.engagementServiceLine = null;
    this.results = [];
    this.showSearchList = false;
    this.departmentDescription = null;
  }

  getFormControlObj(inp1, inp2?) {
    const fg = this.formGroup;
    let fgObj;
    if (inp2 && inp2 !== '') fgObj = fg?.get(inp1).get(inp2);
    else fgObj = fg?.get(inp1);

    return fgObj;
  }

  setLoadingValues(inp1) {
    const loadingVal = this.getFormControlObj(inp1)?.value;
    this.resourceBooking.Loading = loadingVal;
    this.resourceBooking.LoadingPercentage = loadingVal + '%';
    this.resourceBooking.IsPercentSelected = true;
  }

  setValues(buttonType) {
    console.clear();
    if (buttonType === 'Update') {
      this.editFlag = false;
      let sdate = this.getFormControlObj('period1', 'start1')?.value;
      let edate = this.getFormControlObj('period1', 'end1')?.value
      sdate = moment(sdate).format("YYYY-MM-DDT00:00:00.000");
      edate = moment(edate).format("YYYY-MM-DDT00:00:00.000");
      this.previewGridSource[this.rowNumberforEdit].StartDate = sdate;
      //  this.getFormControlObj('period1', 'start1')?.value;
      this.previewGridSource[this.rowNumberforEdit].EndDate = edate;
      //  this.getFormControlObj('period1', 'end1')?.value;
      this.previewGridSource[this.rowNumberforEdit].Description =
        this.description;
      this.previewGridSource[this.rowNumberforEdit].Unconfirmed =
        this.unconfirmed;
      this.previewGridSource[this.rowNumberforEdit].IsOverallocation =
        this.confirmHours; //Overallocation
      if (this.unconfirmed) {
        this.previewGridSource[this.rowNumberforEdit].UnconfirmedYesNo = 'Yes';
      } else if (!this.unconfirmed) {
        this.previewGridSource[this.rowNumberforEdit].UnconfirmedYesNo = 'No';
      }
      if (this.loggedInUser.Ghost) {
        this.previewGridSource[this.rowNumberforEdit].Ghost = this.Ghost;
        this.previewGridSource[this.rowNumberforEdit].GhostYesNo = this.Ghost
          ? 'Yes'
          : 'No';
      }
      if (this.engagementAllocationPercentSelected) {
        const loadingVal = this.getFormControlObj(
          'EngagementAllocation'
        )?.value;
        this.previewGridSource[this.rowNumberforEdit].Loading = loadingVal;
        this.previewGridSource[this.rowNumberforEdit].LoadingPercentage =
          loadingVal + '%';
      } else {
        this.previewGridSource[this.rowNumberforEdit].Hours =
          this.getFormControlObj('EngagementAllocation')?.value;
      }
      this.previewGridSource = [...this.previewGridSource];
    } else {
      this.isHoursValid = true;
      this.resourceBooking = new ResourceBookingVM();
      this.resourceBooking.CurrentUserId = this.currentUserId;
      this.resourceBooking.CurrentUserName = this.currentUserName;
      this.resourceBooking.CurrentUserEmail = this.currentUserEmail;
      this.resourceBooking.ResourceId = this.resourceId;
      this.resourceBooking.BookingId = this.bookingId;
      this.resourceBooking.BookingType = 'External Customer Project';
      this.resourceBooking.Description = this.description;
      this.resourceBooking.JobId = +sessionStorage.getItem('JobId');
      this.resourceBooking.ActionDescriptionId = 1;
      this.resourceBooking.MeraBookingStatusId = 1;
      this.resourceBooking.ResourceName =
        this.selectedResourceItem?.ResourceName;
      let sdate = new Date(this.getFormatedDate(1, true)).toUTCString();
      sdate = sdate.split('00')[0];
      let edate = new Date(this.getFormatedDate(1, false)).toUTCString();
      edate = edate.split('00')[0];
      this.resourceBooking.StartDate = new Date(sdate).toDateString();
      this.resourceBooking.EndDate = new Date(edate).toDateString();
      // this.resourceBooking.StartDate = this.getFormatedDate(1, true);
      // this.resourceBooking.EndDate = this.getFormatedDate(1, false);
      this.resourceBooking.Unconfirmed = this.unconfirmed;
      this.resourceBooking.IsOverallocation = this.confirmHours; // OverAllocation
      if (this.unconfirmed) this.resourceBooking.UnconfirmedYesNo = 'Yes';
      else if (!this.unconfirmed) this.resourceBooking.UnconfirmedYesNo = 'No';
      this.resourceBooking.Rank = this.Rank;
      if (this.loggedInUser.Ghost) {
        this.resourceBooking.Ghost = this.Ghost;
        this.resourceBooking.GhostYesNo = this.Ghost ? 'Yes' : 'No';
      }
      if (this.engagementAllocationPercentSelected)
        this.setLoadingValues('EngagementAllocation');
      else {
        this.resourceBooking.Hours = this.getFormControlObj(
          'EngagementAllocation'
        )?.value;
        this.resourceBooking.Loading = +this.loading;
      }

      // this.resourceBooking.Rank = this.selectedResourceItem?.RankDescription;
      this.resourceBooking.LocationDescription = this.Location;
      this.resourceBooking.ServiceLineDescription = this.engagementServiceLine;
      this.resourceBooking.RequestMode = JSON.parse(
        sessionStorage.getItem('requestMode')
      );

      // this.previewGridSource.push(this.resourceBooking);
      this.previewGridSource = [
        ...this.previewGridSource,
        this.resourceBooking,
      ];
      this.totalGridElements = this.totalGridElements + 1;

      if (this.showSplit_2) {
        this.resourceBooking = new ResourceBookingVM();
        this.resourceBooking.CurrentUserId = this.currentUserId;
        this.resourceBooking.CurrentUserName = this.currentUserName;
        this.resourceBooking.CurrentUserEmail = this.currentUserEmail;
        this.resourceBooking.ResourceId = this.resourceId;
        this.resourceBooking.BookingId = this.bookingId;
        this.resourceBooking.BookingType = 'External Customer Project';
        this.resourceBooking.Description = this.description;
        this.resourceBooking.JobId = +sessionStorage.getItem('JobId');
        this.resourceBooking.ActionDescriptionId = 1;
        this.resourceBooking.MeraBookingStatusId = 1;
        this.resourceBooking.ResourceName =
          this.selectedResourceItem?.ResourceName;
        let sdate = new Date(this.getFormatedDate(2, true)).toUTCString();
        sdate = sdate.split('00')[0];
        let edate = new Date(this.getFormatedDate(2, false)).toUTCString();
        edate = edate.split('00')[0];
        this.resourceBooking.StartDate = new Date(sdate).toDateString();
        this.resourceBooking.EndDate = new Date(edate).toDateString();
        // this.resourceBooking.StartDate = this.getFormatedDate(2, true);
        // this.resourceBooking.EndDate = this.getFormatedDate(2, false);
        this.resourceBooking.Unconfirmed = this.unconfirmed_2;
        this.resourceBooking.IsOverallocation = this.confirmHours_2; // OverAllocation
        this.resourceBooking.Rank = this.Rank;
        if (this.unconfirmed_2) this.resourceBooking.UnconfirmedYesNo = 'Yes';
        else if (!this.unconfirmed_2)
          this.resourceBooking.UnconfirmedYesNo = 'No';

        if (this.loggedInUser.Ghost) {
          this.resourceBooking.Ghost = this.Ghost_2;
          this.resourceBooking.GhostYesNo = this.Ghost_2 ? 'Yes' : 'No';
        }

        if (this.engagementAllocationPercentSelected)
          this.setLoadingValues('allocationValue_2');
        else {
          this.resourceBooking.Hours =
            this.getFormControlObj('allocationValue_2')?.value;
          this.resourceBooking.Loading = +this.loading_2;
        }
        // this.resourceBooking.Rank = this.selectedResourceItem?.RankDescription;
        this.resourceBooking.LocationDescription = this.Location;
        this.resourceBooking.ServiceLineDescription =
          this.engagementServiceLine;
        this.resourceBooking.RequestMode = JSON.parse(
          sessionStorage.getItem('requestMode')
        );
        this.previewGridSource.push(this.resourceBooking);
        this.totalGridElements = this.totalGridElements + 1;
      }
      if (this.showSplit_3) {
        this.resourceBooking = new ResourceBookingVM();
        this.resourceBooking.CurrentUserId = this.currentUserId;
        this.resourceBooking.CurrentUserName = this.currentUserName;
        this.resourceBooking.CurrentUserEmail = this.currentUserEmail;
        this.resourceBooking.ResourceId = this.resourceId;
        this.resourceBooking.BookingId = this.bookingId;
        this.resourceBooking.BookingType = 'External Customer Project';
        this.resourceBooking.Description = this.description;
        this.resourceBooking.JobId = +sessionStorage.getItem('JobId');
        this.resourceBooking.ActionDescriptionId = 1;
        this.resourceBooking.MeraBookingStatusId = 1;
        this.resourceBooking.ResourceName =
          this.selectedResourceItem?.ResourceName;
        let sdate = new Date(this.getFormatedDate(3, true)).toUTCString();
        sdate = sdate.split('00')[0];
        let edate = new Date(this.getFormatedDate(3, false)).toUTCString();
        edate = edate.split('00')[0];
        this.resourceBooking.StartDate = new Date(sdate).toDateString();
        this.resourceBooking.EndDate = new Date(edate).toDateString();
        // this.resourceBooking.StartDate = this.getFormatedDate(3, true);
        // this.resourceBooking.EndDate = this.getFormatedDate(3, false);
        this.resourceBooking.Unconfirmed = this.unconfirmed_3;
        this.resourceBooking.IsOverallocation = this.confirmHours_3; // OverAllocation
        this.resourceBooking.Rank = this.Rank;
        if (this.unconfirmed_3) {
          this.resourceBooking.UnconfirmedYesNo = 'Yes';
        } else if (!this.unconfirmed_3) {
          this.resourceBooking.UnconfirmedYesNo = 'No';
        }

        if (this.loggedInUser.Ghost) {
          this.resourceBooking.Ghost = this.Ghost_3;
          this.resourceBooking.GhostYesNo = this.Ghost_3 ? 'Yes' : 'No';
        }

        if (this.engagementAllocationPercentSelected) {
          this.setLoadingValues('allocationValue_3');
        } else {
          this.resourceBooking.Hours =
            this.getFormControlObj('allocationValue_3')?.value;
          this.resourceBooking.Loading = +this.loading_3;
        }
        // this.resourceBooking.Rank = this.selectedResourceItem?.RankDescription;
        this.resourceBooking.LocationDescription = this.Location;
        this.resourceBooking.ServiceLineDescription =
          this.engagementServiceLine;
        this.resourceBooking.RequestMode = JSON.parse(
          sessionStorage.getItem('requestMode')
        );
        this.previewGridSource.push(this.resourceBooking);
        this.totalGridElements = this.totalGridElements + 1;
      }
      if (this.showSplit_4) {
        this.resourceBooking = new ResourceBookingVM();
        this.resourceBooking.CurrentUserId = this.currentUserId;
        this.resourceBooking.CurrentUserName = this.currentUserName;
        this.resourceBooking.CurrentUserEmail = this.currentUserEmail;
        this.resourceBooking.ResourceId = this.resourceId;
        this.resourceBooking.BookingId = this.bookingId;
        this.resourceBooking.BookingType = 'External Customer Project';
        this.resourceBooking.Description = this.description;
        this.resourceBooking.JobId = +sessionStorage.getItem('JobId');
        this.resourceBooking.ActionDescriptionId = 1;
        this.resourceBooking.MeraBookingStatusId = 1;
        this.resourceBooking.ResourceName =
          this.selectedResourceItem?.ResourceName;
        let sdate = new Date(this.getFormatedDate(4, true)).toUTCString();
        sdate = sdate.split('00')[0];
        let edate = new Date(this.getFormatedDate(4, false)).toUTCString();
        edate = edate.split('00')[0];
        this.resourceBooking.StartDate = new Date(sdate).toDateString();
        this.resourceBooking.EndDate = new Date(edate).toDateString();
        // this.resourceBooking.StartDate = this.getFormatedDate(4, true);
        // this.resourceBooking.EndDate = this.getFormatedDate(4, false);
        this.resourceBooking.Unconfirmed = this.unconfirmed_4;
        this.resourceBooking.IsOverallocation = this.confirmHours_4; // OverAllocation
        this.resourceBooking.Rank = this.Rank;
        if (this.unconfirmed_4) {
          this.resourceBooking.UnconfirmedYesNo = 'Yes';
        } else if (!this.unconfirmed_4) {
          this.resourceBooking.UnconfirmedYesNo = 'No';
        }

        if (this.loggedInUser.Ghost) {
          this.resourceBooking.Ghost = this.Ghost_4;
          this.resourceBooking.GhostYesNo = this.Ghost_4 ? 'Yes' : 'No';
        }

        if (this.engagementAllocationPercentSelected) {
          this.setLoadingValues('allocationValue_4');
        } else {
          this.resourceBooking.Hours =
            this.getFormControlObj('allocationValue_4')?.value;
          this.resourceBooking.Loading = +this.loading_4;
        }
        // this.resourceBooking.Rank = this.selectedResourceItem?.RankDescription;
        this.resourceBooking.LocationDescription = this.Location;
        this.resourceBooking.ServiceLineDescription =
          this.engagementServiceLine;
        this.resourceBooking.RequestMode = JSON.parse(
          sessionStorage.getItem('requestMode')
        );
        this.previewGridSource.push(this.resourceBooking);
        this.totalGridElements = this.totalGridElements + 1;
      }
      if (this.showSplit_5) {
        this.resourceBooking = new ResourceBookingVM();
        this.resourceBooking.CurrentUserId = this.currentUserId;
        this.resourceBooking.CurrentUserName = this.currentUserName;
        this.resourceBooking.CurrentUserEmail = this.currentUserEmail;
        this.resourceBooking.ResourceId = this.resourceId;
        this.resourceBooking.BookingId = this.bookingId;
        this.resourceBooking.BookingType = 'External Customer Project';
        this.resourceBooking.Description = this.description;
        this.resourceBooking.JobId = +sessionStorage.getItem('JobId');
        this.resourceBooking.ActionDescriptionId = 1;
        this.resourceBooking.MeraBookingStatusId = 1;
        this.resourceBooking.ResourceName =
          this.selectedResourceItem?.ResourceName;
        let sdate = new Date(this.getFormatedDate(5, true)).toUTCString();
        sdate = sdate.split('00')[0];
        let edate = new Date(this.getFormatedDate(5, false)).toUTCString();
        edate = edate.split('00')[0];
        this.resourceBooking.StartDate = new Date(sdate).toDateString();
        this.resourceBooking.EndDate = new Date(edate).toDateString();
        // this.resourceBooking.StartDate = this.getFormatedDate(5, true);
        // this.resourceBooking.EndDate = this.getFormatedDate(5, false);
        this.resourceBooking.Unconfirmed = this.unconfirmed_5;
        this.resourceBooking.IsOverallocation = this.confirmHours_5; // OverAllocation
        this.resourceBooking.Rank = this.Rank;
        if (this.unconfirmed_5) {
          this.resourceBooking.UnconfirmedYesNo = 'Yes';
        } else if (!this.unconfirmed_5) {
          this.resourceBooking.UnconfirmedYesNo = 'No';
        }

        if (this.loggedInUser.Ghost) {
          this.resourceBooking.Ghost = this.Ghost_5;
          this.resourceBooking.GhostYesNo = this.Ghost_5 ? 'Yes' : 'No';
        }

        if (this.engagementAllocationPercentSelected) {
          this.setLoadingValues('allocationValue_5');
        } else {
          this.resourceBooking.Hours =
            this.getFormControlObj('allocationValue_5')?.value;
          this.resourceBooking.Loading = +this.loading_5;
        }
        // this.resourceBooking.Rank = this.selectedResourceItem?.RankDescription;
        this.resourceBooking.LocationDescription = this.Location;
        this.resourceBooking.ServiceLineDescription =
          this.engagementServiceLine;
        this.resourceBooking.RequestMode = JSON.parse(
          sessionStorage.getItem('requestMode')
        );
        this.previewGridSource.push(this.resourceBooking);
        this.totalGridElements = this.totalGridElements + 1;
      }
    }
    if (!this.roleDelgtFlg) {
      this.save();
    } else {
      this.editFlag = false;
      this.dirtyFlag = false;

      this.ResourceName = '';
      this.engagementServiceLine = '';
      this.Rank = '';
      this.Location = '';

      this.confirmHours = false;
      this.autocompleteResetvalue = '';
      this.description = '';

      this.hoursValid = true;

      this.selectedResourceItem = null;
      // this.formGroup.reset();
      this.formGroup.get('EngagementResourceName').reset();
      this.clearControls();
      //this.setValidatorsFalse();
    }
  }

  public confirmHoursChange() {
    if (this.confirmHours) {
      this.dirtyFlag = true;
      if (this.formGroup.get('EngagementAllocation').value != '') {
        this.formGroup.get('EngagementAllocation').setErrors(null);
      }
      if (this.formGroup.get('allocationValue_2').value != '') {
        this.formGroup.get('allocationValue_2').setErrors(null);
      }
      if (this.formGroup.get('allocationValue_3').value != '') {
        this.formGroup.get('allocationValue_3').setErrors(null);
      }
      if (this.formGroup.get('allocationValue_4').value != '') {
        this.formGroup.get('allocationValue_4').setErrors(null);
      }
      if (this.formGroup.get('allocationValue_5').value != '') {
        this.formGroup.get('allocationValue_5').setErrors(null);
      }
    } else if (!this.hoursValid && !this.confirmHours) {
      this.dirtyFlag = false;
    }
  }

  validate(resourceId) {
    let result = true;
    if (resourceId === 0 || resourceId === undefined || resourceId === null) {
      this.validResource = false;
      result = false;
    }
    return result;
  }

  @Output() isFormDirtyFlag: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  public dirtyFlag = false;

  mouseEnter() {}

  mouseLeave(val) {}

  public makeDirty(event) {
    if (!this.dirtyFlag) {
      this.dirtyFlag = true;
      this.isFormDirtyFlag.emit(this.dirtyFlag);
    }

    if (this.overallocationerror_1 == false && event == 'Hours_1')
      this.overallocationerror_1 = true;

    if (this.overallocationerror_2 == false && event == 'Hours_2')
      this.overallocationerror_2 = true;

    if (this.overallocationerror_3 == false && event == 'Hours_3')
      this.overallocationerror_3 = true;

    if (this.overallocationerror_4 == false && event == 'Hours_4')
      this.overallocationerror_4 = true;

    if (this.overallocationerror_5 == false && event == 'Hours_5')
      this.overallocationerror_5 = true;
  }

  public checkDirty() {
    if (!this.dirtyFlag) {
      if (this.formGroup.get('Period').dirty) {
        this.makeDirty(event);
      }
    }
  }

  save() {
    this.buttonText = 'Submitting';
    //this.confirmModalRef.hide(); // Confirmation popup removed
    this.submitDisableFlg = true;
    this.previewGridSource.forEach((element) => {
      element.InstanceId = this.selectedInstanceId;
      element.UiViewSource = UiViewSourceEnum.EngamentView;
      element.StartDate = moment(element.StartDate).format(
        'YYYY-MM-DDT00:00:00.000'
      );
      element.EndDate = moment(element.EndDate).format(
        'YYYY-MM-DDT00:00:00.000'
      );
    });
    let bookingAddActionDto = this.sharedService.getBookingActionDtoFromArray(
      this.previewGridSource
    );
    this.addBookingSubscription = this.EngagementServices.addBooking(
      bookingAddActionDto
    ).subscribe(
      (data: any) => {
        this.spinner.hide();
        if ((data.status = '200')) {
          this.response = data.body;
          if (this.response.customResultId > 0) {
            this.EngagementServices.setRquestNumber(
              this.response.customResultId
            );
          }
          // alert('Successfully submitted the changes');
          this.submitDisableFlg = false;
          this.EngagementServices.closeModal(true);
        }
        this.buttonText = 'Submit';
        this.submitDisableFlg = false;
        console.log('API Error with status Code' + data.status);
      },
      (err) => {
        this.buttonText = 'Error';
        alert('Something Went Wrong...');
        console.log('New EV Booking API Error', err);
      },
      () => {
        this.buttonText = 'Submit';
      }
    );
    this.dirtyFlag = false;
  }

  ngOnDestroy() {
    if (this.addBookingSubscription !== undefined) {
      this.addBookingSubscription.unsubscribe();
    }
    if (this.searchBookingSubscription !== undefined) {
      this.searchBookingSubscription.unsubscribe();
    }
  }

  getFormatedDate(whichSeq, isStart) {
    const date = this.formGroup
      .get('period' + whichSeq)
      .get((isStart ? 'start' : 'end') + whichSeq).value;

    return date;
  }

  setSplitValues() {
    this.booking.InstanceId = this.selectedInstanceId;
    this.booking.ResourceId = this.resourceId;
    this.booking.BookingType = 'External Customer Project';
    this.booking.Description = this.description;
    this.booking.JobId = this.jobId;
    this.booking.JobId = +sessionStorage.getItem('JobId');
    this.booking.ActionDescriptionId = 1;
    this.booking.MeraBookingStatusId = 1;
    this.booking.ResourceName = this.ResourceName;
    this.booking.BookingId = this.bookingId;
    this.booking.Unconfirmed = this.unconfirmed;
    this.booking.Unconfirmed_2 = this.unconfirmed_2;
    this.booking.Unconfirmed_3 = this.unconfirmed_3;
    this.booking.Unconfirmed_4 = this.unconfirmed_4;
    this.booking.Unconfirmed_5 = this.unconfirmed_5;
    this.booking.Ghost = this.Ghost;
    this.booking.Ghost_2 = this.Ghost_2;
    this.booking.Ghost_3 = this.Ghost_3;
    this.booking.Ghost_4 = this.Ghost_4;
    this.booking.Ghost_5 = this.Ghost_5;

    this.booking.StartDate = this.getFormatedDate(1, true);
    this.booking.EndDate = this.getFormatedDate(1, false);
    this.booking.StartDate_2 = this.getFormatedDate(2, true);
    this.booking.EndDate_2 = this.getFormatedDate(2, false);
    this.booking.StartDate_3 = this.getFormatedDate(3, true);
    this.booking.EndDate_3 = this.getFormatedDate(3, false);
    this.booking.StartDate_4 = this.getFormatedDate(4, true);
    this.booking.EndDate_4 = this.getFormatedDate(4, false);
    this.booking.StartDate_5 = this.getFormatedDate(5, true);
    this.booking.EndDate_5 = this.getFormatedDate(5, false);

    if (this.engagementAllocationPercentSelected) {
      this.booking.Loading = this.formGroup.get('EngagementAllocation').value;
      this.booking.Loading_2 = this.formGroup.get('allocationValue_2').value;
      this.booking.Loading_3 = this.formGroup.get('allocationValue_3').value;
      this.booking.Loading_4 = this.formGroup.get('allocationValue_4').value;
      this.booking.Loading_5 = this.formGroup.get('allocationValue_5').value;

      this.booking.IsPercentSelected = true;

      this.booking.Hours = this.hours;
      this.booking.Hours_2 = this.hours;
      this.booking.Hours_3 = this.hours;
      this.booking.Hours_4 = this.hours;
      this.booking.Hours_5 = this.hours;
    } else {
      this.booking.Hours = this.formGroup.get('EngagementAllocation').value;
      this.booking.Hours_2 = this.formGroup.get('allocationValue_2').value;
      this.booking.Hours_3 = this.formGroup.get('allocationValue_3').value;
      this.booking.Hours_4 = this.formGroup.get('allocationValue_4').value;
      this.booking.Hours_5 = this.formGroup.get('allocationValue_5').value;
      this.booking.Loading = +this.loading;
      this.booking.Loading_2 = +this.loading_2;
      this.booking.Loading_3 = +this.loading_3;
      this.booking.Loading_4 = +this.loading_4;
      this.booking.Loading_5 = +this.loading_5;
    }
    if (!this.showSplit_2) {
      this.booking.StartDate_2 = null;
      this.booking.EndDate_2 = null;
      this.booking.Loading_2 = null;
      this.booking.Hours_2 = null;
    }
    if (!this.showSplit_3) {
      this.booking.StartDate_3 = null;
      this.booking.EndDate_3 = null;
      this.booking.Loading_3 = null;
      this.booking.Hours_3 = null;
    }
    if (!this.showSplit_4) {
      this.booking.StartDate_4 = null;
      this.booking.EndDate_4 = null;
      this.booking.Loading_4 = null;
      this.booking.Hours_4 = null;
    }
    if (!this.showSplit_5) {
      this.booking.StartDate_5 = null;
      this.booking.EndDate_5 = null;
      this.booking.Loading_5 = null;
      this.booking.Hours_5 = null;
    }
  }

  setEngagementAllocation(isPercentageSelected) {
    if (!isPercentageSelected) {
      this.engagementAllocationText = 'Hours';
      this.engagementAllocationTypeValue = this.hours;
      this.engagementAllocationTypeValue_2 = this.hours;
      this.engagementAllocationTypeValue_3 = this.hours;
      this.engagementAllocationTypeValue_4 = this.hours;
      this.engagementAllocationTypeValue_5 = this.hours;
    } else {
      this.engagementAllocationText = 'Loading %';
      this.engagementAllocationTypeValue = this.loading;
      this.engagementAllocationTypeValue_2 = this.loading;
      this.engagementAllocationTypeValue_3 = this.loading;
      this.engagementAllocationTypeValue_4 = this.loading;
      this.engagementAllocationTypeValue_5 = this.loading;
    }
    this.engagementAllocationPercentSelected = isPercentageSelected;
  }

  setData() {
    this.formGroup.reset();
    this.bsRangeValue = [];
    this.bsRangeValue_2 = [];
    this.bsRangeValue_3 = [];
    this.bsRangeValue_4 = [];
    this.bsRangeValue_5 = [];
    if (this.bookingType.includes('Unconfirmed')) {
      this.unconfirmed = true;
    } else {
      this.unconfirmed = false;
    }
    this.engagementStatus = null;
    this.engagementServiceLine = null;
    this.hoursValid = true;
    this.setEngagementAllocation(this.isPercentageSelected);
  }

  showSplit() {
    this.makeDirty(event);
    this.splitId = this.splitId + 1;
    switch (this.splitId) {
      case 2: {
        if (this.showSplit_2) {
          this.showSplit();
        }
        this.showSplit_2 = true;
        break;
      }
      case 3: {
        if (this.showSplit_3) {
          this.showSplit();
        }
        this.showSplit_3 = true;
        break;
      }
      case 4: {
        if (this.showSplit_4) {
          this.showSplit();
        }
        this.showSplit_4 = true;
        break;
      }
      case 5: {
        if (this.showSplit_5) {
          this.showSplit();
        }
        this.showSplit_5 = true;
        break;
      }
      default: {
        if (this.splitId > 5) {
          this.splitId = 1;
          if (
            this.showSplit_2 &&
            this.showSplit_3 &&
            this.showSplit_4 &&
            this.showSplit_5
          ) {
            break;
          } else {
            this.showSplit();
          }
        }
        if (
          !this.showSplit_2 &&
          !this.showSplit_3 &&
          !this.showSplit_4 &&
          !this.showSplit_5
        ) {
          this.splitId = 1;
          this.showSplit();
        }
        break;
      }
    }
  }

  hideSplit(splitId) {
    this.splitId = splitId - 1;
    switch (splitId) {
      case 2: {
        this.showSplit_2 = false;
        break;
      }
      case 3: {
        this.showSplit_3 = false;
        break;
      }
      case 4: {
        this.showSplit_4 = false;
        break;
      }
      case 5: {
        this.showSplit_5 = false;
        break;
      }
    }
    if (
      !this.showSplit_2 &&
      !this.showSplit_3 &&
      !this.showSplit_4 &&
      !this.showSplit_5
    ) {
      this.splitId = 1;
    }
  }

  mouseEnterSplit() {}

  setStartDateTime(inputDate) {
    let newDate = inputDate;
    if (newDate.getHours() === 0) {
      newDate.setHours(newDate.getHours() + 9);
      newDate.setMinutes(newDate.getMinutes() + 30);
    }
    return newDate;
  }

  setEndDateTime(inputDate) {
    let newDate = inputDate;
    if (newDate.getHours() === 0) {
      newDate.setHours(newDate.getHours() + 17);
      newDate.setMinutes(newDate.getMinutes() + 30);
    }
    return newDate;
  }

  deleteDataItem() {
    this.previewGridSource.splice(this.rowNumberforDelete, 1);
    this.previewGridSource = [...this.previewGridSource];
    this.totalGridElements = this.totalGridElements - 1;
    this.confirmModalRef.hide();
    this.clearControls();
  }

  editDataItem(row: number) {
    this.showSplit_2 = false;
    this.showSplit_3 = false;
    this.showSplit_4 = false;
    this.showSplit_5 = false;

    row === -1 ? this.confirmModalRef.hide() : (this.rowNumberforEdit = row);

    this.updateFlag = true;

    this.getFormControlObj('EngagementResourceName')?.patchValue(
      this.previewGridSource[row].ResourceName
    );
    this.ResourceName = this.previewGridSource[row].ResourceName;
    this.engagementServiceLine =
      this.previewGridSource[row].ServiceLineDescription;
    this.Rank = this.previewGridSource[row].Rank;
    this.resourceLocation = this.previewGridSource[row].LocationDescription;
    this.departmentDescription =
      this.previewGridSource[row].DepartmentDescription;

    this.getFormControlObj('period1', 'start1')?.patchValue(
      this.previewGridSource[row].StartDate
    );

    this.getFormControlObj('period1', 'end1')?.patchValue(
      this.previewGridSource[row].EndDate
    );

    this.getFormControlObj('EngagementAllocation')?.patchValue(
      this.engagementAllocationPercentSelected
        ? this.previewGridSource[row].Loading
        : this.previewGridSource[row].Hours
    );

    this.unconfirmed = this.previewGridSource[row].Unconfirmed;
    this.Ghost = this.previewGridSource[row].Ghost;
    this.description = this.previewGridSource[row].Description;
    this.editFlag = true;

    // this.previewGridSource = [...this.previewGridSource];
  }

  clearControls(action?) {
    this.editFlag = false;
    this.dirtyFlag = false;

    this.ResourceName = '';
    this.engagementServiceLine = '';
    this.Rank = '';
    this.Location = '';

    this.confirmHours = false;
    this.autocompleteResetvalue = '';
    this.description = '';
    this.resourceLocation = '';
    this.departmentDescription = '';

    this.hoursValid = true;

    this.selectedResourceItem = null;

    this.unconfirmed = action == 'reset' ? false : this.unconfirmed;
    //this.unconfirmed = false;
    this.unconfirmed_2 = false;
    this.unconfirmed_3 = false;
    this.unconfirmed_4 = false;
    this.unconfirmed_5 = false;

    // this.Ghost = false;
    this.Ghost = action == 'reset' ? false : this.Ghost;
    this.Ghost_2 = false;
    this.Ghost_3 = false;
    this.Ghost_4 = false;
    this.Ghost_5 = false;

    this.showSplit_2 = false;
    this.showSplit_3 = false;
    this.showSplit_4 = false;
    this.showSplit_5 = false;

    //this.formGroup.reset();
    if (action == 'reset') {
      this.formGroup.get('period1').get('start1').reset();
      this.formGroup.get('period1').get('end1').reset();
      this.formGroup.get('EngagementAllocation').reset();
      this.formGroup.get('EngagementResourceName').reset();
    }
    this.setValidatorsFalse();
  }

  setValidators() {
    if (this.bsRangeValue === null || this.bsRangeValue.length === 0) {
      this.formGroup.get('Period').setErrors({ incorrect: true });
      this.formGroup.get('Period').setValidators(Validators.required);
    }
    if (
      this.engagementAllocationTypeValue === null ||
      this.engagementAllocationTypeValue === undefined
    ) {
      this.formGroup.get('EngagementAllocation').setErrors({ incorrect: true });
      this.formGroup.get('EngagementAllocation').setValidators([
        Validators.required,
        Validators.pattern(/^(?!(0))[0-9]*(\.[0-9]{0,2})?$/),
        //Validators.max(999),
      ]);
    }
    if (this.showSplit_2) {
      if (this.bsRangeValue_2 === null || this.bsRangeValue_2.length === 0) {
        this.formGroup.get('Period_2').setErrors({ incorrect: true });
        this.formGroup.get('Period_2').setValidators(Validators.required);
      }
      if (
        this.engagementAllocationTypeValue_2 === null ||
        this.engagementAllocationTypeValue_2 === undefined
      ) {
        this.formGroup.get('allocationValue_2').setErrors({ incorrect: true });
        this.overallocationerror = false;
        this.formGroup.get('allocationValue_2').setValidators([
          Validators.required,
          Validators.pattern(/^(?!(0))[0-9]*(\.[0-9]{0,2})?$/),
          //Validators.max(999),
        ]);
      }
    }
    if (this.showSplit_3) {
      if (this.bsRangeValue_3 === null || this.bsRangeValue_3.length === 0) {
        this.formGroup.get('Period_3').setErrors({ incorrect: true });
        this.formGroup.get('Period_3').setValidators(Validators.required);
      }
      if (
        this.engagementAllocationTypeValue_3 === null ||
        this.engagementAllocationTypeValue_3 === undefined
      ) {
        this.formGroup.get('allocationValue_3').setErrors({ incorrect: true });
        this.overallocationerror = false;
        this.formGroup.get('allocationValue_3').setValidators([
          Validators.required,
          Validators.pattern(/^(?!(0))[0-9]*(\.[0-9]{0,2})?$/),
          //Validators.max(999),
        ]);
      }
    }
    if (this.showSplit_4) {
      if (this.bsRangeValue_4 === null || this.bsRangeValue_4.length === 0) {
        this.formGroup.get('Period_4').setErrors({ incorrect: true });
        this.formGroup.get('Period_4').setValidators(Validators.required);
      }
      if (
        this.engagementAllocationTypeValue_4 === null ||
        this.engagementAllocationTypeValue_4 === undefined
      ) {
        this.formGroup.get('allocationValue_4').setErrors({ incorrect: true });
        this.overallocationerror = false;
        this.formGroup.get('allocationValue_4').setValidators([
          Validators.required,
          Validators.pattern(/^(?!(0))[0-9]*(\.[0-9]{0,2})?$/),
          //Validators.max(999),
        ]);
      }
    }
    if (this.showSplit_5) {
      if (this.bsRangeValue_5 === null || this.bsRangeValue_5.length === 0) {
        this.formGroup.get('Period_5').setErrors({ incorrect: true });
        this.formGroup.get('Period_5').setValidators(Validators.required);
      }
      if (
        this.engagementAllocationTypeValue_5 === null ||
        this.engagementAllocationTypeValue_5 === undefined
      ) {
        this.formGroup.get('allocationValue_5').setErrors({ incorrect: true });
        this.overallocationerror = false;
        this.formGroup.get('allocationValue_5').setValidators([
          Validators.required,
          Validators.pattern(/^(?!(0))[0-9]*(\.[0-9]{0,2})?$/),
          // Validators.max(999),
        ]);
      }
    }
  }

  openConfirmationModal(template, row, templateName) {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    if (templateName === 'delete') {
      this.rowNumberforDelete = row;
    } else if (templateName === 'edit') {
      this.rowNumberforEdit = row;
    }

    this.confirmModalRef = this.modalService.show(template, this.modalOption);
  }

  markAsPristineAndUntouched(inp1, inp2?) {
    const fgVal = this.getFormControlObj(inp1, inp2);
    fgVal?.markAsPristine();
    fgVal?.markAsUntouched();
  }

  markValidatorsAndErrorsNull(inp1, inp2?) {
    const fgVal = this.getFormControlObj(inp1, inp2);
    fgVal?.setErrors(null);
    fgVal?.setValidators(null);
  }

  setValidatorsFalse() {
    this.markValidatorsAndErrorsNull('EngagementResourceName');

    this.markValidatorsAndErrorsNull('period1', 'start1');
    this.markValidatorsAndErrorsNull('period1', 'end1');

    this.markValidatorsAndErrorsNull('EngagementAllocation');

    this.markAsPristineAndUntouched('period1', 'start1');
    this.markAsPristineAndUntouched('period1', 'end1');

    if (!this.showSplit_2) {
      this.markValidatorsAndErrorsNull('period2', 'start2');
      this.markValidatorsAndErrorsNull('period2', 'end2');
      this.markValidatorsAndErrorsNull('allocationValue_2');
      this.markAsPristineAndUntouched('period2', 'start2');
      this.markAsPristineAndUntouched('period2', 'end2');
    }
    if (!this.showSplit_3) {
      this.markValidatorsAndErrorsNull('period3', 'start3');
      this.markValidatorsAndErrorsNull('period3', 'end3');
      this.markValidatorsAndErrorsNull('allocationValue_3');
      this.markAsPristineAndUntouched('period3', 'start3');
      this.markAsPristineAndUntouched('period3', 'end3');
    }
    if (!this.showSplit_4) {
      this.markValidatorsAndErrorsNull('period4', 'start4');
      this.markValidatorsAndErrorsNull('period4', 'end4');
      this.markValidatorsAndErrorsNull('allocationValue_4');
      this.markAsPristineAndUntouched('period4', 'start4');
      this.markAsPristineAndUntouched('period4', 'end4');
    }
    if (!this.showSplit_5) {
      this.markValidatorsAndErrorsNull('period5', 'start5');
      this.markValidatorsAndErrorsNull('period5', 'end5');
      this.markValidatorsAndErrorsNull('allocationValue_5');
      this.markAsPristineAndUntouched('period5', 'start5');
      this.markAsPristineAndUntouched('period5', 'end5');
    }
  }

  enabledWeek(isCurrentWeekEditable) {
    this.startWith = this.getFirstDateOfWeek(this.todaysDate);
    if (isCurrentWeekEditable) {
      this.startWith.setMinutes(
        this.startWith.getMinutes() - this.startWith.getTimezoneOffset()
      );
    } else {
      this.startWith.setDate(this.startWith.getDate() + 7);
      this.startWith.setMinutes(
        this.startWith.getMinutes() - this.startWith.getTimezoneOffset()
      );
    }
    this.spinner.hide();

    // const dayNeeded = 7 //For Saturday
    // const currentDay = moment().isoWeekday();
    // if (isCurrentWeekEditable) {
    //   if (currentDay === dayNeeded) {
    //     let day = moment().day(dayNeeded);
    //     this.startWith = new Date(day.year(), day.month(), day.date())
    //   } else {
    //     let day = moment().subtract(1, 'weeks').isoWeekday(dayNeeded);
    //     this.startWith = new Date(day.year(), day.month(), day.date());
    //   }
    // } else {
    //   if (currentDay === dayNeeded) {
    //     let day = moment().add(1, 'weeks').isoWeekday(dayNeeded);
    //     this.startWith = new Date(day.year(), day.month(), day.date());
    //   } else {
    //     let day = moment().day(dayNeeded);
    //     this.startWith = new Date(day.year(), day.month(), day.date());

    //   }
    // }
  }
  getFirstDateOfWeek(anyDateOfWeek: Date): Date {
    // date.getDay() RETURNS Week Day Index: SUN 0, MON 1, TUE 2, WED 3, THU 4, FRI 5, SAT 6.
    let date = new Date(anyDateOfWeek);
    let firstDayOfWeekIndex: number = 6; //Week Day Index: SUN 0, MON 1, TUE 2, WED 3, THU 4, FRI 5, SAT 6.
    let daysToShiftBack = (7 + (date.getDay() - firstDayOfWeekIndex)) % 7;
    let firstDateOfWeek = new Date(
      date.setDate(date.getDate() - daysToShiftBack)
    );
    return firstDateOfWeek;
  }

  //CurrentWeekVM: CurrentWeekVM;
  // AddWeek: number = 0;
  // getCurrentWeek() {
  //   this.spinner.show();
  //   this.EngagementServices.getCurrentWeek().subscribe(
  //     (data: any) => {
  //       this.AddWeek = data.AddWeek;
  //       const currentDate = new Date();

  //       if (this.AddWeek == 1) {
  //         this.enabledWeek(false);
  //       }
  //       if (this.AddWeek == 0 || this.AddWeek == null) {
  //         this.enabledWeek(true);
  //       }
  //     });
  // }
}
