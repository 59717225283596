import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { LoginService } from 'app/landing/login.service';
import { environment } from 'environments/environment';
import { Observable, of } from "rxjs";
import { map, catchError } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { EncryptedKey } from '../EncryptedKey/EncryptedKey';

@Injectable()
export class AppInterceptor implements HttpInterceptor {

  constructor(private loginService: LoginService, 
    private router: Router,
    private spinner: NgxSpinnerService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
      let reqCloned = req.clone();
      if (req.url.startsWith(environment.apiEndpoint)) {
        if (sessionStorage.getItem('sessionkey') && !reqCloned.headers.has('x-sessionkey')) {
            reqCloned = reqCloned.clone({ headers: reqCloned.headers.set('x-sessionkey', sessionStorage.getItem('sessionkey')) });
        }
        
        if (!reqCloned.headers.has('Content-Type')) {
          reqCloned = reqCloned.clone({ headers: reqCloned.headers.set('Content-Type', 'application/json; charset=utf-8') });
        }

        if (!reqCloned.headers.has('x-requestkey')) {
          reqCloned = reqCloned.clone({ headers: reqCloned.headers.set('x-requestkey', EncryptedKey.encryptUsingAES256()) });
        }
      }
      return next.handle(reqCloned)
      .pipe(
        map((event: HttpEvent<any>) => {
          sessionStorage.setItem('loginRefreshedOn', (new Date()).toString());
          if (event instanceof HttpResponse) {
            return event;
          }
        })
        ,catchError(this.handleError<any>())
      );
  }

  navigateToErrorView(error) {
      //console.error(JSON.stringify(error));
      sessionStorage.clear();
      localStorage.clear();
      this.router.navigate(['AccessError']);
  }

  
  private handleError<T>(result?: T) {
    // return (error: any): Observable<T> => {
    //   console.error(error); // log to console instead      
    //   return of(result as T);
    // };
    return (error: any): Observable<T> => {
      let isErrorDisplayed = false;
      let status = '';
      let msg = '';
      if(Object.prototype.hasOwnProperty.call(error, 'status')) {
          status = error.status.toString();
      }
      if (typeof error.error === 'string') {
          msg = error.error;
      }
      else if (Object.prototype.hasOwnProperty.call(error.error, 'title')) {
          msg = error.error.title;
          if(error.error.title === "One or more validation errors occurred.") {
            Object.keys(error.error.errors).forEach(function(key,index) {
                // key: property name, index: property sequence no.
                msg = msg + `
                ` + error.error.errors[key];
            });
          }
      }
      else if (Object.prototype.hasOwnProperty.call(error.error, 'customException')) {
          msg = error.error.customException;
          msg += `
          `+ JSON.stringify(error.error.customData);
      }
      if(status === '0' && (!msg || msg.trim().length === 0)) {
        msg = 'MERA Web API Service is down or inaccessible.'
      }

      let exceptionPrefix = '';
      if(status === '400') {
        exceptionPrefix = 'Unexpected error, please try later';
      }
    
      let alertMsg =
      msg && msg.indexOf("One or more validation errors occurred.") > -1
      ? msg
      :`
      Response for MERA Web API Service - 
      Status Code: `+status+`
      Message: `+ exceptionPrefix + ` - 
      `+ msg;

      if(alertMsg.indexOf("One or more validation errors occurred.") > -1) {
        alert(alertMsg);
        isErrorDisplayed = true;
      }

      // if(alertMsg.indexOf(exceptionPrefix) > -1) {
      //   alert(alertMsg);
      //   isErrorDisplayed = true;
      // }

      console.warn(alertMsg);
      console.warn(JSON.stringify(error)); // log to console instead      
      
      // if http response status is 401-Unauthorized or 403-Forbidden(similar to 401 and re-authenticating makes no difference)
      if(status === '401' || status === '403') {
        //this.router.navigate(['AccessError']);
        this.navigateToErrorView(error);
      }
      else if(status === '0') {
        //MERA Web API Service is down or inaccessible.
        // todo: show diff page 
        //this.router.navigate(['AccessError']); 
      }
      
      this.spinner.hide();

      // if(!isErrorDisplayed)
      //   throw error;

      return of(result as T);
    };
  }
}