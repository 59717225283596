import { Injectable, EventEmitter, Output, Directive } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient,HttpHeaders, HttpParams } from '@angular/common/http';
import { MyRequest_RequestByMeVM } from '../../Models/MyRequest_RequestByMeVM';
import { map, catchError } from 'rxjs/operators';
import { MyRequest_RequestForMeVM } from '../../Models/MyRequest_RequestForMeVM';
import { BookingGridActionService } from '../../Models/BookingGridActionService';
import { MyRequest_ApproveByMeVM } from '../../Models/MyRequest_ApproveByMeVM';
import { AuthService } from '../../shared/auth/auth.service';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class RequestQueueService extends BehaviorSubject<any> {
  @Output() change: EventEmitter<boolean> = new EventEmitter();
  private eventData = new BookingGridActionService();
  private deleteSource = new BehaviorSubject(this.eventData);
  deleteEvent = this.deleteSource.asObservable();
  endpoint = environment.apiEndpoint;
  constructor(private http: HttpClient, private auth: AuthService) {
    super(null);
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
      })
  };

  private getAPI(actionName: string): string {
    this.auth.checkTokenExpiredAndRefresh();
    return this.endpoint + 'MyRequest/' + actionName;
  }

  getRequestByMeList(from, to): Observable<any> {
    if(from !=null && to != null){
    return this.http.get<MyRequest_RequestByMeVM[]>(this.getAPI('FetchRequestByMeList?RequestedDateFrom=' + from.toISOString() + '&RequestedDateTo=' + to.toISOString()),this.httpOptions
    ).pipe(
        map(data => data),
        catchError(this.handleError<any>())
      );
    }else{
      return this.http.get<MyRequest_RequestByMeVM[]>(this.getAPI('FetchRequestByMeList'), this.httpOptions
    ).pipe(
        map(data => data),
        catchError(this.handleError<any>())
      );
    }
    
    
  }

  getRequestForMeList(from, to): Observable<any> {
    if(from !=null && to != null){
      return this.http.get<MyRequest_RequestForMeVM[]>(this.getAPI('FetchRequestForMeList?RequestedDateFrom=' + from.toISOString() + '&RequestedDateTo=' + to.toISOString()),
      this.httpOptions).pipe(
        map(data => data),
        catchError(this.handleError<any>())
      );
    }else{
      return this.http.get<MyRequest_RequestForMeVM[]>(this.getAPI('FetchRequestForMeList'),
      this.httpOptions).pipe(
        map(data => data),
        catchError(this.handleError<any>())
      );
    }
    
  }

  getApproveRequestsByMeList(from, to): Observable<any> {
    if(from !=null && to != null){
      return this.http.get<MyRequest_ApproveByMeVM[]>(this.getAPI('FetchApprovedOrRejectedByMeList?RequestedDateFrom=' + from.toISOString() + '&RequestedDateTo=' + to.toISOString()),
      this.httpOptions).pipe(
        map(data => data),
        catchError(this.handleError<any>())
      );
    }else{
      return this.http.get<MyRequest_ApproveByMeVM[]>(this.getAPI('FetchApprovedOrRejectedByMeList'),
      this.httpOptions).pipe(
        map(data => data),
        catchError(this.handleError<any>())
      );
    }
    
  }

  openDeleteModal(eventData: BookingGridActionService) {
    this.deleteSource.next(eventData);
  }

  closeModalWindow: boolean = false;
  //private requestId = 0;
  // private setRequestId = new BehaviorSubject(this.requestId);
  // setRequestIdEvent = this.setRequestId.asObservable();

  // setRequestNumber(requestId) {
  //   this.setRequestId.next(requestId);
  // }

  private messageCloseModal = new BehaviorSubject(this.closeModalWindow);
  currentMessageCloseModal = this.messageCloseModal.asObservable();

  // private messageCloseChildModal = new BehaviorSubject(this.closeModalWindow);
  // currentMessageCloseChildModal = this.messageCloseChildModal.asObservable();

  closeModal(exitModal) {
    this.messageCloseModal.next(exitModal);
  }

  // closeChildModal(exitModal) {
  //   this.messageCloseChildModal.next(exitModal);
  // }

  // cancelRequest(request: MyRequest_RequestByMeVM): Observable<any> {
  //   return this.http.post<MyRequest_RequestByMeVM[]>(this.getAPI('CancelRequest'), request,
  //     { headers: this.httpOptions.headers, observe: 'response' }).pipe(
  //       map(data => data),
  //       catchError(this.handleError<any>('deleteBooking'))
  //     );
  // }

  CancelBookingAction(CancelBookingDto: any): Observable<any> {
    return this.http.post<any>(this.getAPI('CancelRequest'), CancelBookingDto,
      { headers: this.httpOptions.headers, observe: 'response' }).pipe(
        map(data => data),
        catchError(this.handleError<any>('CancelRequest'))
      );
  }

  private handleError<T>(result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      return of(result as T);
    };
  }

}
