export const environment = {
    production: '#{{production}}#',
    EnableAuth: '#{{EnableAuth}}#',
  
    appInsightsInstrumentationKey: '#{{appInsightsInstrumentationKey}}#',
    
    apiEndpoint: '#{{apiEndpoint}}#',
    apiScope: '#{{apiScope}}#',
    uiClientId: '#{{uiClientId}}#',
    authority: '#{{authority}}#',
    redirectUri: '#{{redirectUri}}#',
    postLogoutRedirectUri: '#{{postLogoutRedirectUri}}#',
    chatBotLink: '#{{chatBotLink}}#'
    // APIEndpoint: '#{{APIEndpoint}}#',
    // autherizationServiceUrl: '#{{autherizationServiceUrl}}#',
    // refreshTokenUrl: '#{{refreshTokenUrl}}#',
    // appInsightsInstrumentationKey: '#{{appInsightsInstrumentationKey}}#',
    // redirectUrl: '#{{redirectUrl}}#',
    // logoutUrl: '#{{logoutUrl}}#'
  };
  