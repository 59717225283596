import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest,
} from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginService } from './login.service';
import { CurrentUser } from '../Models/CurrentUser';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit, OnDestroy {
  loggedinUser: any;
  public userRoleMatched: boolean;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private service: LoginService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {}

  ngOnInit() {
    this.spinner.show();
    this.loggedinUser = JSON.parse(sessionStorage.getItem('resource'));
    let roleMatched = '';
    if (this.route.snapshot.paramMap.get('a') !== null) {
      roleMatched = this.route.snapshot.paramMap.get('a').toLowerCase();
    }
    this.userRoleMatched = true;
    if (roleMatched !== '') {
      this.userRoleMatched = roleMatched === 'true' ? true : false;
    }
    if (!this.userRoleMatched) {
      this.router.navigate(['Error']);
    }

    /**
     * You can subscribe to MSAL events as shown below. For more info,
     * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
     */

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkLogin();
      });

    if (
      this.msalService.instance.getAllAccounts().length == 0 &&
      this.getMsalCallStatus() !== 'interaction_in_progress'
    ) {
      this.login();
    }
  }

  getMsalCallStatus() {
    let status = sessionStorage.getItem(
      'msal.' + environment.uiClientId + '.interaction.status'
    );
    // alert('msal.' + environment.uiClientId + '.interaction.status');
    // alert(status);
    return status;
  }

  checkLogin() {
    // when login successful
    if (this.msalService.instance.getAllAccounts().length > 0) {
      if (!this.loggedinUser) {
        let account = this.msalService.instance.getAllAccounts()[0];
        // todo: added for testing / trouble shooting - need to remove
        // alert(`USER IS LOGGED IN :
        // `+ JSON.stringify(account));
        // sessionStorage.setItem('user_id', account.username);
        // sessionStorage.setItem('user_name', account.name);
      }

      if (!sessionStorage.getItem('login_api_initiated')) {
        //this.router.navigate(['Home']);
        // call user profile api
        sessionStorage.setItem('login_api_initiated', 'true');
        setTimeout(() => {
          sessionStorage.removeItem('login_api_initiated');
        }, 5000);
        this.service.login();
      }
    }
  }

  login() {
    //alert('GOING FOR LOGIN');
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.msalService
          .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.msalService.instance.setActiveAccount(response.account);
          });
      } else {
        this.msalService
          .loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.msalService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.msalService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
        } as RedirectRequest);
      } else {
        this.msalService.loginRedirect();
      }
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
