import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { BookingGridActionService } from '../../../Models/BookingGridActionService';
//import { IndividualBookingVM } from '../../../Models/IndividualBookingVM';
import { IndividualService } from '../individual.service';
//import { DatePipe } from '@angular/common';
import { Response } from '../../../Models/Response';
// import { ApplicationInsightService } from 'src/applicationInsightService';
//import { RequestQueueService } from '../../individualRequest/request-queue.service';
import { UiViewSourceEnum } from 'app/shared/enum';
import { CurrentWeekActionDTO } from 'app/Models/CurrentWeekActionDTO';
import { EngagementService } from 'app/Views/engagement/engagement.service';
import { DatePipe } from '@angular/common';
import { SharedServicesService } from '../../../shared/sharedservices/shared-services.service';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.css']
})
export class DeleteComponent implements OnInit {
  @Input() public calledFromChild: boolean;
  // bookingId: number;
  // resourceId: number;
  booking: any; //IndividualBookingVM;
  //eventData: BookingGridActionService;
  @Input() actionEventData: BookingGridActionService;
  deleteBookingSubscription: Subscription;
  //Engagement = '' ;
  // startDate: Date = null;
  // endDate: Date = null;
  closeModalWindow = false;
  response: Response;
  //jobId = 0;
  // jobCategory:any;
  // description:any;
  //currentUserName: any;
  //currentUserId: any;
  //currentUserEmail: any;
  // hours: number;
  // unconfirmed: boolean;
  // loading = '';
  // bookingType = '';
  buttonText = "Delete";
  //instanceId:number;
  // isNewBooking : boolean = false;
  // isNewBookingChildRowsExist: boolean = false;
  //user;
  // ActualBookingId:number=0;
  // ParentBookingId:number=0;
  //meraBookingId;
  @Input() currentWeekActionDTO: CurrentWeekActionDTO;
  @Input() isPercentSelected: boolean;
  @Input() uiViewSource: string;
  today = new Date();
  todaysDate = this.today.getFullYear()+'-'+ ('0'+(this.today.getMonth()+1)).slice(-2) +'-'+ ('0'+this.today.getDate()).slice(-2) +'T00:00:00';
  endOfHistory = this.todaysDate;
  startOfWeek = this.todaysDate; //this.getFirstDateOfWeek(this.todaysDate);
  isHistoryBlockBooking = false;
  defaultDateFormat: string;
  user: any;

  constructor(private individualServices: IndividualService, public datepipe: DatePipe,
    private engagementServices: EngagementService, private sharedService: SharedServicesService
    ) { }

  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem('resource')); 
    this.startOfWeek = this.getFirstDateOfWeek(this.todaysDate);
    this.endOfHistory = this.getLastDateOfPreviousWeek(this.todaysDate);
    this.defaultDateFormat = this.sharedService.getDateFormat(
      this.user.DefaultDateFormat
    );
    
    // this.currentUserName = this.user.ResourceName;
    // this.currentUserEmail = this.user.Email;
    // this.currentUserId = sessionStorage.getItem("resourceId");
    
    // this.applicationInsightsService.logPageView("Individual View Delete Bookings");
    //this.booking = new IndividualBookingVM();
    this.response = new Response();
    // this.individualServices.deleteEvent.subscribe((data) => {
    //   this.eventData = data;
    //   this.setData(data);
    // });
    this.setData(this.actionEventData);
    // this.individualServices.currentMessageCloseModal.subscribe(closeModal => {
    //   this.closeModalWindow = closeModal;
    // });
    // this.individualServices.currentMessageCloseChildModal.subscribe(closeChildModal => {
    //   this.closeModalWindow = closeChildModal;
    // });
  }

  setData(rowdata: BookingGridActionService){
    this.booking = rowdata.dataItem;
    //this.booking = JSON.parse(JSON.stringify(rowdata.dataItem));
    // this.bookingId = rowdata.dataItem.BookingId;
    // this.resourceId = rowdata.dataItem.ResourceId;
    //this.jobId = rowdata.dataItem.JobId;
    // this.startDate = rowdata.dataItem.StartDate;
    // this.endDate = rowdata.dataItem.EndDate;
    //this.Engagement = rowdata.dataItem.Engagement;
    // this.jobCategory= rowdata.dataItem.JobCategory;
    // this.bookingType= rowdata.dataItem.BookingType;
    // this.description= rowdata.dataItem.Description;
    // this.loading = rowdata.dataItem.Loading;
    // this.hours = rowdata.dataItem.Hours;
    // this.instanceId=rowdata.dataItem.InstanceId;
    // this.meraBookingId = rowdata.dataItem.MeraBookingId;
    //todo: remove comment after get sp contain ActualBookingId
    //this.ActualBookingId = rowdata.dataItem.ActualBookingId;
    //this.ParentBookingId = rowdata.dataItem.ParentBookingId;
    
    // this.isNewBookingChildRowsExist = rowdata.dataItem.ActionDescriptionId == 1 && rowdata.dataItem.ChildRowsExist;
    // //this.isNewBooking = (rowdata.dataItem.ActionDescriptionId >  0 &&  rowdata.dataItem.ActionType.length > 0 ) ? true : false;
    // this.isNewBooking = rowdata.dataItem.ActionDescriptionId == 1 && !rowdata.dataItem.ChildRowsExist;

    // if (this.bookingType.includes('Unconfirmed')) {
    //   this.unconfirmed = true;
    // } else {
    //   this.unconfirmed = false;
    // }

    this.isHistoryBlockBooking = new Date(this.booking.StartDate) < new Date(this.startOfWeek);
  }
  
  deleteBooking() {
    this.buttonText="Deleting...";
    // this.booking.BookingId = this.bookingId;
    // this.booking.ResourceId = this.resourceId;
    // this.booking.JobId = this.jobId;
    // this.booking.CurrentUserEmail = this.currentUserEmail;
    // this.booking.CurrentUserName = this.currentUserName;
    // this.booking.currentUserId = +sessionStorage.getItem('resourceId');
    // this.booking.JobCategory = this.jobCategory;
    // this.booking.BookingType = this.bookingType;
    // this.booking.Description = this.description;
    // this.booking.StartDate = new Date(this.startDate);
    // this.booking.EndDate = new Date(this.endDate);
    // this.booking.Loading = +this.loading;
    // this.booking.Hours = this.hours;
    //this.booking.RequestMode = JSON.parse(sessionStorage.getItem('requestMode'));
    //this.booking.InstanceId = this.instanceId;
    //this.booking.UiViewSource = UiViewSourceEnum.IndividualView;
    // this.booking.ActualBookingId = this.booking.BookingId;
    // this.booking.ParentBookingId = 0;

    let bookingDtos: any[] = [];
    //bookingDtos.push(this.booking);
    bookingDtos.push({
      BookingId: this.booking.BookingId,
      ResourceId: this.booking.ResourceId,
      JobId: this.booking.JobId,
      BookingType: this.booking.BookingType,
      Description: this.booking.Description,
      StartDate: this.booking.StartDate,
      EndDate: this.booking.EndDate,
      Hours: this.booking.Hours,
      Loading: this.booking.Loading,
      Unconfirmed: this.booking.Unconfirmed,
      Ghost: this.booking.Ghost,
      MeraActionDescriptionId: 4,
      InstanceId: this.booking.InstanceId,
      ActualBookingId: this.booking.BookingId,
      ParentBookingId: 0
    });
    let bookingActionDto = {
      Bookings: bookingDtos,
      IsPercentSelected: this.isPercentSelected, 
      RequestMode:JSON.parse(sessionStorage.getItem('requestMode')),
      UiViewSource: 
        this.uiViewSource == 'IV' 
        ? UiViewSourceEnum.IndividualView : UiViewSourceEnum.EngamentView
    };

    /*
    if(this.isNewBookingChildRowsExist) {
      this.individualServices.CancelMeraBookingsChild(this.actionEventData.dataItem).subscribe(
        (data: any) => {
          if (data.status = '200') {
            this.response = data.body;
            if (this.calledFromChild) {
              this.individualServices.closeChildModal(true);
            } else {
              this.individualServices.closeModal(true);
            }
          }
          this.buttonText = "Delete";
        }, err => {
          this.buttonText = "Error";
        }, () => {
          this.buttonText = "Delete";
      });
    }
    else if(this.isNewBooking){
      //this.booking.MeraBookingId = this.meraBookingId;
      this.individualServices.CancelBookings(this.actionEventData.dataItem).subscribe(
        (data: any) => {
          if (data.status = '200') {
            this.response = data.body;
            if (this.response.customResultId > 0) { this.individualServices.setRquestNumber(this.response.customResultId); }
            if (this.calledFromChild) {
              this.individualServices.closeChildModal(true);
            } else {
              this.individualServices.closeModal(true);
            }
          }
          this.buttonText = "Delete";
        }, err => {
          this.buttonText = "Error";
        }, () => {
          this.buttonText = "Delete";
        });
    }
    else{
    */
      // this.booking.ActionDescriptionId = 4;
      // this.booking.MeraBookingStatusId = 5;
      this.deleteBookingSubscription = this.individualServices.deleteBooking(bookingActionDto).subscribe(
        (data: any) => {
          if (data.status = '200') {
            this.response = data.body;
            if (this.response.customResultId > 0) { this.individualServices.setRquestNumber(this.response.customResultId); }
            //alert('Successfully saved the changes');
            this.cancelDelete(true);
          }
          this.buttonText = "Delete";
        }, err => {
          this.buttonText = "Error";
        }, () => {
          this.buttonText = "Delete";
        });
    //}
    
    this.dirtyFlag = false;
  }

  cancelDelete(refreshData: boolean){
    if (this.calledFromChild) {
      if(this.uiViewSource == 'IV'){
        this.individualServices.closeChildModal(refreshData);
      }
      else {
        this.engagementServices.closeChildModal(refreshData);
      }
    } 
    else {
      if(this.uiViewSource == 'IV'){
        this.individualServices.closeModal(refreshData);
      }
      else {
        this.engagementServices.closeModal(refreshData);
      }
    }
  }

  public dirtyFlag = true;

  getFirstDateOfWeek(anyDateOfWeek) {
    // date.getDay() RETURNS Week Day Index: SUN 0, MON 1, TUE 2, WED 3, THU 4, FRI 5, SAT 6.
    let date = new Date(anyDateOfWeek);
    let firstDayOfWeekIndex:number = 6; //Week Day Index: SUN 0, MON 1, TUE 2, WED 3, THU 4, FRI 5, SAT 6.
    let daysToShiftBack = (7 + (date.getDay() - firstDayOfWeekIndex)) % 7;
    let firstDateOfWeek = new Date(date.setDate(date.getDate() - daysToShiftBack));
    if(this.currentWeekActionDTO.IsDisabledDeleteBookingCurrWeek === true){
      firstDateOfWeek = new Date(firstDateOfWeek.setDate(firstDateOfWeek.getDate() + 7));
    }
    //this.endOfHistory = new Date(firstDateOfWeek.setDate(firstDateOfWeek.getDate() - 2)).toISOString();
    //return firstDateOfWeek.toISOString();
    return firstDateOfWeek.getFullYear()+'-'+ ('0'+(firstDateOfWeek.getMonth()+1)).slice(-2) +'-'+ ('0'+firstDateOfWeek.getDate()).slice(-2) +'T00:00:00';
  }

  getLastDateOfPreviousWeek(anyDateOfWeek) {
    let date = new Date(this.getFirstDateOfWeek(anyDateOfWeek));    
    //return new Date(date.setDate(date.getDate() - 1)).toISOString();
    date = new Date(date.setDate(date.getDate() - 1));
    return date.getFullYear()+'-'+ ('0'+(date.getMonth()+1)).slice(-2) +'-'+ ('0'+date.getDate()).slice(-2) +'T00:00:00';
  }
}
