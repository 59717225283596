import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, of, observable } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { EngagementSubmissionVM } from '../../Models/EngagementSubmissionVM';
import { ResourceDto } from '../../Models/ResourceDto';
import { BookingGridActionService } from '../../Models/BookingGridActionService';
// import { GridDataResult } from '@progress/kendo-angular-grid';
// import { toODataString } from '@progress/kendo-data-query';
import { EngagementBookingVM} from '../../Models/EngagementBookingVM'
import { BehaviorSubject } from 'rxjs';
// import 'rxjs/add/operator/share';
import { WorkRequestQueueVM} from '../../Models/WorkRequestQueueVM';
import { WorkRequestQueueXLExportVM} from '../../Models/WorkRequestQueueXLExportVM';
import { AuthService } from '../../shared/auth/auth.service';
@Directive()
@Injectable({
  providedIn: 'root'
})
export class WorkRequestService extends BehaviorSubject<any> {
  isPercentageSelected: boolean = false;
  closeModalWindow: boolean = false;
  @Output() change: EventEmitter<boolean> = new EventEmitter();
  private eventData = new BookingGridActionService();
  private engagementSubmission = new EngagementSubmissionVM();
  private requestId = 0;
  private refreshChartData = 0;
  private jobId = 0;
  private refereshWorkRequestQueueData = 0;
  private workRequestSubmission = new WorkRequestQueueVM();
  
  private workRequestSource = new BehaviorSubject(this.workRequestSubmission);
  workRequestEvent = this.workRequestSource.asObservable();

  private messageCloseModal = new BehaviorSubject(this.closeModalWindow);
  currentMessageCloseModal = this.messageCloseModal.asObservable();

  private refreshWorkRequest = new BehaviorSubject(this.refereshWorkRequestQueueData);
  refreshWorkRequestQueueEvent = this.refreshWorkRequest.asObservable();

  endpoint = environment.apiEndpoint;

  constructor(private http: HttpClient, private auth: AuthService) {
    super(null);
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  private getAPI(actionName: string): string {
    this.auth.checkTokenExpiredAndRefresh();
    return this.endpoint + 'WorkRequestQueue/' + actionName;
  }
  
  getWorkRequestQueueList(request: WorkRequestQueueVM): Observable<any> {
    return this.http.post<WorkRequestQueueVM[]>(this.getAPI('GetWorkRequestQueue'), request,
      this.httpOptions).pipe(
        map(data => data),
      catchError(this.handleError<any>('GetWorkRequestQueue'))
      );
  }

  getBookingListForGrid(booking: WorkRequestQueueVM): Observable<any> {
    return this.http.post<WorkRequestQueueVM[]>(this.getAPI('FetchBookingList'), booking,
      this.httpOptions).pipe(
        map(data => data),
        catchError(this.handleError<any>('getBookingListForGrid'))
      );
  }
  
  getEngagementSubmissionList(booking: WorkRequestQueueVM): Observable<any> {
    return this.http.post<WorkRequestQueueVM[]>(this.getAPI('FetchEngagementSubmissions'),
      booking, this.httpOptions).pipe(
        map(data => data),
        catchError(this.handleError<any>('getEngagementSubmissionList'))
      );
  }
 
  getEngagementBookingListForGrid(booking: EngagementBookingVM): Observable<any> {
    return this.http.post<EngagementBookingVM[]>(this.getAPI('FetchEngagementBookingList'), booking,
      this.httpOptions).pipe(
        map(data => data),
        catchError(this.handleError<any>('getEngagementBookingListForGrid'))
      );
  }
  
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      return of(result as T);
    };
  }

  openWorkRequestModal(workRequestSubmission: WorkRequestQueueVM) {
    this.workRequestSource.next(workRequestSubmission);
  }

  closeModal(exitModal) {
    this.messageCloseModal.next(exitModal);
  }

  refreshWorkRequestQueue(refresh) {
    this.refreshWorkRequest.next(refresh);
  }
  
  updateEngagementSubmissionList(workRequestSubmission: WorkRequestQueueVM[]): Observable<any> {
    return this.http.post<WorkRequestQueueVM[]>(this.getAPI('UpdateWorkRequestList'),
      workRequestSubmission, { headers: this.httpOptions.headers, observe: 'response' }).pipe(
        map(data => data),
        catchError(this.handleError<any>('updateEngagementSubmissionList'))
      );
  }

  updateWorkRequestStatus(workRequestSubmission: WorkRequestQueueVM): Observable<any> {
    return this.http.post<WorkRequestQueueVM>(this.getAPI('UpdateWorkRequestStatus'),
      workRequestSubmission, { headers: this.httpOptions.headers, observe: 'response' }).pipe(
        map(data => data),
      catchError(this.handleError<any>('updateWorkRequestStatus'))
      );
  }

  getExportXLData(): Observable<any> {
    return this.http.get<WorkRequestQueueXLExportVM[]>(this.getAPI('GetExportXLData'),
      this.httpOptions).pipe(
        map(data => data),
      catchError(this.handleError<any>('getExportXLData'))
      );
  }

  async getExportData() {
    const excelData = await this.http.get<WorkRequestQueueXLExportVM[]>(this.getAPI('GetExportXLData'), this.httpOptions).toPromise();
    return excelData;
  }

  getResourceList(): Observable<any> {
    return this.http.get<ResourceDto[]>(this.getAPI('GetReources'),
      this.httpOptions).pipe(
        map(data => data),
        catchError(this.handleError<any>('getResourceList'))
      );
  }

  getStatus(requestedBy: WorkRequestQueueVM): Observable<any> {
    return this.http.post<boolean>(this.getAPI('CheckRequestStatus'),requestedBy,this.httpOptions).pipe(
      map(data=>data),
      catchError(this.handleError<any>('getStatus'))
    );
  }

  checkCancelStatus(requestedBy: WorkRequestQueueVM): Observable<any> {
    return this.http.post<boolean>(this.getAPI('CheckRequestCancelStatus'),requestedBy,this.httpOptions).pipe(
      map(data=>data),
      catchError(this.handleError<any>('checkCancelStatus'))
    );
  }
}


